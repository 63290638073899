import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const ContentLoader: React.FC = () => (
	<div className="box2 justify-content-center m-2 p-5 w-100 section-loader">
		<CircularProgress />
	</div>
);

export default ContentLoader;
