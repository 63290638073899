import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function getConsultant(
	consultantType: string,
	id: string,
	search?: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		`admin/consultant?consultantType=${consultantType}${
			id ? `&assetId=${id}` : ""
		}${search ? `&search=${search}` : ""}`
	);
}

export async function assignAssetToConsultant(
	reqData: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "assets-to-admin", undefined, reqData);
}

export async function sendConsultationReport(
	reqData: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"consultation-report",
		undefined,
		reqData,
		undefined,
		false,
		true
	);
}

export async function downloadIAQReport(
	buildingId: string,
	readings: any,
	lIds: string[],
	dimensions: string[]
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		`iaq-reports`,
		undefined,
		{ buildingId, readings, lIds, dimensions },
		undefined,
		false,
		true
	);
}

export async function downloadExposureReport(
	buildingId: string,
	readings: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		`exposure-reports`,
		undefined,
		{ buildingId, readings },
		undefined,
		false,
		true
	);
}

export async function getMarketPlaceProduct(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `market-place/product`);
}

export async function uploadConsultationReport(
	floorFormData: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"v2.0/reports/consultation/upload-report",
		undefined,
		undefined,
		floorFormData
	);
}

export async function doFavouriteUnFavourite(
	product: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"admin/consultant/user-favourite",
		undefined,
		product
	);
}

export async function downloadCSVReport(data: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"gen-csv",
		undefined,
		data,
		undefined,
		undefined,
		true
	);
}
