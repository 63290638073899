import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { AnySrvRecord } from "dns";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";
import { URL } from "../../components/Navbar/routes";

import { BuildingsDetailsModel } from "../../modals/device/Buildings";
import { addBuilding, updateBuilding } from "../../api/buildingsController";
import { SpinnerLoader } from "../../common/Global.Style";
import { RootState } from "../../redux/reducers";
import { getAssetBuildings } from "../../api/AssetController";
import { getTimeZoneByLatLng } from "../../api/buildingsController";
import { setBuildings, setBuilding } from "../../redux/actions/buildingActions";
import "../DeviceManagement/NewBuildign.scoped.scss";
import AddressAutoComplete from "../../components/AddressAutoComplete/AddressAutoComplete";
import { getBuildingDetails } from "../../api/DevicesController";
import { setupWebViewJavascriptBridge } from "../../common/JsBridge";

interface Item {
	label: string;
	value: any;
	path?: string;
}

const AddNewBuldingForMobile: React.FC = () => {
	const history = useHistory();
	const [formData, setFormData] = useState<any>({});
	const [buldingType, setBuldingType] = useState<any>("");

	const [selectedTimeZone, setSelectedTimeZone] = useState<any>({});

	const timezoneOptions: Item[] = [
		{
			label: "EST/EDT",
			value: "EST5EDT",
		},
		{ label: "CST/CDT", value: "CST6CDT" },
		{ label: "MST/MDT", value: "MST7MDT" },
		{ label: "PST/PDT", value: "PST8PDT" },
		{ label: "Alaska", value: "US/Alaska" },
		{ label: "Hawaii-Aleutian", value: "US/Hawaii" },
	];

	const dispatch = useDispatch();

	const building = useSelector((state: RootState) => state.building);
	const [assetId, setAssetId] = useState<string>("");

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const customStyles = {
		control: (provided: Record<string, unknown>, selectState: any) => ({
			...provided,
			border: "none",
			boxShadow: selectState.isFocused
				? "0 0 0 0.2rem rgb(0 123 255 / 25%)"
				: "none",
			borderRadius: "10px",
			background: "#f7f8fb",
		}),
	};

	useEffect(() => {
		if (formData.timeZoneName) {
			const timeZone = timezoneOptions.find(
				(timeZone: Item) => timeZone.value === formData?.timeZoneName
			);
			if (timeZone) {
				setSelectedTimeZone(timeZone);
			}
		}
	}, [formData]);

	const saveDetails = (values: any) => {
		setIsLoading(true);
		let buildingDetails: BuildingsDetailsModel = new BuildingsDetailsModel();
		buildingDetails.address = values.address;
		buildingDetails.description = values.description;
		buildingDetails.name = values.name;
		buildingDetails.zipcode = values.zipcode;
		buildingDetails.city = values.city;
		buildingDetails.state = values.state;
		buildingDetails.timeZoneName = values?.timeZoneName;

		if (formData?._id) {
			updateBuilding(formData._id, buildingDetails)
				.then((response: any) => {
					if (response.responseObj) {
						setIsLoading(false);
						dispatch(setBuilding(get(response, `responseObj.building`, {})));
						onCloseAddNewBuilding();
					} else {
						toast.error(response.error);
						setIsLoading(false);
					}
				})
				.catch((error: AnySrvRecord) => {
					setIsLoading(false);
				});
		} else {
			addBuilding({ ...buildingDetails, asset: assetId })
				.then((response: any) => {
					if (response.responseObj) {
						setIsLoading(false);
						toast.success(response.responseObj.message);
						getBuildingList();
						history.push(
							`${URL.ADDLOCATION}/${assetId}/${response.responseObj.result._id}`
						);
						// onCloseAddNewBuilding();
					} else {
						toast.error(response.error);
						setIsLoading(false);
					}
				})
				.catch((error: AnySrvRecord) => {
					setIsLoading(false);
				});
		}
	};
	const getBuildingList = async (assetId?: any) => {
		const response = await getAssetBuildings(assetId);
		if (response.responseObj) {
			dispatch(setBuildings(get(response, "responseObj.buildings", [])));
			if (!building._id) {
				dispatch(setBuilding(get(response, `responseObj.buildings[0]`, {})));
			}
			return response.responseObj;
		}
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().trim().required("Name is required"),
		// address: Yup.string().required("Address or Hours required"),
		// description: Yup.string().required("Description is required"),
		// city: Yup.string().required("City is required"),
		// zipcode: Yup.string().required("Zipcode is required"),
		// state: Yup.string().required("State is required"),
		timeZoneName: Yup.string().required("Timezone is required"),
	});
	const formik = useFormik({
		initialValues: {
			name: formData?.name || "",
			address: formData.address || "",
			autopilot: formData?.autopilot || false,
			description: formData?.description || "",
			zipcode: formData?.zipcode || "",
			city: formData?.city || "",
			state: formData?.state || "",
			timeZoneName: formData?.timeZoneName || "",
		},
		validationSchema: validationSchema,
		onSubmit: (values: any) => {
			saveDetails(values);
		},
	});
	const setFormikAddress = (address: any) => {
		formik.setFieldValue("address", address);
	};
	const getTimeZone = async (latlng: any) => {
		if (latlng.length) {
			const response = await getTimeZoneByLatLng({
				latLong: latlng[0].location?.lat + "," + latlng[0]?.location?.lng,
			});
			if (response.responseObj && response.responseObj?.timeZoneName) {
				const timeZone = timezoneOptions.filter(
					(time) => time.value == response.responseObj?.timeZoneName
				);
				setSelectedTimeZone(timeZone);
				formik.setFieldValue(
					"timeZoneName",
					response.responseObj?.timeZoneName
				);
			}
		}
	};
	const registerHandler = (funName: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.registerHandler(
				funName,
				function (data: any, responseCallback: any) {
					// data 接收的数据，responseCallback 调用回调函数
					callback && callback(data, responseCallback);
				}
			);
		});
	};
	const onCloseAddNewBuilding = () => {
		callHandler("sendStatusToNative", {}, (data: any) => {});
	};
	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};
	useEffect(() => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			registerHandler("addNewBuildingFromNative", function (data: any) {
				setFormData({});
				setAssetId(data);
			});
		});
		setupWebViewJavascriptBridge(function (bridge: any) {
			registerHandler("editBuildingFromNative", async function (data: any) {
				const dataFromNative: any = data?.split("-DATAFROMNATIVE-");
				if (dataFromNative.length >= 2) {
					const formValue = JSON.parse(dataFromNative[1]);
					setFormData(formValue);
					formik.setFieldValue("name", formValue.name);
					formik.setFieldValue("description", formValue.description);
					formik.setFieldValue("address", formValue.address);
					formik.setFieldValue("timeZoneName", formValue.timeZoneName);
					setAssetId(dataFromNative[0]);
					await getBuildingList(JSON.parse(dataFromNative[0]));
					setBuldingType("edit");
				}
			});
		});
		// setAssetId("1515101534831046656");
		// setFormData({
		// 	_id: "1515101534831046656",
		// 	name: "name",
		// 	description: "description",
		// 	address: "address",
		// });
		// formik.setFieldValue("name", "name");
		// formik.setFieldValue("description", "description");
		// formik.setFieldValue("address", "address");
		// formik.setFieldValue("timeZoneName", "PST8PDT");
	}, []);

	return (
		<div className="col-lg-6 col-md-12">
			<h5 className="mt-3">
				{buldingType === "edit" ? "Update Building" : "Add New Bulding"}
			</h5>
			<div className="row pr-3 pl-3 h-100 pb-5 mb-2">
				<div className="col-lg-12 col-md-12 loca bg-white p-3">
					<div className="container-fluid">
						<FormikProvider value={formik}>
							<form onSubmit={formik.handleSubmit}>
								<div className="row mt-4">
									<div className="col-12">
										<div className="w-100 form-group">
											<div className="d-flex pb-0">
												<h6>Building Name</h6>
											</div>
											<input
												id="name"
												name="name"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.name}
												onChange={formik.handleChange}
												maxLength={100}
											/>
											{formik.touched.name && formik.errors.name ? (
												<div className="text-danger">{formik.errors.name}</div>
											) : null}
										</div>

										{!isEmpty(formData) && (
											<div className="w-100 form-group">
												<div className="d-flex pb-0">
													<h6>Description</h6>
												</div>
												<input
													id="description"
													name="description"
													type="text"
													className="add-re-input form-control pt-2 pb-2"
													value={formik.values.description}
													onChange={formik.handleChange}
												/>
												{formik.touched.description &&
												formik.errors.description ? (
													<div className="text-danger">
														{formik.errors.description}
													</div>
												) : null}
											</div>
										)}

										<div className="w-100 form-group">
											<div className="d-flex pb-0">
												<h6>Address</h6>
											</div>
											<AddressAutoComplete
												setFormikAddress={setFormikAddress}
												formikFormData={formik.values.address}
												getTimeZone={getTimeZone}
											/>
											{formik.touched.address && formik.errors.address ? (
												<div className="text-danger">
													{formik.errors.address}
												</div>
											) : null}
										</div>

										<div className="w-100 form-group">
											<div className="d-flex pb-0">
												<h6>TimeZone</h6>
											</div>
											<Select
												isClearable={true}
												isSearchable={false}
												placeholder="Select timezone"
												onChange={(tz: any) => {
													formik.setFieldValue(
														"timeZoneName",
														get(tz, "value", "")
													);
													setSelectedTimeZone(tz);
												}}
												name="timeZone"
												id="timeZone"
												styles={customStyles}
												value={selectedTimeZone}
												options={timezoneOptions}
											/>
											{formik.touched.timeZoneName &&
											formik.errors.timeZoneName ? (
												<div className="text-danger">
													{formik.errors.timeZoneName}
												</div>
											) : null}
										</div>
										<div className="row mx-0 mt-3 justify-content-end r-g-16">
											<div className="col-lg-6 col-md-12 mx-0">
												<button type="submit" className="btn add mx-0 w-100">
													{formData?._id ? "Update" : "Add"}
												</button>
											</div>
											<div className="col-lg-6 col-md-12 mx-0">
												<button
													type="submit"
													className="btn add mx-0 w-100"
													onClick={onCloseAddNewBuilding}
												>
													Back
												</button>
											</div>
										</div>
									</div>
								</div>
							</form>
						</FormikProvider>
					</div>
				</div>
				{isLoading && <SpinnerLoader />}
			</div>
		</div>
	);
};
export default AddNewBuldingForMobile;
