import { SET_ASSET, SET_ASSETS } from "../actions/actionTypes";

export const assetReducer = (state = {}, action: any) => {
	switch (action.type) {
		case SET_ASSET:
			return { ...action.payload };
		default:
			return state;
	}
};

export const assetsReducer = (state = [], action: any) => {
	switch (action.type) {
		case SET_ASSETS:
			return [...action.payload];
		default:
			return state;
	}
};
