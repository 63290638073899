import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import { RootState } from "../../redux/reducers";
import {
	getAddableDeviceList,
	getSyncDeviceList,
	getBuildingDetails,
} from "../../api/DevicesController";
import SystemTour from "../../components/SystemTour/SystemTour";
import {
	deleteBuildingData,
	generateQRCode,
	getEncryptedBuildingId,
} from "../../api/buildingsController";
import { deleteLocationData } from "../../api/LocationController";
import { SpinnerLoader } from "../../common/Global.Style";
import DeviceList from "../../components/DeviceList/DeviceList";
import MonitorList from "../../components/MonitorList/V2MonitorList";
import GatewayList from "../../components/GatewayList/GatewayList";
import NewBuilding from "./AddNewBuilding";
import AddNewLocation from "../../components/AddNewLocation/AddNewLocation";
import { setSelectedDevice } from "../../redux/actions/deviceActions";
import { setBuildings, setBuilding } from "../../redux/actions/buildingActions";
import { URL } from "../../components/Navbar/routes";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { UnassignedDevice } from "./UnUssingedDeviceList";
import { getAssetBuildings } from "../../api/AssetController";
import { DeviceTab } from "./DeviceTab";
import { DeviceManagementHeader } from "./DeviceManagementHeader";
import copy from "copy-to-clipboard";

import "./DeviceManagement.scoped.scss";
import { QRCodeDownloadModal } from "../../components/QRCodeDownload/QRCodeDownload";
import AllDevices from "../../components/AllDevices/AllDevices";

import "./DeviceManagement.scoped.scss";
import PaymentPendingInfo from "../../components/PaymentPendingInfo/PaymentPendingInfo";
import { isIpadOS } from "../../common/service";
import {
	setupWebViewJavascriptBridge,
	registerHandler,
} from "../../common/JsBridge";
import Shared from "../../common/Shared";
import { UserRoles } from "../../enums/UserRoles";

interface ITab {
	name: string;
	deviceType: string;
}

const DeviceManagement: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const asset = useSelector((state: RootState) => state.asset);
	const building = useSelector((state: RootState) => state.building);
	const user = useSelector((state: RootState) => state.user);
	const assets: any[] = useSelector((state: RootState) => state.assets);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isShowQRDownloadModal, setShowQRDownloadModal] =
		useState<boolean>(false);
	const [isUnAssignDeviceApiLoading, setIsUnAssignDeviceApiLoading] =
		useState<boolean>(false);
	const [addableDevices, setAddableDevice] = useState<Array<any>>([]);
	const [buildingList, setBuildingList] = useState<Array<any>>([]);
	const [initialBuildingList, setInitialBuildingList] = useState<Array<any>>(
		[]
	);
	const [initialAddableDevice, setInitialAddableDevice] = useState<any>([]);
	const [toggleValue, setToggleValue] = useState<any>();
	const [showAddBuilding, setShowAddBuilding] = useState<boolean>(false);
	const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
	const [selectedLocationData, setSelectedLocationData] = useState<any>({});
	const [buildingOption, setBuildingOption] = useState<any>([]);
	const [selectedBuildingData, setSelectedBuildingData] = useState<any>({});
	const [locationType, setLocationType] = useState<string>("");
	const [buildingIndex, setBuildingIndex] = useState<any>(null);
	const [showLocationDelete, setShowLocationDelete] = useState<boolean>(false);
	const [showBuildingDelete, setShowBuildingDelete] = useState<boolean>(false);
	const [selectedBuildingId, setSelectedBuildingId] = useState<string>("");
	const [selectedLocationId, setSelectedLocationId] = useState<string>("");
	const [tourSteps, SetTourSteps] = useState<any>([
		{
			selector: ".unassigned-device-tour",
			content: `Devices added from the iQiCloud app will be listed here under Unassigned Devices. Click on the device and assign it to an existing building or create a new building/location as per requirement,
				For Example: -
				Building can be Name of the business/customer 
				Location can be the location where the device is physically kept - Lobby, Office or Living room.`,
		},
		{
			selector: ".refresh-button-tour",
			content: `Click here to refresh the unassigned devices list`,
		},
		{
			selector: ".add-new-building-tour",
			content: "Click here to add a new building.",
		},
		{
			selector: ".add-new-location-tour",
			content: `Click here to add a new location to a building`,
		},
	]);
	const [deviceTabData] = useState([
		{
			deviceType: "all",
			name: "All",
		},
		{
			deviceType: "monitor",
			name: "Monitor",
		},
		{
			deviceType: "devices",
			name: "Remediation",
		},
		{
			deviceType: "gateway",
			name: "Gateway",
		},
	]);
	const [buildingDataForQRCode, setBuildingDataForQRCode] = useState<any>();

	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};

	useEffect(() => {
		if (
			/android/.test(navigator.userAgent.toLowerCase()) ||
			/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) ||
			isIpadOS()
		) {
			callHandler("sendUserData", { userData: user }, () => {});
		}
		setupWebViewJavascriptBridge(function (bridge: any) {
			registerHandler("isWebViewHandler", function (data: any) {
				localStorage.setItem("isWebView", "true");
			});
		});
	}, []);

	useEffect(() => {
		if (asset?._id) {
			fetchDeviceManagementData();
		}
	}, [asset]);
	const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
	useEffect(() => {
		if (window.innerWidth <= 1024) {
			const tepmTourData = cloneDeep(tourSteps);
			tepmTourData.push({
				selector: ".pair-button-tour",
				content: "Click on Pair Device to Pair your device",
			});
			SetTourSteps(tepmTourData);
		}
	}, []);

	const ProcessBuildingData = (buildingData: any) => {
		const tempBuildingData = cloneDeep(buildingData);
		tempBuildingData?.forEach((mapData: any) => {
			mapData?.locations?.forEach((locationData: any) => {
				const purifier: any = [];
				const monitor: any = [];
				const gateway: any = [];
				locationData?.devices?.forEach((deviceData: any) => {
					if (
						deviceData.deviceType === "remediation" ||
						deviceData.deviceType === "interops"
					) {
						purifier.push(deviceData);
					} else if (deviceData.deviceType === "monitor") {
						monitor.push(deviceData);
					} else if (deviceData.deviceType === "gateway") {
						gateway.push(deviceData);
					}
				});
				set(locationData, "purifier", purifier);
				set(locationData, "monitor", monitor);
				set(locationData, "gateway", gateway);
			});
		});
		setBuildingList(tempBuildingData);
	};

	const fetchDeviceManagementData = async () => {
		setIsLoading(true);
		await getBuildingDetailsApi();
		setIsLoading(false);
		setIsUnAssignDeviceApiLoading(true);
		await refreshButtonClick("refresh");
		setIsUnAssignDeviceApiLoading(false);
	};

	const refreshBuildingData = async (
		refreshType?: string,
		addedReading?: any
	) => {
		setIsLoading(true);
		await getBuildingDetailsApi(refreshType, addedReading);
		setIsLoading(false);
	};

	const onFilterData = (e: any) => {
		const tempData = cloneDeep(initialBuildingList);
		const tempDeviceList = cloneDeep(initialAddableDevice);
		const filteredData = tempData.filter((building: any) => {
			if (
				building?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())
			) {
				return true;
			} else {
				let isFound = false;
				let filteredLocation = building?.locations?.filter((location: any) => {
					if (
						location?.name.toLowerCase().includes(e.target.value.toLowerCase())
					) {
						isFound = true;
						return true;
					}
					const filteredDevices = location?.devices?.filter((device: any) => {
						if (
							device?.deviceName
								.toLowerCase()
								.includes(e.target.value.toLowerCase()) ||
							device?.deviceId
								.toLowerCase()
								.includes(e.target.value.toLowerCase())
						) {
							isFound = true;
							return true;
						}
						return false;
					});
					location.devices = filteredDevices;
					return filteredDevices.length > 0;
				});
				building.locations = filteredLocation;
				return isFound;
			}
		});
		const filteredDevice = tempDeviceList.filter(
			(device: any) =>
				device?.deviceId
					?.toLowerCase()
					?.includes(e.target.value?.toLowerCase()) ||
				device?.deviceName
					?.toLowerCase()
					?.includes(e.target.value?.toLowerCase())
		);
		ProcessBuildingData(filteredData);
		setAddableDevice(filteredDevice);
	};

	const getBuildingDetailsApi = async (
		refreshType: string = "",
		addedrec?: any
	) => {
		const response = await getBuildingDetails(asset?._id);
		if (response.responseObj) {
			if (refreshType === "add-building") {
				const index = response.responseObj.findIndex(
					(mapData: any) => mapData._id === addedrec._id
				);
				setBuildingIndex(index);
				setShowLocationModal(true);
			}
			if (["update-building", "add-building"].includes(refreshType)) {
				getBuildingList(addedrec);
			}
			ProcessBuildingData(response.responseObj);
			setInitialBuildingList(response.responseObj);
			setBuildingOption(
				response.responseObj.map((mapData: any) => ({
					value: mapData._id,
					label: mapData.name,
				}))
			);
		}
	};

	const onAddBuildingClose = async () => {
		setShowAddBuilding(false);
	};

	const refreshButtonClick = async (type: string = "") => {
		if (!type) {
			setIsLoading(true);
		}
		await getSyncDeviceList(asset?._id);
		await getAddableDevices();
		setIsLoading(false);
	};

	const getAddableDevices = async () => {
		const response = await getAddableDeviceList(asset?._id);
		if (response.responseObj) {
			setAddableDevice(response.responseObj);
			setInitialAddableDevice(response.responseObj);
		}
	};

	const deleteBuildingAPICall = () => {
		setIsLoading(true);
		deleteBuildingData(selectedBuildingId)
			.then((response: any) => {
				setShowBuildingDelete(false);
				setIsLoading(false);
				if (response && response.responseObj.message) {
					toast.success(response.responseObj.message);
					refreshBuildingData();
					refreshButtonClick();
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const deleteLocation = () => {
		setIsLoading(true);
		deleteLocationData(selectedLocationId)
			.then((response: any) => {
				setShowLocationDelete(false);
				setIsLoading(false);
				if (response && response.responseObj.message) {
					toast.success(response.responseObj.message);
					refreshBuildingData();
					refreshButtonClick();
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const getBuildingList = async (deviceData: any) => {
		const response = await getAssetBuildings(asset._id);
		if (response.responseObj) {
			dispatch(setBuildings(get(response, "responseObj.buildings", [])));
			if (isEmpty(building) || building?._id === deviceData?._id) {
				dispatch(setBuilding(deviceData));
			}
		}
	};

	const assignDevice = async (deviceData: any) => {
		dispatch(setBuildings(buildingList));
		dispatch(setSelectedDevice(deviceData));
		history.push(URL.CONFIGURE_DEVICE);
	};

	const selectDeviceType = (type: string, id: string) => {
		const tempData = { ...toggleValue };
		tempData[id] = type;
		setToggleValue(tempData);
	};

	const refreshBuildingAndDevice = async () => {
		setIsLoading(true);
		await getBuildingDetailsApi();
		await refreshButtonClick("refresh");
		setIsLoading(false);
	};

	const downloadQRCode = async (buildingData: any) => {
		try {
			let qrCode = buildingData?.qrCode;
			if (!qrCode?.length) {
				const response = await generateQRCode(buildingData?._id);
				if (response?.responseObj) {
					qrCode = response?.responseObj?.result;
				}
			}

			const response: any = await fetch(qrCode, {
				method: "GET",
			});

			if (!response) {
				return null;
			}

			const data = await response.arrayBuffer();

			const url = window.URL.createObjectURL(new Blob([data]));

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `qr_${buildingData?._id}.png`); //or any other extension
			document.body.appendChild(link);
			link.click();
		} catch (err: any) {
			toast.warning(err?.message);
		}
	};

	const copyScript = async (buildingId: string) => {
		const response = await getEncryptedBuildingId(buildingId);
		if (response?.responseObj) {
			const displaysAppUrl =
				Shared.title === "iQi Cloud"
					? process.env.REACT_APP_IQI_DISPLAYS_URL
					: process.env.REACT_APP_IAQ_DISPLAYS_URL;
			const url: string = `${displaysAppUrl}/${response?.responseObj?.result}`;
			copy(`<embed src=${url}></embed>`);
			toast.success("Script copied to clipboard!");
		} else {
			toast.success("Some error occurred!");
		}
	};

	const closeShowQRCodeModal = () => {
		setShowQRDownloadModal(false);
	};

	const showQRCodeModal = async (builidingData: any) => {
		setIsLoading(true);
		if (builidingData?.qrCode) {
			setBuildingDataForQRCode(builidingData);
			setShowQRDownloadModal(true);
		} else {
			const response = await generateQRCode(builidingData?._id);
			if (response?.responseObj) {
				setBuildingDataForQRCode({
					...builidingData,
					qrCode: response?.responseObj?.response?.qrCode,
					qrCodeWithLogo: response?.responseObj?.response?.qrCodeWithLogo,
				});
				setShowQRDownloadModal(true);
			}
		}
		setTimeout(function () {
			setIsLoading(false);
		}, 3000);
	};

	const renderDevice = (
		purifier: any,
		monitor: any,
		gateway: any,
		id: string
	) => {
		switch (get(toggleValue, `[${id}]`, "all")) {
			case "all":
				return (
					<AllDevices
						deviceList={[...monitor, ...gateway, ...purifier]}
						refreshBuildingData={refreshBuildingAndDevice}
						getBuildingDetailsApi={getBuildingDetailsApi}
						locationId={id}
					/>
				);
			case "monitor":
				return (
					<MonitorList
						filteredMonitorList={monitor}
						refreshBuildingData={refreshBuildingAndDevice}
					/>
				);
			case "gateway":
				return (
					<GatewayList
						filteredGatewayList={gateway}
						refreshBuildingData={refreshBuildingAndDevice}
					/>
				);
			case "devices":
			default:
				return (
					<DeviceList
						locationId={id}
						filteredDeviceList={purifier}
						getBuildingDetailsApi={getBuildingDetailsApi}
						refreshBuildingData={fetchDeviceManagementData}
					/>
				);
		}
	};

	const checkAsset = () => {
		return !asset?.payPending;
	};

	return checkAsset() ? (
		<div className="pt-1 w-100">
			{/* <SystemTour tourSteps={tourSteps} position={2} /> */}
			{isLoading && <SpinnerLoader />}
			{showAddBuilding && (
				<NewBuilding
					show={showAddBuilding}
					onHide={() => {
						onAddBuildingClose();
					}}
					refreshBuildingData={refreshBuildingData}
					formData={selectedBuildingData}
				/>
			)}
			{showLocationModal && (
				<AddNewLocation
					formData={selectedLocationData}
					type={locationType}
					buildingOption={buildingOption}
					showBuilding={true}
					show={true}
					index={buildingIndex}
					fetchLocationList={refreshBuildingData}
					onHide={() => {
						setShowLocationModal(false);
						setSelectedLocationData({});
					}}
				/>
			)}
			{showBuildingDelete && (
				<ConfirmDialog
					dialogTitle="Delete"
					message="Are you sure you want to delete this Building?"
					positiveBtnLabel="Delete Permanently"
					negativeBtnLabel=""
					onPositiveBtnClicked={deleteBuildingAPICall}
					onNegativeBtnClicked={() => setShowBuildingDelete(false)}
					onCloseIconClicked={() => setShowBuildingDelete(false)}
				/>
			)}
			{showLocationDelete && (
				<ConfirmDialog
					dialogTitle="Delete"
					message="Are you sure you want to delete this Location?"
					positiveBtnLabel="Delete Permanently"
					negativeBtnLabel=""
					onPositiveBtnClicked={deleteLocation}
					onNegativeBtnClicked={() => setShowLocationDelete(false)}
					onCloseIconClicked={() => setShowLocationDelete(false)}
				/>
			)}
			<div className="d-flex flex-wrap">
				<div className="col-lg-12 col-md-12 col-sm-12 Maintenance-re bg-white p-3">
					<DeviceManagementHeader
						setSelectedBuildingData={setSelectedBuildingData}
						setShowAddBuilding={setShowAddBuilding}
						onFilterData={onFilterData}
						setShowLocationModal={() => {
							setShowLocationModal(true);
							setLocationType("");
						}}
						setBuildingIndex={setBuildingIndex}
						fetchDeviceManagementData={fetchDeviceManagementData}
					/>

					<Card className="mt-4 unassigned-device-tour">
						<UnassignedDevice
							fetchDeviceManagementData={fetchDeviceManagementData}
							isUnAssignDeviceApiLoading={isUnAssignDeviceApiLoading}
							addableDevices={addableDevices}
							assignDevice={assignDevice}
						/>
					</Card>
					<Card className="mt-5 mb-4 device-management-section m-mb-0 m-mt-3">
						<div className="py-3">
							<h5>Buildings</h5>
						</div>
						<div className="d-none m-d-block">
							<div className="d-flex align-items-center mb-3">
								<Button
									onClick={() => {
										setShowAddBuilding(true);
										setSelectedBuildingData({});
									}}
									type="button"
									className="btn add-new-building-tour btn-medium"
								>
									Add New Building
								</Button>
								<Button
									onClick={() => {
										setShowLocationModal(true);
										setBuildingIndex(null);
									}}
									type="button"
									className="btn add-new-location-tour btn-medium mr-3 m-mx-2"
								>
									Add New Location
								</Button>
							</div>
						</div>
						<div>
							{buildingList?.map((buildingData: any, index: number) => {
								return (
									<div key={buildingData._id} className="">
										<Accordion
											defaultExpanded
											className={
												index !== 0
													? "accordian-border dm-building-list position-relative mb-2"
													: " dm-building-list position-relative mb-2"
											}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												<div className="w-100">
													<div className="d-flex align-items-center justify-content-between">
														<div className="flex-1">
															<div className="d-flex align-items-center">
																<div className="office-icon d-flex align-items-center justify-content-center">
																	<LocationCityIcon />
																</div>
																<div className="ml-2 m-d-none">
																	<p className="desc-large font-primary font-weight-bold mb-0">
																		{buildingData.name}
																	</p>
																	<p className="desc-normal font-secondary mt-1 mb-0">
																		{buildingData.address && (
																			<span>{buildingData.address},</span>
																		)}
																		{buildingData.city && (
																			<span>{buildingData.city}, </span>
																		)}
																		{buildingData.state && (
																			<span>{buildingData.state}, </span>
																		)}
																		{buildingData.zipcode && (
																			<span>{buildingData.zipcode}</span>
																		)}
																	</p>
																</div>
															</div>
														</div>
														<div className="flex-1 m-d-none">
															<p className="desc-small font-secondary mb-0">
																Description
															</p>
															<p className="desc-normal font-primary mt-1 mb-0 flex-1">
																{buildingData.description}
															</p>
														</div>
														<div className="d-flex mr-3 m-pr-4">
															{buildingData.locations.length > 0 && (
																<div className="d-flex flex-1">
																	<Tooltip title="Get QRCode" placement="top">
																		<QrCodeIcon
																			className="edit-icon mr-2"
																			onClick={(e: any) => {
																				e.stopPropagation();
																				showQRCodeModal(buildingData);
																			}}
																		/>
																	</Tooltip>

																	<Tooltip
																		title="Copy Script To Clipboard"
																		placement="top"
																	>
																		<ContentCopyIcon
																			className="ml-2 mr-3 edit-icon"
																			onClick={(e: any) => {
																				e.stopPropagation();
																				copyScript(buildingData?._id);
																			}}
																		/>
																	</Tooltip>
																</div>
															)}
															<EditIcon
																className="mr-4 m-mr-2 edit-icon building-edit-tour"
																onClick={(e: any) => {
																	setSelectedBuildingData(buildingData);
																	setShowAddBuilding(true);
																	e.stopPropagation();
																}}
															/>
															<DeleteIcon
																className="edit-icon m-m-0 building-delete-tour"
																onClick={(e: any) => {
																	e.stopPropagation();
																	setShowBuildingDelete(true);
																	setSelectedBuildingData(buildingData);
																	setSelectedBuildingId(buildingData._id);
																}}
															/>
														</div>
													</div>
													<div className="d-none m-d-block mt-3">
														<div className="d-none m-d-block">
															<p className="desc-normal font-primary font-weight-bold mb-0 text-break">
																{buildingData.name}
															</p>
															<p className="desc-small font-secondary mt-1 mb-0 text-break">
																{buildingData.address && (
																	<span>{buildingData.address},</span>
																)}
																{buildingData.city && (
																	<span>{buildingData.city}, </span>
																)}
																{buildingData.state && (
																	<span>{buildingData.state}, </span>
																)}
																{buildingData.zipcode && (
																	<span>{buildingData.zipcode}</span>
																)}
															</p>
															<div className="flex-1 m-mt-2">
																<p className="desc-exsmall font-tertiary mb-0 text-break">
																	Description
																</p>
																<p className="desc-small font-primary mt-1 mb-0 flex-1 text-break">
																	{buildingData.description}
																</p>
															</div>
														</div>
													</div>
												</div>
											</AccordionSummary>
											<AccordionDetails>
												<div className="w-100">
													{buildingData.locations.length === 0 && (
														<div className="text-center font-14">
															No Location Found
														</div>
													)}
													{buildingData.locations.map(
														(locationData: any, locationIndex: number) => {
															const selectedmonitorType = get(
																toggleValue,
																`[${locationData._id}]`,
																"all"
															);
															return (
																<div
																	key={`${locationData?.name}-${locationIndex}`}
																	className="accordian-list"
																>
																	<Accordion className="accordian-border">
																		<AccordionSummary
																			expandIcon={<ExpandMoreIcon />}
																			aria-controls="panel1a-content"
																			id="panel1a-header"
																		>
																			<div className="d-flex justify-content-between w-100 py-2">
																				<p className="font-weight-bold mb-0">
																					{locationData?.name}
																				</p>
																				<div className="mr-3 m-pr-4">
																					<EditIcon
																						className="mr-3 edit-icon"
																						onClick={(e: any) => {
																							setShowLocationModal(true);
																							setSelectedLocationData(
																								locationData
																							);
																							setLocationType("edit");
																							setBuildingIndex(index);
																							e.stopPropagation();
																						}}
																					/>
																					<DeleteIcon
																						className="edit-icon"
																						onClick={(e: any) => {
																							e.stopPropagation();
																							setSelectedLocationData(
																								locationData
																							);
																							setShowLocationDelete(true);
																							setSelectedLocationId(
																								locationData._id
																							);
																						}}
																					/>
																				</div>
																			</div>
																		</AccordionSummary>
																		<AccordionDetails>
																			<div className="d-flex flex-column w-100">
																				<div className="w-100 d-flex flex-wrap">
																					{deviceTabData.map(
																						(mapData: ITab) => (
																							<DeviceTab
																								name={mapData.name}
																								selectedmonitorType={
																									selectedmonitorType
																								}
																								deviceType={mapData.deviceType}
																								locationData={locationData}
																								selectDeviceType={
																									selectDeviceType
																								}
																							/>
																						)
																					)}
																				</div>
																				{renderDevice(
																					get(locationData, "purifier", []),
																					get(locationData, "monitor", []),
																					get(locationData, "gateway", []),
																					locationData._id
																				)}
																			</div>
																		</AccordionDetails>
																	</Accordion>
																</div>
															);
														}
													)}
												</div>
											</AccordionDetails>
										</Accordion>
									</div>
								);
							})}
						</div>
						{buildingList.length === 0 && (
							<p className="d-flex justify-content-center pb-5 pt-3">
								No Building Found
							</p>
						)}
						{isShowQRDownloadModal && (
							<QRCodeDownloadModal
								handleClose={closeShowQRCodeModal}
								buildingData={buildingDataForQRCode}
								open={true}
							/>
						)}
					</Card>
				</div>
			</div>
		</div>
	) : (
		<PaymentPendingInfo name={asset.name} />
	);
};

export default DeviceManagement;
