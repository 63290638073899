import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import BusinessIcon from "@material-ui/icons/Business";
import GroupIcon from "@material-ui/icons/Group";
import get from "lodash/get";

import { RootState } from "../../redux/reducers";
import { URL } from "../Navbar/routes";
import { setBuilding, setBuildings } from "../../redux/actions/buildingActions";
import { Building } from "../../pages/Buildings/Buildings";
import { getLocations } from "../../redux/actions/locationActions";
import { setAsset } from "../../redux/actions/assetActions";
import "./mobile-header.scoped.scss";
import { EventEmitter } from "../../common/EventEmitter";
import { Button } from "@material-ui/core";
import { isIpadOS } from "../../common/service";
import { SpinnerLoader } from "../../common/Global.Style";

import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";

import cloudLogo from "../../images/iqi-cloud-logo.svg";
import { toast } from "react-toastify";
import {
	callHandler,
	registerHandler,
	setupWebViewJavascriptBridge,
} from "../../common/JsBridge";
import { setSelectedDevice } from "../../redux/actions/deviceActions";

export const MobileHeader = () => {
	const dispatch = useDispatch();
	const building: any = useSelector((state: RootState) => state.building);
	const asset: any = useSelector((state: RootState) => state.asset);
	const buildingList: Array<object> = useSelector(
		(state: RootState) => state.buildings
	);
	const assetList: Array<object> = useSelector(
		(state: RootState) => state.assets
	);
	let [buildingsList, setBuildingsList] = useState<any>([]);
	let [assetsList, setAssetsList] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { pathname } = window.location;

	const setAssetData = (asset: any) => {
		dispatch(setAsset(asset));
		dispatch(setBuildings(get(asset, "buildings", [])));
		callHandler("assetToNative", asset?._id, () => {});
		// Added in order to call the buildings list api while changing asset
		EventEmitter.dispatch("assetChange", asset);
		if (asset?.buildings?.length > 0) {
			dispatch(
				getLocations([], (asset?.buildings[0] && asset?.buildings[0]._id) ?? "")
			);
			dispatch(setBuilding(asset?.buildings.length ? asset?.buildings[0] : []));
		} else {
			dispatch(setBuilding({ name: "N/A", _id: null }));
			dispatch(getLocations([], ""));
		}
	};
	useEffect(() => {
		setAssetsList(assetList);
	}, [assetList]);

	useEffect(() => {
		if (
			localStorage?.getItem("isWebView") &&
			localStorage?.getItem("isAssetFromNative")
		) {
			setTimeout(() => {
				localStorage?.removeItem("isAssetFromNative");
			}, 2000);
		} else {
			setBuildingsList(buildingList);
		}
	}, [buildingList]);

	const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
	useEffect(() => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			registerHandler("assetFromNative", function (data: any) {
				setIsLoading(true);
				const selectedAsset = assetList.find((a: any) => a._id === data);
				if (selectedAsset) {
					dispatch(setAsset(selectedAsset));
					dispatch(setBuildings(get(selectedAsset, "buildings", [])));
					setBuildingsList(get(selectedAsset, "buildings", []));
					localStorage.setItem("isAssetFromNative", "true");
					toast.success(get(selectedAsset, "buildings", []));
					forceUpdate();
					setTimeout(() => {
						setIsLoading(false);
					}, 2000);
				}
			});
		});
	}, []);

	const onSearchBuilding = (value: string) => {
		buildingsList = buildingList.filter((building: any) =>
			building.name.toLowerCase().includes(value.toLocaleLowerCase())
		);
		setBuildingsList(buildingsList);
	};

	const onSearchAsset = (value: string) => {
		assetsList = assetList.filter((asset: any) =>
			asset.name.toLowerCase().includes(value.toLocaleLowerCase())
		);
		setAssetsList(assetsList);
	};

	const setBuildingData = (buildingData: Building) => {
		dispatch(setBuilding(buildingData));
		dispatch(getLocations([], (buildingData && buildingData._id) ?? ""));
	};

	const CustomToggle = React.forwardRef<any>(
		({ children, onClick }: any, ref) => (
			<div
				className="buildings"
				onClick={(e) => {
					e.preventDefault();
					onClick(e);
				}}
				ref={ref}
			>
				<div className="drop-down-menu-bar">{children}</div>
			</div>
		)
	);

	return (
		<>
			<div className="px-3 bg-white main-header d-none m-d-block">
				<img src={cloudLogo} alt="Cloud Logo" />
			</div>
			<div className="d-none mobile-header-dropdown m-d-flex align-items-center justify-content-between mobile-dropdown-fixed bg-white py-2 flex-wrap c-g-12 r-g-6">
				<div className="flex-1 text-truncate">
					{![
						URL.DASHBOARD_PROFILE,
						URL.ASSETS,
						URL.USERS,
						URL.DASHBOARD_PRODUCT_HELp,
						URL.CUSTOMER_ACCOUNT,
						URL.CUSTOMER_ACCOUNT_UNASSIGNED,
						URL.DASHBOARD_AQ_PARAMETERS_EXPLAINED,
						URL.CHANGE_PASSWORD,
						URL.CONTRACTOR_USERS,
						URL.ADD_EDIT_CONSULTANT_REPORT,
						URL.MORENAVBAR,
						URL.IAQSUBMENU,
						URL.ADMINSUBMENU,
						URL.IAQPRODUCT,
						URL.IAQCONSULTANT,
						URL.IAQSERVICES,
						URL.IAQCONSULTANTALL,
						URL.IAQVIEWPRODUCT,
						URL.IAQALLPRODUCT,
						URL.ADDNEWBUILDING,
						URL.EDITLOCATION,
						URL.ADDLOCATION,
						URL.EDITNEWBUILDING,
						URL.OFFLINE_SUBSCRIPTION,
						URL.CUSTOMER_ACCOUNT,
						URL.DATA_SIM,
					].includes(pathname) &&
						!(pathname.indexOf(URL.IAQVIEWPRODUCT) !== -1) &&
						!(pathname.indexOf(URL.IAQALLPRODUCT) !== -1) &&
						!(pathname.indexOf(URL.IAQCONSULTANTALL) !== -1) &&
						!(pathname.indexOf(URL.ADDLOCATION) !== -1) && (
							<Dropdown className="profile p-0">
								<Dropdown.Toggle
									variant="Primary"
									id="dropdown-basic"
									bsPrefix="my-dropdown-toggle p-0"
									as={CustomToggle}
								>
									<div className="m-flex m-w-100">
										<div className="flex-1 m-flex m-align-centern text-truncate">
											<GroupIcon className="m-pr-2" />
											<span className="text-truncate">{asset?.name}</span>
										</div>
										<KeyboardArrowDownIcon />
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu style={{ width: "inherit" }} className="p-0">
									{assetList?.length > 0 && (
										<>
											<div className="dashboard-sticky">
												<input
													type="text"
													placeholder="Search Asset"
													className="dashboard-sticky"
													onChange={(event) =>
														onSearchAsset(event?.target?.value?.trim())
													}
												/>
											</div>
											{assetsList.map((singleAsset: any, index: number) => {
												return (
													<>
														<Dropdown.Item
															className="simple text-break"
															key={index}
															onClick={() => setAssetData(singleAsset)}
														>
															{singleAsset.name}
														</Dropdown.Item>
													</>
												);
											})}
											{assetsList?.length === 0 && (
												<Dropdown.Item
													className="simple cursor-disabled"
													key={"No-asset"}
												>
													No result found
												</Dropdown.Item>
											)}
										</>
									)}
									{assetsList?.length === 0 && (
										<Dropdown.Item
											className="simple cursor-disabled"
											key={"No-building"}
										>
											No Asset Available
										</Dropdown.Item>
									)}
								</Dropdown.Menu>
							</Dropdown>
						)}
					{![
						URL.CLUSTER_CONFIG,
						URL.DASHBOARD_BUILDINGS,
						URL.ASSETS,
						URL.USERS,
						URL.DEVICE_MANAGEMENT,
						URL.CONFIGURE_DEVICE,
						URL.DASHBOARD_PROFILE,
						URL.DASHBOARD_PRODUCT_HELp,
						URL.DASHBOARD_AQ_PARAMETERS_EXPLAINED,
						URL.CUSTOMER_ACCOUNT,
						URL.CUSTOMER_ACCOUNT_UNASSIGNED,
						URL.CHANGE_PASSWORD,
						URL.CONTRACTOR_USERS,
						URL.ADD_EDIT_CONSULTANT_REPORT,
						URL.CONSULTANTS,
						URL.MORENAVBAR,
						URL.IAQSUBMENU,
						URL.ADMINSUBMENU,
						URL.IAQPRODUCT,
						URL.IAQCONSULTANT,
						URL.IAQSERVICES,
						URL.IAQCONSULTANTALL,
						URL.IAQVIEWPRODUCT,
						URL.IAQALLPRODUCT,
						URL.ADDNEWBUILDING,
						URL.EDITLOCATION,
						URL.ADDLOCATION,
						URL.EDITNEWBUILDING,
						URL.OFFLINE_SUBSCRIPTION,
						URL.CUSTOMER_ACCOUNT,
						URL.DATA_SIM,
					].includes(pathname) &&
						!(pathname.indexOf(URL.IAQVIEWPRODUCT) !== -1) &&
						!(pathname.indexOf(URL.IAQALLPRODUCT) !== -1) &&
						!(pathname.indexOf(URL.IAQCONSULTANTALL) !== -1) &&
						!(pathname.indexOf(URL.ADDLOCATION) !== -1) &&
						!(pathname.indexOf(URL.CONFIGURE_DEVICE) !== -1) && (
							<Dropdown className="profile p-0">
								<Dropdown.Toggle
									variant="Primary"
									id="dropdown-basic"
									bsPrefix="my-dropdown-toggle p-0"
									as={CustomToggle}
								>
									<div className="m-flex m-w-100">
										<div className="flex-1 m-flex m-align-center text-truncate">
											<BusinessIcon className="m-pr-2" />
											<span className="text-truncate">
												{get(building, "name", "N/A")}
											</span>
										</div>
										<KeyboardArrowDownIcon />
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu style={{ width: "inherit" }} className="p-0">
									{buildingList?.length > 0 && (
										<>
											<div className="dashboard-sticky">
												<input
													type="text"
													placeholder="Search Building"
													onChange={(event) =>
														onSearchBuilding(event?.target?.value?.trim())
													}
												/>
											</div>
											{buildingsList.map(
												(singlebuilding: any, index: number) => {
													return (
														<>
															<Dropdown.Item
																className="simple text-break"
																key={index}
																onClick={() => setBuildingData(singlebuilding)}
															>
																{singlebuilding.name}
															</Dropdown.Item>
														</>
													);
												}
											)}
											{buildingsList?.length === 0 && (
												<Dropdown.Item
													className="simple cursor-disabled"
													key={"No-buildings"}
												>
													No result found
												</Dropdown.Item>
											)}
										</>
									)}
									{buildingsList?.length === 0 && (
										<Dropdown.Item
											className="simple cursor-disabled"
											key={"No-building"}
										>
											No Building Available
										</Dropdown.Item>
									)}
								</Dropdown.Menu>
							</Dropdown>
						)}
					{isLoading && <SpinnerLoader />}
				</div>
				{/* <Button className="btn radius-20 xs-w-100 btn-medium">
					Pair devices
				</Button> */}
			</div>
		</>
	);
};
