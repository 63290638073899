import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";

import { IMenuItem } from "../../common/Interface";
import {
	SidebarLink,
	SidebarLabel,
	DropdownLink,
	SidebarCollapsibleLabel,
} from "./Navbar.Style";
import "./Navbar.scoped.scss";

interface SubMenuProps {
	item: IMenuItem;
	keyValue: any;
	handleDrawerClose: any;
}

const SubMenu: React.FC<SubMenuProps> = ({
	item,
	keyValue,
	handleDrawerClose,
}) => {
	const { path, subNav, icon, iconOpened, iconClosed, title } = item;
	const [subnav, setSubnav] = useState(false);

	useEffect(() => {
		if (subNav?.length) {
			const isFound = subNav.find(
				(mapData) => mapData.path === window.location.pathname
			);
			if (!isEmpty(isFound)) {
				setSubnav(true);
			}
		}
	}, []);

	const showSubnav = () => setSubnav(!subnav);

	return (
		<>
			{path && (
				<SidebarLink
					className={
						window.location.pathname === path ? "active-link mb-1" : "mb-1"
					}
					to={path}
					key={keyValue}
					onClick={() => {
						subNav && showSubnav();
						handleDrawerClose();
					}}
				>
					<div>
						{icon}
						<SidebarLabel className="side-menu-font">{title}</SidebarLabel>
					</div>
					<div>
						{subNav && subnav ? iconOpened : subNav ? iconClosed : null}
					</div>
				</SidebarLink>
			)}
			{!path && (
				<SidebarCollapsibleLabel key={keyValue} onClick={subNav && showSubnav}>
					<div>
						{icon}
						<SidebarLabel className="side-menu-font">{title}</SidebarLabel>
					</div>
					<div>
						{subNav && subnav ? iconOpened : subNav ? iconClosed : null}
					</div>
				</SidebarCollapsibleLabel>
			)}
			<div className="sub-nav-container">
				{subnav &&
					subNav?.map((item: any, index: any) => {
						return (
							<div key={`${index}-${item.path}`} className="">
								<DropdownLink
									className={
										window.location.pathname === item.path
											? "active-link mb-1"
											: "mb-1"
									}
									onClick={handleDrawerClose}
									to={item.path}
									key={index}
								>
									<SidebarLabel className="sub-menu-font">
										{item.title}
									</SidebarLabel>
								</DropdownLink>
							</div>
						);
					})}
			</div>
		</>
	);
};

export default SubMenu;
