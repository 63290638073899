import { GET_LOCATION, SET_LOCATION } from "./actionTypes";

export const getLocations = (locations: any, buildingId: string) => {
	return {
		type: GET_LOCATION,
		payload: { locations: locations, buildingId: buildingId },
	};
};

export const setLocations = (locations: any) => {
	return {
		type: SET_LOCATION,
		payload: locations,
	};
};
