import React from "react";
import { isEmpty } from "lodash";
import { CanvasExposureChart } from "./ExposureChart";
import "./ExposureAccordian.scoped.scss";

interface Item {
	value: string;
	label: string;
}

interface Props {
	exposureGraphData: any;
	selectedLocations: Item[];
	dimension: string[];
}

const ExposureGraphList: React.FC<Props> = (props) => {
	const { exposureGraphData, dimension, selectedLocations } = props;
	const locations: string[] = selectedLocations.map((item: Item) => {
		return item.label;
	});
	return (
		<div className="row mt-4 root-container flex-grow-1 w-100">
			{!isEmpty(exposureGraphData) &&
				Object.entries(exposureGraphData).map(([key, value]: [string, any]) => {
					return (
						<div className="col-xl-12 list-monitor-container mb-5 col">
							{locations.includes(key) && (
								<>
									<div className="exposure-chart-item">
										<div className="d-flex justify-content-between bg-white para-title">
											<div className="pt-2 w-100">
												<h6 className="text-center">{key}</h6>
											</div>
										</div>
										<div className="row w-100 sensor-value-row flex-nowrap bg-white m-flex-wrap">
											{dimension.includes("Temperature") && (
												<div className="col">
													<div className="d-flex flex-column align-items-center para-items">
														<div className="position-relative d-flex align-items-center justify-content-center">
															<CanvasExposureChart
																title="Temperature"
																graphData={value["temp_current_f"]}
															/>
														</div>
													</div>
												</div>
											)}
											{dimension.includes("Humidity") && (
												<div className="col">
													<div className="d-flex flex-column align-items-center para-items">
														<div className="position-relative d-flex align-items-center justify-content-center">
															<CanvasExposureChart
																title="Humidity"
																graphData={value["humidity_value"]}
															/>
														</div>
													</div>
												</div>
											)}

											{dimension.includes("CO2") && (
												<div className="col">
													<div className="d-flex flex-column align-items-center para-items">
														<div className="position-relative d-flex align-items-center justify-content-center">
															<CanvasExposureChart
																title="CO2"
																graphData={value["co2_value"]}
															/>
														</div>
													</div>
												</div>
											)}
											{dimension.includes("PM2.5") && (
												<div className="col">
													<div className="d-flex flex-column align-items-center para-items">
														<div className="position-relative d-flex align-items-center justify-content-center">
															<CanvasExposureChart
																title="PM2.5"
																graphData={value["pm25_value"]}
															/>
														</div>
													</div>
												</div>
											)}
										</div>
										<div className="row w-100 sensor-value-row flex-nowrap bg-white m-flex-wrap">
											{dimension.includes("PM10") && (
												<div className="col">
													<div className="d-flex flex-column align-items-center para-items">
														<div className="position-relative d-flex align-items-center justify-content-center">
															<CanvasExposureChart
																title="PM10"
																graphData={value["pm10"]}
															/>
														</div>
													</div>
												</div>
											)}
											{dimension.includes("PM1.0") && (
												<div className="col">
													<div className="d-flex flex-column align-items-center para-items">
														<div className="position-relative d-flex align-items-center justify-content-center">
															<CanvasExposureChart
																title="PM1.0"
																graphData={value["pm1"]}
															/>
														</div>
													</div>
												</div>
											)}
											{dimension.includes("TVOC") && (
												<div className="col">
													<div className="d-flex flex-column align-items-center para-items">
														<div className="position-relative d-flex align-items-center justify-content-center">
															<CanvasExposureChart
																title="TVOC"
																graphData={value["voc_value"]}
															/>
														</div>
													</div>
												</div>
											)}
											{dimension.includes("Formaldehyde") && (
												<div className="col">
													<div className="d-flex flex-column align-items-center para-items">
														<div className="position-relative d-flex align-items-center justify-content-center">
															<CanvasExposureChart
																title="Formaldehyde"
																graphData={value["ch2o_value"]}
															/>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
									<hr />
								</>
							)}
						</div>
					);
				})}
			{isEmpty(exposureGraphData) && (
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 text-center">
					No Data Found
				</div>
			)}
		</div>
	);
};

export default ExposureGraphList;
