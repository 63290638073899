import { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useLocation, useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { SpinnerLoader } from "../../common/Global.Style";
import {
	getConsultant,
	assignAssetToConsultant,
} from "../../api/commonController";
import { URL } from "../../components/Navbar/routes";
import { RootState } from "../../redux/reducers";

import "./ConsultantList.scoped.scss";

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const ConsultantList = () => {
	let query = useQuery();
	const consultationType: any = query.get("type");
	const history = useHistory();
	const asset = useSelector((state: RootState) => state.asset);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [consultantList, setConsultantList] = useState([]);
	const [initialConsultantList, setInitialConsultantList] = useState([]);

	useEffect(() => {
		loadPageAPi();
	}, [asset]);

	const loadPageAPi = async () => {
		setIsLoading(true);
		await getAllConsultant();
		setIsLoading(false);
	};

	const getAllConsultant = async () => {
		const response = await getConsultant(consultationType, asset._id);
		if (response?.responseObj?.length) {
			setConsultantList(get(response, "responseObj", []));
			setInitialConsultantList(get(response, "responseObj", []));
		}
	};

	const searchAutopilotLog = (e: any) => {
		let { value } = e.target;
		value = value.toLowerCase();
		setConsultantList(
			initialConsultantList.filter((filterData: any) => {
				if (
					filterData?.firstName?.toLowerCase()?.includes(value) ||
					filterData?.lastName?.toLowerCase()?.includes(value) ||
					filterData?.email?.toLowerCase()?.includes(value)
				) {
					return true;
				}
				return false;
			})
		);
	};

	const assignAsset = async (email: string) => {
		setIsLoading(true);
		const response = await assignAssetToConsultant({
			assets: [{ _id: asset._id, name: asset.name }],
			email,
		});
		if (response.responseObj) {
			await getAllConsultant();
			toast.success(response?.responseObj?.message);
		} else {
			toast.error(response.error);
		}
		setIsLoading(false);
	};

	return (
		<div className="p-4 bg-white mt-4">
			<div className="market-place-container">
				{isLoading && <SpinnerLoader />}
				<div
					className="d-flex cursor-pointer"
					onClick={() => history.push(URL.CONSULTATION_REPORTS)}
				>
					<ArrowBackIosIcon fontSize="small" />
					<p className="breadcrumb-para">Consultation</p>
				</div>

				<div className="search-section mt-2">
					<input
						onChange={debounce(searchAutopilotLog, 1000)}
						type="text"
						placeholder="search"
					/>
					<span>
						<SearchIcon />
					</span>
				</div>

				<p className="mt-3 text-center">
					** iQi is not responsible for consultant hiring process and payments
					for consultants. It is purely between the user and consultants
				</p>

				<div className="market-block">
					<div>
						<div className="recomended-products row mt-4">
							{consultantList?.map((mapData: any) => (
								<div className="product-card col">
									<div className="d-flex">
										<div className="product-img">
											<img
												alt="prod"
												src={get(
													mapData,
													"avatar",
													"https://iqintel-common.s3.amazonaws.com/avatar.jpg"
												)}
											/>
										</div>
										<div className="product-des">
											<div className="product-email">{mapData.email}</div>
											<div className="product-name">
												{mapData.firstName} {mapData.lastName}
											</div>
											<div className="product-name">
												{mapData.contactNumber}
											</div>
										</div>
									</div>
									<div className="mt-4">
										<p className="desc-normal consultant-desc mb-1">
											{mapData?.description}
										</p>
									</div>
									{asset._id && (
										<button
											onClick={() => assignAsset(mapData.email)}
											className="btn btn-primary-outline mt-3"
											disabled={mapData?.isAssetAssignedAlready === 1}
										>
											{mapData?.isAssetAssignedAlready === 1
												? "Already Hired"
												: "Hire Consultant"}
										</button>
									)}
								</div>
							))}
							{isEmpty(consultantList) && (
								<div className="d-flex justify-content-center mt-5 w-100">
									<p>No Consultant Available</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConsultantList;
