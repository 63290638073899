import React, { useEffect, useState } from "react";
import { SelectableRows } from "mui-datatables";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import get from "lodash/get";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { SpinnerLoader } from "../../common/Global.Style";
import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import SubscriptionPlanDialog from "../../components/SubscriptionPlanModal/SubscriptionPlanModal";
import {
	getInvoiceList,
	getSubscriptionList,
} from "../../api/SubscriptionController";
import { Dropdown } from "react-bootstrap";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PriceBreakdownModal from "./PriceBreakdown";
import InvoiceModal from "./ViewInvoice";
import MobileBack from "../../components/MobileBack";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash/debounce";
import "./Subscription.scopped.scss";
import { RootState } from "../../redux/reducers";
import { useSelector } from "react-redux";

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: any;
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			className="root-table"
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const Subscription: React.FC = () => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [monitoringList, setMonitoringList] = useState<any>([]);
	const [initialMonitoringList, setInitialMonitoringList] = useState<any>([]);
	const [showSubscriptionList, setShowSubscriptionList] = useState(false);
	const [showBreakdownModal, setShowBreakdownModal] = useState<boolean>(false);
	const [showInvoicesModal, setShowInvoicesModal] = useState<boolean>(false);
	const [subscriptionData, setSubscriptionData] = useState<any>({});
	const [invoiceList, setInvoiceList] = useState<any>([]);
	const [showInvoiceColumn, setShowInvoiceColumn] = useState<any>([]);
	const user: any = useSelector((state: RootState) => state.user);

	useEffect(() => {
		getAllSubscription();
	}, []);

	const getAllSubscription = async () => {
		setIsLoading(true);
		const response = await getSubscriptionList();
		setIsLoading(false);
		const subscriptionList = get(response, "responseObj.records", []);
		const showInvoice = get(response, "responseObj.showInvoice", false);
		setShowInvoiceColumn(showInvoice);
		const tempMonitorList: any = [];
		if (subscriptionList?.length) {
			subscriptionList.forEach((mapData: any) => {
				if (mapData?.planName === "Continuous Monitoring Plan") {
					tempMonitorList.push(mapData);
				}
			});
		}
		setMonitoringList(tempMonitorList);
		setInitialMonitoringList(tempMonitorList);
	};

	const onClickBreakDown = (data: any) => {
		setShowBreakdownModal(true);
		setSubscriptionData(data);
	};

	const onClickViewInvoices = async (data: any) => {
		const response = await getInvoiceList(data?.subscriptionId);
		if (response?.responseObj && response?.responseObj?.list?.length) {
			setInvoiceList(response?.responseObj?.list);
			setShowInvoicesModal(true);
		}
	};

	const options = {
		print: false,
		download: false,
		filter: false,
		responsive: "vertical",
		viewColumns: false,
		selectableRows: "none" as SelectableRows,
	};

	const columns = [
		{
			name: "isCouponApplied",
			options: {
				filter: false,
				display: false, // hide this filed in Data-Table
			},
		},
		{
			name: "name",
			label: "Customer Name",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => {
					return <p className="mb-0">{!!value ? value : "-"}</p>;
				},
			},
		},
		{
			name: "activeDevices",
			label: "Devices Used in Current Cycle",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "subscriptionId",
			label: "SubscriptionId",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "_id",
			label: "Invoices",
			options: {
				filter: false,
				sort: true,
				display: showInvoiceColumn,
				customBodyRender: (value: any, tableMeta: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex - 1] ? (
								<p
									className="mb-0 link"
									onClick={() => {
										const data = monitoringList.find(
											(m: any) => m._id === value
										);
										onClickViewInvoices(data);
									}}
								>
									View
								</p>
							) : (
								<p>-</p>
							)}
						</>
					);
				},
			},
		},
		{
			name: "percentage",
			label: "Discount %",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => {
					return <p className="mb-0">{!!value ? value : "-"}</p>;
				},
			},
		},
		{
			name: "isCouponApplied",
			label: "Discount Applied",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: boolean) => {
					return <p className="mb-0">{value ? "Applied" : "Not-Applied"}</p>;
				},
			},
		},
		{
			name: "amount",
			label: "Next Payment Due",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: number) => {
					return <p className="mb-0">{!!value ? `$${value / 100}` : `-`}</p>;
				},
			},
		},
		{
			name: "nextPaymentDate",
			label: "Next Payment Date",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => {
					return (
						<p className="mb-0">
							{!!value ? moment(value).format("MMM DD,YYYY") : `-`}
						</p>
					);
				},
			},
		},
		{
			name: "_id",
			label: "Price Breakdown",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => {
					return (
						<p
							className="mb-0 link"
							onClick={() => {
								const data = monitoringList.find((m: any) => m._id === value);
								setSubscriptionData(data);
								setShowBreakdownModal(true);
							}}
						>
							View
						</p>
					);
				},
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: string) => {
					return <p className="mb-0">{value}</p>;
				},
			},
		},
	];
	const goBack = () => {
		history.goBack();
	};

	const searchMonitor = (e: any) => {
		let { value } = e.target;
		value = value.toLowerCase();
		setMonitoringList(
			initialMonitoringList?.filter((filterData: any) =>
				filterData?.name?.toLowerCase()?.includes(value)
			)
		);
	};

	const renderMobileCard = (mapData: any) => (
		<div>
			<div className="d-flex justify-content-between">
				<p className="desc-normal font-primary font-bold text-truncate mb-2 m-mv-200 asset-name">
					{mapData.name}
				</p>

				{(mapData?.amount > 0 || user?.paymentType === "online") && (
					<Dropdown className="more-dropdown">
						<Dropdown.Toggle
							id="dropdown-basic"
							bsPrefix="my-dropdown-toggle p-0"
						>
							<MoreHorizIcon className="i-20 font-tertiary cursor-pointer" />
						</Dropdown.Toggle>
						<Dropdown.Menu className="card-action" style={{ width: "inherit" }}>
							{mapData?.amount > 0 && (
								<Dropdown.Item
									onClick={() => {
										onClickBreakDown(mapData);
									}}
								>
									View Breakdown
								</Dropdown.Item>
							)}
							{user?.paymentType === "online" && (
								<Dropdown.Item
									onClick={() => {
										onClickViewInvoices(mapData);
									}}
								>
									View Invoice
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				)}
			</div>
			<div className="d-flex card-first-block">
				<div className="d-flex flex-1 flex-column">
					<p className="desc-exsmall card-title-color  d-flex justify-content-between mb-0">
						Devices Used in Current Cycle
					</p>
					<p className="desc-small font-bold card-value-color d-flex justify-content-between mb-0">
						<span>{mapData.activeDevices}</span>
					</p>
				</div>
				<div className="d-flex flex-1 flex-column">
					<p className="desc-exsmall card-title-color d-flex justify-content-between mb-0">
						Discount
					</p>
					<p className="desc-small font-bold card-value-color d-flex justify-content-between mb-0">
						<span>{mapData?.discount}</span>
					</p>
				</div>
				<div className="d-flex flex-1 flex-column">
					<p className="desc-exsmall card-title-color  d-flex justify-content-between mb-0">
						Next Payment Due
					</p>
					<p className="desc-small font-bold card-value-color d-flex justify-content-between mb-0">
						<span>${mapData.amount / 100}</span>
					</p>
				</div>
			</div>

			<div className="d-flex justify-content-between card-first-block mt-3">
				<div className="d-flex flex-1 flex-column">
					<p className="desc-exsmall card-title-color  d-flex justify-content-between mb-0">
						Next Payment Date
					</p>
					<p className="desc-small font-bold card-value-color d-flex justify-content-between mb-0">
						<span>
							{mapData.nextPaymentDate &&
								moment(mapData.nextPaymentDate).format("MMM DD,YYYY")}
						</span>
					</p>
				</div>
				<div className="d-flex flex-1 flex-column">
					<p className="desc-exsmall card-title-color d-flex justify-content-between mb-0">
						Discount Applied
					</p>
					<p className="desc-small font-bold card-value-color d-flex justify-content-between mb-0">
						<span>{mapData.isCouponApplied ? "Applied" : "Not-Applied"}</span>
					</p>
				</div>
			</div>
		</div>
	);

	return (
		<div className="w-100">
			<div className="customer-account-page p-4 m-p-0 w-100">
				{isLoading && <SpinnerLoader />}
				{showSubscriptionList && (
					<SubscriptionPlanDialog
						onPositiveBtnClicked={() => setShowSubscriptionList(false)}
						onCloseIconClicked={() => setShowSubscriptionList(false)}
					/>
				)}
				{showBreakdownModal && (
					<PriceBreakdownModal
						show={showBreakdownModal}
						data={subscriptionData}
						onHide={() => setShowBreakdownModal(false)}
					/>
				)}
				{showInvoicesModal && (
					<InvoiceModal
						show={showInvoicesModal}
						data={invoiceList}
						onHide={() => setShowInvoicesModal(false)}
					/>
				)}
				<div className="customer-account-content">
					<div className="d-flex subscription-top-container justify-content-end">
						<div className="d-flex subscription-heading justify-content-between w-100">
							<div
								onClick={() => goBack()}
								className="align-items-center pl-2 d-none m-d-flex pt-2 pb-3 sub-header"
							>
								<ArrowBackIcon className="i-18 txt-primary font-600" />
								<h2 className="desc-large font-600 font-text-4 mb-0">
									Subscription
								</h2>
							</div>
							<h2 className="subscription-head mb-0 m-d-none d-flex align-items-center pb-3">
								Subscription
							</h2>
							<div className="justify-content-end d-flex pb-3 m-p-0">
								<button
									onClick={getAllSubscription}
									className="btn primary-btn ml-3 add-customer refresh-subscription-tour"
								>
									Refresh
								</button>
								<button
									onClick={() => setShowSubscriptionList(true)}
									className="btn primary-btn ml-3 add-customer"
								>
									View Plans
								</button>
							</div>
						</div>
					</div>

					<div className="table-responsive subscription-table m-d-none">
						<CustomDataTable
							title=""
							rows={monitoringList}
							columns={columns}
							options={options}
						/>
					</div>
					<div className="responsive-search">
						<input
							type="text"
							placeholder="Search by Name"
							onChange={debounce(searchMonitor, 700)}
						/>
						<SearchIcon />
					</div>
					{/* Mobile Card Design */}
					<div className="m-customer-account d-none m-d-block">
						<div className="m-customer-account-cards">
							{!!monitoringList.length &&
								monitoringList.map((mapData: any, index: number) => (
									<div
										className={
											monitoringList.length - 1 === index
												? "m-customer-account-card mb-2 subscription-last-element"
												: "m-customer-account-card mb-2"
										}
									>
										{renderMobileCard(mapData)}
									</div>
								))}
						</div>
						{!monitoringList.length && (
							<div className="d-flex justify-content-center">
								<p>Sorry, no matching records found</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Subscription;
