import React, { useEffect, useState } from "react";
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import flatten from "lodash/flatten";
import { toast } from "react-toastify";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../redux/reducers";
import { URL } from "../../../components/Navbar/routes";
import { getAssetBuildings } from "../../../api/AssetController";
import { SpinnerLoader } from "../../../common/Global.Style";
import {
	getProductData,
	addDevice,
	getMasterDimension,
	getSensorsDataList,
	getCategoriesList,
} from "../../../api/DevicesController";
import {
	getLocations,
	setLocations,
} from "../../../redux/actions/locationActions";
import { locationMapSelector } from "../../../redux/selectors/locationSelector";
import AddNewLocation from "../../../components/AddNewLocation/AddNewLocation";
import NewBuilding from "../../DeviceManagement/AddNewBuilding";

import "./ConfigureDevice.scoped.scss";
import { getUserDimension } from "../../../api/clusterConfigController";
import { MultiSelectWithAdd } from "../../../components/MultiSelectWithAdd/MultiSelectWithAdd";
import {
	CategoryOptions,
	ProductCategory,
	ProductCategoryResponse,
} from "../../../common/Interface";
import { ReplacementPart } from "../../../components/AddEditRemediation/AddEditRemediation";
import AddIcon from "@material-ui/icons/Add";
import { RemoveCircle } from "@material-ui/icons";
import { MultiSelectWithCheckBox } from "../../../components/MultiSelectWithCheckbox/MultiSelectWithCheckbox";
import SearchableSelect from "./SearchableSelect";
import {
	registerHandler,
	setupWebViewJavascriptBridge,
} from "../../../common/JsBridge";
import { setSelectedDevice } from "../../../redux/actions/deviceActions";
import { isIpadOS } from "../../../common/service";
import { setAsset } from "../../../redux/actions/assetActions";

interface Item {
	label: string;
	value: string | boolean;
}

interface DimensionOption {
	label: string;
	value: string[];
}

export const colourStyles = {
	control: (styles: any) => ({ ...styles, borderRadius: "5px" }),
	option: (styles: any, { data }: any) => {
		let customStyle = { ...styles };
		if (data.value === "*") {
			customStyle = { ...styles, fontSize: "14px", fontWeight: "500" };
		}
		return {
			...customStyle,
		};
	},
};

const ConfigureDevice: React.FC = () => {
	const locations: Item[] = locationMapSelector(
		useSelector((state: RootState) => state)
	);
	const asset = useSelector((state: RootState) => state.asset);
	const device: any = useSelector((state: RootState) => state?.devices);

	const history = useHistory();
	const dispatch = useDispatch();

	const [selectedBuilding, setSelectedBuilding] = useState<any>();
	const [selectedLocation, setSelectedLocation] = useState<any>();
	const [fromNativeData, setFromNativeData] = useState<any>({});
	const [buildingOption, setBuildingOption] = useState<any>([]);
	const [searchableOptions, setSearchableOptions] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedDeviceData, setSelectedDeviceData] = useState<any>({});
	const [parameterName, setParameterName] = useState<any>({});
	const [assetParameters, setAssetParameter] = useState<any>({});
	const [productError, setProductError] = useState<string>("");
	const [showAddBuilding, setShowAddBuilding] = useState<boolean>(false);
	const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
	const [formErrorObject, setFormErrorObject] = useState<any>({});
	const [buildingIndex, setBuildingIndex] = useState<any>(null);
	const [selectedCategory, setSelectedCategory] = useState<any>();
	const [categoryOption, setCategoryOption] = useState<CategoryOptions[]>();
	const [selectedRemediationType, setSelectedRemediationType] =
		useState<Item[]>();
	const [remediationType, setRemediationType] = useState<string[]>();
	const [showReplacementSection, setShowReplacementSection] =
		useState<boolean>(false);
	const [selectedReplacement, setSelectedReplacement] = useState<
		Item | undefined
	>(undefined);
	const replacementOptions: Item[] = [
		{ label: "Yes", value: true },
		{ label: "No", value: false },
	];
	const [selectedLocations, setSelectedLocations] = useState<Array<Item>>([]);

	let [commonRemediationTypes] = useState<Array<DimensionOption>>([
		{
			label: "Particulate Matter (PM)",
			value: ["pm25_value", "pm10", "pm1"],
		},
		{ label: "Carbon di oxide (CO2)", value: ["co2_value"] },
		{
			label: "Volatile Organic Compounds (VOC)",
			value: ["voc_value", "ch2o_value"],
		},
		{ label: "Humidifier", value: ["humidity_value"] },
		{ label: "Dehumidifier", value: ["humidity_value"] },
	]);

	const initialize = () => {
		if (!device?.selectedDevice?.deviceId) {
			history.push(URL.DEVICE_MANAGEMENT);
		} else {
			dispatch(setLocations([]));
			getBuildings();
			getProductDataApi();
		}
	};

	useEffect(() => {
		if (selectedDeviceData.deviceType === "remediation") {
			getMasterDimension()
				.then((response: any) => {
					if (response.responseObj) {
						let tempParameterData: any = {};
						let tempParameterWithIdData: any = {};
						get(response, "responseObj", []).forEach((mapData: any) => {
							tempParameterData[mapData._id] = mapData.shortName;
							tempParameterWithIdData[mapData.shortName] = mapData._id;
						});
						setParameterName(tempParameterData);
					}
				})
				.catch((error: any) => {
					console.log(error);
				});

			getSensorsDataList(asset._id)
				.then((response: any) => {
					if (response.responseObj) {
						let tempAssetParameterData: any = {};
						let tempAssetParameterWithIdData: any = {};
						get(response, "responseObj.dimensions", []).forEach(
							(mapData: any) => {
								tempAssetParameterData[mapData.shortName] = mapData._id;
								tempAssetParameterWithIdData[mapData._id] = mapData.shortName;
							}
						);
						setAssetParameter(tempAssetParameterData);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [selectedDeviceData.deviceType]);

	const getProductDataApi = async (deviceId?: any) => {
		setIsLoading(true);
		const productResponse: any = await getProductData(
			deviceId ? deviceId : device?.selectedDevice?.deviceId
		);
		if (productResponse.responseObj) {
			setSelectedDeviceData(productResponse.responseObj);

			// To get the list of default categories only for interops
			if (productResponse.responseObj.deviceType === "interops") {
				getCategoriesListApi();
				getUserDimensionListApi();
			}
			setTimeout(() => {
				setIsLoading(false);
			}, 2000);
		} else {
			setProductError(productResponse.error);
			setIsLoading(false);
		}
	};

	const getCategoriesListApi = async () => {
		const productCategories: ProductCategoryResponse = await getCategoriesList(
			true
		);
		if (productCategories?.responseObj) {
			setCategoryOption(
				productCategories?.responseObj.map((mapData: ProductCategory) => ({
					value: mapData,
					label: mapData.name,
				}))
			);
		}
	};

	const getUserDimensionListApi = async () => {
		const userDimensions: any = await getUserDimension(asset?._id);

		let remediationTypes: any = {};
		if (userDimensions?.responseObj?.dimensions) {
			userDimensions.responseObj.dimensions.forEach((dimension: any) => {
				remediationTypes[dimension["code"]] = dimension["_id"];
			});
			commonRemediationTypes.forEach((item: DimensionOption) => {
				let value = item.value.map((data: string) => {
					return remediationTypes[data];
				});
				item.value = value;
			});
		}
	};

	const setCreatedBuilding = (buildingData: any) => {
		setSelectedBuilding({
			value: buildingData._id,
			label: buildingData.name,
		});
		const tempData = cloneDeep(formErrorObject);
		tempData.building = "";
		setFormErrorObject(tempData);
	};

	const setCreatedLocation = (locationData: any) => {
		if (selectedBuilding.value === locationData.building) {
			setSelectedLocation({
				value: locationData._id,
				label: locationData.name,
			});
			const tempData = cloneDeep(formErrorObject);
			tempData.location = "";
			setFormErrorObject(tempData);
		}
	};

	const getBuildings = async (refreshType?: string, addedrec?: any) => {
		setIsLoading(true);
		const response = await getAssetBuildings(asset._id);
		const buildings = get(response, "responseObj.buildings", []);
		if (refreshType === "add-building") {
			dispatch(getLocations([], addedrec._id));
			const index = buildings.findIndex(
				(mapData: any) => mapData._id === addedrec._id
			);
			setBuildingIndex(index);
		}
		setBuildingOption(
			buildings?.map((mapData: any) => ({
				value: mapData._id,
				label: mapData.name,
			}))
		);
		setIsLoading(false);
	};
	const checkIsMobile = () => {
		if (
			/android/.test(navigator.userAgent.toLowerCase()) ||
			/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) ||
			isIpadOS()
		) {
			return true;
		}
		return false;
	};

	const createDevice = async (formData: any) => {
		if (productError) {
			toast.error(
				"This product doesn't exist in the catalog, please contact your admin."
			);
			return;
		}
		const formError: any = {};
		if (!selectedLocation?.value && !selectedLocations.length) {
			formError.location = "Location is required";
		}
		if (!selectedBuilding?.value) {
			formError.building = "Building is required";
		}
		if (
			selectedDeviceData.deviceType === "interops" &&
			!remediationType?.length
		) {
			formError.remediationType = "Remediation type is required";
		}
		if (
			selectedDeviceData.deviceType === "interops" &&
			!selectedCategory?.value
		) {
			formError.category = "Category is required";
		}
		if (!isEmpty(formError)) {
			setFormErrorObject(formError);
			return;
		}
		setIsLoading(true);
		let deviceData: any = {
			asset: asset._id,
			building: selectedBuilding?.value,
			location: selectedLocation?.value,
			description: formData.description,
			deviceId: device?.selectedDevice?.deviceId,
			deviceName: formData.name,
			deviceType: selectedDeviceData.deviceType,
			subType: selectedDeviceData.subType,
			image: selectedDeviceData.itemImage,
		};
		switch (selectedDeviceData.deviceType) {
			case "monitor":
				deviceData = {
					...deviceData,
					features: [],
				};
				break;
			case "gateway":
				deviceData = {
					...deviceData,
					features: [],
					gatewayType: selectedDeviceData.subType,
				};
				break;
			case "remediation":
				let productDimension: any = [];
				const productfearures: string = get(
					selectedDeviceData,
					"productsFeature",
					[]
				).map((mapData: any) => {
					productDimension = [...productDimension, ...mapData.dimensions];
					return mapData._id;
				});
				const replacementParts = get(
					selectedDeviceData,
					"replacementPartDueDays",
					[]
				).map((mapData: any) => {
					const dueDate = new Date();
					dueDate.setDate(dueDate.getDate() + parseInt(mapData.days));
					return { name: mapData.name, days: mapData.days, dueDate };
				});
				let dimensions: any = [];
				productDimension = [...new Set(productDimension)];
				productDimension.forEach((dimensionId: string) => {
					const code = parameterName[dimensionId];
					dimensions.push(assetParameters[code]);
				});

				deviceData = {
					...deviceData,
					features: productfearures,
					areaCoverage: selectedDeviceData.areaCoverage,
					replacementParts,
					category: get(selectedDeviceData, "productCategory._id"),
					dueDate: "",
					dimensions,
				};
				break;
			case "interops":
				deviceData = {
					...deviceData,
					location: selectedLocations.map((location) => {
						return location.value;
					}),
					category: selectedCategory?.value?._id,
					features: selectedCategory?.value?.features,
					dimensions: remediationType,
					replacementParts: showReplacementSection
						? formik.values.replacementParts
						: [],
				};
				break;
		}
		const response: any = await addDevice(deviceData);
		if (response.responseObj) {
			setTimeout(() => {
				onSubmitAddDevice();
			}, 5000);
			setTimeout(() => {
				setIsLoading(false);
				history.push(URL.DEVICE_MANAGEMENT);
			}, 8000);
		} else if (response.error) {
			setIsLoading(false);
			toast.error(response.error);
		}
	};

	let validationSchemaTemp: any = {
		name: Yup.string().trim().required("Name is required"),
		replacementParts: null,
	};

	if (showReplacementSection) {
		validationSchemaTemp.replacementParts = Yup.array().of(
			Yup.object().shape({
				name: Yup.string().required("Name is required"),
				days: Yup.number()
					.min(1, "Minimum 1 day required")
					.max(9999, "Maximum days should be lesser than 4 years"),
			})
		);
	}
	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};
	const onSubmitAddDevice = () => {
		callHandler("sendStatusToNative", {}, (data: any) => {});
	};
	const registerHandler = (funName: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.registerHandler(
				funName,
				function (data: any, responseCallback: any) {
					callback && callback(data, responseCallback);
				}
			);
		});
	};
	const formik = useFormik({
		initialValues: {
			name: device?.selectedDevice?.deviceName,
			description: "",
			replacementParts: [
				{
					name: "",
					days: 0,
				},
			],
		},
		validationSchema: Yup.object().shape(validationSchemaTemp),
		onSubmit: (values) => {
			createDevice(values);
		},
	});
	const onChangeBuilding = (event: any) => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
		if (event.value === "new") {
			setShowAddBuilding(true);
			setSelectedBuilding((prevData: any) => prevData);
		} else {
			const index = buildingOption.findIndex(
				(mapData: any) => mapData.value === event.value
			);
			setBuildingIndex(index);
			dispatch(getLocations([], event.value));
			setSelectedBuilding(event);
			setSelectedLocation(null);
			const tempData = cloneDeep(formErrorObject);
			tempData.building = "";
			setFormErrorObject(tempData);
		}
	};

	const onChangeCategory = (event: CategoryOptions) => {
		setSelectedCategory(event);
	};

	const onChangeRemediationType = (event: Item[]) => {
		setSelectedRemediationType(event);
		const dimensions: string[] = event?.map((data) => data.value) as string[];
		setRemediationType(flatten(dimensions));
	};

	const onChangeReplacement = (event: any) => {
		setSelectedReplacement(event);
		setShowReplacementSection(event?.value);
	};

	const onAddBuildingClose = async () => {
		setShowAddBuilding(false);
	};

	const onChangeLocation = (event: any) => {
		if (event.value === "*") {
			setShowLocationModal(true);
			setSelectedLocation((prevData: any) => prevData);
		} else {
			const tempData = cloneDeep(formErrorObject);
			tempData.location = "";
			setFormErrorObject(tempData);
			setSelectedLocation(event);
		}
	};

	const onSelectMultipleLocation = (event: any) => {
		if (event.length && event[0].value === "+") {
			setShowLocationModal(true);
			setSelectedLocations((prevData: any) => prevData);
		} else {
			selectedLocations.length === locations.length
				? setSelectedLocations([])
				: setSelectedLocations(event);
		}
	};

	const onSelectAddNewLocation = () => {
		setShowLocationModal(true);
		setSelectedLocations((prevData: any) => prevData);
	};

	const multiSelect: any = {
		control: (styles: any) => ({
			...styles,
			"min-width": "270px !important",
		}),
	};
	useEffect(() => {
		setSearchableOptions([
			{ value: "new", label: "+ Add New Building" },
			...buildingOption,
		]);
	}, [buildingOption]);
	const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

	useEffect(() => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			registerHandler("addUnassignedDeviceFromNative", function (data: any) {
				const nativeData = JSON.parse(data);
				dispatch(setSelectedDevice(nativeData));
				dispatch(setAsset({ _id: nativeData.assetId }));
				setFromNativeData(nativeData);
				formik.setFieldValue("name", nativeData.deviceName);
				forceUpdate();
				setTimeout(function () {
					dispatch(setLocations([]));
					getBuildings();
					getProductDataApi(nativeData.deviceId);
				}, 300);
			});
		});
	}, []);

	useEffect(() => {
		if (
			!(
				/android/.test(navigator.userAgent.toLowerCase()) ||
				/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) ||
				isIpadOS()
			)
		) {
			initialize();
		}
	}, []);

	return (
		<>
			<div className="mt-3">
				{isLoading && <SpinnerLoader />}
				{showAddBuilding && (
					<NewBuilding
						show={showAddBuilding}
						onHide={onAddBuildingClose}
						refreshBuildingData={getBuildings}
						formData={{}}
						setCreatedBuilding={setCreatedBuilding}
					/>
				)}
				{showLocationModal && (
					<AddNewLocation
						formData={{ _id: selectedBuilding.value }}
						type={""}
						buildingOption={buildingOption}
						showBuilding={true}
						show={showLocationModal}
						index={buildingIndex}
						setCreatedLocation={setCreatedLocation}
						fetchLocationList={() => {
							if (selectedBuilding) {
								dispatch(getLocations([], selectedBuilding.value));
							}
						}}
						onHide={() => {
							setShowLocationModal(false);
						}}
					/>
				)}
				<div>
					<div className="col-md-12 col-lg-12 col-sm-12">
						<div className="row mt-3 pl-3 pr-3 pt-3 box bg-white pb-5 mb-2">
							<div className="col-lg-12 col-md-12 col-sm-12 p-3">
								<div className="d-flex mb-2">
									{checkIsMobile() && (
										<h5
											className="mr-3 cursor-pointer md-f-15"
											onClick={() => onSubmitAddDevice()}
										>
											Device Management
										</h5>
									)}
									{!checkIsMobile() && (
										<h5
											onClick={() => history.push(URL.DEVICE_MANAGEMENT)}
											className="mr-3 cursor-pointer md-f-15"
										>
											Device Management
										</h5>
									)}
									<h5 className="mr-3"> {">"} </h5>
									<h5 className="md-f-15">Configure Devices</h5>
									<input type="text" value={fromNativeData} hidden={true} />
								</div>

								<div className="row">
									<div className="col-md-10 col-lg-8 col-sm-12 mt-4">
										<FormikProvider value={formik}>
											<form onSubmit={formik.handleSubmit}>
												<div className="d-flex md-d-block mb-5">
													<div className="w-50 md-w-100">
														<h6 className="">
															Select Building
															<span className="error-message ml-1">*</span>
														</h6>
														<div className="dropdown pl-0 pr-0">
															<div className="dropdown w-100 pl-0 pr-0 search-input-details">
																{/* <Select
																	className="basic-multi-select"
																	classNamePrefix="select"
																	name="building"
																	placeholder="Select building"
																	onChange={onChangeBuilding}
																	options={[
																		{
																			value: "new",
																			label: "+ Add New Building",
																		},
																		...buildingOption,
																	]}
																	value={selectedBuilding}
																	styles={colourStyles}
																/> */}
																<SearchableSelect
																	searchableOptions={searchableOptions}
																	onChangeBuilding={onChangeBuilding}
																	selectedBuilding={selectedBuilding}
																/>
																{formErrorObject?.building && (
																	<div className="text-danger error-message mt-1">
																		{formErrorObject.building}
																	</div>
																)}
															</div>
														</div>
													</div>
													<div className=" ml-2 w-50 md-w-100 md-m-0 md-p-t-3">
														<div className="form-group ">
															<h6>
																Device Name
																<span className="error-message ml-1">*</span>
															</h6>
															<input
																type="text"
																id="name"
																className="input w-100 p-2 ml-1"
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																value={formik.values.name}
																maxLength={100}
															/>
															{formik.touched.name && formik.errors.name && (
																<div className="text-danger ml-2 mt-1">
																	{formik.errors.name}
																</div>
															)}
														</div>
													</div>
												</div>

												<div className="d-flex md-d-block">
													{selectedDeviceData?.deviceType !== "interops" &&
														selectedBuilding?.value && (
															<div className=" ml-2 w-50 md-w-100 md-m-0 md-p-t-3">
																<h6 className="">
																	Location
																	<span className="error-message ml-1">*</span>
																</h6>
																<div className="dropdown w-100  pl-0 pr-0">
																	<Select
																		className="basic-multi-select"
																		classNamePrefix="select"
																		onChange={onChangeLocation}
																		options={[
																			{
																				value: "*",
																				label: "+ Add New Location",
																			},
																			...locations,
																		]}
																		placeholder="Select location"
																		value={selectedLocation}
																		styles={colourStyles}
																		name="location"
																	/>
																</div>
																{formErrorObject?.location && (
																	<div className="text-danger mt-1">
																		{formErrorObject.location}
																	</div>
																)}
															</div>
														)}

													{selectedDeviceData?.deviceType === "interops" && (
														<div className="w-50 mb-3 md-w-100">
															<h6 className="">
																Locations
																<span className="error-message ml-1">*</span>
															</h6>
															<div className="dropdown w-100  pl-0 pr-0">
																<MultiSelectWithAdd
																	selectedOptions={
																		locations.length
																			? [
																					{ value: "*", label: "All" },
																					...locations,
																			  ]
																			: []
																	}
																	selectValue={selectedLocations}
																	onSelectionChange={onSelectMultipleLocation}
																	customStyle={multiSelect}
																	placeholder="Select locations"
																	onAddNewLocation={onSelectAddNewLocation}
																/>
															</div>
															<span className="desc-normal">
																Select all applicable locations
															</span>
														</div>
													)}

													<div className=" ml-2 w-50 md-w-100 md-m-0 md-p-t-3">
														<div className="form-group ">
															<h6>Description</h6>
															<input
																id="description"
																type="text"
																className="input w-100 p-2 ml-1"
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																value={formik.values.description}
																maxLength={100}
															/>
															{formik.touched.description &&
															formik.errors.description ? (
																<div className="text-danger ml-2 mt-1">
																	{formik.errors.description}
																</div>
															) : null}
														</div>
													</div>
												</div>

												{selectedDeviceData?.deviceType === "interops" && (
													<div>
														<div className="d-flex md-d-block">
															<div className="w-50 md-w-100">
																<h6 className="">
																	Select Category
																	<span className="error-message ml-1">*</span>
																</h6>
																<div className="dropdown pl-0 pr-0">
																	<div className="dropdown w-100 pl-0 pr-0">
																		<Select
																			className="basic-select"
																			classNamePrefix="select"
																			name="category"
																			placeholder="Select Category"
																			onChange={onChangeCategory}
																			options={categoryOption}
																			value={selectedCategory}
																			styles={colourStyles}
																		/>
																		{formErrorObject?.category && (
																			<div className="text-danger error-message mt-1">
																				{formErrorObject.category}
																			</div>
																		)}
																	</div>
																</div>
															</div>
															<div className="w-50 md-w-100 ml-2 md-m-0 md-p-t-3">
																<h6 className="">
																	Remediation Type
																	<span className="error-message ml-1">*</span>
																</h6>
																<div className="dropdown pl-0 pr-0">
																	<MultiSelectWithCheckBox
																		seletedOptions={commonRemediationTypes}
																		selectValue={selectedRemediationType}
																		onSelectionChange={onChangeRemediationType}
																		customStyle={multiSelect}
																	/>
																</div>
																<span className="desc-normal">
																	Choose the right remediation type,
																	auto-remediation works based on this selection
																</span>
																{formErrorObject?.remediationType && (
																	<div className="text-danger mt-1">
																		{formErrorObject.remediationType}
																	</div>
																)}
															</div>
														</div>
														<div>
															<div className="w-50 md-w-100 mr-2 mt-3">
																<h6>Replacement Parts</h6>
																<div className="dropdown w-100  pl-0 pr-0">
																	<Select
																		className="basic-select"
																		classNamePrefix="select"
																		onChange={onChangeReplacement}
																		options={replacementOptions}
																		value={selectedReplacement}
																	/>
																</div>
															</div>
															{showReplacementSection && (
																<div className="w-100 bg-light add-re p-3 mt-3">
																	<FieldArray
																		name="replacementParts"
																		render={(arrayHelpers) => (
																			<>
																				{formik.values.replacementParts &&
																					formik.values.replacementParts.map(
																						(
																							replacementPart: ReplacementPart,
																							index: number
																						) => (
																							<div
																								className="col-lg-12 p-0 add-re-pr d-flex"
																								key={index}
																							>
																								<div className="col-lg-5 form-group">
																									<h6>Part Name {index + 1}</h6>
																									<input
																										type="text"
																										className="add-re-input bg-white form-control pt-2 pb-2 ml-1"
																										placeholder="input"
																										name={`replacementParts[${index}].name`}
																										id={`replacementParts[${index}].name`}
																										value={replacementPart.name}
																										onChange={
																											formik.handleChange
																										}
																									/>
																									<ErrorMessage
																										name={`replacementParts[${index}].name`}
																										component="div"
																										className="text-danger"
																									/>
																								</div>
																								<div className="col-lg-5 form-group mb-3">
																									<h6>
																										Replacement Time (in days)
																									</h6>
																									<input
																										type="number"
																										className="add-re-input bg-white form-control pt-2 pb-2 ml-1"
																										placeholder="Days"
																										name={`replacementParts[${index}].days`}
																										id={`replacementParts[${index}].days`}
																										value={
																											replacementPart?.days
																										}
																										onChange={
																											formik.handleChange
																										}
																									/>
																									<ErrorMessage
																										name={`replacementParts[${index}].days`}
																										component="div"
																										className="text-danger"
																									/>
																								</div>
																								{formik.values.replacementParts
																									.length > 1 && (
																									<div className="align-items-center d-flex justify-content-center">
																										<RemoveCircle
																											onClick={() =>
																												arrayHelpers.remove(
																													index
																												)
																											}
																											className="txt-light"
																										/>
																									</div>
																								)}
																							</div>
																						)
																					)}
																				<div className="text-right plus-icon">
																					<span className="mt-2 rounded-circle p-3">
																						<AddIcon
																							onClick={() =>
																								arrayHelpers.push({
																									name: "",
																									days: 0,
																								})
																							}
																						/>
																					</span>
																				</div>
																			</>
																		)}
																	/>
																</div>
															)}
														</div>
													</div>
												)}

												<div className="w-50">
													<button
														type="submit"
														className="btn btn Configurebtn w-75 mt-4"
													>
														Add
													</button>
												</div>
											</form>
										</FormikProvider>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default ConfigureDevice;
