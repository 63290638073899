import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

import { addLocation, updateLocation } from "../../api/LocationController";
import { RootState } from "../../redux/reducers";
import { SpinnerLoader } from "../../common/Global.Style";

import "./AddNewLocation.scoped.scss";

interface EditMonitorProps {
	formData: any;
	show: boolean;
	onHide: () => void;
	type: string;
	fetchLocationList: any;
	showBuilding?: boolean;
	buildingOption?: any;
	index?: any;
	setCreatedLocation?: (locationData: any) => void;
}

const AddNewLocation: React.FC<EditMonitorProps> = (props: any) => {
	const {
		show,
		onHide,
		formData,
		type,
		fetchLocationList,
		showBuilding,
		buildingOption,
		index,
		setCreatedLocation,
	} = props;
	const asset = useSelector((state: RootState) => state.asset);
	const [selectedBuildind, setSelectedBuilding] = useState<any>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const colourStyles = {
		control: (styles: any) => ({
			...styles,
			whiteSpace: "pre-wrap",
			wordBreak: "break-all",
		}),
	};
	const [formErrorData, setFormErrorData] = useState<any>({});
	const [locationName, setLocationName] = useState<string>("");
	const [locationDesc, setLocationDesc] = useState<string>("");

	useEffect(() => {
		if (formData) {
			setLocationName(formData?.name);
			setLocationDesc(formData?.description);
		}
	}, [formData]);

	useEffect(() => {
		if (index >= 0 && buildingOption?.length) {
			setSelectedBuilding(buildingOption[index]);
		}
	}, [index, buildingOption]);

	const saveDetails = (values: any) => {
		setIsLoading(true);
		if (type === "edit") {
			updateLocation(formData._id, values)
				.then((response: any) => {
					setIsLoading(false);
					if (response.responseObj) {
						toast.success(response.responseObj.message);
						fetchLocationList("refresh");
						hideAddLocation();
					} else {
						toast.error(response.error);
					}
				})
				.catch((error) => {
					setIsLoading(false);
				});
		} else {
			addLocation(values)
				.then((response: any) => {
					setIsLoading(false);
					if (response.responseObj) {
						toast.success(response.responseObj.message);
						fetchLocationList("refresh");
						hideAddLocation();
						setCreatedLocation &&
							setCreatedLocation(response?.responseObj?.result);
					} else {
						toast.error(response.error);
					}
				})
				.catch((error) => {
					setIsLoading(false);
				});
		}
	};

	const onSubmit = (values: any) => {
		const formError: any = {};
		if (!locationName) {
			formError.name = "Location name is required";
		}
		// if (!locationDesc) {
		// 	formError.description = "Description is required";
		// }
		if (!selectedBuildind.value) {
			formError.building = "Building is required";
		}
		if (!isEmpty(formError)) {
			setFormErrorData(formError);
			return;
		}
		saveDetails({
			name: locationName,
			description: locationDesc,
			building: selectedBuildind.value,
			asset: asset._id,
		});
	};

	const onNameChange = (e: any) => {
		setLocationName(e.target.value);
		const tempData = cloneDeep(formErrorData);
		tempData.name = "";
		setFormErrorData(tempData);
	};

	const onDescChange = (e: any) => {
		setLocationDesc(e.target.value);
		const tempData = cloneDeep(formErrorData);
		tempData.description = "";
		setFormErrorData(tempData);
	};
	const hideAddLocation = () => {
		onHide();
	};
	return (
		<Modal
			show={show}
			size="lg"
			onHide={hideAddLocation}
			dialogClassName={"modal-custom"}
			centered
		>
			<Modal.Header closeButton className="align-items-center">
				<Modal.Title id="contained-modal-title-vcenter">
					<h5 className="mt-3">
						{type === "edit" ? "Update Location" : "Add New Location"}
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row pr-3 pl-3 h-100 pb-5 mb-2">
					<div className="col-lg-12 col-md-12 loca bg-white p-3">
						<div className="col-md-6 col-lg-6 mt-4">
							{showBuilding && (
								<>
									<h6 className="mb-0 mb-2">Building</h6>
									<Select
										className="basic-multi-select"
										components={{ IndicatorSeparator: () => null }}
										onChange={(data: any) => {
											setSelectedBuilding(data);
											const tempData = cloneDeep(formErrorData);
											tempData.building = "";
											setFormErrorData(tempData);
										}}
										isDisabled={!!formData?._id}
										options={buildingOption}
										placeholder=""
										value={selectedBuildind}
										name="building"
										styles={colourStyles}
									/>
									{formErrorData.building && (
										<div className="text-danger">{formErrorData.building}</div>
									)}
								</>
							)}
						</div>
						<div className="col-md-6 col-lg-6 mt-4">
							<h6 className="mb-0">Location Name</h6>
							<input
								id="name"
								name="name"
								type="text"
								className="w-100 mt-2 p-3 bg-light locationinput"
								onChange={onNameChange}
								value={locationName}
								maxLength={100}
							/>
							{formErrorData?.name ? (
								<div className="text-danger">{formErrorData.name}</div>
							) : null}
						</div>

						{!isEmpty(formData) && (
							<div className="col-md-6 col-lg-6 mt-3">
								<h6 className="mb-0">Description</h6>
								<input
									id="description"
									name="description"
									type="text"
									className="w-100 p-3 mt-2 bg-light locationinput"
									onChange={onDescChange}
									value={locationDesc}
								/>
								{formErrorData?.description ? (
									<div className="text-danger">{formErrorData.description}</div>
								) : null}
							</div>
						)}

						<div className="col-md-6 col-lg-6 mt-3 mb-3">
							<button
								onClick={onSubmit}
								className="add-location-btn add col-md-4 col-lg-4 mt-3"
							>
								{type === "edit" ? "Update" : "Add"}
							</button>
						</div>
					</div>

					{isLoading && <SpinnerLoader />}
				</div>
			</Modal.Body>
		</Modal>
	);
};
export default AddNewLocation;
