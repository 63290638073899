import React from "react";
import { default as ReactSelect } from "react-select";

interface MySelectProps {
	isMulti: boolean;
	options: any[];
	value: any;
	closeMenuOnSelect: boolean;
	hideSelectedOptions: boolean;
	components: any;
	onChange: (value: any) => void;
	allowSelectAll: boolean;
	allOption?: any;
	name?: string;
}

const MySelect: React.FC<MySelectProps> = (props) => {
	if (props.allowSelectAll) {
		return (
			<ReactSelect
				{...props}
				options={[props.allOption, ...props.options]}
				onChange={(selected: any, event: any) => {
					if (selected !== null && selected.length > 0) {
						if (selected[selected.length - 1].value === props.allOption.value) {
							return props.onChange([props.allOption, ...props.options]);
						}
						let result = [];
						if (selected.length === props.options.length) {
							if (selected.includes(props.allOption)) {
								result = selected.filter(
									(option: any) => option.value !== props.allOption.value
								);
							} else if (event.action === "select-option") {
								result = [props.allOption, ...props.options];
							} else {
								if (event.option !== props.allOption) {
									result = [
										...props.options.filter((x: any) => x !== event.option),
									];
								}
							}
							return props.onChange(result);
						}
					}
					return props.onChange(selected);
				}}
			/>
		);
	}

	return <ReactSelect {...props} />;
};

export default MySelect;
