import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function getMarketPlaceCategory(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `admin/market-place-product-category`);
}

export async function getMarketPlaceProduct(
	category?: string,
	isFeatured?: any,
	search?: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		`market-place/product?${isFeatured ? `isFeatured=${isFeatured}` : ""}${
			category ? `&category=${category}` : ""
		}${search ? `&search=${search}` : ""}`
	);
}

export async function getMarketPlaceProductById(
	id: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `market-place/product/${id}`);
}

export async function getAllMarketPlaceProduct(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `market-place/product`);
}

export async function doFavouriteUnFavourite(
	product: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"market-place/product/favourite",
		undefined,
		product
	);
}
