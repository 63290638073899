export default class Shared {
	// Global Level Variable declaration
	public static commonLogoUrl: string = "/images/iQi-white.svg";
	public static commonHeaderLogoUrl: string = "/images/iqi-mobile-logo.svg";
	public static reportLogoUrl: string = "/images/iQi-blue.svg";
	public static title: string = "iQi Cloud";
	public static loginImage: string = "/images/iqi-login.svg";
	public static loginBgImage: string = "/images/iqi-bg.png";
	public static appUrl: string = "https://iqicloud.io";
	public static reportBgUrl: string =
		"https://iqintel-dev-files.s3.amazonaws.com/product/1653562345411-IQI_Report_front.jpg";
	public static reportCloudLogoUrl: string =
		"https://iqintel-dev-files.s3.amazonaws.com/product/1653562332058-iqi%20logo.svg";
	public static exposureGraphColorCodes: any = {
		Good: "#1880fd",
		Moderate: "#f5a83e",
		Poor: "#d73f4c",
		"Very Poor": "#8f242e",
		Danger: "#4e1319",
		"N/A": "#808080",
	};
}
