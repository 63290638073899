import React from "react";
import { IMenuItem } from "../../../common/Interface";
import { Button, Card } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";

interface SubMenuProps {
	item: IMenuItem;
}

const SubMenu: React.FC<SubMenuProps> = ({ item }) => {
	const { icon, title, description, viewText, viewLink, path } = item;
	return (
		<NavLink className="flex-1" to={path ? path : "/"}>
			<Card className="sub-menu bg-grey-1 px-3 py-4 box-shadow-none radius-8 mb-2">
				<div className="d-flex align-items-center">
					{icon}
					<p className="desc-exlarge font-600 font-text-1 mb-0 pl-1">{title}</p>
				</div>
				<p className="desc-medium font-grey-light-4 mb-3 mt-2">{description}</p>
				<Button className="btn-medium txt-primary font-600 p-0">
					{viewText}
					<ArrowForwardIcon className="i-12 ml-2" />
				</Button>
			</Card>
		</NavLink>
	);
};

export default SubMenu;
