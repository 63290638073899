import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./ForgotPassword.scoped.scss";
import { forgotPasswordApi } from "../../api/UserController";
import { SpinnerLoader } from "../../common/Global.Style";
import { withRouter } from "react-router-dom";
import { ApiResponse } from "../../common/Interface";
import Shared from "../../common/Shared";
import { validationErrorMessage } from "../../utils/errMessages";

const ForgotPassword: React.FC = (props: any) => {
	const [response, setResponse] = useState<ApiResponse | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const userForgotPassword = (value: object) => {
		setIsLoading(true);
		forgotPasswordApi(value)
			.then((response: any) => {
				if (response.responseObj) {
					setResponse({
						isSuccess: true,
						message: response.responseObj.success,
					});
					setTimeout(() => {
						props.history.push("/login");
					}, 2000);
				} else {
					setResponse({ isSuccess: false, message: response.error });
				}
				setIsLoading(false);
			})
			.catch((error) => {
				setResponse({ isSuccess: false, message: error.message });
				setIsLoading(false);
			});
	};

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email(validationErrorMessage.EMAIL)
				.required("Email is required"),
		}),
		onSubmit: (values: object) => {
			userForgotPassword(values);
		},
	});

	return (
		<>
			<div className="container-fluid">
				{isLoading && <SpinnerLoader />}

				<div className="row h-50 bac"></div>
				<div className="forgot ">
					<div className="logo-block">
						<img
							className="logo mb-3"
							src={Shared.commonLogoUrl}
							alt="login-logo"
						/>
					</div>
					<div className="bg-white p-3 forgot-block">
						<div className="text-center mb-5">
							<img src="/images/Frame.svg" alt="logo" />
						</div>
						<form onSubmit={formik.handleSubmit}>
							<div className="form-group">
								<label className="label-email">Email</label>
								<input
									id="email"
									name="email"
									className="input-email bg-light border-0 mt-2"
									placeholder="Type email"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
								/>
								{formik.touched.email && formik.errors.email ? (
									<div className="text-danger">{formik.errors.email}</div>
								) : null}
							</div>
							{response && (
								<div
									className={
										response.isSuccess ? "text-success" : "text-danger"
									}
								>
									{response.message ? response.message : ""}
								</div>
							)}
							<div className="col-lg-4 mb-5 m-auto">
								<button type="submit" className="btn btn-submit w-100 mt-3">
									submit
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};
export default withRouter(ForgotPassword);
