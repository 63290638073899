import { FC } from "react";
import styled from "styled-components";

const Loader: any = styled.div`
	border: 10px solid #f3f3f3;
	border-radius: 50%;
	border-top: 10px solid #3498db;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
	z-index: 9999;

	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export const SpinnerLoader: FC = () => {
	return (
		<div
			className="position-fixed w-100 h-100 d-flex justify-content-center align-items-center"
			style={{
				backgroundColor: "rgba(0, 0, 0, 0.54)",
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: 10000,
			}}
		>
			<Loader />
		</div>
	);
};
