import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { SpinnerLoader } from "../../common/Global.Style";
import "./CreateReport.scoped.scss";
import Select from "react-select";
import {
	createIAQReportSchedule,
	editIAQScheduleReport,
} from "../../api/ReportsController";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { IAQReportSchedule } from "../../types/IAQReportSchedule";
import { ApiResponse } from "../../api/apiPlugin";
import { IAQReportScheduleFrequency } from "../../enums/IAQReportScheduleFrequency";
import { validationErrorMessage } from "../../utils/errMessages";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

interface Item {
	label: string;
	value: IAQReportScheduleFrequency;
}

interface CreateReportProps {
	onHide: () => void;
	show: boolean;
	afterEdit?: (editedSchedule: IAQReportSchedule) => void;
	schedule?: IAQReportSchedule;
}

interface IAQScheduleForm {
	name: string;
	scheduleFrequently: Item[];
	emails: string;
	subject: string;
}

const IAQScheduleReportModal: React.FC<CreateReportProps> = (
	props: CreateReportProps
) => {
	const { show, onHide } = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedFrequency, setSelectedFrequency] = useState<Item[]>();
	const [frequencyList, setFrequencyList] = useState<Array<Item>>([
		{
			label: "Daily",
			value: IAQReportScheduleFrequency.DAILY,
		},
		{
			label: "Weekly",
			value: IAQReportScheduleFrequency.WEEKLY,
		},
		{
			label: "Monthly",
			value: IAQReportScheduleFrequency.MONTHLY,
		},
	]);
	const building = useSelector((state: RootState) => state.building);
	const asset = useSelector((state: RootState) => state.asset);
	const user = useSelector((state: RootState) => state.user);
	const emailRegexPattern =
		/^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])(,)?)+$/;
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Schedule name required"),
		scheduleFrequently: Yup.array()
			.min(1, "Select at-least one frequency")
			.of(Yup.mixed<Item>().required()),
		subject: Yup.string().required("Subject is required"),
		emails: Yup.string()
			.matches(emailRegexPattern, validationErrorMessage.EMAIL)
			.required("Valid Email(s) required"),
	});

	useEffect(() => {
		if (!!props?.schedule?._id) {
			const selectedFrequently = props.schedule.scheduleFrequently.map(
				(frequency: IAQReportScheduleFrequency) => {
					return {
						label: frequency.charAt(0).toUpperCase() + frequency.slice(1),
						value: frequency,
					};
				}
			);
			setSelectedFrequency(selectedFrequently);
			formik.setFieldValue("scheduleFrequently", selectedFrequently);
		} else {
			setSelectedFrequency([]);
		}
	}, []);

	const formik = useFormik({
		initialValues: {
			name: props?.schedule?.name || "",
			scheduleFrequently: !!selectedFrequency?.length ? selectedFrequency : [],
			emails: props?.schedule?.emails?.join(",") || "",
			subject: props?.schedule?.subject || "IAQ Report Attached",
		},
		validationSchema: validationSchema,
		onSubmit: async (report: IAQScheduleForm) => {
			const selectedScheduleFrequently: IAQReportScheduleFrequency[] =
				report.scheduleFrequently.map((i: Item) => i.value);
			report.emails = report.emails.toLowerCase();
			const newSchedule: IAQReportSchedule = {
				...report,
				building: building._id,
				asset: asset._id,
				emails: [...new Set(report.emails.split(","))],
				scheduleFrequently: selectedScheduleFrequently,
			};
			if (!props?.schedule?._id) {
				createSchedule(newSchedule);
			} else {
				newSchedule._id = props.schedule._id;
				updateSchedule(newSchedule);
			}
		},
	});

	function createSchedule(schedule: IAQReportSchedule) {
		setIsLoading(true);
		createIAQReportSchedule(schedule)
			.then((response: ApiResponse<any>) => {
				setIsLoading(false);
				if (!!response?.error) {
					toast.error(response.error);
				} else {
					toast.success("Schedule created successfully");
					onHide();
				}
			})
			.catch((error: any) => {
				setIsLoading(false);
				toast.error(error.message);
			});
	}

	function updateSchedule(schedule: IAQReportSchedule) {
		setIsLoading(true);
		editIAQScheduleReport(schedule)
			.then((response: ApiResponse<IAQReportSchedule>) => {
				setIsLoading(false);
				if (!!response?.error) {
					toast.error(response.error);
					return;
				}
				if (!!response.responseObj && !!props.afterEdit) {
					props.afterEdit(schedule);
					toast.success("Edited successfully");
					onHide();
				}
			})
			.catch((err: any) => {
				setIsLoading(false);
				toast.error(err?.message || "Ty again later");
			});
	}

	return (
		<Modal
			centered
			dialogClassName="modal-custom"
			show={show}
			size="lg"
			onHide={onHide}
			className="schedule-report-popup"
		>
			<Modal.Header closeButton className="align-items-center">
				<Modal.Title>
					<h5 className="mt-3">
						<span onClick={onHide} className="back-button">
							<ChevronLeftIcon />
						</span>
						Schedule Report
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container-fluid">
					<FormikProvider value={formik}>
						<form onSubmit={formik.handleSubmit}>
							<div>
								<div className="d-flex mt-4">
									<div className="w-50 form-group">
										<h6>Schedule Name</h6>
										<div className="dropdown w-100 pl-0 pr-0">
											<input
												type="text"
												className="add-re-input bg-white form-control pt-2 pb-2"
												placeholder=""
												name="name"
												id="name"
												value={formik.values.name}
												onChange={formik.handleChange}
												maxLength={100}
												autoComplete="off"
											/>
											{formik.touched.name && formik.errors.name ? (
												<div className="text-danger">{formik.errors.name}</div>
											) : null}
										</div>
									</div>
								</div>
								<div className="d-flex mt-4">
									<div className="dropdown w-50 form-group">
										<h6>Select Frequency</h6>
										<div className="dropdown w-100 pl-0 pr-0">
											<Select
												isMulti
												name="scheduleFrequently"
												id="scheduleFrequently"
												className="basic-multi-select"
												placeholder="Select Category"
												onChange={(option) => {
													return formik.setFieldValue(
														"scheduleFrequently",
														option
													);
												}}
												options={frequencyList}
												value={formik.values.scheduleFrequently}
											/>
										</div>
										{formik.touched.scheduleFrequently &&
										formik.errors.scheduleFrequently ? (
											<div className="text-danger">
												{formik.errors.scheduleFrequently}
											</div>
										) : null}
									</div>
								</div>
								<div className="d-flex mt-4">
									<div className="w-50 form-group">
										<h6>Email Id(s) to receive report</h6>
										<div className="dropdown w-100 pl-0 pr-0">
											<input
												type="text"
												className="add-re-input bg-white form-control pt-2 pb-21"
												placeholder=""
												name="emails"
												id="emails"
												value={formik.values.emails}
												onChange={formik.handleChange}
												maxLength={100}
												autoComplete="off"
											/>
											<div className="text-secondary mt-1">
												Add multiple email ids separated by comma
											</div>
											{formik.touched.emails && formik.errors.emails ? (
												<div className="text-danger">
													{formik.errors.emails}
												</div>
											) : null}
										</div>
									</div>
								</div>
								<div className="d-flex mt-4">
									<div className="dropdown w-50 form-group">
										<h6>Email Subject Line</h6>
										<div className="dropdown w-100 pl-0 pr-0">
											<input
												type="text"
												className="add-re-input bg-white form-control pt-2 pb-2"
												placeholder=""
												name="subject"
												id="subject"
												value={formik.values.subject}
												onChange={formik.handleChange}
												maxLength={100}
												autoComplete="off"
											/>
											<div className="text-secondary mt-1">
												Frequency of the report will be set accordingly in the
												subject line.
											</div>
											{formik.touched.subject && formik.errors.subject ? (
												<div className="text-danger">
													{formik.errors.subject}
												</div>
											) : null}
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex mt-4 justify-content-end add-schedule">
								<button className="btn add pl-5 pr-5" type="submit">
									Schedule Report
								</button>
							</div>
						</form>
					</FormikProvider>
					{isLoading && <SpinnerLoader />}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default IAQScheduleReportModal;
