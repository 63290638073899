import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/reducers";
import { DimensionBox } from "../../pages/ClusterConfig/DimensionBox";

import "./Autopilot.scss";
import { UserRoles } from "../../enums/UserRoles";
import PaymentPendingInfo from "../PaymentPendingInfo/PaymentPendingInfo";

export const AutopilotTable = ({
	toggleDrawer,
	onStatusChange,
	setUserDimensionList,
	processedDimData,
}: any) => {
	const [isAdmin, setIsAdmin] = useState<any>(true);

	const asset = useSelector((state: RootState) => state.asset);
	const user = useSelector((state: RootState) => state.user);

	useEffect(() => {
		const isCreatedUser = user._id === asset.userId;
		let checkAdminAccess = true;
		if (isCreatedUser) {
			checkAdminAccess = isCreatedUser;
		} else {
			const assetData = user?.assets?.find(
				(mapData: any) => mapData._id === asset._id
			);
			checkAdminAccess =
				assetData?.permissions?.includes("write") &&
				user?.role === UserRoles.ADMIN &&
				user?.addedBy !== process.env.REACT_APP_NORMI_PROVIDER_ID;
		}
		setIsAdmin(checkAdminAccess);
	}, [asset]);

	// will use in feature
	// useEffect(() => {
	// 	if (userDimensionList?.length) {
	// 		setDownloadCsvData(
	// 			userDimensionList.map((mapData: any) => {
	// 				let goodText = "";
	// 				get(mapData, "good", []).forEach((item: any) => {
	// 					goodText = goodText + getRangeData(item);
	// 				});
	// 				let moderateText = "";
	// 				get(mapData, "moderate", []).forEach((item: any) => {
	// 					moderateText = moderateText + getRangeData(item);
	// 				});
	// 				let poorText = "";
	// 				get(mapData, "poor", []).forEach((item: any) => {
	// 					poorText = poorText + getRangeData(item);
	// 				});
	// 				let veryPoorText = "";
	// 				get(mapData, "veryPoor", []).forEach((item: any) => {
	// 					veryPoorText = veryPoorText + getRangeData(item);
	// 				});
	// 				let dangerText = "";
	// 				get(mapData, "danger", []).forEach((item: any) => {
	// 					dangerText = dangerText + getRangeData(item);
	// 				});
	// 				return {
	// 					Name: mapData.name,
	// 					AssetId: mapData.asset,
	// 					Code: mapData.code,
	// 					Unit: mapData.unit,
	// 					Good: goodText,
	// 					Moderate: moderateText,
	// 					Poor: poorText,
	// 					VeryPoor: veryPoorText,
	// 					Hazardous: dangerText,
	// 				};
	// 			})
	// 		);
	// 	}
	// }, [userDimensionList]);

	const openDrawer = (tableMeta: any) => {
		let newData: any;
		setUserDimensionList((preData: any) => {
			newData = preData;
			return preData;
		});
		toggleDrawer(newData[tableMeta.rowIndex]);
	};

	return !asset.payPending ? (
		<div className="autopilot-table">
			<div className="row">
				{processedDimData?.map((mapData: any, index: number) => (
					<DimensionBox
						dimIndex={index}
						onStatusChange={onStatusChange}
						isAdmin={isAdmin}
						openDrawer={openDrawer}
						{...mapData}
					/>
				))}
			</div>
		</div>
	) : (
		<PaymentPendingInfo name={asset.name} />
	);
};
