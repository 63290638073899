import React, { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash/debounce";

import { getAutopilotList } from "../../api/autopilotController";
import { SpinnerLoader } from "../../common/Global.Style";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { EventEmitter } from "../../common/EventEmitter";
import {
	getBuildingsById,
	updateBuilding,
} from "../../api/buildingsController";
import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import { SelectableRows } from "mui-datatables";
import { AutopilotStatus } from "../../enums/common";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import "./Autopilot.scoped.scss";
import { useHistory } from "react-router-dom";

const Autopilot: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [autopilotList, setAutopilotList] = useState<Array<any>>([]);
	const [buildingData, setBuildingData] = useState<any>([]);
	const [logDateFormat] = useState<string>("MMM D ddd yyyy h:mm a");
	const [filteredAutopilotReport, setFilteredAutopilot] = useState<Array<any>>(
		[]
	);

	const building = useSelector((state: RootState) => state.building);
	const callAPI = (event: any) => {
		getAutoPilotData(event._id);
		fetchAnalyticsData(building._id);
	};
	const history = useHistory();

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (building && building._id) {
				getAutoPilotData(building._id);
				fetchAnalyticsData(building._id);
			}
			return () => {
				isMounted = false;
			};
		}
	}, [building]);
	useEffect(() => {
		EventEmitter.subscribe("buildingChange", (event) => callAPI(event));
		return () => EventEmitter.unsubscribe("buildingChange");
	}, []);
	const getAutoPilotData = (buildingId: any) => {
		setIsLoading(true);
		getAutopilotList(buildingId)
			.then((response: any) => {
				if (
					response.responseObj.results &&
					response.responseObj.results.length > 0
				) {
					setFilteredAutopilot(response.responseObj.results);
					setAutopilotList(response.responseObj.results);
					setIsLoading(false);
				} else {
					setAutopilotList([]);
					setFilteredAutopilot([]);
					setIsLoading(false);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				setFilteredAutopilot([]);
				setAutopilotList([]);
			});
	};

	const updateHandler = (event: any) => {
		updateBuilding(building && building._id, {
			autoPilot: event.target.checked,
		})
			.then((response: any) => {
				if (response.responseObj) {
					// fetchLocationList();
					fetchAnalyticsData(building._id);
				}
			})
			.catch((error) => {});
	};
	const fetchAnalyticsData = (id: any) => {
		setIsLoading(true);
		getBuildingsById(id, "month")
			.then((response: any) => {
				setIsLoading(false);
				if (response.responseObj) {
					setBuildingData(response.responseObj);
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const searchAutopilotLog = (e: any) => {
		let { value } = e.target;
		value = value.toLowerCase();
		setFilteredAutopilot(
			autopilotList.filter((filterData: any) => {
				if (
					filterData?.locationName?.toLowerCase()?.includes(value) ||
					filterData?.deviceName?.toLowerCase()?.includes(value)
				) {
					return true;
				}
				return false;
			})
		);
	};

	const aQIParameter: any = {
		co2_value: "CO2",
		temp_current_f: "Temperature",
		humidity_value: "Humidity",
		ch2o_value: "Formaldehyde",
		pm1: "PM1.0",
		pm25_value: "PM2.5",
		pm10: "PM10",
		voc_value: "TVOC",
	};

	const options = {
		print: true,
		responsive: "standard",
		download: true,
		viewColumns: false,
		selectableRows: "none" as SelectableRows,
	};

	const columns = [
		{
			name: "_id",
			options: {
				filter: false,
				display: false, // hide this filed in Data-Table
			},
		},
		{
			name: "sno",
			label: "S.NO",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <>{tableMeta.rowIndex + 1}</>;
				},
			},
		},
		{
			name: "locationName",
			label: "LOCATION",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "N/A"}{" "}
						</>
					);
				},
			},
		},
		{
			name: "AQIParam",
			label: "AQI PARAMETER",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? aQIParameter[tableMeta.rowData[tableMeta.columnIndex]]
								: "N/A"}{" "}
						</>
					);
				},
			},
		},
		{
			name: "threshold",
			label: "THRESHOLD",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "N/A"}{" "}
						</>
					);
				},
			},
		},
		{
			name: "valueBreach",
			label: "VALUE BREACH",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "N/A"}{" "}
						</>
					);
				},
			},
		},
		{
			name: "remediationDeviceSubType",
			options: {
				filter: false,
				display: false,
			},
		},
		{
			name: "deviceName",
			label: "DEVICE TRIGGERED",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							<div>
								<span>
									{tableMeta.rowData[tableMeta.columnIndex]
										? tableMeta.rowData[tableMeta.columnIndex]
										: "N/A"}{" "}
								</span>
								{tableMeta.rowData[tableMeta.columnIndex - 1] && (
									<span> - {tableMeta.rowData[tableMeta.columnIndex - 1]}</span>
								)}
							</div>
						</>
					);
				},
			},
		},
		{
			name: "triggeredAt",
			label: "EVENT DATE/TIME",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? moment(tableMeta.rowData[tableMeta.columnIndex])
										.tz(
											building?.timeZoneName
												? building?.timeZoneName
												: "EST5EDT"
										)
										.format("LLLL")
								: ""}{" "}
						</>
					);
				},
			},
		},
		{
			name: "achievedAt",
			label: "EVENT END DATE/TIME",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? moment(tableMeta.rowData[tableMeta.columnIndex])
										.tz(
											building?.timeZoneName
												? building?.timeZoneName
												: "EST5EDT"
										)
										.format("LLLL")
								: "N/A"}{" "}
						</>
					);
				},
			},
		},
		{
			name: "action",
			label: "STATUS",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>{AutopilotStatus[tableMeta.rowData[tableMeta.columnIndex]]}</>
					);
				},
			},
		},
		{
			name: "intervention",
			label: "INTERVENTION",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "-"}
						</>
					);
				},
			},
		},
	];
	return (
		<div className="container-fluid">
			<div className="row mt-3 mb-3">
				<div className="d-flex cursor-pointer" onClick={() => history.goBack()}>
					<ArrowBackIosIcon fontSize="small" className="ml-2" />
					<p className="breadcrumb-para">Autofix</p>
				</div>
				<div className="col-lg-12 col-md-12 col-sm-12  autopilot bg-white p-3">
					<h5 className="mt-2">Autofix Report</h5>
					<div className="mb-3 pl-0 pr-0 responsive-search">
						<input
							onChange={debounce(searchAutopilotLog, 1000)}
							type="text"
							placeholder="Search"
						/>
						<SearchIcon />
					</div>
					{isLoading && <SpinnerLoader />}

					<div className="mt-3 m-d-none">
						<CustomDataTable
							title=""
							rows={autopilotList}
							columns={columns}
							options={options}
						/>
					</div>
					<div className="autopilot-log-card d-none m-d-block">
						{!isEmpty(filteredAutopilotReport) &&
							filteredAutopilotReport?.map((mapData) => (
								<div className="mobile-card mb-2" key={mapData?._id}>
									<div className="d-flex justify-content-between">
										<div className="d-flex flex-column flex-1">
											<p className="content-label">LOCATIONS</p>
											<p className="content-name">{mapData?.locationName}</p>
										</div>
										<div className="auto-pilot-right">
											<p className="content-label">STATUS</p>
											<p className="content-value">
												{get(AutopilotStatus, mapData?.action)}
											</p>
										</div>
									</div>
									<div className="d-flex justify-content-between">
										<div className="d-flex flex-column flex-1">
											<p className="content-label">THRESHOLD</p>
											<p className="content-value">{mapData?.threshold}</p>
										</div>
										<div className="auto-pilot-right">
											<p className="content-label">AQI PARAMETER</p>
											<p className="content-value">
												{get(aQIParameter, mapData.AQIParam)}
											</p>
										</div>
									</div>
									<div className="d-flex justify-content-between">
										<div className="d-flex flex-column flex-1">
											<p className="content-label">DEVICE TRIGGERED</p>
											<p className="content-value">{mapData?.deviceName}</p>
										</div>
										<div className="auto-pilot-right">
											<p className="content-label">VALUE BREACH</p>
											<p className="content-value">{mapData?.valueBreach}</p>
										</div>
									</div>
									<div className="">
										<div>
											<p className="content-label">EVENT START DATE</p>
											<p className="content-value">
												{mapData?.triggeredAt &&
													moment(mapData?.triggeredAt)
														.tz(
															building?.timeZoneName
																? building?.timeZoneName
																: "EST5EDT"
														)
														.format(logDateFormat)}
											</p>
										</div>
										<div>
											<p className="content-label">EVENT END DATE</p>
											<p className="content-value">
												{mapData?.achievedAt &&
													moment(mapData?.achievedAt)
														.tz(
															building?.timeZoneName
																? building?.timeZoneName
																: "EST5EDT"
														)
														.format(logDateFormat)}
											</p>
										</div>
									</div>
									<div>
										<p className="content-label">INTERVENTION</p>
										<p className="content-value">{mapData?.intervention}</p>
									</div>
								</div>
							))}
						<>
							{isEmpty(filteredAutopilotReport) && (
								<div className="d-flex justify-content-center mt-2">
									<p>No Record found</p>
								</div>
							)}
						</>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Autopilot;
