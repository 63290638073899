import { IconButton } from "@material-ui/core";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import EditIcon from "@material-ui/icons/Edit";
import { MUIDataTableMeta, SelectableRows } from "mui-datatables";
import React, { useEffect, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import get from "lodash/get";
import debounce from "lodash/debounce";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTheme } from "@material-ui/core/styles";

import { getDimensions } from "../../../api/clusterConfigController";
import {
	deleteConsultation,
	getConsultationReport,
	updateConsultationReport,
} from "../../../api/ReportsController";
import { SpinnerLoader } from "../../../common/Global.Style";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import ConsultationModal from "../../../components/ConsultationModal/ConsultationModal";
import CustomDataTable from "../../../components/CustomDataTable/CustomDataTable";
import LocationSelector from "../../../components/LocationSelector/LocationSelector";
import { URL } from "../../../components/Navbar/routes";
import { UserRoles } from "../../../enums/UserRoles";
import { RootState } from "../../../redux/reducers";
import { ConsultationReport } from "../../../types/ConsultationReport";
import { hasWriteAccess } from "../../../utils/helpers";
import { consultationTypes } from "../../../enums/common";
import { ReportModal } from "./ReportModal";
import { EmailModal } from "./EmailModal";
import { sendConsultationReport } from "../../../api/commonController";
import Shared from "../../../common/Shared";
import PaymentPendingInfo from "../../../components/PaymentPendingInfo/PaymentPendingInfo";
import { TabPanel } from "../../Subscription/Subscription";

import "./ConsultationReports.scoped.scss";
import SystemTour from "../../../components/SystemTour/SystemTour";
import DownloadPDFDialog from "../../../components/DownloadPDFDialog/DownloadPDFDialog";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { setupWebViewJavascriptBridge } from "../../../common/JsBridge";

const ConsultationReports: React.FC = () => {
	const building = useSelector((state: RootState) => state.building);
	const asset = useSelector((state: RootState) => state.asset);
	const assets: any = useSelector((state: RootState) => state.assets);
	const user = useSelector((state: RootState) => state.user);

	const history = useHistory();
	const theme = useTheme();
	const locationHistory = useLocation();
	const [consultationReport, setConsultationReport] = useState<
		Array<ConsultationReport>
	>([]);
	const [archivedReport, setArchivedReport] = useState<
		Array<ConsultationReport>
	>([]);
	const [filterConsultationReport, setFilterConsultationReport] = useState<
		Array<ConsultationReport>
	>([]);
	const [filterArchivedReport, setFilterArchivedReport] = useState<
		Array<ConsultationReport>
	>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedLocation, setSelectedLocation] = useState({});
	const [selectedConsultation, setSelectedConsultation] =
		useState<any>(undefined);
	const [showDeleteConfirmation, setShowDeleteConfirmation] =
		useState<boolean>(false);
	const [showResolveConfirmation, setShowResolveConfirmation] =
		useState<boolean>(false);
	const [showCalendlyLinkNotFound, setShowCalendlyLinkNotFound] =
		useState<boolean>(false);
	const [dimensions, setDimensions] = useState<any>(undefined);
	const [downloadCsvData, setDownloadCsvData] = useState<any>([]);
	const [showReportModal, setShowReportModal] = useState(false);
	const [reportData, setShowReportData] = useState<any>({});
	const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
	const [reportList, setReportList] = useState<any>([]);
	const [initialReportList, setInitialReportList] = useState<any>([]);
	const [uploadReport, setUploadReport] = useState<boolean>(false);
	const [tabValue, setTabValue] = React.useState(0);
	const [tourSteps] = useState<any>([
		{
			selector: ".upload-consultation-tour",
			content: `Click here to upload an existing consultation report`,
		},
		{
			selector: ".add-consultation-tour",
			content: `Click here to create a new consultation report.`,
		},
		{
			selector: ".schedule-consultation-tour",
			content: `You can schedule a  consultation meeting with assigned IAQ experts from here.`,
		},
	]);
	const [showDownloadPDFModal, setShowDownloadPDFModal] =
		useState<boolean>(false);
	const [downloadLink, setDownloadLink] = useState<string>("");

	const getCalendlyNotFoundMsg = (): string => {
		if (user.role === UserRoles.CONTRACTOR) {
			return "Please add calendly link under customer account to schedule consultation";
		}
		return "Contact your admin to assign consultants";
	};

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabValue(newValue);
		console.log(tabValue);
	};

	const hideModal = () => setShowEmailModal(false);
	const getCalendlyNotFoundModalPositiveButtonLabel = (): string => {
		if (user.role === UserRoles.CONTRACTOR) {
			return "Add Link";
		} else {
			return "Close";
		}
	};

	const onChangeLocation = (value: any) => {
		setSelectedLocation(value);
		filterConsultationByLocation(value);
	};

	useEffect(() => {
		getConsultationReportData(building._id);
		getAQIParams();
	}, [building]);

	useEffect(() => {
		const consultationReports: any[] = !tabValue
			? filterConsultationReport
			: filterArchivedReport;
		if (consultationReports?.length && dimensions) {
			const tempReportData: any = [];
			setDownloadCsvData(
				consultationReports.map((mapData: any) => {
					const currentObj = {
						LocationName: mapData?.locations
							?.map((loc: any) => loc?.name)
							?.join(", "),
						AQI_Unresolved_Parameter: `${
							!!mapData.aqiParam
								? mapData.aqiParam
										?.map(
											(param: any) =>
												dimensions?.find(
													(dimension: any) => dimension.code === param
												)?.shortName
										)
										.join(", ")
								: "N/A"
						}${
							mapData?.aqiParamSubType ? ` - ${mapData?.aqiParamSubType}` : ""
						}`,
						RootCause: mapData.cause,
						PossibleSolution: mapData.solution,
					};
					tempReportData.push({
						...mapData,
						locationName: currentObj.LocationName,
						unResolvedParam: currentObj.AQI_Unresolved_Parameter,
					});
					return currentObj;
				})
			);
			setReportList(tempReportData);
			setInitialReportList(tempReportData);
		} else {
			setReportList([]);
			setInitialReportList([]);
		}
	}, [filterConsultationReport, filterArchivedReport, dimensions, tabValue]);

	const filterConsultationByLocation = (location: any) => {
		if (location) {
			const report = consultationReport.filter(
				(consultation: ConsultationReport) => {
					return consultation.locations.some(
						(loc: any) => loc._id === location.value
					);
				}
			);
			const archivedReports = archivedReport.filter(
				(consultation: ConsultationReport) => {
					return consultation.locations.some(
						(loc: any) => loc._id === location.value
					);
				}
			);
			setFilterConsultationReport(report);
			setFilterArchivedReport(archivedReports);
		} else {
			setFilterConsultationReport(consultationReport);
		}
	};

	const actionRow = (value: any, tableMeta: MUIDataTableMeta) => {
		return (
			<div className="d-flex">
				<IconButton
					aria-label="edit-consultation"
					className="mr-1"
					onClick={() => {
						const reportData = !tabValue
							? consultationReport[tableMeta.rowIndex]
							: archivedReport[tableMeta.rowIndex];
						if (reportData?.reportUrl) {
							setUploadReport(true);
							setSelectedConsultation(reportData);
						} else {
							history.push(URL.ADD_EDIT_CONSULTANT_REPORT, reportData);
						}
					}}
					disabled={!hasWriteAccess(user, asset)}
				>
					<EditIcon />
				</IconButton>
				<IconButton
					aria-label="delete-consultation"
					onClick={() => {
						if (!tabValue) {
							onClickDelete(consultationReport[tableMeta.rowIndex]);
						} else {
							onClickDelete(archivedReport[tableMeta.rowIndex]);
						}
					}}
					disabled={!hasWriteAccess(user, asset)}
				>
					<CheckCircleOutlinedIcon className="resolved-icon" />
				</IconButton>
			</div>
		);
	};

	const getConsultationReportData = (buildingId: any) => {
		setIsLoading(true);

		const data = "building=" + buildingId;

		getConsultationReport(data)
			.then((response: any) => {
				if (
					response?.responseObj?.results &&
					response.responseObj.results.length > 0
				) {
					const consultationReports: ConsultationReport[] =
						response.responseObj.results.filter((data: any) => !data.isDeleted);
					const archivedReports: ConsultationReport[] =
						response.responseObj.results.filter((data: any) => data.isDeleted);
					setFilterConsultationReport(consultationReports);
					setConsultationReport(consultationReports);
					setArchivedReport(archivedReports);
					setFilterArchivedReport(archivedReports);
				} else {
					setConsultationReport([]);
					setFilterConsultationReport([]);
				}
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				setFilterConsultationReport([]);
				setConsultationReport([]);
			});
	};

	const getAQIParams = async () => {
		try {
			const dimensionResponse: any = await getDimensions();
			if (!dimensionResponse?.responseObj?.result) {
				return;
			}
			setDimensions(get(dimensionResponse, "responseObj.result", []));
		} catch (err) {
			console.log(err);
		}
	};

	const sendEmail = async (emails: string) => {
		setIsLoading(true);
		const response = await sendConsultationReport({
			reportId: reportData._id,
			assetId: asset._id,
			emails,
		});
		setIsLoading(false);
		if (
			response &&
			response.responseObj &&
			response.responseObj.message === "OK"
		) {
			hideModal();
			toast.success("Email sent successfully");
		} else if (response.error) {
			toast.error(response.error);
		}
	};

	const downloadPDF = async () => {
		setIsLoading(true);
		const response = await sendConsultationReport({
			reportId: reportData._id,
			assetId: asset._id,
			isDownload: true,
		});
		setIsLoading(false);
		if (
			response &&
			response.responseObj &&
			response.responseObj?.response?.length
		) {
			hideModal();
			const result: string = response?.responseObj?.response;
			// window.open(result);
			setDownloadLink(result);
			setShowDownloadPDFModal(true);
		} else if (response.error) {
			toast.error("Error in Downloading");
		}
	};

	const searchReport = (e: any) => {
		let { value } = e.target;
		value = value.toLowerCase();
		setReportList(
			initialReportList.filter((filterData: any) => {
				if (
					filterData?.locationName?.toLowerCase()?.includes(value) ||
					filterData?.unResolvedParam?.toLowerCase()?.includes(value)
				) {
					return true;
				}
				return false;
			})
		);
	};

	const newConsultationReport = (): ConsultationReport => {
		return {
			_id: "",
			location: "",
			building: "",
			locations: [],
			aqiParam: [],
			cause: "",
			solution: "",
		};
	};

	const options = {
		print: true,
		download: true,
		viewColumns: false,
		showResponsive: true,
		responsive: "scrollMaxHeight",
		onDownload: () => {
			return false;
		},
		selectableRows: "none" as SelectableRows,
	};

	const columns: any[] = [
		{
			name: "consultantName",
			label: "Consultant Name",
			options: {
				filter: false,
				display: true,
			},
		},
		{
			name: "locations",
			label: "LOCATION",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? value?.map((loc: any) => loc?.name)?.join(", ")
								: "N/A"}
						</>
					);
				},
			},
		},
		{
			name: "aqiParamSubType",
			options: {
				filter: false,
				display: false,
			},
		},
		{
			name: "aqiParam",
			label: "AQI UNRESOLVED PARAMETER",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{
								<div>
									<span className="text-center">
										{tableMeta.rowData[tableMeta.columnIndex] && !!value
											? value
													?.map(
														(param: any) =>
															dimensions?.find(
																(dimension: any) => dimension.code === param
															)?.shortName
													)
													.join(", ")
											: "N/A"}
									</span>
									{tableMeta.rowData[tableMeta.columnIndex - 1] && (
										<span>
											{" "}
											- {tableMeta.rowData[tableMeta.columnIndex - 1]}
										</span>
									)}
								</div>
							}
						</>
					);
				},
			},
		},
		{
			name: "cause",
			label: "ROOT CAUSE",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "N/A"}
						</>
					);
				},
			},
		},
		{
			name: "solution",
			label: "POSSIBLE SOLUTION",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "N/A"}
						</>
					);
				},
			},
		},
		{
			name: "createdAt",
			label: "REPORT ADDED AT",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? moment(tableMeta.rowData[tableMeta.columnIndex])
										.tz(building?.timeZoneName || "EST5EDT")
										.format("MMM DD, ddd, LT")
								: ""}{" "}
						</>
					);
				},
			},
		},
		{
			name: "updatedAt",
			label: "REPORT UPDATED AT",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? moment(tableMeta.rowData[tableMeta.columnIndex])
										.tz(building?.timeZoneName || "EST5EDT")
										.format("MMM DD, ddd, LT")
								: ""}{" "}
						</>
					);
				},
			},
		},
		{
			name: "reportContent",
			label: "Report",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any) => {
					const currentData = consultationReport[tableMeta.rowIndex];
					const showViewReport =
						currentData?.reportContent || currentData?.reportUrl;
					return (
						showViewReport && (
							<p
								onClick={() => {
									if (currentData?.reportUrl) {
										window.open(currentData?.reportUrl);
									} else {
										setShowReportData(currentData);
										setShowReportModal(true);
									}
								}}
								className="link"
							>
								View Report
							</p>
						)
					);
				},
			},
		},
		{
			name: "Actions",
			Label: "Actions",
			options: {
				empty: true,
				filter: false,
				sort: false,
				customBodyRender: actionRow,
				dimensions: dimensions,
			},
		},
	];

	const deleteSchedule = async () => {
		setIsLoading(true);
		const updateUI = () => {
			setIsLoading(false);
			setSelectedConsultation(undefined);
			setShowDeleteConfirmation(false);
		};
		try {
			await deleteConsultation(selectedConsultation._id);
			updateUI();
			getConsultationReportData(building._id);
		} catch (err) {
			updateUI();
		}
	};

	const hasCalendlyLink: Function = (): boolean => {
		const link: string = assets?.find(
			(obj: any) => obj?._id === asset?._id
		)?.calendlyLink;

		return !!link;
	};

	const getCalendlyLink: Function = (): string => {
		const link: string = assets?.find(
			(obj: any) => obj?._id === asset?._id
		)?.calendlyLink;
		const check: string = link.substring(0, 4);
		if (check?.toLowerCase() === "http") {
			return link;
		} else {
			return "//" + link;
		}
	};

	const onHideConsulationModal = (refresh: boolean = false) => {
		setUploadReport(false);
		setSelectedConsultation(undefined);
		if (refresh) {
			getConsultationReportData(building._id);
		}
	};

	const checkAsset = () => {
		return !asset.payPending;
	};

	function a11yProps(index: any) {
		return {
			id: `full-width-tab-${index}`,
			"aria-controls": `full-width-tabpanel-${index}`,
		};
	}

	const onClickDelete = (data: any) => {
		setSelectedConsultation(data);
		if (data.isDeleted) {
			setShowDeleteConfirmation(true);
		} else {
			setShowResolveConfirmation(true);
		}
	};

	const updateConsultation = async () => {
		setIsLoading(true);
		const updateUI = () => {
			setIsLoading(false);
			setSelectedConsultation(undefined);
			setShowResolveConfirmation(false);
		};
		try {
			await updateConsultationReport(selectedConsultation._id, {
				isDeleted: true,
			});
			updateUI();
			getConsultationReportData(building._id);
		} catch (err) {
			updateUI();
		}
	};
	const goBack = () => {
		if (locationHistory.key) {
			history.goBack();
		} else callHandler("sendBackToNative", {}, (data: any) => {});
	};

	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};

	return checkAsset() ? (
		<div className="container-fluid consultant-responssive">
			{/* <SystemTour tourSteps={tourSteps} position={0} /> */}
			{showReportModal && (
				<ReportModal
					show={showReportModal}
					onClose={() => setShowReportModal(false)}
					content={reportData?.reportContent}
					productList={reportData?.recomentedProduct}
					sendEmail={() => {
						setShowReportModal(false);
						setShowEmailModal(true);
					}}
					downloadPDF={downloadPDF}
				/>
			)}
			{uploadReport && (
				<ConsultationModal
					dimensions={dimensions}
					onHide={onHideConsulationModal}
					show={uploadReport}
					consultation={
						!!selectedConsultation
							? selectedConsultation
							: newConsultationReport()
					}
				/>
			)}
			{showEmailModal && (
				<EmailModal
					show={showEmailModal}
					onClose={hideModal}
					sendEmail={sendEmail}
				/>
			)}
			<div className="mt-3 bg-white">
				{Shared.title === "iQi Cloud" && (
					<>
						<div className="w-100 d-flex m-d-none">
							<div className="w-50">
								<h5 className="mt-2 ml-12">Consultation</h5>
							</div>
						</div>
						<div
							onClick={() => goBack()}
							className="align-items-center pl-2 d-none m-d-flex pt-2 pb-3 sub-header"
						>
							<ArrowBackIcon className="i-18 txt-primary font-600" />
							<h2 className="desc-large font-600 font-text-4 mb-0">Back</h2>
						</div>
					</>
				)}

				<div className="w-100 d-flex consultant-block">
					{/* <div className="w-50 consultant-header">
						<h5 className="mt-2">Consultation Report</h5>
					</div> */}
					<div className="responsive-search">
						<input
							onChange={debounce(searchReport, 1000)}
							type="text"
							placeholder="Search"
						/>
						<SearchIcon />
					</div>
					<div className="w-50 select-building">
						<div className="w-50 ml-auto buding-block">
							<LocationSelector onSelectLocation={onChangeLocation} />
						</div>
					</div>
				</div>
				<div className="w-100 d-flex mt-4 consult-button-container">
					<div className="w-100 d-flex pt-3 pb-3 justify-content-end consult-buttons">
						<div className="d-flex">
							{hasWriteAccess(user, asset) && (
								<>
									<button
										className="btn co-re-btn1 pl-3 pr-3 ml-1 upload-consultation-tour"
										onClick={() => {
											setUploadReport(true);
										}}
									>
										Upload Report
									</button>
									<button
										className="btn co-re-btn1 pl-3 pr-3 ml-1 add-consultation-tour"
										onClick={() => {
											history.push(URL.ADD_EDIT_CONSULTANT_REPORT, {});
										}}
									>
										Add Report
									</button>
								</>
							)}
							<button
								className="btn co-re-btn2 pl-4 pr-4 schedule-consultation-tour"
								onClick={() =>
									hasCalendlyLink()
										? window.open(getCalendlyLink(), "_blank")
										: setShowCalendlyLinkNotFound(true)
								}
							>
								Schedule Consultation
							</button>
						</div>
					</div>
				</div>
				<Tabs
					value={tabValue}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					aria-label="full width tabs example"
					className="table-tab"
				>
					<Tab label="consultation" {...a11yProps(0)} />
					<Tab label="Archived consultation" {...a11yProps(1)} />
				</Tabs>
				<TabPanel value={tabValue} index={0} dir={theme.direction}>
					<div className="row p-3">
						<div className="col-lg-12 col-md-12 col-sm-12 consultation-re p-3">
							<div className="mt-3 mb-5 consultation-table">
								<CustomDataTable
									title=""
									rows={filterConsultationReport}
									columns={columns}
									options={options}
									components={{
										icons: {
											DownloadIcon: forwardRef((iconProps: any, ref: any) => (
												<CSVLink
													data={downloadCsvData}
													uFEFF={false}
													filename={"ConsultationReport.csv"}
													className="d-inherit"
												>
													<CloudDownload
														className="custom-download-icon"
														{...iconProps}
														ref={ref}
													/>
												</CSVLink>
											)),
										},
									}}
								/>
							</div>

							<div className="consultation-card-container">
								{reportList.map((mapData: any) => {
									const showViewReport =
										mapData?.reportContent || mapData?.reportUrl;
									return (
										<div className="consultation-card mb-2">
											<div className="card-head d-flex justify-content-between">
												<div className="card-value-block">
													<p className="prop-head">LOCATIONS</p>
													<p className="prop-value bold">
														{mapData?.locationName}
													</p>
												</div>
												<div className="d-flex">
													<div className="icon-blocks green">
														<IconButton
															onClick={() => {
																setSelectedConsultation(mapData);
																setShowDeleteConfirmation(true);
															}}
															disabled={!hasWriteAccess(user, asset)}
														>
															<CheckCircleOutlineIcon />
														</IconButton>
													</div>
													<div className="icon-blocks">
														<Dropdown className="more-dropdown">
															<Dropdown.Toggle
																id="dropdown-basic"
																bsPrefix="my-dropdown-toggle p-0"
															>
																<MoreHorizIcon className="i-20 font-tertiary cursor-pointer" />
															</Dropdown.Toggle>
															<Dropdown.Menu
																className="card-action"
																style={{ width: "inherit" }}
															>
																<Dropdown.Item
																	onClick={() => {
																		history.push(
																			URL.ADD_EDIT_CONSULTANT_REPORT,
																			mapData
																		);
																	}}
																	disabled={!hasWriteAccess(user, asset)}
																>
																	Edit
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</div>
											</div>
											<div>
												<div className="card-block">
													<div className="card-value-block">
														<p className="prop-head">
															AQI UNRESOLVED PARAMETER
														</p>
														<p className="prop-value">
															{mapData?.unResolvedParam}
														</p>
													</div>
													<div className="card-value-block">
														<p className="prop-head">ROOT CAUSE</p>
														<p className="prop-value">{mapData?.cause}</p>
													</div>
													<div className="card-value-block">
														<p className="prop-head">POSSIBLE SOLUTION</p>
														<p className="prop-value">{mapData?.solution}</p>
													</div>
												</div>
												<div className="card-foot d-flex">
													<div className="card-value-block flex-1">
														<p className="prop-head">REPORT ADDED AT</p>
														<p className="prop-value">
															{mapData?.createdAt &&
																moment(mapData?.createdAt)
																	.tz(building?.timeZoneName || "EST5EDT")
																	.format("MMM DD, ddd, LT")}
														</p>
													</div>
													<div className="card-value-block flex-1">
														<p className="prop-head">REPORT UPDATED AT</p>
														<p className="prop-value">
															{mapData?.createdAt &&
																moment(mapData?.updatedAt)
																	.tz(building?.updatedAt || "EST5EDT")
																	.format("MMM DD, ddd, LT")}
														</p>
													</div>
												</div>
											</div>
											{showViewReport && (
												<p
													onClick={() => {
														if (mapData?.reportUrl) {
															window.open(mapData?.reportUrl);
														} else {
															setShowReportData(mapData);
															setShowReportModal(true);
														}
													}}
													className="link desc-small mb-2"
												>
													View Report
												</p>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</TabPanel>
				<TabPanel value={tabValue} index={1} dir={theme.direction}>
					<div className="row p-3">
						<div className="col-lg-12 col-md-12 col-sm-12 consultation-re p-3">
							<div className="mt-3 mb-5 consultation-table">
								<CustomDataTable
									title=""
									rows={filterArchivedReport}
									columns={columns}
									options={options}
									components={{
										icons: {
											DownloadIcon: forwardRef((iconProps: any, ref: any) => (
												<CSVLink
													data={downloadCsvData}
													uFEFF={false}
													filename={"ArchivedReport.csv"}
													className="d-inherit"
												>
													<CloudDownload
														className="custom-download-icon"
														{...iconProps}
														ref={ref}
													/>
												</CSVLink>
											)),
										},
									}}
								/>
							</div>

							<div className="consultation-card-container">
								{reportList.map((mapData: any) => {
									const showViewReport =
										mapData?.reportContent || mapData?.reportUrl;
									return (
										<div className="consultation-card mb-2">
											<div className="card-head d-flex justify-content-between">
												<div className="card-value-block">
													<p className="prop-head">LOCATIONS</p>
													<p className="prop-value bold">
														{mapData?.locationName}
													</p>
												</div>
												<div className="d-flex">
													<div className="icon-blocks green">
														<IconButton
															onClick={() => {
																setSelectedConsultation(mapData);
																setShowDeleteConfirmation(true);
															}}
															disabled={!hasWriteAccess(user, asset)}
														>
															<CheckCircleOutlineIcon />
														</IconButton>
													</div>
													<div className="icon-blocks">
														<Dropdown className="more-dropdown">
															<Dropdown.Toggle
																id="dropdown-basic"
																bsPrefix="my-dropdown-toggle p-0"
															>
																<MoreHorizIcon className="i-20 font-tertiary cursor-pointer" />
															</Dropdown.Toggle>
															<Dropdown.Menu
																className="card-action"
																style={{ width: "inherit" }}
															>
																<Dropdown.Item
																	onClick={() => {
																		history.push(
																			URL.ADD_EDIT_CONSULTANT_REPORT,
																			mapData
																		);
																	}}
																	disabled={!hasWriteAccess(user, asset)}
																>
																	Edit
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</div>
											</div>
											<div>
												<div className="card-block">
													<div className="card-value-block">
														<p className="prop-head">
															AQI UNRESOLVED PARAMETER
														</p>
														<p className="prop-value">
															{mapData?.unResolvedParam}
														</p>
													</div>
													<div className="card-value-block">
														<p className="prop-head">ROOT CAUSE</p>
														<p className="prop-value">{mapData?.cause}</p>
													</div>
													<div className="card-value-block">
														<p className="prop-head">POSSIBLE SOLUTION</p>
														<p className="prop-value">{mapData?.solution}</p>
													</div>
												</div>
												<div className="card-foot d-flex">
													<div className="card-value-block flex-1">
														<p className="prop-head">REPORT ADDED AT</p>
														<p className="prop-value">
															{mapData?.createdAt &&
																moment(mapData?.createdAt)
																	.tz(building?.timeZoneName || "EST5EDT")
																	.format("MMM DD, ddd, LT")}
														</p>
													</div>
													<div className="card-value-block flex-1">
														<p className="prop-head">REPORT UPDATED AT</p>
														<p className="prop-value">
															{mapData?.createdAt &&
																moment(mapData?.updatedAt)
																	.tz(building?.updatedAt || "EST5EDT")
																	.format("MMM DD, ddd, LT")}
														</p>
													</div>
												</div>
											</div>
											{showViewReport && (
												<p
													onClick={() => {
														if (mapData?.reportUrl) {
															window.open(mapData?.reportUrl);
														} else {
															setShowReportData(mapData);
															setShowReportModal(true);
														}
													}}
													className="link desc-small mb-2"
												>
													View Report
												</p>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</TabPanel>
			</div>
			{isLoading && <SpinnerLoader />}
			{showDeleteConfirmation && (
				<ConfirmDialog
					dialogTitle="Resolve Issue"
					message="Do you want to remove this report? This action will permanently remove the selected entry"
					positiveBtnLabel="Remove"
					negativeBtnLabel=""
					onPositiveBtnClicked={() => {
						deleteSchedule();
					}}
					onNegativeBtnClicked={() => {
						setShowDeleteConfirmation(false);
						setSelectedConsultation(undefined);
					}}
					onCloseIconClicked={() => {
						setShowDeleteConfirmation(false);
						setSelectedConsultation(undefined);
					}}
				/>
			)}
			{showResolveConfirmation && (
				<ConfirmDialog
					dialogTitle="Resolve Issue"
					message="Do you want to mark this issue resolved? 
						Resolve - This action will permenanently remove the selected entry.
						Resolve And Archive - This action will mark issue as resolved and move it to Archived Reports tab for future reference"
					positiveBtnLabel="Resolve"
					negativeBtnLabel="Resolve And Archive"
					onPositiveBtnClicked={() => {
						deleteSchedule();
					}}
					onNegativeBtnClicked={() => {
						updateConsultation();
					}}
					onCloseIconClicked={() => {
						setShowResolveConfirmation(false);
						setSelectedConsultation(undefined);
					}}
				/>
			)}
			{showCalendlyLinkNotFound && (
				<ConfirmDialog
					dialogTitle="Add Calendly Link"
					message={getCalendlyNotFoundMsg()}
					positiveBtnLabel={getCalendlyNotFoundModalPositiveButtonLabel()}
					negativeBtnLabel=""
					onPositiveBtnClicked={() => {
						setShowCalendlyLinkNotFound(false);
						if (user.role === UserRoles.CONTRACTOR) {
							history.push(`${URL.CUSTOMER_ACCOUNT}?edit=${asset._id}`);
						}
					}}
					onNegativeBtnClicked={() => {
						setShowCalendlyLinkNotFound(false);
					}}
					onCloseIconClicked={() => {
						setShowCalendlyLinkNotFound(false);
					}}
				/>
			)}
			{showDownloadPDFModal && (
				<DownloadPDFDialog
					downloadPDFLink={downloadLink}
					closeModal={() => setShowDownloadPDFModal(false)}
				/>
			)}
		</div>
	) : (
		<PaymentPendingInfo name={asset.name} />
	);
};
export default ConsultationReports;
