import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function getContractorAssets(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "assets/contractor-assets?isAudit=false");
}

export async function getAdminAssets(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "assets/admin-assets?isAudit=false");
}

export async function getAllAssetBuildings(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "assets/getAllAssetBuildings");
}

export async function addAsset(
	assetDetails: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "assets", undefined, assetDetails);
}

export async function deleteAssetUser(
	assetDetails: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "delete-user-asset", undefined, assetDetails);
}

export async function updateAsset(
	assetDetails: Object,
	assetId: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("PUT", `assets/${assetId}`, undefined, assetDetails);
}

export async function deleteAsset(assetId: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", `assets/${assetId}`);
}
export async function getAssetBuildings(
	assetId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `assets/${assetId}`);
}

export async function getAssetDimension(
	assetId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `assets/dimension?assetId=${assetId}`);
}

export async function getAssetData(assetId: string): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `assets/asset-details/${assetId}`);
}

export async function getSimCardSubscriptions(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `subscription/list`);
}

export async function isAssetHasDevices(
	assetId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `assets/asset-has-devices/${assetId}`);
}
