import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import get from "lodash/get";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

import { AntSwitch } from "../../components/V2Autopilot/CommonSwitch";

import "./DimensionBox.scoped.scss";

interface IDimensionBox {
	shortName: string;
	unit: string;
	enable: boolean;
	currentDimData: any;
	emailAlertThreshold: number;
	phoneAlertMinThreshold: number;
	emailAlertMinThreshold: number;
	phoneAlertThreshold: number;
	openDrawer: (data: any) => void;
	dimIndex: number;
	onStatusChange: (data: any, id: string) => void;
	_id: string;
	isAdmin: boolean;
}

export const DimensionBox: React.FC<IDimensionBox> = ({
	shortName,
	unit,
	enable,
	currentDimData,
	emailAlertThreshold,
	phoneAlertThreshold,
	emailAlertMinThreshold,
	phoneAlertMinThreshold,
	openDrawer,
	dimIndex,
	onStatusChange,
	_id,
	isAdmin,
}) => {
	const [dimensionLimit] = useState({
		"PM2.5": { start: 0, end: 250, range: [0, 50, 100, 150, 200, 250] },
		PM10: { start: 0, end: 250, range: [0, 50, 100, 150, 200, 250] },
		"PM1.0": { start: 0, end: 250, range: [0, 50, 100, 150, 200, 250] },
		TVOC: { start: 0, end: 5000, range: [0, "1k", "2k", "3k", "4k", "5k"] },
		Temp: { start: 0, end: 100, range: [0, 25, 50, 100] },
		CO2: { start: 0, end: 3000, range: [0, "0.75k", "1.5k", "2.25k", "3k"] },
		Formaldehyde: { start: 0, end: 150, range: [0, 30, 60, 90, 120, 150] },
		Humidity: { start: 0, end: 100, range: [0, 25, 50, 100] },
	});
	const currentValue = get(dimensionLimit, `${shortName}`);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [popoverText, setPopoverText] = useState<any>({});

	const handleClick = (event: any, data: any) => {
		setAnchorEl(event.currentTarget);
		setPopoverText(data);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const calculateWidth = (data: any) => {
		const start =
			data.start == currentValue.start || data.start > currentValue.start
				? data.start
				: currentValue.start;
		const end =
			data.end == currentValue.end || data.end <= currentValue.end
				? data.end
				: currentValue.end;
		const range = end - start;
		if (!isNaN(range)) {
			return (100 / currentValue.end) * range;
		}
		return 0;
	};

	const open = Boolean(anchorEl);
	const id = open ? shortName : undefined;

	return (
		<div className="col-6 col-m-sm-12 m-p-0">
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<div className="dim-popover p-3">
					<div className="mb-2 d-flex">
						<div className={popoverText?.type + " circle"}></div>
						<p className="ml-2 status mb-0">{popoverText?.type}</p>
					</div>
					<p className="value-range mb-0">{`${
						popoverText?.start !== -9999 ? popoverText?.start : ""
					} - ${popoverText?.end !== 9999 ? popoverText?.end : ""}`}</p>
				</div>
			</Popover>
			<div className="dimesion-card p-4 mb-3">
				<div className="d-flex justify-content-between mb-2">
					<p className="name">
						{shortName}
						<span className="dim-unit">{`(${unit})`}</span>
					</p>
					{isAdmin && (
						<div className="d-flex align-items-center mb-1">
							<IconButton
								className="mr-2 dimension-edit-tour"
								onClick={() => openDrawer({ rowIndex: dimIndex })}
							>
								<EditIcon />
							</IconButton>
							<AntSwitch
								className="dimension-toggle-tour"
								checked={enable}
								onClick={() => onStatusChange({ enable: !enable }, _id)}
								name="checkedC"
							/>
						</div>
					)}
				</div>
				<div className="d-flex">
					{currentDimData?.map((mapData: any, index: number) => (
						<div
							style={{
								width: `${calculateWidth(mapData)}%`,
							}}
							className={`${mapData.type} ${
								index === 0 ? " left-border" : ""
							} ${index === currentDimData?.length - 1 ? " right-border" : ""}`}
							onClick={(e) => handleClick(e, mapData)}
							aria-describedby={id}
						></div>
					))}
				</div>
				<div
					style={{
						width: `100%`,
					}}
					className="pt-2 pb-4 d-flex justify-content-between"
				>
					{currentValue?.range?.map((mapData: any) => (
						<p className="mb-0 dim-value">{mapData}</p>
					))}
				</div>

				<div className="d-flex justify-content-between">
					<div>
						<p className="mb-0 threshold-text">
							Threshold value for Email Alert
						</p>
						{!emailAlertMinThreshold ? (
							<p className="threshold-value">{emailAlertThreshold}</p>
						) : (
							<>
								<p className="threshold-value">Upper: {emailAlertThreshold}</p>
								<p className="threshold-value">
									Lower: {emailAlertMinThreshold}
								</p>
							</>
						)}
					</div>
					<div>
						<p className="mb-0 threshold-text">
							Threshold value for Phone Alert
						</p>
						{!phoneAlertMinThreshold ? (
							<p className="threshold-value">{phoneAlertThreshold}</p>
						) : (
							<>
								<p className="threshold-value">Upper: {phoneAlertThreshold}</p>
								<p className="threshold-value">
									Lower: {phoneAlertMinThreshold}
								</p>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
