import React from "react";
import { useHistory } from "react-router-dom";

import { URL } from "../../components/Navbar/routes";

import "./MarketPlace.scoped.scss";

export interface ICategory {
	name: string;
	imgSrc: string;
	id: string;
}

export const Category: React.FC<ICategory> = ({ name, imgSrc, id }) => {
	const history = useHistory();

	return (
		<div
			onClick={() => history.push(`${URL.MARKET_PLACE_PRODUCT_LIST}/${id}`)}
			className="categroy-card cursor-pointer"
		>
			<p>{name}</p>
			<img alt="prod" src={imgSrc} />
		</div>
	);
};
