import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

import { addLocation, updateLocation } from "../../api/LocationController";
import { RootState } from "../../redux/reducers";
import { SpinnerLoader } from "../../common/Global.Style";

import "../../components/AddNewLocation/AddNewLocation.scoped.scss";
import { useParams } from "react-router-dom";
import { setupWebViewJavascriptBridge } from "../../common/JsBridge";
import { getBuildingDetails } from "../../api/DevicesController";

const AddNewLocationForMobile: React.FC = () => {
	const params: any = useParams();
	const [formData, setFormData] = useState<any>({});
	const [buildingOption, setBuildingOption] = useState<any>([]);
	const [locationType, setLocationType] = useState<string>("");
	const [assetId, setAssetId] = useState<string>("");
	const { asset_id, building_id } = params;
	useEffect(() => {
		if (asset_id) {
			setAssetId(asset_id);
			getBuilding(asset_id);
		}
	}, [asset_id]);

	const [selectedBuilding, setSelectedBuilding] = useState<any>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const colourStyles = {
		control: (styles: any) => ({
			...styles,
			borderRadius: "10px",
			backgroundColor: "#f8f9fa",
			height: "55px",
		}),
	};
	const [formErrorData, setFormErrorData] = useState<any>({});
	const [locationName, setLocationName] = useState<string>("");
	const [locationDesc, setLocationDesc] = useState<string>("");

	useEffect(() => {
		if (formData) {
			setLocationName(formData?.name);
			setLocationDesc(formData?.description);
		}
	}, [formData]);

	const saveDetails = (values: any) => {
		setIsLoading(true);
		if (locationType === "edit") {
			updateLocation(formData._id, values)
				.then((response: any) => {
					setIsLoading(false);
					if (response.responseObj) {
						toast.success(response.responseObj.message);
						onCloseEditLocation();
					} else {
						toast.error(response.error);
					}
				})
				.catch((error) => {
					setIsLoading(false);
				});
		} else {
			addLocation(values)
				.then((response: any) => {
					setIsLoading(false);
					if (response.responseObj) {
						toast.success(response.responseObj.message);
						onCloseEditLocation();
					} else {
						toast.error(response.error);
					}
				})
				.catch((error) => {
					setIsLoading(false);
				});
		}
	};

	const onSubmit = (values: any) => {
		const formError: any = {};
		if (!locationName) {
			formError.name = "Location name is required";
		}
		// if (!locationDesc) {
		// 	formError.description = "Description is required";
		// }
		if (!selectedBuilding.value) {
			formError.building = "Building is required";
		}
		if (!isEmpty(formError)) {
			setFormErrorData(formError);
			return;
		}
		saveDetails({
			name: locationName,
			description: locationDesc,
			building: selectedBuilding.value,
			asset: assetId,
		});
	};

	const onNameChange = (e: any) => {
		setLocationName(e.target.value);
		const tempData = cloneDeep(formErrorData);
		tempData.name = "";
		setFormErrorData(tempData);
	};

	const onDescChange = (e: any) => {
		setLocationDesc(e.target.value);
		const tempData = cloneDeep(formErrorData);
		tempData.description = "";
		setFormErrorData(tempData);
	};
	const getBuilding = async (assetId: any) => {
		setIsLoading(true);
		const response: any = await getBuildingDetails(assetId);
		setTimeout(() => {
			setIsLoading(false);
		}, 3000);
		if (response.responseObj) {
			const buidings = response.responseObj;
			if (building_id) {
				let index = buidings.findIndex(
					(x: any) => x._id.toString() === building_id.toString()
				);
				setSelectedBuilding(buidings[index]);
			}
			setBuildingOption(
				response.responseObj.map((mapData: any) => ({
					value: mapData._id,
					label: mapData.name,
				}))
			);
			return response.responseObj;
		}
		return [];
	};
	const registerHandler = (funName: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.registerHandler(
				funName,
				function (data: any, responseCallback: any) {
					// data 接收的数据，responseCallback 调用回调函数
					callback && callback(data, responseCallback);
				}
			);
		});
	};
	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};
	const onCloseEditLocation = () => {
		callHandler("sendStatusToNative", {}, (data: any) => {});
	};
	useEffect(() => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			registerHandler("editLocationFromNative", async function (data: any) {
				const dataFromNative: any = data?.split("-DATAFROMNATIVE-");
				if (dataFromNative.length >= 3) {
					setAssetId(dataFromNative[2]);
					const buldingListForMobile: any = await getBuilding(
						dataFromNative[2]
					);
					setFormData(JSON.parse(dataFromNative[1]));
					setLocationType("edit");
					let index = buldingListForMobile.findIndex(
						(x: any) => x._id.toString() === dataFromNative[0].toString()
					);
					setSelectedBuilding(buldingListForMobile[index]);
				}
			});
			registerHandler("addNewLocationFromNative", async function (data: any) {
				setAssetId(data);
				await getBuilding(data);
			});
		});
		// getBuilding("1515101534831046656");
		// const mm: any = {
		// 	_id: "62f4ae5b017ad5000aa96420",
		// 	autoPilot: true,
		// 	name: "Test t4 4567789",
		// 	building: "625a01c8f8c80c000af1225b",
		// 	asset: "1515101534831046656",
		// 	description: "Renovated iQi Lab  check",
		// };
		// setSelectedBuilding(buildingOption[0]);
		// setLocationType("edit");
		// setFormData(mm);
		// getBuilding("1515101534831046656");
		// setAssetId("1515101534831046656");
	}, []);

	return (
		<div className="col-md-12 col-lg-6">
			<h5 className="mt-3">
				{locationType === "edit" ? "Update Location" : "Add New Location"}
			</h5>
			<div className="row pr-3 pl-3 h-100 pb-5 mb-2">
				<div className="col-lg-12 col-md-12 loca bg-white p-3">
					<div className="col-12 mt-4">
						<h6 className="mb-0 mb-2">Building</h6>
						<Select
							className="basic-multi-select"
							components={{ IndicatorSeparator: () => null }}
							onChange={(data: any) => {
								setSelectedBuilding(data);
								const tempData = cloneDeep(formErrorData);
								tempData.building = "";
								setFormErrorData(tempData);
							}}
							isDisabled={!!formData?._id}
							options={buildingOption}
							placeholder=""
							value={selectedBuilding}
							name="building"
							styles={colourStyles}
						/>
						{formErrorData.building && (
							<div className="text-danger">{formErrorData.building}</div>
						)}
					</div>
					<div className="col-12 mt-4">
						<h6 className="mb-0">Location Name</h6>
						<input
							id="name"
							name="name"
							type="text"
							className="w-100 mt-2 p-3 bg-light locationinput"
							onChange={onNameChange}
							value={locationName}
							maxLength={100}
						/>
						{formErrorData?.name ? (
							<div className="text-danger">{formErrorData.name}</div>
						) : null}
					</div>

					{!isEmpty(formData) && (
						<div className="col-12 mt-3">
							<h6 className="mb-0">Description</h6>
							<input
								id="description"
								name="description"
								type="text"
								className="w-100 p-3 mt-2 bg-light locationinput"
								onChange={onDescChange}
								value={locationDesc}
							/>
							{formErrorData?.description ? (
								<div className="text-danger">{formErrorData.description}</div>
							) : null}
						</div>
					)}

					<div className="row mx-0 mt-3 justify-content-end r-g-16">
						<div className="col-lg-6 col-md-12 mx-0">
							<button onClick={onSubmit} className="btn add mx-0 w-100">
								{locationType === "edit" ? "Update" : "Add"}
							</button>
						</div>
						<div className="col-lg-6 col-md-12 mx-0">
							<button
								type="submit"
								className="btn add mx-0 w-100"
								onClick={onCloseEditLocation}
							>
								Back
							</button>
						</div>
					</div>
				</div>

				{isLoading && <SpinnerLoader />}
			</div>
		</div>
	);
};
export default AddNewLocationForMobile;
