import { isIpadOS } from "./service";
declare global {
	interface Window {
		WebViewJavascriptBridge: any;
		WKWebViewJavascriptBridge: any;
		WKWVJBCallbacks: any;
		webkit: any;
	}
}

export const setupWebViewJavascriptBridge = (callback: any) => {
	if (/android/.test(navigator.userAgent.toLowerCase())) {
		if (window?.WebViewJavascriptBridge) {
			callback(window.WebViewJavascriptBridge);
		} else {
			document.addEventListener(
				"WebViewJavascriptBridgeReady",
				function () {
					callback(window.WebViewJavascriptBridge);
				},
				false
			);
		}
	} else if (
		/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) ||
		isIpadOS()
	) {
		if (window?.WKWebViewJavascriptBridge) {
			return callback(window?.WKWebViewJavascriptBridge);
		}
		if (window.WKWVJBCallbacks) {
			return window.WKWVJBCallbacks.push(callback);
		}
		window.WKWVJBCallbacks = [callback];
		window?.webkit?.messageHandlers?.iOS_Native_InjectJavascript?.postMessage(
			null
		);
	}
};

export const registerHandler = (funName: any, callback: any) => {
	setupWebViewJavascriptBridge(function (bridge: any) {
		bridge.registerHandler(
			funName,
			function (data: any, responseCallback: any) {
				// data 接收的数据，responseCallback 调用回调函数
				callback && callback(data, responseCallback);
			}
		);
	});
};

export const callHandler = (funName: any, dataJson: any, callback: any) => {
	setupWebViewJavascriptBridge(function (bridge: any) {
		bridge.callHandler(funName, dataJson, function (response: any) {
			callback && callback(response);
		});
	});
};
