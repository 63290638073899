import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function getBuildingsList(
	period: string,
	asset: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		"v2.0/buildings?period=" + period + "&asset=" + asset
	);
}

export async function getBuildingsById(
	id: any,
	period: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `buildings/${id}?period=` + period);
}

export async function addBuilding(
	buildingDetails: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "buildings", undefined, buildingDetails);
}

export async function updateBuilding(
	buildingId: any,
	buildingDetails: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		"buildings/" + buildingId,
		undefined,
		buildingDetails
	);
}

export async function deleteBuildingData(id: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", "buildings/" + id);
}

export async function getCertificationData(
	buildingId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `certifications/${buildingId}`);
}

export async function generateQRCode(
	buildingId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"generate-qrcode/with-logo",
		undefined,
		{
			buildingId,
		},
		undefined,
		undefined,
		true
	);
}

export async function getEncryptedBuildingId(
	buildingId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "v2.0/buildings/encrypt-bId", undefined, {
		buildingId,
	});
}

export async function getAutoCompleteAddress(
	query: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `audit-report/search-address?query=${query}`);
}

export async function getTimeZoneByLatLng(
	latlng: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "audit-report/get-timezone", undefined, latlng);
}
