import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import {
	getBuildingsById,
	getCertificationData,
} from "../../api/buildingsController";
import { RootState } from "../../redux/reducers";
import MonitorList from "./MonitorList/MonitorList";
import { getMergeIAQLinear } from "../../api/ReportsController";
import {
	getGaugesDataList,
	getSyncDeviceStatus,
	getDeviceCountBasedOnCount,
	getMonitorTypeData,
	getBuildingDeviceList,
} from "../../api/DevicesController";
import IonMonitor from "./IonMonitor";
import { setDevices } from "../../redux/actions/deviceActions";
import DeviceCount from "./DeviceCount/DeviceCount";
import Certification from "./Certification/Certification";
import ContentLoader from "../../components/ContentLoader/ContentLoader";
import { colorStatus } from "../../constant";
import Chart from "./Chart";
import Summary from "./Summary/Summary";
import { SpinnerLoader } from "../../common/Global.Style";
import { getAssetBuildings } from "../../api/AssetController";
import { URL } from "../../components/Navbar/routes";
import { setBuilding, setBuildings } from "../../redux/actions/buildingActions";
import PaymentPendingInfo from "../../components/PaymentPendingInfo/PaymentPendingInfo";

import Subscription from "../Subscription/Subscription";
import WorkerBuilder from "./WokerBuilder";
import ChartWebWorker from "./GraphWebWorker";

import "./Dashboard.scoped.scss";
import {
	upgradeToPremiumOffline,
	upgradeToPremiumOnline,
} from "../../api/ApplicationSubscriptionController";
import { toast } from "react-toastify";
import { setUser } from "../../redux/actions/userActions";
import round from "lodash/round";

let apiInterval: any;

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const Dashboard: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	let query = useQuery();

	const building = useSelector((state: RootState) => state.building);
	const user = useSelector((state: RootState) => state.user);
	const asset = useSelector((state: RootState) => state.asset);
	const devices: any = useSelector(
		(state: RootState) => state?.devices?.deviceList
	);

	const action = query.get("action");
	const transaction = query.get("transaction");
	const sessionKey = query.get("sessionKey");

	useEffect(() => {
		if (asset._id) {
			getBuildingList();
		}
	}, [asset]);

	useEffect(() => {
		if (action === "upgrade" && transaction === "success") {
			updatePremiumOnline();
		} else if (action === "upgrade" && transaction === "fail") {
			toast.warning("Please try again.");
		}
	}, []);

	const [buildingData, setBuildingData] = useState<any>({});
	const [deviceData, setDeviceData] = useState<any>({});
	const [buildingDataUpdatedAt, setBuildingDataUpdatedAt] = useState<Date>(
		new Date()
	);
	const [monitorList, setMonitorList] = useState<any>([]);
	const [isBuildingApiLoading, setIsBuildingApiLoading] =
		useState<boolean>(false);
	const [isGaugesApiLoading, setIsGaugesApiLoading] = useState<boolean>(false);
	const [isGraphApiLoading, setIsGraphApiLoading] = useState<boolean>(false);
	const [ionSectionLoading, setIonSectionLoading] = useState<boolean>(false);
	const [isCertificationLoading, setIsCertificationLoading] =
		useState<boolean>(false);
	const [ionMonitorData, setIonMonitorData] = useState<any>([]);
	const [certificationData, setCertificationData] = useState<any>({});

	const [chartWorkerData, setChartWorkerData] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedBuilding, setSelectedBuilding] = useState<any>({});
	const [isHavingMonitors, setIsHavingMonitor] = useState<boolean>(true);

	const ionMonitorDataRef = useRef<any>([]);

	const [dewPointData, setDewPointData] = useState<{
		status: string;
		value: number | string;
	}>({
		value: "N/A",
		status: "N/A",
	});
	const [aqiData, setAqiData] = useState<{
		value: string;
		status: string;
	}>({
		value: "N/A",
		status: "N/A",
	});

	const fetchAnalyticsData = (id: any, period: any, type: string = "") => {
		if (!type) {
			setIsBuildingApiLoading(true);
		}
		getBuildingsById(id, period)
			.then((response: any) => {
				setIsBuildingApiLoading(false);
				if (
					response.responseObj &&
					id === localStorage?.getItem("currentBuildingId")
				) {
					if (
						response?.responseObj?.latest?.pm25_value &&
						response?.responseObj?.latest?.pm10
					) {
						const pm10Value = get(response, "responseObj.latest.pm10.value");
						const pm25Value = get(
							response,
							"responseObj.latest.pm25_value.value"
						);
						calculateAqiStatusValue(pm25Value, pm10Value);
					}
					if (
						response?.responseObj?.latest?.temp_current_f?.value &&
						response?.responseObj?.latest?.humidity_value?.value
					) {
						calculateDewPoint(
							response?.responseObj?.latest?.temp_current_f?.value,
							response?.responseObj?.latest?.humidity_value?.value
						);
					}
					setBuildingData(response.responseObj);
					setBuildingDataUpdatedAt(response.responseObj?.lastUpdatedAt);
				} else {
					setAqiData({ value: "N/A", status: "N/A" });
					setDewPointData({ value: "N/A", status: "N/A" });
					setBuildingData({});
				}
			})
			.catch((error) => {
				setIsBuildingApiLoading(false);
			});
	};

	const getBuildingList = async () => {
		const response = await getAssetBuildings(asset._id);
		if (response.responseObj) {
			const buildingList = get(response, "responseObj.buildings", []);
			dispatch(setBuildings(buildingList));
			const isBuildingFound = buildingList.find(
				(mapData: any) => mapData._id === building?._id
			);
			if (isEmpty(isBuildingFound)) {
				dispatch(setBuilding(get(response, `responseObj.buildings[0]`, {})));
			}
		}
	};

	const getDeviceCount = async (type: string = "", id = "") => {
		const response = await getDeviceCountBasedOnCount(id || building._id);
		if (response.responseObj) {
			setDeviceData(response.responseObj);
		}
	};

	const getCertificationDataApi = async (id = "", type: string = "") => {
		if (!type) {
			setIsCertificationLoading(true);
		}
		const response = await getCertificationData(id || building?._id);
		setIsCertificationLoading(false);
		if (response?.responseObj) {
			setCertificationData(response?.responseObj);
		} else {
			setCertificationData({});
		}
	};

	const getDevicesDataByBuilding = async () => {
		setIsLoading(true);
		const response = await getBuildingDeviceList(building._id);
		setIsLoading(false);
		dispatch(setDevices(get(response, "responseObj.devices", [])));
	};

	useEffect(() => {
		refreshButtonClick();
		if (isEmpty(devices)) {
			getDevicesDataByBuilding();
		}
		document.getElementById("app-container")?.scrollIntoView();
		getBuildingList();
	}, []);

	useEffect(() => {
		let isMonitorFound = false;
		devices?.forEach((device: any) => {
			if (device?.deviceType === "monitor") {
				isMonitorFound = true;
			}
		});
		setIsHavingMonitor(isMonitorFound);
	}, [devices]);

	useEffect(() => {
		setDewPointData({ value: "N/A", status: "N/A" });
		setAqiData({ value: "N/A", status: "N/A" });
		if (building && building._id) {
			// socket.off(localStorage.getItem('socket-name'))
			setSelectedBuilding(building);
			fetchAnalyticsData(building._id, "day");
			getMonitor(building._id);
			getIonMonitorData(building._id);
			getIAQMergeReportData(building._id);
			getCertificationDataApi(building._id);
			getDeviceCount();
			getDevicesDataByBuilding();
		} else {
			setCertificationData({});
			setBuildingData({});
			setMonitorList([]);
			setChartWorkerData({});
		}

		apiInterval = setInterval(() => {
			if (building && !!building._id) {
				let latestBuildingData: any;
				setSelectedBuilding((prevState: any) => {
					latestBuildingData = prevState;
					return prevState;
				});
				fetchAnalyticsData(latestBuildingData?._id, "day", "refresh");
				getMonitor(latestBuildingData?._id, "refresh");
				getIonMonitorData(latestBuildingData?._id, "refresh");
				getIAQMergeReportData(latestBuildingData?._id, "refresh");
				getDeviceCount("refresh", latestBuildingData?._id);
				getCertificationDataApi(building._id, "refresh");
			}
		}, 60000);

		// this will clear Timeout
		// when component unmount like in willComponentUnmount
		// and show will not change to true
		return () => {
			clearInterval(apiInterval);
			apiInterval = null;
		};
	}, [building]);

	const calculateAqiStatusValue = (pm25Value: any, pm10Value: any) => {
		if (pm25Value && pm10Value) {
			let status = "N/A";
			let value = "N/A";
			if (pm25Value <= 15 && pm10Value <= 54) {
				status = "Good";
				value = "below 50";
			} else if (
				(pm25Value <= 15 && pm10Value <= 254) ||
				(pm25Value <= 65 && pm10Value <= 54) ||
				(pm25Value <= 65 && pm10Value <= 254)
			) {
				status = "Moderate";
				value = "below 150";
			} else if (
				(pm25Value <= 15 && pm10Value > 255) ||
				(pm25Value <= 65 && pm10Value > 255) ||
				(pm25Value > 65 && pm10Value <= 54) ||
				(pm25Value > 65 && pm10Value <= 255) ||
				(pm25Value > 65 && pm10Value > 255)
			) {
				status = "Poor";
				value = "above 150";
			}
			setAqiData({ value, status });
		}
	};

	const calculateDewPoint = (temp: any, humidity: any) => {
		if (temp && humidity) {
			// formula Td=T-((9/25)*(100-RH))
			let value: any = round(temp - (9 / 25) * (100 - humidity), 1);

			const status = value <= 62 ? "Good" : "Moderate";
			value = value.toString();
			const valueArrays = value.split(".");
			if (valueArrays.length > 1) {
				valueArrays[1] = valueArrays[1].substring(0, 2);
			}
			value = valueArrays.join(".");
			setDewPointData({ value, status });
		}
	};

	const refreshButtonClick = () => {
		if (building && building._id) {
			getSyncDeviceStatus()
				.then((res: any) => {})
				.catch((error) => {});
		}
	};

	const getIAQMergeReportData = (buildingId: any, type: string = "") => {
		if (!type) {
			setIsGraphApiLoading(true);
		}
		const dateFrom = new Date(
			new Date().setDate(new Date().getDate() - 1)
		).toISOString();
		const dateTo = new Date().toISOString();
		const iaqReportReq =
			"startDate=" +
			dateFrom +
			"&endDate=" +
			dateTo +
			"&buildingId=" +
			buildingId;

		const data = iaqReportReq;
		localStorage?.setItem("currentBuildingId", building._id);
		getMergeIAQLinear(data)
			.then((response: any) => {
				if (response.responseObj && response.responseObj?.chartData) {
					let chartWorkerInstance = new WorkerBuilder(ChartWebWorker);
					chartWorkerInstance.postMessage({
						apiData: response.responseObj?.chartData,
						timeZone: get(building, "timeZoneName", "EST"),
					});
					chartWorkerInstance.onmessage = (message: any) => {
						if (
							message &&
							buildingId === localStorage?.getItem("currentBuildingId")
						) {
							Object.keys(message?.data).forEach((key: string) => {
								message?.data[key].groupDataValues?.forEach(
									(chartValue: any) => {
										chartValue.yValueFormatString = "###0.##";
									}
								);
							});
							setChartWorkerData(message.data);
							setIsGraphApiLoading(false);
							chartWorkerInstance?.terminate();
						}
					};
				} else {
					setIsGraphApiLoading(false);
				}
			})
			.catch((error) => {
				setIsGraphApiLoading(false);
			});
	};

	const getMonitor = (buildingId: string, type: string = "") => {
		if (!type) {
			setIsGaugesApiLoading(true);
		}
		getGaugesDataList(buildingId, asset._id)
			.then((response: any) => {
				setIsGaugesApiLoading(false);
				if (response.responseObj) {
					setMonitorList(response.responseObj);
				} else {
					setMonitorList([]);
				}
			})
			.catch((error) => {
				setIsGaugesApiLoading(false);
				setMonitorList([]);
			});
	};

	const getIonMonitorData = (buildingId: string, type: string = "") => {
		const ionMonitorExists = devices?.some(
			(device: any) =>
				device?.deviceType === "monitor" && device?.subType === "ionMonitor"
		);

		if (!ionMonitorExists) {
			setIonMonitorData([]);
			ionMonitorDataRef.current = [];
			return;
		}

		if (!type) {
			setIonSectionLoading(true);
		}
		getMonitorTypeData(buildingId, asset._id)
			.then((response: any) => {
				setIonSectionLoading(false);
				if (response.responseObj) {
					setIonMonitorData(response.responseObj);
					ionMonitorDataRef.current = response.responseObj;
				} else {
					setIonMonitorData([]);
					ionMonitorDataRef.current = [];
				}
			})
			.catch((error) => {
				setIonSectionLoading(false);
				setIonMonitorData([]);
				ionMonitorDataRef.current = [];
			});
	};

	const checkAsset = () => {
		return !asset?.payPending;
	};

	const updatePremiumOnline = async () => {
		setIsLoading(true);
		try {
			const response = await upgradeToPremiumOnline();
			if (response?.responseObj) {
				dispatch(setUser(response?.responseObj?.user));
				setIsLoading(false);
				toast.success(response?.responseObj?.message);
			} else {
				setIsLoading(false);
				toast.warning(response?.error);
			}
		} catch (error: any) {
			setIsLoading(false);
			toast.warning(error?.message);
		}
	};

	return checkAsset() ? (
		<div className="m-px-2 mt-3">
			{isLoading && <SpinnerLoader />}
			{isHavingMonitors ? (
				<div>
					<Accordion defaultExpanded={true} className="">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
						>
							<p className="accordian-summary mb-0 summary-tour">Summary</p>
						</AccordionSummary>
						<AccordionDetails>
							{isBuildingApiLoading ? (
								<ContentLoader />
							) : (
								<Summary
									aqiData={aqiData}
									buildingData={buildingData}
									dewPointData={dewPointData}
									buildingDataUpdatedAt={buildingDataUpdatedAt}
								/>
							)}
						</AccordionDetails>
					</Accordion>

					<Accordion defaultExpanded={true} className="">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
						>
							<p className="accordian-summary mb-0 gauges-tour">Gauges</p>
						</AccordionSummary>
						<AccordionDetails>
							{isGaugesApiLoading ? (
								<ContentLoader />
							) : (
								<div className="box3 w-100">
									<MonitorList
										monitorList={monitorList}
										colorStatus={colorStatus}
									/>
								</div>
							)}
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded={true} className="">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
						>
							<p className="accordian-summary mb-0 graphs-tour">Graphs</p>
						</AccordionSummary>
						{isGraphApiLoading ? (
							<ContentLoader />
						) : (
							<>
								<AccordionDetails>
									<div className="row m-m-auto w-100">
										<div className="text-center col-lg-6 col-m-sm-12 m-p-0">
											<Chart
												chartTitle="Temperature (F)"
												chartData={chartWorkerData?.temp_current_f}
											/>
										</div>
										<div className="text-center col-lg-6 col-m-sm-12 m-mt-4 m-p-0">
											<Chart
												chartTitle="Humidity (%)"
												chartData={chartWorkerData?.humidity_value}
											/>
										</div>
									</div>
								</AccordionDetails>
								<AccordionDetails>
									<div className="row m-m-auto w-100">
										<div className="text-center col-lg-6 col-m-sm-12 m-p-0">
											<Chart
												chartTitle="CO2 (ppm)"
												chartData={chartWorkerData?.co2_value}
											/>
										</div>
										<div className="text-center col-lg-6 col-m-sm-12 m-mt-4 m-p-0">
											<Chart
												chartTitle="PM1.0 (ug/m3)"
												chartData={chartWorkerData?.pm1}
											/>
										</div>
									</div>
								</AccordionDetails>

								<AccordionDetails>
									<div className="row m-m-auto w-100">
										<div className="text-center col-lg-6 col-m-sm-12 m-p-0">
											<Chart
												chartTitle="Formaldehyde (ppb)"
												chartData={chartWorkerData?.ch2o_value}
											/>
										</div>
										<div className="text-center col-lg-6 col-m-sm-12 m-mt-4 m-p-0">
											<Chart
												chartTitle="PM10 (ug/m3)"
												chartData={chartWorkerData?.pm10}
											/>
										</div>
									</div>
								</AccordionDetails>

								<AccordionDetails>
									<div className="row m-m-auto w-100">
										<div className="text-center col-lg-6 col-m-sm-12 m-p-0">
											<Chart
												chartTitle="PM2.5 (ug/m3)"
												chartData={chartWorkerData?.pm25_value}
											/>
										</div>
										<div className="text-center col-lg-6 col-m-sm-12 m-mt-4 m-p-0">
											<Chart
												chartTitle="TVOC (ppb)"
												chartData={chartWorkerData?.voc_value}
											/>
										</div>
									</div>
								</AccordionDetails>
							</>
						)}
					</Accordion>

					{!isEmpty(ionMonitorData) && (
						<Accordion defaultExpanded={true}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
							>
								<p className="accordian-summary mb-0">Ion Analytics</p>
							</AccordionSummary>
							<AccordionDetails>
								{ionSectionLoading ? (
									<ContentLoader />
								) : (
									<div className="box3 w-100">
										<IonMonitor
											subTitle="Ion Count"
											title="Ion Analytics"
											imagePath="/images/ion_icon.png"
											monitorData={ionMonitorData}
										/>
									</div>
								)}
							</AccordionDetails>
						</Accordion>
					)}

					<Accordion defaultExpanded={true}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
						>
							<p className="accordian-summary standards-tour mb-0">Standards</p>
						</AccordionSummary>
						<AccordionDetails>
							{isBuildingApiLoading ? (
								<ContentLoader />
							) : (
								<Certification certificationData={certificationData} />
							)}
						</AccordionDetails>
					</Accordion>

					<Accordion defaultExpanded={true} className="mb-4">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
						>
							<p className="accordian-summary devices-tour mb-0">Devices</p>
						</AccordionSummary>
						<AccordionDetails>
							<DeviceCount deviceData={deviceData} />
						</AccordionDetails>
					</Accordion>
				</div>
			) : (
				<div className="dashboard-nodata-container">
					{!isLoading && (
						<p className="mb-0 mt-5 pt-3">
							No Air Quality Monitor is assigned to this building yet. Please
							use iQiCloud mobile app to add devices
						</p>
					)}
				</div>
			)}
		</div>
	) : (
		<PaymentPendingInfo name={asset.name} />
	);
};

export default Dashboard;
