import { URL } from "../../../components/Navbar/routes";

import DataSim from "../../../images/data-service.svg";
import customerAccount from "../../../images/customer-account.svg";

const AdminSubMenuValues: any = [
	{
		title: "Data SIM",
		icon: <img className="mr-2" src={DataSim} alt="Data SIM" />,
		description:
			"Active Data SIMs and plan details. Data SIM and LTE Hotspot helps to measure air quality anytime , anywhere. You can activate a new SIM card or redeem credit for a preactivated data SIM.",
		viewText: "View Data SIM",
		viewLink: URL.CONSULTATION_REPORTS,
		path: URL.DATA_SIM,
	},
	{
		title: "Customer Accounts",
		icon: (
			<img className="mr-2" src={customerAccount} alt="Customer Accounts" />
		),
		description:
			"List of all the customer accounts you remotely manage. You can view, edit, delete and assign users to these accounts.",
		viewText: "View Account",
		viewLink: "",
		path: URL.CUSTOMER_ACCOUNT,
	},
];

export { AdminSubMenuValues };
