import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { Close, AddCircle, RemoveCircle } from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { cloneDeep } from "lodash";

import { AntSwitch } from "./CommonSwitch";
import { SpinnerLoader } from "../../common/Global.Style";

import "./Autopilot.scss";

const statusValue: any = {
	gte: ">=",
	gt: ">",
	lte: "<=",
	lt: "<",
	"N/A": "N/A",
};

const statusCode: any = {
	">=": "gte",
	">": "gt",
	"<=": "lte",
	"<": "lt",
	"N/A": "N/A",
};

export const AutopilotDrawer = ({
	open,
	onClose,
	drawerData,
	onDrawerDataSave,
	onDrawerDataChange,
	isLoading,
}: any) => {
	const [emailThresholdError, setEmailThresholdError] = useState<string>("");
	const [phoneThresholdError, setPhoneThresholdError] = useState<string>("");
	const [emailThresholdMinError, setEmailThresholdMinError] =
		useState<string>("");
	const [phoneThresholdMinError, setPhoneThresholdMinError] =
		useState<string>("");

	const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onDrawerDataChange({ ...drawerData, enable: event.target.checked });
	};

	const onPhoneThresholdChange = (event: any) => {
		if (event?.target?.value?.length > 4) {
			setPhoneThresholdError("Enter valid numeric value");
		} else {
			setPhoneThresholdError("");
			onDrawerDataChange({
				...drawerData,
				phoneAlertThreshold: event.target.value,
			});
		}
	};

	const onEmailThresholdChange = (event: any) => {
		if (event?.target?.value?.length > 4) {
			setEmailThresholdError("Enter valid numeric value");
		} else {
			setEmailThresholdError("");
			onDrawerDataChange({
				...drawerData,
				emailAlertThreshold: event.target.value,
			});
		}
	};

	const onPhoneMinThresholdChange = (event: any) => {
		if (event?.target?.value?.length > 4) {
			setPhoneThresholdMinError("Enter valid numeric value");
		} else {
			setPhoneThresholdMinError("");
			onDrawerDataChange({
				...drawerData,
				phoneAlertMinThreshold: event.target.value,
			});
		}
	};

	const onEmailMinThresholdChange = (event: any) => {
		if (event?.target?.value?.length > 4) {
			setEmailThresholdMinError("Enter valid numeric value");
		} else {
			setEmailThresholdMinError("");
			onDrawerDataChange({
				...drawerData,
				emailAlertMinThreshold: event.target.value,
			});
		}
	};

	const validateDimension = (arrayData: any, goodRange: any) => {
		let isErrorFound = false;
		arrayData.forEach((mapData: any, index: number) => {
			let ishaveError = false;
			// if (mapData.start > mapData.end) {
			// 	isErrorFound = true;
			// 	ishaveError = true;
			// 	mapData.startError = "Min value is not Greater than Max value";
			// }

			// if (mapData.start === mapData.end) {
			// 	isErrorFound = true;
			// 	ishaveError = true;
			// 	mapData.startError = "Mix value and Max value cannot be same";
			// }

			if (
				index !== 0 &&
				goodRange.end &&
				mapData.start &&
				goodRange.end > mapData.start
			) {
				isErrorFound = true;
				ishaveError = true;
				mapData.startError = "Invalid range";
			}
			goodRange.start = mapData.start;
			goodRange.end = mapData.end;
			if (!ishaveError) {
				delete mapData.startError;
				delete mapData.endError;
			}
		});
		return isErrorFound;
	};

	const validateArrayData = (dimensionList: any) => {
		return dimensionList.map((mapData: any) => {
			if (!mapData.start && mapData.end) {
				mapData.startSymbol = "gte";
				mapData.start = -9999;
			}
			if (!mapData.end && mapData.start) {
				mapData.endSymbol = "lte";
				mapData.end = 9999;
			}
			if ((!mapData.start && mapData.end) || (!mapData.end && mapData.start)) {
				if (!mapData.startSymbol || mapData.startSymbol === "N/A") {
					mapData.startSymbol = "gte";
					mapData.start = -9999;
				}
				if (!mapData.endSymbol || mapData.endSymbol === "N/A") {
					mapData.endSymbol = "lte";
					mapData.end = 9999;
				}
			}
			if (
				!["N/A", ""].includes(mapData.startSymbol) &&
				mapData.start &&
				mapData.start !== -9999 &&
				["N/A", ""].includes(mapData.endSymbol)
			) {
				mapData.endSymbol = "lte";
				mapData.end = 9999;
			}

			if (
				!["N/A", ""].includes(mapData.endSymbol) &&
				mapData.end &&
				mapData.end !== -9999 &&
				["N/A", ""].includes(mapData.startSymbol)
			) {
				mapData.startSymbol = "gte";
				mapData.start = -9999;
			}

			if (mapData.startSymbol === "N/A" && mapData.endSymbol === "N/A") {
				mapData.start = null;
				mapData.end = null;
			}
			return mapData;
		});
	};

	const onSave = () => {
		const tempData = cloneDeep(drawerData);
		tempData.danger = validateArrayData(tempData.danger);
		tempData.good = validateArrayData(tempData.good);
		tempData.moderate = validateArrayData(tempData.moderate);
		tempData.poor = validateArrayData(tempData.poor);
		tempData.veryPoor = validateArrayData(tempData.veryPoor);
		onDrawerDataChange(tempData);
		onDrawerDataSave(tempData, drawerData._id);
	};

	const addNewConfig = (type: any) => {
		const tempData = { ...drawerData };
		const arrayData = tempData[type];
		arrayData[arrayData.length] = {
			startSymbol: "gte",
			endSymbol: "lte",
			start: "",
			end: "",
		};
		tempData[type] = arrayData;
		onDrawerDataChange(tempData);
	};

	const removeExistingConfig = (type: string, index: number) => {
		const tempData = cloneDeep(drawerData);
		const arrayData: any = cloneDeep(tempData[type]);
		arrayData.splice(index, 1);
		tempData[type] = arrayData;
		onDrawerDataChange(tempData);
	};

	const renderMaxSelect = (value: string, updateSelect: any) => {
		return (
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={statusValue[value]}
				onChange={updateSelect}
				className="adornment-select"
			>
				<MenuItem value={statusValue.lt}>{`<`}</MenuItem>
				<MenuItem value={statusValue.lte}>{`<=`}</MenuItem>
				<MenuItem value={statusValue["N/A"]}>{`N/A`}</MenuItem>
			</Select>
		);
	};

	const renderMinSelect = (value: string, updateSelect: any) => {
		return (
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={statusValue[value]}
				onChange={updateSelect}
				className="adornment-select"
			>
				<MenuItem value={statusValue.gt}>{`>`}</MenuItem>
				<MenuItem value={statusValue.gte}>{`>=`}</MenuItem>
				<MenuItem value={statusValue["N/A"]}>{`N/A`}</MenuItem>
			</Select>
		);
	};

	const renderField = (dimData: any, type: string, index: number) => {
		let { start, end, startSymbol, endSymbol, startError, endError } = dimData;
		const onChangeMin = (event: any) => {
			const tempData = { ...drawerData };
			tempData[type][index].start = event.target.value;
			onDrawerDataChange(tempData);
		};
		const onChangeMax = (event: any) => {
			const tempData = { ...drawerData };
			tempData[type][index].end = event.target.value;
			onDrawerDataChange(tempData);
		};
		const changeMinSymbol = (event: any) => {
			const tempData = { ...drawerData };
			tempData[type][index].startSymbol = statusCode[event.target.value];
			onDrawerDataChange(tempData);
		};
		const changeMaxSymbol = (event: any) => {
			const tempData = { ...drawerData };
			tempData[type][index].endSymbol = statusCode[event.target.value];
			onDrawerDataChange(tempData);
		};
		startSymbol = start === -9999 ? "N/A" : startSymbol;
		endSymbol = end === 9999 ? "N/A" : endSymbol;
		start = start === -9999 ? "" : start;
		end = end === 9999 ? "" : end;
		return (
			<>
				<div className="d-flex form-field-items">
					<div className="row">
						<div className="col">
							<FormControl>
								<TextField
									variant="outlined"
									size="small"
									className={`adornment-input-section ${
										startError ? "input-error" : ""
									}`}
									placeholder=""
									defaultValue={start}
									onBlur={onChangeMin}
									InputProps={{
										startAdornment: renderMinSelect(
											startSymbol,
											changeMinSymbol
										),
										// endAdornment: (
										// 	<InputAdornment position="end">Min</InputAdornment>
										// ),
									}}
								/>
							</FormControl>
							{startError && (
								<p className="d-flex error-message justify-content-center mb-0 mt-4">
									{startError}
								</p>
							)}
						</div>
						<div className="col">
							<FormControl>
								<TextField
									variant="outlined"
									size="small"
									className={`adornment-input-section ${
										endError ? "input-error" : ""
									}`}
									placeholder=""
									defaultValue={end}
									onBlur={onChangeMax}
									InputProps={{
										startAdornment: renderMaxSelect(endSymbol, changeMaxSymbol),
										// endAdornment: (
										// 	<InputAdornment position="end">Max</InputAdornment>
										// ),
									}}
								/>
							</FormControl>
							{endError && (
								<p className="d-flex error-message justify-content-center mb-0 mt-4">
									{endError}
								</p>
							)}
						</div>
					</div>
					<div className="d-flex align-items-center drawer-add-icon">
						<div className="ml-3">
							{!(["good", "moderate"].includes(type) && index === 0) ? (
								<RemoveCircle
									onClick={() => removeExistingConfig(type, index)}
									className="txt-light"
								/>
							) : (
								<AddCircle
									onClick={() => {
										addNewConfig(type);
									}}
									className="txt-primary"
								/>
							)}
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<Drawer anchor="right" open={open} onClose={onClose}>
			<div className="drawer-content-section">
				{isLoading && <SpinnerLoader />}
				<div>
					<div className="drawer-header d-flex align-items-center justify-content-between">
						<h3 className="drawer-header-heading">{`Add ${drawerData.shortName} Value`}</h3>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</div>
					<div className="drawer-content">
						<div className="d-flex mb-1">
							<label className="form-label">Good</label>
						</div>
						<div className="form-group">
							{drawerData?.good?.map((mapData: any, index: number) =>
								renderField(mapData, "good", index)
							)}
						</div>

						<div className="">
							<div className="d-flex mb-1">
								<label className="form-label">Moderate</label>
							</div>
							<div className="form-group">
								{drawerData?.moderate?.map((mapData: any, index: number) =>
									renderField(mapData, "moderate", index)
								)}
							</div>
						</div>

						<div className="">
							<div className="d-flex mb-1">
								<label className="form-label">Poor</label>
								<div className="d-flex align-items-center drawer-add-icon">
									<div className="ml-3">
										<AddCircle
											onClick={() => {
												addNewConfig("poor");
											}}
											className="txt-primary"
										/>
									</div>
								</div>
							</div>
							<div className="form-group">
								{drawerData?.poor?.map((mapData: any, index: number) =>
									renderField(mapData, "poor", index)
								)}
							</div>
						</div>
						<div className="">
							<div className="d-flex mb-1">
								<label className="form-label">Very Poor</label>
								<div className="d-flex align-items-center drawer-add-icon">
									<div className="ml-3">
										<AddCircle
											onClick={() => {
												addNewConfig("veryPoor");
											}}
											className="txt-primary"
										/>
									</div>
								</div>
							</div>
							<div className="form-group">
								{drawerData?.veryPoor?.map((mapData: any, index: number) =>
									renderField(mapData, "veryPoor", index)
								)}
							</div>
						</div>
						<div className="">
							<div className="d-flex mb-1">
								<label className="form-label">Hazardous</label>
								<div className="d-flex align-items-center drawer-add-icon">
									<div className="ml-3">
										<AddCircle
											onClick={() => {
												addNewConfig("danger");
											}}
											className="txt-primary"
										/>
									</div>
								</div>
							</div>
							<div className="form-group">
								{drawerData?.danger?.map((mapData: any, index: number) =>
									renderField(mapData, "danger", index)
								)}
							</div>
						</div>
						<div className="form-group">
							<label className="form-label">Remediation device</label>
							<FormControl>
								<AntSwitch
									checked={drawerData.enable}
									onChange={handleStatusChange}
									name="checkedC"
								/>
							</FormControl>
						</div>

						{!["temp_current_f", "humidity_value"].includes(drawerData.code) ? (
							<>
								<div className="form-group">
									<label className="form-label">Phone Alert Threshold</label>
									<FormControl>
										<TextField
											variant="outlined"
											size="small"
											className="input-medium"
											defaultValue={
												drawerData.phoneAlertThreshold !== 0
													? drawerData.phoneAlertThreshold
													: ""
											}
											onBlur={onPhoneThresholdChange}
											placeholder=""
										/>
									</FormControl>
									{phoneThresholdError?.length > 0 && (
										<p className="d-flex error-message justify-content-center mb-0 mt-4">
											{phoneThresholdError}
										</p>
									)}
									<p className="desc-medium mt-3">
										Phone alert will be sent when the value of the air quality
										dimension goes beyond the value set here
									</p>
								</div>
								<div className="form-group">
									<label className="form-label">Email Alert Threshold</label>
									<FormControl>
										<TextField
											variant="outlined"
											defaultValue={
												drawerData.emailAlertThreshold !== 0
													? drawerData.emailAlertThreshold
													: ""
											}
											size="small"
											onBlur={onEmailThresholdChange}
											className="input-medium"
											placeholder=""
										/>
									</FormControl>
									{emailThresholdError?.length > 0 && (
										<p className="d-flex error-message mb-0 mt-4">
											{emailThresholdError}
										</p>
									)}
									<p className="desc-medium mt-3">
										Mail alert will be sent when the value of the air quality
										dimension goes beyond the value set here
									</p>
								</div>
							</>
						) : (
							<>
								<div className="form-group">
									<label className="form-label">Phone Alert Threshold</label>
									<div className="d-flex">
										<div className="mr-2">
											<FormControl>
												<TextField
													variant="outlined"
													size="small"
													className="input-medium"
													defaultValue={
														drawerData.phoneAlertThreshold !== 0
															? drawerData.phoneAlertThreshold
															: ""
													}
													onBlur={onPhoneThresholdChange}
													placeholder=""
												/>
											</FormControl>
											<label className="form-label">Upper</label>
										</div>
										<div>
											<FormControl>
												<TextField
													variant="outlined"
													size="small"
													className="input-medium"
													defaultValue={
														drawerData.phoneAlertMinThreshold !== 0
															? drawerData.phoneAlertMinThreshold
															: ""
													}
													onBlur={onPhoneMinThresholdChange}
													placeholder=""
												/>
											</FormControl>
											<label className="form-label">Lower</label>
										</div>
									</div>

									{phoneThresholdError?.length > 0 && (
										<p className="d-flex error-message justify-content-center mb-0 mt-4">
											{phoneThresholdError}
										</p>
									)}
									{phoneThresholdMinError?.length > 0 && (
										<p className="d-flex error-message justify-content-center mb-0 mt-4">
											{phoneThresholdMinError}
										</p>
									)}
									<p className="desc-medium mt-3">
										Phone alert will be sent when the value of the air quality
										dimension goes beyond the value set here
									</p>
								</div>
								<div className="form-group">
									<label className="form-label">Email Alert Threshold</label>
									<div className="d-flex">
										<div className="mr-2">
											<FormControl>
												<TextField
													variant="outlined"
													defaultValue={
														drawerData.emailAlertThreshold !== 0
															? drawerData.emailAlertThreshold
															: ""
													}
													size="small"
													onBlur={onEmailThresholdChange}
													className="input-medium"
													placeholder=""
												/>
											</FormControl>
											<label className="form-label">Upper</label>
										</div>
										<div>
											<FormControl>
												<TextField
													variant="outlined"
													defaultValue={
														drawerData.emailAlertMinThreshold !== 0
															? drawerData.emailAlertMinThreshold
															: ""
													}
													size="small"
													onBlur={onEmailMinThresholdChange}
													className="input-medium"
													placeholder=""
												/>
											</FormControl>
											<label className="form-label">Lower</label>
										</div>
									</div>
									{emailThresholdError?.length > 0 && (
										<p className="d-flex error-message mb-0 mt-4">
											{emailThresholdError}
										</p>
									)}
									{emailThresholdMinError?.length > 0 && (
										<p className="d-flex error-message mb-0 mt-4">
											{emailThresholdMinError}
										</p>
									)}
									<p className="desc-medium mt-3">
										Mail alert will be sent when the value of the air quality
										dimension goes beyond the value set here
									</p>
								</div>
							</>
						)}
					</div>
					<div className="drawer-footer d-flex justify-content-end">
						<Button
							className="mr-2"
							variant="outlined"
							color="default"
							onClick={onClose}
							disabled={isLoading}
						>
							Cancel
						</Button>
						<Button
							onClick={onSave}
							className="save-btn"
							variant="contained"
							disabled={isLoading}
						>
							Update
						</Button>
					</div>
				</div>
			</div>
		</Drawer>
	);
};
