import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";

import { theme } from "../../theme/CustomTheme";

export const FloorPlanReadings = ({
	deviceData,
	reading,
	isLoading,
	floorId,
	handlePopoverClose,
	onRemoveDevice,
}: any) => {
	const [colorStatus] = useState<any>({
		Good: theme.good,
		Moderate: theme.moderate,
		Poor: theme.poor,
		"Very Poor": theme.veryPoor,
		Hazardous: theme.hezardous,
	});
	const [readingProperties] = useState<any>({
		co2_value: {
			src: "/images/co2-large.svg",
			value: "CO2",
			type: "ppm",
		},
		ch2o_value: {
			src: "/images/formaldehyde-large.svg",
			value: "Formaldehyde",
			type: "ppb",
		},
		voc_value: {
			src: "/images/tvoc-large.svg",
			value: "TVOC",
			type: "ppb",
		},
		pm25_value: {
			src: "/images/pm2.5-large.svg",
			value: "PM2.5",
			type: "µg/㎥",
		},
		temp_current_f: {
			src: "/images/temperature-large.svg",
			value: "Temperature",
			type: "F",
		},
		pm10: {
			src: "/images/pm10-large.svg",
			value: "PM10",
			type: "µg/㎥",
		},
		pm1: {
			value: "PM1.0",
			src: "/images/pm1.0-large.svg",
			type: "µg/㎥",
		},
		humidity_value: {
			value: "Humidity",
			src: "/images/humidity-large.svg",
			type: "%",
		},
	});
	const [testOnData] = useState<any>({
		gateway: "Active",
		remediation: "ON",
		interops: "ON",
	});
	const [testOffData] = useState<any>({
		gateway: "Inactive",
		remediation: "OFF",
		interops: "OFF",
	});

	const onClickDelete = () => {
		handlePopoverClose();
		onRemoveDevice(reading?.deviceData?._id || deviceData.deviceId, floorId);
	};

	const renderDeviceData = () => {
		switch (reading?.deviceData?.deviceType) {
			case "monitor":
				return (
					<>
						<div className="align-items-center d-flex flex-column py-2">
							<div className="d-flex align-item-center">
								<h5 className="d-flex justify-content-center floor-plan-readings-text mb-0 reading-name">
									{reading?.deviceData?.deviceName || "Floor"}
								</h5>
								<DeleteIcon
									onClick={onClickDelete}
									className="align-items-center d-flex ml-2 cursor-pointer"
								/>
							</div>
							<p className="reading-details floor-plan-readings-text mt-1 mb-1">{`Status: ${
								reading?.deviceData?.online ? "Active" : "Inactive"
							}`}</p>
							<p className="floor-plan-readings-text">{`Location: ${reading?.deviceData?.location[0]?.name}`}</p>
						</div>
						{reading?.sensorData?.length > 0 ? (
							<>
								{reading?.sensorData?.map((mapData: any, index: number) => {
									return (
										<div
											key={`${mapData?.readings?.code}-${index}`}
											className="reading-details-card w-100 d-flex align-items-center justify-content-between"
										>
											<div className="d-flex align-items-center">
												<img
													className="reading-img"
													src={readingProperties[mapData?.readings?.code].src}
													alt={mapData?.readings?.code}
												/>
												<h5 className="reading-name floor-plan-readings-text ml-2 mr-2">
													{readingProperties[mapData?.readings?.code].value}
												</h5>
											</div>
											<h5
												style={{
													color: colorStatus[mapData.status] || theme.noData,
												}}
												className="reading-details floor-plan-readings-text"
											>
												{`${mapData?.readings?.value} ${
													readingProperties[mapData?.readings?.code].type
												}`}
											</h5>
										</div>
									);
								})}
							</>
						) : (
							<div className="reading-details-card text-center">
								{reading?.deviceData?.subType !== "ionMonitor" && (
									<h5 className="floor-plan-readings-text">No Record Found</h5>
								)}
							</div>
						)}
					</>
				);

			case "gateway":
				return (
					<div className="reading-details-card w-100 d-flex">
						<div className="d-flex">
							<img
								className="reading-img"
								src={reading?.deviceData?.image}
								alt=""
							/>
						</div>
						<div className="ml-3">
							<div className="align-items-center d-flex">
								<h5 className="reading-name mb-0 floor-plan-readings-text">
									{reading?.deviceData?.deviceName}
								</h5>
								<DeleteIcon
									onClick={onClickDelete}
									className="align-items-center d-flex ml-2 cursor-pointer"
								/>
							</div>
							<p className="reading-details floor-plan-readings-text mb-2 mt-2">{`Status: ${
								reading?.deviceData?.online
									? testOnData[reading?.deviceData?.deviceType]
									: testOffData[reading?.deviceData?.deviceType]
							}`}</p>
							<p className="floor-plan-readings-text">{`Location: ${reading?.deviceData?.location[0]?.name}`}</p>
						</div>
					</div>
				);
			case "remediation":
			case "interops":
				return (
					<div className="reading-details-card w-100 d-flex">
						<div className="d-flex">
							<img
								className="reading-img"
								// src={
								// 	deviceData?.deviceType === "gateway"
								// 		? "https://iqintel-common.s3.amazonaws.com/devices/10.png"
								// 		: "https://iqintel-common.s3.amazonaws.com/devices/3.png"
								// }
								src={reading?.deviceData?.image}
								alt=""
							/>
						</div>
						<div className="ml-3">
							<div className="align-items-center d-flex">
								<h5 className="reading-name mb-0 floor-plan-readings-text">
									{reading?.deviceData?.deviceName}
								</h5>
								<DeleteIcon
									onClick={onClickDelete}
									className="align-items-center d-flex ml-2 cursor-pointer"
								/>
							</div>
							<p className="reading-details floor-plan-readings-text mb-2 mt-2">{`Status: ${
								reading?.deviceData?.status
									? testOnData[reading?.deviceData?.deviceType]
									: testOffData[reading?.deviceData?.deviceType]
							}`}</p>
							<p className="reading-details floor-plan-readings-text mb-0 mt-1">{`Location: ${reading?.deviceData?.location?.map(
								(loc: any) => loc.name
							)}`}</p>
						</div>
					</div>
				);
			default:
				return (
					<div className="align-items-center d-flex flex-column py-2">
						<div className="d-flex align-item-center">
							<h5 className="d-flex justify-content-center floor-plan-readings-text mb-0 reading-name">
								{reading?.deviceData?.deviceName ||
									"Some issues with this device"}
							</h5>
							<DeleteIcon
								onClick={onClickDelete}
								className="align-items-center d-flex ml-2 cursor-pointer"
							/>
						</div>
					</div>
				);
		}
	};

	return (
		<div className="pl-2 pr-2 pt-2 w-100 floor-plan-readings">
			{isLoading ? (
				<div className="m-3">
					<h5 className="reading-name floor-plan-readings-text">Loading...</h5>
				</div>
			) : (
				renderDeviceData()
			)}
		</div>
	);
};
