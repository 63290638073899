import React, { Fragment, memo } from "react";
import { CanvasChat } from "../../components/DashboardChart/Chart";

interface ChartProps {
	chartTitle: string;
	chartData: any;
}
const Chart: React.FC<ChartProps> = ({ chartTitle, chartData }) => (
	<Fragment>
		{chartData?.groupDataValues ? (
			<CanvasChat title={chartTitle} graphData={chartData} />
		) : (
			<div className="text-center">
				Information not available for this location.
			</div>
		)}
		<h6 className="mt-2">{chartTitle}</h6>
	</Fragment>
);

export default memo(Chart);
