import { SET_ASSETS, SET_ASSET } from "./actionTypes";

export const setAsset = (asset: any) => {
	return {
		type: SET_ASSET,
		payload: asset,
	};
};

export const setAssets = (assets: any) => {
	return {
		type: SET_ASSETS,
		payload: assets,
	};
};
