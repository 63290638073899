import "./style.scoped.scss";
import { Link } from "@material-ui/core";

const IAQService: React.FC = () => {
	return (
		<div>
			<p>Welcome</p>
		</div>
	);
};
export default IAQService;
