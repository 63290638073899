import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import download from "downloadjs";
import html2canvas from "html2canvas";

import "./QRCodeDownload.scoped.scss";

interface QACodeDownloadData {
	handleClose: () => void;
	buildingData: any;
	open: boolean;
}

export const QRCodeDownloadModal: React.FC<QACodeDownloadData> = ({
	buildingData,
	handleClose,
	open,
}) => {
	const [isDownloadWithLogo, setIsDownloadWithLogo] = useState<string>("true");

	const onChange = (event: any) => {
		setIsDownloadWithLogo(event.target.value);
	};

	// useEffect(() => {
	// 	if (isDownloadWithLogo === "true") {
	// 		const ctx: any = (document?.getElementById("c") as any)?.getContext("2d");
	// 		const background = new Image();
	// 		const photo = new Image();
	// 		photo.crossOrigin = "anonymous";
	// 		background.crossOrigin = "anonymous";

	// 		background.onload = function () {
	// 			ctx.drawImage(background, 0, 0, 200, 200);
	// 			photo.src = buildingData?.qrCode;
	// 		};

	// 		photo.onload = function () {
	// 			ctx.drawImage(photo, 0, 0, 50, 50);
	// 		};
	// 		background.src = bgImage;
	// 	}
	// }, [isDownloadWithLogo]);

	const bgImage: string = `https://iqintel-dev-files.s3.amazonaws.com/qrCode/building_qr_code_template.png`;

	const downloadPng = async () => {
		let imgUrl = buildingData?.qrCode;
		if (isDownloadWithLogo === "true") {
			imgUrl = buildingData?.qrCodeWithLogo;
		}
		fetch(imgUrl, {
			method: "get",
			mode: "cors",
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "image/png",
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				let blobUrl = window.URL.createObjectURL(blob);
				var a: any = document.createElement("a");
				a.download = `qr_${buildingData?._id}.png`;
				a.href = blobUrl;
				document.body.appendChild(a);
				a.click();
				a.remove();
			})
			.catch((e) => console.error(e));
	};

	const PrintWithButton = () => {
		return (
			<Button type="button" variant="primary" onClick={downloadPng}>
				Download
			</Button>
		);
	};

	return (
		<Modal id="modalParent" show={open} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Download QR Code</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="mt-2 d-flex align-items-center">
					<div className="d-flex align-items-center mr-2">
						<input
							type="radio"
							onChange={onChange}
							id="p-plan"
							name="isWithLogo"
							className="mr-1"
							value="true"
							checked={isDownloadWithLogo === "true"}
						/>
						<p className="mb-0">With Logo</p>
					</div>
					<div className="d-flex align-items-center">
						<input
							type="radio"
							onChange={onChange}
							id="f-plan"
							name="isWithLogo"
							className="mr-1 ml-2"
							value="false"
							checked={isDownloadWithLogo === "false"}
						/>
						<p className="mb-0">Without Logo</p>
					</div>
				</div>

				{/* {isDownloadWithLogo === "false" && (
					<div className="img-template" id="qrCodeWithoutLogo">
						<img src={buildingData?.qrCode} className="template"></img>
					</div>
				)}

				{isDownloadWithLogo === "true" && (
					<canvas id="c" style={{ width: "350px", height: "350px" }} />
				)} */}

				<div className="d-flex justify-content-center mt-2" id="qrCodeParent">
					{isDownloadWithLogo === "false" && (
						<div
							className="img-template qrcode-img-container d-flex align-items-center justify-content-center"
							id="qrCodeWithoutLogo"
						>
							<img src={buildingData?.qrCode} className="template"></img>
						</div>
					)}
					{isDownloadWithLogo === "true" && (
						<div className="img-template qrcode-img-container w-100 h-100 d-flex align-items-center justify-content-center">
							<img
								id="lgo"
								src={buildingData?.qrCodeWithLogo}
								alt="qr code building"
								className="qrcode qr-code-generate"
							/>
						</div>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<PrintWithButton></PrintWithButton>
			</Modal.Footer>
		</Modal>
	);
};
