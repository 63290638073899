import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { FloorPlanReadings } from "./FloorPlanReadings";
import { getDeviceReadingApi } from "../../api/FloorController";
import { theme } from "../../theme/CustomTheme";
import { RootState } from "../../redux/reducers";

import "./floorplan.scss";
import { AnyAction } from "redux";

interface Props {
	floorData: any;
	deviceList: any;
	onEditFloor: (data: any) => void;
	deviceStatus: any;
	onRemoveDevice: any;
	isActiveDevice: any;
}

const ClickableSVG = styled.div<any>`
	position: relative;
	width: 100%;
	height: 100%;
	background-size: contain;
	background-image: url(${(props: any) => props.imageUrl});
	background-repeat: no-repeat;
	background-position: center;
`;

export const FloorPlan = ({
	floorData,
	deviceList,
	onEditFloor,
	onRemoveDevice,
	deviceStatus,
	isActiveDevice,
}: Props) => {
	const asset = useSelector((state: RootState) => state.asset);

	const [redinAnchorEl, setRedinAnchorEl] = React.useState<HTMLElement | null>(
		null
	);
	const [anchorEl, setAnchorEl] = useState(null);
	const [lastCoordinate, setLastCoordinate] = useState<number[]>([]);
	const [toolTipLoading, setToolTipLoading] = useState<boolean>(false);
	const [reading, setReading] = useState<any>({ readings: [] });
	const [colorObj] = useState<any>({
		gateway: { color: "#808080", styleClass: "device-square" },
		remediation: { color: "#A020F0", styleClass: "device-rectangle" },
		monitor: { color: "#1880fd", styleClass: "device-circle" },
		Interops: { color: "#cb90f0", styleClass: "device-rectangle" },
		interops: { color: "#cb90f0", styleClass: "device-rectangle" },
	});
	const [colorStatus] = useState<any>({
		Good: theme.good,
		Moderate: theme.moderate,
		Poor: theme.poor,
		"Very Poor": theme.veryPoor,
		Hazardous: theme.hezardous,
	});
	const [contextMenu, setContextMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const open = Boolean(redinAnchorEl);

	const getClickCoords = (event: any) => {
		const e = event.target;
		const dim = e.getBoundingClientRect();
		const x = event.clientX - dim.left;
		const y = event.clientY - dim.top;
		return [x, y];
	};

	const handleClick = (event: any) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};

	const onRightClick = (event: any) => {
		if (!redinAnchorEl) {
			setContextMenu(
				contextMenu === null
					? {
							mouseX: event.clientX - 2,
							mouseY: event.clientY - 4,
					  }
					: null
			);
			addCircle(event);
			handleClick(event);
		}
	};

	const onClickMobile = (e: any) => {
		if (window.innerWidth < 1025) {
			onRightClick(e);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
		setContextMenu(null);
	};

	const getDeviceReading = async (deviceData: AnyAction) => {
		setToolTipLoading(true);
		setReading({});
		const response = await getDeviceReadingApi(
			deviceData.deviceId,
			deviceData.deviceType,
			asset._id
		);
		if (response?.responseObj) {
			setReading(response?.responseObj);
		}
		setToolTipLoading(false);
	};

	const handlePopoverOpen = (
		event: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		setRedinAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setRedinAnchorEl(null);
	};

	const newCircle = (deviceData: any) => {
		const { deviceType, x, y } = deviceData;
		const background =
			colorStatus[deviceStatus[deviceData?.deviceId]?.status] || theme.noData;

		return (
			<>
				<Popover
					id={`registerTip-${deviceData?.deviceId}`}
					open={open}
					anchorEl={redinAnchorEl}
					onClose={handlePopoverClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<FloorPlanReadings
						deviceData={deviceData}
						onRemoveDevice={onRemoveDevice}
						reading={reading}
						handlePopoverClose={handlePopoverClose}
						isLoading={toolTipLoading}
						floorId={floorData._id}
					/>
				</Popover>
				<div
					className="device-card"
					style={{ transform: `translate(${x}px, ${y}px)` }}
				>
					<Typography
						aria-describedby={
							open ? `registerTip-${deviceData?.deviceId}` : undefined
						}
						className={`device-section cursor-pointer ${colorObj[deviceType].styleClass}`}
						style={{
							background,
						}}
						onClick={(event) => {
							event?.stopPropagation();
							getDeviceReading(deviceData);
							handlePopoverOpen(event);
						}}
					/>
				</div>
			</>
		);
	};

	const addDevice = ({ deviceType, _id, deviceName, status }: any) => {
		const [x, y] = lastCoordinate;
		onEditFloor({
			id: floorData._id,
			coordinates: {
				x,
				y,
				deviceId: _id,
				deviceName,
				deviceType,
				status,
			},
		});
	};

	const addCircle = (event: any) => {
		setLastCoordinate(getClickCoords(event));
	};

	return (
		<Fragment>
			<div
				aria-controls="customized-menu"
				aria-haspopup="true"
				style={{
					width: `${get(floorData, "width", "670")}px`,
					height: `${get(floorData, "height", "450")}px`,
				}}
			>
				<ClickableSVG
					imageUrl={floorData.imageUrl}
					onContextMenu={onRightClick}
					onClick={onClickMobile}
				>
					{floorData.coordinates.map((mapData: any) => {
						return newCircle(mapData);
					})}
				</ClickableSVG>
			</div>
			<Menu
				id="customized-menu"
				anchorEl={anchorEl}
				anchorReference="anchorPosition"
				anchorPosition={
					contextMenu !== null
						? { top: contextMenu.mouseY, left: contextMenu.mouseX }
						: undefined
				}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{deviceList.length > 0 &&
					deviceList.map((mapData: any) => (
						<MenuItem
							onClick={() => {
								addDevice(mapData);
								handleClose();
							}}
							key={mapData.deviceName}
						>
							{mapData.deviceName}
						</MenuItem>
					))}
				{deviceList.length === 0 && <MenuItem>No device found</MenuItem>}
			</Menu>
		</Fragment>
	);
};
