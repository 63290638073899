import store from "../images/store.svg";
import housePerson from "../images/house-user.svg";
import cloudWind from "../images/cloud-wind.svg";
import coronavirus from "../images/coronavirus.svg";

export const CommonEnum = {
	StatusNA: "N/A",
};

export const AutopilotStatus: any = {
	0: "Initiated",
	1: "Inprogress",
	2: "Completed",
	3: "Failed",
};

export const consultationTypes = [
	{
		name: "IAQ Consultation",
		img: "https://iqintel-dev-files.s3.amazonaws.com/consultation-category/Consultation+page+images/IAQ.png",
	},
	{
		name: "Mold Remediation",
		img: "https://iqintel-dev-files.s3.amazonaws.com/consultation-category/Consultation+page+images/Mold.png",
	},
	{
		name: "HVAC Consultation",
		img: "https://iqintel-dev-files.s3.amazonaws.com/consultation-category/Consultation+page+images/HVAC.png",
	},
	{
		name: "Microbial Consultation",
		img: "https://iqintel-dev-files.s3.amazonaws.com/consultation-category/Consultation+page+images/Microbes.png",
	},
];

export const consultationTypesMobile = [
	{
		name: "IAQ Consultation",
		img: store,
		bgClass: "bg-green-light-1",
	},
	{
		name: "Mold Remediation",
		img: housePerson,
		bgClass: "bg-green-light-2",
	},
	{
		name: "HVAC Consultation",
		img: cloudWind,
		bgClass: "bg-red-light-1",
	},
	{
		name: "Microbial Consultation",
		img: coronavirus,
		bgClass: "bg-green-light-3",
	},
];
