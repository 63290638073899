import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory, withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import get from "lodash/get";

import {
	getContractorAssets,
	getAllAssetBuildings,
} from "../../api/AssetController";
import { confirmManageCustomer, loginApi } from "../../api/UserController";
import { SpinnerLoader } from "../../common/Global.Style";
import { setToken, setUser } from "../../redux/actions/userActions";
import { setAsset, setAssets } from "../../redux/actions/assetActions";
import Shared from "../../common/Shared";
import { URL } from "../../components/Navbar/routes";
import { setBuilding, setBuildings } from "../../redux/actions/buildingActions";
import { UserRoles } from "../../enums/UserRoles";
import { RootState } from "../../redux/reducers";

import "./Login.scoped.scss";
import ConfirmManageCustomer from "../../components/ConfirmManageCustomer/ConfirmManageCustomer";

const Login: React.FC = () => {
	const [errorText, setErrorText] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmManageUserDialog, setShowConfirmManageUserDialog] =
		useState<boolean>(false);

	const userData: any = useSelector((state: RootState) => state.user);

	const history = useHistory();
	const dispatch = useDispatch();

	const navigate = () => {
		// for iaq guard after login we need to show building page
		history.push(
			Shared.title === "IAQ Guard"
				? URL.DASHBOARD_BUILDINGS
				: URL.DASHBOARD_BUILDINGS
		);
	};

	const closeConfirmManageUser = () => setShowConfirmManageUserDialog(false);

	const confirmAndCloseConfirmManageUser = async (isConfirm: boolean) => {
		const response = await confirmManageCustomer(isConfirm);

		setIsLoading(true);

		if (response.responseObj) {
			await getUserData(response);
			setIsLoading(false);
			toast.success("User preference updated");
		} else {
			setIsLoading(false);
			toast.error("An error occurred, please contact care@getiqi.com");
		}
		navigate();
	};

	useEffect(() => {
		if (userData.token) {
			if (Shared.title === "IAQ Guard") {
				history.push(URL.DASHBOARD_BUILDINGS);
			} else {
				history.push(URL.DEVICE_MANAGEMENT);
			}
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	}, []);

	const userLogin = async (value: object) => {
		setIsLoading(true);
		const response: any = await loginApi({ ...value, isAssessment: false });
		if (response.responseObj) {
			await getUserData(response);
		} else {
			setErrorText(response.error);
		}
		setIsLoading(false);
	};

	const proceedWithUserResponse = async () => {
		const resContractor: any = await getContractorAssets();
		if (resContractor.responseObj) {
			if (resContractor.responseObj?.length > 0) {
				dispatch(setAssets(resContractor?.responseObj));
				dispatch(setAsset(resContractor?.responseObj[0]));
			}

			if (get(resContractor, "responseObj[0].buildings.length", 0)) {
				dispatch(
					setBuildings(get(resContractor, "responseObj[0].buildings", []))
				);
				dispatch(
					setBuilding(get(resContractor, "responseObj[0].buildings[0]", {}))
				);
			}
		} else {
			toast.error(resContractor.error);
		}
	};

	const getUserData = async (response: any) => {
		let isContractor = false;
		setErrorText("");
		const apiUserData = response?.responseObj?.userData;
		dispatch(setToken(response.responseObj.token));
		dispatch(setUser(response.responseObj.userData));

		if (apiUserData.role === "contractor") {
			isContractor = true;
			setIsLoading(false);
			await proceedWithUserResponse();
			navigate();
		} else {
			const res: any = await getAllAssetBuildings();
			if (res.responseObj?.length > 0) {
				res.responseObj.forEach((mapData: any) => {
					if (mapData.permissions.includes("write")) {
						isContractor = true;
					}
				});
				dispatch(setAssets(res.responseObj));
				dispatch(setAsset(res.responseObj[0]));
				if (res.responseObj[0].buildings?.length > 0) {
					dispatch(setBuildings(res.responseObj[0].buildings));
					dispatch(setBuilding(res.responseObj[0].buildings[0]));
				}
			}
			navigate();
		}
	};

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email("Invalid email address")
				.required("Email is required"),
			password: Yup.string().required("Password is required"),
		}),
		onSubmit: (values) => {
			userLogin(values);
		},
	});

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};

	return (
		<>
			<div className="container-fluid">
				<div
					className="row h-50 bac"
					style={{ backgroundImage: `url(${Shared.loginBgImage})` }}
				></div>
				<div className="login flex-column">
					{Shared.title === "iQi Cloud" && (
						<div className="mb-3">
							<img
								className="logo mb-3 m-auto d-table"
								src={Shared.commonLogoUrl}
								alt="login-logo"
							/>
						</div>
					)}
					<div className="bg-white p-3 login-block">
						<div className="mb-2">
							<img className="img" src={Shared.loginImage} alt="login-logo" />
							<form onSubmit={formik.handleSubmit}>
								<div className="mb-3 form-group">
									<label className="emaill">Email</label>
									<input
										type="email"
										id="email"
										name="email"
										className="email bg-light border-0 mt-2"
										placeholder="Type email"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.email}
									/>
									{formik.touched.email && formik.errors.email ? (
										<div className="text-danger">{formik.errors.email}</div>
									) : null}
								</div>
								<div className="mb-3 form-group">
									<label className="pass mb-2">Password</label>
									<div className="password">
										<input
											type={showPassword ? "text" : "password"}
											id="password"
											name="password"
											className="bg-light border-0"
											placeholder="Type password"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.password}
										/>
										<IconButton
											className="icon"
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</div>
									{formik.touched.password && formik.errors.password ? (
										<div className="text-danger">{formik.errors.password}</div>
									) : null}
								</div>
								<p className="text-danger"> {errorText ? errorText : ""} </p>
								<div className="mb-3">
									<Link to={URL.FORGOT_PASSWORD} className="mt-2 forg">
										Forgot Password?
									</Link>
								</div>
								<button type="submit" className="login-btn	 mb-3">
									Log In
								</button>
							</form>
							<div className="signup border-top pt-3">
								<p className="mb-1 desc-xlarge font-bold">
									Don't have a login?
								</p>
								<p className="mb-4 desc-large font-600 font-tertiary">
									Create it here
								</p>
								<button
									onClick={() => history.push(URL.SIGN_UP)}
									type="submit"
									className="btn btn-primary-outline w-100 mb-3"
								>
									Sign Up
								</button>
							</div>
						</div>
					</div>
				</div>
				{showConfirmManageUserDialog && (
					<ConfirmManageCustomer
						dialogTitle="Confirmation"
						message="Do you want your account to be managed by IAQ professionals?"
						positiveBtnLabel="Submit"
						onPositiveBtnClicked={confirmAndCloseConfirmManageUser}
						onCloseIconClicked={closeConfirmManageUser}
					/>
				)}
				{isLoading && <SpinnerLoader />}
			</div>
		</>
	);
};
export default withRouter(Login);
