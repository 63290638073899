import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";

import "./style.scoped.scss";
interface MobileBackProps {
	heading?: any;
	redirect?: any;
	subheading?: any;
}
const MobileBack: React.FC<MobileBackProps> = ({
	heading,
	redirect,
	subheading,
}) => {
	const history = useHistory();
	const goBack = () => {
		if (redirect) history.push(redirect);
		else history.goBack();
	};
	return (
		<div className="d-flex align-items-center" onClick={() => goBack()}>
			<ArrowBackIcon className="i-16 txt-primary font-600" />
			<p className="desc-large font-text-4 font-600 ls-024 mb-0 ml-1">
				{heading ? heading : <>Back</>}
			</p>
			{/* <span>
				{subheading && (
					<p className="desc-large font-600 ls-024 mb-0 ml-1">{subheading}</p>
				)}
			</span> */}
		</div>
	);
};
export default MobileBack;
