import "./PaymentPendingInfo.scoped.scss";

const PaymentPendingInfo: React.FC<{ name: string }> = (props: {
	name: string;
}) => {
	const msg = `The customer account is suspended due to payment failure.
		Please contact your admin or email to care@getiqi.com for assistance`;
	return (
		<div className="payment-info-container">
			<p className="mb-0 m-3 pt-3">{msg}</p>
		</div>
	);
};

export default PaymentPendingInfo;
