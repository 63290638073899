import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "../More/More.scopped.scss";
import { IMenuItem } from "../../../common/Interface";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";

interface SubMenuProps {
	item: IMenuItem;
}
const MoreMenus: React.FC<SubMenuProps> = ({ item }) => {
	const { icon, title, description, viewText, viewLink, path } = item;

	return (
		<NavLink className="flex-1" to={path ? path : "/"}>
			<div className="more-list d-flex align-items-center justify-content-between p-3">
				<div className="d-flex align-items-center flex-1 mr-2">
					<div className="menu-icon rounded-circle d-flex align-items-center justify-content-center mr-3 bg-grey-1">
						{icon}
					</div>
					<div className="flex-1 text-truncate">
						<p className="mb-1 text-truncate desc-large font-text-1 font-500">
							{title}
						</p>
						<p className="mb-0 text-truncate desc-small font-text-2 text-wrap">
							{description}
						</p>
					</div>
				</div>
				<ArrowForwardIcon className="font-grey-light-2 i-16" />
			</div>
		</NavLink>
	);
};
export default MoreMenus;
