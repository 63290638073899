import React, { useState, memo, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import Shared from "../../../common/Shared";

import "./Certification.scoped.scss";

interface CertificationProps {
	certificationData: any;
}

const Certification: React.FC<CertificationProps> = ({ certificationData }) => {
	const [certificationInfo] = useState<{
		Fitwell: string;
		EPA: string;
		RESET: string;
		WELL: string;
		ASHRAE: string;
		NORMI: string;
	}>({
		Fitwell:
			"As per Fitwell recommendation of well being and safety in Indoors, it is good to have an Air Quality monitor(s) and HEPA air purifier(s) and UV sterilization device(s) installed in the building.",
		EPA: "As per EPA standards Indoor Air Quality is good when PM2.5 is below 35ug/m3 and humidity is between 30%-60%.",
		RESET: `	
    As per RESET standards Indoor Air Quality is good when PM2.5 is below 35ug/m3 and CO2 is below 1000 ppm and TVOC is below 500 ppb in the building.`,
		WELL: "As per WELL standards Indoor Air Quality is good when PM2.5 is below 15ug/m3 and PM10 is below 50ug/m3 and CO2 is below 800 ppm and TVOC is below 500 ppb and Formaldehyde is below 27 ppb and Humidity is between 30% - 50% in the building.",
		ASHRAE: `	
    As per ASHRAE standards Indoor Air Quality is good when CO2 is below 1000 ppm and temperature is between 68 F to 82 F and humidity is between 30% - 65% in the building.`,
		NORMI:
			"As per NORMI standards Indoor Air Quality is good when PM2.5 & PM1.0 is below 35ug/m3 and PM10 is below 150ug/m3 and CO2 is below 1600 ppm and TVOC is below 500 ppb and Formaldehyde is below 80 ppb and Humidity is between 30% - 60% and Temperature is between 65F - 82F in the building.",
	});

	const [exposureGraphColorCodes] = useState<any>(
		Shared.exposureGraphColorCodes
	);
	const [certificationKeys, updateCertificationKey] = useState([
		"EPA",
		"RESET",
		"WELL",
		"ASHRAE",
	]);

	useEffect(() => {
		if (Shared.title === "iQi Cloud") {
			updateCertificationKey([...certificationKeys, "NORMI"]);
		}
	}, []);

	return (
		<div className="m-auto">
			<div className="box6 bg-white p-3 mt-3 w-100 certification-wrap m-l-0 m-p-0">
				<div>
					<h5 className="text-center" style={{ fontSize: "1.1rem" }}>
						Indoor Air Quality Standards Maintained
					</h5>
				</div>
				<div className=" certificate-block">
					<div className="d-flex justify-content-center mt-3 flex-wrap">
						{!isEmpty(certificationData) &&
							certificationKeys.map((keyValue: any, index: number) => {
								const goodPercent = get(
									certificationData,
									`${keyValue}.goodPercent`
								);
								const moderatePercent = get(
									certificationData,
									`${keyValue}.moderatePercent`
								);
								return (
									<div
										className={`qulity${
											index + 1
										} pt-2 ml-3 mb-3 justify-content-center certification certificate`}
									>
										<div className={`qu m-auto justify-content-center mt-4`}>
											<img
												className="certImg"
												src={get(certificationData, `${keyValue}.logo`)}
												alt=""
											/>
										</div>
										<div className="certification-content">
											<p className="px-3">
												{get(certificationInfo, keyValue, "")}
											</p>
										</div>
										<div className="d-flex pl-4 pr-4 mt-3">
											<div
												style={{ backgroundColor: "#e5e5e5" }}
												className="d-flex w-100"
											>
												<div
													style={{
														width: `${goodPercent}%`,
														height: "25px",
														backgroundColor: exposureGraphColorCodes.Good,
													}}
													className="d-flex justify-content-center color-while"
												/>
												<div
													style={{
														width: `${moderatePercent}%`,
														height: "25px",
														backgroundColor: exposureGraphColorCodes.Moderate,
													}}
													className="d-flex justify-content-center color-while"
												/>
											</div>
										</div>
										<div className="d-flex mb-2 mt-1 pl-4 pr-4">
											<div className="d-flex w-100">
												{[0, "0"].includes(goodPercent) &&
													[0, "0"].includes(moderatePercent) && (
														<div className="d-flex w-100 justify-content-center cert-percentage">
															<p className="mb-0">N/A </p>
														</div>
													)}
												{![0, "0"].includes(goodPercent) && (
													<div
														style={{
															width: `${goodPercent}%`,
															minWidth: "50px",
														}}
														className="d-flex justify-content-center cert-percentage"
													>
														<p className="mb-0">{`${goodPercent}%`}</p>
													</div>
												)}
												{![0, "0"].includes(moderatePercent) && (
													<div
														style={{
															width: `${moderatePercent}%`,
															minWidth: "50px",
														}}
														className="d-flex cert-percentage justify-content-center"
													>
														<p className="mb-0">{`${moderatePercent}%`}</p>
													</div>
												)}
											</div>
										</div>
									</div>
								);
							})}
					</div>
					{!isEmpty(certificationData) && (
						<div>
							<p className="mt-3 text-center">
								**Air Quality Exposure data for the latest 24 hrs
							</p>
							<div className="d-flex mt-4 justify-content-center">
								<div className="text-good">
									<p className="mb-0">Good</p>
								</div>
								<div className="text-moderate">
									<p className="mb-0">Moderate</p>
								</div>
							</div>
						</div>
					)}
				</div>
				{isEmpty(certificationData) && (
					<div className="position-absolute info px-0 d-block">
						<p className="mb-0 text-white text-justify text-center text-break">
							No Air Quality Monitor Found
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(Certification);
