import { SET_BUILDING, SET_BUILDINGS } from "./actionTypes";

export const setBuilding = (building: any) => {
	return {
		type: SET_BUILDING,
		payload: building,
	};
};

export const setBuildings = (buildings: any) => {
	return {
		type: SET_BUILDINGS,
		payload: buildings,
	};
};
