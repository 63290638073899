import { SET_USER, SET_TOKEN, CLEAR_USER } from "./actionTypes";

export const setUser = (userData: any) => {
	return {
		type: SET_USER,
		payload: userData,
	};
};

export const setToken = (token: any) => {
	return {
		type: SET_TOKEN,
		payload: token,
	};
};

export const clearUserData = () => {
	return {
		type: CLEAR_USER,
	};
};
