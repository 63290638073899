import { UserRoles } from "../enums/UserRoles";

export const hasWriteAccess = (user: any, asset: any) => {
	if (user.role === UserRoles.CONTRACTOR) {
		return true;
	}
	return false;
};

export const validateEmail = (email: string) => {
	const reForEmail =
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	return reForEmail.test(email);
};

export const getMonitorTypeFullName = (device: string) => {
	if ("aqMonitor") {
		return "Air Quality Monitor";
	} else if ("ionMonitor") {
		return "Ion Monitor";
	} else if ("radanMonitor") {
		return "Radan Monitor";
	}
	return "";
};

export const roleConditionChecker = (item: any, user: any, asset: any) => {
	let isAllowed = true;
	if (item?.roleconditions?.length) {
		const roleconditions = item?.roleconditions;
		if (
			roleconditions &&
			roleconditions[0] &&
			Object.keys(roleconditions[0]).length
		) {
			if (
				roleconditions[0].condition === "check_write_access" &&
				roleconditions[0].roles.includes(user.role)
			) {
				isAllowed = user?.assets
					.find((mapData: any) => mapData._id === asset._id)
					?.permissions.includes("write");
			}
		}
		if (
			roleconditions &&
			roleconditions[1] &&
			Object.keys(roleconditions[1]).length
		) {
			if (
				roleconditions[1].condition === "check_write_access_not_allowed" &&
				roleconditions[1].roles.includes(user.role) &&
				roleconditions[1].notallowed.includes(item.path)
			) {
				isAllowed = false;
			}
		}
		if (
			roleconditions &&
			roleconditions[2] &&
			Object.keys(roleconditions[2]).length
		) {
			if (
				roleconditions[2].condition === "check_premium_user" &&
				roleconditions[2].notallowed.includes(item.path) &&
				user.hasOwnProperty("isPremiumUser") &&
				!user?.isPremiumUser
			) {
				localStorage?.setItem("isPremiumUserMobile", "true");
				return false;
			}
		}
	}
	return isAllowed;
};
