import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import BusinessIcon from "@material-ui/icons/Business";
import { useSelector, useDispatch } from "react-redux";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import get from "lodash/get";
import ArrowDropDownRounded from "@material-ui/icons/ArrowDropDownRounded";
import MenuIcon from "@material-ui/icons/Menu";
import HelpIcon from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";

import { updateProfile } from "./../../api/UserController";
import { RootState } from "../../redux/reducers";
import { Building } from "../../pages/Buildings/Buildings";
import { setBuilding, setBuildings } from "../../redux/actions/buildingActions";
import { URL } from "../Navbar/routes";
import { getLocations } from "../../redux/actions/locationActions";
import { setAsset, setAssets } from "../../redux/actions/assetActions";
import { clearUserData, setUser } from "../../redux/actions/userActions";
import { EventEmitter } from "../../common/EventEmitter";
import { setupWebViewJavascriptBridge } from "../../common/JsBridge";
import { isIpadOS } from "../../common/service";

import "./Header.scoped.scss";
interface BuildingsList {
	_id: string;
	name: string;
}

const Navbar: React.FC<any> = (props: any) => {
	const { setShowSideNav } = props;
	const building: any = useSelector((state: RootState) => state.building);
	const asset: any = useSelector((state: RootState) => state.asset);
	const buildingList: Array<BuildingsList> = useSelector(
		(state: RootState) => state.buildings
	);
	const user = useSelector((state: RootState) => state.user);
	const assetList: Array<object> = useSelector(
		(state: RootState) => state.assets
	);
	const [dateState, setDateState] = useState(new Date());
	let [buildingsList, setBuildingsList] = useState<any>([]);
	let [assetsList, setAssetsList] = useState<any>([]);

	const { pathname } = window.location;
	const [isShowPremiumConfirmationDialog, setIsShowPremiumConfirmationDialog] =
		useState<boolean>(false);

	useEffect(() => {
		setInterval(() => setDateState(new Date()), 30000);
	}, []);

	useEffect(() => {
		setBuildingsList(buildingList);
		setAssetsList(assetList);
	}, [assetList, buildingList]);

	const history = useHistory();

	const dispatch = useDispatch();

	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};

	const goToLogin = () => {
		if (
			/android/.test(navigator.userAgent.toLowerCase()) ||
			/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) ||
			isIpadOS()
		) {
			callHandler("logout", {}, () => {});
		}
		dispatch(setAssets([]));
		dispatch(setAsset({}));
		dispatch(setBuildings([]));
		dispatch(setBuilding({}));
		dispatch(clearUserData());
		localStorage?.clear();
		props.history.push("/login");
	};

	const goToProfile = () => {
		history.push(URL.DASHBOARD_PROFILE);
	};

	const goToChangePassword = () => {
		history.push(URL.CHANGE_PASSWORD);
	};

	const CustomToggle = React.forwardRef<any>(
		({ children, onClick }: any, ref) => (
			<div
				className="buildings mr-3"
				onClick={(e) => {
					e.preventDefault();
					onClick(e);
				}}
				ref={ref}
			>
				{children}
			</div>
		)
	);

	const CustomProfileToggle = React.forwardRef<any>(
		({ children, onClick }: any, ref) => (
			<div
				className="user-profile"
				onClick={(e) => {
					e.preventDefault();
					onClick(e);
				}}
				ref={ref}
			>
				{children}
			</div>
		)
	);

	const setBuildingData = (building: Building) => {
		dispatch(setBuilding(building));
		dispatch(getLocations([], (building && building._id) ?? ""));
	};

	const setAssetData = (asset: any) => {
		dispatch(setAsset(asset));
		dispatch(setBuildings(get(asset, "buildings", [])));
		// Added in order to call the buildings list api while changing asset
		EventEmitter.dispatch("assetChange", asset);
		if (asset?.buildings?.length > 0) {
			dispatch(
				getLocations([], (asset?.buildings[0] && asset?.buildings[0]._id) ?? "")
			);
			dispatch(setBuilding(asset?.buildings.length ? asset?.buildings[0] : []));
		} else {
			dispatch(setBuilding({ name: "N/A", _id: null }));
			dispatch(getLocations([], ""));
		}
	};

	const onSearchBuilding = (value: string) => {
		buildingsList = buildingList.filter((building: any) =>
			building.name.toLowerCase().includes(value.toLocaleLowerCase())
		);
		setBuildingsList(buildingsList);
	};

	const onSearchAsset = (value: string) => {
		assetsList = assetList.filter((asset: any) =>
			asset.name.toLowerCase().includes(value.toLocaleLowerCase())
		);
		setAssetsList(assetsList);
	};

	const showNavBarInMobile = () => {
		setShowSideNav((currentValue: boolean) => !currentValue);
	};

	const help = () => {
		user.tourPosition = [];
		updateProfile({ tourPosition: user.tourPosition })
			.then((response: any) => {
				dispatch(setUser(response?.responseObj?.user));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const renderHelpIcon = () => {
		if (
			![URL.DASHBOARD, URL.MARKET_PLACE, URL.DASHBOARD_BUILDINGS].includes(
				window.location.pathname
			)
		) {
			return (
				<IconButton aria-label="help" className="mr-2">
					<HelpIcon className="i-exlarge text-danger" onClick={help} />
				</IconButton>
			);
		}
	};

	return (
		<div className="header-section m-d-none d-flex align-items-center justify-content-between m-px-3">
			<div className="d-flex align-items-center justify-content-between w-100 desktop-header">
				<div className="d-flex flex-fill">
					{![
						URL.DASHBOARD_PROFILE,
						URL.ASSETS,
						URL.USERS,
						URL.DASHBOARD_PRODUCT_HELp,
						URL.CUSTOMER_ACCOUNT,
						URL.CUSTOMER_ACCOUNT_UNASSIGNED,
						URL.DASHBOARD_AQ_PARAMETERS_EXPLAINED,
						URL.CHANGE_PASSWORD,
						URL.CONTRACTOR_USERS,
						URL.DATA_SIM,
						URL.ADD_EDIT_CONSULTANT_REPORT,
						URL.OFFLINE_SUBSCRIPTION,
						URL.MARKET_PLACE,
					].includes(pathname) && (
						<Dropdown className="profile drop-down-width flex-fill">
							<Dropdown.Toggle
								variant="Primary"
								id="dropdown-basic"
								bsPrefix="my-dropdown-toggle p-0"
								as={CustomToggle}
							>
								<GroupIcon className="ml-2" />{" "}
								<p className="mb-0 text-truncate w-100">{asset?.name} </p>
								<KeyboardArrowDownIcon />
							</Dropdown.Toggle>
							<Dropdown.Menu
								style={{
									width: "inherit",
								}}
								className="p-0"
							>
								{assetList?.length > 0 && (
									<>
										<div className="dashboard-sticky">
											<input
												type="text"
												placeholder="Search Asset"
												onChange={(event) =>
													onSearchAsset(event?.target?.value?.trim())
												}
											/>
										</div>
										{assetsList.map((singleAsset: any, index: number) => {
											return (
												<>
													<Dropdown.Item
														className="simple text-break"
														key={index}
														onClick={() => setAssetData(singleAsset)}
													>
														<p className="mb-0 text-truncate w-100 text-break">
															{singleAsset?.name}{" "}
														</p>
													</Dropdown.Item>
												</>
											);
										})}
										{assetsList?.length === 0 && (
											<Dropdown.Item
												className="simple cursor-disabled"
												key={"No-asset"}
											>
												No result found
											</Dropdown.Item>
										)}
									</>
								)}
								{assetList?.length === 0 && (
									<Dropdown.Item
										className="simple cursor-disabled"
										key={"No-building"}
									>
										No Asset Available
									</Dropdown.Item>
								)}
							</Dropdown.Menu>
						</Dropdown>
					)}
					{![
						URL.CLUSTER_CONFIG,
						URL.DASHBOARD_BUILDINGS,
						URL.ASSETS,
						URL.USERS,
						URL.DEVICE_MANAGEMENT,
						URL.CONFIGURE_DEVICE,
						URL.DASHBOARD_PROFILE,
						URL.DASHBOARD_PRODUCT_HELp,
						URL.DASHBOARD_AQ_PARAMETERS_EXPLAINED,
						URL.CUSTOMER_ACCOUNT,
						URL.CUSTOMER_ACCOUNT_UNASSIGNED,
						URL.CHANGE_PASSWORD,
						URL.CONTRACTOR_USERS,
						URL.DATA_SIM,
						URL.ADD_EDIT_CONSULTANT_REPORT,
						URL.OFFLINE_SUBSCRIPTION,
						URL.MARKET_PLACE,
						URL.CONSULTANTS,
					].includes(pathname) &&
						!(pathname.indexOf(URL.CONFIGURE_DEVICE) !== -1) && (
							<Dropdown className="profile drop-down-width flex-fill">
								<Dropdown.Toggle
									variant="Primary"
									id="dropdown-basic"
									bsPrefix="my-dropdown-toggle p-0"
									as={CustomToggle}
								>
									<BusinessIcon /> {get(building, "name", "N/A")}
									<KeyboardArrowDownIcon />
								</Dropdown.Toggle>
								<Dropdown.Menu style={{ width: "inherit" }} className="p-0">
									{buildingList?.length > 0 && (
										<>
											<div className="dashboard-sticky">
												<input
													type="text"
													placeholder="Search Building"
													className="dashboard-sticky"
													onChange={(event) =>
														onSearchBuilding(event?.target?.value?.trim())
													}
												/>
											</div>
											{buildingsList.map(
												(singleBuilding: any, index: number) => {
													return (
														<>
															<Dropdown.Item
																className="simple"
																key={index}
																onClick={() => setBuildingData(singleBuilding)}
															>
																<p className="mb-0 text-truncate w-100">
																	{singleBuilding?.name}{" "}
																</p>
															</Dropdown.Item>
														</>
													);
												}
											)}
											{buildingsList?.length === 0 && (
												<Dropdown.Item
													className="simple cursor-disabled"
													key={"No-building"}
												>
													No result found
												</Dropdown.Item>
											)}
										</>
									)}
									{buildingList?.length === 0 && (
										<Dropdown.Item
											className="simple cursor-disabled"
											key={"No-building"}
										>
											No Building Available
										</Dropdown.Item>
									)}
								</Dropdown.Menu>
							</Dropdown>
						)}
				</div>
				<div className="d-flex justify-content-between">
					<div className="date d-flex">
						<QueryBuilderIcon />
						<p className="m-0">
							{dateState.toLocaleDateString("en-GB", {
								day: "numeric",
								month: "short",
								year: "numeric",
							})}
						</p>
						{"-"}
						<p className="m-0">
							{dateState.toLocaleString("en-US", {
								hour: "numeric",
								minute: "numeric",
								hour12: true,
							})}
						</p>
					</div>

					<div className="d-flex">
						<Dropdown className="user-profile">
							<Dropdown.Toggle
								variant="Primary"
								id="dropdown-basic"
								bsPrefix="my-dropdown-toggle p-0"
								as={CustomProfileToggle}
							>
								<div className="user-profile-img">
									<img src={user.avatar || "/images/userimg.svg"} alt="" />
								</div>
								<div className="user-profile-name ml-2 mr-4">
									{user && (
										<p className="mb-0 text-capitalize">
											{user.firstName} {user.lastName}
										</p>
									)}
								</div>
								<ArrowDropDownRounded className="link" />
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ width: "inherit" }}>
								<Dropdown.Item onClick={goToProfile}>Profile</Dropdown.Item>
								<Dropdown.Item onClick={goToChangePassword}>
									Change Password
								</Dropdown.Item>
								<Dropdown.Item onClick={goToLogin}>Logout</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
			</div>
		</div>
	);
};
export default withRouter(Navbar);
