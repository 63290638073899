import React, { useEffect, useState } from "react";
import "./style.scoped.scss";
import { Button, Link } from "@material-ui/core";

import FavoriteIcon from "@material-ui/icons/Favorite";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";

import vantilator from "../../../images/ventilator.svg";
import machine from "../../../images/machine.svg";

import { consultationTypesMobile } from "../../../enums/common";
import ConsultationCategory from "./Components/ConsultationCategory";
import MobileBack from "../../../components/MobileBack";
import ViewConsultant from "./Components/ViewConsultant";
import AllConsultant from "./Components/AllConsultant";
import FavouriteConsultant from "./Components/FavouriteConsultant";
import { SpinnerLoader } from "../../../common/Global.Style";

import {
	getConsultant,
	assignAssetToConsultant,
	doFavouriteUnFavourite,
} from "../../../api/commonController";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import get from "lodash/get";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchIcon from "@material-ui/icons/Search";
import { URL } from "../../../components/Navbar/routes";
import ReportProblemIcon from "@material-ui/icons/ReportProblemOutlined";
import { toast } from "react-toastify";
import { UserRoles } from "../../../enums/UserRoles";

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down("xs")]: {
			alignItems: "flex-end", // push the dialog to bottom
		},
	},
	paper: {
		// make the content full width
		[theme.breakpoints.down("xs")]: {
			margin: 0,
			maxWidth: "100%",
			width: "100%",
		},
	},
}));

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const IAQConsultant: React.FC = () => {
	let query = useQuery();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showConsultant, SetShowConsultant] = useState(false);
	const asset = useSelector((state: RootState) => state.asset);
	const consultationType: any = query.get("type");
	const [consultantList, setConsultantList] = useState([]);
	const [favouriteConsultantList, setFavouriteConsultantList] = useState([]);
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const openDialog = () => setOpen(true);
	const [search, setSearch] = useState("");
	const [viewConsultantData, setViewConsultantData] = useState<any>();
	const [searchFilter, setSearchFilter] = useState<any>();
	const user = useSelector((state: RootState) => state.user);
	const [isViewReportAvailable, setIsViewReportAvailable] = useState(true);
	let searchOptions: any = [{ content: "ALL", isActive: true }];

	const addToFavourite = async (consultant: any) => {
		setIsLoading(true);
		const response = await doFavouriteUnFavourite({ consultant: consultant });
		if (response?.responseObj?.message) {
			getAllConsultant();
			closePopUp();
		}
	};
	let searchTimeout: any;
	const onSearchSubmit = async (e: any) => {
		searchTimeout?.clearTimeout();
		e.preventDefault();
		await setFilterActive(searchOptions[0]);
		searchTimeout = setTimeout(function () {
			getAllConsultant();
		}, 100);
	};
	const openPopUp = (consultant: any) => {
		setViewConsultantData(consultant);
		setOpen(true);
	};
	const closePopUp = () => {
		setOpen(false);
		setViewConsultantData("");
	};
	const filterSearch = async (certification: any) => {
		searchTimeout?.clearTimeout();
		await setFilterActive(certification);
		if (certification.content === "ALL") setSearch("");
		else setSearch(certification.content);
		searchTimeout = setTimeout(function () {
			getAllConsultant();
		}, 100);
	};
	const setFilterActive = async (certification?: any) => {
		const filter = searchFilter.map((s: any) => {
			if (JSON.stringify(s) === JSON.stringify(certification))
				s.isActive = true;
			else s.isActive = false;
			return s;
		});
		setSearchFilter(filter);
	};
	const getAllConsultant = async () => {
		setIsLoading(true);
		let filter = "";
		setSearch((prevState: any) => {
			if (prevState) filter = prevState;
			return prevState;
		});
		const response = await getConsultant(
			"",
			asset._id,
			filter !== "ALL" ? filter : ""
		);
		if (response?.responseObj?.length) {
			const products = get(response, "responseObj", []);
			const favorite = products?.filter(
				(p: any) => p?.favorite[0]?.isFavourite
			);
			// if (!filter) {
			// 	consultationTypesMobile.map((co: any) =>
			// 		searchOptions.push({
			// 			content: co.name.split(" ")[0],
			// 			isActive: false,
			// 		})
			// 	);
			// 	setSearchFilter(searchOptions);
			// }
			if (!filter) {
				products?.map((p: any) => {
					if (p?.consultantCertification) {
						p?.consultantCertification?.map((con: any) => {
							searchOptions.push({
								content: con,
								isActive: false,
							});
						});
					}
				});
				let result = searchOptions.reduce((acc: any, item: any) => {
					if (!acc.find((other: any) => item.content == other.content)) {
						acc.push(item);
					}
					return acc;
				}, []);
				setSearchFilter(result);
			}
			setFavouriteConsultantList(favorite);
			setConsultantList(products);
		} else {
			setFavouriteConsultantList([]);
			setConsultantList([]);
		}
		setIsLoading(false);
	};
	const assignAsset = async (email: string) => {
		setIsLoading(true);
		const response = await assignAssetToConsultant({
			assets: [{ _id: asset._id, name: asset.name }],
			email,
		});
		if (response.responseObj) {
			await getAllConsultant();
			closePopUp();
			toast.success(response?.responseObj?.message);
		} else {
			toast.error(response.error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getAllConsultant();
	}, [asset]);
	return (
		<div className="w-100">
			<div className="mobile-header d-none m-d-flex align-items-center justify-content-between bg-white p-12 px-3">
				<MobileBack heading="Consultant" redirect={URL.IAQSUBMENU} />
				{isViewReportAvailable && (
					<div>
						<Button className="btn-medium p-0 lh-normal font-600 ls-024 txt-primary">
							<NavLink className="txt-primary" to={URL.CONSULTATION_REPORTS}>
								View Reports
							</NavLink>
						</Button>
					</div>
				)}
			</div>
			<div className="bg-white px-3 py-2">
				<div className="icon-input">
					<form className="position-relative" onSubmit={onSearchSubmit}>
						<input
							name="search"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							className="add-re-input form-control pt-2 pb-2"
							placeholder="Search Consultant"
						/>
						<SearchIcon className="search-icon" />
					</form>
					{searchFilter?.length > 1 && (
						<div className="d-flex align-items-center overflow-auto w-100 pt-3 pb-2 scroll-hidden">
							{searchFilter?.map((certification: any) => {
								return (
									<Button
										className={
											certification.isActive
												? "desc-small ls-024 lh-14 font-grey-light-1 tag-scrollable active"
												: "desc-small ls-024 lh-14 font-grey-light-1 tag-scrollable"
										}
										onClick={() => filterSearch(certification)}
									>
										{certification.content}
									</Button>
								);
							})}
						</div>
					)}
				</div>
			</div>
			<div className="consultant-section w-100">
				<div className="mt-1 p-3 bg-white">
					<div className="w-100 radius-4 bg-warn-light-1 d-flex align-items-start p-2">
						<div className="p-2 bg-warn-light-1 rounded-circle d-flex align-items-center justify-content-center">
							<ReportProblemIcon className="i-16 font-warn-light" />
						</div>
						<p className="pl-3 desc-small font-grey-light-4 ls-024 lh-18 mb-0">
							iQi is not responsible for consultant hiring process and payments
							for consultants. It is purely between the user and consultants
						</p>
					</div>
				</div>
				<div className="mt-1 p-12 px-3 w-100 d-flex align-items-start justify-content-between overflow-auto scroll-hidden bg-white c-g-20">
					{consultationTypesMobile.map((category: any) => {
						return <ConsultationCategory category={category} />;
					})}
				</div>
				<Dialog
					className="view-consultant-details-modal"
					open={open}
					classes={{ container: classes.root, paper: classes.paper }}
				>
					<DialogContent>
						{viewConsultantData && (
							<ViewConsultant
								closePopUp={closePopUp}
								viewConsultantData={viewConsultantData}
								addToFavourite={addToFavourite}
								assignAsset={assignAsset}
							/>
						)}
					</DialogContent>
				</Dialog>
				{favouriteConsultantList.length > 0 && (
					<div className="mt-1 p-12 px-3 w-100 bg-white consultant-section">
						<div className="mb-3 d-flex align-items-center justify-content-between">
							<p className="mb-0 desc-exlarge ls-024 font-700 font-primary">
								Favourite
							</p>
							<NavLink
								to={URL.IAQCONSULTANTALL}
								className="desc-normal font-600 ls-024 lh-18 txt-primary"
							>
								View All
							</NavLink>
						</div>
						<div className="d-flex align-items-stretch overflow-auto scroll-hidden c-g-8">
							{favouriteConsultantList.map((favourite: any) => {
								return (
									<FavouriteConsultant
										favourite={favourite}
										addToFavourite={addToFavourite}
										openPopUp={openPopUp}
									/>
								);
							})}
						</div>
					</div>
				)}
				<div className="mt-1 p-12 px-3 w-100 bg-white all-product-section">
					<div className="mb-3 d-flex align-items-center justify-content-between">
						<p className="mb-0 desc-exlarge ls-024 font-700 font-primary">
							All Consultant
						</p>
					</div>
					<div className="d-flex align-items-stretch justify-content-between flex-wrap w-100 c-g-8 r-g-8">
						{isLoading && <SpinnerLoader />}
						{consultantList.map((consulatan: any) => {
							return (
								<AllConsultant
									consulatan={consulatan}
									addToFavourite={addToFavourite}
									openPopUp={openPopUp}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
export default IAQConsultant;
