import { GET_DEVICES, SET_DEVICES, SET_SELECTED_DEVICE } from "./actionTypes";
import { toast } from "react-toastify";

export const setDevices = (deviceData: any) => {
	return {
		type: SET_DEVICES,
		payload: deviceData,
	};
};

export const getDevices = (devices: any) => {
	return {
		type: GET_DEVICES,
		payload: devices,
	};
};

export const setSelectedDevice = (deviceId: any) => {
	return {
		type: SET_SELECTED_DEVICE,
		payload: deviceId,
	};
};
