import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import { toast } from "react-toastify";

import {
	createFloorApi,
	getFloorApi,
	updateFloorApi,
	removeDeviceFromFloor,
	deleteFloorPlan,
} from "../../../api/FloorController";
import { getBuildingDeviceList } from "../../../api/DevicesController";
import "./List.scoped.scss";

import { SpinnerLoader } from "../../../common/Global.Style";
import { RootState } from "../../../redux/reducers";
import { FloorPlan } from "../../../components/FloorPlan/FloorPlan";
import { FloorPlanName } from "../../../components/FloorPlan/FloorPlanName";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import PaymentPendingInfo from "../../../components/PaymentPendingInfo/PaymentPendingInfo";
import SystemTour from "../../../components/SystemTour/SystemTour";

const List: React.FC = () => {
	const building = useSelector((state: RootState) => state.building);
	const asset = useSelector((state: RootState) => state.asset);
	const user = useSelector((state: RootState) => state.user);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [deviceData, setDeviceData] = useState<any>({});
	const [deviceList, setDeviceList] = useState<any>([]);
	const [initialDeviceList, setInitialDeviceList] = useState<any>([]);
	const [urlList, setUrlList] = useState<
		{ name: string; imageUrl: string; isEditing: boolean; _id: string }[]
	>([]);
	const [deviceStatus, setDeviceStatus] = useState<any>({});
	const [selectedId, setSelectedId] = useState<any>(null);
	const [canWriteAsset, setCanWriteAsset] = useState<boolean>(true);
	const [tourSteps] = useState<any>([
		{
			selector: ".upload-floorplan-tour",
			content: `Click here to upload floorplans for the building.`,
		},
	]);

	useEffect(() => {
		const isCreatedUser = user._id === asset.userId;
		if (isCreatedUser) {
			setCanWriteAsset(isCreatedUser);
		} else {
			const assetData = user?.assets?.find(
				(mapData: any) => mapData._id === asset._id
			);
			setCanWriteAsset(assetData?.permissions?.includes("write"));
		}
	}, [asset]);

	useEffect(() => {
		setInitialData();
		triggerAPi();
	}, [building]);

	useEffect(() => {
		if (initialDeviceList.length > 0 && urlList.length > 0) {
			findUsedDevice();
		}
	}, [initialDeviceList, urlList]);

	const setInitialData = () => {
		setDeviceData({});
		setDeviceList([]);
		setInitialDeviceList([]);
		setUrlList([]);
		setDeviceStatus({});
	};

	const triggerAPi = async () => {
		setIsLoading(true);
		await getDeviceList();
		await getFloorList();
		setIsLoading(false);
	};

	const deleteDeviceFromFloor = async (deviceId: string, floorId: string) => {
		await removeDeviceFromFloor(deviceId, floorId);
	};

	const getDeviceList = async () => {
		const response = await getBuildingDeviceList(building._id);
		setInitialDeviceList(get(response, "responseObj.devices", []));
	};

	const getFloorList = async () => {
		const response = await getFloorApi(building._id, asset._id);
		updateDeviceStatus(get(response, "responseObj.deviceStatus", []));
		setUrlList(get(response, "responseObj.floorList", []));
	};

	const findUsedDevice = () => {
		const tempData = initialDeviceList.filter((filterData: any) => {
			let isFound = false;
			urlList.forEach((mapData: any) => {
				if (mapData.deviceIds.includes(filterData._id)) {
					isFound = true;
				}
			});
			return !isFound;
		});
		let tempDeviceData: any = {};
		initialDeviceList.forEach((mapData: any) => {
			tempDeviceData[mapData._id] = mapData;
		});
		setDeviceData(tempDeviceData);
		setDeviceList(tempData);
	};

	const showDeleteFloorModal = (id: string) => {
		setSelectedId(id);
	};

	const deleteFloor = async () => {
		setSelectedId("");
		setIsLoading(true);
		await deleteFloorPlan(selectedId);
		toast.success("Floor plan deleted successfully");
		await getFloorList();
		setIsLoading(false);
	};

	const onEditFloor = async (data: any) => {
		setIsLoading(true);
		const response = await updateFloorApi(
			{
				buildingId: building._id,
				...data,
			},
			asset._id
		);
		if (response?.responseObj) {
			toast.success("Floor plan updated successfully");
			setUrlList(get(response, "responseObj.floorList", []));
			updateDeviceStatus(get(response, "responseObj.deviceStatus"));
		}
		setIsLoading(false);
	};

	const onRemoveDevice = async (deviceId: string, floorId: string) => {
		setIsLoading(true);
		await deleteDeviceFromFloor(deviceId, floorId);
		await getFloorList();
		setIsLoading(false);
	};

	const updateDeviceStatus = (data: any) => {
		let tempData: any = {};
		data.forEach((mapData: any) => {
			tempData[mapData._id] = mapData;
		});
		setDeviceStatus(tempData);
	};

	const createFloor = (formData: any) => {
		setIsLoading(true);
		createFloorApi(formData)
			.then((response: any) => {
				setIsLoading(false);
				toast.success("Floor plan added successfully");
				setUrlList(get(response, "responseObj.floorList", []));
				updateDeviceStatus(get(response, "responseObj.deviceStatus"));
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const onChooseImage = (event: any) => {
		if (!building._id) {
			toast.error("For uploading floor plan you need to create building.");
			return;
		}
		const formData = new FormData();
		formData.append("buildingId", building._id);
		Array.from(event.target.files).forEach((mapData: any) => {
			formData.append("files", mapData);
		});
		if (event?.target?.files?.length) {
			createFloor(formData);
		}
	};

	const checkAsset = () => {
		return !asset?.payPending;
	};

	const renderFloorPlan = () =>
		checkAsset() ? (
			<>
				<div className="col-md-12 col-lg-12 col-sm-12 p-2">
					{/* <SystemTour tourSteps={tourSteps} position={0} /> */}
					<div className="location-list bg-light p-4 md-p-r-0 md-p-l-0">
						{canWriteAsset && (
							<div className="d-flex justify-content-end mb-3">
								<input
									accept="image/*"
									style={{ display: "none" }}
									id="contained-button-file"
									multiple
									type="file"
									onClick={(event: any) => {
										event.target.value = null;
									}}
									onChange={onChooseImage}
								/>
								<div className="text-right p-2">
									<label htmlFor="contained-button-file">
										<p className="btn add-device-btn upload-btn cursor-pointer m-0 upload-floorplan-tour">
											Upload
										</p>
									</label>
									<p className="m-0">
										<b>Formats supported</b> - .PNG, .SVG, .JPG
									</p>
									<p className="font-italic">
										Note: For better clarity use images with resolution 400x250
										/ 500x280 or higher
									</p>
								</div>
							</div>
						)}
						<div className="floor-plan-list">
							{urlList.map((mapData: any, index: number) => (
								<div className="floor-plan-card">
									<div className="floor-plan-card-inside">
										<FloorPlan
											floorData={mapData}
											key={`image-${index}`}
											deviceList={uniqBy(deviceList, "_id")}
											onEditFloor={onEditFloor}
											onRemoveDevice={onRemoveDevice}
											deviceStatus={deviceStatus}
											isActiveDevice={deviceData}
										/>
									</div>
									<FloorPlanName
										name={mapData.name}
										imageList={urlList}
										updateName={setUrlList}
										canWriteAsset={canWriteAsset}
										index={index}
										onEditName={onEditFloor}
										isEditing={mapData.isEditing}
										deleteFloor={showDeleteFloorModal}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</>
		) : (
			<PaymentPendingInfo name={asset.name} />
		);

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12 col-lg-12 col-sm-12 pr-0 pl-0">
					<div className="mt-3 h-100 pb-5 mb-2">
						<div className="col-md-12 col-lg-12 col-sm-12 location-list bg-white p-3 md-p-r-0 md-p-l-0">
							<h4 className="mt-2 pl-2">Floor Plan</h4>
							{renderFloorPlan()}
							{isLoading && <SpinnerLoader />}
							{!!selectedId && (
								<ConfirmDialog
									dialogTitle="Delete"
									message="Are you sure you want to delete this Floor Plan?"
									positiveBtnLabel="Delete Permanently"
									negativeBtnLabel=""
									onPositiveBtnClicked={deleteFloor}
									onNegativeBtnClicked={() => setSelectedId("")}
									onCloseIconClicked={() => setSelectedId("")}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default List;
