import React, { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { SelectableRows } from "mui-datatables";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import debounce from "lodash/debounce";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";

import { RootState } from "../../../redux/reducers";
import { SpinnerLoader } from "../../../common/Global.Style";
import CustomDataTable from "../../../components/CustomDataTable/CustomDataTable";
import { AutopilotStatus } from "../../../enums/common";
import LocationSelector from "../../../components/LocationSelector/LocationSelector";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { getAutopilotReport } from "../../../api/ReportsController";
import PaymentPendingInfo from "../../../components/PaymentPendingInfo/PaymentPendingInfo";

import "./AutopilotReports.scoped.scss";
import SystemTour from "../../../components/SystemTour/SystemTour";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";

const AutopilotReports: React.FC = () => {
	const asset = useSelector((state: RootState) => state.asset);
	const user: any = useSelector((state: RootState) => state.user);
	const building = useSelector((state: RootState) => state.building);
	const history = useHistory();

	const [logDateFormat] = useState<string>("MMM D ddd yyyy h:mm a");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [iaqFromDate, setIaqFromDate] = useState<any>();
	const [iaqToDate, setIaqToDate] = useState<any>();
	const [autoPilotReport, setAutoPilotReport] = useState<Array<any>>([]);
	const [filteredAutopilotReport, setFilteredAutopilot] = useState<Array<any>>(
		[]
	);
	const onChangeLocation = (value: any) => {
		filterAutopilotByLocation(value);
	};

	useEffect(() => {
		getAutoPilotData(building._id);
	}, [building]);

	const getAutoPilotData = (buildingId: any) => {
		setIsLoading(true);
		const dateFrom = iaqFromDate
			? moment(iaqFromDate).format("YYYY-MM-DD")
			: moment().subtract(3, "days").format("YYYY-MM-DD");
		const dateTo = moment(iaqToDate).format("YYYY-MM-DD");
		const iaqReportReq =
			"startDate=" +
			dateFrom +
			"&endDate=" +
			dateTo +
			"&building=" +
			buildingId;

		const data = iaqReportReq;

		getAutopilotReport(data)
			.then((response: any) => {
				if (
					response.responseObj.results &&
					response.responseObj.results.length
				) {
					setIsLoading(false);
					setAutoPilotReport(response.responseObj.results);
					setFilteredAutopilot(response.responseObj.results);
				} else {
					setIsLoading(false);
					setAutoPilotReport([]);
					setFilteredAutopilot([]);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				setAutoPilotReport([]);
				setFilteredAutopilot([]);
			});
	};

	const updateDate = (value: Date | null, propertyName: string) => {
		if (propertyName === "dateFrom") {
			setIaqFromDate(value);
		} else {
			setIaqToDate(value);
		}
	};
	const filterAutopilotByLocation = (location: any) => {
		if (location)
			setFilteredAutopilot(
				autoPilotReport.filter(
					(schedule: any) => schedule.locationName === location.label
				)
			);
		else setFilteredAutopilot(autoPilotReport);
	};

	const searchAutopilotLog = (e: any) => {
		let { value } = e.target;
		value = value.toLowerCase();
		setFilteredAutopilot(
			autoPilotReport.filter((filterData: any) => {
				if (
					filterData?.locationName?.toLowerCase()?.includes(value) ||
					filterData?.deviceName?.toLowerCase()?.includes(value)
				) {
					return true;
				}
				return false;
			})
		);
	};

	const options = {
		print: true,
		download: true,
		viewColumns: false,
		responsive: "scrollMaxHeight",
		showResponsive: true,
		downloadOptions: {
			filename: "AutopilotReport.csv",
		},
		selectableRows: "none" as SelectableRows,
	};

	const aQIParameter: any = {
		co2_value: "CO2",
		temp_current_f: "Temperature",
		humidity_value: "Humidity",
		ch2o_value: "Formaldehyde",
		pm1: "PM1.0",
		pm25_value: "PM2.5",
		pm10: "PM10",
		voc_value: "TVOC",
	};

	const [tourSteps] = useState<any>([
		{
			selector: ".autofix-run-reports-tour",
			content: `Click here to view the Autofix logs for the selected time period.`,
		},
	]);

	const columns = [
		{
			name: "_id",
			options: {
				filter: false,
				display: false, // hide this filed in Data-Table
			},
		},
		{
			name: "sno",
			label: "S.NO",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <>{tableMeta.rowIndex + 1}</>;
				},
			},
		},
		{
			name: "locationName",
			label: "LOCATION",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "AQIParam",
			label: "AQI PARAMETER",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? aQIParameter[tableMeta.rowData[tableMeta.columnIndex]]
								: "N/A"}{" "}
						</>
					);
				},
			},
		},
		{
			name: "threshold",
			label: "THRESHOLD",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "valueBreach",
			label: "VALUE BREACH",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "remediationDeviceSubType",
			options: {
				filter: false,
				display: false,
			},
		},
		{
			name: "deviceName",
			label: "DEVICE TRIGGERED",
			options: {
				filterOptions: { fullWidth: true },
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							<div>
								<span>
									{tableMeta.rowData[tableMeta.columnIndex]
										? tableMeta.rowData[tableMeta.columnIndex]
										: "N/A"}{" "}
								</span>
								{tableMeta.rowData[tableMeta.columnIndex - 1] && (
									<span> - {tableMeta.rowData[tableMeta.columnIndex - 1]}</span>
								)}
							</div>
						</>
					);
				},
			},
		},
		{
			name: "triggeredAt",
			label: "EVENT START DATE",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? moment(tableMeta.rowData[tableMeta.columnIndex])
										.tz(
											building?.timeZoneName
												? building?.timeZoneName
												: "EST5EDT"
										)
										.format("LLLL")
								: ""}{" "}
						</>
					);
				},
			},
		},
		{
			name: "achievedAt",
			label: "EVENT END DATE",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? moment(tableMeta.rowData[tableMeta.columnIndex])
										.tz(
											building?.timeZoneName
												? building?.timeZoneName
												: "EST5EDT"
										)
										.format("LLLL")
								: "N/A"}
						</>
					);
				},
			},
		},
		{
			name: "action",
			label: "STATUS",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>{AutopilotStatus[tableMeta.rowData[tableMeta.columnIndex]]}</>
					);
				},
			},
		},
		{
			name: "intervention",
			label: "INTERVENTION",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "-"}
						</>
					);
				},
			},
		},
	];

	const checkAsset = () => {
		return !asset.payPending;
	};
	const goBack = () => {
		history.goBack();
	};

	return checkAsset() ? (
		<div className="container-fluid auto-pilot-block">
			{/* <SystemTour tourSteps={tourSteps} position={0} /> */}
			<div className="row mt-3 mb-3">
				<div className="col-lg-12 col-md-12 col-sm-12 mobile-auto-pilot-log autopilot-re bg-white p-3">
					<div className="w-100 d-flex m-d-none">
						<div className="w-50">
							<h5 className="mt-2 ml-12">Autofix Report</h5>
						</div>
					</div>
					<div
						onClick={() => goBack()}
						className="align-items-center pl-2 d-none m-d-flex pt-2 pb-3 sub-header"
					>
						<ArrowBackIcon className="i-18 txt-primary font-600" />
						<h2 className="desc-large font-600 font-text-4 mb-0">
							Autofix Report
						</h2>
					</div>
					<div className="responsive-search">
						<input
							onChange={debounce(searchAutopilotLog, 1000)}
							type="text"
							placeholder="Search"
						/>
						<SearchIcon />
					</div>
					<div className="d-flex top-section mt-4">
						<div className="col pr-2 pl-0 mr-2">
							<h6>Select Location</h6>
							<div className="dropdown w-100 pl-0 pr-0">
								<LocationSelector onSelectLocation={onChangeLocation} />
							</div>
						</div>
						<div className="col start-date pr-2 pl-0 mr-2">
							<div className="form-group ">
								<DatePicker
									label="Start Date"
									inputId={"start-date-input"}
									date={
										new Date(moment().subtract(3, "days").format("YYYY-MM-DD"))
									}
									onDateChanged={(date: Date | null) =>
										updateDate(date, "dateFrom")
									}
								/>
							</div>
						</div>
						<div className="col pr-2 pl-0 mr-2">
							<div className="form-group ">
								<DatePicker
									label="End Date"
									inputId={"end-date-input"}
									date={new Date()}
									onDateChanged={(date: Date | null) =>
										updateDate(date, "dateTo")
									}
									minDate={iaqFromDate}
								/>
							</div>
						</div>

						<div className="col p-0 d-flex pt-3 pb-3">
							<button
								className="btn autopilot-re-btn pl-4 pr-4 autofix-run-reports-tour"
								onClick={() => getAutoPilotData(building._id)}
							>
								<PlayArrowIcon /> Run Report
							</button>
						</div>
					</div>
					<div className="mt-3 mb-5 autopilot-re-table m-d-none">
						<CustomDataTable
							title="Autopilot Report"
							rows={filteredAutopilotReport}
							columns={columns}
							options={options}
						/>
					</div>
					<div className="autopilot-log-card d-none m-d-block">
						{!isEmpty(filteredAutopilotReport) &&
							filteredAutopilotReport?.map((mapData) => (
								<div className="mobile-card mb-2" key={mapData?._id}>
									<div className="d-flex justify-content-between">
										<div className="d-flex flex-column flex-1">
											<p className="content-label">LOCATIONS</p>
											<p className="content-name">{mapData?.locationName}</p>
										</div>
										<div className="auto-pilot-right">
											<p className="content-label">STATUS</p>
											<p className="content-value">
												{get(AutopilotStatus, mapData?.action)}
											</p>
										</div>
									</div>
									<div className="d-flex justify-content-between">
										<div className="d-flex flex-column flex-1">
											<p className="content-label">THRESHOLD</p>
											<p className="content-value">{mapData?.threshold}</p>
										</div>
										<div className="auto-pilot-right">
											<p className="content-label">AQI PARAMETER</p>
											<p className="content-value">
												{get(aQIParameter, mapData.AQIParam)}
											</p>
										</div>
									</div>
									<div className="d-flex justify-content-between">
										<div className="d-flex flex-column flex-1">
											<p className="content-label">DEVICE TRIGGERED</p>
											<p className="content-value">{mapData?.deviceName}</p>
										</div>
										<div className="auto-pilot-right">
											<p className="content-label">VALUE BREACH</p>
											<p className="content-value">{mapData?.valueBreach}</p>
										</div>
									</div>
									<div className="">
										<div>
											<p className="content-label">EVENT START DATE</p>
											<p className="content-value">
												{moment(mapData?.triggeredAt)
													.tz(
														building?.timeZoneName
															? building?.timeZoneName
															: "EST5EDT"
													)
													.format(logDateFormat)}
											</p>
										</div>
										<div>
											<p className="content-label">EVENT END DATE</p>
											<p className="content-value">
												{moment(mapData?.achievedAt)
													.tz(
														building?.timeZoneName
															? building?.timeZoneName
															: "EST5EDT"
													)
													.format(logDateFormat)}
											</p>
										</div>
									</div>
									<div>
										<p className="content-label">INTERVENTION</p>
										<p className="content-value">{mapData?.intervention}</p>
									</div>
								</div>
							))}
						<>
							{isEmpty(filteredAutopilotReport) && (
								<div className="d-flex justify-content-center mt-2">
									<p>No Record found</p>
								</div>
							)}
						</>
					</div>
				</div>
			</div>
			{isLoading && <SpinnerLoader />}
		</div>
	) : (
		<PaymentPendingInfo name={asset.name} />
	);
};
export default AutopilotReports;
