import { SvgIcon } from "@material-ui/core";
import { memo } from "react";

const Pm25 = (props) => {
	return (
		<SvgIcon
			width="48"
			height="25"
			viewBox="0 0 48 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="3.2526"
				cy="3.75424"
				r="2.2526"
				transform="rotate(-90 3.2526 3.75424)"
				fill={props.color}
			/>
			<circle
				cx="10.0105"
				cy="3.00315"
				r="1.50173"
				transform="rotate(-90 10.0105 3.00315)"
				fill={props.color}
			/>
			<circle
				cx="19.7716"
				cy="2.25229"
				r="2.2526"
				transform="rotate(-90 19.7716 2.25229)"
				fill={props.color}
			/>
			<circle
				cx="36.2907"
				cy="9.76108"
				r="2.2526"
				transform="rotate(-90 36.2907 9.76108)"
				fill={props.color}
			/>
			<circle
				cx="31.7853"
				cy="15.7679"
				r="2.2526"
				transform="rotate(-90 31.7853 15.7679)"
				fill={props.color}
			/>
			<circle
				cx="40.0452"
				cy="4.50511"
				r="1.50173"
				transform="rotate(-90 40.0452 4.50511)"
				fill={props.color}
			/>
			<circle
				cx="43.7995"
				cy="9.76108"
				r="2.2526"
				transform="rotate(-90 43.7995 9.76108)"
				fill={props.color}
			/>
			<circle
				cx="40.7961"
				cy="17.2699"
				r="2.2526"
				transform="rotate(-90 40.7961 17.2699)"
				fill={props.color}
			/>
			<circle
				cx="3.2526"
				cy="11.263"
				r="2.2526"
				transform="rotate(-90 3.2526 11.263)"
				fill={props.color}
			/>
			<circle
				cx="13.0137"
				cy="9.01021"
				r="3.00346"
				transform="rotate(-90 13.0137 9.01021)"
				fill={props.color}
			/>
			<circle
				cx="22.7751"
				cy="11.263"
				r="2.2526"
				transform="rotate(-90 22.7751 11.263)"
				fill={props.color}
			/>
			<circle
				cx="28.7822"
				cy="5.25641"
				r="3.75433"
				transform="rotate(-90 28.7822 5.25641)"
				fill={props.color}
			/>
			<path
				d="M1 20.2734C1 20.2734 5.50519 12.0139 23.526 20.2734C41.5467 28.533 46.0519 20.2734 46.0519 20.2734"
				stroke={props.color}
				strokeWidth="2"
				strokeLinecap="round"
				fill="transparent"
			/>
		</SvgIcon>
	);
};

export default memo(Pm25);
