import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function addDevice(
	deviceDetails: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "v2.0/device", undefined, deviceDetails);
}

export async function getDeviceList(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "devices");
}

export async function getBuildingDeviceList(
	id: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `devices?building=${id}`);
}

export async function getAddableDeviceList(
	asset: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "devices/addable?asset=" + asset);
}

export async function getAssignableDeviceList(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "devices/assignable");
}

export async function updateDevice(
	deviceDetails: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		`v2.0/device/${deviceDetails._id}`,
		undefined,
		deviceDetails
	);
}

export async function updateRemidiationDevice(
	reqBody: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		`devices/${reqBody.deviceId}`,
		undefined,
		reqBody
	);
}

export async function getCategoryList(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "categories");
}

export async function getSensorsDataList(
	assetId: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "misc/user-dimensions" + "?asset=" + assetId);
}

export async function getMasterDimension(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "misc/master-dimensions");
}

export async function getMonitorDataList(
	buildingId: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "devices?type=monitor&building=" + buildingId);
}

export async function getGaugesDataList(
	buildingId: string,
	assetId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		"v2.0/location/guages/" + buildingId + "?assetId=" + assetId
	);
}

export async function getGatewayDataList(
	buildingId: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "devices?type=gateway&building=" + buildingId);
}

export async function getRemediationDataList(
	buildingId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		"devices?type=remediation&building=" + buildingId
	);
}

export async function getRemediationByBuilding(
	buildingId: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		"devices?type=remediation&building=" + buildingId
	);
}

export async function getGatewayTypeDataList(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "devices/gateway-types");
}

export async function deleteDeviceData(id: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", "devices/" + id);
}

export async function detechDeviceFromLocation(
	id: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("PUT", "devices/" + id);
}

export async function getSyncDeviceList(asset: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "devices/syncDevices?asset=" + asset);
}

export async function getSyncDeviceStatus(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "devices/syncStatus");
}

export async function getDeviceImage(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "misc/device-images");
}

export async function getDeviceCountBasedOnCount(buildingId: string) {
	return apiPlugin<any>("GET", `v2.0/device/${buildingId}/count`);
}

export async function getBuildingDetails(assetId: string) {
	return apiPlugin<any>("GET", `v2.0/device-management/${assetId}`);
}

export async function getDeviceControlls(deviceId: string) {
	return apiPlugin<any>("GET", `v2.0/device-management/controls/${deviceId}`);
}

export async function changeDeviceControlls(deviceId: string, payload: any) {
	return apiPlugin<any>(
		"PUT",
		`v2.0/device-management/controls/${deviceId}`,
		undefined,
		payload
	);
}

export async function getMonitorTypeData(
	buildingId: string,
	assetId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		"v2.0/location/monitor/" + buildingId + "?assetId=" + assetId
	);
}

export async function getProductData(
	deviceId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `devices/product-data?device_id=${deviceId}`);
}

export async function getCategoriesList(
	isInterops: boolean
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `categories?isInterops=${isInterops}`);
}

export async function resetMaintenanceReport(
	apiPayload: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"devices/maintenance/reset",
		undefined,
		apiPayload
	);
}

export async function getDeviceFeatureAndCategory(
	apiPayload: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"devices/product/catogery-feature",
		undefined,
		apiPayload
	);
}

export async function checkCurrentlyRunningAutopilot(
	deviceId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `v2.0/device/autopilot-running/${deviceId}`);
}
