import { combineReducers } from "redux";
import { buildingReducer, buildingsReducer } from "./building";
import { devicesReducer } from "./device";
import { locationReducer } from "./location";
import { userReducer } from "./user";
import { assetReducer, assetsReducer } from "./asset";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
	key: "root",
	storage, // used local-storage
	whitelist: ["building", "buildings", "user", "asset", "assets"], // add reducers name you want to persistance
};

const rootReducer = combineReducers({
	building: buildingReducer,
	buildings: buildingsReducer,
	devices: devicesReducer,
	locations: locationReducer,
	user: userReducer,
	asset: assetReducer,
	assets: assetsReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export default persistReducer(persistConfig, rootReducer);
