import PeopleRoundedIcon from "@material-ui/icons/PeopleRounded";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import TuneIcon from "@material-ui/icons/Tune";
import HelpIcon from "@material-ui/icons/Help";

import { URL } from "./routes";
import { IMenuItem } from "../../common/Interface";

import "./Navbar.scoped.scss";

const menuList: IMenuItem[] = [
	{
		title: "Buildings",
		icon: <LocationCityRoundedIcon className="navbar-menu-icon" />,
		path: URL.DASHBOARD_BUILDINGS,
	},
	{
		title: "Dashboard",
		icon: <DashboardIcon className="navbar-menu-icon" />,
		path: URL.DASHBOARD,
	},
	{
		title: "Floorplans",
		icon: <LocationOnIcon className="navbar-menu-icon" />,
		path: URL.FLOOR_PLAN_LIST,
	},
	{
		title: "Autofix",
		icon: <TuneIcon className="navbar-menu-icon" />,
		path: URL.CLUSTER_CONFIG,
	},
	{
		title: "Consultation",
		icon: <AssessmentIcon className="navbar-menu-icon" />,
		path: URL.CONSULTATION_REPORTS,
	},
	{
		title: "Schedules",
		icon: <DateRangeIcon className="navbar-menu-icon" />,
		path: URL.SCHEDULE,
	},
	{
		title: "Reports",
		icon: <AssessmentIcon className="navbar-menu-icon" />,
		iconOpened: <ExpandLess className="navbar-menu-icon" />,
		iconClosed: <ExpandMore className="navbar-menu-icon" />,
		subNav: [
			{
				title: "IAQ",
				path: URL.IAQ_REPORTS,
			},
			{
				title: "Maintenance",
				path: URL.MAINTENANCE_REPORTS,
			},
			{
				title: "Autofix",
				path: URL.AUTOPILOT_REPORTS,
			},
		],
	},
];

const adminMenu: IMenuItem[] = [
	{
		title: "Admin",
		icon: <PeopleRoundedIcon className="navbar-menu-icon" />,
		iconOpened: <ExpandLess className="navbar-menu-icon" />,
		iconClosed: <ExpandMore className="navbar-menu-icon" />,
		subNav: [
			{
				title: "Device Management",
				path: URL.DEVICE_MANAGEMENT,
			},
			{
				title: "Customer Accounts",
				path: URL.CUSTOMER_ACCOUNT,
			},
			{
				title: "Data Sim",
				path: URL.DATA_SIM,
			},
			{
				title: "Subscription",
				path: URL.OFFLINE_SUBSCRIPTION,
			},
		],
	},
];

const adminIAQMenu: IMenuItem[] = [
	{
		title: "Admin",
		icon: <PeopleRoundedIcon className="navbar-menu-icon" />,
		iconOpened: <ExpandLess className="navbar-menu-icon" />,
		iconClosed: <ExpandMore className="navbar-menu-icon" />,
		subNav: [
			{
				title: "Device Management",
				path: URL.DEVICE_MANAGEMENT,
			},
			{
				title: "Customer Accounts",
				path: URL.CUSTOMER_ACCOUNT,
			},
			{
				title: "Data Sim",
				path: URL.DATA_SIM,
			},
			{
				title: "Subscription",
				path: URL.OFFLINE_SUBSCRIPTION,
			},
		],
	},
];

const userAdminMenu: IMenuItem[] = [
	{
		title: "Admin",
		icon: <PeopleRoundedIcon className="navbar-menu-icon" />,
		iconOpened: <ExpandLess className="navbar-menu-icon" />,
		iconClosed: <ExpandMore className="navbar-menu-icon" />,
		subNav: [
			{
				title: "Device Management",
				path: URL.DEVICE_MANAGEMENT,
			},
		],
	},
];

const productMenu: IMenuItem[] = [
	{
		title: "Quick Help",
		icon: <HelpIcon className="navbar-menu-icon" />,
		path: URL.DASHBOARD_PRODUCT_HELp,
	},
];

export { adminMenu, menuList, userAdminMenu, productMenu, adminIAQMenu };
