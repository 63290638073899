import React, { useEffect, useState } from "react";
import { Link, Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import {
	getMarketPlaceCategory,
	getMarketPlaceProduct,
	doFavouriteUnFavourite,
} from "../../../api/MarketPlaceController";
import get from "lodash/get";
import "./style.scoped.scss";
import SearchIcon from "@material-ui/icons/Search";
import ProductCategory from "./Components/ProductCategory";
import AllProducts from "./Components/AllProducts";
import FavouriteProduct from "./Components/FavouriteProduct";
import { SpinnerLoader } from "../../../common/Global.Style";
import MobileBack from "../../../components/MobileBack";
import { URL } from "../../../components/Navbar/routes";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { UserRoles } from "../../../enums/UserRoles";

const IAQProduct: React.FC = () => {
	const [productCategoryList, setProductCategoryList] = useState([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [productList, setProductList] = useState([]);
	const [productFavouriteList, setProductFavouriteList] = useState([]);
	const user = useSelector((state: RootState) => state.user);
	const [search, setSearch] = useState("");
	const [isViewReportAvailable, setIsViewReportAvailable] = useState(true);
	useEffect(() => {
		getProductsApiCall();
		getProductCategoryApiCall();
	}, []);

	const addToFavourite = async (productId: any) => {
		setIsLoading(true);
		const response = await doFavouriteUnFavourite({ product: productId });
		if (response?.responseObj?.message) {
			getProductsApiCall();
		}
	};
	const onSearchSubmit = (e: any) => {
		e.preventDefault();
		getProductsApiCall();
	};
	const getProductCategoryApiCall = async () => {
		const response = await getMarketPlaceCategory();
		if (response?.responseObj?.length) {
			setProductCategoryList(get(response, "responseObj", []));
		}
	};
	const getProductsApiCall = async () => {
		setIsLoading(true);
		const response = await getMarketPlaceProduct("", "", search);
		if (response?.responseObj?.length) {
			const products = get(response, "responseObj", []);
			const favorite = products?.filter((p: any) => {
				if (p?.favorite && p?.favorite[0]) return p?.favorite[0]?.isFavourite;
			});
			setProductFavouriteList(favorite);
			setProductList(products);
		} else {
			setProductFavouriteList([]);
			setProductList([]);
		}
		setIsLoading(false);
	};
	return (
		<div className="iaq-product-section w-100">
			<div className="mobile-header d-none m-d-flex align-items-center justify-content-between bg-white p-12 px-3">
				<MobileBack redirect={URL.IAQSUBMENU} heading="Products" />
				{isViewReportAvailable && (
					<div>
						<Button className="btn-medium p-0 lh-normal font-600 ls-024 txt-primary">
							<NavLink className="txt-primary" to={URL.CONSULTATION_REPORTS}>
								View Reports
							</NavLink>
						</Button>
					</div>
				)}
			</div>
			<div className="bg-white px-3 py-2">
				<div className="icon-input">
					<form className="position-relative" onSubmit={onSearchSubmit}>
						<input
							name="search"
							onChange={(e) => setSearch(e.target.value)}
							className="add-re-input form-control pt-2 pb-2"
							placeholder="Search Products"
						/>
						<SearchIcon className="search-icon" />
					</form>
				</div>
			</div>
			<div className="mt-1 p-12 px-3 w-100 d-flex align-items-start justify-content-between overflow-auto scroll-hidden bg-white c-g-20">
				{productCategoryList.map((category: any) => {
					return <ProductCategory category={category} />;
				})}
			</div>
			{productFavouriteList.length > 0 && (
				<div className="mt-1 p-12 px-3 w-100 bg-white favourite-section">
					<div className="mb-3 d-flex align-items-center justify-content-between">
						<p className="mb-0 desc-exlarge ls-024 font-700 font-primary">
							Favourite
						</p>
						<NavLink
							className="desc-normal font-600 ls-024 lh-18 txt-primary"
							to={URL.IAQALLPRODUCT}
						>
							View All
						</NavLink>
					</div>
					<div className="d-flex align-items-stretch overflow-auto scroll-hidden c-g-8 fav-list-section">
						{productFavouriteList.map((products: any) => {
							return (
								<FavouriteProduct
									products={products}
									addToFavourite={addToFavourite}
								/>
							);
						})}
					</div>
				</div>
			)}
			<div className="mt-1 p-12 px-3 w-100 bg-white all-product-section">
				<div className="mb-3 d-flex align-items-center justify-content-between">
					<p className="mb-0 desc-exlarge ls-024 font-700 font-primary">
						All Products
					</p>
				</div>
				<div className="product-list-section w-100 c-g-8 r-g-8">
					{isLoading && <SpinnerLoader />}
					{productList.map((products: any) => {
						return (
							<AllProducts
								products={products}
								addToFavourite={addToFavourite}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default IAQProduct;
