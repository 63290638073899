import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface DownloadPDFDialogProps {
	downloadPDFLink: string;
	closeModal: () => void;
}

const DownloadPDFDialog: React.FC<DownloadPDFDialogProps> = ({
	downloadPDFLink,
	closeModal,
}) => {
	const download = () => {
		window.open(downloadPDFLink);
		closeModal();
	};

	return (
		<>
			<Modal
				show={true}
				backdrop="static"
				keyboard={false}
				className="delete-popup"
				onHide={() => closeModal()}
			>
				<Modal.Header closeButton>
					<Modal.Title>Download Reports</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Click the download button to get your report.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						type="submit"
						variant="primary"
						style={{ cursor: "pointer" }}
						onClick={download}
					>
						Download
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default DownloadPDFDialog;
