import React, { memo } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import isEmpty from "lodash/isEmpty";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";

import "./DeviceManagementTable.scoped.scss";

interface IDeviceManagementTable {
	tableData: any;
	onEdit: (id: string) => void;
	onDelete: (id: string) => void;
	openControll?: (data: any) => void;
}

const DeviceManagementTable: React.FC<IDeviceManagementTable> = ({
	tableData,
	onEdit,
	onDelete,
	openControll,
}) => {
	const renderStatus = (deviceData: any) => {
		switch (deviceData.deviceType) {
			case "monitor":
			case "gateway":
				return (
					<p
						className={`${
							deviceData.online ? "text-success" : "text-danger"
						} desc-small font-primary mb-0`}
					>
						<FiberManualRecordIcon className="i-18 mr-1" />
						{deviceData.online ? "Online" : "Offline"}
					</p>
				);

			case "remediation":
			case "interops":
				return (
					<>
						{deviceData?.online && (
							<div>
								<p
									className={`${
										deviceData.status ? "text-success" : "text-danger"
									} desc-small font-primary mb-0`}
								>
									<FiberManualRecordIcon className="i-18 mr-1" />
									{deviceData.status ? "ON" : "OFF"}
								</p>
							</div>
						)}
						{!deviceData?.online && (
							<p className={`text-secondary desc-small font-secondary mb-0`}>
								<FiberManualRecordIcon className="i-18 mr-1" />
								{"Offline"}
							</p>
						)}
					</>
				);
		}
	};

	return (
		<TableContainer className="mt-4" component={Paper}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>DEVICE NAME</TableCell>
						<TableCell align="left">DEVICE ID</TableCell>
						<TableCell align="left">DEVICE IMAGE</TableCell>
						<TableCell align="left">DEVICE TYPE</TableCell>
						<TableCell align="left">DEVICE DESCRIPTION</TableCell>
						<TableCell align="left">STATUS</TableCell>
						<TableCell align="left">CONTROL</TableCell>
						<TableCell align="left">ACTION</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{tableData.map((row: any, index: number) => (
						<TableRow className="table-row" key={`table-${index}`}>
							<TableCell>{row.deviceName}</TableCell>
							<TableCell align="left">{row.deviceId}</TableCell>
							<TableCell align="left">
								<img
									className="w-10 h-10 product-image"
									src={row.image ? row.image : "/images/device1.svg"}
									alt=""
								/>
							</TableCell>
							<TableCell align="left">{row.deviceType}</TableCell>
							<TableCell align="left">{row.description}</TableCell>
							<TableCell align="left">{renderStatus(row)}</TableCell>
							<TableCell align="left">
								{["remediation", "interops"].includes(row.deviceType) && (
									<IconButton
										onClick={() => {
											openControll && openControll(row);
										}}
									>
										<SettingsIcon />
									</IconButton>
								)}
							</TableCell>
							<TableCell align="left">
								<div className="device-list-edit">
									<IconButton>
										<EditIcon onClick={() => onEdit(row)} />
									</IconButton>
									<IconButton onClick={() => onDelete(row)}>
										<DeleteIcon />
									</IconButton>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{isEmpty(tableData) && (
				<div className="d-flex justify-content-center py-3 table-no-rec">
					<p className="text-center">Sorry, no matching records found</p>
				</div>
			)}
		</TableContainer>
	);
};

export default memo(DeviceManagementTable);
