import { SvgIcon } from "@material-ui/core";
import { memo } from "react";

const Humidity = (props) => {
	return (
		<SvgIcon
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="mr-1"
		>
			<g clipPath="url(#clip0)">
				<path
					d="M11.9138 21.9994C14.1393 21.9894 16.2699 21.0971 17.8384 19.5183C19.4069 17.9395 20.2852 15.803 20.2806 13.5775C20.2806 7.39002 12.6975 0.47033 12.3744 0.171267C12.2481 0.0572524 12.0839 -0.00585937 11.9138 -0.00585937C11.7436 -0.00585938 11.5795 0.0572524 11.4531 0.171267C11.13 0.463455 3.54689 7.39002 3.54689 13.5775C3.54232 15.803 4.42061 17.9395 5.98911 19.5183C7.55762 21.0971 9.68828 21.9894 11.9138 21.9994ZM11.9138 1.63564C13.5397 3.22721 18.9056 8.83377 18.9056 13.5775C18.9263 14.509 18.7607 15.4352 18.4185 16.3018C18.0763 17.1683 17.5645 17.9578 16.913 18.6239C16.2615 19.2899 15.4836 19.8192 14.6248 20.1805C13.766 20.5418 12.8437 20.7279 11.912 20.7279C10.9804 20.7279 10.0581 20.5418 9.19928 20.1805C8.34051 19.8192 7.56256 19.2899 6.9111 18.6239C6.25964 17.9578 5.74779 17.1683 5.40561 16.3018C5.06343 15.4352 4.8978 14.509 4.91846 13.5775C4.91846 8.83377 10.2844 3.22721 11.9138 1.63564Z"
					fill={props.color}
				/>
				<path
					d="M11.9171 19.249C13.415 19.2381 14.8474 18.6333 15.8999 17.5673C16.9523 16.5014 17.5388 15.0613 17.5305 13.5634C17.5305 13.513 17.5305 13.4614 17.5305 13.4087C17.5203 13.2909 17.4831 13.1771 17.4217 13.076C17.3604 12.975 17.2767 12.8894 17.177 12.8259C17.0772 12.7624 16.9642 12.7227 16.8467 12.7099C16.7292 12.6972 16.6103 12.7116 16.4993 12.7521C15.8846 12.9006 15.2547 12.9767 14.6224 12.979C12.0993 12.9756 10.8858 11.3428 9.33209 11.3428C7.77834 11.3428 6.23834 12.4221 6.23834 13.859C6.22459 16.994 8.82334 19.249 11.9171 19.249Z"
					fill={props.color}
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="22" height="22" fill={props.color} />
				</clipPath>
			</defs>
		</SvgIcon>
	);
};

export default memo(Humidity);
