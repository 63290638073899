import React from "react";
import get from "lodash/get";
import Reading from "./Reading";
import moment from "moment";

import "./MonitorList.scoped.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
interface Props {
	monitorList: any[];
	colorStatus: any;
}

const MonitorList: React.FC<Props> = (props) => {
	const { monitorList } = props;
	const building: any = useSelector((state: RootState) => state.building);

	const buildingUpdateTime = (date: any) =>
		moment(date)
			.tz(building?.timeZoneName || "EST5EDT")
			.format("DD MMM hh:mm");
	return (
		<div className="row flex-grow-1 w-100">
			{monitorList &&
				monitorList.length > 0 &&
				monitorList.map((device: any, index: number) => {
					return (
						<div
							key={`${index}-${device.name}`}
							className="col-xl-6 col-m-sm-12 list-monitor-container mb-3 col"
						>
							<div className="listmonitor">
								<div className="d-flex justify-content-between bg-white para-title">
									<div className="pt-2 w-100">
										<h6 className="text-center">{device.name}</h6>
									</div>
								</div>
								<div className="row w-100 sensor-value-row flex-nowrap bg-white m-flex-wrap">
									<Reading
										name="Temperature"
										unit="F"
										readingData={get(device, "currentReadings.temp_current_f")}
									/>
									<Reading
										name="Humidity"
										unit="%"
										readingData={get(device, "currentReadings.humidity_value")}
									/>
									<Reading
										name="CO2"
										unit="ppm"
										readingData={get(device, "currentReadings.co2_value")}
									/>
									<Reading
										name="PM2.5"
										unit="ug/m3"
										readingData={get(device, "currentReadings.pm25_value")}
									/>
									<Reading
										name="PM10"
										unit="ug/m3"
										readingData={get(device, "currentReadings.pm10")}
									/>
									<Reading
										name="PM1.0"
										unit="ug/m3"
										readingData={get(device, "currentReadings.pm1")}
									/>
									<Reading
										name="TVOC"
										unit="ppb"
										readingData={get(device, "currentReadings.voc_value")}
									/>
									<Reading
										name="Formaldehyde"
										unit="ppb"
										readingData={get(device, "currentReadings.ch2o_value")}
									/>
								</div>
								{device?.device && (
									<div className="last-updated-time">
										<p className="m-0">
											Last updated:{" "}
											{buildingUpdateTime(device?.recentUpdatedDate)}
										</p>
									</div>
								)}
							</div>
							{device?.device && !device?.device?.online && (
								<div className="info position-absolute extra-info px-0 d-block">
									<p className="mb-0 text-white text-justify text-center text-break">
										Monitor is Offline
									</p>
								</div>
							)}
							{!device?.device && (
								<div className="info position-absolute extra-info px-0 d-block">
									<p className="mb-0 text-white text-justify text-center text-break">
										No Monitor Found
									</p>
								</div>
							)}
						</div>
					);
				})}
			{monitorList.length == 0 && (
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 text-center">
					No Monitor Found
				</div>
			)}
		</div>
	);
};
export default MonitorList;
