import { call, put, takeLatest } from "redux-saga/effects";
import { GET_DEVICES } from "../actions/actionTypes";
import { getDeviceList } from "../../api/DevicesController";
import { setDevices } from "../actions/deviceActions";

interface DeviceResponse {
	responseObj: DeviceList;
	message: string;
}

interface DeviceList {
	devices: Array<object>;
}

export function* watchGetDeviceRequests() {
	yield takeLatest(GET_DEVICES, fetchDeviceList);
}

function* fetchDeviceList(action: any) {
	try {
		if (action.payload.length === 0) {
			const response: DeviceResponse = yield call(getDeviceList);
			if (response.responseObj) {
				const deviceList = response.responseObj.devices;
				yield put(setDevices(deviceList));
			} else {
				yield put(setDevices([]));
			}
		}
	} catch (error) {}
}
