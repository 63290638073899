import React, { useEffect, useState } from "react";
import "./style.scoped.scss";
import { getMarketPlaceProductById } from "../../../api/MarketPlaceController";
import { SpinnerLoader } from "../../../common/Global.Style";
import get from "lodash/get";
import MobileBack from "../../../components/MobileBack";
import { URL } from "../../../components/Navbar/routes";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles((theme: any) => ({
	root: {
		maxWidth: 400,
		flexGrow: 1,
	},
	header: {
		display: "flex",
		alignItems: "center",
		height: 50,
		paddingLeft: theme.spacing(4),
		backgroundColor: theme.palette.background.default,
	},
}));
const ViewProduct: React.FC = (props: any) => {
	const { match } = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [product, setProduct] = useState<any>();
	const classes = useStyles();
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);
	const [images, setImages] = useState<any>([]);
	const [imageLength, setImageLength] = useState<any>(0);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step: any) => {
		setActiveStep(step);
	};

	useEffect(() => {
		getProductsApiCall();
	}, []);
	const getProductsApiCall = async () => {
		setIsLoading(true);

		const response = await getMarketPlaceProductById(match?.params?.id);
		if (response?.responseObj) {
			setProduct(response?.responseObj);
			let img = response?.responseObj?.images.map(function (row: any) {
				return {
					imgPath: row,
				};
			});
			setImages(img);
			setImageLength(img.length);
		}
		setIsLoading(false);
	};

	return (
		<div className="radius-6 w-100 product-list d-flex flex-column bg-white">
			<div className="mobile-header d-none m-d-flex align-items-center justify-content-between bg-white p-12 px-3">
				<MobileBack />
			</div>
			{product && (
				<div className="iaq-product-section individial-section p-3">
					<div className={classes.root}>
						<AutoPlaySwipeableViews
							axis={theme.direction === "rtl" ? "x-reverse" : "x"}
							index={activeStep}
							onChangeIndex={handleStepChange}
							enableMouseEvents
						>
							{images?.map((step: any, index: any) => (
								<div
									className="product-img w-100 d-flex justify-content-center align-items-end product-img-fill"
									key={step.label}
								>
									{Math.abs(activeStep - index) <= 2 ? (
										<img
											className="w-100 h-100"
											src={step.imgPath}
											alt={step.label}
										/>
									) : null}
								</div>
							))}
						</AutoPlaySwipeableViews>
						<MobileStepper
							className="bg-white"
							steps={imageLength}
							position="static"
							variant="dots"
							activeStep={activeStep}
							nextButton={
								<Button
									size="small"
									onClick={handleNext}
									disabled={activeStep === imageLength - 1}
								>
									{theme.direction === "rtl" ? (
										<KeyboardArrowLeft />
									) : (
										<KeyboardArrowRight />
									)}
								</Button>
							}
							backButton={
								<Button
									size="small"
									onClick={handleBack}
									disabled={activeStep === 0}
								>
									{theme.direction === "rtl" ? (
										<KeyboardArrowRight />
									) : (
										<KeyboardArrowLeft />
									)}
								</Button>
							}
						/>
					</div>
					<div className="pt-4 d-flex w-100">
						<div className="flex-1 text-truncate pr-2">
							<p className="text-truncate mb-3 desc-exlarge lh-24 ls-024 font-text-4">
								{product.name}
							</p>
							{/* <p className="text-truncate mb-0 i-28 ls-024 font-700 font-text-4">
								${product?.price ? product?.price : "0"}
							</p> */}
							<p className="desc-large font-600 ls-024 lh-20 font-text-4 mb-2">
								Description
							</p>
							<p className="desc-large ls-024 lh-24 font-grey-light-1 mb-0 text-wrap">
								{product.description}
							</p>
						</div>
					</div>
				</div>
			)}
			{isLoading && <SpinnerLoader />}
		</div>
	);
};
export default ViewProduct;
