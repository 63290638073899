import React, { memo } from "react";

import "./DeviceCount.scopped.scss";

interface DeviceCountProps {
	deviceData: {
		monitorCount: number;
		remCount: number;
		uvCount: number;
	};
}

const DeviceCount: React.FC<DeviceCountProps> = ({ deviceData }) => {
	return (
		<div className="box7 row m-m-auto p-0 col-lg-12">
			<div className="air1 col col-m-sm-12 m-mt-3 p-0 bg-white d-flex justify-content-around align-items-center">
				<div className="w-100 p-3 d-flex align-items-center">
					<img className="" src="/images/air2.svg" alt="" />

					<h3 className="mb-0 ml-2">
						{deviceData?.monitorCount ? deviceData?.monitorCount : 0}
					</h3>
					<p className="mt-0 mb-0 text-black ai ml-2">Air Quality Monitors</p>
				</div>
			</div>
			<div className="air1 col col-m-sm-12 m-mt-3 p-0 bg-white d-flex justify-content-around align-items-center">
				<div className="w-100 p-3 d-flex align-items-center">
					<img className="" src="/images/air3.svg" alt="" />

					<h3 className="mb-0 ml-2">
						{deviceData?.remCount ? deviceData?.remCount : 0}
					</h3>
					<p className="mt-0 ml-2 mb-0 text-black ai">Air Purifiers</p>
				</div>
			</div>
			<div className="air1 col col-m-sm-12 m-mt-3 p-0 bg-white d-flex justify-content-around align-items-center">
				<div className="w-100 p-3 d-flex align-items-center">
					<img className="" src="/images/air4.svg" alt="" />

					<h3 className="mb-0 ml-2">
						{deviceData?.uvCount ? deviceData?.uvCount : 0}
					</h3>
					<p className="mt-0 ml-2 mb-0 text-black ai">UV Devices</p>
				</div>
			</div>
		</div>
	);
};

export default memo(DeviceCount);
