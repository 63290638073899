import React, { useState, useEffect } from "react";
import { SelectableRows } from "mui-datatables";
import Typography from "@material-ui/core/Typography";
import { Dropdown } from "react-bootstrap";
import { Box, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import get from "lodash/get";
import PeopleRoundedIcon from "@material-ui/icons/PeopleRounded";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import GroupIcon from "@material-ui/icons/Group";
import BusinessIcon from "@material-ui/icons/Business";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";

import { setAsset, setAssets } from "../../redux/actions/assetActions";
import { SpinnerLoader } from "../../common/Global.Style";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import {
	getContractorAssets,
	deleteAsset,
	updateAsset,
	deleteAssetUser,
	getAdminAssets,
	isAssetHasDevices,
} from "../../api/AssetController";
import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import CreateAsset from "../../components/CreateAsset/CreateAsset";
import ViewAsset from "../../components/ViewAsset/ViewAsset";
import EditAsset from "../../components/EditAsset/EditAsset";
import { RootState } from "../../redux/reducers";
import SearchIcon from "@material-ui/icons/Search";
import { UserRoles } from "../../enums/UserRoles";
import SystemTour from "../../components/SystemTour/SystemTour";

import "./Assets.scoped.scss";
import AssignUserModal from "../../components/AssignUserModal/AssignUserModal";
import {
	setupWebViewJavascriptBridge,
	registerHandler,
} from "../../common/JsBridge";

import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
export interface Building {
	name: string;
	_id: string;
	userId: string;
	address: string;
	locations: number;
	good: number;
	notGood: number;
	chartData: string[];
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popover: {
			pointerEvents: "none",
		},
		paper: {
			padding: theme.spacing(1),
		},
	})
);

function MouseOverPopover({ columnData }: any) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	const handlePopoverOpen = (
		event: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	return (
		<div className="d-flex">
			<IconButton
				aria-owns={open ? "mouse-over-popover" : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
				size="small"
				className="ml-2"
			>
				<MoreHorizIcon />
			</IconButton>
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				{columnData?.map((mapData: any) => (
					<p className="mb-0 p-1">{mapData}</p>
				))}
			</Popover>
		</div>
	);
}

const Assets: React.FC = () => {
	const user = useSelector((state: RootState) => state.user);
	const history = useHistory();

	const [showAssetModal, setShowAssetModal] = React.useState(false);
	const [viewAssetData, setViewAssetData] = useState<any>({});
	const [showAssetView, setShowAssetView] = React.useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [assetToDelete, setAssetToDelete] = useState<string>("");
	const [assetsList, setAssetsList] = useState<Array<any>>([]);
	const [initialAsetsList, setInitialAssetsList] = useState<Array<any>>([]);
	const [isShownConfirmDialog, setIsShownConfirmDialog] =
		useState<boolean>(false);
	const [isShownAssignDialog, setIsShownAssignDialog] =
		useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const [popoverData, setPopOverData] = useState<any>({});
	const [showEditAsset, setShowEditAsset] = useState<boolean>(false);
	const [isCreateAssetLimitReached, setCreateAssetLimitReached] =
		useState<false>(false);

	const [assetDeleteMessage, setAssetDeleteMessage] = useState<string>("");

	let assetList: Array<object> = useSelector(
		(state: RootState) => state.assets
	);

	const dispatch = useDispatch();

	useEffect(() => {
		initialApiLoading();
	}, []);

	const checkAssetLimitReached = (assetListData: any) => {
		if (user.role === UserRoles.ADMIN) {
			let limitReached = false;
			assetListData.forEach((assetData: any) => {
				if (assetData?.createdBy?.email === user.email) {
					limitReached = true;
				}
			});
			setCreateAssetLimitReached(limitReached);
		}
	};

	const initialApiLoading = async () => {
		const response = await getAssetData();
		const params = new URLSearchParams(window.location.search);

		if (!params.has("edit")) {
			return;
		}

		const editAsset: any = response.responseObj?.find(
			(asset: any) => asset._id === params.get("edit")
		);

		if (!editAsset) {
			return;
		}
		setViewAssetData(processViewAssetData(editAsset));
		setShowEditAsset(true);
	};

	const updateAssetAction = async (apiData: any) => {
		setIsLoading(true);
		const response = await updateAsset(apiData, viewAssetData._id);
		setIsLoading(false);
		if (response.responseObj) {
			setShowEditAsset(false);
			getAssetData();
		}
	};

	const getAssetData = async () => {
		let response: any = { responseObj: {} };
		setIsLoading(true);
		if (user.role === UserRoles.ADMIN) {
			response = await getAdminAssets();
			if (response?.responseObj?.response)
				response.responseObj = [response?.responseObj?.response];
		} else if ([UserRoles.CONTRACTOR].includes(user?.role)) {
			response = await getContractorAssets();
		}
		setIsLoading(false);
		if (response?.responseObj?.length) {
			setAssetsList(response?.responseObj);
			setInitialAssetsList(response?.responseObj);
			checkAssetLimitReached(response?.responseObj);
			dispatch(setAssets(response?.responseObj));
			dispatch(setAsset(response?.responseObj[0]));
		} else {
			toast.error(response.error);
		}
		return response;
	};

	const updateAssetData = async () => {
		const response = await getAssetData();

		setViewAssetData((prevData: any) => {
			let findData = response.responseObj.find(
				(mapData: any) => mapData._id === prevData._id
			);
			return processViewAssetData(findData);
		});
		dispatch(setAssets(response.responseObj));
	};

	const openAddAssetPopUp = () => {
		setShowAssetModal(true);
		setViewAssetData({});
	};

	const confirmDeleteAsset = async (assetId: any) => {
		setIsLoading(true);
		const response = await isAssetHasDevices(assetId);
		const hasDevice = response?.responseObj?.result;

		if (hasDevice) {
			setAssetDeleteMessage(
				"There are devices paired into this account, do you want to delete all the devices and delete the account permanently?"
			);
		} else {
			setAssetDeleteMessage("Are you sure you want to delete this Customer?");
		}

		setAssetDeleteMessage("Are you sure you want to delete this Customer?");

		setIsLoading(false);

		setIsShownConfirmDialog(true);
		setAssetToDelete(assetId);
	};

	const handleClick = (event: any, value: any) => {
		setAnchorEl(event.currentTarget);
		setPopOverData(value);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDeleteAsset = () => {
		setIsShownConfirmDialog(false);
		setIsLoading(true);
		deleteAsset(assetToDelete)
			.then((response: any) => {
				setIsLoading(false);
				if (response && response?.responseObj?.message) {
					getAssetData();
					toast.success(response.responseObj.message);
				} else if (response?.error) {
					toast.error(response.error);
				}
			})
			.catch((error: any) => {
				setIsLoading(false);
				toast.error(error?.message);
			});
	};

	const deleteUserFromAsset = async (userData: any) => {
		setIsLoading(true);
		const response = await deleteAssetUser({
			user_id: userData._id,
			asset_id: viewAssetData._id,
			isAdmin: userData.write,
		});
		setIsLoading(false);
		if (response.responseObj) {
			toast.success(response?.responseObj?.message);
			updateAssetData();
		} else {
			toast.error(response.error);
		}
	};

	const processViewAssetData = (arrayData: any) => {
		let findData = cloneDeep(arrayData);
		findData.authorized_users = [
			get(findData, "createdBy", {}),
			...findData.authorized_users,
		];
		return findData;
	};

	const dialogCloseClick = () => {
		setIsShownConfirmDialog(false);
		setAssetToDelete("");
	};

	const options = {
		print: false,
		download: false,
		filter: false,
		responsive: "vertical",
		viewColumns: false,
		downloadOptions: {
			filename: "CustomerAccount.csv",
		},
		selectableRows: "none" as SelectableRows,

		onCellClick: (row: any, index: any) => {
			if (index.colIndex < 4) {
				setShowAssetView(true);
				setAssetsList((prevData) => {
					setViewAssetData(processViewAssetData(prevData[index.dataIndex]));
					return prevData;
				});
			}
		},
	};

	const onEditClick = (data: any) => {
		setViewAssetData(processViewAssetData(data));
		setShowAssetView(true);
	};

	const onAssignClick = () => {
		setIsShownAssignDialog(true);
	};

	const searchAsset = (e: any) => {
		let { value } = e.target;
		value = value.toLowerCase();
		setAssetsList(
			initialAsetsList.filter((filterData: any) =>
				filterData?.name?.toLowerCase()?.includes(value)
			)
		);
	};

	const open = Boolean(anchorEl);
	const id = open ? "user-popover" : undefined;

	const columns = [
		{
			name: "_id",
			options: {
				filter: false,
				display: false, // hide this filed in Data-Table
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <p className="font-primary font-700 mb-0">{value}</p>;
				},
			},
		},
		{
			name: "customerNo",
			label: "Customer No",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <p className="mb-0">{value ? value : "-"}</p>;
				},
			},
		},
		{
			name: "alertContact",
			label: "Alert Contact",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const arrayData = value?.split(",");
					let slicedData = arrayData?.slice(0, 2);
					slicedData = slicedData?.join(",");
					return (
						<div className="d-flex align-items-center">
							<Box display="flex" flexWrap="wrap" className="alertEmailContact">
								{slicedData}
							</Box>
							{arrayData?.length > 2 && (
								<MouseOverPopover columnData={arrayData} />
							)}
						</div>
					);
				},
			},
		},
		{
			name: "alertEmail",
			label: "Alert Email",

			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const arrayData = value?.split(",");
					let slicedData = arrayData?.slice(0, 2);
					slicedData = slicedData?.join(",");
					return (
						<div className="d-flex align-items-center">
							<Box display="flex" flexWrap="wrap" className="alertEmailContact">
								{slicedData}
							</Box>
							{arrayData?.length > 2 && (
								<MouseOverPopover columnData={arrayData} />
							)}
						</div>
					);
				},
			},
		},
		{
			name: "authorized_users",
			label: "Authorized Users",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					let slicedData = value;
					slicedData = [
						{
							firstName: user.firstName,
							lastName: user.lastName,
							email: user.email,
						},
						...value,
					].splice(0, 4);

					return (
						<Box display="flex" flexWrap="wrap">
							{slicedData.length ? (
								<div className="d-flex">
									{slicedData.map((item: any) => (
										<Tooltip title={item?.email} placement="top">
											<div className="user-img">
												<span className="user-icon">{item.firstName[0]}</span>
											</div>
										</Tooltip>
									))}

									{value.length > 4 && (
										<div
											onClick={(event) => {
												handleClick(
													event,
													get(tableMeta, `tableData[${tableMeta.rowIndex}]`, {})
												);
											}}
											className="user-img cursor-pointer"
										>
											<span className="user-count cursor-pointer">{`+${
												value.length + 1 - 4
											}`}</span>
										</div>
									)}
								</div>
							) : (
								<Typography variant="caption" color="initial">
									None
								</Typography>
							)}
						</Box>
					);
				},
			},
		},
		{
			name: "_id",
			label: " ",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="d-flex justify-content-end table-actions">
							<IconButton
								aria-label="Edit Customer Button"
								onClick={() => {
									setViewAssetData(
										processViewAssetData(assetsList[tableMeta.rowIndex])
									);
									setShowAssetView(true);
								}}
							>
								<Edit />
							</IconButton>
							<IconButton
								aria-label="Delete Customer Button"
								onClick={() => confirmDeleteAsset(value)}
							>
								<DeleteOutlineIcon />
							</IconButton>
						</div>
					);
				},
			},
		},
	];

	const tourSteps = [
		{
			selector: "",
			content:
				"This page helps you to manage all your customer accounts and users for each customer account.",
		},
		{
			selector: ".assign-admin",
			content: `Click here to assign one or more customer accounts to a new or existing user as Admin`,
		},
		{
			selector: ".add-customer",
			content: `Click here to add a new customer account to install devices and manage air quality for the customer. `,
		},
	];

	const closeEditModal = (isCallFromSave: boolean) => {
		setIsShownAssignDialog(false);
	};
	const goBack = () => {
		history.goBack();
	};

	useEffect(() => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			registerHandler("addNewCustomerFromNative", function (data: any) {
				openAddAssetPopUp();
			});
		});
	}, []);
	return (
		<>
			<div className="customer-account-page p-4 m-p-0 w-100">
				{/* <SystemTour tourSteps={tourSteps} position={5} /> */}
				<div className="customer-account-content">
					{!isCreateAssetLimitReached && window.innerWidth > 1024 && (
						<div className="d-flex justify-content-end p-4 m-d-none">
							<button
								className="btn primary-btn assign-admin ml-3"
								onClick={onAssignClick}
							>
								Assign Admin to Accounts
							</button>
							<button
								className="btn primary-btn add-customer ml-3"
								onClick={openAddAssetPopUp}
							>
								Add Customer Account
							</button>
						</div>
					)}
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						<div className="p-2">
							<div className="align-items-center d-flex justify-content-between p-1">
								<p className="mb-2 popover-heading">Users</p>
								<div>
									<PeopleRoundedIcon className="mr-1" />
									{get(popoverData, "authorized_users.length", 0) + 1}
								</div>
							</div>
							<div>
								{[
									{
										firstName: user.firstName,
										lastName: user.lastName,
										email: user.email,
									},
									...get(popoverData, "authorized_users", []),
								].map((mapData: any) => (
									<div className="popover-content">
										<p className="name mb-2">{`${get(
											mapData,
											"firstName",
											""
										)} ${get(mapData, "lastName", "")}`}</p>
										<p className="user-email mb-3">
											{get(mapData, "email", "")}
										</p>
									</div>
								))}
							</div>
						</div>
					</Popover>
					{isShownConfirmDialog && (
						<ConfirmDialog
							dialogTitle="Delete"
							message={assetDeleteMessage}
							positiveBtnLabel="Delete"
							negativeBtnLabel="Cancel"
							onPositiveBtnClicked={() => handleDeleteAsset()}
							onNegativeBtnClicked={() => dialogCloseClick()}
							onCloseIconClicked={() => dialogCloseClick()}
						/>
					)}
					{isLoading && <SpinnerLoader />}
					<div className="table-responsive customer-table m-d-none">
						<CustomDataTable
							title=""
							rows={assetsList}
							columns={columns}
							options={options}
						/>
					</div>

					{/* Mobile Card Design */}
					<div className="m-customer-account d-none m-d-block">
						<div
							className="m-customer-account-header border-bottom d-flex align-items-center"
							onClick={() => goBack()}
						>
							<ArrowBackIcon className="i-16 txt-primary font-600" />
							<p className="desc-normal font-text-4 font-600 mb-0">
								Customer Account
							</p>
						</div>
						<div className="responsive-search">
							<input
								type="text"
								onChange={debounce(searchAsset, 1000)}
								placeholder="Search"
							/>
							<SearchIcon />
						</div>
						<div className="m-customer-account-cards">
							{assetsList.map((mapData) => (
								<div className="m-customer-account-card mb-2 d-flex">
									<div className="circle-icon asset-circle-icon">
										<BusinessIcon className="i-16" />
									</div>
									<div className="flex-1 ml-2 pl-1">
										<h4 className="desc-normal font-bold font-primary d-flex justify-content-between mb-0">
											<span onClick={() => onEditClick(mapData)}>
												{mapData.name}
											</span>
											<Dropdown className="more-dropdown">
												<Dropdown.Toggle
													id="dropdown-basic"
													bsPrefix="my-dropdown-toggle p-0"
												>
													<MoreHorizIcon className="i-20 font-tertiary cursor-pointer" />
												</Dropdown.Toggle>
												<Dropdown.Menu
													className="card-action"
													style={{ width: "inherit" }}
												>
													<Dropdown.Item onClick={() => onEditClick(mapData)}>
														Edit
													</Dropdown.Item>
													<Dropdown.Item
														onClick={(event) => {
															confirmDeleteAsset(mapData._id);
														}}
													>
														Delete
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</h4>
										<p
											onClick={() => onEditClick(mapData)}
											className="desc-small font-tertiary text-truncate mb-0 mt-1 m-mv-200"
										>
											{mapData.alertContact}
										</p>
										<div
											onClick={() => onEditClick(mapData)}
											className="d-flex align-items-center mt-2"
										>
											<GroupIcon className="i-16 txt-primary" />
											<p className="desc-small font-500 font-tertiary text-truncate mb-0 ml-2 m-mv-200">
												{mapData.alertEmail}
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
						{!isCreateAssetLimitReached && window.innerWidth < 1024 && (
							<div className="m-add-customer d-none m-d-block">
								<button
									className="btn btn-medium assign-admin primary-btn mb-2"
									onClick={onAssignClick}
								>
									<AddIcon /> Assign user to Accounts
								</button>
								<button
									className="btn btn-medium add-customer primary-btn"
									onClick={openAddAssetPopUp}
								>
									<AddIcon /> Add Customer
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
			{showAssetView && (
				<ViewAsset
					showDrawer={showAssetView}
					setShowDrawer={setShowAssetView}
					assetData={viewAssetData}
					deleteUserFromAsset={deleteUserFromAsset}
					updateAssetData={updateAssetData}
					isLoading={isLoading}
					setShowAssetModal={setShowAssetModal}
				/>
			)}
			{showAssetModal && (
				<CreateAsset
					showDrawer={showAssetModal}
					setShowDrawer={setShowAssetModal}
					getAssets={getAssetData}
					updateLocalAsset={updateAssetData}
					editAssetData={viewAssetData}
				/>
			)}
			{showEditAsset && (
				<EditAsset
					showDrawer={showEditAsset}
					setShowDrawer={setShowEditAsset}
					assetData={viewAssetData}
					updateAsset={updateAssetAction}
				/>
			)}
			{isShownConfirmDialog && (
				<ConfirmDialog
					dialogTitle="Delete"
					message={assetDeleteMessage}
					positiveBtnLabel="Delete"
					negativeBtnLabel="Cancel"
					onPositiveBtnClicked={() => handleDeleteAsset()}
					onNegativeBtnClicked={() => dialogCloseClick()}
					onCloseIconClicked={() => dialogCloseClick()}
				/>
			)}

			{isShownAssignDialog && (
				<AssignUserModal
					show={isShownAssignDialog}
					options={assetList}
					onHide={closeEditModal}
				/>
			)}

			{isLoading && <SpinnerLoader />}
		</>
	);
};
export default Assets;
