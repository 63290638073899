import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";

import "./ConsultationReports.scoped.scss";

interface ConfirmDialogProps {
	show: boolean;
	onClose: () => void;
	content: string;
	sendEmail: () => void;
	productList: any;
	downloadPDF: () => void;
}

export const ReportModal: React.FC<ConfirmDialogProps> = ({
	show,
	onClose,
	content,
	sendEmail,
	productList,
	downloadPDF,
}) => {
	const componentRef = useRef(null);

	return (
		<Modal
			show={show}
			min
			onHide={onClose}
			className="consultation-report-modal"
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>IAQ Consultation Report</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div
					id="download-element"
					className="consultation-report invisible-scrollbar"
					ref={componentRef}
				>
					<div dangerouslySetInnerHTML={{ __html: content }}></div>
					<div className="consultation-product">
						<p>
							<strong>Recommeded Product:</strong>
						</p>
						<div className="row">
							{productList?.map((mapData: any) => (
								<div className="product-card col-5">
									<div className="product-image">
										<img alt="product" src={mapData?.image} />
									</div>
									<p className="product-des mb-2 mt-2">{mapData?.name}</p>
									<a
										target="_blank"
										href={mapData?.productUrl}
										rel="noreferrer"
										className="product-des link mb-2"
									>
										Product Link
									</a>
								</div>
							))}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					className="cursor-pointer con-btn"
					variant="secondary"
					onClick={onClose}
				>
					Cancel
				</Button>

				<Button
					variant="primary"
					className="cursor-pointer con-btn"
					onClick={downloadPDF}
				>
					Download
				</Button>

				<Button
					onClick={sendEmail}
					variant="primary"
					className="cursor-pointer con-btn"
				>
					Send Email
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
