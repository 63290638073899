import { FC, memo } from "react";

import { CircularProgress } from "../../../components/CircularProgress/CircularProgress";
import Temperature from "../../../svg/Temperature";
import { colorStatus, getPerc } from "../../../constant";
import Pm1 from "../../../svg/Pm1";
import Co2 from "../../../svg/Co2";
import Formaldehyde from "../../../svg/Formaldehyde";
import Humidity from "../../../svg/Humidity";
import Pm10 from "../../../svg/Pm10";
import Pm25 from "../../../svg/Pm25";
import Tvoc from "../../../svg/Tvoc";

import "./MonitorList.scoped.scss";

const Reading: FC<any> = ({ readingData, name, unit }) => {
	const color: any = colorStatus[readingData?.aqstatus];

	const renderIcon = () => {
		switch (name) {
			case "Temperature":
				return <Temperature color={color} />;
			case "Humidity":
				return <Humidity color={color} />;
			case "CO2":
				return <Co2 color={color} />;
			case "PM2.5":
				return <Pm25 color={color} />;
			case "PM10":
				return <Pm10 color={color} />;
			case "PM1.0":
				return <Pm1 color={color} />;
			case "TVOC":
				return <Tvoc color={color} />;
			case "Formaldehyde":
				return <Formaldehyde color={color} />;
		}
	};

	return (
		<div className="col col-m-sm-4 m-mt-3">
			<div className="d-flex flex-column align-items-center para-items">
				<div className="para-name fw-bold">{name}</div>
				<div className="position-absoute d-flex align-items-center justify-content-center">
					<CircularProgress
						strokeWidth={3}
						sqSize={50}
						percentage={getPerc[readingData?.aqstatus]}
						color={color}
					/>
					<div className="rounded-circle position-absolute d-flex align-items-center justify-content-center inner-svg-container">
						{renderIcon()}
					</div>
				</div>
				<div
					className="para-unit fw-bold"
					style={{
						color: color ? color : "",
					}}
				>
					{readingData?.value != undefined
						? readingData?.value + ` ${unit}`
						: "N/A"}
				</div>
				<small
					style={{
						color: color ? color : "",
					}}
				>
					{readingData?.value != undefined ? readingData?.aqstatus : "N/A"}
				</small>
			</div>
		</div>
	);
};

export default memo(Reading);
