import React, { useEffect, useState } from "react";
import { Link, Button } from "@material-ui/core";
import {
	getMarketPlaceProduct,
	doFavouriteUnFavourite,
} from "../../../api/MarketPlaceController";
import FavoriteIcon from "@material-ui/icons/Favorite";
import get from "lodash/get";
import "./style.scoped.scss";
import { SpinnerLoader } from "../../../common/Global.Style";
import MobileBack from "../../../components/MobileBack";
import AllProducts from "./Components/AllProducts";
import SearchIcon from "@material-ui/icons/Search";
import { URL } from "../../../components/Navbar/routes";

const AllFavouriteProduct: React.FC = (props: any) => {
	const { match } = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [productList, setProductList] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		getProductsApiCall();
	}, []);
	const addToFavourite = async (productId: any) => {
		setIsLoading(true);
		const response = await doFavouriteUnFavourite({ product: productId });
		if (response?.responseObj?.message) {
			getProductsApiCall();
		}
	};
	const onSearchSubmit = (e: any) => {
		e.preventDefault();
		getProductsApiCall();
	};
	const getProductsApiCall = async () => {
		setIsLoading(true);
		const response = await getMarketPlaceProduct(
			match?.params?.category,
			"",
			search
		);
		if (response?.responseObj?.length) {
			const products = get(response, "responseObj", []);
			const favorite = products.filter((p: any) => {
				if (p?.favorite && p?.favorite[0]) return p?.favorite[0]?.isFavourite;
			});
			if (match?.params?.category) {
				setProductList(products);
			} else setProductList(favorite);
		}
		setIsLoading(false);
	};
	return (
		<div className="iaq-product-section w-100">
			<div className="mobile-header d-none m-d-flex align-items-center justify-content-between bg-white p-12 px-3">
				<MobileBack
					heading={
						match?.params?.category ? match?.params?.categoryname : "Favorite"
					}
					redirect={URL.IAQPRODUCT}
				/>
			</div>
			<div className="bg-white px-3 py-2">
				<div className="icon-input">
					<form className="position-relative" onSubmit={onSearchSubmit}>
						<input
							name="search"
							onChange={(e) => setSearch(e.target.value)}
							className="add-re-input form-control pt-2 pb-2"
							placeholder="Search Products"
						/>
						<SearchIcon className="search-icon" />
					</form>
				</div>
			</div>
			<div className="mt-1 p-12 px-3 w-100 bg-white all-product-section">
				<div className="product-list-section w-100 c-g-8 r-g-8">
					{isLoading && <SpinnerLoader />}
					{productList.map((products: any) => {
						return (
							<AllProducts
								products={products}
								addToFavourite={addToFavourite}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};
export default AllFavouriteProduct;
