import React, { useState, useRef, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import get from "lodash/get";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import { SpinnerLoader } from "../../common/Global.Style";
import { getSignatureUrl, updateUserAvtar } from "../../api/UserController";

import "./EditAsset.scss";
import {
	requiredFieldError,
	validationErrorMessage,
} from "../../utils/errMessages";
interface EditAssetProps {
	showDrawer: boolean;
	setShowDrawer: (value: boolean) => void;
	assetData: {
		alertEmail?: string;
		name?: string;
		alertContact?: string;
	};
	updateAsset: (value: any) => void;
}

export default function EditAsset({
	showDrawer,
	setShowDrawer,
	assetData,
	updateAsset,
}: EditAssetProps) {
	const [loading, setIsLoading] = useState<boolean>(false);
	const [appLogo, setAppLogo] = useState<any>("");
	const [appBackground, setAppBackground] = useState<any>("");
	const [appLogoS3Url, setAppLogoS3Url] = useState<string>("");
	const [appBackgroundS3Url, setAppBackgroundS3Url] = useState<string>("");
	const appLogoUploader = useRef<HTMLInputElement>(null);
	const appBackgroundUploader = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (get(assetData, "logo")) {
			setAppLogo(get(assetData, "logo"));
			setAppLogoS3Url(get(assetData, "logo"));
		}
		if (get(assetData, "theme")) {
			setAppBackground(get(assetData, "theme"));
			setAppBackgroundS3Url(get(assetData, "theme"));
		}
	}, [assetData]);

	const emailRegexPattern =
		/^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])(,)?)+$/;
	const contactRegexPattern = /^(\d{10}(,(?=.))?)+$/;
	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.trim()
			.required(requiredFieldError.ASSET_NAME)
			.max(50, "Name should be lesser than 50 characters"),
		salesEmails: Yup.string().matches(
			emailRegexPattern,
			validationErrorMessage.EMAIL
		),
	});

	const formik = useFormik({
		initialValues: {
			name: get(assetData, "name", ""),
			alertContact: get(assetData, "alertContact", ""),
			alertEmail: get(assetData, "alertEmail", ""),
			salesEmails: get(assetData, "salesEmails", ""),
			calendlyLink: get(assetData, "calendlyLink", ""),
			licenseCode: get(assetData, "licenseCode.code", ""),
			assignedConsultant: get(assetData, "assignedConsultant", ""),
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			setIsLoading(true);
			if (!values.licenseCode.length) {
				delete values.licenseCode;
			}
			values.alertEmail = values.alertEmail.toLowerCase();
			const email = values.alertEmail.split(",");
			values.assignedConsultant = values.assignedConsultant.trim();
			await updateAsset({
				...values,
				alertEmail: [...new Set(email)].join(),
				logo: appLogoS3Url,
				theme: appBackgroundS3Url,
			});
			setIsLoading(false);
		},
	});

	const handleLogoUpload = (e: any) => {
		const [file] = e.target.files;
		if (file) {
			setIsLoading(true);
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target!.result) {
					setAppLogo(e?.target?.result);
				}
			};
			reader.readAsDataURL(file);
			getSignatureUrl({ name: file.name, type: file.type }).then(
				async (response: any) => {
					setAppLogoS3Url(response.responseObj.signedUrl.split("?")[0]);
					await updateUserAvtar(
						response.responseObj.signedUrl,
						e.target.files[0]
					).then(() => {
						setIsLoading(false);
					});
				}
			);
		}
	};

	const handleImageUpload = (e: any) => {
		const [file] = e.target.files;
		if (file) {
			setIsLoading(true);
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target!.result) {
					setAppBackground(e?.target?.result);
				}
			};
			reader.readAsDataURL(file);
			getSignatureUrl({ name: file.name, type: file.type }).then(
				async (response: any) => {
					setAppBackgroundS3Url(response.responseObj.signedUrl.split("?")[0]);
					await updateUserAvtar(
						response.responseObj.signedUrl,
						e.target.files[0]
					).then(() => {
						setIsLoading(false);
					});
				}
			);
		}
	};

	const onChangeLicenseCode = (code: string) => {
		code = code.toUpperCase();
		if (code.length >= 4) {
			code = `iQi-${code?.slice(4)}`;
		}
		formik.setFieldValue("licenseCode", code);
	};

	return (
		<React.Fragment>
			<Drawer
				anchor="right"
				open={showDrawer}
				className="customer-drawer"
				onClose={() => setShowDrawer(false)}
			>
				<div className="drawer-content-section">
					<div className="drawer-header">
						<div className="drawer-header-title d-flex justify-content-between">
							<h6 className="drawer-header-heading">Edit Asset</h6>
							<CloseIcon
								className="cursor-pointer m-d-none"
								onClick={() => setShowDrawer(false)}
							/>
							<ArrowBackIosIcon
								className="i-18 txt-primary cursor-pointer d-none m-d-block mr-2"
								onClick={() => setShowDrawer(false)}
							/>
						</div>
					</div>
					<div className="drawer-content customer-edit">
						<FormikProvider value={formik}>
							<form className="p-3" onSubmit={formik.handleSubmit}>
								<div className="row">
									<div className="col-md-12 col-lg-12 col-sm-12">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-secondary">
												Name
											</label>
											<input
												id="name"
												name="name"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.name}
												onChange={formik.handleChange}
												maxLength={100}
											/>
											{formik.touched.name && formik.errors.name ? (
												<div className="text-danger">{formik.errors.name}</div>
											) : null}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 col-lg-12 col-sm-12">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-secondary">
												Cell Phone Number for Text Alerts
											</label>
											<input
												id="alertContact"
												name="alertContact"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.alertContact}
												onChange={formik.handleChange}
											/>
											{formik.touched.alertContact &&
											formik.errors.alertContact ? (
												<div className="text-danger">
													{formik.errors.alertContact}
												</div>
											) : null}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 col-lg-12 col-sm-12">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-secondary">
												Email Id for Email Alerts
											</label>
											<input
												id="alertEmail"
												name="alertEmail"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.alertEmail}
												onChange={formik.handleChange}
											/>
											{formik.touched.alertEmail && formik.errors.alertEmail ? (
												<div className="text-danger">
													{formik.errors.alertEmail}
												</div>
											) : null}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 col-lg-12 col-sm-12">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-secondary">
												License Code
											</label>
											<input
												id="licenseCode"
												name="licenseCode"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.licenseCode}
												onChange={(e: any) => {
													onChangeLicenseCode(e?.target?.value);
												}}
											/>

											<div className="text-danger">
												{formik.touched.licenseCode &&
													get(formik, "errors.licenseCode")}
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12 col-lg-12 col-sm-12">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-secondary">
												Assigned IAQ Consultant
											</label>
											<input
												id="assignedConsultant"
												name="assignedConsultant"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.assignedConsultant}
												onChange={formik.handleChange}
											/>

											<div className="text-danger">
												{formik.touched.assignedConsultant &&
													get(formik, "errors.assignedConsultant")}
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12 col-lg-12 col-sm-12">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-secondary">
												Sales email for customers to order parts from you
											</label>
											<input
												id="salesEmails"
												name="salesEmails"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.salesEmails}
												onChange={formik.handleChange}
												maxLength={100}
											/>
											<div className="text-danger">
												{formik.touched.salesEmails &&
													get(formik, "errors.salesEmails")}
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 col-lg-12 col-sm-12">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-secondary">
												Calendly link for customers to schedule consulations
												with you
											</label>
											<input
												id="calendlyLink"
												name="calendlyLink"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.calendlyLink}
												onChange={formik.handleChange}
												maxLength={100}
											/>
										</div>
									</div>
								</div>
								<div className="mb-3 customer-account-logo">
									<p className="desc-normal font-500 font-secondary mb-1">
										Logo for TV Displays & Reports
									</p>
									<p className="desc-small font-500 font-tertiary mb-2">
										(if not set default iQi logo will be used)
									</p>
									{appLogo ? (
										<div className="d-flex">
											<div
												className="customer-account-img"
												onClick={() => appLogoUploader.current!.click()}
											>
												<img
													src={appLogo}
													alt="user Profile"
													className="cursor-pointer"
												/>
												<div className="img-action">
													<EditIcon />
												</div>
											</div>
											<div className="d-flex align-items-baseline">
												<IconButton>
													<DeleteIcon
														onClick={() => {
															setAppLogo("");
															setAppLogoS3Url("");
														}}
														className="cursor-pointer font-secondary i-18"
													/>
												</IconButton>
											</div>
										</div>
									) : (
										<div
											onClick={() => appLogoUploader.current!.click()}
											className="customer-account-upload d-flex justify-content-center p-3"
										>
											<CloudUploadOutlinedIcon className="mr-2" />
											Upload Your logo
										</div>
									)}

									<input
										type="file"
										accept="image/*"
										onChange={handleLogoUpload}
										onClick={(event: any) => {
											event.target.value = null;
										}}
										ref={appLogoUploader}
										multiple={false}
										hidden
									/>
								</div>

								<div className="mb-3 customer-account-logo">
									<p className="desc-normal font-500 font-secondary mb-2">
										Background Theme for TV Displays
									</p>
									<p className="desc-small font-500 font-tertiary mb-2">
										(if not set default iQi Theme will be used)
									</p>
									{appBackground ? (
										<div className="d-flex">
											<div
												className="customer-account-img cover-img"
												onClick={() => appBackgroundUploader.current!.click()}
											>
												<img
													src={appBackground}
													alt="user Profile"
													className="cursor-pointer"
												/>
												<div className="img-action">
													<EditIcon />
												</div>
											</div>
											<div className="d-flex align-items-baseline">
												<IconButton>
													<DeleteIcon
														className="cursor-pointer font-secondary i-18"
														onClick={() => {
															setAppBackground("");
															setAppBackgroundS3Url("");
														}}
													/>
												</IconButton>
											</div>
										</div>
									) : (
										<div
											onClick={() => appBackgroundUploader.current!.click()}
											className="customer-account-upload d-flex justify-content-center p-3"
										>
											<CloudUploadOutlinedIcon className="mr-2" />
											Upload Your background image
										</div>
									)}

									<input
										type="file"
										accept="image/*"
										onChange={handleImageUpload}
										onClick={(event: any) => {
											event.target.value = null;
										}}
										ref={appBackgroundUploader}
										multiple={false}
										hidden
									/>
								</div>
								<div className="customer-edit-footer drawer-footer d-flex align-items-center justify-content-end">
									<button
										onClick={() => setShowDrawer(false)}
										className="btn btn-medium btn-accent"
									>
										Cancel
									</button>
									<button
										className="btn btn-medium primary-btn ml-2"
										type="submit"
									>
										Update
									</button>
								</div>
							</form>
						</FormikProvider>
					</div>
				</div>
				{loading && <SpinnerLoader />}
			</Drawer>
		</React.Fragment>
	);
}
