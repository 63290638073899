import React, { useEffect, useState } from "react";
import "../style.scoped.scss";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { NavLink } from "react-router-dom";
import { URL } from "../../../../components/Navbar/routes";

interface FavouriteProductProps {
	products: any;
	addToFavourite: any;
}

const FavouriteProduct: React.FC<FavouriteProductProps> = ({
	products,
	addToFavourite,
}) => {
	return (
		<div className="p-1 radius-6 w-100 product-list d-flex flex-column">
			<NavLink className="flex-1" to={`${URL.IAQVIEWPRODUCT}/${products._id}`}>
				<div className="radius-t-l-6 radius-t-r-6 product-img product-img-fill d-flex align-items-end justify-content-center flex-1 w-100">
					<img src={products?.images[0]} alt="machine" />
				</div>
			</NavLink>

			<div className="px-1 pb-1 pt-2 d-flex w-100">
				<NavLink
					className="flex-1"
					to={`${URL.IAQVIEWPRODUCT}/${products._id}`}
				>
					<div className="flex-1 text-truncate pr-2">
						<p className="text-wrap text-break mb-1 desc-xsmall font-grey-light-1 ls-024">
							{products.name}
						</p>
						{/* <p className="text-wrap text-break mb-0 desc-normal font-700 ls-024 font-text-4">
							${products?.price ? products?.price : "0"}
						</p> */}
					</div>
				</NavLink>
				<div className="like-icon active">
					<FavoriteIcon onClick={() => addToFavourite(products._id)} />
				</div>
			</div>
		</div>
	);
};
export default FavouriteProduct;
