import Switch from "@material-ui/core/Switch";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

export const AntSwitch = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 48,
			height: 24,
			padding: 0,
			display: "flex",
		},
		switchBase: {
			padding: 2,
			color: "#1844EB",
			"&$checked": {
				transform: "translateX(24px)",
				color: "#1844EB",
				"& + $track": {
					opacity: 1,
					backgroundColor: "white",
					borderColor: "#1844EB",
				},
				"& + $thumb": {
					color: "#1844EB !important",
				},
			},
		},
		thumb: {
			width: 20,
			height: 20,
			boxShadow: "none",
			color: "#aaaaaa",
		},
		track: {
			border: `1px solid #D8D8D8`,
			opacity: 1,
			backgroundColor: "white",
			borderRadius: "50px",
		},
		checked: {
			color: "#1844EB",
		},
	})
)(Switch);
