import React, { useEffect, useLayoutEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useSelector } from "react-redux";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import isEmpty from "lodash/isEmpty";

import {
	getIAQReport,
	getIAQReportSchedules,
} from "../../../api/ReportsController";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { getAllLocation } from "../../../api/LocationController";
import { RootState } from "../../../redux/reducers";
import { SpinnerLoader } from "../../../common/Global.Style";
import Shared from "../../../common/Shared";
import { CanvasChatIAQ } from "./ChartIaq";
import { MultiSelectWithCheckBox } from "../../../components/MultiSelectWithCheckbox/MultiSelectWithCheckbox";
import IAQScheduleReportModal from "../../../components/IAQScheduleReportModal/IAQScheduleReportModal";
import { toast } from "react-toastify";
import IAQScheduleReports from "../../../components/IAQScheduleReports/IAQScheduleReports";
import { IAQReportSchedule } from "../../../types/IAQReportSchedule";
import { findIndex } from "lodash";
import ExposureGraphList from "./ExposureAccordian";
import PaymentPendingInfo from "../../../components/PaymentPendingInfo/PaymentPendingInfo";
import {
	downloadCSVReport,
	downloadExposureReport,
	downloadIAQReport,
} from "../../../api/commonController";
import DownloadPDFDialog from "../../../components/DownloadPDFDialog/DownloadPDFDialog";

import "./IaqReports.scoped.scss";
import LazyLoad from "react-lazyload";
import { UserRoles } from "../../../enums/UserRoles";

interface Item {
	label: string;
	value: string;
}

const IaqReports: React.FC = () => {
	const getDefaultIAQReportStartDate = () =>
		moment()
			.tz(building?.timeZoneName || "EST5EDT")
			.subtract(7, "days");

	const building = useSelector((state: RootState) => state.building);
	const allDimensions: string[] = [
		"PM2.5",
		"PM10",
		"PM1.0",
		"Formaldehyde",
		"CO2",
		"TVOC",
		"Humidity",
		"Temperature",
	];
	const [dimensionCodeConversion] = useState<any>({
		Formaldehyde: "ch2o_value",
		CO2: "co2_value",
		Humidity: "humidity_value",
		"PM1.0": "pm1",
		PM10: "pm10",
		"PM2.5": "pm25_value",
		Temperature: "temp_current_f",
		TVOC: "voc_value",
	});

	const [dimension, setDimension] = useState<string[]>(allDimensions);
	const [selectedDimensions] = useState<Array<Item>>([
		{ label: "PM2.5", value: "PM2.5" },
		{ label: "PM10", value: "PM10" },
		{ label: "PM1.0", value: "PM1.0" },
		{ label: "Formaldehyde", value: "Formaldehyde" },
		{ label: "CO2", value: "CO2" },
		{ label: "TVOC", value: "TVOC" },
		{ label: "Humidity", value: "Humidity" },
		{ label: "Temperature", value: "Temperature" },
	]);
	const [selectedLocations, setSelectedLocations] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [iaqReport, setIaqReport] = useState<Array<any>>([]);
	const [iaqReportResponse, setIaqReportResponse] = useState<any>({});
	const [iaqReportExposure, setIaqReportExposure] = useState<any>({});

	const [iaqFromDate, setIaqFromDate] = useState<any>(
		getDefaultIAQReportStartDate()
	);
	const [iaqToDate, setIaqToDate] = useState<any>(new Date());
	const [locationListOptions, setLocationListOptions] = useState([]);
	const [optionsSelected, setOptionsSelected] = useState<any>([]);
	const [modalShowCreateSchedule, setModalShowCreateSchedule] =
		useState<boolean>(false);
	const [iaqReportSchedules, setIaqReportSchedules] = useState<
		IAQReportSchedule[]
	>([]);
	const [showViewSchedule, setShowViewSchedule] = useState<boolean>(false);
	const [selectedIAQSchedule, setSelectedIAQSchedule] =
		useState<IAQReportSchedule>();
	const [showIAQReportLoader, setShowIAQReportLoader] =
		useState<boolean>(false);

	const asset = useSelector((state: RootState) => state.asset);
	const user = useSelector((state: RootState) => state.user);
	const [isApiCalled, setIsApiCalled] = useState<boolean>(false);
	const [showDownloadPDFModal, setShowDownloadPDFModal] =
		useState<boolean>(false);
	const [downloadLink, setDownloadLink] = useState<string>("");

	useEffect(() => {
		setOptionsSelected(selectedDimensions);
		fetchLocationList(building._id);
		setIsApiCalled(false);
	}, [building]);

	useEffect(() => {
		setOptionsSelected(selectedDimensions);
		fetchLocationList(building._id);
		setIsApiCalled(false);
	}, [asset]);

	useLayoutEffect(() => {
		if (isLoading) {
			document.body.style.position = "fixed";
			document.body.style.overflow = "hidden";
		}
		if (!isLoading) {
			document.body.style.position = "";
			document.body.style.overflow = "";
		}
	}, [isLoading]);

	const fetchLocationList = async (buildingId: any) => {
		if (!buildingId) {
			setLocationListOptions([]);
			setSelectedLocations([]);
			return;
		}
		setIsLoading(true);
		const response = await getAllLocation(buildingId);
		if (response.responseObj) {
			const locationList = response.responseObj;
			const locationsOptions: any = [];
			for (const location of locationList) {
				locationsOptions.push({
					value: location["_id"],
					label: location["name"],
				});
			}

			setSelectedLocations(locationsOptions);
			setLocationListOptions(locationsOptions);
		}
		setIsLoading(false);
	};

	const updateDate = (value: Date | null, propertyName: string) => {
		if (propertyName === "dateFrom") {
			setIaqFromDate(value);
		} else {
			setIaqToDate(value);
		}
	};

	const getIAQReportData = async (buildingId: any) => {
		if (!selectedLocations.length) {
			toast.error("Please select locations");
		} else {
			const dateFrom = new Date(
				moment(iaqFromDate).tz("UTC").format("YYYY-MM-DD")
			).toISOString();
			const dateTo = new Date(
				moment(iaqToDate).tz("UTC").format("YYYY-MM-DD")
			).toISOString();
			const iaqReportReq =
				"startDate=" +
				dateFrom +
				"&endDate=" +
				dateTo +
				"&buildingId=" +
				(!!buildingId ? buildingId : "");

			const data = iaqReportReq;
			setIsLoading(true);
			const response: any = await getIAQReport(data);

			if (response?.responseObj?.responseData) {
				const resArr = Object.values(response.responseObj.responseData);
				setIaqReport(resArr);
				filterDownloadPDFData(response.responseObj.responseData, true);
				setIaqReportExposure(response.responseObj.exposureData);
				setIsLoading(false);
			} else {
				setIaqReport([]);
				setIaqReportResponse({});
				setIaqReportExposure({});
				setIsLoading(false);
			}
		}
	};

	const onDimensionSelectionChange = (values: any) => {
		setOptionsSelected(values);
		if (values.length === 0) {
			setDimension(allDimensions);
		} else {
			setDimension(values.map((o: any) => o.value));
		}
	};

	const onLocationSelectionChange = (values: any) => {
		setSelectedLocations(values);
		filterDownloadPDFData(iaqReportResponse, true);
	};

	const showCreateSchedule = () => {
		setSelectedIAQSchedule(undefined);
		setModalShowCreateSchedule(true);
	};

	const onHideRemediationDetail = () => {
		setModalShowCreateSchedule(false);
	};

	const onHideViewScheduleModal = () => {
		setShowViewSchedule(false);
	};

	const viewSchedules = () => {
		setShowIAQReportLoader(true);
		getIAQReportSchedules(building._id)
			.then((data: any) => {
				if (!!data?.responseObj && !!data.responseObj?.results) {
					setIaqReportSchedules(data.responseObj.results);
				}
				setShowIAQReportLoader(false);
				setShowViewSchedule(true);
			})
			.catch((err) => {
				setShowIAQReportLoader(false);
				toast.error(err?.message || "Unable to fetch reports");
			});
	};

	const loadScheduleData = async () => {
		const data = await getIAQReportSchedules(building._id);
		if (!!data?.responseObj && !!data.responseObj?.results) {
			setIaqReportSchedules(data.responseObj.results);
		}
	};

	const onEdit = (index: number) => {
		setShowViewSchedule(false);
		editSchedule(index);
	};

	const editSchedule = (index: number) => {
		const scheduleId = iaqReportSchedules[index]._id;
		if (!scheduleId) {
			return;
		}
		setModalShowCreateSchedule(true);
		setSelectedIAQSchedule(iaqReportSchedules[index]);
	};

	const afterEdit = (schedule: IAQReportSchedule) => {
		const reports = iaqReportSchedules;
		const index = findIndex(reports, { _id: schedule?._id });
		reports[index] = schedule;
		setIaqReportSchedules(reports);
		setShowViewSchedule(true);
		setSelectedIAQSchedule(undefined);
	};

	const hasDimension: Function = (dimension: string): boolean => {
		let locations: string[] = Object.keys(iaqReportResponse);
		if (selectedLocations.length > 0) {
			const selectedLocationNames = selectedLocations?.map(
				(loc: { value: string; label: string }) => loc.label
			);
			locations = locations.filter(
				(key: string) => !!selectedLocationNames.includes(key)
			);
		}

		return locations?.some(
			(key: string) =>
				iaqReportResponse[key][dimension] &&
				!!iaqReportResponse[key][dimension]?.length
		);
	};

	const checkAsset = () => {
		return !asset.payPending;
	};

	const filterDownloadPDFData = (data: any, isIAQ: boolean) => {
		const locationNames = selectedLocations.map((s: any) => s?.label);
		const dimensions = optionsSelected.map(
			(d: any) => dimensionCodeConversion[d?.label]
		);

		let readings: any = {};
		for (const key of locationNames) {
			if (key in data) {
				readings[key] = {};
				for (const dimKey of dimensions) {
					if (dimKey in data[key]) {
						readings[key][dimKey] = data[key][dimKey];
					}
				}
			}
		}
		if (isIAQ) {
			setIaqReportResponse(readings);
		}
		return readings;
	};

	const downloadIAQPdf = async () => {
		setIsLoading(true);
		const dimensions = optionsSelected.map(
			(d: any) => dimensionCodeConversion[d?.label]
		);

		const response: any = await downloadIAQReport(
			building?._id,
			filterDownloadPDFData(iaqReportResponse, true),
			selectedLocations.map((l: any) => l?.value),
			dimensions
		);
		setIsLoading(false);
		if (response && response?.responseObj && response?.responseObj?.response) {
			setDownloadLink(response.responseObj.response);
			setShowDownloadPDFModal(true);
		} else {
			toast.error("Error in downloading PDF");
		}
	};

	const downloadCSVFile = async () => {
		setIsLoading(true);
		const dateFrom = new Date(
			moment(iaqFromDate).tz("UTC").format("YYYY-MM-DD")
		).toISOString();
		const dateTo = new Date(
			moment(iaqToDate).tz("UTC").format("YYYY-MM-DD")
		).toISOString();
		const response: any = await downloadCSVReport({
			startDate: dateFrom,
			endDate: dateTo,
			buildingId: building?._id,
			locationIds: selectedLocations.map((l: any) => l.value),
			dimensions: optionsSelected.map(
				(d: any) => dimensionCodeConversion[d.label]
			),
		});
		setIsLoading(false);
		if (response && response?.responseObj && response?.responseObj?.response) {
			setDownloadLink(response.responseObj.response);
			setShowDownloadPDFModal(true);
		} else {
			toast.error("Error in downloading PDF");
		}
	};

	const downloadExposurePdf = async () => {
		setIsLoading(true);

		const response: any = await downloadExposureReport(
			building?._id,
			filterDownloadPDFData(iaqReportExposure, false)
		);
		setIsLoading(false);
		if (response && response?.responseObj && response?.responseObj?.response) {
			setDownloadLink(response.responseObj.response);
			setShowDownloadPDFModal(true);
		} else {
			toast.error("Error in downloading PDF");
		}
	};

	return checkAsset() ? (
		<div className="container-fluid iaq-report-block">
			{/* <SystemTour tourSteps={tourSteps} position={0} /> */}
			<div className="row h-100 mt-3 mb-3 pb-5">
				<div className="col-lg-12 col-md-12 col-sm-12 iaq-report-responsive">
					{/* <h5>IAQ Report</h5> */}
					<div className="mt-3 bg-white">
						<div className="w-100 d-flex m-d-none">
							<div className="w-50">
								<h5 className="mt-2 ml-12">IAQ Report</h5>
							</div>
						</div>
						<div
							// onClick={() => goBack()}
							className="align-items-center pl-2 d-none m-d-flex pt-2 pb-3 sub-header"
						>
							{/* <ArrowBackIcon className="i-18 txt-primary font-600" /> */}
							<h2 className="desc-large font-600 font-text-4 mb-0">
								IAQ Report
							</h2>
						</div>
					</div>
					<div className="d-flex mt-4 iaq-report-block">
						<div className="d-flex col-lg-6 col-md-12 p-0 iaq-report-select">
							<div className="mr-2 w-50 date-select">
								<div className="form-group">
									<DatePicker
										label="Start Date"
										inputId={"start-date-input"}
										date={iaqFromDate}
										onDateChanged={(date: Date | null) =>
											updateDate(date, "dateFrom")
										}
										disableFuture={true}
										datepickerClass="iaq-re-input p-2"
									/>
								</div>
							</div>
							<div className="mr-2 w-50 date-select">
								<div className="form-group">
									<DatePicker
										label="End Date"
										inputId={"end-date-input"}
										date={new Date()}
										onDateChanged={(date: Date | null) =>
											updateDate(date, "dateTo")
										}
										minDate={iaqFromDate}
										disableFuture={true}
										datepickerClass="iaq-re-input p-2"
									/>
								</div>
							</div>
						</div>
					</div>
					{modalShowCreateSchedule && (
						<IAQScheduleReportModal
							show={modalShowCreateSchedule}
							onHide={onHideRemediationDetail}
							schedule={selectedIAQSchedule}
							afterEdit={afterEdit}
						/>
					)}
					{showViewSchedule && (
						<IAQScheduleReports
							afterDelete={loadScheduleData}
							onEdit={onEdit}
							onHide={onHideViewScheduleModal}
							reports={iaqReportSchedules}
							show={showViewSchedule}
						/>
					)}
					{showIAQReportLoader && <SpinnerLoader />}
					<div className="d-flex location-select">
						<div>
							<h6 className="standard-title">Select Locations</h6>
							<MultiSelectWithCheckBox
								seletedOptions={locationListOptions}
								selectValue={selectedLocations}
								onSelectionChange={onLocationSelectionChange}
							/>
						</div>
					</div>
					<div className="d-flex location-select">
						<div>
							<h6 className="standard-title">Select Dimensions</h6>
							<MultiSelectWithCheckBox
								seletedOptions={selectedDimensions}
								selectValue={optionsSelected}
								onSelectionChange={onDimensionSelectionChange}
							/>
						</div>
					</div>
					<div className="d-flex pt-3 pb-4 mt-2 iaq-report-btn-container">
						<button
							className="btn iaq-re-btn pl-3 pr-3 ml-1 run-report-tour"
							onClick={() => {
								setIsApiCalled(true);
								getIAQReportData(building._id);
							}}
						>
							<PlayArrowIcon /> Run Report
						</button>

						<button
							type="button"
							className="btn d-flex iaq-re-btn mr-2 schedule-report-tour"
							onClick={() => showCreateSchedule()}
						>
							Schedule Report
						</button>

						<button
							type="button"
							className="btn d-flex iaq-re-btn ml-1 view-schedule-report-tour"
							onClick={() => viewSchedules()}
						>
							View Schedules
						</button>
					</div>
					<div className="w-100 mt-4 mb-4 iaq-report-container">
						<div className="dropdown iaq-report w-100 pl-0 pr-0">
							{isApiCalled && (
								<div>
									<Accordion defaultExpanded={true}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<Typography>Graph</Typography>
										</AccordionSummary>
										<AccordionDetails>
											{!isLoading && iaqReport && iaqReport.length > 0 && (
												<div className="w-100 ">
													{dimension.length > 0 && (
														<div className="d-flex">
															<button
																type="button"
																className="invisible-button"
																onClick={downloadIAQPdf}
															>
																Download PDF
															</button>

															<button
																type="button"
																className="invisible-button"
																onClick={downloadCSVFile}
															>
																Download CSV
															</button>
														</div>
													)}

													<div className="invisible-logo">
														<img
															// className="text-white-color"
															src={Shared.reportLogoUrl}
															alt=""
														/>
													</div>
													{/* Multi line graph */}
													<div className="print-chart-item print-chart-item-landscape">
														<LazyLoad offset={200}>
															{dimension.includes("Temperature") && (
																<div className="chart-item px-4 paddingX-0">
																	<div className="text-center my-4">
																		{hasDimension("temp_current_f") ? (
																			<CanvasChatIAQ
																				title=""
																				graphData={iaqReportResponse}
																				dimension="temp_current_f"
																				timeZone={
																					building?.timeZoneName || "EST5EDT"
																				}
																			/>
																		) : (
																			<div className="text-center">
																				{" "}
																				Information not available for this
																				location.
																			</div>
																		)}
																		<h6 className="mt-2">Temperature (F)</h6>
																	</div>
																</div>
															)}
														</LazyLoad>

														<div className="print-chart-item-landscape">
															<LazyLoad offset={200}>
																{dimension.includes("Humidity") && (
																	<div className="chart-item px-4 paddingX-0">
																		<div className="text-center my-4">
																			{hasDimension("humidity_value") ? (
																				<CanvasChatIAQ
																					title=""
																					graphData={iaqReportResponse}
																					dimension="humidity_value"
																					timeZone={
																						building?.timeZoneName || "EST5EDT"
																					}
																				/>
																			) : (
																				<div className="text-center">
																					{" "}
																					Information not available for this
																					location.
																				</div>
																			)}
																			<h6 className="mt-2">Humidity (%)</h6>
																		</div>
																	</div>
																)}
															</LazyLoad>
														</div>
													</div>
													<div className="print-chart-item print-chart-item-landscape">
														<LazyLoad offset={200}>
															{dimension.includes("CO2") && (
																<div className="chart-item px-4 paddingX-0">
																	<div className="text-center my-4">
																		{hasDimension("co2_value") ? (
																			<CanvasChatIAQ
																				title=""
																				graphData={iaqReportResponse}
																				dimension="co2_value"
																				timeZone={
																					building?.timeZoneName || "EST5EDT"
																				}
																			/>
																		) : (
																			<div className="text-center">
																				{" "}
																				Information not available for this
																				location.
																			</div>
																		)}
																		<h6 className="mt-2">CO2 (ppm)</h6>
																	</div>
																</div>
															)}
														</LazyLoad>
														<div className="print-chart-item-landscape">
															<LazyLoad offset={200}>
																{dimension.includes("PM2.5") && (
																	<div className="chart-item px-4 paddingX-0">
																		<div className="text-center my-4">
																			{hasDimension("pm25_value") ? (
																				<CanvasChatIAQ
																					title=""
																					graphData={iaqReportResponse}
																					dimension="pm25_value"
																					timeZone={
																						building?.timeZoneName || "EST5EDT"
																					}
																				/>
																			) : (
																				<div className="text-center">
																					{" "}
																					Information not available for this
																					location.
																				</div>
																			)}
																			<h6 className="mt-2">PM2.5 (ug/m3)</h6>
																		</div>
																	</div>
																)}
															</LazyLoad>
														</div>
													</div>
													<div className="print-chart-item print-chart-item-landscape">
														<LazyLoad offset={200}>
															{dimension.includes("PM10") && (
																<div className="chart-item px-4 paddingX-0">
																	<div className="text-center my-4">
																		{hasDimension("pm10") ? (
																			<CanvasChatIAQ
																				title=""
																				graphData={iaqReportResponse}
																				dimension="pm10"
																				timeZone={
																					building?.timeZoneName || "EST5EDT"
																				}
																			/>
																		) : (
																			<div className="text-center">
																				{" "}
																				Information not available for this
																				location.
																			</div>
																		)}
																		<h6 className="mt-2">PM10 (ug/m3)</h6>
																	</div>
																</div>
															)}
														</LazyLoad>
														<div className="print-chart-item-landscape">
															<LazyLoad offset={200}>
																{dimension.includes("PM1.0") && (
																	<div className="chart-item px-4 paddingX-0">
																		<div className="text-center my-4">
																			{hasDimension("pm1") ? (
																				<CanvasChatIAQ
																					title=""
																					graphData={iaqReportResponse}
																					dimension="pm1"
																					timeZone={
																						building?.timeZoneName || "EST5EDT"
																					}
																				/>
																			) : (
																				<div className="text-center">
																					{" "}
																					Information not available for this
																					location.
																				</div>
																			)}
																			<h6 className="mt-2">PM1.0 (ug/m3)</h6>
																		</div>
																	</div>
																)}
															</LazyLoad>
														</div>
													</div>
													<div className="print-chart-item print-chart-item-landscape">
														<LazyLoad offset={200}>
															{dimension.includes("TVOC") && (
																<div className="chart-item px-4 paddingX-0">
																	<div className="text-center my-4">
																		{hasDimension("voc_value") ? (
																			<CanvasChatIAQ
																				title=""
																				graphData={iaqReportResponse}
																				dimension="voc_value"
																				timeZone={
																					building?.timeZoneName || "EST5EDT"
																				}
																			/>
																		) : (
																			<div className="text-center">
																				{" "}
																				Information not available for this
																				location.
																			</div>
																		)}
																		<h6 className="mt-2">TVOC (ppb)</h6>
																	</div>
																</div>
															)}
														</LazyLoad>
														<LazyLoad offset={200}>
															{dimension.includes("Formaldehyde") && (
																<div className="chart-item px-4 paddingX-0">
																	<div className="text-center my-4">
																		{hasDimension("ch2o_value") ? (
																			<CanvasChatIAQ
																				title=""
																				graphData={iaqReportResponse}
																				dimension="ch2o_value"
																				timeZone={
																					building?.timeZoneName || "EST5EDT"
																				}
																			/>
																		) : (
																			<div className="text-center">
																				{" "}
																				Information not available for this
																				location.
																			</div>
																		)}
																		<h6 className="mt-2">Formaldehyde (ppb)</h6>
																	</div>
																</div>
															)}
														</LazyLoad>
													</div>
												</div>
											)}
										</AccordionDetails>
									</Accordion>
									<Accordion defaultExpanded={true}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<Typography>Exposure Data</Typography>
										</AccordionSummary>

										<AccordionDetails>
											<div className="w-100">
												{dimension.length > 0 && !isEmpty(iaqReportExposure) && (
													<div className="d-flex pl-3">
														<button
															type="button"
															className="invisible-button"
															onClick={downloadExposurePdf}
														>
															Download PDF
														</button>
													</div>
												)}

												{/* Doughnut graph */}
												<div className="print-exposure-chart-item">
													<div className="text-center my-4">
														<ExposureGraphList
															exposureGraphData={iaqReportExposure}
															selectedLocations={selectedLocations}
															dimension={dimension}
														/>
													</div>
												</div>
											</div>
											{/* )} */}
										</AccordionDetails>
									</Accordion>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{isLoading && <SpinnerLoader />}
			{showDownloadPDFModal && (
				<DownloadPDFDialog
					downloadPDFLink={downloadLink}
					closeModal={() => setShowDownloadPDFModal(false)}
				/>
			)}
		</div>
	) : (
		<PaymentPendingInfo name={asset.name} />
	);
};
export default IaqReports;
