/*
 CanvasJS HTML5 & JavaScript StockCharts - v1.6.1 GA - https://canvasjs.com/ 
 Copyright 2022 fenopix

  --------------------- License Information --------------------
 CanvasJS is a commercial product which requires purchase of license. Without a commercial license you can use it for evaluation purposes for upto 30 days. Please refer to the following link for further details.
     https://canvasjs.com/license/

*/
/*eslint-disable*/
/*jshint ignore:start*/
(function () {
	function ia(l, v) {
		l.prototype = ob(v.prototype);
		l.prototype.constructor = l;
		l.base = v.prototype;
	}
	function ob(l) {
		function v() {}
		v.prototype = l;
		return new v();
	}
	function cb(l, v, g) {
		"millisecond" === g
			? l.setMilliseconds(l.getMilliseconds() + 1 * v)
			: "second" === g
			? l.setSeconds(l.getSeconds() + 1 * v)
			: "minute" === g
			? l.setMinutes(l.getMinutes() + 1 * v)
			: "hour" === g
			? l.setHours(l.getHours() + 1 * v)
			: "day" === g
			? l.setDate(l.getDate() + 1 * v)
			: "week" === g
			? l.setDate(l.getDate() + 7 * v)
			: "month" === g
			? l.setMonth(l.getMonth() + 1 * v)
			: "year" === g && l.setFullYear(l.getFullYear() + 1 * v);
		return l;
	}
	function ma(l, v) {
		var g = !1;
		0 > l && ((g = !0), (l *= -1));
		l = "" + l;
		for (v = v ? v : 1; l.length < v; ) l = "0" + l;
		return g ? "-" + l : l;
	}
	function Oa(l) {
		if (!l) return l;
		l = l.replace(/^\s\s*/, "");
		for (var v = /\s/, g = l.length; v.test(l.charAt(--g)); );
		return l.slice(0, g + 1);
	}
	function Ga(l) {
		l.roundRect = function (l, g, t, aa, P, D, w, y) {
			w && (this.fillStyle = w);
			y && (this.strokeStyle = y);
			"undefined" === typeof P && (P = 5);
			this.lineWidth = D;
			this.beginPath();
			this.moveTo(l + P, g);
			this.lineTo(l + t - P, g);
			this.quadraticCurveTo(l + t, g, l + t, g + P);
			this.lineTo(l + t, g + aa - P);
			this.quadraticCurveTo(l + t, g + aa, l + t - P, g + aa);
			this.lineTo(l + P, g + aa);
			this.quadraticCurveTo(l, g + aa, l, g + aa - P);
			this.lineTo(l, g + P);
			this.quadraticCurveTo(l, g, l + P, g);
			this.closePath();
			w && this.fill();
			y && 0 < D && this.stroke();
		};
	}
	function Xa(l, v) {
		return l - v;
	}
	function db(l, v, g) {
		if (l && v && g) {
			g = g + "." + v;
			var t = "image/" + v;
			l = l.toDataURL(t);
			var aa = !1,
				P = document.createElement("a");
			P.download = g;
			P.href = l;
			if ("undefined" !== typeof Blob && new Blob()) {
				for (
					var D = l.replace(/^data:[a-z\/]*;base64,/, ""),
						D = atob(D),
						w = new ArrayBuffer(D.length),
						w = new Uint8Array(w),
						y = 0;
					y < D.length;
					y++
				)
					w[y] = D.charCodeAt(y);
				v = new Blob([w.buffer], { type: "image/" + v });
				try {
					window.navigator.msSaveBlob(v, g), (aa = !0);
				} catch (Q) {
					(P.dataset.downloadurl = [t, P.download, P.href].join(":")),
						(P.href = window.URL.createObjectURL(v));
				}
			}
			if (!aa)
				try {
					(event = document.createEvent("MouseEvents")),
						event.initMouseEvent(
							"click",
							!0,
							!1,
							window,
							0,
							0,
							0,
							0,
							0,
							!1,
							!1,
							!1,
							!1,
							0,
							null
						),
						P.dispatchEvent
							? P.dispatchEvent(event)
							: P.fireEvent && P.fireEvent("onclick");
				} catch (E) {
					(v = window.open()),
						v.document.write(
							"<img src='" +
								l +
								"'></img><div>Please right click on the image and save it to your device</div>"
						),
						v.document.close();
				}
		}
	}
	function U(l) {
		var v = ((l & 16711680) >> 16).toString(16),
			g = ((l & 65280) >> 8).toString(16);
		l = ((l & 255) >> 0).toString(16);
		v = 2 > v.length ? "0" + v : v;
		g = 2 > g.length ? "0" + g : g;
		l = 2 > l.length ? "0" + l : l;
		return "#" + v + g + l;
	}
	function pb(l, v) {
		var g = this.length >>> 0,
			t = Number(v) || 0,
			t = 0 > t ? Math.ceil(t) : Math.floor(t);
		for (0 > t && (t += g); t < g; t++)
			if (t in this && this[t] === l) return t;
		return -1;
	}
	function g(l) {
		return null === l || "undefined" === typeof l;
	}
	function Da(l) {
		l.indexOf || (l.indexOf = pb);
		return l;
	}
	function qb(l) {
		if (Pa.fSDec)
			l[wa("`eeDwdouMhrudods")](wa("e`u`@ohl`uhnoHuds`uhnoDoe"), function () {
				Pa._fTWm && Pa._fTWm(l);
			});
	}
	function eb(l, v, g) {
		g = g || "normal";
		var t = l + "_" + v + "_" + g,
			aa = fb[t];
		if (isNaN(aa)) {
			try {
				if (!Ea) {
					var P = document.body;
					Ea = document.createElement("span");
					Ea.innerHTML = "";
					var D = document.createTextNode("Mpgyi");
					Ea.appendChild(D);
					P.appendChild(Ea);
				}
				Ea.style.display = "";
				N(Ea, {
					position: "absolute",
					left: "0px",
					top: "-20000px",
					padding: "0px",
					margin: "0px",
					border: "none",
					whiteSpace: "pre",
					lineHeight: "normal",
					fontFamily: l,
					fontSize: v + "px",
					fontWeight: g,
				});
				aa = Math.round(Ea.offsetHeight);
				Ea.style.display = "none";
			} catch (w) {
				aa = Math.ceil(1.1 * v);
			}
			aa = Math.max(aa, v);
			fb[t] = aa;
		}
		return aa;
	}
	function S(l, v) {
		var g = [];
		if (
			(g = {
				solid: [],
				shortDash: [3, 1],
				shortDot: [1, 1],
				shortDashDot: [3, 1, 1, 1],
				shortDashDotDot: [3, 1, 1, 1, 1, 1],
				dot: [1, 2],
				dash: [4, 2],
				dashDot: [4, 2, 1, 2],
				longDash: [8, 2],
				longDashDot: [8, 2, 1, 2],
				longDashDotDot: [8, 2, 1, 2, 1, 2],
			}[l || "solid"])
		)
			for (var t = 0; t < g.length; t++) g[t] *= v;
		else g = [];
		return g;
	}
	function y(l, v, H, t, aa) {
		t = t || [];
		aa = g(aa) ? (rb ? { passive: !1, capture: !1 } : !1) : aa;
		t.push([l, v, H, aa]);
		return l.addEventListener
			? (l.addEventListener(v, H, aa), H)
			: l.attachEvent
			? ((t = function (v) {
					v = v || window.event;
					v.preventDefault =
						v.preventDefault ||
						function () {
							v.returnValue = !1;
						};
					v.stopPropagation =
						v.stopPropagation ||
						function () {
							v.cancelBubble = !0;
						};
					H.call(l, v);
			  }),
			  l.attachEvent("on" + v, t),
			  t)
			: !1;
	}
	function gb(l, v, g, t) {
		t = t || !1;
		if (l.removeEventListener) l.removeEventListener(v, g, t);
		else if (l.detachEvent) l.detachEvent("on" + v, g);
		else return !1;
	}
	function hb(l, v) {
		if ("createEvent" in document) {
			var g = document.createEvent("HTMLEvents");
			g.initEvent(v, !1, !0);
			l.dispatchEvent(g);
		} else l.fireEvent("on" + v);
	}
	function ib(l) {
		if (l._menuButton)
			l.exportEnabled
				? (N(l._menuButton, {
						backgroundColor: l.toolbar.itemBackgroundColor,
						color: l.toolbar.fontColor,
				  }),
				  Sa(l._menuButton),
				  (l._menuButton.title = l._cultureInfo.menuText))
				: ya(l._menuButton);
		else if (l.exportEnabled && t) {
			var v = !1;
			l._menuButton = document.createElement("button");
			Ba(l, l._menuButton, "menu");
			l._toolBar.appendChild(l._menuButton);
			y(
				l._menuButton,
				"touchstart",
				function (l) {
					v = !0;
				},
				l.allDOMEventHandlers
			);
			y(
				l._menuButton,
				"click",
				function () {
					"none" !== l._dropdownMenu.style.display ||
						(l._dropDownCloseTime &&
							500 >= new Date().getTime() - l._dropDownCloseTime.getTime()) ||
						((l._dropdownMenu.style.display = "block"),
						l._menuButton.blur(),
						l._dropdownMenu.focus());
				},
				l.allDOMEventHandlers,
				!0
			);
			y(
				l._menuButton,
				"mousemove",
				function () {
					v ||
						(N(l._menuButton, {
							backgroundColor: l.toolbar.itemBackgroundColorOnHover,
							color: l.toolbar.fontColorOnHover,
						}),
						0 >= navigator.userAgent.search("MSIE") &&
							N(l._menuButton.childNodes[0], {
								WebkitFilter: "invert(100%)",
								filter: "invert(100%)",
							}));
				},
				l.allDOMEventHandlers,
				!0
			);
			y(
				l._menuButton,
				"mouseout",
				function () {
					v ||
						(N(l._menuButton, {
							backgroundColor: l.toolbar.itemBackgroundColor,
							color: l.toolbar.fontColor,
						}),
						0 >= navigator.userAgent.search("MSIE") &&
							N(l._menuButton.childNodes[0], {
								WebkitFilter: "invert(0%)",
								filter: "invert(0%)",
							}));
				},
				l.allDOMEventHandlers,
				!0
			);
		}
		if (l.exportEnabled && l._dropdownMenu) {
			N(l._dropdownMenu, {
				backgroundColor: l.toolbar.itemBackgroundColor,
				color: l.toolbar.fontColor,
			});
			for (
				var g = l._dropdownMenu.childNodes,
					R = [
						l._cultureInfo.printText,
						l._cultureInfo.saveJPGText,
						l._cultureInfo.savePNGText,
					],
					aa = 0;
				aa < g.length;
				aa++
			)
				N(g[aa], {
					backgroundColor: l.toolbar.itemBackgroundColor,
					color: l.toolbar.fontColor,
				}),
					(g[aa].innerHTML = R[aa]);
		} else
			!l._dropdownMenu &&
				l.exportEnabled &&
				t &&
				((v = !1),
				(l._dropdownMenu = document.createElement("div")),
				l._dropdownMenu.setAttribute("tabindex", -1),
				(g = -1 !== l.theme.indexOf("dark") ? "black" : "#888888"),
				N(l._dropdownMenu, {
					position: "absolute",
					zIndex: 1,
					userSelect: "none",
					MozUserSeelct: "none",
					WebkitUserSelect: "none",
					msUserSelect: "none",
					cursor: "pointer",
					right: "0px",
					top: "25px",
					minWidth: "120px",
					outline: 0,
					fontSize: "14px",
					fontFamily: "Arial, Helvetica, sans-serif",
					padding: "5px 0px 5px 0px",
					textAlign: "left",
					lineHeight: "10px",
					backgroundColor: l.toolbar.itemBackgroundColor,
					boxShadow: "2px 2px 10px" + g,
				}),
				(l._dropdownMenu.style.display = "none"),
				l._toolBar.appendChild(l._dropdownMenu),
				y(
					l._dropdownMenu,
					"blur",
					function () {
						ya(l._dropdownMenu);
						l._dropDownCloseTime = new Date();
					},
					l.allDOMEventHandlers,
					!0
				),
				(g = document.createElement("div")),
				N(g, { padding: "12px 8px 12px 8px" }),
				(g.innerHTML = l._cultureInfo.printText),
				(g.style.backgroundColor = l.toolbar.itemBackgroundColor),
				(g.style.color = l.toolbar.fontColor),
				l._dropdownMenu.appendChild(g),
				y(
					g,
					"touchstart",
					function (l) {
						v = !0;
					},
					l.allDOMEventHandlers
				),
				y(
					g,
					"mousemove",
					function () {
						v ||
							((this.style.backgroundColor =
								l.toolbar.itemBackgroundColorOnHover),
							(this.style.color = l.toolbar.fontColorOnHover));
					},
					l.allDOMEventHandlers,
					!0
				),
				y(
					g,
					"mouseout",
					function () {
						v ||
							((this.style.backgroundColor = l.toolbar.itemBackgroundColor),
							(this.style.color = l.toolbar.fontColor));
					},
					l.allDOMEventHandlers,
					!0
				),
				y(
					g,
					"click",
					function () {
						l.print();
						ya(l._dropdownMenu);
					},
					l.allDOMEventHandlers,
					!0
				),
				(g = document.createElement("div")),
				N(g, { padding: "12px 8px 12px 8px" }),
				(g.innerHTML = l._cultureInfo.saveJPGText),
				(g.style.backgroundColor = l.toolbar.itemBackgroundColor),
				(g.style.color = l.toolbar.fontColor),
				l._dropdownMenu.appendChild(g),
				y(
					g,
					"touchstart",
					function (l) {
						v = !0;
					},
					l.allDOMEventHandlers
				),
				y(
					g,
					"mousemove",
					function () {
						v ||
							((this.style.backgroundColor =
								l.toolbar.itemBackgroundColorOnHover),
							(this.style.color = l.toolbar.fontColorOnHover));
					},
					l.allDOMEventHandlers,
					!0
				),
				y(
					g,
					"mouseout",
					function () {
						v ||
							((this.style.backgroundColor = l.toolbar.itemBackgroundColor),
							(this.style.color = l.toolbar.fontColor));
					},
					l.allDOMEventHandlers,
					!0
				),
				y(
					g,
					"click",
					function () {
						l.exportChart({ format: "jpeg", fileName: l.exportFileName });
						ya(l._dropdownMenu);
					},
					l.allDOMEventHandlers,
					!0
				),
				(g = document.createElement("div")),
				N(g, { padding: "12px 8px 12px 8px" }),
				(g.innerHTML = l._cultureInfo.savePNGText),
				(g.style.backgroundColor = l.toolbar.itemBackgroundColor),
				(g.style.color = l.toolbar.fontColor),
				l._dropdownMenu.appendChild(g),
				y(
					g,
					"touchstart",
					function (l) {
						v = !0;
					},
					l.allDOMEventHandlers
				),
				y(
					g,
					"mousemove",
					function () {
						v ||
							((this.style.backgroundColor =
								l.toolbar.itemBackgroundColorOnHover),
							(this.style.color = l.toolbar.fontColorOnHover));
					},
					l.allDOMEventHandlers,
					!0
				),
				y(
					g,
					"mouseout",
					function () {
						v ||
							((this.style.backgroundColor = l.toolbar.itemBackgroundColor),
							(this.style.color = l.toolbar.fontColor));
					},
					l.allDOMEventHandlers,
					!0
				),
				y(
					g,
					"click",
					function () {
						l.exportChart({ format: "png", fileName: l.exportFileName });
						ya(l._dropdownMenu);
					},
					l.allDOMEventHandlers,
					!0
				));
	}
	function jb(l, v, g) {
		l *= ka;
		v *= ka;
		l = g.getImageData(l, v, 2, 2).data;
		v = !0;
		for (g = 0; 4 > g; g++)
			if ((l[g] !== l[g + 4]) | (l[g] !== l[g + 8]) | (l[g] !== l[g + 12])) {
				v = !1;
				break;
			}
		return v ? (l[0] << 16) | (l[1] << 8) | l[2] : 0;
	}
	function oa(l, v, g) {
		return l in v ? v[l] : g[l];
	}
	function Ta(l, g, H) {
		if (t && kb) {
			var R = l.getContext("2d");
			Ua =
				R.webkitBackingStorePixelRatio ||
				R.mozBackingStorePixelRatio ||
				R.msBackingStorePixelRatio ||
				R.oBackingStorePixelRatio ||
				R.backingStorePixelRatio ||
				1;
			ka = Ya / Ua;
			l.width = g * ka;
			l.height = H * ka;
			Ya !== Ua &&
				((l.style.width = g + "px"),
				(l.style.height = H + "px"),
				R.scale(ka, ka));
		} else (l.width = g), (l.height = H);
	}
	function sb(l) {
		if (!tb) {
			var g = !1,
				t = !1;
			"undefined" === typeof ra.Chart.creditHref
				? ((l.creditHref = wa("iuuqr;..b`ow`rkr/bnl.")),
				  (l.creditText = wa("B`ow`rKR/bnl")))
				: ((g = l.updateOption("creditText")),
				  (t = l.updateOption("creditHref")));
			if (l.creditHref && l.creditText) {
				l._creditLink ||
					((l._creditLink = document.createElement("a")),
					l._creditLink.setAttribute("class", "canvasjs-chart-credit"),
					l._creditLink.setAttribute("title", "JavaScript Charts"),
					N(l._creditLink, {
						outline: "none",
						margin: "0px",
						position: "absolute",
						right: "2px",
						top: l.height - 14 + "px",
						color: "dimgrey",
						textDecoration: "none",
						fontSize: "11px",
						fontFamily:
							"Calibri, Lucida Grande, Lucida Sans Unicode, Arial, sans-serif",
					}),
					l._creditLink.setAttribute("tabIndex", -1),
					l._creditLink.setAttribute("target", "_blank"));
				if (0 === l.renderCount || g || t)
					l._creditLink.setAttribute("href", l.creditHref),
						(l._creditLink.innerHTML = l.creditText);
				l._creditLink && l.creditHref && l.creditText
					? (l._creditLink.parentElement ||
							l._canvasJSContainer.appendChild(l._creditLink),
					  (l._creditLink.style.top = l.height - 14 + "px"))
					: l._creditLink.parentElement &&
					  l._canvasJSContainer.removeChild(l._creditLink);
			}
		}
	}
	function sa(l, g) {
		La && ((this.canvasCount |= 0), window.console.log(++this.canvasCount));
		var H = document.createElement("canvas");
		H.setAttribute("class", "canvasjs-chart-canvas");
		Ta(H, l, g);
		t ||
			"undefined" === typeof G_vmlCanvasManager ||
			G_vmlCanvasManager.initElement(H);
		return H;
	}
	function N(l, g) {
		for (var t in g) l.style[t] = g[t];
	}
	function Ba(l, g, t) {
		g.getAttribute("state") ||
			((g.style.backgroundColor = l.toolbar.itemBackgroundColor),
			(g.style.color = l.toolbar.fontColor),
			(g.style.border = "none"),
			N(g, {
				WebkitUserSelect: "none",
				MozUserSelect: "none",
				msUserSelect: "none",
				userSelect: "none",
			}));
		g.getAttribute("state") !== t &&
			(g.setAttribute("state", t),
			g.setAttribute("type", "button"),
			N(g, {
				padding: "5px 12px",
				cursor: "pointer",
				float: "left",
				width: "40px",
				height: "25px",
				outline: "0px",
				verticalAlign: "baseline",
				lineHeight: "0",
			}),
			g.setAttribute("title", l._cultureInfo[t + "Text"]),
			(g.innerHTML =
				"<img src='" +
				ub[t].image +
				"' alt='" +
				l._cultureInfo[t + "Text"] +
				"' />"),
			N(g.childNodes[0], { height: "95%", pointerEvents: "none" }));
	}
	function Sa() {
		for (var l = null, g = 0; g < arguments.length; g++)
			(l = arguments[g]), l.style && (l.style.display = "inline");
	}
	function ya() {
		for (var l = null, g = 0; g < arguments.length; g++)
			(l = arguments[g]) && l.style && (l.style.display = "none");
	}
	function Za(l) {
		if (null === l || "object" !== typeof l) return l;
		if (l instanceof Date) return new Date(l.getTime());
		Array.isArray ||
			(Array.isArray = function (l) {
				return "[object Array]" === Object.prototype.toString.call(l);
			});
		if (Array.isArray(l)) {
			for (var g = [], t = 0; t < l.length; t++) g.push(Za(l[t]));
			return g;
		}
		g = new l.constructor();
		for (t in l) l.hasOwnProperty(t) && (g[t] = Za(l[t]));
		return g;
	}
	function Va(l, g, t, R, aa) {
		if (null === l || "undefined" === typeof l)
			return "undefined" === typeof t ? g : t;
		l =
			parseFloat(l.toString()) * (0 <= l.toString().indexOf("%") ? g / 100 : 1);
		"undefined" !== typeof R &&
			((l = Math.min(R, l)),
			"undefined" !== typeof aa && (l = Math.max(aa, l)));
		return !isNaN(l) && l <= g && 0 <= l ? l : "undefined" === typeof t ? g : t;
	}
	function da(l, v, t, R, aa) {
		this._defaultsKey = l;
		this._themeOptionsKey = v;
		this._index = R;
		this.parent = aa;
		this._eventListeners = [];
		l = {};
		this.theme && g(this.parent) && g(v) && g(R)
			? (l = g(this.predefinedThemes[this.theme])
					? this.predefinedThemes.light1
					: this.predefinedThemes[this.theme])
			: this.parent &&
			  this.parent.themeOptions &&
			  this.parent.themeOptions[v] &&
			  (null === R
					? (l = this.parent.themeOptions[v])
					: 0 < this.parent.themeOptions[v].length &&
					  ((R = Math.min(this.parent.themeOptions[v].length - 1, R)),
					  (l = this.parent.themeOptions[v][R])));
		this.themeOptions = l;
		this.options = t ? t : { _isPlaceholder: !0 };
		this.setOptions(this.options, l);
	}
	function Ka(l, g, t, R, aa) {
		"undefined" === typeof aa && (aa = 0);
		this._padding = aa;
		this._x1 = l;
		this._y1 = g;
		this._x2 = t;
		this._y2 = R;
		this._rightOccupied =
			this._leftOccupied =
			this._bottomOccupied =
			this._topOccupied =
				this._padding;
	}
	function na(l, g) {
		na.base.constructor.call(this, "TextBlock", null, g, null, null);
		this.ctx = l;
		this._isDirty = !0;
		this._wrappedText = null;
		this._initialize();
	}
	function Wa(l, g) {
		Wa.base.constructor.call(this, "Toolbar", "toolbar", g, null, l);
		this.chart = l;
		this.canvas = l.canvas;
		this.ctx = this.chart.ctx;
		this.optionsName = "toolbar";
	}
	function Ha(l, v) {
		Ha.base.constructor.call(this, "Title", "title", v, null, l);
		this.chart = l;
		this.canvas = l.canvas;
		this.ctx = this.chart.ctx;
		this.optionsName = "title";
		if (g(this.options.margin) && l.options.subtitles)
			for (var t = l.options.subtitles, R = 0; R < t.length; R++)
				if (
					((g(t[R].horizontalAlign) && "center" === this.horizontalAlign) ||
						t[R].horizontalAlign === this.horizontalAlign) &&
					((g(t[R].verticalAlign) && "top" === this.verticalAlign) ||
						t[R].verticalAlign === this.verticalAlign) &&
					!t[R].dockInsidePlotArea === !this.dockInsidePlotArea
				) {
					this.margin = 0;
					break;
				}
		"undefined" === typeof this.options.fontSize &&
			(this.fontSize = this.chart.getAutoFontSize(this.fontSize));
		this.height = this.width = null;
		this.bounds = { x1: null, y1: null, x2: null, y2: null };
	}
	function Ma(l, g, t) {
		Ma.base.constructor.call(this, "Subtitle", "subtitles", g, t, l);
		this.chart = l;
		this.canvas = l.canvas;
		this.ctx = this.chart.ctx;
		this.optionsName = "subtitles";
		this.isOptionsInArray = !0;
		"undefined" === typeof this.options.fontSize &&
			(this.fontSize = this.chart.getAutoFontSize(this.fontSize));
		this.height = this.width = null;
		this.bounds = { x1: null, y1: null, x2: null, y2: null };
	}
	function $a() {
		this.pool = [];
	}
	function Na(l) {
		var g;
		l && Qa[l] && (g = Qa[l]);
		Na.base.constructor.call(this, "CultureInfo", null, g, null, null);
	}
	var La = !1,
		Pa = {},
		t = !!document.createElement("canvas").getContext,
		ra = {
			Chart: {
				width: 500,
				height: 400,
				zoomEnabled: !1,
				zoomType: "x",
				backgroundColor: "white",
				theme: "light1",
				animationEnabled: !1,
				animationDuration: 1200,
				dataPointWidth: null,
				dataPointMinWidth: null,
				dataPointMaxWidth: null,
				colorSet: "colorSet1",
				culture: "en",
				creditHref: "",
				creditText: "CanvasJS",
				interactivityEnabled: !0,
				exportEnabled: !1,
				exportFileName: "Chart",
				rangeChanging: null,
				rangeChanged: null,
				publicProperties: {
					title: "readWrite",
					subtitles: "readWrite",
					toolbar: "readWrite",
					toolTip: "readWrite",
					legend: "readWrite",
					axisX: "readWrite",
					axisY: "readWrite",
					axisX2: "readWrite",
					axisY2: "readWrite",
					data: "readWrite",
					options: "readWrite",
					bounds: "readOnly",
					container: "readOnly",
					selectedColorSet: "readOnly",
				},
			},
			Title: {
				padding: 0,
				text: null,
				verticalAlign: "top",
				horizontalAlign: "center",
				fontSize: 20,
				fontFamily: "Calibri",
				fontWeight: "normal",
				fontColor: "black",
				fontStyle: "normal",
				borderThickness: 0,
				borderColor: "black",
				cornerRadius: 0,
				backgroundColor: t ? "transparent" : null,
				margin: 5,
				wrap: !0,
				maxWidth: null,
				dockInsidePlotArea: !1,
				publicProperties: {
					options: "readWrite",
					bounds: "readOnly",
					chart: "readOnly",
				},
			},
			Subtitle: {
				padding: 0,
				text: null,
				verticalAlign: "top",
				horizontalAlign: "center",
				fontSize: 14,
				fontFamily: "Calibri",
				fontWeight: "normal",
				fontColor: "black",
				fontStyle: "normal",
				borderThickness: 0,
				borderColor: "black",
				cornerRadius: 0,
				backgroundColor: null,
				margin: 2,
				wrap: !0,
				maxWidth: null,
				dockInsidePlotArea: !1,
				publicProperties: {
					options: "readWrite",
					bounds: "readOnly",
					chart: "readOnly",
				},
			},
			Toolbar: {
				itemBackgroundColor: "white",
				itemBackgroundColorOnHover: "#2196f3",
				buttonBorderColor: "#2196f3",
				buttonBorderThickness: 1,
				fontColor: "black",
				fontColorOnHover: "white",
				publicProperties: { options: "readWrite", chart: "readOnly" },
			},
			Legend: {
				name: null,
				verticalAlign: "center",
				horizontalAlign: "right",
				fontSize: 14,
				fontFamily: "calibri",
				fontWeight: "normal",
				fontColor: "black",
				fontStyle: "normal",
				cursor: null,
				itemmouseover: null,
				itemmouseout: null,
				itemmousemove: null,
				itemclick: null,
				dockInsidePlotArea: !1,
				reversed: !1,
				backgroundColor: t ? "transparent" : null,
				borderColor: t ? "transparent" : null,
				borderThickness: 0,
				cornerRadius: 0,
				maxWidth: null,
				maxHeight: null,
				markerMargin: null,
				itemMaxWidth: null,
				itemWidth: null,
				itemWrap: !0,
				itemTextFormatter: null,
				publicProperties: {
					options: "readWrite",
					bounds: "readOnly",
					chart: "readOnly",
				},
			},
			ToolTip: {
				enabled: !0,
				shared: !1,
				animationEnabled: !0,
				content: null,
				contentFormatter: null,
				reversed: !1,
				backgroundColor: t ? "rgba(255,255,255,.9)" : "rgb(255,255,255)",
				borderColor: null,
				borderThickness: 2,
				cornerRadius: 5,
				fontSize: 14,
				fontColor: "black",
				fontFamily: "Calibri, Arial, Georgia, serif;",
				fontWeight: "normal",
				fontStyle: "italic",
				updated: null,
				hidden: null,
				publicProperties: { options: "readWrite", chart: "readOnly" },
			},
			Axis: {
				minimum: null,
				maximum: null,
				viewportMinimum: null,
				viewportMaximum: null,
				interval: null,
				intervalType: null,
				reversed: !1,
				logarithmic: !1,
				logarithmBase: 10,
				title: null,
				titleFontColor: "black",
				titleFontSize: 20,
				titleFontFamily: "arial",
				titleFontWeight: "normal",
				titleFontStyle: "normal",
				titleWrap: !0,
				titleMaxWidth: null,
				titleBackgroundColor: t ? "transparent" : null,
				titleBorderColor: t ? "transparent" : null,
				titleBorderThickness: 0,
				titleCornerRadius: 0,
				labelAngle: 0,
				labelFontFamily: "arial",
				labelFontColor: "black",
				labelFontSize: 12,
				labelFontWeight: "normal",
				labelFontStyle: "normal",
				labelAutoFit: !0,
				labelWrap: !0,
				labelMaxWidth: null,
				labelFormatter: null,
				labelBackgroundColor: t ? "transparent" : null,
				labelBorderColor: t ? "transparent" : null,
				labelBorderThickness: 0,
				labelCornerRadius: 0,
				labelPlacement: "outside",
				labelTextAlign: "left",
				prefix: "",
				suffix: "",
				includeZero: !1,
				tickLength: 5,
				tickColor: "black",
				tickThickness: 1,
				tickPlacement: "outside",
				lineColor: "black",
				lineThickness: 1,
				lineDashType: "solid",
				gridColor: "#A0A0A0",
				gridThickness: 0,
				gridDashType: "solid",
				interlacedColor: t ? "transparent" : null,
				valueFormatString: null,
				margin: 2,
				publicProperties: {
					options: "readWrite",
					stripLines: "readWrite",
					scaleBreaks: "readWrite",
					crosshair: "readWrite",
					bounds: "readOnly",
					chart: "readOnly",
				},
			},
			StripLine: {
				value: null,
				startValue: null,
				endValue: null,
				color: "orange",
				opacity: null,
				thickness: 2,
				lineDashType: "solid",
				label: "",
				labelPlacement: "inside",
				labelAlign: "far",
				labelWrap: !0,
				labelMaxWidth: null,
				labelBackgroundColor: null,
				labelBorderColor: t ? "transparent" : null,
				labelBorderThickness: 0,
				labelCornerRadius: 0,
				labelFontFamily: "arial",
				labelFontColor: "orange",
				labelFontSize: 12,
				labelFontWeight: "normal",
				labelFontStyle: "normal",
				labelFormatter: null,
				showOnTop: !1,
				publicProperties: {
					options: "readWrite",
					axis: "readOnly",
					bounds: "readOnly",
					chart: "readOnly",
				},
			},
			ScaleBreaks: {
				autoCalculate: !1,
				collapsibleThreshold: "25%",
				maxNumberOfAutoBreaks: 2,
				spacing: 8,
				type: "straight",
				color: "#FFFFFF",
				fillOpacity: 0.9,
				lineThickness: 2,
				lineColor: "#E16E6E",
				lineDashType: "solid",
				publicProperties: {
					options: "readWrite",
					customBreaks: "readWrite",
					axis: "readOnly",
					autoBreaks: "readOnly",
					bounds: "readOnly",
					chart: "readOnly",
				},
			},
			Break: {
				startValue: null,
				endValue: null,
				spacing: 8,
				type: "straight",
				color: "#FFFFFF",
				fillOpacity: 0.9,
				lineThickness: 2,
				lineColor: "#E16E6E",
				lineDashType: "solid",
				publicProperties: {
					options: "readWrite",
					scaleBreaks: "readOnly",
					bounds: "readOnly",
					chart: "readOnly",
				},
			},
			Crosshair: {
				enabled: !1,
				snapToDataPoint: !1,
				color: "grey",
				opacity: null,
				thickness: 2,
				lineDashType: "solid",
				label: "",
				labelWrap: !0,
				labelMaxWidth: null,
				labelBackgroundColor: t ? "grey" : null,
				labelBorderColor: t ? "grey" : null,
				labelBorderThickness: 0,
				labelCornerRadius: 0,
				labelFontFamily: t
					? "Calibri, Optima, Candara, Verdana, Geneva, sans-serif"
					: "calibri",
				labelFontSize: 12,
				labelFontColor: "#fff",
				labelFontWeight: "normal",
				labelFontStyle: "normal",
				labelFormatter: null,
				valueFormatString: null,
				updated: null,
				hidden: null,
				publicProperties: {
					options: "readWrite",
					axis: "readOnly",
					bounds: "readOnly",
					chart: "readOnly",
				},
			},
			DataSeries: {
				name: null,
				dataPoints: null,
				label: "",
				bevelEnabled: !1,
				highlightEnabled: !0,
				cursor: "default",
				indexLabel: "",
				indexLabelPlacement: "auto",
				indexLabelOrientation: "horizontal",
				indexLabelTextAlign: "left",
				indexLabelFontColor: "black",
				indexLabelFontSize: 12,
				indexLabelFontStyle: "normal",
				indexLabelFontFamily: "Arial",
				indexLabelFontWeight: "normal",
				indexLabelBackgroundColor: null,
				indexLabelLineColor: "gray",
				indexLabelLineThickness: 1,
				indexLabelLineDashType: "solid",
				indexLabelMaxWidth: null,
				indexLabelWrap: !0,
				indexLabelFormatter: null,
				lineThickness: 2,
				lineDashType: "solid",
				connectNullData: !1,
				nullDataLineDashType: "dash",
				color: null,
				lineColor: null,
				risingColor: "white",
				fallingColor: "red",
				fillOpacity: null,
				startAngle: 0,
				radius: null,
				innerRadius: null,
				neckHeight: null,
				neckWidth: null,
				reversed: !1,
				valueRepresents: null,
				linkedDataSeriesIndex: null,
				whiskerThickness: 2,
				whiskerDashType: "solid",
				whiskerColor: null,
				whiskerLength: null,
				stemThickness: 2,
				stemColor: null,
				stemDashType: "solid",
				upperBoxColor: "white",
				lowerBoxColor: "white",
				type: "column",
				xValueType: "number",
				axisXType: "primary",
				axisYType: "primary",
				axisXIndex: 0,
				axisYIndex: 0,
				xValueFormatString: null,
				yValueFormatString: null,
				zValueFormatString: null,
				percentFormatString: null,
				showInLegend: null,
				legendMarkerType: null,
				legendMarkerColor: null,
				legendText: null,
				legendMarkerBorderColor: t ? "transparent" : null,
				legendMarkerBorderThickness: 0,
				markerType: "circle",
				markerColor: null,
				markerSize: null,
				markerBorderColor: t ? "transparent" : null,
				markerBorderThickness: 0,
				mouseover: null,
				mouseout: null,
				mousemove: null,
				click: null,
				toolTipContent: null,
				visible: !0,
				publicProperties: {
					options: "readWrite",
					axisX: "readWrite",
					axisY: "readWrite",
					chart: "readOnly",
				},
			},
			TextBlock: {
				x: 0,
				y: 0,
				width: null,
				height: null,
				maxWidth: null,
				maxHeight: null,
				padding: 0,
				angle: 0,
				text: "",
				horizontalAlign: "center",
				textAlign: "left",
				fontSize: 12,
				fontFamily: "calibri",
				fontWeight: "normal",
				fontColor: "black",
				fontStyle: "normal",
				borderThickness: 0,
				borderColor: "black",
				cornerRadius: 0,
				backgroundColor: null,
				textBaseline: "top",
			},
			CultureInfo: {
				decimalSeparator: ".",
				digitGroupSeparator: ",",
				zoomText: "Zoom",
				panText: "Pan",
				resetText: "Reset",
				menuText: "More Options",
				saveJPGText: "Save as JPEG",
				savePNGText: "Save as PNG",
				printText: "Print",
				days: "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(
					" "
				),
				shortDays: "Sun Mon Tue Wed Thu Fri Sat".split(" "),
				months:
					"January February March April May June July August September October November December".split(
						" "
					),
				shortMonths: "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(
					" "
				),
			},
		},
		Qa = { en: {} },
		w = t ? "Trebuchet MS, Helvetica, sans-serif" : "Arial",
		za = t ? "Impact, Charcoal, sans-serif" : "Arial",
		xa = {
			colorSet1:
				"#4F81BC #C0504E #9BBB58 #23BFAA #8064A1 #4AACC5 #F79647 #7F6084 #77A033 #33558B #E59566".split(
					" "
				),
			colorSet2:
				"#6D78AD #51CDA0 #DF7970 #4C9CA0 #AE7D99 #C9D45C #5592AD #DF874D #52BCA8 #8E7AA3 #E3CB64 #C77B85 #C39762 #8DD17E #B57952 #FCC26C".split(
					" "
				),
			colorSet3:
				"#8CA1BC #36845C #017E82 #8CB9D0 #708C98 #94838D #F08891 #0366A7 #008276 #EE7757 #E5BA3A #F2990B #03557B #782970".split(
					" "
				),
		},
		K,
		W,
		O,
		Y,
		ta;
	W = "#333333";
	O = "#000000";
	K = "#666666";
	ta = Y = "#000000";
	var ga = 20,
		E = 14,
		Fa = {
			colorSet: "colorSet1",
			backgroundColor: "#FFFFFF",
			title: {
				fontFamily: za,
				fontSize: 32,
				fontColor: W,
				fontWeight: "normal",
				verticalAlign: "top",
				margin: 5,
			},
			subtitles: [
				{
					fontFamily: za,
					fontSize: E,
					fontColor: W,
					fontWeight: "normal",
					verticalAlign: "top",
					margin: 5,
				},
			],
			data: [
				{
					indexLabelFontFamily: w,
					indexLabelFontSize: E,
					indexLabelFontColor: W,
					indexLabelFontWeight: "normal",
					indexLabelLineThickness: 1,
				},
			],
			axisX: [
				{
					titleFontFamily: w,
					titleFontSize: ga,
					titleFontColor: W,
					titleFontWeight: "normal",
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: O,
					labelFontWeight: "normal",
					lineThickness: 1,
					lineColor: K,
					tickThickness: 1,
					tickColor: K,
					gridThickness: 0,
					gridColor: K,
					stripLines: [
						{
							labelFontFamily: w,
							labelFontSize: E,
							labelFontColor: "#FF7300",
							labelFontWeight: "normal",
							labelBackgroundColor: null,
							color: "#FF7300",
							thickness: 1,
						},
					],
					crosshair: {
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#EEEEEE",
						labelFontWeight: "normal",
						labelBackgroundColor: ta,
						color: Y,
						thickness: 1,
						lineDashType: "dash",
					},
					scaleBreaks: {
						type: "zigzag",
						spacing: "2%",
						lineColor: "#BBBBBB",
						lineThickness: 1,
						lineDashType: "solid",
					},
				},
			],
			axisX2: [
				{
					titleFontFamily: w,
					titleFontSize: ga,
					titleFontColor: W,
					titleFontWeight: "normal",
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: O,
					labelFontWeight: "normal",
					lineThickness: 1,
					lineColor: K,
					tickThickness: 1,
					tickColor: K,
					gridThickness: 0,
					gridColor: K,
					stripLines: [
						{
							labelFontFamily: w,
							labelFontSize: E,
							labelFontColor: "#FF7300",
							labelFontWeight: "normal",
							labelBackgroundColor: null,
							color: "#FF7300",
							thickness: 1,
						},
					],
					crosshair: {
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#EEEEEE",
						labelFontWeight: "normal",
						labelBackgroundColor: ta,
						color: Y,
						thickness: 1,
						lineDashType: "dash",
					},
					scaleBreaks: {
						type: "zigzag",
						spacing: "2%",
						lineColor: "#BBBBBB",
						lineThickness: 1,
						lineDashType: "solid",
					},
				},
			],
			axisY: [
				{
					titleFontFamily: w,
					titleFontSize: ga,
					titleFontColor: W,
					titleFontWeight: "normal",
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: O,
					labelFontWeight: "normal",
					lineThickness: 1,
					lineColor: K,
					tickThickness: 1,
					tickColor: K,
					gridThickness: 1,
					gridColor: K,
					stripLines: [
						{
							labelFontFamily: w,
							labelFontSize: E,
							labelFontColor: "#FF7300",
							labelFontWeight: "normal",
							labelBackgroundColor: null,
							color: "#FF7300",
							thickness: 1,
						},
					],
					crosshair: {
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#EEEEEE",
						labelFontWeight: "normal",
						labelBackgroundColor: ta,
						color: Y,
						thickness: 1,
						lineDashType: "dash",
					},
					scaleBreaks: {
						type: "zigzag",
						spacing: "2%",
						lineColor: "#BBBBBB",
						lineThickness: 1,
						lineDashType: "solid",
					},
				},
			],
			axisY2: [
				{
					titleFontFamily: w,
					titleFontSize: ga,
					titleFontColor: W,
					titleFontWeight: "normal",
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: O,
					labelFontWeight: "normal",
					lineThickness: 1,
					lineColor: K,
					tickThickness: 1,
					tickColor: K,
					gridThickness: 1,
					gridColor: K,
					stripLines: [
						{
							labelFontFamily: w,
							labelFontSize: E,
							labelFontColor: "#FF7300",
							labelFontWeight: "normal",
							labelBackgroundColor: null,
							color: "#FF7300",
							thickness: 1,
						},
					],
					crosshair: {
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#EEEEEE",
						labelFontWeight: "normal",
						labelBackgroundColor: ta,
						color: Y,
						thickness: 1,
						lineDashType: "dash",
					},
					scaleBreaks: {
						type: "zigzag",
						spacing: "2%",
						lineColor: "#BBBBBB",
						lineThickness: 1,
						lineDashType: "solid",
					},
				},
			],
			legend: {
				fontFamily: w,
				fontSize: 14,
				fontColor: W,
				fontWeight: "bold",
				verticalAlign: "bottom",
				horizontalAlign: "center",
			},
			toolTip: {
				fontFamily: w,
				fontSize: 14,
				fontStyle: "normal",
				cornerRadius: 0,
				borderThickness: 1,
			},
			toolbar: {
				itemBackgroundColor: "white",
				itemBackgroundColorOnHover: "#2196f3",
				buttonBorderColor: "#2196f3",
				buttonBorderThickness: 1,
				fontColor: "black",
				fontColorOnHover: "white",
			},
		};
	K = "#2A2A2A";
	O = W = "#F5F5F5";
	K = "#FFFFFF";
	Y = "#40BAF1";
	ta = "#F5F5F5";
	var ga = 20,
		E = 14,
		Ia = {
			colorSet: "colorSet2",
			title: {
				fontFamily: w,
				fontSize: 33,
				fontColor: "#3A3A3A",
				fontWeight: "bold",
				verticalAlign: "top",
				margin: 5,
			},
			subtitles: [
				{
					fontFamily: w,
					fontSize: E,
					fontColor: "#3A3A3A",
					fontWeight: "normal",
					verticalAlign: "top",
					margin: 5,
				},
			],
			data: [
				{
					indexLabelFontFamily: w,
					indexLabelFontSize: E,
					indexLabelFontColor: "#666666",
					indexLabelFontWeight: "normal",
					indexLabelLineThickness: 1,
				},
			],
			axisX: [
				{
					titleFontFamily: w,
					titleFontSize: ga,
					titleFontColor: "#666666",
					titleFontWeight: "normal",
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#666666",
					labelFontWeight: "normal",
					lineThickness: 1,
					lineColor: "#BBBBBB",
					tickThickness: 1,
					tickColor: "#BBBBBB",
					gridThickness: 1,
					gridColor: "#BBBBBB",
					stripLines: [
						{
							labelFontFamily: w,
							labelFontSize: E,
							labelFontColor: "#FFA500",
							labelFontWeight: "normal",
							labelBackgroundColor: null,
							color: "#FFA500",
							thickness: 1,
						},
					],
					crosshair: {
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#EEEEEE",
						labelFontWeight: "normal",
						labelBackgroundColor: "black",
						color: "black",
						thickness: 1,
						lineDashType: "dot",
					},
					scaleBreaks: {
						type: "zigzag",
						spacing: "2%",
						lineColor: "#BBBBBB",
						lineThickness: 1,
						lineDashType: "solid",
					},
				},
			],
			axisX2: [
				{
					titleFontFamily: w,
					titleFontSize: ga,
					titleFontColor: "#666666",
					titleFontWeight: "normal",
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#666666",
					labelFontWeight: "normal",
					lineThickness: 1,
					lineColor: "#BBBBBB",
					tickColor: "#BBBBBB",
					tickThickness: 1,
					gridThickness: 1,
					gridColor: "#BBBBBB",
					stripLines: [
						{
							labelFontFamily: w,
							labelFontSize: E,
							labelFontColor: "#FFA500",
							labelFontWeight: "normal",
							labelBackgroundColor: null,
							color: "#FFA500",
							thickness: 1,
						},
					],
					crosshair: {
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#EEEEEE",
						labelFontWeight: "normal",
						labelBackgroundColor: "black",
						color: "black",
						thickness: 1,
						lineDashType: "dot",
					},
					scaleBreaks: {
						type: "zigzag",
						spacing: "2%",
						lineColor: "#BBBBBB",
						lineThickness: 1,
						lineDashType: "solid",
					},
				},
			],
			axisY: [
				{
					titleFontFamily: w,
					titleFontSize: ga,
					titleFontColor: "#666666",
					titleFontWeight: "normal",
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#666666",
					labelFontWeight: "normal",
					lineThickness: 0,
					lineColor: "#BBBBBB",
					tickColor: "#BBBBBB",
					tickThickness: 1,
					gridThickness: 1,
					gridColor: "#BBBBBB",
					stripLines: [
						{
							labelFontFamily: w,
							labelFontSize: E,
							labelFontColor: "#FFA500",
							labelFontWeight: "normal",
							labelBackgroundColor: null,
							color: "#FFA500",
							thickness: 1,
						},
					],
					crosshair: {
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#EEEEEE",
						labelFontWeight: "normal",
						labelBackgroundColor: "black",
						color: "black",
						thickness: 1,
						lineDashType: "dot",
					},
					scaleBreaks: {
						type: "zigzag",
						spacing: "2%",
						lineColor: "#BBBBBB",
						lineThickness: 1,
						lineDashType: "solid",
					},
				},
			],
			axisY2: [
				{
					titleFontFamily: w,
					titleFontSize: ga,
					titleFontColor: "#666666",
					titleFontWeight: "normal",
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#666666",
					labelFontWeight: "normal",
					lineThickness: 0,
					lineColor: "#BBBBBB",
					tickColor: "#BBBBBB",
					tickThickness: 1,
					gridThickness: 1,
					gridColor: "#BBBBBB",
					stripLines: [
						{
							labelFontFamily: w,
							labelFontSize: E,
							labelFontColor: "#FFA500",
							labelFontWeight: "normal",
							labelBackgroundColor: null,
							color: "#FFA500",
							thickness: 1,
						},
					],
					crosshair: {
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#EEEEEE",
						labelFontWeight: "normal",
						labelBackgroundColor: "black",
						color: "black",
						thickness: 1,
						lineDashType: "dot",
					},
					scaleBreaks: {
						type: "zigzag",
						spacing: "2%",
						lineColor: "#BBBBBB",
						lineThickness: 1,
						lineDashType: "solid",
					},
				},
			],
			legend: {
				fontFamily: w,
				fontSize: 14,
				fontColor: "#3A3A3A",
				fontWeight: "bold",
				verticalAlign: "bottom",
				horizontalAlign: "center",
			},
			toolTip: {
				fontFamily: w,
				fontSize: 14,
				fontStyle: "normal",
				cornerRadius: 0,
				borderThickness: 1,
			},
			toolbar: {
				itemBackgroundColor: "white",
				itemBackgroundColorOnHover: "#2196f3",
				buttonBorderColor: "#2196f3",
				buttonBorderThickness: 1,
				fontColor: "black",
				fontColorOnHover: "white",
			},
		};
	O = W = "#F5F5F5";
	K = "#FFFFFF";
	Y = "#40BAF1";
	ta = "#F5F5F5";
	ga = 20;
	E = 14;
	za = {
		colorSet: "colorSet12",
		backgroundColor: "#2A2A2A",
		title: {
			fontFamily: za,
			fontSize: 32,
			fontColor: W,
			fontWeight: "normal",
			verticalAlign: "top",
			margin: 5,
		},
		subtitles: [
			{
				fontFamily: za,
				fontSize: E,
				fontColor: W,
				fontWeight: "normal",
				verticalAlign: "top",
				margin: 5,
			},
		],
		toolbar: {
			itemBackgroundColor: "#666666",
			itemBackgroundColorOnHover: "#FF7372",
			buttonBorderColor: "#FF7372",
			buttonBorderThickness: 1,
			fontColor: "#F5F5F5",
			fontColorOnHover: "#F5F5F5",
		},
		data: [
			{
				indexLabelFontFamily: w,
				indexLabelFontSize: E,
				indexLabelFontColor: O,
				indexLabelFontWeight: "normal",
				indexLabelLineThickness: 1,
			},
		],
		axisX: [
			{
				titleFontFamily: w,
				titleFontSize: ga,
				titleFontColor: O,
				titleFontWeight: "normal",
				labelFontFamily: w,
				labelFontSize: E,
				labelFontColor: O,
				labelFontWeight: "normal",
				lineThickness: 1,
				lineColor: K,
				tickThickness: 1,
				tickColor: K,
				gridThickness: 0,
				gridColor: K,
				stripLines: [
					{
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#FF7300",
						labelFontWeight: "normal",
						labelBackgroundColor: null,
						color: "#FF7300",
						thickness: 1,
					},
				],
				crosshair: {
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#000000",
					labelFontWeight: "normal",
					labelBackgroundColor: ta,
					color: Y,
					thickness: 1,
					lineDashType: "dash",
				},
				scaleBreaks: {
					type: "zigzag",
					spacing: "2%",
					lineColor: "#777777",
					lineThickness: 1,
					lineDashType: "solid",
					color: "#111111",
				},
			},
		],
		axisX2: [
			{
				titleFontFamily: w,
				titleFontSize: ga,
				titleFontColor: O,
				titleFontWeight: "normal",
				labelFontFamily: w,
				labelFontSize: E,
				labelFontColor: O,
				labelFontWeight: "normal",
				lineThickness: 1,
				lineColor: K,
				tickThickness: 1,
				tickColor: K,
				gridThickness: 0,
				gridColor: K,
				stripLines: [
					{
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#FF7300",
						labelFontWeight: "normal",
						labelBackgroundColor: null,
						color: "#FF7300",
						thickness: 1,
					},
				],
				crosshair: {
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#000000",
					labelFontWeight: "normal",
					labelBackgroundColor: ta,
					color: Y,
					thickness: 1,
					lineDashType: "dash",
				},
				scaleBreaks: {
					type: "zigzag",
					spacing: "2%",
					lineColor: "#777777",
					lineThickness: 1,
					lineDashType: "solid",
					color: "#111111",
				},
			},
		],
		axisY: [
			{
				titleFontFamily: w,
				titleFontSize: ga,
				titleFontColor: O,
				titleFontWeight: "normal",
				labelFontFamily: w,
				labelFontSize: E,
				labelFontColor: O,
				labelFontWeight: "normal",
				lineThickness: 1,
				lineColor: K,
				tickThickness: 1,
				tickColor: K,
				gridThickness: 1,
				gridColor: K,
				stripLines: [
					{
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#FF7300",
						labelFontWeight: "normal",
						labelBackgroundColor: null,
						color: "#FF7300",
						thickness: 1,
					},
				],
				crosshair: {
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#000000",
					labelFontWeight: "normal",
					labelBackgroundColor: ta,
					color: Y,
					thickness: 1,
					lineDashType: "dash",
				},
				scaleBreaks: {
					type: "zigzag",
					spacing: "2%",
					lineColor: "#777777",
					lineThickness: 1,
					lineDashType: "solid",
					color: "#111111",
				},
			},
		],
		axisY2: [
			{
				titleFontFamily: w,
				titleFontSize: ga,
				titleFontColor: O,
				titleFontWeight: "normal",
				labelFontFamily: w,
				labelFontSize: E,
				labelFontColor: O,
				labelFontWeight: "normal",
				lineThickness: 1,
				lineColor: K,
				tickThickness: 1,
				tickColor: K,
				gridThickness: 1,
				gridColor: K,
				stripLines: [
					{
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#FF7300",
						labelFontWeight: "normal",
						labelBackgroundColor: null,
						color: "#FF7300",
						thickness: 1,
					},
				],
				crosshair: {
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#000000",
					labelFontWeight: "normal",
					labelBackgroundColor: ta,
					color: Y,
					thickness: 1,
					lineDashType: "dash",
				},
				scaleBreaks: {
					type: "zigzag",
					spacing: "2%",
					lineColor: "#777777",
					lineThickness: 1,
					lineDashType: "solid",
					color: "#111111",
				},
			},
		],
		legend: {
			fontFamily: w,
			fontSize: 14,
			fontColor: W,
			fontWeight: "bold",
			verticalAlign: "bottom",
			horizontalAlign: "center",
		},
		toolTip: {
			fontFamily: w,
			fontSize: 14,
			fontStyle: "normal",
			cornerRadius: 0,
			borderThickness: 1,
			fontColor: O,
			backgroundColor: "rgba(0, 0, 0, .7)",
		},
	};
	K = "#FFFFFF";
	O = W = "#FAFAFA";
	Y = "#40BAF1";
	ta = "#F5F5F5";
	ga = 20;
	E = 14;
	Y = {
		colorSet: "colorSet2",
		backgroundColor: "#32373A",
		title: {
			fontFamily: w,
			fontSize: 32,
			fontColor: W,
			fontWeight: "normal",
			verticalAlign: "top",
			margin: 5,
		},
		subtitles: [
			{
				fontFamily: w,
				fontSize: E,
				fontColor: W,
				fontWeight: "normal",
				verticalAlign: "top",
				margin: 5,
			},
		],
		toolbar: {
			itemBackgroundColor: "#666666",
			itemBackgroundColorOnHover: "#FF7372",
			buttonBorderColor: "#FF7372",
			buttonBorderThickness: 1,
			fontColor: "#F5F5F5",
			fontColorOnHover: "#F5F5F5",
		},
		data: [
			{
				indexLabelFontFamily: w,
				indexLabelFontSize: E,
				indexLabelFontColor: O,
				indexLabelFontWeight: "normal",
				indexLabelLineThickness: 1,
			},
		],
		axisX: [
			{
				titleFontFamily: w,
				titleFontSize: ga,
				titleFontColor: O,
				titleFontWeight: "normal",
				labelFontFamily: w,
				labelFontSize: E,
				labelFontColor: O,
				labelFontWeight: "normal",
				lineThickness: 1,
				lineColor: K,
				tickThickness: 1,
				tickColor: K,
				gridThickness: 0,
				gridColor: K,
				stripLines: [
					{
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#FF7300",
						labelFontWeight: "normal",
						labelBackgroundColor: null,
						color: "#FF7300",
						thickness: 1,
					},
				],
				crosshair: {
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#000000",
					labelFontWeight: "normal",
					labelBackgroundColor: ta,
					color: Y,
					thickness: 1,
					lineDashType: "dash",
				},
				scaleBreaks: {
					type: "zigzag",
					spacing: "2%",
					lineColor: "#777777",
					lineThickness: 1,
					lineDashType: "solid",
					color: "#111111",
				},
			},
		],
		axisX2: [
			{
				titleFontFamily: w,
				titleFontSize: ga,
				titleFontColor: O,
				titleFontWeight: "normal",
				labelFontFamily: w,
				labelFontSize: E,
				labelFontColor: O,
				labelFontWeight: "normal",
				lineThickness: 1,
				lineColor: K,
				tickThickness: 1,
				tickColor: K,
				gridThickness: 0,
				gridColor: K,
				stripLines: [
					{
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#FF7300",
						labelFontWeight: "normal",
						labelBackgroundColor: null,
						color: "#FF7300",
						thickness: 1,
					},
				],
				crosshair: {
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#000000",
					labelFontWeight: "normal",
					labelBackgroundColor: ta,
					color: Y,
					thickness: 1,
					lineDashType: "dash",
				},
				scaleBreaks: {
					type: "zigzag",
					spacing: "2%",
					lineColor: "#777777",
					lineThickness: 1,
					lineDashType: "solid",
					color: "#111111",
				},
			},
		],
		axisY: [
			{
				titleFontFamily: w,
				titleFontSize: ga,
				titleFontColor: O,
				titleFontWeight: "normal",
				labelFontFamily: w,
				labelFontSize: E,
				labelFontColor: O,
				labelFontWeight: "normal",
				lineThickness: 0,
				lineColor: K,
				tickThickness: 1,
				tickColor: K,
				gridThickness: 1,
				gridColor: K,
				stripLines: [
					{
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#FF7300",
						labelFontWeight: "normal",
						labelBackgroundColor: null,
						color: "#FF7300",
						thickness: 1,
					},
				],
				crosshair: {
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#000000",
					labelFontWeight: "normal",
					labelBackgroundColor: ta,
					color: Y,
					thickness: 1,
					lineDashType: "dash",
				},
				scaleBreaks: {
					type: "zigzag",
					spacing: "2%",
					lineColor: "#777777",
					lineThickness: 1,
					lineDashType: "solid",
					color: "#111111",
				},
			},
		],
		axisY2: [
			{
				titleFontFamily: w,
				titleFontSize: ga,
				titleFontColor: O,
				titleFontWeight: "normal",
				labelFontFamily: w,
				labelFontSize: E,
				labelFontColor: O,
				labelFontWeight: "normal",
				lineThickness: 0,
				lineColor: K,
				tickThickness: 1,
				tickColor: K,
				gridThickness: 1,
				gridColor: K,
				stripLines: [
					{
						labelFontFamily: w,
						labelFontSize: E,
						labelFontColor: "#FF7300",
						labelFontWeight: "normal",
						labelBackgroundColor: null,
						color: "#FF7300",
						thickness: 1,
					},
				],
				crosshair: {
					labelFontFamily: w,
					labelFontSize: E,
					labelFontColor: "#000000",
					labelFontWeight: "normal",
					labelBackgroundColor: ta,
					color: Y,
					thickness: 1,
					lineDashType: "dash",
				},
				scaleBreaks: {
					type: "zigzag",
					spacing: "2%",
					lineColor: "#777777",
					lineThickness: 1,
					lineDashType: "solid",
					color: "#111111",
				},
			},
		],
		legend: {
			fontFamily: w,
			fontSize: 14,
			fontColor: W,
			fontWeight: "bold",
			verticalAlign: "bottom",
			horizontalAlign: "center",
		},
		toolTip: {
			fontFamily: w,
			fontSize: 14,
			fontStyle: "normal",
			cornerRadius: 0,
			borderThickness: 1,
			fontColor: O,
			backgroundColor: "rgba(0, 0, 0, .7)",
		},
	};
	var ab = {
			light1: Fa,
			light2: Ia,
			dark1: za,
			dark2: Y,
			theme1: Fa,
			theme2: Ia,
			theme3: Fa,
		},
		Z = {
			numberDuration: 1,
			yearDuration: 314496e5,
			monthDuration: 2592e6,
			weekDuration: 6048e5,
			dayDuration: 864e5,
			hourDuration: 36e5,
			minuteDuration: 6e4,
			secondDuration: 1e3,
			millisecondDuration: 1,
			dayOfWeekFromInt:
				"Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
		},
		rb = (function () {
			var l = !1;
			try {
				var g =
					Object.defineProperty &&
					Object.defineProperty({}, "passive", {
						get: function () {
							l = !0;
							return !1;
						},
					});
				window.addEventListener &&
					(window.addEventListener("test", null, g),
					window.removeEventListener("test", null, g));
			} catch (t) {
				l = !1;
			}
			return l;
		})(),
		fb = {},
		Ea = null,
		lb = function (l) {
			var g = 0,
				t = 0;
			this.options.width
				? (g = this.width)
				: (this.width = g =
						0 < this.container.clientWidth
							? this.container.clientWidth
							: this.width);
			this.options.height
				? (t = this.height)
				: (this.height = t =
						0 < this.container.clientHeight
							? this.container.clientHeight
							: this.height);
			if (this.canvas.width !== g * ka || this.canvas.height !== t * ka) {
				for (var R = 0; R < l.length; R++) Ta(l[R], g, t);
				this.bounds = {
					x1: 0,
					y1: 0,
					x2: this.width,
					y2: this.height,
					width: this.width,
					height: this.height,
				};
				return !0;
			}
			return !1;
		},
		mb = function () {
			this.ctx.clearRect(0, 0, this.width, this.height);
			this.backgroundColor &&
				((this.ctx.fillStyle = this.backgroundColor),
				this.ctx.fillRect(0, 0, this.width, this.height));
		},
		Ca = function (l) {
			l.width = 1;
			l.height = 1;
			l.getContext("2d") && l.getContext("2d").clearRect(0, 0, 1, 1);
		},
		nb = function (l, g, t) {
			g = Math.min(this.width, this.height);
			return Math.max(
				"theme4" === this.theme ? 0 : 300 <= g ? 12 : 11,
				Math.round(g * (l / 400))
			);
		},
		ha = (function () {
			var l =
					/D{1,4}|M{1,4}|Y{1,4}|h{1,2}|H{1,2}|m{1,2}|s{1,2}|f{1,3}|t{1,2}|T{1,2}|K|z{1,3}|"[^"]*"|'[^']*'/g,
				g = "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(
					" "
				),
				t = "Sun Mon Tue Wed Thu Fri Sat".split(" "),
				R =
					"January February March April May June July August September October November December".split(
						" "
					),
				aa = "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" "),
				w =
					/\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g,
				D = /[^-+\dA-Z]/g;
			return function (y, E, Q) {
				var K = Q ? Q.days : g,
					S = Q ? Q.months : R,
					O = Q ? Q.shortDays : t,
					U = Q ? Q.shortMonths : aa;
				Q = "";
				var d = !1;
				y = y && y.getTime ? y : y ? new Date(y) : new Date();
				if (isNaN(y)) throw SyntaxError("invalid date");
				"UTC:" === E.slice(0, 4) && ((E = E.slice(4)), (d = !0));
				Q = d ? "getUTC" : "get";
				var q = y[Q + "Date"](),
					z = y[Q + "Day"](),
					s = y[Q + "Month"](),
					a = y[Q + "FullYear"](),
					f = y[Q + "Hours"](),
					c = y[Q + "Minutes"](),
					b = y[Q + "Seconds"](),
					e = y[Q + "Milliseconds"](),
					h = d ? 0 : y.getTimezoneOffset();
				return (Q = E.replace(l, function (A) {
					switch (A) {
						case "D":
							return q;
						case "DD":
							return ma(q, 2);
						case "DDD":
							return O[z];
						case "DDDD":
							return K[z];
						case "M":
							return s + 1;
						case "MM":
							return ma(s + 1, 2);
						case "MMM":
							return U[s];
						case "MMMM":
							return S[s];
						case "Y":
							return parseInt(String(a).slice(-2));
						case "YY":
							return ma(String(a).slice(-2), 2);
						case "YYY":
							return ma(String(a).slice(-3), 3);
						case "YYYY":
							return ma(a, 4);
						case "h":
							return f % 12 || 12;
						case "hh":
							return ma(f % 12 || 12, 2);
						case "H":
							return f;
						case "HH":
							return ma(f, 2);
						case "m":
							return c;
						case "mm":
							return ma(c, 2);
						case "s":
							return b;
						case "ss":
							return ma(b, 2);
						case "f":
							return ma(String(e), 3).slice(0, 1);
						case "ff":
							return ma(String(e), 3).slice(0, 2);
						case "fff":
							return ma(String(e), 3).slice(0, 3);
						case "t":
							return 12 > f ? "a" : "p";
						case "tt":
							return 12 > f ? "am" : "pm";
						case "T":
							return 12 > f ? "A" : "P";
						case "TT":
							return 12 > f ? "AM" : "PM";
						case "K":
							return d
								? "UTC"
								: (String(y).match(w) || [""]).pop().replace(D, "");
						case "z":
							return (0 < h ? "-" : "+") + Math.floor(Math.abs(h) / 60);
						case "zz":
							return (0 < h ? "-" : "+") + ma(Math.floor(Math.abs(h) / 60), 2);
						case "zzz":
							return (
								(0 < h ? "-" : "+") +
								ma(Math.floor(Math.abs(h) / 60), 2) +
								ma(Math.abs(h) % 60, 2)
							);
						default:
							return A.slice(1, A.length - 1);
					}
				}));
			};
		})(),
		ba = function (l, g, t) {
			if (null === l) return "";
			if (!isFinite(l)) return l;
			l = Number(l);
			var R = 0 > l ? !0 : !1;
			R && (l *= -1);
			var aa = t ? t.decimalSeparator : ".",
				w = t ? t.digitGroupSeparator : ",",
				D = "";
			g = String(g);
			var D = 1,
				y = (t = ""),
				E = -1,
				Q = [],
				K = [],
				S = 0,
				O = 0,
				U = 0,
				d = !1,
				q = 0,
				y = g.match(/"[^"]*"|'[^']*'|[eE][+-]*[0]+|[,]+[.]|\u2030|./g);
			g = null;
			for (var z = 0; y && z < y.length; z++)
				if (((g = y[z]), "." === g && 0 > E)) E = z;
				else {
					if ("%" === g) D *= 100;
					else if ("\u2030" === g) {
						D *= 1e3;
						continue;
					} else if ("," === g[0] && "." === g[g.length - 1]) {
						D /= Math.pow(1e3, g.length - 1);
						E = z + g.length - 1;
						continue;
					} else
						("E" !== g[0] && "e" !== g[0]) ||
							"0" !== g[g.length - 1] ||
							(d = !0);
					0 > E
						? (Q.push(g), "#" === g || "0" === g ? S++ : "," === g && U++)
						: (K.push(g), ("#" !== g && "0" !== g) || O++);
				}
			d &&
				((g = Math.floor(l)),
				(y = -Math.floor(Math.log(l) / Math.LN10 + 1)),
				(q = 0 === l ? 0 : 0 === g ? -(S + y) : String(g).length - S),
				(D /= Math.pow(10, q)));
			0 > E && (E = z);
			D = (l * D).toFixed(O);
			g = D.split(".");
			D = (g[0] + "").split("");
			l = (g[1] + "").split("");
			D && "0" === D[0] && D.shift();
			for (d = y = z = O = E = 0; 0 < Q.length; )
				if (((g = Q.pop()), "#" === g || "0" === g))
					if ((E++, E === S)) {
						var s = D,
							D = [];
						if ("0" === g)
							for (g = S - O - (s ? s.length : 0); 0 < g; ) s.unshift("0"), g--;
						for (; 0 < s.length; )
							(t = s.pop() + t),
								d++,
								0 === d % y && z === U && 0 < s.length && (t = w + t);
					} else
						0 < D.length
							? ((t = D.pop() + t), O++, d++)
							: "0" === g && ((t = "0" + t), O++, d++),
							0 === d % y && z === U && 0 < D.length && (t = w + t);
				else
					("E" !== g[0] && "e" !== g[0]) ||
					"0" !== g[g.length - 1] ||
					!/[eE][+-]*[0]+/.test(g)
						? "," === g
							? (z++, (y = d), (d = 0), 0 < D.length && (t = w + t))
							: (t =
									1 < g.length &&
									(('"' === g[0] && '"' === g[g.length - 1]) ||
										("'" === g[0] && "'" === g[g.length - 1]))
										? g.slice(1, g.length - 1) + t
										: g + t)
						: ((g =
								0 > q
									? g.replace("+", "").replace("-", "")
									: g.replace("-", "")),
						  (t += g.replace(/[0]+/, function (a) {
								return ma(q, a.length);
						  })));
			w = "";
			for (Q = !1; 0 < K.length; )
				(g = K.shift()),
					"#" === g || "0" === g
						? 0 < l.length && 0 !== Number(l.join(""))
							? ((w += l.shift()), (Q = !0))
							: "0" === g && ((w += "0"), (Q = !0))
						: 1 < g.length &&
						  (('"' === g[0] && '"' === g[g.length - 1]) ||
								("'" === g[0] && "'" === g[g.length - 1]))
						? (w += g.slice(1, g.length - 1))
						: ("E" !== g[0] && "e" !== g[0]) ||
						  "0" !== g[g.length - 1] ||
						  !/[eE][+-]*[0]+/.test(g)
						? (w += g)
						: ((g =
								0 > q
									? g.replace("+", "").replace("-", "")
									: g.replace("-", "")),
						  (w += g.replace(/[0]+/, function (a) {
								return ma(q, a.length);
						  })));
			t += (Q ? aa : "") + w;
			return R ? "-" + t : t;
		},
		Aa = function (g) {
			var t = 0,
				H = 0;
			g = g || window.event;
			g.offsetX || 0 === g.offsetX
				? ((t = g.offsetX), (H = g.offsetY))
				: g.layerX || 0 == g.layerX
				? ((t = g.layerX), (H = g.layerY))
				: ((t = g.pageX - g.target.offsetLeft),
				  (H = g.pageY - g.target.offsetTop));
			return { x: t, y: H };
		},
		kb = !0,
		Ya = window.devicePixelRatio || 1,
		Ua = 1,
		ka = kb ? Ya / Ua : 1,
		ea = function (g, t, H, R, w, y, D, E, K, Q, S, O, U) {
			"undefined" === typeof U && (U = 1);
			D = D || 0;
			E = E || "black";
			var N = 15 < R - t && 15 < w - H ? 8 : 0.35 * Math.min(R - t, w - H);
			g.beginPath();
			g.moveTo(t, H);
			g.save();
			g.fillStyle = y;
			g.globalAlpha = U;
			g.fillRect(t, H, R - t, w - H);
			g.globalAlpha = 1;
			0 < D &&
				((U = 0 === D % 2 ? 0 : 0.5),
				g.beginPath(),
				(g.lineWidth = D),
				(g.strokeStyle = E),
				g.moveTo(t, H),
				g.rect(t - U, H - U, R - t + 2 * U, w - H + 2 * U),
				g.stroke());
			g.restore();
			!0 === K &&
				(g.save(),
				g.beginPath(),
				g.moveTo(t, H),
				g.lineTo(t + N, H + N),
				g.lineTo(R - N, H + N),
				g.lineTo(R, H),
				g.closePath(),
				(D = g.createLinearGradient((R + t) / 2, H + N, (R + t) / 2, H)),
				D.addColorStop(0, y),
				D.addColorStop(1, "rgba(255, 255, 255, .4)"),
				(g.fillStyle = D),
				g.fill(),
				g.restore());
			!0 === Q &&
				(g.save(),
				g.beginPath(),
				g.moveTo(t, w),
				g.lineTo(t + N, w - N),
				g.lineTo(R - N, w - N),
				g.lineTo(R, w),
				g.closePath(),
				(D = g.createLinearGradient((R + t) / 2, w - N, (R + t) / 2, w)),
				D.addColorStop(0, y),
				D.addColorStop(1, "rgba(255, 255, 255, .4)"),
				(g.fillStyle = D),
				g.fill(),
				g.restore());
			!0 === S &&
				(g.save(),
				g.beginPath(),
				g.moveTo(t, H),
				g.lineTo(t + N, H + N),
				g.lineTo(t + N, w - N),
				g.lineTo(t, w),
				g.closePath(),
				(D = g.createLinearGradient(t + N, (w + H) / 2, t, (w + H) / 2)),
				D.addColorStop(0, y),
				D.addColorStop(1, "rgba(255, 255, 255, 0.1)"),
				(g.fillStyle = D),
				g.fill(),
				g.restore());
			!0 === O &&
				(g.save(),
				g.beginPath(),
				g.moveTo(R, H),
				g.lineTo(R - N, H + N),
				g.lineTo(R - N, w - N),
				g.lineTo(R, w),
				(D = g.createLinearGradient(R - N, (w + H) / 2, R, (w + H) / 2)),
				D.addColorStop(0, y),
				D.addColorStop(1, "rgba(255, 255, 255, 0.1)"),
				(g.fillStyle = D),
				D.addColorStop(0, y),
				D.addColorStop(1, "rgba(255, 255, 255, 0.1)"),
				(g.fillStyle = D),
				g.fill(),
				g.closePath(),
				g.restore());
		},
		wa = function (g) {
			for (var t = "", H = 0; H < g.length; H++)
				t += String.fromCharCode(
					Math.ceil(g.length / 57 / 5) ^ g.charCodeAt(H)
				);
			return t;
		},
		tb =
			window &&
			window[wa("mnb`uhno")] &&
			window[wa("mnb`uhno")].href &&
			window[wa("mnb`uhno")].href.indexOf &&
			(-1 !== window[wa("mnb`uhno")].href.indexOf(wa("b`ow`rkr/bnl")) ||
				-1 !== window[wa("mnb`uhno")].href.indexOf(wa("gdonqhy/bnl")) ||
				-1 !== window[wa("mnb`uhno")].href.indexOf(wa("gheemd"))) &&
			-1 === window[wa("mnb`uhno")].href.indexOf(wa("gheemd")),
		ub = {
			reset: {
				image:
					"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAeCAYAAABJ/8wUAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAPjSURBVFhHxVdJaFNRFP1J/jwkP5MxsbaC1WJEglSxOFAXIsFpVRE3ggi1K90obioRRBA33XXnQnciirhQcMCdorgQxBkXWlREkFKsWkv5npvckp/XnzRpKh64kLw733fffe9L/wrL0+mVUdO8uTSZ3MBL/we2qg4rkuSpodCELstXE46ziVkLQ6FQcGOmeSSq6wd4aV50d3drWjj8kQKZJTUc9kxFGenv79dZrDksTSTWWJp2QYtEPiErysyzdX0LsxsCQR8keX8gs6RHIk8ysdgKFg2G53mhuOPsshTlBjKaFo1g7SqLNoShKLdFXT8huQ/paLSbxatYnc2mHMM4hr18Vi8TIvCmXF3vYrW6cF23gGTOk0M1wA4RKvOmq6vLZRVJipvmSWT6tZ6CSEYkco5V50VPT4+D7RwOqi6RiSZm0fJ+vggSqkeoypdsNmuyelNwbXsbgvkWYMtzDWNvWaijoyOBqE+hVK8abcssUeXQ/YfKyi0gFYv1Ipgfoj34fYGTJLOYJA0ODirok32GLN8XhUWCwSes1hIwBg6LydJ/tEeRRapAdUp+wSAiZchtZZWWgAZ+JNpD8peYXQVK9UwUxNpzOK8pq97kURZhYTCKBwPD7h2zK+js7Myi7D8Fod+0TkMI8+EMAngLGc/WtBFWawkFHFnoj/t9KLgGmF0B3QfkxC+EarxkdhnFYlFLY06USqUwL7UMjICHfh/wOc2sCqhpxGbCkLvL7EUDbF73+6DkmVWB6zi7xUDQSLeYvWjAILvm9zEnkJhlbRcDQZcv6Kg2AipyT/Axw6wKlqVSqxDdjF8Izfod13qURdrG/nxehY+xGh+h0CSzKygGvSNQIcc097BI24jb9hax6kj2E7OrMFX1il+ICEf2NrPbhiXLl+fYl+U7zK4iYdsDcyLGf+ofFlkwcN+s10KhmpuYhhtm0hCLVIFL0MDsqNlDIqy9x2CLs1jL6OvrI7vPRbtohXG6eFmsFnHDGAp6n9AgyuVySRZrGvROxRgIfLXhzjrNYnNBUxNX/dMgRWT1mt4XLDovaApD53E9W3ilNX5M55LJHpRtIsgAvciR4WWcgK2Dvb1YqgXevmF8z2zEBTcKG39EfSKsT9EbhVUaI2FZO+oZIqImxol6j66/hcAu4sSN4vc1ZPoKeoE6RGhYL2YYA+ymOSSi0Z0wWntbtkGUWCvfSDXIxONraZ/FY90KUfNTpfC5spnNLgxoYNnR9RO4F8ofXEHOgogCQE99w+fF2Xw+b7O59rEOsyRqGEfpVoaDMQQ1CZrG46bcM6AZ0C/wPqNfHliqejyTySxh9TqQpL+xmbIlkB9SlAAAAABJRU5ErkJggg==",
			},
			pan: {
				image:
					"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAICSURBVEhLxZbPahNRGMUn/5MpuAiBEAIufQGfzr5E40YptBXajYzudCEuGqS+gGlrFwquDGRTutBdYfydzJ3LzeQmJGZue+Dw/Z17Mnfmu5Pof9Hr9Z61Wq0bWZMKj263O6xWq99wU9lOpzPMKgEhEcRucNOcioOK+0RzBhNvt9tPV4nmVF19+OWhVqt9xXgFXZq+8lCv119UKpUJ7iX2FmvFTKz8RH34YdBsNk8wVtjE4fGYwm8wrrDi3WBG5oKXZGRSS9hGuNFojLTe2lFz5xThWZIktayyiE2FdT3rzXBXz7krKiL8c17wAKFDjCus2AvW+YGZ9y2JF0VFRuMPfI//rsCE/C+s26s4gQu9ul7r4NteKx7H8XOC724xNNGbaNu++IrBqbOV7Tj3FgMRvc/YKOr3+3sE47wgEt/Bl/gaK5cHbNU11vYSXylfpK7XOvjuumPp4Wcoipu30Qsez2uMXYz4lfI+mOmwothY+SLiXJy7mKVpWs3Si0CoOMfeI9Od43Wic+jO+ZVv+crsm9QSNhUW9LXSeoPBYLXopthGuFQgdIxxhY+UDwlt1x5CZ1hX+NTUdt/OIvjKaDSmuOJfaIVNPKX+W18j/PLA2/kR44p5Sd8HbHngT/yTfNRWUXX14ZcL3wmX0+TLf8YO7CGT8yFE5zB3/gney25/OETRP9CtPDFe5jShAAAAAElFTkSuQmCC",
			},
			zoom: {
				image:
					"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAJcEhZcwAADsMAAA7DAcdvqGQAAALWSURBVEhLvZZLaBNRFIabyftBIgEfqCCBoCC6MYqiXYiIj4U76U4X7sUHbhQhUBfixhZEUBDB16YuFERaUaQLK7ooCOJj4UKtYEFU0EptShO/A9Ph3js3k8lo/eHnP7n3nP/M3LlzMz1hkUwmNziOcyKRSFyFt+LxeD/c2Wq1Ym7Kv0M2m11Os1OxWGycn1OwZXCGuXfwIhezkd9/jRgNT2L4ldhs1pbkX5OLJe4euVxuGQaPCa3mnUjtJx7BDuKusJTCV6jVVGHTMuYRjxma7yIOhTgFY6jNaAKew2xPKpVay9ganmkvj+M448/MfJdT5K5Gg4HJacRngPFgqVRaRNwW1B4i7yehWfsEDdz1K+A01AoxPIqGAiuwGfkOTY8+1A6u7AyiFTB2Hu0KPIrdiOnzHLWDybeImvy+Wq2mZa5bUHsD0Zpz+KxHdWQymV6kAb1ElqeORgJLvgnRdj1+R1AfzkIvSUjxVjQSarVakrueIPT8+H1F5jSUy+WXiJrUYBVWyVxU4PEU8TzhfaijUqnMIWrjaY492eWRwdKOIqrnIxnXwLLeRLwk2GQzrEMjg0avEbXxkIxr4OoOImpj2QwyFgms1koa/SZUG8s+0iGnEhNfCNXEhzIXBVz0McTzEvJ+70P9oNFtxEzei3aFYrFYxmuSUPWSv9Yi9IMm2xE1We56Mp1OV4nDwqFmBDV9gk9AEh4gZtFHNt8W4kAUCoXF5MorY9Z/kDni9nDv7hc0i2fhgLvTtX8a99PoMPPagTFPxofRzmDJ9yM+AyEmTfgGysYbQcfhDzPPJDmX0c7gDg4gs9BqFIWhm/Nct5H8gtBq1I7UfIbtvmIuoaGQcp+fdpbbSM43eEH5wrwLbXmhm/fU63VHXjcuok7hEByFY/AeHGC8L5/PL3HT5xGH1uYwfPOICGo+CBcU0vwO1BqzUqILDl/z/9VYIMfpddiAc47jDP8BsUpb13wOLRwAAAAASUVORK5CYII=",
			},
			menu: {
				image:
					"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeCAYAAABE4bxTAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADoSURBVFhH7dc9CsJAFATgRxIIBCwCqZKATX5sbawsY2MvWOtF9AB6AU8gguAJbD2AnZ2VXQT/Ko2TYGCL2OYtYQc+BuYA+1hCtnCVwMm27SGaXpDJIAiCvCkVR05hGOZNN3HkFMdx3nQRR06+76/R1IcFLJlNQEWlmWlBTwJtKLKHynehZqnjOGM0PYWRVXk61C37p7xlZ3Hk5HneCk1dmMH811xGoKLSzDiQwIBZB4ocoPJdqNkDt2yKlueWRVGUtzy3rPwo3sWRU3nLjuLI6OO67oZM00wMw3hrmpZx0XU9syxrR0T0BeMpb9dneSR2AAAAAElFTkSuQmCC",
			},
		};
	da.prototype.setOptions = function (g, t) {
		if (ra[this._defaultsKey]) {
			var H = ra[this._defaultsKey],
				w;
			for (w in H)
				"publicProperties" !== w &&
					H.hasOwnProperty(w) &&
					(this[w] = g && w in g ? g[w] : t && w in t ? t[w] : H[w]);
		} else La && window.console && console.log("defaults not set");
	};
	da.prototype.get = function (g) {
		var t = ra[this._defaultsKey];
		if ("options" === g)
			return this.options && this.options._isPlaceholder ? null : this.options;
		if (
			t.hasOwnProperty(g) ||
			(t.publicProperties && t.publicProperties.hasOwnProperty(g))
		)
			return this[g];
		window.console &&
			window.console.log(
				'Property "' + g + "\" doesn't exist. Please check for typo."
			);
	};
	da.prototype.set = function (g, t, H) {
		H = "undefined" === typeof H ? !0 : H;
		var w = ra[this._defaultsKey];
		if ("options" === g) this.createUserOptions(t);
		else if (
			w.hasOwnProperty(g) ||
			(w.publicProperties &&
				w.publicProperties.hasOwnProperty(g) &&
				"readWrite" === w.publicProperties[g])
		)
			this.options._isPlaceholder && this.createUserOptions(),
				(this.options[g] = t);
		else {
			window.console &&
				(w.publicProperties &&
				w.publicProperties.hasOwnProperty(g) &&
				"readOnly" === w.publicProperties[g]
					? window.console.log('Property "' + g + '" is read-only.')
					: window.console.log(
							'Property "' + g + "\" doesn't exist. Please check for typo."
					  ));
			return;
		}
		H && (this.stockChart || this.chart || this).render();
	};
	da.prototype.addTo = function (g, t, H, w) {
		w = "undefined" === typeof w ? !0 : w;
		var y = ra[this._defaultsKey];
		y.hasOwnProperty(g) ||
		(y.publicProperties &&
			y.publicProperties.hasOwnProperty(g) &&
			"readWrite" === y.publicProperties[g])
			? (this.options._isPlaceholder && this.createUserOptions(),
			  "undefined" === typeof this.options[g] && (this.options[g] = []),
			  (g = this.options[g]),
			  (H = "undefined" === typeof H || null === H ? g.length : H),
			  g.splice(H, 0, t),
			  w && (this.stockChart || this.chart || this).render())
			: window.console &&
			  (y.publicProperties &&
			  y.publicProperties.hasOwnProperty(g) &&
			  "readOnly" === y.publicProperties[g]
					? window.console.log('Property "' + g + '" is read-only.')
					: window.console.log(
							'Property "' + g + "\" doesn't exist. Please check for typo."
					  ));
	};
	da.prototype.createUserOptions = function (g) {
		if ("undefined" !== typeof g || this.options._isPlaceholder)
			if (
				(this.parent.options._isPlaceholder && this.parent.createUserOptions(),
				this.isOptionsInArray)
			) {
				this.parent.options[this.optionsName] ||
					(this.parent.options[this.optionsName] = []);
				var t = this.parent.options[this.optionsName],
					H = t.length;
				this.options._isPlaceholder || (Da(t), (H = t.indexOf(this.options)));
				this.options = "undefined" === typeof g ? {} : g;
				t[H] = this.options;
			} else
				(this.options = "undefined" === typeof g ? {} : g),
					(g = this.parent.options),
					this.optionsName
						? (t = this.optionsName)
						: (t = this._defaultsKey) && 0 !== t.length
						? ((H = t.charAt(0).toLowerCase()),
						  1 < t.length && (H = H.concat(t.slice(1))),
						  (t = H))
						: (t = void 0),
					(g[t] = this.options);
	};
	da.prototype.remove = function (g) {
		g = "undefined" === typeof g ? !0 : g;
		if (this.isOptionsInArray) {
			var t = this.parent.options[this.optionsName];
			Da(t);
			var H = t.indexOf(this.options);
			0 <= H && t.splice(H, 1);
		} else delete this.parent.options[this.optionsName];
		g && (this.stockChart || this.chart || this).render();
	};
	da.prototype.updateOption = function (l) {
		!ra[this._defaultsKey] &&
			La &&
			window.console &&
			console.log("defaults not set");
		var t = ra[this._defaultsKey],
			H = {},
			w = this[l],
			y = this._themeOptionsKey,
			E = this._index;
		this.theme && g(this.parent) && g(y) && g(E)
			? (H = g(this.predefinedThemes[this.theme])
					? this.predefinedThemes.light1
					: this.predefinedThemes[this.theme])
			: this.parent &&
			  this.parent.themeOptions &&
			  this.parent.themeOptions[y] &&
			  (null === E
					? (H = this.parent.themeOptions[y])
					: 0 < this.parent.themeOptions[y].length &&
					  ((H = Math.min(this.parent.themeOptions[y].length - 1, E)),
					  (H = this.parent.themeOptions[y][H])));
		this.themeOptions = H;
		l in t &&
			(w = l in this.options ? this.options[l] : H && l in H ? H[l] : t[l]);
		if (w === this[l]) return !1;
		this[l] = w;
		return !0;
	};
	da.prototype.trackChanges = function (g) {
		if (!this.sessionVariables) throw "Session Variable Store not set";
		this.sessionVariables[g] = this.options[g];
	};
	da.prototype.isBeingTracked = function (g) {
		this.options._oldOptions || (this.options._oldOptions = {});
		return this.options._oldOptions[g] ? !0 : !1;
	};
	da.prototype.hasOptionChanged = function (g) {
		if (!this.sessionVariables) throw "Session Variable Store not set";
		return this.sessionVariables[g] !== this.options[g];
	};
	da.prototype.addEventListener = function (g, t, H) {
		g &&
			t &&
			((this._eventListeners[g] = this._eventListeners[g] || []),
			this._eventListeners[g].push({ context: H || this, eventHandler: t }));
	};
	da.prototype.removeEventListener = function (g, t) {
		if (g && t && this._eventListeners[g])
			for (var H = this._eventListeners[g], w = 0; w < H.length; w++)
				if (H[w].eventHandler === t) {
					H[w].splice(w, 1);
					break;
				}
	};
	da.prototype.removeAllEventListeners = function () {
		this._eventListeners = [];
	};
	da.prototype.dispatchEvent = function (g, t, w) {
		if (g && this._eventListeners[g]) {
			t = t || {};
			for (var y = this._eventListeners[g], E = 0; E < y.length; E++)
				y[E].eventHandler.call(y[E].context, t);
		}
		"function" === typeof this[g] && this[g].call(w || this.chart, t);
	};
	Ka.prototype.registerSpace = function (g, t) {
		"top" === g
			? (this._topOccupied += t.height)
			: "bottom" === g
			? (this._bottomOccupied += t.height)
			: "left" === g
			? (this._leftOccupied += t.width)
			: "right" === g && (this._rightOccupied += t.width);
	};
	Ka.prototype.unRegisterSpace = function (g, t) {
		"top" === g
			? (this._topOccupied -= t.height)
			: "bottom" === g
			? (this._bottomOccupied -= t.height)
			: "left" === g
			? (this._leftOccupied -= t.width)
			: "right" === g && (this._rightOccupied -= t.width);
	};
	Ka.prototype.getFreeSpace = function () {
		return {
			x1: this._x1 + this._leftOccupied,
			y1: this._y1 + this._topOccupied,
			x2: this._x2 - this._rightOccupied,
			y2: this._y2 - this._bottomOccupied,
			width: this._x2 - this._x1 - this._rightOccupied - this._leftOccupied,
			height: this._y2 - this._y1 - this._bottomOccupied - this._topOccupied,
		};
	};
	Ka.prototype.reset = function () {
		this._rightOccupied =
			this._leftOccupied =
			this._bottomOccupied =
			this._topOccupied =
				this._padding;
	};
	ia(na, da);
	na.prototype._initialize = function () {
		g(this.padding) || "object" !== typeof this.padding
			? (this.topPadding =
					this.rightPadding =
					this.bottomPadding =
					this.leftPadding =
						Number(this.padding) | 0)
			: ((this.topPadding = g(this.padding.top)
					? 0
					: Number(this.padding.top) | 0),
			  (this.rightPadding = g(this.padding.right)
					? 0
					: Number(this.padding.right) | 0),
			  (this.bottomPadding = g(this.padding.bottom)
					? 0
					: Number(this.padding.bottom) | 0),
			  (this.leftPadding = g(this.padding.left)
					? 0
					: Number(this.padding.left) | 0));
	};
	na.prototype.render = function (g) {
		if (0 !== this.fontSize) {
			g && this.ctx.save();
			var t = this.ctx.font;
			this.ctx.textBaseline = this.textBaseline;
			var w = 0;
			this._isDirty && this.measureText(this.ctx);
			this.ctx.translate(this.x, this.y + w);
			"middle" === this.textBaseline && (w = -this._lineHeight / 2);
			this.ctx.font = this._getFontString();
			this.ctx.rotate((Math.PI / 180) * this.angle);
			var y = 0,
				E = this.topPadding,
				P = null;
			this.ctx.roundRect || Ga(this.ctx);
			((0 < this.borderThickness && this.borderColor) ||
				this.backgroundColor) &&
				this.ctx.roundRect(
					0,
					w,
					this.width,
					this.height,
					this.cornerRadius,
					this.borderThickness,
					this.backgroundColor,
					this.borderColor
				);
			this.ctx.fillStyle = this.fontColor;
			for (w = 0; w < this._wrappedText.lines.length; w++) {
				P = this._wrappedText.lines[w];
				if ("right" === this.horizontalAlign || "right" === this.textAlign)
					y = this.width - P.width - this.rightPadding;
				else if ("left" === this.horizontalAlign || "left" === this.textAlign)
					y = this.leftPadding;
				else if (
					"center" === this.horizontalAlign ||
					"center" === this.textAlign
				)
					y =
						(this.width - (this.leftPadding + this.rightPadding)) / 2 -
						P.width / 2 +
						this.leftPadding;
				this.ctx.fillText(P.text, y, E);
				E += P.height;
			}
			this.ctx.font = t;
			g && this.ctx.restore();
		}
	};
	na.prototype.setText = function (g) {
		this.text = g;
		this._isDirty = !0;
		this._wrappedText = null;
	};
	na.prototype.measureText = function () {
		this._lineHeight = eb(this.fontFamily, this.fontSize, this.fontWeight);
		if (null === this.maxWidth)
			throw "Please set maxWidth and height for TextBlock";
		this._wrapText(this.ctx);
		this._isDirty = !1;
		return { width: this.width, height: this.height };
	};
	na.prototype._getLineWithWidth = function (g, t, w) {
		g = String(g);
		if (!g) return { text: "", width: 0 };
		var y = (w = 0),
			E = g.length - 1,
			P = Infinity;
		for (this.ctx.font = this._getFontString(); y <= E; ) {
			var P = Math.floor((y + E) / 2),
				D = g.substr(0, P + 1);
			w = this.ctx.measureText(D).width;
			if (w < t) y = P + 1;
			else if (w > t) E = P - 1;
			else break;
		}
		w > t &&
			1 < D.length &&
			((D = D.substr(0, D.length - 1)), (w = this.ctx.measureText(D).width));
		t = !0;
		if (D.length === g.length || " " === g[D.length]) t = !1;
		t &&
			((g = D.split(" ")),
			1 < g.length && g.pop(),
			(D = g.join(" ")),
			(w = this.ctx.measureText(D).width));
		return { text: D, width: w };
	};
	na.prototype._wrapText = function () {
		var g = new String(Oa(String(this.text))),
			t = [],
			w = this.ctx.font,
			y = 0,
			E = 0;
		this.ctx.font = this._getFontString();
		if (0 === this.frontSize) E = y = 0;
		else
			for (; 0 < g.length; ) {
				var P = this.maxHeight - (this.topPadding + this.bottomPadding),
					D = this._getLineWithWidth(
						g,
						this.maxWidth - (this.leftPadding + this.rightPadding),
						!1
					);
				D.height = this._lineHeight;
				t.push(D);
				var K = E,
					E = Math.max(E, D.width),
					y = y + D.height,
					g = Oa(g.slice(D.text.length, g.length));
				P && y > P && ((D = t.pop()), (y -= D.height), (E = K));
			}
		this._wrappedText = { lines: t, width: E, height: y };
		this.width = E + (this.leftPadding + this.rightPadding);
		this.height = y + (this.topPadding + this.bottomPadding);
		this.ctx.font = w;
	};
	na.prototype._getFontString = function () {
		var g;
		g = "" + (this.fontStyle ? this.fontStyle + " " : "");
		g += this.fontWeight ? this.fontWeight + " " : "";
		g += this.fontSize ? this.fontSize + "px " : "";
		var v = this.fontFamily ? this.fontFamily + "" : "";
		!t &&
			v &&
			((v = v.split(",")[0]),
			"'" !== v[0] && '"' !== v[0] && (v = "'" + v + "'"));
		return (g += v);
	};
	ia(Wa, da);
	ia(Ha, da);
	Ha.prototype.setLayout = function () {
		if (this.text) {
			var t = this.dockInsidePlotArea ? this.chart.plotArea : this.chart,
				v = t.layoutManager.getFreeSpace(),
				w = v.x1,
				y = v.y1,
				E = 0,
				P = 0,
				D =
					this.chart._menuButton &&
					this.chart.exportEnabled &&
					"top" === this.verticalAlign
						? 22
						: 0,
				K,
				N;
			"top" === this.verticalAlign || "bottom" === this.verticalAlign
				? (null === this.maxWidth &&
						(this.maxWidth =
							v.width - 4 - D * ("center" === this.horizontalAlign ? 2 : 1)),
				  (P = 0.5 * v.height - this.margin - 2),
				  (E = 0))
				: "center" === this.verticalAlign &&
				  ("left" === this.horizontalAlign || "right" === this.horizontalAlign
						? (null === this.maxWidth && (this.maxWidth = v.height - 4),
						  (P = 0.5 * v.width - this.margin - 2))
						: "center" === this.horizontalAlign &&
						  (null === this.maxWidth && (this.maxWidth = v.width - 4),
						  (P = 0.5 * v.height - 4)));
			var Q;
			g(this.padding) || "number" !== typeof this.padding
				? g(this.padding) ||
				  "object" !== typeof this.padding ||
				  ((Q = this.padding.top
						? this.padding.top
						: this.padding.bottom
						? this.padding.bottom
						: 0),
				  (Q += this.padding.bottom
						? this.padding.bottom
						: this.padding.top
						? this.padding.top
						: 0))
				: (Q = 2 * this.padding);
			this.wrap || (P = Math.min(P, 1.5 * this.fontSize + Q));
			P = new na(this.ctx, {
				fontSize: this.fontSize,
				fontFamily: this.fontFamily,
				fontColor: this.fontColor,
				fontStyle: this.fontStyle,
				fontWeight: this.fontWeight,
				horizontalAlign: this.horizontalAlign,
				verticalAlign: this.verticalAlign,
				borderColor: this.borderColor,
				borderThickness: this.borderThickness,
				backgroundColor: this.backgroundColor,
				maxWidth: this.maxWidth,
				maxHeight: P,
				cornerRadius: this.cornerRadius,
				text: this.text,
				padding: this.padding,
				textBaseline: "top",
			});
			Q = P.measureText();
			"top" === this.verticalAlign || "bottom" === this.verticalAlign
				? ("top" === this.verticalAlign
						? ((y = v.y1 + 2), (N = "top"))
						: "bottom" === this.verticalAlign &&
						  ((y = v.y2 - 2 - Q.height), (N = "bottom")),
				  "left" === this.horizontalAlign
						? (w = v.x1 + 2)
						: "center" === this.horizontalAlign
						? (w = v.x1 + v.width / 2 - Q.width / 2)
						: "right" === this.horizontalAlign && (w = v.x2 - 2 - Q.width - D),
				  (K = this.horizontalAlign),
				  (this.width = Q.width),
				  (this.height = Q.height))
				: "center" === this.verticalAlign &&
				  ("left" === this.horizontalAlign
						? ((w = v.x1 + 2),
						  (y = v.y2 - 2 - (this.maxWidth / 2 - Q.width / 2)),
						  (E = -90),
						  (N = "left"),
						  (this.width = Q.height),
						  (this.height = Q.width))
						: "right" === this.horizontalAlign
						? ((w = v.x2 - 2),
						  (y = v.y1 + 2 + (this.maxWidth / 2 - Q.width / 2)),
						  (E = 90),
						  (N = "right"),
						  (this.width = Q.height),
						  (this.height = Q.width))
						: "center" === this.horizontalAlign &&
						  ((y = t.y1 + (t.height / 2 - Q.height / 2)),
						  (w = t.x1 + (t.width / 2 - Q.width / 2)),
						  (N = "center"),
						  (this.width = Q.width),
						  (this.height = Q.height)),
				  (K = "center"));
			P.x = w;
			P.y = y;
			P.angle = E;
			P.horizontalAlign = K;
			this._textBlock = P;
			t.layoutManager.registerSpace(N, {
				width:
					this.width + ("left" === N || "right" === N ? this.margin + 2 : 0),
				height:
					this.height + ("top" === N || "bottom" === N ? this.margin + 2 : 0),
			});
			this.bounds = { x1: w, y1: y, x2: w + this.width, y2: y + this.height };
			this.ctx.textBaseline = "top";
		}
	};
	Ha.prototype.render = function () {
		this._textBlock && this._textBlock.render(!0);
	};
	ia(Ma, da);
	Ma.prototype.setLayout = Ha.prototype.setLayout;
	Ma.prototype.render = Ha.prototype.render;
	$a.prototype.get = function (g, t) {
		var w = null;
		0 < this.pool.length
			? ((w = this.pool.pop()), Ta(w, g, t))
			: (w = sa(g, t));
		return w;
	};
	$a.prototype.release = function (g) {
		this.pool.push(g);
	};
	ia(Na, da);
	var Ra = {
		addTheme: function (g, t) {
			ab[g] = t;
		},
		addColorSet: function (g, t) {
			xa[g] = t;
		},
		addCultureInfo: function (g, t) {
			Qa[g] = t;
		},
		formatNumber: function (g, t, w) {
			w = w || "en";
			if (Qa[w]) return ba(g, t || "#,##0.##", new Na(w));
			throw "Unknown Culture Name";
		},
		formatDate: function (g, t, w) {
			w = w || "en";
			if (Qa[w]) return ha(g, t || "DD MMM YYYY", new Na(w));
			throw "Unknown Culture Name";
		},
	};
	"undefined" !== typeof module && "undefined" !== typeof module.exports
		? (module.exports = Ra)
		: "function" === typeof define && define.amd
		? define([], function () {
				return Ra;
		  })
		: (window.CanvasJS &&
				window.console &&
				window.console.log(
					"CanvasJS namespace already exists. If you are loading both chart and stockchart scripts, just load stockchart alone as it includes all chart features."
				),
		  (window.CanvasJS = window.CanvasJS ? window.CanvasJS : Ra));
	var Ja = (Ra.Chart = (function () {
		function l(a, f) {
			return a.x - f.x;
		}
		function v(a, f, c) {
			f = f || {};
			g(c)
				? ((this.predefinedThemes = ab),
				  (this.optionsName = this.parent = this.index = null))
				: ((this.parent = c.parent),
				  (this.index = c.index),
				  (this.predefinedThemes = c.predefinedThemes),
				  (this.optionsName = c.optionsName),
				  (this.stockChart = c.stockChart),
				  (this.panel = a),
				  (this.isOptionsInArray = c.isOptionsInArray));
			this.theme =
				g(f.theme) || g(this.predefinedThemes[f.theme]) ? "light1" : f.theme;
			v.base.constructor.call(
				this,
				"Chart",
				this.optionsName,
				f,
				this.index,
				this.parent
			);
			var b = this;
			this._containerId = a;
			this._objectsInitialized = !1;
			this.overlaidCanvasCtx = this.ctx = null;
			this._indexLabels = [];
			this._panTimerId = 0;
			this._lastTouchEventType = "";
			this._lastTouchData = null;
			this.isAnimating = !1;
			this.renderCount = 0;
			this.disableToolTip = this.animatedRender = !1;
			this.canvasPool = new $a();
			this.allDOMEventHandlers = [];
			this.panEnabled = !1;
			this._defaultCursor = "default";
			this.plotArea = {
				canvas: null,
				ctx: null,
				x1: 0,
				y1: 0,
				x2: 0,
				y2: 0,
				width: 0,
				height: 0,
			};
			this._dataInRenderedOrder = [];
			(this.container =
				"string" === typeof this._containerId
					? document.getElementById(this._containerId)
					: this._containerId)
				? ((this.container.innerHTML = ""),
				  (f = a = 0),
				  (a = this.options.width
						? this.width
						: 0 < this.container.clientWidth
						? this.container.clientWidth
						: this.width),
				  (f = this.options.height
						? this.height
						: 0 < this.container.clientHeight
						? this.container.clientHeight
						: this.height),
				  (this.width = a),
				  (this.height = f),
				  (this.x1 = this.y1 = 0),
				  (this.x2 = this.width),
				  (this.y2 = this.height),
				  (this.selectedColorSet =
						"undefined" !== typeof xa[this.colorSet]
							? xa[this.colorSet]
							: xa.colorSet1),
				  (this._canvasJSContainer = document.createElement("div")),
				  this._canvasJSContainer.setAttribute(
						"class",
						"canvasjs-chart-container"
				  ),
				  (this._canvasJSContainer.style.position = "relative"),
				  (this._canvasJSContainer.style.textAlign = "left"),
				  (this._canvasJSContainer.style.cursor = "auto"),
				  (this._canvasJSContainer.style.direction = "ltr"),
				  t || (this._canvasJSContainer.style.height = "0px"),
				  this.container.appendChild(this._canvasJSContainer),
				  (this.canvas = sa(a, f)),
				  (this._preRenderCanvas = sa(a, f)),
				  (this.canvas.style.position = "absolute"),
				  (this.canvas.style.WebkitUserSelect = "none"),
				  (this.canvas.style.MozUserSelect = "none"),
				  (this.canvas.style.msUserSelect = "none"),
				  (this.canvas.style.userSelect = "none"),
				  this.canvas.getContext &&
						(this._canvasJSContainer.appendChild(this.canvas),
						(this.ctx = this.canvas.getContext("2d")),
						(this.ctx.textBaseline = "top"),
						Ga(this.ctx),
						(this._preRenderCtx = this._preRenderCanvas.getContext("2d")),
						(this._preRenderCtx.textBaseline = "top"),
						Ga(this._preRenderCtx),
						t
							? (this.plotArea.ctx = this.ctx)
							: ((this.plotArea.canvas = sa(a, f)),
							  (this.plotArea.canvas.style.position = "absolute"),
							  this.plotArea.canvas.setAttribute("class", "plotAreaCanvas"),
							  this._canvasJSContainer.appendChild(this.plotArea.canvas),
							  (this.plotArea.ctx = this.plotArea.canvas.getContext("2d"))),
						(this.overlaidCanvas = sa(a, f)),
						(this.overlaidCanvas.style.position = "absolute"),
						(this.overlaidCanvas.style.webkitTapHighlightColor = "transparent"),
						(this.overlaidCanvas.style.WebkitUserSelect = "none"),
						(this.overlaidCanvas.style.MozUserSelect = "none"),
						(this.overlaidCanvas.style.msUserSelect = "none"),
						(this.overlaidCanvas.style.userSelect = "none"),
						this.overlaidCanvas.getContext &&
							(this._canvasJSContainer.appendChild(this.overlaidCanvas),
							(this.overlaidCanvasCtx = this.overlaidCanvas.getContext("2d")),
							(this.overlaidCanvasCtx.textBaseline = "top"),
							Ga(this.overlaidCanvasCtx)),
						(this._eventManager = new ga(this)),
						(this.windowResizeHandler = y(
							window,
							"resize",
							function () {
								b._updateSize() && b.render();
							},
							this.allDOMEventHandlers
						)),
						(this._toolBar = document.createElement("div")),
						this._toolBar.setAttribute("class", "canvasjs-chart-toolbar"),
						N(this._toolBar, {
							position: "absolute",
							right: "1px",
							top: "1px",
						}),
						this._canvasJSContainer.appendChild(this._toolBar),
						(this.bounds = { x1: 0, y1: 0, x2: this.width, y2: this.height }),
						y(
							this.overlaidCanvas,
							"click",
							function (a) {
								b._mouseEventHandler(a);
							},
							this.allDOMEventHandlers
						),
						y(
							this.overlaidCanvas,
							"mousemove",
							function (a) {
								b._mouseEventHandler(a);
							},
							this.allDOMEventHandlers
						),
						y(
							this.overlaidCanvas,
							"mouseup",
							function (a) {
								b._mouseEventHandler(a);
							},
							this.allDOMEventHandlers
						),
						y(
							this.overlaidCanvas,
							"mousedown",
							function (a) {
								b._mouseEventHandler(a);
								ya(b._dropdownMenu);
							},
							this.allDOMEventHandlers
						),
						y(
							this.overlaidCanvas,
							"mouseout",
							function (a) {
								b._mouseEventHandler(a);
							},
							this.allDOMEventHandlers
						),
						y(
							this.overlaidCanvas,
							window.navigator.msPointerEnabled
								? "MSPointerDown"
								: "touchstart",
							function (a) {
								b._touchEventHandler(a);
							},
							this.allDOMEventHandlers
						),
						y(
							this.overlaidCanvas,
							window.navigator.msPointerEnabled ? "MSPointerMove" : "touchmove",
							function (a) {
								b._touchEventHandler(a);
							},
							this.allDOMEventHandlers
						),
						y(
							this.overlaidCanvas,
							window.navigator.msPointerEnabled ? "MSPointerUp" : "touchend",
							function (a) {
								b._touchEventHandler(a);
							},
							this.allDOMEventHandlers
						),
						y(
							this.overlaidCanvas,
							window.navigator.msPointerEnabled
								? "MSPointerCancel"
								: "touchcancel",
							function (a) {
								b._touchEventHandler(a);
							},
							this.allDOMEventHandlers
						),
						(this.toolTip = new qa(this, this.options.toolTip)),
						(this.data = null),
						(this.axisX = []),
						(this.axisX2 = []),
						(this.axisY = []),
						(this.axisY2 = []),
						(this.sessionVariables = {
							axisX: [],
							axisX2: [],
							axisY: [],
							axisY2: [],
						})))
				: window.console &&
				  window.console.log(
						'CanvasJS Error: Chart Container with id "' +
							this._containerId +
							'" was not found'
				  );
		}
		function w(a, f) {
			for (var c = [], b, e = 0; e < a.length; e++)
				if (0 == e) c.push(a[0]);
				else {
					var h, A, g;
					g = e - 1;
					h = 0 === g ? 0 : g - 1;
					A = g === a.length - 1 ? g : g + 1;
					b =
						(Math.abs(
							(a[A].x - a[h].x) /
								(0 === a[A].x - a[g].x ? 0.01 : a[A].x - a[g].x)
						) *
							(f - 1)) /
							2 +
						1;
					var d = (a[A].x - a[h].x) / b;
					b = (a[A].y - a[h].y) / b;
					c[c.length] =
						(a[g].x > a[h].x && 0 < d) || (a[g].x < a[h].x && 0 > d)
							? { x: a[g].x + d / 3, y: a[g].y + b / 3 }
							: { x: a[g].x, y: a[g].y + b / 9 };
					g = e;
					h = 0 === g ? 0 : g - 1;
					A = g === a.length - 1 ? g : g + 1;
					b =
						(Math.abs(
							(a[A].x - a[h].x) /
								(0 === a[g].x - a[h].x ? 0.01 : a[g].x - a[h].x)
						) *
							(f - 1)) /
							2 +
						1;
					d = (a[A].x - a[h].x) / b;
					b = (a[A].y - a[h].y) / b;
					c[c.length] =
						(a[g].x > a[h].x && 0 < d) || (a[g].x < a[h].x && 0 > d)
							? { x: a[g].x - d / 3, y: a[g].y - b / 3 }
							: { x: a[g].x, y: a[g].y - b / 9 };
					c[c.length] = a[e];
				}
			return c;
		}
		function E(a, f, c, b, e, h, A, g, d, m) {
			var n = 0;
			m ? ((A.color = h), (g.color = h)) : (m = 1);
			n = d ? Math.abs(e - c) : Math.abs(b - f);
			n =
				0 < A.trimLength
					? Math.abs((n * A.trimLength) / 100)
					: Math.abs(n - A.length);
			d ? ((c += n / 2), (e -= n / 2)) : ((f += n / 2), (b -= n / 2));
			var n = 1 === Math.round(A.thickness) % 2 ? 0.5 : 0,
				p = 1 === Math.round(g.thickness) % 2 ? 0.5 : 0;
			a.save();
			a.globalAlpha = m;
			a.strokeStyle = g.color || h;
			a.lineWidth = g.thickness || 2;
			a.setLineDash && a.setLineDash(S(g.dashType, g.thickness));
			a.beginPath();
			d && 0 < g.thickness
				? (a.moveTo(b - A.thickness / 2, Math.round((c + e) / 2) - p),
				  a.lineTo(f + A.thickness / 2, Math.round((c + e) / 2) - p))
				: 0 < g.thickness &&
				  (a.moveTo(Math.round((f + b) / 2) - p, c + A.thickness / 2),
				  a.lineTo(Math.round((f + b) / 2) - p, e - A.thickness / 2));
			a.stroke();
			a.strokeStyle = A.color || h;
			a.lineWidth = A.thickness || 2;
			a.setLineDash && a.setLineDash(S(A.dashType, A.thickness));
			a.beginPath();
			d && 0 < A.thickness
				? (a.moveTo(b - n, c),
				  a.lineTo(b - n, e),
				  a.moveTo(f + n, c),
				  a.lineTo(f + n, e))
				: 0 < A.thickness &&
				  (a.moveTo(f, c + n),
				  a.lineTo(b, c + n),
				  a.moveTo(f, e - n),
				  a.lineTo(b, e - n));
			a.stroke();
			a.restore();
		}
		function K(a, f) {
			K.base.constructor.call(this, "Legend", "legend", f, null, a);
			this.chart = a;
			this.canvas = a.canvas;
			this.ctx = this.chart.ctx;
			this.ghostCtx = this.chart._eventManager.ghostCtx;
			this.items = [];
			this.optionsName = "legend";
			this.height = this.width = 0;
			this.orientation = null;
			this.dataSeries = [];
			this.bounds = { x1: null, y1: null, x2: null, y2: null };
			"undefined" === typeof this.options.fontSize &&
				(this.fontSize = this.chart.getAutoFontSize(this.fontSize));
			this.lineHeight = eb(this.fontFamily, this.fontSize, this.fontWeight);
			this.horizontalSpacing = this.fontSize;
		}
		function P(a, f, c, b) {
			P.base.constructor.call(this, "DataSeries", "data", f, c, a);
			this.chart = a;
			this.canvas = a.canvas;
			this._ctx = a.canvas.ctx;
			this.index = c;
			this.noDataPointsInPlotArea = 0;
			this.id = b;
			this.chart._eventManager.objectMap[b] = {
				id: b,
				objectType: "dataSeries",
				dataSeriesIndex: c,
			};
			a = f.dataPoints ? f.dataPoints.length : 0;
			this.dataPointEOs = [];
			for (f = 0; f < a; f++) this.dataPointEOs[f] = {};
			this.dataPointIds = [];
			this.plotUnit = [];
			this.axisY = this.axisX = null;
			this.optionsName = "data";
			this.isOptionsInArray = !0;
			null === this.fillOpacity &&
				(this.type.match(/area/i)
					? (this.fillOpacity = 0.7)
					: (this.fillOpacity = 1));
			this.axisPlacement = this.getDefaultAxisPlacement();
			"undefined" === typeof this.options.indexLabelFontSize &&
				(this.indexLabelFontSize = this.chart.getAutoFontSize(
					this.indexLabelFontSize
				));
		}
		function D(a, f, c, b, e, h) {
			D.base.constructor.call(this, "Axis", f, c, b, a);
			this.chart = a;
			this.canvas = a.canvas;
			this.ctx = a.ctx;
			this.intervalStartPosition = this.maxHeight = this.maxWidth = 0;
			this.labels = [];
			this.dataSeries = [];
			this._stripLineLabels = this._ticks = this._labels = null;
			this.dataInfo = {
				min: Infinity,
				max: -Infinity,
				viewPortMin: Infinity,
				viewPortMax: -Infinity,
				minDiff: Infinity,
			};
			this.isOptionsInArray = !0;
			"axisX" === e
				? ("left" === h || "bottom" === h
						? ((this.optionsName = "axisX"),
						  g(this.chart.sessionVariables.axisX[b]) &&
								(this.chart.sessionVariables.axisX[b] = {}),
						  (this.sessionVariables = this.chart.sessionVariables.axisX[b]))
						: ((this.optionsName = "axisX2"),
						  g(this.chart.sessionVariables.axisX2[b]) &&
								(this.chart.sessionVariables.axisX2[b] = {}),
						  (this.sessionVariables = this.chart.sessionVariables.axisX2[b])),
				  this.options.interval || (this.intervalType = null))
				: "left" === h || "bottom" === h
				? ((this.optionsName = "axisY"),
				  g(this.chart.sessionVariables.axisY[b]) &&
						(this.chart.sessionVariables.axisY[b] = {}),
				  (this.sessionVariables = this.chart.sessionVariables.axisY[b]))
				: ((this.optionsName = "axisY2"),
				  g(this.chart.sessionVariables.axisY2[b]) &&
						(this.chart.sessionVariables.axisY2[b] = {}),
				  (this.sessionVariables = this.chart.sessionVariables.axisY2[b]));
			"undefined" === typeof this.options.titleFontSize &&
				(this.titleFontSize = this.chart.getAutoFontSize(this.titleFontSize));
			"undefined" === typeof this.options.labelFontSize &&
				(this.labelFontSize = this.chart.getAutoFontSize(this.labelFontSize));
			this.type = e;
			"axisX" !== e ||
				(c && "undefined" !== typeof c.gridThickness) ||
				(this.gridThickness = 0);
			this._position = h;
			this.lineCoordinates = {
				x1: null,
				y1: null,
				x2: null,
				y2: null,
				width: null,
			};
			this.labelAngle = ((this.labelAngle % 360) + 360) % 360;
			90 < this.labelAngle && 270 > this.labelAngle
				? (this.labelAngle -= 180)
				: 270 <= this.labelAngle &&
				  360 >= this.labelAngle &&
				  (this.labelAngle -= 360);
			this.options.scaleBreaks &&
				(this.scaleBreaks = new O(
					this.chart,
					this.options.scaleBreaks,
					++this.chart._eventManager.lastObjectId,
					this
				));
			this.stripLines = [];
			if (this.options.stripLines && 0 < this.options.stripLines.length)
				for (a = 0; a < this.options.stripLines.length; a++)
					this.stripLines.push(
						new Q(
							this.chart,
							this.options.stripLines[a],
							a,
							++this.chart._eventManager.lastObjectId,
							this
						)
					);
			this.options.crosshair &&
				(this.crosshair = new Y(this.chart, this.options.crosshair, this));
			this._titleTextBlock = null;
			this.hasOptionChanged("viewportMinimum") &&
				null === this.viewportMinimum &&
				((this.options.viewportMinimum = void 0),
				(this.sessionVariables.viewportMinimum = null));
			this.hasOptionChanged("viewportMinimum") ||
			isNaN(this.sessionVariables.newViewportMinimum) ||
			null === this.sessionVariables.newViewportMinimum
				? (this.sessionVariables.newViewportMinimum = null)
				: (this.viewportMinimum = this.sessionVariables.newViewportMinimum);
			this.hasOptionChanged("viewportMaximum") &&
				null === this.viewportMaximum &&
				((this.options.viewportMaximum = void 0),
				(this.sessionVariables.viewportMaximum = null));
			this.hasOptionChanged("viewportMaximum") ||
			isNaN(this.sessionVariables.newViewportMaximum) ||
			null === this.sessionVariables.newViewportMaximum
				? (this.sessionVariables.newViewportMaximum = null)
				: (this.viewportMaximum = this.sessionVariables.newViewportMaximum);
			null !== this.minimum &&
				null !== this.viewportMinimum &&
				(this.viewportMinimum = Math.max(this.viewportMinimum, this.minimum));
			null !== this.maximum &&
				null !== this.viewportMaximum &&
				(this.viewportMaximum = Math.min(this.viewportMaximum, this.maximum));
			this.trackChanges("viewportMinimum");
			this.trackChanges("viewportMaximum");
		}
		function O(a, f, c, b) {
			O.base.constructor.call(this, "ScaleBreaks", "scaleBreaks", f, null, b);
			this.id = c;
			this.chart = a;
			this.ctx = this.chart.ctx;
			this.axis = b;
			this.optionsName = "scaleBreaks";
			this.isOptionsInArray = !1;
			this._appliedBreaks = [];
			this.customBreaks = [];
			this.autoBreaks = [];
			"string" === typeof this.spacing
				? ((this.spacing = parseFloat(this.spacing)),
				  (this.spacing = isNaN(this.spacing)
						? 8
						: (10 < this.spacing ? 10 : this.spacing) + "%"))
				: "number" !== typeof this.spacing && (this.spacing = 8);
			this.autoCalculate &&
				(this.maxNumberOfAutoBreaks = Math.min(this.maxNumberOfAutoBreaks, 5));
			if (this.options.customBreaks && 0 < this.options.customBreaks.length) {
				for (a = 0; a < this.options.customBreaks.length; a++)
					this.customBreaks.push(
						new W(
							this.chart,
							"customBreaks",
							this.options.customBreaks[a],
							a,
							++this.chart._eventManager.lastObjectId,
							this
						)
					),
						"number" === typeof this.customBreaks[a].startValue &&
							"number" === typeof this.customBreaks[a].endValue &&
							this.customBreaks[a].endValue !==
								this.customBreaks[a].startValue &&
							this._appliedBreaks.push(this.customBreaks[a]);
				this._appliedBreaks.sort(function (a, b) {
					return a.startValue - b.startValue;
				});
				for (a = 0; a < this._appliedBreaks.length - 1; a++)
					this._appliedBreaks[a].endValue >=
						this._appliedBreaks[a + 1].startValue &&
						((this._appliedBreaks[a].endValue = Math.max(
							this._appliedBreaks[a].endValue,
							this._appliedBreaks[a + 1].endValue
						)),
						window.console &&
							window.console.log(
								"CanvasJS Error: Breaks " +
									a +
									" and " +
									(a + 1) +
									" are overlapping."
							),
						this._appliedBreaks.splice(a, 2),
						a--);
			}
		}
		function W(a, f, c, b, e, h) {
			W.base.constructor.call(this, "Break", f, c, b, h);
			this.id = e;
			this.chart = a;
			this.ctx = this.chart.ctx;
			this.scaleBreaks = h;
			this.optionsName = f;
			this.isOptionsInArray = !0;
			this.type = c.type ? this.type : h.type;
			this.fillOpacity = g(c.fillOpacity) ? h.fillOpacity : this.fillOpacity;
			this.lineThickness = g(c.lineThickness)
				? h.lineThickness
				: this.lineThickness;
			this.color = c.color ? this.color : h.color;
			this.lineColor = c.lineColor ? this.lineColor : h.lineColor;
			this.lineDashType = c.lineDashType ? this.lineDashType : h.lineDashType;
			!g(this.startValue) &&
				this.startValue.getTime &&
				(this.startValue = this.startValue.getTime());
			!g(this.endValue) &&
				this.endValue.getTime &&
				(this.endValue = this.endValue.getTime());
			"number" === typeof this.startValue &&
				"number" === typeof this.endValue &&
				this.endValue < this.startValue &&
				((a = this.startValue),
				(this.startValue = this.endValue),
				(this.endValue = a));
			this.spacing = "undefined" === typeof c.spacing ? h.spacing : c.spacing;
			"string" === typeof this.options.spacing
				? ((this.spacing = parseFloat(this.spacing)),
				  (this.spacing = isNaN(this.spacing)
						? 0
						: (10 < this.spacing ? 10 : this.spacing) + "%"))
				: "number" !== typeof this.options.spacing &&
				  (this.spacing = h.spacing);
			this.size = h.parent.logarithmic ? 1 : 0;
		}
		function Q(a, f, c, b, e) {
			Q.base.constructor.call(this, "StripLine", "stripLines", f, c, e);
			this.id = b;
			this.chart = a;
			this.ctx = this.chart.ctx;
			this.label = this.label;
			this.axis = e;
			this.optionsName = "stripLines";
			this.isOptionsInArray = !0;
			this._thicknessType = "pixel";
			null !== this.startValue &&
				null !== this.endValue &&
				((this.value = e.logarithmic
					? Math.sqrt(
							(this.startValue.getTime
								? this.startValue.getTime()
								: this.startValue) *
								(this.endValue.getTime
									? this.endValue.getTime()
									: this.endValue)
					  )
					: ((this.startValue.getTime
							? this.startValue.getTime()
							: this.startValue) +
							(this.endValue.getTime
								? this.endValue.getTime()
								: this.endValue)) /
					  2),
				(this._thicknessType = null));
		}
		function Y(a, f, c) {
			Y.base.constructor.call(this, "Crosshair", "crosshair", f, null, c);
			this.chart = a;
			this.ctx = this.chart.ctx;
			this.axis = c;
			this.optionsName = "crosshair";
			this._thicknessType = "pixel";
		}
		function qa(a, f) {
			qa.base.constructor.call(this, "ToolTip", "toolTip", f, null, a);
			this.chart = a;
			this.canvas = a.canvas;
			this.ctx = this.chart.ctx;
			this.currentDataPointIndex = this.currentSeriesIndex = -1;
			this._prevY = this._prevX = NaN;
			this.containerTransitionDuration = 0.1;
			this.mozContainerTransition = this.getContainerTransition(
				this.containerTransitionDuration
			);
			this.optionsName = "toolTip";
			this._initialize();
		}
		function ga(a) {
			this.chart = a;
			this.lastObjectId = 0;
			this.objectMap = [];
			this.rectangularRegionEventSubscriptions = [];
			this.previousDataPointEventObject = null;
			this.ghostCanvas = sa(this.chart.width, this.chart.height);
			this.ghostCtx = this.ghostCanvas.getContext("2d");
			this.mouseoveredObjectMaps = [];
		}
		function ma(a) {
			this.chart = a;
			this.ctx = this.chart.plotArea.ctx;
			this.animations = [];
			this.animationRequestId = null;
		}
		ia(v, da);
		v.prototype.destroy = function () {
			var a = this.allDOMEventHandlers;
			this._animator && this._animator.cancelAllAnimations();
			this._panTimerId && clearTimeout(this._panTimerId);
			for (var f = 0; f < a.length; f++) gb(a[f][0], a[f][1], a[f][2], a[f][3]);
			this.allDOMEventHandlers = [];
			for (
				this.removeAllEventListeners();
				this._canvasJSContainer && this._canvasJSContainer.hasChildNodes();

			)
				this._canvasJSContainer.removeChild(this._canvasJSContainer.lastChild);
			for (; this.container && this.container.hasChildNodes(); )
				this.container.removeChild(this.container.lastChild);
			for (; this._dropdownMenu && this._dropdownMenu.hasChildNodes(); )
				this._dropdownMenu.removeChild(this._dropdownMenu.lastChild);
			this.container = this._canvasJSContainer = null;
			this.toolTip.container = null;
			this.canvas && Ca(this.canvas);
			this.overlaidCanvas && Ca(this.overlaidCanvas);
			this._preRenderCanvas && Ca(this._preRenderCanvas);
			this._breaksCanvas && Ca(this._breaksCanvas);
			this._eventManager &&
				this._eventManager.ghostCanvas &&
				Ca(this._eventManager.ghostCanvas);
			this._toolBar =
				this._dropdownMenu =
				this._menuButton =
				this._resetButton =
				this._zoomButton =
					null;
		};
		v.prototype._updateOptions = function () {
			var a = this;
			this.updateOption("width");
			this.updateOption("height");
			this.updateOption("dataPointWidth");
			this.updateOption("dataPointMinWidth");
			this.updateOption("dataPointMaxWidth");
			this.updateOption("interactivityEnabled");
			this.updateOption("theme");
			this.updateOption("colorSet") &&
				(this.selectedColorSet =
					"undefined" !== typeof xa[this.colorSet]
						? xa[this.colorSet]
						: xa.colorSet1);
			this.updateOption("backgroundColor");
			this.backgroundColor || (this.backgroundColor = "rgba(0,0,0,0)");
			this.updateOption("culture");
			this._cultureInfo = new Na(this.options.culture);
			this.updateOption("animationEnabled");
			this.animationEnabled = this.animationEnabled && t;
			this.updateOption("animationDuration");
			this.updateOption("rangeChanging");
			this.updateOption("rangeChanged");
			this.updateOption("exportEnabled");
			this.updateOption("exportFileName");
			this.updateOption("zoomType");
			this.toolbar = new Wa(this, this.options.toolbar);
			if (this.options.zoomEnabled || this.panEnabled) {
				if (this._zoomButton)
					N(this._zoomButton, {
						borderRight:
							this.toolbar.buttonBorderThickness +
							"px solid " +
							this.toolbar.buttonBorderColor,
						backgroundColor: a.toolbar.itemBackgroundColor,
						color: a.toolbar.fontColor,
					}),
						(this._zoomButton.title = this._cultureInfo.zoomText);
				else {
					var f = !1;
					ya((this._zoomButton = document.createElement("button")));
					Ba(this, this._zoomButton, "pan");
					this._zoomButton.title = this._cultureInfo.panText;
					this._toolBar.appendChild(this._zoomButton);
					this._zoomButton.style.borderRight =
						this.toolbar.buttonBorderThickness +
						"px solid " +
						this.toolbar.buttonBorderColor;
					y(
						this._zoomButton,
						"touchstart",
						function (a) {
							f = !0;
						},
						this.allDOMEventHandlers
					);
					y(
						this._zoomButton,
						"click",
						function () {
							a.zoomEnabled
								? ((a.zoomEnabled = !1),
								  (a.panEnabled = !0),
								  Ba(a, a._zoomButton, "zoom"))
								: ((a.zoomEnabled = !0),
								  (a.panEnabled = !1),
								  Ba(a, a._zoomButton, "pan"));
							a.render();
						},
						this.allDOMEventHandlers
					);
					y(
						this._zoomButton,
						"mousemove",
						function () {
							f
								? (f = !1)
								: (N(a._zoomButton, {
										backgroundColor: a.toolbar.itemBackgroundColorOnHover,
										color: a.toolbar.fontColorOnHover,
										transition: "0.4s",
										WebkitTransition: "0.4s",
								  }),
								  0 >= navigator.userAgent.search("MSIE") &&
										N(a._zoomButton.childNodes[0], {
											WebkitFilter: "invert(100%)",
											filter: "invert(100%)",
										}));
						},
						this.allDOMEventHandlers
					);
					y(
						this._zoomButton,
						"mouseout",
						function () {
							f ||
								(N(a._zoomButton, {
									backgroundColor: a.toolbar.itemBackgroundColor,
									color: a.toolbar.fontColor,
									transition: "0.4s",
									WebkitTransition: "0.4s",
								}),
								0 >= navigator.userAgent.search("MSIE") &&
									N(a._zoomButton.childNodes[0], {
										WebkitFilter: "invert(0%)",
										filter: "invert(0%)",
									}));
						},
						this.allDOMEventHandlers
					);
				}
				this._resetButton
					? (N(this._resetButton, {
							borderRight:
								this.toolbar.buttonBorderThickness +
								"px solid " +
								this.toolbar.buttonBorderColor,
							backgroundColor: a.toolbar.itemBackgroundColor,
							color: a.toolbar.fontColor,
					  }),
					  (this._resetButton.title = this._cultureInfo.resetText))
					: ((f = !1),
					  ya((this._resetButton = document.createElement("button"))),
					  Ba(this, this._resetButton, "reset"),
					  (this._resetButton.style.borderRight =
							(this.exportEnabled ? this.toolbar.buttonBorderThickness : 0) +
							"px solid " +
							this.toolbar.buttonBorderColor),
					  this._toolBar.appendChild(this._resetButton),
					  y(
							this._resetButton,
							"touchstart",
							function (a) {
								f = !0;
							},
							this.allDOMEventHandlers
					  ),
					  y(
							this._resetButton,
							"click",
							function () {
								a.toolTip.hide();
								a.toolTip &&
									a.toolTip.enabled &&
									a.toolTip.dispatchEvent(
										"hidden",
										{ chart: a, toolTip: a.toolTip },
										a.toolTip
									);
								a.zoomEnabled || a.panEnabled
									? ((a.zoomEnabled = !0),
									  (a.panEnabled = !1),
									  Ba(a, a._zoomButton, "pan"),
									  (a._defaultCursor = "default"),
									  (a.overlaidCanvas.style.cursor = a._defaultCursor))
									: ((a.zoomEnabled = !1), (a.panEnabled = !1));
								if (a.sessionVariables.axisX)
									for (var b = 0; b < a.sessionVariables.axisX.length; b++)
										(a.sessionVariables.axisX[b].newViewportMinimum = null),
											(a.sessionVariables.axisX[b].newViewportMaximum = null);
								if (a.sessionVariables.axisX2)
									for (b = 0; b < a.sessionVariables.axisX2.length; b++)
										(a.sessionVariables.axisX2[b].newViewportMinimum = null),
											(a.sessionVariables.axisX2[b].newViewportMaximum = null);
								if (a.sessionVariables.axisY)
									for (b = 0; b < a.sessionVariables.axisY.length; b++)
										(a.sessionVariables.axisY[b].newViewportMinimum = null),
											(a.sessionVariables.axisY[b].newViewportMaximum = null);
								if (a.sessionVariables.axisY2)
									for (b = 0; b < a.sessionVariables.axisY2.length; b++)
										(a.sessionVariables.axisY2[b].newViewportMinimum = null),
											(a.sessionVariables.axisY2[b].newViewportMaximum = null);
								a.resetOverlayedCanvas();
								0 >= navigator.userAgent.search("MSIE") &&
									N(a._resetButton.childNodes[0], {
										WebkitFilter: "invert(0%)",
										filter: "invert(0%)",
									});
								ya(a._zoomButton, a._resetButton);
								a.stockChart &&
									(a.stockChart._rangeEventParameter = {
										stockChart: a.stockChart,
										source: "chart",
										index: a.stockChart.charts.indexOf(a),
										minimum: null,
										maximum: null,
									});
								a._dispatchRangeEvent("rangeChanging", "reset");
								a.stockChart &&
									((a.stockChart._rangeEventParameter.type = "rangeChanging"),
									a.stockChart.dispatchEvent(
										"rangeChanging",
										a.stockChart._rangeEventParameter,
										a.stockChart
									));
								a.render();
								a.syncCharts && a.syncCharts(null, null);
								a._dispatchRangeEvent("rangeChanged", "reset");
								a.stockChart &&
									((a.stockChart._rangeEventParameter.type = "rangeChanged"),
									a.stockChart.dispatchEvent(
										"rangeChanged",
										a.stockChart._rangeEventParameter,
										a.stockChart
									));
							},
							this.allDOMEventHandlers
					  ),
					  y(
							this._resetButton,
							"mousemove",
							function () {
								f ||
									(N(a._resetButton, {
										backgroundColor: a.toolbar.itemBackgroundColorOnHover,
										color: a.toolbar.fontColorOnHover,
										transition: "0.4s",
										WebkitTransition: "0.4s",
									}),
									0 >= navigator.userAgent.search("MSIE") &&
										N(a._resetButton.childNodes[0], {
											WebkitFilter: "invert(100%)",
											filter: "invert(100%)",
										}));
							},
							this.allDOMEventHandlers
					  ),
					  y(
							this._resetButton,
							"mouseout",
							function () {
								f ||
									(N(a._resetButton, {
										backgroundColor: a.toolbar.itemBackgroundColor,
										color: a.toolbar.fontColor,
										transition: "0.4s",
										WebkitTransition: "0.4s",
									}),
									0 >= navigator.userAgent.search("MSIE") &&
										N(a._resetButton.childNodes[0], {
											WebkitFilter: "invert(0%)",
											filter: "invert(0%)",
										}));
							},
							this.allDOMEventHandlers
					  ),
					  (this.overlaidCanvas.style.cursor = a._defaultCursor));
				this.zoomEnabled ||
					this.panEnabled ||
					(this._zoomButton
						? (a._zoomButton.getAttribute("state") === a._cultureInfo.zoomText
								? ((this.panEnabled = !0), (this.zoomEnabled = !1))
								: ((this.zoomEnabled = !0), (this.panEnabled = !1)),
						  Sa(a._zoomButton, a._resetButton))
						: ((this.zoomEnabled = !0), (this.panEnabled = !1)));
			} else this.panEnabled = this.zoomEnabled = !1;
			ib(this);
			"none" !== this._toolBar.style.display &&
				this._zoomButton &&
				(this.panEnabled
					? Ba(a, a._zoomButton, "zoom")
					: Ba(a, a._zoomButton, "pan"),
				a._resetButton.getAttribute("state") !== a._cultureInfo.resetText &&
					Ba(a, a._resetButton, "reset"));
			this.options.toolTip &&
				this.toolTip.options !== this.options.toolTip &&
				(this.toolTip.options = this.options.toolTip);
			for (var c in this.toolTip.options)
				this.toolTip.options.hasOwnProperty(c) && this.toolTip.updateOption(c);
		};
		v.prototype._updateSize = function () {
			return lb.call(this, [
				this.canvas,
				this.overlaidCanvas,
				this._eventManager.ghostCanvas,
			]);
		};
		v.prototype._initialize = function () {
			this.isNavigator =
				g(this.parent) ||
				g(this.parent._defaultsKey) ||
				"Navigator" !== this.parent._defaultsKey
					? !1
					: !0;
			this._animator
				? this._animator.cancelAllAnimations()
				: (this._animator = new ma(this));
			this.removeAllEventListeners();
			this.disableToolTip = !1;
			this._axes = [];
			this.funnelPyramidClickHandler = this.pieDoughnutClickHandler = null;
			this._updateOptions();
			this.animatedRender =
				t && this.animationEnabled && 0 === this.renderCount;
			this._updateSize();
			this.clearCanvas();
			this.ctx.beginPath();
			this.axisX = [];
			this.axisX2 = [];
			this.axisY = [];
			this.axisY2 = [];
			this._indexLabels = [];
			this._dataInRenderedOrder = [];
			this._events = [];
			this._eventManager && this._eventManager.reset();
			this.plotInfo = { axisPlacement: null, plotTypes: [] };
			this.layoutManager = new Ka(
				0,
				0,
				this.width,
				this.height,
				this.isNavigator ? 0 : 2
			);
			this.plotArea.layoutManager && this.plotArea.layoutManager.reset();
			this.data = [];
			this.title = null;
			this.subtitles = [];
			var a = 0,
				f = null;
			if (this.options.data) {
				for (var c = 0; c < this.options.data.length; c++)
					if (
						(a++,
						!this.options.data[c].type ||
							0 <= v._supportedChartTypes.indexOf(this.options.data[c].type))
					) {
						var b = new P(
							this,
							this.options.data[c],
							a - 1,
							++this._eventManager.lastObjectId
						);
						"error" === b.type &&
							((b.linkedDataSeriesIndex = g(
								this.options.data[c].linkedDataSeriesIndex
							)
								? c - 1
								: this.options.data[c].linkedDataSeriesIndex),
							0 > b.linkedDataSeriesIndex ||
								b.linkedDataSeriesIndex >= this.options.data.length ||
								"number" !== typeof b.linkedDataSeriesIndex ||
								"error" === this.options.data[b.linkedDataSeriesIndex].type) &&
							(b.linkedDataSeriesIndex = null);
						null === b.name && (b.name = "DataSeries " + a);
						null === b.color
							? 1 < this.options.data.length
								? ((b._colorSet = [
										this.selectedColorSet[
											b.index % this.selectedColorSet.length
										],
								  ]),
								  (b.color =
										this.selectedColorSet[
											b.index % this.selectedColorSet.length
										]))
								: (b._colorSet =
										"line" === b.type ||
										"stepLine" === b.type ||
										"spline" === b.type ||
										"area" === b.type ||
										"stepArea" === b.type ||
										"splineArea" === b.type ||
										"stackedArea" === b.type ||
										"stackedArea100" === b.type ||
										"rangeArea" === b.type ||
										"rangeSplineArea" === b.type ||
										"candlestick" === b.type ||
										"ohlc" === b.type ||
										"waterfall" === b.type ||
										"boxAndWhisker" === b.type
											? [this.selectedColorSet[0]]
											: this.selectedColorSet)
							: (b._colorSet = [b.color]);
						null === b.markerSize &&
							((("line" === b.type ||
								"stepLine" === b.type ||
								"spline" === b.type ||
								0 <= b.type.toLowerCase().indexOf("area")) &&
								b.dataPoints &&
								b.dataPoints.length < this.width / 16) ||
								"scatter" === b.type) &&
							(b.markerSize = 8);
						("bubble" !== b.type && "scatter" !== b.type) ||
							!b.dataPoints ||
							(b.dataPoints.some
								? b.dataPoints.some(function (a) {
										return a.x;
								  }) && b.dataPoints.sort(l)
								: b.dataPoints.sort(l));
						this.data.push(b);
						var e = b.axisPlacement,
							f = f || e,
							h;
						"normal" === e
							? "xySwapped" === this.plotInfo.axisPlacement
								? (h = 'You cannot combine "' + b.type + '" with bar chart')
								: "none" === this.plotInfo.axisPlacement
								? (h = 'You cannot combine "' + b.type + '" with pie chart')
								: null === this.plotInfo.axisPlacement &&
								  (this.plotInfo.axisPlacement = "normal")
							: "xySwapped" === e
							? "normal" === this.plotInfo.axisPlacement
								? (h =
										'You cannot combine "' +
										b.type +
										'" with line, area, column or pie chart')
								: "none" === this.plotInfo.axisPlacement
								? (h = 'You cannot combine "' + b.type + '" with pie chart')
								: null === this.plotInfo.axisPlacement &&
								  (this.plotInfo.axisPlacement = "xySwapped")
							: "none" === e
							? "normal" === this.plotInfo.axisPlacement
								? (h =
										'You cannot combine "' +
										b.type +
										'" with line, area, column or bar chart')
								: "xySwapped" === this.plotInfo.axisPlacement
								? (h = 'You cannot combine "' + b.type + '" with bar chart')
								: null === this.plotInfo.axisPlacement &&
								  (this.plotInfo.axisPlacement = "none")
							: null === e &&
							  "none" === this.plotInfo.axisPlacement &&
							  (h = 'You cannot combine "' + b.type + '" with pie chart');
						if (h && window.console) {
							window.console.log(h);
							return;
						}
					}
				for (c = 0; c < this.data.length; c++) {
					if ("none" == f && "error" === this.data[c].type && window.console) {
						window.console.log(
							'You cannot combine "' + b.type + '" with error chart'
						);
						return;
					}
					"error" === this.data[c].type &&
						((this.data[c].axisPlacement = this.plotInfo.axisPlacement =
							f || "normal"),
						(this.data[c]._linkedSeries =
							null === this.data[c].linkedDataSeriesIndex
								? null
								: this.data[this.data[c].linkedDataSeriesIndex]));
				}
			}
			this._objectsInitialized = !0;
			this._plotAreaElements = [];
		};
		v._supportedChartTypes = Da(
			"line stepLine spline column area stepArea splineArea bar bubble scatter stackedColumn stackedColumn100 stackedBar stackedBar100 stackedArea stackedArea100 candlestick ohlc boxAndWhisker rangeColumn error rangeBar rangeArea rangeSplineArea pie doughnut funnel pyramid waterfall".split(
				" "
			)
		);
		v.prototype.setLayout = function () {
			for (var a = this._plotAreaElements, f = 0; f < this.data.length; f++)
				if (
					"normal" === this.plotInfo.axisPlacement ||
					"xySwapped" === this.plotInfo.axisPlacement
				) {
					if (!this.data[f].axisYType || "primary" === this.data[f].axisYType)
						if (this.options.axisY && 0 < this.options.axisY.length) {
							if (!this.axisY.length)
								for (var c = 0; c < this.options.axisY.length; c++)
									"normal" === this.plotInfo.axisPlacement
										? this._axes.push(
												(this.axisY[c] = new D(
													this,
													"axisY",
													this.options.axisY[c],
													c,
													"axisY",
													"left"
												))
										  )
										: "xySwapped" === this.plotInfo.axisPlacement &&
										  this._axes.push(
												(this.axisY[c] = new D(
													this,
													"axisY",
													this.options.axisY[c],
													c,
													"axisY",
													"bottom"
												))
										  );
							this.data[f].axisY =
								this.axisY[
									0 <= this.data[f].axisYIndex &&
									this.data[f].axisYIndex < this.axisY.length
										? this.data[f].axisYIndex
										: 0
								];
							this.axisY[
								0 <= this.data[f].axisYIndex &&
								this.data[f].axisYIndex < this.axisY.length
									? this.data[f].axisYIndex
									: 0
							].dataSeries.push(this.data[f]);
						} else
							this.axisY.length ||
								("normal" === this.plotInfo.axisPlacement
									? this._axes.push(
											(this.axisY[0] = new D(
												this,
												"axisY",
												this.options.axisY,
												0,
												"axisY",
												"left"
											))
									  )
									: "xySwapped" === this.plotInfo.axisPlacement &&
									  this._axes.push(
											(this.axisY[0] = new D(
												this,
												"axisY",
												this.options.axisY,
												0,
												"axisY",
												"bottom"
											))
									  )),
								(this.data[f].axisY = this.axisY[0]),
								this.axisY[0].dataSeries.push(this.data[f]);
					if ("secondary" === this.data[f].axisYType)
						if (this.options.axisY2 && 0 < this.options.axisY2.length) {
							if (!this.axisY2.length)
								for (c = 0; c < this.options.axisY2.length; c++)
									"normal" === this.plotInfo.axisPlacement
										? this._axes.push(
												(this.axisY2[c] = new D(
													this,
													"axisY2",
													this.options.axisY2[c],
													c,
													"axisY",
													"right"
												))
										  )
										: "xySwapped" === this.plotInfo.axisPlacement &&
										  this._axes.push(
												(this.axisY2[c] = new D(
													this,
													"axisY2",
													this.options.axisY2[c],
													c,
													"axisY",
													"top"
												))
										  );
							this.data[f].axisY =
								this.axisY2[
									0 <= this.data[f].axisYIndex &&
									this.data[f].axisYIndex < this.axisY2.length
										? this.data[f].axisYIndex
										: 0
								];
							this.axisY2[
								0 <= this.data[f].axisYIndex &&
								this.data[f].axisYIndex < this.axisY2.length
									? this.data[f].axisYIndex
									: 0
							].dataSeries.push(this.data[f]);
						} else
							this.axisY2.length ||
								("normal" === this.plotInfo.axisPlacement
									? this._axes.push(
											(this.axisY2[0] = new D(
												this,
												"axisY2",
												this.options.axisY2,
												0,
												"axisY",
												"right"
											))
									  )
									: "xySwapped" === this.plotInfo.axisPlacement &&
									  this._axes.push(
											(this.axisY2[0] = new D(
												this,
												"axisY2",
												this.options.axisY2,
												0,
												"axisY",
												"top"
											))
									  )),
								(this.data[f].axisY = this.axisY2[0]),
								this.axisY2[0].dataSeries.push(this.data[f]);
					if (!this.data[f].axisXType || "primary" === this.data[f].axisXType)
						if (this.options.axisX && 0 < this.options.axisX.length) {
							if (!this.axisX.length)
								for (c = 0; c < this.options.axisX.length; c++)
									"normal" === this.plotInfo.axisPlacement
										? this._axes.push(
												(this.axisX[c] = new D(
													this,
													"axisX",
													this.options.axisX[c],
													c,
													"axisX",
													"bottom"
												))
										  )
										: "xySwapped" === this.plotInfo.axisPlacement &&
										  this._axes.push(
												(this.axisX[c] = new D(
													this,
													"axisX",
													this.options.axisX[c],
													c,
													"axisX",
													"left"
												))
										  );
							this.data[f].axisX =
								this.axisX[
									0 <= this.data[f].axisXIndex &&
									this.data[f].axisXIndex < this.axisX.length
										? this.data[f].axisXIndex
										: 0
								];
							this.axisX[
								0 <= this.data[f].axisXIndex &&
								this.data[f].axisXIndex < this.axisX.length
									? this.data[f].axisXIndex
									: 0
							].dataSeries.push(this.data[f]);
						} else
							this.axisX.length ||
								("normal" === this.plotInfo.axisPlacement
									? this._axes.push(
											(this.axisX[0] = new D(
												this,
												"axisX",
												this.options.axisX,
												0,
												"axisX",
												"bottom"
											))
									  )
									: "xySwapped" === this.plotInfo.axisPlacement &&
									  this._axes.push(
											(this.axisX[0] = new D(
												this,
												"axisX",
												this.options.axisX,
												0,
												"axisX",
												"left"
											))
									  )),
								(this.data[f].axisX = this.axisX[0]),
								this.axisX[0].dataSeries.push(this.data[f]);
					if ("secondary" === this.data[f].axisXType)
						if (this.options.axisX2 && 0 < this.options.axisX2.length) {
							if (!this.axisX2.length)
								for (c = 0; c < this.options.axisX2.length; c++)
									"normal" === this.plotInfo.axisPlacement
										? this._axes.push(
												(this.axisX2[c] = new D(
													this,
													"axisX2",
													this.options.axisX2[c],
													c,
													"axisX",
													"top"
												))
										  )
										: "xySwapped" === this.plotInfo.axisPlacement &&
										  this._axes.push(
												(this.axisX2[c] = new D(
													this,
													"axisX2",
													this.options.axisX2[c],
													c,
													"axisX",
													"right"
												))
										  );
							this.data[f].axisX =
								this.axisX2[
									0 <= this.data[f].axisXIndex &&
									this.data[f].axisXIndex < this.axisX2.length
										? this.data[f].axisXIndex
										: 0
								];
							this.axisX2[
								0 <= this.data[f].axisXIndex &&
								this.data[f].axisXIndex < this.axisX2.length
									? this.data[f].axisXIndex
									: 0
							].dataSeries.push(this.data[f]);
						} else
							this.axisX2.length ||
								("normal" === this.plotInfo.axisPlacement
									? this._axes.push(
											(this.axisX2[0] = new D(
												this,
												"axisX2",
												this.options.axisX2,
												0,
												"axisX",
												"top"
											))
									  )
									: "xySwapped" === this.plotInfo.axisPlacement &&
									  this._axes.push(
											(this.axisX2[0] = new D(
												this,
												"axisX2",
												this.options.axisX2,
												0,
												"axisX",
												"right"
											))
									  )),
								(this.data[f].axisX = this.axisX2[0]),
								this.axisX2[0].dataSeries.push(this.data[f]);
				}
			if (this.axisY) {
				for (c = 1; c < this.axisY.length; c++)
					"undefined" === typeof this.axisY[c].options.gridThickness &&
						(this.axisY[c].gridThickness = 0);
				for (c = 0; c < this.axisY.length - 1; c++)
					"undefined" === typeof this.axisY[c].options.margin &&
						(this.axisY[c].margin = 10);
			}
			if (this.axisY2) {
				for (c = 1; c < this.axisY2.length; c++)
					"undefined" === typeof this.axisY2[c].options.gridThickness &&
						(this.axisY2[c].gridThickness = 0);
				for (c = 0; c < this.axisY2.length - 1; c++)
					"undefined" === typeof this.axisY2[c].options.margin &&
						(this.axisY2[c].margin = 10);
			}
			this.axisY &&
				0 < this.axisY.length &&
				this.axisY2 &&
				0 < this.axisY2.length &&
				(0 < this.axisY[0].gridThickness &&
				"undefined" === typeof this.axisY2[0].options.gridThickness
					? (this.axisY2[0].gridThickness = 0)
					: 0 < this.axisY2[0].gridThickness &&
					  "undefined" === typeof this.axisY[0].options.gridThickness &&
					  (this.axisY[0].gridThickness = 0));
			if (this.axisX)
				for (c = 0; c < this.axisX.length; c++)
					"undefined" === typeof this.axisX[c].options.gridThickness &&
						(this.axisX[c].gridThickness = 0);
			if (this.axisX2)
				for (c = 0; c < this.axisX2.length; c++)
					"undefined" === typeof this.axisX2[c].options.gridThickness &&
						(this.axisX2[c].gridThickness = 0);
			this.axisX &&
				0 < this.axisX.length &&
				this.axisX2 &&
				0 < this.axisX2.length &&
				(0 < this.axisX[0].gridThickness &&
				"undefined" === typeof this.axisX2[0].options.gridThickness
					? (this.axisX2[0].gridThickness = 0)
					: 0 < this.axisX2[0].gridThickness &&
					  "undefined" === typeof this.axisX[0].options.gridThickness &&
					  (this.axisX[0].gridThickness = 0));
			c = !1;
			if (
				0 < this._axes.length &&
				this.options.zoomEnabled &&
				(this.zoomEnabled || this.panEnabled)
			)
				for (f = 0; f < this._axes.length; f++)
					if (
						!g(this._axes[f].viewportMinimum) ||
						!g(this._axes[f].viewportMaximum)
					) {
						c = !0;
						break;
					}
			c
				? (Sa(this._zoomButton, this._resetButton),
				  (this._toolBar.style.border =
						this.toolbar.buttonBorderThickness +
						"px solid " +
						this.toolbar.buttonBorderColor),
				  (this._zoomButton.style.borderRight =
						this.toolbar.buttonBorderThickness +
						"px solid " +
						this.toolbar.buttonBorderColor),
				  (this._resetButton.style.borderRight =
						(this.exportEnabled ? this.toolbar.buttonBorderThickness : 0) +
						"px solid " +
						this.toolbar.buttonBorderColor))
				: (ya(this._zoomButton, this._resetButton),
				  (this._toolBar.style.border =
						this.toolbar.buttonBorderThickness + "px solid transparent"),
				  this.options.zoomEnabled &&
						((this.zoomEnabled = !0), (this.panEnabled = !1)));
			qb(this);
			this._processData();
			this.options.title &&
				((this.title = new Ha(this, this.options.title)),
				this.title.dockInsidePlotArea
					? a.push(this.title)
					: this.title.setLayout());
			if (this.options.subtitles)
				for (f = 0; f < this.options.subtitles.length; f++)
					(c = new Ma(this, this.options.subtitles[f], f)),
						this.subtitles.push(c),
						c.dockInsidePlotArea ? a.push(c) : c.setLayout();
			this.legend = new K(this, this.options.legend);
			for (f = 0; f < this.data.length; f++)
				(this.data[f].showInLegend ||
					"pie" === this.data[f].type ||
					"doughnut" === this.data[f].type ||
					"funnel" === this.data[f].type ||
					"pyramid" === this.data[f].type) &&
					this.legend.dataSeries.push(this.data[f]);
			this.legend.dockInsidePlotArea
				? a.push(this.legend)
				: this.legend.setLayout();
			for (f = 0; f < this._axes.length; f++)
				if (
					this._axes[f].scaleBreaks &&
					this._axes[f].scaleBreaks._appliedBreaks.length
				) {
					t
						? ((this._breaksCanvas = sa(this.width, this.height, !0)),
						  (this._breaksCanvasCtx = this._breaksCanvas.getContext("2d")))
						: ((this._breaksCanvas = this.canvas),
						  (this._breaksCanvasCtx = this.ctx));
					break;
				}
			this._preRenderCanvas = sa(this.width, this.height);
			this._preRenderCtx = this._preRenderCanvas.getContext("2d");
			("normal" !== this.plotInfo.axisPlacement &&
				"xySwapped" !== this.plotInfo.axisPlacement) ||
				D.setLayout(
					this.axisX,
					this.axisX2,
					this.axisY,
					this.axisY2,
					this.plotInfo.axisPlacement,
					this.layoutManager.getFreeSpace()
				);
		};
		v.prototype.renderElements = function () {
			var a = this._plotAreaElements;
			this.title && !this.title.dockInsidePlotArea && this.title.render();
			for (var f = 0; f < this.subtitles.length; f++)
				this.subtitles[f].dockInsidePlotArea || this.subtitles[f].render();
			this.legend.dockInsidePlotArea || this.legend.render();
			if (
				"normal" === this.plotInfo.axisPlacement ||
				"xySwapped" === this.plotInfo.axisPlacement
			)
				D.render(
					this.axisX,
					this.axisX2,
					this.axisY,
					this.axisY2,
					this.plotInfo.axisPlacement
				);
			else if ("none" === this.plotInfo.axisPlacement) this.preparePlotArea();
			else return;
			for (f = 0; f < a.length; f++) a[f].setLayout(), a[f].render();
			var c = [];
			if (this.animatedRender) {
				var b = sa(this.width, this.height);
				b.getContext("2d").drawImage(
					this.canvas,
					0,
					0,
					this.width,
					this.height
				);
			}
			sb(this);
			var a = this.ctx.miterLimit,
				e;
			this.ctx.miterLimit = 3;
			t &&
				this._breaksCanvas &&
				(this._preRenderCtx.drawImage(
					this.canvas,
					0,
					0,
					this.width,
					this.height
				),
				this._preRenderCtx.drawImage(
					this._breaksCanvas,
					0,
					0,
					this.width,
					this.height
				),
				(this._breaksCanvasCtx.globalCompositeOperation = "source-atop"),
				this._breaksCanvasCtx.drawImage(
					this._preRenderCanvas,
					0,
					0,
					this.width,
					this.height
				),
				this._preRenderCtx.clearRect(0, 0, this.width, this.height));
			for (f = 0; f < this.plotInfo.plotTypes.length; f++)
				for (
					var h = this.plotInfo.plotTypes[f], A = 0;
					A < h.plotUnits.length;
					A++
				) {
					var g = h.plotUnits[A],
						d = null;
					g.targetCanvas && Ca(g.targetCanvas);
					g.targetCanvas = null;
					this.animatedRender &&
						((g.targetCanvas = sa(this.width, this.height)),
						(g.targetCanvasCtx = g.targetCanvas.getContext("2d")),
						(e = g.targetCanvasCtx.miterLimit),
						(g.targetCanvasCtx.miterLimit = 3));
					"line" === g.type
						? (d = this.renderLine(g))
						: "stepLine" === g.type
						? (d = this.renderStepLine(g))
						: "spline" === g.type
						? (d = this.renderSpline(g))
						: "column" === g.type
						? (d = this.renderColumn(g))
						: "bar" === g.type
						? (d = this.renderBar(g))
						: "area" === g.type
						? (d = this.renderArea(g))
						: "stepArea" === g.type
						? (d = this.renderStepArea(g))
						: "splineArea" === g.type
						? (d = this.renderSplineArea(g))
						: "stackedColumn" === g.type
						? (d = this.renderStackedColumn(g))
						: "stackedColumn100" === g.type
						? (d = this.renderStackedColumn100(g))
						: "stackedBar" === g.type
						? (d = this.renderStackedBar(g))
						: "stackedBar100" === g.type
						? (d = this.renderStackedBar100(g))
						: "stackedArea" === g.type
						? (d = this.renderStackedArea(g))
						: "stackedArea100" === g.type
						? (d = this.renderStackedArea100(g))
						: "bubble" === g.type
						? (d = d = this.renderBubble(g))
						: "scatter" === g.type
						? (d = this.renderScatter(g))
						: "pie" === g.type
						? this.renderPie(g)
						: "doughnut" === g.type
						? this.renderPie(g)
						: "funnel" === g.type
						? (d = this.renderFunnel(g))
						: "pyramid" === g.type
						? (d = this.renderFunnel(g))
						: "candlestick" === g.type
						? (d = this.renderCandlestick(g))
						: "ohlc" === g.type
						? (d = this.renderCandlestick(g))
						: "rangeColumn" === g.type
						? (d = this.renderRangeColumn(g))
						: "error" === g.type
						? (d = this.renderError(g))
						: "rangeBar" === g.type
						? (d = this.renderRangeBar(g))
						: "rangeArea" === g.type
						? (d = this.renderRangeArea(g))
						: "rangeSplineArea" === g.type
						? (d = this.renderRangeSplineArea(g))
						: "waterfall" === g.type
						? (d = this.renderWaterfall(g))
						: "boxAndWhisker" === g.type && (d = this.renderBoxAndWhisker(g));
					for (var m = 0; m < g.dataSeriesIndexes.length; m++)
						this._dataInRenderedOrder.push(this.data[g.dataSeriesIndexes[m]]);
					this.animatedRender &&
						((g.targetCanvasCtx.miterLimit = e), d && c.push(d));
				}
			this.ctx.miterLimit = a;
			this.animatedRender &&
				this._breaksCanvasCtx &&
				c.push({
					source: this._breaksCanvasCtx,
					dest: this.plotArea.ctx,
					animationCallback: z.fadeInAnimation,
					easingFunction: z.easing.easeInQuad,
					animationBase: 0,
					startTimePercent: 0.7,
				});
			this.animatedRender &&
				0 < this._indexLabels.length &&
				((e = sa(this.width, this.height).getContext("2d")),
				c.push(this.renderIndexLabels(e)));
			var n = this;
			if (0 < c.length)
				(n.disableToolTip = !0),
					n._animator.animate(
						200,
						n.animationDuration,
						function (a) {
							n.ctx.clearRect(0, 0, n.width, n.height);
							n.ctx.drawImage(
								b,
								0,
								0,
								Math.floor(n.width * ka),
								Math.floor(n.height * ka),
								0,
								0,
								n.width,
								n.height
							);
							for (var f = 0; f < c.length; f++)
								(d = c[f]),
									1 > a && "undefined" !== typeof d.startTimePercent
										? a >= d.startTimePercent &&
										  d.animationCallback(
												d.easingFunction(
													a - d.startTimePercent,
													0,
													1,
													1 - d.startTimePercent
												),
												d
										  )
										: d.animationCallback(d.easingFunction(a, 0, 1, 1), d);
							n.dispatchEvent("dataAnimationIterationEnd", { chart: n });
						},
						function () {
							c = [];
							for (var a = 0; a < n.plotInfo.plotTypes.length; a++)
								for (
									var f = n.plotInfo.plotTypes[a], e = 0;
									e < f.plotUnits.length;
									e++
								) {
									var h = f.plotUnits[e];
									h.targetCanvas && Ca(h.targetCanvas);
									h.targetCanvas = null;
								}
							b = null;
							n.disableToolTip = !1;
							n.dispatchEvent("dataAnimationEnd", { chart: n });
						}
					);
			else {
				if (n._breaksCanvas)
					if (t)
						n.plotArea.ctx.drawImage(
							n._breaksCanvas,
							0,
							0,
							this.width,
							this.height
						);
					else for (m = 0; m < n._axes.length; m++) n._axes[m].createMask();
				0 < n._indexLabels.length && n.renderIndexLabels();
				n.dispatchEvent("dataAnimationIterationEnd", { chart: n });
				n.dispatchEvent("dataAnimationEnd", { chart: n });
			}
			this.attachPlotAreaEventHandlers();
			this.zoomEnabled ||
				this.panEnabled ||
				!this._zoomButton ||
				"none" === this._zoomButton.style.display ||
				ya(this._zoomButton, this._resetButton);
			this.toolTip._updateToolTip();
			this.renderCount++;
			La &&
				((n = this),
				setTimeout(function () {
					var a = document.getElementById("ghostCanvasCopy");
					a &&
						(Ta(a, n.width, n.height),
						a.getContext("2d").drawImage(n._eventManager.ghostCanvas, 0, 0));
				}, 2e3));
			this._breaksCanvas &&
				(delete this._breaksCanvas, delete this._breaksCanvasCtx);
			for (m = 0; m < this._axes.length; m++)
				this._axes[m].maskCanvas &&
					(delete this._axes[m].maskCanvas, delete this._axes[m].maskCtx);
		};
		v.prototype.render = function (a) {
			a && (this.options = a);
			this._initialize();
			this.setLayout();
			this.renderElements();
			this._preRenderCanvas && Ca(this._preRenderCanvas);
		};
		v.prototype.attachPlotAreaEventHandlers = function () {
			this.attachEvent({
				context: this,
				chart: this,
				mousedown: this._plotAreaMouseDown,
				mouseup: this._plotAreaMouseUp,
				mousemove: this._plotAreaMouseMove,
				cursor: this.panEnabled ? "move" : "default",
				capture: !0,
				bounds: this.plotArea,
			});
		};
		v.prototype.categoriseDataSeries = function () {
			for (var a = "", f = 0; f < this.data.length; f++)
				if (
					((a = this.data[f]),
					a.dataPoints &&
						0 !== a.dataPoints.length &&
						a.visible &&
						0 <= v._supportedChartTypes.indexOf(a.type))
				) {
					for (
						var c = null, b = !1, e = null, h = !1, A = 0;
						A < this.plotInfo.plotTypes.length;
						A++
					)
						if (this.plotInfo.plotTypes[A].type === a.type) {
							b = !0;
							c = this.plotInfo.plotTypes[A];
							break;
						}
					b ||
						((c = { type: a.type, totalDataSeries: 0, plotUnits: [] }),
						this.plotInfo.plotTypes.push(c));
					for (A = 0; A < c.plotUnits.length; A++)
						if (
							c.plotUnits[A].axisYType === a.axisYType &&
							c.plotUnits[A].axisXType === a.axisXType &&
							c.plotUnits[A].axisYIndex === a.axisYIndex &&
							c.plotUnits[A].axisXIndex === a.axisXIndex
						) {
							h = !0;
							e = c.plotUnits[A];
							break;
						}
					h ||
						((e = {
							type: a.type,
							previousDataSeriesCount: 0,
							index: c.plotUnits.length,
							plotType: c,
							axisXType: a.axisXType,
							axisYType: a.axisYType,
							axisYIndex: a.axisYIndex,
							axisXIndex: a.axisXIndex,
							axisY:
								"primary" === a.axisYType
									? this.axisY[
											0 <= a.axisYIndex && a.axisYIndex < this.axisY.length
												? a.axisYIndex
												: 0
									  ]
									: this.axisY2[
											0 <= a.axisYIndex && a.axisYIndex < this.axisY2.length
												? a.axisYIndex
												: 0
									  ],
							axisX:
								"primary" === a.axisXType
									? this.axisX[
											0 <= a.axisXIndex && a.axisXIndex < this.axisX.length
												? a.axisXIndex
												: 0
									  ]
									: this.axisX2[
											0 <= a.axisXIndex && a.axisXIndex < this.axisX2.length
												? a.axisXIndex
												: 0
									  ],
							dataSeriesIndexes: [],
							yTotals: [],
							yAbsTotals: [],
						}),
						c.plotUnits.push(e));
					c.totalDataSeries++;
					e.dataSeriesIndexes.push(f);
					a.plotUnit = e;
				}
			for (f = 0; f < this.plotInfo.plotTypes.length; f++)
				for (
					c = this.plotInfo.plotTypes[f], A = a = 0;
					A < c.plotUnits.length;
					A++
				)
					(c.plotUnits[A].previousDataSeriesCount = a),
						(a += c.plotUnits[A].dataSeriesIndexes.length);
		};
		v.prototype.assignIdToDataPoints = function () {
			for (var a = 0; a < this.data.length; a++) {
				var f = this.data[a];
				if (f.dataPoints)
					for (var c = f.dataPoints.length, b = 0; b < c; b++)
						f.dataPointIds[b] = ++this._eventManager.lastObjectId;
			}
		};
		v.prototype._processData = function () {
			this.assignIdToDataPoints();
			this.categoriseDataSeries();
			for (var a = 0; a < this.plotInfo.plotTypes.length; a++)
				for (
					var f = this.plotInfo.plotTypes[a], c = 0;
					c < f.plotUnits.length;
					c++
				) {
					var b = f.plotUnits[c];
					"line" === b.type ||
					"stepLine" === b.type ||
					"spline" === b.type ||
					"column" === b.type ||
					"area" === b.type ||
					"stepArea" === b.type ||
					"splineArea" === b.type ||
					"bar" === b.type ||
					"bubble" === b.type ||
					"scatter" === b.type
						? this._processMultiseriesPlotUnit(b)
						: "stackedColumn" === b.type ||
						  "stackedBar" === b.type ||
						  "stackedArea" === b.type
						? this._processStackedPlotUnit(b)
						: "stackedColumn100" === b.type ||
						  "stackedBar100" === b.type ||
						  "stackedArea100" === b.type
						? this._processStacked100PlotUnit(b)
						: "candlestick" === b.type ||
						  "ohlc" === b.type ||
						  "rangeColumn" === b.type ||
						  "rangeBar" === b.type ||
						  "rangeArea" === b.type ||
						  "rangeSplineArea" === b.type ||
						  "error" === b.type ||
						  "boxAndWhisker" === b.type
						? this._processMultiYPlotUnit(b)
						: "waterfall" === b.type && this._processSpecificPlotUnit(b);
				}
			this.calculateAutoBreaks();
		};
		v.prototype._processMultiseriesPlotUnit = function (a) {
			if (a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length))
				for (
					var f = a.axisY.dataInfo, c = a.axisX.dataInfo, b, e, h = !1, A = 0;
					A < a.dataSeriesIndexes.length;
					A++
				) {
					var g = this.data[a.dataSeriesIndexes[A]],
						d = 0,
						m = !1,
						n = !1,
						p;
					if ("normal" === g.axisPlacement || "xySwapped" === g.axisPlacement)
						var r = a.axisX.sessionVariables.newViewportMinimum
								? a.axisX.sessionVariables.newViewportMinimum
								: this.options.axisX && this.options.axisX.viewportMinimum
								? this.options.axisX.viewportMinimum
								: this.options.axisX && this.options.axisX.minimum
								? this.options.axisX.minimum
								: a.axisX.logarithmic
								? 0
								: -Infinity,
							k = a.axisX.sessionVariables.newViewportMaximum
								? a.axisX.sessionVariables.newViewportMaximum
								: this.options.axisX && this.options.axisX.viewportMaximum
								? this.options.axisX.viewportMaximum
								: this.options.axisX && this.options.axisX.maximum
								? this.options.axisX.maximum
								: Infinity;
					if (
						(g.dataPoints[d].x && g.dataPoints[d].x.getTime) ||
						"dateTime" === g.xValueType
					)
						h = !0;
					for (d = 0; d < g.dataPoints.length; d++) {
						"undefined" === typeof g.dataPoints[d].x &&
							(g.dataPoints[d].x = d + (a.axisX.logarithmic ? 1 : 0));
						g.dataPoints[d].x.getTime
							? ((h = !0), (b = g.dataPoints[d].x.getTime()))
							: (b = g.dataPoints[d].x);
						e = g.dataPoints[d].y;
						b < c.min && (c.min = b);
						b > c.max && (c.max = b);
						e < f.min && "number" === typeof e && (f.min = e);
						e > f.max && "number" === typeof e && (f.max = e);
						if (0 < d) {
							if (a.axisX.logarithmic) {
								var x = b / g.dataPoints[d - 1].x;
								1 > x && (x = 1 / x);
								c.minDiff > x && 1 !== x && (c.minDiff = x);
							} else
								(x = b - g.dataPoints[d - 1].x),
									0 > x && (x *= -1),
									c.minDiff > x && 0 !== x && (c.minDiff = x);
							null !== e &&
								null !== g.dataPoints[d - 1].y &&
								(a.axisY.logarithmic
									? ((x = e / g.dataPoints[d - 1].y),
									  1 > x && (x = 1 / x),
									  f.minDiff > x && 1 !== x && (f.minDiff = x))
									: ((x = e - g.dataPoints[d - 1].y),
									  0 > x && (x *= -1),
									  f.minDiff > x && 0 !== x && (f.minDiff = x)));
						}
						if (b < r && !m) null !== e && (p = b);
						else {
							if (!m && ((m = !0), 0 < d)) {
								d -= 2;
								continue;
							}
							if (b > k && !n) n = !0;
							else if (b > k && n) continue;
							g.dataPoints[d].label &&
								(a.axisX.labels[b] = g.dataPoints[d].label);
							b < c.viewPortMin && (c.viewPortMin = b);
							b > c.viewPortMax && (c.viewPortMax = b);
							null === e
								? c.viewPortMin === b && p < b && (c.viewPortMin = p)
								: (e < f.viewPortMin &&
										"number" === typeof e &&
										(f.viewPortMin = e),
								  e > f.viewPortMax &&
										"number" === typeof e &&
										(f.viewPortMax = e));
						}
					}
					g.axisX.valueType = g.xValueType = h ? "dateTime" : "number";
				}
		};
		v.prototype._processStackedPlotUnit = function (a) {
			if (a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length)) {
				for (
					var f = a.axisY.dataInfo,
						c = a.axisX.dataInfo,
						b,
						e,
						h = !1,
						A = [],
						d = [],
						M = Infinity,
						m = -Infinity,
						n = 0;
					n < a.dataSeriesIndexes.length;
					n++
				) {
					var p = this.data[a.dataSeriesIndexes[n]],
						r = 0,
						k = !1,
						x = !1,
						z;
					if ("normal" === p.axisPlacement || "xySwapped" === p.axisPlacement)
						var t = a.axisX.sessionVariables.newViewportMinimum
								? a.axisX.sessionVariables.newViewportMinimum
								: this.options.axisX && this.options.axisX.viewportMinimum
								? this.options.axisX.viewportMinimum
								: this.options.axisX && this.options.axisX.minimum
								? this.options.axisX.minimum
								: -Infinity,
							u = a.axisX.sessionVariables.newViewportMaximum
								? a.axisX.sessionVariables.newViewportMaximum
								: this.options.axisX && this.options.axisX.viewportMaximum
								? this.options.axisX.viewportMaximum
								: this.options.axisX && this.options.axisX.maximum
								? this.options.axisX.maximum
								: Infinity;
					if (
						(p.dataPoints[r].x && p.dataPoints[r].x.getTime) ||
						"dateTime" === p.xValueType
					)
						h = !0;
					for (r = 0; r < p.dataPoints.length; r++) {
						"undefined" === typeof p.dataPoints[r].x &&
							(p.dataPoints[r].x = r + (a.axisX.logarithmic ? 1 : 0));
						p.dataPoints[r].x.getTime
							? ((h = !0), (b = p.dataPoints[r].x.getTime()))
							: (b = p.dataPoints[r].x);
						e = g(p.dataPoints[r].y) ? 0 : p.dataPoints[r].y;
						b < c.min && (c.min = b);
						b > c.max && (c.max = b);
						if (0 < r) {
							if (a.axisX.logarithmic) {
								var B = b / p.dataPoints[r - 1].x;
								1 > B && (B = 1 / B);
								c.minDiff > B && 1 !== B && (c.minDiff = B);
							} else
								(B = b - p.dataPoints[r - 1].x),
									0 > B && (B *= -1),
									c.minDiff > B && 0 !== B && (c.minDiff = B);
							null !== e &&
								null !== p.dataPoints[r - 1].y &&
								(a.axisY.logarithmic
									? 0 < e &&
									  ((B = e / p.dataPoints[r - 1].y),
									  1 > B && (B = 1 / B),
									  f.minDiff > B && 1 !== B && (f.minDiff = B))
									: ((B = e - p.dataPoints[r - 1].y),
									  0 > B && (B *= -1),
									  f.minDiff > B && 0 !== B && (f.minDiff = B)));
						}
						if (b < t && !k) null !== p.dataPoints[r].y && (z = b);
						else {
							if (!k && ((k = !0), 0 < r)) {
								r -= 2;
								continue;
							}
							if (b > u && !x) x = !0;
							else if (b > u && x) continue;
							p.dataPoints[r].label &&
								(a.axisX.labels[b] = p.dataPoints[r].label);
							b < c.viewPortMin && (c.viewPortMin = b);
							b > c.viewPortMax && (c.viewPortMax = b);
							null === p.dataPoints[r].y
								? c.viewPortMin === b && z < b && (c.viewPortMin = z)
								: ((a.yTotals[b] = (a.yTotals[b] ? a.yTotals[b] : 0) + e),
								  (a.yAbsTotals[b] =
										(a.yAbsTotals[b] ? a.yAbsTotals[b] : 0) + Math.abs(e)),
								  0 <= e
										? A[b]
											? (A[b] += e)
											: ((A[b] = e), (M = Math.min(e, M)))
										: d[b]
										? (d[b] += e)
										: ((d[b] = e), (m = Math.max(e, m))));
						}
					}
					a.axisY.scaleBreaks &&
						a.axisY.scaleBreaks.autoCalculate &&
						1 <= a.axisY.scaleBreaks.maxNumberOfAutoBreaks &&
						(f.dataPointYPositiveSums
							? (f.dataPointYPositiveSums.push.apply(
									f.dataPointYPositiveSums,
									A
							  ),
							  f.dataPointYNegativeSums.push.apply(
									f.dataPointYPositiveSums,
									d
							  ))
							: ((f.dataPointYPositiveSums = A),
							  (f.dataPointYNegativeSums = d)));
					p.axisX.valueType = p.xValueType = h ? "dateTime" : "number";
				}
				for (r in A)
					A.hasOwnProperty(r) &&
						!isNaN(r) &&
						((a = A[r]),
						a < f.min && (f.min = Math.min(a, M)),
						a > f.max && (f.max = a),
						r < c.viewPortMin ||
							r > c.viewPortMax ||
							(a < f.viewPortMin && (f.viewPortMin = Math.min(a, M)),
							a > f.viewPortMax && (f.viewPortMax = a)));
				for (r in d)
					d.hasOwnProperty(r) &&
						!isNaN(r) &&
						((a = d[r]),
						a < f.min && (f.min = a),
						a > f.max && (f.max = Math.max(a, m)),
						r < c.viewPortMin ||
							r > c.viewPortMax ||
							(a < f.viewPortMin && (f.viewPortMin = a),
							a > f.viewPortMax && (f.viewPortMax = Math.max(a, m))));
			}
		};
		v.prototype._processStacked100PlotUnit = function (a) {
			if (a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length)) {
				for (
					var f = a.axisY.dataInfo,
						c = a.axisX.dataInfo,
						b,
						e,
						h = !1,
						A = !1,
						d = !1,
						M = [],
						m = 0;
					m < a.dataSeriesIndexes.length;
					m++
				) {
					var n = this.data[a.dataSeriesIndexes[m]],
						p = 0,
						r = !1,
						k = !1,
						x;
					if ("normal" === n.axisPlacement || "xySwapped" === n.axisPlacement)
						var z = a.axisX.sessionVariables.newViewportMinimum
								? a.axisX.sessionVariables.newViewportMinimum
								: this.options.axisX && this.options.axisX.viewportMinimum
								? this.options.axisX.viewportMinimum
								: this.options.axisX && this.options.axisX.minimum
								? this.options.axisX.minimum
								: -Infinity,
							t = a.axisX.sessionVariables.newViewportMaximum
								? a.axisX.sessionVariables.newViewportMaximum
								: this.options.axisX && this.options.axisX.viewportMaximum
								? this.options.axisX.viewportMaximum
								: this.options.axisX && this.options.axisX.maximum
								? this.options.axisX.maximum
								: Infinity;
					if (
						(n.dataPoints[p].x && n.dataPoints[p].x.getTime) ||
						"dateTime" === n.xValueType
					)
						h = !0;
					for (p = 0; p < n.dataPoints.length; p++) {
						"undefined" === typeof n.dataPoints[p].x &&
							(n.dataPoints[p].x = p + (a.axisX.logarithmic ? 1 : 0));
						n.dataPoints[p].x.getTime
							? ((h = !0), (b = n.dataPoints[p].x.getTime()))
							: (b = n.dataPoints[p].x);
						e = g(n.dataPoints[p].y) ? null : n.dataPoints[p].y;
						b < c.min && (c.min = b);
						b > c.max && (c.max = b);
						if (0 < p) {
							if (a.axisX.logarithmic) {
								var u = b / n.dataPoints[p - 1].x;
								1 > u && (u = 1 / u);
								c.minDiff > u && 1 !== u && (c.minDiff = u);
							} else
								(u = b - n.dataPoints[p - 1].x),
									0 > u && (u *= -1),
									c.minDiff > u && 0 !== u && (c.minDiff = u);
							g(e) ||
								null === n.dataPoints[p - 1].y ||
								(a.axisY.logarithmic
									? 0 < e &&
									  ((u = e / n.dataPoints[p - 1].y),
									  1 > u && (u = 1 / u),
									  f.minDiff > u && 1 !== u && (f.minDiff = u))
									: ((u = e - n.dataPoints[p - 1].y),
									  0 > u && (u *= -1),
									  f.minDiff > u && 0 !== u && (f.minDiff = u)));
						}
						if (b < z && !r) null !== e && (x = b);
						else {
							if (!r && ((r = !0), 0 < p)) {
								p -= 2;
								continue;
							}
							if (b > t && !k) k = !0;
							else if (b > t && k) continue;
							n.dataPoints[p].label &&
								(a.axisX.labels[b] = n.dataPoints[p].label);
							b < c.viewPortMin && (c.viewPortMin = b);
							b > c.viewPortMax && (c.viewPortMax = b);
							null === e
								? c.viewPortMin === b && x < b && (c.viewPortMin = x)
								: ((a.yTotals[b] = (a.yTotals[b] ? a.yTotals[b] : 0) + e),
								  (a.yAbsTotals[b] =
										(a.yAbsTotals[b] ? a.yAbsTotals[b] : 0) + Math.abs(e)),
								  0 <= e ? (A = !0) : 0 > e && (d = !0),
								  (M[b] = M[b] ? M[b] + Math.abs(e) : Math.abs(e)));
						}
					}
					n.axisX.valueType = n.xValueType = h ? "dateTime" : "number";
				}
				a.axisY.logarithmic
					? ((f.max = g(f.viewPortMax)
							? 99 * Math.pow(a.axisY.logarithmBase, -0.05)
							: Math.max(
									f.viewPortMax,
									99 * Math.pow(a.axisY.logarithmBase, -0.05)
							  )),
					  (f.min = g(f.viewPortMin) ? 1 : Math.min(f.viewPortMin, 1)))
					: A && !d
					? ((f.max = g(f.viewPortMax) ? 99 : Math.max(f.viewPortMax, 99)),
					  (f.min = g(f.viewPortMin) ? 1 : Math.min(f.viewPortMin, 1)))
					: A && d
					? ((f.max = g(f.viewPortMax) ? 99 : Math.max(f.viewPortMax, 99)),
					  (f.min = g(f.viewPortMin) ? -99 : Math.min(f.viewPortMin, -99)))
					: !A &&
					  d &&
					  ((f.max = g(f.viewPortMax) ? -1 : Math.max(f.viewPortMax, -1)),
					  (f.min = g(f.viewPortMin) ? -99 : Math.min(f.viewPortMin, -99)));
				f.viewPortMin = f.min;
				f.viewPortMax = f.max;
				a.dataPointYSums = M;
			}
		};
		v.prototype._processMultiYPlotUnit = function (a) {
			if (a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length))
				for (
					var f = a.axisY.dataInfo,
						c = a.axisX.dataInfo,
						b,
						e,
						h,
						A,
						g = !1,
						d = 0;
					d < a.dataSeriesIndexes.length;
					d++
				) {
					var m = this.data[a.dataSeriesIndexes[d]],
						n = 0,
						p = !1,
						r = !1,
						k,
						x,
						z;
					if ("normal" === m.axisPlacement || "xySwapped" === m.axisPlacement)
						var t = a.axisX.sessionVariables.newViewportMinimum
								? a.axisX.sessionVariables.newViewportMinimum
								: this.options.axisX && this.options.axisX.viewportMinimum
								? this.options.axisX.viewportMinimum
								: this.options.axisX && this.options.axisX.minimum
								? this.options.axisX.minimum
								: a.axisX.logarithmic
								? 0
								: -Infinity,
							u = a.axisX.sessionVariables.newViewportMaximum
								? a.axisX.sessionVariables.newViewportMaximum
								: this.options.axisX && this.options.axisX.viewportMaximum
								? this.options.axisX.viewportMaximum
								: this.options.axisX && this.options.axisX.maximum
								? this.options.axisX.maximum
								: Infinity;
					if (
						(m.dataPoints[n].x && m.dataPoints[n].x.getTime) ||
						"dateTime" === m.xValueType
					)
						g = !0;
					for (n = 0; n < m.dataPoints.length; n++) {
						"undefined" === typeof m.dataPoints[n].x &&
							(m.dataPoints[n].x = n + (a.axisX.logarithmic ? 1 : 0));
						m.dataPoints[n].x.getTime
							? ((g = !0), (b = m.dataPoints[n].x.getTime()))
							: (b = m.dataPoints[n].x);
						if ((e = m.dataPoints[n].y) && e.length) {
							h = Math.min.apply(null, e);
							A = Math.max.apply(null, e);
							x = !0;
							for (var B = 0; B < e.length; B++) null === e.k && (x = !1);
							x && (p || (z = k), (k = b));
						}
						b < c.min && (c.min = b);
						b > c.max && (c.max = b);
						h < f.min && (f.min = h);
						A > f.max && (f.max = A);
						0 < n &&
							(a.axisX.logarithmic
								? ((x = b / m.dataPoints[n - 1].x),
								  1 > x && (x = 1 / x),
								  c.minDiff > x && 1 !== x && (c.minDiff = x))
								: ((x = b - m.dataPoints[n - 1].x),
								  0 > x && (x *= -1),
								  c.minDiff > x && 0 !== x && (c.minDiff = x)),
							e &&
								null !== e[0] &&
								m.dataPoints[n - 1].y &&
								null !== m.dataPoints[n - 1].y[0] &&
								(a.axisY.logarithmic
									? ((x = e[0] / m.dataPoints[n - 1].y[0]),
									  1 > x && (x = 1 / x),
									  f.minDiff > x && 1 !== x && (f.minDiff = x))
									: ((x = e[0] - m.dataPoints[n - 1].y[0]),
									  0 > x && (x *= -1),
									  f.minDiff > x && 0 !== x && (f.minDiff = x))));
						if (!(b < t) || p) {
							if (!p && ((p = !0), 0 < n)) {
								n -= 2;
								k = z;
								continue;
							}
							if (b > u && !r) r = !0;
							else if (b > u && r) continue;
							m.dataPoints[n].label &&
								(a.axisX.labels[b] = m.dataPoints[n].label);
							b < c.viewPortMin && (c.viewPortMin = b);
							b > c.viewPortMax && (c.viewPortMax = b);
							if (c.viewPortMin === b && e)
								for (B = 0; B < e.length; B++)
									if (null === e[B] && k < b) {
										c.viewPortMin = k;
										break;
									}
							null === e
								? c.viewPortMin === b && k < b && (c.viewPortMin = k)
								: (h < f.viewPortMin && (f.viewPortMin = h),
								  A > f.viewPortMax && (f.viewPortMax = A));
						}
					}
					m.axisX.valueType = m.xValueType = g ? "dateTime" : "number";
				}
		};
		v.prototype._processSpecificPlotUnit = function (a) {
			if (
				"waterfall" === a.type &&
				a.dataSeriesIndexes &&
				!(1 > a.dataSeriesIndexes.length)
			)
				for (
					var f = a.axisY.dataInfo, c = a.axisX.dataInfo, b, e, h = !1, A = 0;
					A < a.dataSeriesIndexes.length;
					A++
				) {
					var g = this.data[a.dataSeriesIndexes[A]],
						d = 0,
						m = !1,
						n = !1,
						p = (b = 0);
					if ("normal" === g.axisPlacement || "xySwapped" === g.axisPlacement)
						var r = a.axisX.sessionVariables.newViewportMinimum
								? a.axisX.sessionVariables.newViewportMinimum
								: this.options.axisX && this.options.axisX.viewportMinimum
								? this.options.axisX.viewportMinimum
								: this.options.axisX && this.options.axisX.minimum
								? this.options.axisX.minimum
								: a.axisX.logarithmic
								? 0
								: -Infinity,
							k = a.axisX.sessionVariables.newViewportMaximum
								? a.axisX.sessionVariables.newViewportMaximum
								: this.options.axisX && this.options.axisX.viewportMaximum
								? this.options.axisX.viewportMaximum
								: this.options.axisX && this.options.axisX.maximum
								? this.options.axisX.maximum
								: Infinity;
					if (
						(g.dataPoints[d].x && g.dataPoints[d].x.getTime) ||
						"dateTime" === g.xValueType
					)
						h = !0;
					for (d = 0; d < g.dataPoints.length; d++)
						"undefined" !== typeof g.dataPoints[d].isCumulativeSum &&
						!0 === g.dataPoints[d].isCumulativeSum
							? ((g.dataPointEOs[d].cumulativeSumYStartValue = 0),
							  (g.dataPointEOs[d].cumulativeSum =
									0 === d ? 0 : g.dataPointEOs[d - 1].cumulativeSum),
							  (g.dataPoints[d].y =
									0 === d ? 0 : g.dataPointEOs[d - 1].cumulativeSum))
							: "undefined" !== typeof g.dataPoints[d].isIntermediateSum &&
							  !0 === g.dataPoints[d].isIntermediateSum
							? ((g.dataPointEOs[d].cumulativeSumYStartValue = p),
							  (g.dataPointEOs[d].cumulativeSum =
									0 === d ? 0 : g.dataPointEOs[d - 1].cumulativeSum),
							  (g.dataPoints[d].y = 0 === d ? 0 : b),
							  (p = 0 === d ? 0 : g.dataPointEOs[d - 1].cumulativeSum),
							  (b = 0))
							: ((e =
									"number" !== typeof g.dataPoints[d].y
										? 0
										: g.dataPoints[d].y),
							  (g.dataPointEOs[d].cumulativeSumYStartValue =
									0 === d ? 0 : g.dataPointEOs[d - 1].cumulativeSum),
							  (g.dataPointEOs[d].cumulativeSum =
									0 === d ? e : g.dataPointEOs[d - 1].cumulativeSum + e),
							  (b += e));
					for (d = 0; d < g.dataPoints.length; d++)
						if (
							("undefined" === typeof g.dataPoints[d].x &&
								(g.dataPoints[d].x = d + (a.axisX.logarithmic ? 1 : 0)),
							g.dataPoints[d].x.getTime
								? ((h = !0), (b = g.dataPoints[d].x.getTime()))
								: (b = g.dataPoints[d].x),
							(e = g.dataPoints[d].y),
							b < c.min && (c.min = b),
							b > c.max && (c.max = b),
							g.dataPointEOs[d].cumulativeSum < f.min &&
								(f.min = g.dataPointEOs[d].cumulativeSum),
							g.dataPointEOs[d].cumulativeSum > f.max &&
								(f.max = g.dataPointEOs[d].cumulativeSum),
							0 < d &&
								(a.axisX.logarithmic
									? ((p = b / g.dataPoints[d - 1].x),
									  1 > p && (p = 1 / p),
									  c.minDiff > p && 1 !== p && (c.minDiff = p))
									: ((p = b - g.dataPoints[d - 1].x),
									  0 > p && (p *= -1),
									  c.minDiff > p && 0 !== p && (c.minDiff = p)),
								null !== e &&
									null !== g.dataPoints[d - 1].y &&
									(a.axisY.logarithmic
										? ((e =
												g.dataPointEOs[d].cumulativeSum /
												g.dataPointEOs[d - 1].cumulativeSum),
										  1 > e && (e = 1 / e),
										  f.minDiff > e && 1 !== e && (f.minDiff = e))
										: ((e =
												g.dataPointEOs[d].cumulativeSum -
												g.dataPointEOs[d - 1].cumulativeSum),
										  0 > e && (e *= -1),
										  f.minDiff > e && 0 !== e && (f.minDiff = e)))),
							!(b < r) || m)
						) {
							if (!m && ((m = !0), 0 < d)) {
								d -= 2;
								continue;
							}
							if (b > k && !n) n = !0;
							else if (b > k && n) continue;
							g.dataPoints[d].label &&
								(a.axisX.labels[b] = g.dataPoints[d].label);
							b < c.viewPortMin && (c.viewPortMin = b);
							b > c.viewPortMax && (c.viewPortMax = b);
							0 < d &&
								(g.dataPointEOs[d - 1].cumulativeSum < f.viewPortMin &&
									(f.viewPortMin = g.dataPointEOs[d - 1].cumulativeSum),
								g.dataPointEOs[d - 1].cumulativeSum > f.viewPortMax &&
									(f.viewPortMax = g.dataPointEOs[d - 1].cumulativeSum));
							g.dataPointEOs[d].cumulativeSum < f.viewPortMin &&
								(f.viewPortMin = g.dataPointEOs[d].cumulativeSum);
							g.dataPointEOs[d].cumulativeSum > f.viewPortMax &&
								(f.viewPortMax = g.dataPointEOs[d].cumulativeSum);
						}
					g.axisX.valueType = g.xValueType = h ? "dateTime" : "number";
				}
		};
		v.prototype.calculateAutoBreaks = function () {
			function a(a, b, c, f) {
				if (f)
					return (
						(c = Math.pow(Math.min((c * a) / b, b / a), 0.2)),
						1 >= c && (c = Math.pow(1 > a ? 1 / a : Math.min(b / a, a), 0.25)),
						{ startValue: a * c, endValue: b / c }
					);
				c = 0.2 * Math.min(c - b + a, b - a);
				0 >= c && (c = 0.25 * Math.min(b - a, Math.abs(a)));
				return { startValue: a + c, endValue: b - c };
			}
			function f(a) {
				if (a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length)) {
					var b =
							a.axisX.scaleBreaks &&
							a.axisX.scaleBreaks.autoCalculate &&
							1 <= a.axisX.scaleBreaks.maxNumberOfAutoBreaks,
						c =
							a.axisY.scaleBreaks &&
							a.axisY.scaleBreaks.autoCalculate &&
							1 <= a.axisY.scaleBreaks.maxNumberOfAutoBreaks;
					if (b || c)
						for (
							var f = a.axisY.dataInfo,
								h = a.axisX.dataInfo,
								k,
								A = h.min,
								m = h.max,
								d = f.min,
								n = f.max,
								h = h._dataRanges,
								f = f._dataRanges,
								p,
								r = 0,
								ua = 0;
							ua < a.dataSeriesIndexes.length;
							ua++
						) {
							var z = e.data[a.dataSeriesIndexes[ua]];
							if (!(4 > z.dataPoints.length))
								for (r = 0; r < z.dataPoints.length; r++)
									if (
										(b &&
											((p =
												((m + 1 - A) *
													Math.max(
														parseFloat(
															a.axisX.scaleBreaks.collapsibleThreshold
														) || 10,
														10
													)) /
												100),
											(k = z.dataPoints[r].x.getTime
												? z.dataPoints[r].x.getTime()
												: z.dataPoints[r].x),
											(p = Math.floor((k - A) / p)),
											k < h[p].min && (h[p].min = k),
											k > h[p].max && (h[p].max = k)),
										c)
									) {
										var M =
											((n + 1 - d) *
												Math.max(
													parseFloat(
														a.axisY.scaleBreaks.collapsibleThreshold
													) || 10,
													10
												)) /
											100;
										if (
											(k =
												"waterfall" === a.type
													? z.dataPointEOs[r].cumulativeSum
													: z.dataPoints[r].y) &&
											k.length
										)
											for (var t = 0; t < k.length; t++)
												(p = Math.floor((k[t] - d) / M)),
													k[t] < f[p].min && (f[p].min = k[t]),
													k[t] > f[p].max && (f[p].max = k[t]);
										else
											g(k) ||
												((p = Math.floor((k - d) / M)),
												k < f[p].min && (f[p].min = k),
												k > f[p].max && (f[p].max = k));
									}
						}
				}
			}
			function c(a) {
				if (
					a.dataSeriesIndexes &&
					!(1 > a.dataSeriesIndexes.length) &&
					a.axisX.scaleBreaks &&
					a.axisX.scaleBreaks.autoCalculate &&
					1 <= a.axisX.scaleBreaks.maxNumberOfAutoBreaks
				)
					for (
						var b = a.axisX.dataInfo,
							c = b.min,
							f = b.max,
							h = b._dataRanges,
							k,
							A = 0,
							m = 0;
						m < a.dataSeriesIndexes.length;
						m++
					) {
						var g = e.data[a.dataSeriesIndexes[m]];
						if (!(4 > g.dataPoints.length))
							for (A = 0; A < g.dataPoints.length; A++)
								(k =
									((f + 1 - c) *
										Math.max(
											parseFloat(a.axisX.scaleBreaks.collapsibleThreshold) ||
												10,
											10
										)) /
									100),
									(b = g.dataPoints[A].x.getTime
										? g.dataPoints[A].x.getTime()
										: g.dataPoints[A].x),
									(k = Math.floor((b - c) / k)),
									b < h[k].min && (h[k].min = b),
									b > h[k].max && (h[k].max = b);
					}
			}
			for (var b, e = this, h = !1, A = 0; A < this._axes.length; A++)
				if (
					this._axes[A].scaleBreaks &&
					this._axes[A].scaleBreaks.autoCalculate &&
					1 <= this._axes[A].scaleBreaks.maxNumberOfAutoBreaks
				) {
					h = !0;
					this._axes[A].dataInfo._dataRanges = [];
					for (
						var d = 0;
						d <
						100 /
							Math.max(
								parseFloat(this._axes[A].scaleBreaks.collapsibleThreshold) ||
									10,
								10
							);
						d++
					)
						this._axes[A].dataInfo._dataRanges.push({
							min: Infinity,
							max: -Infinity,
						});
				}
			if (h) {
				for (A = 0; A < this.plotInfo.plotTypes.length; A++)
					for (
						h = this.plotInfo.plotTypes[A], d = 0;
						d < h.plotUnits.length;
						d++
					)
						(b = h.plotUnits[d]),
							"line" === b.type ||
							"stepLine" === b.type ||
							"spline" === b.type ||
							"column" === b.type ||
							"area" === b.type ||
							"stepArea" === b.type ||
							"splineArea" === b.type ||
							"bar" === b.type ||
							"bubble" === b.type ||
							"scatter" === b.type ||
							"candlestick" === b.type ||
							"ohlc" === b.type ||
							"rangeColumn" === b.type ||
							"rangeBar" === b.type ||
							"rangeArea" === b.type ||
							"rangeSplineArea" === b.type ||
							"waterfall" === b.type ||
							"error" === b.type ||
							"boxAndWhisker" === b.type
								? f(b)
								: 0 <= b.type.indexOf("stacked") && c(b);
				for (A = 0; A < this._axes.length; A++)
					if (this._axes[A].dataInfo._dataRanges) {
						var z = this._axes[A].dataInfo.min;
						b =
							((this._axes[A].dataInfo.max + 1 - z) *
								Math.max(
									parseFloat(this._axes[A].scaleBreaks.collapsibleThreshold) ||
										10,
									10
								)) /
							100;
						var m = this._axes[A].dataInfo._dataRanges,
							n,
							p,
							h = [];
						if (this._axes[A].dataInfo.dataPointYPositiveSums) {
							var r = this._axes[A].dataInfo.dataPointYPositiveSums;
							n = m;
							for (d in r)
								if (r.hasOwnProperty(d) && !isNaN(d) && ((p = r[d]), !g(p))) {
									var k = Math.floor((p - z) / b);
									p < n[k].min && (n[k].min = p);
									p > n[k].max && (n[k].max = p);
								}
							delete this._axes[A].dataInfo.dataPointYPositiveSums;
						}
						if (this._axes[A].dataInfo.dataPointYNegativeSums) {
							r = this._axes[A].dataInfo.dataPointYNegativeSums;
							n = m;
							for (d in r)
								r.hasOwnProperty(d) &&
									!isNaN(d) &&
									((p = -1 * r[d]),
									g(p) ||
										((k = Math.floor((p - z) / b)),
										p < n[k].min && (n[k].min = p),
										p > n[k].max && (n[k].max = p)));
							delete this._axes[A].dataInfo.dataPointYNegativeSums;
						}
						for (d = 0; d < m.length - 1; d++)
							if (((n = m[d].max), isFinite(n)))
								for (; d < m.length - 1; )
									if (((z = m[d + 1].min), isFinite(z))) {
										p = z - n;
										p > b && h.push({ diff: p, start: n, end: z });
										break;
									} else d++;
						if (this._axes[A].scaleBreaks.customBreaks)
							for (
								d = 0;
								d < this._axes[A].scaleBreaks.customBreaks.length;
								d++
							)
								for (b = 0; b < h.length; b++)
									if (
										(this._axes[A].scaleBreaks.customBreaks[d].startValue <=
											h[b].start &&
											h[b].start <=
												this._axes[A].scaleBreaks.customBreaks[d].endValue) ||
										(this._axes[A].scaleBreaks.customBreaks[d].startValue <=
											h[b].start &&
											h[b].start <=
												this._axes[A].scaleBreaks.customBreaks[d].endValue) ||
										(h[b].start <=
											this._axes[A].scaleBreaks.customBreaks[d].startValue &&
											this._axes[A].scaleBreaks.customBreaks[d].startValue <=
												h[b].end) ||
										(h[b].start <=
											this._axes[A].scaleBreaks.customBreaks[d].endValue &&
											this._axes[A].scaleBreaks.customBreaks[d].endValue <=
												h[b].end)
									)
										h.splice(b, 1), b--;
						h.sort(function (a, b) {
							return b.diff - a.diff;
						});
						for (
							d = 0;
							d <
							Math.min(
								h.length,
								this._axes[A].scaleBreaks.maxNumberOfAutoBreaks
							);
							d++
						)
							(b = a(
								h[d].start,
								h[d].end,
								this._axes[A].logarithmic
									? this._axes[A].dataInfo.max / this._axes[A].dataInfo.min
									: this._axes[A].dataInfo.max - this._axes[A].dataInfo.min,
								this._axes[A].logarithmic
							)),
								this._axes[A].scaleBreaks.autoBreaks.push(
									new W(
										this,
										"autoBreaks",
										b,
										d,
										++this._eventManager.lastObjectId,
										this._axes[A].scaleBreaks
									)
								),
								this._axes[A].scaleBreaks._appliedBreaks.push(
									this._axes[A].scaleBreaks.autoBreaks[
										this._axes[A].scaleBreaks.autoBreaks.length - 1
									]
								);
						this._axes[A].scaleBreaks._appliedBreaks.sort(function (a, b) {
							return a.startValue - b.startValue;
						});
					}
			}
		};
		v.prototype.renderCrosshairs = function (a) {
			for (var f = 0; f < this.axisX.length; f++)
				this.axisX[f] != a &&
					this.axisX[f].crosshair &&
					this.axisX[f].crosshair.enabled &&
					!this.axisX[f].crosshair._hidden &&
					this.axisX[f].showCrosshair(this.axisX[f].crosshair._updatedValue);
			for (f = 0; f < this.axisX2.length; f++)
				this.axisX2[f] != a &&
					this.axisX2[f].crosshair &&
					this.axisX2[f].crosshair.enabled &&
					!this.axisX2[f].crosshair._hidden &&
					this.axisX2[f].showCrosshair(this.axisX2[f].crosshair._updatedValue);
			for (f = 0; f < this.axisY.length; f++)
				this.axisY[f] != a &&
					this.axisY[f].crosshair &&
					this.axisY[f].crosshair.enabled &&
					!this.axisY[f].crosshair._hidden &&
					this.axisY[f].showCrosshair(this.axisY[f].crosshair._updatedValue);
			for (f = 0; f < this.axisY2.length; f++)
				this.axisY2[f] != a &&
					this.axisY2[f].crosshair &&
					this.axisY2[f].crosshair.enabled &&
					!this.axisY2[f].crosshair._hidden &&
					this.axisY2[f].showCrosshair(this.axisY2[f].crosshair._updatedValue);
		};
		v.prototype.getDataPointAtXY = function (a, f, c) {
			c = c || !1;
			for (var b = [], e = this._dataInRenderedOrder.length - 1; 0 <= e; e--) {
				var h = null;
				(h = this._dataInRenderedOrder[e].getDataPointAtXY(a, f, c)) &&
					b.push(h);
			}
			a = null;
			f = !1;
			for (c = 0; c < b.length; c++)
				if (
					"line" === b[c].dataSeries.type ||
					"stepLine" === b[c].dataSeries.type ||
					"area" === b[c].dataSeries.type ||
					"stepArea" === b[c].dataSeries.type
				)
					if (
						((e = oa("markerSize", b[c].dataPoint, b[c].dataSeries) || 8),
						b[c].distance <= e / 2)
					) {
						f = !0;
						break;
					}
			for (c = 0; c < b.length; c++)
				(f &&
					"line" !== b[c].dataSeries.type &&
					"stepLine" !== b[c].dataSeries.type &&
					"area" !== b[c].dataSeries.type &&
					"stepArea" !== b[c].dataSeries.type) ||
					(a ? b[c].distance <= a.distance && (a = b[c]) : (a = b[c]));
			return a;
		};
		v.prototype.getObjectAtXY = function (a, f, c) {
			var b = null;
			if ((c = this.getDataPointAtXY(a, f, c || !1)))
				b = c.dataSeries.dataPointIds[c.dataPointIndex];
			else if (t) b = jb(a, f, this._eventManager.ghostCtx);
			else
				for (c = 0; c < this.legend.items.length; c++) {
					var e = this.legend.items[c];
					a >= e.x1 && a <= e.x2 && f >= e.y1 && f <= e.y2 && (b = e.id);
				}
			return b;
		};
		v.prototype.getAutoFontSize = nb;
		v.prototype.resetOverlayedCanvas = function () {
			this.overlaidCanvasCtx.clearRect(0, 0, this.width, this.height);
		};
		v.prototype.clearCanvas = mb;
		v.prototype.attachEvent = function (a) {
			this._events.push(a);
		};
		v.prototype._touchEventHandler = function (a) {
			if (a.changedTouches && this.interactivityEnabled) {
				var f = [],
					c = a.changedTouches,
					b = c ? c[0] : a,
					e = null;
				switch (a.type) {
					case "touchstart":
					case "MSPointerDown":
						f = ["mousemove", "mousedown"];
						this._lastTouchData = Aa(b);
						this._lastTouchData.time = new Date();
						break;
					case "touchmove":
					case "MSPointerMove":
						f = ["mousemove"];
						break;
					case "touchend":
					case "MSPointerUp":
						var h =
								this._lastTouchData && this._lastTouchData.time
									? new Date() - this._lastTouchData.time
									: 0,
							f =
								"touchstart" === this._lastTouchEventType ||
								"MSPointerDown" === this._lastTouchEventType ||
								300 > h
									? ["mouseup", "click"]
									: ["mouseup"];
						break;
					default:
						return;
				}
				if (!(c && 1 < c.length)) {
					e = Aa(b);
					e.time = new Date();
					try {
						var A = e.y - this._lastTouchData.y,
							h = e.time - this._lastTouchData.time;
						if (
							(1 < Math.abs(A) && this._lastTouchData.scroll) ||
							(5 < Math.abs(A) && 250 > h)
						)
							this._lastTouchData.scroll = !0;
					} catch (d) {}
					this._lastTouchEventType = a.type;
					if (this._lastTouchData.scroll && this.zoomEnabled)
						this.isDrag && this.resetOverlayedCanvas(), (this.isDrag = !1);
					else
						for (c = 0; c < f.length; c++)
							if (
								((e = f[c]),
								(A = document.createEvent("MouseEvent")),
								A.initMouseEvent(
									e,
									!0,
									!0,
									window,
									1,
									b.screenX,
									b.screenY,
									b.clientX,
									b.clientY,
									!1,
									!1,
									!1,
									!1,
									0,
									null
								),
								b.target.dispatchEvent(A),
								(!g(this._lastTouchData.scroll) &&
									!this._lastTouchData.scroll) ||
									(!this._lastTouchData.scroll && 250 < h) ||
									"click" === e)
							)
								a.preventManipulation && a.preventManipulation(),
									a.preventDefault && a.cancelable && a.preventDefault();
				}
			}
		};
		v.prototype._dispatchRangeEvent = function (a, f) {
			var c = { chart: this };
			c.type = a;
			c.trigger = f;
			var b = [];
			this.axisX && 0 < this.axisX.length && b.push("axisX");
			this.axisX2 && 0 < this.axisX2.length && b.push("axisX2");
			this.axisY && 0 < this.axisY.length && b.push("axisY");
			this.axisY2 && 0 < this.axisY2.length && b.push("axisY2");
			for (var e = 0; e < b.length; e++)
				if ((g(c[b[e]]) && (c[b[e]] = []), "axisY" === b[e]))
					for (var h = 0; h < this.axisY.length; h++)
						c[b[e]].push({
							viewportMinimum:
								this[b[e]][h].sessionVariables.newViewportMinimum,
							viewportMaximum:
								this[b[e]][h].sessionVariables.newViewportMaximum,
						});
				else if ("axisY2" === b[e])
					for (h = 0; h < this.axisY2.length; h++)
						c[b[e]].push({
							viewportMinimum:
								this[b[e]][h].sessionVariables.newViewportMinimum,
							viewportMaximum:
								this[b[e]][h].sessionVariables.newViewportMaximum,
						});
				else if ("axisX" === b[e])
					for (h = 0; h < this.axisX.length; h++)
						c[b[e]].push({
							viewportMinimum:
								this[b[e]][h].sessionVariables.newViewportMinimum,
							viewportMaximum:
								this[b[e]][h].sessionVariables.newViewportMaximum,
						});
				else if ("axisX2" === b[e])
					for (h = 0; h < this.axisX2.length; h++)
						c[b[e]].push({
							viewportMinimum:
								this[b[e]][h].sessionVariables.newViewportMinimum,
							viewportMaximum:
								this[b[e]][h].sessionVariables.newViewportMaximum,
						});
			this.dispatchEvent(a, c, this);
		};
		v.prototype._mouseEventHandler = function (a) {
			"undefined" === typeof a.target &&
				a.srcElement &&
				(a.target = a.srcElement);
			var f = Aa(a),
				c = a.type,
				b,
				e;
			a.which ? (e = 3 == a.which) : a.button && (e = 2 == a.button);
			v.capturedEventParam &&
				((b = v.capturedEventParam),
				"mouseup" === c &&
					((v.capturedEventParam = null),
					b.chart.overlaidCanvas.releaseCapture
						? b.chart.overlaidCanvas.releaseCapture()
						: document.documentElement.removeEventListener(
								"mouseup",
								b.chart._mouseEventHandler,
								!1
						  )),
				b.hasOwnProperty(c) &&
					("mouseup" !== c || b.chart.overlaidCanvas.releaseCapture
						? (a.target !== b.chart.overlaidCanvas && t) ||
						  b[c].call(b.context, f.x, f.y)
						: a.target !== b.chart.overlaidCanvas && (b.chart.isDrag = !1)));
			if (this.interactivityEnabled)
				if (this._ignoreNextEvent) this._ignoreNextEvent = !1;
				else if (
					(a.preventManipulation && a.preventManipulation(),
					a.preventDefault && a.preventDefault(),
					La &&
						window.console &&
						(window.console.log(c + " --\x3e x: " + f.x + "; y:" + f.y),
						e && window.console.log(a.which),
						"mouseup" === c && window.console.log("mouseup")),
					!e)
				) {
					if (!v.capturedEventParam && this._events) {
						for (var h = 0; h < this._events.length; h++)
							if (this._events[h].hasOwnProperty(c))
								if (
									((b = this._events[h]),
									(e = b.bounds),
									f.x >= e.x1 && f.x <= e.x2 && f.y >= e.y1 && f.y <= e.y2)
								) {
									b[c].call(b.context, f.x, f.y);
									"mousedown" === c && !0 === b.capture
										? ((v.capturedEventParam = b),
										  this.overlaidCanvas.setCapture
												? this.overlaidCanvas.setCapture()
												: document.documentElement.addEventListener(
														"mouseup",
														this._mouseEventHandler,
														!1
												  ))
										: "mouseup" === c &&
										  (b.chart.overlaidCanvas.releaseCapture
												? b.chart.overlaidCanvas.releaseCapture()
												: document.documentElement.removeEventListener(
														"mouseup",
														this._mouseEventHandler,
														!1
												  ));
									break;
								} else b = null;
						a.target.style.cursor =
							b && b.cursor ? b.cursor : this._defaultCursor;
					}
					c = this.plotArea;
					if (f.x < c.x1 || f.x > c.x2 || f.y < c.y1 || f.y > c.y2) {
						this.toolTip && this.toolTip.enabled
							? (this.toolTip.hide(),
							  this.toolTip.dispatchEvent(
									"hidden",
									{ chart: this, toolTip: this.toolTip },
									this.toolTip
							  ))
							: this.resetOverlayedCanvas();
						for (h = 0; h < this.axisX.length; h++)
							this.axisX[h].crosshair &&
								this.axisX[h].crosshair.enabled &&
								(this.axisX[h].crosshair.hide(),
								this.axisX[h].crosshair.dispatchEvent(
									"hidden",
									{ chart: this, axis: this.axisX[h].options },
									this.axisX[h].crosshair
								));
						for (h = 0; h < this.axisX2.length; h++)
							this.axisX2[h].crosshair &&
								this.axisX2[h].crosshair.enabled &&
								(this.axisX2[h].crosshair.hide(),
								this.axisX2[h].crosshair.dispatchEvent(
									"hidden",
									{ chart: this, axis: this.axisX2[h].options },
									this.axisX2[h].crosshair
								));
						for (h = 0; h < this.axisY.length; h++)
							this.axisY[h].crosshair &&
								this.axisY[h].crosshair.enabled &&
								(this.axisY[h].crosshair.hide(),
								this.axisY[h].crosshair.dispatchEvent(
									"hidden",
									{ chart: this, axis: this.axisY[h].options },
									this.axisY[h].crosshair
								));
						for (h = 0; h < this.axisY2.length; h++)
							this.axisY2[h].crosshair &&
								this.axisY2[h].crosshair.enabled &&
								(this.axisY2[h].crosshair.hide(),
								this.axisY2[h].crosshair.dispatchEvent(
									"hidden",
									{ chart: this, axis: this.axisY2[h].options },
									this.axisY2[h].crosshair
								));
					}
					(this.isDrag && this.zoomEnabled) ||
						!this._eventManager ||
						this._eventManager.mouseEventHandler(a);
				}
		};
		v.prototype._plotAreaMouseDown = function (a, f) {
			this.isDrag = !0;
			this.dragStartPoint = { x: a, y: f };
		};
		v.prototype._plotAreaMouseUp = function (a, f) {
			if (
				("normal" === this.plotInfo.axisPlacement ||
					"xySwapped" === this.plotInfo.axisPlacement) &&
				this.isDrag
			) {
				var c = f - this.dragStartPoint.y,
					b = a - this.dragStartPoint.x,
					e = 0 <= this.zoomType.indexOf("x"),
					h = 0 <= this.zoomType.indexOf("y"),
					A = !1;
				this.resetOverlayedCanvas();
				if ("xySwapped" === this.plotInfo.axisPlacement)
					var g = h,
						h = e,
						e = g;
				if (this.panEnabled || this.zoomEnabled) {
					if (this.panEnabled)
						for (e = h = 0; e < this._axes.length; e++)
							(c = this._axes[e]),
								c.logarithmic
									? c.viewportMinimum < c.minimum
										? ((h = c.minimum / c.viewportMinimum),
										  (c.sessionVariables.newViewportMinimum =
												c.viewportMinimum * h),
										  (c.sessionVariables.newViewportMaximum =
												c.viewportMaximum * h),
										  (A = !0))
										: c.viewportMaximum > c.maximum &&
										  ((h = c.viewportMaximum / c.maximum),
										  (c.sessionVariables.newViewportMinimum =
												c.viewportMinimum / h),
										  (c.sessionVariables.newViewportMaximum =
												c.viewportMaximum / h),
										  (A = !0))
									: c.viewportMinimum < c.minimum
									? ((h = c.minimum - c.viewportMinimum),
									  (c.sessionVariables.newViewportMinimum =
											c.viewportMinimum + h),
									  (c.sessionVariables.newViewportMaximum =
											c.viewportMaximum + h),
									  (A = !0))
									: c.viewportMaximum > c.maximum &&
									  ((h = c.viewportMaximum - c.maximum),
									  (c.sessionVariables.newViewportMinimum =
											c.viewportMinimum - h),
									  (c.sessionVariables.newViewportMaximum =
											c.viewportMaximum - h),
									  (A = !0));
					else if (
						(!e || 2 < Math.abs(b)) &&
						(!h || 2 < Math.abs(c)) &&
						this.zoomEnabled
					) {
						if (!this.dragStartPoint) return;
						c = e ? this.dragStartPoint.x : this.plotArea.x1;
						b = h ? this.dragStartPoint.y : this.plotArea.y1;
						e = e ? a : this.plotArea.x2;
						h = h ? f : this.plotArea.y2;
						2 < Math.abs(c - e) &&
							2 < Math.abs(b - h) &&
							this._zoomPanToSelectedRegion(c, b, e, h) &&
							(A = !0);
					}
					A &&
						((this._ignoreNextEvent = !0),
						this._dispatchRangeEvent("rangeChanging", "zoom"),
						this.stockChart &&
							this.stockChart.navigator &&
							this.stockChart.navigator.enabled &&
							(this.stockChart._rangeEventParameter ||
								(this.stockChart._rangeEventParameter = {
									stockChart: this.stockChart,
									source: "chart",
									index: this.stockChart.charts.indexOf(this),
									minimum: this.stockChart.sessionVariables._axisXMin,
									maximum: this.stockChart.sessionVariables._axisXMax,
								}),
							(this.stockChart._rangeEventParameter.type = "rangeChanging"),
							this.stockChart.dispatchEvent(
								"rangeChanging",
								this.stockChart._rangeEventParameter,
								this.stockChart
							)),
						this.render(),
						this._dispatchRangeEvent("rangeChanged", "zoom"),
						this.stockChart &&
							this.stockChart.navigator &&
							this.stockChart.navigator.enabled &&
							((this.stockChart._rangeEventParameter.type = "rangeChanged"),
							this.stockChart.dispatchEvent(
								"rangeChanged",
								this.stockChart._rangeEventParameter,
								this.stockChart
							)),
						A &&
							this.zoomEnabled &&
							"none" === this._zoomButton.style.display &&
							(Sa(this._zoomButton, this._resetButton),
							Ba(this, this._zoomButton, "pan"),
							Ba(this, this._resetButton, "reset")));
				}
			}
			this.isDrag = !1;
			if ("none" !== this.plotInfo.axisPlacement) {
				this.resetOverlayedCanvas();
				if (this.axisX && 0 < this.axisX.length)
					for (A = 0; A < this.axisX.length; A++)
						this.axisX[A].crosshair &&
							this.axisX[A].crosshair.enabled &&
							this.axisX[A].renderCrosshair(a, f);
				if (this.axisX2 && 0 < this.axisX2.length)
					for (A = 0; A < this.axisX2.length; A++)
						this.axisX2[A].crosshair &&
							this.axisX2[A].crosshair.enabled &&
							this.axisX2[A].renderCrosshair(a, f);
				if (this.axisY && 0 < this.axisY.length)
					for (A = 0; A < this.axisY.length; A++)
						this.axisY[A].crosshair &&
							this.axisY[A].crosshair.enabled &&
							this.axisY[A].renderCrosshair(a, f);
				if (this.axisY2 && 0 < this.axisY2.length)
					for (A = 0; A < this.axisY2.length; A++)
						this.axisY2[A].crosshair &&
							this.axisY2[A].crosshair.enabled &&
							this.axisY2[A].renderCrosshair(a, f);
				if (this.axisX && 0 < this.axisX.length)
					for (A = 0; A < this.axisX.length; A++)
						this.axisX[A].crosshair &&
							this.axisX[A].crosshair.enabled &&
							this.axisX[A].crosshair.renderLabel();
				if (this.axisX2 && 0 < this.axisX2.length)
					for (A = 0; A < this.axisX2.length; A++)
						this.axisX2[A].crosshair &&
							this.axisX2[A].crosshair.enabled &&
							this.axisX2[A].crosshair.renderLabel();
				if (this.axisY && 0 < this.axisY.length)
					for (A = 0; A < this.axisY.length; A++)
						this.axisY[A].crosshair &&
							this.axisY[A].crosshair.enabled &&
							this.axisY[A].crosshair.renderLabel();
				if (this.axisY2 && 0 < this.axisY2.length)
					for (A = 0; A < this.axisY2.length; A++)
						this.axisY2[A].crosshair &&
							this.axisY2[A].crosshair.enabled &&
							this.axisY2[A].crosshair.renderLabel();
			}
		};
		v.prototype._plotAreaMouseMove = function (a, f) {
			if (this.isDrag && "none" !== this.plotInfo.axisPlacement) {
				var c = 0,
					b = 0,
					e = (c = null),
					e = 0 <= this.zoomType.indexOf("x"),
					h = 0 <= this.zoomType.indexOf("y"),
					A = this;
				"xySwapped" === this.plotInfo.axisPlacement &&
					((c = h), (h = e), (e = c));
				c = this.dragStartPoint.x - a;
				b = this.dragStartPoint.y - f;
				if (
					2 < Math.abs(c) &&
					8 > Math.abs(c) &&
					(this.panEnabled || this.zoomEnabled)
				) {
					this.toolTip.hide();
					this.toolTip &&
						this.toolTip.enabled &&
						this.toolTip.dispatchEvent(
							"hidden",
							{ chart: this, toolTip: this.toolTip },
							this.toolTip
						);
					for (var g = 0; g < this.axisX.length; g++)
						this.axisX[g].crosshair &&
							this.axisX[g].crosshair.enabled &&
							(this.axisX[g].crosshair.hide(),
							this.axisX[g].crosshair.dispatchEvent(
								"hidden",
								{ chart: this, axis: this.axisX[g].options },
								this.axisX[g].crosshair
							));
					for (g = 0; g < this.axisX2.length; g++)
						this.axisX2[g].crosshair &&
							this.axisX2[g].crosshair.enabled &&
							(this.axisX2[g].crosshair.hide(),
							this.axisX2[g].crosshair.dispatchEvent(
								"hidden",
								{ chart: this, axis: this.axisX2[g].options },
								this.axisX2[g].crosshair
							));
					for (g = 0; g < this.axisY.length; g++)
						this.axisY[g].crosshair &&
							this.axisY[g].crosshair.enabled &&
							(this.axisY[g].crosshair.hide(),
							this.axisY[g].crosshair.dispatchEvent(
								"hidden",
								{ chart: this, axis: this.axisY[g].options },
								this.axisY[g].crosshair
							));
					for (g = 0; g < this.axisY2.length; g++)
						this.axisY2[g].crosshair &&
							this.axisY2[g].crosshair.enabled &&
							(this.axisY2[g].crosshair.hide(),
							this.axisY2[g].crosshair.dispatchEvent(
								"hidden",
								{ chart: this, axis: this.axisY2[g].options },
								this.axisY2[g].crosshair
							));
				} else
					this.panEnabled ||
						this.zoomEnabled ||
						this.toolTip.mouseMoveHandler(a, f);
				if (
					(!e || 2 < Math.abs(c) || !h || 2 < Math.abs(b)) &&
					(this.panEnabled || this.zoomEnabled)
				)
					if (this.panEnabled)
						(e = {
							x1: e ? this.plotArea.x1 + c : this.plotArea.x1,
							y1: h ? this.plotArea.y1 + b : this.plotArea.y1,
							x2: e ? this.plotArea.x2 + c : this.plotArea.x2,
							y2: h ? this.plotArea.y2 + b : this.plotArea.y2,
						}),
							clearTimeout(A._panTimerId),
							(A._panTimerId = setTimeout(
								(function (b, c, e, h) {
									return function () {
										A._zoomPanToSelectedRegion(b, c, e, h, !0) &&
											(A._dispatchRangeEvent("rangeChanging", "pan"),
											A.stockChart &&
												A.stockChart.navigator &&
												A.stockChart.navigator.enabled &&
												((A.stockChart._rangeEventParameter.type =
													"rangeChanging"),
												A.stockChart.dispatchEvent(
													"rangeChanging",
													A.stockChart._rangeEventParameter,
													A.stockChart
												)),
											A.render(),
											A._dispatchRangeEvent("rangeChanged", "pan"),
											A.stockChart &&
												A.stockChart.navigator &&
												A.stockChart.navigator.enabled &&
												((A.stockChart._rangeEventParameter.type =
													"rangeChanged"),
												A.stockChart.dispatchEvent(
													"rangeChanged",
													A.stockChart._rangeEventParameter,
													A.stockChart
												)),
											(A.dragStartPoint.x = a),
											(A.dragStartPoint.y = f));
									};
								})(e.x1, e.y1, e.x2, e.y2),
								0
							));
					else if (this.zoomEnabled) {
						this.resetOverlayedCanvas();
						c = this.overlaidCanvasCtx.globalAlpha;
						this.overlaidCanvasCtx.fillStyle = "#A89896";
						var b = e ? this.dragStartPoint.x : this.plotArea.x1,
							g = h ? this.dragStartPoint.y : this.plotArea.y1,
							d = e
								? a - this.dragStartPoint.x
								: this.plotArea.x2 - this.plotArea.x1,
							m = h
								? f - this.dragStartPoint.y
								: this.plotArea.y2 - this.plotArea.y1;
						this.validateRegion(
							b,
							g,
							e ? a : this.plotArea.x2 - this.plotArea.x1,
							h ? f : this.plotArea.y2 - this.plotArea.y1,
							"xy" !== this.zoomType
						).isValid &&
							(this.resetOverlayedCanvas(),
							(this.overlaidCanvasCtx.fillStyle = "#99B2B5"));
						this.overlaidCanvasCtx.globalAlpha = 0.7;
						this.overlaidCanvasCtx.fillRect(b, g, d, m);
						this.overlaidCanvasCtx.globalAlpha = c;
					}
			} else if (
				(this.toolTip.mouseMoveHandler(a, f),
				"none" !== this.plotInfo.axisPlacement)
			) {
				if (this.axisX && 0 < this.axisX.length)
					for (e = 0; e < this.axisX.length; e++)
						this.axisX[e].crosshair &&
							this.axisX[e].crosshair.enabled &&
							this.axisX[e].renderCrosshair(a, f);
				if (this.axisX2 && 0 < this.axisX2.length)
					for (e = 0; e < this.axisX2.length; e++)
						this.axisX2[e].crosshair &&
							this.axisX2[e].crosshair.enabled &&
							this.axisX2[e].renderCrosshair(a, f);
				if (this.axisY && 0 < this.axisY.length)
					for (e = 0; e < this.axisY.length; e++)
						this.axisY[e].crosshair &&
							this.axisY[e].crosshair.enabled &&
							this.axisY[e].renderCrosshair(a, f);
				if (this.axisY2 && 0 < this.axisY2.length)
					for (e = 0; e < this.axisY2.length; e++)
						this.axisY2[e].crosshair &&
							this.axisY2[e].crosshair.enabled &&
							this.axisY2[e].renderCrosshair(a, f);
				if (this.axisX && 0 < this.axisX.length)
					for (e = 0; e < this.axisX.length; e++)
						this.axisX[e].crosshair &&
							this.axisX[e].crosshair.enabled &&
							this.axisX[e].crosshair.renderLabel();
				if (this.axisX2 && 0 < this.axisX2.length)
					for (e = 0; e < this.axisX2.length; e++)
						this.axisX2[e].crosshair &&
							this.axisX2[e].crosshair.enabled &&
							this.axisX2[e].crosshair.renderLabel();
				if (this.axisY && 0 < this.axisY.length)
					for (e = 0; e < this.axisY.length; e++)
						this.axisY[e].crosshair &&
							this.axisY[e].crosshair.enabled &&
							this.axisY[e].crosshair.renderLabel();
				if (this.axisY2 && 0 < this.axisY2.length)
					for (e = 0; e < this.axisY2.length; e++)
						this.axisY2[e].crosshair &&
							this.axisY2[e].crosshair.enabled &&
							this.axisY2[e].crosshair.renderLabel();
			}
		};
		v.prototype._zoomPanToSelectedRegion = function (a, f, c, b, e) {
			a = this.validateRegion(a, f, c, b, e);
			f = a.axesWithValidRange;
			c = a.axesRanges;
			if (a.isValid)
				for (b = 0; b < f.length; b++)
					(e = c[b]),
						f[b].setViewPortRange(e.val1, e.val2),
						this.syncCharts &&
							"y" != this.zoomType &&
							this.syncCharts(e.val1, e.val2),
						this.stockChart &&
							(this.stockChart._rangeEventParameter = {
								stockChart: this.stockChart,
								source: "chart",
								index: this.stockChart.charts.indexOf(this),
								minimum: e.val1,
								maximum: e.val2,
							});
			return a.isValid;
		};
		v.prototype.validateRegion = function (a, f, c, b, e) {
			e = e || !1;
			for (
				var h = 0 <= this.zoomType.indexOf("x"),
					A = 0 <= this.zoomType.indexOf("y"),
					g = !1,
					d = [],
					m = [],
					n = [],
					p = 0;
				p < this._axes.length;
				p++
			)
				(("axisX" === this._axes[p].type && h) ||
					("axisY" === this._axes[p].type && A)) &&
					m.push(this._axes[p]);
			for (A = 0; A < m.length; A++) {
				var p = m[A],
					h = !1,
					r = p.convertPixelToValue({ x: a, y: f }),
					k = p.convertPixelToValue({ x: c, y: b });
				if (r > k)
					var x = k,
						k = r,
						r = x;
				if (p.scaleBreaks)
					for (x = 0; !h && x < p.scaleBreaks._appliedBreaks.length; x++)
						h =
							p.scaleBreaks._appliedBreaks[x].startValue <= r &&
							p.scaleBreaks._appliedBreaks[x].endValue >= k;
				if (isFinite(p.dataInfo.minDiff))
					if (
						((x = p.getApparentDifference(r, k, null, !0)),
						!(
							h ||
							(!(
								this.panEnabled &&
								p.scaleBreaks &&
								p.scaleBreaks._appliedBreaks.length
							) &&
								((p.logarithmic && x < Math.pow(p.dataInfo.minDiff, 3)) ||
									(!p.logarithmic && x < 3 * Math.abs(p.dataInfo.minDiff)))) ||
							r < p.minimum ||
							k > p.maximum
						))
					)
						d.push(p), n.push({ val1: r, val2: k }), (g = !0);
					else if (!e) {
						g = !1;
						break;
					}
			}
			return { isValid: g, axesWithValidRange: d, axesRanges: n };
		};
		v.prototype.preparePlotArea = function () {
			var a = this.plotArea;
			!t && (0 < a.x1 || 0 < a.y1) && a.ctx.translate(a.x1, a.y1);
			if (
				(this.axisX[0] || this.axisX2[0]) &&
				(this.axisY[0] || this.axisY2[0])
			) {
				var f = this.axisX[0]
					? this.axisX[0].lineCoordinates
					: this.axisX2[0].lineCoordinates;
				if (this.axisY && 0 < this.axisY.length && this.axisY[0]) {
					var c = this.axisY[0];
					a.x1 = f.x1 < f.x2 ? f.x1 : c.lineCoordinates.x1;
					a.y1 = f.y1 < c.lineCoordinates.y1 ? f.y1 : c.lineCoordinates.y1;
					a.x2 = f.x2 > c.lineCoordinates.x2 ? f.x2 : c.lineCoordinates.x2;
					a.y2 = f.y2 > f.y1 ? f.y2 : c.lineCoordinates.y2;
					a.width = a.x2 - a.x1;
					a.height = a.y2 - a.y1;
				}
				this.axisY2 &&
					0 < this.axisY2.length &&
					this.axisY2[0] &&
					((c = this.axisY2[0]),
					(a.x1 = f.x1 < f.x2 ? f.x1 : c.lineCoordinates.x1),
					(a.y1 = f.y1 < c.lineCoordinates.y1 ? f.y1 : c.lineCoordinates.y1),
					(a.x2 = f.x2 > c.lineCoordinates.x2 ? f.x2 : c.lineCoordinates.x2),
					(a.y2 = f.y2 > f.y1 ? f.y2 : c.lineCoordinates.y2),
					(a.width = a.x2 - a.x1),
					(a.height = a.y2 - a.y1));
			} else
				(f = this.layoutManager.getFreeSpace()),
					(a.x1 = f.x1),
					(a.x2 = f.x2),
					(a.y1 = f.y1),
					(a.y2 = f.y2),
					(a.width = f.width),
					(a.height = f.height);
			t ||
				((a.canvas.width = a.width),
				(a.canvas.height = a.height),
				(a.canvas.style.left = a.x1 + "px"),
				(a.canvas.style.top = a.y1 + "px"),
				(0 < a.x1 || 0 < a.y1) && a.ctx.translate(-a.x1, -a.y1));
			a.layoutManager = new Ka(a.x1, a.y1, a.x2, a.y2, 2);
		};
		v.prototype.renderIndexLabels = function (a) {
			var f = a || this.plotArea.ctx,
				c = this.plotArea,
				b = 0,
				e = 0,
				h = 0,
				A = (h = e = 0),
				d = 0,
				M = (b = 0),
				m = 0;
			for (a = 0; a < this._indexLabels.length; a++) {
				var n = this._indexLabels[a],
					p = n.chartType.toLowerCase(),
					r,
					k,
					A = oa("indexLabelFontColor", n.dataPoint, n.dataSeries),
					x = oa("indexLabelFontSize", n.dataPoint, n.dataSeries),
					d = oa("indexLabelFontFamily", n.dataPoint, n.dataSeries),
					M = oa("indexLabelFontStyle", n.dataPoint, n.dataSeries),
					m = oa("indexLabelFontWeight", n.dataPoint, n.dataSeries),
					s = oa("indexLabelBackgroundColor", n.dataPoint, n.dataSeries);
				r = oa("indexLabelMaxWidth", n.dataPoint, n.dataSeries);
				k = oa("indexLabelWrap", n.dataPoint, n.dataSeries);
				var L = oa("indexLabelLineDashType", n.dataPoint, n.dataSeries),
					u = oa("indexLabelLineColor", n.dataPoint, n.dataSeries),
					B = g(n.dataPoint.indexLabelLineThickness)
						? g(n.dataSeries.options.indexLabelLineThickness)
							? 0
							: n.dataSeries.options.indexLabelLineThickness
						: n.dataPoint.indexLabelLineThickness,
					b =
						0 < B
							? Math.min(
									10,
									("normal" === this.plotInfo.axisPlacement
										? this.plotArea.height
										: this.plotArea.width) << 0
							  )
							: 0,
					l = { percent: null, total: null },
					q = null;
				if (
					0 <= n.dataSeries.type.indexOf("stacked") ||
					"pie" === n.dataSeries.type ||
					"doughnut" === n.dataSeries.type
				)
					l = this.getPercentAndTotal(n.dataSeries, n.dataPoint);
				if (n.dataSeries.indexLabelFormatter || n.dataPoint.indexLabelFormatter)
					q = {
						chart: this,
						dataSeries: n.dataSeries,
						dataPoint: n.dataPoint,
						index: n.indexKeyword,
						total: l.total,
						percent: l.percent,
					};
				var I = n.dataPoint.indexLabelFormatter
					? n.dataPoint.indexLabelFormatter(q)
					: n.dataPoint.indexLabel
					? this.replaceKeywordsWithValue(
							n.dataPoint.indexLabel,
							n.dataPoint,
							n.dataSeries,
							null,
							n.indexKeyword
					  )
					: n.dataSeries.indexLabelFormatter
					? n.dataSeries.indexLabelFormatter(q)
					: n.dataSeries.indexLabel
					? this.replaceKeywordsWithValue(
							n.dataSeries.indexLabel,
							n.dataPoint,
							n.dataSeries,
							null,
							n.indexKeyword
					  )
					: null;
				if (null !== I && "" !== I) {
					var l = oa("indexLabelPlacement", n.dataPoint, n.dataSeries),
						q = oa("indexLabelOrientation", n.dataPoint, n.dataSeries),
						C = oa("indexLabelTextAlign", n.dataPoint, n.dataSeries),
						w = n.direction,
						e = n.dataSeries.axisX,
						h = n.dataSeries.axisY,
						v = !1,
						s = new na(f, {
							x: 0,
							y: 0,
							maxWidth: r ? r : 0.5 * this.width,
							maxHeight: k ? 5 * x : 1.5 * x,
							angle: "horizontal" === q ? 0 : -90,
							text: I,
							padding: 0,
							backgroundColor: s,
							textAlign: C,
							fontSize: x,
							fontFamily: d,
							fontWeight: m,
							fontColor: A,
							fontStyle: M,
							textBaseline: "middle",
						});
					s.measureText();
					n.dataSeries.indexLabelMaxWidth = s.maxWidth;
					if ("stackedarea100" === p) {
						if (
							n.point.x < c.x1 ||
							n.point.x > c.x2 ||
							n.point.y < c.y1 - 1 ||
							n.point.y > c.y2 + 1
						)
							continue;
					} else if ("rangearea" === p || "rangesplinearea" === p) {
						if (
							n.dataPoint.x < e.viewportMinimum ||
							n.dataPoint.x > e.viewportMaximum ||
							Math.max.apply(null, n.dataPoint.y) < h.viewportMinimum ||
							Math.min.apply(null, n.dataPoint.y) > h.viewportMaximum
						)
							continue;
					} else if (
						0 <= p.indexOf("line") ||
						0 <= p.indexOf("area") ||
						0 <= p.indexOf("bubble") ||
						0 <= p.indexOf("scatter")
					) {
						if (
							n.dataPoint.x < e.viewportMinimum ||
							n.dataPoint.x > e.viewportMaximum ||
							n.dataPoint.y < h.viewportMinimum ||
							n.dataPoint.y > h.viewportMaximum
						)
							continue;
					} else if (
						0 <= p.indexOf("column") ||
						"waterfall" === p ||
						("error" === p && !n.axisSwapped)
					) {
						if (
							n.dataPoint.x < e.viewportMinimum ||
							n.dataPoint.x > e.viewportMaximum ||
							n.bounds.y1 > c.y2 ||
							n.bounds.y2 < c.y1
						)
							continue;
					} else if (0 <= p.indexOf("bar") || "error" === p) {
						if (
							n.dataPoint.x < e.viewportMinimum ||
							n.dataPoint.x > e.viewportMaximum ||
							n.bounds.x1 > c.x2 ||
							n.bounds.x2 < c.x1
						)
							continue;
					} else if ("candlestick" === p || "ohlc" === p) {
						if (
							n.dataPoint.x < e.viewportMinimum ||
							n.dataPoint.x > e.viewportMaximum ||
							Math.max.apply(null, n.dataPoint.y) < h.viewportMinimum ||
							Math.min.apply(null, n.dataPoint.y) > h.viewportMaximum
						)
							continue;
					} else if (
						n.dataPoint.x < e.viewportMinimum ||
						n.dataPoint.x > e.viewportMaximum
					)
						continue;
					A = d = 2;
					"horizontal" === q
						? ((M = s.width), (m = s.height))
						: ((m = s.width), (M = s.height));
					if ("normal" === this.plotInfo.axisPlacement) {
						if (0 <= p.indexOf("line") || 0 <= p.indexOf("area"))
							(l = "auto"), (d = 4);
						else if (0 <= p.indexOf("stacked")) "auto" === l && (l = "inside");
						else if ("bubble" === p || "scatter" === p) l = "inside";
						r = n.point.x - ("horizontal" === q ? M / 2 : M / 2 - x / 2);
						"inside" !== l
							? ((e = c.y1),
							  (h = c.y2),
							  0 < w
									? ((k =
											n.point.y + ("horizontal" === q ? x / 2 : 0) - m - d - b),
									  k < e &&
											((k =
												"auto" === l
													? Math.max(n.point.y, e) + x / 2 + d
													: e + x / 2 + d),
											(v = k + m > n.point.y)))
									: ((k = n.point.y + x / 2 + d + b),
									  k > h - m &&
											((k =
												"auto" === l
													? Math.min(n.point.y, h) + x / 2 - m - d
													: h + x / 2 - m),
											(v = k < n.point.y))))
							: ((e = Math.max(n.bounds.y1, c.y1)),
							  (h = Math.min(n.bounds.y2, c.y2 - m + x / 2)),
							  (b =
									0 <= p.indexOf("range") || "error" === p
										? 0 < w
											? Math.max(n.bounds.y1, c.y1) + x / 2 + d
											: Math.min(n.bounds.y2, c.y2) + x / 2 - m + d
										: (Math.max(n.bounds.y1, c.y1) +
												Math.min(n.bounds.y2, c.y2)) /
												2 -
										  m / 2 +
										  x / 2 +
										  ("horizontal" === q ? d : 0)),
							  0 < w
									? ((k = Math.max(n.point.y, b)),
									  k < e &&
											("bubble" === p || "scatter" === p) &&
											(k = Math.max(n.point.y - m - d, c.y1 + d)))
									: ((k = Math.min(n.point.y, b)),
									  k > h - m - d &&
											("bubble" === p || "scatter" === p) &&
											(k = Math.min(n.point.y + d, c.y2 - m - d))),
							  (k = Math.min(k, h)));
					} else
						0 <= p.indexOf("line") ||
						0 <= p.indexOf("area") ||
						0 <= p.indexOf("scatter")
							? ((l = "auto"), (A = 4))
							: 0 <= p.indexOf("stacked")
							? "auto" === l && (l = "inside")
							: "bubble" === p && (l = "inside"),
							(k = n.point.y + x / 2 - m / 2 + d),
							"inside" !== l
								? ((e = c.x1),
								  (h = c.x2),
								  0 > w
										? ((r =
												n.point.x -
												("horizontal" === q ? M : M - x / 2) -
												A -
												b),
										  r < e &&
												((r =
													"auto" === l ? Math.max(n.point.x, e) + A : e + A),
												(v = r + M > n.point.x)))
										: ((r =
												n.point.x + ("horizontal" === q ? 0 : x / 2) + A + b),
										  r > h - M - A - b &&
												((r =
													"auto" === l
														? Math.min(n.point.x, h) -
														  ("horizontal" === q ? M : M / 2) -
														  A
														: h - M - A),
												(v = r < n.point.x))))
								: ((e = Math.max(n.bounds.x1, c.x1)),
								  Math.min(n.bounds.x2, c.x2),
								  (b =
										0 <= p.indexOf("range") || "error" === p
											? 0 > w
												? Math.max(n.bounds.x1, c.x1) + x / 2 + A
												: Math.min(n.bounds.x2, c.x2) -
												  M / 2 -
												  A +
												  ("horizontal" === q ? 0 : x / 2)
											: (Math.max(n.bounds.x1, c.x1) +
													Math.min(n.bounds.x2, c.x2)) /
													2 +
											  ("horizontal" === q ? 0 : x / 2)),
								  (r =
										0 > w
											? Math.max(n.point.x, b) -
											  ("horizontal" === q ? M / 2 : 0)
											: Math.min(n.point.x, b) - M / 2),
								  (r = Math.max(r, e)));
					"vertical" === q && (k += m - x / 2);
					s.x = r;
					s.y = k;
					s.render(!0);
					B &&
						"inside" !== l &&
						((0 > p.indexOf("bar") &&
							("error" !== p || !n.axisSwapped) &&
							n.point.x > c.x1 &&
							n.point.x < c.x2) ||
							!v) &&
						((0 > p.indexOf("column") &&
							("error" !== p || n.axisSwapped) &&
							n.point.y > c.y1 &&
							n.point.y < c.y2) ||
							!v) &&
						((f.lineWidth = B),
						(f.strokeStyle = u ? u : "gray"),
						f.setLineDash && f.setLineDash(S(L, B)),
						f.beginPath(),
						f.moveTo(n.point.x, n.point.y),
						0 <= p.indexOf("bar") || ("error" === p && n.axisSwapped)
							? f.lineTo(
									r +
										(0 < n.direction ? -A : M + A) +
										("vertical" === q ? -x / 2 : 0),
									k + ("vertical" === q ? -m / 2 : m / 2 - x / 2) - d
							  )
							: 0 <= p.indexOf("column") || ("error" === p && !n.axisSwapped)
							? f.lineTo(
									r + M / 2 - ("horizontal" === q ? 0 : x / 2),
									k +
										("vertical" === q
											? (k - m < n.point.y ? 0 : -m) + d
											: (k - x / 2 < n.point.y ? m : 0) - x / 2)
							  )
							: f.lineTo(
									r + M / 2 - ("horizontal" === q ? 0 : x / 2),
									k +
										("vertical" === q
											? k - m < n.point.y
												? 0
												: -m
											: (k - x / 2 < n.point.y ? m : 0) - x / 2)
							  ),
						f.stroke());
				}
			}
			f = {
				source: f,
				dest: this.plotArea.ctx,
				animationCallback: z.fadeInAnimation,
				easingFunction: z.easing.easeInQuad,
				animationBase: 0,
				startTimePercent: 0.7,
			};
			for (a = 0; a < this._indexLabels.length; a++)
				(n = this._indexLabels[a]),
					(s = oa("indexLabelBackgroundColor", n.dataPoint, n.dataSeries)),
					(n.dataSeries.indexLabelBackgroundColor = g(s)
						? t
							? "transparent"
							: null
						: s);
			return f;
		};
		v.prototype.renderLine = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = this._eventManager.ghostCtx;
				c.save();
				var e = this.plotArea;
				c.beginPath();
				c.rect(e.x1, e.y1, e.width, e.height);
				c.clip();
				for (var h = [], A, g = 0; g < a.dataSeriesIndexes.length; g++) {
					var d = a.dataSeriesIndexes[g],
						m = this.data[d];
					c.lineWidth = m.lineThickness;
					var n = m.dataPoints,
						p = "solid";
					if (c.setLineDash) {
						var r = S(m.nullDataLineDashType, m.lineThickness),
							p = m.lineDashType,
							k = S(p, m.lineThickness);
						c.setLineDash(k);
					}
					var x = m.id;
					this._eventManager.objectMap[x] = {
						objectType: "dataSeries",
						dataSeriesIndex: d,
					};
					x = U(x);
					b.strokeStyle = x;
					b.lineWidth = 0 < m.lineThickness ? Math.max(m.lineThickness, 4) : 0;
					var x = m._colorSet,
						l =
							(x =
							m.lineColor =
								m.options.lineColor ? m.options.lineColor : x[0]);
					c.strokeStyle = x;
					var L = !0,
						u = 0,
						B,
						q;
					c.beginPath();
					if (0 < n.length) {
						for (var ca = !1, u = 0; u < n.length; u++)
							if (
								((B = n[u].x.getTime ? n[u].x.getTime() : n[u].x),
								!(
									B < a.axisX.dataInfo.viewPortMin ||
									(B > a.axisX.dataInfo.viewPortMax &&
										(!m.connectNullData || !ca))
								))
							)
								if ("number" !== typeof n[u].y)
									0 < u &&
										!(m.connectNullData || ca || L) &&
										(c.stroke(), t && b.stroke()),
										(ca = !0);
								else {
									B = a.axisX.convertValueToPixel(B);
									q = a.axisY.convertValueToPixel(n[u].y);
									var I = m.dataPointIds[u];
									this._eventManager.objectMap[I] = {
										id: I,
										objectType: "dataPoint",
										dataSeriesIndex: d,
										dataPointIndex: u,
										x1: B,
										y1: q,
									};
									L || ca
										? (!L && m.connectNullData
												? (c.setLineDash &&
														(m.options.nullDataLineDashType ||
															(p === m.lineDashType &&
																m.lineDashType !== m.nullDataLineDashType)) &&
														(c.stroke(),
														c.beginPath(),
														c.moveTo(A.x, A.y),
														(p = m.nullDataLineDashType),
														c.setLineDash(r)),
												  c.lineTo(B, q),
												  t && b.lineTo(B, q))
												: (c.beginPath(),
												  c.moveTo(B, q),
												  t && (b.beginPath(), b.moveTo(B, q))),
										  (ca = L = !1))
										: (c.lineTo(B, q),
										  t && b.lineTo(B, q),
										  0 == u % 500 &&
												(c.stroke(),
												c.beginPath(),
												c.moveTo(B, q),
												t && (b.stroke(), b.beginPath(), b.moveTo(B, q))));
									A = { x: B, y: q };
									u < n.length - 1 &&
										(l !== (n[u].lineColor || x) ||
											p !== (n[u].lineDashType || m.lineDashType)) &&
										(c.stroke(),
										c.beginPath(),
										c.moveTo(B, q),
										(l = n[u].lineColor || x),
										(c.strokeStyle = l),
										c.setLineDash &&
											(n[u].lineDashType
												? ((p = n[u].lineDashType),
												  c.setLineDash(S(p, m.lineThickness)))
												: ((p = m.lineDashType), c.setLineDash(k))));
									if (0 < n[u].markerSize || 0 < m.markerSize) {
										var C = m.getMarkerProperties(u, B, q, c);
										h.push(C);
										I = U(I);
										t &&
											h.push({
												x: B,
												y: q,
												ctx: b,
												type: C.type,
												size: C.size,
												color: I,
												borderColor: I,
												borderThickness: C.borderThickness,
											});
									}
									(n[u].indexLabel ||
										m.indexLabel ||
										n[u].indexLabelFormatter ||
										m.indexLabelFormatter) &&
										this._indexLabels.push({
											chartType: "line",
											dataPoint: n[u],
											dataSeries: m,
											point: { x: B, y: q },
											direction: 0 > n[u].y === a.axisY.reversed ? 1 : -1,
											color: x,
										});
								}
						c.stroke();
						t && b.stroke();
					}
				}
				s.drawMarkers(h);
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(e.x1, e.y1, e.width, e.height),
					b.beginPath());
				c.restore();
				c.beginPath();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.xClipAnimation,
					easingFunction: z.easing.linear,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderStepLine = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = this._eventManager.ghostCtx;
				c.save();
				var e = this.plotArea;
				c.beginPath();
				c.rect(e.x1, e.y1, e.width, e.height);
				c.clip();
				for (var h = [], A, g = 0; g < a.dataSeriesIndexes.length; g++) {
					var d = a.dataSeriesIndexes[g],
						m = this.data[d];
					c.lineWidth = m.lineThickness;
					var n = m.dataPoints,
						p = "solid";
					if (c.setLineDash) {
						var r = S(m.nullDataLineDashType, m.lineThickness),
							p = m.lineDashType,
							k = S(p, m.lineThickness);
						c.setLineDash(k);
					}
					var x = m.id;
					this._eventManager.objectMap[x] = {
						objectType: "dataSeries",
						dataSeriesIndex: d,
					};
					x = U(x);
					b.strokeStyle = x;
					b.lineWidth = 0 < m.lineThickness ? Math.max(m.lineThickness, 4) : 0;
					var x = m._colorSet,
						l =
							(x =
							m.lineColor =
								m.options.lineColor ? m.options.lineColor : x[0]);
					c.strokeStyle = x;
					var L = !0,
						u = 0,
						B,
						q;
					c.beginPath();
					if (0 < n.length) {
						for (var ca = !1, u = 0; u < n.length; u++)
							if (
								((B = n[u].getTime ? n[u].x.getTime() : n[u].x),
								!(
									B < a.axisX.dataInfo.viewPortMin ||
									(B > a.axisX.dataInfo.viewPortMax &&
										(!m.connectNullData || !ca))
								))
							)
								if ("number" !== typeof n[u].y)
									0 < u &&
										!(m.connectNullData || ca || L) &&
										(c.stroke(), t && b.stroke()),
										(ca = !0);
								else {
									var I = q;
									B = a.axisX.convertValueToPixel(B);
									q = a.axisY.convertValueToPixel(n[u].y);
									var C = m.dataPointIds[u];
									this._eventManager.objectMap[C] = {
										id: C,
										objectType: "dataPoint",
										dataSeriesIndex: d,
										dataPointIndex: u,
										x1: B,
										y1: q,
									};
									L || ca
										? (!L && m.connectNullData
												? (c.setLineDash &&
														(m.options.nullDataLineDashType ||
															(p === m.lineDashType &&
																m.lineDashType !== m.nullDataLineDashType)) &&
														(c.stroke(),
														c.beginPath(),
														c.moveTo(A.x, A.y),
														(p = m.nullDataLineDashType),
														c.setLineDash(r)),
												  c.lineTo(B, I),
												  c.lineTo(B, q),
												  t && (b.lineTo(B, I), b.lineTo(B, q)))
												: (c.beginPath(),
												  c.moveTo(B, q),
												  t && (b.beginPath(), b.moveTo(B, q))),
										  (ca = L = !1))
										: (c.lineTo(B, I),
										  t && b.lineTo(B, I),
										  c.lineTo(B, q),
										  t && b.lineTo(B, q),
										  0 == u % 500 &&
												(c.stroke(),
												c.beginPath(),
												c.moveTo(B, q),
												t && (b.stroke(), b.beginPath(), b.moveTo(B, q))));
									A = { x: B, y: q };
									u < n.length - 1 &&
										(l !== (n[u].lineColor || x) ||
											p !== (n[u].lineDashType || m.lineDashType)) &&
										(c.stroke(),
										c.beginPath(),
										c.moveTo(B, q),
										(l = n[u].lineColor || x),
										(c.strokeStyle = l),
										c.setLineDash &&
											(n[u].lineDashType
												? ((p = n[u].lineDashType),
												  c.setLineDash(S(p, m.lineThickness)))
												: ((p = m.lineDashType), c.setLineDash(k))));
									if (0 < n[u].markerSize || 0 < m.markerSize)
										(I = m.getMarkerProperties(u, B, q, c)),
											h.push(I),
											(C = U(C)),
											t &&
												h.push({
													x: B,
													y: q,
													ctx: b,
													type: I.type,
													size: I.size,
													color: C,
													borderColor: C,
													borderThickness: I.borderThickness,
												});
									(n[u].indexLabel ||
										m.indexLabel ||
										n[u].indexLabelFormatter ||
										m.indexLabelFormatter) &&
										this._indexLabels.push({
											chartType: "stepLine",
											dataPoint: n[u],
											dataSeries: m,
											point: { x: B, y: q },
											direction: 0 > n[u].y === a.axisY.reversed ? 1 : -1,
											color: x,
										});
								}
						c.stroke();
						t && b.stroke();
					}
				}
				s.drawMarkers(h);
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(e.x1, e.y1, e.width, e.height),
					b.beginPath());
				c.restore();
				c.beginPath();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.xClipAnimation,
					easingFunction: z.easing.linear,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderSpline = function (a) {
			function f(a) {
				a = w(a, 2);
				if (0 < a.length) {
					b.beginPath();
					t && e.beginPath();
					b.moveTo(a[0].x, a[0].y);
					a[0].newStrokeStyle && (b.strokeStyle = a[0].newStrokeStyle);
					a[0].newLineDashArray && b.setLineDash(a[0].newLineDashArray);
					t && e.moveTo(a[0].x, a[0].y);
					for (var c = 0; c < a.length - 3; c += 3)
						if (
							(b.bezierCurveTo(
								a[c + 1].x,
								a[c + 1].y,
								a[c + 2].x,
								a[c + 2].y,
								a[c + 3].x,
								a[c + 3].y
							),
							t &&
								e.bezierCurveTo(
									a[c + 1].x,
									a[c + 1].y,
									a[c + 2].x,
									a[c + 2].y,
									a[c + 3].x,
									a[c + 3].y
								),
							(0 < c && 0 === c % 3e3) ||
								a[c + 3].newStrokeStyle ||
								a[c + 3].newLineDashArray)
						)
							b.stroke(),
								b.beginPath(),
								b.moveTo(a[c + 3].x, a[c + 3].y),
								a[c + 3].newStrokeStyle &&
									(b.strokeStyle = a[c + 3].newStrokeStyle),
								a[c + 3].newLineDashArray &&
									b.setLineDash(a[c + 3].newLineDashArray),
								t &&
									(e.stroke(), e.beginPath(), e.moveTo(a[c + 3].x, a[c + 3].y));
					b.stroke();
					t && e.stroke();
				}
			}
			var c = a.targetCanvasCtx || this.plotArea.ctx,
				b = t ? this._preRenderCtx : c;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var e = this._eventManager.ghostCtx;
				b.save();
				var h = this.plotArea;
				b.beginPath();
				b.rect(h.x1, h.y1, h.width, h.height);
				b.clip();
				for (var A = [], g = 0; g < a.dataSeriesIndexes.length; g++) {
					var d = a.dataSeriesIndexes[g],
						m = this.data[d];
					b.lineWidth = m.lineThickness;
					var n = m.dataPoints,
						p = "solid";
					if (b.setLineDash) {
						var r = S(m.nullDataLineDashType, m.lineThickness),
							p = m.lineDashType,
							k = S(p, m.lineThickness);
						b.setLineDash(k);
					}
					var x = m.id;
					this._eventManager.objectMap[x] = {
						objectType: "dataSeries",
						dataSeriesIndex: d,
					};
					x = U(x);
					e.strokeStyle = x;
					e.lineWidth = 0 < m.lineThickness ? Math.max(m.lineThickness, 4) : 0;
					var x = m._colorSet,
						l =
							(x =
							m.lineColor =
								m.options.lineColor ? m.options.lineColor : x[0]);
					b.strokeStyle = x;
					var L = 0,
						u,
						B,
						q = [];
					b.beginPath();
					if (0 < n.length)
						for (B = !1, L = 0; L < n.length; L++)
							if (
								((u = n[L].getTime ? n[L].x.getTime() : n[L].x),
								!(
									u < a.axisX.dataInfo.viewPortMin ||
									(u > a.axisX.dataInfo.viewPortMax &&
										(!m.connectNullData || !B))
								))
							)
								if ("number" !== typeof n[L].y)
									0 < L &&
										!B &&
										(m.connectNullData
											? b.setLineDash &&
											  0 < q.length &&
											  (m.options.nullDataLineDashType ||
													!n[L - 1].lineDashType) &&
											  ((q[q.length - 1].newLineDashArray = r),
											  (p = m.nullDataLineDashType))
											: (f(q), (q = []))),
										(B = !0);
								else {
									u = a.axisX.convertValueToPixel(u);
									B = a.axisY.convertValueToPixel(n[L].y);
									var ca = m.dataPointIds[L];
									this._eventManager.objectMap[ca] = {
										id: ca,
										objectType: "dataPoint",
										dataSeriesIndex: d,
										dataPointIndex: L,
										x1: u,
										y1: B,
									};
									q[q.length] = { x: u, y: B };
									L < n.length - 1 &&
										(l !== (n[L].lineColor || x) ||
											p !== (n[L].lineDashType || m.lineDashType)) &&
										((l = n[L].lineColor || x),
										(q[q.length - 1].newStrokeStyle = l),
										b.setLineDash &&
											(n[L].lineDashType
												? ((p = n[L].lineDashType),
												  (q[q.length - 1].newLineDashArray = S(
														p,
														m.lineThickness
												  )))
												: ((p = m.lineDashType),
												  (q[q.length - 1].newLineDashArray = k))));
									if (0 < n[L].markerSize || 0 < m.markerSize) {
										var I = m.getMarkerProperties(L, u, B, b);
										A.push(I);
										ca = U(ca);
										t &&
											A.push({
												x: u,
												y: B,
												ctx: e,
												type: I.type,
												size: I.size,
												color: ca,
												borderColor: ca,
												borderThickness: I.borderThickness,
											});
									}
									(n[L].indexLabel ||
										m.indexLabel ||
										n[L].indexLabelFormatter ||
										m.indexLabelFormatter) &&
										this._indexLabels.push({
											chartType: "spline",
											dataPoint: n[L],
											dataSeries: m,
											point: { x: u, y: B },
											direction: 0 > n[L].y === a.axisY.reversed ? 1 : -1,
											color: x,
										});
									B = !1;
								}
					f(q);
				}
				s.drawMarkers(A);
				t &&
					(c.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(b.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					b.clearRect(h.x1, h.y1, h.width, h.height),
					e.beginPath());
				b.restore();
				b.beginPath();
				return {
					source: c,
					dest: this.plotArea.ctx,
					animationCallback: z.xClipAnimation,
					easingFunction: z.easing.linear,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderColumn = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = null,
					e = this.plotArea,
					h = 0,
					g,
					d,
					M,
					m = a.axisY.convertValueToPixel(
						a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
					),
					h = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1,
					n = this.options.dataPointMaxWidth
						? this.dataPointMaxWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: Math.min(
								0.15 * this.width,
								0.9 * (this.plotArea.width / a.plotType.totalDataSeries)
						  ) << 0,
					p = a.axisX.dataInfo.minDiff;
				isFinite(p) || (p = 0.3 * Math.abs(a.axisX.range));
				p = this.dataPointWidth = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.9 *
							((e.width *
								(a.axisX.logarithmic
									? Math.log(p) / Math.log(a.axisX.range)
									: Math.abs(p) / Math.abs(a.axisX.range))) /
								a.plotType.totalDataSeries)) <<
					  0;
				this.dataPointMaxWidth &&
					h > n &&
					(h = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						n
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					n < h &&
					(n = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						h
					));
				p < h && (p = h);
				p > n && (p = n);
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(e.x1, e.y1, e.width, e.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.clip());
				for (n = 0; n < a.dataSeriesIndexes.length; n++) {
					var r = a.dataSeriesIndexes[n],
						k = this.data[r],
						x = k.dataPoints;
					if (0 < x.length)
						for (
							var s = 5 < p && k.bevelEnabled ? !0 : !1, h = 0;
							h < x.length;
							h++
						)
							if (
								(x[h].getTime ? (M = x[h].x.getTime()) : (M = x[h].x),
								!(
									M < a.axisX.dataInfo.viewPortMin ||
									M > a.axisX.dataInfo.viewPortMax
								) && "number" === typeof x[h].y)
							) {
								g = a.axisX.convertValueToPixel(M);
								d = a.axisY.convertValueToPixel(x[h].y);
								g = a.axisX.reversed
									? (g +
											(a.plotType.totalDataSeries * p) / 2 -
											(a.previousDataSeriesCount + n) * p) <<
									  0
									: (g -
											(a.plotType.totalDataSeries * p) / 2 +
											(a.previousDataSeriesCount + n) * p) <<
									  0;
								var q = a.axisX.reversed ? (g - p) << 0 : (g + p) << 0,
									u;
								0 <= x[h].y ? (u = m) : ((u = d), (d = m));
								d > u && ((b = d), (d = u), (u = b));
								b = x[h].color
									? x[h].color
									: k._colorSet[h % k._colorSet.length];
								ea(
									c,
									g,
									d,
									q,
									u,
									b,
									0,
									null,
									s && 0 <= x[h].y,
									0 > x[h].y && s,
									!1,
									!1,
									k.fillOpacity
								);
								b = k.dataPointIds[h];
								this._eventManager.objectMap[b] = {
									id: b,
									objectType: "dataPoint",
									dataSeriesIndex: r,
									dataPointIndex: h,
									x1: g,
									y1: d,
									x2: q,
									y2: u,
								};
								b = U(b);
								t &&
									ea(
										this._eventManager.ghostCtx,
										g,
										d,
										q,
										u,
										b,
										0,
										null,
										!1,
										!1,
										!1,
										!1
									);
								(x[h].indexLabel ||
									k.indexLabel ||
									x[h].indexLabelFormatter ||
									k.indexLabelFormatter) &&
									this._indexLabels.push({
										chartType: "column",
										dataPoint: x[h],
										dataSeries: k,
										point: {
											x: g + (q - g) / 2,
											y: 0 > x[h].y === a.axisY.reversed ? d : u,
										},
										direction: 0 > x[h].y === a.axisY.reversed ? 1 : -1,
										bounds: {
											x1: g,
											y1: Math.min(d, u),
											x2: q,
											y2: Math.max(d, u),
										},
										color: b,
									});
							}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.yScaleAnimation,
					easingFunction: z.easing.easeOutQuart,
					animationBase:
						m < a.axisY.bounds.y1
							? a.axisY.bounds.y1
							: m > a.axisY.bounds.y2
							? a.axisY.bounds.y2
							: m,
				};
			}
		};
		v.prototype.renderStackedColumn = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = null,
					e = this.plotArea,
					h = [],
					g = [],
					d = [],
					M = [],
					m = 0,
					n,
					p,
					r = a.axisY.convertValueToPixel(
						a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
					),
					m = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1;
				n = this.options.dataPointMaxWidth
					? this.dataPointMaxWidth
					: this.options.dataPointWidth
					? this.dataPointWidth
					: (0.15 * this.width) << 0;
				var k = a.axisX.dataInfo.minDiff;
				isFinite(k) || (k = 0.3 * Math.abs(a.axisX.range));
				k = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.9 *
							((e.width *
								(a.axisX.logarithmic
									? Math.log(k) / Math.log(a.axisX.range)
									: Math.abs(k) / Math.abs(a.axisX.range))) /
								a.plotType.plotUnits.length)) <<
					  0;
				this.dataPointMaxWidth &&
					m > n &&
					(m = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						n
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					n < m &&
					(n = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						m
					));
				k < m && (k = m);
				k > n && (k = n);
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(e.x1, e.y1, e.width, e.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.clip());
				for (var x = 0; x < a.dataSeriesIndexes.length; x++) {
					var q = a.dataSeriesIndexes[x],
						s = this.data[q],
						u = s.dataPoints;
					if (0 < u.length) {
						var l = 5 < k && s.bevelEnabled ? !0 : !1;
						c.strokeStyle = "#4572A7 ";
						for (m = 0; m < u.length; m++)
							if (
								((b = u[m].x.getTime ? u[m].x.getTime() : u[m].x),
								!(
									b < a.axisX.dataInfo.viewPortMin ||
									b > a.axisX.dataInfo.viewPortMax
								) && "number" === typeof u[m].y)
							) {
								n = a.axisX.convertValueToPixel(b);
								var F =
										(n - (a.plotType.plotUnits.length * k) / 2 + a.index * k) <<
										0,
									ca = (F + k) << 0,
									I;
								if (
									a.axisY.logarithmic ||
									(a.axisY.scaleBreaks &&
										0 < a.axisY.scaleBreaks._appliedBreaks.length &&
										0 < u[m].y)
								)
									(d[b] = u[m].y + (d[b] ? d[b] : 0)),
										0 < d[b] &&
											((p = a.axisY.convertValueToPixel(d[b])),
											(I = "undefined" !== typeof h[b] ? h[b] : r),
											(h[b] = p));
								else if (
									a.axisY.scaleBreaks &&
									0 < a.axisY.scaleBreaks._appliedBreaks.length &&
									0 >= u[m].y
								)
									(M[b] = u[m].y + (M[b] ? M[b] : 0)),
										(I = a.axisY.convertValueToPixel(M[b])),
										(p = "undefined" !== typeof g[b] ? g[b] : r),
										(g[b] = I);
								else if (
									((p = a.axisY.convertValueToPixel(u[m].y)), 0 <= u[m].y)
								) {
									var C = "undefined" !== typeof h[b] ? h[b] : 0;
									p -= C;
									I = r - C;
									h[b] = C + (I - p);
								} else
									(C = g[b] ? g[b] : 0),
										(I = p + C),
										(p = r + C),
										(g[b] = C + (I - p));
								b = u[m].color
									? u[m].color
									: s._colorSet[m % s._colorSet.length];
								ea(
									c,
									F,
									p,
									ca,
									I,
									b,
									0,
									null,
									l && 0 <= u[m].y,
									0 > u[m].y && l,
									!1,
									!1,
									s.fillOpacity
								);
								b = s.dataPointIds[m];
								this._eventManager.objectMap[b] = {
									id: b,
									objectType: "dataPoint",
									dataSeriesIndex: q,
									dataPointIndex: m,
									x1: F,
									y1: p,
									x2: ca,
									y2: I,
								};
								b = U(b);
								t &&
									ea(
										this._eventManager.ghostCtx,
										F,
										p,
										ca,
										I,
										b,
										0,
										null,
										!1,
										!1,
										!1,
										!1
									);
								(u[m].indexLabel ||
									s.indexLabel ||
									u[m].indexLabelFormatter ||
									s.indexLabelFormatter) &&
									this._indexLabels.push({
										chartType: "stackedColumn",
										dataPoint: u[m],
										dataSeries: s,
										point: { x: n, y: 0 <= u[m].y ? p : I },
										direction: 0 > u[m].y === a.axisY.reversed ? 1 : -1,
										bounds: {
											x1: F,
											y1: Math.min(p, I),
											x2: ca,
											y2: Math.max(p, I),
										},
										color: b,
									});
							}
					}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.yScaleAnimation,
					easingFunction: z.easing.easeOutQuart,
					animationBase:
						r < a.axisY.bounds.y1
							? a.axisY.bounds.y1
							: r > a.axisY.bounds.y2
							? a.axisY.bounds.y2
							: r,
				};
			}
		};
		v.prototype.renderStackedColumn100 = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = null,
					e = this.plotArea,
					h = [],
					g = [],
					d = [],
					M = [],
					m = 0,
					n,
					p,
					r = a.axisY.convertValueToPixel(
						a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
					),
					m = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1;
				n = this.options.dataPointMaxWidth
					? this.dataPointMaxWidth
					: this.options.dataPointWidth
					? this.dataPointWidth
					: (0.15 * this.width) << 0;
				var k = a.axisX.dataInfo.minDiff;
				isFinite(k) || (k = 0.3 * Math.abs(a.axisX.range));
				k = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.9 *
							((e.width *
								(a.axisX.logarithmic
									? Math.log(k) / Math.log(a.axisX.range)
									: Math.abs(k) / Math.abs(a.axisX.range))) /
								a.plotType.plotUnits.length)) <<
					  0;
				this.dataPointMaxWidth &&
					m > n &&
					(m = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						n
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					n < m &&
					(n = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						m
					));
				k < m && (k = m);
				k > n && (k = n);
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(e.x1, e.y1, e.width, e.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.clip());
				for (var x = 0; x < a.dataSeriesIndexes.length; x++) {
					var s = a.dataSeriesIndexes[x],
						q = this.data[s],
						u = q.dataPoints;
					if (0 < u.length)
						for (
							var l = 5 < k && q.bevelEnabled ? !0 : !1, m = 0;
							m < u.length;
							m++
						)
							if (
								((b = u[m].x.getTime ? u[m].x.getTime() : u[m].x),
								!(
									b < a.axisX.dataInfo.viewPortMin ||
									b > a.axisX.dataInfo.viewPortMax
								) && "number" === typeof u[m].y)
							) {
								n = a.axisX.convertValueToPixel(b);
								p =
									0 !== a.dataPointYSums[b]
										? 100 * (u[m].y / a.dataPointYSums[b])
										: 0;
								var F =
										(n - (a.plotType.plotUnits.length * k) / 2 + a.index * k) <<
										0,
									ca = (F + k) << 0,
									I;
								if (
									a.axisY.logarithmic ||
									(a.axisY.scaleBreaks &&
										0 < a.axisY.scaleBreaks._appliedBreaks.length &&
										0 < u[m].y)
								) {
									d[b] = p + ("undefined" !== typeof d[b] ? d[b] : 0);
									if (0 >= d[b]) continue;
									p = a.axisY.convertValueToPixel(d[b]);
									I = h[b] ? h[b] : r;
									h[b] = p;
								} else if (
									a.axisY.scaleBreaks &&
									0 < a.axisY.scaleBreaks._appliedBreaks.length &&
									0 >= u[m].y
								)
									(M[b] = p + ("undefined" !== typeof M[b] ? M[b] : 0)),
										(I = a.axisY.convertValueToPixel(M[b])),
										(p = g[b] ? g[b] : r),
										(g[b] = I);
								else if (((p = a.axisY.convertValueToPixel(p)), 0 <= u[m].y)) {
									var C = "undefined" !== typeof h[b] ? h[b] : 0;
									p -= C;
									I = r - C;
									a.dataSeriesIndexes.length - 1 === x &&
										1 >= Math.abs(e.y1 - p) &&
										(p = e.y1);
									h[b] = C + (I - p);
								} else
									(C = "undefined" !== typeof g[b] ? g[b] : 0),
										(I = p + C),
										(p = r + C),
										a.dataSeriesIndexes.length - 1 === x &&
											1 >= Math.abs(e.y2 - I) &&
											(I = e.y2),
										(g[b] = C + (I - p));
								b = u[m].color
									? u[m].color
									: q._colorSet[m % q._colorSet.length];
								ea(
									c,
									F,
									p,
									ca,
									I,
									b,
									0,
									null,
									l && 0 <= u[m].y,
									0 > u[m].y && l,
									!1,
									!1,
									q.fillOpacity
								);
								b = q.dataPointIds[m];
								this._eventManager.objectMap[b] = {
									id: b,
									objectType: "dataPoint",
									dataSeriesIndex: s,
									dataPointIndex: m,
									x1: F,
									y1: p,
									x2: ca,
									y2: I,
								};
								b = U(b);
								t &&
									ea(
										this._eventManager.ghostCtx,
										F,
										p,
										ca,
										I,
										b,
										0,
										null,
										!1,
										!1,
										!1,
										!1
									);
								(u[m].indexLabel ||
									q.indexLabel ||
									u[m].indexLabelFormatter ||
									q.indexLabelFormatter) &&
									this._indexLabels.push({
										chartType: "stackedColumn100",
										dataPoint: u[m],
										dataSeries: q,
										point: { x: n, y: 0 <= u[m].y ? p : I },
										direction: 0 > u[m].y === a.axisY.reversed ? 1 : -1,
										bounds: {
											x1: F,
											y1: Math.min(p, I),
											x2: ca,
											y2: Math.max(p, I),
										},
										color: b,
									});
							}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.yScaleAnimation,
					easingFunction: z.easing.easeOutQuart,
					animationBase:
						r < a.axisY.bounds.y1
							? a.axisY.bounds.y1
							: r > a.axisY.bounds.y2
							? a.axisY.bounds.y2
							: r,
				};
			}
		};
		v.prototype.renderBar = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = null,
					e = this.plotArea,
					h = 0,
					g,
					d,
					M,
					m = a.axisY.convertValueToPixel(
						a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
					),
					h = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1,
					n = this.options.dataPointMaxWidth
						? this.dataPointMaxWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: Math.min(
								0.15 * this.height,
								0.9 * (this.plotArea.height / a.plotType.totalDataSeries)
						  ) << 0,
					p = a.axisX.dataInfo.minDiff;
				isFinite(p) || (p = 0.3 * Math.abs(a.axisX.range));
				p = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.9 *
							((e.height *
								(a.axisX.logarithmic
									? Math.log(p) / Math.log(a.axisX.range)
									: Math.abs(p) / Math.abs(a.axisX.range))) /
								a.plotType.totalDataSeries)) <<
					  0;
				this.dataPointMaxWidth &&
					h > n &&
					(h = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						n
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					n < h &&
					(n = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						h
					));
				p < h && (p = h);
				p > n && (p = n);
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(e.x1, e.y1, e.width, e.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.clip());
				for (n = 0; n < a.dataSeriesIndexes.length; n++) {
					var r = a.dataSeriesIndexes[n],
						k = this.data[r],
						x = k.dataPoints;
					if (0 < x.length) {
						var q = 5 < p && k.bevelEnabled ? !0 : !1;
						c.strokeStyle = "#4572A7 ";
						for (h = 0; h < x.length; h++)
							if (
								(x[h].getTime ? (M = x[h].x.getTime()) : (M = x[h].x),
								!(
									M < a.axisX.dataInfo.viewPortMin ||
									M > a.axisX.dataInfo.viewPortMax
								) && "number" === typeof x[h].y)
							) {
								d = a.axisX.convertValueToPixel(M);
								g = a.axisY.convertValueToPixel(x[h].y);
								d = a.axisX.reversed
									? (d +
											(a.plotType.totalDataSeries * p) / 2 -
											(a.previousDataSeriesCount + n) * p) <<
									  0
									: (d -
											(a.plotType.totalDataSeries * p) / 2 +
											(a.previousDataSeriesCount + n) * p) <<
									  0;
								var s = a.axisX.reversed ? (d - p) << 0 : (d + p) << 0,
									u;
								0 <= x[h].y ? (u = m) : ((u = g), (g = m));
								b = x[h].color
									? x[h].color
									: k._colorSet[h % k._colorSet.length];
								ea(c, u, d, g, s, b, 0, null, q, !1, !1, !1, k.fillOpacity);
								b = k.dataPointIds[h];
								this._eventManager.objectMap[b] = {
									id: b,
									objectType: "dataPoint",
									dataSeriesIndex: r,
									dataPointIndex: h,
									x1: u,
									y1: d,
									x2: g,
									y2: s,
								};
								b = U(b);
								t &&
									ea(
										this._eventManager.ghostCtx,
										u,
										d,
										g,
										s,
										b,
										0,
										null,
										!1,
										!1,
										!1,
										!1
									);
								(x[h].indexLabel ||
									k.indexLabel ||
									x[h].indexLabelFormatter ||
									k.indexLabelFormatter) &&
									this._indexLabels.push({
										chartType: "bar",
										dataPoint: x[h],
										dataSeries: k,
										point: { x: 0 <= x[h].y ? g : u, y: d + (s - d) / 2 },
										direction: 0 > x[h].y === a.axisY.reversed ? 1 : -1,
										bounds: {
											x1: Math.min(u, g),
											y1: d,
											x2: Math.max(u, g),
											y2: s,
										},
										color: b,
									});
							}
					}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.xScaleAnimation,
					easingFunction: z.easing.easeOutQuart,
					animationBase:
						m < a.axisY.bounds.x1
							? a.axisY.bounds.x1
							: m > a.axisY.bounds.x2
							? a.axisY.bounds.x2
							: m,
				};
			}
		};
		v.prototype.renderStackedBar = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = null,
					e = this.plotArea,
					h = [],
					g = [],
					d = [],
					M = [],
					m = 0,
					n,
					p,
					r = a.axisY.convertValueToPixel(
						a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
					),
					m = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1;
				p = this.options.dataPointMaxWidth
					? this.dataPointMaxWidth
					: this.options.dataPointWidth
					? this.dataPointWidth
					: (0.15 * this.height) << 0;
				var k = a.axisX.dataInfo.minDiff;
				isFinite(k) || (k = 0.3 * Math.abs(a.axisX.range));
				k = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.9 *
							((e.height *
								(a.axisX.logarithmic
									? Math.log(k) / Math.log(a.axisX.range)
									: Math.abs(k) / Math.abs(a.axisX.range))) /
								a.plotType.plotUnits.length)) <<
					  0;
				this.dataPointMaxWidth &&
					m > p &&
					(m = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						p
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					p < m &&
					(p = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						m
					));
				k < m && (k = m);
				k > p && (k = p);
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(e.x1, e.y1, e.width, e.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.clip());
				for (var x = 0; x < a.dataSeriesIndexes.length; x++) {
					var s = a.dataSeriesIndexes[x],
						q = this.data[s],
						u = q.dataPoints;
					if (0 < u.length) {
						var l = 5 < k && q.bevelEnabled ? !0 : !1;
						c.strokeStyle = "#4572A7 ";
						for (m = 0; m < u.length; m++)
							if (
								((b = u[m].x.getTime ? u[m].x.getTime() : u[m].x),
								!(
									b < a.axisX.dataInfo.viewPortMin ||
									b > a.axisX.dataInfo.viewPortMax
								) && "number" === typeof u[m].y)
							) {
								p = a.axisX.convertValueToPixel(b);
								var F =
										(p - (a.plotType.plotUnits.length * k) / 2 + a.index * k) <<
										0,
									ca = (F + k) << 0,
									I;
								if (
									a.axisY.logarithmic ||
									(a.axisY.scaleBreaks &&
										0 < a.axisY.scaleBreaks._appliedBreaks.length &&
										0 < u[m].y)
								)
									(d[b] = u[m].y + (d[b] ? d[b] : 0)),
										0 < d[b] &&
											((I = h[b] ? h[b] : r),
											(h[b] = n = a.axisY.convertValueToPixel(d[b])));
								else if (
									a.axisY.scaleBreaks &&
									0 < a.axisY.scaleBreaks._appliedBreaks.length &&
									0 >= u[m].y
								)
									(M[b] = u[m].y + (M[b] ? M[b] : 0)),
										(n = g[b] ? g[b] : r),
										(g[b] = I = a.axisY.convertValueToPixel(M[b]));
								else if (
									((n = a.axisY.convertValueToPixel(u[m].y)), 0 <= u[m].y)
								) {
									var C = h[b] ? h[b] : 0;
									I = r + C;
									n += C;
									h[b] = C + (n - I);
								} else
									(C = g[b] ? g[b] : 0),
										(I = n - C),
										(n = r - C),
										(g[b] = C + (n - I));
								b = u[m].color
									? u[m].color
									: q._colorSet[m % q._colorSet.length];
								ea(c, I, F, n, ca, b, 0, null, l, !1, !1, !1, q.fillOpacity);
								b = q.dataPointIds[m];
								this._eventManager.objectMap[b] = {
									id: b,
									objectType: "dataPoint",
									dataSeriesIndex: s,
									dataPointIndex: m,
									x1: I,
									y1: F,
									x2: n,
									y2: ca,
								};
								b = U(b);
								t &&
									ea(
										this._eventManager.ghostCtx,
										I,
										F,
										n,
										ca,
										b,
										0,
										null,
										!1,
										!1,
										!1,
										!1
									);
								(u[m].indexLabel ||
									q.indexLabel ||
									u[m].indexLabelFormatter ||
									q.indexLabelFormatter) &&
									this._indexLabels.push({
										chartType: "stackedBar",
										dataPoint: u[m],
										dataSeries: q,
										point: { x: 0 <= u[m].y ? n : I, y: p },
										direction: 0 > u[m].y === a.axisY.reversed ? 1 : -1,
										bounds: {
											x1: Math.min(I, n),
											y1: F,
											x2: Math.max(I, n),
											y2: ca,
										},
										color: b,
									});
							}
					}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.xScaleAnimation,
					easingFunction: z.easing.easeOutQuart,
					animationBase:
						r < a.axisY.bounds.x1
							? a.axisY.bounds.x1
							: r > a.axisY.bounds.x2
							? a.axisY.bounds.x2
							: r,
				};
			}
		};
		v.prototype.renderStackedBar100 = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = null,
					e = this.plotArea,
					h = [],
					g = [],
					d = [],
					M = [],
					m = 0,
					n,
					p,
					r = a.axisY.convertValueToPixel(
						a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
					),
					m = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1;
				p = this.options.dataPointMaxWidth
					? this.dataPointMaxWidth
					: this.options.dataPointWidth
					? this.dataPointWidth
					: (0.15 * this.height) << 0;
				var k = a.axisX.dataInfo.minDiff;
				isFinite(k) || (k = 0.3 * Math.abs(a.axisX.range));
				k = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.9 *
							((e.height *
								(a.axisX.logarithmic
									? Math.log(k) / Math.log(a.axisX.range)
									: Math.abs(k) / Math.abs(a.axisX.range))) /
								a.plotType.plotUnits.length)) <<
					  0;
				this.dataPointMaxWidth &&
					m > p &&
					(m = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						p
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					p < m &&
					(p = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						m
					));
				k < m && (k = m);
				k > p && (k = p);
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(e.x1, e.y1, e.width, e.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.clip());
				for (var x = 0; x < a.dataSeriesIndexes.length; x++) {
					var q = a.dataSeriesIndexes[x],
						s = this.data[q],
						u = s.dataPoints;
					if (0 < u.length) {
						var l = 5 < k && s.bevelEnabled ? !0 : !1;
						c.strokeStyle = "#4572A7 ";
						for (m = 0; m < u.length; m++)
							if (
								((b = u[m].x.getTime ? u[m].x.getTime() : u[m].x),
								!(
									b < a.axisX.dataInfo.viewPortMin ||
									b > a.axisX.dataInfo.viewPortMax
								) && "number" === typeof u[m].y)
							) {
								p = a.axisX.convertValueToPixel(b);
								var F;
								F =
									0 !== a.dataPointYSums[b]
										? 100 * (u[m].y / a.dataPointYSums[b])
										: 0;
								var ca =
										(p - (a.plotType.plotUnits.length * k) / 2 + a.index * k) <<
										0,
									I = (ca + k) << 0;
								if (
									a.axisY.logarithmic ||
									(a.axisY.scaleBreaks &&
										0 < a.axisY.scaleBreaks._appliedBreaks.length &&
										0 < u[m].y)
								) {
									d[b] = F + (d[b] ? d[b] : 0);
									if (0 >= d[b]) continue;
									F = h[b] ? h[b] : r;
									h[b] = n = a.axisY.convertValueToPixel(d[b]);
								} else if (
									a.axisY.scaleBreaks &&
									0 < a.axisY.scaleBreaks._appliedBreaks.length &&
									0 >= u[m].y
								)
									(M[b] = F + (M[b] ? M[b] : 0)),
										(n = g[b] ? g[b] : r),
										(g[b] = F = a.axisY.convertValueToPixel(M[b]));
								else if (((n = a.axisY.convertValueToPixel(F)), 0 <= u[m].y)) {
									var C = h[b] ? h[b] : 0;
									F = r + C;
									n += C;
									a.dataSeriesIndexes.length - 1 === x &&
										1 >= Math.abs(e.x2 - n) &&
										(n = e.x2);
									h[b] = C + (n - F);
								} else
									(C = g[b] ? g[b] : 0),
										(F = n - C),
										(n = r - C),
										a.dataSeriesIndexes.length - 1 === x &&
											1 >= Math.abs(e.x1 - F) &&
											(F = e.x1),
										(g[b] = C + (n - F));
								b = u[m].color
									? u[m].color
									: s._colorSet[m % s._colorSet.length];
								ea(c, F, ca, n, I, b, 0, null, l, !1, !1, !1, s.fillOpacity);
								b = s.dataPointIds[m];
								this._eventManager.objectMap[b] = {
									id: b,
									objectType: "dataPoint",
									dataSeriesIndex: q,
									dataPointIndex: m,
									x1: F,
									y1: ca,
									x2: n,
									y2: I,
								};
								b = U(b);
								t &&
									ea(
										this._eventManager.ghostCtx,
										F,
										ca,
										n,
										I,
										b,
										0,
										null,
										!1,
										!1,
										!1,
										!1
									);
								(u[m].indexLabel ||
									s.indexLabel ||
									u[m].indexLabelFormatter ||
									s.indexLabelFormatter) &&
									this._indexLabels.push({
										chartType: "stackedBar100",
										dataPoint: u[m],
										dataSeries: s,
										point: { x: 0 <= u[m].y ? n : F, y: p },
										direction: 0 > u[m].y === a.axisY.reversed ? 1 : -1,
										bounds: {
											x1: Math.min(F, n),
											y1: ca,
											x2: Math.max(F, n),
											y2: I,
										},
										color: b,
									});
							}
					}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.xScaleAnimation,
					easingFunction: z.easing.easeOutQuart,
					animationBase:
						r < a.axisY.bounds.x1
							? a.axisY.bounds.x1
							: r > a.axisY.bounds.x2
							? a.axisY.bounds.x2
							: r,
				};
			}
		};
		v.prototype.renderArea = function (a) {
			var f, c;
			function b() {
				I &&
					(0 < k.lineThickness && h.stroke(),
					a.axisY.logarithmic ||
					(0 >= a.axisY.viewportMinimum && 0 <= a.axisY.viewportMaximum)
						? (ca = F)
						: 0 > a.axisY.viewportMaximum
						? (ca = d.y1)
						: 0 < a.axisY.viewportMinimum && (ca = F),
					h.lineTo(l, ca),
					h.lineTo(I.x, ca),
					h.closePath(),
					(h.globalAlpha = k.fillOpacity),
					h.fill(),
					(h.globalAlpha = 1),
					t && (g.lineTo(l, ca), g.lineTo(I.x, ca), g.closePath(), g.fill()),
					h.beginPath(),
					h.moveTo(l, u),
					g.beginPath(),
					g.moveTo(l, u),
					(I = { x: l, y: u }));
			}
			var e = a.targetCanvasCtx || this.plotArea.ctx,
				h = t ? this._preRenderCtx : e;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var g = this._eventManager.ghostCtx,
					d = a.axisY.lineCoordinates,
					M = [],
					m = this.plotArea,
					n;
				h.save();
				t && g.save();
				h.beginPath();
				h.rect(m.x1, m.y1, m.width, m.height);
				h.clip();
				t && (g.beginPath(), g.rect(m.x1, m.y1, m.width, m.height), g.clip());
				for (var p = 0; p < a.dataSeriesIndexes.length; p++) {
					var r = a.dataSeriesIndexes[p],
						k = this.data[r],
						x = k.dataPoints,
						M = k.id;
					this._eventManager.objectMap[M] = {
						objectType: "dataSeries",
						dataSeriesIndex: r,
					};
					M = U(M);
					g.fillStyle = M;
					M = [];
					f = !0;
					var q = 0,
						l,
						u,
						B,
						F = a.axisY.convertValueToPixel(
							a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
						),
						ca,
						I = null;
					if (0 < x.length) {
						var C = k._colorSet[q % k._colorSet.length],
							w = (k.lineColor = k.options.lineColor || C),
							v = w;
						h.fillStyle = C;
						h.strokeStyle = w;
						h.lineWidth = k.lineThickness;
						c = "solid";
						if (h.setLineDash) {
							var $ = S(k.nullDataLineDashType, k.lineThickness);
							c = k.lineDashType;
							var X = S(c, k.lineThickness);
							h.setLineDash(X);
						}
						for (var ja = !0; q < x.length; q++)
							if (
								((B = x[q].x.getTime ? x[q].x.getTime() : x[q].x),
								!(
									B < a.axisX.dataInfo.viewPortMin ||
									(B > a.axisX.dataInfo.viewPortMax &&
										(!k.connectNullData || !ja))
								))
							)
								if ("number" !== typeof x[q].y)
									k.connectNullData || ja || f || b(), (ja = !0);
								else {
									l = a.axisX.convertValueToPixel(B);
									u = a.axisY.convertValueToPixel(x[q].y);
									f || ja
										? (!f && k.connectNullData
												? (h.setLineDash &&
														(k.options.nullDataLineDashType ||
															(c === k.lineDashType &&
																k.lineDashType !== k.nullDataLineDashType)) &&
														((f = l),
														(c = u),
														(l = n.x),
														(u = n.y),
														b(),
														h.moveTo(n.x, n.y),
														(l = f),
														(u = c),
														(I = n),
														(c = k.nullDataLineDashType),
														h.setLineDash($)),
												  h.lineTo(l, u),
												  t && g.lineTo(l, u))
												: (h.beginPath(),
												  h.moveTo(l, u),
												  t && (g.beginPath(), g.moveTo(l, u)),
												  (I = { x: l, y: u })),
										  (ja = f = !1))
										: (h.lineTo(l, u),
										  t && g.lineTo(l, u),
										  0 == q % 250 && b());
									n = { x: l, y: u };
									q < x.length - 1 &&
										(v !== (x[q].lineColor || w) ||
											c !== (x[q].lineDashType || k.lineDashType)) &&
										(b(),
										(v = x[q].lineColor || w),
										(h.strokeStyle = v),
										h.setLineDash &&
											(x[q].lineDashType
												? ((c = x[q].lineDashType),
												  h.setLineDash(S(c, k.lineThickness)))
												: ((c = k.lineDashType), h.setLineDash(X))));
									var fa = k.dataPointIds[q];
									this._eventManager.objectMap[fa] = {
										id: fa,
										objectType: "dataPoint",
										dataSeriesIndex: r,
										dataPointIndex: q,
										x1: l,
										y1: u,
									};
									0 !== x[q].markerSize &&
										(0 < x[q].markerSize || 0 < k.markerSize) &&
										((B = k.getMarkerProperties(q, l, u, h)),
										M.push(B),
										(fa = U(fa)),
										t &&
											M.push({
												x: l,
												y: u,
												ctx: g,
												type: B.type,
												size: B.size,
												color: fa,
												borderColor: fa,
												borderThickness: B.borderThickness,
											}));
									(x[q].indexLabel ||
										k.indexLabel ||
										x[q].indexLabelFormatter ||
										k.indexLabelFormatter) &&
										this._indexLabels.push({
											chartType: "area",
											dataPoint: x[q],
											dataSeries: k,
											point: { x: l, y: u },
											direction: 0 > x[q].y === a.axisY.reversed ? 1 : -1,
											color: C,
										});
								}
						b();
						s.drawMarkers(M);
					}
				}
				t &&
					(e.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(h.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						h.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						h.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					h.clearRect(m.x1, m.y1, m.width, m.height),
					this._eventManager.ghostCtx.restore());
				h.restore();
				return {
					source: e,
					dest: this.plotArea.ctx,
					animationCallback: z.xClipAnimation,
					easingFunction: z.easing.linear,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderSplineArea = function (a) {
			function f() {
				var c = w(B, 2);
				if (0 < c.length) {
					if (0 < n.lineThickness) {
						b.beginPath();
						b.moveTo(c[0].x, c[0].y);
						c[0].newStrokeStyle && (b.strokeStyle = c[0].newStrokeStyle);
						c[0].newLineDashArray && b.setLineDash(c[0].newLineDashArray);
						for (var f = 0; f < c.length - 3; f += 3)
							if (
								(b.bezierCurveTo(
									c[f + 1].x,
									c[f + 1].y,
									c[f + 2].x,
									c[f + 2].y,
									c[f + 3].x,
									c[f + 3].y
								),
								t &&
									e.bezierCurveTo(
										c[f + 1].x,
										c[f + 1].y,
										c[f + 2].x,
										c[f + 2].y,
										c[f + 3].x,
										c[f + 3].y
									),
								c[f + 3].newStrokeStyle || c[f + 3].newLineDashArray)
							)
								b.stroke(),
									b.beginPath(),
									b.moveTo(c[f + 3].x, c[f + 3].y),
									c[f + 3].newStrokeStyle &&
										(b.strokeStyle = c[f + 3].newStrokeStyle),
									c[f + 3].newLineDashArray &&
										b.setLineDash(c[f + 3].newLineDashArray);
						b.stroke();
					}
					b.beginPath();
					b.moveTo(c[0].x, c[0].y);
					t && (e.beginPath(), e.moveTo(c[0].x, c[0].y));
					for (f = 0; f < c.length - 3; f += 3)
						b.bezierCurveTo(
							c[f + 1].x,
							c[f + 1].y,
							c[f + 2].x,
							c[f + 2].y,
							c[f + 3].x,
							c[f + 3].y
						),
							t &&
								e.bezierCurveTo(
									c[f + 1].x,
									c[f + 1].y,
									c[f + 2].x,
									c[f + 2].y,
									c[f + 3].x,
									c[f + 3].y
								);
					a.axisY.logarithmic ||
					(0 >= a.axisY.viewportMinimum && 0 <= a.axisY.viewportMaximum)
						? (l = q)
						: 0 > a.axisY.viewportMaximum
						? (l = h.y1)
						: 0 < a.axisY.viewportMinimum && (l = q);
					u = { x: c[0].x, y: c[0].y };
					b.lineTo(c[c.length - 1].x, l);
					b.lineTo(u.x, l);
					b.closePath();
					b.globalAlpha = n.fillOpacity;
					b.fill();
					b.globalAlpha = 1;
					t &&
						(e.lineTo(c[c.length - 1].x, l),
						e.lineTo(u.x, l),
						e.closePath(),
						e.fill());
				}
			}
			var c = a.targetCanvasCtx || this.plotArea.ctx,
				b = t ? this._preRenderCtx : c;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var e = this._eventManager.ghostCtx,
					h = a.axisY.lineCoordinates,
					g = [],
					d = this.plotArea;
				b.save();
				t && e.save();
				b.beginPath();
				b.rect(d.x1, d.y1, d.width, d.height);
				b.clip();
				t && (e.beginPath(), e.rect(d.x1, d.y1, d.width, d.height), e.clip());
				for (var M = 0; M < a.dataSeriesIndexes.length; M++) {
					var m = a.dataSeriesIndexes[M],
						n = this.data[m],
						p = n.dataPoints,
						g = n.id;
					this._eventManager.objectMap[g] = {
						objectType: "dataSeries",
						dataSeriesIndex: m,
					};
					g = U(g);
					e.fillStyle = g;
					var g = [],
						r = 0,
						k,
						x,
						q = a.axisY.convertValueToPixel(
							a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
						),
						l,
						u = null,
						B = [];
					if (0 < p.length) {
						var F = n._colorSet[r % n._colorSet.length],
							ca = (n.lineColor = n.options.lineColor || F),
							I = ca;
						b.fillStyle = F;
						b.strokeStyle = ca;
						b.lineWidth = n.lineThickness;
						var C = "solid";
						if (b.setLineDash) {
							var v = S(n.nullDataLineDashType, n.lineThickness),
								C = n.lineDashType,
								y = S(C, n.lineThickness);
							b.setLineDash(y);
						}
						for (x = !1; r < p.length; r++)
							if (
								((k = p[r].x.getTime ? p[r].x.getTime() : p[r].x),
								!(
									k < a.axisX.dataInfo.viewPortMin ||
									(k > a.axisX.dataInfo.viewPortMax &&
										(!n.connectNullData || !x))
								))
							)
								if ("number" !== typeof p[r].y)
									0 < r &&
										!x &&
										(n.connectNullData
											? b.setLineDash &&
											  0 < B.length &&
											  (n.options.nullDataLineDashType ||
													!p[r - 1].lineDashType) &&
											  ((B[B.length - 1].newLineDashArray = v),
											  (C = n.nullDataLineDashType))
											: (f(), (B = []))),
										(x = !0);
								else {
									k = a.axisX.convertValueToPixel(k);
									x = a.axisY.convertValueToPixel(p[r].y);
									var $ = n.dataPointIds[r];
									this._eventManager.objectMap[$] = {
										id: $,
										objectType: "dataPoint",
										dataSeriesIndex: m,
										dataPointIndex: r,
										x1: k,
										y1: x,
									};
									B[B.length] = { x: k, y: x };
									r < p.length - 1 &&
										(I !== (p[r].lineColor || ca) ||
											C !== (p[r].lineDashType || n.lineDashType)) &&
										((I = p[r].lineColor || ca),
										(B[B.length - 1].newStrokeStyle = I),
										b.setLineDash &&
											(p[r].lineDashType
												? ((C = p[r].lineDashType),
												  (B[B.length - 1].newLineDashArray = S(
														C,
														n.lineThickness
												  )))
												: ((C = n.lineDashType),
												  (B[B.length - 1].newLineDashArray = y))));
									if (
										0 !== p[r].markerSize &&
										(0 < p[r].markerSize || 0 < n.markerSize)
									) {
										var X = n.getMarkerProperties(r, k, x, b);
										g.push(X);
										$ = U($);
										t &&
											g.push({
												x: k,
												y: x,
												ctx: e,
												type: X.type,
												size: X.size,
												color: $,
												borderColor: $,
												borderThickness: X.borderThickness,
											});
									}
									(p[r].indexLabel ||
										n.indexLabel ||
										p[r].indexLabelFormatter ||
										n.indexLabelFormatter) &&
										this._indexLabels.push({
											chartType: "splineArea",
											dataPoint: p[r],
											dataSeries: n,
											point: { x: k, y: x },
											direction: 0 > p[r].y === a.axisY.reversed ? 1 : -1,
											color: F,
										});
									x = !1;
								}
						f();
						s.drawMarkers(g);
					}
				}
				t &&
					(c.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(b.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					b.clearRect(d.x1, d.y1, d.width, d.height),
					this._eventManager.ghostCtx.restore());
				b.restore();
				return {
					source: c,
					dest: this.plotArea.ctx,
					animationCallback: z.xClipAnimation,
					easingFunction: z.easing.linear,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderStepArea = function (a) {
			var f, c;
			function b() {
				I &&
					(0 < k.lineThickness && h.stroke(),
					a.axisY.logarithmic ||
					(0 >= a.axisY.viewportMinimum && 0 <= a.axisY.viewportMaximum)
						? (w = F)
						: 0 > a.axisY.viewportMaximum
						? (w = d.y1)
						: 0 < a.axisY.viewportMinimum && (w = F),
					h.lineTo(l, w),
					h.lineTo(I.x, w),
					h.closePath(),
					(h.globalAlpha = k.fillOpacity),
					h.fill(),
					(h.globalAlpha = 1),
					t && (g.lineTo(l, w), g.lineTo(I.x, w), g.closePath(), g.fill()),
					h.beginPath(),
					h.moveTo(l, u),
					g.beginPath(),
					g.moveTo(l, u),
					(I = { x: l, y: u }));
			}
			var e = a.targetCanvasCtx || this.plotArea.ctx,
				h = t ? this._preRenderCtx : e;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var g = this._eventManager.ghostCtx,
					d = a.axisY.lineCoordinates,
					M = [],
					m = this.plotArea,
					n;
				h.save();
				t && g.save();
				h.beginPath();
				h.rect(m.x1, m.y1, m.width, m.height);
				h.clip();
				t && (g.beginPath(), g.rect(m.x1, m.y1, m.width, m.height), g.clip());
				for (var p = 0; p < a.dataSeriesIndexes.length; p++) {
					var r = a.dataSeriesIndexes[p],
						k = this.data[r],
						x = k.dataPoints,
						M = k.id;
					this._eventManager.objectMap[M] = {
						objectType: "dataSeries",
						dataSeriesIndex: r,
					};
					M = U(M);
					g.fillStyle = M;
					M = [];
					f = !0;
					var q = 0,
						l,
						u,
						B,
						F = a.axisY.convertValueToPixel(
							a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
						),
						w,
						I = null;
					c = !1;
					if (0 < x.length) {
						var C = k._colorSet[q % k._colorSet.length],
							v = (k.lineColor = k.options.lineColor || C),
							y = v;
						h.fillStyle = C;
						h.strokeStyle = v;
						h.lineWidth = k.lineThickness;
						var $ = "solid";
						if (h.setLineDash) {
							var X = S(k.nullDataLineDashType, k.lineThickness),
								$ = k.lineDashType,
								D = S($, k.lineThickness);
							h.setLineDash(D);
						}
						for (; q < x.length; q++)
							if (
								((B = x[q].x.getTime ? x[q].x.getTime() : x[q].x),
								!(
									B < a.axisX.dataInfo.viewPortMin ||
									(B > a.axisX.dataInfo.viewPortMax &&
										(!k.connectNullData || !c))
								))
							) {
								var fa = u;
								"number" !== typeof x[q].y
									? (k.connectNullData || c || f || b(), (c = !0))
									: ((l = a.axisX.convertValueToPixel(B)),
									  (u = a.axisY.convertValueToPixel(x[q].y)),
									  f || c
											? (!f && k.connectNullData
													? (h.setLineDash &&
															(k.options.nullDataLineDashType ||
																($ === k.lineDashType &&
																	k.lineDashType !== k.nullDataLineDashType)) &&
															((f = l),
															(c = u),
															(l = n.x),
															(u = n.y),
															b(),
															h.moveTo(n.x, n.y),
															(l = f),
															(u = c),
															(I = n),
															($ = k.nullDataLineDashType),
															h.setLineDash(X)),
													  h.lineTo(l, fa),
													  h.lineTo(l, u),
													  t && (g.lineTo(l, fa), g.lineTo(l, u)))
													: (h.beginPath(),
													  h.moveTo(l, u),
													  t && (g.beginPath(), g.moveTo(l, u)),
													  (I = { x: l, y: u })),
											  (c = f = !1))
											: (h.lineTo(l, fa),
											  t && g.lineTo(l, fa),
											  h.lineTo(l, u),
											  t && g.lineTo(l, u),
											  0 == q % 250 && b()),
									  (n = { x: l, y: u }),
									  q < x.length - 1 &&
											(y !== (x[q].lineColor || v) ||
												$ !== (x[q].lineDashType || k.lineDashType)) &&
											(b(),
											(y = x[q].lineColor || v),
											(h.strokeStyle = y),
											h.setLineDash &&
												(x[q].lineDashType
													? (($ = x[q].lineDashType),
													  h.setLineDash(S($, k.lineThickness)))
													: (($ = k.lineDashType), h.setLineDash(D)))),
									  (B = k.dataPointIds[q]),
									  (this._eventManager.objectMap[B] = {
											id: B,
											objectType: "dataPoint",
											dataSeriesIndex: r,
											dataPointIndex: q,
											x1: l,
											y1: u,
									  }),
									  0 !== x[q].markerSize &&
											(0 < x[q].markerSize || 0 < k.markerSize) &&
											((fa = k.getMarkerProperties(q, l, u, h)),
											M.push(fa),
											(B = U(B)),
											t &&
												M.push({
													x: l,
													y: u,
													ctx: g,
													type: fa.type,
													size: fa.size,
													color: B,
													borderColor: B,
													borderThickness: fa.borderThickness,
												})),
									  (x[q].indexLabel ||
											k.indexLabel ||
											x[q].indexLabelFormatter ||
											k.indexLabelFormatter) &&
											this._indexLabels.push({
												chartType: "stepArea",
												dataPoint: x[q],
												dataSeries: k,
												point: { x: l, y: u },
												direction: 0 > x[q].y === a.axisY.reversed ? 1 : -1,
												color: C,
											}));
							}
						b();
						s.drawMarkers(M);
					}
				}
				t &&
					(e.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(h.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						h.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						h.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					h.clearRect(m.x1, m.y1, m.width, m.height),
					this._eventManager.ghostCtx.restore());
				h.restore();
				return {
					source: e,
					dest: this.plotArea.ctx,
					animationCallback: z.xClipAnimation,
					easingFunction: z.easing.linear,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderStackedArea = function (a) {
			function f() {
				if (!(1 > m.length)) {
					for (0 < C.lineThickness && b.stroke(); 0 < m.length; ) {
						var a = m.pop();
						b.lineTo(a.x, a.y);
						t && L.lineTo(a.x, a.y);
					}
					b.closePath();
					b.globalAlpha = C.fillOpacity;
					b.fill();
					b.globalAlpha = 1;
					b.beginPath();
					t && (L.closePath(), L.fill(), L.beginPath());
					m = [];
				}
			}
			var c = a.targetCanvasCtx || this.plotArea.ctx,
				b = t ? this._preRenderCtx : c;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var e = null,
					h = null,
					g = [],
					d = this.plotArea,
					q = [],
					m = [],
					n = [],
					p = [],
					r = 0,
					k,
					x,
					l = a.axisY.convertValueToPixel(
						a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
					),
					L = this._eventManager.ghostCtx,
					u,
					B,
					F;
				t && L.beginPath();
				b.save();
				t && L.save();
				b.beginPath();
				b.rect(d.x1, d.y1, d.width, d.height);
				b.clip();
				t && (L.beginPath(), L.rect(d.x1, d.y1, d.width, d.height), L.clip());
				for (var e = [], w = 0; w < a.dataSeriesIndexes.length; w++) {
					var I = a.dataSeriesIndexes[w],
						C = this.data[I],
						v = C.dataPoints;
					C.dataPointIndexes = [];
					for (r = 0; r < v.length; r++)
						(I = v[r].x.getTime ? v[r].x.getTime() : v[r].x),
							(C.dataPointIndexes[I] = r),
							e[I] || (n.push(I), (e[I] = !0));
					n.sort(Xa);
				}
				for (w = 0; w < a.dataSeriesIndexes.length; w++) {
					I = a.dataSeriesIndexes[w];
					C = this.data[I];
					v = C.dataPoints;
					B = !0;
					m = [];
					r = C.id;
					this._eventManager.objectMap[r] = {
						objectType: "dataSeries",
						dataSeriesIndex: I,
					};
					r = U(r);
					L.fillStyle = r;
					if (0 < n.length) {
						var e = C._colorSet[0],
							y = (C.lineColor = C.options.lineColor || e),
							$ = y;
						b.fillStyle = e;
						b.strokeStyle = y;
						b.lineWidth = C.lineThickness;
						F = "solid";
						if (b.setLineDash) {
							var X = S(C.nullDataLineDashType, C.lineThickness);
							F = C.lineDashType;
							var D = S(F, C.lineThickness);
							b.setLineDash(D);
						}
						for (var fa = !0, r = 0; r < n.length; r++) {
							var h = n[r],
								la = null,
								la =
									0 <= C.dataPointIndexes[h]
										? v[C.dataPointIndexes[h]]
										: { x: h, y: null };
							if (
								!(
									h < a.axisX.dataInfo.viewPortMin ||
									(h > a.axisX.dataInfo.viewPortMax &&
										(!C.connectNullData || !fa))
								)
							)
								if ("number" !== typeof la.y)
									C.connectNullData || fa || B || f(), (fa = !0);
								else {
									k = a.axisX.convertValueToPixel(h);
									var va = q[h] ? q[h] : 0;
									if (
										a.axisY.logarithmic ||
										(a.axisY.scaleBreaks &&
											0 < a.axisY.scaleBreaks._appliedBreaks.length)
									) {
										p[h] = la.y + (p[h] ? p[h] : 0);
										if (0 >= p[h] && a.axisY.logarithmic) continue;
										x = a.axisY.convertValueToPixel(p[h]);
									} else (x = a.axisY.convertValueToPixel(la.y)), (x -= va);
									m.push({ x: k, y: l - va });
									q[h] = l - x;
									B || fa
										? (!B && C.connectNullData
												? (b.setLineDash &&
														(C.options.nullDataLineDashType ||
															(F === C.lineDashType &&
																C.lineDashType !== C.nullDataLineDashType)) &&
														((B = m.pop()),
														(F = m[m.length - 1]),
														f(),
														b.moveTo(u.x, u.y),
														m.push(F),
														m.push(B),
														(F = C.nullDataLineDashType),
														b.setLineDash(X)),
												  b.lineTo(k, x),
												  t && L.lineTo(k, x))
												: (b.beginPath(),
												  b.moveTo(k, x),
												  t && (L.beginPath(), L.moveTo(k, x))),
										  (fa = B = !1))
										: (b.lineTo(k, x),
										  t && L.lineTo(k, x),
										  0 == r % 250 &&
												(f(),
												b.moveTo(k, x),
												t && L.moveTo(k, x),
												m.push({ x: k, y: l - va })));
									u = { x: k, y: x };
									r < v.length - 1 &&
										($ !== (v[r].lineColor || y) ||
											F !== (v[r].lineDashType || C.lineDashType)) &&
										(f(),
										b.beginPath(),
										b.moveTo(k, x),
										m.push({ x: k, y: l - va }),
										($ = v[r].lineColor || y),
										(b.strokeStyle = $),
										b.setLineDash &&
											(v[r].lineDashType
												? ((F = v[r].lineDashType),
												  b.setLineDash(S(F, C.lineThickness)))
												: ((F = C.lineDashType), b.setLineDash(D))));
									if (0 <= C.dataPointIndexes[h]) {
										var pa = C.dataPointIds[C.dataPointIndexes[h]];
										this._eventManager.objectMap[pa] = {
											id: pa,
											objectType: "dataPoint",
											dataSeriesIndex: I,
											dataPointIndex: C.dataPointIndexes[h],
											x1: k,
											y1: x,
										};
									}
									0 <= C.dataPointIndexes[h] &&
										0 !== la.markerSize &&
										(0 < la.markerSize || 0 < C.markerSize) &&
										((va = C.getMarkerProperties(
											C.dataPointIndexes[h],
											k,
											x,
											b
										)),
										g.push(va),
										(h = U(pa)),
										t &&
											g.push({
												x: k,
												y: x,
												ctx: L,
												type: va.type,
												size: va.size,
												color: h,
												borderColor: h,
												borderThickness: va.borderThickness,
											}));
									(la.indexLabel ||
										C.indexLabel ||
										la.indexLabelFormatter ||
										C.indexLabelFormatter) &&
										this._indexLabels.push({
											chartType: "stackedArea",
											dataPoint: la,
											dataSeries: C,
											point: { x: k, y: x },
											direction: 0 > v[r].y === a.axisY.reversed ? 1 : -1,
											color: e,
										});
								}
						}
						f();
						b.moveTo(k, x);
						t && L.moveTo(k, x);
					}
					delete C.dataPointIndexes;
				}
				s.drawMarkers(g);
				t &&
					(c.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(b.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					b.clearRect(d.x1, d.y1, d.width, d.height),
					L.restore());
				b.restore();
				return {
					source: c,
					dest: this.plotArea.ctx,
					animationCallback: z.xClipAnimation,
					easingFunction: z.easing.linear,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderStackedArea100 = function (a) {
			function f() {
				for (0 < C.lineThickness && b.stroke(); 0 < m.length; ) {
					var a = m.pop();
					b.lineTo(a.x, a.y);
					t && F.lineTo(a.x, a.y);
				}
				b.closePath();
				b.globalAlpha = C.fillOpacity;
				b.fill();
				b.globalAlpha = 1;
				b.beginPath();
				t && (F.closePath(), F.fill(), F.beginPath());
				m = [];
			}
			var c = a.targetCanvasCtx || this.plotArea.ctx,
				b = t ? this._preRenderCtx : c;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var e = null,
					h = null,
					g = this.plotArea,
					d = [],
					q = [],
					m = [],
					n = [],
					p = [],
					r = 0,
					k,
					x,
					l,
					L,
					u,
					B = a.axisY.convertValueToPixel(
						a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
					),
					F = this._eventManager.ghostCtx;
				b.save();
				t && F.save();
				b.beginPath();
				b.rect(g.x1, g.y1, g.width, g.height);
				b.clip();
				t && (F.beginPath(), F.rect(g.x1, g.y1, g.width, g.height), F.clip());
				for (var e = [], w = 0; w < a.dataSeriesIndexes.length; w++) {
					var v = a.dataSeriesIndexes[w],
						C = this.data[v],
						y = C.dataPoints;
					C.dataPointIndexes = [];
					for (r = 0; r < y.length; r++)
						(v = y[r].x.getTime ? y[r].x.getTime() : y[r].x),
							(C.dataPointIndexes[v] = r),
							e[v] || (n.push(v), (e[v] = !0));
					n.sort(Xa);
				}
				for (w = 0; w < a.dataSeriesIndexes.length; w++) {
					v = a.dataSeriesIndexes[w];
					C = this.data[v];
					y = C.dataPoints;
					L = !0;
					e = C.id;
					this._eventManager.objectMap[e] = {
						objectType: "dataSeries",
						dataSeriesIndex: v,
					};
					e = U(e);
					F.fillStyle = e;
					m = [];
					if (0 < n.length) {
						var e = C._colorSet[r % C._colorSet.length],
							D = (C.lineColor = C.options.lineColor || e),
							$ = D;
						b.fillStyle = e;
						b.strokeStyle = D;
						b.lineWidth = C.lineThickness;
						u = "solid";
						if (b.setLineDash) {
							var X = S(C.nullDataLineDashType, C.lineThickness);
							u = C.lineDashType;
							var ja = S(u, C.lineThickness);
							b.setLineDash(ja);
						}
						for (var fa = !0, r = 0; r < n.length; r++) {
							var h = n[r],
								la = null,
								la =
									0 <= C.dataPointIndexes[h]
										? y[C.dataPointIndexes[h]]
										: { x: h, y: null };
							if (
								!(
									h < a.axisX.dataInfo.viewPortMin ||
									(h > a.axisX.dataInfo.viewPortMax &&
										(!C.connectNullData || !fa))
								)
							)
								if ("number" !== typeof la.y)
									C.connectNullData || fa || L || f(), (fa = !0);
								else {
									var va;
									va =
										0 !== a.dataPointYSums[h]
											? 100 * (la.y / a.dataPointYSums[h])
											: 0;
									k = a.axisX.convertValueToPixel(h);
									var pa = q[h] ? q[h] : 0;
									if (
										a.axisY.logarithmic ||
										(a.axisY.scaleBreaks &&
											0 < a.axisY.scaleBreaks._appliedBreaks.length)
									) {
										p[h] = va + (p[h] ? p[h] : 0);
										if (0 >= p[h] && a.axisY.logarithmic) continue;
										x = a.axisY.convertValueToPixel(p[h]);
									} else (x = a.axisY.convertValueToPixel(va)), (x -= pa);
									m.push({ x: k, y: B - pa });
									q[h] = B - x;
									L || fa
										? (!L && C.connectNullData
												? (b.setLineDash &&
														(C.options.nullDataLineDashType ||
															(u === C.lineDashType &&
																C.lineDashType !== C.nullDataLineDashType)) &&
														((L = m.pop()),
														(u = m[m.length - 1]),
														f(),
														b.moveTo(l.x, l.y),
														m.push(u),
														m.push(L),
														(u = C.nullDataLineDashType),
														b.setLineDash(X)),
												  b.lineTo(k, x),
												  t && F.lineTo(k, x))
												: (b.beginPath(),
												  b.moveTo(k, x),
												  t && (F.beginPath(), F.moveTo(k, x))),
										  (fa = L = !1))
										: (b.lineTo(k, x),
										  t && F.lineTo(k, x),
										  0 == r % 250 &&
												(f(),
												b.moveTo(k, x),
												t && F.moveTo(k, x),
												m.push({ x: k, y: B - pa })));
									l = { x: k, y: x };
									r < y.length - 1 &&
										($ !== (y[r].lineColor || D) ||
											u !== (y[r].lineDashType || C.lineDashType)) &&
										(f(),
										b.beginPath(),
										b.moveTo(k, x),
										m.push({ x: k, y: B - pa }),
										($ = y[r].lineColor || D),
										(b.strokeStyle = $),
										b.setLineDash &&
											(y[r].lineDashType
												? ((u = y[r].lineDashType),
												  b.setLineDash(S(u, C.lineThickness)))
												: ((u = C.lineDashType), b.setLineDash(ja))));
									if (0 <= C.dataPointIndexes[h]) {
										var G = C.dataPointIds[C.dataPointIndexes[h]];
										this._eventManager.objectMap[G] = {
											id: G,
											objectType: "dataPoint",
											dataSeriesIndex: v,
											dataPointIndex: C.dataPointIndexes[h],
											x1: k,
											y1: x,
										};
									}
									0 <= C.dataPointIndexes[h] &&
										0 !== la.markerSize &&
										(0 < la.markerSize || 0 < C.markerSize) &&
										((pa = C.getMarkerProperties(r, k, x, b)),
										d.push(pa),
										(h = U(G)),
										t &&
											d.push({
												x: k,
												y: x,
												ctx: F,
												type: pa.type,
												size: pa.size,
												color: h,
												borderColor: h,
												borderThickness: pa.borderThickness,
											}));
									(la.indexLabel ||
										C.indexLabel ||
										la.indexLabelFormatter ||
										C.indexLabelFormatter) &&
										this._indexLabels.push({
											chartType: "stackedArea100",
											dataPoint: la,
											dataSeries: C,
											point: { x: k, y: x },
											direction: 0 > y[r].y === a.axisY.reversed ? 1 : -1,
											color: e,
										});
								}
						}
						f();
						b.moveTo(k, x);
						t && F.moveTo(k, x);
					}
					delete C.dataPointIndexes;
				}
				s.drawMarkers(d);
				t &&
					(c.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(b.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					b.clearRect(g.x1, g.y1, g.width, g.height),
					F.restore());
				b.restore();
				return {
					source: c,
					dest: this.plotArea.ctx,
					animationCallback: z.xClipAnimation,
					easingFunction: z.easing.linear,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderBubble = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = this.plotArea,
					e = 0,
					h,
					g;
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(b.x1, b.y1, b.width, b.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(b.x1, b.y1, b.width, b.height),
					this._eventManager.ghostCtx.clip());
				for (
					var d = -Infinity, q = Infinity, m = 0;
					m < a.dataSeriesIndexes.length;
					m++
				)
					for (
						var n = a.dataSeriesIndexes[m],
							p = this.data[n],
							r = p.dataPoints,
							k = 0,
							e = 0;
						e < r.length;
						e++
					)
						(h = r[e].getTime ? (h = r[e].x.getTime()) : (h = r[e].x)),
							h < a.axisX.dataInfo.viewPortMin ||
								h > a.axisX.dataInfo.viewPortMax ||
								"undefined" === typeof r[e].z ||
								((k = r[e].z), k > d && (d = k), k < q && (q = k));
				for (
					var x = 25 * Math.PI,
						l = Math.max(
							Math.pow((0.25 * Math.min(b.height, b.width)) / 2, 2) * Math.PI,
							x
						),
						m = 0;
					m < a.dataSeriesIndexes.length;
					m++
				)
					if (
						((n = a.dataSeriesIndexes[m]),
						(p = this.data[n]),
						(r = p.dataPoints),
						0 < r.length)
					)
						for (c.strokeStyle = "#4572A7 ", e = 0; e < r.length; e++)
							if (
								((h = r[e].getTime ? (h = r[e].x.getTime()) : (h = r[e].x)),
								!(
									h < a.axisX.dataInfo.viewPortMin ||
									h > a.axisX.dataInfo.viewPortMax
								) && "number" === typeof r[e].y)
							) {
								h = a.axisX.convertValueToPixel(h);
								g = a.axisY.convertValueToPixel(r[e].y);
								var k = r[e].z,
									L =
										2 *
										Math.max(
											Math.sqrt(
												(d === q ? l / 2 : x + ((l - x) / (d - q)) * (k - q)) /
													Math.PI
											) << 0,
											1
										),
									k = p.getMarkerProperties(e, c);
								k.size = L;
								c.globalAlpha = p.fillOpacity;
								s.drawMarker(
									h,
									g,
									c,
									k.type,
									k.size,
									k.color,
									k.borderColor,
									k.borderThickness
								);
								c.globalAlpha = 1;
								var u = p.dataPointIds[e];
								this._eventManager.objectMap[u] = {
									id: u,
									objectType: "dataPoint",
									dataSeriesIndex: n,
									dataPointIndex: e,
									x1: h,
									y1: g,
									size: L,
								};
								L = U(u);
								t &&
									s.drawMarker(
										h,
										g,
										this._eventManager.ghostCtx,
										k.type,
										k.size,
										L,
										L,
										k.borderThickness
									);
								(r[e].indexLabel ||
									p.indexLabel ||
									r[e].indexLabelFormatter ||
									p.indexLabelFormatter) &&
									this._indexLabels.push({
										chartType: "bubble",
										dataPoint: r[e],
										dataSeries: p,
										point: { x: h, y: g },
										direction: 1,
										bounds: {
											x1: h - k.size / 2,
											y1: g - k.size / 2,
											x2: h + k.size / 2,
											y2: g + k.size / 2,
										},
										color: null,
									});
							}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(b.x1, b.y1, b.width, b.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.fadeInAnimation,
					easingFunction: z.easing.easeInQuad,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderScatter = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = this.plotArea,
					e = 0,
					h,
					g;
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(b.x1, b.y1, b.width, b.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(b.x1, b.y1, b.width, b.height),
					this._eventManager.ghostCtx.clip());
				for (var d = 0; d < a.dataSeriesIndexes.length; d++) {
					var q = a.dataSeriesIndexes[d],
						m = this.data[q],
						n = m.dataPoints;
					if (0 < n.length) {
						c.strokeStyle = "#4572A7 ";
						Math.pow((0.3 * Math.min(b.height, b.width)) / 2, 2);
						for (var p = 0, r = 0, e = 0; e < n.length; e++)
							if (
								((h = n[e].getTime ? (h = n[e].x.getTime()) : (h = n[e].x)),
								!(
									h < a.axisX.dataInfo.viewPortMin ||
									h > a.axisX.dataInfo.viewPortMax
								) && "number" === typeof n[e].y)
							) {
								h = a.axisX.convertValueToPixel(h);
								g = a.axisY.convertValueToPixel(n[e].y);
								var k = m.getMarkerProperties(e, h, g, c);
								c.globalAlpha = m.fillOpacity;
								s.drawMarker(
									k.x,
									k.y,
									k.ctx,
									k.type,
									k.size,
									k.color,
									k.borderColor,
									k.borderThickness
								);
								c.globalAlpha = 1;
								(Math.sqrt((p - h) * (p - h) + (r - g) * (r - g)) <
									Math.min(k.size, 5) &&
									n.length >
										Math.min(this.plotArea.width, this.plotArea.height)) ||
									((p = m.dataPointIds[e]),
									(this._eventManager.objectMap[p] = {
										id: p,
										objectType: "dataPoint",
										dataSeriesIndex: q,
										dataPointIndex: e,
										x1: h,
										y1: g,
									}),
									(p = U(p)),
									t &&
										s.drawMarker(
											k.x,
											k.y,
											this._eventManager.ghostCtx,
											k.type,
											k.size,
											p,
											p,
											k.borderThickness
										),
									(n[e].indexLabel ||
										m.indexLabel ||
										n[e].indexLabelFormatter ||
										m.indexLabelFormatter) &&
										this._indexLabels.push({
											chartType: "scatter",
											dataPoint: n[e],
											dataSeries: m,
											point: { x: h, y: g },
											direction: 1,
											bounds: {
												x1: h - k.size / 2,
												y1: g - k.size / 2,
												x2: h + k.size / 2,
												y2: g + k.size / 2,
											},
											color: null,
										}),
									(p = h),
									(r = g));
							}
					}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(b.x1, b.y1, b.width, b.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.fadeInAnimation,
					easingFunction: z.easing.easeInQuad,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderCandlestick = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f,
				b = this._eventManager.ghostCtx;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var e = null,
					h = null,
					d = this.plotArea,
					q = 0,
					l,
					m,
					n,
					p,
					r,
					k,
					e = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1,
					h = this.options.dataPointMaxWidth
						? this.dataPointMaxWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 0.015 * this.width,
					x = a.axisX.dataInfo.minDiff;
				isFinite(x) || (x = 0.3 * Math.abs(a.axisX.range));
				x = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.7 *
							d.width *
							(a.axisX.logarithmic
								? Math.log(x) / Math.log(a.axisX.range)
								: Math.abs(x) / Math.abs(a.axisX.range))) <<
					  0;
				this.dataPointMaxWidth &&
					e > h &&
					(e = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						h
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					h < e &&
					(h = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						e
					));
				x < e && (x = e);
				x > h && (x = h);
				c.save();
				t && b.save();
				c.beginPath();
				c.rect(d.x1, d.y1, d.width, d.height);
				c.clip();
				t && (b.beginPath(), b.rect(d.x1, d.y1, d.width, d.height), b.clip());
				for (var s = 0; s < a.dataSeriesIndexes.length; s++) {
					var L = a.dataSeriesIndexes[s],
						u = this.data[L],
						B = u.dataPoints;
					if (0 < B.length)
						for (
							var F = 5 < x && u.bevelEnabled ? !0 : !1, q = 0;
							q < B.length;
							q++
						)
							if (
								(B[q].getTime ? (k = B[q].x.getTime()) : (k = B[q].x),
								!(
									k < a.axisX.dataInfo.viewPortMin ||
									k > a.axisX.dataInfo.viewPortMax
								) &&
									!g(B[q].y) &&
									B[q].y.length &&
									"number" === typeof B[q].y[0] &&
									"number" === typeof B[q].y[1] &&
									"number" === typeof B[q].y[2] &&
									"number" === typeof B[q].y[3])
							) {
								l = a.axisX.convertValueToPixel(k);
								m = a.axisY.convertValueToPixel(B[q].y[0]);
								n = a.axisY.convertValueToPixel(B[q].y[1]);
								p = a.axisY.convertValueToPixel(B[q].y[2]);
								r = a.axisY.convertValueToPixel(B[q].y[3]);
								var v = (l - x / 2) << 0,
									w = (v + x) << 0,
									h = u.options.fallingColor ? u.fallingColor : u._colorSet[0],
									e = B[q].color ? B[q].color : u._colorSet[0],
									C = Math.round(Math.max(1, 0.15 * x)),
									y = 0 === C % 2 ? 0 : 0.5,
									D = u.dataPointIds[q];
								this._eventManager.objectMap[D] = {
									id: D,
									objectType: "dataPoint",
									dataSeriesIndex: L,
									dataPointIndex: q,
									x1: v,
									y1: m,
									x2: w,
									y2: n,
									x3: l,
									y3: p,
									x4: l,
									y4: r,
									borderThickness: C,
									color: e,
								};
								c.strokeStyle = e;
								c.beginPath();
								c.lineWidth = C;
								b.lineWidth = Math.max(C, 4);
								"candlestick" === u.type
									? (c.moveTo(l - y, n),
									  c.lineTo(l - y, Math.min(m, r)),
									  c.stroke(),
									  c.moveTo(l - y, Math.max(m, r)),
									  c.lineTo(l - y, p),
									  c.stroke(),
									  ea(
											c,
											v,
											Math.min(m, r),
											w,
											Math.max(m, r),
											B[q].y[0] <= B[q].y[3] ? u.risingColor : h,
											C,
											e,
											F,
											F,
											!1,
											!1,
											u.fillOpacity
									  ),
									  t &&
											((e = U(D)),
											(b.strokeStyle = e),
											b.moveTo(l - y, n),
											b.lineTo(l - y, Math.min(m, r)),
											b.stroke(),
											b.moveTo(l - y, Math.max(m, r)),
											b.lineTo(l - y, p),
											b.stroke(),
											ea(
												b,
												v,
												Math.min(m, r),
												w,
												Math.max(m, r),
												e,
												0,
												null,
												!1,
												!1,
												!1,
												!1
											)))
									: "ohlc" === u.type &&
									  (c.moveTo(l - y, n),
									  c.lineTo(l - y, p),
									  c.stroke(),
									  c.beginPath(),
									  c.moveTo(l, m),
									  c.lineTo(v, m),
									  c.stroke(),
									  c.beginPath(),
									  c.moveTo(l, r),
									  c.lineTo(w, r),
									  c.stroke(),
									  t &&
											((e = U(D)),
											(b.strokeStyle = e),
											b.moveTo(l - y, n),
											b.lineTo(l - y, p),
											b.stroke(),
											b.beginPath(),
											b.moveTo(l, m),
											b.lineTo(v, m),
											b.stroke(),
											b.beginPath(),
											b.moveTo(l, r),
											b.lineTo(w, r),
											b.stroke()));
								(B[q].indexLabel ||
									u.indexLabel ||
									B[q].indexLabelFormatter ||
									u.indexLabelFormatter) &&
									this._indexLabels.push({
										chartType: u.type,
										dataPoint: B[q],
										dataSeries: u,
										point: { x: v + (w - v) / 2, y: a.axisY.reversed ? p : n },
										direction: 1,
										bounds: {
											x1: v,
											y1: Math.min(n, p),
											x2: w,
											y2: Math.max(n, p),
										},
										color: e,
									});
							}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(d.x1, d.y1, d.width, d.height),
					b.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.fadeInAnimation,
					easingFunction: z.easing.easeInQuad,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderBoxAndWhisker = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f,
				b = this._eventManager.ghostCtx;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var e = null,
					h = this.plotArea,
					d = 0,
					q,
					l,
					m,
					n,
					p,
					r,
					k,
					e = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1,
					d = this.options.dataPointMaxWidth
						? this.dataPointMaxWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 0.015 * this.width,
					x = a.axisX.dataInfo.minDiff;
				isFinite(x) || (x = 0.3 * Math.abs(a.axisX.range));
				x = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.7 *
							h.width *
							(a.axisX.logarithmic
								? Math.log(x) / Math.log(a.axisX.range)
								: Math.abs(x) / Math.abs(a.axisX.range))) <<
					  0;
				this.dataPointMaxWidth &&
					e > d &&
					(e = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						d
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					d < e &&
					(d = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						e
					));
				x < e && (x = e);
				x > d && (x = d);
				c.save();
				t && b.save();
				c.beginPath();
				c.rect(h.x1, h.y1, h.width, h.height);
				c.clip();
				t && (b.beginPath(), b.rect(h.x1, h.y1, h.width, h.height), b.clip());
				for (
					var s = !1, s = !!a.axisY.reversed, L = 0;
					L < a.dataSeriesIndexes.length;
					L++
				) {
					var u = a.dataSeriesIndexes[L],
						B = this.data[u],
						F = B.dataPoints;
					if (0 < F.length)
						for (
							var v = 5 < x && B.bevelEnabled ? !0 : !1, d = 0;
							d < F.length;
							d++
						)
							if (
								(F[d].getTime ? (k = F[d].x.getTime()) : (k = F[d].x),
								!(
									k < a.axisX.dataInfo.viewPortMin ||
									k > a.axisX.dataInfo.viewPortMax
								) &&
									!g(F[d].y) &&
									F[d].y.length &&
									"number" === typeof F[d].y[0] &&
									"number" === typeof F[d].y[1] &&
									"number" === typeof F[d].y[2] &&
									"number" === typeof F[d].y[3] &&
									"number" === typeof F[d].y[4] &&
									5 === F[d].y.length)
							) {
								q = a.axisX.convertValueToPixel(k);
								l = a.axisY.convertValueToPixel(F[d].y[0]);
								m = a.axisY.convertValueToPixel(F[d].y[1]);
								n = a.axisY.convertValueToPixel(F[d].y[2]);
								p = a.axisY.convertValueToPixel(F[d].y[3]);
								r = a.axisY.convertValueToPixel(F[d].y[4]);
								var w = (q - x / 2) << 0,
									C = (q + x / 2) << 0,
									e = F[d].color ? F[d].color : B._colorSet[0],
									y = Math.round(Math.max(1, 0.15 * x)),
									D = 0 === y % 2 ? 0 : 0.5,
									$ = F[d].whiskerColor
										? F[d].whiskerColor
										: F[d].color
										? B.whiskerColor
											? B.whiskerColor
											: F[d].color
										: B.whiskerColor
										? B.whiskerColor
										: e,
									X =
										"number" === typeof F[d].whiskerThickness
											? F[d].whiskerThickness
											: "number" === typeof B.options.whiskerThickness
											? B.whiskerThickness
											: y,
									ja = F[d].whiskerDashType
										? F[d].whiskerDashType
										: B.whiskerDashType,
									fa = g(F[d].whiskerLength)
										? g(B.options.whiskerLength)
											? x
											: B.whiskerLength
										: F[d].whiskerLength,
									fa =
										"number" === typeof fa
											? 0 >= fa
												? 0
												: fa >= x
												? x
												: fa
											: "string" === typeof fa
											? (parseInt(fa) * x) / 100 > x
												? x
												: (parseInt(fa) * x) / 100
											: x,
									la = 1 === Math.round(X) % 2 ? 0.5 : 0,
									va = F[d].stemColor
										? F[d].stemColor
										: F[d].color
										? B.stemColor
											? B.stemColor
											: F[d].color
										: B.stemColor
										? B.stemColor
										: e,
									pa =
										"number" === typeof F[d].stemThickness
											? F[d].stemThickness
											: "number" === typeof B.options.stemThickness
											? B.stemThickness
											: y,
									G = 1 === Math.round(pa) % 2 ? 0.5 : 0,
									E = F[d].stemDashType ? F[d].stemDashType : B.stemDashType,
									H = F[d].lineColor
										? F[d].lineColor
										: F[d].color
										? B.lineColor
											? B.lineColor
											: F[d].color
										: B.lineColor
										? B.lineColor
										: e,
									K =
										"number" === typeof F[d].lineThickness
											? F[d].lineThickness
											: "number" === typeof B.options.lineThickness
											? B.lineThickness
											: y,
									P = F[d].lineDashType ? F[d].lineDashType : B.lineDashType,
									T = 1 === Math.round(K) % 2 ? 0.5 : 0,
									N = B.upperBoxColor,
									Q = B.lowerBoxColor,
									R = g(B.options.fillOpacity) ? 1 : B.fillOpacity,
									V = B.dataPointIds[d];
								this._eventManager.objectMap[V] = {
									id: V,
									objectType: "dataPoint",
									dataSeriesIndex: u,
									dataPointIndex: d,
									x1: w,
									y1: l,
									x2: C,
									y2: m,
									x3: q,
									y3: n,
									x4: q,
									y4: p,
									y5: r,
									borderThickness: y,
									color: e,
									stemThickness: pa,
									stemColor: va,
									whiskerThickness: X,
									whiskerLength: fa,
									whiskerColor: $,
									lineThickness: K,
									lineColor: H,
								};
								c.save();
								0 < pa &&
									(c.beginPath(),
									(c.strokeStyle = va),
									(c.lineWidth = pa),
									c.setLineDash && c.setLineDash(S(E, pa)),
									c.moveTo(q - G, m),
									c.lineTo(q - G, l),
									c.stroke(),
									c.moveTo(q - G, p),
									c.lineTo(q - G, n),
									c.stroke());
								c.restore();
								b.lineWidth = Math.max(y, 4);
								c.beginPath();
								ea(
									c,
									w,
									Math.min(r, m),
									C,
									Math.max(m, r),
									Q,
									0,
									e,
									s ? v : !1,
									s ? !1 : v,
									!1,
									!1,
									R
								);
								c.beginPath();
								ea(
									c,
									w,
									Math.min(n, r),
									C,
									Math.max(r, n),
									N,
									0,
									e,
									s ? !1 : v,
									s ? v : !1,
									!1,
									!1,
									R
								);
								c.beginPath();
								c.lineWidth = y;
								c.strokeStyle = e;
								c.rect(
									w - D,
									Math.min(m, n) - D,
									C - w + 2 * D,
									Math.max(m, n) - Math.min(m, n) + 2 * D
								);
								c.stroke();
								c.save();
								0 < K &&
									(c.beginPath(),
									(c.globalAlpha = 1),
									c.setLineDash && c.setLineDash(S(P, K)),
									(c.strokeStyle = H),
									(c.lineWidth = K),
									c.moveTo(w, r - T),
									c.lineTo(C, r - T),
									c.stroke());
								c.restore();
								c.save();
								0 < X &&
									(c.beginPath(),
									c.setLineDash && c.setLineDash(S(ja, X)),
									(c.strokeStyle = $),
									(c.lineWidth = X),
									c.moveTo((q - fa / 2) << 0, p - la),
									c.lineTo((q + fa / 2) << 0, p - la),
									c.stroke(),
									c.moveTo((q - fa / 2) << 0, l + la),
									c.lineTo((q + fa / 2) << 0, l + la),
									c.stroke());
								c.restore();
								t &&
									((e = U(V)),
									(b.strokeStyle = e),
									(b.lineWidth = pa),
									0 < pa &&
										(b.moveTo(q - D - G, m),
										b.lineTo(q - D - G, Math.max(l, p)),
										b.stroke(),
										b.moveTo(q - D - G, Math.min(l, p)),
										b.lineTo(q - D - G, n),
										b.stroke()),
									ea(
										b,
										w,
										Math.max(m, n),
										C,
										Math.min(m, n),
										e,
										0,
										null,
										!1,
										!1,
										!1,
										!1
									),
									0 < X &&
										(b.beginPath(),
										(b.lineWidth = X),
										b.moveTo(q + fa / 2, p - la),
										b.lineTo(q - fa / 2, p - la),
										b.stroke(),
										b.moveTo(q + fa / 2, l + la),
										b.lineTo(q - fa / 2, l + la),
										b.stroke()));
								(F[d].indexLabel ||
									B.indexLabel ||
									F[d].indexLabelFormatter ||
									B.indexLabelFormatter) &&
									this._indexLabels.push({
										chartType: B.type,
										dataPoint: F[d],
										dataSeries: B,
										point: { x: w + (C - w) / 2, y: a.axisY.reversed ? l : p },
										direction: 1,
										bounds: {
											x1: w,
											y1: Math.min(l, p),
											x2: C,
											y2: Math.max(l, p),
										},
										color: e,
									});
							}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(h.x1, h.y1, h.width, h.height),
					b.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.fadeInAnimation,
					easingFunction: z.easing.easeInQuad,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderRangeColumn = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = null,
					e = this.plotArea,
					h = 0,
					d,
					q,
					l,
					h = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1;
				d = this.options.dataPointMaxWidth
					? this.dataPointMaxWidth
					: this.options.dataPointWidth
					? this.dataPointWidth
					: 0.03 * this.width;
				var m = a.axisX.dataInfo.minDiff;
				isFinite(m) || (m = 0.3 * Math.abs(a.axisX.range));
				m = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.9 *
							((e.width *
								(a.axisX.logarithmic
									? Math.log(m) / Math.log(a.axisX.range)
									: Math.abs(m) / Math.abs(a.axisX.range))) /
								a.plotType.totalDataSeries)) <<
					  0;
				this.dataPointMaxWidth &&
					h > d &&
					(h = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						d
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					d < h &&
					(d = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						h
					));
				m < h && (m = h);
				m > d && (m = d);
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(e.x1, e.y1, e.width, e.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.clip());
				for (var n = 0; n < a.dataSeriesIndexes.length; n++) {
					var p = a.dataSeriesIndexes[n],
						r = this.data[p],
						k = r.dataPoints;
					if (0 < k.length)
						for (
							var x = 5 < m && r.bevelEnabled ? !0 : !1, h = 0;
							h < k.length;
							h++
						)
							if (
								(k[h].getTime ? (l = k[h].x.getTime()) : (l = k[h].x),
								!(
									l < a.axisX.dataInfo.viewPortMin ||
									l > a.axisX.dataInfo.viewPortMax
								) &&
									!g(k[h].y) &&
									k[h].y.length &&
									"number" === typeof k[h].y[0] &&
									"number" === typeof k[h].y[1])
							) {
								b = a.axisX.convertValueToPixel(l);
								d = a.axisY.convertValueToPixel(k[h].y[0]);
								q = a.axisY.convertValueToPixel(k[h].y[1]);
								var s = a.axisX.reversed
										? (b +
												(a.plotType.totalDataSeries * m) / 2 -
												(a.previousDataSeriesCount + n) * m) <<
										  0
										: (b -
												(a.plotType.totalDataSeries * m) / 2 +
												(a.previousDataSeriesCount + n) * m) <<
										  0,
									L = a.axisX.reversed ? (s - m) << 0 : (s + m) << 0,
									b = k[h].color
										? k[h].color
										: r._colorSet[h % r._colorSet.length];
								if (d > q) {
									var u = d;
									d = q;
									q = u;
								}
								u = r.dataPointIds[h];
								this._eventManager.objectMap[u] = {
									id: u,
									objectType: "dataPoint",
									dataSeriesIndex: p,
									dataPointIndex: h,
									x1: s,
									y1: d,
									x2: L,
									y2: q,
								};
								ea(c, s, d, L, q, b, 0, b, x, x, !1, !1, r.fillOpacity);
								b = U(u);
								t &&
									ea(
										this._eventManager.ghostCtx,
										s,
										d,
										L,
										q,
										b,
										0,
										null,
										!1,
										!1,
										!1,
										!1
									);
								if (
									k[h].indexLabel ||
									r.indexLabel ||
									k[h].indexLabelFormatter ||
									r.indexLabelFormatter
								)
									this._indexLabels.push({
										chartType: "rangeColumn",
										dataPoint: k[h],
										dataSeries: r,
										indexKeyword: 0,
										point: {
											x: s + (L - s) / 2,
											y: k[h].y[1] >= k[h].y[0] ? q : d,
										},
										direction: k[h].y[1] >= k[h].y[0] ? -1 : 1,
										bounds: {
											x1: s,
											y1: Math.min(d, q),
											x2: L,
											y2: Math.max(d, q),
										},
										color: b,
									}),
										this._indexLabels.push({
											chartType: "rangeColumn",
											dataPoint: k[h],
											dataSeries: r,
											indexKeyword: 1,
											point: {
												x: s + (L - s) / 2,
												y: k[h].y[1] >= k[h].y[0] ? d : q,
											},
											direction: k[h].y[1] >= k[h].y[0] ? 1 : -1,
											bounds: {
												x1: s,
												y1: Math.min(d, q),
												x2: L,
												y2: Math.max(d, q),
											},
											color: b,
										});
							}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.fadeInAnimation,
					easingFunction: z.easing.easeInQuad,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderError = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f,
				b = a.axisY._position
					? "left" === a.axisY._position || "right" === a.axisY._position
						? !1
						: !0
					: !1;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var e = null,
					h = !1,
					d = this.plotArea,
					q = 0,
					l,
					m,
					n,
					p,
					r,
					k,
					x,
					s = a.axisX.dataInfo.minDiff;
				isFinite(s) || (s = 0.3 * Math.abs(a.axisX.range));
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(d.x1, d.y1, d.width, d.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(d.x1, d.y1, d.width, d.height),
					this._eventManager.ghostCtx.clip());
				for (var L = 0, u = 0; u < this.data.length; u++)
					!this.data[u].type.match(/(bar|column)/gi) ||
						!this.data[u].visible ||
						(this.data[u].type.match(/(stacked)/gi) && L) ||
						L++;
				for (var B = 0; B < a.dataSeriesIndexes.length; B++) {
					var F = a.dataSeriesIndexes[B],
						w = this.data[F],
						v = w.dataPoints,
						C = g(w._linkedSeries)
							? !1
							: w._linkedSeries.type.match(/(bar|column)/gi) &&
							  w._linkedSeries.visible
							? !0
							: !1,
						y = 0;
					if (C)
						for (e = w._linkedSeries.id, u = 0; u < e; u++)
							!this.data[u].type.match(/(bar|column)/gi) ||
								!this.data[u].visible ||
								(this.data[u].type.match(/(stacked)/gi) && y) ||
								(this.data[u].type.match(/(range)/gi) && (h = !0), y++);
					e = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1;
					q = this.options.dataPointMaxWidth
						? this.dataPointMaxWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: b
						? Math.min(
								0.15 * this.height,
								0.9 * (this.plotArea.height / (C ? L : 1))
						  ) << 0
						: 0.3 * this.width;
					h &&
						(q = this.options.dataPointMaxWidth
							? this.dataPointMaxWidth
							: this.options.dataPointWidth
							? this.dataPointWidth
							: b
							? Math.min(
									0.15 * this.height,
									0.9 * (this.plotArea.height / (C ? L : 1))
							  ) << 0
							: 0.03 * this.width);
					u = this.options.dataPointWidth
						? this.dataPointWidth
						: (0.9 *
								(((b ? d.height : d.width) *
									(a.axisX.logarithmic
										? Math.log(s) / Math.log(a.axisX.range)
										: Math.abs(s) / Math.abs(a.axisX.range))) /
									(C ? L : 1))) <<
						  0;
					this.dataPointMaxWidth &&
						e > q &&
						(e = Math.min(
							this.options.dataPointWidth ? this.dataPointWidth : Infinity,
							q
						));
					!this.dataPointMaxWidth &&
						this.dataPointMinWidth &&
						q < e &&
						(q = Math.max(
							this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
							e
						));
					u < e && (u = e);
					u > q && (u = q);
					if (0 < v.length)
						for (var D = w._colorSet, q = 0; q < v.length; q++) {
							var e = (w.lineColor = w.options.color ? w.options.color : D[0]),
								$ = {
									color: v[q].whiskerColor
										? v[q].whiskerColor
										: v[q].color
										? w.whiskerColor
											? w.whiskerColor
											: v[q].color
										: w.whiskerColor
										? w.whiskerColor
										: e,
									thickness: g(v[q].whiskerThickness)
										? w.whiskerThickness
										: v[q].whiskerThickness,
									dashType: v[q].whiskerDashType
										? v[q].whiskerDashType
										: w.whiskerDashType,
									length: g(v[q].whiskerLength)
										? g(w.options.whiskerLength)
											? u
											: w.options.whiskerLength
										: v[q].whiskerLength,
									trimLength: g(v[q].whiskerLength)
										? g(w.options.whiskerLength)
											? 50
											: 0
										: 0,
								};
							$.length =
								"number" === typeof $.length
									? 0 >= $.length
										? 0
										: $.length >= u
										? u
										: $.length
									: "string" === typeof $.length
									? (parseInt($.length) * u) / 100 > u
										? u
										: (parseInt($.length) * u) / 100 > u
									: u;
							$.thickness =
								"number" === typeof $.thickness
									? 0 > $.thickness
										? 0
										: Math.round($.thickness)
									: 2;
							var X = {
								color: v[q].stemColor
									? v[q].stemColor
									: v[q].color
									? w.stemColor
										? w.stemColor
										: v[q].color
									: w.stemColor
									? w.stemColor
									: e,
								thickness: v[q].stemThickness
									? v[q].stemThickness
									: w.stemThickness,
								dashType: v[q].stemDashType
									? v[q].stemDashType
									: w.stemDashType,
							};
							X.thickness =
								"number" === typeof X.thickness
									? 0 > X.thickness
										? 0
										: Math.round(X.thickness)
									: 2;
							v[q].getTime ? (x = v[q].x.getTime()) : (x = v[q].x);
							if (
								!(
									x < a.axisX.dataInfo.viewPortMin ||
									x > a.axisX.dataInfo.viewPortMax
								) &&
								!g(v[q].y) &&
								v[q].y.length &&
								"number" === typeof v[q].y[0] &&
								"number" === typeof v[q].y[1]
							) {
								var ja = a.axisX.convertValueToPixel(x);
								b ? (m = ja) : (l = ja);
								ja = a.axisY.convertValueToPixel(v[q].y[0]);
								b ? (n = ja) : (r = ja);
								ja = a.axisY.convertValueToPixel(v[q].y[1]);
								b ? (p = ja) : (k = ja);
								b
									? ((r = a.axisX.reversed
											? (m + ((C ? L : 1) * u) / 2 - (C ? y - 1 : 0) * u) << 0
											: (m - ((C ? L : 1) * u) / 2 + (C ? y - 1 : 0) * u) << 0),
									  (k = a.axisX.reversed ? (r - u) << 0 : (r + u) << 0))
									: ((n = a.axisX.reversed
											? (l + ((C ? L : 1) * u) / 2 - (C ? y - 1 : 0) * u) << 0
											: (l - ((C ? L : 1) * u) / 2 + (C ? y - 1 : 0) * u) << 0),
									  (p = a.axisX.reversed ? (n - u) << 0 : (n + u) << 0));
								!b && r > k && ((ja = r), (r = k), (k = ja));
								b && n > p && ((ja = n), (n = p), (p = ja));
								ja = w.dataPointIds[q];
								this._eventManager.objectMap[ja] = {
									id: ja,
									objectType: "dataPoint",
									dataSeriesIndex: F,
									dataPointIndex: q,
									x1: Math.min(n, p),
									y1: Math.min(r, k),
									x2: Math.max(p, n),
									y2: Math.max(k, r),
									isXYSwapped: b,
									stemProperties: X,
									whiskerProperties: $,
								};
								E(
									c,
									Math.min(n, p),
									Math.min(r, k),
									Math.max(p, n),
									Math.max(k, r),
									e,
									$,
									X,
									b
								);
								t && E(this._eventManager.ghostCtx, n, r, p, k, e, $, X, b);
								if (
									v[q].indexLabel ||
									w.indexLabel ||
									v[q].indexLabelFormatter ||
									w.indexLabelFormatter
								)
									this._indexLabels.push({
										chartType: "error",
										dataPoint: v[q],
										dataSeries: w,
										indexKeyword: 0,
										point: {
											x: b ? (v[q].y[1] >= v[q].y[0] ? n : p) : n + (p - n) / 2,
											y: b ? r + (k - r) / 2 : v[q].y[1] >= v[q].y[0] ? k : r,
										},
										direction: v[q].y[1] >= v[q].y[0] ? -1 : 1,
										bounds: {
											x1: b ? Math.min(n, p) : n,
											y1: b ? r : Math.min(r, k),
											x2: b ? Math.max(n, p) : p,
											y2: b ? k : Math.max(r, k),
										},
										color: e,
										axisSwapped: b,
									}),
										this._indexLabels.push({
											chartType: "error",
											dataPoint: v[q],
											dataSeries: w,
											indexKeyword: 1,
											point: {
												x: b
													? v[q].y[1] >= v[q].y[0]
														? p
														: n
													: n + (p - n) / 2,
												y: b ? r + (k - r) / 2 : v[q].y[1] >= v[q].y[0] ? r : k,
											},
											direction: v[q].y[1] >= v[q].y[0] ? 1 : -1,
											bounds: {
												x1: b ? Math.min(n, p) : n,
												y1: b ? r : Math.min(r, k),
												x2: b ? Math.max(n, p) : p,
												y2: b ? k : Math.max(r, k),
											},
											color: e,
											axisSwapped: b,
										});
							}
						}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(d.x1, d.y1, d.width, d.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.fadeInAnimation,
					easingFunction: z.easing.easeInQuad,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderRangeBar = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = null,
					e = this.plotArea,
					h = 0,
					d,
					q,
					l,
					m,
					h = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1;
				d = this.options.dataPointMaxWidth
					? this.dataPointMaxWidth
					: this.options.dataPointWidth
					? this.dataPointWidth
					: Math.min(
							0.15 * this.height,
							0.9 * (this.plotArea.height / a.plotType.totalDataSeries)
					  ) << 0;
				var n = a.axisX.dataInfo.minDiff;
				isFinite(n) || (n = 0.3 * Math.abs(a.axisX.range));
				n = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.9 *
							((e.height *
								(a.axisX.logarithmic
									? Math.log(n) / Math.log(a.axisX.range)
									: Math.abs(n) / Math.abs(a.axisX.range))) /
								a.plotType.totalDataSeries)) <<
					  0;
				this.dataPointMaxWidth &&
					h > d &&
					(h = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						d
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					d < h &&
					(d = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						h
					));
				n < h && (n = h);
				n > d && (n = d);
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(e.x1, e.y1, e.width, e.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.clip());
				for (var p = 0; p < a.dataSeriesIndexes.length; p++) {
					var r = a.dataSeriesIndexes[p],
						k = this.data[r],
						x = k.dataPoints;
					if (0 < x.length) {
						var s = 5 < n && k.bevelEnabled ? !0 : !1;
						c.strokeStyle = "#4572A7 ";
						for (h = 0; h < x.length; h++)
							if (
								(x[h].getTime ? (m = x[h].x.getTime()) : (m = x[h].x),
								!(
									m < a.axisX.dataInfo.viewPortMin ||
									m > a.axisX.dataInfo.viewPortMax
								) &&
									!g(x[h].y) &&
									x[h].y.length &&
									"number" === typeof x[h].y[0] &&
									"number" === typeof x[h].y[1])
							) {
								d = a.axisY.convertValueToPixel(x[h].y[0]);
								q = a.axisY.convertValueToPixel(x[h].y[1]);
								l = a.axisX.convertValueToPixel(m);
								l = a.axisX.reversed
									? (l +
											(a.plotType.totalDataSeries * n) / 2 -
											(a.previousDataSeriesCount + p) * n) <<
									  0
									: (l -
											(a.plotType.totalDataSeries * n) / 2 +
											(a.previousDataSeriesCount + p) * n) <<
									  0;
								var v = a.axisX.reversed ? (l - n) << 0 : (l + n) << 0;
								d > q && ((b = d), (d = q), (q = b));
								b = x[h].color
									? x[h].color
									: k._colorSet[h % k._colorSet.length];
								ea(c, d, l, q, v, b, 0, null, s, !1, !1, !1, k.fillOpacity);
								b = k.dataPointIds[h];
								this._eventManager.objectMap[b] = {
									id: b,
									objectType: "dataPoint",
									dataSeriesIndex: r,
									dataPointIndex: h,
									x1: d,
									y1: l,
									x2: q,
									y2: v,
								};
								b = U(b);
								t &&
									ea(
										this._eventManager.ghostCtx,
										d,
										l,
										q,
										v,
										b,
										0,
										null,
										!1,
										!1,
										!1,
										!1
									);
								if (
									x[h].indexLabel ||
									k.indexLabel ||
									x[h].indexLabelFormatter ||
									k.indexLabelFormatter
								)
									this._indexLabels.push({
										chartType: "rangeBar",
										dataPoint: x[h],
										dataSeries: k,
										indexKeyword: 0,
										point: {
											x: x[h].y[1] >= x[h].y[0] ? d : q,
											y: l + (v - l) / 2,
										},
										direction: x[h].y[1] >= x[h].y[0] ? -1 : 1,
										bounds: {
											x1: Math.min(d, q),
											y1: l,
											x2: Math.max(d, q),
											y2: v,
										},
										color: b,
									}),
										this._indexLabels.push({
											chartType: "rangeBar",
											dataPoint: x[h],
											dataSeries: k,
											indexKeyword: 1,
											point: {
												x: x[h].y[1] >= x[h].y[0] ? q : d,
												y: l + (v - l) / 2,
											},
											direction: x[h].y[1] >= x[h].y[0] ? 1 : -1,
											bounds: {
												x1: Math.min(d, q),
												y1: l,
												x2: Math.max(d, q),
												y2: v,
											},
											color: b,
										});
							}
					}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(e.x1, e.y1, e.width, e.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.fadeInAnimation,
					easingFunction: z.easing.easeInQuad,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderRangeArea = function (a) {
			function f() {
				if (B) {
					for (var a = null, c = q.length - 1; 0 <= c; c--)
						(a = q[c]), b.lineTo(a.x, a.y2), e.lineTo(a.x, a.y2);
					b.closePath();
					b.globalAlpha = n.fillOpacity;
					b.fill();
					b.globalAlpha = 1;
					e.fill();
					if (0 < n.lineThickness) {
						b.beginPath();
						b.moveTo(a.x, a.y2);
						for (c = 0; c < q.length; c++) (a = q[c]), b.lineTo(a.x, a.y2);
						b.moveTo(q[0].x, q[0].y1);
						for (c = 0; c < q.length; c++) (a = q[c]), b.lineTo(a.x, a.y1);
						b.stroke();
					}
					b.beginPath();
					b.moveTo(x, l);
					e.beginPath();
					e.moveTo(x, l);
					B = { x: x, y: l };
					q = [];
					q.push({ x: x, y1: l, y2: v });
				}
			}
			var c = a.targetCanvasCtx || this.plotArea.ctx,
				b = t ? this._preRenderCtx : c;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var e = this._eventManager.ghostCtx,
					h = [],
					g = this.plotArea;
				b.save();
				t && e.save();
				b.beginPath();
				b.rect(g.x1, g.y1, g.width, g.height);
				b.clip();
				t && (e.beginPath(), e.rect(g.x1, g.y1, g.width, g.height), e.clip());
				for (var d = 0; d < a.dataSeriesIndexes.length; d++) {
					var q = [],
						m = a.dataSeriesIndexes[d],
						n = this.data[m],
						p = n.dataPoints,
						h = n.id;
					this._eventManager.objectMap[h] = {
						objectType: "dataSeries",
						dataSeriesIndex: m,
					};
					h = U(h);
					e.fillStyle = h;
					var h = [],
						r = !0,
						k = 0,
						x,
						l,
						v,
						u,
						B = null;
					if (0 < p.length) {
						var w = n._colorSet[k % n._colorSet.length],
							y = (n.lineColor = n.options.lineColor || w),
							D = y;
						b.fillStyle = w;
						b.strokeStyle = y;
						b.lineWidth = n.lineThickness;
						var C = "solid";
						if (b.setLineDash) {
							var H = S(n.nullDataLineDashType, n.lineThickness),
								C = n.lineDashType,
								E = S(C, n.lineThickness);
							b.setLineDash(E);
						}
						for (var $ = !0; k < p.length; k++)
							if (
								((u = p[k].x.getTime ? p[k].x.getTime() : p[k].x),
								!(
									u < a.axisX.dataInfo.viewPortMin ||
									(u > a.axisX.dataInfo.viewPortMax &&
										(!n.connectNullData || !$))
								))
							)
								if (
									null !== p[k].y &&
									p[k].y.length &&
									"number" === typeof p[k].y[0] &&
									"number" === typeof p[k].y[1]
								) {
									x = a.axisX.convertValueToPixel(u);
									l = a.axisY.convertValueToPixel(p[k].y[0]);
									v = a.axisY.convertValueToPixel(p[k].y[1]);
									r || $
										? (n.connectNullData && !r
												? (b.setLineDash &&
														(n.options.nullDataLineDashType ||
															(C === n.lineDashType &&
																n.lineDashType !== n.nullDataLineDashType)) &&
														((q[q.length - 1].newLineDashArray = E),
														(C = n.nullDataLineDashType),
														b.setLineDash(H)),
												  b.lineTo(x, l),
												  t && e.lineTo(x, l),
												  q.push({ x: x, y1: l, y2: v }))
												: (b.beginPath(),
												  b.moveTo(x, l),
												  (B = { x: x, y: l }),
												  (q = []),
												  q.push({ x: x, y1: l, y2: v }),
												  t && (e.beginPath(), e.moveTo(x, l))),
										  ($ = r = !1))
										: (b.lineTo(x, l),
										  q.push({ x: x, y1: l, y2: v }),
										  t && e.lineTo(x, l),
										  0 == k % 250 && f());
									u = n.dataPointIds[k];
									this._eventManager.objectMap[u] = {
										id: u,
										objectType: "dataPoint",
										dataSeriesIndex: m,
										dataPointIndex: k,
										x1: x,
										y1: l,
										y2: v,
									};
									k < p.length - 1 &&
										(D !== (p[k].lineColor || y) ||
											C !== (p[k].lineDashType || n.lineDashType)) &&
										(f(),
										(D = p[k].lineColor || y),
										(q[q.length - 1].newStrokeStyle = D),
										(b.strokeStyle = D),
										b.setLineDash &&
											(p[k].lineDashType
												? ((C = p[k].lineDashType),
												  (q[q.length - 1].newLineDashArray = S(
														C,
														n.lineThickness
												  )),
												  b.setLineDash(q[q.length - 1].newLineDashArray))
												: ((C = n.lineDashType),
												  (q[q.length - 1].newLineDashArray = E),
												  b.setLineDash(E))));
									if (
										0 !== p[k].markerSize &&
										(0 < p[k].markerSize || 0 < n.markerSize)
									) {
										var X = n.getMarkerProperties(k, x, v, b);
										h.push(X);
										var ja = U(u);
										t &&
											h.push({
												x: x,
												y: v,
												ctx: e,
												type: X.type,
												size: X.size,
												color: ja,
												borderColor: ja,
												borderThickness: X.borderThickness,
											});
										X = n.getMarkerProperties(k, x, l, b);
										h.push(X);
										ja = U(u);
										t &&
											h.push({
												x: x,
												y: l,
												ctx: e,
												type: X.type,
												size: X.size,
												color: ja,
												borderColor: ja,
												borderThickness: X.borderThickness,
											});
									}
									if (
										p[k].indexLabel ||
										n.indexLabel ||
										p[k].indexLabelFormatter ||
										n.indexLabelFormatter
									)
										this._indexLabels.push({
											chartType: "rangeArea",
											dataPoint: p[k],
											dataSeries: n,
											indexKeyword: 0,
											point: { x: x, y: l },
											direction:
												p[k].y[0] > p[k].y[1] === a.axisY.reversed ? -1 : 1,
											color: w,
										}),
											this._indexLabels.push({
												chartType: "rangeArea",
												dataPoint: p[k],
												dataSeries: n,
												indexKeyword: 1,
												point: { x: x, y: v },
												direction:
													p[k].y[0] > p[k].y[1] === a.axisY.reversed ? 1 : -1,
												color: w,
											});
								} else $ || r || f(), ($ = !0);
						f();
						s.drawMarkers(h);
					}
				}
				t &&
					(c.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(b.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					b.clearRect(g.x1, g.y1, g.width, g.height),
					this._eventManager.ghostCtx.restore());
				b.restore();
				return {
					source: c,
					dest: this.plotArea.ctx,
					animationCallback: z.xClipAnimation,
					easingFunction: z.easing.linear,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderRangeSplineArea = function (a) {
			function f(a, c) {
				var f = w(l, 2);
				if (0 < f.length) {
					if (0 < m.lineThickness) {
						b.strokeStyle = c;
						b.setLineDash && b.setLineDash(a);
						b.beginPath();
						b.moveTo(f[0].x, f[0].y);
						for (var h = 0; h < f.length - 3; h += 3) {
							if (f[h].newStrokeStyle || f[h].newLineDashArray)
								b.stroke(),
									b.beginPath(),
									b.moveTo(f[h].x, f[h].y),
									f[h].newStrokeStyle && (b.strokeStyle = f[h].newStrokeStyle),
									f[h].newLineDashArray && b.setLineDash(f[h].newLineDashArray);
							b.bezierCurveTo(
								f[h + 1].x,
								f[h + 1].y,
								f[h + 2].x,
								f[h + 2].y,
								f[h + 3].x,
								f[h + 3].y
							);
						}
					}
					b.beginPath();
					b.moveTo(f[0].x, f[0].y);
					t && (e.beginPath(), e.moveTo(f[0].x, f[0].y));
					for (h = 0; h < f.length - 3; h += 3)
						b.bezierCurveTo(
							f[h + 1].x,
							f[h + 1].y,
							f[h + 2].x,
							f[h + 2].y,
							f[h + 3].x,
							f[h + 3].y
						),
							t &&
								e.bezierCurveTo(
									f[h + 1].x,
									f[h + 1].y,
									f[h + 2].x,
									f[h + 2].y,
									f[h + 3].x,
									f[h + 3].y
								);
					f = w(v, 2);
					b.lineTo(v[v.length - 1].x, v[v.length - 1].y);
					for (h = f.length - 1; 2 < h; h -= 3)
						b.bezierCurveTo(
							f[h - 1].x,
							f[h - 1].y,
							f[h - 2].x,
							f[h - 2].y,
							f[h - 3].x,
							f[h - 3].y
						),
							t &&
								e.bezierCurveTo(
									f[h - 1].x,
									f[h - 1].y,
									f[h - 2].x,
									f[h - 2].y,
									f[h - 3].x,
									f[h - 3].y
								);
					b.closePath();
					b.globalAlpha = m.fillOpacity;
					b.fill();
					t && (e.closePath(), e.fill());
					b.globalAlpha = 1;
					if (0 < m.lineThickness) {
						b.strokeStyle = c;
						b.setLineDash && b.setLineDash(a);
						b.beginPath();
						b.moveTo(f[0].x, f[0].y);
						for (var k = (h = 0); h < f.length - 3; h += 3, k++) {
							if (l[k].newStrokeStyle || l[k].newLineDashArray)
								b.stroke(),
									b.beginPath(),
									b.moveTo(f[h].x, f[h].y),
									l[k].newStrokeStyle && (b.strokeStyle = l[k].newStrokeStyle),
									l[k].newLineDashArray && b.setLineDash(l[k].newLineDashArray);
							b.bezierCurveTo(
								f[h + 1].x,
								f[h + 1].y,
								f[h + 2].x,
								f[h + 2].y,
								f[h + 3].x,
								f[h + 3].y
							);
						}
						f = w(l, 2);
						b.moveTo(f[0].x, f[0].y);
						for (k = h = 0; h < f.length - 3; h += 3, k++) {
							if (l[k].newStrokeStyle || l[k].newLineDashArray)
								b.stroke(),
									b.beginPath(),
									b.moveTo(f[h].x, f[h].y),
									l[k].newStrokeStyle && (b.strokeStyle = l[k].newStrokeStyle),
									l[k].newLineDashArray && b.setLineDash(l[k].newLineDashArray);
							b.bezierCurveTo(
								f[h + 1].x,
								f[h + 1].y,
								f[h + 2].x,
								f[h + 2].y,
								f[h + 3].x,
								f[h + 3].y
							);
						}
						b.stroke();
					}
					b.beginPath();
				}
			}
			var c = a.targetCanvasCtx || this.plotArea.ctx,
				b = t ? this._preRenderCtx : c;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var e = this._eventManager.ghostCtx,
					h = [],
					g = this.plotArea;
				b.save();
				t && e.save();
				b.beginPath();
				b.rect(g.x1, g.y1, g.width, g.height);
				b.clip();
				t && (e.beginPath(), e.rect(g.x1, g.y1, g.width, g.height), e.clip());
				for (var d = 0; d < a.dataSeriesIndexes.length; d++) {
					var q = a.dataSeriesIndexes[d],
						m = this.data[q],
						n = m.dataPoints,
						h = m.id;
					this._eventManager.objectMap[h] = {
						objectType: "dataSeries",
						dataSeriesIndex: q,
					};
					h = U(h);
					e.fillStyle = h;
					var h = [],
						p = 0,
						r,
						k,
						x,
						l = [],
						v = [];
					if (0 < n.length) {
						var u = m._colorSet[p % m._colorSet.length],
							B = (m.lineColor = m.options.lineColor || u),
							F = B;
						b.fillStyle = u;
						b.lineWidth = m.lineThickness;
						var y = "solid",
							D;
						if (b.setLineDash) {
							var C = S(m.nullDataLineDashType, m.lineThickness),
								y = m.lineDashType;
							D = S(y, m.lineThickness);
						}
						for (k = !1; p < n.length; p++)
							if (
								((r = n[p].x.getTime ? n[p].x.getTime() : n[p].x),
								!(
									r < a.axisX.dataInfo.viewPortMin ||
									(r > a.axisX.dataInfo.viewPortMax &&
										(!m.connectNullData || !k))
								))
							)
								if (
									null !== n[p].y &&
									n[p].y.length &&
									"number" === typeof n[p].y[0] &&
									"number" === typeof n[p].y[1]
								) {
									r = a.axisX.convertValueToPixel(r);
									k = a.axisY.convertValueToPixel(n[p].y[0]);
									x = a.axisY.convertValueToPixel(n[p].y[1]);
									var E = m.dataPointIds[p];
									this._eventManager.objectMap[E] = {
										id: E,
										objectType: "dataPoint",
										dataSeriesIndex: q,
										dataPointIndex: p,
										x1: r,
										y1: k,
										y2: x,
									};
									l[l.length] = { x: r, y: k };
									v[v.length] = { x: r, y: x };
									p < n.length - 1 &&
										(F !== (n[p].lineColor || B) ||
											y !== (n[p].lineDashType || m.lineDashType)) &&
										((F = n[p].lineColor || B),
										(l[l.length - 1].newStrokeStyle = F),
										b.setLineDash &&
											(n[p].lineDashType
												? ((y = n[p].lineDashType),
												  (l[l.length - 1].newLineDashArray = S(
														y,
														m.lineThickness
												  )))
												: ((y = m.lineDashType),
												  (l[l.length - 1].newLineDashArray = D))));
									if (
										0 !== n[p].markerSize &&
										(0 < n[p].markerSize || 0 < m.markerSize)
									) {
										var K = m.getMarkerProperties(p, r, k, b);
										h.push(K);
										var $ = U(E);
										t &&
											h.push({
												x: r,
												y: k,
												ctx: e,
												type: K.type,
												size: K.size,
												color: $,
												borderColor: $,
												borderThickness: K.borderThickness,
											});
										K = m.getMarkerProperties(p, r, x, b);
										h.push(K);
										$ = U(E);
										t &&
											h.push({
												x: r,
												y: x,
												ctx: e,
												type: K.type,
												size: K.size,
												color: $,
												borderColor: $,
												borderThickness: K.borderThickness,
											});
									}
									if (
										n[p].indexLabel ||
										m.indexLabel ||
										n[p].indexLabelFormatter ||
										m.indexLabelFormatter
									)
										this._indexLabels.push({
											chartType: "rangeSplineArea",
											dataPoint: n[p],
											dataSeries: m,
											indexKeyword: 0,
											point: { x: r, y: k },
											direction: n[p].y[0] <= n[p].y[1] ? -1 : 1,
											color: u,
										}),
											this._indexLabels.push({
												chartType: "rangeSplineArea",
												dataPoint: n[p],
												dataSeries: m,
												indexKeyword: 1,
												point: { x: r, y: x },
												direction: n[p].y[0] <= n[p].y[1] ? 1 : -1,
												color: u,
											});
									k = !1;
								} else
									0 < p &&
										!k &&
										(m.connectNullData
											? b.setLineDash &&
											  0 < l.length &&
											  (m.options.nullDataLineDashType ||
													!n[p - 1].lineDashType) &&
											  ((l[l.length - 1].newLineDashArray = C),
											  (y = m.nullDataLineDashType))
											: (f(D, B), (l = []), (v = []))),
										(k = !0);
						f(D, B);
						s.drawMarkers(h);
					}
				}
				t &&
					(c.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(b.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					b.clearRect(g.x1, g.y1, g.width, g.height),
					this._eventManager.ghostCtx.restore());
				b.restore();
				return {
					source: c,
					dest: this.plotArea.ctx,
					animationCallback: z.xClipAnimation,
					easingFunction: z.easing.linear,
					animationBase: 0,
				};
			}
		};
		v.prototype.renderWaterfall = function (a) {
			var f = a.targetCanvasCtx || this.plotArea.ctx,
				c = t ? this._preRenderCtx : f;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var b = this._eventManager.ghostCtx,
					e = null,
					h = this.plotArea,
					g = 0,
					d,
					q,
					m,
					n,
					p = a.axisY.convertValueToPixel(
						a.axisY.logarithmic ? a.axisY.viewportMinimum : 0
					),
					g = this.options.dataPointMinWidth
						? this.dataPointMinWidth
						: this.options.dataPointWidth
						? this.dataPointWidth
						: 1;
				q = this.options.dataPointMaxWidth
					? this.dataPointMaxWidth
					: this.options.dataPointWidth
					? this.dataPointWidth
					: Math.min(
							0.15 * this.width,
							0.9 * (this.plotArea.width / a.plotType.totalDataSeries)
					  ) << 0;
				var r = a.axisX.dataInfo.minDiff;
				isFinite(r) || (r = 0.3 * Math.abs(a.axisX.range));
				r = this.options.dataPointWidth
					? this.dataPointWidth
					: (0.6 *
							((h.width *
								(a.axisX.logarithmic
									? Math.log(r) / Math.log(a.axisX.range)
									: Math.abs(r) / Math.abs(a.axisX.range))) /
								a.plotType.totalDataSeries)) <<
					  0;
				this.dataPointMaxWidth &&
					g > q &&
					(g = Math.min(
						this.options.dataPointWidth ? this.dataPointWidth : Infinity,
						q
					));
				!this.dataPointMaxWidth &&
					this.dataPointMinWidth &&
					q < g &&
					(q = Math.max(
						this.options.dataPointWidth ? this.dataPointWidth : -Infinity,
						g
					));
				r < g && (r = g);
				r > q && (r = q);
				c.save();
				t && this._eventManager.ghostCtx.save();
				c.beginPath();
				c.rect(h.x1, h.y1, h.width, h.height);
				c.clip();
				t &&
					(this._eventManager.ghostCtx.beginPath(),
					this._eventManager.ghostCtx.rect(h.x1, h.y1, h.width, h.height),
					this._eventManager.ghostCtx.clip());
				for (var k = 0; k < a.dataSeriesIndexes.length; k++) {
					var x = a.dataSeriesIndexes[k],
						l = this.data[x],
						s = l.dataPoints,
						e = l._colorSet[0];
					l.risingColor = l.options.risingColor ? l.options.risingColor : e;
					l.fallingColor = l.options.fallingColor
						? l.options.fallingColor
						: "#e40a0a";
					var u =
							"number" === typeof l.options.lineThickness
								? Math.round(l.lineThickness)
								: 1,
						B = 1 === Math.round(u) % 2 ? -0.5 : 0;
					if (0 < s.length)
						for (
							var v = 5 < r && l.bevelEnabled ? !0 : !1,
								w = !1,
								y = null,
								C = null,
								g = 0;
							g < s.length;
							g++
						)
							if (
								(s[g].getTime ? (n = s[g].x.getTime()) : (n = s[g].x),
								"number" !== typeof s[g].y)
							) {
								if (0 < g && !w && l.connectNullData)
									var D =
										l.options.nullDataLineDashType || !s[g - 1].lineDashType
											? l.nullDataLineDashType
											: s[g - 1].lineDashType;
								w = !0;
							} else {
								d = a.axisX.convertValueToPixel(n);
								q =
									0 === l.dataPointEOs[g].cumulativeSum
										? p
										: a.axisY.convertValueToPixel(
												l.dataPointEOs[g].cumulativeSum
										  );
								m =
									0 === l.dataPointEOs[g].cumulativeSumYStartValue
										? p
										: a.axisY.convertValueToPixel(
												l.dataPointEOs[g].cumulativeSumYStartValue
										  );
								d = a.axisX.reversed
									? (d +
											(a.plotType.totalDataSeries * r) / 2 -
											(a.previousDataSeriesCount + k) * r) <<
									  0
									: (d -
											(a.plotType.totalDataSeries * r) / 2 +
											(a.previousDataSeriesCount + k) * r) <<
									  0;
								var E = a.axisX.reversed ? (d - r) << 0 : (d + r) << 0;
								q > m && ((e = q), (q = m), (m = e));
								a.axisY.reversed && ((e = q), (q = m), (m = e));
								e = l.dataPointIds[g];
								this._eventManager.objectMap[e] = {
									id: e,
									objectType: "dataPoint",
									dataSeriesIndex: x,
									dataPointIndex: g,
									x1: d,
									y1: q,
									x2: E,
									y2: m,
								};
								var $ = s[g].color
									? s[g].color
									: 0 < s[g].y
									? l.risingColor
									: l.fallingColor;
								ea(c, d, q, E, m, $, 0, $, v, v, !1, !1, l.fillOpacity);
								e = U(e);
								t &&
									ea(
										this._eventManager.ghostCtx,
										d,
										q,
										E,
										m,
										e,
										0,
										null,
										!1,
										!1,
										!1,
										!1
									);
								var X,
									$ = d;
								X =
									("undefined" !== typeof s[g].isIntermediateSum &&
										!0 === s[g].isIntermediateSum) ||
									("undefined" !== typeof s[g].isCumulativeSum &&
										!0 === s[g].isCumulativeSum)
										? 0 < s[g].y
											? q
											: m
										: 0 < s[g].y
										? m
										: q;
								0 < g &&
									y &&
									(!w || l.connectNullData) &&
									(w && c.setLineDash && c.setLineDash(S(D, u)),
									c.beginPath(),
									c.moveTo(y, C - B),
									c.lineTo($, X - B),
									0 < u && c.stroke(),
									t &&
										(b.beginPath(),
										b.moveTo(y, C - B),
										b.lineTo($, X - B),
										0 < u && b.stroke()));
								w = !1;
								y = E;
								C = 0 < s[g].y ? q : m;
								$ = s[g].lineDashType
									? s[g].lineDashType
									: l.options.lineDashType
									? l.options.lineDashType
									: "shortDash";
								c.strokeStyle = s[g].lineColor
									? s[g].lineColor
									: l.options.lineColor
									? l.options.lineColor
									: "#9e9e9e";
								c.lineWidth = u;
								c.setLineDash && (($ = S($, u)), c.setLineDash($));
								(s[g].indexLabel ||
									l.indexLabel ||
									s[g].indexLabelFormatter ||
									l.indexLabelFormatter) &&
									this._indexLabels.push({
										chartType: "waterfall",
										dataPoint: s[g],
										dataSeries: l,
										point: { x: d + (E - d) / 2, y: 0 <= s[g].y ? q : m },
										direction: 0 > s[g].y === a.axisY.reversed ? 1 : -1,
										bounds: {
											x1: d,
											y1: Math.min(q, m),
											x2: E,
											y2: Math.max(q, m),
										},
										color: e,
									});
							}
				}
				t &&
					(f.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
					(c.globalCompositeOperation = "source-atop"),
					a.axisX.maskCanvas &&
						c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
					a.axisY.maskCanvas &&
						c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
					this._breaksCanvasCtx &&
						this._breaksCanvasCtx.drawImage(
							this._preRenderCanvas,
							0,
							0,
							this.width,
							this.height
						),
					c.clearRect(h.x1, h.y1, h.width, h.height),
					this._eventManager.ghostCtx.restore());
				c.restore();
				return {
					source: f,
					dest: this.plotArea.ctx,
					animationCallback: z.fadeInAnimation,
					easingFunction: z.easing.easeInQuad,
					animationBase: 0,
				};
			}
		};
		var d = function (a, f, c, b, e, h, g, d, q) {
			if (!(0 > c)) {
				"undefined" === typeof d && (d = 1);
				if (!t) {
					var m = Number((g % (2 * Math.PI)).toFixed(8));
					Number((h % (2 * Math.PI)).toFixed(8)) === m && (g -= 1e-4);
				}
				a.save();
				a.globalAlpha = d;
				"pie" === e
					? (a.beginPath(),
					  a.moveTo(f.x, f.y),
					  a.arc(f.x, f.y, c, h, g, !1),
					  (a.fillStyle = b),
					  (a.strokeStyle = "white"),
					  (a.lineWidth = 2),
					  a.closePath(),
					  a.fill())
					: "doughnut" === e &&
					  (a.beginPath(),
					  a.arc(f.x, f.y, c, h, g, !1),
					  0 <= q && a.arc(f.x, f.y, q * c, g, h, !0),
					  a.closePath(),
					  (a.fillStyle = b),
					  (a.strokeStyle = "white"),
					  (a.lineWidth = 2),
					  a.fill());
				a.globalAlpha = 1;
				a.restore();
			}
		};
		v.prototype.renderPie = function (a) {
			function f() {
				if (m && n) {
					for (var a = 0, b = 0, c = 0, f = 0, e = 0; e < n.length; e++) {
						var h = n[e],
							d = m.dataPointIds[e];
						k[e].id = d;
						k[e].objectType = "dataPoint";
						k[e].dataPointIndex = e;
						k[e].dataSeriesIndex = 0;
						var p = k[e],
							A = { percent: null, total: null },
							q = null,
							A = l.getPercentAndTotal(m, h);
						if (m.indexLabelFormatter || h.indexLabelFormatter)
							q = {
								chart: l.options,
								dataSeries: m,
								dataPoint: h,
								total: A.total,
								percent: A.percent,
							};
						A = h.indexLabelFormatter
							? h.indexLabelFormatter(q)
							: h.indexLabel
							? l.replaceKeywordsWithValue(h.indexLabel, h, m, e)
							: m.indexLabelFormatter
							? m.indexLabelFormatter(q)
							: m.indexLabel
							? l.replaceKeywordsWithValue(m.indexLabel, h, m, e)
							: h.label
							? h.label
							: "";
						l._eventManager.objectMap[d] = p;
						p.center = { x: v.x, y: v.y };
						p.y = h.y;
						p.radius = C;
						p.percentInnerRadius = E;
						p.indexLabelText = A;
						p.indexLabelPlacement = m.indexLabelPlacement;
						p.indexLabelLineColor = h.indexLabelLineColor
							? h.indexLabelLineColor
							: m.options.indexLabelLineColor
							? m.options.indexLabelLineColor
							: h.color
							? h.color
							: m._colorSet[e % m._colorSet.length];
						p.indexLabelLineThickness = g(h.indexLabelLineThickness)
							? m.indexLabelLineThickness
							: h.indexLabelLineThickness;
						p.indexLabelLineDashType = h.indexLabelLineDashType
							? h.indexLabelLineDashType
							: m.indexLabelLineDashType;
						p.indexLabelFontColor = h.indexLabelFontColor
							? h.indexLabelFontColor
							: m.indexLabelFontColor;
						p.indexLabelFontStyle = h.indexLabelFontStyle
							? h.indexLabelFontStyle
							: m.indexLabelFontStyle;
						p.indexLabelFontWeight = h.indexLabelFontWeight
							? h.indexLabelFontWeight
							: m.indexLabelFontWeight;
						p.indexLabelFontSize = g(h.indexLabelFontSize)
							? m.indexLabelFontSize
							: h.indexLabelFontSize;
						p.indexLabelFontFamily = h.indexLabelFontFamily
							? h.indexLabelFontFamily
							: m.indexLabelFontFamily;
						p.indexLabelBackgroundColor = h.indexLabelBackgroundColor
							? h.indexLabelBackgroundColor
							: m.options.indexLabelBackgroundColor
							? m.options.indexLabelBackgroundColor
							: m.indexLabelBackgroundColor;
						p.indexLabelMaxWidth = h.indexLabelMaxWidth
							? h.indexLabelMaxWidth
							: m.indexLabelMaxWidth
							? m.indexLabelMaxWidth
							: 0.33 * r.width;
						p.indexLabelWrap =
							"undefined" !== typeof h.indexLabelWrap
								? h.indexLabelWrap
								: m.indexLabelWrap;
						p.indexLabelTextAlign = h.indexLabelTextAlign
							? h.indexLabelTextAlign
							: m.indexLabelTextAlign
							? m.indexLabelTextAlign
							: "left";
						p.startAngle =
							0 === e
								? m.startAngle
									? (m.startAngle / 180) * Math.PI
									: 0
								: k[e - 1].endAngle;
						p.startAngle = (p.startAngle + 2 * Math.PI) % (2 * Math.PI);
						p.endAngle = p.startAngle + ((2 * Math.PI) / w) * Math.abs(h.y);
						h = (p.endAngle + p.startAngle) / 2;
						h = (h + 2 * Math.PI) % (2 * Math.PI);
						p.midAngle = h;
						if (p.midAngle > Math.PI / 2 - u && p.midAngle < Math.PI / 2 + u) {
							if (0 === a || k[c].midAngle > p.midAngle) c = e;
							a++;
						} else if (
							p.midAngle > (3 * Math.PI) / 2 - u &&
							p.midAngle < (3 * Math.PI) / 2 + u
						) {
							if (0 === b || k[f].midAngle > p.midAngle) f = e;
							b++;
						}
						p.hemisphere =
							h > Math.PI / 2 && h <= (3 * Math.PI) / 2 ? "left" : "right";
						p.indexLabelTextBlock = new na(l.plotArea.ctx, {
							fontSize: p.indexLabelFontSize,
							fontFamily: p.indexLabelFontFamily,
							fontColor: p.indexLabelFontColor,
							fontStyle: p.indexLabelFontStyle,
							fontWeight: p.indexLabelFontWeight,
							textAlign: p.indexLabelTextAlign,
							backgroundColor: p.indexLabelBackgroundColor,
							maxWidth: p.indexLabelMaxWidth,
							maxHeight: p.indexLabelWrap
								? 5 * p.indexLabelFontSize
								: 1.5 * p.indexLabelFontSize,
							text: p.indexLabelText,
							padding: 0,
							textBaseline: "top",
						});
						p.indexLabelTextBlock.measureText();
					}
					d = h = 0;
					A = !1;
					for (e = 0; e < n.length; e++)
						(p = k[(c + e) % n.length]),
							1 < a &&
								p.midAngle > Math.PI / 2 - u &&
								p.midAngle < Math.PI / 2 + u &&
								(h <= a / 2 && !A
									? ((p.hemisphere = "right"), h++)
									: ((p.hemisphere = "left"), (A = !0)));
					A = !1;
					for (e = 0; e < n.length; e++)
						(p = k[(f + e) % n.length]),
							1 < b &&
								p.midAngle > (3 * Math.PI) / 2 - u &&
								p.midAngle < (3 * Math.PI) / 2 + u &&
								(d <= b / 2 && !A
									? ((p.hemisphere = "left"), d++)
									: ((p.hemisphere = "right"), (A = !0)));
				}
			}
			function c(a) {
				var b = l.plotArea.ctx;
				b.clearRect(r.x1, r.y1, r.width, r.height);
				b.fillStyle = l.backgroundColor;
				b.fillRect(r.x1, r.y1, r.width, r.height);
				for (b = 0; b < n.length; b++) {
					var c = k[b].startAngle,
						f = k[b].endAngle;
					if (f > c) {
						var e = 0.07 * C * Math.cos(k[b].midAngle),
							h = 0.07 * C * Math.sin(k[b].midAngle),
							g = !1;
						if (n[b].exploded) {
							if (
								1e-9 < Math.abs(k[b].center.x - (v.x + e)) ||
								1e-9 < Math.abs(k[b].center.y - (v.y + h))
							)
								(k[b].center.x = v.x + e * a),
									(k[b].center.y = v.y + h * a),
									(g = !0);
						} else if (
							0 < Math.abs(k[b].center.x - v.x) ||
							0 < Math.abs(k[b].center.y - v.y)
						)
							(k[b].center.x = v.x + e * (1 - a)),
								(k[b].center.y = v.y + h * (1 - a)),
								(g = !0);
						g &&
							((e = {}),
							(e.dataSeries = m),
							(e.dataPoint = m.dataPoints[b]),
							(e.index = b),
							l.toolTip.highlightObjects([e]));
						d(
							l.plotArea.ctx,
							k[b].center,
							k[b].radius,
							n[b].color ? n[b].color : m._colorSet[b % m._colorSet.length],
							m.type,
							c,
							f,
							m.fillOpacity,
							k[b].percentInnerRadius
						);
					}
				}
				a = l.plotArea.ctx;
				a.save();
				a.fillStyle = "black";
				a.strokeStyle = "grey";
				a.textBaseline = "middle";
				a.lineJoin = "round";
				for (b = b = 0; b < n.length; b++)
					(c = k[b]),
						c.indexLabelText &&
							((c.indexLabelTextBlock.y -= c.indexLabelTextBlock.height / 2),
							(f = 0),
							(f =
								"left" === c.hemisphere
									? "inside" !== m.indexLabelPlacement
										? -(c.indexLabelTextBlock.width + p)
										: -c.indexLabelTextBlock.width / 2
									: "inside" !== m.indexLabelPlacement
									? p
									: -c.indexLabelTextBlock.width / 2),
							(c.indexLabelTextBlock.x += f),
							c.indexLabelTextBlock.render(!0),
							(c.indexLabelTextBlock.x -= f),
							(c.indexLabelTextBlock.y += c.indexLabelTextBlock.height / 2),
							"inside" !== c.indexLabelPlacement &&
								0 < c.indexLabelLineThickness &&
								((f = c.center.x + C * Math.cos(c.midAngle)),
								(e = c.center.y + C * Math.sin(c.midAngle)),
								(a.strokeStyle = c.indexLabelLineColor),
								(a.lineWidth = c.indexLabelLineThickness),
								a.setLineDash &&
									a.setLineDash(
										S(c.indexLabelLineDashType, c.indexLabelLineThickness)
									),
								a.beginPath(),
								a.moveTo(f, e),
								a.lineTo(c.indexLabelTextBlock.x, c.indexLabelTextBlock.y),
								a.lineTo(
									c.indexLabelTextBlock.x + ("left" === c.hemisphere ? -p : p),
									c.indexLabelTextBlock.y
								),
								a.stroke()),
							(a.lineJoin = "miter"));
				a.save();
			}
			function b(a, b) {
				var c = 0,
					c = a.indexLabelTextBlock.y - a.indexLabelTextBlock.height / 2,
					f = a.indexLabelTextBlock.y + a.indexLabelTextBlock.height / 2,
					e = b.indexLabelTextBlock.y - b.indexLabelTextBlock.height / 2,
					h = b.indexLabelTextBlock.y + b.indexLabelTextBlock.height / 2;
				return (c =
					b.indexLabelTextBlock.y > a.indexLabelTextBlock.y ? e - f : c - h);
			}
			function e(a) {
				for (var c = null, f = 1; f < n.length; f++)
					if (
						((c = (a + f + k.length) % k.length),
						k[c].hemisphere !== k[a].hemisphere)
					) {
						c = null;
						break;
					} else if (
						k[c].indexLabelText &&
						c !== a &&
						(0 > b(k[c], k[a]) ||
							("right" === k[a].hemisphere
								? k[c].indexLabelTextBlock.y >= k[a].indexLabelTextBlock.y
								: k[c].indexLabelTextBlock.y <= k[a].indexLabelTextBlock.y))
					)
						break;
					else c = null;
				return c;
			}
			function h(a, c, f) {
				f = (f || 0) + 1;
				if (1e3 < f) return 0;
				c = c || 0;
				var g = 0,
					d = v.y - 1 * z,
					m = v.y + 1 * z;
				if (0 <= a && a < n.length) {
					var p = k[a];
					if (
						(0 > c && p.indexLabelTextBlock.y < d) ||
						(0 < c && p.indexLabelTextBlock.y > m)
					)
						return 0;
					var r = 0,
						A = 0,
						A = (r = r = 0);
					0 > c
						? p.indexLabelTextBlock.y - p.indexLabelTextBlock.height / 2 > d &&
						  p.indexLabelTextBlock.y - p.indexLabelTextBlock.height / 2 + c <
								d &&
						  (c = -(
								d -
								(p.indexLabelTextBlock.y - p.indexLabelTextBlock.height / 2 + c)
						  ))
						: p.indexLabelTextBlock.y + p.indexLabelTextBlock.height / 2 < d &&
						  p.indexLabelTextBlock.y + p.indexLabelTextBlock.height / 2 + c >
								m &&
						  (c =
								p.indexLabelTextBlock.y +
								p.indexLabelTextBlock.height / 2 +
								c -
								m);
					c = p.indexLabelTextBlock.y + c;
					d = 0;
					d =
						"right" === p.hemisphere
							? v.x + Math.sqrt(Math.pow(z, 2) - Math.pow(c - v.y, 2))
							: v.x - Math.sqrt(Math.pow(z, 2) - Math.pow(c - v.y, 2));
					A = v.x + C * Math.cos(p.midAngle);
					r = v.y + C * Math.sin(p.midAngle);
					r = Math.sqrt(Math.pow(d - A, 2) + Math.pow(c - r, 2));
					A = Math.acos(C / z);
					r = Math.acos((z * z + C * C - r * r) / (2 * C * z));
					c = r < A ? c - p.indexLabelTextBlock.y : 0;
					d = null;
					for (m = 1; m < n.length; m++)
						if (
							((d = (a - m + k.length) % k.length),
							k[d].hemisphere !== k[a].hemisphere)
						) {
							d = null;
							break;
						} else if (
							k[d].indexLabelText &&
							k[d].hemisphere === k[a].hemisphere &&
							d !== a &&
							(0 > b(k[d], k[a]) ||
								("right" === k[a].hemisphere
									? k[d].indexLabelTextBlock.y <= k[a].indexLabelTextBlock.y
									: k[d].indexLabelTextBlock.y >= k[a].indexLabelTextBlock.y))
						)
							break;
						else d = null;
					A = d;
					r = e(a);
					m = d = 0;
					0 > c
						? ((m = "right" === p.hemisphere ? A : r),
						  (g = c),
						  null !== m &&
								((A = -c),
								(c =
									p.indexLabelTextBlock.y -
									p.indexLabelTextBlock.height / 2 -
									(k[m].indexLabelTextBlock.y +
										k[m].indexLabelTextBlock.height / 2)),
								c - A < x &&
									((d = -A),
									(m = h(m, d, f + 1)),
									+m.toFixed(t) > +d.toFixed(t) &&
										(g = c > x ? -(c - x) : -(A - (m - d))))))
						: 0 < c &&
						  ((m = "right" === p.hemisphere ? r : A),
						  (g = c),
						  null !== m &&
								((A = c),
								(c =
									k[m].indexLabelTextBlock.y -
									k[m].indexLabelTextBlock.height / 2 -
									(p.indexLabelTextBlock.y + p.indexLabelTextBlock.height / 2)),
								c - A < x &&
									((d = A),
									(m = h(m, d, f + 1)),
									+m.toFixed(t) < +d.toFixed(t) &&
										(g = c > x ? c - x : A - (d - m)))));
					g &&
						((f = p.indexLabelTextBlock.y + g),
						(c = 0),
						(c =
							"right" === p.hemisphere
								? v.x + Math.sqrt(Math.pow(z, 2) - Math.pow(f - v.y, 2))
								: v.x - Math.sqrt(Math.pow(z, 2) - Math.pow(f - v.y, 2))),
						p.midAngle > Math.PI / 2 - u && p.midAngle < Math.PI / 2 + u
							? ((d = (a - 1 + k.length) % k.length),
							  (d = k[d]),
							  (a = k[(a + 1 + k.length) % k.length]),
							  "left" === p.hemisphere &&
							  "right" === d.hemisphere &&
							  c > d.indexLabelTextBlock.x
									? (c = d.indexLabelTextBlock.x - 15)
									: "right" === p.hemisphere &&
									  "left" === a.hemisphere &&
									  c < a.indexLabelTextBlock.x &&
									  (c = a.indexLabelTextBlock.x + 15))
							: p.midAngle > (3 * Math.PI) / 2 - u &&
							  p.midAngle < (3 * Math.PI) / 2 + u &&
							  ((d = (a - 1 + k.length) % k.length),
							  (d = k[d]),
							  (a = k[(a + 1 + k.length) % k.length]),
							  "right" === p.hemisphere &&
							  "left" === d.hemisphere &&
							  c < d.indexLabelTextBlock.x
									? (c = d.indexLabelTextBlock.x + 15)
									: "left" === p.hemisphere &&
									  "right" === a.hemisphere &&
									  c > a.indexLabelTextBlock.x &&
									  (c = a.indexLabelTextBlock.x - 15)),
						(p.indexLabelTextBlock.y = f),
						(p.indexLabelTextBlock.x = c),
						(p.indexLabelAngle = Math.atan2(
							p.indexLabelTextBlock.y - v.y,
							p.indexLabelTextBlock.x - v.x
						)));
				}
				return g;
			}
			function A() {
				var a = l.plotArea.ctx;
				a.fillStyle = "grey";
				a.strokeStyle = "grey";
				a.font = "16px Arial";
				a.textBaseline = "middle";
				for (
					var c = (a = 0), f = 0, g = !0, c = 0;
					10 > c && (1 > c || 0 < f);
					c++
				) {
					if (
						m.radius ||
						(!m.radius &&
							"undefined" !== typeof m.innerRadius &&
							null !== m.innerRadius &&
							C - f <= D)
					)
						g = !1;
					g && (C -= f);
					f = 0;
					if ("inside" !== m.indexLabelPlacement) {
						z = C * s;
						for (a = 0; a < n.length; a++) {
							var d = k[a];
							d.indexLabelTextBlock.x = v.x + z * Math.cos(d.midAngle);
							d.indexLabelTextBlock.y = v.y + z * Math.sin(d.midAngle);
							d.indexLabelAngle = d.midAngle;
							d.radius = C;
							d.percentInnerRadius = E;
						}
						for (var A, q, a = 0; a < n.length; a++) {
							var d = k[a],
								u = e(a);
							if (null !== u) {
								A = k[a];
								q = k[u];
								var w = 0,
									w = b(A, q) - x;
								if (0 > w) {
									for (var ua = (q = 0), y = 0; y < n.length; y++)
										y !== a &&
											k[y].hemisphere === d.hemisphere &&
											(k[y].indexLabelTextBlock.y < d.indexLabelTextBlock.y
												? q++
												: ua++);
									q = (w / (q + ua || 1)) * ua;
									var ua = -1 * (w - q),
										ca = (y = 0);
									"right" === d.hemisphere
										? ((y = h(a, q)),
										  (ua = -1 * (w - y)),
										  (ca = h(u, ua)),
										  +ca.toFixed(t) < +ua.toFixed(t) &&
												+y.toFixed(t) <= +q.toFixed(t) &&
												h(a, -(ua - ca)))
										: ((y = h(u, q)),
										  (ua = -1 * (w - y)),
										  (ca = h(a, ua)),
										  +ca.toFixed(t) < +ua.toFixed(t) &&
												+y.toFixed(t) <= +q.toFixed(t) &&
												h(u, -(ua - ca)));
								}
							}
						}
					} else
						for (a = 0; a < n.length; a++)
							(d = k[a]),
								(z = "pie" === m.type ? 0.7 * C : 0.8 * C),
								(u = v.x + z * Math.cos(d.midAngle)),
								(q = v.y + z * Math.sin(d.midAngle)),
								(d.indexLabelTextBlock.x = u),
								(d.indexLabelTextBlock.y = q);
					for (a = 0; a < n.length; a++)
						if (
							((d = k[a]),
							(u = d.indexLabelTextBlock.measureText()),
							0 !== u.height && 0 !== u.width)
						)
							(u = u = 0),
								"right" === d.hemisphere
									? ((u =
											r.x2 -
											(d.indexLabelTextBlock.x +
												d.indexLabelTextBlock.width +
												p)),
									  (u *= -1))
									: (u =
											r.x1 -
											(d.indexLabelTextBlock.x -
												d.indexLabelTextBlock.width -
												p)),
								0 < u &&
									(!g &&
										d.indexLabelText &&
										((q =
											"right" === d.hemisphere
												? r.x2 - d.indexLabelTextBlock.x
												: d.indexLabelTextBlock.x - r.x1),
										0.3 * d.indexLabelTextBlock.maxWidth > q
											? (d.indexLabelText = "")
											: (d.indexLabelTextBlock.maxWidth = 0.85 * q),
										0.3 * d.indexLabelTextBlock.maxWidth < q &&
											(d.indexLabelTextBlock.x -=
												"right" === d.hemisphere ? 2 : -2)),
									Math.abs(
										d.indexLabelTextBlock.y -
											d.indexLabelTextBlock.height / 2 -
											v.y
									) < C ||
										Math.abs(
											d.indexLabelTextBlock.y +
												d.indexLabelTextBlock.height / 2 -
												v.y
										) < C) &&
									((u /= Math.abs(Math.cos(d.indexLabelAngle))),
									9 < u && (u *= 0.3),
									u > f && (f = u)),
								(u = u = 0),
								0 < d.indexLabelAngle && d.indexLabelAngle < Math.PI
									? ((u =
											r.y2 -
											(d.indexLabelTextBlock.y +
												d.indexLabelTextBlock.height / 2 +
												5)),
									  (u *= -1))
									: (u =
											r.y1 -
											(d.indexLabelTextBlock.y -
												d.indexLabelTextBlock.height / 2 -
												5)),
								0 < u &&
									(!g &&
										d.indexLabelText &&
										((q =
											0 < d.indexLabelAngle && d.indexLabelAngle < Math.PI
												? -1
												: 1),
										0 === h(a, u * q) && h(a, 2 * q)),
									Math.abs(d.indexLabelTextBlock.x - v.x) < C &&
										((u /= Math.abs(Math.sin(d.indexLabelAngle))),
										9 < u && (u *= 0.3),
										u > f && (f = u)));
					var I = function (a, b, c) {
						for (
							var f = [], e = 0;
							f.push(k[b]), b !== c;
							b = (b + 1 + n.length) % n.length
						);
						f.sort(function (a, b) {
							return a.y - b.y;
						});
						for (b = 0; b < f.length; b++)
							if (((c = f[b]), e < 0.7 * a))
								(e += c.indexLabelTextBlock.height),
									(c.indexLabelTextBlock.text = ""),
									(c.indexLabelText = ""),
									c.indexLabelTextBlock.measureText();
							else break;
					};
					(function () {
						for (var a = -1, c = -1, f = 0, h = !1, d = 0; d < n.length; d++)
							if (((h = !1), (A = k[d]), A.indexLabelText)) {
								var g = e(d);
								if (null !== g) {
									var m = k[g];
									w = 0;
									w = b(A, m);
									var r;
									if ((r = 0 > w)) {
										r = A.indexLabelTextBlock.x;
										var q =
												A.indexLabelTextBlock.y -
												A.indexLabelTextBlock.height / 2,
											l =
												A.indexLabelTextBlock.y +
												A.indexLabelTextBlock.height / 2,
											x =
												m.indexLabelTextBlock.y -
												m.indexLabelTextBlock.height / 2,
											u = m.indexLabelTextBlock.x + m.indexLabelTextBlock.width,
											z =
												m.indexLabelTextBlock.y +
												m.indexLabelTextBlock.height / 2;
										r =
											A.indexLabelTextBlock.x + A.indexLabelTextBlock.width <
												m.indexLabelTextBlock.x - p ||
											r > u + p ||
											q > z + p ||
											l < x - p
												? !1
												: !0;
									}
									r
										? (0 > a && (a = d),
										  g !== a && ((c = g), (f += -w)),
										  0 === d % Math.max(n.length / 10, 3) && (h = !0))
										: (h = !0);
									h &&
										0 < f &&
										0 <= a &&
										0 <= c &&
										(I(f, a, c), (c = a = -1), (f = 0));
								}
							}
						0 < f && I(f, a, c);
					})();
				}
			}
			function q() {
				l.plotArea.layoutManager.reset();
				l.title &&
					(l.title.dockInsidePlotArea ||
						("center" === l.title.horizontalAlign &&
							"center" === l.title.verticalAlign)) &&
					l.title.render();
				if (l.subtitles)
					for (var a = 0; a < l.subtitles.length; a++) {
						var b = l.subtitles[a];
						(b.dockInsidePlotArea ||
							("center" === b.horizontalAlign &&
								"center" === b.verticalAlign)) &&
							b.render();
					}
				l.legend &&
					(l.legend.dockInsidePlotArea ||
						("center" === l.legend.horizontalAlign &&
							"center" === l.legend.verticalAlign)) &&
					(l.legend.setLayout(), l.legend.render());
			}
			var l = this;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				var m = this.data[a.dataSeriesIndexes[0]],
					n = m.dataPoints,
					p = 10,
					r = this.plotArea,
					k = m.dataPointEOs,
					x = 2,
					z,
					s = 1.3,
					u = (20 / 180) * Math.PI,
					t = 6,
					v = { x: (r.x2 + r.x1) / 2, y: (r.y2 + r.y1) / 2 },
					w = 0;
				a = !1;
				for (var y = 0; y < n.length; y++)
					(w += Math.abs(n[y].y)),
						!a &&
							"undefined" !== typeof n[y].indexLabel &&
							null !== n[y].indexLabel &&
							0 < n[y].indexLabel.toString().length &&
							(a = !0),
						!a &&
							"undefined" !== typeof n[y].label &&
							null !== n[y].label &&
							0 < n[y].label.toString().length &&
							(a = !0);
				if (0 !== w) {
					a =
						a ||
						("undefined" !== typeof m.indexLabel &&
							null !== m.indexLabel &&
							0 < m.indexLabel.toString().length);
					var C =
						"inside" !== m.indexLabelPlacement && a
							? (0.75 * Math.min(r.width, r.height)) / 2
							: (0.92 * Math.min(r.width, r.height)) / 2;
					m.radius && (C = Va(m.radius, C));
					var D =
						"undefined" !== typeof m.innerRadius && null !== m.innerRadius
							? Va(m.innerRadius, C)
							: 0.7 * C;
					m.radius = C;
					"doughnut" === m.type && (m.innerRadius = D);
					var E = Math.min(D / C, (C - 1) / C);
					this.pieDoughnutClickHandler = function (a) {
						l.isAnimating ||
							(!g(a.dataSeries.explodeOnClick) &&
								!a.dataSeries.explodeOnClick) ||
							((a = a.dataPoint),
							(a.exploded = a.exploded ? !1 : !0),
							1 < this.dataPoints.length &&
								l._animator.animate(0, 500, function (a) {
									c(a);
									q();
									l.dispatchEvent("dataAnimationIterationEnd", { chart: l });
									l.dispatchEvent("dataAnimationEnd", { chart: l });
								}));
					};
					f();
					A();
					A();
					A();
					A();
					this.disableToolTip = !0;
					this._animator.animate(
						0,
						this.animatedRender ? this.animationDuration : 0,
						function (a) {
							var b = l.plotArea.ctx;
							b.clearRect(r.x1, r.y1, r.width, r.height);
							b.fillStyle = l.backgroundColor;
							b.fillRect(r.x1, r.y1, r.width, r.height);
							for (
								var b = k[0].startAngle + 2 * Math.PI * a, c = 0;
								c < n.length;
								c++
							) {
								var f = 0 === c ? k[c].startAngle : e,
									e = f + (k[c].endAngle - k[c].startAngle),
									h = !1;
								e > b && ((e = b), (h = !0));
								var g = n[c].color
									? n[c].color
									: m._colorSet[c % m._colorSet.length];
								e > f &&
									d(
										l.plotArea.ctx,
										k[c].center,
										k[c].radius,
										g,
										m.type,
										f,
										e,
										m.fillOpacity,
										k[c].percentInnerRadius
									);
								if (h) break;
							}
							q();
							l.dispatchEvent("dataAnimationIterationEnd", { chart: l });
							1 <= a && l.dispatchEvent("dataAnimationEnd", { chart: l });
						},
						function () {
							l.disableToolTip = !1;
							l._animator.animate(0, l.animatedRender ? 500 : 0, function (a) {
								c(a);
								q();
								l.dispatchEvent("dataAnimationIterationEnd", { chart: l });
							});
							l.dispatchEvent("dataAnimationEnd", { chart: l });
						}
					);
				}
			}
		};
		var q = function (a, f, c, b) {
			"undefined" === typeof c && (c = 1);
			0 >= Math.round(f.y4 - f.y1) ||
				(a.save(),
				(a.globalAlpha = c),
				a.beginPath(),
				a.moveTo(Math.round(f.x1), Math.round(f.y1)),
				a.lineTo(Math.round(f.x2), Math.round(f.y2)),
				a.lineTo(Math.round(f.x3), Math.round(f.y3)),
				a.lineTo(Math.round(f.x4), Math.round(f.y4)),
				"undefined" !== f.x5 &&
					(a.lineTo(Math.round(f.x5), Math.round(f.y5)),
					a.lineTo(Math.round(f.x6), Math.round(f.y6))),
				a.closePath(),
				(a.fillStyle = b ? b : f.color),
				a.fill(),
				(a.globalAplha = 1),
				a.restore());
		};
		v.prototype.renderFunnel = function (a) {
			function f() {
				for (var a = 0, b = [], c = 0; c < B.length; c++) {
					if ("undefined" === typeof B[c].y) return -1;
					B[c].y = "number" === typeof B[c].y ? B[c].y : 0;
					a += Math.abs(B[c].y);
				}
				if (0 === a) return -1;
				for (c = b[0] = 0; c < B.length; c++)
					b.push((Math.abs(B[c].y) * E) / a);
				return b;
			}
			function c() {
				var a = aa,
					b = da,
					c = T,
					f = Z,
					e,
					h;
				e = N;
				h = Q - O;
				f = Math.abs(((h - e) * (b - a + (f - c))) / 2);
				c = Z - T;
				e = h - e;
				h = c * (h - Q);
				h = Math.abs(h);
				h = f + h;
				for (var f = [], k = 0, d = 0; d < B.length; d++) {
					if ("undefined" === typeof B[d].y) return -1;
					B[d].y = "number" === typeof B[d].y ? B[d].y : 0;
					k += Math.abs(B[d].y);
				}
				if (0 === k) return -1;
				for (
					var g = (f[0] = 0), m = 0, p, n, b = b - a, g = !1, d = 0;
					d < B.length;
					d++
				)
					(a = (Math.abs(B[d].y) * h) / k),
						g
							? (p = 0 == Number(c.toFixed(3)) ? 0 : a / c)
							: ((n = ia * ia * b * b - 4 * Math.abs(ia) * a),
							  0 > n
									? ((n = c),
									  (g = ((b + n) * (e - m)) / 2),
									  (a -= g),
									  (p = e - m),
									  (m += e - m),
									  (p += 0 == n ? 0 : a / n),
									  (m += a / n),
									  (g = !0))
									: ((p = (Math.abs(ia) * b - Math.sqrt(n)) / 2),
									  (n = b - (2 * p) / Math.abs(ia)),
									  (m += p),
									  m > e &&
											((m -= p),
											(n = c),
											(g = ((b + n) * (e - m)) / 2),
											(a -= g),
											(p = e - m),
											(m += e - m),
											(p += a / n),
											(m += a / n),
											(g = !0)),
									  (b = n))),
						f.push(p);
				return f;
			}
			function b() {
				if (u && B) {
					for (
						var a,
							b,
							c,
							f,
							e,
							h,
							d,
							m,
							p,
							n,
							r,
							A,
							q,
							l,
							x,
							z = [],
							s = [],
							v = { percent: null, total: null },
							M = null,
							y = 0;
						y < B.length;
						y++
					)
						(x = V[y]),
							(x =
								"undefined" !== typeof x.x5
									? (x.y2 + x.y4) / 2
									: (x.y2 + x.y3) / 2),
							(x = k(x).x2 + 1),
							(z[y] = P - x - U);
					x = 0.5 * U;
					for (var y = 0, C = B.length - 1; y < B.length || 0 <= C; y++, C--) {
						b = u.reversed ? B[C] : B[y];
						a = b.color
							? b.color
							: u.reversed
							? u._colorSet[(B.length - 1 - y) % u._colorSet.length]
							: u._colorSet[y % u._colorSet.length];
						c = b.indexLabelPlacement || u.indexLabelPlacement || "outside";
						l = b.indexLabelTextAlign || u.indexLabelTextAlign || "left";
						f =
							b.indexLabelBackgroundColor ||
							u.indexLabelBackgroundColor ||
							(t ? "transparent" : null);
						e = b.indexLabelFontColor || u.indexLabelFontColor || "#979797";
						h = g(b.indexLabelFontSize)
							? u.indexLabelFontSize
							: b.indexLabelFontSize;
						d = b.indexLabelFontStyle || u.indexLabelFontStyle || "normal";
						m = b.indexLabelFontFamily || u.indexLabelFontFamily || "arial";
						p = b.indexLabelFontWeight || u.indexLabelFontWeight || "normal";
						a = b.indexLabelLineColor || u.options.indexLabelLineColor || a;
						n =
							"number" === typeof b.indexLabelLineThickness
								? b.indexLabelLineThickness
								: "number" === typeof u.indexLabelLineThickness
								? u.indexLabelLineThickness
								: 2;
						r = b.indexLabelLineDashType || u.indexLabelLineDashType || "solid";
						A =
							"undefined" !== typeof b.indexLabelWrap
								? b.indexLabelWrap
								: "undefined" !== typeof u.indexLabelWrap
								? u.indexLabelWrap
								: !0;
						q = u.dataPointIds[y];
						w._eventManager.objectMap[q] = {
							id: q,
							objectType: "dataPoint",
							dataPointIndex: y,
							dataSeriesIndex: 0,
							funnelSection: V[u.reversed ? B.length - 1 - y : y],
						};
						"inside" === u.indexLabelPlacement &&
							((z[y] =
								y !== ga
									? u.reversed
										? V[y].x2 - V[y].x1
										: V[y].x3 - V[y].x4
									: V[y].x3 - V[y].x6),
							20 > z[y] &&
								((z[y] =
									y !== ga
										? u.reversed
											? V[y].x3 - V[y].x4
											: V[y].x2 - V[y].x1
										: V[y].x2 - V[y].x1),
								(z[y] /= 2)));
						q = b.indexLabelMaxWidth
							? b.indexLabelMaxWidth
							: u.options.indexLabelMaxWidth
							? u.indexLabelMaxWidth
							: z[y];
						if (q > z[y] || 0 > q) q = z[y];
						s[y] = "inside" === u.indexLabelPlacement ? V[y].height : !1;
						v = w.getPercentAndTotal(u, b);
						if (u.indexLabelFormatter || b.indexLabelFormatter)
							M = {
								chart: w.options,
								dataSeries: u,
								dataPoint: b,
								total: v.total,
								percent: v.percent,
							};
						b = b.indexLabelFormatter
							? b.indexLabelFormatter(M)
							: b.indexLabel
							? w.replaceKeywordsWithValue(b.indexLabel, b, u, y)
							: u.indexLabelFormatter
							? u.indexLabelFormatter(M)
							: u.indexLabel
							? w.replaceKeywordsWithValue(u.indexLabel, b, u, y)
							: b.label
							? b.label
							: "";
						0 >= n && (n = 0);
						1e3 > q && 1e3 - q < x && (q += 1e3 - q);
						R.roundRect || Ga(R);
						c = new na(R, {
							fontSize: h,
							fontFamily: m,
							fontColor: e,
							fontStyle: d,
							fontWeight: p,
							horizontalAlign: c,
							textAlign: l,
							backgroundColor: f,
							maxWidth: q,
							maxHeight: !1 === s[y] ? (A ? 4.28571429 * h : 1.5 * h) : s[y],
							text: b,
							padding: ka,
						});
						c.measureText();
						J.push({
							textBlock: c,
							id: u.reversed ? C : y,
							isDirty: !1,
							lineColor: a,
							lineThickness: n,
							lineDashType: r,
							height: c.height < c.maxHeight ? c.height : c.maxHeight,
							width: c.width < c.maxWidth ? c.width : c.maxWidth,
						});
					}
				}
			}
			function e() {
				var a,
					b,
					c,
					f,
					e,
					h = [];
				e = !1;
				c = 0;
				for (
					var d,
						k = P - da - U / 2,
						k = u.options.indexLabelMaxWidth
							? u.indexLabelMaxWidth > k
								? k
								: u.indexLabelMaxWidth
							: k,
						g = J.length - 1;
					0 <= g;
					g--
				) {
					d = B[J[g].id];
					c = J[g];
					f = c.textBlock;
					b = (a = r(g) < V.length ? J[r(g)] : null) ? a.textBlock : null;
					c = c.height;
					a && f.y + c + ka > b.y && (e = !0);
					c = d.indexLabelMaxWidth || k;
					if (c > k || 0 > c) c = k;
					h.push(c);
				}
				if (e)
					for (g = J.length - 1; 0 <= g; g--)
						(a = V[g]),
							(J[g].textBlock.maxWidth = h[h.length - (g + 1)]),
							J[g].textBlock.measureText(),
							(J[g].textBlock.x = P - k),
							(c =
								J[g].textBlock.height < J[g].textBlock.maxHeight
									? J[g].textBlock.height
									: J[g].textBlock.maxHeight),
							(e =
								J[g].textBlock.width < J[g].textBlock.maxWidth
									? J[g].textBlock.width
									: J[g].textBlock.maxWidth),
							(J[g].height = c),
							(J[g].width = e),
							(c =
								"undefined" !== typeof a.x5
									? (a.y2 + a.y4) / 2
									: (a.y2 + a.y3) / 2),
							(J[g].textBlock.y = c - J[g].height / 2),
							u.reversed
								? (J[g].textBlock.y + J[g].height > W + D &&
										(J[g].textBlock.y = W + D - J[g].height),
								  J[g].textBlock.y < ba - D && (J[g].textBlock.y = ba - D))
								: (J[g].textBlock.y < W - D && (J[g].textBlock.y = W - D),
								  J[g].textBlock.y + J[g].height > ba + D &&
										(J[g].textBlock.y = ba + D - J[g].height));
			}
			function h() {
				var a, b, c, f;
				if ("inside" !== u.indexLabelPlacement)
					for (var e = 0; e < V.length; e++)
						0 == J[e].textBlock.text.length
							? (J[e].isDirty = !0)
							: ((a = V[e]),
							  (c =
									"undefined" !== typeof a.x5
										? (a.y2 + a.y4) / 2
										: (a.y2 + a.y3) / 2),
							  (b = u.reversed
									? "undefined" !== typeof a.x5
										? c > ea
											? k(c).x2 + 1
											: (a.x2 + a.x3) / 2 + 1
										: (a.x2 + a.x3) / 2 + 1
									: "undefined" !== typeof a.x5
									? c < ea
										? k(c).x2 + 1
										: (a.x4 + a.x3) / 2 + 1
									: (a.x2 + a.x3) / 2 + 1),
							  (J[e].textBlock.x = b + U),
							  (J[e].textBlock.y = c - J[e].height / 2),
							  u.reversed
									? (J[e].textBlock.y + J[e].height > W + D &&
											(J[e].textBlock.y = W + D - J[e].height),
									  J[e].textBlock.y < ba - D && (J[e].textBlock.y = ba - D))
									: (J[e].textBlock.y < W - D && (J[e].textBlock.y = W - D),
									  J[e].textBlock.y + J[e].height > ba + D &&
											(J[e].textBlock.y = ba + D - J[e].height)));
				else
					for (e = 0; e < V.length; e++)
						0 == J[e].textBlock.text.length
							? (J[e].isDirty = !0)
							: ((a = V[e]),
							  (b = a.height),
							  (c = J[e].height),
							  (f = J[e].width),
							  b >= c
									? ((b =
											e != ga
												? (a.x4 + a.x3) / 2 - f / 2
												: (a.x5 + a.x4) / 2 - f / 2),
									  (c =
											e != ga
												? (a.y1 + a.y3) / 2 - c / 2
												: (a.y1 + a.y4) / 2 - c / 2),
									  (J[e].textBlock.x = b),
									  (J[e].textBlock.y = c))
									: (J[e].isDirty = !0));
			}
			function d() {
				function a(b, c) {
					var f;
					if (0 > b || b >= J.length) return 0;
					var e,
						h = J[b].textBlock;
					if (0 > c) {
						c *= -1;
						e = p(b);
						f = l(e, b);
						if (f >= c) return (h.y -= c), c;
						if (0 == b) return 0 < f && (h.y -= f), f;
						f += a(e, -(c - f));
						0 < f && (h.y -= f);
						return f;
					}
					e = r(b);
					f = l(b, e);
					if (f >= c) return (h.y += c), c;
					if (b == V.length - 1) return 0 < f && (h.y += f), f;
					f += a(e, c - f);
					0 < f && (h.y += f);
					return f;
				}
				function b() {
					var a,
						f,
						e,
						h,
						g = 0,
						k;
					h = (Q - N + 2 * D) / n;
					k = n;
					for (var d, m = 1; m < k; m++) {
						e = m * h;
						for (var A = J.length - 1; 0 <= A; A--)
							!J[A].isDirty &&
								J[A].textBlock.y < e &&
								J[A].textBlock.y + J[A].height > e &&
								((d = r(A)),
								!(d >= J.length - 1) &&
									J[A].textBlock.y + J[A].height + ka > J[d].textBlock.y &&
									(J[A].textBlock.y =
										J[A].textBlock.y + J[A].height - e > e - J[A].textBlock.y
											? e + 1
											: e - J[A].height - 1));
					}
					for (d = V.length - 1; 0 < d; d--)
						if (!J[d].isDirty) {
							e = p(d);
							if (0 > e && ((e = 0), J[e].isDirty)) break;
							if (J[d].textBlock.y < J[e].textBlock.y + J[e].height) {
								f = f || d;
								h = d;
								for (
									k = 0;
									J[h].textBlock.y < J[e].textBlock.y + J[e].height + ka;

								) {
									a = a || J[h].textBlock.y + J[h].height;
									k += J[h].height;
									k += ka;
									h = e;
									if (0 >= h) {
										h = 0;
										k += J[h].height;
										break;
									}
									e = p(h);
									if (0 > e) {
										h = 0;
										k += J[h].height;
										break;
									}
								}
								if (h != d) {
									g = J[h].textBlock.y;
									a -= g;
									a = k - a;
									g = c(a, f, h);
									break;
								}
							}
						}
					return g;
				}
				function c(a, b, f) {
					var e = [],
						h = 0,
						k = 0;
					for (a = Math.abs(a); f <= b; f++) e.push(V[f]);
					e.sort(function (a, b) {
						return a.height - b.height;
					});
					for (f = 0; f < e.length; f++)
						if (((b = e[f]), h < a))
							k++,
								(h += J[b.id].height + ka),
								(J[b.id].textBlock.text = ""),
								(J[b.id].indexLabelText = ""),
								(J[b.id].isDirty = !0),
								J[b.id].textBlock.measureText();
						else break;
					return k;
				}
				for (var f, e, h, g, k, m, n = 1, A = 0; A < 2 * n; A++) {
					for (
						var q = J.length - 1;
						0 <= q &&
						!(0 <= p(q) && p(q),
						(h = J[q]),
						(g = h.textBlock),
						(m = (k = r(q) < V.length ? J[r(q)] : null) ? k.textBlock : null),
						(f = +h.height.toFixed(6)),
						(e = +g.y.toFixed(6)),
						!h.isDirty &&
							k &&
							e + f + ka > +m.y.toFixed(6) &&
							((f = g.y + f + ka - m.y),
							(e = a(q, -f)),
							e < f && (0 < e && (f -= e), (e = a(r(q), f)), e != f)));
						q--
					);
					b();
				}
			}
			function l(a, b) {
				return (
					(b < V.length ? J[b].textBlock.y : u.reversed ? W + D : ba + D) -
					(0 > a
						? u.reversed
							? ba - D
							: W - D
						: J[a].textBlock.y + J[a].height + ka)
				);
			}
			function s(a, b, c) {
				var f,
					e,
					k,
					g = [],
					d = D,
					p = [];
				-1 !== b &&
					(0 <= ha.indexOf(b)
						? ((e = ha.indexOf(b)), ha.splice(e, 1))
						: (ha.push(b),
						  (ha = ha.sort(function (a, b) {
								return a - b;
						  }))));
				if (0 === ha.length) g = oa;
				else {
					e =
						(D *
							(1 != ha.length || (0 != ha[0] && ha[0] != V.length - 1)
								? 2
								: 1)) /
						m();
					for (var r = 0; r < V.length; r++) {
						if (1 == ha.length && 0 == ha[0]) {
							if (0 === r) {
								g.push(oa[r]);
								f = d;
								continue;
							}
						} else 0 === r && (f = -1 * d);
						g.push(oa[r] + f);
						if (0 <= ha.indexOf(r) || (r < V.length && 0 <= ha.indexOf(r + 1)))
							f += e;
					}
				}
				k = (function () {
					for (var a = [], b = 0; b < V.length; b++) a.push(g[b] - V[b].y1);
					return a;
				})();
				var A = {
					startTime: new Date().getTime(),
					duration: c || 500,
					easingFunction: function (a, b, c, f) {
						return z.easing.easeOutQuart(a, b, c, f);
					},
					changeSection: function (a) {
						for (var b, c, f = 0; f < V.length; f++)
							(b = k[f]),
								(c = V[f]),
								(b *= a),
								"undefined" === typeof p[f] && (p[f] = 0),
								0 > p && (p *= -1),
								(c.y1 += b - p[f]),
								(c.y2 += b - p[f]),
								(c.y3 += b - p[f]),
								(c.y4 += b - p[f]),
								c.y5 && ((c.y5 += b - p[f]), (c.y6 += b - p[f])),
								(p[f] = b);
					},
				};
				a._animator.animate(
					0,
					c,
					function (c) {
						var f = a.plotArea.ctx || a.ctx;
						qa = !0;
						f.clearRect(y.x1, y.y1, y.x2 - y.x1, y.y2 - y.y1);
						f.fillStyle = a.backgroundColor;
						f.fillRect(y.x1, y.y1, y.width, y.height);
						A.changeSection(c, b);
						var e = {};
						e.dataSeries = u;
						e.dataPoint = u.reversed
							? u.dataPoints[B.length - 1 - b]
							: u.dataPoints[b];
						e.index = u.reversed ? B.length - 1 - b : b;
						a.toolTip.highlightObjects([e]);
						for (e = 0; e < V.length; e++) q(f, V[e], u.fillOpacity);
						v(f);
						K && ("inside" !== u.indexLabelPlacement ? n(f) : h(), x(f));
						1 <= c && (qa = !1);
					},
					null,
					z.easing.easeOutQuart
				);
			}
			function m() {
				for (var a = 0, b = 0; b < V.length - 1; b++)
					(0 <= ha.indexOf(b) || 0 <= ha.indexOf(b + 1)) && a++;
				return a;
			}
			function n(a) {
				for (var b, c, f, e, h = 0; h < V.length; h++)
					(e = 1 === J[h].lineThickness % 2 ? 0.5 : 0),
						(c = (((V[h].y2 + V[h].y4) / 2) << 0) + e),
						(b = k(c).x2 - 1),
						(f = J[h].textBlock.x),
						(e = ((J[h].textBlock.y + J[h].height / 2) << 0) + e),
						J[h].isDirty ||
							0 == J[h].lineThickness ||
							((a.strokeStyle = J[h].lineColor),
							(a.lineWidth = J[h].lineThickness),
							a.setLineDash &&
								a.setLineDash(S(J[h].lineDashType, J[h].lineThickness)),
							a.beginPath(),
							a.moveTo(b, c),
							a.lineTo(f, e),
							a.stroke());
			}
			function p(a) {
				for (a -= 1; -1 <= a && -1 != a && J[a].isDirty; a--);
				return a;
			}
			function r(a) {
				for (a += 1; a <= V.length && a != V.length && J[a].isDirty; a++);
				return a;
			}
			function k(a) {
				for (var b, c = 0; c < B.length; c++)
					if (V[c].y1 < a && V[c].y4 > a) {
						b = V[c];
						break;
					}
				return b
					? ((a = b.y6
							? a > b.y6
								? b.x3 + ((b.x4 - b.x3) / (b.y4 - b.y3)) * (a - b.y3)
								: b.x2 + ((b.x3 - b.x2) / (b.y3 - b.y2)) * (a - b.y2)
							: b.x2 + ((b.x3 - b.x2) / (b.y3 - b.y2)) * (a - b.y2)),
					  { x1: a, x2: a })
					: -1;
			}
			function x(a) {
				for (var b = 0; b < V.length; b++)
					J[b].isDirty ||
						(a && (J[b].textBlock.ctx = a), J[b].textBlock.render(!0));
			}
			function v(a) {
				w.plotArea.layoutManager.reset();
				a.roundRect || Ga(a);
				w.title &&
					(w.title.dockInsidePlotArea ||
						("center" === w.title.horizontalAlign &&
							"center" === w.title.verticalAlign)) &&
					((w.title.ctx = a), w.title.render());
				if (w.subtitles)
					for (var b = 0; b < w.subtitles.length; b++) {
						var c = w.subtitles[b];
						if (
							c.dockInsidePlotArea ||
							("center" === c.horizontalAlign && "center" === c.verticalAlign)
						)
							(w.subtitles.ctx = a), c.render();
					}
				w.legend &&
					(w.legend.dockInsidePlotArea ||
						("center" === w.legend.horizontalAlign &&
							"center" === w.legend.verticalAlign)) &&
					((w.legend.ctx = a), w.legend.setLayout(), w.legend.render());
				Pa.fNg && Pa.fNg(w);
			}
			var w = this;
			if (!(0 >= a.dataSeriesIndexes.length)) {
				for (
					var u = this.data[a.dataSeriesIndexes[0]],
						B = u.dataPoints,
						y = this.plotArea,
						D = 0.025 * y.width,
						I = 0.01 * y.width,
						C = 0,
						E = y.height - 2 * D,
						H = Math.min(y.width - 2 * I, 2.8 * y.height),
						K = !1,
						X = 0;
					X < B.length;
					X++
				)
					if (
						(!K &&
							"undefined" !== typeof B[X].indexLabel &&
							null !== B[X].indexLabel &&
							0 < B[X].indexLabel.toString().length &&
							(K = !0),
						!K &&
							"undefined" !== typeof B[X].label &&
							null !== B[X].label &&
							0 < B[X].label.toString().length &&
							(K = !0),
						(!K && "function" === typeof u.indexLabelFormatter) ||
							"function" === typeof B[X].indexLabelFormatter)
					)
						K = !0;
				K =
					K ||
					("undefined" !== typeof u.indexLabel &&
						null !== u.indexLabel &&
						0 < u.indexLabel.toString().length);
				("inside" !== u.indexLabelPlacement && K) ||
					(I = (y.width - 0.75 * H) / 2);
				var X = y.x1 + I,
					P = y.x2 - I,
					N = y.y1 + D,
					Q = y.y2 - D,
					R = a.targetCanvasCtx || this.plotArea.ctx || this.ctx;
				if (0 != u.length && u.dataPoints && u.visible && 0 !== B.length) {
					var O, G;
					a = (75 * H) / 100;
					var U = (30 * (P - a)) / 100;
					"funnel" === u.type
						? ((O = g(u.options.neckHeight) ? 0.35 * E : u.neckHeight),
						  (G = g(u.options.neckWidth) ? 0.25 * a : u.neckWidth),
						  "string" === typeof O && O.match(/%$/)
								? ((O = parseInt(O)), (O = (O * E) / 100))
								: (O = parseInt(O)),
						  "string" === typeof G && G.match(/%$/)
								? ((G = parseInt(G)), (G = (G * a) / 100))
								: (G = parseInt(G)),
						  O > E ? (O = E) : 0 >= O && (O = 0),
						  G > a ? (G = a - 0.5) : 0 >= G && (G = 0))
						: "pyramid" === u.type &&
						  ((G = O = 0), (u.reversed = u.reversed ? !1 : !0));
					var I = X + a / 2,
						aa = X,
						da = X + a,
						W = u.reversed ? Q : N,
						T = I - G / 2,
						Z = I + G / 2,
						ea = u.reversed ? N + O : Q - O,
						ba = u.reversed ? N : Q;
					a = [];
					var I = [],
						V = [],
						H = [],
						Y = N,
						ga,
						ia = (ea - W) / (T - aa),
						ma = -ia,
						X =
							"area" === (u.valueRepresents ? u.valueRepresents : "height")
								? c()
								: f();
					if (-1 !== X) {
						if (u.reversed)
							for (H.push(Y), G = X.length - 1; 0 < G; G--)
								(Y += X[G]), H.push(Y);
						else for (G = 0; G < X.length; G++) (Y += X[G]), H.push(Y);
						if (u.reversed)
							for (G = 0; G < X.length; G++)
								H[G] < ea
									? (a.push(T), I.push(Z), (ga = G))
									: (a.push((H[G] - W + ia * aa) / ia),
									  I.push((H[G] - W + ma * da) / ma));
						else
							for (G = 0; G < X.length; G++)
								H[G] < ea
									? (a.push((H[G] - W + ia * aa) / ia),
									  I.push((H[G] - W + ma * da) / ma),
									  (ga = G))
									: (a.push(T), I.push(Z));
						for (G = 0; G < X.length - 1; G++)
							(Y = u.reversed
								? B[B.length - 1 - G].color
									? B[B.length - 1 - G].color
									: u._colorSet[(B.length - 1 - G) % u._colorSet.length]
								: B[G].color
								? B[G].color
								: u._colorSet[G % u._colorSet.length]),
								G === ga
									? V.push({
											x1: a[G],
											y1: H[G],
											x2: I[G],
											y2: H[G],
											x3: Z,
											y3: ea,
											x4: I[G + 1],
											y4: H[G + 1],
											x5: a[G + 1],
											y5: H[G + 1],
											x6: T,
											y6: ea,
											id: G,
											height: H[G + 1] - H[G],
											color: Y,
									  })
									: V.push({
											x1: a[G],
											y1: H[G],
											x2: I[G],
											y2: H[G],
											x3: I[G + 1],
											y3: H[G + 1],
											x4: a[G + 1],
											y4: H[G + 1],
											id: G,
											height: H[G + 1] - H[G],
											color: Y,
									  });
						var ka = 2,
							J = [],
							qa = !1,
							ha = [],
							oa = [],
							X = !1;
						a = a = 0;
						Da(ha);
						for (G = 0; G < B.length; G++)
							B[G].exploded &&
								((X = !0), u.reversed ? ha.push(B.length - 1 - G) : ha.push(G));
						R.clearRect(y.x1, y.y1, y.width, y.height);
						R.fillStyle = w.backgroundColor;
						R.fillRect(y.x1, y.y1, y.width, y.height);
						if (
							K &&
							u.visible &&
							(b(), h(), e(), "inside" !== u.indexLabelPlacement)
						) {
							d();
							for (G = 0; G < B.length; G++)
								J[G].isDirty ||
									((a = J[G].textBlock.x + J[G].width),
									(a = (P - a) / 2),
									0 == G && (C = a),
									C > a && (C = a));
							for (G = 0; G < V.length; G++)
								(V[G].x1 += C),
									(V[G].x2 += C),
									(V[G].x3 += C),
									(V[G].x4 += C),
									V[G].x5 && ((V[G].x5 += C), (V[G].x6 += C)),
									(J[G].textBlock.x += C);
						}
						for (G = 0; G < V.length; G++)
							(C = V[G]), q(R, C, u.fillOpacity), oa.push(C.y1);
						v(R);
						K &&
							u.visible &&
							("inside" === u.indexLabelPlacement || w.animationEnabled || n(R),
							w.animationEnabled || x());
						if (!K)
							for (G = 0; G < B.length; G++)
								(C = u.dataPointIds[G]),
									(a = {
										id: C,
										objectType: "dataPoint",
										dataPointIndex: G,
										dataSeriesIndex: 0,
										funnelSection: V[u.reversed ? B.length - 1 - G : G],
									}),
									(w._eventManager.objectMap[C] = a);
						!w.animationEnabled && X
							? s(w, -1, 0)
							: w.animationEnabled && !w.animatedRender && s(w, -1, 0);
						this.funnelPyramidClickHandler = function (a) {
							var b = -1;
							if (
								!qa &&
								!w.isAnimating &&
								(g(a.dataSeries.explodeOnClick) ||
									a.dataSeries.explodeOnClick) &&
								((b = u.reversed
									? B.length - 1 - a.dataPointIndex
									: a.dataPointIndex),
								0 <= b)
							) {
								a = b;
								if ("funnel" === u.type || "pyramid" === u.type)
									u.reversed
										? (B[B.length - 1 - a].exploded = B[B.length - 1 - a]
												.exploded
												? !1
												: !0)
										: (B[a].exploded = B[a].exploded ? !1 : !0);
								s(w, b, 500);
							}
						};
						return {
							source: R,
							dest: this.plotArea.ctx,
							animationCallback: function (a, b) {
								z.fadeInAnimation(a, b);
								1 <= a && (s(w, -1, 500), v(w.plotArea.ctx || w.ctx));
							},
							easingFunction: z.easing.easeInQuad,
							animationBase: 0,
						};
					}
				}
			}
		};
		v.prototype.requestAnimFrame = (function () {
			return (
				window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				window.oRequestAnimationFrame ||
				window.msRequestAnimationFrame ||
				function (a) {
					window.setTimeout(a, 1e3 / 60);
				}
			);
		})();
		v.prototype.cancelRequestAnimFrame =
			window.cancelAnimationFrame ||
			window.webkitCancelRequestAnimationFrame ||
			window.mozCancelRequestAnimationFrame ||
			window.oCancelRequestAnimationFrame ||
			window.msCancelRequestAnimationFrame ||
			clearTimeout;
		v.prototype.set = function (a, f, c) {
			c = "undefined" === typeof c ? !0 : c;
			"options" === a
				? ((this.options = f), c && this.render())
				: v.base.set.call(this, a, f, c);
		};
		v.prototype.exportChart = function (a) {
			a = "undefined" === typeof a ? {} : a;
			var f = a.format ? a.format : "png",
				c = a.fileName ? a.fileName : this.exportFileName;
			if (a.toDataURL) return this.canvas.toDataURL("image/" + f);
			db(this.canvas, f, c);
		};
		v.prototype.print = function () {
			var a = this.exportChart({ toDataURL: !0 }),
				f = document.createElement("iframe");
			f.setAttribute("class", "canvasjs-chart-print-frame");
			N(f, {
				position: "absolute",
				width: "100%",
				border: "0px",
				margin: "0px 0px 0px 0px",
				padding: "0px 0px 0px 0px",
			});
			f.style.height = this.height + "px";
			this._canvasJSContainer.appendChild(f);
			var c = this,
				b = f.contentWindow || f.contentDocument.document || f.contentDocument;
			b.document.open();
			b.document.write(
				'<!DOCTYPE HTML>\n<html><body><img src="' + a + '"/><body/></html>'
			);
			b.document.body &&
				b.document.body.style &&
				((b.document.body.style.margin = "0px 0px 0px 0px"),
				(b.document.body.style.padding = "0px 0px 0px 0px"));
			b.document.close();
			setTimeout(function () {
				b.focus();
				b.print();
				setTimeout(function () {
					c._canvasJSContainer.removeChild(f);
				}, 1e3);
			}, 500);
		};
		v.prototype.getPercentAndTotal = function (a, f) {
			var c = null,
				b = null,
				e = (c = null);
			if (0 <= a.type.indexOf("stacked"))
				(b = 0),
					(c = f.x.getTime ? f.x.getTime() : f.x),
					c in a.plotUnit.yTotals &&
						((b = a.plotUnit.yTotals[c]),
						(c = a.plotUnit.yAbsTotals[c]),
						(e = isNaN(f.y) ? 0 : 0 === c ? 0 : 100 * (f.y / c)));
			else if (
				"pie" === a.type ||
				"doughnut" === a.type ||
				"funnel" === a.type ||
				"pyramid" === a.type
			) {
				for (c = b = 0; c < a.dataPoints.length; c++)
					isNaN(a.dataPoints[c].y) || (b += a.dataPoints[c].y);
				e = isNaN(f.y) ? 0 : 100 * (f.y / b);
			}
			return { percent: e, total: b };
		};
		v.prototype.replaceKeywordsWithValue = function (a, f, c, b, e) {
			var h = this;
			e = "undefined" === typeof e ? 0 : e;
			if (
				(0 <= c.type.indexOf("stacked") ||
					"pie" === c.type ||
					"doughnut" === c.type ||
					"funnel" === c.type ||
					"pyramid" === c.type) &&
				(0 <= a.indexOf("#percent") || 0 <= a.indexOf("#total"))
			) {
				var g = "#percent",
					d = "#total",
					q = this.getPercentAndTotal(c, f),
					d = isNaN(q.total) ? d : q.total,
					g = isNaN(q.percent) ? g : q.percent;
				do {
					q = "";
					if (c.percentFormatString) q = c.percentFormatString;
					else {
						var q = "#,##0.",
							m = Math.max(Math.ceil(Math.log(1 / Math.abs(g)) / Math.LN10), 2);
						if (isNaN(m) || !isFinite(m)) m = 2;
						for (var n = 0; n < m; n++) q += "#";
						c.percentFormatString = q;
					}
					a = a.replace("#percent", ba(g, q, h._cultureInfo));
					a = a.replace(
						"#total",
						ba(
							d,
							c.yValueFormatString ? c.yValueFormatString : "#,##0.########",
							h._cultureInfo
						)
					);
				} while (0 <= a.indexOf("#percent") || 0 <= a.indexOf("#total"));
			}
			return a.replace(/\{.*?\}|"[^"]*"|'[^']*'/g, function (a) {
				if (
					('"' === a[0] && '"' === a[a.length - 1]) ||
					("'" === a[0] && "'" === a[a.length - 1])
				)
					return a.slice(1, a.length - 1);
				a = Oa(a.slice(1, a.length - 1));
				a = a.replace("#index", e);
				var g = null;
				try {
					var k = a.match(/(.*?)\s*\[\s*(.*?)\s*\]/);
					k && 0 < k.length && ((g = Oa(k[2])), (a = Oa(k[1])));
				} catch (d) {}
				k = null;
				if ("color" === a)
					return "waterfall" === c.type
						? f.color
							? f.color
							: 0 < f.y
							? c.risingColor
							: c.fallingColor
						: "error" === c.type
						? c.color
							? c.color
							: c._colorSet[g % c._colorSet.length]
						: f.color
						? f.color
						: c.color
						? c.color
						: c._colorSet[b % c._colorSet.length];
				if (f.hasOwnProperty(a)) k = f;
				else if (c.hasOwnProperty(a)) k = c;
				else return "";
				k = k[a];
				null !== g && (k = k[g]);
				if ("x" === a)
					if (
						(c.axisX && "dateTime" === c.axisX.valueType) ||
						"dateTime" === c.xValueType ||
						(f.x && f.x.getTime)
					) {
						if (!c.axisX.logarithmic)
							return ha(
								k,
								f.xValueFormatString
									? f.xValueFormatString
									: c.xValueFormatString
									? c.xValueFormatString
									: (c.xValueFormatString =
											h.axisX && h.axisX.autoValueFormatString
												? h.axisX.autoValueFormatString
												: "DD MMM YY"),
								h._cultureInfo
							);
					} else
						return ba(
							k,
							f.xValueFormatString
								? f.xValueFormatString
								: c.xValueFormatString
								? c.xValueFormatString
								: (c.xValueFormatString = "#,##0.########"),
							h._cultureInfo
						);
				else
					return "y" === a
						? ba(
								k,
								f.yValueFormatString
									? f.yValueFormatString
									: c.yValueFormatString
									? c.yValueFormatString
									: (c.yValueFormatString = "#,##0.########"),
								h._cultureInfo
						  )
						: "z" === a
						? ba(
								k,
								f.zValueFormatString
									? f.zValueFormatString
									: c.zValueFormatString
									? c.zValueFormatString
									: (c.zValueFormatString = "#,##0.########"),
								h._cultureInfo
						  )
						: k;
			});
		};
		ia(K, da);
		K.prototype.setLayout = function () {
			var a = this.dockInsidePlotArea ? this.chart.plotArea : this.chart,
				f = a.layoutManager.getFreeSpace(),
				c = null,
				b = 0,
				e = 0,
				h = 0,
				d = 0,
				q = (this.markerMargin =
					this.chart.options.legend &&
					!g(this.chart.options.legend.markerMargin)
						? this.chart.options.legend.markerMargin
						: 0.3 * this.fontSize);
			this.height = 0;
			var l = [],
				m = [];
			if ("top" === this.verticalAlign || "bottom" === this.verticalAlign)
				(this.orientation = "horizontal"),
					(c = this.verticalAlign),
					(h = this.maxWidth =
						null !== this.maxWidth ? this.maxWidth : f.width),
					(d = this.maxHeight =
						null !== this.maxHeight ? this.maxHeight : 0.5 * f.height);
			else if ("center" === this.verticalAlign) {
				this.orientation = "vertical";
				if (
					"left" === this.horizontalAlign ||
					"center" === this.horizontalAlign ||
					"right" === this.horizontalAlign
				)
					c = this.horizontalAlign;
				h = this.maxWidth =
					null !== this.maxWidth ? this.maxWidth : 0.5 * f.width;
				d = this.maxHeight =
					null !== this.maxHeight ? this.maxHeight : f.height;
			}
			this.errorMarkerColor = [];
			for (var n = 0; n < this.dataSeries.length; n++) {
				var p = this.dataSeries[n];
				if (p.dataPoints && p.dataPoints.length)
					if (
						"pie" !== p.type &&
						"doughnut" !== p.type &&
						"funnel" !== p.type &&
						"pyramid" !== p.type
					) {
						var r = (p.legendMarkerType = p.legendMarkerType
								? p.legendMarkerType
								: ("line" !== p.type &&
										"stepLine" !== p.type &&
										"spline" !== p.type &&
										"scatter" !== p.type &&
										"bubble" !== p.type) ||
								  !p.markerType
								? "error" === p.type && p._linkedSeries
									? p._linkedSeries.legendMarkerType
										? p._linkedSeries.legendMarkerType
										: P.getDefaultLegendMarker(p._linkedSeries.type)
									: P.getDefaultLegendMarker(p.type)
								: p.markerType),
							k = p.legendText
								? p.legendText
								: this.itemTextFormatter
								? this.itemTextFormatter({
										chart: this.chart,
										legend: this.options,
										dataSeries: p,
										dataPoint: null,
								  })
								: p.name,
							x = (p.legendMarkerColor = p.legendMarkerColor
								? p.legendMarkerColor
								: p.markerColor
								? p.markerColor
								: "error" === p.type
								? g(p.whiskerColor)
									? p._colorSet[0]
									: p.whiskerColor
								: p._colorSet[0]),
							z =
								p.markerSize ||
								("line" !== p.type &&
									"stepLine" !== p.type &&
									"spline" !== p.type)
									? 0.75 * this.lineHeight
									: 0,
							s = p.legendMarkerBorderColor
								? p.legendMarkerBorderColor
								: p.markerBorderColor,
							u = p.legendMarkerBorderThickness
								? p.legendMarkerBorderThickness
								: p.markerBorderThickness
								? Math.max(1, Math.round(0.2 * z))
								: 0;
						"error" === p.type && this.errorMarkerColor.push(x);
						k = this.chart.replaceKeywordsWithValue(k, p.dataPoints[0], p, n);
						r = {
							markerType: r,
							markerColor: x,
							text: k,
							textBlock: null,
							chartType: p.type,
							markerSize: z,
							lineColor: p._colorSet[0],
							dataSeriesIndex: p.index,
							dataPointIndex: null,
							markerBorderColor: s,
							markerBorderThickness: u,
						};
						l.push(r);
					} else
						for (var t = 0; t < p.dataPoints.length; t++) {
							var v = p.dataPoints[t],
								r = v.legendMarkerType
									? v.legendMarkerType
									: p.legendMarkerType
									? p.legendMarkerType
									: P.getDefaultLegendMarker(p.type),
								k = v.legendText
									? v.legendText
									: p.legendText
									? p.legendText
									: this.itemTextFormatter
									? this.itemTextFormatter({
											chart: this.chart,
											legend: this.options,
											dataSeries: p,
											dataPoint: v,
									  })
									: v.name
									? v.name
									: "DataPoint: " + (t + 1),
								x = v.legendMarkerColor
									? v.legendMarkerColor
									: p.legendMarkerColor
									? p.legendMarkerColor
									: v.color
									? v.color
									: p.color
									? p.color
									: p._colorSet[t % p._colorSet.length],
								z = 0.75 * this.lineHeight,
								s = v.legendMarkerBorderColor
									? v.legendMarkerBorderColor
									: p.legendMarkerBorderColor
									? p.legendMarkerBorderColor
									: v.markerBorderColor
									? v.markerBorderColor
									: p.markerBorderColor,
								u = v.legendMarkerBorderThickness
									? v.legendMarkerBorderThickness
									: p.legendMarkerBorderThickness
									? p.legendMarkerBorderThickness
									: v.markerBorderThickness || p.markerBorderThickness
									? Math.max(1, Math.round(0.2 * z))
									: 0,
								k = this.chart.replaceKeywordsWithValue(k, v, p, t),
								r = {
									markerType: r,
									markerColor: x,
									text: k,
									textBlock: null,
									chartType: p.type,
									markerSize: z,
									dataSeriesIndex: n,
									dataPointIndex: t,
									markerBorderColor: s,
									markerBorderThickness: u,
								};
							(v.showInLegend || (p.showInLegend && !1 !== v.showInLegend)) &&
								l.push(r);
						}
			}
			!0 === this.reversed && l.reverse();
			if (0 < l.length) {
				p = null;
				x = k = v = t = 0;
				v =
					null !== this.itemWidth
						? null !== this.itemMaxWidth
							? Math.min(this.itemWidth, this.itemMaxWidth, h)
							: (this.itemMaxWidth = Math.min(this.itemWidth, h))
						: null !== this.itemMaxWidth
						? Math.min(this.itemMaxWidth, h)
						: (this.itemMaxWidth = h);
				z = 0 === z ? 0.75 * this.lineHeight : z;
				v -= z + q;
				for (n = 0; n < l.length; n++) {
					r = l[n];
					s = v;
					if (
						"line" === r.chartType ||
						"spline" === r.chartType ||
						"stepLine" === r.chartType
					)
						s -= 2 * 0.1 * this.lineHeight;
					if (
						!(
							0 >= d ||
							"undefined" === typeof d ||
							0 >= s ||
							"undefined" === typeof s
						)
					) {
						if ("horizontal" === this.orientation) {
							r.textBlock = new na(this.ctx, {
								x: 0,
								y: 0,
								maxWidth: s,
								maxHeight: this.itemWrap ? d : this.lineHeight,
								angle: 0,
								text: r.text,
								horizontalAlign: "left",
								fontSize: this.fontSize,
								fontFamily: this.fontFamily,
								fontWeight: this.fontWeight,
								fontColor: this.fontColor,
								fontStyle: this.fontStyle,
								textBaseline: "middle",
							});
							r.textBlock.measureText();
							null !== this.itemWidth &&
								(r.textBlock.width =
									this.itemWidth -
									(z +
										q +
										("line" === r.chartType ||
										"spline" === r.chartType ||
										"stepLine" === r.chartType
											? 2 * 0.1 * this.lineHeight
											: 0)));
							if (
								!p ||
								p.width +
									Math.round(
										r.textBlock.width +
											z +
											q +
											(0 === p.width ? 0 : this.horizontalSpacing) +
											("line" === r.chartType ||
											"spline" === r.chartType ||
											"stepLine" === r.chartType
												? 2 * 0.1 * this.lineHeight
												: 0)
									) >
									h
							)
								(p = { items: [], width: 0 }),
									m.push(p),
									(this.height += k),
									(k = 0);
							k = Math.max(k, r.textBlock.height);
						} else
							(r.textBlock = new na(this.ctx, {
								x: 0,
								y: 0,
								maxWidth: v,
								maxHeight: !0 === this.itemWrap ? d : 1.5 * this.fontSize,
								angle: 0,
								text: r.text,
								horizontalAlign: "left",
								fontSize: this.fontSize,
								fontFamily: this.fontFamily,
								fontWeight: this.fontWeight,
								fontColor: this.fontColor,
								fontStyle: this.fontStyle,
								textBaseline: "middle",
							})),
								r.textBlock.measureText(),
								null !== this.itemWidth &&
									(r.textBlock.width =
										this.itemWidth -
										(z +
											q +
											("line" === r.chartType ||
											"spline" === r.chartType ||
											"stepLine" === r.chartType
												? 2 * 0.1 * this.lineHeight
												: 0))),
								this.height < d - this.lineHeight
									? ((p = { items: [], width: 0 }), m.push(p))
									: ((p = m[t]), (t = (t + 1) % m.length)),
								(this.height += r.textBlock.height);
						r.textBlock.x = p.width;
						r.textBlock.y = 0;
						p.width += Math.round(
							r.textBlock.width +
								z +
								q +
								(0 === p.width ? 0 : this.horizontalSpacing) +
								("line" === r.chartType ||
								"spline" === r.chartType ||
								"stepLine" === r.chartType
									? 2 * 0.1 * this.lineHeight
									: 0)
						);
						p.items.push(r);
						this.width = Math.max(p.width, this.width);
						x =
							r.textBlock.width +
							(z +
								q +
								("line" === r.chartType ||
								"spline" === r.chartType ||
								"stepLine" === r.chartType
									? 2 * 0.1 * this.lineHeight
									: 0));
					}
				}
				this.itemWidth = x;
				this.height =
					!1 === this.itemWrap ? m.length * this.lineHeight : this.height + k;
				this.height = Math.min(d, this.height);
				this.width = Math.min(h, this.width);
			}
			"top" === this.verticalAlign
				? ((e =
						"left" === this.horizontalAlign
							? f.x1
							: "right" === this.horizontalAlign
							? f.x2 - this.width
							: f.x1 + f.width / 2 - this.width / 2),
				  (b = f.y1))
				: "center" === this.verticalAlign
				? ((e =
						"left" === this.horizontalAlign
							? f.x1
							: "right" === this.horizontalAlign
							? f.x2 - this.width
							: f.x1 + f.width / 2 - this.width / 2),
				  (b = f.y1 + f.height / 2 - this.height / 2))
				: "bottom" === this.verticalAlign &&
				  ((e =
						"left" === this.horizontalAlign
							? f.x1
							: "right" === this.horizontalAlign
							? f.x2 - this.width
							: f.x1 + f.width / 2 - this.width / 2),
				  (b = f.y2 - this.height));
			this.items = l;
			for (n = 0; n < this.items.length; n++)
				(r = l[n]),
					(r.id = ++this.chart._eventManager.lastObjectId),
					(this.chart._eventManager.objectMap[r.id] = {
						id: r.id,
						objectType: "legendItem",
						legendItemIndex: n,
						dataSeriesIndex: r.dataSeriesIndex,
						dataPointIndex: r.dataPointIndex,
					});
			this.markerSize = z;
			this.rows = m;
			0 < l.length &&
				a.layoutManager.registerSpace(c, {
					width: this.width + 2 + 2,
					height: this.height + 5 + 5,
				});
			this.bounds = { x1: e, y1: b, x2: e + this.width, y2: b + this.height };
		};
		K.prototype.render = function () {
			var a = this.bounds.x1,
				f = this.bounds.y1,
				c = this.markerMargin,
				b = this.maxWidth,
				e = this.maxHeight,
				h = this.markerSize,
				g = this.rows;
			((0 < this.borderThickness && this.borderColor) ||
				this.backgroundColor) &&
				this.ctx.roundRect(
					a,
					f,
					this.width,
					this.height,
					this.cornerRadius,
					this.borderThickness,
					this.backgroundColor,
					this.borderColor
				);
			for (var d = 0, q = 0; q < g.length; q++) {
				for (var m = g[q], n = 0, p = 0; p < m.items.length; p++) {
					var r = m.items[p],
						k =
							r.textBlock.x + a + (0 === p ? 0.2 * h : this.horizontalSpacing),
						l = f + d,
						z = k;
					this.chart.data[r.dataSeriesIndex].visible ||
						(this.ctx.globalAlpha = 0.5);
					this.ctx.save();
					this.ctx.beginPath();
					this.ctx.rect(a, f, b, Math.max(e - (e % this.lineHeight), 0));
					this.ctx.clip();
					if (
						"line" === r.chartType ||
						"stepLine" === r.chartType ||
						"spline" === r.chartType
					)
						(this.ctx.strokeStyle = r.lineColor),
							(this.ctx.lineWidth = Math.ceil(this.lineHeight / 8)),
							this.ctx.beginPath(),
							this.ctx.moveTo(
								k - 0.1 * this.lineHeight,
								l + this.lineHeight / 2
							),
							this.ctx.lineTo(
								k + 0.85 * this.lineHeight,
								l + this.lineHeight / 2
							),
							this.ctx.stroke(),
							(z -= 0.1 * this.lineHeight);
					if ("error" === r.chartType) {
						this.ctx.strokeStyle = this.errorMarkerColor[0];
						this.ctx.lineWidth = h / 8;
						this.ctx.beginPath();
						var t = k - 0.08 * this.lineHeight + 0.1 * this.lineHeight,
							u = l + 0.15 * this.lineHeight,
							v = 0.7 * this.lineHeight,
							w = v + 0.02 * this.lineHeight;
						this.ctx.moveTo(t, u);
						this.ctx.lineTo(t + v, u);
						this.ctx.stroke();
						this.ctx.beginPath();
						this.ctx.moveTo(t + v / 2, u);
						this.ctx.lineTo(t + v / 2, u + w);
						this.ctx.stroke();
						this.ctx.beginPath();
						this.ctx.moveTo(t, u + w);
						this.ctx.lineTo(t + v, u + w);
						this.ctx.stroke();
						this.errorMarkerColor.shift();
					}
					s.drawMarker(
						k + h / 2,
						l + this.lineHeight / 2,
						this.ctx,
						r.markerType,
						"error" === r.chartType ||
							"line" === r.chartType ||
							"spline" === r.chartType
							? r.markerSize / 2
							: r.markerSize,
						r.markerColor,
						r.markerBorderColor,
						r.markerBorderThickness
					);
					r.textBlock.x = k + c + h;
					if (
						"line" === r.chartType ||
						"stepLine" === r.chartType ||
						"spline" === r.chartType
					)
						r.textBlock.x += 0.1 * this.lineHeight;
					r.textBlock.y = Math.round(l + this.lineHeight / 2);
					r.textBlock.render(!0);
					this.ctx.restore();
					n = 0 < p ? Math.max(n, r.textBlock.height) : r.textBlock.height;
					this.chart.data[r.dataSeriesIndex].visible ||
						(this.ctx.globalAlpha = 1);
					k = U(r.id);
					this.ghostCtx.fillStyle = k;
					this.ghostCtx.beginPath();
					this.ghostCtx.fillRect(
						z,
						r.textBlock.y - this.lineHeight / 2,
						r.textBlock.x + r.textBlock.width - z,
						r.textBlock.height
					);
					r.x1 = this.chart._eventManager.objectMap[r.id].x1 = z;
					r.y1 = this.chart._eventManager.objectMap[r.id].y1 =
						r.textBlock.y - this.lineHeight / 2;
					r.x2 = this.chart._eventManager.objectMap[r.id].x2 =
						r.textBlock.x + r.textBlock.width;
					r.y2 = this.chart._eventManager.objectMap[r.id].y2 =
						r.textBlock.y + r.textBlock.height - this.lineHeight / 2;
				}
				d += n;
			}
		};
		ia(P, da);
		P.prototype.getDefaultAxisPlacement = function () {
			var a = this.type;
			if (
				"column" === a ||
				"line" === a ||
				"stepLine" === a ||
				"spline" === a ||
				"area" === a ||
				"stepArea" === a ||
				"splineArea" === a ||
				"stackedColumn" === a ||
				"stackedLine" === a ||
				"bubble" === a ||
				"scatter" === a ||
				"stackedArea" === a ||
				"stackedColumn100" === a ||
				"stackedLine100" === a ||
				"stackedArea100" === a ||
				"candlestick" === a ||
				"ohlc" === a ||
				"rangeColumn" === a ||
				"rangeArea" === a ||
				"rangeSplineArea" === a ||
				"boxAndWhisker" === a ||
				"waterfall" === a
			)
				return "normal";
			if (
				"bar" === a ||
				"stackedBar" === a ||
				"stackedBar100" === a ||
				"rangeBar" === a
			)
				return "xySwapped";
			if ("pie" === a || "doughnut" === a || "funnel" === a || "pyramid" === a)
				return "none";
			"error" !== a && window.console.log("Unknown Chart Type: " + a);
			return null;
		};
		P.getDefaultLegendMarker = function (a) {
			if (
				"column" === a ||
				"stackedColumn" === a ||
				"stackedLine" === a ||
				"bar" === a ||
				"stackedBar" === a ||
				"stackedBar100" === a ||
				"bubble" === a ||
				"scatter" === a ||
				"stackedColumn100" === a ||
				"stackedLine100" === a ||
				"stepArea" === a ||
				"candlestick" === a ||
				"ohlc" === a ||
				"rangeColumn" === a ||
				"rangeBar" === a ||
				"rangeArea" === a ||
				"rangeSplineArea" === a ||
				"boxAndWhisker" === a ||
				"waterfall" === a
			)
				return "square";
			if (
				"line" === a ||
				"stepLine" === a ||
				"spline" === a ||
				"pie" === a ||
				"doughnut" === a
			)
				return "circle";
			if (
				"area" === a ||
				"splineArea" === a ||
				"stackedArea" === a ||
				"stackedArea100" === a ||
				"funnel" === a ||
				"pyramid" === a
			)
				return "triangle";
			if ("error" === a) return "none";
			window.console.log("Unknown Chart Type: " + a);
			return null;
		};
		P.prototype.getDataPointAtX = function (a, f) {
			if (!this.dataPoints || 0 === this.dataPoints.length) return null;
			var c = { dataPoint: null, distance: Infinity, index: NaN },
				b = null,
				e = 0,
				h = 0,
				g = 1,
				d = Infinity,
				q = 0,
				m = 0,
				n = 0;
			"none" !== this.chart.plotInfo.axisPlacement &&
				(this.axisX.logarithmic
					? ((n = Math.log(
							this.dataPoints[this.dataPoints.length - 1].x /
								this.dataPoints[0].x
					  )),
					  (n =
							1 < n
								? Math.min(
										Math.max(
											(((this.dataPoints.length - 1) / n) *
												Math.log(a / this.dataPoints[0].x)) >>
												0,
											0
										),
										this.dataPoints.length
								  )
								: 0))
					: ((n =
							this.dataPoints[this.dataPoints.length - 1].x -
							this.dataPoints[0].x),
					  (n =
							0 < n
								? Math.min(
										Math.max(
											(((this.dataPoints.length - 1) / n) *
												(a - this.dataPoints[0].x)) >>
												0,
											0
										),
										this.dataPoints.length
								  )
								: 0)));
			for (;;) {
				h = 0 < g ? n + e : n - e;
				if (0 <= h && h < this.dataPoints.length) {
					var b = this.dataPoints[h],
						p = this.axisX.logarithmic
							? b.x > a
								? b.x / a
								: a / b.x
							: Math.abs(b.x - a);
					p < c.distance &&
						((c.dataPoint = b), (c.distance = p), (c.index = h));
					b = p;
					b <= d ? (d = b) : 0 < g ? q++ : m++;
					if (1e3 < q && 1e3 < m) break;
				} else if (0 > n - e && n + e >= this.dataPoints.length) break;
				-1 === g ? (e++, (g = 1)) : (g = -1);
			}
			return f ||
				(c.dataPoint.x.getTime ? c.dataPoint.x.getTime() : c.dataPoint.x) !==
					(a.getTime ? a.getTime() : a)
				? f && null !== c.dataPoint
					? c
					: null
				: c;
		};
		P.prototype.getDataPointAtXY = function (a, f, c) {
			if (
				!this.dataPoints ||
				0 === this.dataPoints.length ||
				a < this.chart.plotArea.x1 ||
				a > this.chart.plotArea.x2 ||
				f < this.chart.plotArea.y1 ||
				f > this.chart.plotArea.y2
			)
				return null;
			c = c || !1;
			var b = [],
				e = 0,
				h = 0,
				g = 1,
				d = !1,
				q = Infinity,
				m = 0,
				n = 0,
				p = 0;
			if ("none" !== this.chart.plotInfo.axisPlacement)
				if (
					((p = (
						this.chart.axisX[0] ? this.chart.axisX[0] : this.chart.axisX2[0]
					).getXValueAt({ x: a, y: f })),
					this.axisX.logarithmic)
				)
					var r = Math.log(
							this.dataPoints[this.dataPoints.length - 1].x /
								this.dataPoints[0].x
						),
						p =
							1 < r
								? Math.min(
										Math.max(
											(((this.dataPoints.length - 1) / r) *
												Math.log(p / this.dataPoints[0].x)) >>
												0,
											0
										),
										this.dataPoints.length
								  )
								: 0;
				else
					(r =
						this.dataPoints[this.dataPoints.length - 1].x -
						this.dataPoints[0].x),
						(p =
							0 < r
								? Math.min(
										Math.max(
											(((this.dataPoints.length - 1) / r) *
												(p - this.dataPoints[0].x)) >>
												0,
											0
										),
										this.dataPoints.length
								  )
								: 0);
			for (;;) {
				h = 0 < g ? p + e : p - e;
				if (0 <= h && h < this.dataPoints.length) {
					var r = this.chart._eventManager.objectMap[this.dataPointIds[h]],
						k = this.dataPoints[h],
						l = null;
					if (r) {
						switch (this.type) {
							case "column":
							case "stackedColumn":
							case "stackedColumn100":
							case "bar":
							case "stackedBar":
							case "stackedBar100":
							case "rangeColumn":
							case "rangeBar":
							case "waterfall":
							case "error":
								a >= r.x1 &&
									a <= r.x2 &&
									f >= r.y1 &&
									f <= r.y2 &&
									(b.push({
										dataPoint: k,
										dataPointIndex: h,
										dataSeries: this,
										distance: Math.min(
											Math.abs(r.x1 - a),
											Math.abs(r.x2 - a),
											Math.abs(r.y1 - f),
											Math.abs(r.y2 - f)
										),
									}),
									(d = !0));
								break;
							case "line":
							case "stepLine":
							case "spline":
							case "area":
							case "stepArea":
							case "stackedArea":
							case "stackedArea100":
							case "splineArea":
							case "scatter":
								var z = oa("markerSize", k, this) || 4,
									s = c ? 20 : z,
									l = Math.sqrt(Math.pow(r.x1 - a, 2) + Math.pow(r.y1 - f, 2));
								l <= s &&
									b.push({
										dataPoint: k,
										dataPointIndex: h,
										dataSeries: this,
										distance: l,
									});
								r = Math.abs(r.x1 - a);
								r <= q ? (q = r) : 0 < g ? m++ : n++;
								l <= z / 2 && (d = !0);
								break;
							case "rangeArea":
							case "rangeSplineArea":
								z = oa("markerSize", k, this) || 4;
								s = c ? 20 : z;
								l = Math.min(
									Math.sqrt(Math.pow(r.x1 - a, 2) + Math.pow(r.y1 - f, 2)),
									Math.sqrt(Math.pow(r.x1 - a, 2) + Math.pow(r.y2 - f, 2))
								);
								l <= s &&
									b.push({
										dataPoint: k,
										dataPointIndex: h,
										dataSeries: this,
										distance: l,
									});
								r = Math.abs(r.x1 - a);
								r <= q ? (q = r) : 0 < g ? m++ : n++;
								l <= z / 2 && (d = !0);
								break;
							case "bubble":
								z = r.size;
								l = Math.sqrt(Math.pow(r.x1 - a, 2) + Math.pow(r.y1 - f, 2));
								l <= z / 2 &&
									(b.push({
										dataPoint: k,
										dataPointIndex: h,
										dataSeries: this,
										distance: l,
									}),
									(d = !0));
								break;
							case "pie":
							case "doughnut":
								z = r.center;
								s =
									"doughnut" === this.type
										? r.percentInnerRadius * r.radius
										: 0;
								l = Math.sqrt(Math.pow(z.x - a, 2) + Math.pow(z.y - f, 2));
								l < r.radius &&
									l > s &&
									((l = Math.atan2(f - z.y, a - z.x)),
									0 > l && (l += 2 * Math.PI),
									(l = Number(
										((((180 * (l / Math.PI)) % 360) + 360) % 360).toFixed(12)
									)),
									(z = Number(
										(
											(((180 * (r.startAngle / Math.PI)) % 360) + 360) %
											360
										).toFixed(12)
									)),
									(s = Number(
										(
											(((180 * (r.endAngle / Math.PI)) % 360) + 360) %
											360
										).toFixed(12)
									)),
									0 === s && 1 < r.endAngle && (s = 360),
									z >= s && 0 !== k.y && ((s += 360), l < z && (l += 360)),
									l > z &&
										l < s &&
										(b.push({
											dataPoint: k,
											dataPointIndex: h,
											dataSeries: this,
											distance: 0,
										}),
										(d = !0)));
								break;
							case "funnel":
							case "pyramid":
								l = r.funnelSection;
								f > l.y1 &&
									f < l.y4 &&
									(l.y6
										? f > l.y6
											? ((h =
													l.x6 + ((l.x5 - l.x6) / (l.y5 - l.y6)) * (f - l.y6)),
											  (l =
													l.x3 + ((l.x4 - l.x3) / (l.y4 - l.y3)) * (f - l.y3)))
											: ((h =
													l.x1 + ((l.x6 - l.x1) / (l.y6 - l.y1)) * (f - l.y1)),
											  (l =
													l.x2 + ((l.x3 - l.x2) / (l.y3 - l.y2)) * (f - l.y2)))
										: ((h =
												l.x1 + ((l.x4 - l.x1) / (l.y4 - l.y1)) * (f - l.y1)),
										  (l =
												l.x2 + ((l.x3 - l.x2) / (l.y3 - l.y2)) * (f - l.y2))),
									a > h &&
										a < l &&
										(b.push({
											dataPoint: k,
											dataPointIndex: r.dataPointIndex,
											dataSeries: this,
											distance: 0,
										}),
										(d = !0)));
								break;
							case "boxAndWhisker":
								if (
									(a >= r.x1 - r.borderThickness / 2 &&
										a <= r.x2 + r.borderThickness / 2 &&
										f >= r.y4 - r.borderThickness / 2 &&
										f <= r.y1 + r.borderThickness / 2) ||
									(Math.abs(r.x2 - a + r.x1 - a) < r.borderThickness &&
										f >= r.y1 &&
										f <= r.y4)
								)
									b.push({
										dataPoint: k,
										dataPointIndex: h,
										dataSeries: this,
										distance: Math.min(
											Math.abs(r.x1 - a),
											Math.abs(r.x2 - a),
											Math.abs(r.y2 - f),
											Math.abs(r.y3 - f)
										),
									}),
										(d = !0);
								break;
							case "candlestick":
								if (
									(a >= r.x1 - r.borderThickness / 2 &&
										a <= r.x2 + r.borderThickness / 2 &&
										f >= r.y2 - r.borderThickness / 2 &&
										f <= r.y3 + r.borderThickness / 2) ||
									(Math.abs(r.x2 - a + r.x1 - a) < r.borderThickness &&
										f >= r.y1 &&
										f <= r.y4)
								)
									b.push({
										dataPoint: k,
										dataPointIndex: h,
										dataSeries: this,
										distance: Math.min(
											Math.abs(r.x1 - a),
											Math.abs(r.x2 - a),
											Math.abs(r.y2 - f),
											Math.abs(r.y3 - f)
										),
									}),
										(d = !0);
								break;
							case "ohlc":
								if (
									(Math.abs(r.x2 - a + r.x1 - a) < r.borderThickness &&
										f >= r.y2 &&
										f <= r.y3) ||
									(a >= r.x1 &&
										a <= (r.x2 + r.x1) / 2 &&
										f >= r.y1 - r.borderThickness / 2 &&
										f <= r.y1 + r.borderThickness / 2) ||
									(a >= (r.x1 + r.x2) / 2 &&
										a <= r.x2 &&
										f >= r.y4 - r.borderThickness / 2 &&
										f <= r.y4 + r.borderThickness / 2)
								)
									b.push({
										dataPoint: k,
										dataPointIndex: h,
										dataSeries: this,
										distance: Math.min(
											Math.abs(r.x1 - a),
											Math.abs(r.x2 - a),
											Math.abs(r.y2 - f),
											Math.abs(r.y3 - f)
										),
									}),
										(d = !0);
						}
						if (d || (1e3 < m && 1e3 < n)) break;
					}
				} else if (0 > p - e && p + e >= this.dataPoints.length) break;
				-1 === g ? (e++, (g = 1)) : (g = -1);
			}
			a = null;
			for (f = 0; f < b.length; f++)
				a ? b[f].distance <= a.distance && (a = b[f]) : (a = b[f]);
			return a;
		};
		P.prototype.getMarkerProperties = function (a, f, c, b) {
			var e = this.dataPoints;
			return {
				x: f,
				y: c,
				ctx: b,
				type: e[a].markerType ? e[a].markerType : this.markerType,
				size: e[a].markerSize ? e[a].markerSize : this.markerSize,
				color: e[a].markerColor
					? e[a].markerColor
					: this.markerColor
					? this.markerColor
					: e[a].color
					? e[a].color
					: this.color
					? this.color
					: this._colorSet[a % this._colorSet.length],
				borderColor: e[a].markerBorderColor
					? e[a].markerBorderColor
					: this.markerBorderColor
					? this.markerBorderColor
					: null,
				borderThickness: e[a].markerBorderThickness
					? e[a].markerBorderThickness
					: this.markerBorderThickness
					? this.markerBorderThickness
					: null,
			};
		};
		ia(D, da);
		D.prototype.createExtraLabelsForLog = function (a) {
			a = (a || 0) + 1;
			if (!(5 < a)) {
				var f = this.logLabelValues[0] || this.intervalStartPosition;
				if (
					Math.log(this.range) / Math.log(f / this.viewportMinimum) <
					this.noTicks - 1
				) {
					for (
						var c = D.getNiceNumber(
								(f - this.viewportMinimum) /
									Math.min(
										Math.max(2, this.noTicks - this.logLabelValues.length),
										3
									),
								!0
							),
							b = Math.ceil(this.viewportMinimum / c) * c;
						b < f;
						b += c
					)
						b < this.viewportMinimum || this.logLabelValues.push(b);
					this.logLabelValues.sort(Xa);
					this.createExtraLabelsForLog(a);
				}
			}
		};
		D.prototype.createLabels = function () {
			var a,
				f,
				c = 0,
				b = 0,
				e,
				h = 0,
				d = 0,
				b = 0,
				b = this.interval,
				q = 0,
				l,
				m = 0.6 * this.chart.height,
				n;
			a = !1;
			var p = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [],
				r = p.length
					? g(this.scaleBreaks.firstBreakIndex)
						? 0
						: this.scaleBreaks.firstBreakIndex
					: 0;
			if (
				"axisX" !== this.type ||
				"dateTime" !== this.valueType ||
				this.logarithmic
			) {
				e = this.viewportMaximum;
				if (this.labels) {
					a = Math.ceil(b);
					for (
						var b = Math.ceil(this.intervalStartPosition), k = !1, c = b;
						c < this.viewportMaximum;
						c += a
					)
						if (this.labels[c]) k = !0;
						else {
							k = !1;
							break;
						}
					k && ((this.interval = a), (this.intervalStartPosition = b));
				}
				if (this.logarithmic && !this.equidistantInterval)
					for (
						this.logLabelValues ||
							((this.logLabelValues = []), this.createExtraLabelsForLog()),
							b = 0,
							k = r;
						b < this.logLabelValues.length;
						b++
					)
						if (((c = this.logLabelValues[b]), c < this.viewportMinimum)) b++;
						else {
							for (; k < p.length && c > p[k].endValue; k++);
							a = k < p.length && c >= p[k].startValue && c <= p[k].endValue;
							n = c;
							a ||
								((a = this.labelFormatter
									? this.labelFormatter({
											chart: this.chart,
											axis: this.options,
											value: n,
											label: this.labels[n] ? this.labels[n] : null,
									  })
									: "axisX" === this.type && this.labels[n]
									? this.labels[n]
									: ba(n, this.valueFormatString, this.chart._cultureInfo)),
								(a = new na(this.ctx, {
									x: 0,
									y: 0,
									maxWidth: h,
									maxHeight: d,
									angle: this.labelAngle,
									text: this.prefix + a + this.suffix,
									backgroundColor: this.labelBackgroundColor,
									borderColor: this.labelBorderColor,
									cornerRadius: this.labelCornerRadius,
									textAlign: this.labelTextAlign,
									fontSize: this.labelFontSize,
									fontFamily: this.labelFontFamily,
									fontWeight: this.labelFontWeight,
									fontColor: this.labelFontColor,
									fontStyle: this.labelFontStyle,
									textBaseline: "middle",
									borderThickness: 0,
								})),
								this._labels.push({
									position: n,
									textBlock: a,
									effectiveHeight: null,
								}));
						}
				k = r;
				for (
					c = this.intervalStartPosition;
					c <= e;
					c = parseFloat(
						1e-12 > this.interval
							? this.logarithmic && this.equidistantInterval
								? c * Math.pow(this.logarithmBase, this.interval)
								: c + this.interval
							: (this.logarithmic && this.equidistantInterval
									? c * Math.pow(this.logarithmBase, this.interval)
									: c + this.interval
							  ).toFixed(12)
					)
				) {
					for (; k < p.length && c > p[k].endValue; k++);
					a = k < p.length && c >= p[k].startValue && c <= p[k].endValue;
					n = c;
					a ||
						((a = this.labelFormatter
							? this.labelFormatter({
									chart: this.chart,
									axis: this.options,
									value: n,
									label: this.labels[n] ? this.labels[n] : null,
							  })
							: "axisX" === this.type && this.labels[n]
							? this.labels[n]
							: ba(n, this.valueFormatString, this.chart._cultureInfo)),
						(a = new na(this.ctx, {
							x: 0,
							y: 0,
							maxWidth: h,
							maxHeight: d,
							angle: this.labelAngle,
							text: this.prefix + a + this.suffix,
							textAlign: this.labelTextAlign,
							backgroundColor: this.labelBackgroundColor,
							borderColor: this.labelBorderColor,
							borderThickness: this.labelBorderThickness,
							cornerRadius: this.labelCornerRadius,
							fontSize: this.labelFontSize,
							fontFamily: this.labelFontFamily,
							fontWeight: this.labelFontWeight,
							fontColor: this.labelFontColor,
							fontStyle: this.labelFontStyle,
							textBaseline: "middle",
						})),
						this._labels.push({
							position: n,
							textBlock: a,
							effectiveHeight: null,
						}));
				}
			} else
				for (
					this.intervalStartPosition = this.getLabelStartPoint(
						new Date(this.viewportMinimum),
						this.intervalType,
						this.interval
					),
						e = cb(
							new Date(this.viewportMaximum),
							this.interval,
							this.intervalType
						),
						k = r,
						c = this.intervalStartPosition;
					c < e;
					cb(c, b, this.intervalType)
				) {
					for (a = c.getTime(); k < p.length && a > p[k].endValue; k++);
					n = a;
					a = k < p.length && a >= p[k].startValue && a <= p[k].endValue;
					a ||
						((a = this.labelFormatter
							? this.labelFormatter({
									chart: this.chart,
									axis: this.options,
									value: new Date(n),
									label: this.labels[n] ? this.labels[n] : null,
							  })
							: "axisX" === this.type && this.labels[n]
							? this.labels[n]
							: ha(n, this.valueFormatString, this.chart._cultureInfo)),
						(a = new na(this.ctx, {
							x: 0,
							y: 0,
							maxWidth: h,
							backgroundColor: this.labelBackgroundColor,
							borderColor: this.labelBorderColor,
							borderThickness: this.labelBorderThickness,
							cornerRadius: this.labelCornerRadius,
							maxHeight: d,
							angle: this.labelAngle,
							text: this.prefix + a + this.suffix,
							textAlign: this.labelTextAlign,
							fontSize: this.labelFontSize,
							fontFamily: this.labelFontFamily,
							fontWeight: this.labelFontWeight,
							fontColor: this.labelFontColor,
							fontStyle: this.labelFontStyle,
							textBaseline: "middle",
						})),
						this._labels.push({
							position: n,
							textBlock: a,
							effectiveHeight: null,
							breaksLabelType: void 0,
						}));
				}
			if ("bottom" === this._position || "top" === this._position)
				(q =
					this.logarithmic &&
					!this.equidistantInterval &&
					2 <= this._labels.length
						? (this.lineCoordinates.width *
								Math.log(
									Math.min(
										this._labels[this._labels.length - 1].position /
											this._labels[this._labels.length - 2].position,
										this._labels[1].position / this._labels[0].position
									)
								)) /
						  Math.log(this.range)
						: (this.lineCoordinates.width /
								(this.logarithmic && this.equidistantInterval
									? Math.log(this.range) / Math.log(this.logarithmBase)
									: Math.abs(this.range))) *
						  Z[this.intervalType + "Duration"] *
						  this.interval),
					(h =
						"undefined" === typeof this.options.labelMaxWidth
							? (0.5 * this.chart.width) >> 0
							: this.options.labelMaxWidth),
					this.chart.panEnabled ||
						(d =
							"undefined" === typeof this.options.labelWrap || this.labelWrap
								? (0.8 * this.chart.height) >> 0
								: 1.5 * this.labelFontSize);
			else if ("left" === this._position || "right" === this._position)
				(q =
					this.logarithmic &&
					!this.equidistantInterval &&
					2 <= this._labels.length
						? (this.lineCoordinates.height *
								Math.log(
									Math.min(
										this._labels[this._labels.length - 1].position /
											this._labels[this._labels.length - 2].position,
										this._labels[1].position / this._labels[0].position
									)
								)) /
						  Math.log(this.range)
						: (this.lineCoordinates.height /
								(this.logarithmic && this.equidistantInterval
									? Math.log(this.range) / Math.log(this.logarithmBase)
									: Math.abs(this.range))) *
						  Z[this.intervalType + "Duration"] *
						  this.interval),
					this.chart.panEnabled ||
						(h =
							"undefined" === typeof this.options.labelMaxWidth
								? (0.3 * this.chart.width) >> 0
								: this.options.labelMaxWidth),
					(d =
						"undefined" === typeof this.options.labelWrap || this.labelWrap
							? (0.3 * this.chart.height) >> 0
							: 1.5 * this.labelFontSize);
			for (b = 0; b < this._labels.length; b++) {
				a = this._labels[b].textBlock;
				a.maxWidth = h;
				a.maxHeight = d;
				var z = a.measureText();
				l = z.height;
			}
			e = [];
			r = p = 0;
			if (this.labelAutoFit || this.options.labelAutoFit)
				if (
					(g(this.labelAngle) ||
						((this.labelAngle = ((this.labelAngle % 360) + 360) % 360),
						90 < this.labelAngle && 270 > this.labelAngle
							? (this.labelAngle -= 180)
							: 270 <= this.labelAngle &&
							  360 >= this.labelAngle &&
							  (this.labelAngle -= 360)),
					"bottom" === this._position || "top" === this._position)
				)
					if (
						((h = (0.9 * q) >> 0),
						(r = 0),
						!this.chart.panEnabled && 1 <= this._labels.length)
					) {
						this.sessionVariables.labelFontSize = this.labelFontSize;
						this.sessionVariables.labelMaxWidth = h;
						this.sessionVariables.labelMaxHeight = d;
						this.sessionVariables.labelAngle = this.labelAngle;
						this.sessionVariables.labelWrap = this.labelWrap;
						for (c = 0; c < this._labels.length; c++)
							if (!this._labels[c].breaksLabelType) {
								a = this._labels[c].textBlock;
								for (var s, k = a.text.split(" "), b = 0; b < k.length; b++)
									(n = k[b]),
										(this.ctx.font =
											a.fontStyle +
											" " +
											a.fontWeight +
											" " +
											a.fontSize +
											"px " +
											a.fontFamily),
										(n = this.ctx.measureText(n)),
										n.width > r && ((s = c), (r = n.width));
							}
						c = 0;
						for (
							c = this.intervalStartPosition < this.viewportMinimum ? 1 : 0;
							c < this._labels.length;
							c++
						)
							if (!this._labels[c].breaksLabelType) {
								a = this._labels[c].textBlock;
								z = a.measureText();
								for (k = c + 1; k < this._labels.length; k++)
									if (!this._labels[k].breaksLabelType) {
										f = this._labels[k].textBlock;
										f = f.measureText();
										break;
									}
								e.push(a.height);
								this.sessionVariables.labelMaxHeight = Math.max.apply(Math, e);
								Math.cos((Math.PI / 180) * Math.abs(this.labelAngle));
								Math.sin((Math.PI / 180) * Math.abs(this.labelAngle));
								b =
									h * Math.sin((Math.PI / 180) * Math.abs(this.labelAngle)) +
									(d - a.fontSize / 2) *
										Math.cos((Math.PI / 180) * Math.abs(this.labelAngle));
								if (
									g(this.options.labelAngle) &&
									isNaN(this.options.labelAngle) &&
									0 !== this.options.labelAngle
								)
									if (
										((this.sessionVariables.labelMaxHeight =
											0 === this.labelAngle
												? d
												: Math.min(
														(b -
															h *
																Math.cos(
																	(Math.PI / 180) * Math.abs(this.labelAngle)
																)) /
															Math.sin(
																(Math.PI / 180) * Math.abs(this.labelAngle)
															),
														b
												  )),
										(n =
											(m -
												(l + a.fontSize / 2) *
													Math.cos((Math.PI / 180) * Math.abs(-25))) /
											Math.sin((Math.PI / 180) * Math.abs(-25))),
										!g(this.options.labelWrap))
									)
										this.labelWrap
											? g(this.options.labelMaxWidth)
												? ((this.sessionVariables.labelMaxWidth = Math.min(
														Math.max(h, r),
														n
												  )),
												  (this.sessionVariables.labelWrap = this.labelWrap),
												  f &&
														(z.width + f.width) >> 0 > 2 * h &&
														(this.sessionVariables.labelAngle = -25))
												: ((this.sessionVariables.labelWrap = this.labelWrap),
												  (this.sessionVariables.labelMaxWidth =
														this.options.labelMaxWidth),
												  (this.sessionVariables.labelAngle =
														this.sessionVariables.labelMaxWidth > h
															? -25
															: this.sessionVariables.labelAngle))
											: g(this.options.labelMaxWidth)
											? ((this.sessionVariables.labelWrap = this.labelWrap),
											  (this.sessionVariables.labelMaxHeight = d),
											  (this.sessionVariables.labelMaxWidth = h),
											  f &&
													(z.width + f.width) >> 0 > 2 * h &&
													((this.sessionVariables.labelAngle = -25),
													(this.sessionVariables.labelMaxWidth = n)))
											: ((this.sessionVariables.labelAngle =
													this.sessionVariables.labelMaxWidth > h
														? -25
														: this.sessionVariables.labelAngle),
											  (this.sessionVariables.labelMaxWidth =
													this.options.labelMaxWidth),
											  (this.sessionVariables.labelMaxHeight = d),
											  (this.sessionVariables.labelWrap = this.labelWrap));
									else {
										if (g(this.options.labelWrap))
											if (!g(this.options.labelMaxWidth))
												this.options.labelMaxWidth < h
													? ((this.sessionVariables.labelMaxWidth =
															this.options.labelMaxWidth),
													  (this.sessionVariables.labelMaxHeight = b))
													: ((this.sessionVariables.labelAngle = -25),
													  (this.sessionVariables.labelMaxWidth =
															this.options.labelMaxWidth),
													  (this.sessionVariables.labelMaxHeight = d));
											else if (!g(f))
												if (
													((b = (z.width + f.width) >> 0),
													(k = this.labelFontSize),
													r < h)
												)
													b - 2 * h > p &&
														((p = b - 2 * h),
														b >= 2 * h && b < 2.2 * h
															? ((this.sessionVariables.labelMaxWidth = h),
															  g(this.options.labelFontSize) &&
																	12 < k &&
																	((k = Math.floor((12 / 13) * k)),
																	a.measureText()),
															  (this.sessionVariables.labelFontSize = g(
																	this.options.labelFontSize
															  )
																	? k
																	: this.options.labelFontSize),
															  (this.sessionVariables.labelAngle =
																	this.labelAngle))
															: b >= 2.2 * h && b < 2.8 * h
															? ((this.sessionVariables.labelAngle = -25),
															  (this.sessionVariables.labelMaxWidth = n),
															  (this.sessionVariables.labelFontSize = k))
															: b >= 2.8 * h && b < 3.2 * h
															? ((this.sessionVariables.labelMaxWidth =
																	Math.max(h, r)),
															  (this.sessionVariables.labelWrap = !0),
															  g(this.options.labelFontSize) &&
																	12 < this.labelFontSize &&
																	((this.labelFontSize = Math.floor(
																		(12 / 13) * this.labelFontSize
																	)),
																	a.measureText()),
															  (this.sessionVariables.labelFontSize = g(
																	this.options.labelFontSize
															  )
																	? k
																	: this.options.labelFontSize),
															  (this.sessionVariables.labelAngle =
																	this.labelAngle))
															: b >= 3.2 * h && b < 3.6 * h
															? ((this.sessionVariables.labelAngle = -25),
															  (this.sessionVariables.labelWrap = !0),
															  (this.sessionVariables.labelMaxWidth = n),
															  (this.sessionVariables.labelFontSize =
																	this.labelFontSize))
															: b > 3.6 * h && b < 5 * h
															? (g(this.options.labelFontSize) &&
																	12 < k &&
																	((k = Math.floor((12 / 13) * k)),
																	a.measureText()),
															  (this.sessionVariables.labelFontSize = g(
																	this.options.labelFontSize
															  )
																	? k
																	: this.options.labelFontSize),
															  (this.sessionVariables.labelWrap = !0),
															  (this.sessionVariables.labelAngle = -25),
															  (this.sessionVariables.labelMaxWidth = n))
															: b > 5 * h &&
															  ((this.sessionVariables.labelWrap = !0),
															  (this.sessionVariables.labelMaxWidth = h),
															  (this.sessionVariables.labelFontSize = k),
															  (this.sessionVariables.labelMaxHeight = d),
															  (this.sessionVariables.labelAngle =
																	this.labelAngle)));
												else if (
													s === c &&
													((0 === s &&
														r +
															this._labels[s + 1].textBlock.measureText()
																.width -
															2 * h >
															p) ||
														(s === this._labels.length - 1 &&
															r +
																this._labels[s - 1].textBlock.measureText()
																	.width -
																2 * h >
																p) ||
														(0 < s &&
															s < this._labels.length - 1 &&
															r +
																this._labels[s + 1].textBlock.measureText()
																	.width -
																2 * h >
																p &&
															r +
																this._labels[s - 1].textBlock.measureText()
																	.width -
																2 * h >
																p))
												)
													(p =
														0 === s
															? r +
															  this._labels[s + 1].textBlock.measureText()
																	.width -
															  2 * h
															: r +
															  this._labels[s - 1].textBlock.measureText()
																	.width -
															  2 * h),
														(this.sessionVariables.labelFontSize = g(
															this.options.labelFontSize
														)
															? k
															: this.options.labelFontSize),
														(this.sessionVariables.labelWrap = !0),
														(this.sessionVariables.labelAngle = -25),
														(this.sessionVariables.labelMaxWidth = n);
												else if (0 === p)
													for (
														this.sessionVariables.labelFontSize = g(
															this.options.labelFontSize
														)
															? k
															: this.options.labelFontSize,
															this.sessionVariables.labelWrap = !0,
															b = 0;
														b < this._labels.length;
														b++
													)
														(a = this._labels[b].textBlock),
															(a.maxWidth =
																this.sessionVariables.labelMaxWidth =
																	Math.min(Math.max(h, r), n)),
															(z = a.measureText()),
															b < this._labels.length - 1 &&
																((k = b + 1),
																(f = this._labels[k].textBlock),
																(f.maxWidth =
																	this.sessionVariables.labelMaxWidth =
																		Math.min(Math.max(h, r), n)),
																(f = f.measureText()),
																(z.width + f.width) >> 0 > 2 * h &&
																	(this.sessionVariables.labelAngle = -25));
									}
								else
									((this.sessionVariables.labelAngle = this.labelAngle),
									(this.sessionVariables.labelMaxHeight =
										0 === this.labelAngle
											? d
											: Math.min(
													(b -
														h *
															Math.cos(
																(Math.PI / 180) * Math.abs(this.labelAngle)
															)) /
														Math.sin(
															(Math.PI / 180) * Math.abs(this.labelAngle)
														),
													b
											  )),
									(n =
										0 != this.labelAngle
											? (m -
													(l + a.fontSize / 2) *
														Math.cos(
															(Math.PI / 180) * Math.abs(this.labelAngle)
														)) /
											  Math.sin((Math.PI / 180) * Math.abs(this.labelAngle))
											: h),
									(this.sessionVariables.labelMaxHeight = this.labelWrap
										? (m -
												n *
													Math.sin(
														(Math.PI / 180) * Math.abs(this.labelAngle)
													)) /
										  Math.cos((Math.PI / 180) * Math.abs(this.labelAngle))
										: 1.5 * this.labelFontSize),
									g(this.options.labelWrap))
										? g(this.options.labelWrap) &&
										  (this.labelWrap && !g(this.options.labelMaxWidth)
												? ((this.sessionVariables.labelWrap = this.labelWrap),
												  (this.sessionVariables.labelMaxWidth = this.options
														.labelMaxWidth
														? this.options.labelMaxWidth
														: n),
												  (this.sessionVariables.labelMaxHeight = d))
												: ((this.sessionVariables.labelAngle = this.labelAngle),
												  (this.sessionVariables.labelMaxWidth = n),
												  (this.sessionVariables.labelMaxHeight =
														b < 0.9 * q ? 0.9 * q : b),
												  (this.sessionVariables.labelWrap = this.labelWrap)))
										: (this.options.labelWrap
												? ((this.sessionVariables.labelWrap = this.labelWrap),
												  (this.sessionVariables.labelMaxWidth = this.options
														.labelMaxWidth
														? this.options.labelMaxWidth
														: n))
												: (g(this.options.labelMaxWidth),
												  (this.sessionVariables.labelMaxWidth = this.options
														.labelMaxWidth
														? this.options.labelMaxWidth
														: n),
												  (this.sessionVariables.labelWrap = this.labelWrap)),
										  (this.sessionVariables.labelMaxHeight = d));
							}
						for (b = 0; b < this._labels.length; b++)
							(a = this._labels[b].textBlock),
								(a.maxWidth = this.labelMaxWidth =
									this.sessionVariables.labelMaxWidth),
								(a.fontSize = this.sessionVariables.labelFontSize),
								(a.angle = this.labelAngle = this.sessionVariables.labelAngle),
								(a.wrap = this.labelWrap = this.sessionVariables.labelWrap),
								(a.maxHeight = this.sessionVariables.labelMaxHeight),
								a.measureText();
					} else
						for (c = 0; c < this._labels.length; c++)
							(a = this._labels[c].textBlock),
								(a.maxWidth = this.labelMaxWidth =
									g(this.options.labelMaxWidth)
										? g(this.sessionVariables.labelMaxWidth)
											? (this.sessionVariables.labelMaxWidth = h)
											: this.sessionVariables.labelMaxWidth
										: this.options.labelMaxWidth),
								(a.fontSize = this.labelFontSize =
									g(this.options.labelFontSize)
										? g(this.sessionVariables.labelFontSize)
											? (this.sessionVariables.labelFontSize =
													this.labelFontSize)
											: this.sessionVariables.labelFontSize
										: this.options.labelFontSize),
								(a.angle = this.labelAngle =
									g(this.options.labelAngle)
										? g(this.sessionVariables.labelAngle)
											? (this.sessionVariables.labelAngle = this.labelAngle)
											: this.sessionVariables.labelAngle
										: this.labelAngle),
								(a.wrap = this.labelWrap =
									g(this.options.labelWrap)
										? g(this.sessionVariables.labelWrap)
											? (this.sessionVariables.labelWrap = this.labelWrap)
											: this.sessionVariables.labelWrap
										: this.options.labelWrap),
								(a.maxHeight = g(this.sessionVariables.labelMaxHeight)
									? (this.sessionVariables.labelMaxHeight = d)
									: this.sessionVariables.labelMaxHeight),
								a.measureText();
				else if ("left" === this._position || "right" === this._position)
					if (
						((h = g(this.options.labelMaxWidth)
							? (0.3 * this.chart.width) >> 0
							: this.options.labelMaxWidth),
						(d =
							"undefined" === typeof this.options.labelWrap || this.labelWrap
								? (0.3 * this.chart.height) >> 0
								: 1.5 * this.labelFontSize),
						!this.chart.panEnabled && 1 <= this._labels.length)
					) {
						this.sessionVariables.labelFontSize = this.labelFontSize;
						this.sessionVariables.labelMaxWidth = h;
						this.sessionVariables.labelMaxHeight = d;
						this.sessionVariables.labelAngle = g(
							this.sessionVariables.labelAngle
						)
							? 0
							: this.sessionVariables.labelAngle;
						this.sessionVariables.labelWrap = this.labelWrap;
						for (c = 0; c < this._labels.length; c++)
							if (!this._labels[c].breaksLabelType) {
								a = this._labels[c].textBlock;
								z = a.measureText();
								for (k = c + 1; k < this._labels.length; k++)
									if (!this._labels[k].breaksLabelType) {
										f = this._labels[k].textBlock;
										f = f.measureText();
										break;
									}
								e.push(a.height);
								this.sessionVariables.labelMaxHeight = Math.max.apply(Math, e);
								b =
									h * Math.sin((Math.PI / 180) * Math.abs(this.labelAngle)) +
									(d - a.fontSize / 2) *
										Math.cos((Math.PI / 180) * Math.abs(this.labelAngle));
								Math.cos((Math.PI / 180) * Math.abs(this.labelAngle));
								Math.sin((Math.PI / 180) * Math.abs(this.labelAngle));
								g(this.options.labelAngle) &&
								isNaN(this.options.labelAngle) &&
								0 !== this.options.labelAngle
									? g(this.options.labelWrap)
										? g(this.options.labelWrap) &&
										  (g(this.options.labelMaxWidth)
												? g(f) ||
												  ((q = (z.height + f.height) >> 0),
												  q - 2 * d > r &&
														((r = q - 2 * d),
														q >= 2 * d && q < 2.4 * d
															? (g(this.options.labelFontSize) &&
																	12 < this.labelFontSize &&
																	((this.labelFontSize = Math.floor(
																		(12 / 13) * this.labelFontSize
																	)),
																	a.measureText()),
															  (this.sessionVariables.labelMaxHeight = d),
															  (this.sessionVariables.labelFontSize = g(
																	this.options.labelFontSize
															  )
																	? this.labelFontSize
																	: this.options.labelFontSize))
															: q >= 2.4 * d && q < 2.8 * d
															? ((this.sessionVariables.labelMaxHeight = b),
															  (this.sessionVariables.labelFontSize =
																	this.labelFontSize),
															  (this.sessionVariables.labelWrap = !0))
															: q >= 2.8 * d && q < 3.2 * d
															? ((this.sessionVariables.labelMaxHeight = d),
															  (this.sessionVariables.labelWrap = !0),
															  g(this.options.labelFontSize) &&
																	12 < this.labelFontSize &&
																	((this.labelFontSize = Math.floor(
																		(12 / 13) * this.labelFontSize
																	)),
																	a.measureText()),
															  (this.sessionVariables.labelFontSize = g(
																	this.options.labelFontSize
															  )
																	? this.labelFontSize
																	: this.options.labelFontSize),
															  (this.sessionVariables.labelAngle = g(
																	this.sessionVariables.labelAngle
															  )
																	? 0
																	: this.sessionVariables.labelAngle))
															: q >= 3.2 * d && q < 3.6 * d
															? ((this.sessionVariables.labelMaxHeight = b),
															  (this.sessionVariables.labelWrap = !0),
															  (this.sessionVariables.labelFontSize =
																	this.labelFontSize))
															: q > 3.6 * d && q < 10 * d
															? (g(this.options.labelFontSize) &&
																	12 < this.labelFontSize &&
																	((this.labelFontSize = Math.floor(
																		(12 / 13) * this.labelFontSize
																	)),
																	a.measureText()),
															  (this.sessionVariables.labelFontSize = g(
																	this.options.labelFontSize
															  )
																	? this.labelFontSize
																	: this.options.labelFontSize),
															  (this.sessionVariables.labelMaxWidth = h),
															  (this.sessionVariables.labelMaxHeight = d),
															  (this.sessionVariables.labelAngle = g(
																	this.sessionVariables.labelAngle
															  )
																	? 0
																	: this.sessionVariables.labelAngle))
															: q > 10 * d &&
															  q < 50 * d &&
															  (g(this.options.labelFontSize) &&
																	12 < this.labelFontSize &&
																	((this.labelFontSize = Math.floor(
																		(12 / 13) * this.labelFontSize
																	)),
																	a.measureText()),
															  (this.sessionVariables.labelFontSize = g(
																	this.options.labelFontSize
															  )
																	? this.labelFontSize
																	: this.options.labelFontSize),
															  (this.sessionVariables.labelMaxHeight = d),
															  (this.sessionVariables.labelMaxWidth = h),
															  (this.sessionVariables.labelAngle = g(
																	this.sessionVariables.labelAngle
															  )
																	? 0
																	: this.sessionVariables.labelAngle))))
												: ((this.sessionVariables.labelMaxHeight = d),
												  (this.sessionVariables.labelMaxWidth = this.options
														.labelMaxWidth
														? this.options.labelMaxWidth
														: this.sessionVariables.labelMaxWidth)))
										: ((this.sessionVariables.labelMaxWidth = this.labelWrap
												? this.options.labelMaxWidth
													? this.options.labelMaxWidth
													: this.sessionVariables.labelMaxWidth
												: this.labelMaxWidth
												? this.options.labelMaxWidth
													? this.options.labelMaxWidth
													: this.sessionVariables.labelMaxWidth
												: h),
										  (this.sessionVariables.labelMaxHeight = d))
									: ((this.sessionVariables.labelAngle = this.labelAngle),
									  (this.sessionVariables.labelMaxWidth =
											0 === this.labelAngle
												? h
												: Math.min(
														(b -
															d *
																Math.sin(
																	(Math.PI / 180) * Math.abs(this.labelAngle)
																)) /
															Math.cos(
																(Math.PI / 180) * Math.abs(this.labelAngle)
															),
														d
												  )),
									  g(this.options.labelWrap))
									? g(this.options.labelWrap) &&
									  (this.labelWrap && !g(this.options.labelMaxWidth)
											? ((this.sessionVariables.labelMaxWidth = this.options
													.labelMaxWidth
													? this.options.labelMaxWidth
													: this.sessionVariables.labelMaxWidth),
											  (this.sessionVariables.labelWrap = this.labelWrap),
											  (this.sessionVariables.labelMaxHeight = b))
											: ((this.sessionVariables.labelMaxWidth = this.options
													.labelMaxWidth
													? this.options.labelMaxWidth
													: h),
											  (this.sessionVariables.labelMaxHeight =
													0 === this.labelAngle ? d : b),
											  g(this.options.labelMaxWidth) &&
													(this.sessionVariables.labelAngle = this.labelAngle)))
									: this.options.labelWrap
									? ((this.sessionVariables.labelMaxHeight =
											0 === this.labelAngle ? d : b),
									  (this.sessionVariables.labelWrap = this.labelWrap),
									  (this.sessionVariables.labelMaxWidth = h))
									: ((this.sessionVariables.labelMaxHeight = d),
									  g(this.options.labelMaxWidth),
									  (this.sessionVariables.labelMaxWidth = this.options
											.labelMaxWidth
											? this.options.labelMaxWidth
											: this.sessionVariables.labelMaxWidth),
									  (this.sessionVariables.labelWrap = this.labelWrap));
							}
						for (b = 0; b < this._labels.length; b++)
							(a = this._labels[b].textBlock),
								(a.maxWidth = this.labelMaxWidth =
									this.sessionVariables.labelMaxWidth),
								(a.fontSize = this.labelFontSize =
									this.sessionVariables.labelFontSize),
								(a.angle = this.labelAngle = this.sessionVariables.labelAngle),
								(a.wrap = this.labelWrap = this.sessionVariables.labelWrap),
								(a.maxHeight = this.sessionVariables.labelMaxHeight),
								a.measureText();
					} else
						for (c = 0; c < this._labels.length; c++)
							(a = this._labels[c].textBlock),
								(a.maxWidth = this.labelMaxWidth =
									g(this.options.labelMaxWidth)
										? g(this.sessionVariables.labelMaxWidth)
											? (this.sessionVariables.labelMaxWidth = h)
											: this.sessionVariables.labelMaxWidth
										: this.options.labelMaxWidth),
								(a.fontSize = this.labelFontSize =
									g(this.options.labelFontSize)
										? g(this.sessionVariables.labelFontSize)
											? (this.sessionVariables.labelFontSize =
													this.labelFontSize)
											: this.sessionVariables.labelFontSize
										: this.options.labelFontSize),
								(a.angle = this.labelAngle =
									g(this.options.labelAngle)
										? g(this.sessionVariables.labelAngle)
											? (this.sessionVariables.labelAngle = this.labelAngle)
											: this.sessionVariables.labelAngle
										: this.labelAngle),
								(a.wrap = this.labelWrap =
									g(this.options.labelWrap)
										? g(this.sessionVariables.labelWrap)
											? (this.sessionVariables.labelWrap = this.labelWrap)
											: this.sessionVariables.labelWrap
										: this.options.labelWrap),
								(a.maxHeight = g(this.sessionVariables.labelMaxHeight)
									? (this.sessionVariables.labelMaxHeight = d)
									: this.sessionVariables.labelMaxHeight),
								a.measureText();
			for (c = 0; c < this.stripLines.length; c++) {
				var h = this.stripLines[c],
					v;
				if ("outside" === h.labelPlacement) {
					d = this.sessionVariables.labelMaxWidth;
					if ("bottom" === this._position || "top" === this._position)
						g(h.options.labelWrap) &&
						!g(this.sessionVariables.stripLineLabelMaxHeight)
							? (v = this.sessionVariables.stripLineLabelMaxHeight)
							: (this.sessionVariables.stripLineLabelMaxHeight = v =
									h.labelWrap
										? (0.8 * this.chart.height) >> 0
										: 1.5 * this.labelFontSize);
					if ("left" === this._position || "right" === this._position)
						g(h.options.labelWrap) &&
						!g(this.sessionVariables.stripLineLabelMaxHeight)
							? (v = this.sessionVariables.stripLineLabelMaxHeight)
							: (this.sessionVariables.stripLineLabelMaxHeight = v =
									h.labelWrap
										? (0.8 * this.chart.width) >> 0
										: 1.5 * this.labelFontSize);
					g(h.labelBackgroundColor) && (h.labelBackgroundColor = "#EEEEEE");
				} else
					(d =
						"bottom" === this._position || "top" === this._position
							? (0.9 * this.chart.width) >> 0
							: (0.9 * this.chart.height) >> 0),
						(v =
							g(h.options.labelWrap) || h.labelWrap
								? "bottom" === this._position || "top" === this._position
									? (0.8 * this.chart.width) >> 0
									: (0.8 * this.chart.height) >> 0
								: 1.5 * this.labelFontSize),
						g(h.labelBackgroundColor) &&
							(g(h.startValue) && 0 !== h.startValue
								? (h.labelBackgroundColor = t ? "transparent" : null)
								: (h.labelBackgroundColor = "#EEEEEE"));
				a = new na(this.ctx, {
					x: 0,
					y: 0,
					backgroundColor: h.labelBackgroundColor,
					borderColor: h.labelBorderColor,
					borderThickness: h.labelBorderThickness,
					cornerRadius: h.labelCornerRadius,
					maxWidth: h.options.labelMaxWidth ? h.options.labelMaxWidth : d,
					maxHeight: v,
					angle: this.labelAngle,
					text: h.labelFormatter
						? h.labelFormatter({ chart: this.chart, axis: this, stripLine: h })
						: h.label,
					textAlign: this.labelTextAlign,
					fontSize:
						"outside" === h.labelPlacement
							? h.options.labelFontSize
								? h.labelFontSize
								: this.labelFontSize
							: h.labelFontSize,
					fontFamily:
						"outside" === h.labelPlacement
							? h.options.labelFontFamily
								? h.labelFontFamily
								: this.labelFontFamily
							: h.labelFontFamily,
					fontWeight:
						"outside" === h.labelPlacement
							? h.options.labelFontWeight
								? h.labelFontWeight
								: this.labelFontWeight
							: h.labelFontWeight,
					fontColor: h.labelFontColor || h.color,
					fontStyle:
						"outside" === h.labelPlacement
							? h.options.labelFontStyle
								? h.labelFontStyle
								: this.fontWeight
							: h.labelFontStyle,
					textBaseline: "middle",
				});
				this._stripLineLabels.push({
					position: h.value,
					textBlock: a,
					effectiveHeight: null,
					stripLine: h,
				});
			}
		};
		D.prototype.createLabelsAndCalculateWidth = function () {
			var a = 0,
				f = 0;
			this._labels = [];
			this._stripLineLabels = [];
			var c = this.chart.isNavigator ? 0 : 5;
			if ("left" === this._position || "right" === this._position) {
				this.createLabels();
				if (
					"inside" != this.labelPlacement ||
					("inside" === this.labelPlacement && 0 < this._index)
				)
					for (f = 0; f < this._labels.length; f++) {
						var b = this._labels[f].textBlock,
							e = b.measureText(),
							h = 0,
							h =
								0 === this.labelAngle
									? e.width
									: e.width *
											Math.cos((Math.PI / 180) * Math.abs(this.labelAngle)) +
									  (e.height - b.fontSize / 2) *
											Math.sin((Math.PI / 180) * Math.abs(this.labelAngle));
						a < h && (a = h);
						this._labels[f].effectiveWidth = h;
					}
				for (f = 0; f < this._stripLineLabels.length; f++)
					"outside" === this._stripLineLabels[f].stripLine.labelPlacement &&
						this._stripLineLabels[f].stripLine.value >= this.viewportMinimum &&
						this._stripLineLabels[f].stripLine.value <= this.viewportMaximum &&
						((b = this._stripLineLabels[f].textBlock),
						(e = b.measureText()),
						(h =
							0 === this.labelAngle
								? e.width
								: e.width *
										Math.cos((Math.PI / 180) * Math.abs(this.labelAngle)) +
								  (e.height - b.fontSize / 2) *
										Math.sin((Math.PI / 180) * Math.abs(this.labelAngle))),
						a < h && (a = h),
						(this._stripLineLabels[f].effectiveWidth = h));
			}
			return (
				(this.title ? this._titleTextBlock.measureText().height + 2 : 0) +
				a +
				("inside" === this.tickPlacement ? 0 : this.tickLength) +
				c
			);
		};
		D.prototype.createLabelsAndCalculateHeight = function () {
			var a = 0;
			this._labels = [];
			this._stripLineLabels = [];
			var f,
				c = 0,
				b = this.chart.isNavigator ? 0 : 5;
			if ("bottom" === this._position || "top" === this._position) {
				this.createLabels();
				if (
					"inside" != this.labelPlacement ||
					("inside" === this.labelPlacement && 0 < this._index)
				)
					for (c = 0; c < this._labels.length; c++) {
						f = this._labels[c].textBlock;
						var e = f.measureText(),
							h = 0,
							h =
								0 === this.labelAngle
									? e.height
									: e.width *
											Math.sin((Math.PI / 180) * Math.abs(this.labelAngle)) +
									  (e.height - f.fontSize / 2) *
											Math.cos((Math.PI / 180) * Math.abs(this.labelAngle));
						a < h && (a = h);
						this._labels[c].effectiveHeight = h;
					}
				for (c = 0; c < this._stripLineLabels.length; c++)
					"outside" === this._stripLineLabels[c].stripLine.labelPlacement &&
						this._stripLineLabels[c].stripLine.value >= this.viewportMinimum &&
						this._stripLineLabels[c].stripLine.value <= this.viewportMaximum &&
						((f = this._stripLineLabels[c].textBlock),
						(e = f.measureText()),
						(h =
							0 === this.labelAngle
								? e.height
								: e.width *
										Math.sin((Math.PI / 180) * Math.abs(this.labelAngle)) +
								  (e.height - f.fontSize / 2) *
										Math.cos((Math.PI / 180) * Math.abs(this.labelAngle))),
						a < h && (a = h),
						(this._stripLineLabels[c].effectiveHeight = h));
			}
			return (
				(this.title ? this._titleTextBlock.measureText().height + 2 : 0) +
				a +
				("inside" === this.tickPlacement ? 0 : this.tickLength) +
				b
			);
		};
		D.setLayout = function (a, f, c, b, e, h) {
			var d,
				q,
				l,
				m,
				n = a[0] ? a[0].chart : f[0].chart,
				p = n.isNavigator ? 0 : 10,
				r = n._axes;
			if (a && 0 < a.length)
				for (var k = 0; k < a.length; k++)
					a[k] && a[k].calculateAxisParameters();
			if (f && 0 < f.length)
				for (k = 0; k < f.length; k++) f[k].calculateAxisParameters();
			if (c && 0 < c.length)
				for (k = 0; k < c.length; k++) c[k].calculateAxisParameters();
			if (b && 0 < b.length)
				for (k = 0; k < b.length; k++) b[k].calculateAxisParameters();
			for (k = 0; k < r.length; k++)
				if (r[k] && r[k].scaleBreaks && r[k].scaleBreaks._appliedBreaks.length)
					for (
						var z = r[k].scaleBreaks._appliedBreaks, s = 0;
						s < z.length && !(z[s].startValue > r[k].viewportMaximum);
						s++
					)
						z[s].endValue < r[k].viewportMinimum ||
							(g(r[k].scaleBreaks.firstBreakIndex) &&
								(r[k].scaleBreaks.firstBreakIndex = s),
							z[s].startValue >= r[k].viewPortMinimum &&
								(r[k].scaleBreaks.lastBreakIndex = s));
			for (
				var t = (s = 0),
					u = 0,
					v = 0,
					w = 0,
					y = 0,
					D = 0,
					C,
					H,
					E = (q = 0),
					K,
					N,
					P,
					z = (K = N = P = !1),
					k = 0;
				k < r.length;
				k++
			)
				r[k] &&
					r[k].title &&
					(r[k]._titleTextBlock = new na(r[k].ctx, {
						text: r[k].title,
						horizontalAlign: "center",
						fontSize: r[k].titleFontSize,
						fontFamily: r[k].titleFontFamily,
						fontWeight: r[k].titleFontWeight,
						fontColor: r[k].titleFontColor,
						fontStyle: r[k].titleFontStyle,
						borderColor: r[k].titleBorderColor,
						borderThickness: r[k].titleBorderThickness,
						backgroundColor: r[k].titleBackgroundColor,
						cornerRadius: r[k].titleCornerRadius,
						textBaseline: "top",
					}));
			for (k = 0; k < r.length; k++)
				if (r[k].title)
					switch (r[k]._position) {
						case "left":
							r[k]._titleTextBlock.maxWidth = r[k].titleMaxWidth || h.height;
							r[k]._titleTextBlock.maxHeight = r[k].titleWrap
								? 0.8 * h.width
								: 1.5 * r[k].titleFontSize;
							r[k]._titleTextBlock.angle = -90;
							break;
						case "right":
							r[k]._titleTextBlock.maxWidth = r[k].titleMaxWidth || h.height;
							r[k]._titleTextBlock.maxHeight = r[k].titleWrap
								? 0.8 * h.width
								: 1.5 * r[k].titleFontSize;
							r[k]._titleTextBlock.angle = 90;
							break;
						default:
							(r[k]._titleTextBlock.maxWidth = r[k].titleMaxWidth || h.width),
								(r[k]._titleTextBlock.maxHeight = r[k].titleWrap
									? 0.8 * h.height
									: 1.5 * r[k].titleFontSize),
								(r[k]._titleTextBlock.angle = 0);
					}
			if ("normal" === e) {
				for (
					var v = [], w = [], y = [], D = [], Q = [], R = [], O = [], U = [];
					4 > s;

				) {
					var G = 0,
						S = 0,
						aa = 0,
						W = 0,
						ba = (e = 0),
						T = 0,
						da = 0,
						Y = 0,
						Z = 0,
						V = 0,
						ea = 0;
					if (c && 0 < c.length)
						for (y = [], k = V = 0; k < c.length; k++)
							y.push(
								Math.ceil(c[k] ? c[k].createLabelsAndCalculateWidth() : 0)
							),
								(V += y[k]),
								(T += c[k] && !n.isNavigator ? c[k].margin : 0);
					else
						y.push(Math.ceil(c[0] ? c[0].createLabelsAndCalculateWidth() : 0));
					O.push(y);
					if (b && 0 < b.length)
						for (D = [], k = ea = 0; k < b.length; k++)
							D.push(
								Math.ceil(b[k] ? b[k].createLabelsAndCalculateWidth() : 0)
							),
								(ea += D[k]),
								(da += b[k] ? b[k].margin : 0);
					else
						D.push(Math.ceil(b[0] ? b[0].createLabelsAndCalculateWidth() : 0));
					U.push(D);
					d = Math.round(h.x1 + V + T);
					l = Math.round(
						h.x2 - ea - da > n.width - p ? n.width - p : h.x2 - ea - da
					);
					if (a && 0 < a.length)
						for (v = [], k = Y = 0; k < a.length; k++)
							a[k] && (a[k].lineCoordinates = {}),
								(a[k].lineCoordinates.width = Math.abs(l - d)),
								a[k].title &&
									(a[k]._titleTextBlock.maxWidth =
										0 < a[k].titleMaxWidth &&
										a[k].titleMaxWidth < a[k].lineCoordinates.width
											? a[k].titleMaxWidth
											: a[k].lineCoordinates.width),
								v.push(
									Math.ceil(a[k] ? a[k].createLabelsAndCalculateHeight() : 0)
								),
								(Y += v[k]),
								(e += a[k] && !n.isNavigator ? a[k].margin : 0);
					else
						v.push(Math.ceil(a[0] ? a[0].createLabelsAndCalculateHeight() : 0));
					Q.push(v);
					if (f && 0 < f.length)
						for (w = [], k = Z = 0; k < f.length; k++)
							f[k] && (f[k].lineCoordinates = {}),
								(f[k].lineCoordinates.width = Math.abs(l - d)),
								f[k].title &&
									(f[k]._titleTextBlock.maxWidth =
										0 < f[k].titleMaxWidth &&
										f[k].titleMaxWidth < f[k].lineCoordinates.width
											? f[k].titleMaxWidth
											: f[k].lineCoordinates.width),
								w.push(
									Math.ceil(f[k] ? f[k].createLabelsAndCalculateHeight() : 0)
								),
								(Z += w[k]),
								(ba += f[k] && !n.isNavigator ? f[k].margin : 0);
					else
						w.push(Math.ceil(f[0] ? f[0].createLabelsAndCalculateHeight() : 0));
					R.push(w);
					if (a && 0 < a.length)
						for (k = 0; k < a.length; k++)
							a[k] &&
								((a[k].lineCoordinates.x1 = d),
								(l = Math.round(
									h.x2 - ea - da > n.width - p ? n.width - p : h.x2 - ea - da
								)),
								a[k]._labels &&
									1 < a[k]._labels.length &&
									((q = m = 0),
									(m = a[k]._labels[1]),
									(q =
										"dateTime" === a[k].valueType
											? a[k]._labels[a[k]._labels.length - 2]
											: a[k]._labels[a[k]._labels.length - 1]),
									(t =
										m.textBlock.width *
											Math.cos((Math.PI / 180) * Math.abs(m.textBlock.angle)) +
										(m.textBlock.height - q.textBlock.fontSize / 2) *
											Math.sin((Math.PI / 180) * Math.abs(m.textBlock.angle))),
									(u =
										q.textBlock.width *
											Math.cos((Math.PI / 180) * Math.abs(q.textBlock.angle)) +
										(q.textBlock.height - q.textBlock.fontSize / 2) *
											Math.sin((Math.PI / 180) * Math.abs(q.textBlock.angle)))),
								!a[k] ||
									!a[k].labelAutoFit ||
									g(C) ||
									g(H) ||
									n.isNavigator ||
									n.stockChart ||
									((q = 0),
									0 < a[k].labelAngle
										? H + u > l &&
										  (q += 0 < a[k].labelAngle ? H + u - l - ea : 0)
										: 0 > a[k].labelAngle
										? C - t < d &&
										  C - t < a[k].viewportMinimum &&
										  (E =
												d -
												(T +
													a[k].tickLength +
													y +
													C -
													t +
													a[k].labelFontSize / 2))
										: 0 === a[k].labelAngle &&
										  (H + u > l && (q = H + u / 2 - l - ea),
										  C - t < d &&
												C - t < a[k].viewportMinimum &&
												(E = d - T - a[k].tickLength - y - C + t / 2)),
									a[k].viewportMaximum === a[k].maximum &&
									a[k].viewportMinimum === a[k].minimum &&
									0 < a[k].labelAngle &&
									0 < q
										? (l -= q)
										: a[k].viewportMaximum === a[k].maximum &&
										  a[k].viewportMinimum === a[k].minimum &&
										  0 > a[k].labelAngle &&
										  0 < E
										? (d += E)
										: a[k].viewportMaximum === a[k].maximum &&
										  a[k].viewportMinimum === a[k].minimum &&
										  0 === a[k].labelAngle &&
										  (0 < E && (d += E), 0 < q && (l -= q))),
								n.panEnabled
									? (Y = g(n.sessionVariables.axisX.height)
											? (n.sessionVariables.axisX.height = Y)
											: n.sessionVariables.axisX.height)
									: (n.sessionVariables.axisX.height = Y),
								(q = Math.round(h.y2 - Y - e + G)),
								(m = Math.round(h.y2)),
								(a[k].lineCoordinates.x2 = l),
								(a[k].lineCoordinates.width = l - d),
								(a[k].lineCoordinates.y1 = q),
								(a[k].lineCoordinates.y2 = q + a[k].lineThickness / 2),
								"inside" === a[k].labelPlacement &&
									0 < k &&
									((a[k].lineCoordinates.y1 =
										a[k].lineCoordinates.y1 +
										v[k] -
										(a[k]._titleTextBlock ? a[k]._titleTextBlock.height : 0) -
										("inside" === a[k].tickPlacement ? a[k].tickLength : 0)),
									(a[k].lineCoordinates.y2 =
										a[k].lineCoordinates.y1 + a[k].lineThickness / 2)),
								(a[k].bounds = {
									x1: d,
									y1: q,
									x2: l,
									y2: m - (Y + e - v[k] - G),
									width: l - d,
									height: m - q,
								})),
								(G += v[k] + a[k].margin);
					if (f && 0 < f.length)
						for (k = 0; k < f.length; k++)
							(f[k].lineCoordinates.x1 = Math.round(h.x1 + V + T)),
								(f[k].lineCoordinates.x2 = Math.round(
									h.x2 - ea - da > n.width - p ? n.width - p : h.x2 - ea - da
								)),
								(f[k].lineCoordinates.width = Math.abs(l - d)),
								f[k]._labels &&
									1 < f[k]._labels.length &&
									((m = f[k]._labels[1]),
									(q =
										"dateTime" === f[k].valueType
											? f[k]._labels[f[k]._labels.length - 2]
											: f[k]._labels[f[k]._labels.length - 1]),
									(t =
										m.textBlock.width *
											Math.cos((Math.PI / 180) * Math.abs(m.textBlock.angle)) +
										(m.textBlock.height - q.textBlock.fontSize / 2) *
											Math.sin((Math.PI / 180) * Math.abs(m.textBlock.angle))),
									(u =
										q.textBlock.width *
											Math.cos((Math.PI / 180) * Math.abs(q.textBlock.angle)) +
										(q.textBlock.height - q.textBlock.fontSize / 2) *
											Math.sin((Math.PI / 180) * Math.abs(q.textBlock.angle)))),
								n.panEnabled
									? (Z = g(n.sessionVariables.axisX2.height)
											? (n.sessionVariables.axisX2.height = Z)
											: n.sessionVariables.axisX2.height)
									: (n.sessionVariables.axisX2.height = Z),
								(q = Math.round(h.y1)),
								(m = Math.round(h.y2 + f[k].margin)),
								(f[k].lineCoordinates.y1 = q + Z + ba - S),
								(f[k].lineCoordinates.y2 = q),
								"inside" === f[k].labelPlacement &&
									0 < k &&
									(f[k].lineCoordinates.y1 =
										f[k - 1].bounds.y1 -
										w[k] +
										(f[k]._titleTextBlock ? f[k]._titleTextBlock.height : 0)),
								(f[k].bounds = {
									x1: d,
									y1: q + (Z + ba - w[k] - S),
									x2: l,
									y2: m,
									width: l - d,
									height: m - q,
								}),
								(S += w[k] + f[k].margin);
					if (c && 0 < c.length)
						for (k = 0; k < c.length; k++)
							(T = n.isNavigator ? 0 : 10),
								c[k] &&
									((d = Math.round(
										a[0] ? a[0].lineCoordinates.x1 : f[0].lineCoordinates.x1
									)),
									(T =
										c[k]._labels && 0 < c[k]._labels.length
											? c[k]._labels[c[k]._labels.length - 1].textBlock.height /
											  2
											: p),
									(q = Math.round(
										h.y1 + Z + ba < Math.max(T, p)
											? Math.max(T, p)
											: h.y1 + Z + ba
									)),
									(l = Math.round(
										a[0] ? a[0].lineCoordinates.x1 : f[0].lineCoordinates.x1
									)),
									(T =
										0 < a.length
											? 0
											: c[k]._labels && 0 < c[k]._labels.length
											? c[k]._labels[0].textBlock.height / 2
											: p),
									(m = Math.round(h.y2 - Y - e - T)),
									(c[k].lineCoordinates = {
										x1: d - aa,
										y1: q,
										x2: l - aa,
										y2: m,
										height: Math.abs(m - q),
									}),
									"inside" === c[k].labelPlacement &&
										0 < k &&
										((c[k].lineCoordinates.x1 =
											c[k].lineCoordinates.x1 -
											(y[k] -
												(c[k]._titleTextBlock
													? c[k]._titleTextBlock.height
													: 0)) +
											("outside" === c[k].tickPlacement ? c[k].tickLength : 0)),
										(c[k].lineCoordinates.x2 =
											c[k].lineCoordinates.x1 + c[k].lineThickness / 2)),
									(c[k].bounds = {
										x1: d - (y[k] + aa),
										y1: q,
										x2: l,
										y2: m,
										width: l - d,
										height: m - q,
									}),
									c[k].title &&
										(c[k]._titleTextBlock.maxWidth =
											0 < c[k].titleMaxWidth &&
											c[k].titleMaxWidth < c[k].lineCoordinates.height
												? c[k].titleMaxWidth
												: c[k].lineCoordinates.height),
									(aa += y[k] + c[k].margin));
					if (b && 0 < b.length)
						for (k = 0; k < b.length; k++)
							b[k] &&
								((d = Math.round(
									a[0] ? a[0].lineCoordinates.x2 : f[0].lineCoordinates.x2
								)),
								(l = Math.round(d)),
								(T =
									b[k]._labels && 0 < b[k]._labels.length
										? b[k]._labels[b[k]._labels.length - 1].textBlock.height / 2
										: 0),
								(q = Math.round(
									h.y1 + Z + ba < Math.max(T, p)
										? Math.max(T, p)
										: h.y1 + Z + ba
								)),
								(T =
									0 < a.length
										? 0
										: b[k]._labels && 0 < b[k]._labels.length
										? b[k]._labels[0].textBlock.height / 2
										: 0),
								(m = Math.round(h.y2 - (Y + e + T))),
								(b[k].lineCoordinates = {
									x1: d + W,
									y1: q,
									x2: d + W,
									y2: m,
									height: Math.abs(m - q),
								}),
								"inside" === b[k].labelPlacement &&
									0 < k &&
									((b[k].lineCoordinates.x1 =
										b[k].lineCoordinates.x1 +
										(D[k] -
											(b[k]._titleTextBlock
												? b[k]._titleTextBlock.height
												: 0)) -
										("outside" === b[k].tickPlacement ? b[k].tickLength : 0) -
										2),
									(b[k].lineCoordinates.x2 =
										b[k].lineCoordinates.x1 + b[k].lineThickness / 2)),
								(b[k].bounds = {
									x1: d,
									y1: q,
									x2: l + (D[k] + W),
									y2: m,
									width: l - d,
									height: m - q,
								}),
								b[k].title &&
									(b[k]._titleTextBlock.maxWidth =
										0 < b[k].titleMaxWidth &&
										b[k].titleMaxWidth < b[k].lineCoordinates.height
											? b[k].titleMaxWidth
											: b[k].lineCoordinates.height),
								(W += D[k] + b[k].margin));
					if (a && 0 < a.length)
						for (k = 0; k < a.length; k++)
							a[k] &&
								(a[k].calculateValueToPixelConversionParameters(),
								a[k].calculateBreaksSizeInValues(),
								a[k]._labels &&
									1 < a[k]._labels.length &&
									((C =
										(a[k].logarithmic
											? Math.log(
													a[k]._labels[1].position / a[k].viewportMinimum
											  ) / a[k].conversionParameters.lnLogarithmBase
											: a[k]._labels[1].position - a[k].viewportMinimum) *
											Math.abs(a[k].conversionParameters.pixelPerUnit) +
										a[k].lineCoordinates.x1),
									(d =
										a[k]._labels[
											a[k]._labels.length -
												("dateTime" === a[k].valueType ? 2 : 1)
										].position),
									(d = a[k].getApparentDifference(a[k].viewportMinimum, d)),
									(H = a[k].logarithmic
										? (1 < d
												? (Math.log(d) /
														a[k].conversionParameters.lnLogarithmBase) *
												  Math.abs(a[k].conversionParameters.pixelPerUnit)
												: 0) + a[k].lineCoordinates.x1
										: (0 < d
												? d * Math.abs(a[k].conversionParameters.pixelPerUnit)
												: 0) + a[k].lineCoordinates.x1)));
					if (f && 0 < f.length)
						for (k = 0; k < f.length; k++)
							f[k].calculateValueToPixelConversionParameters(),
								f[k].calculateBreaksSizeInValues(),
								f[k]._labels &&
									1 < f[k]._labels.length &&
									((C =
										(f[k].logarithmic
											? Math.log(
													f[k]._labels[1].position / f[k].viewportMinimum
											  ) / f[k].conversionParameters.lnLogarithmBase
											: f[k]._labels[1].position - f[k].viewportMinimum) *
											Math.abs(f[k].conversionParameters.pixelPerUnit) +
										f[k].lineCoordinates.x1),
									(d =
										f[k]._labels[
											f[k]._labels.length -
												("dateTime" === f[k].valueType ? 2 : 1)
										].position),
									(d = f[k].getApparentDifference(f[k].viewportMinimum, d)),
									(H = f[k].logarithmic
										? (1 < d
												? (Math.log(d) /
														f[k].conversionParameters.lnLogarithmBase) *
												  Math.abs(f[k].conversionParameters.pixelPerUnit)
												: 0) + f[k].lineCoordinates.x1
										: (0 < d
												? d * Math.abs(f[k].conversionParameters.pixelPerUnit)
												: 0) + f[k].lineCoordinates.x1));
					for (k = 0; k < r.length; k++)
						"axisY" === r[k].type &&
							(r[k].calculateValueToPixelConversionParameters(),
							r[k].calculateBreaksSizeInValues());
					if (0 < s) {
						if (a && 0 < a.length)
							for (k = 0; k < a.length; k++)
								z = Q[s - 1][k] === Q[s][k] ? !0 : !1;
						else z = !0;
						if (f && 0 < f.length)
							for (k = 0; k < f.length; k++)
								K = R[s - 1][k] === R[s][k] ? !0 : !1;
						else K = !0;
						if (c && 0 < c.length)
							for (k = 0; k < c.length; k++)
								N = O[s - 1][k] === O[s][k] ? !0 : !1;
						else N = !0;
						if (b && 0 < b.length)
							for (k = 0; k < b.length; k++)
								P = U[s - 1][k] === U[s][k] ? !0 : !1;
						else P = !0;
					}
					if (z && K && N && P) break;
					s++;
				}
				if (a && 0 < a.length)
					for (k = 0; k < a.length; k++)
						a[k].calculateStripLinesThicknessInValues(),
							a[k].calculateBreaksInPixels();
				if (f && 0 < f.length)
					for (k = 0; k < f.length; k++)
						f[k].calculateStripLinesThicknessInValues(),
							f[k].calculateBreaksInPixels();
				if (c && 0 < c.length)
					for (k = 0; k < c.length; k++)
						c[k].calculateStripLinesThicknessInValues(),
							c[k].calculateBreaksInPixels();
				if (b && 0 < b.length)
					for (k = 0; k < b.length; k++)
						b[k].calculateStripLinesThicknessInValues(),
							b[k].calculateBreaksInPixels();
			} else {
				p = [];
				C = [];
				E = [];
				t = [];
				H = [];
				u = [];
				Q = [];
				for (R = []; 4 > s; ) {
					Y = W = S = aa = da = T = ba = e = U = O = G = Z = 0;
					if (a && 0 < a.length)
						for (E = [], k = W = 0; k < a.length; k++)
							E.push(
								Math.ceil(a[k] ? a[k].createLabelsAndCalculateWidth() : 0)
							),
								(W += E[k]),
								(e += a[k] && !n.isNavigator ? a[k].margin : 0);
					else
						E.push(Math.ceil(a[0] ? a[0].createLabelsAndCalculateWidth() : 0));
					Q.push(E);
					if (f && 0 < f.length)
						for (t = [], k = Y = 0; k < f.length; k++)
							t.push(
								Math.ceil(f[k] ? f[k].createLabelsAndCalculateWidth() : 0)
							),
								(Y += t[k]),
								(ba += f[k] ? f[k].margin : 0);
					else
						t.push(Math.ceil(f[0] ? f[0].createLabelsAndCalculateWidth() : 0));
					R.push(t);
					if (c && 0 < c.length)
						for (k = 0; k < c.length; k++)
							(c[k].lineCoordinates = {}),
								(d = Math.round(h.x1 + W + e)),
								(l = Math.round(
									h.x2 - Y - ba > n.width - 10 ? n.width - 10 : h.x2 - Y - ba
								)),
								c[k].labelAutoFit &&
									!g(v) &&
									(0 < !a.length &&
										(d =
											0 > c[k].labelAngle
												? Math.max(d, v)
												: 0 === c[k].labelAngle
												? Math.max(d, v / 2)
												: d),
									0 < !f.length &&
										(l =
											0 < c[k].labelAngle
												? l - w / 2
												: 0 === c[k].labelAngle
												? l - w / 2
												: l)),
								(c[k].lineCoordinates.x1 = d),
								(c[k].lineCoordinates.x2 = l),
								(c[k].lineCoordinates.width = Math.abs(l - d)),
								c[k].title &&
									(c[k]._titleTextBlock.maxWidth =
										0 < c[k].titleMaxWidth &&
										c[k].titleMaxWidth < c[k].lineCoordinates.width
											? c[k].titleMaxWidth
											: c[k].lineCoordinates.width);
					if (b && 0 < b.length)
						for (k = 0; k < b.length; k++)
							(b[k].lineCoordinates = {}),
								(d = Math.round(h.x1 + W + e)),
								(l = Math.round(
									h.x2 - Y - ba > b[k].chart.width - 10
										? b[k].chart.width - 10
										: h.x2 - Y - ba
								)),
								b[k] &&
									b[k].labelAutoFit &&
									!g(y) &&
									(0 < !a.length &&
										(d =
											0 < b[k].labelAngle
												? Math.max(d, y)
												: 0 === b[k].labelAngle
												? Math.max(d, y / 2)
												: d),
									0 < !f.length && (l -= D / 2)),
								(b[k].lineCoordinates.x1 = d),
								(b[k].lineCoordinates.x2 = l),
								(b[k].lineCoordinates.width = Math.abs(l - d)),
								b[k].title &&
									(b[k]._titleTextBlock.maxWidth =
										0 < b[k].titleMaxWidth &&
										b[k].titleMaxWidth < b[k].lineCoordinates.width
											? b[k].titleMaxWidth
											: b[k].lineCoordinates.width);
					if (c && 0 < c.length)
						for (p = [], k = aa = 0; k < c.length; k++)
							p.push(
								Math.ceil(c[k] ? c[k].createLabelsAndCalculateHeight() : 0)
							),
								(aa += p[k] + c[k].margin),
								(T += c[k].margin);
					else
						p.push(Math.ceil(c[0] ? c[0].createLabelsAndCalculateHeight() : 0));
					H.push(p);
					if (b && 0 < b.length)
						for (C = [], k = S = 0; k < b.length; k++)
							C.push(
								Math.ceil(b[k] ? b[k].createLabelsAndCalculateHeight() : 0)
							),
								(S += C[k]),
								(da += b[k].margin);
					else
						C.push(Math.ceil(b[0] ? b[0].createLabelsAndCalculateHeight() : 0));
					u.push(C);
					if (c && 0 < c.length)
						for (k = 0; k < c.length; k++)
							0 < c[k]._labels.length &&
								((m = c[k]._labels[0]),
								(q = c[k]._labels[c[k]._labels.length - 1]),
								(v =
									m.textBlock.width *
										Math.cos((Math.PI / 180) * Math.abs(m.textBlock.angle)) +
									(m.textBlock.height - q.textBlock.fontSize / 2) *
										Math.sin((Math.PI / 180) * Math.abs(m.textBlock.angle))),
								(w =
									q.textBlock.width *
										Math.cos((Math.PI / 180) * Math.abs(q.textBlock.angle)) +
									(q.textBlock.height - q.textBlock.fontSize / 2) *
										Math.sin((Math.PI / 180) * Math.abs(q.textBlock.angle))));
					if (b && 0 < b.length)
						for (k = 0; k < b.length; k++)
							b[k] &&
								0 < b[k]._labels.length &&
								((m = b[k]._labels[0]),
								(q = b[k]._labels[b[k]._labels.length - 1]),
								(y =
									m.textBlock.width *
										Math.cos((Math.PI / 180) * Math.abs(m.textBlock.angle)) +
									(m.textBlock.height - q.textBlock.fontSize / 2) *
										Math.sin((Math.PI / 180) * Math.abs(m.textBlock.angle))),
								(D =
									q.textBlock.width *
										Math.cos((Math.PI / 180) * Math.abs(q.textBlock.angle)) +
									(q.textBlock.height - q.textBlock.fontSize / 2) *
										Math.sin((Math.PI / 180) * Math.abs(q.textBlock.angle))));
					if (n.panEnabled)
						for (k = 0; k < c.length; k++)
							p[k] = g(n.sessionVariables.axisY.height)
								? (n.sessionVariables.axisY.height = p[k])
								: n.sessionVariables.axisY.height;
					else
						for (k = 0; k < c.length; k++)
							n.sessionVariables.axisY.height = p[k];
					if (c && 0 < c.length)
						for (k = c.length - 1; 0 <= k; k--)
							(q = Math.round(h.y2)),
								(m = Math.round(
									h.y2 > c[k].chart.height ? c[k].chart.height : h.y2
								)),
								(c[k].lineCoordinates.y1 = q - (p[k] + c[k].margin + Z)),
								(c[k].lineCoordinates.y2 = q - (p[k] + c[k].margin + Z)),
								"inside" === c[k].labelPlacement &&
									0 < k &&
									((c[k].lineCoordinates.y1 =
										c[k].lineCoordinates.y1 +
										p[k] -
										(c[k]._titleTextBlock ? c[k]._titleTextBlock.height : 0) -
										("inside" === c[k].tickPlacement ? c[k].tickLength : 0)),
									(c[k].lineCoordinates.y2 =
										c[k].lineCoordinates.y1 + c[k].lineThickness / 2)),
								(c[k].bounds = {
									x1: d,
									y1: q - (p[k] + Z + c[k].margin),
									x2: l,
									y2: m - (Z + c[k].margin),
									width: l - d,
									height: p[k],
								}),
								c[k].title &&
									(c[k]._titleTextBlock.maxWidth =
										0 < c[k].titleMaxWidth &&
										c[k].titleMaxWidth < c[k].lineCoordinates.width
											? c[k].titleMaxWidth
											: c[k].lineCoordinates.width),
								(Z += p[k] + c[k].margin);
					if (b && 0 < b.length)
						for (k = b.length - 1; 0 <= k; k--)
							b[k] &&
								((q = Math.round(h.y1)),
								(m = Math.round(h.y1 + (C[k] + b[k].margin + G))),
								(b[k].lineCoordinates.y1 = m),
								(b[k].lineCoordinates.y2 = m),
								"inside" === b[k].labelPlacement &&
									0 < k &&
									(b[k].lineCoordinates.y1 =
										m -
										C[k] +
										(b[k]._titleTextBlock ? b[k]._titleTextBlock.height : 0)),
								(b[k].bounds = {
									x1: d,
									y1: q + (b[k].margin + G),
									x2: l,
									y2: m,
									width: l - d,
									height: S,
								}),
								b[k].title &&
									(b[k]._titleTextBlock.maxWidth =
										0 < b[k].titleMaxWidth &&
										b[k].titleMaxWidth < b[k].lineCoordinates.width
											? b[k].titleMaxWidth
											: b[k].lineCoordinates.width),
								(G += C[k] + b[k].margin));
					if (a && 0 < a.length)
						for (k = 0; k < a.length; k++) {
							T =
								a[k]._labels && 0 < a[k]._labels.length
									? a[k]._labels[0].textBlock.fontSize / 2
									: 0;
							d = Math.round(h.x1 + e);
							q =
								b && 0 < b.length
									? Math.round(
											b[0]
												? b[0].lineCoordinates.y2
												: h.y1 < Math.max(T, 10)
												? Math.max(T, 10)
												: h.y1
									  )
									: h.y1 < Math.max(T, 10)
									? Math.max(T, 10)
									: h.y1;
							l = Math.round(h.x1 + W + e);
							m =
								c && 0 < c.length
									? Math.round(
											c[0]
												? c[0].lineCoordinates.y1
												: h.y2 - aa > n.height - Math.max(T, 10)
												? n.height - Math.max(T, 10)
												: h.y2 - aa
									  )
									: h.y2 > n.height - Math.max(T, 10)
									? n.height - Math.max(T, 10)
									: h.y2;
							if (c && 0 < c.length)
								for (T = 0; T < c.length; T++)
									c[T] &&
										c[T].labelAutoFit &&
										((l =
											0 > c[T].labelAngle
												? Math.max(l, v)
												: 0 === c[T].labelAngle
												? Math.max(l, v / 2)
												: l),
										(d =
											0 > c[T].labelAngle || 0 === c[T].labelAngle
												? l - W
												: d));
							if (b && 0 < b.length)
								for (T = 0; T < b.length; T++)
									b[T] &&
										b[T].labelAutoFit &&
										((l = b[T].lineCoordinates.x1), (d = l - W));
							a[k].lineCoordinates = {
								x1: l - O,
								y1: q,
								x2: l - O,
								y2: m,
								height: Math.abs(m - q),
							};
							"inside" === a[k].labelPlacement &&
								0 < k &&
								((a[k].lineCoordinates.x1 =
									a[k].lineCoordinates.x1 -
									(E[k] -
										(a[k]._titleTextBlock ? a[k]._titleTextBlock.height : 0)) +
									("outside" === a[k].tickPlacement ? a[k].tickLength : 0)),
								(a[k].lineCoordinates.x2 =
									a[k].lineCoordinates.x1 + a[k].lineThickness / 2));
							a[k].bounds = {
								x1: l - (E[k] + O),
								y1: q,
								x2: l,
								y2: m,
								width: l - d,
								height: m - q,
							};
							a[k].title &&
								(a[k]._titleTextBlock.maxWidth =
									0 < a[k].titleMaxWidth &&
									a[k].titleMaxWidth < a[k].lineCoordinates.height
										? a[k].titleMaxWidth
										: a[k].lineCoordinates.height);
							a[k].calculateValueToPixelConversionParameters();
							a[k].calculateBreaksSizeInValues();
							O += E[k] + a[k].margin;
						}
					if (f && 0 < f.length)
						for (k = 0; k < f.length; k++) {
							T =
								f[k]._labels && 0 < f[k]._labels.length
									? f[k]._labels[0].textBlock.fontSize / 2
									: 0;
							d = Math.round(h.x1 - e);
							q =
								b && 0 < b.length
									? Math.round(
											b[0]
												? b[0].lineCoordinates.y2
												: h.y1 < Math.max(T, 10)
												? Math.max(T, 10)
												: h.y1
									  )
									: h.y1 < Math.max(T, 10)
									? Math.max(T, 10)
									: h.y1;
							l = Math.round(h.x2 - Y - ba);
							m =
								c && 0 < c.length
									? Math.round(
											c[0]
												? c[0].lineCoordinates.y1
												: h.y2 - aa > n.height - Math.max(T, 10)
												? n.height - Math.max(T, 10)
												: h.y2 - aa
									  )
									: h.y2 > n.height - Math.max(T, 10)
									? n.height - Math.max(T, 10)
									: h.y2;
							if (c && 0 < c.length)
								for (T = 0; T < c.length; T++)
									c[T] &&
										c[T].labelAutoFit &&
										((l =
											0 > c[T].labelAngle
												? Math.max(l, v)
												: 0 === c[T].labelAngle
												? Math.max(l, v / 2)
												: l),
										(d =
											0 > c[T].labelAngle || 0 === c[T].labelAngle
												? l - Y
												: d));
							if (b && 0 < b.length)
								for (T = 0; T < b.length; T++)
									b[T] &&
										b[T].labelAutoFit &&
										((l = b[T].lineCoordinates.x2), (d = l - Y));
							f[k].lineCoordinates = {
								x1: l + U,
								y1: q,
								x2: l + U,
								y2: m,
								height: Math.abs(m - q),
							};
							"inside" === f[k].labelPlacement &&
								0 < k &&
								((f[k].lineCoordinates.x1 =
									f[k].lineCoordinates.x1 +
									(t[k] -
										(f[k]._titleTextBlock ? f[k]._titleTextBlock.height : 0) -
										2) -
									("outside" === f[k].tickPlacement ? f[k].tickLength : 0)),
								(f[k].lineCoordinates.x2 =
									f[k].lineCoordinates.x1 + f[k].lineThickness / 2));
							f[k].bounds = {
								x1: f[k].lineCoordinates.x1,
								y1: q,
								x2: l + t[k] + U,
								y2: m,
								width: l - d,
								height: m - q,
							};
							f[k].title &&
								(f[k]._titleTextBlock.maxWidth =
									0 < f[k].titleMaxWidth &&
									f[k].titleMaxWidth < f[k].lineCoordinates.height
										? f[k].titleMaxWidth
										: f[k].lineCoordinates.height);
							f[k].calculateValueToPixelConversionParameters();
							f[k].calculateBreaksSizeInValues();
							U += t[k] + f[k].margin;
						}
					for (k = 0; k < r.length; k++)
						"axisY" === r[k].type &&
							(r[k].calculateValueToPixelConversionParameters(),
							r[k].calculateBreaksSizeInValues());
					if (0 < s) {
						if (a && 0 < a.length)
							for (k = 0; k < a.length; k++)
								z = Q[s - 1][k] === Q[s][k] ? !0 : !1;
						else z = !0;
						if (f && 0 < f.length)
							for (k = 0; k < f.length; k++)
								K = R[s - 1][k] === R[s][k] ? !0 : !1;
						else K = !0;
						if (c && 0 < c.length)
							for (k = 0; k < c.length; k++)
								N = H[s - 1][k] === H[s][k] ? !0 : !1;
						else N = !0;
						if (b && 0 < b.length)
							for (k = 0; k < b.length; k++)
								P = u[s - 1][k] === u[s][k] ? !0 : !1;
						else P = !0;
					}
					if (z && K && N && P) break;
					s++;
				}
				if (c && 0 < c.length)
					for (k = 0; k < c.length; k++)
						c[k].calculateStripLinesThicknessInValues(),
							c[k].calculateBreaksInPixels();
				if (b && 0 < b.length)
					for (k = 0; k < b.length; k++)
						b[k].calculateStripLinesThicknessInValues(),
							b[k].calculateBreaksInPixels();
				if (a && 0 < a.length)
					for (k = 0; k < a.length; k++)
						a[k].calculateStripLinesThicknessInValues(),
							a[k].calculateBreaksInPixels();
				if (f && 0 < f.length)
					for (k = 0; k < f.length; k++)
						f[k].calculateStripLinesThicknessInValues(),
							f[k].calculateBreaksInPixels();
			}
		};
		D.render = function (a, f, c, b, e) {
			var h = a[0] ? a[0].chart : f[0].chart;
			e = h.ctx;
			h.alignVerticalAxes && h.alignVerticalAxes();
			e.save();
			e.beginPath();
			a[0] &&
				e.rect(5, a[0].bounds.y1, a[0].chart.width - 10, a[0].bounds.height);
			f[0] &&
				e.rect(
					5,
					f[f.length - 1].bounds.y1,
					f[0].chart.width - 10,
					f[0].bounds.height
				);
			e.clip();
			if (a && 0 < a.length)
				for (var d = 0; d < a.length; d++) a[d].renderLabelsTicksAndTitle();
			if (f && 0 < f.length)
				for (d = 0; d < f.length; d++) f[d].renderLabelsTicksAndTitle();
			e.restore();
			if (c && 0 < c.length)
				for (d = 0; d < c.length; d++) c[d].renderLabelsTicksAndTitle();
			if (b && 0 < b.length)
				for (d = 0; d < b.length; d++) b[d].renderLabelsTicksAndTitle();
			h.preparePlotArea();
			h = h.plotArea;
			e.save();
			e.beginPath();
			e.rect(h.x1, h.y1, Math.abs(h.x2 - h.x1), Math.abs(h.y2 - h.y1));
			e.clip();
			if (a && 0 < a.length)
				for (d = 0; d < a.length; d++)
					a[d].renderStripLinesOfThicknessType("value");
			if (f && 0 < f.length)
				for (d = 0; d < f.length; d++)
					f[d].renderStripLinesOfThicknessType("value");
			if (c && 0 < c.length)
				for (d = 0; d < c.length; d++)
					c[d].renderStripLinesOfThicknessType("value");
			if (b && 0 < b.length)
				for (d = 0; d < b.length; d++)
					b[d].renderStripLinesOfThicknessType("value");
			if (a && 0 < a.length)
				for (d = 0; d < a.length; d++) a[d].renderInterlacedColors();
			if (f && 0 < f.length)
				for (d = 0; d < f.length; d++) f[d].renderInterlacedColors();
			if (c && 0 < c.length)
				for (d = 0; d < c.length; d++) c[d].renderInterlacedColors();
			if (b && 0 < b.length)
				for (d = 0; d < b.length; d++) b[d].renderInterlacedColors();
			e.restore();
			if (a && 0 < a.length)
				for (d = 0; d < a.length; d++)
					a[d].renderGrid(),
						t && (a[d].createMask(), a[d].renderBreaksBackground());
			if (f && 0 < f.length)
				for (d = 0; d < f.length; d++)
					f[d].renderGrid(),
						t && (f[d].createMask(), f[d].renderBreaksBackground());
			if (c && 0 < c.length)
				for (d = 0; d < c.length; d++)
					c[d].renderGrid(),
						t && (c[d].createMask(), c[d].renderBreaksBackground());
			if (b && 0 < b.length)
				for (d = 0; d < b.length; d++)
					b[d].renderGrid(),
						t && (b[d].createMask(), b[d].renderBreaksBackground());
			if (a && 0 < a.length)
				for (d = 0; d < a.length; d++) a[d].renderAxisLine();
			if (f && 0 < f.length)
				for (d = 0; d < f.length; d++) f[d].renderAxisLine();
			if (c && 0 < c.length)
				for (d = 0; d < c.length; d++) c[d].renderAxisLine();
			if (b && 0 < b.length)
				for (d = 0; d < b.length; d++) b[d].renderAxisLine();
			if (a && 0 < a.length)
				for (d = 0; d < a.length; d++)
					a[d].renderStripLinesOfThicknessType("pixel");
			if (f && 0 < f.length)
				for (d = 0; d < f.length; d++)
					f[d].renderStripLinesOfThicknessType("pixel");
			if (c && 0 < c.length)
				for (d = 0; d < c.length; d++)
					c[d].renderStripLinesOfThicknessType("pixel");
			if (b && 0 < b.length)
				for (d = 0; d < b.length; d++)
					b[d].renderStripLinesOfThicknessType("pixel");
		};
		D.prototype.calculateStripLinesThicknessInValues = function () {
			for (var a = 0; a < this.stripLines.length; a++)
				if (
					null !== this.stripLines[a].startValue &&
					null !== this.stripLines[a].endValue
				) {
					var f = Math.min(
							this.stripLines[a].startValue,
							this.stripLines[a].endValue
						),
						c = Math.max(
							this.stripLines[a].startValue,
							this.stripLines[a].endValue
						),
						f = this.getApparentDifference(f, c);
					this.stripLines[a].value = this.logarithmic
						? this.stripLines[a].value *
						  Math.sqrt(
								Math.log(
									Math.max(
										this.stripLines[a].startValue,
										this.stripLines[a].endValue
									) /
										Math.min(
											this.stripLines[a].startValue,
											this.stripLines[a].endValue
										)
								) / Math.log(f)
						  )
						: this.stripLines[a].value +
						  (Math.abs(
								this.stripLines[a].endValue - this.stripLines[a].startValue
						  ) -
								f) /
								2;
					this.stripLines[a].thickness = f;
					this.stripLines[a]._thicknessType = "value";
				}
		};
		D.prototype.calculateBreaksSizeInValues = function () {
			for (
				var a =
						"left" === this._position || "right" === this._position
							? this.lineCoordinates.height || this.chart.height
							: this.lineCoordinates.width || this.chart.width,
					f = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [],
					c =
						this.conversionParameters.pixelPerUnit ||
						a /
							(this.logarithmic
								? this.conversionParameters.maximum /
								  this.conversionParameters.minimum
								: this.conversionParameters.maximum -
								  this.conversionParameters.minimum),
					b = this.scaleBreaks && !g(this.scaleBreaks.options.spacing),
					e,
					d = 0;
				d < f.length;
				d++
			)
				(e = b || !g(f[d].options.spacing)),
					(f[d].spacing =
						Va(f[d].spacing, a, 8, e ? 0.1 * a : 8, e ? 0 : 3) << 0),
					(f[d].size = 0 > f[d].spacing ? 0 : Math.abs(f[d].spacing / c)),
					this.logarithmic &&
						(f[d].size = Math.pow(this.logarithmBase, f[d].size));
		};
		D.prototype.calculateBreaksInPixels = function () {
			if (!(this.scaleBreaks && 0 >= this.scaleBreaks._appliedBreaks.length)) {
				var a = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [];
				a.length &&
					(this.scaleBreaks.firstBreakIndex = this.scaleBreaks.lastBreakIndex =
						null);
				for (
					var f = 0;
					f < a.length &&
					!(a[f].startValue > this.conversionParameters.maximum);
					f++
				)
					a[f].endValue < this.conversionParameters.minimum ||
						(g(this.scaleBreaks.firstBreakIndex) &&
							(this.scaleBreaks.firstBreakIndex = f),
						a[f].startValue >= this.conversionParameters.minimum &&
							((a[f].startPixel = this.convertValueToPixel(a[f].startValue)),
							(this.scaleBreaks.lastBreakIndex = f)),
						a[f].endValue <= this.conversionParameters.maximum &&
							(a[f].endPixel = this.convertValueToPixel(a[f].endValue)));
			}
		};
		D.prototype.renderLabelsTicksAndTitle = function () {
			var a = this,
				f = !1,
				c = 0,
				b = 0,
				e = 1,
				d = 0;
			0 !== this.labelAngle && 360 !== this.labelAngle && (e = 1.2);
			if ("undefined" === typeof this.options.interval) {
				if ("bottom" === this._position || "top" === this._position)
					if (
						this.logarithmic &&
						!this.equidistantInterval &&
						this.labelAutoFit
					) {
						for (
							var c = [],
								e = 0 !== this.labelAngle && 360 !== this.labelAngle ? 1 : 1.2,
								g,
								q = this.viewportMaximum,
								l = this.lineCoordinates.width / Math.log(this.range),
								m = this._labels.length - 1;
							0 <= m;
							m--
						) {
							p = this._labels[m];
							if (p.position < this.viewportMinimum) break;
							p.position > this.viewportMaximum ||
								!(
									m === this._labels.length - 1 ||
									g < (Math.log(q / p.position) * l) / e
								) ||
								(c.push(p),
								(q = p.position),
								(g =
									p.textBlock.width *
										Math.abs(Math.cos((Math.PI / 180) * this.labelAngle)) +
									p.textBlock.height *
										Math.abs(Math.sin((Math.PI / 180) * this.labelAngle))));
						}
						this._labels = c;
					} else {
						for (m = 0; m < this._labels.length; m++)
							(p = this._labels[m]),
								p.position < this.viewportMinimum ||
									((g =
										p.textBlock.width *
											Math.abs(Math.cos((Math.PI / 180) * this.labelAngle)) +
										p.textBlock.height *
											Math.abs(Math.sin((Math.PI / 180) * this.labelAngle))),
									(c += g));
						c > this.lineCoordinates.width * e && this.labelAutoFit && (f = !0);
					}
				if ("left" === this._position || "right" === this._position)
					if (
						this.logarithmic &&
						!this.equidistantInterval &&
						this.labelAutoFit
					) {
						for (
							var c = [],
								n,
								q = this.viewportMaximum,
								l = this.lineCoordinates.height / Math.log(this.range),
								m = this._labels.length - 1;
							0 <= m;
							m--
						) {
							p = this._labels[m];
							if (p.position < this.viewportMinimum) break;
							p.position > this.viewportMaximum ||
								!(
									m === this._labels.length - 1 ||
									n < Math.log(q / p.position) * l
								) ||
								(c.push(p),
								(q = p.position),
								(n =
									p.textBlock.height *
										Math.abs(Math.cos((Math.PI / 180) * this.labelAngle)) +
									p.textBlock.width *
										Math.abs(Math.sin((Math.PI / 180) * this.labelAngle))));
						}
						this._labels = c;
					} else {
						for (m = 0; m < this._labels.length; m++)
							(p = this._labels[m]),
								p.position < this.viewportMinimum ||
									((n =
										p.textBlock.height *
											Math.abs(Math.cos((Math.PI / 180) * this.labelAngle)) +
										p.textBlock.width *
											Math.abs(Math.sin((Math.PI / 180) * this.labelAngle))),
									(b += n));
						b > this.lineCoordinates.height * e &&
							this.labelAutoFit &&
							(f = !0);
					}
			}
			this.logarithmic &&
				!this.equidistantInterval &&
				this.labelAutoFit &&
				this._labels.sort(function (a, b) {
					return a.position - b.position;
				});
			var m = 0,
				p,
				r;
			if ("bottom" === this._position) {
				for (m = 0; m < this._labels.length; m++)
					(p = this._labels[m]),
						p.position < this.viewportMinimum ||
							p.position > this.viewportMaximum ||
							((r = this.getPixelCoordinatesOnAxis(p.position)),
							this.tickThickness &&
								"inside" != this.tickPlacement &&
								((this.ctx.lineWidth = this.tickThickness),
								(this.ctx.strokeStyle = this.tickColor),
								(b =
									1 === this.ctx.lineWidth % 2 ? (r.x << 0) + 0.5 : r.x << 0),
								this.ctx.beginPath(),
								this.ctx.moveTo(b, r.y << 0),
								this.ctx.lineTo(b, (r.y + this.tickLength) << 0),
								this.ctx.stroke()),
							(f && 0 !== d++ % 2 && this.labelAutoFit) ||
								(0 === p.textBlock.angle
									? ((r.x -= p.textBlock.width / 2),
									  (r.y =
											"inside" === this.labelPlacement
												? r.y -
												  (("inside" === this.tickPlacement
														? this.tickLength
														: 0) +
														p.textBlock.height -
														p.textBlock.fontSize / 2)
												: r.y +
												  ("inside" === this.tickPlacement
														? 0
														: this.tickLength) +
												  p.textBlock.fontSize / 2 +
												  5))
									: ((r.x =
											"inside" === this.labelPlacement
												? 0 > this.labelAngle
													? r.x
													: r.x -
													  p.textBlock.width *
															Math.cos((Math.PI / 180) * this.labelAngle)
												: r.x -
												  (0 > this.labelAngle
														? p.textBlock.width *
														  Math.cos((Math.PI / 180) * this.labelAngle)
														: 0)),
									  (r.y =
											"inside" === this.labelPlacement
												? 0 > this.labelAngle
													? r.y -
													  ("inside" === this.tickPlacement
															? this.tickLength
															: 0) -
													  5
													: r.y -
													  ("inside" === this.tickPlacement
															? this.tickLength
															: 0) -
													  Math.abs(
															p.textBlock.width *
																Math.sin((Math.PI / 180) * this.labelAngle) +
																5
													  )
												: r.y +
												  ("inside" === this.tickPlacement
														? 0
														: this.tickLength) +
												  Math.abs(
														0 > this.labelAngle
															? p.textBlock.width *
																	Math.sin((Math.PI / 180) * this.labelAngle) -
																	5
															: 5
												  ))),
								(p.textBlock.x = r.x),
								(p.textBlock.y = r.y)));
				"inside" === this.tickPlacement &&
					this.chart.addEventListener(
						"dataAnimationEnd",
						function () {
							for (m = 0; m < a._labels.length; m++)
								if (
									((p = a._labels[m]),
									!(
										p.position < a.viewportMinimum ||
										p.position > a.viewportMaximum
									) &&
										((r = a.getPixelCoordinatesOnAxis(p.position)),
										a.tickThickness))
								) {
									a.ctx.lineWidth = a.tickThickness;
									a.ctx.strokeStyle = a.tickColor;
									var b =
										1 === a.ctx.lineWidth % 2 ? (r.x << 0) + 0.5 : r.x << 0;
									a.ctx.save();
									a.ctx.beginPath();
									a.ctx.moveTo(b, r.y << 0);
									a.ctx.lineTo(b, (r.y - a.tickLength) << 0);
									a.ctx.stroke();
									a.ctx.restore();
								}
						},
						this
					);
				this.title &&
					(this._titleTextBlock.measureText(),
					(this._titleTextBlock.x =
						this.lineCoordinates.x1 +
						this.lineCoordinates.width / 2 -
						this._titleTextBlock.width / 2),
					(this._titleTextBlock.y =
						this.bounds.y2 - this._titleTextBlock.height - 3),
					(this.titleMaxWidth = this._titleTextBlock.maxWidth),
					this._titleTextBlock.render(!0));
			} else if ("top" === this._position) {
				for (m = 0; m < this._labels.length; m++)
					(p = this._labels[m]),
						p.position < this.viewportMinimum ||
							p.position > this.viewportMaximum ||
							((r = this.getPixelCoordinatesOnAxis(p.position)),
							this.tickThickness &&
								"inside" != this.tickPlacement &&
								((this.ctx.lineWidth = this.tickThickness),
								(this.ctx.strokeStyle = this.tickColor),
								(b =
									1 === this.ctx.lineWidth % 2 ? (r.x << 0) + 0.5 : r.x << 0),
								this.ctx.beginPath(),
								this.ctx.moveTo(b, r.y << 0),
								this.ctx.lineTo(b, (r.y - this.tickLength) << 0),
								this.ctx.stroke()),
							(f && 0 !== d++ % 2 && this.labelAutoFit) ||
								(0 === p.textBlock.angle
									? ((r.x -= p.textBlock.width / 2),
									  (r.y =
											"inside" === this.labelPlacement
												? r.y +
												  this.labelFontSize / 2 +
												  ("inside" === this.tickPlacement
														? this.tickLength
														: 0) +
												  5
												: r.y -
												  (("inside" === this.tickPlacement
														? 0
														: this.tickLength) +
														p.textBlock.height -
														p.textBlock.fontSize / 2)))
									: ((r.x =
											"inside" === this.labelPlacement
												? 0 < this.labelAngle
													? r.x
													: r.x -
													  p.textBlock.width *
															Math.cos((Math.PI / 180) * this.labelAngle)
												: r.x +
												  (p.textBlock.height - this.labelFontSize) *
														Math.sin((Math.PI / 180) * this.labelAngle) -
												  (0 < this.labelAngle
														? p.textBlock.width *
														  Math.cos((Math.PI / 180) * this.labelAngle)
														: 0)),
									  (r.y =
											"inside" === this.labelPlacement
												? 0 < this.labelAngle
													? r.y +
													  ("inside" === this.tickPlacement
															? this.tickLength
															: 0) +
													  5
													: r.y -
													  p.textBlock.width *
															Math.sin((Math.PI / 180) * this.labelAngle) +
													  ("inside" === this.tickPlacement
															? this.tickLength
															: 0) +
													  5
												: r.y -
												  (("inside" === this.tickPlacement
														? 0
														: this.tickLength) +
														((p.textBlock.height - p.textBlock.fontSize / 2) *
															Math.cos((Math.PI / 180) * this.labelAngle) +
															(0 < this.labelAngle
																? p.textBlock.width *
																  Math.sin((Math.PI / 180) * this.labelAngle)
																: 0))))),
								(p.textBlock.x = r.x),
								(p.textBlock.y = r.y)));
				"inside" === this.tickPlacement &&
					this.chart.addEventListener(
						"dataAnimationEnd",
						function () {
							for (m = 0; m < a._labels.length; m++)
								if (
									((p = a._labels[m]),
									!(
										p.position < a.viewportMinimum ||
										p.position > a.viewportMaximum
									) &&
										((r = a.getPixelCoordinatesOnAxis(p.position)),
										a.tickThickness))
								) {
									a.ctx.lineWidth = a.tickThickness;
									a.ctx.strokeStyle = a.tickColor;
									var b =
										1 === a.ctx.lineWidth % 2 ? (r.x << 0) + 0.5 : r.x << 0;
									a.ctx.save();
									a.ctx.beginPath();
									a.ctx.moveTo(b, r.y << 0);
									a.ctx.lineTo(b, (r.y + a.tickLength) << 0);
									a.ctx.stroke();
									a.ctx.restore();
								}
						},
						this
					);
				this.title &&
					(this._titleTextBlock.measureText(),
					(this._titleTextBlock.x =
						this.lineCoordinates.x1 +
						this.lineCoordinates.width / 2 -
						this._titleTextBlock.width / 2),
					(this._titleTextBlock.y = this.bounds.y1 + 1),
					(this.titleMaxWidth = this._titleTextBlock.maxWidth),
					this._titleTextBlock.render(!0));
			} else if ("left" === this._position) {
				for (m = 0; m < this._labels.length; m++)
					(p = this._labels[m]),
						p.position < this.viewportMinimum ||
							p.position > this.viewportMaximum ||
							((r = this.getPixelCoordinatesOnAxis(p.position)),
							this.tickThickness &&
								"inside" != this.tickPlacement &&
								((this.ctx.lineWidth = this.tickThickness),
								(this.ctx.strokeStyle = this.tickColor),
								(b =
									1 === this.ctx.lineWidth % 2 ? (r.y << 0) + 0.5 : r.y << 0),
								this.ctx.beginPath(),
								this.ctx.moveTo(r.x << 0, b),
								this.ctx.lineTo((r.x - this.tickLength) << 0, b),
								this.ctx.stroke()),
							(f && 0 !== d++ % 2 && this.labelAutoFit) ||
								(0 === this.labelAngle
									? ((p.textBlock.y = r.y),
									  (p.textBlock.x =
											"inside" === this.labelPlacement
												? r.x +
												  ("inside" === this.tickPlacement
														? this.tickLength
														: 0) +
												  5
												: r.x -
												  p.textBlock.width *
														Math.cos((Math.PI / 180) * this.labelAngle) -
												  ("inside" === this.tickPlacement
														? 0
														: this.tickLength) -
												  5))
									: ((p.textBlock.y =
											"inside" === this.labelPlacement
												? r.y
												: r.y -
												  p.textBlock.width *
														Math.sin((Math.PI / 180) * this.labelAngle)),
									  (p.textBlock.x =
											"inside" === this.labelPlacement
												? r.x +
												  ("inside" === this.tickPlacement
														? this.tickLength
														: 0) +
												  5
												: 0 < this.labelAngle
												? r.x -
												  p.textBlock.width *
														Math.cos((Math.PI / 180) * this.labelAngle) -
												  ("inside" === this.tickPlacement
														? 0
														: this.tickLength) -
												  5
												: r.x -
												  p.textBlock.width *
														Math.cos((Math.PI / 180) * this.labelAngle) +
												  (p.textBlock.height - p.textBlock.fontSize / 2 - 5) *
														Math.sin((Math.PI / 180) * this.labelAngle) -
												  ("inside" === this.tickPlacement
														? 0
														: this.tickLength)))));
				"inside" === this.tickPlacement &&
					this.chart.addEventListener(
						"dataAnimationEnd",
						function () {
							for (m = 0; m < a._labels.length; m++)
								if (
									((p = a._labels[m]),
									!(
										p.position < a.viewportMinimum ||
										p.position > a.viewportMaximum
									) &&
										((r = a.getPixelCoordinatesOnAxis(p.position)),
										a.tickThickness))
								) {
									a.ctx.lineWidth = a.tickThickness;
									a.ctx.strokeStyle = a.tickColor;
									var b =
										1 === a.ctx.lineWidth % 2 ? (r.y << 0) + 0.5 : r.y << 0;
									a.ctx.save();
									a.ctx.beginPath();
									a.ctx.moveTo(r.x << 0, b);
									a.ctx.lineTo((r.x + a.tickLength) << 0, b);
									a.ctx.stroke();
									a.ctx.restore();
								}
						},
						this
					);
				this.title &&
					(this._titleTextBlock.measureText(),
					(this._titleTextBlock.x = this.bounds.x1 + 1),
					(this._titleTextBlock.y =
						this.lineCoordinates.height / 2 +
						this._titleTextBlock.width / 2 +
						this.lineCoordinates.y1),
					(this.titleMaxWidth = this._titleTextBlock.maxWidth),
					this._titleTextBlock.render(!0));
			} else if ("right" === this._position) {
				for (m = 0; m < this._labels.length; m++)
					(p = this._labels[m]),
						p.position < this.viewportMinimum ||
							p.position > this.viewportMaximum ||
							((r = this.getPixelCoordinatesOnAxis(p.position)),
							this.tickThickness &&
								"inside" != this.tickPlacement &&
								((this.ctx.lineWidth = this.tickThickness),
								(this.ctx.strokeStyle = this.tickColor),
								(b =
									1 === this.ctx.lineWidth % 2 ? (r.y << 0) + 0.5 : r.y << 0),
								this.ctx.beginPath(),
								this.ctx.moveTo(r.x << 0, b),
								this.ctx.lineTo((r.x + this.tickLength) << 0, b),
								this.ctx.stroke()),
							(f && 0 !== d++ % 2 && this.labelAutoFit) ||
								(0 === this.labelAngle
									? ((p.textBlock.y = r.y),
									  (p.textBlock.x =
											"inside" === this.labelPlacement
												? r.x -
												  p.textBlock.width -
												  ("inside" === this.tickPlacement
														? this.tickLength
														: 0) -
												  5
												: r.x +
												  ("inside" === this.tickPlacement
														? 0
														: this.tickLength) +
												  5))
									: ((p.textBlock.y =
											"inside" === this.labelPlacement
												? r.y -
												  p.textBlock.width *
														Math.sin((Math.PI / 180) * this.labelAngle)
												: 0 > this.labelAngle
												? r.y
												: r.y -
												  (p.textBlock.height - p.textBlock.fontSize / 2 - 5) *
														Math.cos((Math.PI / 180) * this.labelAngle)),
									  (p.textBlock.x =
											"inside" === this.labelPlacement
												? r.x -
												  p.textBlock.width *
														Math.cos((Math.PI / 180) * this.labelAngle) -
												  ("inside" === this.tickPlacement
														? this.tickLength
														: 0) -
												  5
												: 0 < this.labelAngle
												? r.x +
												  (p.textBlock.height - p.textBlock.fontSize / 2 - 5) *
														Math.sin((Math.PI / 180) * this.labelAngle) +
												  ("inside" === this.tickPlacement
														? 0
														: this.tickLength)
												: r.x +
												  ("inside" === this.tickPlacement
														? 0
														: this.tickLength) +
												  5))));
				"inside" === this.tickPlacement &&
					this.chart.addEventListener(
						"dataAnimationEnd",
						function () {
							for (m = 0; m < a._labels.length; m++)
								if (
									((p = a._labels[m]),
									!(
										p.position < a.viewportMinimum ||
										p.position > a.viewportMaximum
									) &&
										((r = a.getPixelCoordinatesOnAxis(p.position)),
										a.tickThickness))
								) {
									a.ctx.lineWidth = a.tickThickness;
									a.ctx.strokeStyle = a.tickColor;
									var b =
										1 === a.ctx.lineWidth % 2 ? (r.y << 0) + 0.5 : r.y << 0;
									a.ctx.save();
									a.ctx.beginPath();
									a.ctx.moveTo(r.x << 0, b);
									a.ctx.lineTo((r.x - a.tickLength) << 0, b);
									a.ctx.stroke();
									a.ctx.restore();
								}
						},
						this
					);
				this.title &&
					(this._titleTextBlock.measureText(),
					(this._titleTextBlock.x = this.bounds.x2 - 1),
					(this._titleTextBlock.y =
						this.lineCoordinates.height / 2 -
						this._titleTextBlock.width / 2 +
						this.lineCoordinates.y1),
					(this.titleMaxWidth = this._titleTextBlock.maxWidth),
					this._titleTextBlock.render(!0));
			}
			d = 0;
			if ("inside" === this.labelPlacement)
				this.chart.addEventListener(
					"dataAnimationEnd",
					function () {
						for (m = 0; m < a._labels.length; m++)
							(p = a._labels[m]),
								p.position < a.viewportMinimum ||
									p.position > a.viewportMaximum ||
									(f && 0 !== d++ % 2 && a.labelAutoFit) ||
									(a.ctx.save(),
									a.ctx.beginPath(),
									p.textBlock.render(!0),
									a.ctx.restore());
					},
					this
				);
			else
				for (m = 0; m < this._labels.length; m++)
					(p = this._labels[m]),
						p.position < this.viewportMinimum ||
							p.position > this.viewportMaximum ||
							(f && 0 !== d++ % 2 && this.labelAutoFit) ||
							p.textBlock.render(!0);
		};
		D.prototype.renderInterlacedColors = function () {
			var a = this.chart.plotArea.ctx,
				f,
				c,
				b = this.chart.plotArea,
				e = 0;
			f = !0;
			if (
				("bottom" === this._position || "top" === this._position) &&
				this.interlacedColor
			)
				for (
					a.fillStyle = this.interlacedColor, e = 0;
					e < this._labels.length;
					e++
				)
					f
						? ((f = this.getPixelCoordinatesOnAxis(this._labels[e].position)),
						  (c =
								e + 1 > this._labels.length - 1
									? this.getPixelCoordinatesOnAxis(this.viewportMaximum)
									: this.getPixelCoordinatesOnAxis(
											this._labels[e + 1].position
									  )),
						  a.fillRect(
								Math.min(c.x, f.x),
								b.y1,
								Math.abs(c.x - f.x),
								Math.abs(b.y1 - b.y2)
						  ),
						  (f = !1))
						: (f = !0);
			else if (
				("left" === this._position || "right" === this._position) &&
				this.interlacedColor
			)
				for (
					a.fillStyle = this.interlacedColor, e = 0;
					e < this._labels.length;
					e++
				)
					f
						? ((c = this.getPixelCoordinatesOnAxis(this._labels[e].position)),
						  (f =
								e + 1 > this._labels.length - 1
									? this.getPixelCoordinatesOnAxis(this.viewportMaximum)
									: this.getPixelCoordinatesOnAxis(
											this._labels[e + 1].position
									  )),
						  a.fillRect(
								b.x1,
								Math.min(c.y, f.y),
								Math.abs(b.x1 - b.x2),
								Math.abs(f.y - c.y)
						  ),
						  (f = !1))
						: (f = !0);
			a.beginPath();
		};
		D.prototype.renderStripLinesOfThicknessType = function (a) {
			if (this.stripLines && 0 < this.stripLines.length && a) {
				for (
					var f = this,
						c,
						b = 0,
						e = 0,
						d = !1,
						q = !1,
						l = [],
						z = [],
						q = !1,
						b = 0;
					b < this.stripLines.length;
					b++
				) {
					var m = this.stripLines[b];
					m._thicknessType === a &&
						(("pixel" === a &&
							(m.value < this.viewportMinimum ||
								m.value > this.viewportMaximum ||
								g(m.value) ||
								isNaN(this.range))) ||
							("value" === a &&
								((m.startValue <= this.viewportMinimum &&
									m.endValue <= this.viewportMinimum) ||
									(m.startValue >= this.viewportMaximum &&
										m.endValue >= this.viewportMaximum) ||
									g(m.startValue) ||
									g(m.endValue) ||
									isNaN(this.range))) ||
							l.push(m));
				}
				for (b = 0; b < this._stripLineLabels.length; b++)
					if (
						((m = this.stripLines[b]),
						(c = this._stripLineLabels[b]),
						!(
							c.position < this.viewportMinimum ||
							c.position > this.viewportMaximum ||
							isNaN(this.range)
						))
					) {
						a = this.getPixelCoordinatesOnAxis(c.position);
						if ("outside" === c.stripLine.labelPlacement)
							if (
								(m &&
									((this.ctx.strokeStyle = m.color),
									"pixel" === m._thicknessType &&
										(this.ctx.lineWidth = m.thickness)),
								"bottom" === this._position)
							) {
								var n =
									1 === this.ctx.lineWidth % 2 ? (a.x << 0) + 0.5 : a.x << 0;
								this.ctx.beginPath();
								this.ctx.moveTo(n, a.y << 0);
								this.ctx.lineTo(n, (a.y + this.tickLength) << 0);
								this.ctx.stroke();
								0 === this.labelAngle
									? ((a.x -= c.textBlock.width / 2),
									  (a.y += this.tickLength + c.textBlock.fontSize / 2 + 5))
									: ((a.x -=
											0 > this.labelAngle
												? c.textBlock.width *
												  Math.cos((Math.PI / 180) * this.labelAngle)
												: 0),
									  (a.y +=
											this.tickLength +
											Math.abs(
												0 > this.labelAngle
													? c.textBlock.width *
															Math.sin((Math.PI / 180) * this.labelAngle) -
															5
													: 5
											)));
							} else
								"top" === this._position
									? ((n =
											1 === this.ctx.lineWidth % 2
												? (a.x << 0) + 0.5
												: a.x << 0),
									  this.ctx.beginPath(),
									  this.ctx.moveTo(n, a.y << 0),
									  this.ctx.lineTo(n, (a.y - this.tickLength) << 0),
									  this.ctx.stroke(),
									  0 === this.labelAngle
											? ((a.x -= c.textBlock.width / 2),
											  (a.y -=
													this.tickLength +
													c.textBlock.height -
													c.textBlock.fontSize / 2))
											: ((a.x +=
													(c.textBlock.height -
														this.tickLength -
														this.labelFontSize / 2) *
														Math.sin((Math.PI / 180) * this.labelAngle) -
													(0 < this.labelAngle
														? c.textBlock.width *
														  Math.cos((Math.PI / 180) * this.labelAngle)
														: 0)),
											  (a.y -=
													this.tickLength +
													(c.textBlock.height *
														Math.cos((Math.PI / 180) * this.labelAngle) +
														(0 < this.labelAngle
															? c.textBlock.width *
															  Math.sin((Math.PI / 180) * this.labelAngle)
															: 0)))))
									: "left" === this._position
									? ((n =
											1 === this.ctx.lineWidth % 2
												? (a.y << 0) + 0.5
												: a.y << 0),
									  this.ctx.beginPath(),
									  this.ctx.moveTo(a.x << 0, n),
									  this.ctx.lineTo((a.x - this.tickLength) << 0, n),
									  this.ctx.stroke(),
									  0 === this.labelAngle
											? (a.x =
													a.x -
													c.textBlock.width *
														Math.cos((Math.PI / 180) * this.labelAngle) -
													this.tickLength -
													5)
											: ((a.y -=
													c.textBlock.width *
													Math.sin((Math.PI / 180) * this.labelAngle)),
											  (a.x =
													0 < this.labelAngle
														? a.x -
														  c.textBlock.width *
																Math.cos((Math.PI / 180) * this.labelAngle) -
														  this.tickLength -
														  5
														: a.x -
														  c.textBlock.width *
																Math.cos((Math.PI / 180) * this.labelAngle) +
														  (c.textBlock.height -
																c.textBlock.fontSize / 2 -
																5) *
																Math.sin((Math.PI / 180) * this.labelAngle) -
														  this.tickLength)))
									: "right" === this._position &&
									  ((n =
											1 === this.ctx.lineWidth % 2
												? (a.y << 0) + 0.5
												: a.y << 0),
									  this.ctx.beginPath(),
									  this.ctx.moveTo(a.x << 0, n),
									  this.ctx.lineTo((a.x + this.tickLength) << 0, n),
									  this.ctx.stroke(),
									  0 === this.labelAngle
											? (a.x = a.x + this.tickLength + 5)
											: ((a.y =
													0 > this.labelAngle
														? a.y
														: a.y -
														  (c.textBlock.height -
																c.textBlock.fontSize / 2 -
																5) *
																Math.cos((Math.PI / 180) * this.labelAngle)),
											  (a.x =
													0 < this.labelAngle
														? a.x +
														  (c.textBlock.height -
																c.textBlock.fontSize / 2 -
																5) *
																Math.sin((Math.PI / 180) * this.labelAngle) +
														  this.tickLength
														: a.x + this.tickLength + 5)));
						else
							(c.textBlock.angle = -90),
								"bottom" === this._position
									? ((c.textBlock.maxWidth = this.options.stripLines[b]
											.labelMaxWidth
											? this.options.stripLines[b].labelMaxWidth
											: this.chart.plotArea.height - 3),
									  c.textBlock.measureText(),
									  a.x - c.textBlock.height > this.chart.plotArea.x1
											? g(m.startValue)
												? (a.x -= c.textBlock.height - c.textBlock.fontSize / 2)
												: (a.x -=
														c.textBlock.height / 2 -
														c.textBlock.fontSize / 2 +
														3)
											: ((c.textBlock.angle = 90),
											  g(m.startValue)
													? (a.x +=
															c.textBlock.height - c.textBlock.fontSize / 2)
													: (a.x +=
															c.textBlock.height / 2 -
															c.textBlock.fontSize / 2 +
															3)),
									  (a.y =
											-90 === c.textBlock.angle
												? "near" === c.stripLine.labelAlign
													? this.chart.plotArea.y2 - 3
													: "center" === c.stripLine.labelAlign
													? (this.chart.plotArea.y2 +
															this.chart.plotArea.y1 +
															c.textBlock.width) /
													  2
													: this.chart.plotArea.y1 + c.textBlock.width + 3
												: "near" === c.stripLine.labelAlign
												? this.chart.plotArea.y2 - c.textBlock.width - 3
												: "center" === c.stripLine.labelAlign
												? (this.chart.plotArea.y2 +
														this.chart.plotArea.y1 -
														c.textBlock.width) /
												  2
												: this.chart.plotArea.y1 + 3))
									: "top" === this._position
									? ((c.textBlock.maxWidth = this.options.stripLines[b]
											.labelMaxWidth
											? this.options.stripLines[b].labelMaxWidth
											: this.chart.plotArea.height - 3),
									  c.textBlock.measureText(),
									  a.x - c.textBlock.height > this.chart.plotArea.x1
											? g(m.startValue)
												? (a.x -= c.textBlock.height - c.textBlock.fontSize / 2)
												: (a.x -=
														c.textBlock.height / 2 -
														c.textBlock.fontSize / 2 +
														3)
											: ((c.textBlock.angle = 90),
											  g(m.startValue)
													? (a.x +=
															c.textBlock.height - c.textBlock.fontSize / 2)
													: (a.x +=
															c.textBlock.height / 2 -
															c.textBlock.fontSize / 2 +
															3)),
									  (a.y =
											-90 === c.textBlock.angle
												? "near" === c.stripLine.labelAlign
													? this.chart.plotArea.y1 + c.textBlock.width + 3
													: "center" === c.stripLine.labelAlign
													? (this.chart.plotArea.y2 +
															this.chart.plotArea.y1 +
															c.textBlock.width) /
													  2
													: this.chart.plotArea.y2 - 3
												: "near" === c.stripLine.labelAlign
												? this.chart.plotArea.y1 + 3
												: "center" === c.stripLine.labelAlign
												? (this.chart.plotArea.y2 +
														this.chart.plotArea.y1 -
														c.textBlock.width) /
												  2
												: this.chart.plotArea.y2 - c.textBlock.width - 3))
									: "left" === this._position
									? ((c.textBlock.maxWidth = this.options.stripLines[b]
											.labelMaxWidth
											? this.options.stripLines[b].labelMaxWidth
											: this.chart.plotArea.width - 3),
									  (c.textBlock.angle = 0),
									  c.textBlock.measureText(),
									  a.y - c.textBlock.height > this.chart.plotArea.y1
											? g(m.startValue)
												? (a.y -= c.textBlock.height - c.textBlock.fontSize / 2)
												: (a.y -=
														c.textBlock.height / 2 - c.textBlock.fontSize + 3)
											: a.y - c.textBlock.height < this.chart.plotArea.y2
											? (a.y += c.textBlock.fontSize / 2 + 3)
											: g(m.startValue)
											? (a.y -= c.textBlock.height - c.textBlock.fontSize / 2)
											: (a.y -=
													c.textBlock.height / 2 - c.textBlock.fontSize + 3),
									  (a.x =
											"near" === c.stripLine.labelAlign
												? this.chart.plotArea.x1 + 3
												: "center" === c.stripLine.labelAlign
												? (this.chart.plotArea.x2 + this.chart.plotArea.x1) /
														2 -
												  c.textBlock.width / 2
												: this.chart.plotArea.x2 - c.textBlock.width - 3))
									: "right" === this._position &&
									  ((c.textBlock.maxWidth = this.options.stripLines[b]
											.labelMaxWidth
											? this.options.stripLines[b].labelMaxWidth
											: this.chart.plotArea.width - 3),
									  (c.textBlock.angle = 0),
									  c.textBlock.measureText(),
									  a.y - +c.textBlock.height > this.chart.plotArea.y1
											? g(m.startValue)
												? (a.y -= c.textBlock.height - c.textBlock.fontSize / 2)
												: (a.y -=
														c.textBlock.height / 2 -
														c.textBlock.fontSize / 2 -
														3)
											: a.y - c.textBlock.height < this.chart.plotArea.y2
											? (a.y += c.textBlock.fontSize / 2 + 3)
											: g(m.startValue)
											? (a.y -= c.textBlock.height - c.textBlock.fontSize / 2)
											: (a.y -=
													c.textBlock.height / 2 -
													c.textBlock.fontSize / 2 +
													3),
									  (a.x =
											"near" === c.stripLine.labelAlign
												? this.chart.plotArea.x2 - c.textBlock.width - 3
												: "center" === c.stripLine.labelAlign
												? (this.chart.plotArea.x2 + this.chart.plotArea.x1) /
														2 -
												  c.textBlock.width / 2
												: this.chart.plotArea.x1 + 3));
						c.textBlock.x = a.x;
						c.textBlock.y = a.y;
						z.push(c);
					}
				if (!q) {
					q = !1;
					this.ctx.save();
					this.ctx.beginPath();
					this.ctx.rect(
						this.chart.plotArea.x1,
						this.chart.plotArea.y1,
						this.chart.plotArea.width,
						this.chart.plotArea.height
					);
					this.ctx.clip();
					for (b = 0; b < l.length; b++)
						(m = l[b]),
							m.showOnTop
								? d ||
								  ((d = !0),
								  this.chart.addEventListener(
										"dataAnimationIterationEnd",
										function () {
											this.ctx.save();
											this.ctx.beginPath();
											this.ctx.rect(
												this.chart.plotArea.x1,
												this.chart.plotArea.y1,
												this.chart.plotArea.width,
												this.chart.plotArea.height
											);
											this.ctx.clip();
											for (e = 0; e < l.length; e++)
												(m = l[e]), m.showOnTop && m.render();
											this.ctx.restore();
										},
										m
								  ))
								: m.render();
					for (b = 0; b < z.length; b++)
						(c = z[b]),
							c.stripLine.showOnTop
								? q ||
								  ((q = !0),
								  this.chart.addEventListener(
										"dataAnimationIterationEnd",
										function () {
											for (e = 0; e < z.length; e++)
												(c = z[e]),
													"inside" === c.stripLine.labelPlacement &&
														c.stripLine.showOnTop &&
														(f.ctx.save(),
														f.ctx.beginPath(),
														f.ctx.rect(
															f.chart.plotArea.x1,
															f.chart.plotArea.y1,
															f.chart.plotArea.width,
															f.chart.plotArea.height
														),
														f.ctx.clip(),
														c.textBlock.render(!0),
														f.ctx.restore());
										},
										c.textBlock
								  ))
								: "inside" === c.stripLine.labelPlacement &&
								  c.textBlock.render(!0);
					this.ctx.restore();
					q = !0;
				}
				if (q)
					for (q = !1, b = 0; b < z.length; b++)
						(c = z[b]),
							"outside" === c.stripLine.labelPlacement &&
								c.textBlock.render(!0);
			}
		};
		D.prototype.renderBreaksBackground = function () {
			this.chart._breaksCanvas &&
				this.scaleBreaks &&
				0 < this.scaleBreaks._appliedBreaks.length &&
				this.maskCanvas &&
				(this.chart._breaksCanvasCtx.save(),
				this.chart._breaksCanvasCtx.beginPath(),
				this.chart._breaksCanvasCtx.rect(
					this.chart.plotArea.x1,
					this.chart.plotArea.y1,
					this.chart.plotArea.width,
					this.chart.plotArea.height
				),
				this.chart._breaksCanvasCtx.clip(),
				this.chart._breaksCanvasCtx.drawImage(
					this.maskCanvas,
					0,
					0,
					this.chart.width,
					this.chart.height
				),
				this.chart._breaksCanvasCtx.restore());
		};
		D.prototype.createMask = function () {
			if (this.scaleBreaks && 0 < this.scaleBreaks._appliedBreaks.length) {
				var a = this.scaleBreaks._appliedBreaks;
				t
					? ((this.maskCanvas = sa(this.chart.width, this.chart.height)),
					  (this.maskCtx = this.maskCanvas.getContext("2d")))
					: ((this.maskCanvas = this.chart.plotArea.canvas),
					  (this.maskCtx = this.chart.plotArea.ctx));
				this.maskCtx.save();
				this.maskCtx.beginPath();
				this.maskCtx.rect(
					this.chart.plotArea.x1,
					this.chart.plotArea.y1,
					this.chart.plotArea.width,
					this.chart.plotArea.height
				);
				this.maskCtx.clip();
				for (var f = 0; f < a.length; f++)
					a[f].endValue < this.viewportMinimum ||
						a[f].startValue > this.viewportMaximum ||
						isNaN(this.range) ||
						a[f].render(this.maskCtx);
				this.maskCtx.restore();
			}
		};
		D.prototype.renderCrosshair = function (a, f) {
			isFinite(this.minimum) &&
				isFinite(this.maximum) &&
				(this.crosshair.render(a, f),
				this.crosshair.dispatchEvent(
					"updated",
					{
						chart: this.chart,
						crosshair: this.options,
						axis: this,
						value: this.crosshair.value,
					},
					this
				));
		};
		D.prototype.showCrosshair = function (a) {
			g(a) ||
				a < this.viewportMinimum ||
				a > this.viewportMaximum ||
				("top" === this._position || "bottom" === this._position
					? this.crosshair.render(this.convertValueToPixel(a), null, a)
					: this.crosshair.render(null, this.convertValueToPixel(a), a));
		};
		D.prototype.renderGrid = function () {
			if (this.gridThickness && 0 < this.gridThickness) {
				var a = this.chart.ctx;
				a.save();
				var f,
					c = this.chart.plotArea;
				a.lineWidth = this.gridThickness;
				a.strokeStyle = this.gridColor;
				a.setLineDash &&
					a.setLineDash(S(this.gridDashType, this.gridThickness));
				if ("bottom" === this._position || "top" === this._position)
					for (b = 0; b < this._labels.length; b++)
						this._labels[b].position < this.viewportMinimum ||
							this._labels[b].position > this.viewportMaximum ||
							this._labels[b].breaksLabelType ||
							(a.beginPath(),
							(f = this.getPixelCoordinatesOnAxis(this._labels[b].position)),
							(f = 1 === a.lineWidth % 2 ? (f.x << 0) + 0.5 : f.x << 0),
							a.moveTo(f, c.y1 << 0),
							a.lineTo(f, c.y2 << 0),
							a.stroke());
				else if ("left" === this._position || "right" === this._position)
					for (var b = 0; b < this._labels.length; b++)
						this._labels[b].position < this.viewportMinimum ||
							this._labels[b].position > this.viewportMaximum ||
							this._labels[b].breaksLabelType ||
							(a.beginPath(),
							(f = this.getPixelCoordinatesOnAxis(this._labels[b].position)),
							(f = 1 === a.lineWidth % 2 ? (f.y << 0) + 0.5 : f.y << 0),
							a.moveTo(c.x1 << 0, f),
							a.lineTo(c.x2 << 0, f),
							a.stroke());
				a.restore();
			}
		};
		D.prototype.renderAxisLine = function () {
			var a = this.chart.ctx,
				f = t ? this.chart._preRenderCtx : a,
				c = Math.ceil(this.tickThickness / (this.reversed ? -2 : 2)),
				b = Math.ceil(this.tickThickness / (this.reversed ? 2 : -2)),
				e,
				d;
			f.save();
			if ("bottom" === this._position || "top" === this._position) {
				if (this.lineThickness) {
					this.reversed
						? ((e = this.lineCoordinates.x2), (d = this.lineCoordinates.x1))
						: ((e = this.lineCoordinates.x1), (d = this.lineCoordinates.x2));
					f.lineWidth = this.lineThickness;
					f.strokeStyle = this.lineColor ? this.lineColor : "black";
					f.setLineDash &&
						f.setLineDash(S(this.lineDashType, this.lineThickness));
					var q =
						1 === this.lineThickness % 2
							? (this.lineCoordinates.y1 << 0) + 0.5
							: this.lineCoordinates.y1 << 0;
					f.beginPath();
					if (this.scaleBreaks && !g(this.scaleBreaks.firstBreakIndex))
						if (g(this.scaleBreaks.lastBreakIndex))
							e =
								this.scaleBreaks._appliedBreaks[
									this.scaleBreaks.firstBreakIndex
								].endPixel + b;
						else
							for (
								var l = this.scaleBreaks.firstBreakIndex;
								l <= this.scaleBreaks.lastBreakIndex;
								l++
							)
								f.moveTo(e, q),
									f.lineTo(
										this.scaleBreaks._appliedBreaks[l].startPixel + c,
										q
									),
									(e = this.scaleBreaks._appliedBreaks[l].endPixel + b);
					e && (f.moveTo(e, q), f.lineTo(d, q));
					f.stroke();
				}
			} else if (
				("left" === this._position || "right" === this._position) &&
				this.lineThickness
			) {
				this.reversed
					? ((e = this.lineCoordinates.y1), (d = this.lineCoordinates.y2))
					: ((e = this.lineCoordinates.y2), (d = this.lineCoordinates.y1));
				f.lineWidth = this.lineThickness;
				f.strokeStyle = this.lineColor;
				f.setLineDash &&
					f.setLineDash(S(this.lineDashType, this.lineThickness));
				q =
					1 === this.lineThickness % 2
						? (this.lineCoordinates.x1 << 0) + 0.5
						: this.lineCoordinates.x1 << 0;
				f.beginPath();
				if (this.scaleBreaks && !g(this.scaleBreaks.firstBreakIndex))
					if (g(this.scaleBreaks.lastBreakIndex))
						e =
							this.scaleBreaks._appliedBreaks[this.scaleBreaks.firstBreakIndex]
								.endPixel + c;
					else
						for (
							l = this.scaleBreaks.firstBreakIndex;
							l <= this.scaleBreaks.lastBreakIndex;
							l++
						)
							f.moveTo(q, e),
								f.lineTo(q, this.scaleBreaks._appliedBreaks[l].startPixel + b),
								(e = this.scaleBreaks._appliedBreaks[l].endPixel + c);
				e && (f.moveTo(q, e), f.lineTo(q, d));
				f.stroke();
			}
			t &&
				(a.drawImage(
					this.chart._preRenderCanvas,
					0,
					0,
					this.chart.width,
					this.chart.height
				),
				this.chart._breaksCanvasCtx &&
					this.chart._breaksCanvasCtx.drawImage(
						this.chart._preRenderCanvas,
						0,
						0,
						this.chart.width,
						this.chart.height
					),
				f.clearRect(0, 0, this.chart.width, this.chart.height));
			f.restore();
		};
		D.prototype.getPixelCoordinatesOnAxis = function (a) {
			var f = {};
			if ("bottom" === this._position || "top" === this._position)
				(f.x = this.convertValueToPixel(a)), (f.y = this.lineCoordinates.y1);
			if ("left" === this._position || "right" === this._position)
				(f.y = this.convertValueToPixel(a)), (f.x = this.lineCoordinates.x2);
			return f;
		};
		D.prototype.convertPixelToValue = function (a) {
			if ("undefined" === typeof a) return null;
			var f = 0,
				c = 0,
				b,
				f = !0,
				e = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [],
				c =
					"number" === typeof a
						? a
						: "left" === this._position || "right" === this._position
						? a.y
						: a.x;
			if (this.logarithmic) {
				a = b = Math.pow(
					this.logarithmBase,
					(c - this.conversionParameters.reference) /
						this.conversionParameters.pixelPerUnit
				);
				if (
					(c <= this.conversionParameters.reference ===
						("left" === this._position || "right" === this._position)) !==
					this.reversed
				)
					for (c = 0; c < e.length; c++) {
						if (!(e[c].endValue < this.conversionParameters.minimum))
							if (f)
								if (e[c].startValue < this.conversionParameters.minimum) {
									if (
										1 < e[c].size &&
										this.conversionParameters.minimum *
											Math.pow(
												e[c].endValue / e[c].startValue,
												Math.log(b) / Math.log(e[c].size)
											) <
											e[c].endValue
									) {
										a = Math.pow(
											e[c].endValue / e[c].startValue,
											Math.log(b) / Math.log(e[c].size)
										);
										break;
									} else
										(a *=
											e[c].endValue /
											this.conversionParameters.minimum /
											Math.pow(
												e[c].size,
												Math.log(
													e[c].endValue / this.conversionParameters.minimum
												) / Math.log(e[c].endValue / e[c].startValue)
											)),
											(b /= Math.pow(
												e[c].size,
												Math.log(
													e[c].endValue / this.conversionParameters.minimum
												) / Math.log(e[c].endValue / e[c].startValue)
											));
									f = !1;
								} else if (
									b >
									e[c].startValue / this.conversionParameters.minimum
								) {
									b /= e[c].startValue / this.conversionParameters.minimum;
									if (b < e[c].size) {
										a *=
											Math.pow(
												e[c].endValue / e[c].startValue,
												1 === e[c].size ? 1 : Math.log(b) / Math.log(e[c].size)
											) / b;
										break;
									} else a *= e[c].endValue / e[c].startValue / e[c].size;
									b /= e[c].size;
									f = !1;
								} else break;
							else if (b > e[c].startValue / e[c - 1].endValue) {
								b /= e[c].startValue / e[c - 1].endValue;
								if (b < e[c].size) {
									a *=
										Math.pow(
											e[c].endValue / e[c].startValue,
											1 === e[c].size ? 1 : Math.log(b) / Math.log(e[c].size)
										) / b;
									break;
								} else a *= e[c].endValue / e[c].startValue / e[c].size;
								b /= e[c].size;
							} else break;
					}
				else
					for (c = e.length - 1; 0 <= c; c--)
						if (!(e[c].startValue > this.conversionParameters.minimum))
							if (f)
								if (e[c].endValue > this.conversionParameters.minimum) {
									if (
										1 < e[c].size &&
										this.conversionParameters.minimum *
											Math.pow(
												e[c].endValue / e[c].startValue,
												Math.log(b) / Math.log(e[c].size)
											) >
											e[c].startValue
									) {
										a = Math.pow(
											e[c].endValue / e[c].startValue,
											Math.log(b) / Math.log(e[c].size)
										);
										break;
									} else
										(a *=
											(e[c].startValue / this.conversionParameters.minimum) *
											Math.pow(
												e[c].size,
												Math.log(
													e[c].startValue / this.conversionParameters.minimum
												) / Math.log(e[c].endValue / e[c].startValue)
											) *
											b),
											(b *= Math.pow(
												e[c].size,
												Math.log(
													this.conversionParameters.minimum / e[c].startValue
												) / Math.log(e[c].endValue / e[c].startValue)
											));
									f = !1;
								} else if (
									b <
									e[c].endValue / this.conversionParameters.minimum
								) {
									b /= e[c].endValue / this.conversionParameters.minimum;
									if (b > 1 / e[c].size) {
										a *=
											Math.pow(
												e[c].endValue / e[c].startValue,
												1 >= e[c].size ? 1 : Math.log(b) / Math.log(e[c].size)
											) * b;
										break;
									} else a /= e[c].endValue / e[c].startValue / e[c].size;
									b *= e[c].size;
									f = !1;
								} else break;
							else if (b < e[c].endValue / e[c + 1].startValue) {
								b /= e[c].endValue / e[c + 1].startValue;
								if (b > 1 / e[c].size) {
									a *=
										Math.pow(
											e[c].endValue / e[c].startValue,
											1 >= e[c].size ? 1 : Math.log(b) / Math.log(e[c].size)
										) * b;
									break;
								} else a /= e[c].endValue / e[c].startValue / e[c].size;
								b *= e[c].size;
							} else break;
				f = a * this.viewportMinimum;
			} else {
				a = b =
					(c - this.conversionParameters.reference) /
					this.conversionParameters.pixelPerUnit;
				if (
					(c <= this.conversionParameters.reference ===
						("left" === this._position || "right" === this._position)) !==
					this.reversed
				)
					for (c = 0; c < e.length; c++) {
						if (!(e[c].endValue < this.conversionParameters.minimum))
							if (f)
								if (e[c].startValue < this.conversionParameters.minimum) {
									if (
										e[c].size &&
										this.conversionParameters.minimum +
											(b * (e[c].endValue - e[c].startValue)) / e[c].size <
											e[c].endValue
									) {
										a =
											0 >= e[c].size
												? 0
												: (b * (e[c].endValue - e[c].startValue)) / e[c].size;
										break;
									} else
										(a +=
											e[c].endValue -
											this.conversionParameters.minimum -
											(e[c].size *
												(e[c].endValue - this.conversionParameters.minimum)) /
												(e[c].endValue - e[c].startValue)),
											(b -=
												(e[c].size *
													(e[c].endValue - this.conversionParameters.minimum)) /
												(e[c].endValue - e[c].startValue));
									f = !1;
								} else if (
									b >
									e[c].startValue - this.conversionParameters.minimum
								) {
									b -= e[c].startValue - this.conversionParameters.minimum;
									if (b < e[c].size) {
										a +=
											(e[c].endValue - e[c].startValue) *
												(0 === e[c].size ? 1 : b / e[c].size) -
											b;
										break;
									} else a += e[c].endValue - e[c].startValue - e[c].size;
									b -= e[c].size;
									f = !1;
								} else break;
							else if (b > e[c].startValue - e[c - 1].endValue) {
								b -= e[c].startValue - e[c - 1].endValue;
								if (b < e[c].size) {
									a +=
										(e[c].endValue - e[c].startValue) *
											(0 === e[c].size ? 1 : b / e[c].size) -
										b;
									break;
								} else a += e[c].endValue - e[c].startValue - e[c].size;
								b -= e[c].size;
							} else break;
					}
				else
					for (c = e.length - 1; 0 <= c; c--)
						if (!(e[c].startValue > this.conversionParameters.minimum))
							if (f)
								if (e[c].endValue > this.conversionParameters.minimum)
									if (
										e[c].size &&
										this.conversionParameters.minimum +
											(b * (e[c].endValue - e[c].startValue)) / e[c].size >
											e[c].startValue
									) {
										a =
											0 >= e[c].size
												? 0
												: (b * (e[c].endValue - e[c].startValue)) / e[c].size;
										break;
									} else
										(a +=
											e[c].startValue -
											this.conversionParameters.minimum +
											(e[c].size *
												(this.conversionParameters.minimum - e[c].startValue)) /
												(e[c].endValue - e[c].startValue)),
											(b +=
												(e[c].size *
													(this.conversionParameters.minimum -
														e[c].startValue)) /
												(e[c].endValue - e[c].startValue)),
											(f = !1);
								else if (
									b <
									e[c].endValue - this.conversionParameters.minimum
								) {
									b -= e[c].endValue - this.conversionParameters.minimum;
									if (b > -1 * e[c].size) {
										a +=
											(e[c].endValue - e[c].startValue) *
												(0 === e[c].size ? 1 : b / e[c].size) +
											b;
										break;
									} else a -= e[c].endValue - e[c].startValue - e[c].size;
									b += e[c].size;
									f = !1;
								} else break;
							else if (b < e[c].endValue - e[c + 1].startValue) {
								b -= e[c].endValue - e[c + 1].startValue;
								if (b > -1 * e[c].size) {
									a +=
										(e[c].endValue - e[c].startValue) *
											(0 === e[c].size ? 1 : b / e[c].size) +
										b;
									break;
								} else a -= e[c].endValue - e[c].startValue - e[c].size;
								b += e[c].size;
							} else break;
				f = this.conversionParameters.minimum + a;
			}
			return f;
		};
		D.prototype.convertValueToPixel = function (a) {
			a = this.getApparentDifference(this.conversionParameters.minimum, a, a);
			return this.logarithmic
				? (this.conversionParameters.reference +
						(this.conversionParameters.pixelPerUnit *
							Math.log(a / this.conversionParameters.minimum)) /
							this.conversionParameters.lnLogarithmBase +
						0.5) <<
						0
				: "axisX" === this.type
				? (this.conversionParameters.reference +
						this.conversionParameters.pixelPerUnit *
							(a - this.conversionParameters.minimum) +
						0.5) <<
				  0
				: this.conversionParameters.reference +
				  this.conversionParameters.pixelPerUnit *
						(a - this.conversionParameters.minimum) +
				  0.5;
		};
		D.prototype.getApparentDifference = function (a, f, c, b) {
			var e = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [];
			if (this.logarithmic) {
				c = g(c) ? f / a : c;
				for (var d = 0; d < e.length && !(f < e[d].startValue); d++)
					a > e[d].endValue ||
						(a <= e[d].startValue && f >= e[d].endValue
							? (c = (c / e[d].endValue) * e[d].startValue * e[d].size)
							: a >= e[d].startValue && f >= e[d].endValue
							? (c =
									(c / e[d].endValue) *
									a *
									Math.pow(
										e[d].size,
										Math.log(e[d].endValue / a) /
											Math.log(e[d].endValue / e[d].startValue)
									))
							: a <= e[d].startValue && f <= e[d].endValue
							? (c =
									(c / f) *
									e[d].startValue *
									Math.pow(
										e[d].size,
										Math.log(f / e[d].startValue) /
											Math.log(e[d].endValue / e[d].startValue)
									))
							: !b &&
							  a > e[d].startValue &&
							  f < e[d].endValue &&
							  (c =
									a *
									Math.pow(
										e[d].size,
										Math.log(f / a) / Math.log(e[d].endValue / e[d].startValue)
									)));
			} else
				for (
					c = g(c) ? Math.abs(f - a) : c, d = 0;
					d < e.length && !(f < e[d].startValue);
					d++
				)
					a > e[d].endValue ||
						(a <= e[d].startValue && f >= e[d].endValue
							? (c = c - e[d].endValue + e[d].startValue + e[d].size)
							: a > e[d].startValue && f >= e[d].endValue
							? (c =
									c -
									e[d].endValue +
									a +
									(e[d].size * (e[d].endValue - a)) /
										(e[d].endValue - e[d].startValue))
							: a <= e[d].startValue && f < e[d].endValue
							? (c =
									c -
									f +
									e[d].startValue +
									(e[d].size * (f - e[d].startValue)) /
										(e[d].endValue - e[d].startValue))
							: !b &&
							  a > e[d].startValue &&
							  f < e[d].endValue &&
							  (c =
									a +
									(e[d].size * (f - a)) / (e[d].endValue - e[d].startValue)));
			return c;
		};
		D.prototype.setViewPortRange = function (a, f) {
			this.sessionVariables.newViewportMinimum = this.viewportMinimum =
				Math.min(a, f);
			this.sessionVariables.newViewportMaximum = this.viewportMaximum =
				Math.max(a, f);
		};
		D.prototype.getXValueAt = function (a) {
			if (!a) return null;
			var f = null;
			"left" === this._position
				? (f = this.convertPixelToValue(a.y))
				: "bottom" === this._position && (f = this.convertPixelToValue(a.x));
			return f;
		};
		D.prototype.calculateValueToPixelConversionParameters = function (a) {
			a = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [];
			var f = { pixelPerUnit: null, minimum: null, reference: null },
				c = this.lineCoordinates.width,
				b = this.lineCoordinates.height,
				c = "bottom" === this._position || "top" === this._position ? c : b,
				b = Math.abs(this.range);
			if (this.logarithmic)
				for (
					var e = 0;
					e < a.length && !(this.viewportMaximum < a[e].startValue);
					e++
				)
					this.viewportMinimum > a[e].endValue ||
						(this.viewportMinimum >= a[e].startValue &&
						this.viewportMaximum <= a[e].endValue
							? (c = 0)
							: this.viewportMinimum <= a[e].startValue &&
							  this.viewportMaximum >= a[e].endValue
							? ((b = (b / a[e].endValue) * a[e].startValue),
							  (c =
									0 < a[e].spacing.toString().indexOf("%")
										? c * (1 - parseFloat(a[e].spacing) / 100)
										: c - Math.min(a[e].spacing, 0.1 * c)))
							: this.viewportMinimum > a[e].startValue &&
							  this.viewportMaximum >= a[e].endValue
							? ((b = (b / a[e].endValue) * this.viewportMinimum),
							  (c =
									0 < a[e].spacing.toString().indexOf("%")
										? c *
										  (1 -
												((parseFloat(a[e].spacing) / 100) *
													Math.log(a[e].endValue / this.viewportMinimum)) /
													Math.log(a[e].endValue / a[e].startValue))
										: c -
										  (Math.min(a[e].spacing, 0.1 * c) *
												Math.log(a[e].endValue / this.viewportMinimum)) /
												Math.log(a[e].endValue / a[e].startValue)))
							: this.viewportMinimum <= a[e].startValue &&
							  this.viewportMaximum < a[e].endValue &&
							  ((b = (b / this.viewportMaximum) * a[e].startValue),
							  (c =
									0 < a[e].spacing.toString().indexOf("%")
										? c *
										  (1 -
												((parseFloat(a[e].spacing) / 100) *
													Math.log(this.viewportMaximum / a[e].startValue)) /
													Math.log(a[e].endValue / a[e].startValue))
										: c -
										  (Math.min(a[e].spacing, 0.1 * c) *
												Math.log(this.viewportMaximum / a[e].startValue)) /
												Math.log(a[e].endValue / a[e].startValue))));
			else
				for (
					e = 0;
					e < a.length && !(this.viewportMaximum < a[e].startValue);
					e++
				)
					this.viewportMinimum > a[e].endValue ||
						(this.viewportMinimum >= a[e].startValue &&
						this.viewportMaximum <= a[e].endValue
							? (c = 0)
							: this.viewportMinimum <= a[e].startValue &&
							  this.viewportMaximum >= a[e].endValue
							? ((b = b - a[e].endValue + a[e].startValue),
							  (c =
									0 < a[e].spacing.toString().indexOf("%")
										? c * (1 - parseFloat(a[e].spacing) / 100)
										: c - Math.min(a[e].spacing, 0.1 * c)))
							: this.viewportMinimum > a[e].startValue &&
							  this.viewportMaximum >= a[e].endValue
							? ((b = b - a[e].endValue + this.viewportMinimum),
							  (c =
									0 < a[e].spacing.toString().indexOf("%")
										? c *
										  (1 -
												((parseFloat(a[e].spacing) / 100) *
													(a[e].endValue - this.viewportMinimum)) /
													(a[e].endValue - a[e].startValue))
										: c -
										  (Math.min(a[e].spacing, 0.1 * c) *
												(a[e].endValue - this.viewportMinimum)) /
												(a[e].endValue - a[e].startValue)))
							: this.viewportMinimum <= a[e].startValue &&
							  this.viewportMaximum < a[e].endValue &&
							  ((b = b - this.viewportMaximum + a[e].startValue),
							  (c =
									0 < a[e].spacing.toString().indexOf("%")
										? c *
										  (1 -
												((parseFloat(a[e].spacing) / 100) *
													(this.viewportMaximum - a[e].startValue)) /
													(a[e].endValue - a[e].startValue))
										: c -
										  (Math.min(a[e].spacing, 0.1 * c) *
												(this.viewportMaximum - a[e].startValue)) /
												(a[e].endValue - a[e].startValue))));
			f.minimum = this.viewportMinimum;
			f.maximum = this.viewportMaximum;
			f.range = b;
			if ("bottom" === this._position || "top" === this._position)
				this.logarithmic
					? ((f.lnLogarithmBase = Math.log(this.logarithmBase)),
					  (f.pixelPerUnit =
							((this.reversed ? -1 : 1) * c * f.lnLogarithmBase) /
							Math.log(Math.abs(b))))
					: (f.pixelPerUnit = ((this.reversed ? -1 : 1) * c) / Math.abs(b)),
					(f.reference = this.reversed
						? this.lineCoordinates.x2
						: this.lineCoordinates.x1);
			if ("left" === this._position || "right" === this._position)
				this.logarithmic
					? ((f.lnLogarithmBase = Math.log(this.logarithmBase)),
					  (f.pixelPerUnit =
							((this.reversed ? 1 : -1) * c * f.lnLogarithmBase) /
							Math.log(Math.abs(b))))
					: (f.pixelPerUnit = ((this.reversed ? 1 : -1) * c) / Math.abs(b)),
					(f.reference = this.reversed
						? this.lineCoordinates.y1
						: this.lineCoordinates.y2);
			this.conversionParameters = f;
		};
		D.prototype.calculateAxisParameters = function () {
			if (this.logarithmic) this.calculateLogarithmicAxisParameters();
			else {
				var a = this.chart.layoutManager.getFreeSpace(),
					f = !1,
					c = !1;
				"bottom" === this._position || "top" === this._position
					? ((this.maxWidth = a.width), (this.maxHeight = a.height))
					: ((this.maxWidth = a.height), (this.maxHeight = a.width));
				var a =
						"axisX" === this.type
							? "xySwapped" === this.chart.plotInfo.axisPlacement
								? 62
								: 70
							: "xySwapped" === this.chart.plotInfo.axisPlacement
							? 50
							: 40,
					b = 4;
				"axisX" === this.type && (b = 600 > this.maxWidth ? 8 : 6);
				var a = Math.max(b, Math.floor(this.maxWidth / a)),
					e,
					d,
					q,
					b = 0;
				!g(this.options.viewportMinimum) &&
					!g(this.options.viewportMaximum) &&
					this.options.viewportMinimum >= this.options.viewportMaximum &&
					(this.viewportMinimum = this.viewportMaximum = null);
				if (
					g(this.options.viewportMinimum) &&
					!g(this.sessionVariables.newViewportMinimum) &&
					!isNaN(this.sessionVariables.newViewportMinimum)
				)
					this.viewportMinimum = this.sessionVariables.newViewportMinimum;
				else if (null === this.viewportMinimum || isNaN(this.viewportMinimum))
					this.viewportMinimum = this.minimum;
				if (
					g(this.options.viewportMaximum) &&
					!g(this.sessionVariables.newViewportMaximum) &&
					!isNaN(this.sessionVariables.newViewportMaximum)
				)
					this.viewportMaximum = this.sessionVariables.newViewportMaximum;
				else if (null === this.viewportMaximum || isNaN(this.viewportMaximum))
					this.viewportMaximum = this.maximum;
				if (this.scaleBreaks)
					for (b = 0; b < this.scaleBreaks._appliedBreaks.length; b++)
						if (
							((!g(this.sessionVariables.newViewportMinimum) &&
								this.sessionVariables.newViewportMinimum >=
									this.scaleBreaks._appliedBreaks[b].startValue) ||
								(!g(this.options.minimum) &&
									this.options.minimum >=
										this.scaleBreaks._appliedBreaks[b].startValue) ||
								(!g(this.options.viewportMinimum) &&
									this.viewportMinimum >=
										this.scaleBreaks._appliedBreaks[b].startValue)) &&
							((!g(this.sessionVariables.newViewportMaximum) &&
								this.sessionVariables.newViewportMaximum <=
									this.scaleBreaks._appliedBreaks[b].endValue) ||
								(!g(this.options.maximum) &&
									this.options.maximum <=
										this.scaleBreaks._appliedBreaks[b].endValue) ||
								(!g(this.options.viewportMaximum) &&
									this.viewportMaximum <=
										this.scaleBreaks._appliedBreaks[b].endValue))
						) {
							this.scaleBreaks._appliedBreaks.splice(b, 1);
							break;
						}
				if ("axisX" === this.type) {
					if (this.dataSeries && 0 < this.dataSeries.length)
						for (e = 0; e < this.dataSeries.length; e++)
							"dateTime" === this.dataSeries[e].xValueType && (c = !0);
					e =
						null !== this.viewportMinimum
							? this.viewportMinimum
							: this.dataInfo.viewPortMin;
					d =
						null !== this.viewportMaximum
							? this.viewportMaximum
							: this.dataInfo.viewPortMax;
					0 === d - e &&
						((b =
							"undefined" === typeof this.options.interval
								? 0.4
								: this.options.interval),
						(d += b),
						(e -= b));
					Infinity !== this.dataInfo.minDiff
						? (q = this.dataInfo.minDiff)
						: 1 < d - e
						? (q = 0.5 * Math.abs(d - e))
						: ((q = 1), c && (f = !0));
				} else
					"axisY" === this.type &&
						((e =
							null !== this.viewportMinimum
								? this.viewportMinimum
								: this.dataInfo.viewPortMin),
						(d =
							null !== this.viewportMaximum
								? this.viewportMaximum
								: this.dataInfo.viewPortMax),
						isFinite(e) || isFinite(d)
							? isFinite(e)
								? isFinite(d) || (d = e)
								: (e = d)
							: ((d =
									"undefined" === typeof this.options.interval
										? -Infinity
										: this.options.interval),
							  (e =
									"undefined" !== typeof this.options.interval ||
									isFinite(this.dataInfo.minDiff)
										? 0
										: Infinity)),
						0 === e && 0 === d
							? ((d += 9), (e = 0))
							: 0 === d - e
							? ((b = Math.min(Math.abs(0.01 * Math.abs(d)), 5)),
							  (d += b),
							  (e -= b))
							: e > d
							? ((b = Math.min(
									0.01 * Math.abs(this.getApparentDifference(d, e, null, !0)),
									5
							  )),
							  0 <= d ? (e = d - b) : (d = isFinite(e) ? e + b : 0))
							: ((b = Math.min(
									0.01 * Math.abs(this.getApparentDifference(e, d, null, !0)),
									0.05
							  )),
							  0 !== d && (d += b),
							  0 !== e && (e -= b)),
						(q =
							Infinity !== this.dataInfo.minDiff
								? this.dataInfo.minDiff
								: 1 < d - e
								? 0.5 * Math.abs(d - e)
								: 1),
						this.includeZero &&
							(null === this.viewportMinimum || isNaN(this.viewportMinimum)) &&
							0 < e &&
							(e = 0),
						this.includeZero &&
							(null === this.viewportMaximum || isNaN(this.viewportMaximum)) &&
							0 > d &&
							(d = 0));
				b = this.getApparentDifference(
					isNaN(this.viewportMinimum) || null === this.viewportMinimum
						? e
						: this.viewportMinimum,
					isNaN(this.viewportMaximum) || null === this.viewportMaximum
						? d
						: this.viewportMaximum,
					null,
					!0
				);
				if ("axisX" === this.type && c) {
					this.intervalType ||
						(b / 1 <= a
							? ((this.interval = 1), (this.intervalType = "millisecond"))
							: b / 2 <= a
							? ((this.interval = 2), (this.intervalType = "millisecond"))
							: b / 5 <= a
							? ((this.interval = 5), (this.intervalType = "millisecond"))
							: b / 10 <= a
							? ((this.interval = 10), (this.intervalType = "millisecond"))
							: b / 20 <= a
							? ((this.interval = 20), (this.intervalType = "millisecond"))
							: b / 50 <= a
							? ((this.interval = 50), (this.intervalType = "millisecond"))
							: b / 100 <= a
							? ((this.interval = 100), (this.intervalType = "millisecond"))
							: b / 200 <= a
							? ((this.interval = 200), (this.intervalType = "millisecond"))
							: b / 250 <= a
							? ((this.interval = 250), (this.intervalType = "millisecond"))
							: b / 300 <= a
							? ((this.interval = 300), (this.intervalType = "millisecond"))
							: b / 400 <= a
							? ((this.interval = 400), (this.intervalType = "millisecond"))
							: b / 500 <= a
							? ((this.interval = 500), (this.intervalType = "millisecond"))
							: b / (1 * Z.secondDuration) <= a
							? ((this.interval = 1), (this.intervalType = "second"))
							: b / (2 * Z.secondDuration) <= a
							? ((this.interval = 2), (this.intervalType = "second"))
							: b / (5 * Z.secondDuration) <= a
							? ((this.interval = 5), (this.intervalType = "second"))
							: b / (10 * Z.secondDuration) <= a
							? ((this.interval = 10), (this.intervalType = "second"))
							: b / (15 * Z.secondDuration) <= a
							? ((this.interval = 15), (this.intervalType = "second"))
							: b / (20 * Z.secondDuration) <= a
							? ((this.interval = 20), (this.intervalType = "second"))
							: b / (30 * Z.secondDuration) <= a
							? ((this.interval = 30), (this.intervalType = "second"))
							: b / (1 * Z.minuteDuration) <= a
							? ((this.interval = 1), (this.intervalType = "minute"))
							: b / (2 * Z.minuteDuration) <= a
							? ((this.interval = 2), (this.intervalType = "minute"))
							: b / (5 * Z.minuteDuration) <= a
							? ((this.interval = 5), (this.intervalType = "minute"))
							: b / (10 * Z.minuteDuration) <= a
							? ((this.interval = 10), (this.intervalType = "minute"))
							: b / (15 * Z.minuteDuration) <= a
							? ((this.interval = 15), (this.intervalType = "minute"))
							: b / (20 * Z.minuteDuration) <= a
							? ((this.interval = 20), (this.intervalType = "minute"))
							: b / (30 * Z.minuteDuration) <= a
							? ((this.interval = 30), (this.intervalType = "minute"))
							: b / (1 * Z.hourDuration) <= a
							? ((this.interval = 1), (this.intervalType = "hour"))
							: b / (2 * Z.hourDuration) <= a
							? ((this.interval = 2), (this.intervalType = "hour"))
							: b / (3 * Z.hourDuration) <= a
							? ((this.interval = 3), (this.intervalType = "hour"))
							: b / (6 * Z.hourDuration) <= a
							? ((this.interval = 6), (this.intervalType = "hour"))
							: b / (1 * Z.dayDuration) <= a
							? ((this.interval = 1), (this.intervalType = "day"))
							: b / (2 * Z.dayDuration) <= a
							? ((this.interval = 2), (this.intervalType = "day"))
							: b / (4 * Z.dayDuration) <= a
							? ((this.interval = 4), (this.intervalType = "day"))
							: b / (1 * Z.weekDuration) <= a
							? ((this.interval = 1), (this.intervalType = "week"))
							: b / (2 * Z.weekDuration) <= a
							? ((this.interval = 2), (this.intervalType = "week"))
							: b / (3 * Z.weekDuration) <= a
							? ((this.interval = 3), (this.intervalType = "week"))
							: b / (1 * Z.monthDuration) <= a
							? ((this.interval = 1), (this.intervalType = "month"))
							: b / (2 * Z.monthDuration) <= a
							? ((this.interval = 2), (this.intervalType = "month"))
							: b / (3 * Z.monthDuration) <= a
							? ((this.interval = 3), (this.intervalType = "month"))
							: b / (6 * Z.monthDuration) <= a
							? ((this.interval = 6), (this.intervalType = "month"))
							: ((this.interval =
									b / (1 * Z.yearDuration) <= a
										? 1
										: b / (2 * Z.yearDuration) <= a
										? 2
										: b / (4 * Z.yearDuration) <= a
										? 4
										: Math.floor(
												D.getNiceNumber(b / (a - 1), !0) / Z.yearDuration
										  )),
							  (this.intervalType = "year")));
					if (null === this.viewportMinimum || isNaN(this.viewportMinimum))
						this.viewportMinimum = e - q / 2;
					if (null === this.viewportMaximum || isNaN(this.viewportMaximum))
						this.viewportMaximum = d + q / 2;
					f
						? (this.autoValueFormatString = "MMM DD YYYY HH:mm")
						: "year" === this.intervalType
						? (this.autoValueFormatString = "YYYY")
						: "month" === this.intervalType
						? (this.autoValueFormatString = "MMM YYYY")
						: "week" === this.intervalType
						? (this.autoValueFormatString = "MMM DD YYYY")
						: "day" === this.intervalType
						? (this.autoValueFormatString = "MMM DD YYYY")
						: "hour" === this.intervalType
						? (this.autoValueFormatString = "hh:mm TT")
						: "minute" === this.intervalType
						? (this.autoValueFormatString = "hh:mm TT")
						: "second" === this.intervalType
						? (this.autoValueFormatString = "hh:mm:ss TT")
						: "millisecond" === this.intervalType &&
						  (this.autoValueFormatString = "fff'ms'");
					this.valueFormatString ||
						(this.valueFormatString = this.autoValueFormatString);
				} else {
					this.intervalType = "number";
					b = D.getNiceNumber(b, !1);
					this.interval =
						this.options && 0 < this.options.interval
							? this.options.interval
							: D.getNiceNumber(b / (a - 1), !0);
					if (null === this.viewportMinimum || isNaN(this.viewportMinimum))
						this.viewportMinimum =
							"axisX" === this.type
								? e - q / 2
								: Math.floor(e / this.interval) * this.interval;
					if (null === this.viewportMaximum || isNaN(this.viewportMaximum))
						this.viewportMaximum =
							"axisX" === this.type
								? d + q / 2
								: Math.ceil(d / this.interval) * this.interval;
					0 === this.viewportMaximum &&
						0 === this.viewportMinimum &&
						(0 === this.options.viewportMinimum
							? (this.viewportMaximum += 10)
							: 0 === this.options.viewportMaximum &&
							  (this.viewportMinimum -= 10),
						this.options &&
							"undefined" === typeof this.options.interval &&
							(this.interval = D.getNiceNumber(
								(this.viewportMaximum - this.viewportMinimum) / (a - 1),
								!0
							)));
				}
				if (null === this.minimum || null === this.maximum)
					if (
						("axisX" === this.type
							? ((e = null !== this.minimum ? this.minimum : this.dataInfo.min),
							  (d = null !== this.maximum ? this.maximum : this.dataInfo.max),
							  0 === d - e &&
									((b =
										"undefined" === typeof this.options.interval
											? 0.4
											: this.options.interval),
									(d += b),
									(e -= b)),
							  (q =
									Infinity !== this.dataInfo.minDiff
										? this.dataInfo.minDiff
										: 1 < d - e
										? 0.5 * Math.abs(d - e)
										: 1))
							: "axisY" === this.type &&
							  ((e = null !== this.minimum ? this.minimum : this.dataInfo.min),
							  (d = null !== this.maximum ? this.maximum : this.dataInfo.max),
							  isFinite(e) || isFinite(d)
									? 0 === e && 0 === d
										? ((d += 9), (e = 0))
										: 0 === d - e
										? ((b = Math.min(Math.abs(0.01 * Math.abs(d)), 5)),
										  (d += b),
										  (e -= b))
										: e > d
										? ((b = Math.min(
												0.01 *
													Math.abs(this.getApparentDifference(d, e, null, !0)),
												5
										  )),
										  0 <= d ? (e = d - b) : (d = isFinite(e) ? e + b : 0))
										: ((b = Math.min(
												0.01 *
													Math.abs(this.getApparentDifference(e, d, null, !0)),
												0.05
										  )),
										  0 !== d && (d += b),
										  0 !== e && (e -= b))
									: ((d =
											"undefined" === typeof this.options.interval
												? -Infinity
												: this.options.interval),
									  (e =
											"undefined" !== typeof this.options.interval ||
											isFinite(this.dataInfo.minDiff)
												? 0
												: Infinity)),
							  (q =
									Infinity !== this.dataInfo.minDiff
										? this.dataInfo.minDiff
										: 1 < d - e
										? 0.5 * Math.abs(d - e)
										: 1),
							  this.includeZero &&
									(null === this.minimum || isNaN(this.minimum)) &&
									0 < e &&
									(e = 0),
							  this.includeZero &&
									(null === this.maximum || isNaN(this.maximum)) &&
									0 > d &&
									(d = 0)),
						Math.abs(this.getApparentDifference(e, d, null, !0)),
						"axisX" === this.type && c)
					) {
						this.valueType = "dateTime";
						if (null === this.minimum || isNaN(this.minimum))
							(this.minimum = e - q / 2),
								(this.minimum = Math.min(
									this.minimum,
									null === this.sessionVariables.viewportMinimum ||
										isNaN(this.sessionVariables.viewportMinimum)
										? Infinity
										: this.sessionVariables.viewportMinimum
								));
						if (null === this.maximum || isNaN(this.maximum))
							(this.maximum = d + q / 2),
								(this.maximum = Math.max(
									this.maximum,
									null === this.sessionVariables.viewportMaximum ||
										isNaN(this.sessionVariables.viewportMaximum)
										? -Infinity
										: this.sessionVariables.viewportMaximum
								));
					} else
						(this.intervalType = this.valueType = "number"),
							null === this.minimum &&
								((this.minimum =
									"axisX" === this.type
										? e - q / 2
										: Math.floor(e / this.interval) * this.interval),
								(this.minimum = Math.min(
									this.minimum,
									null === this.sessionVariables.viewportMinimum ||
										isNaN(this.sessionVariables.viewportMinimum)
										? Infinity
										: this.sessionVariables.viewportMinimum
								))),
							null === this.maximum &&
								((this.maximum =
									"axisX" === this.type
										? d + q / 2
										: Math.ceil(d / this.interval) * this.interval),
								(this.maximum = Math.max(
									this.maximum,
									null === this.sessionVariables.viewportMaximum ||
										isNaN(this.sessionVariables.viewportMaximum)
										? -Infinity
										: this.sessionVariables.viewportMaximum
								))),
							0 === this.maximum &&
								0 === this.minimum &&
								(0 === this.options.minimum
									? (this.maximum += 10)
									: 0 === this.options.maximum && (this.minimum -= 10));
				g(this.sessionVariables.newViewportMinimum) &&
					(this.viewportMinimum = Math.max(this.viewportMinimum, this.minimum));
				g(this.sessionVariables.newViewportMaximum) &&
					(this.viewportMaximum = Math.min(this.viewportMaximum, this.maximum));
				this.range = this.viewportMaximum - this.viewportMinimum;
				this.intervalStartPosition =
					"axisX" === this.type && c
						? this.getLabelStartPoint(
								new Date(this.viewportMinimum),
								this.intervalType,
								this.interval
						  )
						: Math.floor(
								(this.viewportMinimum + 0.2 * this.interval) / this.interval
						  ) * this.interval;
				this.valueFormatString ||
					(this.valueFormatString = D.generateValueFormatString(this.range, 2));
			}
		};
		D.prototype.calculateLogarithmicAxisParameters = function () {
			var a = this.chart.layoutManager.getFreeSpace(),
				f = Math.log(this.logarithmBase),
				c;
			"bottom" === this._position || "top" === this._position
				? ((this.maxWidth = a.width), (this.maxHeight = a.height))
				: ((this.maxWidth = a.height), (this.maxHeight = a.width));
			var a =
					"axisX" === this.type
						? 500 > this.maxWidth
							? 7
							: Math.max(7, Math.floor(this.maxWidth / 100))
						: Math.max(Math.floor(this.maxWidth / 50), 3),
				b,
				d,
				h,
				q;
			q = 1;
			if (null === this.viewportMinimum || isNaN(this.viewportMinimum))
				this.viewportMinimum = this.minimum;
			if (null === this.viewportMaximum || isNaN(this.viewportMaximum))
				this.viewportMaximum = this.maximum;
			if (this.scaleBreaks)
				for (q = 0; q < this.scaleBreaks._appliedBreaks.length; q++)
					if (
						((!g(this.sessionVariables.newViewportMinimum) &&
							this.sessionVariables.newViewportMinimum >=
								this.scaleBreaks._appliedBreaks[q].startValue) ||
							(!g(this.options.minimum) &&
								this.options.minimum >=
									this.scaleBreaks._appliedBreaks[q].startValue) ||
							(!g(this.options.viewportMinimum) &&
								this.viewportMinimum >=
									this.scaleBreaks._appliedBreaks[q].startValue)) &&
						((!g(this.sessionVariables.newViewportMaximum) &&
							this.sessionVariables.newViewportMaximum <=
								this.scaleBreaks._appliedBreaks[q].endValue) ||
							(!g(this.options.maximum) &&
								this.options.maximum <=
									this.scaleBreaks._appliedBreaks[q].endValue) ||
							(!g(this.options.viewportMaximum) &&
								this.viewportMaximum <=
									this.scaleBreaks._appliedBreaks[q].endValue))
					) {
						this.scaleBreaks._appliedBreaks.splice(q, 1);
						break;
					}
			"axisX" === this.type
				? ((b =
						null !== this.viewportMinimum
							? this.viewportMinimum
							: this.dataInfo.viewPortMin),
				  (d =
						null !== this.viewportMaximum
							? this.viewportMaximum
							: this.dataInfo.viewPortMax),
				  1 === d / b &&
						((q = Math.pow(
							this.logarithmBase,
							"undefined" === typeof this.options.interval
								? 0.4
								: this.options.interval
						)),
						(d *= q),
						(b /= q)),
				  (h =
						Infinity !== this.dataInfo.minDiff
							? this.dataInfo.minDiff
							: d / b > this.logarithmBase
							? (d / b) * Math.pow(this.logarithmBase, 0.5)
							: this.logarithmBase))
				: "axisY" === this.type &&
				  ((b =
						null !== this.viewportMinimum
							? this.viewportMinimum
							: this.dataInfo.viewPortMin),
				  (d =
						null !== this.viewportMaximum
							? this.viewportMaximum
							: this.dataInfo.viewPortMax),
				  0 >= b && !isFinite(d)
						? ((d =
								"undefined" === typeof this.options.interval
									? 0
									: this.options.interval),
						  (b = 1))
						: 0 >= b
						? (b = d)
						: isFinite(d) || (d = b),
				  1 === b && 1 === d
						? ((d *= this.logarithmBase - 1 / this.logarithmBase), (b = 1))
						: 1 === d / b
						? ((q = Math.min(
								d * Math.pow(this.logarithmBase, 0.01),
								Math.pow(this.logarithmBase, 5)
						  )),
						  (d *= q),
						  (b /= q))
						: b > d
						? ((q = Math.min(
								(b / d) * Math.pow(this.logarithmBase, 0.01),
								Math.pow(this.logarithmBase, 5)
						  )),
						  1 <= d ? (b = d / q) : (d = b * q))
						: ((q = Math.min(
								(d / b) * Math.pow(this.logarithmBase, 0.01),
								Math.pow(this.logarithmBase, 0.04)
						  )),
						  1 !== d && (d *= q),
						  1 !== b && (b /= q)),
				  (h =
						Infinity !== this.dataInfo.minDiff
							? this.dataInfo.minDiff
							: d / b > this.logarithmBase
							? (d / b) * Math.pow(this.logarithmBase, 0.5)
							: this.logarithmBase),
				  this.includeZero &&
						(null === this.viewportMinimum || isNaN(this.viewportMinimum)) &&
						1 < b &&
						(b = 1),
				  this.includeZero &&
						(null === this.viewportMaximum || isNaN(this.viewportMaximum)) &&
						1 > d &&
						(d = 1));
			q =
				(isNaN(this.viewportMaximum) || null === this.viewportMaximum
					? d
					: this.viewportMaximum) /
				(isNaN(this.viewportMinimum) || null === this.viewportMinimum
					? b
					: this.viewportMinimum);
			var l =
				(isNaN(this.viewportMaximum) || null === this.viewportMaximum
					? d
					: this.viewportMaximum) -
				(isNaN(this.viewportMinimum) || null === this.viewportMinimum
					? b
					: this.viewportMinimum);
			this.intervalType = "number";
			q = Math.pow(
				this.logarithmBase,
				D.getNiceNumber(Math.abs(Math.log(q) / f), !1)
			);
			this.options && 0 < this.options.interval
				? (this.interval = this.options.interval)
				: ((this.interval = D.getNiceExponent(Math.log(q) / f / (a - 1), !0)),
				  (c = D.getNiceNumber(l / (a - 1), !0)));
			if (null === this.viewportMinimum || isNaN(this.viewportMinimum))
				this.viewportMinimum =
					"axisX" === this.type
						? b / Math.sqrt(h)
						: Math.pow(
								this.logarithmBase,
								this.interval * Math.floor(Math.log(b) / f / this.interval)
						  );
			if (null === this.viewportMaximum || isNaN(this.viewportMaximum))
				this.viewportMaximum =
					"axisX" === this.type
						? d * Math.sqrt(h)
						: Math.pow(
								this.logarithmBase,
								this.interval * Math.ceil(Math.log(d) / f / this.interval)
						  );
			1 === this.viewportMaximum &&
				1 === this.viewportMinimum &&
				(1 === this.options.viewportMinimum
					? (this.viewportMaximum *=
							this.logarithmBase - 1 / this.logarithmBase)
					: 1 === this.options.viewportMaximum &&
					  (this.viewportMinimum /=
							this.logarithmBase - 1 / this.logarithmBase),
				this.options &&
					"undefined" === typeof this.options.interval &&
					((this.interval = D.getNiceExponent(
						Math.ceil(Math.log(q) / f) / (a - 1)
					)),
					(c = D.getNiceNumber(
						(this.viewportMaximum - this.viewportMinimum) / (a - 1),
						!0
					))));
			if (null === this.minimum || null === this.maximum)
				"axisX" === this.type
					? ((b = null !== this.minimum ? this.minimum : this.dataInfo.min),
					  (d = null !== this.maximum ? this.maximum : this.dataInfo.max),
					  1 === d / b &&
							((q = Math.pow(
								this.logarithmBase,
								"undefined" === typeof this.options.interval
									? 0.4
									: this.options.interval
							)),
							(d *= q),
							(b /= q)),
					  (h =
							Infinity !== this.dataInfo.minDiff
								? this.dataInfo.minDiff
								: d / b > this.logarithmBase
								? (d / b) * Math.pow(this.logarithmBase, 0.5)
								: this.logarithmBase))
					: "axisY" === this.type &&
					  ((b = null !== this.minimum ? this.minimum : this.dataInfo.min),
					  (d = null !== this.maximum ? this.maximum : this.dataInfo.max),
					  isFinite(b) || isFinite(d)
							? 1 === b && 1 === d
								? ((d *= this.logarithmBase), (b /= this.logarithmBase))
								: 1 === d / b
								? ((q = Math.pow(this.logarithmBase, this.interval)),
								  (d *= q),
								  (b /= q))
								: b > d
								? ((q = Math.min(0.01 * (b / d), 5)),
								  1 <= d ? (b = d / q) : (d = b * q))
								: ((q = Math.min(
										(d / b) * Math.pow(this.logarithmBase, 0.01),
										Math.pow(this.logarithmBase, 0.04)
								  )),
								  1 !== d && (d *= q),
								  1 !== b && (b /= q))
							: ((d =
									"undefined" === typeof this.options.interval
										? 0
										: this.options.interval),
							  (b = 1)),
					  (h =
							Infinity !== this.dataInfo.minDiff
								? this.dataInfo.minDiff
								: d / b > this.logarithmBase
								? (d / b) * Math.pow(this.logarithmBase, 0.5)
								: this.logarithmBase),
					  this.includeZero &&
							(null === this.minimum || isNaN(this.minimum)) &&
							1 < b &&
							(b = 1),
					  this.includeZero &&
							(null === this.maximum || isNaN(this.maximum)) &&
							1 > d &&
							(d = 1)),
					(this.intervalType = "number"),
					null === this.minimum &&
						((this.minimum =
							"axisX" === this.type
								? b / Math.sqrt(h)
								: Math.pow(
										this.logarithmBase,
										this.interval * Math.floor(Math.log(b) / f / this.interval)
								  )),
						g(
							null === this.sessionVariables.viewportMinimum ||
								isNaN(this.sessionVariables.viewportMinimum)
								? "undefined" ===
								  typeof this.sessionVariables.newViewportMinimum
									? Infinity
									: this.sessionVariables.newViewportMinimum
								: this.sessionVariables.viewportMinimum
						) ||
							(this.minimum = Math.min(
								this.minimum,
								null === this.sessionVariables.viewportMinimum ||
									isNaN(this.sessionVariables.viewportMinimum)
									? "undefined" ===
									  typeof this.sessionVariables.newViewportMinimum
										? Infinity
										: this.sessionVariables.newViewportMinimum
									: this.sessionVariables.viewportMinimum
							))),
					null === this.maximum &&
						((this.maximum =
							"axisX" === this.type
								? d * Math.sqrt(h)
								: Math.pow(
										this.logarithmBase,
										this.interval * Math.ceil(Math.log(d) / f / this.interval)
								  )),
						g(
							null === this.sessionVariables.viewportMaximum ||
								isNaN(this.sessionVariables.viewportMaximum)
								? "undefined" ===
								  typeof this.sessionVariables.newViewportMaximum
									? 0
									: this.sessionVariables.newViewportMaximum
								: this.sessionVariables.viewportMaximum
						) ||
							(this.maximum = Math.max(
								this.maximum,
								null === this.sessionVariables.viewportMaximum ||
									isNaN(this.sessionVariables.viewportMaximum)
									? "undefined" ===
									  typeof this.sessionVariables.newViewportMaximum
										? 0
										: this.sessionVariables.newViewportMaximum
									: this.sessionVariables.viewportMaximum
							))),
					1 === this.maximum &&
						1 === this.minimum &&
						(1 === this.options.minimum
							? (this.maximum *= this.logarithmBase - 1 / this.logarithmBase)
							: 1 === this.options.maximum &&
							  (this.minimum /= this.logarithmBase - 1 / this.logarithmBase));
			this.viewportMinimum = Math.max(this.viewportMinimum, this.minimum);
			this.viewportMaximum = Math.min(this.viewportMaximum, this.maximum);
			this.viewportMinimum > this.viewportMaximum &&
				((!this.options.viewportMinimum && !this.options.minimum) ||
				this.options.viewportMaximum ||
				this.options.maximum
					? this.options.viewportMinimum ||
					  this.options.minimum ||
					  (!this.options.viewportMaximum && !this.options.maximum) ||
					  (this.viewportMinimum = this.minimum =
							(this.options.viewportMaximum || this.options.maximum) /
							Math.pow(this.logarithmBase, 2 * Math.ceil(this.interval)))
					: (this.viewportMaximum = this.maximum =
							this.options.viewportMinimum || this.options.minimum));
			b = Math.pow(
				this.logarithmBase,
				Math.floor(Math.log(this.viewportMinimum) / (f * this.interval) + 0.2) *
					this.interval
			);
			this.range = this.viewportMaximum / this.viewportMinimum;
			this.noTicks = a;
			if (
				!this.options.interval &&
				this.range <
					Math.pow(
						this.logarithmBase,
						8 > this.viewportMaximum || 3 > a ? 2 : 3
					)
			) {
				for (
					f = Math.floor(this.viewportMinimum / c + 0.5) * c;
					f < this.viewportMinimum;

				)
					f += c;
				this.equidistantInterval = !1;
				this.intervalStartPosition = f;
				this.interval = c;
			} else
				this.options.interval ||
					((c = Math.ceil(this.interval)),
					this.range > this.interval &&
						((this.interval = c),
						(b = Math.pow(
							this.logarithmBase,
							Math.floor(
								Math.log(this.viewportMinimum) / (f * this.interval) + 0.2
							) * this.interval
						)))),
					(this.equidistantInterval = !0),
					(this.intervalStartPosition = b);
			if (
				!this.valueFormatString &&
				((this.valueFormatString = "#,##0.##"), 1 > this.viewportMinimum)
			) {
				f =
					Math.floor(Math.abs(Math.log(this.viewportMinimum) / Math.LN10)) + 2;
				if (isNaN(f) || !isFinite(f)) f = 2;
				if (2 < f) for (q = 0; q < f - 2; q++) this.valueFormatString += "#";
			}
		};
		D.generateValueFormatString = function (a, f) {
			var c = "#,##0.",
				b = f;
			1 > a &&
				((b += Math.floor(Math.abs(Math.log(a) / Math.LN10))),
				isNaN(b) || !isFinite(b)) &&
				(b = f);
			for (var d = 0; d < b; d++) c += "#";
			return c;
		};
		D.getNiceExponent = function (a, f) {
			var c = Math.floor(Math.log(a) / Math.LN10),
				b = a / Math.pow(10, c),
				b = 0 > c ? (1 >= b ? 1 : 5 >= b ? 5 : 10) : Math.max(Math.floor(b), 1);
			return -20 > c
				? Number(b * Math.pow(10, c))
				: Number((b * Math.pow(10, c)).toFixed(20));
		};
		D.getNiceNumber = function (a, f) {
			var c = Math.floor(Math.log(a) / Math.LN10),
				b = a / Math.pow(10, c),
				b = f
					? 1.5 > b
						? 1
						: 3 > b
						? 2
						: 7 > b
						? 5
						: 10
					: 1 >= b
					? 1
					: 2 >= b
					? 2
					: 5 >= b
					? 5
					: 10;
			return -20 > c
				? Number(b * Math.pow(10, c))
				: Number((b * Math.pow(10, c)).toFixed(20));
		};
		D.prototype.getLabelStartPoint = function () {
			var a = Z[this.intervalType + "Duration"] * this.interval,
				a = new Date(Math.floor(this.viewportMinimum / a) * a);
			if ("millisecond" !== this.intervalType)
				if ("second" === this.intervalType)
					0 < a.getMilliseconds() &&
						(a.setSeconds(a.getSeconds() + 1), a.setMilliseconds(0));
				else if ("minute" === this.intervalType) {
					if (0 < a.getSeconds() || 0 < a.getMilliseconds())
						a.setMinutes(a.getMinutes() + 1),
							a.setSeconds(0),
							a.setMilliseconds(0);
				} else if ("hour" === this.intervalType) {
					if (
						0 < a.getMinutes() ||
						0 < a.getSeconds() ||
						0 < a.getMilliseconds()
					)
						a.setHours(a.getHours() + 1),
							a.setMinutes(0),
							a.setSeconds(0),
							a.setMilliseconds(0);
				} else if ("day" === this.intervalType) {
					if (
						0 < a.getHours() ||
						0 < a.getMinutes() ||
						0 < a.getSeconds() ||
						0 < a.getMilliseconds()
					)
						a.setDate(a.getDate() + 1),
							a.setHours(0),
							a.setMinutes(0),
							a.setSeconds(0),
							a.setMilliseconds(0);
				} else if ("week" === this.intervalType) {
					if (
						0 < a.getDay() ||
						0 < a.getHours() ||
						0 < a.getMinutes() ||
						0 < a.getSeconds() ||
						0 < a.getMilliseconds()
					)
						a.setDate(a.getDate() + (7 - a.getDay())),
							a.setHours(0),
							a.setMinutes(0),
							a.setSeconds(0),
							a.setMilliseconds(0);
				} else if ("month" === this.intervalType) {
					if (
						1 < a.getDate() ||
						0 < a.getHours() ||
						0 < a.getMinutes() ||
						0 < a.getSeconds() ||
						0 < a.getMilliseconds()
					)
						a.setMonth(a.getMonth() + 1),
							a.setDate(1),
							a.setHours(0),
							a.setMinutes(0),
							a.setSeconds(0),
							a.setMilliseconds(0);
				} else
					"year" === this.intervalType &&
						(0 < a.getMonth() ||
							1 < a.getDate() ||
							0 < a.getHours() ||
							0 < a.getMinutes() ||
							0 < a.getSeconds() ||
							0 < a.getMilliseconds()) &&
						(a.setFullYear(a.getFullYear() + 1),
						a.setMonth(0),
						a.setDate(1),
						a.setHours(0),
						a.setMinutes(0),
						a.setSeconds(0),
						a.setMilliseconds(0));
			return a;
		};
		ia(O, da);
		ia(W, da);
		W.prototype.createUserOptions = function (a) {
			if ("undefined" !== typeof a || this.options._isPlaceholder) {
				var f = 0;
				this.parent.options._isPlaceholder && this.parent.createUserOptions();
				this.options._isPlaceholder ||
					(Da(this.parent[this.optionsName]),
					(f = this.parent.options[this.optionsName].indexOf(this.options)));
				this.options = "undefined" === typeof a ? {} : a;
				this.parent.options[this.optionsName][f] = this.options;
			}
		};
		W.prototype.render = function (a) {
			if (
				0 !== this.spacing ||
				(0 !== this.options.lineThickness &&
					("undefined" !== typeof this.options.lineThickness ||
						0 !== this.parent.lineThickness))
			) {
				var f = this.ctx,
					c = this.ctx.globalAlpha;
				this.ctx = a || this.ctx;
				this.ctx.save();
				this.ctx.beginPath();
				this.ctx.rect(
					this.chart.plotArea.x1,
					this.chart.plotArea.y1,
					this.chart.plotArea.width,
					this.chart.plotArea.height
				);
				this.ctx.clip();
				var b = this.scaleBreaks.parent.getPixelCoordinatesOnAxis(
						this.startValue
					),
					d = this.scaleBreaks.parent.getPixelCoordinatesOnAxis(this.endValue);
				this.ctx.strokeStyle = this.lineColor;
				this.ctx.fillStyle = this.color;
				this.ctx.beginPath();
				this.ctx.globalAlpha = 1;
				U(this.id);
				var h, g, q, l, m, n;
				a = Math.max(this.spacing, 3);
				var p = Math.max(0, this.lineThickness);
				this.ctx.lineWidth = p;
				this.ctx.setLineDash && this.ctx.setLineDash(S(this.lineDashType, p));
				if (
					"bottom" === this.scaleBreaks.parent._position ||
					"top" === this.scaleBreaks.parent._position
				)
					if (
						((b = 1 === p % 2 ? (b.x << 0) + 0.5 : b.x << 0),
						(g = 1 === p % 2 ? (d.x << 0) + 0.5 : d.x << 0),
						"top" === this.scaleBreaks.parent._position
							? ((d = this.chart.plotArea.y1),
							  (q = (this.chart.plotArea.y2 + p / 2 + 0.5) << 0))
							: ((d = this.chart.plotArea.y2),
							  (q = (this.chart.plotArea.y1 - p / 2 + 0.5) << 0),
							  (a *= -1)),
						(this.bounds = { x1: b - p / 2, y1: d, x2: g + p / 2, y2: q }),
						this.ctx.moveTo(b, d),
						"straight" === this.type ||
							("top" === this.scaleBreaks.parent._position && 0 >= a) ||
							("bottom" === this.scaleBreaks.parent._position && 0 <= a))
					)
						this.ctx.lineTo(b, q), this.ctx.lineTo(g, q), this.ctx.lineTo(g, d);
					else if ("wavy" === this.type) {
						l = b;
						m = d;
						h = 0.5;
						n = (q - m) / a / 3;
						for (var r = 0; r < n; r++)
							this.ctx.bezierCurveTo(
								l + h * a,
								m + a,
								l + h * a,
								m + 2 * a,
								l,
								m + 3 * a
							),
								(m += 3 * a),
								(h *= -1);
						this.ctx.bezierCurveTo(
							l + h * a,
							m + a,
							l + h * a,
							m + 2 * a,
							l,
							m + 3 * a
						);
						l = g;
						h *= -1;
						this.ctx.lineTo(l, m);
						for (r = 0; r < n; r++)
							this.ctx.bezierCurveTo(
								l + h * a,
								m - a,
								l + h * a,
								m - 2 * a,
								l,
								m - 3 * a
							),
								(m -= 3 * a),
								(h *= -1);
					} else {
						if ("zigzag" === this.type) {
							h = -1;
							m = d + a;
							l = b + a;
							n = (q - m) / a / 2;
							for (r = 0; r < n; r++)
								this.ctx.lineTo(l, m),
									(l += 2 * h * a),
									(m += 2 * a),
									(h *= -1);
							this.ctx.lineTo(l, m);
							l += g - b;
							for (r = 0; r < n + 1; r++)
								this.ctx.lineTo(l, m),
									(l += 2 * h * a),
									(m -= 2 * a),
									(h *= -1);
							this.ctx.lineTo(l + h * a, m + a);
						}
					}
				else if (
					"left" === this.scaleBreaks.parent._position ||
					"right" === this.scaleBreaks.parent._position
				)
					if (
						((d = 1 === p % 2 ? (d.y << 0) + 0.5 : d.y << 0),
						(q = 1 === p % 2 ? (b.y << 0) + 0.5 : b.y << 0),
						"left" === this.scaleBreaks.parent._position
							? ((b = this.chart.plotArea.x1),
							  (g = (this.chart.plotArea.x2 + p / 2 + 0.5) << 0))
							: ((b = this.chart.plotArea.x2),
							  (g = (this.chart.plotArea.x1 - p / 2 + 0.5) << 0),
							  (a *= -1)),
						(this.bounds = { x1: b, y1: d - p / 2, x2: g, y2: q + p / 2 }),
						this.ctx.moveTo(b, d),
						"straight" === this.type ||
							("left" === this.scaleBreaks.parent._position && 0 >= a) ||
							("right" === this.scaleBreaks.parent._position && 0 <= a))
					)
						this.ctx.lineTo(g, d), this.ctx.lineTo(g, q), this.ctx.lineTo(b, q);
					else if ("wavy" === this.type) {
						l = b;
						m = d;
						h = 0.5;
						n = (g - l) / a / 3;
						for (r = 0; r < n; r++)
							this.ctx.bezierCurveTo(
								l + a,
								m + h * a,
								l + 2 * a,
								m + h * a,
								l + 3 * a,
								m
							),
								(l += 3 * a),
								(h *= -1);
						this.ctx.bezierCurveTo(
							l + a,
							m + h * a,
							l + 2 * a,
							m + h * a,
							l + 3 * a,
							m
						);
						m = q;
						h *= -1;
						this.ctx.lineTo(l, m);
						for (r = 0; r < n; r++)
							this.ctx.bezierCurveTo(
								l - a,
								m + h * a,
								l - 2 * a,
								m + h * a,
								l - 3 * a,
								m
							),
								(l -= 3 * a),
								(h *= -1);
					} else if ("zigzag" === this.type) {
						h = 1;
						m = d - a;
						l = b + a;
						n = (g - l) / a / 2;
						for (r = 0; r < n; r++)
							this.ctx.lineTo(l, m), (m += 2 * h * a), (l += 2 * a), (h *= -1);
						this.ctx.lineTo(l, m);
						m += q - d;
						for (r = 0; r < n + 1; r++)
							this.ctx.lineTo(l, m), (m += 2 * h * a), (l -= 2 * a), (h *= -1);
						this.ctx.lineTo(l + a, m + h * a);
					}
				0 < p && this.ctx.stroke();
				this.ctx.closePath();
				this.ctx.globalAlpha = this.fillOpacity;
				this.ctx.globalCompositeOperation = "destination-over";
				this.ctx.fill();
				this.ctx.restore();
				this.ctx.globalAlpha = c;
				this.ctx = f;
			}
		};
		ia(Q, da);
		Q.prototype.createUserOptions = function (a) {
			if ("undefined" !== typeof a || this.options._isPlaceholder) {
				var d = 0;
				this.parent.options._isPlaceholder && this.parent.createUserOptions();
				this.options._isPlaceholder ||
					(Da(this.parent.stripLines),
					(d = this.parent.options.stripLines.indexOf(this.options)));
				this.options = "undefined" === typeof a ? {} : a;
				this.parent.options.stripLines[d] = this.options;
			}
		};
		Q.prototype.render = function () {
			this.ctx.save();
			var a = this.parent.getPixelCoordinatesOnAxis(this.value),
				d = Math.abs(
					"pixel" === this._thicknessType
						? this.thickness
						: Math.abs(
								this.parent.convertValueToPixel(this.endValue) -
									this.parent.convertValueToPixel(this.startValue)
						  )
				);
			if (0 < d) {
				var c = null === this.opacity ? 1 : this.opacity;
				this.ctx.strokeStyle = this.color;
				this.ctx.beginPath();
				var b = this.ctx.globalAlpha;
				this.ctx.globalAlpha = c;
				U(this.id);
				var e, h, g, q;
				this.ctx.lineWidth = d;
				this.ctx.setLineDash && this.ctx.setLineDash(S(this.lineDashType, d));
				if (
					"bottom" === this.parent._position ||
					"top" === this.parent._position
				)
					(e = h = 1 === this.ctx.lineWidth % 2 ? (a.x << 0) + 0.5 : a.x << 0),
						(g = this.chart.plotArea.y1),
						(q = this.chart.plotArea.y2),
						(this.bounds = { x1: e - d / 2, y1: g, x2: h + d / 2, y2: q });
				else if (
					"left" === this.parent._position ||
					"right" === this.parent._position
				)
					(g = q = 1 === this.ctx.lineWidth % 2 ? (a.y << 0) + 0.5 : a.y << 0),
						(e = this.chart.plotArea.x1),
						(h = this.chart.plotArea.x2),
						(this.bounds = { x1: e, y1: g - d / 2, x2: h, y2: q + d / 2 });
				this.ctx.moveTo(e, g);
				this.ctx.lineTo(h, q);
				this.ctx.stroke();
				this.ctx.globalAlpha = b;
			}
			this.ctx.restore();
		};
		ia(Y, da);
		Y.prototype.showAt = function (a) {
			if (!this.enabled) return !1;
			var d = this.chart,
				c = !1;
			d.resetOverlayedCanvas();
			d.clearedOverlayedCanvas = this.parent.type;
			this.chart.renderCrosshairs(this.parent);
			if ("xySwapped" === d.plotInfo.axisPlacement)
				if ("bottom" === this.parent._position)
					for (var b = 0; b < d.axisY.length; b++)
						this.parent === d.axisY[b] &&
							(d.axisY[b]._crosshairValue =
								a >= d.axisY[b].viewportMinimum &&
								a <= d.axisY[b].viewportMaximum
									? a
									: null);
				else if ("top" === this.parent._position)
					for (b = 0; b < d.axisY2.length; b++)
						this.parent === d.axisY2[b] &&
							(d.axisY2[b]._crosshairValue =
								a >= d.axisY2[b].viewportMinimum &&
								a <= d.axisY2[b].viewportMaximum
									? a
									: null);
				else if ("left" === this.parent._position)
					for (b = 0; b < d.axisX.length; b++)
						this.parent === d.axisX[b] &&
							(d.axisX[b]._crosshairValue =
								a >= d.axisX[b].viewportMinimum &&
								a <= d.axisX[b].viewportMaximum
									? a
									: null);
				else {
					if ("right" === this.parent._position)
						for (b = 0; b < d.axisX2.length; b++)
							this.parent === d.axisX2[b] &&
								(d.axisX2[b]._crosshairValue =
									a >= d.axisX2[b].viewportMinimum &&
									a <= d.axisX2[b].viewportMaximum
										? a
										: null);
				}
			else if ("bottom" === this.parent._position)
				for (b = 0; b < d.axisX.length; b++)
					this.parent === d.axisX[b] &&
						(d.axisX[b]._crosshairValue =
							a >= d.axisX[b].viewportMinimum && a <= d.axisX[b].viewportMaximum
								? a
								: null);
			else if ("top" === this.parent._position)
				for (b = 0; b < d.axisX2.length; b++)
					this.parent === d.axisX2[b] &&
						(d.axisX2[b]._crosshairValue =
							a >= d.axisX2[b].viewportMinimum &&
							a <= d.axisX2[b].viewportMaximum
								? a
								: null);
			else if ("left" === this.parent._position)
				for (b = 0; b < d.axisY.length; b++)
					this.parent === d.axisY[b] &&
						(d.axisY[b]._crosshairValue =
							a >= d.axisY[b].viewportMinimum && a <= d.axisY[b].viewportMaximum
								? a
								: null);
			else if ("right" === this.parent._position)
				for (b = 0; b < d.axisY2.length; b++)
					this.parent === d.axisY2[b] &&
						(d.axisY2[b]._crosshairValue =
							a >= d.axisY2[b].viewportMinimum &&
							a <= d.axisY2[b].viewportMaximum
								? a
								: null);
			for (b = 0; b < d.axisX.length; b++)
				(a = d.axisX[b]._crosshairValue),
					d.axisX[b].crosshair &&
						d.axisX[b].crosshair.enabled &&
						!g(a) &&
						a >= d.axisX[b].viewportMinimum &&
						a <= d.axisX[b].viewportMaximum &&
						(d.axisX[b].showCrosshair(a),
						(d.axisX[b].crosshair._updatedValue = a),
						this === d.axisX[b].crosshair && (c = !0));
			for (b = 0; b < d.axisX2.length; b++)
				(a = d.axisX2[b]._crosshairValue),
					d.axisX2[b].crosshair &&
						d.axisX2[b].crosshair.enabled &&
						!g(a) &&
						a >= d.axisX2[b].viewportMinimum &&
						a <= d.axisX2[b].viewportMaximum &&
						(d.axisX2[b].showCrosshair(a),
						(d.axisX2[b].crosshair._updatedValue = a),
						this === d.axisX2[b].crosshair && (c = !0));
			for (b = 0; b < d.axisY.length; b++)
				(a = d.axisY[b]._crosshairValue),
					d.axisY[b].crosshair &&
						d.axisY[b].crosshair.enabled &&
						!g(a) &&
						a >= d.axisY[b].viewportMinimum &&
						a <= d.axisY[b].viewportMaximum &&
						(d.axisY[b].showCrosshair(a),
						(d.axisY[b].crosshair._updatedValue = a),
						this === d.axisY[b].crosshair && (c = !0));
			for (b = 0; b < d.axisY2.length; b++)
				(a = d.axisY2[b]._crosshairValue),
					d.axisY2[b].crosshair &&
						d.axisY2[b].crosshair.enabled &&
						!g(a) &&
						a >= d.axisY2[b].viewportMinimum &&
						a <= d.axisY2[b].viewportMaximum &&
						(d.axisY2[b].showCrosshair(a),
						(d.axisY2[b].crosshair._updatedValue = a),
						this === d.axisY2[b].crosshair && (c = !0));
			this.chart.toolTip &&
				this.chart.toolTip._entries &&
				this.chart.toolTip.highlightObjects(this.chart.toolTip._entries);
			return c;
		};
		Y.prototype.hide = function () {
			this.chart.resetOverlayedCanvas();
			this.chart.renderCrosshairs(this.parent);
			this._hidden = !0;
		};
		Y.prototype.render = function (a, d, c) {
			var b,
				e,
				h,
				q,
				l = null,
				z = null,
				m = null,
				n = "";
			if (!this.valueFormatString)
				if ("dateTime" === this.parent.valueType)
					this.valueFormatString = this.parent.valueFormatString;
				else {
					var p = 0,
						p =
							"xySwapped" === this.chart.plotInfo.axisPlacement
								? 50 < this.parent.range
									? 0
									: 500 < this.chart.width && 25 > this.parent.range
									? 2
									: Math.floor(
											Math.abs(Math.log(this.parent.range) / Math.LN10)
									  ) +
									  (5 > this.parent.range ? 2 : 10 > this.parent.range ? 1 : 0)
								: 50 < this.parent.range
								? 0
								: Math.floor(
										Math.abs(Math.log(this.parent.range) / Math.LN10)
								  ) +
								  (5 > this.parent.range ? 2 : 10 > this.parent.range ? 1 : 0);
					this.valueFormatString = D.generateValueFormatString(
						this.parent.range,
						p
					);
				}
			var m = null === this.opacity ? 1 : this.opacity,
				p = Math.abs(
					"pixel" === this._thicknessType
						? this.thickness
						: this.parent.conversionParameters.pixelPerUnit * this.thickness
				),
				r = this.chart.overlaidCanvasCtx,
				k = r.globalAlpha;
			r.globalAlpha = m;
			r.beginPath();
			r.strokeStyle = this.color;
			r.lineWidth = p;
			r.save();
			this.labelFontSize = Math.abs(
				g(this.options.labelFontSize)
					? this.parent.labelFontSize
					: this.labelFontSize
			);
			this.labelMaxWidth = g(this.options.labelMaxWidth)
				? 0.3 * this.chart.width
				: this.labelMaxWidth;
			this.labelMaxHeight =
				g(this.options.labelWrap) || this.labelWrap
					? 0.3 * this.chart.height
					: 2 * this.labelFontSize;
			0 < p && r.setLineDash && r.setLineDash(S(this.lineDashType, p));
			m = new na(r, {
				x: 0,
				y: 0,
				padding: { top: 2, right: 3, bottom: 2, left: 4 },
				backgroundColor: this.labelBackgroundColor,
				borderColor: this.labelBorderColor,
				borderThickness: this.labelBorderThickness,
				cornerRadius: this.labelCornerRadius,
				maxWidth: this.labelMaxWidth,
				maxHeight: this.labelMaxHeight,
				angle: this.labelAngle,
				text: n,
				horizontalAlign: "left",
				fontSize: this.labelFontSize,
				fontFamily: this.labelFontFamily,
				fontWeight: this.labelFontWeight,
				fontColor: this.labelFontColor,
				fontStyle: this.labelFontStyle,
				textBaseline: "middle",
			});
			if (this.snapToDataPoint) {
				var s = 0,
					n = [];
				if ("xySwapped" === this.chart.plotInfo.axisPlacement) {
					var t = null;
					if (
						"bottom" === this.parent._position ||
						"top" === this.parent._position
					)
						s = this.parent.dataSeries[0].axisX.convertPixelToValue({ y: d });
					else if (
						"left" === this.parent._position ||
						"right" === this.parent._position
					)
						s = this.parent.convertPixelToValue({ y: d });
					for (var v = 0; v < this.parent.dataSeries.length; v++)
						(t = this.parent.dataSeries[v].getDataPointAtX(s, !0)) &&
							0 <= t.index &&
							((t.dataSeries = this.parent.dataSeries[v]),
							null !== t.dataPoint.y && t.dataSeries.visible && n.push(t));
					t = null;
					if (0 === n.length) return;
					n.sort(function (a, b) {
						return a.distance - b.distance;
					});
					t = Math.abs(a - this.parent.convertValueToPixel(n[0].dataPoint.y));
					v = 0;
					if (
						"rangeBar" === n[0].dataSeries.type ||
						"error" === n[0].dataSeries.type
					)
						for (
							var t = Math.abs(
									a - this.parent.convertValueToPixel(n[v].dataPoint.y[0])
								),
								u = 0,
								s = 0;
							s < n.length;
							s++
						)
							if (n[s].dataPoint.y && n[s].dataPoint.y.length)
								for (var w = 0; w < n[s].dataPoint.y.length; w++)
									(u = Math.abs(
										a - this.parent.convertValueToPixel(n[s].dataPoint.y[w])
									)),
										u < t && ((t = u), (v = s));
							else
								(u = Math.abs(
									a - this.parent.convertValueToPixel(n[s].dataPoint.y)
								)),
									u < t && ((t = u), (v = s));
					else if ("stackedBar" === n[0].dataSeries.type)
						for (
							var t = Math.abs(
									a - this.parent.convertValueToPixel(n[0].dataPoint.y)
								),
								y = (u = 0),
								s = (v = 0);
							s < n.length;
							s++
						)
							if (n[s].dataPoint.y && n[s].dataPoint.y.length)
								for (w = 0; w < n[s].dataPoint.y.length; w++)
									(u = Math.abs(
										a - this.parent.convertValueToPixel(n[s].dataPoint.y[w])
									)),
										u < t && ((t = u), (v = s));
							else
								(y += n[s].dataPoint.y),
									(u = Math.abs(a - this.parent.convertValueToPixel(y))),
									u < t && ((t = u), (v = s));
					else if ("stackedBar100" === n[0].dataSeries.type)
						for (
							var t = Math.abs(
									a - this.parent.convertValueToPixel(n[0].dataPoint.y)
								),
								E = (y = u = 0),
								s = 0;
							s < n.length;
							s++
						)
							if (n[s].dataPoint.y && n[s].dataPoint.y.length)
								for (w = 0; w < n[s].dataPoint.y.length; w++)
									(u = Math.abs(
										a - this.parent.convertValueToPixel(n[s].dataPoint.y[w])
									)),
										u < t && ((t = u), (v = s));
							else
								(y += n[s].dataPoint.y),
									(E = n[s].dataPoint.x.getTime
										? n[s].dataPoint.x.getTime()
										: n[s].dataPoint.x),
									(E = 100 * (y / n[s].dataSeries.plotUnit.dataPointYSums[E])),
									(u = Math.abs(a - this.parent.convertValueToPixel(E))),
									u < t && ((t = u), (v = s));
					else
						for (
							t = Math.abs(
								a - this.parent.convertValueToPixel(n[0].dataPoint.y)
							),
								s = v = u = 0;
							s < n.length;
							s++
						)
							if (n[s].dataPoint.y && n[s].dataPoint.y.length)
								for (w = 0; w < n[s].dataPoint.y.length; w++)
									(u = Math.abs(
										a - this.parent.convertValueToPixel(n[s].dataPoint.y[w])
									)),
										u < t && ((t = u), (v = s));
							else
								(u = Math.abs(
									a - this.parent.convertValueToPixel(n[s].dataPoint.y)
								)),
									u < t && ((t = u), (v = s));
					w = n[v];
					if (
						"bottom" === this.parent._position ||
						"top" === this.parent._position
					) {
						b = 0;
						if (
							"rangeBar" === this.parent.dataSeries[v].type ||
							"error" === this.parent.dataSeries[v].type
						) {
							t = Math.abs(
								a - this.parent.convertValueToPixel(w.dataPoint.y[0])
							);
							for (s = u = 0; s < w.dataPoint.y.length; s++)
								(u = Math.abs(
									a - this.parent.convertValueToPixel(w.dataPoint.y[s])
								)),
									u < t && ((t = u), (b = s));
							l =
								1 === r.lineWidth % 2
									? (this.parent.convertValueToPixel(w.dataPoint.y[b]) << 0) +
									  0.5
									: this.parent.convertValueToPixel(w.dataPoint.y[b]) << 0;
							this.value = w.dataPoint.y[b];
							m.text = this.labelFormatter
								? this.labelFormatter({
										chart: this.chart,
										axis: this.parent.options,
										crosshair: this.options,
										value: w.dataPoint.y[b],
								  })
								: g(this.options.label)
								? ba(
										c ? c : w.dataPoint.y[b],
										this.valueFormatString,
										this.chart._cultureInfo
								  )
								: this.label;
						} else if ("stackedBar" === this.parent.dataSeries[v].type) {
							t = Math.abs(
								a - this.parent.convertValueToPixel(n[0].dataPoint.y)
							);
							y = u = 0;
							for (s = v; 0 <= s; s--)
								(y += n[s].dataPoint.y),
									(u = Math.abs(a - this.parent.convertValueToPixel(y))),
									u < t && ((t = u), (b = s));
							l =
								1 === r.lineWidth % 2
									? (this.parent.convertValueToPixel(y) << 0) + 0.5
									: this.parent.convertValueToPixel(y) << 0;
							this.value = y;
							m.text = this.labelFormatter
								? this.labelFormatter({
										chart: this.chart,
										axis: this.parent.options,
										crosshair: this.options,
										value: w.dataPoint.y,
								  })
								: g(this.options.label)
								? ba(
										c ? c : w.dataPoint.y,
										this.valueFormatString,
										this.chart._cultureInfo
								  )
								: this.label;
						} else if ("stackedBar100" === this.parent.dataSeries[v].type) {
							t = Math.abs(
								a - this.parent.convertValueToPixel(n[0].dataPoint.y)
							);
							E = y = u = 0;
							for (s = v; 0 <= s; s--)
								(y += n[s].dataPoint.y),
									(E = n[s].dataPoint.x.getTime
										? n[s].dataPoint.x.getTime()
										: n[s].dataPoint.x),
									(E = 100 * (y / n[s].dataSeries.plotUnit.dataPointYSums[E])),
									(u = Math.abs(a - this.parent.convertValueToPixel(E))),
									u < t && ((t = u), (b = s));
							l =
								1 === r.lineWidth % 2
									? (this.parent.convertValueToPixel(E) << 0) + 0.5
									: this.parent.convertValueToPixel(E) << 0;
							this.value = E;
							m.text = this.labelFormatter
								? this.labelFormatter({
										chart: this.chart,
										axis: this.parent.options,
										crosshair: this.options,
										value: E,
								  })
								: g(this.options.label)
								? ba(c ? c : E, this.valueFormatString, this.chart._cultureInfo)
								: this.label;
						} else
							(l =
								1 === r.lineWidth % 2
									? (this.parent.convertValueToPixel(w.dataPoint.y) << 0) + 0.5
									: this.parent.convertValueToPixel(w.dataPoint.y) << 0),
								(this.value = w.dataPoint.y),
								(m.text = this.labelFormatter
									? this.labelFormatter({
											chart: this.chart,
											axis: this.parent.options,
											crosshair: this.options,
											value: w.dataPoint.y,
									  })
									: g(this.options.label)
									? ba(
											c ? c : w.dataPoint.y,
											this.valueFormatString,
											this.chart._cultureInfo
									  )
									: this.label);
						b = e = l;
						h = this.chart.plotArea.y1;
						q = this.chart.plotArea.y2;
						this.bounds = { x1: b - p / 2, y1: h, x2: e + p / 2, y2: q };
						m.x = b - m.measureText().width / 2;
						m.x + m.width > this.chart.bounds.x2
							? (m.x = this.chart.bounds.x2 - m.width)
							: m.x < this.chart.bounds.x1 && (m.x = this.chart.bounds.x1);
						m.y =
							this.parent.lineCoordinates.y2 +
							("top" === this.parent._position
								? -m.height + this.parent.tickLength
								: m.fontSize / 2) +
							2;
						m.y + m.height > this.chart.bounds.y2
							? (m.y = this.chart.bounds.y2 - m.height)
							: m.y < this.chart.bounds.y1 && (m.y = this.chart.bounds.y1);
					} else if (
						"left" === this.parent._position ||
						"right" === this.parent._position
					) {
						h =
							q =
							z =
								1 === r.lineWidth % 2
									? (this.parent.convertValueToPixel(w.dataPoint.x) << 0) + 0.5
									: this.parent.convertValueToPixel(w.dataPoint.x) << 0;
						b = this.chart.plotArea.x1;
						e = this.chart.plotArea.x2;
						this.bounds = { x1: b, y1: h - p / 2, x2: e, y2: q + p / 2 };
						E = !1;
						if (this.parent.labels)
							for (
								n = Math.ceil(this.parent.interval), s = 0;
								s < this.parent.viewportMaximum;
								s += n
							)
								if (this.parent.labels[s]) E = !0;
								else {
									E = !1;
									break;
								}
						if (E) {
							if ("axisX" === this.parent.type)
								for (
									s = this.parent.convertPixelToValue({ y: d }),
										t = null,
										v = 0;
									v < this.parent.dataSeries.length;
									v++
								)
									(t = this.parent.dataSeries[v].getDataPointAtX(s, !0)) &&
										0 <= t.index &&
										(m.text = this.labelFormatter
											? this.labelFormatter({
													chart: this.chart,
													axis: this.parent.options,
													crosshair: this.options,
													value: w.dataPoint.x,
											  })
											: g(this.options.label)
											? t.dataPoint.label
											: this.label);
						} else
							"dateTime" === this.parent.valueType
								? (m.text = this.labelFormatter
										? this.labelFormatter({
												chart: this.chart,
												axis: this.parent.options,
												crosshair: this.options,
												value: w.dataPoint.x,
										  })
										: g(this.options.label)
										? ha(
												w.dataPoint.x,
												this.valueFormatString,
												this.chart._cultureInfo
										  )
										: this.label)
								: "number" === this.parent.valueType &&
								  (m.text = this.labelFormatter
										? this.labelFormatter({
												chart: this.chart,
												axis: this.parent.options,
												crosshair: this.options,
												value: w.dataPoint.x,
										  })
										: g(this.options.label)
										? ba(
												w.dataPoint.x,
												this.valueFormatString,
												this.chart._cultureInfo
										  )
										: this.label);
						this.value = w.dataPoint.x;
						m.y = q + m.fontSize / 2 - m.measureText().height / 2 + 2;
						m.y - m.fontSize / 2 < this.chart.bounds.y1
							? (m.y = this.chart.bounds.y1 + m.fontSize / 2 + 2)
							: m.y + m.measureText().height - m.fontSize / 2 >
									this.chart.bounds.y2 &&
							  (m.y =
									this.chart.bounds.y2 -
									m.measureText().height +
									m.fontSize / 2);
						"left" === this.parent._position
							? (m.x = this.parent.lineCoordinates.x2 - m.measureText().width)
							: "right" === this.parent._position &&
							  (m.x = this.parent.lineCoordinates.x2);
					}
				} else if (
					"bottom" === this.parent._position ||
					"top" === this.parent._position
				) {
					s = this.parent.convertPixelToValue({ x: a });
					for (v = 0; v < this.parent.dataSeries.length; v++)
						(t = this.parent.dataSeries[v].getDataPointAtX(s, !0)) &&
							0 <= t.index &&
							((t.dataSeries = this.parent.dataSeries[v]),
							null !== t.dataPoint.y && t.dataSeries.visible && n.push(t));
					if (0 === n.length) return;
					n.sort(function (a, b) {
						return a.distance - b.distance;
					});
					w = n[0];
					b =
						e =
						l =
							1 === r.lineWidth % 2
								? (this.parent.convertValueToPixel(w.dataPoint.x) << 0) + 0.5
								: this.parent.convertValueToPixel(w.dataPoint.x) << 0;
					h = this.chart.plotArea.y1;
					q = this.chart.plotArea.y2;
					this.bounds = { x1: b - p / 2, y1: h, x2: e + p / 2, y2: q };
					E = !1;
					if (this.parent.labels)
						for (
							n = Math.ceil(this.parent.interval), s = 0;
							s < this.parent.viewportMaximum;
							s += n
						)
							if (this.parent.labels[s]) E = !0;
							else {
								E = !1;
								break;
							}
					if (E) {
						if ("axisX" === this.parent.type)
							for (
								s = this.parent.convertPixelToValue({ x: a }), t = null, v = 0;
								v < this.parent.dataSeries.length;
								v++
							)
								(t = this.parent.dataSeries[v].getDataPointAtX(s, !0)) &&
									0 <= t.index &&
									(m.text = this.labelFormatter
										? this.labelFormatter({
												chart: this.chart,
												axis: this.parent.options,
												crosshair: this.options,
												value: w.dataPoint.x,
										  })
										: g(this.options.label)
										? t.dataPoint.label
										: this.label);
					} else
						"dateTime" === this.parent.valueType
							? (m.text = this.labelFormatter
									? this.labelFormatter({
											chart: this.chart,
											axis: this.parent.options,
											crosshair: this.options,
											value: w.dataPoint.x,
									  })
									: g(this.options.label)
									? ha(
											w.dataPoint.x,
											this.valueFormatString,
											this.chart._cultureInfo
									  )
									: this.label)
							: "number" === this.parent.valueType &&
							  (m.text = this.labelFormatter
									? this.labelFormatter({
											chart: this.chart,
											axis: this.parent.options,
											crosshair: this.options,
											value: w.dataPoint.x,
									  })
									: g(this.options.label)
									? ba(
											w.dataPoint.x,
											this.valueFormatString,
											this.chart._cultureInfo
									  )
									: this.label);
					this.value = w.dataPoint.x;
					m.x = b - m.measureText().width / 2;
					m.x + m.width > this.chart.bounds.x2 &&
						(m.x = this.chart.bounds.x2 - m.width);
					m.x < this.chart.bounds.x1 && (m.x = this.chart.bounds.x1);
					"bottom" === this.parent._position
						? (m.y = this.parent.lineCoordinates.y2 + m.fontSize / 2 + 2)
						: "top" === this.parent._position &&
						  (m.y =
								this.parent.lineCoordinates.y1 - m.height + m.fontSize / 2 + 2);
				} else if (
					"left" === this.parent._position ||
					"right" === this.parent._position
				) {
					!g(this.parent.dataSeries) &&
						0 < this.parent.dataSeries.length &&
						(s = this.parent.dataSeries[0].axisX.convertPixelToValue({ x: a }));
					for (v = 0; v < this.parent.dataSeries.length; v++)
						(t = this.parent.dataSeries[v].getDataPointAtX(s, !0)) &&
							0 <= t.index &&
							((t.dataSeries = this.parent.dataSeries[v]),
							null !== t.dataPoint.y && t.dataSeries.visible && n.push(t));
					if (0 === n.length) return;
					n.sort(function (a, b) {
						return a.distance - b.distance;
					});
					v = 0;
					if (
						"rangeColumn" === n[0].dataSeries.type ||
						"rangeArea" === n[0].dataSeries.type ||
						"error" === n[0].dataSeries.type ||
						"rangeSplineArea" === n[0].dataSeries.type ||
						"candlestick" === n[0].dataSeries.type ||
						"ohlc" === n[0].dataSeries.type ||
						"boxAndWhisker" === n[0].dataSeries.type
					)
						for (
							t = Math.abs(
								d - this.parent.convertValueToPixel(n[0].dataPoint.y[0])
							),
								s = u = 0;
							s < n.length;
							s++
						)
							if (n[s].dataPoint.y && n[s].dataPoint.y.length)
								for (w = 0; w < n[s].dataPoint.y.length; w++)
									(u = Math.abs(
										d - this.parent.convertValueToPixel(n[s].dataPoint.y[w])
									)),
										u < t && ((t = u), (v = s));
							else
								(u = Math.abs(
									d - this.parent.convertValueToPixel(n[s].dataPoint.y)
								)),
									u < t && ((t = u), (v = s));
					else if (
						"stackedColumn" === n[0].dataSeries.type ||
						"stackedArea" === n[0].dataSeries.type
					)
						for (
							t = Math.abs(
								d - this.parent.convertValueToPixel(n[0].dataPoint.y)
							),
								s = y = u = 0;
							s < n.length;
							s++
						)
							if (n[s].dataPoint.y && n[s].dataPoint.y.length)
								for (w = 0; w < n[s].dataPoint.y.length; w++)
									(u = Math.abs(
										d - this.parent.convertValueToPixel(n[s].dataPoint.y[w])
									)),
										u < t && ((t = u), (v = s));
							else
								(y += n[s].dataPoint.y),
									(u = Math.abs(d - this.parent.convertValueToPixel(y))),
									u < t && ((t = u), (v = s));
					else if (
						"stackedColumn100" === n[0].dataSeries.type ||
						"stackedArea100" === n[0].dataSeries.type
					)
						for (
							t = Math.abs(
								d - this.parent.convertValueToPixel(n[0].dataPoint.y)
							),
								s = E = y = u = 0;
							s < n.length;
							s++
						)
							if (n[s].dataPoint.y && n[s].dataPoint.y.length)
								for (w = 0; w < n[s].dataPoint.y.length; w++)
									(u = Math.abs(
										d - this.parent.convertValueToPixel(n[s].dataPoint.y[w])
									)),
										u < t && ((t = u), (v = s));
							else
								(y += n[s].dataPoint.y),
									(E = n[s].dataPoint.x.getTime
										? n[s].dataPoint.x.getTime()
										: n[s].dataPoint.x),
									(E = 100 * (y / n[s].dataSeries.plotUnit.dataPointYSums[E])),
									(u = Math.abs(d - this.parent.convertValueToPixel(E))),
									u < t && ((t = u), (v = s));
					else
						for (
							t = Math.abs(
								d - this.parent.convertValueToPixel(n[0].dataPoint.y)
							),
								s = u = 0;
							s < n.length;
							s++
						)
							if (n[s].dataPoint.y && n[s].dataPoint.y.length)
								for (w = 0; w < n[s].dataPoint.y.length; w++)
									(u = Math.abs(
										d - this.parent.convertValueToPixel(n[s].dataPoint.y[w])
									)),
										u < t && ((t = u), (v = s));
							else
								(u = Math.abs(
									d - this.parent.convertValueToPixel(n[s].dataPoint.y)
								)),
									u < t && ((t = u), (v = s));
					w = n[v];
					b = 0;
					if (
						"rangeColumn" === this.parent.dataSeries[v].type ||
						"rangeArea" === this.parent.dataSeries[v].type ||
						"error" === this.parent.dataSeries[v].type ||
						"rangeSplineArea" === this.parent.dataSeries[v].type ||
						"candlestick" === this.parent.dataSeries[v].type ||
						"ohlc" === this.parent.dataSeries[v].type ||
						"boxAndWhisker" === this.parent.dataSeries[v].type
					) {
						t = Math.abs(d - this.parent.convertValueToPixel(w.dataPoint.y[0]));
						for (s = u = 0; s < w.dataPoint.y.length; s++)
							(u = Math.abs(
								d - this.parent.convertValueToPixel(w.dataPoint.y[s])
							)),
								u < t && ((t = u), (b = s));
						z =
							1 === r.lineWidth % 2
								? (this.parent.convertValueToPixel(w.dataPoint.y[b]) << 0) + 0.5
								: this.parent.convertValueToPixel(w.dataPoint.y[b]) << 0;
						m.text = this.labelFormatter
							? this.labelFormatter({
									chart: this.chart,
									axis: this.parent.options,
									crosshair: this.options,
									value: w.dataPoint.y[b],
							  })
							: g(this.options.label)
							? ba(
									c ? c : w.dataPoint.y[b],
									this.valueFormatString,
									this.chart._cultureInfo
							  )
							: this.label;
						this.value = w.dataPoint.y[b];
					} else if (
						"stackedColumn" === this.parent.dataSeries[v].type ||
						"stackedArea" === this.parent.dataSeries[v].type
					) {
						t = Math.abs(d - this.parent.convertValueToPixel(n[0].dataPoint.y));
						y = u = 0;
						for (s = v; 0 <= s; s--)
							(y += n[s].dataPoint.y),
								(u = Math.abs(d - this.parent.convertValueToPixel(y))),
								u < t && ((t = u), (b = s));
						z =
							1 === r.lineWidth % 2
								? (this.parent.convertValueToPixel(y) << 0) + 0.5
								: this.parent.convertValueToPixel(y) << 0;
						m.text = this.labelFormatter
							? this.labelFormatter({
									chart: this.chart,
									axis: this.parent.options,
									crosshair: this.options,
									value: w.dataPoint.y,
							  })
							: g(this.options.label)
							? ba(
									c ? c : w.dataPoint.y,
									this.valueFormatString,
									this.chart._cultureInfo
							  )
							: this.label;
						this.value = y;
					} else if (
						"stackedColumn100" === this.parent.dataSeries[v].type ||
						"stackedArea100" === this.parent.dataSeries[v].type
					) {
						t = Math.abs(d - this.parent.convertValueToPixel(n[0].dataPoint.y));
						y = u = 0;
						for (s = v; 0 <= s; s--)
							(y += n[s].dataPoint.y),
								(E = n[s].dataPoint.x.getTime
									? n[s].dataPoint.x.getTime()
									: n[s].dataPoint.x),
								(E = 100 * (y / n[s].dataSeries.plotUnit.dataPointYSums[E])),
								(u = Math.abs(d - this.parent.convertValueToPixel(E))),
								u < t && ((t = u), (b = s));
						z =
							1 === r.lineWidth % 2
								? (this.parent.convertValueToPixel(E) << 0) + 0.5
								: this.parent.convertValueToPixel(E) << 0;
						m.text = this.labelFormatter
							? this.labelFormatter({
									chart: this.chart,
									axis: this.parent.options,
									crosshair: this.options,
									value: E,
							  })
							: g(this.options.label)
							? ba(c ? c : E, this.valueFormatString, this.chart._cultureInfo)
							: this.label;
						this.value = E;
					} else
						"waterfall" === this.parent.dataSeries[v].type
							? ((z =
									1 === r.lineWidth % 2
										? (this.parent.convertValueToPixel(
												w.dataSeries.dataPointEOs[w.index].cumulativeSum
										  ) <<
												0) +
										  0.5
										: this.parent.convertValueToPixel(
												w.dataSeries.dataPointEOs[w.index].cumulativeSum
										  ) << 0),
							  (m.text = this.labelFormatter
									? this.labelFormatter({
											chart: this.chart,
											axis: this.parent.options,
											crosshair: this.options,
											value: w.dataSeries.dataPointEOs[w.index].cumulativeSum,
									  })
									: g(this.options.label)
									? ba(
											c ? c : w.dataSeries.dataPointEOs[w.index].cumulativeSum,
											this.valueFormatString,
											this.chart._cultureInfo
									  )
									: this.label),
							  (this.value = w.dataSeries.dataPointEOs[w.index].cumulativeSum))
							: ((z =
									1 === r.lineWidth % 2
										? (g(a)
												? d
												: this.parent.convertValueToPixel(w.dataPoint.y) << 0) +
										  0.5
										: g(a)
										? d
										: this.parent.convertValueToPixel(w.dataPoint.y) << 0),
							  (m.text = this.labelFormatter
									? this.labelFormatter({
											chart: this.chart,
											axis: this.parent.options,
											crosshair: this.options,
											value: c ? c : w.dataPoint.y,
									  })
									: g(this.options.label)
									? ba(
											c ? c : w.dataPoint.y,
											this.valueFormatString,
											this.chart._cultureInfo
									  )
									: this.label),
							  (this.value = w.dataPoint.y));
					h = q = z;
					b = this.chart.plotArea.x1;
					e = this.chart.plotArea.x2;
					this.bounds = { x1: b, y1: h - p / 2, x2: e, y2: q + p / 2 };
					m.y = q + m.fontSize / 2 - m.measureText().height / 2 + 2;
					m.y - m.fontSize / 2 < this.chart.bounds.y1
						? (m.y = this.chart.bounds.y1 + m.fontSize / 2 + 2)
						: m.y + m.measureText().height - m.fontSize / 2 >
								this.chart.bounds.y2 &&
						  (m.y =
								this.chart.bounds.y2 - m.measureText().height + m.fontSize / 2);
					"left" === this.parent._position
						? (m.x = this.parent.lineCoordinates.x2 - m.measureText().width)
						: "right" === this.parent._position &&
						  (m.x = this.parent.lineCoordinates.x2);
				}
				n = null;
				if (
					"bottom" === this.parent._position ||
					"top" === this.parent._position
				)
					"top" === this.parent._position &&
						m.y - m.fontSize / 2 < this.chart.bounds.y1 &&
						(m.y = this.chart.bounds.y1 + m.fontSize / 2),
						"bottom" === this.parent._position &&
							this.parent.lineCoordinates.y2 -
								m.fontSize / 2 +
								m.measureText().height >
								this.chart.bounds.y2 &&
							(m.y = this.chart.bounds.y2 - m.height + m.fontSize / 2 + 2),
						b >=
							this.parent.convertValueToPixel(
								this.parent.reversed
									? this.parent.viewportMaximum
									: this.parent.viewportMinimum
							) &&
							e <=
								this.parent.convertValueToPixel(
									this.parent.reversed
										? this.parent.viewportMinimum
										: this.parent.viewportMaximum
								) &&
							(0 < p &&
								(r.moveTo(b, h),
								r.lineTo(e, q),
								r.stroke(),
								(this._hidden = !1)),
							r.restore());
				if (
					"left" === this.parent._position ||
					"right" === this.parent._position
				)
					"left" === this.parent._position &&
						m.x < this.chart.bounds.x1 &&
						(m.x = this.chart.bounds.x1),
						"right" === this.parent._position &&
							m.x + m.measureText().width > this.chart.bounds.x2 &&
							(m.x = this.chart.bounds.x2 - m.measureText().width),
						q >=
							this.parent.convertValueToPixel(
								this.parent.reversed
									? this.parent.viewportMinimum
									: this.parent.viewportMaximum
							) &&
							h <=
								this.parent.convertValueToPixel(
									this.parent.reversed
										? this.parent.viewportMaximum
										: this.parent.viewportMinimum
								) &&
							(0 < p &&
								(r.moveTo(b, h),
								r.lineTo(e, q),
								r.stroke(),
								(this._hidden = !1)),
							r.restore());
			} else {
				if (
					"bottom" === this.parent._position ||
					"top" === this.parent._position
				)
					(b = e = l = 1 === r.lineWidth % 2 ? (a << 0) + 0.5 : a << 0),
						(h = this.chart.plotArea.y1),
						(q = this.chart.plotArea.y2),
						(this.bounds = { x1: b - p / 2, y1: h, x2: e + p / 2, y2: q });
				else if (
					"left" === this.parent._position ||
					"right" === this.parent._position
				)
					(h = q = z = 1 === r.lineWidth % 2 ? (d << 0) + 0.5 : d << 0),
						(b = this.chart.plotArea.x1),
						(e = this.chart.plotArea.x2),
						(this.bounds = { x1: b, y1: h - p / 2, x2: e, y2: q + p / 2 });
				if ("xySwapped" === this.chart.plotInfo.axisPlacement)
					if (
						"left" === this.parent._position ||
						"right" === this.parent._position
					) {
						E = !1;
						if (this.parent.labels)
							for (
								n = Math.ceil(this.parent.interval), s = 0;
								s < this.parent.viewportMaximum;
								s += n
							)
								if (this.parent.labels[s]) E = !0;
								else {
									E = !1;
									break;
								}
						if (E) {
							if ("axisX" === this.parent.type)
								for (
									s = this.parent.convertPixelToValue({ y: d }),
										t = null,
										v = 0;
									v < this.parent.dataSeries.length;
									v++
								)
									(t = this.parent.dataSeries[v].getDataPointAtX(s, !0)) &&
										0 <= t.index &&
										(m.text = this.labelFormatter
											? this.labelFormatter({
													chart: this.chart,
													axis: this.parent.options,
													crosshair: this.options,
													value: c ? c : this.parent.convertPixelToValue(a),
											  })
											: g(this.options.label)
											? t.dataPoint.label
											: this.label);
						} else
							"dateTime" === this.parent.valueType
								? (m.text = this.labelFormatter
										? this.labelFormatter({
												chart: this.chart,
												axis: this.parent.options,
												crosshair: this.options,
												value: c ? c : this.parent.convertPixelToValue(d),
										  })
										: g(this.options.label)
										? ha(
												c ? c : this.parent.convertPixelToValue(d),
												this.valueFormatString,
												this.chart._cultureInfo
										  )
										: this.label)
								: "number" === this.parent.valueType &&
								  (m.text = this.labelFormatter
										? this.labelFormatter({
												chart: this.chart,
												axis: this.parent.options,
												crosshair: this.options,
												value: c ? c : this.parent.convertPixelToValue(d),
										  })
										: g(this.options.label)
										? ba(
												c ? c : this.parent.convertPixelToValue(d),
												this.valueFormatString,
												this.chart._cultureInfo
										  )
										: this.label);
						m.y = d + m.fontSize / 2 - m.measureText().height / 2 + 2;
						m.y - m.fontSize / 2 < this.chart.bounds.y1
							? (m.y = this.chart.bounds.y1 + m.fontSize / 2 + 2)
							: m.y + m.measureText().height - m.fontSize / 2 >
									this.chart.bounds.y2 &&
							  (m.y =
									this.chart.bounds.y2 -
									m.measureText().height +
									m.fontSize / 2);
						"left" === this.parent._position
							? (m.x = this.parent.lineCoordinates.x1 - m.measureText().width)
							: "right" === this.parent._position &&
							  (m.x = this.parent.lineCoordinates.x2);
					} else {
						if (
							"bottom" === this.parent._position ||
							"top" === this.parent._position
						)
							(m.text = this.labelFormatter
								? this.labelFormatter({
										chart: this.chart,
										axis: this.parent.options,
										crosshair: this.options,
										value: c ? c : this.parent.convertPixelToValue(a),
								  })
								: g(this.options.label)
								? ba(
										c ? c : this.parent.convertPixelToValue(a),
										this.valueFormatString,
										this.chart._cultureInfo
								  )
								: this.label),
								(m.x = b - m.measureText().width / 2),
								m.x + m.width > this.chart.bounds.x2 &&
									(m.x = this.chart.bounds.x2 - m.width),
								m.x < this.chart.bounds.x1 && (m.x = this.chart.bounds.x1),
								"bottom" === this.parent._position
									? (m.y = this.parent.lineCoordinates.y2 + m.fontSize / 2 + 2)
									: "top" === this.parent._position &&
									  (m.y =
											this.parent.lineCoordinates.y1 -
											m.height +
											m.fontSize / 2 +
											2);
					}
				else if (
					"bottom" === this.parent._position ||
					"top" === this.parent._position
				) {
					E = !1;
					n = "";
					if (this.parent.labels)
						for (
							n = Math.ceil(this.parent.interval), s = 0;
							s < this.parent.viewportMaximum;
							s += n
						)
							if (this.parent.labels[s]) E = !0;
							else {
								E = !1;
								break;
							}
					if (E) {
						if ("axisX" === this.parent.type)
							for (
								s = this.parent.convertPixelToValue({ x: a }), t = null, v = 0;
								v < this.parent.dataSeries.length;
								v++
							)
								(t = this.parent.dataSeries[v].getDataPointAtX(s, !0)) &&
									0 <= t.index &&
									(m.text = this.labelFormatter
										? this.labelFormatter({
												chart: this.chart,
												axis: this.parent.options,
												crosshair: this.options,
												value: c ? c : this.parent.convertPixelToValue(a),
										  })
										: g(this.options.label)
										? c
											? c
											: t.dataPoint.label
										: this.label);
					} else
						"dateTime" === this.parent.valueType
							? (m.text = this.labelFormatter
									? this.labelFormatter({
											chart: this.chart,
											axis: this.parent.options,
											crosshair: this.options,
											value: c ? c : this.parent.convertPixelToValue(a),
									  })
									: g(this.options.label)
									? ha(
											c ? c : this.parent.convertPixelToValue(a),
											this.valueFormatString,
											this.chart._cultureInfo
									  )
									: this.label)
							: "number" === this.parent.valueType &&
							  (m.text = this.labelFormatter
									? this.labelFormatter({
											chart: this.chart,
											axis: this.parent.options,
											crosshair: this.options,
											value: c
												? c
												: 0 < this.parent.dataSeries.length
												? this.parent.convertPixelToValue(a)
												: "",
									  })
									: g(this.options.label)
									? ba(
											c ? c : this.parent.convertPixelToValue(a),
											this.valueFormatString,
											this.chart._cultureInfo
									  )
									: this.label);
					m.x = b - m.measureText().width / 2;
					m.x + m.width > this.chart.bounds.x2 &&
						(m.x = this.chart.bounds.x2 - m.width);
					m.x < this.chart.bounds.x1 && (m.x = this.chart.bounds.x1);
					"bottom" === this.parent._position
						? (m.y = this.parent.lineCoordinates.y2 + m.fontSize / 2 + 2)
						: "top" === this.parent._position &&
						  (m.y =
								this.parent.lineCoordinates.y1 - m.height + m.fontSize / 2 + 2);
				} else if (
					"left" === this.parent._position ||
					"right" === this.parent._position
				)
					(m.text = this.labelFormatter
						? this.labelFormatter({
								chart: this.chart,
								axis: this.parent.options,
								crosshair: this.options,
								value: c ? c : this.parent.convertPixelToValue(d),
						  })
						: g(this.options.label)
						? ba(
								c ? c : this.parent.convertPixelToValue(d),
								this.valueFormatString,
								this.chart._cultureInfo
						  )
						: this.label),
						(m.y = d + m.fontSize / 2 - m.measureText().height / 2 + 2),
						m.y - m.fontSize / 2 < this.chart.bounds.y1
							? (m.y = this.chart.bounds.y1 + m.fontSize / 2 + 2)
							: m.y + m.measureText().height - m.fontSize / 2 >
									this.chart.bounds.y2 &&
							  (m.y =
									this.chart.bounds.y2 -
									m.measureText().height +
									m.fontSize / 2),
						"left" === this.parent._position
							? (m.x = this.parent.lineCoordinates.x2 - m.measureText().width)
							: "right" === this.parent._position &&
							  (m.x = this.parent.lineCoordinates.x2);
				"left" === this.parent._position && m.x < this.chart.bounds.x1
					? (m.x = this.chart.bounds.x1)
					: "right" === this.parent._position &&
					  m.x + m.measureText().width > this.chart.bounds.x2
					? (m.x = this.chart.bounds.x2 - m.measureText().width)
					: "top" === this.parent._position &&
					  m.y - m.fontSize / 2 < this.chart.bounds.y1
					? (m.y = this.chart.bounds.y1 + m.fontSize / 2)
					: "bottom" === this.parent._position &&
					  this.parent.lineCoordinates.y2 -
							m.fontSize / 2 +
							m.measureText().height >
							this.chart.bounds.y2 &&
					  (m.y = this.chart.bounds.y2 - m.height + m.fontSize / 2 + 2);
				0 < p &&
					(r.moveTo(b, h), r.lineTo(e, q), r.stroke(), (this._hidden = !1));
				r.restore();
				this.value =
					"bottom" === this.parent._position || "top" === this.parent._position
						? this.parent.convertPixelToValue(a)
						: this.parent.convertPixelToValue(d);
			}
			if ("bottom" === this.parent._position || "top" === this.parent._position)
				this._updatedValue = this.parent.convertPixelToValue(l);
			if ("left" === this.parent._position || "right" === this.parent._position)
				this._updatedValue = this.parent.convertPixelToValue(z);
			this._textBlock = m;
			g(c) || this.renderLabel();
			r.globalAlpha = k;
		};
		Y.prototype.renderLabel = function () {
			g(this._textBlock) ||
				g(this._textBlock.text) ||
				!(
					"number" === typeof this._textBlock.text.valueOf() ||
					0 < this._textBlock.text.length
				) ||
				this._hidden ||
				this._textBlock.render(!0);
		};
		ia(qa, da);
		qa.prototype._initialize = function () {
			this.updateOption("updated");
			this.updateOption("hidden");
			if (this.enabled) {
				this.container = document.createElement("div");
				this.container.setAttribute("class", "canvasjs-chart-tooltip");
				this.container.style.position = "absolute";
				this.container.style.height = "auto";
				this.container.style.boxShadow = "1px 1px 2px 2px rgba(0,0,0,0.1)";
				this.container.style.zIndex = "1000";
				this.container.style.pointerEvents = "none";
				this.container.style.display = "none";
				var a = document.createElement("div");
				a.style.width = "auto";
				a.style.height = "auto";
				a.style.minWidth = "50px";
				a.style.lineHeight = "normal";
				a.style.margin = "0px 0px 0px 0px";
				a.style.padding = "5px";
				a.style.fontFamily = "Calibri, Arial, Georgia, serif";
				a.style.fontWeight = "normal";
				a.style.fontStyle = t ? "italic" : "normal";
				a.style.fontSize = "14px";
				a.style.color = "#000000";
				a.style.textShadow = "1px 1px 1px rgba(0, 0, 0, 0.1)";
				a.style.textAlign = "left";
				a.style.border = "2px solid gray";
				a.style.background = t ? "rgba(255,255,255,.9)" : "rgb(255,255,255)";
				a.style.textIndent = "0px";
				a.style.whiteSpace = "nowrap";
				a.style.borderRadius = "5px";
				a.style.MozUserSelect = "none";
				a.style.WebkitUserSelect = "none";
				a.style.msUserSelect = "none";
				a.style.userSelect = "none";
				t ||
					((a.style.filter = "alpha(opacity = 90)"),
					(a.style.filter =
						"progid:DXImageTransform.Microsoft.Shadow(Strength=3, Direction=135, Color='#666666')"));
				a.innerText = "Sample Tooltip";
				this.container.appendChild(a);
				this.contentDiv = this.container.firstChild;
				this.container.style.borderRadius = this.contentDiv.style.borderRadius;
				this.chart._canvasJSContainer.appendChild(this.container);
			}
		};
		qa.prototype.mouseMoveHandler = function (a, d) {
			(this._lastUpdated && 4 > new Date().getTime() - this._lastUpdated) ||
				((this._lastUpdated = new Date().getTime()),
				this.chart.resetOverlayedCanvas(),
				this._updateToolTip(a, d));
		};
		qa.prototype._updateToolTip = function (a, d, c) {
			c = "undefined" === typeof c ? !0 : c;
			this.container || this._initialize();
			this.enabled || this.hide();
			if (!this.chart.disableToolTip) {
				if ("undefined" === typeof a || "undefined" === typeof d) {
					if (isNaN(this._prevX) || isNaN(this._prevY)) return;
					a = this._prevX;
					d = this._prevY;
				} else (this._prevX = a), (this._prevY = d);
				var b = null,
					e = null,
					h = [],
					q = 0;
				if (
					this.shared &&
					this.enabled &&
					"none" !== this.chart.plotInfo.axisPlacement
				) {
					if ("xySwapped" === this.chart.plotInfo.axisPlacement) {
						var l = [];
						if (this.chart.axisX)
							for (var s = 0; s < this.chart.axisX.length; s++) {
								for (
									var q = this.chart.axisX[s].convertPixelToValue({ y: d }),
										m = null,
										b = 0;
									b < this.chart.axisX[s].dataSeries.length;
									b++
								)
									(m = this.chart.axisX[s].dataSeries[b].getDataPointAtX(
										q,
										c
									)) &&
										0 <= m.index &&
										((m.dataSeries = this.chart.axisX[s].dataSeries[b]),
										null !== m.dataPoint.y && l.push(m));
								m = null;
							}
						if (this.chart.axisX2)
							for (s = 0; s < this.chart.axisX2.length; s++) {
								q = this.chart.axisX2[s].convertPixelToValue({ y: d });
								m = null;
								for (b = 0; b < this.chart.axisX2[s].dataSeries.length; b++)
									(m = this.chart.axisX2[s].dataSeries[b].getDataPointAtX(
										q,
										c
									)) &&
										0 <= m.index &&
										((m.dataSeries = this.chart.axisX2[s].dataSeries[b]),
										null !== m.dataPoint.y && l.push(m));
								m = null;
							}
					} else {
						l = [];
						if (this.chart.axisX)
							for (s = 0; s < this.chart.axisX.length; s++)
								for (
									q = this.chart.axisX[s].convertPixelToValue({ x: a }),
										m = null,
										b = 0;
									b < this.chart.axisX[s].dataSeries.length;
									b++
								)
									(m = this.chart.axisX[s].dataSeries[b].getDataPointAtX(
										q,
										c
									)) &&
										0 <= m.index &&
										((m.dataSeries = this.chart.axisX[s].dataSeries[b]),
										null !== m.dataPoint.y && l.push(m));
						if (this.chart.axisX2)
							for (s = 0; s < this.chart.axisX2.length; s++)
								for (
									q = this.chart.axisX2[s].convertPixelToValue({ x: a }),
										m = null,
										b = 0;
									b < this.chart.axisX2[s].dataSeries.length;
									b++
								)
									(m = this.chart.axisX2[s].dataSeries[b].getDataPointAtX(
										q,
										c
									)) &&
										0 <= m.index &&
										((m.dataSeries = this.chart.axisX2[s].dataSeries[b]),
										null !== m.dataPoint.y && l.push(m));
					}
					if (0 === l.length) return;
					l.sort(function (a, b) {
						return a.distance - b.distance;
					});
					c = l[0];
					for (b = 0; b < l.length; b++)
						l[b].dataPoint.x.valueOf() === c.dataPoint.x.valueOf() &&
							h.push(l[b]);
					l = null;
				} else {
					if ((b = this.chart.getDataPointAtXY(a, d, c)))
						(this.currentDataPointIndex = b.dataPointIndex),
							(this.currentSeriesIndex = b.dataSeries.index);
					else if (t)
						if (
							((b = jb(a, d, this.chart._eventManager.ghostCtx)),
							0 < b &&
								"undefined" !== typeof this.chart._eventManager.objectMap[b])
						) {
							b = this.chart._eventManager.objectMap[b];
							if ("legendItem" === b.objectType) return;
							this.currentSeriesIndex = b.dataSeriesIndex;
							this.currentDataPointIndex =
								0 <= b.dataPointIndex ? b.dataPointIndex : -1;
						} else this.currentDataPointIndex = -1;
					else this.currentDataPointIndex = -1;
					if (0 <= this.currentSeriesIndex) {
						e = this.chart.data[this.currentSeriesIndex];
						m = {};
						if (0 <= this.currentDataPointIndex)
							(b = e.dataPoints[this.currentDataPointIndex]),
								(m.dataSeries = e),
								(m.dataPoint = b),
								(m.index = this.currentDataPointIndex),
								(m.distance = Math.abs(b.x - q)),
								"waterfall" === e.type &&
									((m.cumulativeSumYStartValue =
										e.dataPointEOs[
											this.currentDataPointIndex
										].cumulativeSumYStartValue),
									(m.cumulativeSum =
										e.dataPointEOs[this.currentDataPointIndex].cumulativeSum));
						else {
							if (
								!this.enabled ||
								("line" !== e.type &&
									"stepLine" !== e.type &&
									"spline" !== e.type &&
									"area" !== e.type &&
									"stepArea" !== e.type &&
									"splineArea" !== e.type &&
									"stackedArea" !== e.type &&
									"stackedArea100" !== e.type &&
									"rangeArea" !== e.type &&
									"rangeSplineArea" !== e.type &&
									"candlestick" !== e.type &&
									"ohlc" !== e.type &&
									"boxAndWhisker" !== e.type)
							)
								return;
							q = e.axisX.convertPixelToValue({ x: a });
							m = e.getDataPointAtX(q, c);
							g(m) ||
								((m.dataSeries = e),
								(this.currentDataPointIndex = m.index),
								(b = m.dataPoint));
						}
						if (!g(m) && !g(m.dataPoint) && !g(m.dataPoint.y))
							if (m.dataSeries.axisY)
								if (0 < m.dataPoint.y.length) {
									for (b = c = 0; b < m.dataPoint.y.length; b++)
										m.dataPoint.y[b] < m.dataSeries.axisY.viewportMinimum
											? c--
											: m.dataPoint.y[b] > m.dataSeries.axisY.viewportMaximum &&
											  c++;
									c < m.dataPoint.y.length &&
										c > -m.dataPoint.y.length &&
										h.push(m);
								} else
									"column" === e.type || "bar" === e.type
										? 0 > m.dataPoint.y
											? 0 > m.dataSeries.axisY.viewportMinimum &&
											  m.dataSeries.axisY.viewportMaximum >= m.dataPoint.y &&
											  h.push(m)
											: m.dataSeries.axisY.viewportMinimum <= m.dataPoint.y &&
											  0 <= m.dataSeries.axisY.viewportMaximum &&
											  h.push(m)
										: "bubble" === e.type
										? ((c =
												this.chart._eventManager.objectMap[
													e.dataPointIds[m.index]
												].size / 2),
										  m.dataPoint.y >= m.dataSeries.axisY.viewportMinimum - c &&
												m.dataPoint.y <=
													m.dataSeries.axisY.viewportMaximum + c &&
												h.push(m))
										: "waterfall" === e.type
										? ((c = 0),
										  m.cumulativeSumYStartValue <
										  m.dataSeries.axisY.viewportMinimum
												? c--
												: m.cumulativeSumYStartValue >
														m.dataSeries.axisY.viewportMaximum && c++,
										  m.cumulativeSum < m.dataSeries.axisY.viewportMinimum
												? c--
												: m.cumulativeSum >
														m.dataSeries.axisY.viewportMaximum && c++,
										  2 > c && -2 < c && h.push(m))
										: (0 <= m.dataSeries.type.indexOf("100") ||
												"stackedColumn" === e.type ||
												"stackedBar" === e.type ||
												(m.dataPoint.y >= m.dataSeries.axisY.viewportMinimum &&
													m.dataPoint.y <=
														m.dataSeries.axisY.viewportMaximum)) &&
										  h.push(m);
							else h.push(m);
					}
				}
				if (0 < h.length) {
					if ((this.highlightObjects(h), this.enabled)) {
						c = "";
						c = this.getToolTipInnerHTML({ entries: h });
						if (null !== c) {
							this.contentDiv.innerHTML = c;
							if (
								this.isToolTipDefinedInData &&
								g(this.options.content) &&
								g(this.options.contentFormatter)
							)
								for (
									q = this.contentDiv.getElementsByTagName("span"), b = 0;
									b < h.length;
									b++
								)
									q[b] &&
										(q[b].style.color = this.chart.replaceKeywordsWithValue(
											"{color}",
											h[b].dataPoint,
											h[b].dataSeries,
											h[b].index
										));
							q = !1;
							"none" === this.container.style.display &&
								((q = !0), (this.container.style.display = "block"));
							try {
								(this.contentDiv.style.background = this.backgroundColor
									? this.backgroundColor
									: t
									? "rgba(255,255,255,.9)"
									: "rgb(255,255,255)"),
									(this.borderColor =
										"waterfall" === h[0].dataSeries.type
											? (this.contentDiv.style.borderRightColor =
													this.contentDiv.style.borderLeftColor =
													this.contentDiv.style.borderColor =
														this.options.borderColor
															? this.options.borderColor
															: h[0].dataPoint.color
															? h[0].dataPoint.color
															: 0 < h[0].dataPoint.y
															? h[0].dataSeries.risingColor
															: h[0].dataSeries.fallingColor)
											: "error" === h[0].dataSeries.type
											? (this.contentDiv.style.borderRightColor =
													this.contentDiv.style.borderLeftColor =
													this.contentDiv.style.borderColor =
														this.options.borderColor
															? this.options.borderColor
															: h[0].dataSeries.color
															? h[0].dataSeries.color
															: h[0].dataSeries._colorSet[
																	e.index % h[0].dataSeries._colorSet.length
															  ])
											: (this.contentDiv.style.borderRightColor =
													this.contentDiv.style.borderLeftColor =
													this.contentDiv.style.borderColor =
														this.options.borderColor
															? this.options.borderColor
															: h[0].dataPoint.color
															? h[0].dataPoint.color
															: h[0].dataSeries.color
															? h[0].dataSeries.color
															: h[0].dataSeries._colorSet[
																	h[0].index % h[0].dataSeries._colorSet.length
															  ])),
									(this.contentDiv.style.borderWidth =
										this.borderThickness || 0 === this.borderThickness
											? this.borderThickness + "px"
											: "2px"),
									(this.contentDiv.style.borderRadius =
										this.cornerRadius || 0 === this.cornerRadius
											? this.cornerRadius + "px"
											: "5px"),
									(this.container.style.borderRadius =
										this.contentDiv.style.borderRadius),
									(this.contentDiv.style.fontSize =
										this.fontSize || 0 === this.fontSize
											? this.fontSize + "px"
											: "14px"),
									(this.contentDiv.style.color = this.fontColor
										? this.fontColor
										: "#000000"),
									(this.contentDiv.style.fontFamily = this.fontFamily
										? this.fontFamily
										: "Calibri, Arial, Georgia, serif;"),
									(this.contentDiv.style.fontWeight = this.fontWeight
										? this.fontWeight
										: "normal"),
									(this.contentDiv.style.fontStyle = this.fontStyle
										? this.fontStyle
										: t
										? "italic"
										: "normal");
							} catch (n) {}
							"pie" === h[0].dataSeries.type ||
							"doughnut" === h[0].dataSeries.type ||
							"funnel" === h[0].dataSeries.type ||
							"pyramid" === h[0].dataSeries.type ||
							"bar" === h[0].dataSeries.type ||
							"rangeBar" === h[0].dataSeries.type ||
							"stackedBar" === h[0].dataSeries.type ||
							"stackedBar100" === h[0].dataSeries.type
								? (a = a - 10 - this.container.clientWidth)
								: ((a =
										(h[0].dataSeries.axisX.convertValueToPixel(
											h[0].dataPoint.x
										) -
											this.container.clientWidth) <<
										0),
								  (a -= 10));
							0 > a && (a += this.container.clientWidth + 20);
							a + this.container.clientWidth >
								Math.max(this.chart.container.clientWidth, this.chart.width) &&
								(a = Math.max(
									0,
									Math.max(this.chart.container.clientWidth, this.chart.width) -
										this.container.clientWidth
								));
							d =
								1 !== h.length ||
								this.shared ||
								("line" !== h[0].dataSeries.type &&
									"stepLine" !== h[0].dataSeries.type &&
									"spline" !== h[0].dataSeries.type &&
									"area" !== h[0].dataSeries.type &&
									"stepArea" !== h[0].dataSeries.type &&
									"splineArea" !== h[0].dataSeries.type)
									? "bar" === h[0].dataSeries.type ||
									  "rangeBar" === h[0].dataSeries.type ||
									  "stackedBar" === h[0].dataSeries.type ||
									  "stackedBar100" === h[0].dataSeries.type
										? h[0].dataSeries.axisX.convertValueToPixel(
												h[0].dataPoint.x
										  )
										: d
									: h[0].dataSeries.axisY.convertValueToPixel(h[0].dataPoint.y);
							d = -d + 10;
							0 < d + this.container.clientHeight + 5 &&
								(d -= d + this.container.clientHeight + 5 - 0);
							this.fixMozTransitionDelay(a, d);
							!this.animationEnabled || q
								? this.disableAnimation()
								: (this.enableAnimation(),
								  (this.container.style.MozTransition =
										this.mozContainerTransition));
							this.positionLeft = a;
							this.positionBottom = d;
							this.container.style.left = a + "px";
							this.container.style.bottom = d + "px";
						} else
							this.hide(!1),
								this.enabled &&
									this.dispatchEvent(
										"hidden",
										{ chart: this.chart, toolTip: this },
										this
									);
						d = [];
						for (b = 0; b < h.length; b++)
							d.push({
								xValue: h[b].dataPoint.x,
								dataPoint: h[b].dataPoint,
								dataSeries: h[b].dataSeries,
								dataPointIndex: h[b].index,
								dataSeriesIndex: h[b].dataSeries._index,
							});
						d = {
							chart: this.chart,
							toolTip: this.options,
							content: c,
							entries: d,
						};
						this._entries = h;
						this.dispatchEvent("updated", d, this);
					}
				} else
					this.hide(),
						this.enabled &&
							this.dispatchEvent(
								"hidden",
								{ chart: this.chart, toolTip: this },
								this
							);
			}
		};
		qa.prototype.highlightObjects = function (a) {
			var f = this.chart.overlaidCanvasCtx;
			if (
				g(this.chart.clearedOverlayedCanvas) ||
				"toolTip" === this.chart.clearedOverlayedCanvas
			)
				this.chart.resetOverlayedCanvas(),
					f.clearRect(0, 0, this.chart.width, this.chart.height),
					(this.chart.clearedOverlayedCanvas = "toolTip");
			f.save();
			var c = this.chart.plotArea,
				b = 0;
			f.beginPath();
			f.rect(c.x1, c.y1, c.x2 - c.x1, c.y2 - c.y1);
			f.clip();
			for (c = 0; c < a.length; c++) {
				var e = a[c];
				if (
					(e =
						this.chart._eventManager.objectMap[
							e.dataSeries.dataPointIds[e.index]
						]) &&
					e.objectType &&
					"dataPoint" === e.objectType
				) {
					var b = this.chart.data[e.dataSeriesIndex],
						h = b.dataPoints[e.dataPointIndex],
						l = e.dataPointIndex;
					!1 === h.highlightEnabled ||
						(!0 !== b.highlightEnabled && !0 !== h.highlightEnabled) ||
						("line" === b.type ||
						"stepLine" === b.type ||
						"spline" === b.type ||
						"scatter" === b.type ||
						"area" === b.type ||
						"stepArea" === b.type ||
						"splineArea" === b.type ||
						"stackedArea" === b.type ||
						"stackedArea100" === b.type ||
						"rangeArea" === b.type ||
						"rangeSplineArea" === b.type
							? ((h = b.getMarkerProperties(
									l,
									e.x1,
									e.y1,
									this.chart.overlaidCanvasCtx
							  )),
							  (h.size = Math.max((1.5 * h.size) << 0, 10)),
							  (h.borderColor = h.borderColor || "#FFFFFF"),
							  (h.borderThickness =
									h.borderThickness || Math.ceil(0.1 * h.size)),
							  s.drawMarkers([h]),
							  "undefined" !== typeof e.y2 &&
									((h = b.getMarkerProperties(
										l,
										e.x1,
										e.y2,
										this.chart.overlaidCanvasCtx
									)),
									(h.size = Math.max((1.5 * h.size) << 0, 10)),
									(h.borderColor = h.borderColor || "#FFFFFF"),
									(h.borderThickness =
										h.borderThickness || Math.ceil(0.1 * h.size)),
									s.drawMarkers([h])))
							: "bubble" === b.type
							? ((h = b.getMarkerProperties(
									l,
									e.x1,
									e.y1,
									this.chart.overlaidCanvasCtx
							  )),
							  (h.size = e.size),
							  (h.color = "white"),
							  (h.borderColor = "white"),
							  (f.globalAlpha = 0.3),
							  s.drawMarkers([h]),
							  (f.globalAlpha = 1))
							: "column" === b.type ||
							  "stackedColumn" === b.type ||
							  "stackedColumn100" === b.type ||
							  "bar" === b.type ||
							  "rangeBar" === b.type ||
							  "stackedBar" === b.type ||
							  "stackedBar100" === b.type ||
							  "rangeColumn" === b.type ||
							  "waterfall" === b.type
							? ea(
									f,
									e.x1,
									e.y1,
									e.x2,
									e.y2,
									"white",
									0,
									null,
									!1,
									!1,
									!1,
									!1,
									0.3
							  )
							: "pie" === b.type || "doughnut" === b.type
							? d(
									f,
									e.center,
									e.radius,
									"white",
									b.type,
									e.startAngle,
									e.endAngle,
									0.3,
									e.percentInnerRadius
							  )
							: "funnel" === b.type || "pyramid" === b.type
							? q(f, e.funnelSection, 0.3, "white")
							: "candlestick" === b.type
							? ((f.globalAlpha = 1),
							  (f.strokeStyle = e.color),
							  (f.lineWidth = 2 * e.borderThickness),
							  (b = 0 === f.lineWidth % 2 ? 0 : 0.5),
							  f.beginPath(),
							  f.moveTo(e.x3 - b, Math.min(e.y2, e.y3)),
							  f.lineTo(e.x3 - b, Math.min(e.y1, e.y4)),
							  f.stroke(),
							  f.beginPath(),
							  f.moveTo(e.x3 - b, Math.max(e.y1, e.y4)),
							  f.lineTo(e.x3 - b, Math.max(e.y2, e.y3)),
							  f.stroke(),
							  ea(
									f,
									e.x1,
									Math.min(e.y1, e.y4),
									e.x2,
									Math.max(e.y1, e.y4),
									"transparent",
									2 * e.borderThickness,
									e.color,
									!1,
									!1,
									!1,
									!1
							  ),
							  (f.globalAlpha = 1))
							: "ohlc" === b.type
							? ((f.globalAlpha = 1),
							  (f.strokeStyle = e.color),
							  (f.lineWidth = 2 * e.borderThickness),
							  (b = 0 === f.lineWidth % 2 ? 0 : 0.5),
							  f.beginPath(),
							  f.moveTo(e.x3 - b, e.y2),
							  f.lineTo(e.x3 - b, e.y3),
							  f.stroke(),
							  f.beginPath(),
							  f.moveTo(e.x3, e.y1),
							  f.lineTo(e.x1, e.y1),
							  f.stroke(),
							  f.beginPath(),
							  f.moveTo(e.x3, e.y4),
							  f.lineTo(e.x2, e.y4),
							  f.stroke(),
							  (f.globalAlpha = 1))
							: "boxAndWhisker" === b.type
							? (f.save(),
							  (f.globalAlpha = 1),
							  (f.strokeStyle = e.stemColor),
							  (f.lineWidth = 2 * e.stemThickness),
							  0 < e.stemThickness &&
									(f.beginPath(),
									f.moveTo(e.x3, e.y2 + e.borderThickness / 2),
									f.lineTo(e.x3, e.y1 + e.whiskerThickness / 2),
									f.stroke(),
									f.beginPath(),
									f.moveTo(e.x3, e.y4 - e.whiskerThickness / 2),
									f.lineTo(e.x3, e.y3 - e.borderThickness / 2),
									f.stroke()),
							  f.beginPath(),
							  ea(
									f,
									e.x1 - e.borderThickness / 2,
									Math.max(
										e.y2 + e.borderThickness / 2,
										e.y3 + e.borderThickness / 2
									),
									e.x2 + e.borderThickness / 2,
									Math.min(
										e.y2 - e.borderThickness / 2,
										e.y3 - e.borderThickness / 2
									),
									"transparent",
									e.borderThickness,
									e.color,
									!1,
									!1,
									!1,
									!1
							  ),
							  (f.globalAlpha = 1),
							  (f.strokeStyle = e.whiskerColor),
							  (f.lineWidth = 2 * e.whiskerThickness),
							  0 < e.whiskerThickness &&
									(f.beginPath(),
									f.moveTo(Math.floor(e.x3 - e.whiskerLength / 2), e.y4),
									f.lineTo(Math.ceil(e.x3 + e.whiskerLength / 2), e.y4),
									f.stroke(),
									f.beginPath(),
									f.moveTo(Math.floor(e.x3 - e.whiskerLength / 2), e.y1),
									f.lineTo(Math.ceil(e.x3 + e.whiskerLength / 2), e.y1),
									f.stroke()),
							  (f.globalAlpha = 1),
							  (f.strokeStyle = e.lineColor),
							  (f.lineWidth = 2 * e.lineThickness),
							  0 < e.lineThickness &&
									(f.beginPath(),
									f.moveTo(e.x1, e.y5),
									f.lineTo(e.x2, e.y5),
									f.stroke()),
							  f.restore(),
							  (f.globalAlpha = 1))
							: "error" === b.type &&
							  E(
									f,
									e.x1,
									e.y1,
									e.x2,
									e.y2,
									"white",
									e.whiskerProperties,
									e.stemProperties,
									e.isXYSwapped,
									0.3
							  ));
				}
			}
			f.restore();
			f.globalAlpha = 1;
			f.beginPath();
		};
		qa.prototype.getToolTipInnerHTML = function (a) {
			a = a.entries;
			var d = null,
				c = null,
				b = null,
				e = 0,
				h = "";
			this.isToolTipDefinedInData = !0;
			for (var g = 0; g < a.length; g++)
				if (a[g].dataSeries.toolTipContent || a[g].dataPoint.toolTipContent) {
					this.isToolTipDefinedInData = !1;
					break;
				}
			if (
				this.isToolTipDefinedInData &&
				((this.content && "function" === typeof this.content) ||
					this.contentFormatter)
			)
				(a = { chart: this.chart, toolTip: this.options, entries: a }),
					(d = this.contentFormatter
						? this.contentFormatter(a)
						: this.content(a));
			else if (this.shared && "none" !== this.chart.plotInfo.axisPlacement) {
				for (var q = null, l = "", g = 0; g < a.length; g++)
					(c = a[g].dataSeries),
						(b = a[g].dataPoint),
						(e = a[g].index),
						(h = ""),
						0 === g &&
							this.isToolTipDefinedInData &&
							!this.content &&
							(this.chart.axisX && 0 < this.chart.axisX.length
								? (l +=
										"undefined" !== typeof this.chart.axisX[0].labels[b.x]
											? this.chart.axisX[0].labels[b.x]
											: "{x}")
								: this.chart.axisX2 &&
								  0 < this.chart.axisX2.length &&
								  (l +=
										"undefined" !== typeof this.chart.axisX2[0].labels[b.x]
											? this.chart.axisX2[0].labels[b.x]
											: "{x}"),
							(l += "</br>"),
							(l = this.chart.replaceKeywordsWithValue(l, b, c, e))),
						null === b.toolTipContent ||
							("undefined" === typeof b.toolTipContent &&
								null === c.options.toolTipContent) ||
							("line" === c.type ||
							"stepLine" === c.type ||
							"spline" === c.type ||
							"area" === c.type ||
							"stepArea" === c.type ||
							"splineArea" === c.type ||
							"column" === c.type ||
							"bar" === c.type ||
							"scatter" === c.type ||
							"stackedColumn" === c.type ||
							"stackedColumn100" === c.type ||
							"stackedBar" === c.type ||
							"stackedBar100" === c.type ||
							"stackedArea" === c.type ||
							"stackedArea100" === c.type ||
							"waterfall" === c.type
								? (this.chart.axisX &&
										1 < this.chart.axisX.length &&
										(h +=
											q != c.axisXIndex
												? c.axisX.title
													? c.axisX.title + "<br/>"
													: "X:{axisXIndex}<br/>"
												: ""),
								  (h += b.toolTipContent
										? b.toolTipContent
										: c.toolTipContent
										? c.toolTipContent
										: this.content && "function" !== typeof this.content
										? this.content
										: "<span>{name}:</span>&nbsp;&nbsp;{y}"),
								  (q = c.axisXIndex))
								: "bubble" === c.type
								? (this.chart.axisX &&
										1 < this.chart.axisX.length &&
										(h +=
											q != c.axisXIndex
												? c.axisX.title
													? c.axisX.title + "<br/>"
													: "X:{axisXIndex}<br/>"
												: ""),
								  (h += b.toolTipContent
										? b.toolTipContent
										: c.toolTipContent
										? c.toolTipContent
										: this.content && "function" !== typeof this.content
										? this.content
										: "<span>{name}:</span>&nbsp;&nbsp;{y}, &nbsp;&nbsp;{z}"))
								: "rangeColumn" === c.type ||
								  "rangeBar" === c.type ||
								  "rangeArea" === c.type ||
								  "rangeSplineArea" === c.type ||
								  "error" === c.type
								? (this.chart.axisX &&
										1 < this.chart.axisX.length &&
										(h +=
											q != c.axisXIndex
												? c.axisX.title
													? c.axisX.title + "<br/>"
													: "X:{axisXIndex}<br/>"
												: ""),
								  (h += b.toolTipContent
										? b.toolTipContent
										: c.toolTipContent
										? c.toolTipContent
										: this.content && "function" !== typeof this.content
										? this.content
										: "<span>{name}:</span>&nbsp;&nbsp;{y[0]},&nbsp;{y[1]}"))
								: "candlestick" === c.type || "ohlc" === c.type
								? (this.chart.axisX &&
										1 < this.chart.axisX.length &&
										(h +=
											q != c.axisXIndex
												? c.axisX.title
													? c.axisX.title + "<br/>"
													: "X:{axisXIndex}<br/>"
												: ""),
								  (h += b.toolTipContent
										? b.toolTipContent
										: c.toolTipContent
										? c.toolTipContent
										: this.content && "function" !== typeof this.content
										? this.content
										: "<span>{name}:</span><br/>Open: &nbsp;&nbsp;{y[0]}<br/>High: &nbsp;&nbsp;&nbsp;{y[1]}<br/>Low:&nbsp;&nbsp;&nbsp;{y[2]}<br/>Close: &nbsp;&nbsp;{y[3]}"))
								: "boxAndWhisker" === c.type &&
								  (this.chart.axisX &&
										1 < this.chart.axisX.length &&
										(h +=
											q != c.axisXIndex
												? c.axisX.title
													? c.axisX.title + "<br/>"
													: "X:{axisXIndex}<br/>"
												: ""),
								  (h += b.toolTipContent
										? b.toolTipContent
										: c.toolTipContent
										? c.toolTipContent
										: this.content && "function" !== typeof this.content
										? this.content
										: "<span>{name}:</span><br/>Minimum: &nbsp;&nbsp;{y[0]}<br/>Q1: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[1]}<br/>Q2: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[4]}<br/>Q3: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[2]}<br/>Maximum: &nbsp;{y[3]}")),
							null === d && (d = ""),
							!0 === this.reversed
								? ((d = this.chart.replaceKeywordsWithValue(h, b, c, e) + d),
								  g < a.length - 1 && (d = "</br>" + d))
								: ((d += this.chart.replaceKeywordsWithValue(h, b, c, e)),
								  g < a.length - 1 && (d += "</br>")));
				null !== d && (d = l + d);
			} else {
				c = a[0].dataSeries;
				b = a[0].dataPoint;
				e = a[0].index;
				if (
					null === b.toolTipContent ||
					("undefined" === typeof b.toolTipContent &&
						null === c.options.toolTipContent)
				)
					return null;
				"line" === c.type ||
				"stepLine" === c.type ||
				"spline" === c.type ||
				"area" === c.type ||
				"stepArea" === c.type ||
				"splineArea" === c.type ||
				"column" === c.type ||
				"bar" === c.type ||
				"scatter" === c.type ||
				"stackedColumn" === c.type ||
				"stackedColumn100" === c.type ||
				"stackedBar" === c.type ||
				"stackedBar100" === c.type ||
				"stackedArea" === c.type ||
				"stackedArea100" === c.type ||
				"waterfall" === c.type
					? (h = b.toolTipContent
							? b.toolTipContent
							: c.toolTipContent
							? c.toolTipContent
							: this.content && "function" !== typeof this.content
							? this.content
							: "<span>" +
							  (b.label ? "{label}" : "{x}") +
							  ":</span>&nbsp;&nbsp;{y}")
					: "bubble" === c.type
					? (h = b.toolTipContent
							? b.toolTipContent
							: c.toolTipContent
							? c.toolTipContent
							: this.content && "function" !== typeof this.content
							? this.content
							: "<span>" +
							  (b.label ? "{label}" : "{x}") +
							  ":</span>&nbsp;&nbsp;{y}, &nbsp;&nbsp;{z}")
					: "pie" === c.type ||
					  "doughnut" === c.type ||
					  "funnel" === c.type ||
					  "pyramid" === c.type
					? (h = b.toolTipContent
							? b.toolTipContent
							: c.toolTipContent
							? c.toolTipContent
							: this.content && "function" !== typeof this.content
							? this.content
							: "<span>" +
							  (b.name
									? "{name}:</span>&nbsp;&nbsp;"
									: b.label
									? "{label}:</span>&nbsp;&nbsp;"
									: "</span>") +
							  "{y}")
					: "rangeColumn" === c.type ||
					  "rangeBar" === c.type ||
					  "rangeArea" === c.type ||
					  "rangeSplineArea" === c.type ||
					  "error" === c.type
					? (h = b.toolTipContent
							? b.toolTipContent
							: c.toolTipContent
							? c.toolTipContent
							: this.content && "function" !== typeof this.content
							? this.content
							: "<span>" +
							  (b.label ? "{label}" : "{x}") +
							  " :</span>&nbsp;&nbsp;{y[0]}, &nbsp;{y[1]}")
					: "candlestick" === c.type || "ohlc" === c.type
					? (h = b.toolTipContent
							? b.toolTipContent
							: c.toolTipContent
							? c.toolTipContent
							: this.content && "function" !== typeof this.content
							? this.content
							: "<span>" +
							  (b.label ? "{label}" : "{x}") +
							  "</span><br/>Open: &nbsp;&nbsp;{y[0]}<br/>High: &nbsp;&nbsp;&nbsp;{y[1]}<br/>Low: &nbsp;&nbsp;&nbsp;&nbsp;{y[2]}<br/>Close: &nbsp;&nbsp;{y[3]}")
					: "boxAndWhisker" === c.type &&
					  (h = b.toolTipContent
							? b.toolTipContent
							: c.toolTipContent
							? c.toolTipContent
							: this.content && "function" !== typeof this.content
							? this.content
							: "<span>" +
							  (b.label ? "{label}" : "{x}") +
							  "</span><br/>Minimum: &nbsp;&nbsp;{y[0]}<br/>Q1: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[1]}<br/>Q2: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[4]}<br/>Q3: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[2]}<br/>Maximum: &nbsp;{y[3]}");
				null === d && (d = "");
				d += this.chart.replaceKeywordsWithValue(h, b, c, e);
			}
			return d;
		};
		qa.prototype.enableAnimation = function () {
			if (!this.container.style.WebkitTransition) {
				var a = this.getContainerTransition(this.containerTransitionDuration);
				this.container.style.WebkitTransition = a;
				this.container.style.MsTransition = a;
				this.container.style.transition = a;
				this.container.style.MozTransition = this.mozContainerTransition;
			}
		};
		qa.prototype.disableAnimation = function () {
			this.container.style.WebkitTransition &&
				((this.container.style.WebkitTransition = ""),
				(this.container.style.MozTransition = ""),
				(this.container.style.MsTransition = ""),
				(this.container.style.transition = ""));
		};
		qa.prototype.hide = function (a) {
			this.container &&
				((this.container.style.display = "none"),
				(this.currentSeriesIndex = -1),
				(this._prevY = this._prevX = NaN),
				("undefined" === typeof a || a) && this.chart.resetOverlayedCanvas());
		};
		qa.prototype.show = function (a, d, c) {
			this._updateToolTip(a, d, "undefined" === typeof c ? !1 : c);
		};
		qa.prototype.showAtIndex = function (a, d) {};
		qa.prototype.showAtX = function (a, d) {
			if (!this.enabled) return !1;
			this.chart.clearedOverlayedCanvas = null;
			var c,
				b,
				e,
				h = [];
			e = !1;
			d = !g(d) && 0 <= d && d < this.chart.data.length ? d : 0;
			if (this.shared)
				for (var q = 0; q < this.chart.data.length; q++)
					(c = this.chart.data[q]),
						(b = c.getDataPointAtX(a, !1)) &&
							b.dataPoint &&
							!g(b.dataPoint.y) &&
							c.visible &&
							((b.dataSeries = c), h.push(b));
			else
				(c = this.chart.data[d]),
					(b = c.getDataPointAtX(a, !1)) &&
						b.dataPoint &&
						!g(b.dataPoint.y) &&
						c.visible &&
						((b.dataSeries = c), h.push(b));
			if (0 < h.length) {
				for (q = 0; q < h.length; q++)
					if (
						((b = h[q]),
						b.dataPoint.x < b.dataSeries.axisX.viewportMinimum ||
							b.dataPoint.x > b.dataSeries.axisX.viewportMaximum ||
							b.dataPoint.y < b.dataSeries.axisY.viewportMinimum ||
							b.dataPoint.y > b.dataSeries.axisY.viewportMaximum)
					)
						e = !0;
					else {
						e = !1;
						break;
					}
				if (e) return this.hide(), !1;
				this.highlightObjects(h);
				this._entries = h;
				q = "";
				q = this.getToolTipInnerHTML({ entries: h });
				if (null !== q) {
					this.contentDiv.innerHTML = q;
					if (
						this.isToolTipDefinedInData &&
						g(this.options.content) &&
						g(this.options.contentFormatter)
					)
						for (
							b = this.contentDiv.getElementsByTagName("span"), q = 0;
							q < h.length;
							q++
						)
							b[q] &&
								(b[q].style.color = this.chart.replaceKeywordsWithValue(
									"{color}",
									h[q].dataPoint,
									h[q].dataSeries,
									h[q].index
								));
					q = !1;
					"none" === this.container.style.display &&
						((q = !0), (this.container.style.display = "block"));
					try {
						(this.contentDiv.style.background = this.backgroundColor
							? this.backgroundColor
							: t
							? "rgba(255,255,255,.9)"
							: "rgb(255,255,255)"),
							(this.borderColor =
								"waterfall" === h[0].dataSeries.type
									? (this.contentDiv.style.borderRightColor =
											this.contentDiv.style.borderLeftColor =
											this.contentDiv.style.borderColor =
												this.options.borderColor
													? this.options.borderColor
													: h[0].dataPoint.color
													? h[0].dataPoint.color
													: 0 < h[0].dataPoint.y
													? h[0].dataSeries.risingColor
													: h[0].dataSeries.fallingColor)
									: "error" === h[0].dataSeries.type
									? (this.contentDiv.style.borderRightColor =
											this.contentDiv.style.borderLeftColor =
											this.contentDiv.style.borderColor =
												this.options.borderColor
													? this.options.borderColor
													: h[0].dataSeries.color
													? h[0].dataSeries.color
													: h[0].dataSeries._colorSet[
															c.index % h[0].dataSeries._colorSet.length
													  ])
									: (this.contentDiv.style.borderRightColor =
											this.contentDiv.style.borderLeftColor =
											this.contentDiv.style.borderColor =
												this.options.borderColor
													? this.options.borderColor
													: h[0].dataPoint.color
													? h[0].dataPoint.color
													: h[0].dataSeries.color
													? h[0].dataSeries.color
													: h[0].dataSeries._colorSet[
															h[0].index % h[0].dataSeries._colorSet.length
													  ])),
							(this.contentDiv.style.borderWidth =
								this.borderThickness || 0 === this.borderThickness
									? this.borderThickness + "px"
									: "2px"),
							(this.contentDiv.style.borderRadius =
								this.cornerRadius || 0 === this.cornerRadius
									? this.cornerRadius + "px"
									: "5px"),
							(this.container.style.borderRadius =
								this.contentDiv.style.borderRadius),
							(this.contentDiv.style.fontSize =
								this.fontSize || 0 === this.fontSize
									? this.fontSize + "px"
									: "14px"),
							(this.contentDiv.style.color = this.fontColor
								? this.fontColor
								: "#000000"),
							(this.contentDiv.style.fontFamily = this.fontFamily
								? this.fontFamily
								: "Calibri, Arial, Georgia, serif;"),
							(this.contentDiv.style.fontWeight = this.fontWeight
								? this.fontWeight
								: "normal"),
							(this.contentDiv.style.fontStyle = this.fontStyle
								? this.fontStyle
								: t
								? "italic"
								: "normal");
					} catch (l) {}
					"pie" === h[0].dataSeries.type ||
					"doughnut" === h[0].dataSeries.type ||
					"funnel" === h[0].dataSeries.type ||
					"pyramid" === h[0].dataSeries.type
						? (c = mouseX - 10 - this.container.clientWidth)
						: ((c =
								"bar" === h[0].dataSeries.type ||
								"rangeBar" === h[0].dataSeries.type ||
								"stackedBar" === h[0].dataSeries.type ||
								"stackedBar100" === h[0].dataSeries.type
									? (h[0].dataSeries.axisY.convertValueToPixel(
											h[0].dataPoint.y
									  ) -
											this.container.clientWidth) <<
									  0
									: (h[0].dataSeries.axisX.convertValueToPixel(
											h[0].dataPoint.x
									  ) -
											this.container.clientWidth) <<
									  0),
						  (c -= 10));
					0 > c && (c += this.container.clientWidth + 20);
					c + this.container.clientWidth >
						Math.max(this.chart.container.clientWidth, this.chart.width) &&
						(c = Math.max(
							0,
							Math.max(this.chart.container.clientWidth, this.chart.width) -
								this.container.clientWidth
						));
					h =
						1 !== h.length ||
						this.shared ||
						("line" !== h[0].dataSeries.type &&
							"stepLine" !== h[0].dataSeries.type &&
							"spline" !== h[0].dataSeries.type &&
							"area" !== h[0].dataSeries.type &&
							"stepArea" !== h[0].dataSeries.type &&
							"splineArea" !== h[0].dataSeries.type)
							? "bar" === h[0].dataSeries.type ||
							  "rangeBar" === h[0].dataSeries.type ||
							  "stackedBar" === h[0].dataSeries.type ||
							  "stackedBar100" === h[0].dataSeries.type
								? h[0].dataSeries.axisX.convertValueToPixel(h[0].dataPoint.x)
								: h[0].dataSeries.axisY.convertValueToPixel(h[0].dataPoint.y)
							: h[0].dataSeries.axisY.convertValueToPixel(h[0].dataPoint.y);
					h = -h + 10;
					0 < h + this.container.clientHeight + 5 &&
						(h -= h + this.container.clientHeight + 5 - 0);
					this.fixMozTransitionDelay(c, h);
					!this.animationEnabled || q
						? this.disableAnimation()
						: (this.enableAnimation(),
						  (this.container.style.MozTransition =
								this.mozContainerTransition));
					this.container.style.left = c + "px";
					this.container.style.bottom = h + "px";
				} else return this.hide(!1), !1;
			} else return this.hide(), !1;
			return !0;
		};
		qa.prototype.fixMozTransitionDelay = function (a, d) {
			if (20 < this.chart._eventManager.lastObjectId)
				this.mozContainerTransition = this.getContainerTransition(0);
			else {
				var c = parseFloat(this.container.style.left),
					c = isNaN(c) ? 0 : c,
					b = parseFloat(this.container.style.bottom),
					b = isNaN(b) ? 0 : b;
				10 < Math.sqrt(Math.pow(c - a, 2) + Math.pow(b - d, 2))
					? (this.mozContainerTransition = this.getContainerTransition(0.1))
					: (this.mozContainerTransition = this.getContainerTransition(0));
			}
		};
		qa.prototype.getContainerTransition = function (a) {
			return "left " + a + "s ease-out 0s, bottom " + a + "s ease-out 0s";
		};
		ga.prototype.reset = function () {
			this.lastObjectId = 0;
			this.objectMap = [];
			this.rectangularRegionEventSubscriptions = [];
			this.previousDataPointEventObject = null;
			this.eventObjects = [];
			t &&
				(this.ghostCtx.clearRect(0, 0, this.chart.width, this.chart.height),
				this.ghostCtx.beginPath());
		};
		ga.prototype.getNewObjectTrackingId = function () {
			return ++this.lastObjectId;
		};
		ga.prototype.mouseEventHandler = function (a) {
			if ("mousemove" === a.type || "click" === a.type) {
				var d = [],
					c = Aa(a),
					b = null;
				if (
					(b = this.chart.getObjectAtXY(c.x, c.y, !1)) &&
					"undefined" !== typeof this.objectMap[b]
				)
					if (((b = this.objectMap[b]), "dataPoint" === b.objectType)) {
						var e = this.chart.data[b.dataSeriesIndex],
							h = e.dataPoints[b.dataPointIndex],
							g = b.dataPointIndex;
						b.eventParameter = {
							x: c.x,
							y: c.y,
							dataPoint: h,
							dataSeries: e.options,
							dataPointIndex: g,
							dataSeriesIndex: e.index,
							chart: this.chart,
						};
						b.eventContext = {
							context: h,
							userContext: h,
							mouseover: "mouseover",
							mousemove: "mousemove",
							mouseout: "mouseout",
							click: "click",
						};
						d.push(b);
						b = this.objectMap[e.id];
						b.eventParameter = {
							x: c.x,
							y: c.y,
							dataPoint: h,
							dataSeries: e.options,
							dataPointIndex: g,
							dataSeriesIndex: e.index,
							chart: this.chart,
						};
						b.eventContext = {
							context: e,
							userContext: e.options,
							mouseover: "mouseover",
							mousemove: "mousemove",
							mouseout: "mouseout",
							click: "click",
						};
						d.push(this.objectMap[e.id]);
					} else
						"legendItem" === b.objectType &&
							((e = this.chart.data[b.dataSeriesIndex]),
							(h =
								null !== b.dataPointIndex
									? e.dataPoints[b.dataPointIndex]
									: null),
							(b.eventParameter = {
								x: c.x,
								y: c.y,
								dataSeries: e.options,
								dataPoint: h,
								dataPointIndex: b.dataPointIndex,
								dataSeriesIndex: b.dataSeriesIndex,
								chart: this.chart,
							}),
							(b.eventContext = {
								context: this.chart.legend,
								userContext: this.chart.legend.options,
								mouseover: "itemmouseover",
								mousemove: "itemmousemove",
								mouseout: "itemmouseout",
								click: "itemclick",
							}),
							d.push(b));
				e = [];
				for (c = 0; c < this.mouseoveredObjectMaps.length; c++) {
					h = !0;
					for (b = 0; b < d.length; b++)
						if (d[b].id === this.mouseoveredObjectMaps[c].id) {
							h = !1;
							break;
						}
					h
						? this.fireEvent(this.mouseoveredObjectMaps[c], "mouseout", a)
						: e.push(this.mouseoveredObjectMaps[c]);
				}
				this.mouseoveredObjectMaps = e;
				for (c = 0; c < d.length; c++) {
					e = !1;
					for (b = 0; b < this.mouseoveredObjectMaps.length; b++)
						if (d[c].id === this.mouseoveredObjectMaps[b].id) {
							e = !0;
							break;
						}
					e ||
						(this.fireEvent(d[c], "mouseover", a),
						this.mouseoveredObjectMaps.push(d[c]));
					"click" === a.type
						? this.fireEvent(d[c], "click", a)
						: "mousemove" === a.type && this.fireEvent(d[c], "mousemove", a);
				}
			}
		};
		ga.prototype.fireEvent = function (a, d, c) {
			if (a && d) {
				var b = a.eventParameter,
					e = a.eventContext,
					h = a.eventContext.userContext;
				h && e && h[e[d]] && h[e[d]].call(h, b);
				"mouseout" !== d
					? h.cursor &&
					  h.cursor !== c.target.style.cursor &&
					  (c.target.style.cursor = h.cursor)
					: ((c.target.style.cursor = this.chart._defaultCursor),
					  delete a.eventParameter,
					  delete a.eventContext);
				"click" === d &&
					"dataPoint" === a.objectType &&
					this.chart.pieDoughnutClickHandler &&
					this.chart.pieDoughnutClickHandler.call(
						this.chart.data[a.dataSeriesIndex],
						b
					);
				"click" === d &&
					"dataPoint" === a.objectType &&
					this.chart.funnelPyramidClickHandler &&
					this.chart.funnelPyramidClickHandler.call(
						this.chart.data[a.dataSeriesIndex],
						b
					);
			}
		};
		ma.prototype.animate = function (a, d, c, b, e) {
			var h = this;
			this.chart.isAnimating = !0;
			e = e || z.easing.linear;
			c &&
				this.animations.push({
					startTime: new Date().getTime() + (a ? a : 0),
					duration: d,
					animationCallback: c,
					onComplete: b,
				});
			for (a = []; 0 < this.animations.length; )
				if (
					((d = this.animations.shift()),
					(c = new Date().getTime()),
					(b = 0),
					d.startTime <= c &&
						((b = e(Math.min(c - d.startTime, d.duration), 0, 1, d.duration)),
						(b = Math.min(b, 1)),
						isNaN(b) || !isFinite(b)) &&
						(b = 1),
					1 > b && a.push(d),
					d.animationCallback(b),
					1 <= b && d.onComplete)
				)
					d.onComplete();
			this.animations = a;
			0 < this.animations.length
				? (this.animationRequestId = this.chart.requestAnimFrame.call(
						window,
						function () {
							h.animate.call(h);
						}
				  ))
				: (this.chart.isAnimating = !1);
		};
		ma.prototype.cancelAllAnimations = function () {
			this.animations = [];
			this.animationRequestId &&
				this.chart.cancelRequestAnimFrame.call(window, this.animationRequestId);
			this.animationRequestId = null;
			this.chart.isAnimating = !1;
		};
		var z = {
				yScaleAnimation: function (a, d) {
					if (0 !== a) {
						var c = d.dest,
							b = d.source.canvas,
							e = d.animationBase;
						c.drawImage(
							b,
							0,
							0,
							b.width,
							b.height,
							0,
							e - e * a,
							c.canvas.width / ka,
							(a * c.canvas.height) / ka
						);
					}
				},
				xScaleAnimation: function (a, d) {
					if (0 !== a) {
						var c = d.dest,
							b = d.source.canvas,
							e = d.animationBase;
						c.drawImage(
							b,
							0,
							0,
							b.width,
							b.height,
							e - e * a,
							0,
							(a * c.canvas.width) / ka,
							c.canvas.height / ka
						);
					}
				},
				xClipAnimation: function (a, d) {
					if (0 !== a) {
						var c = d.dest,
							b = d.source.canvas;
						c.save();
						0 < a &&
							c.drawImage(
								b,
								0,
								0,
								b.width * a,
								b.height,
								0,
								0,
								(b.width * a) / ka,
								b.height / ka
							);
						c.restore();
					}
				},
				fadeInAnimation: function (a, d) {
					if (0 !== a) {
						var c = d.dest,
							b = d.source.canvas;
						c.save();
						c.globalAlpha = a;
						c.drawImage(
							b,
							0,
							0,
							b.width,
							b.height,
							0,
							0,
							c.canvas.width / ka,
							c.canvas.height / ka
						);
						c.restore();
					}
				},
				easing: {
					linear: function (a, d, c, b) {
						return (c * a) / b + d;
					},
					easeOutQuad: function (a, d, c, b) {
						return -c * (a /= b) * (a - 2) + d;
					},
					easeOutQuart: function (a, d, c, b) {
						return -c * ((a = a / b - 1) * a * a * a - 1) + d;
					},
					easeInQuad: function (a, d, c, b) {
						return c * (a /= b) * a + d;
					},
					easeInQuart: function (a, d, c, b) {
						return c * (a /= b) * a * a * a + d;
					},
				},
			},
			s = {
				drawMarker: function (a, d, c, b, e, h, g, q) {
					if (c) {
						var l = 1;
						c.fillStyle = h ? h : "#000000";
						c.strokeStyle = g ? g : "#000000";
						c.lineWidth = q ? q : 0;
						c.setLineDash && c.setLineDash(S("solid", q));
						"circle" === b
							? (c.moveTo(a, d),
							  c.beginPath(),
							  c.arc(a, d, e / 2, 0, 2 * Math.PI, !1),
							  h && c.fill(),
							  q &&
									(g
										? c.stroke()
										: ((l = c.globalAlpha),
										  (c.globalAlpha = 0.15),
										  (c.strokeStyle = "black"),
										  c.stroke(),
										  (c.globalAlpha = l))))
							: "square" === b
							? (c.beginPath(),
							  c.rect(a - e / 2, d - e / 2, e, e),
							  h && c.fill(),
							  q &&
									(g
										? c.stroke()
										: ((l = c.globalAlpha),
										  (c.globalAlpha = 0.15),
										  (c.strokeStyle = "black"),
										  c.stroke(),
										  (c.globalAlpha = l))))
							: "triangle" === b
							? (c.beginPath(),
							  c.moveTo(a - e / 2, d + e / 2),
							  c.lineTo(a + e / 2, d + e / 2),
							  c.lineTo(a, d - e / 2),
							  c.closePath(),
							  h && c.fill(),
							  q &&
									(g
										? c.stroke()
										: ((l = c.globalAlpha),
										  (c.globalAlpha = 0.15),
										  (c.strokeStyle = "black"),
										  c.stroke(),
										  (c.globalAlpha = l))),
							  c.beginPath())
							: "cross" === b &&
							  ((c.strokeStyle = h),
							  (c.lineWidth = e / 4),
							  c.beginPath(),
							  c.moveTo(a - e / 2, d - e / 2),
							  c.lineTo(a + e / 2, d + e / 2),
							  c.stroke(),
							  c.moveTo(a + e / 2, d - e / 2),
							  c.lineTo(a - e / 2, d + e / 2),
							  c.stroke());
					}
				},
				drawMarkers: function (a) {
					for (var d = 0; d < a.length; d++) {
						var c = a[d];
						s.drawMarker(
							c.x,
							c.y,
							c.ctx,
							c.type,
							c.size,
							c.color,
							c.borderColor,
							c.borderThickness
						);
					}
				},
			};
		return v;
	})());
	Ja.version = "v3.6.1 GA";
	window.CanvasJS &&
		Ja &&
		!window.CanvasJS.Chart &&
		(window.CanvasJS.Chart = Ja);
	ra.StockChart = {
		width: 500,
		height: 600,
		backgroundColor: "white",
		theme: "light1",
		animationEnabled: !1,
		animationDuration: 1200,
		culture: "en",
		creditHref: "",
		creditText: "CanvasJS",
		exportEnabled: !1,
		exportFileName: "StockChart",
		colorSet: "colorSet1",
		rangeChanging: null,
		rangeChanged: null,
		publicProperties: {
			title: "readWrite",
			subtitles: "readWrite",
			toolbar: "readWrite",
			navigator: "readWrite",
			rangeSelector: "readWrite",
			charts: "readWrite",
			options: "readWrite",
			bounds: "readOnly",
			container: "readOnly",
			selectedColorSet: "readOnly",
		},
	};
	ra.Navigator = {
		enabled: !0,
		width: 500,
		height: 70,
		verticalAlign: "bottom",
		horizontalAlign: "center",
		dynamicUpdate: !0,
		backgroundColor: "#fff",
		animationEnabled: !1,
		animationDuration: 1200,
		theme: "light1",
		publicProperties: {
			slider: "readWrite",
			backgroundColor: "readWrite",
			animationEnabled: "readWrite",
			animationDuration: "readWrite",
			theme: "readWrite",
			axisX: "readWrite",
			axisY: "readWrite",
			axisX2: "readWrite",
			axisY2: "readWrite",
			data: "readWrite",
			options: "readWrite",
			bounds: "readOnly",
		},
	};
	ra.RangeSelector = {
		enabled: !0,
		width: 500,
		height: 35,
		label: "Range",
		verticalAlign: "top",
		horizontalAlign: "center",
		selectedRangeButtonIndex: null,
		publicProperties: {
			options: "readWrite",
			buttons: "readWrite",
			inputFields: "readWrite",
			buttonStyle: "readWrite",
			bounds: "readOnly",
		},
	};
	ra.RangeButton = {
		range: null,
		rangeType: null,
		label: null,
		style: "position: inline; margin: 5px 0;text-align:center;cursor: pointer;",
		backgroundColorDisabled: "#ddd",
		publicProperties: { options: "readWrite", bounds: "readOnly" },
	};
	ra.ButtonStyle = {
		backgroundColor: "#fff",
		backgroundColorOnHover: "#2196f3",
		backgroundColorOnSelect: "#2196f3",
		borderColor: "#2196f3",
		borderThickness: 2,
		labelFontColor: "#000",
		labelFontColorOnHover: "#fff",
		labelFontSize: 14,
		labelFontStyle: "normal",
		labelFontFamily: t ? "Trebuchet MS, Helvetica, sans-serif" : "Arial",
		labelFontWeight: "normal",
		width: null,
		maxWidth: null,
		padding: { left: 5, right: 5, top: 2, bottom: 2 },
		spacing: 5,
		cursor: "pointer",
		publicProperties: { options: "readWrite" },
	};
	ra.InputFields = {
		enabled: !0,
		startValue: null,
		endValue: null,
		valueType: "dateTime",
		valueFormatString: "MMM DD YYYY",
		publicProperties: { style: "readWrite", options: "readWrite" },
	};
	ra.Style = {
		backgroundColor: "#fff",
		borderColor: "#2196f3",
		borderColorOnFocus: "#008eff",
		borderThickness: 2,
		fontColor: "#000",
		fontSize: 12,
		fontStyle: "normal",
		fontFamily: t ? "Trebuchet MS, Helvetica, sans-serif" : "Arial",
		fontWeight: "normal",
		width: null,
		maxWidth: null,
		padding: { left: 5, right: 5, top: 2, bottom: 2 },
		spacing: 5,
		cursor: "text",
		publicProperties: { options: "readWrite" },
	};
	ra.Slider = {
		maskColor: "#d9e8f9",
		maskInverted: !1,
		maskOpacity: 0.7,
		handleColor: "#ddd",
		handleBorderColor: "#aaa",
		handleBorderThickness: 1,
		handleWidth: 8,
		handleHeight: 18,
		minimum: null,
		maximum: null,
		outlineThickness: 1,
		outlineColor: "#666",
		outlineInverted: !1,
		publicProperties: { options: "readWrite" },
	};
	ra.CultureInfo.rangeSelector = {
		fromText: "From",
		toText: "To",
		rangeText: "Range",
	};
	K = "#FFFFFF";
	W = "#333333";
	O = "#000000";
	Fa = {
		colorSet: "colorSet1",
		backgroundColor: K,
		title: Fa.title,
		subtitles: Fa.subtitles,
		charts: [Fa],
		rangeSelector: {
			buttonStyle: {
				backgroundColor: "#FEFEFE",
				backgroundColorOnHover: "#41a5f5",
				backgroundColorOnSelect: "#2196f3",
				borderColor: "#2196f3",
				borderThickness: 1,
				labelFontColor: O,
				labelFontColorOnHover: "#ffffff",
				labelFontSize: 14,
				labelFontStyle: "normal",
				labelFontFamily: w,
				labelFontWeight: "normal",
				spacing: 0,
			},
			inputFields: {
				style: {
					backgroundColor: "#ffffff",
					borderColor: "#2196f3",
					borderColorOnFocus: "#2196f3",
					borderThickness: 1,
					fontColor: O,
					fontSize: 14,
					fontStyle: "normal",
					fontFamily: w,
					fontWeight: "normal",
				},
			},
		},
		navigator: {
			backgroundColor: K,
			slider: {
				maskColor: "#d9e8f9",
				maskOpacity: 0.6,
				handleColor: "#efefef",
				handleBorderColor: "#666666",
				handleBorderThickness: 1,
				outlineColor: "#666666",
				outlineThickness: 1,
			},
		},
		toolbar: Fa.toolbar,
	};
	K = "#FFFFFF";
	W = "#3A3A3A";
	O = "#666666";
	Ia = {
		colorSet: "colorSet2",
		backgroundColor: K,
		title: Ia.title,
		subtitles: Ia.subtitles,
		charts: [Ia],
		rangeSelector: {
			buttonStyle: {
				backgroundColor: "#FEFEFE",
				backgroundColorOnHover: "#41a5f5",
				backgroundColorOnSelect: "#2196f3",
				borderColor: "#2196f3",
				borderThickness: 1,
				labelFontColor: O,
				labelFontColorOnHover: "#ffffff",
				labelFontSize: 14,
				labelFontStyle: "normal",
				labelFontFamily: w,
				labelFontWeight: "normal",
				spacing: 0,
			},
			inputFields: {
				style: {
					backgroundColor: "#ffffff",
					borderColor: "#2196f3",
					borderColorOnFocus: "#2196f3",
					borderThickness: 1,
					fontColor: O,
					fontSize: 14,
					fontStyle: "normal",
					fontFamily: w,
					fontWeight: "normal",
				},
			},
		},
		navigator: {
			backgroundColor: K,
			slider: {
				maskColor: "#D9E8F9",
				maskOpacity: 0.6,
				handleColor: "#EFEFEF",
				handleBorderColor: "#BBBBBB",
				handleBorderThickness: 1,
				outlineColor: "#BBBBBB",
				outlineThickness: 1,
			},
		},
		toolbar: Ia.toolbar,
	};
	K = "#2A2A2A";
	O = W = "#F5F5F5";
	za = {
		colorSet: "colorSet1",
		backgroundColor: K,
		title: za.title,
		subtitles: za.subtitles,
		charts: [za],
		rangeSelector: {
			buttonStyle: {
				backgroundColor: "#666666",
				backgroundColorOnHover: "#FF7372",
				backgroundColorOnSelect: "#FF7372",
				borderColor: "#FF7372",
				borderThickness: 1,
				labelFontColor: O,
				labelFontColorOnHover: "#F5F5F5",
				labelFontSize: 14,
				labelFontStyle: "normal",
				labelFontFamily: w,
				labelFontWeight: "normal",
				spacing: 0,
			},
			inputFields: {
				style: {
					backgroundColor: "#666666",
					borderColor: "#FF7372",
					borderColorOnFocus: "#FF7372",
					borderThickness: 1,
					fontColor: O,
					fontSize: 14,
					fontStyle: "normal",
					fontFamily: w,
					fontWeight: "normal",
				},
			},
		},
		navigator: {
			backgroundColor: K,
			slider: {
				maskColor: "#d9e8f9",
				maskOpacity: 0.6,
				handleColor: "#efefef",
				handleBorderColor: "#666666",
				handleBorderThickness: 1,
				outlineColor: "#FFFFFF",
				outlineThickness: 1,
			},
		},
		toolbar: za.toolbar,
	};
	K = "#32373A";
	O = W = "#FAFAFA";
	var bb = {
		light1: Fa,
		light2: Ia,
		dark1: za,
		dark2: {
			colorSet: "colorSet2",
			backgroundColor: K,
			title: Y.title,
			subtitles: Y.subtitles,
			charts: [Y],
			rangeSelector: {
				buttonStyle: {
					backgroundColor: "#666666",
					backgroundColorOnHover: "#FF7372",
					backgroundColorOnSelect: "#FF7372",
					borderColor: "#FF7372",
					borderThickness: 1,
					labelFontColor: O,
					labelFontColorOnHover: "#F5F5F5",
					labelFontSize: 14,
					labelFontStyle: "normal",
					labelFontFamily: w,
					labelFontWeight: "normal",
					spacing: 0,
				},
				inputFields: {
					style: {
						backgroundColor: "#666666",
						borderColor: "#FF7372",
						borderColorOnFocus: "#FF7372",
						borderThickness: 1,
						fontColor: O,
						fontSize: 14,
						fontStyle: "normal",
						fontFamily: w,
						fontWeight: "normal",
					},
				},
			},
			navigator: {
				backgroundColor: K,
				slider: {
					maskColor: "#d9e8f9",
					maskOpacity: 0.6,
					handleColor: "#efefef",
					handleBorderColor: "#666666",
					handleBorderThickness: 1,
					outlineColor: "#FFFFFF",
					outlineThickness: 1,
				},
			},
			toolbar: Y.toolbar,
		},
	};
	W = Ra.StockChart = (function () {
		function l(d) {
			var g;
			g = document.createElement("div");
			g.setAttribute("class", d);
			N(g, {
				position: "absolute",
				textAlign: "left",
				cursor: "auto",
				webkitTapHighlightColor: "transparent",
			});
			return g;
		}
		function v(d, q, l) {
			d = document.createElement(d);
			d.setAttribute("class", q);
			N(d, { webkitTapHighlightColor: "transparent" });
			g(l) || d.setAttribute("type", l);
			return d;
		}
		function E(d, q) {
			q = q || {};
			this.theme = g(q.theme) || g(ab[q.theme]) ? "light1" : q.theme;
			this.predefinedThemes = bb;
			E.base.constructor.call(this, "StockChart", null, q, null, null);
			this.optionsName = "stockChart";
			var l = this;
			this.sessionVariables = {};
			this._axisXMax = this._axisXMin = null;
			this._containerId = d;
			this._objectsInitialized = !1;
			this.ctx = null;
			this.plotArea = {
				canvas: null,
				ctx: null,
				x1: 0,
				y1: 0,
				x2: 0,
				y2: 0,
				width: 0,
				height: 0,
			};
			this._dataInRenderedOrder = [];
			this.allDOMEventHandlers = [];
			if (
				(this.container =
					"string" === typeof this._containerId
						? document.getElementById(this._containerId)
						: this._containerId)
			) {
				this.container.innerHTML = "";
				var s = 0,
					a = 0;
				this._defaultCursor = "default";
				s = this.options.width
					? this.width
					: 0 < this.container.clientWidth
					? this.container.clientWidth
					: this.width;
				a = this.options.height
					? this.height
					: 0 < this.container.clientHeight
					? this.container.clientHeight
					: this.height;
				this.width = s;
				this.height = a;
				this.x1 = this.y1 = 0;
				this.x2 = this.width;
				this.y2 = this.height;
				this.selectedColorSet =
					"undefined" !== typeof xa[this.colorSet]
						? xa[this.colorSet]
						: xa.colorSet1;
				this._canvasJSContainer = document.createElement("div");
				this._canvasJSContainer.setAttribute(
					"class",
					"canvasjs-stock-container"
				);
				this._canvasJSContainer.style.position = "relative";
				this._canvasJSContainer.style.userSelect =
					this._canvasJSContainer.style.WebkitUserSelect =
					this._canvasJSContainer.style.MozUserSelect =
					this._canvasJSContainer.style.msUserSelect =
						"none";
				this._canvasJSContainer.style.textAlign = "left";
				this._canvasJSContainer.style.cursor = "auto";
				this._canvasJSContainer.style.direction = "ltr";
				this._canvasJSContainer.style.fontFamily = w;
				t || (this._canvasJSContainer.style.height = "0px");
				this.container.appendChild(this._canvasJSContainer);
				this.canvas = sa(s, a);
				this.canvas.style.position = "absolute";
				this.canvas.getContext &&
					(this._canvasJSContainer.appendChild(this.canvas),
					(this.ctx = this.canvas.getContext("2d")),
					(this.ctx.textBaseline = "top"),
					Ga(this.ctx),
					(this.overlaidCanvas = sa(s, a)),
					(this.overlaidCanvas.style.position = "absolute"),
					(this.overlaidCanvas.style.webkitTapHighlightColor = "transparent"),
					this.overlaidCanvas.getContext &&
						(this._canvasJSContainer.appendChild(this.overlaidCanvas),
						(this.overlaidCanvasCtx = this.overlaidCanvas.getContext("2d")),
						(this.overlaidCanvasCtx.textBaseline = "top"),
						Ga(this.overlaidCanvasCtx)),
					t
						? (this.plotArea.ctx = this.ctx)
						: ((this.plotArea.canvas = sa(s, a)),
						  (this.plotArea.canvas.style.position = "absolute"),
						  this.plotArea.canvas.setAttribute("class", "plotAreaCanvas"),
						  this._canvasJSContainer.appendChild(this.plotArea.canvas),
						  (this.plotArea.ctx = this.plotArea.canvas.getContext("2d"))),
					(this._toolBar = document.createElement("div")),
					this._toolBar.setAttribute("class", "canvasjs-chart-toolbar"),
					N(this._toolBar, {
						position: "absolute",
						right: "1px",
						top: "1px",
						zIndex: 999,
					}),
					this._canvasJSContainer.appendChild(this._toolBar),
					(this.bounds = { x1: 0, y1: 0, x2: this.width, y2: this.height }),
					(this.charts = []),
					Da(this.charts),
					(this._eventManager = new P(this)),
					y(
						this.overlaidCanvas,
						"click",
						function (a) {
							l._mouseEventHandler(a);
						},
						this.allDOMEventHandlers
					),
					y(
						this.overlaidCanvas,
						"mousemove",
						function (a) {
							l._mouseEventHandler(a);
						},
						this.allDOMEventHandlers
					),
					y(
						this.overlaidCanvas,
						"mouseup",
						function (a) {
							l._mouseEventHandler(a);
						},
						this.allDOMEventHandlers
					),
					y(
						this.overlaidCanvas,
						"mousedown",
						function (a) {
							l._mouseEventHandler(a);
							ya(l._dropdownMenu);
						},
						this.allDOMEventHandlers
					),
					y(
						this.overlaidCanvas,
						"mouseout",
						function (a) {
							l._mouseEventHandler(a);
						},
						this.allDOMEventHandlers
					),
					y(
						this.overlaidCanvas,
						window.navigator.msPointerEnabled ? "MSPointerDown" : "touchstart",
						function (a) {
							l._touchEventHandler(a);
						},
						this.allDOMEventHandlers
					),
					y(
						this.overlaidCanvas,
						window.navigator.msPointerEnabled ? "MSPointerMove" : "touchmove",
						function (a) {
							l._touchEventHandler(a);
						},
						this.allDOMEventHandlers
					),
					y(
						this.overlaidCanvas,
						window.navigator.msPointerEnabled ? "MSPointerUp" : "touchend",
						function (a) {
							l._touchEventHandler(a);
						},
						this.allDOMEventHandlers
					),
					y(
						this.overlaidCanvas,
						window.navigator.msPointerEnabled
							? "MSPointerCancel"
							: "touchcancel",
						function (a) {
							l._touchEventHandler(a);
						},
						this.allDOMEventHandlers
					),
					(this.windowResizeHandler = y(
						window,
						"resize",
						function () {
							l._updateSize() && l.render();
						},
						this.allDOMEventHandlers
					)));
			} else
				window.console &&
					window.console.log(
						'CanvasJS Error: StockChart Container with id "' +
							this._containerId +
							'" was not found'
					);
		}
		function K(d, g) {
			K.base.constructor.call(this, d, g);
			this.options = g;
			this.dockInsidePlotArea = !1;
		}
		function O(d, g, l) {
			O.base.constructor.call(this, d, g, l);
			this.options = g;
			this.dockInsidePlotArea = !1;
		}
		function P(d) {
			this.stockChart = d;
			this.lastObjectId = 0;
			this.objectMap = [];
			this.rectangularRegionEventSubscriptions = [];
			this.ghostCanvas = sa(this.stockChart.width, this.stockChart.height);
			this.ghostCtx = this.ghostCanvas.getContext("2d");
			this.mouseoveredObjectMaps = [];
		}
		function D(d, g) {
			g = g || {};
			this.optionsName = "navigator";
			D.base.constructor.call(this, "Navigator", "navigator", g, null, d);
			this.parent = this.stockChart = d;
		}
		function U(d, q) {
			this.panel = l("canvasjs-slider-panel");
			this.parent = this.navigator = d;
			this.stockChart = d.stockChart;
			this.navigator.panel.appendChild(this.panel);
			U.base.constructor.call(this, "Slider", "slider", q, null, d);
			this.options =
				g(q.navigator) || g(q.navigator.slider) ? {} : q.navigator.slider;
			this.optionsName = "slider";
			this.panMode = !1;
			this.sessionVariables = {};
		}
		function S(d, q) {
			S.base.constructor.call(
				this,
				"RangeSelector",
				"rangeSelector",
				q,
				null,
				d
			);
			this.parent = this.stockChart = d;
			this.options = g(q) || g(q.rangeSelector) ? {} : q.rangeSelector;
			this.optionsName = "rangeSelector";
			this.ctx = d.ctx;
		}
		function Q(d, q, l) {
			Q.base.constructor.call(
				this,
				"RangeButton",
				"rangeButton",
				l,
				q,
				d.rangeSelector
			);
			this.stockChart = d;
			this.parent = this.rangeSelector = d.rangeSelector;
			this.options = g(l) ? {} : l;
			this.optionsName = "rangeButton";
			this.enabled = !1;
			this.state = "off";
		}
		function W(d, q) {
			W.base.constructor.call(
				this,
				"InputFields",
				"inputFields",
				q,
				null,
				d.rangeSelector
			);
			this.stockChart = d;
			this.options = g(q) || g(q.inputFields) ? {} : q.inputFields;
			this.parent = this.rangeSelector = d.rangeSelector;
			this.elements = [];
			this._textBlocks = [];
			this.optionsName = "inputFields";
			this.elements.push(v("input", "canvasjs-input-field", "text"));
			this.elements.push(v("input", "canvasjs-input-field", "text"));
			for (var l = 0; l < this.elements.length; l++)
				this.stockChart._canvasJSContainer.appendChild(this.elements[l]);
			var s = this,
				a = null,
				f = null;
			y(
				this.elements[0],
				"focus",
				function () {
					s.enabled &&
						((s.elements[0].focused = !0),
						(s._textBlocks[0].borderColor = s.style.borderColorOnFocus),
						s._textBlocks[0].render(!0));
				},
				this.rangeSelector.stockChart.allDOMEventHandlers
			);
			y(
				this.elements[0],
				"focusout",
				function () {
					if (s.enabled) {
						s.elements[0].focused = !1;
						var a;
						s.stockChart.charts &&
							s.stockChart.charts.length &&
							(a =
								s.stockChart.charts[0].axisX &&
								s.stockChart.charts[0].axisX.length
									? s.stockChart.charts[0].axisX[0]
									: s.stockChart.charts[0].axisX2[0]);
						this.value =
							"dateTime" === s.valueType
								? ha(
										a ? a.viewportMinimum : null,
										"YYYY-MM-DD",
										s.stockChart._cultureInfo
								  )
								: ba(
										a ? a.viewportMinimum : null,
										s.valueFormatString,
										s.stockChart._cultureInfo
								  );
						this.style.display = "none";
						s._textBlocks[0].borderColor = s.style.borderColor;
						s._textBlocks[0].render(!0);
					}
				},
				this.rangeSelector.stockChart.allDOMEventHandlers
			);
			y(
				this.elements[1],
				"focus",
				function () {
					s.enabled &&
						((s.elements[1].focused = !0),
						(s._textBlocks[1].borderColor = s.style.borderColorOnFocus),
						s._textBlocks[1].render(!0));
				},
				this.rangeSelector.stockChart.allDOMEventHandlers
			);
			y(
				this.elements[1],
				"focusout",
				function () {
					if (s.enabled) {
						s.elements[1].focused = !1;
						var a;
						s.stockChart.charts &&
							s.stockChart.charts.length &&
							(a =
								s.stockChart.charts[0].axisX &&
								s.stockChart.charts[0].axisX.length
									? s.stockChart.charts[0].axisX[0]
									: s.stockChart.charts[0].axisX2[0]);
						this.value =
							"dateTime" === s.valueType
								? ha(
										a ? a.viewportMaximum : null,
										"YYYY-MM-DD",
										s.stockChart._cultureInfo
								  )
								: ba(
										a ? a.viewportMaximum : null,
										s.valueFormatString,
										s.stockChart._cultureInfo
								  );
						this.style.display = "none";
						s._textBlocks[1].borderColor = s.style.borderColor;
						s._textBlocks[1].render(!0);
					}
				},
				this.rangeSelector.stockChart.allDOMEventHandlers
			);
			y(
				this.elements[0],
				"change",
				function (c) {
					if (s.enabled) {
						var b, d;
						s.stockChart.charts &&
							s.stockChart.charts.length &&
							(s.stockChart.navigator &&
								s.stockChart.navigator.enabled &&
								(d =
									s.stockChart.navigator &&
									s.stockChart.navigator.chart &&
									s.stockChart.navigator.chart.axisX
										? s.stockChart.navigator.chart.axisX[0]
										: s.stockChart.navigator.chart.axisX2[0]),
							(b =
								s.stockChart.charts[0].axisX &&
								s.stockChart.charts[0].axisX.length
									? s.stockChart.charts[0].axisX[0]
									: s.stockChart.charts[0].axisX2[0]));
						c =
							"dateTime" === s.valueType
								? isNaN(new Date(this.value.replace(/-/g, "/")).getTime())
									? null
									: new Date(this.value.replace(/-/g, "/")).getTime()
								: this.value;
						a = g(d)
							? !g(c) && c < (g(b) ? null : b.maximum)
								? Math.max(g(b) ? null : b.minimum, c)
								: g(b)
								? null
								: b.minimum
							: !g(c) && c < (g(d) ? null : d.maximum)
							? Math.max(g(d) ? null : d.minimum, c)
							: g(d)
							? null
							: d.minimum;
						f = g(b) ? null : b.viewportMaximum;
						s.stockChart._rangeEventParameter = {
							stockChart: s.stockChart,
							source: "inputFields",
							index: null,
							minimum: a,
							maximum: f,
						};
						s.stockChart._rangeEventParameter.type = "rangeChanging";
						s.stockChart.dispatchEvent(
							"rangeChanging",
							s.stockChart._rangeEventParameter,
							s.stockChart
						);
						s.stockChart.sessionVariables._axisXMin = a;
						s.stockChart.sessionVariables._axisXMax = f;
						s.stockChart._syncCharts(a, f);
						s.stockChart._rangeEventParameter.type = "rangeChanged";
						s.stockChart.dispatchEvent(
							"rangeChanged",
							s.stockChart._rangeEventParameter,
							s.stockChart
						);
						s.stockChart.rangeSelector &&
							s.stockChart.rangeSelector.resetRangeButtons();
					}
				},
				this.rangeSelector.stockChart.allDOMEventHandlers
			);
			y(
				this.elements[1],
				"change",
				function (c) {
					if (s.enabled) {
						var b, d;
						s.stockChart.charts &&
							s.stockChart.charts.length &&
							(s.stockChart.navigator &&
								s.stockChart.navigator.enabled &&
								(d =
									s.stockChart.navigator &&
									s.stockChart.navigator.chart &&
									s.stockChart.navigator.chart.axisX
										? s.stockChart.navigator.chart.axisX[0]
										: s.stockChart.navigator.chart.axisX2[0]),
							(b =
								s.stockChart.charts[0].axisX &&
								s.stockChart.charts[0].axisX.length
									? s.stockChart.charts[0].axisX[0]
									: s.stockChart.charts[0].axisX2[0]));
						c =
							"dateTime" === s.valueType
								? isNaN(new Date(this.value.replace(/-/g, "/")).getTime())
									? null
									: new Date(this.value.replace(/-/g, "/")).getTime()
								: this.value;
						a = b ? b.viewportMinimum : null;
						f = g(d)
							? !g(c) && c > (b ? b.minimum : null)
								? Math.min(b ? b.maximum : null, c)
								: b
								? b.maximum
								: null
							: !g(c) && c > (d ? d.minimum : null)
							? Math.min(d ? d.maximum : null, c)
							: d
							? d.maximum
							: null;
						s.stockChart._rangeEventParameter = {
							stockChart: s.stockChart,
							source: "inputFields",
							index: null,
							minimum: a,
							maximum: f,
						};
						s.stockChart._rangeEventParameter.type = "rangeChanging";
						s.stockChart.dispatchEvent(
							"rangeChanging",
							s.stockChart._rangeEventParameter,
							s.stockChart
						);
						s.stockChart.sessionVariables._axisXMin = a;
						s.stockChart.sessionVariables._axisXMax = f;
						s.stockChart._syncCharts(a, f);
						s.stockChart._rangeEventParameter.type = "rangeChanged";
						s.stockChart.dispatchEvent(
							"rangeChanged",
							s.stockChart._rangeEventParameter,
							s.stockChart
						);
						s.stockChart.rangeSelector &&
							s.stockChart.rangeSelector.resetRangeButtons();
					}
				},
				this.rangeSelector.stockChart.allDOMEventHandlers
			);
			y(
				this.elements[0],
				"keydown",
				function (a) {
					if (s.enabled && 13 === a.keyCode) {
						var b =
							"dateTime" === s.valueType
								? ha(s.startValue, "YYYY-MM-DD")
								: ba(s.startValue, s.valueFormatString);
						this.value != b && hb(this, "change");
						this.blur();
						a.preventDefault && a.preventDefault();
					}
				},
				this.rangeSelector.stockChart.allDOMEventHandlers
			);
			y(
				this.elements[1],
				"keydown",
				function (a) {
					if (s.enabled && 13 === a.keyCode) {
						var b =
							"dateTime" === s.valueType
								? ha(s.endValue, "YYYY-MM-DD")
								: ba(s.endValue, s.valueFormatString);
						this.value != b && hb(this, "change");
						this.blur();
						a.preventDefault && a.preventDefault();
					}
				},
				this.rangeSelector.stockChart.allDOMEventHandlers
			);
		}
		function Y(d, q) {
			Y.base.constructor.call(
				this,
				"ButtonStyle",
				"buttonStyle",
				q,
				null,
				d.rangeSelector
			);
			this.stockChart = d;
			this.parent = this.rangeSelector = d.rangeSelector;
			this.options = g(q) || g(q.buttonStyle) ? {} : q.buttonStyle;
			this.optionsName = "buttonStyle";
		}
		function Z(d, q) {
			Z.base.constructor.call(
				this,
				"Style",
				"style",
				q,
				null,
				d.rangeSelector.inputFields
			);
			this.stockChart = d;
			this.parent = this.inputFields = d.rangeSelector.inputFields;
			this.options = g(q) || g(q.style) ? {} : q.style;
			this.optionsName = "style";
		}
		var ga = !0;
		ia(E, da);
		E.prototype._updateOptions = function () {
			this.updateOption("width");
			this.updateOption("height");
			this.updateOption("theme");
			this.updateOption("colorSet") &&
				(this.selectedColorSet =
					"undefined" !== typeof xa[this.colorSet]
						? xa[this.colorSet]
						: xa.colorSet1);
			this.updateOption("backgroundColor");
			this.backgroundColor || (this.backgroundColor = "rgba(0,0,0,0)");
			this.updateOption("exportEnabled");
			this.updateOption("exportFileName");
			this.updateOption("creditHref");
			this.updateOption("creditHref");
			this.updateOption("culture");
			this._cultureInfo = new Na(this.options.culture);
			this.updateOption("animationEnabled");
			this.updateOption("animationDuration");
			this.updateOption("rangeChanging");
			this.updateOption("rangeChanged");
			this.toolbar = new Wa(this, this.options.toolbar);
			ib(this);
		};
		E._supportedChartTypes = Da(
			"line stepLine spline column area stepArea splineArea bubble scatter stackedColumn stackedColumn100 stackedArea stackedArea100 candlestick ohlc boxAndWhisker rangeColumn error rangeArea rangeSplineArea waterfall".split(
				" "
			)
		);
		E.prototype._initialize = function () {
			this._updateOptions();
			this.animationEnabled = this.animationEnabled && t;
			this._updateSize();
			this.clearCanvas();
			this.ctx.beginPath();
			var d,
				q = g(this.options.charts) ? [] : this.options.charts;
			Da(q);
			for (var z = 0; z < this.charts.length; z++)
				(d = this.charts[z]),
					0 > q.indexOf(d.options) &&
						(this.charts.splice(z, 1),
						d.destroy(),
						this._canvasJSContainer.removeChild(d.panel),
						z--);
			this.layoutManager = new Ka(0, 0, this.width, this.height, 2);
			this.plotArea.layoutManager && this.plotArea.layoutManager.reset();
			this.title = null;
			this.subtitles = [];
			this.options.title &&
				((this.title = new K(this, this.options.title)),
				this.title.setLayout());
			if (this.options.subtitles)
				for (z = 0; z < this.options.subtitles.length; z++)
					(d = new O(this, this.options.subtitles[z], z)),
						this.subtitles.push(d),
						d.setLayout();
			this.navigator = this.navigator || new D(this, this.options.navigator);
			this.navigator._initialize();
			if (this.navigator.enabled) {
				d = {
					theme:
						this.options.navigator && this.options.navigator.theme
							? this.options.navigator.theme
							: this.theme,
					animationEnabled:
						this.options.navigator &&
						!g(this.options.navigator.animationEnabled)
							? this.options.navigator.animationEnabled
							: this.animationEnabled,
					animationDuration:
						this.options.navigator && this.options.navigator.animationDuration
							? this.options.navigator.animationDuration
							: this.animationDuration,
					culture:
						this.options.navigator && this.options.navigator.culture
							? this.options.navigator.culture
							: this.culture,
					zoomEnabled: !1,
					data:
						this.options.navigator && this.options.navigator.data
							? this.options.navigator.data
							: this.options.charts &&
							  this.options.charts[0] &&
							  this.options.charts[0].data
							? Za(this.options.charts[0].data)
							: [],
					axisX:
						this.options.navigator && this.options.navigator.axisX
							? this.options.navigator.axisX
							: {},
					axisY:
						this.options.navigator && this.options.navigator.axisY
							? this.options.navigator.axisY
							: {},
					axisX2:
						this.options.navigator && this.options.navigator.axisX2
							? this.options.navigator.axisX2
							: {},
					axisY2:
						this.options.navigator && this.options.navigator.axisY2
							? this.options.navigator.axisY2
							: {},
					interactivityEnabled: !1,
					exportEnabled: !1,
				};
				for (z = 0; z < d.data.length; z++)
					!g(this.navigator.options.data) ||
					(!g(d.data[z].type) &&
						(g(d.data[z].type) ||
							0 <= d.data[z].type.indexOf("range") ||
							0 <= d.data[z].type.indexOf("ohlc") ||
							0 <= d.data[z].type.indexOf("candlestick") ||
							0 <= d.data[z].type.indexOf("error")))
						? this.navigator.options.data &&
						  g(this.navigator.options.data[z].type) &&
						  (d.data[z].type = "splineArea")
						: (d.data[z].type = "splineArea");
				if (d.axisX.length)
					for (z = 0; z < d.axisX.length; z++)
						(d.axisX[z].titleFontSize = g(d.axisX[z].titleFontSize)
							? 1
							: d.axisX[z].titleFontSize),
							(d.axisX[z].labelFontSize = g(d.axisX[z].labelFontSize)
								? 12
								: d.axisX[z].labelFontSize),
							(d.axisX[z].labelAngle = g(d.axisX[z].labelAngle)
								? 0
								: d.axisX[z].labelAngle),
							(d.axisX[z].labelPlacement = g(d.axisX[z].labelPlacement)
								? "inside"
								: d.axisX[z].labelPlacement),
							(d.axisX[z].tickLength = g(d.axisX[z].tickLength)
								? 0
								: d.axisX[z].tickLength),
							(d.axisX[z].lineThickness = g(d.axisX[z].lineThickness)
								? 0
								: d.axisX[z].lineThickness),
							(d.axisX[z].tickThickness = g(d.axisX[z].tickThickness)
								? 0
								: d.axisX[z].tickThickness),
							(d.axisX[z].gridThickness = g(d.axisX[z].gridThickness)
								? 1
								: d.axisX[z].gridThickness);
				else
					(d.axisX.titleFontSize =
						this.options.navigator &&
						this.options.navigator.axisX &&
						!g(this.options.navigator.axisX.titleFontSize)
							? this.options.navigator.axisX.titleFontSize
							: t
							? 0
							: 1),
						(d.axisX.labelFontSize =
							this.options.navigator &&
							this.options.navigator.axisX &&
							!g(this.options.navigator.axisX.labelFontSize)
								? this.options.navigator.axisX.labelFontSize
								: 12),
						(d.axisX.labelAngle =
							this.options.navigator &&
							this.options.navigator.axisX &&
							!g(this.options.navigator.axisX.labelAngle)
								? this.options.navigator.axisX.labelAngle
								: 0),
						(d.axisX.labelPlacement =
							this.options.navigator &&
							this.options.navigator.axisX &&
							!g(this.options.navigator.axisX.labelPlacement)
								? this.options.navigator.axisX.labelPlacement
								: "inside"),
						(d.axisX.tickLength =
							this.options.navigator &&
							this.options.navigator.axisX &&
							!g(this.options.navigator.axisX.tickLength)
								? this.options.navigator.axisX.tickLength
								: 0),
						(d.axisX.lineThickness =
							this.options.navigator &&
							this.options.navigator.axisX &&
							!g(this.options.navigator.axisX.lineThickness)
								? this.options.navigator.axisX.lineThickness
								: 0),
						(d.axisX.tickThickness =
							this.options.navigator &&
							this.options.navigator.axisX &&
							!g(this.options.navigator.axisX.tickThickness)
								? this.options.navigator.axisX.tickThickness
								: 0),
						(d.axisX.gridThickness =
							this.options.navigator &&
							this.options.navigator.axisX &&
							!g(this.options.navigator.axisX.gridThickness)
								? this.options.navigator.axisX.gridThickness
								: 1);
				if (d.axisY.length)
					for (z = 0; z < d.axisY.length; z++)
						(d.axisY[z].titleFontSize = d.axisY[z].titleFontSize
							? d.axisY[z].titleFontSize
							: t
							? 0
							: 1),
							(d.axisY[z].labelFontSize = d.axisY[z].labelFontSize
								? d.axisY[z].labelFontSize
								: t
								? 0
								: 1),
							(d.axisY[z].labelPlacement = d.axisY[z].labelPlacement
								? d.axisY[z].labelPlacement
								: "inside"),
							(d.axisY[z].includeZero = !1),
							(d.axisY[z].lineThickness = 0),
							(d.axisY[z].tickThickness = 0),
							(d.axisY[z].tickLength = 0),
							(d.axisY[z].gridThickness = 0);
				else
					(d.axisY.titleFontSize =
						this.options.navigator &&
						this.options.navigator.axisY &&
						this.options.navigator.axisY.titleFontSize
							? this.options.navigator.axisY.titleFontSize
							: t
							? 0
							: 1),
						(d.axisY.labelFontSize =
							this.options.navigator &&
							this.options.navigator.axisY &&
							this.options.navigator.axisY.labelFontSize
								? this.options.navigator.axisY.labelFontSize
								: t
								? 0
								: 1),
						(d.axisY.labelPlacement =
							this.options.navigator &&
							this.options.navigator.axisY &&
							this.options.navigator.axisY.labelPlacement
								? this.options.navigator.axisY.labelPlacement
								: "inside"),
						(d.axisY.includeZero = !1),
						(d.axisY.lineThickness = 0),
						(d.axisY.tickThickness = 0),
						(d.axisY.tickLength = 0),
						(d.axisY.gridThickness = 0);
				if (d.axisX2.length)
					for (z = 0; z < d.axisX2.length; z++)
						(d.axisX2[z].titleFontSize = d.axisX2[z].titleFontSize
							? d.axisX2[z].titleFontSize
							: t
							? 0
							: 1),
							(d.axisX2[z].labelFontSize = d.axisX2[z].labelFontSize
								? d.axisX2[z].labelFontSize
								: 12),
							(d.axisX2[z].labelAngle = g(d.axisX2[z].labelAngle)
								? 0
								: d.axisX2[z].labelAngle),
							(d.axisX2[z].labelPlacement = d.axisX2[z].labelPlacement
								? d.axisX2[z].labelPlacement
								: "inside"),
							(d.axisX2[z].tickLength = g(d.axisX2[z].tickLength)
								? 0
								: d.axisX2[z].tickLength),
							(d.axisX2[z].lineThickness = d.axisX2[z].lineThickness
								? d.axisX2[z].lineThickness
								: 0),
							(d.axisX2[z].tickThickness = d.axisX2[z].tickThickness
								? d.axisX2[z].tickThickness
								: 1),
							(d.axisX2[z].gridThickness = g(d.axisX2[z].gridThickness)
								? 1
								: d.axisX2[z].gridThickness);
				else
					(d.axisX2.titleFontSize =
						this.options.navigator &&
						this.options.navigator.axisX2 &&
						this.options.navigator.axisX2.titleFontSize
							? this.options.navigator.axisX2.titleFontSize
							: t
							? 0
							: 1),
						(d.axisX2.labelFontSize =
							this.options.navigator &&
							this.options.navigator.axisX2 &&
							this.options.navigator.axisX2.labelFontSize
								? this.options.navigator.axisX2.labelFontSize
								: 12),
						(d.axisX2.labelAngle =
							this.options.navigator &&
							this.options.navigator.axisX2 &&
							this.options.navigator.axisX2.labelAngle
								? this.options.navigator.axisX2.labelAngle
								: 0),
						(d.axisX2.labelPlacement =
							this.options.navigator &&
							this.options.navigator.axisX2 &&
							this.options.navigator.axisX2.labelPlacement
								? this.options.navigator.axisX2.labelPlacement
								: "inside"),
						(d.axisX2.tickLength =
							this.options.navigator &&
							this.options.navigator.axisX2 &&
							this.options.navigator.axisX2.tickLength
								? this.options.navigator.axisX2.tickLength
								: 0),
						(d.axisX2.lineThickness =
							this.options.navigator &&
							this.options.navigator.axisX2 &&
							this.options.navigator.axisX2.lineThickness
								? this.options.navigator.axisX2.lineThickness
								: 0),
						(d.axisX2.tickThickness =
							this.options.navigator &&
							this.options.navigator.axisX2 &&
							this.options.navigator.axisX2.tickThickness
								? this.options.navigator.axisX2.tickThickness
								: 1),
						(d.axisX2.gridThickness =
							this.options.navigator &&
							this.options.navigator.axisX2 &&
							this.options.navigator.axisX2.gridThickness
								? this.options.navigator.axisX2.gridThickness
								: 1);
				if (d.axisY2.length)
					for (z = 0; z < d.axisY2.length; z++)
						(d.axisY2[z].titleFontSize = d.axisY2[z].titleFontSize
							? d.axisY2[z].titleFontSize
							: t
							? 0
							: 1),
							(d.axisY2[z].labelFontSize = d.axisY2[z].labelFontSize
								? d.axisY2[z].labelFontSize
								: t
								? 0
								: 1),
							(d.axisY2[z].labelPlacement = d.axisY2[z].labelPlacement
								? d.axisY2[z].labelPlacement
								: "inside"),
							(d.axisY2[z].includeZero = !1),
							(d.axisY2[z].lineThickness = 0),
							(d.axisY2[z].margin = 0),
							(d.axisY2[z].tickLength = 0),
							(d.axisY2[z].tickThickness = 0),
							(d.axisY2[z].gridThickness = 0);
				else
					(d.axisY2.titleFontSize =
						this.options.navigator &&
						this.options.navigator.axisY2 &&
						this.options.navigator.axisY2.titleFontSize
							? this.options.navigator.axisY2.titleFontSize
							: t
							? 0
							: 1),
						(d.axisY2.labelFontSize =
							this.options.navigator &&
							this.options.navigator.axisY2 &&
							this.options.navigator.axisY2.labelFontSize
								? this.options.navigator.axisY2.labelFontSize
								: t
								? 0
								: 1),
						(d.axisY2.labelPlacement =
							this.options.navigator &&
							this.options.navigator.axisY2 &&
							this.options.navigator.axisY2.labelPlacement
								? this.options.navigator.axisY2.labelPlacement
								: "inside"),
						(d.axisY2.includeZero = !1),
						(d.axisY2.lineThickness = 0),
						(d.axisY2.margin = 0),
						(d.axisY2.tickLength = 0),
						(d.axisY2.tickThickness = 0),
						(d.axisY2.gridThickness = 0);
				d.backgroundColor = this.navigator.backgroundColor;
				for (z = 0; z < d.data.length; z++)
					d.data[z].markerSize = d.data[z].markerSize
						? d.data[z].markerSize
						: 0;
				if (!this.navigator.chart) {
					var s = l("canvasjs-chart-panel");
					this.navigator.panel.appendChild(s);
					s.style.height = this.navigator.height + "px";
					s.style.width = this.navigator.width + "px";
				}
				this.navigator.chart &&
					(this.navigator.chart.panel.style.width =
						this.navigator.width + "px");
				this.navigator.chart =
					this.navigator.chart ||
					new Ja(s, d, {
						parent: this.navigator,
						isOptionsInArray: !1,
						index: null,
						predefinedThemes: bb,
						optionsName: "navigator",
						stockChart: this,
					});
				this.navigator.chart._initialize();
				this.navigator.chart.setLayout();
				this.navigator.chart.selectedColorSet = this.selectedColorSet;
				this.navigator._chartOptions = d;
				this.navigator.slider =
					this.navigator.slider || new U(this.navigator, this.options);
				this.navigator.slider._initialize();
			}
			this.rangeSelector = this.rangeSelector || new S(this, this.options);
			this.rangeSelector.buttonStyle =
				this.rangeSelector.buttonStyle ||
				new Y(this, this.rangeSelector.options);
			this.rangeSelector.inputFields =
				this.rangeSelector.inputFields ||
				new W(this, this.rangeSelector.options);
			this.rangeSelector.inputFields.style = new Z(
				this,
				this.rangeSelector.inputFields.options
			);
			this.rangeSelector.inputFields.style._updateOptions();
			this.rangeSelector.inputFields._initialize();
			if (
				this.rangeSelector.inputFields &&
				this.rangeSelector.inputFields.enabled
			)
				for (
					this.rangeSelector.inputFields._textBlocks = [],
						this.rangeSelector.inputFields._labels = [],
						this.rangeSelector.inputFieldsWidth = 0,
						s = this.rangeSelector.inputFields.style.maxWidth =
							this.rangeSelector.inputFields.style.options.maxWidth
								? this.rangeSelector.inputFields.style.options.maxWidth
								: this.rangeSelector.stockChart.width / 2,
						z = 0;
					z < this.rangeSelector.inputFields.elements.length;
					z++
				) {
					d = new na(this.rangeSelector.ctx, {
						x: 0,
						y: 0,
						backgroundColor:
							this.rangeSelector.inputFields.style.backgroundColor,
						borderColor: this.rangeSelector.inputFields.style.borderColor,
						borderThickness:
							this.rangeSelector.inputFields.style.borderThickness,
						cornerRadius: 0,
						maxWidth: g(this.rangeSelector.inputFields.style.options.width)
							? s
							: this.rangeSelector.inputFields.style.width,
						maxHeight: 2 * this.rangeSelector.inputFields.style.fontSize,
						angle: 0,
						padding: this.rangeSelector.inputFields.style.padding,
						text: this.rangeSelector.inputFields.valueFormatString,
						horizontalAlign: "center",
						textAlign: "center",
						fontSize: this.rangeSelector.inputFields.style.fontSize,
						fontFamily: this.rangeSelector.inputFields.style.fontFamily,
						fontWeight: this.rangeSelector.inputFields.style.fontWeight,
						fontColor: this.rangeSelector.inputFields.style.fontColor,
						fontStyle: this.rangeSelector.inputFields.style.fontStyle,
						textBaseline: "middle",
					});
					var q = d.measureText(),
						a = new na(this.rangeSelector.ctx, {
							x: 0,
							y: 0,
							backgroundColor: t ? "transparent" : null,
							borderColor: this.rangeSelector.inputFields.style.borderColor,
							borderThickness: 0,
							cornerRadius: 0,
							maxWidth: this.rangeSelector.inputFields.style.maxWidth,
							maxHeight: 2 * this.rangeSelector.inputFields.style.fontSize,
							angle: 0,
							padding: this.rangeSelector.inputFields.style.padding,
							text: "From",
							horizontalAlign: "left",
							fontSize: this.rangeSelector.inputFields.style.fontSize,
							fontFamily: this.rangeSelector.inputFields.style.fontFamily,
							fontWeight: this.rangeSelector.inputFields.style.fontWeight,
							fontColor: this.rangeSelector.inputFields.style.fontColor,
							fontStyle: this.rangeSelector.inputFields.style.fontStyle,
							textBaseline: "middle",
						});
					this.rangeSelector.inputFields._textBlocks.push(d);
					this.rangeSelector.inputFields._labels.push(a);
					this.rangeSelector.inputFields._textBlocks[z].bounds = {
						x1: null,
						x2: null,
						y1: null,
						y2: null,
						width: null,
						height: null,
					};
					this.rangeSelector.inputFields._labels[z].bounds = {
						x1: null,
						x2: null,
						y1: null,
						y2: null,
						width: null,
						height: null,
					};
					this.rangeSelector.inputFieldsWidth +=
						q.width +
						this.rangeSelector.inputFields.style.spacing +
						a.measureText().width;
					this.rangeSelector.inputFieldsHeight =
						this.rangeSelector.inputFieldsHeight < q.height
							? q.height
							: this.rangeSelector.inputFieldsHeight;
					d = this.rangeSelector.inputFields._textBlocks[z];
					d.id = ++this.rangeSelector.stockChart._eventManager.lastObjectId;
					this.rangeSelector.stockChart._eventManager.objectMap[d.id] = {
						id: d.id,
						objectType: "inputFields",
						inputFieldIndex: z,
					};
				}
			this.rangeSelector.buttonStyle._updateOptions();
			this.rangeSelector._initialize();
			this._objectsInitialized = !0;
		};
		E.prototype.render = function (d) {
			var q = this;
			d && (this.options = d);
			this._initialize();
			this.title && this.title.render();
			if (this.subtitles)
				for (d = 0; d < this.subtitles.length; d++) this.subtitles[d].render();
			var z;
			if (this.options.charts) {
				z = Array(this.options.charts.length);
				var s = this.layoutManager.getFreeSpace(),
					a = s.height,
					s = s.height,
					f = this.options.charts.length;
				for (d = 0; d < this.options.charts.length; d++)
					!g(this.options.charts[d].height) &&
						parseFloat(this.options.charts[d].height) &&
						((z[d] =
							0 <= this.options.charts[d].height.toString().indexOf("%")
								? Va(
										this.options.charts[d].height,
										a,
										Math.floor(s / f),
										this.height,
										0
								  )
								: this.options.charts[d].height),
						(s -= z[d]),
						f--);
				s = Math.floor(s / f);
				for (d = 0; d < this.options.charts.length; d++) g(z[d]) && (z[d] = s);
				var c,
					f = this.options.charts,
					b = this.charts;
				this.charts = [];
				for (d = 0; d < f.length; d++) {
					s = this.layoutManager.getFreeSpace();
					a = f[d];
					a.theme = f[d].theme
						? f[d].theme
						: this.options.theme
						? this.options.theme
						: this.theme;
					a.animationEnabled = g(f[d].animationEnabled)
						? this.animationEnabled
						: f[d].animationEnabled;
					a.animationDuration = f[d].animationDuration
						? f[d].animationDuration
						: this.animationDuration;
					a.culture = f[d].culture ? f[d].culture : this.culture;
					a.height = f[d].height ? z[d] : null;
					g(f.backgroundColor)
						? g(this.options.backgroundColor) ||
						  (a.backgroundColor = this.backgroundColor)
						: (a.backgroundColor = this.options.charts[d].backgroundColor);
					for (c = b.length; 0 < c-- && b[c].options !== a; );
					0 <= c
						? ((this.charts[d] = b[c]), (this.charts[d].options = a))
						: ((c = l("canvasjs-chart-panel")),
						  (c.style.height = z[d] + "px"),
						  this._canvasJSContainer.appendChild(c),
						  (this.charts[d] = new Ja(c, a, {
								parent: this,
								isOptionsInArray: !0,
								index: d,
								predefinedThemes: bb,
								optionsName: "charts",
								stockChart: this,
						  })));
					this.charts[d].panel.style.width = s.width + "px";
					this.charts[d].panel.style.top = s.y1 + "px";
					this.charts[d].panel.style.left = s.x1 + "px";
					this.charts[d].panel.style.height = z[d] + "px";
					this.charts[d].height = a.height ? a.height : z[d];
					this.layoutManager.registerSpace("top", {
						width: s.width,
						height: z[d],
					});
				}
				b = null;
				for (d = 0; d < this.charts.length; d++)
					if (!g(this.options.charts[d].data))
						for (c = 0; c < this.options.charts[d].data.length; c++)
							(z = this.options.charts[d].data[c]),
								0 <= E._supportedChartTypes.indexOf(z.type) ||
									g(z.type) ||
									((ga = !1),
									window.console &&
										window.console.log(
											"CanvasJS Error: " +
												z.type +
												" Chart is not Supported in StockChart"
										));
				if (!ga) return;
				this.setChartsLayout();
				for (d = z = 0; d < this.charts.length; d++)
					if (g(this.charts[d].options.colorSet)) {
						this.charts[d].selectedColorSet = [];
						for (c = z; c < z + this.charts[d].data.length; c++)
							this.charts[d].selectedColorSet.push(
								this.selectedColorSet[c % this.selectedColorSet.length]
							);
						z += this.charts[d].data.length;
					}
				if (this.navigator && this.navigator.enabled)
					if (this.navigator.options.data) {
						this.navigator.chart.selectedColorSet = [];
						for (c = z; c < z + this.navigator.chart.data.length; c++)
							this.navigator.chart.selectedColorSet.push(
								this.selectedColorSet[c % this.selectedColorSet.length]
							);
						z += this.navigator.chart.data.length;
					} else
						this.navigator.chart.selectedColorSet =
							this.charts[0].selectedColorSet;
			}
			if (this.navigator && this.navigator.enabled) {
				for (d = 0; d < this.navigator.chart.data.length; d++)
					0 <=
						E._supportedChartTypes.indexOf(this.navigator.chart.data[d].type) ||
						((ga = !1),
						window.console &&
							window.console.log(
								"CanvasJS Error: " +
									this.navigator.chart.data[d].type +
									" Chart is not Supported in Navigator"
							));
				if (ga) {
					var e =
						this.navigator.chart.axisX && this.navigator.chart.axisX.length
							? this.navigator.chart.axisX[0]
							: this.navigator.chart.axisX2[0];
					e.bounds.x1 > this._chartsPlotAreaX1 &&
						(this._chartsPlotAreaX1 = e.bounds.x1);
					this.navigator.width =
						this.navigator.chart.width =
						this.navigator.slider.fullWidth =
							this.navigator.options.width
								? this.navigator.options.width
								: this._chartsPlotAreaX2 - this._chartsPlotAreaX1 + e.bounds.x1;
					this.navigator.panel.style.left =
						this._chartsPlotAreaX1 +
						this.layoutManager.getFreeSpace().x1 -
						e.bounds.x1 +
						"px";
					this.navigator.panel.style.width =
						this.navigator.chart.panel.style.width = this.navigator.options
							.width
							? this.navigator.options.width
							: this._chartsPlotAreaX2 -
							  this._chartsPlotAreaX1 +
							  e.bounds.x1 +
							  "px";
					this.navigator.chart.render(this.navigator._chartOptions);
					this.navigator.backgroundColor = this.navigator.chart.backgroundColor;
					this.navigator.animationEnabled =
						this.navigator.chart.animationEnabled;
					this.navigator.animationDuration =
						this.navigator.chart.animationDuration;
					this.navigator.theme = this.navigator.chart.theme;
					this.navigator.axisX = this.navigator.chart.axisX;
					this.navigator.axisX2 = this.navigator.chart.axisX2;
					this.navigator.axisY = this.navigator.chart.axisY;
					this.navigator.axisY2 = this.navigator.chart.axisY2;
					this.navigator.data = this.navigator.chart.data;
				}
			}
			s = z = null;
			c = 0;
			f = a = null;
			for (d = 0; d < this.charts.length; d++)
				for (
					b =
						this.charts[d].axisX && this.charts[d].axisX.length
							? this.charts[d].axisX
							: this.charts[d].axisX2,
						z = z || (b && 0 < b.length ? b[0].minimum : null),
						s = s || (b && 0 < b.length ? b[0].maximum : null),
						c = 0;
					c < b.length;
					c++
				)
					(e = b[c]),
						e.minimum < z && (z = e.minimum),
						e.maximum > s && (s = e.maximum);
			var h;
			this.navigator &&
				this.navigator.chart &&
				ga &&
				((h =
					this.navigator.chart.axisX && 0 < this.navigator.chart.axisX.length
						? this.navigator.chart.axisX[0]
						: this.navigator.chart.axisX2[0]),
				(this._axisXMin = this.navigator.slider.minimum =
					g(this.navigator.options.slider) ||
					g(this.navigator.options.slider.minimum)
						? null
						: Math.max(h.minimum, this.navigator.slider.minimum)),
				(this._axisXMax = this.navigator.slider.maximum =
					g(this.navigator.options.slider) ||
					g(this.navigator.options.slider.maximum)
						? null
						: Math.min(h.maximum, this.navigator.slider.maximum)));
			if (this.rangeSelector && this.rangeSelector.enabled) {
				c = h ? h.maximum - h.minimum : s - z;
				this.charts &&
					this.charts.length &&
					(e =
						this.charts[0].axisX && this.charts[0].axisX.length
							? this.charts[0].axisX[0]
							: this.charts[0].axisX2[0]);
				for (d = 0; d < this.rangeSelector.buttons.length; d++) {
					var v = null,
						b = this.rangeSelector.buttons[d];
					"year" === b.rangeType
						? (v = 31104e6 * b.range)
						: "ytd" === b.rangeType
						? (v = "ytd")
						: "month" === b.rangeType
						? (v = 2592e6 * b.range)
						: "week" === b.rangeType
						? (v = 6048e5 * b.range)
						: "day" === b.rangeType
						? (v = 864e5 * b.range)
						: "hour" === b.rangeType
						? (v = 36e5 * b.range)
						: "minute" === b.rangeType
						? (v = 6e4 * b.range)
						: "second" === b.rangeType
						? (v = 1e3 * b.range)
						: "millisecond" === b.rangeType
						? (v = b.range)
						: "all" === b.rangeType
						? (v = null)
						: "number" === b.rangeType && (v = b.range);
					if ("ytd" === b.rangeType || v < c) b.enabled = !0;
					b.enabled
						? ((b.cursor = this.rangeSelector.buttonStyle.cursor),
						  "on" === b.state &&
								((g(this._axisXMin) &&
									g(this._axisXMax) &&
									((b.textBlock.fontWeight = "bold"),
									(b.textBlock.fontColor =
										this.rangeSelector.buttonStyle.labelFontColorOnHover),
									(b.textBlock.backgroundColor =
										this.rangeSelector.buttonStyle.backgroundColorOnSelect)),
								"ytd" === b.rangeType && e)
									? ((a = new Date(e.viewportMaximum)),
									  a.setDate(1),
									  a.setMonth(0),
									  (a = a.getTime()))
									: g(v)
									? h
										? ((a = h.minimum), (f = h.maximum))
										: e && ((a = e.minimum), (f = e.maximum))
									: e.viewportMaximum - v < (h ? h.minimum : e.minimum)
									? ((f = h.minimum - (e.viewportMaximum - v)),
									  (a = h.minimum),
									  (f = Math.min(h.maximum, e.viewportMaximum + f)))
									: ((a = e.viewportMaximum - v), (f = e.viewportMaximum))),
						  (g(this._axisXMin) && g(this._axisXMax)) || (b.state = "off"),
						  (this._axisXMin = g(this._axisXMin) ? a : this._axisXMin),
						  (this._axisXMax = g(this._axisXMax) ? f : this._axisXMax))
						: (b.cursor = "not-allowed");
					b.mousemove = function (a) {
						this.enabled &&
							"off" === this.state &&
							((this.textBlock.backgroundColor =
								a.stockChart.rangeSelector.buttonStyle.backgroundColorOnHover),
							(this.textBlock.fontColor =
								a.stockChart.rangeSelector.buttonStyle.labelFontColorOnHover),
							this.textBlock.render(!0));
					};
					b.mouseover = function (a) {
						this.enabled &&
							"off" === this.state &&
							((this.textBlock.backgroundColor =
								a.stockChart.rangeSelector.buttonStyle.backgroundColorOnHover),
							(this.textBlock.fontColor =
								a.stockChart.rangeSelector.buttonStyle.labelFontColorOnHover),
							this.textBlock.render(!0));
					};
					b.mouseout = function (a) {
						this.enabled &&
							"off" === this.state &&
							((this.textBlock.backgroundColor =
								a.stockChart.rangeSelector.buttonStyle.backgroundColor),
							(this.textBlock.fontColor =
								a.stockChart.rangeSelector.buttonStyle.labelFontColor),
							this.textBlock.render(!0));
					};
					b.click = function (a) {
						if (this.enabled) {
							for (
								var b = 0, c = null, d = null, e = 0;
								e < q.rangeSelector.buttons.length;
								e++
							)
								q.rangeSelector.buttons[e] != this &&
									(q.rangeSelector.buttons[e].state = "off"),
									(q.rangeSelector.buttons[e].textBlock.fontWeight =
										q.rangeSelector.buttonStyle.labelFontWeight),
									(q.rangeSelector.buttons[e].textBlock.fontColor =
										a.stockChart.rangeSelector.buttonStyle.labelFontColor),
									(q.rangeSelector.buttons[e].textBlock.backgroundColor =
										a.stockChart.rangeSelector.buttonStyle.backgroundColor),
									q.rangeSelector.buttons[e].textBlock.render(!0);
							var f;
							q.charts &&
								q.charts.length &&
								(f =
									q.charts[0].axisX && q.charts[0].axisX.length
										? q.charts[0].axisX[0]
										: q.charts[0].axisX2[0]);
							var h;
							q.navigator &&
								q.navigator.chart &&
								(h = q.navigator.chart.axisX
									? q.navigator.chart.axisX[0]
									: q.navigator.chart.axisX2[0]);
							"year" === this.rangeType
								? (b = 31104e6 * this.range)
								: "month" === this.rangeType
								? (b = 2592e6 * this.range)
								: "week" === this.rangeType
								? (b = 6048e5 * this.range)
								: "day" === this.rangeType
								? (b = 864e5 * this.range)
								: "hour" === this.rangeType
								? (b = 36e5 * this.range)
								: "minute" === this.rangeType
								? (b = 6e4 * this.range)
								: "second" === this.rangeType
								? (b = 1e3 * this.range)
								: "millisecond" === this.rangeType
								? (b = this.range)
								: "all" === this.rangeType
								? (b = null)
								: "number" === this.rangeType && (b = this.range);
							"ytd" === this.rangeType && f
								? ((c = new Date(f.viewportMaximum)),
								  c.setDate(1),
								  c.setMonth(0),
								  (b = c.getTime()),
								  (c = Math.max(b, h ? h.minimum : f.minimum)),
								  (d = f.viewportMaximum))
								: g(b)
								? h
									? ((c = h.minimum), (d = h.maximum))
									: f && ((c = f.minimum), (d = f.maximum))
								: h && f.viewportMaximum - b < (h ? h.minimum : f.minimum)
								? ((h =
										q.navigator.chart.axisX[0].minimum -
										(f.viewportMaximum - b)),
								  (c = q.navigator.chart.axisX[0].minimum),
								  (d = Math.min(
										q.navigator.chart.axisX[0].maximum,
										f.viewportMaximum + h
								  )))
								: ((c = f.viewportMaximum - b), (d = f.viewportMaximum));
							q._rangeEventParameter = {
								stockChart: q,
								source: "buttons",
								index: this._index,
								minimum: c,
								maximum: d,
							};
							"on" != this.state &&
								((q._rangeEventParameter.type = "rangeChanging"),
								q.dispatchEvent("rangeChanging", q._rangeEventParameter, q),
								q._syncCharts(c, d),
								(q.sessionVariables._axisXMin = c),
								(q.sessionVariables._axisXMax = d),
								(q._rangeEventParameter.type = "rangeChanged"),
								q.dispatchEvent("rangeChanged", q._rangeEventParameter, q),
								(this.state = "on"));
							this.textBlock.fontWeight = "bold";
							this.textBlock.fontColor =
								a.stockChart.rangeSelector.buttonStyle.labelFontColorOnHover;
							this.textBlock.backgroundColor =
								a.stockChart.rangeSelector.buttonStyle.backgroundColorOnSelect;
							this.textBlock.render(!0);
						}
					};
				}
				if (
					this.rangeSelector.inputFields &&
					this.rangeSelector.inputFields.enabled
				) {
					this.rangeSelector.inputFields.valueType =
						this.options.rangeSelector &&
						this.options.rangeSelector.inputFields &&
						this.options.rangeSelector.inputFields.valueType
							? this.options.rangeSelector.inputFields.valueType
							: this.isDateTime
							? "dateTime"
							: "number";
					for (
						d = 0;
						d < this.rangeSelector.inputFields._textBlocks.length;
						d++
					)
						(e = this.rangeSelector.inputFields._textBlocks[d]),
							(e.mousemove = function (a) {
								(q.rangeSelector &&
									q.rangeSelector.inputFields &&
									!q.rangeSelector.inputFields.enabled) ||
									(a.inputField.cursor =
										a.stockChart.rangeSelector.inputFields.style.cursor);
							}),
							(e.mouseover = function (a) {
								(q.rangeSelector &&
									q.rangeSelector.inputFields &&
									!q.rangeSelector.inputFields.enabled) ||
									(a.inputField.cursor =
										a.stockChart.rangeSelector.inputFields.style.cursor);
							}),
							(e.mouseout = function (a) {
								(q.rangeSelector &&
									q.rangeSelector.inputFields &&
									!q.rangeSelector.inputFields.enabled) ||
									(a.inputField.cursor = "auto");
							}),
							(e.click = function (a) {
								(q.rangeSelector &&
									q.rangeSelector.inputFields &&
									!q.rangeSelector.inputFields.enabled) ||
									((a.stockChart.rangeSelector.inputFields.elements[
										a.inputFieldIndex
									].style.display = "inline"),
									a.stockChart.rangeSelector.inputFields.elements[
										a.inputFieldIndex
									].focus());
							});
					if (isFinite(z) && isFinite(s)) {
						"dateTime" === this.rangeSelector.inputFields.valueType
							? ((this.rangeSelector.inputFields.valueFormatString =
									this.options.rangeSelector &&
									this.options.rangeSelector.inputFields &&
									this.options.rangeSelector.inputFields.valueFormatString
										? this.options.rangeSelector.inputFields.valueFormatString
										: "MMM DD YYYY"),
							  (this.rangeSelector.inputFields.startValue = g(
									this.rangeSelector.inputFields.options.startValue
							  )
									? g(a)
										? z
										: a
									: ha(
											this.rangeSelector.inputFields.options.startValue,
											this.rangeSelector.inputFields.valueFormatString
									  )),
							  (this.rangeSelector.inputFields.endValue = g(
									this.rangeSelector.inputFields.options.endValue
							  )
									? g(f)
										? s
										: f
									: ha(
											this.rangeSelector.inputFields.options.endValue,
											this.rangeSelector.inputFields.valueFormatString
									  )))
							: ((this.rangeSelector.inputFields.valueFormatString =
									this.options.rangeSelector &&
									this.options.rangeSelector.inputFields &&
									this.options.rangeSelector.inputFields.valueFormatString
										? this.options.rangeSelector.inputFields.valueFormatString
										: "#,##0.##"),
							  (this.rangeSelector.inputFields.startValue = g(
									this.rangeSelector.inputFields.options.startValue
							  )
									? g(a)
										? z
										: a
									: this.rangeSelector.inputFields.options.startValue),
							  (this.rangeSelector.inputFields.endValue = g(
									this.rangeSelector.inputFields.options.endValue
							  )
									? g(f)
										? s
										: f
									: this.rangeSelector.inputFields.options.endValue));
						if (
							!(
								g(this._axisXMin) &&
								g(this._axisXMax) &&
								g(this.rangeSelector.inputFields.options.startValue) &&
								g(this.rangeSelector.inputFields.options.endValue)
							)
						)
							for (c = 0; c < this.rangeSelector.buttons.length; c++)
								b = this.rangeSelector.buttons[c];
						d = ha(
							this.rangeSelector.inputFields.startValue,
							"YYYY-MM-DD'T'HH:mm:ssZ"
						);
						e = ha(
							this.rangeSelector.inputFields.endValue,
							"YYYY-MM-DD'T'HH:mm:ssZ"
						);
						t ||
							((d = d.replace(/-/g, "/")),
							(e = e.replace(/-/g, "/")),
							(d = d.replace("T", " ")),
							(e = e.replace("T", " ")),
							(d = d.replace(/(\+[0-9]{2})(\:)([0-9]{2}$)/, " UTC$1$3")),
							(e = e.replace(/(\+[0-9]{2})(\:)([0-9]{2}$)/, " UTC$1$3")));
						this._axisXMin = g(this._axisXMin)
							? "dateTime" === this.rangeSelector.inputFields.valueType
								? new Date(d).getTime()
								: this.rangeSelector.inputFields.startValue
							: this._axisXMin;
						this._axisXMax = g(this._axisXMax)
							? "dateTime" === this.rangeSelector.inputFields.valueType
								? new Date(e).getTime()
								: this.rangeSelector.inputFields.endValue
							: this._axisXMax;
					}
					for (d = 0; d < this.rangeSelector.inputFields.elements.length; d++)
						this.rangeSelector.inputFields.elements[d].title =
							"dateTime" === this.rangeSelector.inputFields.valueType
								? "YYYY-MM-DD"
								: this.rangeSelector.inputFields.valueFormatString;
				}
				this.rangeSelector.render();
			}
			g(this._axisXMin) && (this._axisXMin = z);
			g(this._axisXMax) && (this._axisXMax = s);
			this._axisXMin =
				!g(this.sessionVariables._axisXMin) &&
				this.navigator &&
				this.navigator.slider &&
				!this.navigator.slider.hasOptionChanged("minimum")
					? this.sessionVariables._axisXMin
					: this._axisXMin;
			this._axisXMax =
				!g(this.sessionVariables._axisXMax) &&
				this.navigator &&
				this.navigator.slider &&
				!this.navigator.slider.hasOptionChanged("maximum")
					? this.sessionVariables._axisXMax
					: this._axisXMax;
			if (!g(h)) {
				this.navigator.slider.hasOptionChanged("minimum") &&
					(this._axisXMin =
						this.sessionVariables._axisXMin =
						this.navigator.slider.sessionVariables.minimum =
						this.navigator.slider.minimum =
							this.navigator.slider.options.minimum);
				this.navigator.slider.hasOptionChanged("maximum") &&
					(this._axisXMax =
						this.sessionVariables._axisXMax =
						this.navigator.slider.sessionVariables.maximum =
						this.navigator.slider.maximum =
							this.navigator.slider.options.maximum);
				if (this._axisXMin < h.minimum || this._axisXMin > h.maximum)
					this._axisXMin = h.minimum;
				if (this._axisXMax < h.minimum || this._axisXMax > h.maximum)
					this._axisXMax = h.maximum;
			}
			!g(h) && h.reversed
				? this._syncCharts(this._axisXMax, this._axisXMin)
				: this._syncCharts(this._axisXMin, this._axisXMax);
		};
		E.prototype.setChartsLayout = function () {
			var d,
				g,
				l = 0,
				s = this.width;
			this.isPlotAreaSet = this.isDateTime = !1;
			for (var a = 0; a < this.charts.length; a++) {
				d = this.charts[a];
				d._initialize();
				d.setLayout();
				g = {};
				if ((d.axisX[0] || d.axisX2[0]) && (d.axisY[0] || d.axisY2[0])) {
					var f = d.axisX[0]
						? d.axisX[0].lineCoordinates
						: d.axisX2[0].lineCoordinates;
					if (d.axisY && 0 < d.axisY.length && d.axisY[0]) {
						var c = d.axisY[0];
						g.x1 = f.x1 < f.x2 ? f.x1 : c.lineCoordinates.x1;
						g.x2 = f.x2 > c.lineCoordinates.x2 ? f.x2 : c.lineCoordinates.x2;
					}
					d.axisY2 &&
						0 < d.axisY2.length &&
						d.axisY2[0] &&
						((c = d.axisY2[0]),
						(g.x1 = f.x1 < f.x2 ? f.x1 : c.lineCoordinates.x1),
						(g.x2 = f.x2 > c.lineCoordinates.x2 ? f.x2 : c.lineCoordinates.x2));
				}
				for (f = 0; f < d.data.length; f++)
					"dateTime" === d.data[f].xValueType && (this.isDateTime = !0);
				l = Math.max(l, g.x1);
				s = Math.min(s, g.x2);
				d.sessionVariables.plotAreaBounds = g;
			}
			this._chartsPlotAreaX1 = l;
			this._chartsPlotAreaX2 = s;
		};
		E.prototype._updateSize = function () {
			return lb.call(this, [this.canvas, this.overlaidCanvas]);
		};
		E.prototype._syncCharts = function (d, q) {
			for (
				var l = this, s = null, a = Math.min(d, q), f = Math.max(d, q), c = 0;
				c < this.charts.length;
				c++
			) {
				s = this.charts[c];
				s.toolTip && s.toolTip.enabled && s.toolTip.hide();
				for (var b = 0; b < s.axisX.length; b++)
					s.axisX[b].setViewPortRange(d, q),
						(s.axisX[b].options.labelAngle = g(s.axisX[b].options.labelAngle)
							? 0
							: s.axisX[b].options.labelAngle),
						this.charts[c].axisX[b].crosshair &&
							this.charts[c].axisX[b].crosshair.enabled &&
							(this.charts[c].axisX[b].crosshair.options.updated ||
								(this.charts[c].axisX[b].crosshair.options.updated = function (
									a
								) {
									for (var b = 0; b < l.charts.length; b++)
										if (l.charts[b] != a.chart)
											for (var c = 0; c < l.charts[b].axisX.length; c++)
												l.charts[b].axisX[c].crosshair &&
													l.charts[b].axisX[c].crosshair.showAt(a.value);
								}),
							this.charts[c].axisX[b].crosshair.options.hidden ||
								(this.charts[c].axisX[b].crosshair.options.hidden = function (
									a
								) {
									for (var b = 0; b < l.charts.length; b++)
										if (l.charts[b] != a.chart)
											for (var c = 0; c < l.charts[b].axisX.length; c++)
												l.charts[b].axisX[c].crosshair &&
													l.charts[b].axisX[c].crosshair.hide();
								}));
				for (b = 0; b < s.axisX2.length; b++)
					s.axisX2[b].setViewPortRange(d, q),
						(s.axisX2[b].options.labelAngle = g(s.axisX2[b].options.labelAngle)
							? 0
							: s.axisX2[b].options.labelAngle),
						this.charts[c].axisX2[b].crosshair &&
							this.charts[c].axisX2[b].crosshair.enabled &&
							(this.charts[c].axisX2[b].crosshair.options.updated ||
								(this.charts[c].axisX2[b].crosshair.options.updated = function (
									a
								) {
									for (var b = 0; b < l.charts.length; b++)
										if (l.charts[b] != a.chart)
											for (var c = 0; c < l.charts[b].axisX2.length; c++)
												l.charts[b].axisX2[c].crosshair &&
													l.charts[b].axisX2[c].crosshair.showAt(a.value);
								}),
							this.charts[c].axisX2[b].crosshair.options.hidden ||
								(this.charts[c].axisX2[b].crosshair.options.hidden = function (
									a
								) {
									for (var b = 0; b < l.charts.length; b++)
										if (l.charts[b] != a.chart)
											for (var c = 0; c < l.charts[b].axisX2.length; c++)
												l.charts[b].axisX2[c].crosshair &&
													l.charts[b].axisX2[c].crosshair.hide();
								}));
				s.options.toolbar
					? (s.options.toolbar = s.options.toolbar)
					: l.options.toolbar && (s.options.toolbar = l.options.toolbar);
				s._initialize();
				s.setLayout();
				s.panEnabled = s.options.zoomEnabled && s.zoomEnabled ? !1 : !0;
				s.renderElements();
				(g(this.charts[c].options.toolTip) ||
					(!g(this.charts[c].options.toolTip) &&
						g(this.charts[c].options.toolTip.updated))) &&
					this.charts[c].toolTip &&
					(this.charts[c].toolTip.updated = function (a) {
						for (var b = 0; b < l.charts.length; b++)
							l.charts[b] != a.chart &&
								l.charts[b].toolTip.showAtX(
									a.entries[0].xValue,
									a.entries[0].dataSeriesIndex
								);
					});
				(g(this.charts[c].options.toolTip) ||
					(!g(this.charts[c].options.toolTip) &&
						g(this.charts[c].options.toolTip.hidden))) &&
					this.charts[c].toolTip &&
					(this.charts[c].toolTip.hidden = function (a) {
						for (var b = 0; b < l.charts.length; b++)
							l.charts[b] != a.chart && l.charts[b].toolTip.hide();
					});
			}
			this.rangeSelector &&
				this.rangeSelector.enabled &&
				this.rangeSelector.inputFields &&
				this.rangeSelector.inputFields.enabled &&
				this.rangeSelector._updateInputFields(a, f);
			this.navigator &&
				this.navigator.enabled &&
				this.navigator._updateSlider(a, f);
		};
		E.prototype.destroy = function () {
			for (var d = this.allDOMEventHandlers, g = 0; g < this.charts.length; g++)
				this.charts[g].destroy();
			this.navigator &&
				this.navigator.enabled &&
				this.navigator.chart.destroy();
			for (g = 0; g < d.length; g++) gb(d[g][0], d[g][1], d[g][2], d[g][3]);
			for (
				this.allDOMEventHandlers = [];
				this.navigator &&
				this.navigator.enabled &&
				this.navigator.panel.hasChildNodes();

			)
				this.navigator.panel.removeChild(this.navigator.panel.lastChild);
			for (
				;
				this._canvasJSContainer && this._canvasJSContainer.hasChildNodes();

			)
				this._canvasJSContainer.removeChild(this._canvasJSContainer.lastChild);
			for (; this.container && this.container.hasChildNodes(); )
				this.container.removeChild(this.container.lastChild);
			this.container = this._canvasJSContainer = null;
			Ca(this.canvas);
			Ca(this.overlaidCanvas);
			this._eventManager &&
				this._eventManager.ghostCanvas &&
				Ca(this._eventManager.ghostCanvas);
			this.navigator.panel = null;
			this.rangeSelector.panel = null;
		};
		E.prototype.exportChart = function (d) {
			d = "undefined" === typeof d ? {} : d;
			var g = d.format ? d.format : "png",
				l = d.fileName ? d.fileName : this.exportFileName,
				s = this.canvas.cloneNode();
			s.setAttribute("class", s.getAttribute("class") + "-clone");
			var a = s.getContext("2d");
			a.drawImage(this.canvas, 0, 0);
			for (var f = [], c = 0; c < this.charts.length; c++) {
				var b = this.charts[c];
				f.push({
					canvas: b.canvas,
					position: { x: b.container.offsetLeft, y: b.container.offsetTop },
				});
				f.push({
					canvas: b.overlaidCanvas,
					position: { x: b.container.offsetLeft, y: b.container.offsetTop },
				});
			}
			this.navigator &&
				this.navigator.enabled &&
				((c = this.navigator),
				f.push({
					canvas: c.chart.canvas,
					position: { x: c.panel.offsetLeft, y: c.panel.offsetTop },
				}),
				f.push({
					canvas: c.chart.overlaidCanvas,
					position: { x: c.panel.offsetLeft, y: c.panel.offsetTop },
				}));
			for (c = 0; c < f.length; c++)
				a.drawImage(f[c].canvas, f[c].position.x * ka, f[c].position.y * ka);
			if (d.toDataURL) return s.toDataURL("image/" + g);
			db(s, g, l);
		};
		E.prototype.print = function () {
			var d = this.exportChart({ toDataURL: !0 }),
				g = document.createElement("iframe");
			g.setAttribute("class", "canvasjs-chart-print-frame");
			N(g, {
				position: "absolute",
				width: "100%",
				border: 0,
				margin: 0,
				padding: 0,
			});
			g.style.height = this.height + "px";
			this._canvasJSContainer.appendChild(g);
			var l = this,
				s = g.contentWindow || g.contentDocument.document || g.contentDocument;
			s.document.open();
			s.document.write(
				'<!DOCTYPE HTML>\n<html><body><img src="' + d + '"/><body/></html>'
			);
			s.document.body &&
				s.document.body.style &&
				((s.document.body.style.margin = "0px 0px 0px 0px"),
				(s.document.body.style.padding = "0px 0px 0px 0px"));
			s.document.close();
			setTimeout(function () {
				s.focus();
				s.print();
				setTimeout(function () {
					l._canvasJSContainer.removeChild(g);
				}, 1e3);
			}, 500);
		};
		E.prototype.getObjectAtXY = function (d, l, t) {
			t = null;
			if (!g(this.rangeSelector)) {
				for (var s = 0; s < this.rangeSelector.buttons.length; s++) {
					var a = this.rangeSelector.buttons[s];
					d >= a.bounds.x1 &&
						d <= a.bounds.x2 &&
						l >= a.bounds.y1 &&
						l <= a.bounds.y2 &&
						(t = a.id);
				}
				for (s = 0; s < this.rangeSelector.inputFields._textBlocks.length; s++)
					(a = this.rangeSelector.inputFields._textBlocks[s]),
						d >= a.bounds.x1 &&
							d <= a.bounds.x2 &&
							l >= a.bounds.y1 &&
							l <= a.bounds.y2 &&
							(t = a.id);
			}
			return t;
		};
		E.prototype._touchEventHandler = function (d) {
			if (d.changedTouches) {
				var l = [],
					t = d.changedTouches,
					s = t ? t[0] : d,
					a = null;
				switch (d.type) {
					case "touchstart":
					case "MSPointerDown":
						l = ["mousemove", "mousedown"];
						this._lastTouchData = Aa(s);
						this._lastTouchData.time = new Date();
						break;
					case "touchmove":
					case "MSPointerMove":
						l = ["mousemove"];
						break;
					case "touchend":
					case "MSPointerUp":
						var f =
								this._lastTouchData && this._lastTouchData.time
									? new Date() - this._lastTouchData.time
									: 0,
							l =
								"touchstart" === this._lastTouchEventType ||
								"MSPointerDown" === this._lastTouchEventType ||
								300 > f
									? ["mouseup", "click"]
									: ["mouseup"];
						break;
					default:
						return;
				}
				if (!(t && 1 < t.length)) {
					a = Aa(s);
					a.time = new Date();
					try {
						var c = a.y - this._lastTouchData.y,
							f = a.time - this._lastTouchData.time;
						if (
							(1 < Math.abs(c) && this._lastTouchData.scroll) ||
							(5 < Math.abs(c) && 250 > f)
						)
							this._lastTouchData.scroll = !0;
					} catch (b) {}
					this._lastTouchEventType = d.type;
					if (this._lastTouchData && this._lastTouchData.scroll)
						this.isDrag && this.resetOverlayedCanvas(), (this.isDrag = !1);
					else
						for (t = 0; t < l.length; t++)
							if (
								((a = l[t]),
								(c = document.createEvent("MouseEvent")),
								c.initMouseEvent(
									a,
									!0,
									!0,
									window,
									1,
									s.screenX,
									s.screenY,
									s.clientX,
									s.clientY,
									!1,
									!1,
									!1,
									!1,
									0,
									null
								),
								s.target.dispatchEvent(c),
								(this._lastTouchData &&
									!g(this._lastTouchData.scroll) &&
									!this._lastTouchData.scroll) ||
									(this._lastTouchData &&
										!this._lastTouchData.scroll &&
										250 < f) ||
									"click" === a)
							)
								d.preventManipulation && d.preventManipulation(),
									d.preventDefault && d.cancelable && d.preventDefault();
				}
			}
		};
		E.prototype._mouseEventHandler = function (d) {
			"undefined" === typeof d.target &&
				d.srcElement &&
				(d.target = d.srcElement);
			var g = Aa(d),
				l = d.type,
				s,
				a;
			d.which ? (a = 3 == d.which) : d.button && (a = 2 == d.button);
			E.capturedEventParam &&
				((s = E.capturedEventParam),
				"mouseup" === l &&
					((E.capturedEventParam = null),
					s.stockChart.overlaidCanvas.releaseCapture
						? s.stockChart.overlaidCanvas.releaseCapture()
						: document.documentElement.removeEventListener(
								"mouseup",
								s.stockChart._mouseEventHandler,
								!1
						  )),
				s.hasOwnProperty(l) &&
					("mouseup" !== l || s.stockChart.overlaidCanvas.releaseCapture
						? (d.target !== s.stockChart.overlaidCanvas && t) ||
						  s[l].call(s.context, g.x, g.y)
						: d.target !== s.stockChart.overlaidCanvas &&
						  (s.stockChart.isDrag = !1)));
			if (this._ignoreNextEvent) this._ignoreNextEvent = !1;
			else if (
				(d.preventManipulation && d.preventManipulation(),
				d.preventDefault && d.preventDefault(),
				La &&
					window.console &&
					(window.console.log(l + " --\x3e x: " + g.x + "; y:" + g.y),
					a && window.console.log(d.which),
					"mouseup" === l && window.console.log("mouseup")),
				!a)
			) {
				if (!E.capturedEventParam && this._events) {
					for (var f = 0; f < this._events.length; f++)
						if (this._events[f].hasOwnProperty(l))
							if (
								((s = this._events[f]),
								(a = s.bounds),
								g.x >= a.x1 && g.x <= a.x2 && g.y >= a.y1 && g.y <= a.y2)
							) {
								s[l].call(s.context, g.x, g.y);
								"mousedown" === l && !0 === s.capture
									? ((Chart.capturedEventParam = s),
									  this.overlaidCanvas.setCapture
											? this.overlaidCanvas.setCapture()
											: document.documentElement.addEventListener(
													"mouseup",
													this._mouseEventHandler,
													!1
											  ))
									: "mouseup" === l &&
									  (s.stockChart.overlaidCanvas.releaseCapture
											? s.stockChart.overlaidCanvas.releaseCapture()
											: document.documentElement.removeEventListener(
													"mouseup",
													this._mouseEventHandler,
													!1
											  ));
								break;
							} else s = null;
					d.target.style.cursor =
						s && s.cursor ? s.cursor : this._defaultCursor;
				}
				l = this.bounds;
				(g.x < l.x1 || g.x > l.x2 || g.y < l.y1 || g.y > l.y2) &&
					this.resetOverlayedCanvas();
				!this.isDrag &&
					this._eventManager &&
					this._eventManager.mouseEventHandler(d);
			}
		};
		E.prototype.resetOverlayedCanvas = function () {
			this.overlaidCanvasCtx.clearRect(0, 0, this.width, this.height);
		};
		E.prototype.clearCanvas = mb;
		E.prototype.getAutoFontSize = nb;
		ia(K, Ha);
		ia(O, Ma);
		Ja.prototype.alignVerticalAxes = function () {
			if (!this.isNavigator && this.stockChart) {
				for (
					var d = this.sessionVariables.plotAreaBounds,
						g = this.stockChart._chartsPlotAreaX1 - d.x1,
						d = d.x2 - this.stockChart._chartsPlotAreaX2,
						l = 0;
					l < this.axisX.length;
					l++
				)
					(this.axisX[l].lineCoordinates.x1 += g),
						(this.axisX[l].lineCoordinates.x2 -= d),
						(this.axisX[l].lineCoordinates.width =
							this.axisX[l].lineCoordinates.x2 -
							this.axisX[l].lineCoordinates.x1),
						(this.axisX[l].bounds.x1 += g),
						(this.axisX[l].bounds.x2 -= d),
						this.axisX[l].calculateValueToPixelConversionParameters();
				for (l = 0; l < this.axisX2.length; l++)
					(this.axisX2[l].lineCoordinates.x1 += g),
						(this.axisX2[l].lineCoordinates.x2 -= d),
						(this.axisX2[l].lineCoordinates.width =
							this.axisX2[l].lineCoordinates.x2 -
							this.axisX2[l].lineCoordinates.x1),
						(this.axisX2[l].bounds.x1 += g),
						(this.axisX2[l].bounds.x2 -= d),
						this.axisX2[l].calculateValueToPixelConversionParameters();
				for (l = 0; l < this.axisY.length; l++)
					(this.axisY[l].lineCoordinates.x1 += g),
						(this.axisY[l].lineCoordinates.x2 += g),
						(this.axisY[l].bounds.x1 += g),
						(this.axisY[l].bounds.x2 += g);
				for (l = 0; l < this.axisY2.length; l++)
					(this.axisY2[l].lineCoordinates.x1 -= d),
						(this.axisY2[l].lineCoordinates.x2 -= d),
						(this.axisY2[l].bounds.x1 -= d),
						(this.axisY2[l].bounds.x2 -= d);
			}
		};
		Ja.prototype.syncCharts = function (d, l) {
			this.stockChart &&
				((d = g(d) ? this.stockChart._axisXMin : d),
				(l = g(l) ? this.stockChart._axisXMax : l),
				this.stockChart._syncCharts(d, l),
				(this.stockChart.sessionVariables._axisXMin = d),
				(this.stockChart.sessionVariables._axisXMax = l),
				this.stockChart.rangeSelector &&
					"zoom" !== this._zoomButton.getAttribute("state") &&
					this.stockChart.rangeSelector.resetRangeButtons());
		};
		P.prototype.reset = function () {
			this.lastObjectId = 0;
			this.objectMap = [];
			this.rectangularRegionEventSubscriptions = [];
			this.eventObjects = [];
			t &&
				(this.ghostCtx.clearRect(
					0,
					0,
					this.stockChart.width,
					this.stockChart.height
				),
				this.ghostCtx.beginPath());
		};
		P.prototype.getNewObjectTrackingId = function () {
			return ++this.lastObjectId;
		};
		P.prototype.mouseEventHandler = function (d) {
			if ("mousemove" === d.type || "click" === d.type) {
				var g = [],
					l = Aa(d),
					s = null;
				if (
					(s = this.stockChart.getObjectAtXY(l.x, l.y, !1)) &&
					"undefined" !== typeof this.objectMap[s]
				)
					if (((s = this.objectMap[s]), "rangeButton" === s.objectType)) {
						var a = this.stockChart.rangeSelector.buttons[s.rangeButtonIndex];
						s.eventParameter = {
							x: l.x,
							y: l.y,
							rangeButton: a,
							rangeButtonIndex: s.rangeButtonIndex,
							stockChart: this.stockChart,
						};
						s.eventContext = {
							context: this.stockChart.rangeSelector,
							userContext: a,
							mouseover: "mouseover",
							mousemove: "mousemove",
							mouseout: "mouseout",
							click: "click",
						};
						g.push(s);
					} else
						"inputFields" === s.objectType &&
							((a =
								this.stockChart.rangeSelector.inputFields._textBlocks[
									s.inputFieldIndex
								]),
							(s.eventParameter = {
								x: l.x,
								y: l.y,
								inputField: a,
								inputFieldIndex: s.inputFieldIndex,
								stockChart: this.stockChart,
							}),
							(s.eventContext = {
								context: this.stockChart.rangeSelector,
								userContext: a,
								mouseover: "mouseover",
								mousemove: "mousemove",
								mouseout: "mouseout",
								click: "click",
							}),
							g.push(s));
				a = [];
				for (l = 0; l < this.mouseoveredObjectMaps.length; l++) {
					for (var f = !0, s = 0; s < g.length; s++)
						if (g[s].id === this.mouseoveredObjectMaps[l].id) {
							f = !1;
							break;
						}
					f
						? this.fireEvent(this.mouseoveredObjectMaps[l], "mouseout", d)
						: a.push(this.mouseoveredObjectMaps[l]);
				}
				this.mouseoveredObjectMaps = a;
				for (l = 0; l < g.length; l++) {
					a = !1;
					for (s = 0; s < this.mouseoveredObjectMaps.length; s++)
						if (g[l].id === this.mouseoveredObjectMaps[s].id) {
							a = !0;
							break;
						}
					a ||
						(this.fireEvent(g[l], "mouseover", d),
						this.mouseoveredObjectMaps.push(g[l]));
					"click" === d.type
						? this.fireEvent(g[l], "click", d)
						: "mousemove" === d.type && this.fireEvent(g[l], "mousemove", d);
				}
			}
		};
		P.prototype.fireEvent = function (d, g, l) {
			if (d && g) {
				var s = d.eventParameter,
					a = d.eventContext,
					f = d.eventContext.userContext;
				f && a && f[a[g]] && f[a[g]].call(f, s);
				"mouseout" !== g
					? f.cursor &&
					  f.cursor !== l.target.style.cursor &&
					  (l.target.style.cursor = f.cursor)
					: ((l.target.style.cursor = this.stockChart._defaultCursor),
					  delete d.eventParameter,
					  delete d.eventContext);
			}
		};
		ia(D, da);
		D.prototype._updateOptions = function () {
			this.updateOption("enabled");
			this.updateOption("backgroundColor");
			this.options.backgroundColor ||
				(this.backgroundColor = this.stockChart.backgroundColor);
			this.updateOption("animationEnabled");
			this.animationEnabled = this.animationEnabled && t;
			this.updateOption("animationDuration");
			this.updateOption("theme");
			this.updateOption("verticalAlign");
			this.updateOption("horizontalAlign");
			this.updateOption("width");
			this.updateOption("height");
			this.updateOption("dynamicUpdate");
		};
		D.prototype._initialize = function () {
			this._updateOptions();
			var d;
			this.enabled &&
				!this.panel &&
				((this.panel = l("canvasjs-navigator-panel")),
				(d =
					!g(this.panel) &&
					this.stockChart._canvasJSContainer.contains(this.panel)) ||
					this.stockChart._canvasJSContainer.appendChild(this.panel));
			d =
				!g(this.panel) &&
				this.stockChart._canvasJSContainer.contains(this.panel);
			if (this.enabled) {
				this.height = this.options.height
					? parseInt(this.options.height)
					: 0.14 * this.stockChart.height;
				d || this.stockChart._canvasJSContainer.appendChild(this.panel);
				var q = this.stockChart.layoutManager.getFreeSpace(),
					t = q.x1,
					s = q.y1;
				this.width = this.options.width ? this.width : q.width;
				"top" === this.verticalAlign
					? ((s = q.y1 + 2), (d = "top"))
					: ((s = q.y2 - this.height - 2), (d = "bottom"));
				"left" === this.horizontalAlign
					? (t = q.x1 + 2)
					: "center" === this.horizontalAlign
					? (t = q.x1 + q.width / 2 - this.width / 2)
					: "right" === this.horizontalAlign && (t = q.x2 - 2 - this.width);
				this.panel.style.top = s + "px";
				this.panel.style.left = t + "px";
				this.panel.style.height = this.height + "px";
				this.panel.style.width = this.width + "px";
				this.stockChart.layoutManager.registerSpace(d, {
					width: this.width,
					height: this.height + 2,
				});
				this.bounds = { x1: t, y1: s, x2: t + this.width, y2: s + this.height };
			} else d && this.stockChart._canvasJSContainer.removeChild(this.panel);
		};
		D.prototype._updateSlider = function (d, l) {
			var t =
				this.chart.axisX && this.chart.axisX.length
					? this.chart.axisX[0]
					: this.chart.axisX2[0];
			if (!g(t)) {
				var s = Math.max(
						t.bounds.x1,
						t.convertValueToPixel(
							t.reversed
								? Math.max(d, Math.min(t.maximum, l))
								: Math.min(d, Math.min(t.maximum, l))
						)
					),
					a = t.convertValueToPixel(
						t.reversed
							? Math.min(d, Math.min(t.maximum, l))
							: Math.max(d, Math.min(t.maximum, l))
					);
				this.slider.panel.style.left = s + "px";
				this.slider.left = s;
				this.slider.panel.style.width = a - s + "px";
				this.slider.width = a - s;
				this.slider.minimum = d;
				this.slider.maximum = l;
				this.chart.resetOverlayedCanvas();
				this.slider.maskInverted
					? ea(
							this.chart.overlaidCanvasCtx,
							s,
							0,
							a,
							this.height,
							this.slider.maskColor,
							0,
							this.slider.outlineColor,
							0,
							0,
							0,
							0,
							this.slider.maskOpacity
					  )
					: (ea(
							this.chart.overlaidCanvasCtx,
							t.bounds.x1,
							0,
							s,
							this.height,
							this.slider.maskColor,
							0,
							this.slider.outlineColor,
							!1,
							!1,
							!1,
							!1,
							this.slider.maskOpacity
					  ),
					  ea(
							this.chart.overlaidCanvasCtx,
							a,
							0,
							this.width,
							this.height,
							this.slider.maskColor,
							0,
							this.slider.outlineColor,
							!1,
							!1,
							!1,
							!1,
							this.slider.maskOpacity
					  ));
				this.chart.overlaidCanvasCtx.lineWidth = this.slider.outlineThickness;
				this.chart.overlaidCanvasCtx.strokeStyle = this.slider.outlineColor;
				this.chart.overlaidCanvasCtx.save();
				this.chart.overlaidCanvasCtx.beginPath();
				0 < this.slider.outlineThickness &&
					(this.slider.outlineInverted
						? (this.chart.overlaidCanvasCtx.moveTo(
								t.bounds.x1,
								this.height - this.slider.outlineThickness / 2
						  ),
						  this.chart.overlaidCanvasCtx.lineTo(
								1 === this.slider.outlineThickness % 2
									? ((s - this.slider.outlineThickness / 2) << 0) + 0.5
									: s,
								this.height - this.slider.outlineThickness / 2
						  ),
						  this.chart.overlaidCanvasCtx.lineTo(
								1 === this.slider.outlineThickness % 2
									? ((s - this.slider.outlineThickness / 2) << 0) + 0.5
									: s,
								this.slider.outlineThickness / 2
						  ),
						  this.chart.overlaidCanvasCtx.lineTo(
								1 === this.slider.outlineThickness % 2
									? ((a - this.slider.outlineThickness / 2) << 0) + 0.5
									: a,
								this.slider.outlineThickness / 2
						  ),
						  this.chart.overlaidCanvasCtx.lineTo(
								1 === this.slider.outlineThickness % 2
									? ((a - this.slider.outlineThickness / 2) << 0) + 0.5
									: a,
								this.height - this.slider.outlineThickness / 2
						  ),
						  this.chart.overlaidCanvasCtx.lineTo(
								this.width,
								this.height - this.slider.outlineThickness
						  ))
						: (this.chart.overlaidCanvasCtx.moveTo(
								t.bounds.x1,
								this.slider.outlineThickness / 2
						  ),
						  this.chart.overlaidCanvasCtx.lineTo(
								1 === this.slider.outlineThickness % 2
									? ((s - this.slider.outlineThickness / 2) << 0) + 0.5
									: s,
								this.slider.outlineThickness / 2
						  ),
						  this.chart.overlaidCanvasCtx.lineTo(
								1 === this.slider.outlineThickness % 2
									? ((s - this.slider.outlineThickness / 2) << 0) + 0.5
									: s,
								this.height - this.slider.outlineThickness / 2
						  ),
						  this.chart.overlaidCanvasCtx.lineTo(
								1 === this.slider.outlineThickness % 2
									? ((a - this.slider.outlineThickness / 2) << 0) + 0.5
									: a,
								this.height - this.slider.outlineThickness / 2
						  ),
						  this.chart.overlaidCanvasCtx.lineTo(
								1 === this.slider.outlineThickness % 2
									? ((a - this.slider.outlineThickness / 2) << 0) + 0.5
									: a,
								this.slider.outlineThickness / 2
						  ),
						  this.chart.overlaidCanvasCtx.lineTo(
								this.width,
								this.slider.outlineThickness / 2
						  )));
				this.chart.overlaidCanvasCtx.stroke();
				this.chart.overlaidCanvasCtx.restore();
				ea(
					this.chart.overlaidCanvasCtx,
					1 === this.slider.handleBorderThickness % 2
						? (s - this.slider.handleWidth / 2) << 0.5
						: (s - this.slider.handleWidth / 2) << 0,
					1 === this.slider.handleBorderThickness % 2
						? (this.height / 2 - this.slider.handleHeight / 2) << 0.5
						: (this.height / 2 - this.slider.handleHeight / 2) << 0,
					1 === this.slider.handleBorderThickness % 2
						? (s + this.slider.handleWidth / 2) << 0.5
						: (s + this.slider.handleWidth / 2) << 0,
					1 === this.slider.handleBorderThickness % 2
						? (this.height / 2 + this.slider.handleHeight / 2) << 0.5
						: (this.height / 2 + this.slider.handleHeight / 2) << 0,
					this.slider.handleColor,
					this.slider.handleBorderThickness,
					this.slider.handleBorderColor,
					!1,
					!1,
					!1,
					!1,
					1
				);
				ea(
					this.chart.overlaidCanvasCtx,
					1 === this.slider.handleBorderThickness % 2
						? (a - this.slider.handleWidth / 2) << 0.5
						: (a - this.slider.handleWidth / 2) << 0,
					1 === this.slider.handleBorderThickness % 2
						? (this.height / 2 - this.slider.handleHeight / 2) << 0.5
						: (this.height / 2 - this.slider.handleHeight / 2) << 0,
					1 === this.slider.handleBorderThickness % 2
						? (a + this.slider.handleWidth / 2) << 0.5
						: (a + this.slider.handleWidth / 2) << 0,
					1 === this.slider.handleBorderThickness % 2
						? (this.height / 2 + this.slider.handleHeight / 2) << 0.5
						: (this.height / 2 + this.slider.handleHeight / 2) << 0,
					this.slider.handleColor,
					this.slider.handleBorderThickness,
					this.slider.handleBorderColor,
					!1,
					!1,
					!1,
					!1,
					1
				);
				this.chart.overlaidCanvasCtx.lineWidth =
					this.slider.handleBorderThickness;
				this.chart.overlaidCanvasCtx.strokeStyle =
					this.slider.handleBorderColor;
				this.chart.overlaidCanvasCtx.save();
				this.chart.overlaidCanvasCtx.beginPath();
				this.chart.overlaidCanvasCtx.moveTo(
					(1 === this.slider.handleBorderThickness % 2
						? ((s - this.slider.handleBorderThickness / 2) << 0) + 0.5
						: s) - Math.round(this.slider.handleBorderThickness),
					this.height / 2 - this.slider.handleHeight / 4
				);
				this.chart.overlaidCanvasCtx.lineTo(
					(1 === this.slider.handleBorderThickness % 2
						? ((s - this.slider.handleBorderThickness / 2) << 0) + 0.5
						: s) - Math.round(this.slider.handleBorderThickness),
					this.height / 2 + this.slider.handleHeight / 4
				);
				this.chart.overlaidCanvasCtx.moveTo(
					(1 === this.slider.handleBorderThickness % 2
						? ((s + this.slider.handleBorderThickness / 2) << 0) + 0.5
						: s) + Math.round(this.slider.handleBorderThickness),
					this.height / 2 - this.slider.handleHeight / 4
				);
				this.chart.overlaidCanvasCtx.lineTo(
					(1 === this.slider.handleBorderThickness % 2
						? ((s + this.slider.handleBorderThickness / 2) << 0) + 0.5
						: s) + Math.round(this.slider.handleBorderThickness),
					this.height / 2 + this.slider.handleHeight / 4
				);
				this.chart.overlaidCanvasCtx.moveTo(
					(1 === this.slider.handleBorderThickness % 2
						? ((a - this.slider.handleBorderThickness / 2) << 0) + 0.5
						: a) - Math.round(this.slider.handleBorderThickness),
					this.height / 2 - this.slider.handleHeight / 4
				);
				this.chart.overlaidCanvasCtx.lineTo(
					(1 === this.slider.handleBorderThickness % 2
						? ((a - this.slider.handleBorderThickness / 2) << 0) + 0.5
						: a) - Math.round(this.slider.handleBorderThickness),
					this.height / 2 + this.slider.handleHeight / 4
				);
				this.chart.overlaidCanvasCtx.moveTo(
					(1 === this.slider.handleBorderThickness % 2
						? ((a + this.slider.handleBorderThickness / 2) << 0) + 0.5
						: a) + Math.round(this.slider.handleBorderThickness),
					this.height / 2 - this.slider.handleHeight / 4
				);
				this.chart.overlaidCanvasCtx.lineTo(
					(1 === this.slider.handleBorderThickness % 2
						? ((a + this.slider.handleBorderThickness / 2) << 0) + 0.5
						: a) + Math.round(this.slider.handleBorderThickness),
					this.height / 2 + this.slider.handleHeight / 4
				);
				this.chart.overlaidCanvasCtx.stroke();
				this.chart.overlaidCanvasCtx.restore();
			}
		};
		ia(U, da);
		U.prototype._updateOptions = function () {
			this.updateOption("maskColor");
			this.updateOption("maskInverted");
			this.updateOption("maskOpacity");
			this.updateOption("handleColor");
			this.updateOption("handleBorderColor");
			this.updateOption("handleBorderThickness");
			this.updateOption("handleWidth");
			this.updateOption("handleHeight");
			this.updateOption("outlineColor");
			this.updateOption("outlineThickness");
			this.updateOption("outlineInverted");
			this.updateOption("minimum");
			this.updateOption("maximum");
		};
		U.prototype._attachEvents = function () {
			var d = this;
			y(
				this.panel,
				"mousedown",
				function (g) {
					d.stockChart.isDrag = !0;
					g.target !== d.leftHandle &&
						g.target !== d.rightHandle &&
						((d.stockChart.navigatorClicked = !0),
						(d.currentHandle = d.leftHandle),
						(d.panMode = !0),
						(d.startPosition = Aa(g)),
						g.target === d.currentHandle
							? (d.startPosition.x +=
									d.currentHandle.offsetLeft +
									d.currentHandle.clientWidth / 2 +
									d.panel.offsetLeft)
							: g.target === d.panel &&
							  (d.startPosition.x += d.panel.offsetLeft),
						(d.startWidth = d.panel.clientWidth),
						(d.startLeft = d.panel.offsetLeft));
				},
				this.stockChart.allDOMEventHandlers
			);
			y(
				this.panel,
				window.navigator.msPointerEnabled ? "MSPointerDown" : "touchstart",
				function (g) {
					d.stockChart._touchEventHandler(g);
				},
				this.stockChart.allDOMEventHandlers
			);
			y(
				this.navigator.panel,
				"mousedown",
				function (l) {
					if (
						l.target !== d.navigator.slider.panel &&
						l.target !== d.leftHandle &&
						l.target !== d.rightHandle
					) {
						d.stockChart.navigatorClicked = !0;
						var t = Aa(l),
							s,
							a;
						s = 0;
						var f =
							d.navigator.chart.axisX && d.navigator.chart.axisX.length
								? d.navigator.chart.axisX[0]
								: d.navigator.chart.axisX2[0];
						l = d.navigator.slider.left;
						g(d.startLeft) && (d.startLeft = l);
						s = l + d.navigator.slider.width;
						a = t.x - (l + s) / 2;
						if (t.x < l || t.x > s) {
							var t = Math.max(0, l + a),
								c = Math.min(s + a, d.navigator.width);
							s + a > d.navigator.width &&
								((s = s + a - d.navigator.width), (t -= s));
							0 > l + a && ((s = Math.abs(l + a)), (c += s));
							d.stockChart._rangeEventParameter = {
								stockChart: d.stockChart,
								source: "navigator",
								index: null,
								minimum: f.convertPixelToValue(t),
								maximum: f.convertPixelToValue(c),
							};
							d.stockChart.sessionVariables._axisXMin =
								f.convertPixelToValue(t);
							d.stockChart.sessionVariables._axisXMax =
								f.convertPixelToValue(c);
							d.navigator.dynamicUpdate
								? ((d.stockChart._rangeEventParameter.type = "rangeChanging"),
								  d.stockChart.dispatchEvent(
										"rangeChanging",
										d.stockChart._rangeEventParameter,
										d.stockChart
								  ),
								  d.stockChart._syncCharts(
										f.convertPixelToValue(t),
										f.convertPixelToValue(c)
								  ),
								  (d.stockChart._rangeEventParameter.type = "rangeChanged"),
								  d.stockChart.dispatchEvent(
										"rangeChanged",
										d.stockChart._rangeEventParameter,
										d.stockChart
								  ))
								: d.navigator._updateSlider(
										d.stockChart.sessionVariables._axisXMin,
										d.stockChart.sessionVariables._axisXMax
								  );
						}
					}
				},
				this.navigator.stockChart.allDOMEventHandlers
			);
			y(
				this.navigator.panel,
				window.navigator.msPointerEnabled ? "MSPointerDown" : "touchstart",
				function (g) {
					d.stockChart._touchEventHandler(g);
				},
				this.navigator.stockChart.allDOMEventHandlers
			);
			y(
				this.navigator.stockChart.container,
				"mousemove",
				function (l) {
					var t =
						d.navigator.chart.axisX && d.navigator.chart.axisX.length
							? d.navigator.chart.axisX[0]
							: d.navigator.chart.axisX2[0];
					if (!g(d.currentHandle) && d.startPosition) {
						d.navigator.chart.overlaidCanvas.style.cursor =
							d.navigator.chart.container.style.cursor =
							d.navigator.panel.style.cursor =
								"ew-resize";
						var s;
						s = d.currentHandle === d.leftHandle ? 1 : -1;
						var a = Aa(l);
						l = l.target || l.srcElement;
						a.x =
							l === d.currentHandle
								? a.x +
								  (d.currentHandle.offsetLeft +
										d.currentHandle.clientWidth / 2 +
										d.panel.offsetLeft)
								: l === d.mask || l === d.panel
								? a.x + d.panel.offsetLeft
								: l === d.rightHandle && d.currentHandle === d.leftHandle
								? a.x +
								  (d.rightHandle.offsetLeft +
										d.rightHandle.clientWidth / 2 +
										d.panel.offsetLeft)
								: l === d.leftHandle && d.currentHandle === d.rightHandle
								? a.x + d.panel.offsetLeft
								: a.x +
								  ("canvasjs-chart-toolbar" === l.parentElement.className
										? l.offsetParent.offsetLeft +
										  l.offsetLeft -
										  d.stockChart._chartsPlotAreaX1
										: l.parentElement.parentElement.parentElement ===
										  d.navigator.panel
										? l.offsetLeft
										: l.offsetLeft - d.stockChart._chartsPlotAreaX1);
						var f = a.x - d.startPosition.x;
						if (0 !== f) {
							l = d.panel.offsetLeft;
							var c = d.panel.clientWidth;
							0 < s && (l = Math.max(d.startLeft + s * f, d._left));
							d.panMode ||
								((c = Math.min(d.startWidth - s * f, d.fullWidth)),
								s * f > d.startWidth
									? ((l =
											0 < s
												? d.startLeft + d.startWidth
												: Math.max(a.x, d._left)),
									  (c = (0 < s ? a.x : d.startLeft) - parseFloat(l)))
									: 0 > s && (l = d.startLeft));
							l + c <= d._left + d.fullWidth &&
								(2 < Math.abs(d.startLeft - l) ||
									2 < Math.abs(d.startWidth - parseFloat(c))) &&
								(0 < s && ((d.left = l), (d.panel.style.left = d.left + "px")),
								d.panMode || (d.panel.style.width = d.width = c + "px"),
								g(t) ||
									((s = t.convertPixelToValue(
										Math.max(t.bounds.x1, parseFloat(l))
									)),
									(t = t.convertPixelToValue(parseFloat(l) + parseFloat(c))),
									(d.stockChart.sessionVariables._axisXMin = s),
									(d.stockChart.sessionVariables._axisXMax = t),
									d.stockChart.rangeSelector &&
										!d.panMode &&
										d.stockChart.rangeSelector.resetRangeButtons(),
									(d.stockChart._rangeEventParameter = {
										stockChart: d.stockChart,
										source: "navigator",
										index: null,
										minimum: s,
										maximum: t,
									}),
									d.navigator.dynamicUpdate
										? ((d.stockChart._rangeEventParameter.type =
												"rangeChanging"),
										  d.stockChart.dispatchEvent(
												"rangeChanging",
												d.stockChart._rangeEventParameter,
												d.stockChart
										  ),
										  d.stockChart._syncCharts(s, t),
										  (d.stockChart._rangeEventParameter.type = "rangeChanged"),
										  d.stockChart.dispatchEvent(
												"rangeChanged",
												d.stockChart._rangeEventParameter,
												d.stockChart
										  ))
										: d.navigator._updateSlider(
												d.stockChart.sessionVariables._axisXMin,
												d.stockChart.sessionVariables._axisXMax
										  )));
						}
					} else
						d.navigator.chart.overlaidCanvas.style.cursor =
							d.navigator.chart.container.style.cursor =
							d.navigator.panel.style.cursor =
								"auto";
				},
				this.navigator.stockChart.allDOMEventHandlers
			);
			y(
				this.navigator.stockChart.container,
				window.navigator.msPointerEnabled ? "MSPointerMove" : "touchmove",
				function (g) {
					d.stockChart._touchEventHandler(g);
				},
				this.navigator.stockChart.allDOMEventHandlers
			);
		};
		U.prototype._initialize = function () {
			var d = this,
				l;
			this._updateOptions();
			ga &&
				(this.eventsAttached ||
					(this._attachEvents(), (this.eventsAttached = !0)),
				(this.handleWidth = this.options.handleWidth ? this.handleWidth : 8),
				(this.handleHeight = this.options.handleHeight
					? Math.min(this.navigator.height, this.options.handleHeight)
					: 0.25 * this.navigator.height),
				this.leftHandle
					? N(this.leftHandle, {
							height: this.navigator.height + "px",
							width: this.handleWidth + 10 + "px",
							position: "absolute",
							left: "-" + (this.handleWidth / 2 + 8) + "px",
							top: 0,
							cursor: "ew-resize",
							borderStyle: "solid",
							borderWidth: this.handleBorderThickness + "px",
							borderColor: "transparent",
							outline: "none",
							padding: 0,
							backgroundColor: "transparent",
							color: "transparent",
							fontSize: "12px",
							fontFamily: "Arial",
							userSelect: "none",
							MozUserSelect: "none",
							WebkitUserSelect: "none",
							opacity: 1,
							zIndex: 999,
					  })
					: ((this.leftHandle = v(
							"button",
							"canvasjs-slider-handle",
							"button"
					  )),
					  (this.leftHandle.innerHTML = "||"),
					  N(this.leftHandle, {
							height: this.navigator.height + "px",
							width: this.handleWidth + 10 + "px",
							position: "absolute",
							left: "-" + (this.handleWidth / 2 + 8) + "px",
							top: 0,
							cursor: "ew-resize",
							borderStyle: "solid",
							borderWidth: this.handleBorderThickness + "px",
							borderColor: "transparent",
							outline: "none",
							padding: 0,
							backgroundColor: "transparent",
							color: "transparent",
							fontSize: "12px",
							fontFamily: "Arial",
							userSelect: "none",
							MozUserSelect: "none",
							WebkitUserSelect: "none",
							opacity: 1,
							zIndex: 999,
					  }),
					  this.panel.appendChild(this.leftHandle),
					  this.initHandle(this.leftHandle, 1)),
				this.rightHandle
					? N(this.rightHandle, {
							height: this.navigator.height + "px",
							width: this.handleWidth + 10 + "px",
							position: "absolute",
							right: "-" + (this.handleWidth / 2 + 8) + "px",
							top: 0,
							cursor: "ew-resize",
							borderStyle: "solid",
							borderWidth: this.handleBorderThickness + "px",
							borderColor: "transparent",
							outline: "none",
							padding: "0px",
							backgroundColor: "transparent",
							color: "transparent",
							fontSize: "12px",
							fontFamily: "Arial",
							userSelect: "none",
							MozUserSelect: "none",
							WebkitUserSelect: "none",
							msUserSelect: "none",
							opacity: 1,
							zIndex: "999",
					  })
					: ((this.rightHandle = v(
							"button",
							"canvasjs-slider-handle",
							"button"
					  )),
					  (this.rightHandle.innerHTML = "||"),
					  N(this.rightHandle, {
							height: this.navigator.height + "px",
							width: this.handleWidth + 10 + "px",
							position: "absolute",
							right: "-" + (this.handleWidth / 2 + 8) + "px",
							top: 0,
							cursor: "ew-resize",
							borderStyle: "solid",
							borderWidth: this.handleBorderThickness + "px",
							borderColor: "transparent",
							outline: "none",
							padding: "0px",
							backgroundColor: "transparent",
							color: "transparent",
							fontSize: "12px",
							fontFamily: "Arial",
							userSelect: "none",
							MozUserSelect: "none",
							WebkitUserSelect: "none",
							msUserSelect: "none",
							opacity: 1,
							zIndex: "999",
					  }),
					  this.panel.appendChild(this.rightHandle),
					  this.initHandle(this.rightHandle, -1)),
				N(this.panel, {
					height: this.navigator.height + "px",
					width: "100%",
					position: "absolute",
					cursor: "move",
				}),
				(this.fullWidth = this.panel.clientWidth),
				(this._left = this.panel.offsetLeft),
				y(
					document,
					"mousedown",
					function (g) {
						if (
							d.stockChart.rangeSelector &&
							d.stockChart.rangeSelector.inputFields &&
							d.stockChart.rangeSelector.inputFields.enabled
						)
							for (
								var l = 0;
								l < d.stockChart.rangeSelector.inputFields.elements.length;
								l++
							)
								g.target !=
									d.stockChart.rangeSelector.inputFields.elements[l] &&
									d.stockChart.rangeSelector.inputFields.elements[l].focused &&
									d.stockChart.rangeSelector.inputFields.elements[l].blur();
					},
					this.navigator.stockChart.allDOMEventHandlers
				),
				y(
					document,
					window.navigator.msPointerEnabled ? "MSPointerDown" : "touchstart",
					function (d) {
						a: {
							var g = d.changedTouches,
								g = g ? g[0] : d,
								a = "";
							switch (d.type) {
								case "touchstart":
								case "MSPointerDown":
									a = "mousedown";
									break;
								case "touchmove":
								case "MSPointerMove":
									a = "mousemove";
									break;
								case "touchend":
								case "MSPointerUp":
									a = "mouseup";
									break;
								default:
									break a;
							}
							d = document.createEvent("MouseEvent");
							d.initMouseEvent(
								a,
								!0,
								!0,
								window,
								1,
								g.screenX,
								g.screenY,
								g.clientX,
								g.clientY,
								!1,
								!1,
								!1,
								!1,
								0,
								null
							);
							g.target.dispatchEvent(d);
						}
					},
					this.navigator.stockChart.allDOMEventHandlers
				),
				y(
					document,
					"mouseup",
					function (t) {
						d.panMode = !1;
						d.navigator.stockChart.isDrag = !1;
						if (d.stockChart.navigatorClicked) {
							t = Math.min(
								d.stockChart.sessionVariables._axisXMin,
								d.stockChart.sessionVariables._axisXMax
							);
							var s = Math.max(
								d.stockChart.sessionVariables._axisXMin,
								d.stockChart.sessionVariables._axisXMax
							);
							g(d.stockChart._rangeEventParameter) &&
								(d.stockChart._rangeEventParameter = {
									stockChart: d.stockChart,
									source: "navigator",
									index: null,
									minimum: t,
									maximum: s,
								});
							if (
								2 < Math.abs(d.startLeft - d.left) ||
								2 < Math.abs(d.startWidth - parseFloat(d.width))
							) {
								d.navigator.dynamicUpdate ||
									((d.stockChart._rangeEventParameter.type = "rangeChanging"),
									d.stockChart.dispatchEvent(
										"rangeChanging",
										d.stockChart._rangeEventParameter,
										d.stockChart
									));
								for (var a = 0; a < d.stockChart.charts.length; a++) {
									l = d.stockChart.charts[a];
									l.toolTip && l.toolTip.enabled && l.toolTip.hide();
									for (var f = 0; f < l.axisX.length; f++)
										l.axisX[f].setViewPortRange(t, s);
									for (f = 0; f < l.axisX2.length; f++)
										l.axisX2[f].setViewPortRange(t, s);
									l.render();
								}
								d.stockChart.rangeSelector &&
									d.stockChart.rangeSelector.enabled &&
									d.stockChart.rangeSelector.inputFields &&
									d.stockChart.rangeSelector.inputFields.enabled &&
									d.stockChart.rangeSelector._updateInputFields(t, s);
								d.navigator.dynamicUpdate ||
									((d.stockChart._rangeEventParameter.type = "rangeChanged"),
									d.stockChart.dispatchEvent(
										"rangeChanged",
										d.stockChart._rangeEventParameter,
										d.stockChart
									));
							}
						}
						d.stockChart.navigatorClicked =
							d.currentHandle =
							d.startPosition =
							d.startWidth =
							d.startLeft =
								null;
					},
					this.navigator.stockChart.allDOMEventHandlers
				),
				y(
					document,
					window.navigator.msPointerEnabled ? "MSPointerUp" : "touchend",
					function (t) {
						d.panMode = !1;
						d.navigator.stockChart.isDrag = !1;
						if (d.stockChart.navigatorClicked) {
							t = Math.min(
								d.stockChart.sessionVariables._axisXMin,
								d.stockChart.sessionVariables._axisXMax
							);
							var s = Math.max(
								d.stockChart.sessionVariables._axisXMin,
								d.stockChart.sessionVariables._axisXMax
							);
							g(d.stockChart._rangeEventParameter) &&
								(d.stockChart._rangeEventParameter = {
									stockChart: d.stockChart,
									source: "navigator",
									index: null,
									minimum: t,
									maximum: s,
								});
							d.navigator.dynamicUpdate ||
								((d.stockChart._rangeEventParameter.type = "rangeChanging"),
								d.stockChart.dispatchEvent(
									"rangeChanging",
									d.stockChart._rangeEventParameter,
									d.stockChart
								));
							for (var a = 0; a < d.stockChart.charts.length; a++) {
								l = d.stockChart.charts[a];
								l.toolTip && l.toolTip.enabled && l.toolTip.hide();
								for (var f = 0; f < l.axisX.length; f++)
									l.axisX[f].setViewPortRange(t, s);
								for (f = 0; f < l.axisX2.length; f++)
									l.axisX2[f].setViewPortRange(t, s);
								l.render();
							}
							d.stockChart.rangeSelector &&
								d.stockChart.rangeSelector.enabled &&
								d.stockChart.rangeSelector.inputFields &&
								d.stockChart.rangeSelector.inputFields.enabled &&
								d.stockChart.rangeSelector._updateInputFields(t, s);
							d.navigator.dynamicUpdate ||
								((d.stockChart._rangeEventParameter.type = "rangeChanged"),
								d.stockChart.dispatchEvent(
									"rangeChanged",
									d.stockChart._rangeEventParameter,
									d.stockChart
								));
						}
						d.stockChart.navigatorClicked =
							d.currentHandle =
							d.startPosition =
							d.startWidth =
							d.startLeft =
								null;
					},
					this.navigator.stockChart.allDOMEventHandlers
				));
		};
		U.prototype.initHandle = function (d, g) {
			var l = this;
			d &&
				(y(
					d,
					"mousedown",
					function (d) {
						l.stockChart.navigatorClicked = !0;
						l.currentHandle = this;
						l.startPosition = Aa(d);
						d.target === l.currentHandle
							? (l.startPosition.x +=
									l.currentHandle.offsetLeft +
									l.currentHandle.clientWidth / 2 +
									l.panel.offsetLeft)
							: d.target === l.panel &&
							  (l.startPosition.x += l.panel.offsetLeft);
						l.startWidth = l.panel.clientWidth;
						l.startLeft = l.panel.offsetLeft;
						d.preventDefault && d.preventDefault();
						d.stopPropagation && d.stopPropagation();
					},
					this.navigator.stockChart.allDOMEventHandlers
				),
				y(
					d,
					window.navigator.msPointerEnabled ? "MSPointerDown" : "touchstart",
					function (d) {
						l.stockChart._touchEventHandler(d);
					},
					this.navigator.stockChart.allDOMEventHandlers
				));
		};
		ia(S, da);
		S.prototype._updateOptions = function () {
			this.updateOption("enabled");
			this.updateOption("verticalAlign");
			this.updateOption("horizontalAlign");
			this.updateOption("width");
			this.updateOption("height");
			this.updateOption("label");
			this.updateOption("selectedRangeButtonIndex");
		};
		S.prototype._initialize = function () {
			var d = 0;
			this._updateOptions();
			this._label = {};
			this.buttons = [];
			if (ga && this.enabled) {
				if (this.options.buttons)
					for (var l = 0; l < this.options.buttons.length; l++)
						this.buttons.push(
							new Q(this.stockChart, l, this.options.buttons[l])
						);
				else {
					for (l = 0; 6 > l; l++) this.buttons.push(new Q(this.stockChart, l));
					this.buttonLabels = "1m 3m 6m YTD 1y All".split(" ");
				}
				this._wrapElements = !1;
				var t = this.stockChart.layoutManager.getFreeSpace(),
					s = t.x1,
					l = t.y1;
				this.width = this.options.width ? this.width : t.width;
				g(this.buttonStyle.options.labelFontSize) &&
					(this.buttonStyle.labelFontSize = this.stockChart.getAutoFontSize(
						this.buttonStyle.labelFontSize
					));
				this.rangeButtonsWidth = 0;
				this.buttonStyle.maxWidth = this.buttonStyle.options.maxWidth
					? this.buttonStyle.options.maxWidth
					: this.stockChart.width / this.buttons.length;
				if (this.buttons && 0 < this.buttons.length) {
					var a = new na(this.ctx, {
						x: 0,
						y: 0,
						backgroundColor: this.stockChart.backgroundColor,
						borderThickness: 0,
						cornerRadius: 0,
						maxWidth: 0.2 * this.stockChart.width,
						maxHeight: 2 * this.buttonStyle.labelFontSize,
						angle: 0,
						padding: { left: 5, right: 5, top: 2, bottom: 2 },
						text:
							g(this.stockChart.options.culture) ||
							g(this.stockChart._cultureInfo.rangeSelector.rangeText)
								? this.label
								: this.stockChart._cultureInfo.rangeSelector.rangeText,
						horizontalAlign: "left",
						fontSize: this.buttonStyle.labelFontSize,
						fontFamily: this.buttonStyle.labelFontFamily,
						fontWeight: this.buttonStyle.labelFontWeight,
						fontColor: this.buttonStyle.labelFontColor,
						fontStyle: this.buttonStyle.labelFontStyle,
						textBaseline: "middle",
					});
					this._label.textBlock = a;
				}
				for (l = 0; l < this.buttons.length; l++) {
					var f = this.buttons[l];
					f._index = l;
					a = new na(this.ctx, {
						x: 0,
						y: 0,
						backgroundColor: this.buttonStyle.backgroundColor,
						borderColor: this.buttonStyle.borderColor,
						borderThickness: this.buttonStyle.borderThickness,
						cornerRadius: 0,
						maxWidth: this.buttonStyle.options.width
							? this.buttonStyle.width
							: this.buttonStyle.maxWidth,
						maxHeight: 2 * this.buttonStyle.labelFontSize,
						angle: 0,
						padding: this.buttonStyle.padding,
						text:
							this.options.buttons && this.options.buttons.length
								? g(f.label)
									? ""
									: f.label
								: this.buttonLabels[l],
						horizontalAlign: "center",
						textAlign: "center",
						fontSize: this.buttonStyle.labelFontSize,
						fontFamily: this.buttonStyle.labelFontFamily,
						fontWeight: this.buttonStyle.labelFontWeight,
						fontColor: this.buttonStyle.labelFontColor,
						fontStyle: this.buttonStyle.labelFontStyle,
						textBaseline: "middle",
					});
					f.state =
						g(this.selectedRangeButtonIndex) ||
						this.selectedRangeButtonIndex !== l
							? "off"
							: "on";
					f.textBlock = a;
					f.bounds = {
						x1: null,
						x2: null,
						y1: null,
						y2: null,
						width: null,
						height: null,
					};
					a.measureText();
					this.rangeButtonsWidth += a.width + this.buttonStyle.spacing;
					this.rangeButtonsHeight = a.height;
					d = d < a.height ? a.height : d;
					f = this.buttons[l];
					f.id = ++this.stockChart._eventManager.lastObjectId;
					this.stockChart._eventManager.objectMap[f.id] = {
						id: f.id,
						objectType: "rangeButton",
						rangeButtonIndex: l,
					};
				}
				this.height <
					this.inputFieldsHeight + 2 * this.inputFields.style.borderThickness &&
					(this.height =
						this.inputFieldsHeight +
						2 * this.inputFields.style.borderThickness);
				this.height < d + 2 * this.buttonStyle.borderThickness + 5 &&
					(this.height = d + 2 * this.buttonStyle.borderThickness + 5);
				this._label &&
					this._label.textBlock &&
					this.rangeButtonsWidth &&
					this.inputFieldsWidth &&
					this.rangeButtonsWidth +
						this._label.textBlock.measureText().width +
						this.inputFieldsWidth >=
						this.stockChart.width &&
					((this.height = this.options.height ? this.height : 2 * this.height),
					(this._wrapElements = !0));
				"bottom" === this.verticalAlign
					? ((l = t.y2 - this.height), (d = "bottom"))
					: ((l = t.y1), (d = "top"));
				"left" === this.horizontalAlign
					? (s = t.x1 + 2)
					: "center" === this.horizontalAlign
					? (s = t.x1 + t.width / 2 - this.width / 2)
					: "right" === this.horizontalAlign && (s = t.x2 - 2 - this.width);
				this.stockChart.layoutManager.registerSpace(d, {
					width: this.width,
					height: this.height,
				});
				this.bounds = {
					x1: s,
					y1: l,
					x2: s + this.width,
					y2: l + this.height,
					width: this.width,
					height: this.height,
				};
				for (l = 0; l < this.buttons.length; l++)
					this.buttons[l]._initialize(l);
			}
		};
		S.prototype.render = function () {
			var d;
			this.setButtonsLayout();
			ea(
				this.ctx,
				this.bounds.x1,
				this.bounds.y1,
				this.bounds.x2,
				this.bounds.y2,
				this.stockChart.backgroundColor,
				0,
				null,
				0,
				0,
				0,
				0,
				1
			);
			this._label && this._label.textBlock && this._label.textBlock.render(!0);
			for (d = 0; d < this.buttons.length; d++)
				this.buttons[d].enabled ||
					((this.buttons[d].textBlock.backgroundColor =
						this.buttons[d].backgroundColorDisabled),
					(this.buttons[d].textBlock.borderColor =
						this.buttons[d].backgroundColorDisabled)),
					this.buttons[d].render(!0);
			this.inputFields &&
				this.inputFields.enabled &&
				this.renderInputFields(!0);
		};
		S.prototype.setButtonsLayout = function () {
			var d,
				g,
				l = this.bounds.x1 + 5,
				s,
				a;
			this._label &&
				this._label.textBlock &&
				0 < this.buttons.length &&
				((this._label.textBlock.x = l),
				(this._label.textBlock.y = a =
					this.bounds.y1 +
					this._label.textBlock.measureText().height / 2 +
					2 * this.buttons[0].textBlock.borderThickness),
				(this._label.bounds = {
					x1: l,
					y1: a - this._label.textBlock.fontSize / 2,
					x2: l + this._label.textBlock.measureText().width,
					y2: a + this._label.textBlock.measureText().height,
					width: this._label.textBlock.measureText().width,
					height: this._label.textBlock.measureText().height,
				}),
				(l += this._label.bounds.width));
			for (d = 0; d < this.buttons.length; d++)
				(g = this.buttons[d]),
					(g.textBlock.width =
						g.textBlock.maxWidth =
						this.buttonStyle.width =
							this.buttonStyle.options.width
								? this.buttonStyle.width
								: g.textBlock.width),
					(s = l + g.textBlock.width),
					(g.textBlock.x = g.bounds.x1 =
						1 === g.textBlock.borderThickness % 2 ? (l << 0) + 0.5 : l),
					(g.bounds.x2 = s),
					(a =
						1 === g.textBlock.borderThickness % 2
							? (this.bounds.y1 +
									g.textBlock.height / 2 +
									2 * g.textBlock.borderThickness) <<
							  0.5
							: this.bounds.y1 +
							  g.textBlock.height / 2 +
							  2 * g.textBlock.borderThickness),
					(g.textBlock.y = a),
					(g.bounds.y1 = a - g.textBlock.height / 2),
					(g.bounds.y2 = g.bounds.y1 + g.textBlock.height),
					(g.bounds.width = s - l),
					(g.bounds.height = g.bounds.y2 - g.bounds.y1),
					(l = s + this.buttonStyle.spacing),
					(g.textBlock.maxWidth = this.buttonStyle.maxWidth);
		};
		S.prototype.resetRangeButtons = function () {
			for (var d = 0; d < this.buttons.length; d++)
				this.buttons[d].enabled &&
					((this.buttons[d].state = "off"),
					(this.buttons[d].textBlock.backgroundColor =
						this.buttonStyle.backgroundColor),
					(this.buttons[d].textBlock.fontColor =
						this.buttonStyle.labelFontColor),
					(this.buttons[d].textBlock.fontWeight =
						this.buttonStyle.labelFontWeight),
					this.buttons[d].textBlock.render(!0));
		};
		S.prototype.renderInputFields = function () {
			this.inputFields._textBlocks[0].text =
				"dateTime" === this.inputFields.valueType
					? ha(
							this.inputFields.startValue,
							this.inputFields.valueFormatString,
							this.stockChart._cultureInfo
					  )
					: ba(
							this.inputFields.startValue,
							this.inputFields.valueFormatString,
							this.stockChart._cultureInfo
					  );
			this.inputFields._textBlocks[1].text =
				"dateTime" === this.inputFields.valueType
					? ha(
							this.inputFields.endValue,
							this.inputFields.valueFormatString,
							this.stockChart._cultureInfo
					  )
					: ba(
							this.inputFields.endValue,
							this.inputFields.valueFormatString,
							this.stockChart._cultureInfo
					  );
			this.inputFields._labels[0].text = g(
				this.stockChart._cultureInfo.rangeSelector.fromText
			)
				? "From"
				: this.stockChart._cultureInfo.rangeSelector.fromText;
			this.inputFields._labels[1].text = g(
				this.stockChart._cultureInfo.rangeSelector.toText
			)
				? "To"
				: this.stockChart._cultureInfo.rangeSelector.toText;
			var d = 0,
				l = !0;
			if (
				(this.stockChart.title &&
					this.stockChart.title.text &&
					("top" === this.stockChart.title.verticalAlign ||
						("right" === this.stockChart.title.horizontalAlign &&
							"center" === this.stockChart.title.verticalAlign &&
							this.stockChart.exportEnabled))) ||
				"bottom" === this.verticalAlign
			)
				l = !1;
			if (this.stockChart.subtitles)
				for (var t = 0; t < this.stockChart.subtitles.length; t++)
					this.stockChart.subtitles[t] &&
						this.stockChart.subtitles[t].text &&
						("top" === this.stockChart.subtitles[t].verticalAlign ||
							("right" === this.stockChart.subtitles[t].horizontalAlign &&
								"center" === this.stockChart.subtitles[t].verticalAlign &&
								this.stockChart.exportEnabled)) &&
						(l = !1);
			for (t = this.inputFields._textBlocks.length - 1; 0 <= t; t--) {
				var s = this.inputFields._textBlocks[t],
					a = s.measureText();
				s._width = this.inputFields.style.options.width
					? this.inputFields.style.width
					: Math.abs(a.width - s._width) <=
					  ("dateTime" === this.inputFields.valueType ? 10 : 5)
					? s._width
					: a.width + 10;
				d += s._width + this.inputFields.style.spacing;
				this.inputFields.elements[t].style.width =
					s._width -
					this.inputFields.style.spacing -
					2 * this.inputFields.style.borderThickness -
					5 +
					"px";
				s.x = s.bounds.x1 =
					1 === s.borderThickness % 2
						? ((this.bounds.x2 -
								d -
								(this.stockChart.exportEnabled && l ? 40 : 0)) <<
								0) +
						  0.5
						: this.bounds.x2 -
						  d -
						  (this.stockChart.exportEnabled && l ? 40 : 0);
				s.bounds.x2 = s.bounds.x1 + s._width;
				this.inputFields.elements[t].style.left =
					this.bounds.x2 -
					d -
					(this.stockChart.exportEnabled && l ? 40 : 0) +
					this.inputFields.style.borderThickness +
					"px";
				d += this.inputFields._labels[t].measureText().width;
				this.inputFields._labels[t].x = this.inputFields._labels[t].bounds.x1 =
					this.bounds.x2 - d - (this.stockChart.exportEnabled && l ? 40 : 0);
				this.inputFields._labels[t].bounds.x2 =
					this.inputFields._labels[t].bounds.x1 +
					this.inputFields._labels[t].measureText().width;
				s.width = s._width;
				s.y =
					1 === s.borderThickness % 2
						? ((this._wrapElements
								? this.bounds.y1 + this.rangeButtonsHeight + 10
								: this.bounds.y1) +
								a.height / 2 +
								2 * s.borderThickness) <<
						  0.5
						: (this._wrapElements
								? this.bounds.y1 + this.rangeButtonsHeight + 10
								: this.bounds.y1) +
						  a.height / 2 +
						  2 * s.borderThickness;
				this.inputFields._labels[t].y =
					(this._wrapElements
						? this.bounds.y1 + this.rangeButtonsHeight + 10
						: this.bounds.y1) +
					a.height / 2 +
					2 * s.borderThickness;
				s.bounds.y1 = this.inputFields._labels[t].bounds.y1 =
					s.y - a.height / 2 + 2 * s.borderThickness;
				s.bounds.y2 = this.inputFields._labels[t].bounds.y2 =
					s.bounds.y1 + a.height;
				this.inputFields.elements[t].style.top =
					this.inputFields._labels[t].bounds.y1 + "px";
				s.render(!0);
				this.inputFields._labels[t].render(!0);
			}
		};
		S.prototype._updateInputFields = function (d, g) {
			this.inputFields.elements[0].focused ||
				(this.inputFields.elements[0].value =
					"dateTime" === this.inputFields.valueType
						? ha(d, "YYYY-MM-DD")
						: ba(d, this.inputFields.valueFormatString));
			this.inputFields.elements[1].focused ||
				(this.inputFields.elements[1].value =
					"dateTime" === this.inputFields.valueType
						? ha(g, "YYYY-MM-DD")
						: ba(g, this.inputFields.valueFormatString));
			for (var l = 0; l < this.inputFields.elements.length; l++)
				this.inputFields.elements[l].focused &&
					this.inputFields.elements[l].focus();
			this.inputFields.startValue = d;
			this.inputFields.endValue = g;
			this.render();
		};
		ia(Q, da);
		Q.prototype._updateOptions = function () {
			this.updateOption("style");
			this.updateOption("label");
			this.updateOption("range");
			this.updateOption("rangeType");
		};
		Q.prototype._initialize = function (d) {
			this._updateOptions();
			var l = "1m 3m 6m YTD 1y All".split(" "),
				t = [1, 3, 6, null, 1, null],
				s = "month month month ytd year all".split(" ");
			this.range =
				"undefined" !== typeof this.options.range
					? this.range
					: g(t[d])
					? null
					: t[d];
			this.rangeType =
				"undefined" !== typeof this.options.rangeType ? this.rangeType : s[d];
			this.label = this.textBlock.text = g(this.options.label)
				? l[d]
				: this.label;
		};
		Q.prototype.render = function () {
			this.textBlock.render(!0);
		};
		ia(W, da);
		W.prototype._updateOptions = function () {
			this.updateOption("enabled");
			this.updateOption("startValue");
			this.updateOption("endValue");
			this.updateOption("valueType");
			this.updateOption("valueFormatString");
		};
		W.prototype._initialize = function () {
			this._updateOptions();
			g(this.style.options.fontSize) &&
				(this.style.fontSize = this.stockChart.getAutoFontSize(
					this.style.fontSize
				));
			if (this.enabled) {
				for (var d = 0; d < this.elements.length; d++)
					(this.elements[d].style.position = "absolute"),
						(this.elements[d].style.display = this.elements[d].focused
							? "inline"
							: "none"),
						(this.elements[d].style.backgroundColor =
							this.style.backgroundColor),
						(this.elements[d].style.border = "none"),
						(this.elements[d].style.outline = "none"),
						(this.elements[d].style.outlineOffset = 0),
						(this.elements[d].style.textAlign = "center"),
						(this.elements[d].style.color = this.style.fontColor),
						(this.elements[d].style.fontSize = this.elements[d].style.height =
							this.style.fontSize + "px"),
						(this.elements[d].style.fontStyle = this.style.fontStyle),
						(this.elements[d].style.fontFamily = this.style.fontFamily),
						(this.elements[d].style.fontWeight = this.style.fontWeight),
						(this.elements[d].style.lineHeight = 1),
						isNaN(this.style.padding)
							? ((this.elements[d].style.paddingTop =
									this.style.padding.top + "px"),
							  (this.elements[d].style.paddingRight =
									this.style.padding.right + "px"),
							  (this.elements[d].style.paddingBottom =
									this.style.padding.bottom + "px"),
							  (this.elements[d].style.paddingLeft =
									this.style.padding.left + "px"))
							: (this.elements[d].style.padding = this.style.padding + "px"),
						(this.elements[d].style.mozAppearance =
							this.elements[d].style.webkitAppearance =
							this.elements[d].style.appearance =
								"none"),
						(this.elements[d].style.webkitBorderRadius = 0),
						(this.elements[d].style.mozBorderRadius = 0),
						(this.elements[d].style.borderRadius = 0),
						(this.elements[d].style.boxSizing = "content-box");
				this.parent = this.rangeSelector = this.stockChart.rangeSelector;
			}
		};
		ia(Y, da);
		Y.prototype._updateOptions = function () {
			this.updateOption("backgroundColor");
			this.updateOption("backgroundColorOnHover");
			this.updateOption("backgroundColorOnSelect");
			this.updateOption("borderColor");
			this.updateOption("borderThickness");
			this.updateOption("labelFontColor");
			this.updateOption("labelFontColorOnHover");
			this.updateOption("labelFontSize");
			this.updateOption("labelFontStyle");
			this.updateOption("labelFontFamily");
			this.updateOption("labelFontWeight");
			this.updateOption("width");
			this.updateOption("maxWidth");
			this.updateOption("padding");
			this.updateOption("spacing");
			this.updateOption("cursor");
		};
		ia(Z, da);
		Z.prototype._updateOptions = function () {
			this.updateOption("backgroundColor");
			this.updateOption("borderColor");
			this.updateOption("borderThickness");
			this.updateOption("borderColorOnFocus");
			this.updateOption("fontColor");
			this.updateOption("fontSize");
			this.updateOption("fontStyle");
			this.updateOption("fontFamily");
			this.updateOption("fontWeight");
			this.updateOption("width");
			this.updateOption("maxWidth");
			this.updateOption("padding");
			this.updateOption("spacing");
			this.updateOption("cursor");
		};
		return E;
	})();
	W.version = "v1.6.1 GA";
	window.CanvasJS &&
		(W &&
			!window.CanvasJS.StockChart &&
			window.CanvasJS.Chart &&
			(window.CanvasJS.StockChart = W),
		W &&
			!window.CanvasJS.Chart &&
			((window.CanvasJS.Chart = Ja), (window.CanvasJS.StockChart = W)));
})();

/*
  excanvas is used to support IE678 which do not implement HTML5 Canvas Element. You can safely remove the following excanvas code if you don't need to support older browsers.

  Copyright 2006 Google Inc. https://code.google.com/p/explorercanvas/
  Licensed under the Apache License, Version 2.0
*/
document.createElement("canvas").getContext ||
	(function () {
		function V() {
			return this.context_ || (this.context_ = new C(this));
		}
		function W(a, b, c) {
			var g = M.call(arguments, 2);
			return function () {
				return a.apply(b, g.concat(M.call(arguments)));
			};
		}
		function N(a) {
			return String(a).replace(/&/g, "&amp;").replace(/"/g, "&quot;");
		}
		function O(a) {
			a.namespaces.g_vml_ ||
				a.namespaces.add(
					"g_vml_",
					"urn:schemas-microsoft-com:vml",
					"#default#VML"
				);
			a.namespaces.g_o_ ||
				a.namespaces.add(
					"g_o_",
					"urn:schemas-microsoft-com:office:office",
					"#default#VML"
				);
			a.styleSheets.ex_canvas_ ||
				((a = a.createStyleSheet()),
				(a.owningElement.id = "ex_canvas_"),
				(a.cssText =
					"canvas{display:inline-block;overflow:hidden;text-align:left;width:300px;height:150px}"));
		}
		function X(a) {
			var b = a.srcElement;
			switch (a.propertyName) {
				case "width":
					b.getContext().clearRect();
					b.style.width = b.attributes.width.nodeValue + "px";
					b.firstChild.style.width = b.clientWidth + "px";
					break;
				case "height":
					b.getContext().clearRect(),
						(b.style.height = b.attributes.height.nodeValue + "px"),
						(b.firstChild.style.height = b.clientHeight + "px");
			}
		}
		function Y(a) {
			a = a.srcElement;
			a.firstChild &&
				((a.firstChild.style.width = a.clientWidth + "px"),
				(a.firstChild.style.height = a.clientHeight + "px"));
		}
		function D() {
			return [
				[1, 0, 0],
				[0, 1, 0],
				[0, 0, 1],
			];
		}
		function t(a, b) {
			for (var c = D(), g = 0; 3 > g; g++)
				for (var e = 0; 3 > e; e++) {
					for (var f = 0, d = 0; 3 > d; d++) f += a[g][d] * b[d][e];
					c[g][e] = f;
				}
			return c;
		}
		function P(a, b) {
			b.fillStyle = a.fillStyle;
			b.lineCap = a.lineCap;
			b.lineJoin = a.lineJoin;
			b.lineWidth = a.lineWidth;
			b.miterLimit = a.miterLimit;
			b.shadowBlur = a.shadowBlur;
			b.shadowColor = a.shadowColor;
			b.shadowOffsetX = a.shadowOffsetX;
			b.shadowOffsetY = a.shadowOffsetY;
			b.strokeStyle = a.strokeStyle;
			b.globalAlpha = a.globalAlpha;
			b.font = a.font;
			b.textAlign = a.textAlign;
			b.textBaseline = a.textBaseline;
			b.arcScaleX_ = a.arcScaleX_;
			b.arcScaleY_ = a.arcScaleY_;
			b.lineScale_ = a.lineScale_;
		}
		function Q(a) {
			var b = a.indexOf("(", 3),
				c = a.indexOf(")", b + 1),
				b = a.substring(b + 1, c).split(",");
			if (4 != b.length || "a" != a.charAt(3)) b[3] = 1;
			return b;
		}
		function E(a, b, c) {
			return Math.min(c, Math.max(b, a));
		}
		function F(a, b, c) {
			0 > c && c++;
			1 < c && c--;
			return 1 > 6 * c
				? a + 6 * (b - a) * c
				: 1 > 2 * c
				? b
				: 2 > 3 * c
				? a + 6 * (b - a) * (2 / 3 - c)
				: a;
		}
		function G(a) {
			if (a in H) return H[a];
			var b,
				c = 1;
			a = String(a);
			if ("#" == a.charAt(0)) b = a;
			else if (/^rgb/.test(a)) {
				c = Q(a);
				b = "#";
				for (var g, e = 0; 3 > e; e++)
					(g =
						-1 != c[e].indexOf("%")
							? Math.floor(255 * (parseFloat(c[e]) / 100))
							: +c[e]),
						(b += v[E(g, 0, 255)]);
				c = +c[3];
			} else if (/^hsl/.test(a)) {
				e = c = Q(a);
				b = (parseFloat(e[0]) / 360) % 360;
				0 > b && b++;
				g = E(parseFloat(e[1]) / 100, 0, 1);
				e = E(parseFloat(e[2]) / 100, 0, 1);
				if (0 == g) g = e = b = e;
				else {
					var f = 0.5 > e ? e * (1 + g) : e + g - e * g,
						d = 2 * e - f;
					g = F(d, f, b + 1 / 3);
					e = F(d, f, b);
					b = F(d, f, b - 1 / 3);
				}
				b =
					"#" +
					v[Math.floor(255 * g)] +
					v[Math.floor(255 * e)] +
					v[Math.floor(255 * b)];
				c = c[3];
			} else b = Z[a] || a;
			return (H[a] = { color: b, alpha: c });
		}
		function C(a) {
			this.m_ = D();
			this.mStack_ = [];
			this.aStack_ = [];
			this.currentPath_ = [];
			this.fillStyle = this.strokeStyle = "#000";
			this.lineWidth = 1;
			this.lineJoin = "miter";
			this.lineCap = "butt";
			this.miterLimit = 1 * q;
			this.globalAlpha = 1;
			this.font = "10px sans-serif";
			this.textAlign = "left";
			this.textBaseline = "alphabetic";
			this.canvas = a;
			var b =
					"width:" +
					a.clientWidth +
					"px;height:" +
					a.clientHeight +
					"px;overflow:hidden;position:absolute",
				c = a.ownerDocument.createElement("div");
			c.style.cssText = b;
			a.appendChild(c);
			b = c.cloneNode(!1);
			b.style.backgroundColor = "red";
			b.style.filter = "alpha(opacity=0)";
			a.appendChild(b);
			this.element_ = c;
			this.lineScale_ = this.arcScaleY_ = this.arcScaleX_ = 1;
		}
		function R(a, b, c, g) {
			a.currentPath_.push({
				type: "bezierCurveTo",
				cp1x: b.x,
				cp1y: b.y,
				cp2x: c.x,
				cp2y: c.y,
				x: g.x,
				y: g.y,
			});
			a.currentX_ = g.x;
			a.currentY_ = g.y;
		}
		function S(a, b) {
			var c = G(a.strokeStyle),
				g = c.color,
				c = c.alpha * a.globalAlpha,
				e = a.lineScale_ * a.lineWidth;
			1 > e && (c *= e);
			b.push(
				"<g_vml_:stroke",
				' opacity="',
				c,
				'"',
				' joinstyle="',
				a.lineJoin,
				'"',
				' miterlimit="',
				a.miterLimit,
				'"',
				' endcap="',
				$[a.lineCap] || "square",
				'"',
				' weight="',
				e,
				'px"',
				' color="',
				g,
				'" />'
			);
		}
		function T(a, b, c, g) {
			var e = a.fillStyle,
				f = a.arcScaleX_,
				d = a.arcScaleY_,
				k = g.x - c.x,
				n = g.y - c.y;
			if (e instanceof w) {
				var h = 0,
					l = (g = 0),
					u = 0,
					m = 1;
				if ("gradient" == e.type_) {
					h = e.x1_ / f;
					c = e.y1_ / d;
					var p = s(a, e.x0_ / f, e.y0_ / d),
						h = s(a, h, c),
						h = (180 * Math.atan2(h.x - p.x, h.y - p.y)) / Math.PI;
					0 > h && (h += 360);
					1e-6 > h && (h = 0);
				} else
					(p = s(a, e.x0_, e.y0_)),
						(g = (p.x - c.x) / k),
						(l = (p.y - c.y) / n),
						(k /= f * q),
						(n /= d * q),
						(m = x.max(k, n)),
						(u = (2 * e.r0_) / m),
						(m = (2 * e.r1_) / m - u);
				f = e.colors_;
				f.sort(function (a, b) {
					return a.offset - b.offset;
				});
				d = f.length;
				p = f[0].color;
				c = f[d - 1].color;
				k = f[0].alpha * a.globalAlpha;
				a = f[d - 1].alpha * a.globalAlpha;
				for (var n = [], r = 0; r < d; r++) {
					var t = f[r];
					n.push(t.offset * m + u + " " + t.color);
				}
				b.push(
					'<g_vml_:fill type="',
					e.type_,
					'"',
					' method="none" focus="100%"',
					' color="',
					p,
					'"',
					' color2="',
					c,
					'"',
					' colors="',
					n.join(","),
					'"',
					' opacity="',
					a,
					'"',
					' g_o_:opacity2="',
					k,
					'"',
					' angle="',
					h,
					'"',
					' focusposition="',
					g,
					",",
					l,
					'" />'
				);
			} else
				e instanceof I
					? k &&
					  n &&
					  b.push(
							"<g_vml_:fill",
							' position="',
							(-c.x / k) * f * f,
							",",
							(-c.y / n) * d * d,
							'"',
							' type="tile"',
							' src="',
							e.src_,
							'" />'
					  )
					: ((e = G(a.fillStyle)),
					  b.push(
							'<g_vml_:fill color="',
							e.color,
							'" opacity="',
							e.alpha * a.globalAlpha,
							'" />'
					  ));
		}
		function s(a, b, c) {
			a = a.m_;
			return {
				x: q * (b * a[0][0] + c * a[1][0] + a[2][0]) - r,
				y: q * (b * a[0][1] + c * a[1][1] + a[2][1]) - r,
			};
		}
		function z(a, b, c) {
			isFinite(b[0][0]) &&
				isFinite(b[0][1]) &&
				isFinite(b[1][0]) &&
				isFinite(b[1][1]) &&
				isFinite(b[2][0]) &&
				isFinite(b[2][1]) &&
				((a.m_ = b),
				c && (a.lineScale_ = aa(ba(b[0][0] * b[1][1] - b[0][1] * b[1][0]))));
		}
		function w(a) {
			this.type_ = a;
			this.r1_ = this.y1_ = this.x1_ = this.r0_ = this.y0_ = this.x0_ = 0;
			this.colors_ = [];
		}
		function I(a, b) {
			if (!a || 1 != a.nodeType || "IMG" != a.tagName)
				throw new A("TYPE_MISMATCH_ERR");
			if ("complete" != a.readyState) throw new A("INVALID_STATE_ERR");
			switch (b) {
				case "repeat":
				case null:
				case "":
					this.repetition_ = "repeat";
					break;
				case "repeat-x":
				case "repeat-y":
				case "no-repeat":
					this.repetition_ = b;
					break;
				default:
					throw new A("SYNTAX_ERR");
			}
			this.src_ = a.src;
			this.width_ = a.width;
			this.height_ = a.height;
		}
		function A(a) {
			this.code = this[a];
			this.message = a + ": DOM Exception " + this.code;
		}
		var x = Math,
			k = x.round,
			J = x.sin,
			K = x.cos,
			ba = x.abs,
			aa = x.sqrt,
			q = 10,
			r = q / 2;
		navigator.userAgent.match(/MSIE ([\d.]+)?/);
		var M = Array.prototype.slice;
		O(document);
		var U = {
			init: function (a) {
				a = a || document;
				a.createElement("canvas");
				a.attachEvent("onreadystatechange", W(this.init_, this, a));
			},
			init_: function (a) {
				a = a.getElementsByTagName("canvas");
				for (var b = 0; b < a.length; b++) this.initElement(a[b]);
			},
			initElement: function (a) {
				if (!a.getContext) {
					a.getContext = V;
					O(a.ownerDocument);
					a.innerHTML = "";
					a.attachEvent("onpropertychange", X);
					a.attachEvent("onresize", Y);
					var b = a.attributes;
					b.width && b.width.specified
						? (a.style.width = b.width.nodeValue + "px")
						: (a.width = a.clientWidth);
					b.height && b.height.specified
						? (a.style.height = b.height.nodeValue + "px")
						: (a.height = a.clientHeight);
				}
				return a;
			},
		};
		U.init();
		for (var v = [], d = 0; 16 > d; d++)
			for (var B = 0; 16 > B; B++)
				v[16 * d + B] = d.toString(16) + B.toString(16);
		var Z = {
				aliceblue: "#F0F8FF",
				antiquewhite: "#FAEBD7",
				aquamarine: "#7FFFD4",
				azure: "#F0FFFF",
				beige: "#F5F5DC",
				bisque: "#FFE4C4",
				black: "#000000",
				blanchedalmond: "#FFEBCD",
				blueviolet: "#8A2BE2",
				brown: "#A52A2A",
				burlywood: "#DEB887",
				cadetblue: "#5F9EA0",
				chartreuse: "#7FFF00",
				chocolate: "#D2691E",
				coral: "#FF7F50",
				cornflowerblue: "#6495ED",
				cornsilk: "#FFF8DC",
				crimson: "#DC143C",
				cyan: "#00FFFF",
				darkblue: "#00008B",
				darkcyan: "#008B8B",
				darkgoldenrod: "#B8860B",
				darkgray: "#A9A9A9",
				darkgreen: "#006400",
				darkgrey: "#A9A9A9",
				darkkhaki: "#BDB76B",
				darkmagenta: "#8B008B",
				darkolivegreen: "#556B2F",
				darkorange: "#FF8C00",
				darkorchid: "#9932CC",
				darkred: "#8B0000",
				darksalmon: "#E9967A",
				darkseagreen: "#8FBC8F",
				darkslateblue: "#483D8B",
				darkslategray: "#2F4F4F",
				darkslategrey: "#2F4F4F",
				darkturquoise: "#00CED1",
				darkviolet: "#9400D3",
				deeppink: "#FF1493",
				deepskyblue: "#00BFFF",
				dimgray: "#696969",
				dimgrey: "#696969",
				dodgerblue: "#1E90FF",
				firebrick: "#B22222",
				floralwhite: "#FFFAF0",
				forestgreen: "#228B22",
				gainsboro: "#DCDCDC",
				ghostwhite: "#F8F8FF",
				gold: "#FFD700",
				goldenrod: "#DAA520",
				grey: "#808080",
				greenyellow: "#ADFF2F",
				honeydew: "#F0FFF0",
				hotpink: "#FF69B4",
				indianred: "#CD5C5C",
				indigo: "#4B0082",
				ivory: "#FFFFF0",
				khaki: "#F0E68C",
				lavender: "#E6E6FA",
				lavenderblush: "#FFF0F5",
				lawngreen: "#7CFC00",
				lemonchiffon: "#FFFACD",
				lightblue: "#ADD8E6",
				lightcoral: "#F08080",
				lightcyan: "#E0FFFF",
				lightgoldenrodyellow: "#FAFAD2",
				lightgreen: "#90EE90",
				lightgrey: "#D3D3D3",
				lightpink: "#FFB6C1",
				lightsalmon: "#FFA07A",
				lightseagreen: "#20B2AA",
				lightskyblue: "#87CEFA",
				lightslategray: "#778899",
				lightslategrey: "#778899",
				lightsteelblue: "#B0C4DE",
				lightyellow: "#FFFFE0",
				limegreen: "#32CD32",
				linen: "#FAF0E6",
				magenta: "#FF00FF",
				mediumaquamarine: "#66CDAA",
				mediumblue: "#0000CD",
				mediumorchid: "#BA55D3",
				mediumpurple: "#9370DB",
				mediumseagreen: "#3CB371",
				mediumslateblue: "#7B68EE",
				mediumspringgreen: "#00FA9A",
				mediumturquoise: "#48D1CC",
				mediumvioletred: "#C71585",
				midnightblue: "#191970",
				mintcream: "#F5FFFA",
				mistyrose: "#FFE4E1",
				moccasin: "#FFE4B5",
				navajowhite: "#FFDEAD",
				oldlace: "#FDF5E6",
				olivedrab: "#6B8E23",
				orange: "#FFA500",
				orangered: "#FF4500",
				orchid: "#DA70D6",
				palegoldenrod: "#EEE8AA",
				palegreen: "#98FB98",
				paleturquoise: "#AFEEEE",
				palevioletred: "#DB7093",
				papayawhip: "#FFEFD5",
				peachpuff: "#FFDAB9",
				peru: "#CD853F",
				pink: "#FFC0CB",
				plum: "#DDA0DD",
				powderblue: "#B0E0E6",
				rosybrown: "#BC8F8F",
				royalblue: "#4169E1",
				saddlebrown: "#8B4513",
				salmon: "#FA8072",
				sandybrown: "#F4A460",
				seagreen: "#2E8B57",
				seashell: "#FFF5EE",
				sienna: "#A0522D",
				skyblue: "#87CEEB",
				slateblue: "#6A5ACD",
				slategray: "#708090",
				slategrey: "#708090",
				snow: "#FFFAFA",
				springgreen: "#00FF7F",
				steelblue: "#4682B4",
				tan: "#D2B48C",
				thistle: "#D8BFD8",
				tomato: "#FF6347",
				turquoise: "#40E0D0",
				violet: "#EE82EE",
				wheat: "#F5DEB3",
				whitesmoke: "#F5F5F5",
				yellowgreen: "#9ACD32",
			},
			H = {},
			L = {},
			$ = { butt: "flat", round: "round" },
			d = C.prototype;
		d.clearRect = function () {
			this.textMeasureEl_ &&
				(this.textMeasureEl_.removeNode(!0), (this.textMeasureEl_ = null));
			this.element_.innerHTML = "";
		};
		d.beginPath = function () {
			this.currentPath_ = [];
		};
		d.moveTo = function (a, b) {
			var c = s(this, a, b);
			this.currentPath_.push({ type: "moveTo", x: c.x, y: c.y });
			this.currentX_ = c.x;
			this.currentY_ = c.y;
		};
		d.lineTo = function (a, b) {
			var c = s(this, a, b);
			this.currentPath_.push({ type: "lineTo", x: c.x, y: c.y });
			this.currentX_ = c.x;
			this.currentY_ = c.y;
		};
		d.bezierCurveTo = function (a, b, c, g, e, f) {
			e = s(this, e, f);
			a = s(this, a, b);
			c = s(this, c, g);
			R(this, a, c, e);
		};
		d.quadraticCurveTo = function (a, b, c, g) {
			a = s(this, a, b);
			c = s(this, c, g);
			g = {
				x: this.currentX_ + (2 / 3) * (a.x - this.currentX_),
				y: this.currentY_ + (2 / 3) * (a.y - this.currentY_),
			};
			R(
				this,
				g,
				{
					x: g.x + (c.x - this.currentX_) / 3,
					y: g.y + (c.y - this.currentY_) / 3,
				},
				c
			);
		};
		d.arc = function (a, b, c, g, e, f) {
			c *= q;
			var d = f ? "at" : "wa",
				k = a + K(g) * c - r,
				n = b + J(g) * c - r;
			g = a + K(e) * c - r;
			e = b + J(e) * c - r;
			k != g || f || (k += 0.125);
			a = s(this, a, b);
			k = s(this, k, n);
			g = s(this, g, e);
			this.currentPath_.push({
				type: d,
				x: a.x,
				y: a.y,
				radius: c,
				xStart: k.x,
				yStart: k.y,
				xEnd: g.x,
				yEnd: g.y,
			});
		};
		d.rect = function (a, b, c, g) {
			this.moveTo(a, b);
			this.lineTo(a + c, b);
			this.lineTo(a + c, b + g);
			this.lineTo(a, b + g);
			this.closePath();
		};
		d.strokeRect = function (a, b, c, g) {
			var e = this.currentPath_;
			this.beginPath();
			this.moveTo(a, b);
			this.lineTo(a + c, b);
			this.lineTo(a + c, b + g);
			this.lineTo(a, b + g);
			this.closePath();
			this.stroke();
			this.currentPath_ = e;
		};
		d.fillRect = function (a, b, c, g) {
			var e = this.currentPath_;
			this.beginPath();
			this.moveTo(a, b);
			this.lineTo(a + c, b);
			this.lineTo(a + c, b + g);
			this.lineTo(a, b + g);
			this.closePath();
			this.fill();
			this.currentPath_ = e;
		};
		d.createLinearGradient = function (a, b, c, g) {
			var e = new w("gradient");
			e.x0_ = a;
			e.y0_ = b;
			e.x1_ = c;
			e.y1_ = g;
			return e;
		};
		d.createRadialGradient = function (a, b, c, g, e, f) {
			var d = new w("gradientradial");
			d.x0_ = a;
			d.y0_ = b;
			d.r0_ = c;
			d.x1_ = g;
			d.y1_ = e;
			d.r1_ = f;
			return d;
		};
		d.drawImage = function (a, b) {
			var c, g, e, d, r, y, n, h;
			e = a.runtimeStyle.width;
			d = a.runtimeStyle.height;
			a.runtimeStyle.width = "auto";
			a.runtimeStyle.height = "auto";
			var l = a.width,
				u = a.height;
			a.runtimeStyle.width = e;
			a.runtimeStyle.height = d;
			if (3 == arguments.length)
				(c = arguments[1]),
					(g = arguments[2]),
					(r = y = 0),
					(n = e = l),
					(h = d = u);
			else if (5 == arguments.length)
				(c = arguments[1]),
					(g = arguments[2]),
					(e = arguments[3]),
					(d = arguments[4]),
					(r = y = 0),
					(n = l),
					(h = u);
			else if (9 == arguments.length)
				(r = arguments[1]),
					(y = arguments[2]),
					(n = arguments[3]),
					(h = arguments[4]),
					(c = arguments[5]),
					(g = arguments[6]),
					(e = arguments[7]),
					(d = arguments[8]);
			else throw Error("Invalid number of arguments");
			var m = s(this, c, g),
				p = [];
			p.push(
				" <g_vml_:group",
				' coordsize="',
				10 * q,
				",",
				10 * q,
				'"',
				' coordorigin="0,0"',
				' style="width:',
				10,
				"px;height:",
				10,
				"px;position:absolute;"
			);
			if (
				1 != this.m_[0][0] ||
				this.m_[0][1] ||
				1 != this.m_[1][1] ||
				this.m_[1][0]
			) {
				var t = [];
				t.push(
					"M11=",
					this.m_[0][0],
					",",
					"M12=",
					this.m_[1][0],
					",",
					"M21=",
					this.m_[0][1],
					",",
					"M22=",
					this.m_[1][1],
					",",
					"Dx=",
					k(m.x / q),
					",",
					"Dy=",
					k(m.y / q),
					""
				);
				var v = s(this, c + e, g),
					w = s(this, c, g + d);
				c = s(this, c + e, g + d);
				m.x = x.max(m.x, v.x, w.x, c.x);
				m.y = x.max(m.y, v.y, w.y, c.y);
				p.push(
					"padding:0 ",
					k(m.x / q),
					"px ",
					k(m.y / q),
					"px 0;filter:progid:DXImageTransform.Microsoft.Matrix(",
					t.join(""),
					", sizingmethod='clip');"
				);
			} else p.push("top:", k(m.y / q), "px;left:", k(m.x / q), "px;");
			p.push(
				' ">',
				'<g_vml_:image src="',
				a.src,
				'"',
				' style="width:',
				q * e,
				"px;",
				" height:",
				q * d,
				'px"',
				' cropleft="',
				r / l,
				'"',
				' croptop="',
				y / u,
				'"',
				' cropright="',
				(l - r - n) / l,
				'"',
				' cropbottom="',
				(u - y - h) / u,
				'"',
				" />",
				"</g_vml_:group>"
			);
			this.element_.insertAdjacentHTML("BeforeEnd", p.join(""));
		};
		d.stroke = function (a) {
			var b = [];
			b.push(
				"<g_vml_:shape",
				' filled="',
				!!a,
				'"',
				' style="position:absolute;width:',
				10,
				"px;height:",
				10,
				'px;"',
				' coordorigin="0,0"',
				' coordsize="',
				10 * q,
				",",
				10 * q,
				'"',
				' stroked="',
				!a,
				'"',
				' path="'
			);
			for (
				var c = { x: null, y: null }, d = { x: null, y: null }, e = 0;
				e < this.currentPath_.length;
				e++
			) {
				var f = this.currentPath_[e];
				switch (f.type) {
					case "moveTo":
						b.push(" m ", k(f.x), ",", k(f.y));
						break;
					case "lineTo":
						b.push(" l ", k(f.x), ",", k(f.y));
						break;
					case "close":
						b.push(" x ");
						f = null;
						break;
					case "bezierCurveTo":
						b.push(
							" c ",
							k(f.cp1x),
							",",
							k(f.cp1y),
							",",
							k(f.cp2x),
							",",
							k(f.cp2y),
							",",
							k(f.x),
							",",
							k(f.y)
						);
						break;
					case "at":
					case "wa":
						b.push(
							" ",
							f.type,
							" ",
							k(f.x - this.arcScaleX_ * f.radius),
							",",
							k(f.y - this.arcScaleY_ * f.radius),
							" ",
							k(f.x + this.arcScaleX_ * f.radius),
							",",
							k(f.y + this.arcScaleY_ * f.radius),
							" ",
							k(f.xStart),
							",",
							k(f.yStart),
							" ",
							k(f.xEnd),
							",",
							k(f.yEnd)
						);
				}
				if (f) {
					if (null == c.x || f.x < c.x) c.x = f.x;
					if (null == d.x || f.x > d.x) d.x = f.x;
					if (null == c.y || f.y < c.y) c.y = f.y;
					if (null == d.y || f.y > d.y) d.y = f.y;
				}
			}
			b.push(' ">');
			a ? T(this, b, c, d) : S(this, b);
			b.push("</g_vml_:shape>");
			this.element_.insertAdjacentHTML("beforeEnd", b.join(""));
		};
		d.fill = function () {
			this.stroke(!0);
		};
		d.closePath = function () {
			this.currentPath_.push({ type: "close" });
		};
		d.save = function () {
			var a = {};
			P(this, a);
			this.aStack_.push(a);
			this.mStack_.push(this.m_);
			this.m_ = t(D(), this.m_);
		};
		d.restore = function () {
			this.aStack_.length &&
				(P(this.aStack_.pop(), this), (this.m_ = this.mStack_.pop()));
		};
		d.translate = function (a, b) {
			z(
				this,
				t(
					[
						[1, 0, 0],
						[0, 1, 0],
						[a, b, 1],
					],
					this.m_
				),
				!1
			);
		};
		d.rotate = function (a) {
			var b = K(a);
			a = J(a);
			z(
				this,
				t(
					[
						[b, a, 0],
						[-a, b, 0],
						[0, 0, 1],
					],
					this.m_
				),
				!1
			);
		};
		d.scale = function (a, b) {
			this.arcScaleX_ *= a;
			this.arcScaleY_ *= b;
			z(
				this,
				t(
					[
						[a, 0, 0],
						[0, b, 0],
						[0, 0, 1],
					],
					this.m_
				),
				!0
			);
		};
		d.transform = function (a, b, c, d, e, f) {
			z(
				this,
				t(
					[
						[a, b, 0],
						[c, d, 0],
						[e, f, 1],
					],
					this.m_
				),
				!0
			);
		};
		d.setTransform = function (a, b, c, d, e, f) {
			z(
				this,
				[
					[a, b, 0],
					[c, d, 0],
					[e, f, 1],
				],
				!0
			);
		};
		d.drawText_ = function (a, b, c, d, e) {
			var f = this.m_;
			d = 0;
			var r = 1e3,
				t = 0,
				n = [],
				h;
			h = this.font;
			if (L[h]) h = L[h];
			else {
				var l = document.createElement("div").style;
				try {
					l.font = h;
				} catch (u) {}
				h = L[h] = {
					style: l.fontStyle || "normal",
					variant: l.fontVariant || "normal",
					weight: l.fontWeight || "normal",
					size: l.fontSize || 10,
					family: l.fontFamily || "sans-serif",
				};
			}
			var l = h,
				m = this.element_;
			h = {};
			for (var p in l) h[p] = l[p];
			p = parseFloat(m.currentStyle.fontSize);
			m = parseFloat(l.size);
			"number" == typeof l.size
				? (h.size = l.size)
				: -1 != l.size.indexOf("px")
				? (h.size = m)
				: -1 != l.size.indexOf("em")
				? (h.size = p * m)
				: -1 != l.size.indexOf("%")
				? (h.size = (p / 100) * m)
				: -1 != l.size.indexOf("pt")
				? (h.size = m / 0.75)
				: (h.size = p);
			h.size *= 0.981;
			p =
				h.style +
				" " +
				h.variant +
				" " +
				h.weight +
				" " +
				h.size +
				"px " +
				h.family;
			m = this.element_.currentStyle;
			l = this.textAlign.toLowerCase();
			switch (l) {
				case "left":
				case "center":
				case "right":
					break;
				case "end":
					l = "ltr" == m.direction ? "right" : "left";
					break;
				case "start":
					l = "rtl" == m.direction ? "right" : "left";
					break;
				default:
					l = "left";
			}
			switch (this.textBaseline) {
				case "hanging":
				case "top":
					t = h.size / 1.75;
					break;
				case "middle":
					break;
				default:
				case null:
				case "alphabetic":
				case "ideographic":
				case "bottom":
					t = -h.size / 2.25;
			}
			switch (l) {
				case "right":
					d = 1e3;
					r = 0.05;
					break;
				case "center":
					d = r = 500;
			}
			b = s(this, b + 0, c + t);
			n.push(
				'<g_vml_:line from="',
				-d,
				' 0" to="',
				r,
				' 0.05" ',
				' coordsize="100 100" coordorigin="0 0"',
				' filled="',
				!e,
				'" stroked="',
				!!e,
				'" style="position:absolute;width:1px;height:1px;">'
			);
			e ? S(this, n) : T(this, n, { x: -d, y: 0 }, { x: r, y: h.size });
			e =
				f[0][0].toFixed(3) +
				"," +
				f[1][0].toFixed(3) +
				"," +
				f[0][1].toFixed(3) +
				"," +
				f[1][1].toFixed(3) +
				",0,0";
			b = k(b.x / q) + "," + k(b.y / q);
			n.push(
				'<g_vml_:skew on="t" matrix="',
				e,
				'" ',
				' offset="',
				b,
				'" origin="',
				d,
				' 0" />',
				'<g_vml_:path textpathok="true" />',
				'<g_vml_:textpath on="true" string="',
				N(a),
				'" style="v-text-align:',
				l,
				";font:",
				N(p),
				'" /></g_vml_:line>'
			);
			this.element_.insertAdjacentHTML("beforeEnd", n.join(""));
		};
		d.fillText = function (a, b, c, d) {
			this.drawText_(a, b, c, d, !1);
		};
		d.strokeText = function (a, b, c, d) {
			this.drawText_(a, b, c, d, !0);
		};
		d.measureText = function (a) {
			this.textMeasureEl_ ||
				(this.element_.insertAdjacentHTML(
					"beforeEnd",
					'<span style="position:absolute;top:-20000px;left:0;padding:0;margin:0;border:none;white-space:pre;"></span>'
				),
				(this.textMeasureEl_ = this.element_.lastChild));
			var b = this.element_.ownerDocument;
			this.textMeasureEl_.innerHTML = "";
			this.textMeasureEl_.style.font = this.font;
			this.textMeasureEl_.appendChild(b.createTextNode(a));
			return { width: this.textMeasureEl_.offsetWidth };
		};
		d.clip = function () {};
		d.arcTo = function () {};
		d.createPattern = function (a, b) {
			return new I(a, b);
		};
		w.prototype.addColorStop = function (a, b) {
			b = G(b);
			this.colors_.push({ offset: a, color: b.color, alpha: b.alpha });
		};
		d = A.prototype = Error();
		d.INDEX_SIZE_ERR = 1;
		d.DOMSTRING_SIZE_ERR = 2;
		d.HIERARCHY_REQUEST_ERR = 3;
		d.WRONG_DOCUMENT_ERR = 4;
		d.INVALID_CHARACTER_ERR = 5;
		d.NO_DATA_ALLOWED_ERR = 6;
		d.NO_MODIFICATION_ALLOWED_ERR = 7;
		d.NOT_FOUND_ERR = 8;
		d.NOT_SUPPORTED_ERR = 9;
		d.INUSE_ATTRIBUTE_ERR = 10;
		d.INVALID_STATE_ERR = 11;
		d.SYNTAX_ERR = 12;
		d.INVALID_MODIFICATION_ERR = 13;
		d.NAMESPACE_ERR = 14;
		d.INVALID_ACCESS_ERR = 15;
		d.VALIDATION_ERR = 16;
		d.TYPE_MISMATCH_ERR = 17;
		G_vmlCanvasManager = U;
		CanvasRenderingContext2D = C;
		CanvasGradient = w;
		CanvasPattern = I;
		DOMException = A;
	})();
/*eslint-enable*/
/*jshint ignore:end*/
