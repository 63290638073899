import { ResetPasswordModal } from "../pages/ResetPassword/ResetPassword";
import apiPlugin, { ApiResponse, apiPluginForImageUpload } from "./apiPlugin";

export async function loginApi(userDetail: Object): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "login", undefined, userDetail);
}

export async function forgotPasswordApi(
	email: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "forgot-password", undefined, email);
}

export async function resetPasswordApi(
	resetPassword: ResetPasswordModal
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "reset-password", undefined, resetPassword);
}

export async function getProfileDetails(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "profile");
}

export async function updateProfile(
	userDetail: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("PUT", "profile", undefined, userDetail);
}

export async function updateUserAvtar(
	url: string,
	avtarFile: any
): Promise<ApiResponse<any>> {
	return apiPluginForImageUpload<any>("PUT", url, avtarFile);
}

export async function getSignatureUrl(
	imageDetails: object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "get-upload-url", undefined, imageDetails);
}

export async function inviteUser(reqMap: object): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "invite", undefined, reqMap);
}
export async function updateUserUnderContractor(
	reqMap: object,
	userId: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("PUT", `update-user/${userId}`, undefined, reqMap);
}

export async function deleteUserUnderContractor(
	userId: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", `delete-user/${userId}`);
}

export async function acceptContractorInvite(
	reqMap: object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "accept-invite", undefined, reqMap);
}

export async function getUserList(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "contractor-users");
}

export async function addUserAPi(reqMap: object): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "add-user", undefined, reqMap);
}

export async function updateUserByContractor(
	reqMap: object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "contractor/update-user", undefined, reqMap);
}

export async function isUserExist(email: string): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `user/is-exist?email=${email}`);
}

export async function changePassword(
	reqMap: object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("PUT", `change-password`, undefined, reqMap);
}

export async function getResetPasswordLink(
	reqMap: object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "admin/password-reset-link", undefined, reqMap);
}

export async function getContractorAsset(
	id: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `admin/contractor-assest?userId=${id}`);
}

export async function createUser(reqMap: object): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "v2/user", undefined, reqMap);
}

export async function createContractor(
	reqMap: object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "v2/user", undefined, reqMap);
}

export async function rejectAdmin(reqMap: object): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "reject-admin", undefined, reqMap);
}

export async function validateToken(reqMap: object): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "validate-token", undefined, reqMap);
}

export async function assignAssetsToUser(data: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "assets-to-admin", undefined, data);
}

export async function uploadProfileImage(
	imageDetails: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"profile-upload",
		undefined,
		undefined,
		imageDetails
	);
}

export async function generateS3Url(reqMap: object): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "generate-s3-url", undefined, reqMap);
}

export async function confirmManageCustomer(
	confirm: boolean
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "confirm-managed-user", undefined, {
		isManagedAccount: confirm,
	});
}
