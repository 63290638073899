import { SvgIcon } from "@material-ui/core";
import { memo } from "react";

const Pm10 = (props) => {
	return (
		<SvgIcon
			width="48"
			height="36"
			viewBox="0 0 48 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="19.9215" cy="19.4483" r="7.52842" fill={props.color} />
			<circle cx="43.1343" cy="16.3115" r="4.39158" fill={props.color} />
			<circle cx="34.9791" cy="25.7222" r="3.76421" fill={props.color} />
			<circle cx="33.0969" cy="15.0573" r="2.50947" fill={props.color} />
			<circle cx="11.139" cy="30.7411" r="1.25474" fill={props.color} />
			<circle cx="4.55153" cy="20.3894" r="4.07789" fill={props.color} />
			<circle cx="11.1394" cy="8.15596" r="2.50947" fill={props.color} />
			<circle cx="23.6862" cy="33.2507" r="2.50947" fill={props.color} />
			<circle cx="23.6859" cy="5.01895" r="5.01895" fill={props.color} />
		</SvgIcon>
	);
};

export default memo(Pm10);
