import { URL } from "../../components/Navbar/routes";
import { IMenuItem } from "../../common/Interface";

import {
	UserRoles,
	CheckWriteAccess,
	CheckPremiumUser,
} from "../../enums/UserRoles";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import DehazeOutlinedIcon from "@material-ui/icons/DehazeOutlined";

const BottomMenuValues: any = [
	{
		title: "Home",
		icon: <HomeOutlinedIcon />,
		path: URL.DEVICE_MANAGEMENT,
		roles: [
			UserRoles.CONTRACTOR,
			UserRoles.SUPER_ADMIN,
			UserRoles.ADMIN,
			UserRoles.USER,
		],
		roleconditions: [CheckWriteAccess, {}, {}],
	},
	{
		title: "Dashboard",
		icon: <DashboardOutlinedIcon />,
		path: URL.DASHBOARD,
		roles: [
			UserRoles.CONTRACTOR,
			UserRoles.SUPER_ADMIN,
			UserRoles.ADMIN,
			UserRoles.USER,
		],
		roleconditions: [{}, {}, {}],
	},
	{
		title: "IAQ Store",
		icon: <StoreOutlinedIcon />,
		description: "Devices mapping overview",
		path: URL.IAQSUBMENU,
		roles: [
			UserRoles.CONTRACTOR,
			UserRoles.SUPER_ADMIN,
			UserRoles.ADMIN,
			UserRoles.USER,
		],
		roleconditions: [{}, {}, {}],
	},
	{
		title: "More",
		icon: <DehazeOutlinedIcon />,
		path: URL.MORENAVBAR,
		roles: [
			UserRoles.CONTRACTOR,
			UserRoles.SUPER_ADMIN,
			UserRoles.ADMIN,
			UserRoles.USER,
		],
	},
];

export { BottomMenuValues };
