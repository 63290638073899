import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { createTheme, IconButton, MuiThemeProvider } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SearchIcon from "@material-ui/icons/Search";
import MUIDataTable, {
	MUIDataTableMeta,
	MUIDataTableOptions,
} from "mui-datatables";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";

import { deleteIAQReportSchedule } from "../../api/ReportsController";
import { IAQReportSchedule } from "../../types/IAQReportSchedule";
import { toast } from "react-toastify";
import { SpinnerLoader } from "../../common/Global.Style";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

import "./IAQScheduleReport.scoped.scss";
interface ViewIAQReportProps {
	show: boolean;
	onHide: () => void;
	reports: IAQReportSchedule[];
	afterDelete: () => void;
	onEdit: (index: number) => void;
}

const IAQScheduleReports: React.FC<ViewIAQReportProps> = (
	props: ViewIAQReportProps
) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [deleteScheduleData, setDeleteScheduleData] = useState<string>("");
	const [isShowConfirmDelete, setIsShowConfirmDelete] =
		useState<boolean>(false);
	const [scheduleList, setScheduleList] = useState<any>([]);

	const { reports } = props;

	useEffect(() => {
		setScheduleList(cloneDeep(reports));
	}, [reports]);

	const scheduleFrequencyRow = (
		value: IAQReportSchedule,
		tableMeta: MUIDataTableMeta
	) => {
		return (
			<div className="d-flex">
				<ul>
					{scheduleList[tableMeta.rowIndex].scheduleFrequently.map(
						(frequent: string) => {
							return <li className="text-capitalize">{frequent}</li>;
						}
					)}
				</ul>
			</div>
		);
	};

	const emailsRow = (value: IAQReportSchedule, tableMeta: MUIDataTableMeta) => {
		return (
			<div className="d-flex">
				<ul>
					{scheduleList[tableMeta.rowIndex].emails.map((email: string) => {
						return <li>{email}</li>;
					})}
				</ul>
			</div>
		);
	};

	const actionRow = (value: string, tableMeta: MUIDataTableMeta) => {
		return (
			<div className="d-flex">
				<IconButton
					aria-label="edit-schedule"
					className="mr-1"
					onClick={() => props.onEdit(tableMeta.rowIndex)}
				>
					<EditIcon />
				</IconButton>
				<IconButton
					aria-label="delete-schedule"
					onClick={() => {
						setIsShowConfirmDelete(true);
						setDeleteScheduleData(value);
					}}
				>
					<DeleteIcon />
				</IconButton>
			</div>
		);
	};

	const options: MUIDataTableOptions = {
		responsive: "standard",
		filterType: "dropdown",
		filter: false,
		download: false,
		print: false,
		selectableRows: "none",
	};

	const columns = [
		{
			name: "name",
			label: "Schedule Name",
		},
		{
			name: "scheduleFrequently",
			label: "Frequency",
			options: {
				empty: false,
				sort: false,
				customBodyRender: scheduleFrequencyRow,
			},
		},
		{
			name: "emails",
			label: "Email Id(s)",
			options: {
				empty: false,
				sort: false,
				customBodyRender: emailsRow,
			},
		},
		{
			name: "subject",
			label: "Email Subject Line",
		},
		{
			name: "_id",
			Label: "Actions",
			options: {
				empty: true,
				sort: false,
				customBodyRender: actionRow,
			},
		},
	];

	const deleteSchedule = () => {
		if (!deleteScheduleData) {
			return;
		}

		setIsLoading(true);
		deleteIAQReportSchedule(deleteScheduleData)
			.then(async () => {
				toast.success("Schedule deleted successfully");
				await props.afterDelete();
				setDeleteScheduleData("");
				setIsLoading(false);
				setIsShowConfirmDelete(false);
			})
			.catch((err: any) => {
				setIsLoading(false);
				setDeleteScheduleData("");
				setIsShowConfirmDelete(false);
				toast.error(err?.message || "Try again later");
			});
	};

	const getMuiTheme = () =>
		createTheme({
			overrides: {
				MUIDataTableBodyCell: {
					root: {
						"max-width": "200px",
						"min-width": "100px",
						"word-break": "break-all",
						"text-align": "start",
					},
				},
			},
		});

	const searchSchedule = (e: any) => {
		let { value } = e.target;
		value = value.toLowerCase();
		setScheduleList(
			reports.filter(
				(filterData: any) =>
					filterData?.name?.toLowerCase()?.includes(value) ||
					filterData?.subject?.toLowerCase()?.includes(value)
			)
		);
	};

	return (
		<Modal
			show={props.show}
			size="lg"
			onHide={props.onHide}
			dialogClassName={"schedule-list-modal"}
			centered
		>
			<Modal.Header closeButton className="align-items-center">
				<Modal.Title>
					<h5 className="mt-3">
						<span onClick={props.onHide} className="back-button">
							<ChevronLeftIcon />
						</span>
						Schedule Reports
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container-fluid table-block">
					<div className="row mt-2 mb-2">
						<div className="col=lg-12 col-md-12 col-sm-12  bg-white p1">
							<MuiThemeProvider theme={getMuiTheme()}>
								<MUIDataTable
									title={""}
									data={scheduleList}
									columns={columns}
									options={options}
								/>
							</MuiThemeProvider>
						</div>
					</div>
				</div>
				<div className="responsive-card-block">
					<div className="responsive-search">
						<input
							onChange={debounce(searchSchedule, 1000)}
							type="text"
							placeholder="Search by Name or Subject Line"
						/>
						<SearchIcon />
					</div>
					{scheduleList?.map((mapData: any, index: any) => (
						<div key={mapData?._id} className="schedule-card">
							<div className="d-flex align-items-center justify-content-between header-block">
								<h4>{mapData?.name}</h4>
								<Dropdown className="more-dropdown">
									<Dropdown.Toggle
										id="dropdown-basic"
										bsPrefix="my-dropdown-toggle p-0"
									>
										<MoreHorizIcon className="i-20 font-tertiary cursor-pointer" />
									</Dropdown.Toggle>
									<Dropdown.Menu
										className="card-action"
										style={{ width: "inherit" }}
									>
										<Dropdown.Item
											onClick={() => {
												props.onEdit(index);
											}}
										>
											Edit
										</Dropdown.Item>
										<Dropdown.Item
											onClick={() => {
												setIsShowConfirmDelete(true);
												setDeleteScheduleData(mapData?._id);
											}}
										>
											Delete
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="card-inner-block">
								<div className="text-block">
									<p className="heading-props">Frequency</p>
									<p className="values-prop">
										{mapData?.scheduleFrequently
											.map((type: string) => type)
											.join(", ")}
									</p>
								</div>
								<div className="text-block">
									<p className="heading-props">Email Id(s)</p>
									<p className="values-prop">
										{mapData?.emails?.map((email: string) => email).join(", ")}
									</p>
								</div>

								<div>
									<p className="heading-props">Email Subject Line</p>
									<p className="values-prop">{mapData?.subject}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</Modal.Body>
			{isLoading && <SpinnerLoader />}
			{isShowConfirmDelete && (
				<ConfirmDialog
					dialogTitle="Delete Schedule "
					message="Are you sure you wish to delete this schedule?"
					positiveBtnLabel="Delete"
					negativeBtnLabel="Cancel"
					onPositiveBtnClicked={() => deleteSchedule()}
					onNegativeBtnClicked={() => setIsShowConfirmDelete(true)}
					onCloseIconClicked={() => setIsShowConfirmDelete(false)}
				/>
			)}
		</Modal>
	);
};

export default IAQScheduleReports;
