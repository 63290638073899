import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { AnySrvRecord } from "dns";

import { BuildingsDetailsModel } from "../../modals/device/Buildings";
import { addBuilding, updateBuilding } from "../../api/buildingsController";
import { SpinnerLoader } from "../../common/Global.Style";
import { RootState } from "../../redux/reducers";
import { getAssetBuildings } from "../../api/AssetController";
import { getTimeZoneByLatLng } from "../../api/buildingsController";
import { setBuildings, setBuilding } from "../../redux/actions/buildingActions";
import "./NewBuildign.scoped.scss";
import AddressAutoComplete from "../../components/AddressAutoComplete/AddressAutoComplete";
interface Item {
	label: string;
	value: any;
	path?: string;
}

const NewBuilding: React.FC<any> = (props: any) => {
	const { onHide, show, refreshBuildingData, formData, setCreatedBuilding } =
		props;
	const [selectedTimeZone, setSelectedTimeZone] = useState<any>({});

	const timezoneOptions: Item[] = [
		{
			label: "EST/EDT",
			value: "EST5EDT",
		},
		{ label: "CST/CDT", value: "CST6CDT" },
		{ label: "MST/MDT", value: "MST7MDT" },
		{ label: "PST/PDT", value: "PST8PDT" },
		{ label: "Alaska", value: "US/Alaska" },
		{ label: "Hawaii-Aleutian", value: "US/Hawaii" },
	];

	const dispatch = useDispatch();

	const building = useSelector((state: RootState) => state.building);
	const asset = useSelector((state: RootState) => state.asset);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const customStyles = {
		control: (provided: Record<string, unknown>, selectState: any) => ({
			...provided,
			border: "none",
			boxShadow: selectState.isFocused
				? "0 0 0 0.2rem rgb(0 123 255 / 25%)"
				: "none",
			borderRadius: "10px",
			background: "#f7f8fb",
		}),
	};

	useEffect(() => {
		if (formData.timeZoneName) {
			const timeZone = timezoneOptions.find(
				(timeZone: Item) => timeZone.value === formData?.timeZoneName
			);
			if (timeZone) {
				setSelectedTimeZone(timeZone);
			}
		}
	}, [formData]);

	const saveDetails = (values: any) => {
		setIsLoading(true);
		let buildingDetails: BuildingsDetailsModel = new BuildingsDetailsModel();
		buildingDetails.address = values.address;
		buildingDetails.description = values.description;
		buildingDetails.name = values.name;
		buildingDetails.zipcode = values.zipcode;
		buildingDetails.city = values.city;
		buildingDetails.state = values.state;
		buildingDetails.timeZoneName = values?.timeZoneName;

		if (formData?._id) {
			updateBuilding(formData._id, buildingDetails)
				.then((response: any) => {
					if (response.responseObj) {
						setIsLoading(false);
						onHide();
						dispatch(setBuilding(get(response, `responseObj.building`, {})));
						refreshBuildingData("update-building");
					} else {
						toast.error(response.error);
					}
				})
				.catch((error: AnySrvRecord) => {
					setIsLoading(false);
				});
		} else {
			addBuilding({ ...buildingDetails, asset: asset._id })
				.then((response: any) => {
					if (response.responseObj) {
						setIsLoading(false);
						onHide();
						toast.success(response.responseObj.message);
						refreshBuildingData("add-building", response?.responseObj?.result);
						getBuildingList();
						setCreatedBuilding &&
							setCreatedBuilding(response.responseObj.result);
					} else {
						toast.error(response.error);
					}
				})
				.catch((error: AnySrvRecord) => {
					setIsLoading(false);
				});
		}
	};
	const getBuildingList = async () => {
		const response = await getAssetBuildings(asset._id);
		if (response.responseObj) {
			dispatch(setBuildings(get(response, "responseObj.buildings", [])));
			if (!building._id) {
				dispatch(setBuilding(get(response, `responseObj.buildings[0]`, {})));
			}
		}
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().trim().required("Name is required"),
		// address: Yup.string().required("Address or Hours required"),
		// description: Yup.string().required("Description is required"),
		// city: Yup.string().required("City is required"),
		// zipcode: Yup.string().required("Zipcode is required"),
		// state: Yup.string().required("State is required"),
		timeZoneName: Yup.string().required("Timezone is required"),
	});
	const formik = useFormik({
		initialValues: {
			name: formData?.name || "",
			address: formData.address || "",
			autopilot: formData?.autopilot || false,
			description: formData?.description || "",
			zipcode: formData?.zipcode || "",
			city: formData?.city || "",
			state: formData?.state || "",
			timeZoneName: formData?.timeZoneName || "",
		},
		validationSchema: validationSchema,
		onSubmit: (values: any) => {
			saveDetails(values);
		},
	});
	const setFormikAddress = (address: any) => {
		formik.setFieldValue("address", address);
	};
	const getTimeZone = async (latlng: any) => {
		if (latlng.length) {
			const response = await getTimeZoneByLatLng({
				latLong: latlng[0].location?.lat + "," + latlng[0]?.location?.lng,
			});
			if (response.responseObj && response.responseObj?.timeZoneName) {
				const timeZone = timezoneOptions.filter(
					(time) => time.value == response.responseObj?.timeZoneName
				);
				setSelectedTimeZone(timeZone);
				formik.setFieldValue(
					"timeZoneName",
					response.responseObj?.timeZoneName
				);
			}
		}
	};
	return (
		<Modal
			show={show}
			onHide={() => onHide(false)}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			dialogClassName="modal-custom"
			centered
			onClick={(event: any) => event.stopPropagation()}
			onMouseDown={(event: any) => event.stopPropagation()}
			onChange={(event: any) => event.stopPropagation()}
			onFocus={(event: any) => event.stopPropagation()}
			disableEnforceFocus
		>
			<Modal.Header closeButton className="align-items-center">
				<Modal.Title>
					<h5 className="mt-3">
						{formData?._id ? "Update Building" : "Add New Building"}
					</h5>
				</Modal.Title>
			</Modal.Header>

			<div className="row w-100 m-m-auto m-px-3">
				<div className="col-lg-12 col-md-12 col-m-sm-12 loca pb-5 m-pb-4 pl-5 m-px-2 pt-2">
					<div className="container-fluid">
						<FormikProvider value={formik}>
							<form onSubmit={formik.handleSubmit}>
								<div className="row mt-4">
									<div className="col-md-6 col-lg-6 col-sm-12 col-m-sm-12">
										<div className="w-100 form-group">
											<div className="d-flex pb-0">
												<h6>Building Name</h6>
											</div>
											<input
												id="name"
												name="name"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.name}
												onChange={formik.handleChange}
												maxLength={100}
											/>
											{formik.touched.name && formik.errors.name ? (
												<div className="text-danger">{formik.errors.name}</div>
											) : null}
										</div>

										{!isEmpty(formData) && (
											<div className="w-100 form-group">
												<div className="d-flex pb-0">
													<h6>Description</h6>
												</div>
												<input
													id="description"
													name="description"
													type="text"
													className="add-re-input form-control pt-2 pb-2"
													value={formik.values.description}
													onChange={formik.handleChange}
												/>
												{formik.touched.description &&
												formik.errors.description ? (
													<div className="text-danger">
														{formik.errors.description}
													</div>
												) : null}
											</div>
										)}

										<div className="w-100 form-group">
											<div className="d-flex pb-0">
												<h6>Address</h6>
											</div>
											<AddressAutoComplete
												setFormikAddress={setFormikAddress}
												formikFormData={formData.address}
												getTimeZone={getTimeZone}
											/>
											{formik.touched.address && formik.errors.address ? (
												<div className="text-danger">
													{formik.errors.address}
												</div>
											) : null}
										</div>

										<div className="w-100 form-group">
											<div className="d-flex pb-0">
												<h6>TimeZone</h6>
											</div>
											<Select
												isClearable={true}
												isSearchable={false}
												placeholder="Select timezone"
												onChange={(tz: any) => {
													formik.setFieldValue(
														"timeZoneName",
														get(tz, "value", "")
													);
													setSelectedTimeZone(tz);
												}}
												name="timeZone"
												id="timeZone"
												styles={customStyles}
												value={selectedTimeZone}
												options={timezoneOptions}
											/>
											{formik.touched.timeZoneName &&
											formik.errors.timeZoneName ? (
												<div className="text-danger">
													{formik.errors.timeZoneName}
												</div>
											) : null}
										</div>
										<div className="d-flex mt-4 justify-content-end">
											<button type="submit" className="btn add px-4">
												{formData?._id ? "Update" : "Add"}
											</button>
										</div>
									</div>
								</div>
							</form>
						</FormikProvider>
					</div>
				</div>
				{isLoading && <SpinnerLoader />}
			</div>
		</Modal>
	);
};
export default NewBuilding;
