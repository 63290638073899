import React, { useEffect, useState } from "react";
import "../style.scoped.scss";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { NavLink } from "react-router-dom";
import { URL } from "../../../../components/Navbar/routes";
interface AllProductsProps {
	products: any;
	addToFavourite: any;
}

const AllProducts: React.FC<AllProductsProps> = ({
	products,
	addToFavourite,
}) => {
	return (
		<div className="p-1 radius-6 product-list d-flex flex-1 flex-column">
			<NavLink
				className="product-link"
				to={`${URL.IAQVIEWPRODUCT}/${products._id}`}
			>
				<div className="radius-t-l-6 radius-t-r-6 product-img product-img-fill d-flex align-items-end justify-content-center flex-1 w-100">
					<img src={products?.images[0]} alt="machine" />
				</div>
			</NavLink>
			<div className="px-1 pb-1 pt-2 d-flex w-100">
				<NavLink
					className="flex-1 product-link"
					to={`${URL.IAQVIEWPRODUCT}/${products._id}`}
				>
					<div className="flex-1 text-truncate pr-2">
						<p className="text-wrap text-break mb-1 desc-xsmall font-grey-light-1 ls-024">
							{products.name}
						</p>
						{/* <p className="text-wrap text-break mb-0 desc-normal font-700 ls-024 font-text-4">
							${products?.price ? products?.price : "0"}
						</p> */}
					</div>
				</NavLink>
				<div
					className={
						products?.favorite && products?.favorite[0]?.isFavourite
							? "like-icon active"
							: "like-icon"
					}
				>
					<FavoriteIcon onClick={() => addToFavourite(products._id)} />
				</div>
			</div>
		</div>
	);
};
export default AllProducts;
