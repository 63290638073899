import { FC, memo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import { validationErrorMessage } from "../../utils/errMessages";
import { assignAssetsToUser } from "../../api/UserController";
import { toast } from "react-toastify";

class UserModal {
	public email: string;
	public assets: any[];

	public constructor() {
		this.email = "";
		this.assets = [];
	}
}

const colorStyles = {
	option: (styles: any) => {
		return {
			paddingTop: "8px !important",
			paddingBottom: "8px !important",
			...styles,
		};
	},
};

const Option = (props: any) => {
	return (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					className="select-input mr-2"
					checked={props.isSelected}
					onChange={() => null}
				/>
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

const AssignUserModal: FC<{
	show: boolean;
	options: any[];
	onHide: any;
}> = ({ show, options, onHide }) => {
	const [inputError, setInputError] = useState<string>("");
	const [assetError, setAssetError] = useState<string>("");
	let regex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+@[a-z]+.[a-z]{2,3}");
	const [formData, setFormData] = useState<UserModal>({
		email: "",
		assets: [],
	});

	const updateFormData = (data: any, propName: string) => {
		(formData as any)[propName] = data;
		setFormData(formData);
	};

	const assignUserToAssets = async () => {
		try {
			const response = await assignAssetsToUser(formData);
			if (response?.responseObj) {
				toast.success(response?.responseObj?.message);
			} else {
				toast.error(response?.error);
			}
		} catch (error: any) {
			toast.error(error?.message);
		}
	};

	const onPositiveBtnClick = async () => {
		if (formData.email === "") {
			setInputError("Email is required");
		} else if (formData.assets.length === 0) {
			setAssetError("Asset is required");
		} else if (formData.email && formData.assets.length) {
			onHide(false);
			await assignUserToAssets();
		}
	};

	const onNegativeBtnClicked = () => {
		onHide(false);
	};

	return (
		<Modal
			show={show}
			onHide={() => onHide(false)}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton>
				<h5>Assign Multiple Accounts to User</h5>
			</Modal.Header>
			<Modal.Body>
				<div className="mb-3">
					<div className="d-flex pb-0">
						<h6>Email</h6>
					</div>
					<input
						id="userEmail"
						type="email"
						onChange={(e) => {
							if (!regex.test(e?.target?.value?.trim())) {
								setInputError(validationErrorMessage.EMAIL);
							} else {
								setInputError("");
								updateFormData(e?.target?.value?.toLowerCase(), "email");
							}
						}}
						className={`add-re-input ${
							inputError ? "input-error" : ""
						} form-control pt-2 pb-2`}
						placeholder="Enter email ID"
					/>
					{inputError && (
						<p className="text-danger desc-small my-1">{inputError}</p>
					)}
				</div>

				<div>
					<div className="d-flex pb-0">
						<h6>Accounts</h6>
					</div>
					<Select
						options={options.map((asset) => {
							return {
								label: asset?.name,
								value: asset?._id,
							};
						})}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						blurInputOnSelect={false}
						isSearchable={false}
						components={{
							Option,
						}}
						isMulti
						styles={colorStyles}
						onChange={(e: any) => {
							e = e.map((item: any) => {
								return {
									name: item.label,
									_id: item.value,
									permissions: ["read", "write"],
								};
							});
							updateFormData(e, "assets");
						}}
						placeholder="Select Accounts"
					/>

					<p className="desc-large mt-2">
						You can select multiple accounts from the list
					</p>
					{assetError && (
						<p className="text-danger desc-small my-1">{assetError}</p>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onNegativeBtnClicked}>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={onPositiveBtnClick}
					style={{ cursor: "pointer" }}
				>
					Submit
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default memo(AssignUserModal);
