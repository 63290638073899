import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { IconButton } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { Cancel } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";

import { isUserExist } from "../../api/UserController";
import { validateEmail } from "../../utils/helpers";
import { RootState } from "../../redux/reducers";
import SystemTour from "../SystemTour/SystemTour";
import { validationErrorMessage } from "../../utils/errMessages";

import "./CreateAssets.scss";
import "./AddUserForm.scss";

interface AddUserProps {
	updateStep: any;
	addUserTOAsset: (values: CreatUserFormData) => void;
	type: string;
}

export const customStyles = {
	option: (provided: Record<string, unknown>) => ({
		...provided,
		background: "#ffffff",
		":hover": {
			cursor: "pointer",
			background: "#f8f8f9",
		},
	}),
	control: (provided: Record<string, unknown>) => ({
		...provided,
		display: "flex",
		height: "40px",
		paddingLeft: "5px",
		fontSize: "14px",
		fontWeight: 500,
		lineHeight: 1.5,
		color: "#000000",
		letterSpacing: "0.2px",
		border: "1px solid #e7e7e7",
		borderRadius: "4px",
		":hover": {
			borderColor: "#d4d4d8",
		},
	}),
	indicatorSeparator: (provided: Record<string, unknown>) => ({
		...provided,
		display: "none",
	}),
};

export interface UserData {
	contactNumber: string;
	email: string;
	firstName: string;
	lastName: string;
	permissions: string[];
}

export interface CreatUserFormData {
	users: UserData[];
}

const AddUser: React.FC<AddUserProps> = ({
	updateStep,
	addUserTOAsset,
	type,
}) => {
	const user = useSelector((state: RootState) => state.user);

	const [emailIfo, setEmailInfo] = useState<any>({});
	const [emailError, setEmailError] = useState<any>({});

	const checkEmailExistOrNot = async (e: any, index: any) => {
		if (validateEmail(e.target.value)) {
			let tempInfoObj = cloneDeep(emailError);
			let tempErrorObj = cloneDeep(emailIfo);
			if (user.email === e.target.value) {
				set(
					tempInfoObj,
					`${index}-email`,
					"This email id is associated with a superuser account and already has admin previllage for this customer account, try with another email id"
				);
				set(tempErrorObj, `${index}-email`, "");
			} else {
				const response = await isUserExist(e.target.value);
				if (response.responseObj) {
					if (response.responseObj.errorCode === "user-found") {
						set(
							tempErrorObj,
							`${index}-email`,
							"This email id already exists, once added the user can access this customer account with their existing user name and password"
						);
						set(tempInfoObj, `${index}-email`, "");
					} else if (response.responseObj.errorCode === "user-not-found") {
						set(tempErrorObj, `${index}-email`, "");
					}
				}
			}
			setEmailInfo(tempInfoObj);
			setEmailError(tempErrorObj);
		}
	};

	const tourSteps = [
		{
			selector: ".dummy-tour",
			content: `You can add users in this screen.`,
		},
		{
			selector: ".add-invite-user",
			content: ({}) => (
				<div>
					Click here to add one or more users. You can add any number of admin
					and viewer accounts as needed for the customer account.
					<br /> <br />
					User Permissions:
					<ul className="tour-content-styl">
						<li>
							<b>Admin</b> who can access mobile toolbox to install devices and
							can access web console
							<a href="https://iqicloud.io" target="_blank" rel="noreferrer">
								https://iqicloud.io.
							</a>
						</li>
						<li>
							Web dashboards, Reports, Add buildings locations, Configure
							devices and Edit settings.
						</li>
					</ul>
					(Or)
					<ul className="tour-content-styl">
						<li>
							<b>Viewer </b>who can only see dashboards and reports in the web
							console
							<a href="https://iqicloud.io" target="_blank" rel="noreferrer">
								https://iqicloud.io.
							</a>
						</li>
					</ul>
				</div>
			),
		},
		{
			selector: ".invite-user-next",
			content: "Click Next to Continue",
		},
	];

	const initialValues = {
		users: [],
	};
	const validationSchema = Yup.object({
		users: Yup.array().of(
			Yup.object().shape({
				lastName: Yup.string().trim().required("Last Name is required"),
				firstName: Yup.string().trim().required("First Name is required"),
				contactNumber: Yup.string()
					.matches(/^(\d{10}(,(?=.))?)+$/, validationErrorMessage.CONTACT)
					.required("Phone Number is required"),
				permissions: Yup.array().required().min(1, "Permission is required"),
				email: Yup.string()
					.required("Email is required")
					.email(validationErrorMessage.EMAIL),
			})
		),
	});
	const onSubmit = (values: CreatUserFormData) => {
		let tempInfoObj = cloneDeep(emailIfo);
		Object.keys(tempInfoObj).forEach(
			(k) => tempInfoObj[k] == "" && delete tempInfoObj[k]
		);

		if (isEmpty(tempInfoObj)) {
			addUserTOAsset(values);
		}
	};
	const onAddUser = (values: any, setValues: any) => {
		const users = [...values.users];
		users.push({
			firstName: "",
			lastName: "",
			email: "",
			contactNumber: "",
			permissions: [],
		});
		setValues({ ...values, users });
	};
	const [anchorEl, setAnchorEl] = React.useState(null);
	const divRef = React.useRef(null);
	function handleClick() {
		setAnchorEl(divRef.current);
	}
	function handleClose() {
		setAnchorEl(null);
	}
	useEffect(() => {
		setAnchorEl(divRef.current);
	}, [divRef]);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	return (
		<div>
			{/* <SystemTour tourSteps={tourSteps} position={1} /> */}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				render={({ values, setFieldValue, handleBlur, setValues }) => (
					<Form>
						<FieldArray
							name="users"
							render={(arrayHelpers) => {
								const users = values.users;
								return (
									<div>
										<div className="drawer-content add-user">
											<div className="d-flex justify-content-between add-user-header">
												<h3 className="desc-large font-bold font-primary mb-0">
													Invite user
												</h3>
												<button
													type="button"
													onClick={() => onAddUser(values, setValues)}
													className="btn btn-small btn-primary-outline add-invite-user"
												>
													Add User
												</button>
											</div>
											<div className="add-user-content pb-5">
												{users &&
													users.length > 0 &&
													users.map((user, index) => (
														<div key={index} className="add-user-card p-3 mb-3">
															<div className="d-flex justify-content-between cursor-pointer mb-0">
																<p className="circle-icon mb-0">{index + 1}</p>
																{index !== 0 && (
																	<IconButton
																		className="delete-user-text"
																		onClick={() => {
																			arrayHelpers.remove(index);
																			let tempErrorObj = cloneDeep(emailIfo);
																			let tempInfoObj = cloneDeep(emailError);
																			set(tempErrorObj, `${index}-email`, "");
																			set(tempInfoObj, `${index}-email`, "");
																			setEmailError(tempErrorObj);
																			setEmailInfo(tempInfoObj);
																		}}
																	>
																		<Cancel />
																	</IconButton>
																)}
															</div>
															<div className="row mt-3">
																<div className="col-6 col-m-sm-12">
																	<label className="desc-normal font-500 font-tertiary">
																		First Name
																		<span className="error-message ml-1">
																			*
																		</span>
																	</label>
																	<Field
																		placeholder="First Name"
																		name={`users.${index}.firstName`}
																	/>
																	<p className="text-danger mb-0">
																		<ErrorMessage
																			name={`users.${index}.firstName`}
																		/>
																	</p>
																</div>
																<div className="col-6 col-m-sm-12">
																	<label className="desc-normal font-500 font-tertiary">
																		Last Name
																		<span className="error-message ml-1">
																			*
																		</span>
																	</label>
																	<Field
																		placeholder="Last Name"
																		name={`users.${index}.lastName`}
																	/>
																	<p className="text-danger mb-0">
																		<ErrorMessage
																			name={`users.${index}.lastName`}
																		/>
																	</p>
																</div>
															</div>
															<div className="row mt-3">
																<div className="col-6 col-m-sm-12">
																	<label className="desc-normal font-500 font-tertiary">
																		Email
																		<span className="error-message ml-1">
																			*
																		</span>
																	</label>
																	<Field
																		placeholder="Email"
																		name={`users.${index}.email`}
																		onBlur={(e: any) => {
																			handleBlur(e);
																			checkEmailExistOrNot(e, index);
																		}}
																	/>
																	{get(emailIfo, `${index}-email`, "") && (
																		<p className="text-danger mb-0">
																			{get(emailIfo, `${index}-email`, "")}
																		</p>
																	)}

																	{get(emailError, `${index}-email`, "") && (
																		<p className="text-info mb-0">
																			{get(emailError, `${index}-email`, "")}
																		</p>
																	)}
																	<p className="text-danger mb-0">
																		<ErrorMessage
																			name={`users.${index}.email`}
																		/>
																	</p>
																</div>
																<div className="col-6 col-m-sm-12" ref={divRef}>
																	<label className="desc-normal font-500 font-tertiary">
																		Permissions
																		<span className="error-message ml-1">
																			*
																		</span>
																		<HelpOutline
																			ref={divRef}
																			onClick={handleClick}
																			aria-describedby={id}
																		></HelpOutline>
																		<Popover
																			id={id}
																			open={open}
																			anchorEl={anchorEl}
																			onClose={handleClose}
																			anchorOrigin={{
																				vertical: "bottom",
																				horizontal: "center",
																			}}
																			transformOrigin={{
																				vertical: "top",
																				horizontal: "center",
																			}}
																		>
																			<Typography>
																				<h4 className="i-16 m-0">Viewer</h4>
																				<ul className="customer-details">
																					<li>
																						View access to dashboard and
																						reports.
																					</li>
																					<li>No Edit permission</li>
																					<li>Can not add / remove devices</li>
																				</ul>
																				<h4 className="i-16 m-0">Admin</h4>
																				<ul className="customer-details pb-3 pr-3">
																					<li>
																						View access to dashboard and
																						reports.
																					</li>
																					<li>
																						Edit permission to change metrics
																					</li>
																					<li>Generate and Schedule reports</li>
																					<li>
																						Access to add / remove devices
																					</li>
																					<li>
																						Can not create new customer accounts
																					</li>
																					<li>
																						No access to billing and payments
																					</li>
																				</ul>
																			</Typography>
																		</Popover>
																	</label>
																	<Select
																		id="color"
																		styles={customStyles}
																		menuPlacement="auto"
																		options={[
																			{ label: "Viewer", value: ["read"] },
																			{
																				label: "Admin",
																				value: ["read", "write"],
																			},
																		]}
																		onChange={(data: any) =>
																			setFieldValue(
																				`users.${index}.permissions`,
																				data.value
																			)
																		}
																	/>
																	<p className="text-danger mb-0">
																		<ErrorMessage
																			name={`users.${index}.permissions`}
																		/>
																	</p>
																</div>
															</div>
															<div className="row mt-3">
																<div className="col-6 col-m-sm-12">
																	<label className="desc-normal font-500 font-tertiary">
																		Phone Number
																		<span className="error-message ml-1">
																			*
																		</span>
																	</label>
																	<Field
																		placeholder="Phone Number"
																		name={`users.${index}.contactNumber`}
																	/>
																	<p className="text-danger mb-0">
																		<ErrorMessage
																			name={`users.${index}.contactNumber`}
																		/>
																	</p>
																</div>
															</div>
														</div>
													))}
											</div>
										</div>

										<div className="drawer-footer d-flex align-items-center justify-content-end">
											{type !== "edit" && (
												<button
													className="btn btn-medium btn-accent"
													onClick={() =>
														updateStep((preState: number) => preState - 1)
													}
													type="button"
												>
													Back
												</button>
											)}
											<button
												type="submit"
												className="btn btn-medium primary-btn ml-2"
											>
												Next
											</button>
										</div>
									</div>
								);
							}}
						/>
					</Form>
				)}
			/>
		</div>
	);
};

export default AddUser;
