export class RemediationDetailModal {
	public deviceType: string;
	public building: string;
	public deviceId: string;
	public description: string;
	public deviceName: string;
	public location: string[];
	public features: string[];
	public image: string;
	public areaCoverage: string;
	public replacementParts: object[];
	public category: string;
	public status?: boolean;
	public dueDate?: string;
	public dimensions: string[];
	public _id?: string;

	public constructor() {
		this.deviceType = "";
		this.building = "";
		this.deviceId = "";
		this.description = "";
		this.deviceName = "";
		this.location = [];
		this.features = [];
		this.image = "";
		this.areaCoverage = "";
		this.replacementParts = [];
		this.category = "";
		this.status = undefined;
		this._id = "";
		this.dueDate = "";
		this.dimensions = [];
	}
}
