import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import { isIpadOS } from "../../common/service";
import { RootState } from "../../redux/reducers";
import { setupWebViewJavascriptBridge } from "../../common/JsBridge";

import "./DeviceManagement.scoped.scss";

interface IDeviceSetUp {
	onRefresh: () => void;
}

export const DeviceSetUp = ({ onRefresh }: IDeviceSetUp) => {
	const asset = useSelector((state: RootState) => state.asset);

	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};

	const pairDevice = () => {
		callHandler("pairDevice", asset, () => {});
	};

	const renderPairButton = () => {
		if (
			/android/.test(navigator.userAgent.toLowerCase()) ||
			/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) ||
			isIpadOS()
		) {
			return (
				<Button
					onClick={pairDevice}
					className="btn pair-button-tour btn-medium mr-3"
				>
					Pair devices
				</Button>
			);
		}
	};

	return (
		<div className="px-3 w-100 d-none m-d-block">
			<div className="device-setup">
				<div className="d-flex justify-content-between">
					<div className="p-3">
						<h3>Device Setup</h3>
						<p className="mb-0">Pair your new device </p>
					</div>
					<div className="device-setup-icon">
						<img src={"/images/device-setup.png"} alt="" />
					</div>
				</div>
				<div className="button-section d-flex">
					{renderPairButton()}
					<Button
						onClick={onRefresh}
						className="btn-primary-outline btn-medium refresh-button-tour"
					>
						Refresh
					</Button>
				</div>
			</div>
		</div>
	);
};
