import { memo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import pull from "lodash/pull";

import DeviceManagementTable from "../DeviceManagementTable/DeviceManagementTable";
import { MonitorDetailModal } from "../../modals/device/MonitorDetail";
import { GatewayDetailModal } from "../../modals/device/GatewayDetail";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import {
	deleteDeviceData,
	getDeviceControlls,
	changeDeviceControlls,
	updateDevice,
} from "../../api/DevicesController";
import EditMonitorModal from "../AddEditMonitor/AddEditMonitor";
import EditGateway from "../AddEditGateway/AddEditGateway";
import { RemediationDetailModal } from "../../modals/device/RemediationDetail";
import AddEditRemediation from "../AddEditRemediation/AddEditRemediation";
import { DeviceControllerDrawer } from "../DeviceList/DeviceControllerDrawer";
import { SpinnerLoader } from "../../common/Global.Style";
import Device from "../../pages/DeviceManagement/Device";

export const AllDevices = (props: any) => {
	const { deviceList, refreshBuildingData, getBuildingDetailsApi, locationId } =
		props;

	const [monitorDetail, setMonitorDetail] = useState<MonitorDetailModal>(
		new MonitorDetailModal()
	);
	const [showEditMonitor, setShowEditMonitor] = useState<boolean>(false);
	const [showEditGateway, setShowEditGateway] = useState<boolean>(false);
	const [showEditRemediation, setShowEditRemediation] =
		useState<boolean>(false);
	const [selectedDeviceData, setSelectedDeviceData] = useState<any>({});
	const [isShownConfirmDialog, setIsShownConfirmDialog] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [gatewayDetail, setGatewayDetail] = useState<GatewayDetailModal>(
		new GatewayDetailModal()
	);
	const [controlDrawerOpen, setControlDrawerOpen] = useState<any>(false);
	const [selectedDeviceControlls, setSelectedDeviceControlls] = useState<any>(
		{}
	);
	const [remediationDetail, setRemediationDetail] =
		useState<RemediationDetailModal>(new RemediationDetailModal());
	const [drawerLoading, setDrawerLoading] = useState<boolean>(false);
	const [shownRemediationConfirmDialog, setIsShownRemediationConfirmDialog] =
		useState<boolean>(false);
	const [confirmationMessage, setConfirmationMessage] = useState<string>(
		"Do you want to remove the device from this location?"
	);
	const [positiveButtonLabel, setPositiveButtonLabel] =
		useState<string>("Remove Device");
	const [negativeButtonLabel, setNegativeButtonLabel] = useState<string>("");

	const onEdit = (deviceData: any) => {
		const tempDeviceData = cloneDeep(deviceData);
		delete tempDeviceData.__v;
		delete tempDeviceData.status;
		delete tempDeviceData.userId;
		delete tempDeviceData.createdAt;
		delete tempDeviceData.updatedAt;
		switch (deviceData.deviceType) {
			case "monitor":
				onEditMonitor(tempDeviceData);
				break;
			case "gateway":
				onEditGateway(tempDeviceData);
				break;
			case "remediation":
			case "interops":
				onEditRemediation(tempDeviceData);
				break;
		}
	};

	const onEditMonitor = (monitorData: any) => {
		const monitorDetails = cloneDeep(monitorData);
		delete monitorDetails.dimensions;
		delete monitorDetails.replacementParts;
		setMonitorDetail(monitorDetails);
		setShowEditMonitor(true);
	};

	const onEditGateway = (gatewayDetails: any) => {
		const gatewayDetailTemp: any = cloneDeep(gatewayDetails);

		delete gatewayDetailTemp.dimensions;
		delete gatewayDetailTemp.replacementParts;
		delete gatewayDetailTemp.features;
		setGatewayDetail(gatewayDetailTemp);
		setShowEditGateway(true);
	};

	const onEditRemediation = (deviceData: any) => {
		const remediationDetailTemp = cloneDeep(deviceData);
		if (remediationDetailTemp) {
			setRemediationDetail(remediationDetailTemp);
			setShowEditRemediation(true);
		}
	};

	const closeEditMonitor = (isCallFromSave: boolean) => {
		if (isCallFromSave) {
			refreshBuildingData();
		}
		setShowEditMonitor(false);
	};

	const closeEditGateway = (isCallFromSave: boolean) => {
		if (isCallFromSave) {
			refreshBuildingData();
		}
		setShowEditGateway(false);
	};

	const onHideRemediationDetail = (isCallFromSave: boolean) => {
		if (isCallFromSave) {
			refreshBuildingData();
		}
		setShowEditRemediation(false);
	};

	const onDelete = (deviceData: any) => {
		switch (deviceData.deviceType) {
			case "monitor":
			case "gateway":
				onDeleteMonitor(deviceData);
				break;
			case "remediation":
			case "interops":
				onDeleteRemediation(deviceData);
				break;
		}
	};

	const onDeleteRemediation = (deviceData: any) => {
		const selectedDevice = cloneDeep(deviceData);
		setRemediationDetail(selectedDevice);
		if (
			selectedDevice &&
			selectedDevice?.deviceType === "interops" &&
			selectedDevice?.location.length > 1
		) {
			setConfirmationMessage(
				"Do you want to remove the device from this location or from all the locations?"
			);
			setPositiveButtonLabel("From All Locations");
			setNegativeButtonLabel("From This Location");
		}
		setSelectedDeviceData(selectedDevice);
		setIsShownRemediationConfirmDialog(true);
	};

	const onDeleteMonitor = (deviceData: any) => {
		setSelectedDeviceData(deviceData);
		setIsShownConfirmDialog(true);
	};

	const onCloseDeleteMonitor = () => {
		setIsShownConfirmDialog(false);
	};

	const deleteMonitorDevice = () => {
		setIsLoading(true);
		deleteDeviceData(selectedDeviceData?._id)
			.then((response: any) => {
				setIsLoading(false);
				if (response && response.responseObj.message) {
					refreshBuildingData();
				}
			})
			.catch(() => {
				setIsLoading(false);
			});
		onCloseDeleteMonitor();
	};

	const getDeviceController = async (data: any) => {
		setIsLoading(true);
		const response = await getDeviceControlls(data.deviceId);
		setIsLoading(false);
		if (response?.responseObj) {
			setSelectedDeviceControlls(response?.responseObj);
			setControlDrawerOpen(true);
			setSelectedDeviceData(data);
		}
	};

	const changeDeviceControll = async (data: any) => {
		setDrawerLoading(true);
		const response = await changeDeviceControlls(
			selectedDeviceData.deviceId,
			data
		);
		if (response?.responseObj) {
			setSelectedDeviceControlls(response?.responseObj);
			getBuildingDetailsApi();
		}
		setDrawerLoading(false);
	};

	const deleteDevice = () => {
		setIsLoading(true);
		deleteDeviceData(selectedDeviceData?._id)
			.then((response: any) => {
				setIsLoading(false);
				if (response && response.responseObj.message) {
					refreshBuildingData();
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
		dialogCloseClick();
	};

	const dialogCloseClick = () => {
		setIsShownRemediationConfirmDialog(false);
	};

	const removeDeviceFromThisLocation = async () => {
		setIsLoading(true);
		remediationDetail.location = pull(remediationDetail.location, locationId);
		try {
			const response = await updateDevice(remediationDetail);
			setIsLoading(false);
			if (response && response.responseObj.message) {
				refreshBuildingData();
			}
		} catch (error) {
			setIsLoading(false);
		}
		dialogCloseClick();
	};

	return (
		<div>
			{isLoading && <SpinnerLoader />}
			{isShownConfirmDialog && (
				<ConfirmDialog
					dialogTitle="Delete"
					message="Do you want to remove the device from this location?"
					positiveBtnLabel="Remove Device"
					negativeBtnLabel=""
					onPositiveBtnClicked={deleteMonitorDevice}
					onNegativeBtnClicked={onCloseDeleteMonitor}
					onCloseIconClicked={onCloseDeleteMonitor}
				/>
			)}
			{shownRemediationConfirmDialog && (
				<ConfirmDialog
					dialogTitle="Delete"
					message={confirmationMessage}
					positiveBtnLabel={positiveButtonLabel}
					negativeBtnLabel={negativeButtonLabel}
					onPositiveBtnClicked={deleteDevice}
					onNegativeBtnClicked={removeDeviceFromThisLocation}
					onCloseIconClicked={dialogCloseClick}
				/>
			)}
			{showEditGateway && (
				<EditGateway
					show={showEditGateway}
					onHide={closeEditGateway}
					gatewayDetailsModal={gatewayDetail}
				/>
			)}
			{showEditMonitor && (
				<EditMonitorModal
					show={showEditMonitor}
					onHide={closeEditMonitor}
					monitorDetails={monitorDetail}
				/>
			)}
			{controlDrawerOpen && (
				<DeviceControllerDrawer
					open={controlDrawerOpen}
					onClose={() => setControlDrawerOpen(false)}
					selectedDeviceControlls={selectedDeviceControlls}
					selectedDevice={selectedDeviceData}
					changeDeviceControll={changeDeviceControll}
					drawerLoading={drawerLoading}
				/>
			)}
			{showEditRemediation && (
				<AddEditRemediation
					show={showEditRemediation}
					onHide={onHideRemediationDetail}
					remediationDetailsModal={remediationDetail}
				/>
			)}
			<div className="device-responsive d-none m-d-block">
				{deviceList.map((mapData: any) => (
					<Device
						onEdit={onEdit}
						onDelete={onDelete}
						deviceData={mapData}
						openControll={getDeviceController}
					/>
				))}
				{deviceList?.length === 0 && (
					<div className="table">
						<p className="mb-3 ml-2 mt-4">No device found</p>
					</div>
				)}
			</div>
			<div className="device-management-table">
				<DeviceManagementTable
					onEdit={onEdit}
					onDelete={onDelete}
					tableData={deviceList}
					openControll={getDeviceController}
				/>
			</div>
		</div>
	);
};

export default memo(AllDevices);
