/* eslint-disable prettier/prettier */
import React, { useState, useMemo, useEffect } from "react";
import {
	Box,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	ListSubheader,
	TextField,
	InputAdornment,
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";

const SearchableSelect: React.FC<any> = (props: any) => {
	const { searchableOptions, onChangeBuilding, selectedBuilding } = props;
	console.log(selectedBuilding);

	const [data, setData] = useState([]);
	const [selectedOption, setSelectedOption] = useState([]);

	const selectValue = (value: any) => {
		if (value) {
			let selectedData = data?.filter((opt: any) => {
				if (opt.value == value) {
					return opt;
				}
			});
			console.log(selectedData);
			onChangeBuilding(selectedData[0]);
			setSelectedOption(selectedData[0]["label"]);
		}
	};
	const search = (text: any) => {
		if (text) {
			let searchValue = data?.filter((opt: any) => {
				if (opt.label.toLowerCase().indexOf(text.toLowerCase()) > -1) {
					return opt;
				}
			});
			setData(searchValue);
		} else {
			setData(searchableOptions);
		}
	};
	useEffect(() => {
		//	if (data.length < 0) {
		setData(searchableOptions);
		const value = searchableOptions.map((ele: any) => {
			return ele.lable;
		});
		selectedBuilding
			? setSelectedOption(selectedBuilding.label)
			: setSelectedOption(value);
		//		}
	}, [searchableOptions]);
	useEffect(() => {
		if (selectedBuilding) {
			setSelectedOption(selectedBuilding.label);
		}
	}, [selectedBuilding]);
	return (
		<Box sx={{ m: 10 }}>
			<FormControl fullWidth variant="outlined">
				{/* <InputLabel id="search-select-label">Options</InputLabel> */}
				<Select
					MenuProps={{ autoFocus: false }}
					labelId="search-select-label"
					id="search-select-label"
					value={selectedOption}
					// label="Options"
					onChange={(e) => selectValue(e.target.value)}
					onClose={() => search("")}
					renderValue={() => selectedOption}
				>
					<ListSubheader>
						<TextField
							className="bg-white"
							size="small"
							autoFocus
							placeholder="Type to search..."
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchOutlined />
									</InputAdornment>
								),
							}}
							onChange={(e) => search(e.target.value)}
							onKeyDown={(e) => {
								if (e.key !== "Escape") {
									// Prevents autoselecting item while typing (default Select behaviour)
									e.stopPropagation();
								}
							}}
						/>
					</ListSubheader>
					{data &&
						data.length > 0 &&
						data.map((option: any, i: any) => (
							<MenuItem key={i} value={option.value}>
								{option.label}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</Box>
	);
};
export default SearchableSelect;
