import { SET_DEVICES, SET_SELECTED_DEVICE } from "../actions/actionTypes";

export const devicesReducer = (
	state = { deviceList: [], selectedDevice: {} },
	action: any
) => {
	switch (action.type) {
		case SET_DEVICES:
			return { ...state, deviceList: action.payload };
		case SET_SELECTED_DEVICE:
			return { ...state, selectedDevice: action.payload };
		default:
			return state;
	}
};
