import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { isEmpty } from "lodash";

interface ConfirmDialogProps {
	dialogTitle: string;
	message: string;
	positiveBtnLabel: string;
	negativeBtnLabel: string;
	onPositiveBtnClicked: () => void;
	onNegativeBtnClicked: () => void;
	onCloseIconClicked: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
	dialogTitle,
	message,
	positiveBtnLabel,
	negativeBtnLabel,
	onPositiveBtnClicked,
	onNegativeBtnClicked,
	onCloseIconClicked,
}) => {
	const [show, setShow] = useState(true);

	const onNegativeBtn = () => {
		setShow(false);
		onNegativeBtnClicked();
	};

	const onPositiveBtn = () => {
		setShow(false);
		onPositiveBtnClicked();
	};

	const hideModal = () => {
		setShow(false);
		onCloseIconClicked();
	};

	return (
		<>
			<Modal
				show={show}
				onHide={hideModal}
				backdrop="static"
				keyboard={false}
				className="delete-popup"
			>
				<Modal.Header closeButton>
					<Modal.Title>{dialogTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{message}</Modal.Body>
				<Modal.Footer>
					{!isEmpty(negativeBtnLabel) && (
						<Button variant="secondary" onClick={onNegativeBtn}>
							{negativeBtnLabel}
						</Button>
					)}
					<Button
						variant="primary"
						onClick={onPositiveBtn}
						style={{ cursor: "pointer" }}
					>
						{positiveBtnLabel}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ConfirmDialog;
