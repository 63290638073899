import React from "react";
import "./DeviceUtilizationReports.scoped.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import DatePicker from "../../../components/DatePicker/DatePicker";

const DeviceUtilizationReports: React.FC = () => {
	const buildingList: Array<any> = useSelector(
		(state: RootState) => state.buildings
	);
	// const building
	const arr = buildingList.map((item: any) => {
		return { value: item._id, label: item.name };
	});
	const updateDate = (value: Date | null, propertyName: string) => {};
	const schedule: any = [
		{
			label: "Daily",
			value: "daily",
		},
		{
			label: "Weekly",
			value: "weekly",
		},
		{
			label: "Monthly",
			value: "monthly",
		},
	];
	return (
		<div className="container-fluid">
			<div className="row mt-3 mb-3">
				<div className="col-lg-12 col-md-12 col-sm-12 bg-white util-re p-3">
					<h5>Device Utilization Report</h5>
					<div className="d-flex mt-4">
						<div className="w-50 mr-2">
							<h6>Select Building</h6>
							<div className="dropdown w-100 pl-0 pr-0">
								{/* <button
									className="btn util-toggle w-100"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<span className="util-label">select</span>
									<ExpandMoreIcon className="util-down mt-1" />
									<i className="fas fa-chevron-down util-down mt-1"></i>
								</button>
								<div
									className="dropdown-menu w-100 rounded"
									aria-labelledby="dropdownMenuButton"
								>
									<h6 className="dropdown-item">
										<input type="checkbox" /> SENSOR RAM DESK
									</h6>
									<h6 className="dropdown-item">
										<input type="checkbox" /> P550
									</h6>
									<h6 className="dropdown-item">
										<input type="checkbox" /> P800
									</h6>
								</div> */}
								<Select
									className="basic-multi-select"
									onChange={() => {}}
									options={arr}
									placeholder="Select Building"
								/>
							</div>
						</div>
						<div className="w-50 mr-2">
							<div className="form-group ">
								{/* <h6>Start Date</h6>
								<input
									type="date"
									className=" util-input w-100 p-2"
									placeholder="input"
								/> */}
								<DatePicker
									label="Start Date"
									inputId={"start-date-input"}
									date={new Date()}
									onDateChanged={(date: Date | null) =>
										updateDate(date, "dateFrom")
									}
									isDisablePast={true}
								/>
							</div>
						</div>
						<div className="w-50 mr-2">
							<div className="form-group ">
								{/* <h6>End Date</h6>
								<input
									type="date"
									className=" util-input w-100 p-2"
									placeholder="input"
								/> */}
								<DatePicker
									label="End Date"
									inputId={"end-date-input"}
									date={new Date()}
									onDateChanged={(date: Date | null) =>
										updateDate(date, "dateFrom")
									}
									isDisablePast={true}
								/>
							</div>
						</div>
						<div className="w-50 mr-2">
							<div className="form-group ">
								<h6>Start Time</h6>
								<input
									type="time"
									className=" util-input w-100 p-2"
									placeholder="input"
								/>
							</div>
						</div>
						<div className="w-50">
							<div className="form-group ">
								<h6>End Time</h6>
								<input
									type="time"
									className=" util-input w-100 p-2"
									placeholder="input"
								/>
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-between mt-2">
						<div className="d-flex w-50">
							<div className="dropdown w-50 pl-0 pr-0">
								{/* <button
									className="btn util-toggle w-100"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<span className="util-label">Weekly</span>
									<ExpandMoreIcon className="util-down mt-1" />
									<i className="fas fa-chevron-down util-down mt-1"></i>
								</button>
								<div
									className="dropdown-menu w-100 rounded"
									aria-labelledby="dropdownMenuButton"
								>
									<h6 className="dropdown-item">
										<input type="checkbox" /> SENSOR RAM DESK
									</h6>
									<h6 className="dropdown-item">
										<input type="checkbox" /> P550
									</h6>
									<h6 className="dropdown-item">
										<input type="checkbox" /> P800
									</h6>
								</div> */}
								<Select
									className="basic-multi-select"
									onChange={() => {}}
									options={schedule}
									placeholder="List of Schedule"
								/>
							</div>
							<button className="btn util-btn1 pl-4 pr-4 ml-2">
								<i className="fas fa-play"></i> Run Report
							</button>
						</div>
						<div className="w-70 d-flex">
							<button
								type="button"
								className="btn d-flex util-btn pl-3 pr-3 mr-2"
							>
								<img src="/images/email.svg" className="img-icon" alt="" />
								Email
							</button>
							<button
								type="button"
								className="btn d-flex util-btn pl-3 pr-3 mr-2"
							>
								<img src="/images/excel.svg" className="img-icon" alt="" />
								Excel
							</button>
							<button
								type="button"
								className="btn d-flex util-btn pl-3 pr-3 mr-2"
							>
								<img src="/images/csv.svg" className="img-icon" alt="" /> CSV
							</button>
							<button
								type="button"
								className="btn d-flex util-btn pl-3 pr-3 mr-2"
							>
								<img src="/images/pdf.svg" className="img-icon" alt="" /> PDF
							</button>
							<button type="button" className="btn d-flex util-btn pl-3 pr-3">
								<img src="/images/copy.svg" className="img-icon" alt="" /> Copy
							</button>
						</div>
					</div>
					<div>
						<div className="w-100 bg-light mt-3 p-3 util-Monthly">
							<h5>Monthly Report</h5>
						</div>
						<div className="mt-3">
							<table className="table mb-0">
								<thead className="text-center pt-2 pb-2 pl-0 pr-0 mb-1">
									<tr className="bg-light">
										<td>DEVICE ID</td>
										<td>DEVICE NAME</td>
										<td>DEVICE IMAGE</td>
										<td>DESCRIPTION</td>
										<td>CATEGORY</td>
										<td>MONTH</td>
										<td>AVERAGE RUNTIME(HRS.)</td>
										<td>AREA EFFICACY(SQ.FT.)</td>
										<td>HEALTHY BUILDING IAQ CONTRIBUTION(SQ.FT)</td>
									</tr>
								</thead>
								<tbody className="text-center">
									<tr className="align-middle">
										<td>
											<span className="id">12345</span>
										</td>
										<td>TVOC</td>
										<td className="h-10 w-10">
											<img src="/images/Maintenance.svg" alt="" />
										</td>
										<td>UV Sterilizer</td>
										<td>UVGI</td>
										<td>june</td>
										<td>240</td>
										<td>600</td>
										<td>84000</td>
									</tr>
									<tr className="align-middle">
										<td>
											<span className="id">12345</span>
										</td>
										<td>TVOC</td>
										<td className="h-10 w-10">
											<img src="/images/Maintenance.svg" alt="" />
										</td>
										<td>UV Sterilizer</td>
										<td>UVGI</td>
										<td>june</td>
										<td>240</td>
										<td>600</td>
										<td>84000</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div>
						<div className="w-100 bg-light mt-3 p-3 util-Monthly">
							<h5>Weekly Report</h5>
						</div>
						<div className="mt-3">
							<table className="table mb-0">
								<thead className="text-center pt-2 pb-2 pl-0 pr-0 mb-1">
									<tr className="bg-light">
										<td>DEVICE ID</td>
										<td>DEVICE NAME</td>
										<td>DEVICE IMAGE</td>
										<td>DESCRIPTION</td>
										<td>CATEGORY</td>
										<td>MONTH</td>
										<td>AVERAGE RUNTIME(HRS.)</td>
										<td>AREA EFFICACY(SQ.FT.)</td>
										<td>HEALTHY BUILDING IAQ CONTRIBUTION(SQ.FT)</td>
									</tr>
								</thead>
								<tbody className="text-center">
									<tr className="align-middle">
										<td>
											<span className="id">12345</span>
										</td>
										<td>TVOC</td>
										<td className="h-10 w-10">
											<img src="/images/Maintenance.svg" alt="" />
										</td>
										<td>UV Sterilizer</td>
										<td>UVGI</td>
										<td>june</td>
										<td>240</td>
										<td>600</td>
										<td>84000</td>
									</tr>
									<tr className="align-middle">
										<td>
											<span className="id">12345</span>
										</td>
										<td>TVOC</td>
										<td className="h-10 w-10">
											<img src="/images/Maintenance.svg" alt="" />
										</td>
										<td>UV Sterilizer</td>
										<td>UVGI</td>
										<td>june</td>
										<td>240</td>
										<td>600</td>
										<td>84000</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div>
						<div className="w-100 bg-light mt-3 p-3 util-Monthly">
							<h5>Daily Report</h5>
						</div>
						<div className="mt-3">
							<table className="table mb-0">
								<thead className="text-center pt-2 pb-2 pl-0 pr-0 mb-1">
									<tr className="bg-light">
										<td>DEVICE ID</td>
										<td>DEVICE NAME</td>
										<td>DEVICE IMAGE</td>
										<td>DESCRIPTION</td>
										<td>CATEGORY</td>
										<td>MONTH</td>
										<td>AVERAGE RUNTIME(HRS.)</td>
										<td>AREA EFFICACY(SQ.FT.)</td>
										<td>HEALTHY BUILDING IAQ CONTRIBUTION(SQ.FT)</td>
									</tr>
								</thead>
								<tbody className="text-center">
									<tr className="align-middle">
										<td>
											<span className="id">12345</span>
										</td>
										<td>TVOC</td>
										<td className="h-10 w-10">
											<img src="/images/Maintenance.svg" alt="" />
										</td>
										<td>UV Sterilizer</td>
										<td>UVGI</td>
										<td>june</td>
										<td>240</td>
										<td>600</td>
										<td>84000</td>
									</tr>
									<tr className="align-middle">
										<td>
											<span className="id">12345</span>
										</td>
										<td>TVOC</td>
										<td className="h-10 w-10">
											<img src="/images/Maintenance.svg" alt="" />
										</td>
										<td>UV Sterilizer</td>
										<td>UVGI</td>
										<td>june</td>
										<td>240</td>
										<td>600</td>
										<td>84000</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default DeviceUtilizationReports;
