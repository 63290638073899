import React, { useRef } from "react";
import "./CustomReports.scoped.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Area, Pie } from "@ant-design/charts";
import ReactToPrint from "react-to-print";

const CustomReports: React.FC = () => {
	const componentRef = useRef(null);

	const getPieChartData = (pieChartData: any[]) => {
		const config = {
			data: pieChartData,
			angleField: "value",
			colorField: "type",
			radius: 1,
			innerRadius: 0.7,
			height: 150,
			legend: false,
			color: ({ type }: any) => {
				if (type === "Good") {
					return "#1880FD";
				} else if (type === "Moderate") {
					return "#F5A83E";
				} else if (type === "Poor") {
					return "#D73F4C";
				} else if (type === "Very Poor") {
					return "#8F242E";
				} else if (type === "Hazardous") {
					return "#4E1319";
				}
				return "#8F242E";
			},
			label: false,
			statistic: {
				title: false,
				content: false,
			},
		};
		return config;
	};
	const getAreaGraphData = (lineGraphData: any[]) => {
		const config = {
			data: lineGraphData as never[],
			width: 342,
			height: 150,
			xField: "day",
			yField: "value",
			xAxis: {
				range: [0, 1],
			},
			smooth: true,
		};
		return config;
	};
	return (
		<div className="container-fluid">
			<div className="row mt-3 mb-3">
				<div className="col-lg-12 col-md-12 col-sm-12 custom-re bg-white p-3">
					<h5 className="mt-2">Custom IAQ Report</h5>
					<div className="mt-4 p-3 select-iaq">
						<h6>Select IAQ Parameters</h6>
						<div className="d-flex row">
							<div className="d-flex custom-che col-lg-12 col-md-12 justify-content-between pl-3 pr-3 gx-5">
								<div className="col-1 p-0">
									<div className="sa d-flex bg-light">
										<input type="checkbox" className="w-25" />
										<span>PM2.5</span>
									</div>
								</div>
								<div className="col-1 p-0">
									<div className="sa d-flex bg-light">
										<input type="checkbox" className="w-25" />
										<span>PM1.0</span>
									</div>
								</div>
								<div className="col-1 p-0">
									<div className="sa d-flex bg-light">
										<input type="checkbox" className="w-25" />
										<span>PM10</span>
									</div>
								</div>
								<div className="col-1 p-0">
									<div className="sa d-flex bg-light">
										<input type="checkbox" className="w-25" />
										<span>CO2</span>
									</div>
								</div>
								<div className="p-0 col-1">
									<div className="sa d-flex bg-light">
										<input type="checkbox" className="w-25" />
										<span>TVOC</span>
									</div>
								</div>
								<div className="col-2 p-0">
									<div className="sa d-flex bg-light pr-1">
										<input type="checkbox" className="w-25" />
										<span>Temperature</span>
									</div>
								</div>
								<div className="col-3 p-0">
									<div className="sa d-flex bg-light">
										<input type="checkbox" className="w-25" />
										<span>Humidity</span>
									</div>
								</div>
								<div className="col-2 p-0">
									<div className="sa d-flex bg-light pr-1">
										<input type="checkbox" className="w-25" />
										<span>Formaldeyde</span>
									</div>
								</div>
							</div>

							{/* <div className="col p-0 mr-2">
									<div className="sa d-flex bg-light">
										<input type="checkbox" className="w-25" />
										<span>Ozone</span>
									</div>
								</div> */}
						</div>
					</div>
					<div className="d-flex mt-4">
						<div className="w-50 mr-2">
							<h6>select Building</h6>
							<div className="dropdown w-100 pl-0 pr-0">
								<button
									className="btn custom-re-toggle w-100"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<span className="custom-re-label">select</span>
									<ExpandMoreIcon className="custom-re-down ml-auto mt-1" />
									{/* <i className="fas fa-chevron-down custom-re-down ml-auto mt-1"></i> */}
								</button>
								<div
									className="dropdown-menu w-100 rounded"
									aria-labelledby="dropdownMenuButton"
								>
									<h6 className="dropdown-item">
										<input type="checkbox" /> SENSOR RAM DESK
									</h6>
									<h6 className="dropdown-item">
										<input type="checkbox" /> P550
									</h6>
									<h6 className="dropdown-item">
										<input type="checkbox" /> P800
									</h6>
								</div>
							</div>
						</div>
						<div className="w-50 mr-2">
							<div className="form-group ">
								<h6>Start Date</h6>
								<input
									type="date"
									className=" custom-re-input w-100 p-2"
									placeholder="input"
								/>
							</div>
						</div>
						<div className="w-50 mr-2">
							<div className="form-group ">
								<h6>End Date</h6>
								<input
									type="date"
									className=" custom-re-input w-100 p-2"
									placeholder="input"
								/>
							</div>
						</div>
						<div className="w-50 mr-2">
							<div className="form-group ">
								<h6>Start Time</h6>
								<input
									type="time"
									className="custom-re-input w-100 p-2"
									placeholder="input"
								/>
							</div>
						</div>
						<div className="w-50">
							<div className="form-group ">
								<h6>End Time</h6>
								<input
									type="time"
									className="custom-re-input w-100 p-2"
									placeholder="input"
								/>
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-between mt-2">
						<div className="w-30 d-flex">
							<button className="btn custom-re-btn pl-4 pr-4">
								<i className="fas fa-play"></i> Run Report
							</button>
						</div>
						<div className="w-70 d-flex">
							<button
								type="button"
								className="btn d-flex auto-re-btn pl-3 pr-3 mr-2"
							>
								<img src="/images/email.svg" className="img-icon" alt="" />
								Email
							</button>
							<button
								type="button"
								className="btn d-flex auto-re-btn pl-3 pr-3 mr-2"
							>
								<img src="/images/excel.svg" className="img-icon" alt="" />
								Excel
							</button>
							<button
								type="button"
								className="btn d-flex auto-re-btn pl-3 pr-3 mr-2"
							>
								<img src="/images/csv.svg" className="img-icon" alt="" /> CSV
							</button>
							<button
								type="button"
								className="btn d-flex auto-re-btn pl-3 pr-3 mr-2"
							>
								<img src="/images/pdf.svg" className="img-icon" alt="" /> PDF
							</button>
							<button
								type="button"
								className="btn d-flex auto-re-btn pl-3 pr-3"
							>
								<img src="/images/copy.svg" className="img-icon" alt="" /> Copy
							</button>
							<ReactToPrint
								trigger={() => (
									<button
										type="button"
										className="btn d-flex auto-re-btn pl-3 pr-3"
									>
										<img src="/images/copy.svg" className="img-icon" alt="" />
										Download Pdf
									</button>
								)}
								content={() => componentRef.current as any}
							/>
						</div>
					</div>
					<div className=" mt-4" ref={componentRef}>
						<div className="d-flex justify-content-between">
							<div>
								<h4>Exposure Time</h4>
							</div>
							<div className="d-flex pr-3">
								<p className="mr-2 text">
									<img className="mb-1" src="/images/Ellipse1.svg" alt="" />{" "}
									Good
								</p>
								<p className="mr-2 text">
									<img className="mb-1" src="/images/ellipse2.svg" alt="" />{" "}
									Modarate
								</p>
								<p className="mr-2 text">
									<img className="mb-1" src="/images/Ellipse3.svg" alt="" />{" "}
									poor
								</p>
								<p className="mr-2 text">
									<img className="mb-1" src="/images/Ellipse4.svg" alt="" />{" "}
									Very Poor
								</p>
								<p className="text">
									<img className="mb-1" src="/images/Ellipse5.svg" alt="" />{" "}
									Hazardous
								</p>
							</div>
						</div>
						<div className="d-flex justify-content-around col-lg-12">
							<div className="text-center col-lg-2">
								<Pie
									{...getPieChartData([
										{
											type: "Good",
											value: 27,
										},
										{
											type: "Moderate",
											value: 25,
										},
										{
											type: "Poor",
											value: 18,
										},
										{
											type: "Very Poor",
											value: 18,
										},
										{
											type: "Hazardous",
											value: 18,
										},
									])}
								/>{" "}
								<h4 className="mt-2">PM2.5</h4>
							</div>
							<div className="text-center col-lg-2">
								<Pie
									{...getPieChartData([
										{
											type: "Good",
											value: 27,
										},
										{
											type: "Moderate",
											value: 25,
										},
										{
											type: "Poor",
											value: 18,
										},
										{
											type: "Very Poor",
											value: 18,
										},
										{
											type: "Hazardous",
											value: 18,
										},
									])}
								/>{" "}
								<h4 className="mt-2">PM1.0</h4>
							</div>
							<div className="text-center col-lg-2">
								<Pie
									{...getPieChartData([
										{
											type: "Good",
											value: 27,
										},
										{
											type: "Moderate",
											value: 25,
										},
										{
											type: "Poor",
											value: 18,
										},
										{
											type: "Very Poor",
											value: 18,
										},
										{
											type: "Hazardous",
											value: 18,
										},
									])}
								/>{" "}
								<h4 className="mt-2">CO2</h4>
							</div>
							<div className="text-center col-lg-2">
								<Pie
									{...getPieChartData([
										{
											type: "Good",
											value: 27,
										},
										{
											type: "Moderate",
											value: 25,
										},
										{
											type: "Poor",
											value: 18,
										},
										{
											type: "Very Poor",
											value: 18,
										},
										{
											type: "Hazardous",
											value: 18,
										},
									])}
								/>{" "}
								<h4 className="mt-2">TVOC</h4>
							</div>
							<div className="text-center col-lg-2">
								<Pie
									{...getPieChartData([
										{
											type: "Good",
											value: 27,
										},
										{
											type: "Moderate",
											value: 25,
										},
										{
											type: "Poor",
											value: 18,
										},
										{
											type: "Very Poor",
											value: 18,
										},
										{
											type: "Hazardous",
											value: 18,
										},
									])}
								/>{" "}
								<h4 className="mt-2">Temperature</h4>
							</div>
						</div>
						<div>
							<h4>Trend Graph</h4>
						</div>
						<div className="d-flex justify-content-around col-lg-12">
							<div className="text-center col-lg-2">
								<Area
									{...getAreaGraphData([
										{
											day: "Sat",
											value: 25,
										},
										{
											day: "Sun",
											value: 65,
										},
										{
											day: "Mon",
											value: 65,
										},
									])}
								/>

								<h4 className="mt-2">PM2.5</h4>
							</div>
							<div className="text-center col-lg-2">
								<Area
									{...getAreaGraphData([
										{
											day: "Sat",
											value: 25,
										},
										{
											day: "Sun",
											value: 65,
										},
										{
											day: "Mon",
											value: 65,
										},
									])}
								/>

								<h4 className="mt-2">CO2</h4>
							</div>
							<div className="text-center col-lg-2">
								<Area
									{...getAreaGraphData([
										{
											day: "Sat",
											value: 25,
										},
										{
											day: "Sun",
											value: 65,
										},
										{
											day: "Mon",
											value: 65,
										},
									])}
								/>

								<h4 className="mt-2">TVOC</h4>
							</div>
							<div className="text-center col-lg-2">
								<Area
									{...getAreaGraphData([
										{
											day: "Sat",
											value: 25,
										},
										{
											day: "Sun",
											value: 65,
										},
										{
											day: "Mon",
											value: 65,
										},
									])}
								/>

								<h4 className="mt-2">Temperature</h4>
							</div>
							<div className="text-center col-lg-2">
								<Area
									{...getAreaGraphData([
										{
											day: "Sat",
											value: 25,
										},
										{
											day: "Sun",
											value: 65,
										},
										{
											day: "Mon",
											value: 65,
										},
									])}
								/>

								<h4 className="mt-2">Humidity</h4>
							</div>
						</div>
						{/* <div>
							<h6>Trend Graph</h6>
							<div className="d-flex justify-content-around mt-3 flex-wrap">
								<div className="text-center">
									<img className="h-10 w-10" src="/images/trend1.svg" alt="" />
									<div className="d-flex mt-2">
										<button type="button" className="btn trend-btn mr-1 ml-1">
											Hour
										</button>
										<button type="button" className="btn trend-btn mr-1">
											Day
										</button>
										<button type="button" className="btn trend-btn">
											Monthly
										</button>
									</div>
								</div>
								<div className="text-center">
									<img className="h-10 w-10" src="/images/trend2.svg" alt="" />
									<div className="d-flex mt-2">
										<button type="button" className="btn trend-btn mr-1 ml-1">
											Hour
										</button>
										<button type="button" className="btn trend-btn mr-1">
											Day
										</button>
										<button type="button" className="btn trend-btn">
											Monthly
										</button>
									</div>
								</div>
								<div className="text-center">
									<img className="" src="/images/trend3.svg" alt="" />
									<div className="d-flex mt-2">
										<button type="button" className="btn trend-btn mr-1 ml-1">
											Hour
										</button>
										<button type="button" className="btn trend-btn mr-1">
											Day
										</button>
										<button type="button" className="btn trend-btn">
											Monthly
										</button>
									</div>
								</div>
								<div className="text-center">
									<img className="h-10 w-10" src="/images/trend4.svg" alt="" />
									<div className="d-flex mt-2">
										<button type="button" className="btn trend-btn mr-1 ml-1">
											Hour
										</button>
										<button type="button" className="btn trend-btn mr-1">
											Day
										</button>
										<button type="button" className="btn trend-btn">
											Monthly
										</button>
									</div>
								</div>
								<div className="text-center">
									<img className="h-10 w-10" src="/images/trend1.svg" alt="" />
									<div className="d-flex mt-2">
										<button type="button" className="btn trend-btn mr-1 ml-1">
											Hour
										</button>
										<button type="button" className="btn trend-btn mr-1">
											Day
										</button>
										<button type="button" className="btn trend-btn">
											Monthly
										</button>
									</div>
								</div>
							</div>
						</div>
				 */}
					</div>
				</div>
			</div>
		</div>
	);
};
export default CustomReports;
