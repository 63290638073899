import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import { AnySrvRecord } from "dns";
import { useSelector } from "react-redux";

import LocationSelector from "../LocationSelector/LocationSelector";
import { MonitorDetailModal } from "../../modals/device/MonitorDetail";
import { addDevice, updateDevice } from "../../api/DevicesController";
import { URL } from "../Navbar/routes";
import { RootState } from "../../redux/reducers";

import "./AddEditMonitor.scoped.scss";
import { getMonitorTypeFullName } from "../../utils/helpers";
interface EditMonitorProps {
	show: boolean;
	onHide: (isCallFromSave: boolean) => void;
	monitorDetails: MonitorDetailModal;
}

interface Item {
	label: string;
	value: string;
	path?: string;
}

const AddEditMonitor: React.FC<EditMonitorProps> = ({
	show,
	onHide,
	monitorDetails,
}) => {
	const history = useHistory();
	const asset = useSelector((state: RootState) => state.asset);
	const [monitorType, setMonitorType] = useState<any>({});
	const [monitorTypeError, setMonitorTypeError] = useState<string>("");
	const [locationError, setLocationError] = useState<string>("");
	const [selectedLocation, setSelectedLocation] = useState<Item>();

	const [monitorTypeData] = useState<any>({
		aqMonitor: "Indoor Air Quality Monitor",
		ionMonitor: "Ion monitor",
		radonMonitor: "Radon monitor",
		inductMonitor: "Outdoor Air Quality Monitor",
		outdoorMonitor: "Induct Air Quality Monitor",
	});
	const [monitorDetailState, setMonitorDetailState] =
		useState<MonitorDetailModal>(monitorDetails);
	const [apiError, setApiError] = useState<string>("");
	useEffect(() => {
		if (monitorDetails) {
			setMonitorType({
				label: get(monitorTypeData, get(monitorDetails, "subType", "")),
				value: get(monitorDetails, "subType", ""),
			});
			setSelectedLocation({
				label: "",
				value: monitorDetailState.location[0],
			});
		}
	}, [monitorDetails]);

	const isEditMode = () => {
		return monitorDetailState._id ? true : false;
	};

	const saveDetails = () => {
		if (isEmpty(monitorType?.value)) {
			setMonitorTypeError("please select monitor type");
			return;
		} else if (isEmpty(selectedLocation)) {
			setLocationError("please select location");
			return;
		}
		let monitorDetail: MonitorDetailModal = new MonitorDetailModal();
		monitorDetail = monitorDetailState;
		monitorDetail.location = selectedLocation?.value
			? selectedLocation?.value
			: "";
		monitorDetail.subType = monitorType?.value;
		setApiError("");
		if (!isEditMode()) {
			delete monitorDetail._id;
			callAddDevice(monitorDetail);
		} else {
			callUpdateDevice(monitorDetail);
		}
	};

	const callAddDevice = (monitorDetail: MonitorDetailModal) => {
		addDevice(monitorDetail)
			.then((response: any) => {
				if (response.responseObj) {
					onHide(true);
					history.push(URL.DEVICE_MANAGEMENT);
				} else if (response.error) {
					setApiError(response.error);
				}
			})
			.catch((error: AnySrvRecord) => {});
	};

	const callUpdateDevice = (monitorDetail: MonitorDetailModal) => {
		updateDevice(monitorDetail)
			.then((response: any) => {
				if (response.responseObj) {
					onHide(true);
				} else if (response.error) {
					setApiError(response.error);
				}
			})
			.catch((error: AnySrvRecord) => {});
	};

	const onChangeLocation = (value: any) => {
		setSelectedLocation(value);
		setLocationError("");
	};

	const updateDeviceDetail = (value: string, propertyName: string) => {
		const monitorDetailTemp = { ...monitorDetailState };
		(monitorDetailTemp as any)[propertyName] = value;
		setMonitorDetailState(monitorDetailTemp);
	};

	return (
		<Modal
			show={show}
			size="lg"
			onHide={() => onHide(false)}
			dialogClassName="modal-custom"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<h5 className="mt-3">{isEditMode() ? "Edit" : "Add"} Monitor</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-sm-12">
							<div className="d-flex">
								<div className="w-100 pl-0 pr-0">
									<div className="form-group">
										<h6>Device Name</h6>
										<input
											type="text"
											id="name"
											disabled
											className="input w-100"
											placeholder="Device Name"
											value={monitorDetailState.deviceName}
											onChange={(event) =>
												updateDeviceDetail(event.target.value, "deviceName")
											}
										/>
									</div>
								</div>
							</div>
							<h6 className="mt-4">Monitor Type</h6>
							<div className="dropdown w-100 pl-0 pr-0 pb-3">
								<input
									type="text"
									id="monitor-type"
									disabled
									className="input w-100"
									placeholder="Monitor Type Name"
									value={getMonitorTypeFullName(monitorType.value)}
								/>
							</div>
							<div className="d-flex">
								<div className="w-100 pl-0 pr-0">
									<div className="form-group">
										<h6 className="mt-4">Description</h6>
										<input
											id="description"
											type="text"
											className="input w-100"
											placeholder="Device Description"
											value={monitorDetailState.description}
											onChange={(event) =>
												updateDeviceDetail(event.target.value, "description")
											}
										/>
									</div>
								</div>
							</div>
							<h6 className="mt-4">Location</h6>
							<div className="dropdown w-100  pl-0 pr-0">
								<LocationSelector
									onSelectLocation={onChangeLocation}
									buildingId={monitorDetails.building}
									selectedLocationId={selectedLocation?.value}
									selectProps={{
										isClearable: false,
									}}
								/>
								{locationError && (
									<p className="text-danger">{locationError}</p>
								)}
							</div>
							{apiError && (
								<p className="font-14 mt-3 text-danger">{apiError}</p>
							)}
						</div>
					</div>
					<div className="d-flex mt-4 justify-content-end flex-wrap">
						<button
							type="button"
							className="btn mopbtnc pl-5 pr-5"
							onClick={() => onHide(false)}
						>
							Cancel
						</button>
						<button
							type="button"
							className="btn add pl-5 pr-5"
							onClick={saveDetails}
						>
							{isEditMode() ? "Update" : "Add"}
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};
export default AddEditMonitor;
