import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./ConfirmManageCustomer.scoped.scss";

interface ConfirmManageCustomerProps {
	dialogTitle: string;
	message: string;
	positiveBtnLabel: string;
	onPositiveBtnClicked: (isConfirm: boolean) => void;
	onCloseIconClicked: () => void;
}

const ConfirmManageCustomer: React.FC<ConfirmManageCustomerProps> = ({
	dialogTitle,
	message,
	positiveBtnLabel,
	onPositiveBtnClicked,
	onCloseIconClicked,
}) => {
	const [show, setShow] = useState(true);

	const [confirm, isConfirm] = useState<boolean>(false);

	const onPositiveBtn = () => {
		setShow(false);
		onPositiveBtnClicked(confirm);
	};

	const hideModal = () => {
		setShow(false);
		onCloseIconClicked();
	};

	const confirmationChange = (value: any) => {
		const state: boolean = value.target.checked;
		isConfirm(state);
	};

	return (
		<>
			<Modal
				show={show}
				onHide={hideModal}
				backdrop="static"
				keyboard={false}
				className="delete-popup"
			>
				<Modal.Header>
					<Modal.Title>{dialogTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="message-wrap">
						<input
							className="sign-up-checkbox"
							type="checkbox"
							id="terms"
							name="terms"
							onChange={(event) => confirmationChange(event)}
						/>

						<p>{message}</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={onPositiveBtn}
						style={{ cursor: "pointer" }}
					>
						{positiveBtnLabel}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ConfirmManageCustomer;
