import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./StripePaymentStatusDialog.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useHistory } from "react-router-dom";

import "./StripePaymentStatusDialog.scss";
interface StripePaymentStatusDialogProps {
	positiveBtnLabel: string;
	negativeBtnLabel: string;
	onPositiveBtnClicked: () => void;
	onNegativeBtnClicked: () => void;
	onCloseIconClicked: () => void;
	paymentSummary?: any;
}

const StripePaymentStatusDialog: React.FC<StripePaymentStatusDialogProps> = ({
	paymentSummary,
	positiveBtnLabel,
	onPositiveBtnClicked,
	onNegativeBtnClicked,
	onCloseIconClicked,
}) => {
	const history = useHistory();
	const [show, setShow] = useState(true);

	const onNegativeBtn = () => {
		setShow(false);
		onNegativeBtnClicked();
	};

	const onPositiveBtn = () => {
		setShow(false);
		history.push("/dashboard/data-sim");
		onPositiveBtnClicked();
	};

	const hideModal = () => {
		setShow(false);
		onCloseIconClicked();
	};

	return (
		<>
			<Modal show={show} onHide={hideModal} backdrop="static" keyboard={false}>
				<Modal.Body>
					<div>
						<div className="success-msg">
							<div>
								<p className="price">${paymentSummary?.amount}</p>
								<p className="success-text">Payment Success</p>
							</div>
							<div className="icon">
								<CheckCircleIcon
									style={{ width: "54px", height: "54px", color: "#3fbe65" }}
								/>
							</div>
						</div>
						<p>Thank you! your transaction has been completed successfully.</p>
						<p className="transaction-heading">Transaction Details</p>
						<div className="transaction-container-wrap">
							<div className="titles-wrap">
								<p>Invoice</p>
								<p>Paid with</p>
								<p>Transaction Time</p>
								<p>Receipt</p>
							</div>
							<div className="values-wrap">
								<p>: #{paymentSummary?.invoiceNumber}</p>
								<p className="text-capitalize">
									: {paymentSummary?.paymentType}
								</p>
								<p>: {paymentSummary.transactionTime}</p>
								<a
									href={paymentSummary.receiptUrl}
									target="_blank"
									className="link-to-download"
								>
									: Click here to download
								</a>
							</div>
						</div>
						<Button
							variant="primary"
							onClick={onPositiveBtn}
							style={{ cursor: "pointer" }}
							className="w-100"
						>
							{positiveBtnLabel}
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default StripePaymentStatusDialog;
