export const SET_ASSETS = "SET_ASSETS";
export const SET_ASSET = "SET_ASSET";
export const SET_BUILDING = "SET_BUILDING";
export const SET_BUILDINGS = "SET_BUILDINGS";
export const GET_DEVICES = "GET_DEVICES";
export const SET_DEVICES = "SET_DEVICES";
export const GET_LOCATION = "GET_LOCATION";
export const SET_LOCATION = "SET_LOCATION";
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_SELECTED_DEVICE = "SET_SELECTED_DEVICE";
export const CLEAR_USER = "CLEAR_USER";
