import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { isIpadOS } from "../../common/service";

import "./DeviceManagement.scoped.scss";

interface IUnassignedDevice {
	fetchDeviceManagementData: () => void;
	isUnAssignDeviceApiLoading: boolean;
	addableDevices: IDevice[];
	assignDevice: (id: IDevice) => void;
}

interface IDevice {
	deviceId: string;
	deviceName: string;
}

export const UnassignedDevice = ({
	fetchDeviceManagementData,
	isUnAssignDeviceApiLoading,
	addableDevices,
	assignDevice,
}: IUnassignedDevice) => {
	const checkIsMobile = () => {
		if (
			/android/.test(navigator.userAgent.toLowerCase()) ||
			/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) ||
			isIpadOS()
		) {
			return <p>You are All Set!! Now you can start pairing your device</p>;
		} else {
			return (
				<p>
					You are All Set!! Add new devices in the iQi Cloud app to assign
					devices to locations
				</p>
			);
		}
	};

	return (
		<div className="p-3 m-p-3 dm-building-list">
			<div className="d-flex justify-content-between">
				<div>
					<p className="desc-large font-weight-bold font-primary mr-1 mb-1">
						Unassigned Devices
					</p>
					{!isUnAssignDeviceApiLoading && addableDevices.length !== 0 && (
						<p className="desc-normal mb-3">
							Click on the devices below to assign them to a building and a
							location
						</p>
					)}
				</div>
			</div>
			{isUnAssignDeviceApiLoading ? (
				<div className="box2 justify-content-center m-2 p-5 w-100 section-loader">
					<CircularProgress />
				</div>
			) : (
				<div className="row ml-0">
					<div className="align-items-center d-flex flex-wrap">
						{addableDevices.map((deviceData) => (
							<div key={deviceData.deviceId}>
								<Tooltip title={deviceData.deviceId} placement="top">
									<div
										onClick={() => assignDevice(deviceData)}
										className="device align-items-center d-flex device p-2 mr-2 mt-2"
									>
										{deviceData.deviceName || deviceData.deviceId}
									</div>
								</Tooltip>
							</div>
						))}
					</div>
				</div>
			)}

			{!isUnAssignDeviceApiLoading && addableDevices.length === 0 && (
				<>
					<div className="d-flex justify-content-center mt-5">
						You are All Set!! You can add new devices in the iQiCloud mobile app
					</div>
				</>
			)}
		</div>
	);
};
