import React from "react";
import { NavLink } from "react-router-dom";
import { IMenuItem } from "../../../common/Interface";
import { ToggleButton } from "@material-ui/lab";
import "../BottomNavBar.scoped.scss";
import { URL } from "../../Navbar/routes";
import { BottomMenuValues } from "../Menus";

interface SubMenuProps {
	item: IMenuItem;
}
const BottomNav: React.FC<SubMenuProps> = ({ item }) => {
	const { icon, title, path } = item;
	const isMenuActive = (path: any, title: any) => {
		if (
			[
				URL.MORENAVBAR,
				URL.DASHBOARD_BUILDINGS,
				URL.FLOOR_PLAN_LIST,
				URL.IAQ_REPORTS,
				URL.CLUSTER_CONFIG,
				URL.SCHEDULE,
				URL.DATA_SIM,
				URL.CUSTOMER_ACCOUNT,
				URL.CUSTOMER_ACCOUNT_UNASSIGNED,
				URL.OFFLINE_SUBSCRIPTION,
				URL.ADMINSUBMENU,
				URL.DASHBOARD_PROFILE,
			].includes(window.location.pathname) &&
			title == BottomMenuValues[3].title
		) {
			return true;
		} else if (
			[
				URL.IAQSUBMENU,
				URL.IAQPRODUCT,
				URL.IAQCONSULTANT,
				URL.IAQSERVICES,
				URL.IAQCONSULTANTALL,
			].includes(window.location.pathname) &&
			title == BottomMenuValues[2].title
		) {
			return true;
		} else if (window.location.pathname === path) return true;
	};
	return (
		<NavLink to={path ? path : "/"}>
			<ToggleButton
				className={isMenuActive(path, title) ? "w-100 active" : "w-100"}
			>
				<div className="d-flex align-items-center flex-column justify-content-center">
					{icon}
					<p className="mt-1 mb-0 ">{title}</p>
				</div>
			</ToggleButton>
		</NavLink>
	);
};
export default BottomNav;
