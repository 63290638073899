import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface ConfirmDialogProps {
	show: boolean;
	onClose: () => void;
	sendEmail: (value: string) => void;
}

export const EmailModal: React.FC<ConfirmDialogProps> = ({
	show,
	onClose,
	sendEmail,
}) => {
	const emailRegexPattern =
		/^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])(,)?)+$/;

	const [value, updaeValue] = useState<string>("");
	const [inputError, setInputError] = useState<string>("");

	const onSubmit = () => {
		if (value) {
			const email: string[] = value.toLowerCase().split(",");
			const emailaList = [...new Set(email)].join(",");
			updaeValue(emailaList);
			sendEmail(emailaList);
		} else {
			setInputError("Email is required");
		}
	};

	return (
		<Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<h5>Send Report</h5>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex pb-0">
					<h6>Email</h6>
				</div>
				<input
					id="alertEmail"
					value={value}
					name="alertEmail"
					type="text"
					onChange={(e) => {
						updaeValue(e.target.value);
						!emailRegexPattern.test(e.target.value)
							? setInputError("Please enter valid email address")
							: setInputError("");
					}}
					className={`add-re-input ${
						inputError ? "input-error" : ""
					} form-control pt-2 pb-2`}
				/>
				{inputError && (
					<p className="text-danger desc-small my-1">{inputError}</p>
				)}
				<p className="desc-normal mt-1 text-secondary">
					Add multiple email ids separated by comma
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={onSubmit}
					style={{ cursor: "pointer" }}
				>
					Send Report
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
