import { default as ReactSelect, components } from "react-select";

const multiSelect: any = {
	control: (styles: any) => ({
		...styles,
		"min-width": "400px",
	}),
};

const Option = (props: any) => {
	return (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					className="select-input mr-2"
					checked={props.isSelected}
					onChange={() => null}
				/>
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

export const MultiSelectWithAdd = ({
	selectedOptions,
	onSelectionChange,
	selectValue,
	placeholder,
	customStyle,
	onAddNewLocation,
}: any) => {
	const onSelectChange = (selected: any, event: any) => {
		if (event?.option?.value === "+") {
			onAddNewLocation();
		} else if (event?.option?.value === "*") {
			selectedOptions.shift(0, 1);
			onSelectionChange(selectedOptions);
		} else {
			onSelectionChange(selected);
		}
	};

	return (
		<span
			className="d-inline-block"
			data-toggle="popover"
			data-trigger="focus"
			data-content="Please select dimension(s)"
		>
			<ReactSelect
				options={[
					{ value: "+", label: "+ Add New Location" },
					...selectedOptions,
				]}
				isMulti
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				components={{
					Option,
				}}
				onChange={onSelectChange}
				allowSelectAll={true}
				value={selectValue}
				styles={customStyle || multiSelect}
				placeholder={placeholder || ""}
			/>
		</span>
	);
};
