import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { IMenuItem } from "../../common/Interface";
import {
	adminMenu,
	menuList,
	userAdminMenu,
	productMenu,
	adminIAQMenu,
} from "../Navbar/menu";
import SubMenu from "./SubMenu";
import { SidebarNav, SidebarWrap } from "./Navbar.Style";
import Shared from "../../common/Shared";
import { RootState } from "../../redux/reducers";
import { UserRoles } from "../../enums/UserRoles";
import cloneDeep from "lodash/cloneDeep";
import { URL } from "./routes";

import "./Navbar.scoped.scss";

interface ISidebar {
	showSidNav: boolean;
	setShowSideNav: (value: boolean) => void;
}

const Sidebar: React.FC<ISidebar> = ({ showSidNav, setShowSideNav }) => {
	const user = useSelector((state: RootState) => state.user);
	const asset: any = useSelector((state: RootState) => state.asset);
	const [subMenu, updateSubMenu] = useState(adminMenu);
	const [subMenuForAdmin, updateSubMenuForAdmin] = useState(userAdminMenu);

	useEffect(() => {
		const tempSubMenu: any = cloneDeep(subMenu);
		const tempSubMenuForAdmin: any = cloneDeep(subMenuForAdmin);

		if (user.role === UserRoles.CONTRACTOR) {
			tempSubMenu[0].subNav.push({
				title: "IAQ Store",
				path: URL.MARKET_PLACE,
			});
			updateSubMenu(tempSubMenu);
		}

		if (user?.role === UserRoles.ADMIN) {
			tempSubMenuForAdmin[0].subNav.push({
				title: "Customer Accounts",
				path: URL.CUSTOMER_ACCOUNT,
			});
			updateSubMenuForAdmin(tempSubMenuForAdmin);
		}
	}, []);

	const handleDrawerClose = () => {
		setShowSideNav(false);
	};

	const renderAdminMenu = () => (
		<>
			{[UserRoles.CONTRACTOR].includes(user.role)
				? subMenu.map((item: IMenuItem, index) => {
						return (
							<div key={`${index}-${item.path}`}>
								<SubMenu
									handleDrawerClose={handleDrawerClose}
									item={item}
									keyValue={index}
								/>
							</div>
						);
				  })
				: (user?.assets
						.find((mapData: any) => mapData._id === asset._id)
						?.permissions.includes("write") ||
						[UserRoles.ADMIN].includes(user.role)) &&
				  subMenuForAdmin.map((item: IMenuItem, index) => {
						return (
							<div key={`${index}-${item.path}`}>
								<SubMenu
									handleDrawerClose={handleDrawerClose}
									item={item}
									keyValue={index}
								/>
							</div>
						);
				  })}
		</>
	);

	const renderIAQAdminMenu = () => (
		<>
			{[UserRoles.CONTRACTOR, UserRoles.ADMIN].includes(user.role)
				? adminIAQMenu.map((item: IMenuItem, index) => {
						return (
							<div key={`${index}-${item.path}`}>
								<SubMenu
									handleDrawerClose={handleDrawerClose}
									item={item}
									keyValue={index}
								/>
							</div>
						);
				  })
				: user?.assets
						.find((mapData: any) => mapData._id === asset._id)
						?.permissions.includes("write") &&
				  userAdminMenu.map((item: IMenuItem, index) => {
						return (
							<div key={`${index}-${item.path}`}>
								<SubMenu
									handleDrawerClose={handleDrawerClose}
									item={item}
									keyValue={index}
								/>
							</div>
						);
				  })}
		</>
	);

	const renderUserMenu = () => (
		<>
			{menuList.map((item: IMenuItem, index) => {
				return (
					<div key={`${index}-${item.path}`}>
						<SubMenu
							handleDrawerClose={handleDrawerClose}
							item={item}
							keyValue={index}
						/>
					</div>
				);
			})}
		</>
	);

	const renderProductMenu = () => (
		<>
			{productMenu.map((item: IMenuItem, index) => (
				<div key={`${index}-${item.path}`}>
					<SubMenu
						handleDrawerClose={handleDrawerClose}
						item={item}
						keyValue={index}
					/>
				</div>
			))}
		</>
	);

	return (
		<SidebarNav>
			<SidebarWrap>
				<div className="mt-4 mb-5 text-center">
					<img className="text-white-color" src={Shared.commonLogoUrl} alt="" />
				</div>

				<div>
					<div>
						{Shared.title === "IAQ Guard" ? (
							<>
								{renderUserMenu()}
								{renderIAQAdminMenu()}
								{renderProductMenu()}
							</>
						) : (
							<>
								{renderAdminMenu()}
								{renderUserMenu()}
								{renderProductMenu()}
							</>
						)}
					</div>
				</div>

				<div className="d-none m-d-block">
					<SwipeableDrawer
						anchor="left"
						open={showSidNav}
						onClose={() => {}}
						onOpen={() => {}}
						className="menu-drawer"
					>
						<div
							className="mobile-menu-bar d-none m-d-block"
							style={{
								width: "290px",
								borderRadius: "0px!important",
								padding: "12px",
							}}
						>
							<div className="d-flex justify-content-between hamburger-logo">
								<img src={Shared.commonHeaderLogoUrl} alt="" />
								<IconButton onClick={handleDrawerClose} className="close-btn">
									<CloseIcon />
								</IconButton>
							</div>

							<div>
								{Shared.title === "IAQ Guard" ? (
									<>
										{renderUserMenu()}
										{renderIAQAdminMenu()}
										{renderProductMenu()}
									</>
								) : (
									<>
										{renderAdminMenu()}
										{renderUserMenu()}
										{renderProductMenu()}
									</>
								)}
							</div>
						</div>
					</SwipeableDrawer>
				</div>
			</SidebarWrap>
		</SidebarNav>
	);
};

export default Sidebar;
