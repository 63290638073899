import { URL } from "../components/Navbar/routes";

export enum UserRoles {
	CONTRACTOR = "contractor",
	USER = "user",
	ADMIN = "admin",
	SUPER_ADMIN = "super-admin",
	PREMIUMUSER = "premium-user",
}
const CheckWriteAccess = {
	condition: "check_write_access",
	roles: ["user", "admin", "super-admin"],
};
const CheckWriteAccessNotAllowedRoute = {
	condition: "check_write_access_not_allowed",
	roles: ["user"],
	notallowed: [URL.ADMINSUBMENU],
};
const CheckPremiumUser = {
	condition: "check_premium_user",
	roles: ["contractor", "admin"],
	notallowed: [
		URL.ADMINSUBMENU,
		URL.DASHBOARD_BUILDINGS,
		URL.FLOOR_PLAN_LIST,
		URL.IAQ_REPORTS,
		URL.CLUSTER_CONFIG,
		URL.SCHEDULE,
		URL.CONSULTATION_REPORTS,
	],
};
export { CheckWriteAccess, CheckWriteAccessNotAllowedRoute, CheckPremiumUser };
