import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import Header from "../../components/Header/Header";
import LocationList from "../Location/List/List";
import Buildings from "../Buildings/Buildings";
import Navbar from "../../components/Navbar/Navbar";
import BottomNavBar from "../../components/BottomNavBar";
import Profile from "../Profile/Profile";
import ConfigureDevice from "../Devices/ConfigureDevice/ConfigureDevice";
import NewOrEditSchedule from "../Schedule/AddEditSchedule/AddEditSchedule";
import ScheduleList from "../Schedule/List/List";
import Autopilot from "../Autopilot/Autopilot";
import ClusterConfig from "../ClusterConfig";
import IaqReports from "../Reports/IaqReports/IaqReports";
import MaintenanceReports from "../Reports/MaintananceReports/MaintenanceReports";
import DeviceUtilizationReports from "../Reports/DeviceUtiliazationReports/DeviceUtilizationReports";
import AutopilotReports from "../Reports/AutopilotReports/AutopilotReports";
import ConsultationReports from "../Reports/ConsultationReports/ConsultationReports";
import CustomReports from "../Reports/CustomReports/CustomReports";
import ScheduleReports from "../Reports/ScheduleReports/ScheduleReports";
import { RootState } from "../../redux/reducers";
import {
	getContractorAssets,
	getAllAssetBuildings,
} from "../../api/AssetController";
import { URL } from "../../components/Navbar/routes";
import DeviceManagement from "../DeviceManagement";
import AddNewBuldingForMobile from "../AddNewBuilding";
import AddNewLocationForMobile from "../AddNewLocation";
import Help from "../Help/Help";
import AirQualityParameter from "../AqParam/AqParam";
import AssetList from "../AssetList/Assets";
import { setBuilding, setBuildings } from "../../redux/actions/buildingActions";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import AnalyticsDashboard from "../Dashboard/Dashboard";
import DataSim from "../SimSubscriptions/SimSubscriptions";
import { MobileHeader } from "../../components/MobileHeader/MobileHeader";
import { setAssets, setAsset } from "../../redux/actions/assetActions";
import { setUser } from "../../redux/actions/userActions";
import { getProfileDetails } from "../../api/UserController";
import ConsultantList from "../ConsultantList/ConsultantList";
import CreateConsultantReport from "../CreateConsultantReport/CreateConsultantReport";
import MarketPlace from "../MarketPlace/MarketPlace";
import MarketPlaceProduct from "../ProductDetails/ProductDetails";
import ProductList from "../ProductList/ProductList";
import More from "../Mobile/More";
import IAQStoreSubMenu from "../Mobile/IAQStoreSubMenu";
import AdminSubMenu from "../Mobile/AdminSubMenu";
import IAQProduct from "../IAQStore/Product";
import IAQServices from "../IAQStore/Services";
import IAQConsultant from "../IAQStore/Consultant";
import AllFavouriteConsultant from "../IAQStore/Consultant/AllFavouriteConsultant";
import ViewProduct from "../IAQStore/Product/ViewProduct";
import AllFavouriteProduct from "../IAQStore/Product/AllFavouriteProduct";

import "./Routes.scoped.scss";
import Subscription from "../OfflineSubscription/OfflineSubscription";

const Dashboard: React.FC = () => {
	const { pathname } = window.location;
	const asset: any = useSelector((state: RootState) => state.asset);
	const building = useSelector((state: RootState) => state.building);
	const user: any = useSelector((state: RootState) => state.user);

	const dispatch = useDispatch();

	const [showSidNav, setShowSideNav] = useState(false);
	const [isWebView, setIsWebView] = useState(true);

	useEffect(() => {
		if (localStorage.getItem("isWebView")) {
			setIsWebView(false);
		}
		loadAssetAndBuilding();
		getProfileDetails().then((response: any) => {
			if (response.responseObj) {
				setUser(response.responseObj);
			}
		});
	}, []);

	const processAssesData = (response: any) => {
		let assetId = asset._id;
		let selectedAsset;
		if (get(response, "responseObj.length") > 0) {
			dispatch(setAssets(get(response, "responseObj")));
			if (isEmpty(asset)) {
				assetId = get(response, "responseObj[0]._id");
				dispatch(setAsset(get(response, "responseObj[0]")));
			}
			selectedAsset = get(response, "responseObj", []).find(
				(assetData: any) => assetData._id === assetId
			);
			dispatch(setBuildings(get(selectedAsset, "buildings", [])));
			if (isEmpty(building)) {
				dispatch(setBuilding(get(selectedAsset, "buildings[0]", {})));
			}
		}
	};

	const loadAssetAndBuilding = async () => {
		if (user.role === "contractor") {
			const resContractor: any = await getContractorAssets();
			processAssesData(resContractor);
		} else {
			const res: any = await getAllAssetBuildings();
			processAssesData(res);
		}
	};

	return (
		<div>
			<div className="dashboard" id="app-container">
				<div className="left-panel ">
					<Navbar showSidNav={showSidNav} setShowSideNav={setShowSideNav} />
				</div>
				<div className="right-panel ">
					<Header setShowSideNav={setShowSideNav} />
					{![
						URL.DASHBOARD_PROFILE,
						URL.DASHBOARD_PRODUCT_HELp,
						URL.CUSTOMER_ACCOUNT,
						URL.CUSTOMER_ACCOUNT_UNASSIGNED,
						URL.DASHBOARD_AQ_PARAMETERS_EXPLAINED,
						URL.CHANGE_PASSWORD,
						URL.CONFIGURE_DEVICE,
						URL.DATA_SIM,
					].includes(window.location.pathname) &&
						!(pathname.indexOf(URL.CONFIGURE_DEVICE) !== -1) && (
							<MobileHeader />
						)}
					<div className="mobile-container">
						<Switch>
							<Route path={URL.MORENAVBAR} exact component={More} />
							<Route path={URL.IAQSUBMENU} exact component={IAQStoreSubMenu} />
							<Route path={URL.ADMINSUBMENU} exact component={AdminSubMenu} />
							<Route path={URL.IAQPRODUCT} exact component={IAQProduct} />
							<Route path={URL.IAQSERVICES} exact component={IAQServices} />
							<Route
								path={`${URL.IAQVIEWPRODUCT}/:id`}
								exact
								component={ViewProduct}
							/>
							<Route
								path={URL.IAQALLPRODUCT}
								exact
								component={AllFavouriteProduct}
							/>
							<Route
								path={`${URL.IAQALLPRODUCT}/:category/:categoryname`}
								exact
								component={AllFavouriteProduct}
							/>
							<Route path={URL.IAQCONSULTANT} exact component={IAQConsultant} />
							<Route
								path={URL.OFFLINE_SUBSCRIPTION}
								exact
								component={Subscription}
							/>
							<Route
								path={URL.IAQCONSULTANTALL}
								exact
								component={AllFavouriteConsultant}
							/>
							<Route
								path={`${URL.IAQCONSULTANTALL}/:category`}
								exact
								component={AllFavouriteConsultant}
							/>
							<Route
								path={URL.DASHBOARD_BUILDINGS}
								exact
								component={Buildings}
							/>
							<Route path={URL.DASHBOARD_PRODUCT_HELp} exact component={Help} />
							<Route exact path={URL.DATA_SIM} component={DataSim} />

							<Route
								path={URL.DASHBOARD_AQ_PARAMETERS_EXPLAINED}
								component={AirQualityParameter}
							/>
							<Route
								path={URL.DASHBOARD}
								exact
								component={AnalyticsDashboard}
							/>
							<Route path={URL.CUSTOMER_ACCOUNT} exact component={AssetList} />
							<Route
								path={URL.CUSTOMER_ACCOUNT_UNASSIGNED}
								exact
								component={AssetList}
							/>
							<Route
								path={URL.FLOOR_PLAN_LIST}
								exact
								component={LocationList}
							/>
							<Route
								path={URL.CONFIGURE_DEVICE}
								exact
								component={ConfigureDevice}
							/>
							<Route
								path={`${URL.CONFIGURE_DEVICE}/:configureFrom`}
								exact
								component={ConfigureDevice}
							/>
							<Route
								path={URL.NEW_SCHEDULE}
								exact
								component={NewOrEditSchedule}
							/>
							<Route path={URL.SCHEDULE} exact component={ScheduleList} />
							<Route path={URL.AUTOPILOT} exact component={Autopilot} />
							<Route
								path={URL.CLUSTER_CONFIG}
								exact
								component={ClusterConfig}
							/>
							<Route
								path={URL.CHANGE_PASSWORD}
								exact
								component={ChangePassword}
							/>
							<Route exact path={URL.DASHBOARD_PROFILE} component={Profile} />
							<Route path={URL.IAQ_REPORTS} exact component={IaqReports} />

							<Route
								path={URL.MAINTENANCE_REPORTS}
								exact
								component={MaintenanceReports}
							/>
							<Route path={URL.CONSULTANTS} exact component={ConsultantList} />
							<Route
								path={URL.ADD_EDIT_CONSULTANT_REPORT}
								exact
								component={CreateConsultantReport}
							/>
							<Route path={URL.MARKET_PLACE} exact component={MarketPlace} />
							<Route
								path={`${URL.PRODUCT_DETAILS}/:product_id`}
								exact
								component={MarketPlaceProduct}
							/>
							<Route
								path={`${URL.MARKET_PLACE_PRODUCT_LIST}/:category_id`}
								exact
								component={ProductList}
							/>
							<Route
								path={URL.DEVICE_UTILIZATION_REPORTS}
								exact
								component={DeviceUtilizationReports}
							/>
							<Route
								path={URL.AUTOPILOT_REPORTS}
								exact
								component={AutopilotReports}
							/>
							<Route
								path={URL.DEVICE_MANAGEMENT}
								exact
								component={DeviceManagement}
							/>
							<Route
								path={URL.CONSULTATION_REPORTS}
								exact
								component={ConsultationReports}
							/>
							<Route
								path={URL.CUSTOM_REPORTS}
								exact
								component={CustomReports}
							/>
							<Route
								path={URL.SCHEDULE_REPORTS}
								exact
								component={ScheduleReports}
							/>
							<Route
								path={URL.ADDNEWBUILDING}
								exact
								component={AddNewBuldingForMobile}
							/>
							<Route
								path={URL.EDITLOCATION}
								exact
								component={AddNewLocationForMobile}
							/>
							<Route
								path={URL.ADDLOCATION}
								exact
								component={AddNewLocationForMobile}
							/>
							<Route
								path={`${URL.ADDLOCATION}/:asset_id/:building_id`}
								exact
								component={AddNewLocationForMobile}
							/>
							<Route
								path={URL.EDITNEWBUILDING}
								exact
								component={AddNewBuldingForMobile}
							/>
						</Switch>
					</div>
					{isWebView && <BottomNavBar />}
				</div>
			</div>
		</div>
	);
};

export default React.memo(Dashboard);
