import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function getStripeCustomerPortal(
	data: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"subscription/customer-portal",
		undefined,
		data
	);
}

export async function getSubscriptionList(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "account-subscription");
}

export async function getOfflineSubscriptionList(
	userId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `offline-subscription?userId=${userId}`);
}

export async function getInvoiceList(
	subscriptionId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		`account-subscription/invoices?subscriptionId=${subscriptionId}`
	);
}

export async function getCheckoutSession(data: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "subscription", undefined, data);
}

export async function getPaymentSummary(
	sessionKey: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "subscription/payment-summary", undefined, {
		sessionKey,
	});
}

export async function getSimCardPrices(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "subscription/prices");
}

export async function updateSubscription(data: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("PUT", "subscription", undefined, data);
}

export async function cancelSubscription(data: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", "subscription", undefined, data);
}

export async function reactivateSimSubscription(
	data: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "subscription/reactivate-sim", undefined, data);
}
