import React, { useEffect, useState } from "react";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import get from "lodash/get";

import Login from "./pages/Login/Login";
import Dashboard from "./pages/Routes/Routes";
import PrivateRoute from "./utils/PrivateRoute";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import RejectAdmin from "./pages/RejectAdmin/RejectAdmin";
import { theme } from "./theme/CustomTheme";
import Shared from "./common/Shared";
import { URL } from "./components/Navbar/routes";
import SigUp from "./pages/SignUp/SignUp";
import NormiSignUp from "./pages/NormiSignup/NormiSignup";
import { RootState } from "./redux/reducers";
import { SpinnerLoader } from "./common/Global.Style";
import { setupWebViewJavascriptBridge } from "./common/JsBridge";
import { setBuilding, setBuildings } from "./redux/actions/buildingActions";
import { setAssets, setAsset } from "./redux/actions/assetActions";
import { validateToken } from "./api/UserController";
import { setToken, setUser } from "./redux/actions/userActions";
import { UserRoles } from "./enums/UserRoles";
import {
	getContractorAssets,
	getAllAssetBuildings,
} from "./api/AssetController";

import "./MainStyle.scss";
import "./App.css";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import PaymentSetupRequired from "./pages/PaymentSetupRequired/PaymentSetupRequired";
import PaymentActionRequired from "./pages/PaymentActionRequired/PaymentActionRequired";
import PaymentSetupRoute from "./utils/PaymentSetupRoute";
import PaymentActionRequiredRoute from "./utils/PaymentActionRequiredRoute";

function App() {
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const history = useHistory();
	const dispatch = useDispatch();
	const params = new URLSearchParams(window.location.search);
	const queryTokken = params.get("token");
	const userData: any = useSelector((state: RootState) => state.user);

	useEffect(() => {
		document.title = Shared.title;
		const unAuthorizedRoutes = [URL.LOGIN, URL.SIGN_UP, URL.FORGOT_PASSWORD];
		if (unAuthorizedRoutes.includes(window.location.pathname)) {
			if (userData.token) {
				if (Shared.title === "IAQ Guard") {
					history.push(URL.DASHBOARD_BUILDINGS);
				} else if (userData.role === "contractor") {
					history.push(URL.DEVICE_MANAGEMENT);
				} else {
					history.push(URL.DASHBOARD);
				}
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
		}

		// JS bridge connect with native App
		setupWebViewJavascriptBridge(function (bridge: any) {
			if (/android/.test(navigator.userAgent.toLowerCase())) {
				bridge.init(function (message: any, responseCallback: any) {
					// 'JS got a message', message
					var data = {
						"Javascript Responds": "测试中文!",
					};

					if (responseCallback) {
						// 'JS responding with', data
						responseCallback(data);
					}
				});
			}
		});
	}, []);

	useEffect(() => {
		if (queryTokken) {
			updateUserData();
		}
	}, [queryTokken]);

	const updateUserData = async () => {
		setIsLoading(true);
		let isContractor = false;
		const response: any = await validateToken({ token: queryTokken });
		if (response.responseObj) {
			dispatch(setToken(queryTokken));
			dispatch(setUser(response.responseObj?.user));
			if (UserRoles.CONTRACTOR === response.responseObj?.user?.role) {
				isContractor = true;
				const resContractor: any = await getContractorAssets();
				if (resContractor.responseObj) {
					if (resContractor.responseObj?.length > 0) {
						dispatch(setAssets(resContractor?.responseObj));
						dispatch(setAsset(resContractor?.responseObj[0]));
					}

					if (get(resContractor, "responseObj[0].buildings.length", 0)) {
						dispatch(
							setBuildings(get(resContractor, "responseObj[0].buildings", []))
						);
						dispatch(
							setBuilding(get(resContractor, "responseObj[0].buildings[0]", {}))
						);
					}
				}
			} else {
				const res: any = await getAllAssetBuildings();
				if (res.responseObj?.length > 0) {
					res.responseObj.forEach((mapData: any) => {
						if (mapData.permissions.includes("write")) {
							isContractor = true;
						}
					});
					dispatch(setAssets(res.responseObj));
					dispatch(setAsset(res.responseObj[0]));
					if (res.responseObj[0].buildings?.length > 0) {
						dispatch(setBuildings(res.responseObj[0].buildings));
						dispatch(setBuilding(res.responseObj[0].buildings[0]));
					}
				}
			}

			// for iaq guard after login we need to show building page
			if (Shared.title === "IAQ Guard") {
				isContractor = false;
			}
			// history.push(
			// 	isContractor ? URL.DEVICE_MANAGEMENT : URL.DASHBOARD_BUILDINGS
			// );
			history.push(URL.DASHBOARD_PROFILE);
		} else {
			history.push(URL.LOGIN);
		}
		setIsLoading(false);
	};

	return (
		<ThemeProvider theme={theme}>
			{isLoading && <SpinnerLoader />}
			<Switch>
				<div className="sans-serif">
					<Route exact path="/">
						<Redirect to={URL.LOGIN} />
					</Route>
					<Route exact path={URL.LOGIN} component={Login} />
					<Route exact path={URL.SIGN_UP} component={SigUp} />
					<Route exact path={URL.NORMI_SIGN_UP} component={NormiSignUp} />
					<Route exact path={URL.RESET_PASSWORD} component={ResetPassword} />
					<Route exact path={URL.FORGOT_PASSWORD} component={ForgotPassword} />
					<Route exact path={URL.REJECT_ADMIN} component={RejectAdmin} />
					<PrivateRoute path={URL.DASHBOARD} component={Dashboard} />
				</div>
			</Switch>
		</ThemeProvider>
	);
}

export default App;
