import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "./AddEditSchedule.scoped.scss";
import * as Yup from "yup";
import { getRemediationDataList } from "../../../api/DevicesController";
import { addSchedule, updateSchedule } from "../../../api/SchedulerController";
import { ScheduleModal } from "../../../modals/device/Schedule";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import MySelect from "../../../components/MySelect/MySelect";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { URL } from "../../../components/Navbar/routes";
import { useDispatch, useSelector } from "react-redux";
import { getLocations } from "../../../redux/actions/locationActions";
import Select from "react-select";
import { RootState } from "../../../redux/reducers";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import { SpinnerLoader } from "../../../common/Global.Style";
import PaymentPendingInfo from "../../../components/PaymentPendingInfo/PaymentPendingInfo";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { MultiSelectWithCheckBox } from "../../../components/MultiSelectWithCheckbox/MultiSelectWithCheckbox";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

interface Item {
	label: string;
	value: string;
}

interface NewOrEditScheduleProps {
	show?: boolean;
	onHide?: (isCallFromSave: boolean) => void;
	scheduleDetail?: ScheduleModal;
}

interface DeviceDetails {
	_id: string;
	deviceName: string;
	location: string;
}

const NewOrEditSchedule: React.FC<NewOrEditScheduleProps> = ({
	show,
	onHide,
	scheduleDetail,
}) => {
	const history = useHistory();
	const building = useSelector((state: RootState) => state.building);
	const locations: any = useSelector((state: RootState) => state.locations);
	const [scheduleDetailState, setScheduleDetailState] = useState<ScheduleModal>(
		scheduleDetail ? scheduleDetail : new ScheduleModal()
	);

	const [assignableDeviceList, setAssignableDeviceList] = useState<Array<Item>>(
		[]
	);

	const [selectedDeviceList, setSelectedDeviceList] = useState<Array<Item>>([]);

	const [selectedLocationsList, setSelectedLocationsList] = useState<
		Array<Item>
	>([]);
	const [locationsList, setLocationsList] = useState<Array<Item>>([]);
	const [devicesList, setDevicesList] = useState<Array<Item>>([]);
	const [selectedInterval, setSelectedInterval] = useState<any>();
	const asset = useSelector((state: RootState) => state.asset);
	const user = useSelector((state: RootState) => state.user);

	const daysList = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];

	let hoursList = [
		{ value: 1, label: "1 hr" },
		{ value: 2, label: "2 hrs" },
		{ value: 3, label: "3 hrs" },
		{ value: 4, label: "4 hrs" },
		{ value: 5, label: "5 hrs" },
		{ value: 6, label: "6 hrs" },
		{ value: 7, label: "7 hrs" },
		{ value: 8, label: "8 hrs" },
		{ value: 9, label: "9 hrs" },
		{ value: 10, label: "10 hrs" },
		{ value: 11, label: "11 hrs" },
		{ value: 12, label: "12 hrs" },
		{ value: 13, label: "13 hrs" },
		{ value: 14, label: "14 hrs" },
		{ value: 15, label: "15 hrs" },
		{ value: 16, label: "16 hrs" },
		{ value: 17, label: "17 hrs" },
		{ value: 18, label: "18 hrs" },
		{ value: 19, label: "19 hrs" },
		{ value: 20, label: "20 hrs" },
		{ value: 21, label: "21 hrs" },
		{ value: 22, label: "22 hrs" },
		{ value: 23, label: "23 hrs" },
	];

	const isEditMode = () => {
		return scheduleDetail ? true : false;
	};

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const dispatch = useDispatch();

	useEffect(() => {
		let locationOptions: any = [];
		locations.map((item: any) => {
			locationOptions.push({ label: item.name, value: item._id });
		});
		setLocationsList(locationOptions);
		const scheduledLocations = locationOptions.filter((x: any) =>
			scheduleDetailState.location.includes(x.value)
		);
		setSelectedLocationsList(scheduledLocations);
	}, [locations]);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			dispatch(getLocations([], building._id ?? ""));
			getRemediationDataList(building._id)
				.then((response: any) => {
					if (
						response.responseObj.devices &&
						response.responseObj.devices.length > 0
					) {
						let optionsArray: Item[] = [];
						response?.responseObj?.devices.forEach((device: DeviceDetails) => {
							if (
								scheduleDetailState.location.some((item) =>
									device.location.includes(item)
								)
							) {
								optionsArray.push({
									value: device._id,
									label: device.deviceName,
								});
							}
						});
						setAssignableDeviceList(uniqBy(optionsArray, "value"));
						setDevicesList(response.responseObj.devices);
						if (isEditMode()) {
							let scheduleDevices: string[] = [];
							if (scheduleDetailState.devices?.length) {
								scheduleDetailState.devices?.map((item: any) => {
									scheduleDevices.push(item._id);
								});
							}
							const selectedDevice = optionsArray.filter((x: any) =>
								scheduleDevices.includes(x.value)
							);
							const selectedHour = hoursList.filter(
								(x: any) => scheduleDetailState.interval === x.value
							);
							setSelectedInterval(selectedHour[0]);
							setSelectedDeviceList(uniqBy(selectedDevice, "value"));
						}
					}
				})
				.catch((error) => {});
			return () => {
				isMounted = false;
			};
		}
	}, [building]);

	const onChangeDevice = (value: any) => {
		formIk.setFieldValue("devices", value);
		setSelectedDeviceList(value);
	};

	const onChangeLocationValue = (value: any[]) => {
		if (value.length && value[0]["value"] === "*") {
			value.shift();
		}
		let assignableDevices: any = [];
		value.map((item: any) => {
			devicesList.map((device: any) => {
				if (device.location.includes(item.value)) {
					assignableDevices.push({
						label: device["deviceName"],
						value: device["_id"],
					});
				}
			});
		});
		assignableDevices = uniqBy(assignableDevices, "value");
		formIk.setFieldValue("location", value);
		setSelectedLocationsList(value);
		setAssignableDeviceList(assignableDevices);
		formIk.setFieldValue("devices", []);
		setSelectedDeviceList([]);
	};

	const getSelectedDays = (daysEvent: any, daysIndex: number) => {
		const scheduleDetailTemp = { ...scheduleDetailState };
		if (
			scheduleDetailTemp.days.length === 0 ||
			!scheduleDetailTemp.days.includes(daysIndex)
		) {
			scheduleDetailTemp.days.push(daysIndex);
		} else {
			const index = scheduleDetailTemp.days.findIndex((x) => x === daysIndex);
			if (index !== -1) {
				scheduleDetailTemp.days.splice(index, 1);
			}
		}
		formIk.setFieldValue("days", scheduleDetailTemp["days"]);
		setScheduleDetailState(scheduleDetailTemp);
	};

	const formIk = useFormik({
		initialValues: {
			schedulerName: isEditMode() ? scheduleDetailState.name : "",
			startTime: isEditMode() ? scheduleDetailState.timeFrom : "",
			devices: isEditMode() ? scheduleDetailState.devices : [],
			location: isEditMode() ? scheduleDetailState.location : [],
			days: isEditMode() ? scheduleDetailState.days : [],
			interval: isEditMode() ? scheduleDetailState.interval : "",
		},
		validationSchema: Yup.object().shape({
			schedulerName: Yup.string().required("Name is required"),
			startTime: Yup.string().required("Start time is required"),
			days: Yup.array().min(1, "Please select days"),
			interval: Yup.string().required("Duration value is required"),
			location: Yup.array().min(1, "Please select location"),
			devices: Yup.array().min(1, "Please select devices"),
		}),
		onSubmit: async (values) => {
			await createSchedule({
				...values,
			});
		},
	});

	const updateTime = (value: string, propertyName: string) => {
		const scheduleDetailTemp = { ...scheduleDetailState };
		(scheduleDetailTemp as any)[propertyName] = value;
		setScheduleDetailState(scheduleDetailTemp);
	};

	const updateInterval = (value: any) => {
		const scheduleDetailTemp = { ...scheduleDetailState };
		(scheduleDetailTemp as any)["interval"] = value;
		const selectedHour = hoursList.filter(
			(x: any) => scheduleDetailTemp.interval === x.value
		);
		setSelectedInterval(selectedHour[0]);
		setScheduleDetailState(scheduleDetailTemp);
	};

	const createSchedule = async (value: any) => {
		if (checkRequiredField()) {
			const scheduleDetailReq = { ...scheduleDetailState };
			scheduleDetailReq.name = value.schedulerName;
			scheduleDetailReq.buildingId = building._id;

			if (isEditMode()) {
				scheduleDetailReq.location = [];
			}
			selectedLocationsList.map((item: any, index: number) => {
				scheduleDetailReq.location.push(item.value);
			});

			scheduleDetailReq.devices = selectedDeviceList?.map(
				(deviceData: any) => deviceData.value
			);

			if (!isEditMode()) {
				delete scheduleDetailReq._id;
				await callAddSchedule(scheduleDetailReq);
			} else {
				await callUpdateSchedule(scheduleDetailReq);
			}
		}
	};

	const callUpdateSchedule = async (scheduleDetailReq: ScheduleModal) => {
		setIsLoading(true);
		await updateSchedule(scheduleDetailReq)
			.then((response: any) => {
				setIsLoading(false);
				if (response.responseObj) {
					onHide && onHide(true);
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const callAddSchedule = async (scheduleDetailReq: ScheduleModal) => {
		setIsLoading(true);
		await addSchedule(scheduleDetailReq)
			.then((response: any) => {
				setIsLoading(false);
				if (response.responseObj) {
					history.push(URL.SCHEDULE);
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const checkRequiredField = () => {
		return (
			scheduleDetailState.location &&
			scheduleDetailState.timeFrom &&
			scheduleDetailState.interval
		);
	};

	const Option = (props: any) => {
		return (
			<div>
				<components.Option {...props}>
					<div className="d-flex w-100 justify-content-between">
						<div className="dropdown-checkbox">
							<input
								className="ml-auto"
								type="checkbox"
								checked={props.isSelected}
								onChange={() => null}
							/>{" "}
						</div>
						<div className="dropdown-lable">
							<label className="w-100 ml-0">{props.label}</label>
						</div>
					</div>
				</components.Option>
			</div>
		);
	};

	const MultiValue = (props: any) => (
		<components.MultiValue {...props}>
			<span>{props.data.label}</span>
		</components.MultiValue>
	);

	const animatedComponents = makeAnimated();

	const scheduleDetailContainer = (isNewSchedule: boolean) => {
		return (
			<div className={["container-fluid", isNewSchedule ? "" : ""].join(" ")}>
				<div className="row">
					<div className="col-md-12 col-lg-12 col-sm-12 md-p-0">
						<div
							className={[
								"row mt-3 mb-2  create-box bg-white md-m-0",
								isNewSchedule ? "pb-5" : "",
							].join(" ")}
						>
							<div
								className={[
									"col-lg-12 col-md-12 col-sm-12 md-p-3",
									isNewSchedule ? "p-3" : "p-0",
								].join(" ")}
							>
								<div
									className="d-flex cursor-pointer mb-3"
									onClick={() => history.goBack()}
								>
									<ArrowBackIosIcon fontSize="small" className="ml-2" />
									<p className="breadcrumb-para">Schedules</p>
								</div>
								{isNewSchedule && (
									<div className=" create-schedule-block md-m-t-3">
										<h5>Create New Schedule</h5>
										<p>Set an automated work schedule for devices</p>
									</div>
								)}
								<form onSubmit={formIk.handleSubmit} className="p-3 md-p-0">
									<div
										className={[
											"row bg-white",
											isNewSchedule ? "pb-5" : "",
										].join(" ")}
									>
										<div
											className={[
												isNewSchedule
													? "col-md-12 col-lg-7 mt-4 md-p-0 "
													: "col-md-12	 col-lg-10",
												"col-sm-12",
											].join("")}
										>
											{/* <div className="col-md-8 col-lg-7 col-sm-12 mt-4"> */}
											<div className="d-flex wrap-block">
												<div className="w-50 form-block">
													<div className="form-group ">
														<h6>Schedule Name</h6>
														<input
															id="schedulerName"
															name="schedulerName"
															type="text"
															className="create-input w-100 p-2 ml-1"
															onChange={formIk.handleChange}
															onBlur={formIk.handleBlur}
															value={formIk.values.schedulerName}
															maxLength={100}
															placeholder={"Schedule name"}
														/>
														{formIk.touched.schedulerName &&
														formIk.errors.schedulerName ? (
															<div className="text-danger">
																{formIk.errors.schedulerName}
															</div>
														) : null}
													</div>
												</div>
												<div className="ml-3 w-50 form-block">
													<h6>Select Locations</h6>
													<div className="dropdown w-100 pl-0 pr-0">
														<MultiSelectWithCheckBox
															seletedOptions={locationsList}
															selectValue={selectedLocationsList}
															onSelectionChange={onChangeLocationValue}
														/>
													</div>
													{formIk.touched.location && formIk.errors.location ? (
														<div className="text-danger">
															{formIk.errors.location}
														</div>
													) : null}
												</div>
											</div>

											<div className="d-flex wrap-block mt-4">
												<div className="w-50 form-block">
													<div className="form-group ">
														<h6>Start Time</h6>
														<input
															type="time"
															name="startTime"
															className="create-input w-100 p-2 ml-1"
															placeholder="input"
															value={scheduleDetailState.timeFrom}
															onChange={(event) => {
																formIk.setFieldValue(
																	"startTime",
																	get(event.target, "value", "")
																);
																updateTime(event.target.value, "timeFrom");
															}}
														/>
														{formIk.touched.startTime &&
														formIk.errors.startTime ? (
															<div className="text-danger">
																{formIk.errors.startTime}
															</div>
														) : null}
													</div>
												</div>
												<div className="ml-3 w-50 form-block">
													<div className="form-group ">
														<h6>Select Hours</h6>
														<div className="dropdown w-100  pl-0 pr-0">
															<Select
																options={hoursList}
																placeholder={"Select hours"}
																value={selectedInterval}
																onChange={(val) => {
																	formIk.setFieldValue("interval", val?.value);
																	updateInterval(val?.value);
																}}
																name="interval"
															/>
														</div>
														{formIk.touched.interval &&
														formIk.errors.interval ? (
															<div className="text-danger">
																{formIk.errors.interval}
															</div>
														) : null}
														{/* <CustomTimePicker
													label="End Time"
													time={new Date()}
													onTimeChanged={() => {}}
												/> */}
													</div>
												</div>
											</div>

											<div className="w-100 mt-4">
												<h6 className="mt-4">Add Devices</h6>
												<div className="dropdown w-100 pl-0 pr-0">
													<MySelect
														components={{
															Option,
															MultiValue,
															animatedComponents,
														}}
														isMulti
														hideSelectedOptions={false}
														closeMenuOnSelect={false}
														onChange={onChangeDevice}
														options={assignableDeviceList}
														allowSelectAll={false}
														value={selectedDeviceList}
														name="devices"
													/>
												</div>
												{formIk.touched.devices && formIk.errors.devices ? (
													<div className="text-danger">
														{formIk.errors.devices}
													</div>
												) : null}
											</div>

											<div className="w-100 mt-4">
												<h6 className="mt-4">Select Days</h6>
												<div className="d-flex mt-3 flex-wrap">
													{daysList.map(
														(singleBuilding: any, index: number) => {
															return (
																<>
																	<div
																		key={singleBuilding}
																		className="d-flex sa bg-white mr-2 align-items-center pt-2 pb-2 mb-2 day-select"
																	>
																		<input
																			className="max-w-30"
																			type="checkbox"
																			onChange={(e) => {
																				getSelectedDays(e, index);
																			}}
																			value={singleBuilding}
																			checked={scheduleDetailState.days.includes(
																				index
																			)}
																		/>
																		<label className="m-0">
																			{singleBuilding}
																		</label>
																	</div>
																</>
															);
														}
													)}
												</div>
												{formIk.touched.days && formIk.errors.days ? (
													<div className="text-danger">
														{formIk.errors.days}
													</div>
												) : null}
											</div>
											{isNewSchedule ? (
												<div className="w-75 mt-4">
													<button
														type="submit"
														className="btn btn create-btn w-50 mt-4"
													>
														Save
													</button>
												</div>
											) : (
												<div className="d-flex mt-4 justify-content-end">
													<button
														type="button"
														className="btn mt-4  pl-5 pr-5"
														onClick={() => {
															onHide && onHide(false);
														}}
													>
														Cancel
													</button>
													<button
														className="btn create-btn w-50 mt-4"
														type="submit"
													>
														Save
													</button>
												</div>
											)}
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{isLoading && <SpinnerLoader />}
			</div>
		);
	};

	const editScheduleDetail = () => {
		return (
			<>
				<Modal
					show={show}
					onHide={() => onHide && onHide(false)}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="schedule-edit-modal"
					// dialogClassName="my-modal"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							<h5
								className="mt-3 cursor-pointer"
								onClick={() => {
									onHide && onHide(false);
								}}
							>
								<span className="back-button">
									<ChevronLeftIcon />
								</span>
								Edit Schedule
							</h5>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>{scheduleDetailContainer(false)}</Modal.Body>
				</Modal>
			</>
		);
	};

	const checkAsset = () => {
		return !asset.payPending;
	};

	if (!checkAsset()) {
		return <PaymentPendingInfo name={asset.name} />;
	}

	return (
		<>{!isEditMode() ? scheduleDetailContainer(true) : editScheduleDetail()}</>
	);
};
export default NewOrEditSchedule;
