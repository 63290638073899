import { createSelector } from "reselect";

const getLocations = (state: any) => state.locations;

export const locationMapSelector = createSelector(
	[getLocations],
	(locations) => {
		return locations.map((location: any) => {
			return { value: location._id, label: location.name };
		});
	}
);
