import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import get from "lodash/get";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { SpinnerLoader } from "../../common/Global.Style";
import { customStyles } from "../CreateAsset/AddUserForm";
import { validationErrorMessage } from "../../utils/errMessages";

import "./EditUser.scss";

interface EditUserProps {
	showDrawer: boolean;
	setShowDrawer: (value: boolean) => void;
	userData: {
		contactNumber?: string;
		email?: string;
		firstName?: string;
		lastName?: string;
		permissions?: string[];
	};
	updateAssetUserData: (value: any) => void;
}

export default function EditUser({
	showDrawer,
	setShowDrawer,
	userData,
	updateAssetUserData,
}: EditUserProps) {
	const [loading, setIsLoading] = useState<boolean>(false);

	const validationSchema = Yup.object().shape({
		firstName: Yup.string().trim().required("First Name is required"),
		lastName: Yup.string().trim().required("Last Name is required"),
		contactNumber: Yup.string()
			.matches(/^(\d{10}(,(?=.))?)+$/, validationErrorMessage.CONTACT)
			.required("Phone Number is required"),
		email: Yup.string().required("Email is required"),
	});

	const formik = useFormik({
		initialValues: {
			firstName: get(userData, "firstName", ""),
			lastName: get(userData, "lastName", ""),
			contactNumber: get(userData, "contactNumber", ""),
			email: get(userData, "email"),
			permissions: get(userData, "write", false)
				? {
						label: "Admin",
						value: ["read", "write"],
				  }
				: { label: "Viewer", value: ["read"] },
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			setIsLoading(true);
			const payload = values;
			if (payload?.permissions?.value?.length) {
				payload.permissions = payload?.permissions?.value;
			}
			await updateAssetUserData(payload);
			setIsLoading(false);
		},
	});

	return (
		<React.Fragment>
			<Drawer
				anchor="right"
				open={showDrawer}
				className="customer-drawer"
				onClose={() => setShowDrawer(false)}
			>
				<div className="drawer-content-section">
					<div className="drawer-header">
						<div className="drawer-header-title d-flex justify-content-between">
							<h6 className="drawer-header-heading">Edit User</h6>
							<CloseIcon
								className="cursor-pointer m-d-none"
								onClick={() => setShowDrawer(false)}
							/>
							<ArrowBackIosIcon
								className="i-18 txt-primary cursor-pointer d-none m-d-block mr-2"
								onClick={() => setShowDrawer(false)}
							/>
						</div>
					</div>
					{loading && <SpinnerLoader />}
					<FormikProvider value={formik}>
						<form
							className="d-flex flex-column justify-content-between edit-user-account p-3"
							onSubmit={formik.handleSubmit}
						>
							<div>
								<div className="row">
									<div className="col-md-6 col-lg-6 col-sm-6">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-tertiary">
												First Name
											</label>
											<input
												id="firstName"
												name="firstName"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.firstName}
												onChange={formik.handleChange}
												maxLength={100}
											/>
											{formik.touched.firstName && formik.errors.firstName ? (
												<div className="text-danger">
													{formik.errors.firstName}
												</div>
											) : null}
										</div>
									</div>
									<div className="col-md-6 col-lg-6 col-sm-6">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-tertiary">
												Last Name
											</label>
											<input
												id="lastName"
												name="lastName"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.lastName}
												onChange={formik.handleChange}
												maxLength={100}
											/>
											{formik.touched.lastName && formik.errors.lastName ? (
												<div className="text-danger">
													{formik.errors.lastName}
												</div>
											) : null}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6 col-lg-6 col-sm-6">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-tertiary">
												Email ID
											</label>
											<input
												id="email"
												disabled
												name="email"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.email}
												onChange={formik.handleChange}
												maxLength={100}
											/>
											{formik.touched.email && formik.errors.email ? (
												<div className="text-danger">{formik.errors.email}</div>
											) : null}
										</div>
									</div>
									<div className="col-md-6 col-lg-6 col-sm-6">
										<label className="desc-normal font-500 font-tertiary">
											Permissions
										</label>
										<Select
											id="color"
											styles={customStyles}
											options={[
												{ label: "Viewer", value: ["read"] },
												{
													label: "Admin",
													value: ["read", "write"],
												},
											]}
											defaultValue={formik.values.permissions}
											onChange={(data: any) =>
												formik.setFieldValue(`permissions`, data.value)
											}
										/>
										{formik.touched.permissions && formik.errors.permissions ? (
											<div className="text-danger">
												{formik.errors.permissions}
											</div>
										) : null}
									</div>
								</div>
								<div className="row">
									<div className="col-md-6 col-lg-6 col-sm-6">
										<div className="w-100 form-group">
											<label className="desc-normal font-500 font-tertiary">
												Phone Number
											</label>
											<input
												id="contactNumber"
												name="contactNumber"
												type="text"
												className="add-re-input form-control pt-2 pb-2"
												value={formik.values.contactNumber}
												onChange={formik.handleChange}
												maxLength={100}
											/>
											{formik.touched.contactNumber &&
											formik.errors.contactNumber ? (
												<div className="text-danger">
													{formik.errors.contactNumber}
												</div>
											) : null}
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-end">
								<button
									onClick={() => setShowDrawer(false)}
									className="btn btn-medium btn-accent"
								>
									Cancel
								</button>
								<button
									className="btn btn-medium primary-btn ml-2"
									type="submit"
								>
									Update
								</button>
							</div>
						</form>
					</FormikProvider>
				</div>
			</Drawer>
		</React.Fragment>
	);
}
