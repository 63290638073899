import React from "react";
import { useHistory } from "react-router-dom";
// import Input from "@material-ui/core/Input";
// import SearchOutlined from "@material-ui/icons/SearchOutlined";
// import InputAdornment from "@material-ui/core/InputAdornment";

import { URL } from "../../components/Navbar/routes";

import "./Help.scoped.scss";

const Help: React.FC = () => {
	const history = useHistory();

	return (
		<div className="px-3">
			<h6 className=" mb-4 mt-4 heading">Quick Help</h6>
			{/* <div>
				<Input
					startAdornment={
						<InputAdornment position="start">
							<SearchOutlined />
						</InputAdornment>
					}
				/>
			</div> */}
			<div className="row">
				<div className="col-md-8">
					<div className="card">
						<h6 className="desc-exlarge font-700 txt-black">
							Air Quality Parameters Explained.
						</h6>
						<p className="mb-2">
							Indoor Air Quality (IAQ) refers to the air quality within and
							around buildings and structures, especially as it relates to the
							health and comfort of building occupants. Understanding and
							controlling common pollutants indoors can help reduce your risk of
							indoor health concerns.
						</p>
						<p
							onClick={() =>
								history.push(URL.DASHBOARD_AQ_PARAMETERS_EXPLAINED)
							}
							className="link font-600 cursor-pointer mb-0"
						>
							Read more
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Help;
