import { useEffect, useState } from "react";
import CanvasJSReact from "../../../lib/CanvasJs/canvasjs.stock.react";
import moment from "moment-timezone";
const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

export const CanvasChatIAQ = ({
	title,
	graphData,
	dimension,
	timeZone,
}: any) => {
	const [containerProps] = useState({
		width: "100%",
		height: "250px",
		margin: "auto",
	});

	const generateDataPoints = () => {
		const groupDataValues = [];
		for (const locations of Object.keys(graphData)) {
			let dps: any = [];
			if (graphData[locations][dimension]) {
				for (const data of graphData[locations][dimension]) {
					dps.push({
						x: new Date(
							moment(data.date).tz(timeZone).format("YYYY-MM-DDTHH:mm:ss")
						),
						y: data.value,
						markerSize: 1,
					});
				}
			}

			groupDataValues.push({
				showInLegend: true,
				type: "spline",
				dataPoints: dps,
				name: locations,
				xValueFormatString: "MMM DD YYYY HH:mm:ss",
				xValueType: "dateTime",
				yValueFormatString: "###0.##",
			});
		}
		return groupDataValues;
	};

	const optionsValues = () => {
		const dataPoins = generateDataPoints();
		const siderData = dataPoins.map((mapData) => ({
			dataPoints: mapData.dataPoints,
			type: "splineArea",
			showInLegend: false,
		}));
		return {
			title: {
				text: title,
				fontSize: 16,
				fontFamily: "'Inter', sans-serif",
			},
			animationEnabled: true,
			exportEnabled: false,
			charts: [
				{
					axisX: {
						crosshair: {
							enabled: true,
							snapToDataPoint: true,
							valueFormatString: "MMM DD, YYYY HH:mm:ss",
						},
					},
					axisY: {
						crosshair: {
							enabled: true,
							snapToDataPoint: true,
						},
						margin: 10,
						valueFormatString: "####",
					},
					toolTip: {
						shared: true,
					},
					legend: {
						fontSize: 15,
					},
					data: dataPoins,
				},
			],
			rangeSelector: {
				enabled: false,
			},
			navigator: {
				data: siderData,
				axisX: {
					labelAngle: -1,
				},
			},
		};
	};

	let options = optionsValues();

	useEffect(() => {
		options = optionsValues();
	}, [graphData]);
	return (
		<>
			{options?.charts[0]?.data?.length && (
				<CanvasJSStockChart containerProps={containerProps} options={options} />
			)}
		</>
	);
};
