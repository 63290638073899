import React, { memo } from "react";
import { Dropdown } from "react-bootstrap";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";

import "./DeviceManagement.scoped.scss";
interface IDevice {
	onEdit: (deviceData: any) => void;
	onDelete: (id: string) => void;
	deviceData: any;
	openControll?: (data: any) => void;
}

const Device: React.FC<IDevice> = ({
	onEdit,
	onDelete,
	deviceData,
	openControll,
}) => {
	const renderStatus = () => {
		switch (deviceData.deviceType) {
			case "monitor":
			case "gateway":
				return (
					<p
						className={`${
							deviceData.online ? "text-success" : "text-danger"
						} desc-small font-primary mb-0`}
					>
						<FiberManualRecordIcon className="i-18 mr-1" />
						{deviceData.online ? "Online" : "Offline"}
					</p>
				);

			case "interops":
			case "remediation":
				return (
					<>
						{deviceData?.online && (
							<div>
								<p
									className={`${
										deviceData.status ? "text-success" : "text-danger"
									} desc-small font-primary mb-0`}
								>
									<FiberManualRecordIcon className="i-18 mr-1" />
									{deviceData.status ? "ON" : "OFF"}
								</p>
							</div>
						)}
						{!deviceData?.online && (
							<div>
								<p className="text-secondary desc-small font-secondary mb-0">
									<FiberManualRecordIcon className="i-18 mr-1" />
									{"Offline"}
								</p>
							</div>
						)}
					</>
				);
		}
	};

	return (
		<div className="device-management-content p-2 mt-3">
			<div className="d-flex">
				<div className="d-flex align-items-center flex-1">
					<div className="user-image-section">
						<img src={deviceData?.image} alt="" />
					</div>
					<div className="desc-normal font-weight-bold mb-0 ml-2">
						{deviceData?.deviceName}
					</div>
				</div>

				<Dropdown className="">
					<Dropdown.Toggle
						variant="Primary"
						id="dropdown-basic"
						bsPrefix="dropdown-button"
					>
						<MoreHorizIcon />
					</Dropdown.Toggle>
					<Dropdown.Menu style={{ width: "inherit" }}>
						<Dropdown.Item
							className="simple cursor-disabled text-break"
							onClick={() => onEdit(deviceData)}
						>
							<EditIcon className="edit-icon i-20 mr-1" /> Edit
						</Dropdown.Item>
						<Dropdown.Item
							className="simple cursor-disabled text-break"
							onClick={() => onDelete(deviceData)}
						>
							<DeleteIcon className="edit-icon i-20 mr-1" /> Delete
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
			<div>
				<div className="mt-2">
					<p className="desc-exsmall font-tertiary font-weight-normal mb-1">
						DEVICE ID
					</p>
					<p className="desc-small font-primary mb-0">{deviceData.deviceId}</p>
				</div>
				<div className="d-flex my-3">
					<div className="flex-1">
						<p className="desc-exsmall font-tertiary font-weight-normal mb-1">
							DEVICE TYPE
						</p>
						<p className="desc-small font-primary mb-0">
							{deviceData.deviceType}
						</p>
					</div>
					<div className="flex-1">
						<p className="desc-exsmall font-tertiary font-weight-normal mb-1">
							DEVICE DESCRIPTION
						</p>
						<p className="desc-small font-primary mb-0">
							{deviceData.description}
						</p>
					</div>
				</div>
				<div className="d-flex">
					<div className="flex-1">
						<p className="desc-exsmall font-tertiary font-weight-normal mb-1">
							DEVICE Status
						</p>
						{renderStatus()}
					</div>
					{["remediation", "interops"].includes(deviceData.deviceType) &&
						openControll && (
							<div className="flex-1">
								<p className="desc-exsmall font-tertiary font-weight-normal mb-1">
									Control
								</p>

								<SettingsIcon
									onClick={() => {
										openControll(deviceData);
									}}
									className="edit-icon"
								/>
							</div>
						)}
				</div>
			</div>
		</div>
	);
};

export default memo(Device);
