import { Button } from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { getMarketPlaceProductById } from "../../api/MarketPlaceController";
import { SpinnerLoader } from "../../common/Global.Style";

import "./ProductDetails.scoped.scss";

const MarketPlaceProductDetails = () => {
	const params: any = useParams();
	const history = useHistory();
	const { product_id } = params;

	const [productData, setProductData] = useState({});
	const [selectedImage, setSelectedImage] = useState("");
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (product_id) {
			getProductDataById(product_id);
		}
	}, [product_id]);

	const getProductDataById = async (id: string) => {
		setIsLoading(true);
		const response = await getMarketPlaceProductById(id);
		setIsLoading(false);
		if (response.responseObj) {
			setProductData(response.responseObj);
			setSelectedImage(get(response, "responseObj.images[0]", ""));
		}
	};

	return (
		<div className="right-panel-content">
			{isLoading && <SpinnerLoader />}
			<div className="details-container">
				<div className="d-flex cursor-pointer" onClick={() => history.goBack()}>
					<ArrowBackIosIcon fontSize="small" />
					<p className="breadcrumb-para">IAQ Store</p>
				</div>
				{!isLoading && (
					<div className="product-details-block">
						<div className="product-thumbnail">
							{get(productData, "images", [])?.map((mapData: string) => (
								<div
									onClick={() => setSelectedImage(mapData)}
									className="thumb-img active"
								>
									<img src={mapData} alt="images" />
								</div>
							))}
						</div>
						<div className="product-img">
							<img src={selectedImage} alt="product" />
						</div>

						<div className="product-details">
							<p className="brandname">{get(productData, "brand")}</p>
							<h3 className="productname">{get(productData, "name")}</h3>
							{get(productData, "isFeatured") && (
								<span className="recomented-label">Featured</span>
							)}

							<p className="product-description">
								{get(productData, "description")}
							</p>

							{!isEmpty(get(productData, "colorList", [])) && (
								<>
									<p className="color-header">Available colors</p>
									<div className="color-pallete d-flex align-items-center">
										{get(productData, "colorList", []).map(
											(mapData: string) => (
												<div className="color">
													<div
														style={{ backgroundColor: mapData }}
														className="fill-color"
													></div>
												</div>
											)
										)}
									</div>
								</>
							)}

							<div className="chekout-button-block">
								<Button
									onClick={() => window.open(get(productData, "webUrl"))}
									className="check-button btn btn-primary-outline"
								>
									<StoreIcon />
									Check in Store
								</Button>
							</div>

							<div className="product-specs">
								<h4 className="product-header">Product Specification</h4>
								<p className="detail">{get(productData, "specificaion")}</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default MarketPlaceProductDetails;
