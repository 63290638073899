import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

let defaultTheme = createTheme();

let getMuiTheme = () =>
	createTheme({
		overrides: {
			MUIDataTableToolbar: {
				root: {
					"@media print": {
						display: "block !important",
					},
				},
				[defaultTheme.breakpoints.down("xs")]: {
					root: {
						"@media print": {
							display: "block !important",
						},
					},
				},
				actions: {
					"@media print": {
						display: "none !important",
					},
				},
			},
		},
	});
interface CustomDataTableProps {
	title: string;
	rows: any;
	columns: any;
	options: any;
	components?: any;
}

const CustomDataTable: React.FC<CustomDataTableProps> = ({
	title,
	rows,
	columns,
	options,
	components,
}) => {
	return (
		<MuiThemeProvider theme={getMuiTheme()}>
			<MUIDataTable
				title={title}
				data={rows}
				columns={columns}
				components={components}
				options={options}
			/>
		</MuiThemeProvider>
	);
};

export default CustomDataTable;
