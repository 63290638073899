import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function addSchedule(
	scheduleDetails: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "schedules", undefined, scheduleDetails);
}

export async function getScheduleList(
	buildingId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "schedules?buildingId=" + buildingId);
}

export async function deleteSchedule(id: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", "schedules/" + id);
}

export async function updateSchedule(
	scheduleDetails: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		`schedules/${scheduleDetails._id}`,
		undefined,
		scheduleDetails
	);
}
