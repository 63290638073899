export class ScheduleModal {
	public name: string;
	public timeFrom: string;
	public timeTo: string;
	public days: number[];
	public devices?: string[];
	public location: string[];
	public _id?: string;
	public interval?: number;
	public buildingId?: string;

	public constructor() {
		this.name = "";
		this.timeFrom = "";
		this.timeTo = "";
		this.days = [];
		this.devices = [];
		this.location = [];
		this._id = "";
		this.interval = 0;
		this.buildingId = "";
	}
}
