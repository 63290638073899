import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import isEmpty from "lodash/isEmpty";

import { SpinnerLoader } from "../../common/Global.Style";
import { AutopilotTable } from "../../components/V2Autopilot/AutopilotTable";
import {
	checkCurrentlyRunningAutopilotStatus,
	getUserDimension,
	masterToggle,
	resetUserDimension,
	syncDimensions,
	updateUserDimension,
} from "../../api/clusterConfigController";
import { URL } from "../../components/Navbar/routes";
import { RootState } from "../../redux/reducers";
import { EventEmitter } from "../../common/EventEmitter";
import { AutopilotDrawer } from "../../components/V2Autopilot/AutopilotDrawer";

import "./ClusterConfig.scoped.scss";
import { Dimension } from "../../common/Interface";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import SetupAlerts from "../../components/AssetAlertSetup/SetupAlertDialog";
import { toast } from "react-toastify";
import { AntSwitch } from "../../components/V2Autopilot/CommonSwitch";
import SystemTour from "../../components/SystemTour/SystemTour";
import { UserRoles } from "../../enums/UserRoles";

declare global {
	interface Number {
		between: (min: number, max: number) => boolean;
	}
}

const Autopilot: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [userDimensionList, setUserDimensionList] = useState<Array<any>>([]);
	const [isMasterToggleOn, setIsMasterToggleOn] = useState<boolean>(false);
	const [drawerEl, setAnchorEl] = useState<any>(false);
	const [editDrawerData, setEditDrawerData] = useState<any>({});
	const [drawerLoading, setDrawerLoading] = useState<boolean>(false);
	const asset = useSelector((state: RootState) => state.asset);
	const user = useSelector((state: RootState) => state.user);
	const initialDimensionValue = {
		_id: "",
		enable: false,
		name: "",
		shortName: "",
		good: [],
		moderate: [],
		poor: [],
		veryPoor: [],
		danger: [],
		unit: "",
		asset: "",
		emailAlertThreshold: 0,
		phoneAlertThreshold: 0,
		updatedAt: "",
		user: "",
	};
	const [editConfirmationMessage, setEditConfirmationMessage] =
		useState<string>("");
	const [isShownEditConfirmDialog, setIsShownEditConfirmDialog] =
		useState<boolean>(false);
	const [isShownToggleConfirmDialog, setIsShownToggleConfirmDialog] =
		useState<boolean>(false);
	const [userDimensionData, setUserDimensionData] = useState<Dimension>(
		initialDimensionValue
	);
	const [isAdmin, setIsAdminData] = useState<Boolean>(false);
	const [showEditAsset, setShowEditAsset] = useState<boolean>(false);
	const [processedDimData, setprocessdDimData] = useState<any>([]);
	const [showSyncDeviceConfirmation, setShowSyncDeviceConfirmation] =
		useState<boolean>(false);
	const [showResetConfrimation, setShowResetConfrimation] =
		useState<boolean>(false);
	const [tourSteps] = useState<any>([
		{
			selector: ".sync-device-tour",
			content: `Click here to update the current Autofix trigger point to all active monitors in the customer account. `,
		},
		{
			selector: ".reset-button-tour",
			content: `Click here to reset the air quality dimensions to their default values`,
		},
		{
			selector: ".setup-alerts-button-tour",
			content: `Click here to set Phone numbers and Email ids to receive alerts for the Air quality threshold breaches you have set for the customer account.`,
		},
		{
			selector: ".view-logs-tour",
			content: `Click here to view all the Autofix actions taken for the day.`,
		},
		{
			selector: ".master-toggle-tour",
			content: `Use the toggle button to enable/disable Autofix for the selected customer account.`,
		},
		{
			selector: ".dimension-toggle-tour",
			content: `Use the toggle button to enable/disable Autofix for the selected air quality dimension`,
		},
		{
			selector: ".dimension-edit-tour",
			content:
				"Click on the Edit toggle to Edit the autofix dimensions and alert threshold values.",
		},
	]);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			getUserDimensionData(asset._id);
			return () => {
				isMounted = false;
			};
		}
	}, []);

	useEffect(() => {
		const processedData: any = [];

		if (userDimensionList.length) {
			userDimensionList?.forEach((mapData: any) => {
				const currentDimData: any = [];
				mapData?.danger?.forEach((item: any) => {
					if (item?.end && (item?.start || item?.start == 0)) {
						currentDimData.push({ ...item, type: `danger` });
					}
				});
				mapData?.good?.forEach((item: any) => {
					if (item?.end && (item?.start || item?.start == 0)) {
						currentDimData.push({ ...item, type: `good` });
					}
				});
				mapData?.moderate?.forEach((item: any) => {
					if (item?.end && (item?.start || item?.start == 0)) {
						currentDimData.push({ ...item, type: `moderate` });
					}
				});
				mapData?.poor?.forEach((item: any) => {
					if (item?.end && (item?.start || item?.start == 0)) {
						currentDimData.push({ ...item, type: `poor` });
					}
				});
				mapData?.veryPoor?.forEach((item: any) => {
					if (item?.end && (item?.start || item?.start == 0)) {
						currentDimData.push({ ...item, type: `veryPoor` });
					}
				});
				const {
					enable,
					emailAlertThreshold,
					emailAlertMinThreshold,
					code,
					phoneAlertThreshold,
					phoneAlertMinThreshold,
					shortName,
					unit,
					_id,
				} = mapData;
				currentDimData.sort(compare);
				processedData.push({
					code,
					emailAlertThreshold,
					emailAlertMinThreshold,
					enable,
					shortName,
					phoneAlertThreshold,
					phoneAlertMinThreshold,
					unit,
					currentDimData,
					_id,
				});
			});
			setprocessdDimData(processedData);
		}
	}, [userDimensionList]);

	const callAPI = (event: any) => {
		getUserDimensionData(event._id);
	};
	useEffect(() => {
		EventEmitter.subscribe("assetChange", (event) => callAPI(event));
		return () => EventEmitter.unsubscribe("assetChange");
	}, []);

	const getUserDimensionData = (assetId: any) => {
		setIsLoading(true);
		getUserDimension(assetId)
			.then((response: any) => {
				if (response && response.responseObj.dimensions.length > 0) {
					setUserDimensionList(response.responseObj.dimensions);
					setIsMasterToggleOn(response.responseObj?.masterToggle);
				}
				setIsLoading(false);
			})
			.catch((error: any) => {
				console.log(error);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (user.role === UserRoles.CONTRACTOR) {
			setIsAdminData(true);
		} else {
			const assetData = user?.assets?.find(
				(mapData: any) => mapData._id === asset._id
			);
			setIsAdminData(
				assetData?.permissions?.includes("write") &&
					user?.addedBy !== process.env.REACT_APP_NORMI_PROVIDER_ID
			);
		}
	}, [asset]);

	// eslint-disable-next-line no-extend-native
	Number.prototype.between = function (a, b): boolean {
		var min = Math.min.apply(Math, [a, b]),
			max = Math.max.apply(Math, [a, b]);
		return this >= min && this <= max;
	};

	const toggleDrawer = (data: any) => {
		setAnchorEl(true);
		setEditDrawerData(cloneDeep(data));
	};

	const onCloseDrawer = () => {
		setEditDrawerData({});
		setAnchorEl(false);
	};

	const editDimension = async (data: any, id: string) => {
		await updateUserDimension(id, data);
		const response = await getUserDimension(asset._id);
		if (response && response.responseObj.dimensions.length > 0) {
			setUserDimensionList(response.responseObj.dimensions);
		}
	};

	const masterToggleAction = async (data: any) => {
		await masterToggle(asset?._id, data);
		await getUserDimensionData(asset?._id);
	};

	const checkIfAutoPilotRunning = async (
		dimensionId: any = undefined,
		data: any
	) => {
		setIsLoading(true);
		const response = await checkCurrentlyRunningAutopilotStatus(
			dimensionId,
			asset._id
		);
		if (response.responseObj && response.responseObj.isAutoPilotRunning) {
			dimensionId.length
				? setIsShownEditConfirmDialog(true)
				: setIsShownToggleConfirmDialog(true);
			console.log(isShownToggleConfirmDialog);
			setEditConfirmationMessage(
				"There is an active auto remediation in progress, do you want to cancel it? It will turn off the remediation device."
			);
		} else if (dimensionId) {
			await editDimension(data, dimensionId);
			setIsLoading(false);
		} else {
			await masterToggleAction(data);
			setIsLoading(false);
		}
	};

	const onStatusChange = async (data: any, id: string) => {
		setIsLoading(true);
		setUserDimensionData({ ...data, _id: id });
		if (data.hasOwnProperty("enable") && !data.enable) {
			await checkIfAutoPilotRunning(id, data);
		} else {
			await editDimension(data, id);
		}
		setIsLoading(false);
	};

	const onDrawerDataSave = async (data: any, id: string) => {
		setDrawerLoading(true);
		setUserDimensionData({ ...data, _id: id });
		if (!data.enable) {
			await checkIfAutoPilotRunning(id, data);
		}
		await editDimension(data, id);
		setDrawerLoading(false);
		onCloseDrawer();
	};

	const confirmEdit = async (masterToggle: boolean) => {
		setIsLoading(true);

		if (masterToggle) {
			setIsShownEditConfirmDialog(false);
			await masterToggleAction({ enable: !isMasterToggleOn });
		} else {
			setIsShownToggleConfirmDialog(false);
			await editDimension(userDimensionData, userDimensionData._id);
		}
		setIsLoading(false);
	};

	const dialogCloseClick = () => {
		setIsShownEditConfirmDialog(false);
		setIsShownToggleConfirmDialog(false);
		setShowSyncDeviceConfirmation(false);
		setShowResetConfrimation(false);
	};

	const openModal = async () => {
		setShowEditAsset(true);
	};

	const closeEditModal = () => {
		setShowEditAsset(false);
	};

	const resetDimension = async () => {
		setIsLoading(true);
		const response = await resetUserDimension(asset._id);
		setShowResetConfrimation(false);
		if (response?.responseObj) {
			setIsLoading(false);
			toast.success(response?.responseObj?.message);
		} else {
			toast.error(response?.error);
			setIsLoading(true);
		}
		getUserDimensionData(asset._id);
	};

	const syncDimensionsWithDevice = async () => {
		try {
			setIsLoading(true);
			const response = await syncDimensions(asset._id);
			if (response?.responseObj) {
				setIsLoading(false);
				getUserDimensionData(asset._id);
				toast.success(response.responseObj.message);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	function compare(a: any, b: any) {
		if (a.end < b.end) {
			return -1;
		}
		if (a.end > b.end) {
			return 1;
		}
		return 0;
	}

	const onToggleMaster = async (enable: boolean) => {
		setIsLoading(true);
		if (!enable) {
			await checkIfAutoPilotRunning("", { enable });
		} else {
			await masterToggleAction({ enable });
		}
		setIsLoading(false);
	};

	return (
		<div className="container-fluid">
			{/* <SystemTour tourSteps={tourSteps} position={0} /> */}
			<div className="row mt-3 bg-white mb-3">
				<div className="col-lg-12 col-md-12 col-sm-12 autopilot p-3">
					<div className="d-flex flex-wrap justify-content-between">
						{isAdmin && (
							<div className="d-flex m-mb-2">
								<button
									className="btn auto-pilot-buttons log-btn p-2 d-flex align-items-center m-mr-2 sync-device-tour"
									onClick={() => setShowSyncDeviceConfirmation(true)}
								>
									Sync Devices
								</button>

								<button
									className="btn log-btn auto-pilot-buttons p-2 d-flex align-items-center reset-button-tour"
									onClick={() => setShowResetConfrimation(true)}
								>
									Reset
								</button>
							</div>
						)}
						<div className="d-flex autopilot-alert">
							<div className="d-flex">
								{isAdmin && (
									<button
										className="btn log-btn auto-pilot-buttons p-2 d-flex align-items-center m-mr-2 setup-alerts-button-tour"
										onClick={openModal}
									>
										Setup Alerts
									</button>
								)}

								<Link to={URL.AUTOPILOT} className="d-block m-d-none">
									<button className="btn log-btn auto-pilot-buttons p-2 d-flex align-items-center justify-content-center view-logs-tour">
										<DeviceHubIcon />
										Logs
									</button>
								</Link>

								{user.role === UserRoles.ADMIN &&
									user?.addedBy !== process.env.REACT_APP_NORMI_PROVIDER_ID && (
										<Link
											to={URL.AUTOPILOT_REPORTS}
											className="d-none m-d-block"
										>
											<button
												type="button"
												className="btn log-btn auto-pilot-buttons p-2 d-flex align-items-center justify-content-center view-logs-tour"
											>
												<DeviceHubIcon />
												Logs
											</button>
										</Link>
									)}
							</div>

							{isAdmin && (
								<div className="d-flex">
									<p className="mr-2 mt-3">
										Autofix {isMasterToggleOn ? `Enabled` : `Disabled`}
									</p>
									<AntSwitch
										className="mr-4 mt-3 master-toggle-tour"
										checked={isMasterToggleOn}
										onClick={() => onToggleMaster(!isMasterToggleOn)}
										name="checkedC"
									/>
								</div>
							)}
						</div>
					</div>
					<AutopilotDrawer
						onClose={onCloseDrawer}
						open={drawerEl}
						drawerData={editDrawerData}
						onDrawerDataSave={onDrawerDataSave}
						onDrawerDataChange={setEditDrawerData}
						isLoading={drawerLoading}
					/>
					<p className="desc-normal">
						**Triggers Autofix when an anomaly is observed for more than 5
						minutes. The Autofix trigger point is calculated as mid-value of the
						moderate range.
					</p>
					<div className="mt-3">
						<AutopilotTable
							toggleDrawer={toggleDrawer}
							onStatusChange={onStatusChange}
							setUserDimensionList={setUserDimensionList}
							processedDimData={processedDimData}
						/>
						{isShownEditConfirmDialog && (
							<ConfirmDialog
								dialogTitle="Edit"
								message={editConfirmationMessage}
								positiveBtnLabel="Edit"
								negativeBtnLabel="Cancel"
								onPositiveBtnClicked={() => confirmEdit(false)}
								onNegativeBtnClicked={() => dialogCloseClick()}
								onCloseIconClicked={() => dialogCloseClick()}
							/>
						)}
						{showResetConfrimation && (
							<ConfirmDialog
								dialogTitle="Reset"
								message="This action will reset all the dimensions to default values. Click on reset to confirm."
								positiveBtnLabel="Reset"
								negativeBtnLabel="Cancel"
								onPositiveBtnClicked={() => resetDimension()}
								onNegativeBtnClicked={() => dialogCloseClick()}
								onCloseIconClicked={() => dialogCloseClick()}
							/>
						)}
						{showSyncDeviceConfirmation && (
							<ConfirmDialog
								dialogTitle="Sync Dimensions with Monitor"
								message="This action will sync the Current Autofix threshold values to all the active monitors. Press Sync to confirm."
								positiveBtnLabel="Sync"
								negativeBtnLabel="Cancel"
								onPositiveBtnClicked={() => syncDimensionsWithDevice()}
								onNegativeBtnClicked={() => dialogCloseClick()}
								onCloseIconClicked={() => dialogCloseClick()}
							/>
						)}
						{isLoading && <SpinnerLoader />}
						{showEditAsset && (
							<SetupAlerts show={showEditAsset} onHide={closeEditModal} />
						)}
					</div>
				</div>
				{!isEmpty(processedDimData) && (
					<div className="align-content-center d-flex indication justify-content-center w-100">
						<div className="indication-card">
							<div className="d-flex mt-3 ml-3">
								<div className="d-flex color-indication">
									<div className="color-card good" />
									<p className=""> Good </p>
								</div>
								<div className="d-flex color-indication">
									<div className="color-card moderate" />
									<p className=""> Moderate </p>
								</div>
							</div>
							<div className="d-flex ml-3">
								<div className="d-flex color-indication">
									<div className="color-card poor" />
									<p className=""> Poor </p>
								</div>
								<div className="d-flex color-indication">
									<div className="color-card veryPoor" />
									<p className=""> Very Poor </p>
								</div>
							</div>
							<div className="d-flex ml-3">
								<div className="d-flex color-indication">
									<div className="color-card danger" />
									<p className=""> Hazardous </p>
								</div>
							</div>
							<div className="d-flex ml-3">
								<div className="d-flex">
									<div className="autopilot-ata mr-2" />
									<p className=""> AutoFix Trigger Point indication </p>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			{isShownToggleConfirmDialog && (
				<ConfirmDialog
					dialogTitle={isMasterToggleOn ? `Disable Autofix` : `Enable Autofix`}
					message={editConfirmationMessage}
					positiveBtnLabel="Confirm"
					negativeBtnLabel="Cancel"
					onPositiveBtnClicked={() => confirmEdit(true)}
					onNegativeBtnClicked={() => dialogCloseClick()}
					onCloseIconClicked={() => dialogCloseClick()}
				/>
			)}
		</div>
	);
};
export default Autopilot;
