import React from "react";
import { Button, Modal } from "react-bootstrap";

interface IPasswordModal {
	open: boolean;
	handleClose: () => void;
	resetLink: string;
}

export const PasswordModal: React.FC<IPasswordModal> = ({
	open,
	handleClose,
	resetLink,
}) => {
	return (
		<Modal show={open} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Reset Password</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<a
						href={resetLink}
						rel="noreferrer"
						className="link"
						target="_blank"
						style={{ wordBreak: "break-all" }}
					>
						{resetLink}
					</a>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
