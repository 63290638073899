import { SvgIcon } from "@material-ui/core";
import { memo } from "react";

const Pm1 = (props) => {
	return (
		<SvgIcon
			width="41"
			height="23"
			viewBox="0 0 41 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M34.5403 22.5952H1.58334C0.703707 22.5952 0 21.8915 0 21.0118C0 20.1322 0.703707 19.4285 1.58334 19.4285H34.5403C36.1823 19.4285 37.5311 18.0797 37.5311 16.4377C37.5311 14.7957 36.1823 13.447 34.5403 13.447C32.8983 13.447 31.5496 14.7957 31.5496 16.4377C31.5496 17.3174 30.8459 18.0211 29.9662 18.0211C29.0866 18.0211 28.3829 17.3174 28.3829 16.4377C28.3829 13.0365 31.1391 10.2803 34.5403 10.2803C37.9416 10.2803 40.6978 13.0365 40.6978 16.4377C40.6978 19.8976 37.9416 22.5952 34.5403 22.5952Z"
				fill={props.color}
			/>
			<circle
				cx="20.679"
				cy="14.4497"
				r="2.08335"
				transform="rotate(-75 20.679 14.4497)"
				fill={props.color}
			/>
			<circle
				cx="22.8362"
				cy="6.3999"
				r="2.08335"
				transform="rotate(-75 22.8362 6.3999)"
				fill={props.color}
			/>
			<circle
				cx="15.3128"
				cy="13.0112"
				r="2.08335"
				transform="rotate(-75 15.3128 13.0112)"
				fill={props.color}
			/>
			<circle
				cx="17.47"
				cy="4.9624"
				r="2.08335"
				transform="rotate(-75 17.47 4.9624)"
				fill={props.color}
			/>
			<circle
				cx="9.94707"
				cy="11.5737"
				r="2.08335"
				transform="rotate(-75 9.94707 11.5737)"
				fill={props.color}
			/>
			<circle
				cx="12.1038"
				cy="3.52392"
				r="2.08335"
				transform="rotate(-75 12.1038 3.52392)"
				fill={props.color}
			/>
		</SvgIcon>
	);
};

export default memo(Pm1);
