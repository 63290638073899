import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import get from "lodash/get";
import Tooltip from "@material-ui/core/Tooltip";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";

import { ConsultationReport } from "../../types/ConsultationReport";
import { RootState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import { MultiSelectWithCheckBox } from "../MultiSelectWithCheckbox/MultiSelectWithCheckbox";
import { SpinnerLoader } from "../../common/Global.Style";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
	createConsultation,
	updateConsultationReport,
} from "../../api/ReportsController";
import { uploadConsultationReport } from "../../api/commonController";

import "./ConsultationModal.scoped.scss";
interface Item {
	label: string;
	value: string;
	isSeleted?: boolean;
}

interface ConsultationModalProps {
	show: boolean;
	onHide: (isCallFromSave: boolean) => void;
	consultation: ConsultationReport;
	dimensions: any;
}

const ConsultationModal: React.FC<ConsultationModalProps> = (
	props: ConsultationModalProps
) => {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [selectedLocations, setSelectedLocations] = useState<any>();
	const [selectedAQIParams, setSelectedAQIParams] = useState<any>();

	const building = useSelector((state: RootState) => state.building);
	const locations = useSelector((state: RootState) => state.locations);

	const isEditMode = () => {
		return !!props.consultation?._id;
	};

	const prepareLocationList = (locations: any) => {
		if (!locations) {
			return [];
		}

		const locationOptions = [];
		for (const location of locations) {
			locationOptions.push({
				value: location["_id"],
				label: location["name"],
			});
		}
		return locationOptions;
	};

	const prepareAqiParams = (aqiParams: any) => {
		if (!aqiParams) {
			return [];
		}

		const params = [];
		for (const param of aqiParams) {
			params.push({
				value: param.code,
				label: param.shortName,
			});
		}
		return params;
	};

	const prepareAQIParamFromString = (params: string[]) => {
		const aqiParams: any = [];
		params.forEach((param: string) => {
			const dimension = props.dimensions.find((p: any) => p.code === param);
			aqiParams.push({ label: dimension.shortName, value: dimension.code });
		});
		return aqiParams;
	};

	const saveDetails = async (value: any) => {
		if (!value) {
			return;
		}

		const data = {
			cause: value.cause,
			solution: value.solution,
			aqiParam: value.aqiParam.map((aqi: Item) => aqi.value),
			location: value.location.map((loc: Item) => loc.value),
			building: building._id,
			reportUrl: value.reportUrl,
		};

		setIsLoading(true);

		try {
			if (isEditMode()) {
				await updateConsultationReport(props.consultation._id, data);
			} else {
				await createConsultation(data);
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}

		props.onHide(true);
	};

	const onChangeLocation = (value: any) => {
		setSelectedLocations(value);
		formik.values.location = value;
	};

	const onChangeAQIParam = (value: any) => {
		setSelectedAQIParams(value);
		formik.values.aqiParam = value;
	};

	useEffect(() => {
		setSelectedLocations(prepareLocationList(props.consultation.locations));

		const params: any = [];
		props.consultation.aqiParam.forEach((param: string) => {
			const dimension = props.dimensions.find((p: any) => p.code === param);
			params.push({ label: dimension.shortName, value: dimension.code });
		});
		setSelectedAQIParams(params);
	}, []);

	const formik = useFormik({
		initialValues: {
			cause: isEditMode() ? props.consultation.cause : "",
			solution: isEditMode() ? props.consultation.solution : "",
			aqiParam: isEditMode()
				? prepareAQIParamFromString(props.consultation.aqiParam)
				: [],
			location: isEditMode()
				? prepareLocationList(props.consultation.locations)
				: [],
			reportUrl: get(props, "consultation.reportUrl", ""),
		},
		validationSchema: Yup.object({
			reportUrl: Yup.string().required("Report is required"),
			aqiParam: Yup.array()
				.min(1, "At least single dimension required")
				.of(Yup.mixed<Item>().required()),
			location: Yup.array()
				.min(1, "At least single location required")
				.of(Yup.mixed<any>().required()),
		}),
		onSubmit: (values: any) => {
			saveDetails(values);
		},
	});

	const onFileChange = async (event: any) => {
		const formData = new FormData();
		formData.append("file", event.target.files[0], event.target.files[0].name);
		setIsLoading(true);
		const res = await uploadConsultationReport(formData);
		setIsLoading(false);
		if (res?.responseObj?.Location) {
			formik.setFieldTouched("reportUrl");
			formik.setFieldValue("reportUrl", res?.responseObj?.Location);
		}
	};

	return (
		<Modal
			show={props.show}
			onHide={() => props.onHide(false)}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter pr-0"
			dialogClassName="modal-custom"
			centered
			className="consultation-modal"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<h5 className="cursor-pointer" onClick={() => props.onHide(false)}>
						<span className="back-button">
							<ChevronLeftIcon />
						</span>
						{isEditMode() ? "Edit" : "Add"} Consultation Report
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container-fluid">
					<FormikProvider value={formik}>
						<form onSubmit={formik.handleSubmit}>
							<div className="row">
								<div className="col-md-12 col-lg-12 col-sm-12">
									<h6 className="mt-4">Location</h6>
									<div className="dropdown w-100  pl-0 pr-0">
										<MultiSelectWithCheckBox
											seletedOptions={prepareLocationList(locations)}
											selectValue={selectedLocations}
											onSelectionChange={onChangeLocation}
										/>
										{formik.touched.location && formik.errors.location ? (
											<div className="text-danger">
												{formik.errors.location}
											</div>
										) : null}
									</div>

									<h6 className="mt-4">Dimensions</h6>
									<div className="dropdown w-100  pl-0 pr-0">
										<MultiSelectWithCheckBox
											seletedOptions={prepareAqiParams(props.dimensions)}
											selectValue={selectedAQIParams}
											onSelectionChange={onChangeAQIParam}
										/>
										{formik.touched.aqiParam && formik.errors.aqiParam ? (
											<div className="text-danger">
												{formik.errors.aqiParam}
											</div>
										) : null}
									</div>

									<div className="d-flex mt-4">
										<div className="dropdown w-50 form-group">
											<h6>Cause</h6>
											<div className="dropdown w-100 pl-0 pr-0">
												<textarea
													className="add-re-input bg-white form-control pt-2 pb-2"
													placeholder=""
													name="cause"
													id="cause"
													value={formik.values.cause}
													onChange={formik.handleChange}
													maxLength={500}
													autoComplete="off"
												/>
												{formik.touched.cause && formik.errors.cause ? (
													<div className="text-danger">
														{formik.errors.cause}
													</div>
												) : null}
											</div>
										</div>
									</div>

									<div className="d-flex mt-4">
										<div className="dropdown w-50 form-group">
											<h6>Solution</h6>
											<div className="dropdown w-100 pl-0 pr-0">
												<textarea
													className="add-re-input bg-white form-control pt-2 pb-2"
													placeholder=""
													name="solution"
													id="solution"
													value={formik.values.solution}
													onChange={formik.handleChange}
													maxLength={500}
													autoComplete="off"
												/>
												{formik.touched.solution && formik.errors.solution ? (
													<div className="text-danger">
														{formik.errors.solution}
													</div>
												) : null}
											</div>
										</div>
									</div>

									<div className="mt-3">
										<h6>Upload consltation report</h6>
										<Button
											type="button"
											startIcon={<CloudUploadIcon />}
											className="btn p-3"
										>
											<label
												htmlFor="contained-button-file"
												className="m-0 w-100"
											>
												<input
													accept="application/pdf"
													style={{ display: "none" }}
													id="contained-button-file"
													multiple
													type="file"
													onChange={onFileChange}
												/>
												Upload
											</label>
										</Button>
										{formik.values.reportUrl && (
											<Tooltip title="View Report" placement="top">
												<p
													onClick={() => window.open(formik.values.reportUrl)}
													className="link mt-2 cursor-pointer text-break"
												>
													{formik.values.reportUrl}
												</p>
											</Tooltip>
										)}
										{formik.touched.reportUrl && formik.errors.reportUrl ? (
											<div className="text-danger desc-small">
												{formik.errors.reportUrl}
											</div>
										) : null}
									</div>

									<div className="d-flex mt-5 button-blocks">
										<button
											type="button"
											className="btn mopbtnc pl-5 pr-5"
											onClick={() => props.onHide(false)}
										>
											Cancel
										</button>
										<button type="submit" className="btn add pl-5 pr-5">
											{isEditMode() ? "Update" : "Add"} Consultation
										</button>
									</div>
								</div>
							</div>
						</form>
					</FormikProvider>
				</div>
			</Modal.Body>
			{isLoading && <SpinnerLoader />}
		</Modal>
	);
};
export default ConsultationModal;
