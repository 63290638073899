import { Input, Typography } from "@material-ui/core";
import { useState } from "react";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

interface Props {
	name: string;
	updateName: (anyThing: any) => void;
	imageList: {
		name: string;
		imageUrl: string;
		isEditing: boolean;
		_id: string;
	}[];
	isEditing: boolean;
	index: number;
	onEditName: (data: any) => void;
	deleteFloor: any;
	canWriteAsset: boolean;
}
export const FloorPlanName = ({
	name,
	updateName,
	imageList,
	isEditing,
	index,
	onEditName: onEditName,
	deleteFloor,
	canWriteAsset,
}: Props) => {
	const [inputError, setInputError] = useState("");

	const onEditIconClick = () => {
		let tempData = [...imageList];
		tempData[index] = { ...tempData[index], isEditing: true };
		updateName(tempData);
	};
	const onSave = () => {
		if (name.trim()) {
			let tempData = [...imageList];
			tempData[index] = { ...tempData[index], isEditing: false };
			updateName(tempData);
			onEditName({ name: tempData[index].name, id: tempData[index]._id });
		} else {
			setInputError("Name is required");
		}
	};
	const onNameChange = (event: any) => {
		if (event.target.value) {
			setInputError("");
		}
		let tempData = [...imageList];
		tempData[index] = { ...tempData[index], name: event.target.value };
		updateName(tempData);
	};
	const onDeleteFloor = () => {
		deleteFloor(imageList[index]._id);
	};

	return (
		<div style={{ width: "100%", maxWidth: "670px" }}>
			{isEditing ? (
				<div className="align-items-center d-flex mt-3 justify-content-center">
					<div>
						<Input defaultValue={name} onChange={onNameChange} />
						{inputError && (
							<p className="mb-0 mt-2 text-danger">{inputError}</p>
						)}
					</div>
					<IconButton onClick={onSave} className="ml-3" aria-label="check">
						<Check />
					</IconButton>
				</div>
			) : (
				<div className="align-items-center d-flex mt-3 justify-content-center">
					<Typography className="font-weight-normal">{name}</Typography>
					{canWriteAsset && (
						<>
							<IconButton
								onClick={onEditIconClick}
								className="ml-3"
								aria-label="edit"
							>
								<Edit />
							</IconButton>
							<IconButton
								onClick={onDeleteFloor}
								className="ml-3"
								aria-label="delete"
							>
								<DeleteIcon />
							</IconButton>
						</>
					)}
				</div>
			)}
		</div>
	);
};
