import { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { changePassword } from "../../api/UserController";
import { SpinnerLoader } from "../../common/Global.Style";

import "./ChangePassword.scoped.scss";

const ChangePassword = () => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const handleClickShowOldPassword = () => {
		setShowOldPassword(!showOldPassword);
	};

	const onPasswordChange = async (values: any) => {
		setIsLoading(true);
		const response = await changePassword(values);
		setIsLoading(false);
		if (response.error) {
			toast.error(
				response.error === "admin password is same with the old"
					? "The password is same as recent password, try another password"
					: response.error
			);
		} else {
			toast.success(response.responseObj.message);
			formik.resetForm();
		}
	};

	const formik = useFormik({
		initialValues: {
			password: "",
			newPassword: "",
			confirmPassword: "",
		},
		validationSchema: Yup.object({
			password: Yup.string().trim().required("Current password is required"),
			newPassword: Yup.string()
				.trim()
				.required("New password is required")
				.test(
					"match",
					"New Password cannot be same as Current password.",
					function (newPassword) {
						return newPassword !== this.parent.password;
					}
				)
				.test(
					"len",
					"The password must be 6–16 characters in length",
					function (newPassword: any) {
						return newPassword?.length > 5 && newPassword?.length < 17;
					}
				)
				.test(
					"security-check",
					"The password should contain at least two of the following types: numbers, uppercase letters, lowercase letters, and underscores.",
					function (newPassword: any) {
						let isFound = [];
						if (/\d/.test(newPassword)) {
							isFound.push(true);
						}
						if (/[a-z]/.test(newPassword)) {
							isFound.push(true);
						}

						if (/[A-Z]/.test(newPassword)) {
							isFound.push(true);
						}

						if (newPassword && newPassword.includes("_")) {
							isFound.push(true);
						}
						return isFound.length >= 2;
					}
				),
			confirmPassword: Yup.string()
				.trim()
				.required("Confirm password is required")
				.oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
		}),
		onSubmit: (values) => {
			onPasswordChange(values);
		},
	});

	return (
		<div className="container-fluid change-password-block">
			<div className="row">
				<div className="col-md-12 col-lg-10 col-sm-12">
					<div className="row mt-3 pl-3 pr-3 pt-3 h-100 pb-5 mb-2">
						<div className="col-md-12 col-lg-12 col-sm-12 profile-component bg-white p-4">
							<div className="mb-3">
								<h5 className="pl-3">Change Password</h5>
							</div>
							<form onSubmit={formik.handleSubmit}>
								<div className="row">
									<div className="form-group col-md-12 col-lg-6 col-sm-12">
										<label className="pass mb-2">Current Password</label>
										<div className="password">
											<input
												type={showOldPassword ? "text" : "password"}
												id="password"
												name="password"
												className="bg-light border-0"
												placeholder=""
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.password}
											/>
											<IconButton
												className="icon"
												aria-label="toggle password visibility"
												onClick={handleClickShowOldPassword}
												onMouseDown={handleMouseDownPassword}
											>
												{showOldPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</div>
										{formik.touched.password && formik.errors.password ? (
											<div className="text-danger">
												{formik.errors.password}
											</div>
										) : null}
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-12 col-lg-6 col-sm-12">
										<label className="pass mb-2">New Password</label>
										<div className="password">
											<input
												type={showPassword ? "text" : "password"}
												id="newPassword"
												name="newPassword"
												className="bg-light border-0"
												placeholder=""
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.newPassword}
											/>
											<IconButton
												className="icon"
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</div>
										{formik.touched.newPassword && formik.errors.newPassword ? (
											<div className="text-danger">
												{formik.errors.newPassword}
											</div>
										) : null}
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-12 col-lg-6 col-sm-12">
										<label className="pass mb-2">Confirm Password</label>
										<div className="password">
											<input
												type={showConfirmPassword ? "text" : "password"}
												id="confirmPassword"
												name="confirmPassword"
												className="bg-light border-0"
												placeholder=""
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.confirmPassword}
											/>
											<IconButton
												className="icon"
												aria-label="toggle password visibility"
												onClick={handleClickShowConfirmPassword}
												onMouseDown={handleMouseDownPassword}
											>
												{showConfirmPassword ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										</div>
										{formik.touched.confirmPassword &&
										formik.errors.confirmPassword ? (
											<div className="text-danger">
												{formik.errors.confirmPassword}
											</div>
										) : null}
									</div>
								</div>
								<div className="col-md-12 col-lg-6 col-sm-12">
									<div className="d-flex justify-content-center mt-1">
										<button type="submit" className="btn btn-submit">
											Change password
										</button>
									</div>
								</div>
							</form>
							{isLoading && <SpinnerLoader />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
