import React from "react";

import MobileBack from "../../../../components/MobileBack";
import { Button } from "@material-ui/core";

import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import FavoriteIcon from "@material-ui/icons/Favorite";

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import machine from "../../../../images/machine.svg";

import "../style.scoped.scss";

interface ViewConsultantProps {
	closePopUp: any;
	viewConsultantData: any;
	addToFavourite: any;
	assignAsset: any;
}

const ViewConsultant: React.FC<ViewConsultantProps> = ({
	closePopUp,
	viewConsultantData,
	addToFavourite,
	assignAsset,
}) => {
	return (
		<div className="w-100 consultant-section modal-consultant-user">
			<div className="modal-header modal-border d-none m-d-flex align-items-center justify-content-between bg-white p-12 px-3">
				<p className="desc-exlarge font-text-1 lh-normal ls-024 mb-0">
					Consultant
				</p>
				<div>
					<CloseOutlinedIcon
						className="i-16 font-text-1"
						onClick={() => closePopUp()}
					/>
				</div>
			</div>
			<div className="p-4 bg-white">
				<div className="modal-user-img rounded-circle">
					<img
						className="h-100 w-100 rounded-circle"
						src={viewConsultantData.avatar}
						alt="machine"
					/>
				</div>
				<p className="mb-0 mt-3 desc-xlarge fpnt-700 lh-24 ls-024 font-text-4">
					{viewConsultantData.firstName} {viewConsultantData.lastName}
				</p>
				<div className="d-flex align-items-center flex-wrap c-g-4 r-g-4 mt-2">
					{viewConsultantData?.consultantCertification?.map(
						(certification: any) => {
							return (
								<p className="desc-normal font-text-2 ls-024 lh-16 mb-0 mt-2">
									{certification}
								</p>
							);
						}
					)}
				</div>
				<div className="d-flex align-items-center flex-wrap c-g-4 r-g-4 mt-2">
					{viewConsultantData?.consultantType?.map((type: any) => {
						return (
							<p className="mb-0 desc-small ls-024 lh-16 px-2 radius-4 bg-grey-light-5 font-text-2 w-fit">
								{type?.split(" ")[0]}
							</p>
						);
					})}
				</div>
				<div className="py-4">
					<div className="d-flex align-items-center mb-16">
						<PhoneIcon className="font-text-2 i-20 mr-1" />
						<p className="mb-0 font-grey-light-4">
							{viewConsultantData.contactNumber}
						</p>
					</div>
					<div className="d-flex align-items-center mb-16">
						<MailOutlineIcon className="font-text-2 i-20 mr-1" />
						<p className="mb-0 font-grey-light-4">{viewConsultantData.email}</p>
					</div>
				</div>
				<div className="d-flex c-g-16">
					<a
						className="btn btn-primary-outline flex-1"
						href={`tel:${viewConsultantData.contactNumber}`}
					>
						Call Consultant
					</a>
					<Button
						className={
							viewConsultantData?.favorite[0]?.isFavourite
								? "btn btn-icon icon-default-btn like-btn active"
								: "btn btn-icon icon-default-btn like-btn"
						}
					>
						<FavoriteIcon
							onClick={() => addToFavourite(viewConsultantData._id)}
						/>
					</Button>
				</div>
				<p className="mb-0 mt-4 desc-normal font-grey-light-4 ls-024 lh-20">
					{viewConsultantData.description}
				</p>
				<Button
					className="btn w-100 mt-5"
					disabled={viewConsultantData.isAssetAssignedAlready}
					onClick={() => assignAsset(viewConsultantData.email)}
				>
					{viewConsultantData.isAssetAssignedAlready
						? "Already Hired"
						: "Hire Consultant"}
				</Button>
			</div>
		</div>
	);
};
export default ViewConsultant;
