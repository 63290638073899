import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import copy from "copy-to-clipboard";

import {
	getProfileDetails,
	getSignatureUrl,
	updateProfile,
	updateUserAvtar,
	uploadProfileImage,
} from "../../api/UserController";

import { SpinnerLoader } from "../../common/Global.Style";
import { setUser } from "../../redux/actions/userActions";

import "./Profile.scoped.scss";
import { getStripeCustomerPortal } from "../../api/SubscriptionController";
import { RootState } from "../../redux/reducers";
import { UserRoles } from "../../enums/UserRoles";
import { NavLink } from "react-router-dom";
import { URL } from "../../components/Navbar/routes";

interface Profile {
	firstName: string;
	lastName: string;
	email: string;
	contactNumber: string;
	country: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	alertEmail: any;
	alertContact: any;
	providerId: string;
}

const Profile: React.FC = (props) => {
	const imageUploader = React.useRef<HTMLInputElement>(null);
	const [profileData, setProfileData] = useState<Profile>({
		firstName: "",
		lastName: "",
		email: "",
		contactNumber: "",
		country: "",
		address: "",
		city: "",
		state: "",
		zip: "",
		alertEmail: "",
		alertContact: "",
		providerId: "",
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [imageUrl, setImageUrl] = useState<any>("/images/userpro.svg");
	const [alertEmailIds, setAlertEmailIds] = useState<any>();
	const [alertContact, setAlertContact] = useState<any>();
	const [signedImageUrl, setSignedImageUrl] = useState<any>();
	const user = useSelector((state: RootState) => state.user);
	const [countryOption] = useState<Array<{ value: string; label: string }>>([
		{
			value: "United States",
			label: "United States",
		},
	]);
	const [showCopySuccesIcon, setShowCopySuccesIcon] = useState(false);

	const colourStyles = {
		control: (styles: any) => ({
			...styles,
			borderRadius: "10px",
			backgroundColor: "#f8f9fa",
			height: "55px",
			border: "0 !important",
			boxShadow: "0 !important",
			"&:hover": {
				border: "0 !important",
			},
		}),
	};

	const dispatch = useDispatch();

	const profileSchema = Yup.object({
		firstName: Yup.string().trim().required("First name is required"),
		lastName: Yup.string().trim().required("Last name is required"),
		email: Yup.string()
			.email("Invalid email address")
			.required("Email is required"),
		contactNumber: Yup.string(),
		country: Yup.string(),
		address: Yup.string().required("Address is required"),
		city: Yup.string().trim().required("City is required"),
		state: Yup.string().trim().required("State is required"),
		zip: Yup.string().trim().required("Zipcode is required"),
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: profileData,
		validationSchema: profileSchema,
		onSubmit: (values) => {
			updateProfileDetails(values);
		},
	});

	const updateProfileDetails = async (values: object) => {
		setIsLoading(true);
		const obj: any = values;
		obj.alertContact = alertContact;
		obj.alertEmail = alertEmailIds;
		obj.avatar = signedImageUrl;
		const response = await updateProfile(obj);
		setIsLoading(false);
		if (response.responseObj) {
			toast.success(response.responseObj.success);
			dispatch(setUser(response.responseObj.user));
		} else if (response.error) {
			toast.error(response.error);
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			setIsLoading(true);
			getProfileDetails()
				.then((response: any) => {
					setIsLoading(false);
					if (response.responseObj) {
						setUserProfile(response.responseObj);
					}
				})
				.catch((error) => {
					setIsLoading(false);
				});
			return () => {
				isMounted = false;
			};
		}
	}, []);

	const setUserProfile = (profileDetails: any) => {
		const initialValues = {
			firstName: profileDetails.firstName || "",
			lastName: profileDetails.lastName || "",
			email: profileDetails.email || "",
			contactNumber: profileDetails.contactNumber || "",
			country: profileDetails.country || "",
			address: profileDetails.address || "",
			city: profileDetails.city || "",
			state: profileDetails.state || "",
			zip: profileDetails.zip || "",
			avatar: profileDetails.avatar || "",
			alertEmail: setAlertEmailIds(profileDetails.alertEmail),
			alertContact: setAlertContact(profileDetails.alertContact),
		};
		setImageUrl(profileDetails.avatar);
		setProfileData({
			...initialValues,
			providerId: profileDetails?.providerId,
		});
	};

	const handleImageUpload = (e: any) => {
		setIsLoading(true);
		const [file] = e.target.files;

		if (file) {
			const formData = new FormData();
			formData.append("avatar", file);
			const reader = new FileReader();
			let imgBlob: any;
			reader.onload = (e) => {
				if (e.target!.result) {
					setImageUrl(e?.target?.result);
					imgBlob = e?.target?.result;
				}
			};
			reader.readAsDataURL(file);
			getSignatureUrl({ name: file.name, type: file.type }).then(
				async (response: any) => {
					setSignedImageUrl(response.responseObj.signedUrl.split("?")[0]);
					await updateUserAvtar(
						response.responseObj.signedUrl,
						e.target.files[0]
					).then((response: any) => {
						setIsLoading(false);
						if (response.responseObj) {
							dispatch(setUser(response.responseObj.user));
						}
					});
				}
			);
		}
	};

	const onChooseImage = async (event: any) => {
		setIsLoading(true);
		const apiBody = new FormData();
		const [file] = event.target.files;
		apiBody.append("file", file);

		const response = await uploadProfileImage(apiBody);
		if (response?.error) {
			toast.error(response?.error);
		} else if (response?.responseObj?.user) {
			setImageUrl(response?.responseObj?.user?.avatar);
			dispatch(setUser(response.responseObj.user));
		}
		setIsLoading(false);
	};

	const gotoCustomerPortal = async () => {
		try {
			setIsLoading(true);
			const returnUrl = window.location.href;
			const response = await getStripeCustomerPortal({
				returnUrl,
				userId: user._id,
			});
			if (response && response?.responseObj && response.responseObj?.session) {
				window.open(response.responseObj.session.url, "_self");
			}
		} catch (err) {
			setIsLoading(false);
			console.log(err);
		}
	};

	const copyTextToClipboard = async () => {
		setShowCopySuccesIcon(true);
		setTimeout(() => {
			setShowCopySuccesIcon(false);
		}, 5000);
		copy(profileData?.providerId);
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-10 col-lg-10 col-sm-10">
					<div className="row mt-3 pl-3 pr-3 pt-3 h-100 pb-5 mb-2">
						<div className="col-md-12 col-lg-12 col-sm-12 profile-component bg-white p-4">
							<div className="row p-3">
								<h5 className="mt-2">Customer Profile</h5>
							</div>
							<form onSubmit={formik.handleSubmit}>
								<div className="row mt-3">
									<div className="col-md-2 col-lg-2 col-sm-2">
										<img
											src={imageUrl}
											alt="user Profile"
											className="cursor-pointer"
											onClick={() => imageUploader.current!.click()}
										/>
										<input
											type="file"
											id="user-img"
											accept="image/*"
											onClick={(event: any) => {
												event.target.value = null;
											}}
											onChange={onChooseImage}
											ref={imageUploader}
											multiple={false}
											hidden
										/>
									</div>
									<div style={{ marginTop: "auto", fontSize: "13px" }}>
										<p className="mb-0 font-100">
											Click on the profile image to change
										</p>
									</div>
								</div>
								<div className="row mt-2">
									<div className="col-md-4 col-lg-4 col-sm-4 mt-4">
										<h6 className="mb-0">First Name</h6>
										<input
											id="firstName"
											type="text"
											className="w-100 mt-2 p-3 bg-light profileinput"
											placeholder="First Name"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.firstName}
										/>
										{formik.touched.firstName && formik.errors.firstName ? (
											<div className="text-danger">
												{formik.errors.firstName}
											</div>
										) : null}
									</div>
									<div className="col-md-4 col-lg-4 col-sm-4 mt-4">
										<h6 className="mb-0">Last Name</h6>
										<input
											id="lastName"
											type="text"
											className="w-100 mt-2 p-3 bg-light profileinput"
											placeholder="Last Name"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.lastName}
										/>
										{formik.touched.lastName && formik.errors.lastName ? (
											<div className="text-danger">
												{formik.errors.lastName}
											</div>
										) : null}
									</div>
								</div>
								{[UserRoles.CONTRACTOR].includes(user?.role) &&
									profileData?.providerId && (
										<div className="row mt-2">
											<div className="col-md-4 col-lg-4 col-sm-4">
												<h6 className="mb-0">Provider Id</h6>
												<div className="align-items-center d-flex">
													<input
														id="firstName"
														disabled
														type="text"
														className="w-100 mt-2 p-3 bg-light profileinput"
														value={profileData?.providerId}
													/>
													{showCopySuccesIcon ? (
														<CheckCircleIcon
															className="ml-2"
															style={{ color: "green" }}
														/>
													) : (
														<FileCopyIcon
															onClick={copyTextToClipboard}
															className="ml-2"
														/>
													)}
												</div>
											</div>
										</div>
									)}
								<div className="row mt-2">
									<div className="col-md-4 col-lg-4 col-sm-4 mt-4">
										<h6 className="mb-0">Email Address</h6>
										<input
											id="email"
											type="email"
											className="w-100 mt-2 p-3 bg-light profileinput"
											placeholder="email"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.email}
											readOnly
										/>
										{formik.touched.email && formik.errors.email ? (
											<div className="text-danger">{formik.errors.email}</div>
										) : null}
									</div>
									<div className="col-md-4 col-lg-4 col-sm-4 mt-4">
										<h6 className="mb-0">Contact Number</h6>
										<div className="mt-2">
											<input
												id="contactNumber"
												type="text"
												className="w-100 mt-2 p-3 bg-light profileinput"
												placeholder="Enter phone number"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.contactNumber}
											/>
											{formik.touched.contactNumber &&
											formik.errors.contactNumber ? (
												<div className="text-danger">
													{formik.errors.contactNumber}
												</div>
											) : null}
										</div>
									</div>
								</div>

								<div className="row mt-2">
									<div className="col-md-4 col-lg-4 col-sm-4 mt-4">
										<h6 className="">Country</h6>
										<div className="dropdown w-100 pl-0 pr-0">
											<Select
												className="country-select"
												placeholder="Select Country"
												value={{
													value: formik.values.country,
													label: formik.values.country,
												}}
												isSearchable={false}
												name="country"
												onChange={(data: any) => {
													formik.setFieldValue("country", data.value);
												}}
												options={countryOption}
												styles={colourStyles}
											/>
											{formik.touched.country && formik.errors.country ? (
												<div className="text-danger">
													{formik.errors.country}
												</div>
											) : null}
										</div>
									</div>
									<div className="col-md-4 col-lg-4 col-sm-4 mt-4">
										<h6 className="mb-0">Address</h6>
										<input
											id="address"
											type="text"
											className="w-100 mt-2 p-3 bg-light profileinput"
											placeholder="address"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.address}
										/>
										{formik.touched.address && formik.errors.address ? (
											<div className="text-danger">{formik.errors.address}</div>
										) : null}
									</div>
								</div>
								<div className="row mt-2">
									<div className="col-md-4 col-lg-4 col-sm-4 mt-4">
										<h6 className="mb-0">City</h6>
										<input
											id="city"
											type="text"
											className="w-100 mt-2 p-3 bg-light profileinput"
											placeholder="city"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.city}
										/>
										{formik.touched.city && formik.errors.city ? (
											<div className="text-danger">{formik.errors.city}</div>
										) : null}
									</div>
									<div className="col-md-4 col-lg-4 col-sm-4 mt-4">
										<h6 className="mb-0">State</h6>
										<input
											id="state"
											type="text"
											className="w-100 mt-2 p-3 bg-light profileinput"
											placeholder="state"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.state}
										/>
										{formik.touched.state && formik.errors.state ? (
											<div className="text-danger">{formik.errors.state}</div>
										) : null}
									</div>
								</div>
								<div className="row mt-2">
									<div className="col-md-4 col-lg-4 col-sm-4 mt-4">
										<h6 className="mb-0">Zip Code</h6>
										<input
											id="zip"
											type="number"
											className="w-100 mt-2 p-3 bg-light profileinput"
											placeholder="zip code"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.zip}
										/>
										{formik.touched.zip && formik.errors.zip ? (
											<div className="text-danger">{formik.errors.zip}</div>
										) : null}
									</div>
								</div>
								<div className="row">
									<div className="col-md-6 mt-4">
										<div className="col-md-4 col-lg-4 col-sm-4 pl-0 pr-0">
											<button type="submit" className="btn btn1 w-100">
												Save
											</button>
										</div>
									</div>
								</div>
							</form>
							<div className="row d-none m-d-block">
								<div className="col-md-6 mt-4">
									<div className="col-md-4 col-lg-4 col-sm-4 pl-0 pr-0">
										<NavLink to={URL.CHANGE_PASSWORD}>
											Click here to change password
										</NavLink>
									</div>
								</div>
							</div>
							{isLoading && <SpinnerLoader />}
						</div>
					</div>
				</div>
			</div>

			{[UserRoles.CONTRACTOR].includes(user?.role) && user?.stripeCustomerId && (
				<div className="row">
					<div className="col-md-10 col-lg-10 col-sm-10">
						<div className="row mt-3 pl-3 pr-3 pt-3 h-100 pb-5 mb-2">
							<div className="col-md-12 col-lg-12 col-sm-12 profile-component bg-white p-4">
								<h5 className="mt-2">
									Manage Subscriptions and Payment Methods
								</h5>
								<div className="row">
									<div className="col-md-6 mt-4">
										<div className="col-md-4 col-lg-4 col-sm-4 pl-0 pr-0">
											<button
												type="submit"
												className="btn btn1 w-100"
												onClick={() => gotoCustomerPortal()}
											>
												Manage
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
export default Profile;
