import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import get from "lodash/get";
import { AnySrvRecord } from "dns";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers";
import { FormikProvider, useFormik } from "formik";
import { getAssetData, updateAsset } from "../../api/AssetController";
import { toast } from "react-toastify";
import CallIcon from "@material-ui/icons/Call";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { setAsset } from "../../redux/actions/assetActions";
import Button from "@material-ui/core/Button";
import { trim, isEmpty } from "lodash";
import * as Yup from "yup";
import { AssetsDetailsModel } from "../../modals/device/Assets";
import { validationErrorMessage } from "../../utils/errMessages";

interface EditAssetProps {
	show: boolean;
	onHide: (isCallFromSave: boolean) => void;
}

interface AssetData {
	name: string;
	alertEmail: string;
	alertContact: string;
}

const SetupAlerts: React.FC<EditAssetProps> = ({ show, onHide }) => {
	const dispatch = useDispatch();
	const asset = useSelector((state: RootState) => state.asset);
	let [assetData, setAssetData] = useState<AssetsDetailsModel>(
		new AssetsDetailsModel()
	);

	useEffect(() => {
		if (asset._id) {
			getAssetDetails();
		}
	}, [asset]);

	const getAssetDetails = async () => {
		const response = await getAssetData(asset._id);
		if (response?.responseObj && response?.responseObj?.asset) {
			setAssetData(response?.responseObj?.asset);
		}
	};

	const saveDetails = (values: AssetData) => {
		values.alertContact = trim(values.alertContact);
		values.alertEmail = trim(values.alertEmail.toLowerCase());

		assetData.alertContact = !isEmpty(values.alertContact)
			? !isEmpty(assetData.alertContact)
				? assetData.alertContact + "," + values.alertContact
				: values.alertContact
			: assetData.alertContact;

		assetData.alertEmail = !isEmpty(values.alertEmail)
			? !isEmpty(assetData.alertEmail)
				? assetData.alertEmail + "," + values.alertEmail
				: values.alertEmail
			: assetData.alertEmail;

		const alertEmail = assetData.alertEmail.split(",");
		assetData.alertEmail = [...new Set(alertEmail)].join();

		const alertContact = assetData.alertContact.split(",");
		assetData.alertContact = [...new Set(alertContact)].join();

		updateAsset(assetData, asset._id)
			.then((response: any) => {
				if (response.responseObj) {
					toast.success(response.responseObj.message);
					onHide(true);
					dispatch(setAsset(response?.responseObj?.asset));
				} else {
					toast.error(response.error);
				}
			})
			.catch((error: AnySrvRecord) => {
				toast.error(error);
			});
	};

	const emailRegexPattern =
		/^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])(,)?)+$/;
	const contactRegexPattern = /^(\d{10}(,(?=.))?)+$/;
	const validationSchema = Yup.object().shape({
		alertContact: Yup.string().matches(
			contactRegexPattern,
			validationErrorMessage.CONTACT
		),
		alertEmail: Yup.string().matches(
			emailRegexPattern,
			validationErrorMessage.EMAIL
		),
	});

	const formik = useFormik({
		initialValues: {
			name: get(assetData, "name", ""),
			alertContact: "",
			alertEmail: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: AssetData) => {
			await saveDetails({
				...values,
			});
		},
	});

	return (
		<Modal
			show={show}
			size="lg"
			onHide={() => onHide(false)}
			dialogClassName="modal-custom"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<h5 className="mt-3 ml-3">{assetData.name}</h5>
					<p className="desc-medium ml-3 mt-2">
						Enter the phone numbers and email ids to receive alerts for the air
						quality threshold breaches you have set in the Autofix page
					</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container-fluid">
					<FormikProvider value={formik}>
						<form className="p-3" onSubmit={formik.handleSubmit}>
							<div className="mt-2">
								<div className="d-flex">
									<CallIcon className="i-16 font-secondary" />
									<p className="desc-normal ml-2 mb-0 text-break">
										<span>{assetData.alertContact}</span>
									</p>
								</div>
								<div className="d-flex mt-3">
									<MailOutlineIcon className="i-16 font-secondary" />
									<span className="desc-normal ml-2 mb-3 text-break">
										{assetData.alertEmail}
									</span>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 col-lg-12 col-sm-12">
									<div className="w-100 form-group">
										<label className="desc-normal font-500 font-secondary">
											Cell Phone Number for Text Alerts
										</label>
										<input
											id="alertContact"
											name="alertContact"
											type="text"
											className="add-re-input form-control pt-2 pb-2"
											value={formik.values.alertContact}
											onChange={formik.handleChange}
										/>
										<p className="desc-small text-secondary mt-2">
											Enter multiple phone numbers separated by comma
										</p>
										{formik.touched.alertContact &&
										formik.errors.alertContact ? (
											<div className="text-danger">
												{formik.errors.alertContact}
											</div>
										) : null}
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 col-lg-12 col-sm-12">
									<div className="w-100 form-group">
										<label className="desc-normal font-500 font-secondary">
											Email Ids for Email Alerts
										</label>
										<input
											id="alertEmail"
											name="alertEmail"
											type="text"
											className="add-re-input form-control pt-2 pb-2"
											value={formik.values.alertEmail}
											onChange={formik.handleChange}
										/>
										<p className="desc-small text-secondary mt-2">
											Add multiple email ids separated by comma
										</p>
										{formik.touched.alertEmail && formik.errors.alertEmail ? (
											<div className="text-danger">
												{formik.errors.alertEmail}
											</div>
										) : null}
									</div>
								</div>
							</div>
							<div className="drawer-footer d-flex align-items-center justify-content-end">
								<Button
									className="btn btn-medium btn-accent"
									onClick={() => onHide(false)}
								>
									Cancel
								</Button>
								<Button
									className="btn btn-medium primary-btn ml-2"
									type="submit"
									disabled={
										!trim(formik?.values?.alertContact) &&
										!trim(formik?.values?.alertEmail)
									}
								>
									Update
								</Button>
							</div>
						</form>
					</FormikProvider>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default SetupAlerts;
