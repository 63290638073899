import React from "react";

import "./AqParam.scoped.scss";

const AirQualityParameter: React.FC = () => {
	return (
		<div className="aq-param-section">
			<div className="row md-m-0 justify-content-center mb-4">
				<div className="col-md-10">
					<h6 className="d-flex justify-content-center mt-2 mb-3 heading">
						Air Quality Parameters
					</h6>
					<p className="desc-large font-secondary mb-0 text-center">
						Indoor Air Quality (IAQ) refers to the air quality within and around
						buildings and structures, especially as it relates to the health and
						comfort of building occupants. Understanding and controlling common
						pollutants indoors can help reduce your risk of indoor health
						concerns.
					</p>
				</div>
			</div>
			<div className="row md-m-0">
				<div className="col-md-6 mb-4">
					<div className="card h-100">
						<h6 className="desc-exlarge font-700 font-primary mb-2">AQI</h6>
						<p className="desc-large font-secondary mb-0">
							AQI value of 100 generally corresponds to an ambient air
							concentration that equals the level of the short-term national
							ambient air quality standard for protection of public health. AQI
							values at or below 100 are generally thought of as satisfactory.
							When AQI values are above 100, air quality is unhealthy
						</p>
					</div>
				</div>
				<div className="col-md-6 mb-4">
					<div className="card h-100">
						<h6 className="desc-exlarge font-700 font-primary mb-2">PM2.5</h6>
						<p className="desc-large font-secondary mb-0">
							Fine particulate matter PM2.5 are fine inhalable particles of size
							2.5 micrometers and smaller, they are dangerous to human health as
							they can go into lungs and bloodstream. Particles from smokeless
							heating fuels, road dust, combustion particles, organic compounds,
							mold, large bacteria etc.
						</p>
					</div>
				</div>
				<div className="col-md-6 mb-4">
					<div className="card h-100">
						<h6 className="desc-exlarge font-700 font-primary mb-2">PM10</h6>
						<p className="desc-large font-secondary mb-0">
							Particulate Matter PM 10 are inhalable particles, with diameters
							that are generally 10 micrometers and smaller. It includes dust,
							pollen, pet dander, smoke, gaseous pollutants, etc.
						</p>
					</div>
				</div>
				<div className="col-md-6 mb-4">
					<div className="card h-100">
						<h6 className="desc-exlarge font-700 font-primary mb-2">PM1.0</h6>
						<p className="desc-large font-secondary mb-0">
							These are extremely fine particulate matter (PM) particles of
							diameter less than 1 micron — significantly smaller than PM 2.5.
							These can measure the lowest finer particles of the particulate
							matter including microorganisms like bacteria, fungus and other
							organic compounds.
						</p>
					</div>
				</div>
				<div className="col-md-6 mb-4">
					<div className="card h-100">
						<h6 className="desc-exlarge font-700 font-primary mb-2">CO2</h6>
						<p className="desc-large font-secondary mb-0">
							Carbon dioxide levels may rise when people occupy indoor spaces.
							Elevated levels of carbon dioxide can also be caused by poor
							ventilation or the presence of combustible byproducts. When carbon
							dioxide levels spike, it may increase sleepiness, distraction, and
							lethargy, which may result in affecting productivity,
							concentration, and ultimately, work performance.
						</p>
					</div>
				</div>
				<div className="col-md-6 mb-4">
					<div className="card h-100">
						<h6 className="desc-exlarge font-700 font-primary mb-2">TVOC</h6>
						<p className="desc-large font-secondary mb-0">
							VOCs, volatile organic compounds, are cancer-causing gas chemicals
							released by products or processes. they can react with other gases
							and form other air pollutants after they are in the air. Total
							volatile organic compounds (TVOC) is a group of VOCs used to
							represent the entire pool of pollutants.
						</p>
					</div>
				</div>
				<div className="col-md-6 mb-4">
					<div className="card h-100">
						<h6 className="desc-exlarge font-700 font-primary mb-2">
							FORMALDEHYDE
						</h6>
						<p className="desc-large font-secondary mb-0">
							Formaldehyde (CH2O) is a naturally occurring organic compound, a
							chemical that is used in building materials and to produce many
							household products. The dangerous gas is commonly emitted by
							wooden painted surfaces, fabrics, carpets, adhesives & glues in
							furniture, and building materials like pressed wood & particle
							board.
						</p>
					</div>
				</div>
				<div className="col-md-6 mb-4">
					<div className="card h-100">
						<h6 className="desc-exlarge font-700 font-primary mb-2">
							TEMPERATURE
						</h6>
						<p className="desc-large font-secondary mb-0">
							Uniformity of temperature is important to comfort and wellbeing.
							Improper temperature in the indoor is a serious risk for your
							health and can cause insufficient humidity. Maintaining the proper
							temperature for the indoors is very critical to maintain healthy
							indoor air quality.
						</p>
					</div>
				</div>
				<div className="col-md-6 mb-4">
					<div className="card h-100">
						<h6 className="desc-exlarge font-700 font-primary mb-2">
							HUMIDITY
						</h6>
						<p className="desc-large font-secondary mb-0">
							Humidity level is just as important as the indoor temperature.
							Humidity affects comfort, overall health, and the air quality.
							When the humidity threshold is breached it enhances the growth of
							Bacteria, Viruses Mold spores, dust mites and other allergens that
							cause respiratory infections.
						</p>
					</div>
				</div>
				<div className="col-md-6 mb-4">
					<div className="card h-100">
						<h6 className="desc-exlarge font-700 font-primary mb-2">
							DEW POINT
						</h6>
						<p className="desc-large font-secondary mb-0">
							Dew Point is critically important to the end result of the Air
							Quality. Dew point is focused primarily on temperature and
							relative humidity. It refers to the dew point temperature of a gas
							under pressure. This is important because changing the pressure of
							a gas changes the dew point temperature of the gas.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AirQualityParameter;
