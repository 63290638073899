const WebWorker = () => {
	// eslint-disable-next-line no-restricted-globals
	self.onmessage = (message: any) => {
		const colorList = [
			"#4f81bc",
			"#c0504e",
			"#9bbb58",
			"#68c1aa",
			"#8064a1",
			"#54acc4",
			"#ed9447",
			"#7f6084",
			"#FFEBCD",
			"#DDA0DD",
			"#8B4513",
			"#C71585",
			"#FA8072",
			"#FF4500",
			"#D8BFD8",
			"#00FF7F",
		];
		let colorIndex = 0;
		if (!message["data"]) {
			return;
		}
		const topLevel = message.data["apiData"];
		const timeZone = message.data["timeZone"];
		const locationColor: {
			[key: string]: string;
		} = {};
		if (!topLevel) {
			return;
		}
		let objValue: any = {};
		for (let i = 0; i < topLevel.length; i++) {
			const keys = Object.keys(topLevel[i]);

			if (!keys) {
				continue;
			}
			const groupDataValues = [];
			const groupSliderValues = [];
			const locationLevel = topLevel[i][keys[0]];

			for (let x = 0; x < locationLevel.length; x++) {
				const locationKeys = Object.keys(locationLevel[x]);
				const locationName = locationKeys[0];
				const currentColor = locationColor[locationName];

				if (!currentColor) {
					locationColor[locationName] = colorList[colorIndex];
					colorIndex = colorIndex + 1;
				}

				if (!locationKeys.length) {
					continue;
				}

				for (let y = 0; y < locationLevel[x][locationKeys[0]].length; y++) {
					const timeZoneString = new Date(
						locationLevel[x][locationKeys[0]][y].x
					).toLocaleString("en", {
						timeZone: timeZone,
					});
					let yAxiosValue = locationLevel[x][locationKeys[0]][y].y;
					yAxiosValue = Number(yAxiosValue.toFixed(2));
					locationLevel[x][locationKeys[0]][y] = {
						x: new Date(timeZoneString),
						y: yAxiosValue,
						markerSize: 1,
					};
				}

				const groupedValue = {
					dataPoints: locationLevel[x][locationKeys[0]],
					name: locationKeys[0],
					showInLegend: true,
					type: "spline",
					color: locationColor[locationName],
					xValueFormatString: "MMM DD YYYY HH:mm:ss",
					xValueType: "dateTime",
				};

				groupDataValues.push(groupedValue);

				const groupSliderValue = {
					dataPoints: locationLevel[x][locationKeys[0]],
					name: locationKeys[0],
					color: locationColor[locationName],
					type: "splineArea",
					showInLegend: false,
				};

				groupSliderValues.push(groupSliderValue);
			}

			objValue[keys[0]] = {
				groupDataValues: groupDataValues,
				groupSliderValues: groupSliderValues,
			};
		}

		message.currentTarget?.postMessage(objValue);
	};
};

export default WebWorker;
