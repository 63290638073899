import React from "react";
import "../style.scoped.scss";
import machine from "../../../../images/machine.svg";
import FavoriteIcon from "@material-ui/icons/Favorite";

interface FavouriteConsultantProps {
	favourite: any;
	addToFavourite: any;
	openPopUp: any;
}

const FavouriteConsultant: React.FC<FavouriteConsultantProps> = ({
	favourite,
	addToFavourite,
	openPopUp,
}) => {
	return (
		<div className="p-2 radius-6 consultant-list d-flex flex-column position-relative w-100">
			<div onClick={() => openPopUp(favourite)}>
				<div className="consultant-img rounded-circle mb-2">
					<img
						className="w-100 h-100 rounded-circle"
						src={favourite.avatar}
						alt="Consultant"
					/>
				</div>
				<p className="mb-1 desc-medium font-700 txt-text-4 ls-024 lh-16">
					{favourite.firstName} {favourite.lastName}
				</p>
				<div className="d-flex align-items-center flex-wrap c-g-4 r-g-4 mt-2">
					{favourite?.consultantCertification?.map((certification: any) => {
						return (
							<p className="mb-1 desc-small ls-024 lh-14 font-grey-light-1">
								{certification}
							</p>
						);
					})}
				</div>
			</div>
			<div
				className="d-flex align-items-center flex-wrap c-g-4 r-g-4 mt-2"
				onClick={() => openPopUp(favourite)}
			>
				{favourite?.consultantType?.map((type: any) => {
					return (
						<p className="mb-0 desc-small ls-024 lh-16 px-2 radius-4 bg-grey-light-5 font-text-2 w-fit">
							{type?.split(" ")[0]}
						</p>
					);
				})}
			</div>
			<div
				className={
					favourite?.favorite[0]?.isFavourite ? "like-icon active" : "like-icon"
				}
			>
				<FavoriteIcon onClick={() => addToFavourite(favourite._id)} />
			</div>
		</div>
	);
};
export default FavouriteConsultant;
