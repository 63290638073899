import React, { useState } from "react";

import EditModal from "../AddEditMonitor/AddEditMonitor";
import {
	deleteDeviceData,
	detechDeviceFromLocation,
} from "../../api/DevicesController";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { MonitorDetailModal } from "../../modals/device/MonitorDetail";
import Device from "../../pages/DeviceManagement/Device";
import DeviceManagementTable from "../DeviceManagementTable/DeviceManagementTable";

import "./MonitorList.scoped.scss";

interface MonitorListProps {
	filteredMonitorList: any;
	refreshBuildingData: any;
}

const MonitorList: React.FC<MonitorListProps> = (props: any) => {
	const { filteredMonitorList, refreshBuildingData } = props;

	const [modalShow, setModalShow] = React.useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
	const [isShownConfirmDialog, setIsShownConfirmDialog] =
		useState<boolean>(false);
	const [monitorDetail, setMonitorDetail] = useState<MonitorDetailModal>(
		new MonitorDetailModal()
	);

	const deleteDevice = () => {
		setIsLoading(true);
		deleteDeviceData(selectedDeviceId)
			.then((response: any) => {
				setIsLoading(false);
				if (response && response.responseObj.message) {
					refreshBuildingData();
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
		dialogCloseClick();
	};

	const dialogCloseClick = () => {
		setIsShownConfirmDialog(false);
	};

	const onDeleteIconClicked = (selectedDevice: any) => {
		setSelectedDeviceId(selectedDevice._id);
		setIsShownConfirmDialog(true);
	};

	const onEditIconClicked = (monitorDetail: any) => {
		const monitorDetails = Object.assign({}, monitorDetail);
		delete monitorDetails.dimensions;
		delete monitorDetails.status;
		delete monitorDetails.replacementParts;
		delete monitorDetails.userId;
		delete monitorDetails.createdAt;
		delete monitorDetails.updatedAt;
		delete monitorDetails.__v;
		setMonitorDetail(monitorDetails);
		setModalShow(true);
	};

	const closeEditModal = (isCallFromSave: boolean) => {
		if (isCallFromSave) {
			refreshBuildingData();
		}
		setModalShow(false);
	};

	return (
		<div className="mt-4">
			{modalShow && (
				<EditModal
					show={modalShow}
					onHide={closeEditModal}
					monitorDetails={monitorDetail}
				/>
			)}

			{isShownConfirmDialog && (
				<ConfirmDialog
					dialogTitle="Delete"
					message="Do you want to remove the device from this location?"
					positiveBtnLabel="Remove Device"
					negativeBtnLabel=""
					onPositiveBtnClicked={deleteDevice}
					onNegativeBtnClicked={dialogCloseClick}
					onCloseIconClicked={dialogCloseClick}
				/>
			)}
			<div className="table device-responsive m-d-block">
				{filteredMonitorList.map((mapData: any) => (
					<Device
						onEdit={onEditIconClicked}
						onDelete={onDeleteIconClicked}
						deviceData={mapData}
					/>
				))}
				{filteredMonitorList?.length === 0 && (
					<p className="mb-3 ml-2 mt-4">No device found</p>
				)}
			</div>
			<div className="device-management-table">
				<DeviceManagementTable
					onEdit={onEditIconClicked}
					onDelete={onDeleteIconClicked}
					tableData={filteredMonitorList}
				/>
			</div>
		</div>
	);
};
export default MonitorList;
