import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import get from "lodash/get";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";

import { RootState } from "../../redux/reducers";
import { MultiSelectWithCheckBox } from "../../components/MultiSelectWithCheckbox/MultiSelectWithCheckbox";
import { SpinnerLoader } from "../../common/Global.Style";
import {
	createConsultation,
	updateConsultationReport,
} from "../../api/ReportsController";
import { getDimensions } from "../../api/clusterConfigController";
import { URL } from "../../components/Navbar/routes";
import { getSignatureUrl, updateUserAvtar } from "../../api/UserController";
import moment from "moment";
import { getMarketPlaceProduct } from "../../api/commonController";
import Shared from "../../common/Shared";

import "./CreateConsultantReport.scopped.scss";

interface Item {
	label: string;
	value: string;
	isSeleted?: boolean;
}

const CreateConsultationReport: React.FC<any> = () => {
	const building = useSelector((store: RootState) => store.building);
	const locations = useSelector((store: RootState) => store.locations);
	const asset = useSelector((store: RootState) => store.asset);

	const { state }: any = useLocation();
	const history = useHistory();

	const editorRef: any = useRef(null);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedLocations, setSelectedLocations] = useState<any>({});
	const [selectedAQIParams, setSelectedAQIParams] = useState<any>();
	const [dimensions, setDimensions] = useState<any>([]);
	const [isEdit, setIsEdit] = useState(false);
	const [defaultEditorContent, setDefaultEditorContent] = useState("");
	const [productList, setProductList] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState<any>([]);

	const defaultHtml = (buildinName: string, address: string) =>
		`<div>
			<div style="
				page-break-after: always;
				max-width: 750px;
				display: table;
			">
				<div style="position: relative;">
					<img alt="" src=${Shared.reportBgUrl} 
						style="width: 100%;"
					>
					<div style="
						position: absolute;
						bottom: 50px;
						right: 30px;
					">
						<div style="width: 250px !important;">
							<img alt="" src=${
								!!asset.logo ? asset.logo : Shared.reportCloudLogoUrl
							} style="width: 100%;">
						</div>
						<p style="
							font-size: 24px;
							font-weight: bold;
							color: #fff;
							font-family: 'Inter', sans-serif;
						">IAQ Consultation Report</p>
						<p style="
							font-size: 24px;
							font-weight: bold;
							color: #fff;
							justify-content: center;
							display: flex;
							margin-top: 10px;
							font-family: 'Inter', sans-serif;
						">${asset.name}</p>
					</div>
					</div>
				</div>
			<div>
			<h2><!-- pagebreak --></h2>
				<h2>Hello ,</h2>
				<div
					style="border-radius: 4px;"
				>
					<p
						style="padding: 6px;"
					>
					    Here’s the IAQ consultation report for your building.
					</p>
					<p
						style="
							font-size: 18px;
							font-weight: bold;
							color: #1844eb;
							padding: 6px;
						"
					>
						${buildinName}
					</p>
					<p
						style="
							position: relative;
							padding: 6px;
						"
					>
						Date: <span>${moment().format("YYYY MMM DD, H:mm")}</span>
					</p>
					<p
						style="
							font-size: 14px;
							color: rgba(0, 0, 0, 0.6);
							padding: 6px;
						"
					>
						${address}
					</p>
				</div>
				<p><br><strong>Problem Observed: </strong><br><br></p>

				<p><strong>Proposed Solution: <br></p>
			<div>
		<div>`;

	useEffect(() => {
		getAQIParams();
	}, [building]);

	useEffect(() => {
		getProductList();
	}, []);

	useEffect(() => {
		if (state?._id) {
			setIsEdit(true);
			formik.setFieldValue("location", prepareLocationList(state.locations));
			setSelectedProduct(
				get(state, "recomentedProduct", []).map((mapData: any) => ({
					...mapData,
					label: mapData.name,
				}))
			);
		} else {
			const address = `${building.address ? `${building.address},` : ""}${
				building.city ? `${building.city},` : ""
			}${building.state ? `${building.state},` : ""}${
				building.zipcode ? `${building.zipcode},` : ""
			}`;
			setDefaultEditorContent(defaultHtml(building.name, address));
		}
	}, [state]);

	const getAQIParams = async () => {
		setIsLoading(true);
		const dimensionResponse: any = await getDimensions();
		setIsLoading(false);
		const responseData = get(dimensionResponse, "responseObj.result", []);
		setDimensions(responseData);
		if (state?._id) {
			formik.setFieldValue(
				"aqiParam",
				prepareAQIParamFromString(state.aqiParam, responseData)
			);
		}
		const params: any = [];
		state?.aqiParam?.forEach((param: string) => {
			const dimension = responseData?.find((p: any) => p.code === param);
			if (dimension) {
				params.push({ label: dimension.shortName, value: dimension.code });
			}
		});
		setSelectedAQIParams(params);
	};

	const prepareLocationList = (locations: any) => {
		if (!locations) {
			return [];
		}

		const locationOptions = [];
		for (const location of locations) {
			locationOptions.push({
				value: location["_id"],
				label: location["name"],
			});
		}
		return locationOptions;
	};

	const prepareAqiParams = (aqiParams: any) => {
		if (!aqiParams) {
			return [];
		}

		const params = [];
		for (const param of aqiParams) {
			params.push({
				value: param.code,
				label: param.shortName,
			});
		}
		return params;
	};

	const getProductList = async () => {
		const response = await getMarketPlaceProduct();
		if (response?.responseObj?.length) {
			setProductList(
				response?.responseObj?.map((mapData: any) => ({
					label: mapData?.name,
					value: mapData?._id,
					image: get(mapData, "images.[0]"),
					productUrl: mapData?.webUrl,
					name: mapData?.name,
				}))
			);
		}
	};

	const prepareAQIParamFromString = (params: string[], dimArray: any) => {
		const aqiParams: any = [];
		if (dimArray.length) {
			params.forEach((param: string) => {
				const dimension = dimArray.find((p: any) => p.code === param);
				aqiParams.push({ label: dimension.shortName, value: dimension.code });
			});
		}
		return aqiParams;
	};

	const saveDetails = async (value: any) => {
		if (!value) {
			return;
		}

		const data = {
			consultantName: value.consultantName,
			cause: value.cause,
			solution: value.solution,
			aqiParam: value.aqiParam.map((aqi: Item) => aqi.value),
			location: value.location.map((loc: Item) => loc.value),
			building: building._id,
			reportContent: editorRef.current.getContent(),
			recomentedProduct: selectedProduct,
		};

		setIsLoading(true);

		try {
			if (isEdit) {
				await updateConsultationReport(state._id, data);
			} else {
				await createConsultation(data);
			}
			history.push(URL.CONSULTATION_REPORTS);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}
	};

	const onChangeLocation = (value: any) => {
		setSelectedLocations(value);
		formik.setFieldValue("location", value);
	};

	const onChangeAQIParam = (value: any) => {
		setSelectedAQIParams(value);
		formik.setFieldValue("aqiParam", value);
	};

	useEffect(() => {
		setSelectedLocations(prepareLocationList(state.locations));
	}, []);

	const formik = useFormik({
		initialValues: {
			consultantName: get(state, "consultantName", ""),
			cause: get(state, "cause", ""),
			solution: get(state, "solution", ""),
			aqiParam: isEdit
				? prepareAQIParamFromString(state.aqiParam, dimensions)
				: [],
			location: isEdit ? prepareLocationList(state.locations) : [],
		},
		validationSchema: Yup.object({
			aqiParam: Yup.array()
				.min(1, "At least single dimension required")
				.of(Yup.mixed<Item>().required()),
			location: Yup.array()
				.min(1, "At least single location required")
				.of(Yup.mixed<any>().required()),
		}),
		onSubmit: (values: any) => {
			saveDetails(values);
		},
	});

	const formatOptionLabel = ({ label, image }: any) => (
		<div className="align-items-center d-flex p-1">
			<img className="product-img" src={image} alt="product" />
			<div>{label}</div>
		</div>
	);

	return (
		<div className="create-consultation-container w-100">
			{isLoading && <SpinnerLoader />}
			<div className="d-flex cursor-pointer">
				<ArrowBackIosIcon
					onClick={() => history.push(URL.CONSULTATION_REPORTS)}
					fontSize="small"
				/>
				<p className="breadcrumb-para mb-0">
					{isEdit ? "Edit" : "Add"} Consultation Report
				</p>
			</div>

			<div className="container-fluid">
				<FormikProvider value={formik}>
					<form onSubmit={formik.handleSubmit}>
						<div className="row m-0">
							<div className="col-md-12 col-lg-6 col-sm-12 p-0">
								<h6 className="mt-4">Consultant Name</h6>
								<div className="dropdown w-100 pl-0 pr-0">
									<input
										className="add-re-input bg-white form-control pt-2 pb-2"
										placeholder=""
										name="consultantName"
										id="consultantName"
										value={formik.values.consultantName}
										onChange={formik.handleChange}
										maxLength={100}
										autoComplete="off"
									/>
									{formik.touched?.consultantName &&
									formik.errors?.consultantName ? (
										<div className="text-danger">{formik.errors.cause}</div>
									) : null}
								</div>
								<h6 className="mt-4">Location</h6>
								<div className="dropdown w-100 create-schdule-input pl-0 pr-0">
									<MultiSelectWithCheckBox
										seletedOptions={prepareLocationList(locations)}
										selectValue={selectedLocations}
										onSelectionChange={onChangeLocation}
									/>
									{formik.touched.location && formik.errors.location ? (
										<div className="text-danger">{formik.errors.location}</div>
									) : null}
								</div>

								<h6 className="mt-4">Dimensions</h6>
								<div className="dropdown w-100 create-schdule-input pl-0 pr-0 multi-select">
									<MultiSelectWithCheckBox
										seletedOptions={prepareAqiParams(dimensions)}
										selectValue={selectedAQIParams}
										onSelectionChange={onChangeAQIParam}
									/>
									{formik.touched.aqiParam && formik.errors.aqiParam ? (
										<div className="text-danger">{formik.errors.aqiParam}</div>
									) : null}
								</div>

								<div className="d-flex mt-4">
									<div className="dropdown create-schdule-input form-group">
										<h6>Cause</h6>
										<div className="dropdown w-100 pl-0 pr-0">
											<textarea
												className="add-re-input bg-white form-control pt-2 pb-2"
												placeholder=""
												name="cause"
												id="cause"
												value={formik.values.cause}
												onChange={formik.handleChange}
												maxLength={500}
												autoComplete="off"
											/>
											{formik.touched.cause && formik.errors.cause ? (
												<div className="text-danger">{formik.errors.cause}</div>
											) : null}
										</div>
									</div>
								</div>

								<div className="d-flex mt-4">
									<div className="dropdown create-schdule-input form-group">
										<h6>Solution</h6>
										<div className="dropdown w-100 pl-0 pr-0">
											<textarea
												className="add-re-input bg-white form-control pt-2 pb-2"
												placeholder=""
												name="solution"
												id="solution"
												value={formik.values.solution}
												onChange={formik.handleChange}
												maxLength={500}
												autoComplete="off"
											/>
											{formik.touched.solution && formik.errors.solution ? (
												<div className="text-danger">
													{formik.errors.solution}
												</div>
											) : null}
										</div>
									</div>
								</div>

								<div>
									<Editor
										onInit={(evt, editor) => (editorRef.current = editor)}
										initialValue={state?.reportContent || defaultEditorContent}
										apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
										init={{
											height: 800,
											plugins:
												"preview importcss searchreplace autolink autosave directionality  visualblocks visualchars fullscreen image link media  template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists  wordcount charmap quickbars  emoticons",
											images_upload_url:
												"https://ydgsm5kko9.execute-api.us-east-1.amazonaws.com/qa/api/get-upload-url",
											mobile: {
												plugins:
													"preview importcss searchreplace autolink autosave directionality  visualblocks visualchars fullscreen image link media  template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists  wordcount charmap quickbars emoticons ",
											},
											contextmenu: "link pagebreak",
											images_upload_handler: function (blobInfo) {
												setIsLoading(true);
												return new Promise((resolve) => {
													getSignatureUrl({ name: blobInfo.filename() }).then(
														async (response: any) => {
															const imgUrl =
																response?.responseObj?.signedUrl?.split("?")[0];
															await updateUserAvtar(
																response?.responseObj?.signedUrl,
																blobInfo.blob()
															).then((response: any) => {
																setIsLoading(false);
																resolve(imgUrl);
															});
														}
													);
												});
											},
										}}
									/>
								</div>

								{Shared.title === "iQi Cloud" && (
									<div>
										<h6 className="mt-4">Recommended Product</h6>
										<div className="dropdown create-schdule-input pl-0 pr-0">
											<Select
												isMulti
												name="colors"
												options={productList}
												value={selectedProduct}
												formatOptionLabel={formatOptionLabel}
												onChange={(value) => setSelectedProduct(value)}
												className="basic-multi-select"
												classNamePrefix="select"
											/>
										</div>
									</div>
								)}

								<div className="mt-4">
									<button type="submit" className="btn add pl-5 pr-5">
										{isEdit ? "Update" : "Add"} Consultation
									</button>
								</div>
							</div>
						</div>
					</form>
				</FormikProvider>
			</div>
		</div>
	);
};

export default CreateConsultationReport;
