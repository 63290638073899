import { SvgIcon } from "@material-ui/core";
import { memo } from "react";

const Formaldehyde = (props) => {
	return (
		<SvgIcon
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.3283 14.0262C12.8278 14.0262 14.0433 12.8107 14.0433 11.3112C14.0433 9.81175 12.8278 8.59619 11.3283 8.59619C9.82884 8.59619 8.61328 9.81175 8.61328 11.3112C8.61328 12.8107 9.82884 14.0262 11.3283 14.0262Z"
				fill={props.color}
			/>
			<path
				d="M18.3348 11.0193C17.8556 11.0193 17.4453 11.3053 17.259 11.7145L15.2592 11.5422L15.1914 12.3276L17.193 12.5001C17.3256 13.009 17.7843 13.3867 18.3345 13.3867C18.9884 13.3867 19.5184 12.8567 19.5184 12.2029C19.5184 11.549 18.9884 11.0193 18.3348 11.0193Z"
				fill={props.color}
			/>
			<path
				d="M12.8599 16.1865C12.8403 16.1865 12.8214 16.1889 12.802 16.1896L12.4516 14.9783L11.6946 15.1974L12.0446 16.4081C11.563 16.6892 11.2383 17.2102 11.2383 17.8084C11.2383 18.7038 11.9643 19.43 12.8599 19.43C13.7555 19.43 14.4815 18.7038 14.4815 17.8084C14.4815 16.9125 13.7555 16.1865 12.8599 16.1865Z"
				fill={props.color}
			/>
			<path
				d="M7.43781 6.97523C7.43781 6.43887 7.00309 6.00415 6.46695 6.00415C5.93081 6.00415 5.49609 6.43887 5.49609 6.97523C5.49609 7.51159 5.93081 7.94609 6.46695 7.94609C6.57497 7.94609 6.67705 7.92409 6.77407 7.89175L7.66727 8.86349L8.24786 8.32999L7.35751 7.36133C7.40855 7.24253 7.43781 7.11251 7.43781 6.97523Z"
				fill={props.color}
			/>
			<path
				d="M13.9608 7.6275L14.8672 6.11984C15.0493 6.181 15.2438 6.21554 15.4464 6.21554C16.4534 6.21554 17.27 5.39912 17.27 4.39218C17.27 3.38502 16.4534 2.5686 15.4464 2.5686C14.4395 2.5686 13.6231 3.38502 13.6231 4.39218C13.6231 4.91248 13.8422 5.38108 14.192 5.71328L13.2852 7.22116L13.9608 7.6275Z"
				fill={props.color}
			/>
			<path
				d="M7.09298 12.6107L5.45311 13.1301C5.16271 12.6934 4.66617 12.405 4.10209 12.405C3.20647 12.405 2.48047 13.1312 2.48047 14.0266C2.48047 14.9223 3.20647 15.6485 4.10209 15.6485C4.99771 15.6485 5.72371 14.9223 5.72371 14.0266C5.72371 13.9752 5.72085 13.9241 5.71601 13.8737L7.33124 13.362L7.09298 12.6107Z"
				fill={props.color}
			/>
		</SvgIcon>
	);
};

export default memo(Formaldehyde);
