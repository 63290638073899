import { SET_LOCATION } from "../actions/actionTypes";

export const locationReducer = (state = [], action: any) => {
	switch (action.type) {
		case SET_LOCATION:
			return [...action.payload];
		default:
			return state;
	}
};
