export class AssetsDetailsModel {
	public name: string;
	public alertContact: string;
	public alertEmail: string;
	public salesEmails: string;
	public calendlyLink: string;

	public constructor() {
		this.name = "";
		this.alertContact = "";
		this.alertEmail = "";
		this.salesEmails = "";
		this.calendlyLink = "";
	}
}
