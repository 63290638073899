import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import { Dropdown } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import CallIcon from "@material-ui/icons/Call";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { AccountCircle } from "@material-ui/icons";

import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import Edit from "@material-ui/icons/Edit";
import { SelectableRows } from "mui-datatables";
import get from "lodash/get";

import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import CustomDataTable from "../CustomDataTable/CustomDataTable";
import EditUser from "../EditUser/EditUser";
import {
	updateUserByContractor,
	getResetPasswordLink,
} from "../../api/UserController";
import { updateAsset } from "../../api/AssetController";
import EditAsset from "../EditAsset/EditAsset";
import { SpinnerLoader } from "../../common/Global.Style";
import { PasswordModal } from "../../components/PasswordModal/PasswordModal";
import SystemTour from "../SystemTour/SystemTour";
import { UserRoles } from "../../enums/UserRoles";
import { toast } from "react-toastify";

import "./viewAssets.scss";

interface ViewAssetProps {
	showDrawer: boolean;
	setShowDrawer: (value: boolean) => void;
	assetData: {
		alertContact?: string;
		alertEmail?: string;
		name?: string;
		authorized_users: IUserLit[];
		_id: string;
		assignedConsultant: string;
	};
	deleteUserFromAsset: (data: any) => void;
	updateAssetData: () => void;
	setShowAssetModal: (value: boolean) => void;
	isLoading: boolean;
}

interface IUserLit {
	contactNumber: string;
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	permissions: string[];
	write: boolean;
}

export default function ViewAsset({
	showDrawer,
	setShowDrawer,
	assetData,
	deleteUserFromAsset,
	updateAssetData,
	setShowAssetModal,
	isLoading,
}: ViewAssetProps) {
	const [loading, setIsLoading] = useState<boolean>(false);
	const [showEditUser, setShowEditUser] = useState<boolean>(false);
	const [showEditAsset, setShowEditAsset] = useState<boolean>(false);
	const [isShownConfirmDialog, setIsShownConfirmDialog] =
		useState<boolean>(false);
	const [selectedser, setSelectedUser] = useState<any>({});
	const [open, setOpen] = useState(false);
	const [resetLink, setResetLink] = useState("");

	const userRoleAlias: any = {
		contractor: "Superuser",
		admin: "Admin",
	};

	const options = {
		print: false,
		search: false,
		filter: false,
		download: false,
		responsive: "vertical",
		viewColumns: false,
		selectableRows: "none" as SelectableRows,
	};

	const confirmDeleteAsset = (userData: any) => {
		setIsShownConfirmDialog(true);
		setSelectedUser(userData);
	};

	const dialogCloseClick = () => {
		setIsShownConfirmDialog(false);
	};

	const updateAssetUserData = async (apiData: any) => {
		setIsLoading(true);
		const response = await updateUserByContractor({
			...apiData,
			assetId: assetData._id,
			user_id: selectedser._id,
		});
		setIsLoading(false);
		if (response.responseObj?.message === "Updated successfully") {
			setShowEditUser(false);
			updateAssetData();
		}
	};

	const updateAssetAction = async (apiData: any) => {
		setIsLoading(true);
		const response = await updateAsset(apiData, assetData._id);
		setIsLoading(false);
		if (response.responseObj) {
			setShowEditAsset(false);
			updateAssetData();
		} else if (response.error) {
			toast.error(response.error);
		}
		if (response.error) {
			toast.error(response.error);
		}
	};

	const generateLink = async (email: string) => {
		setIsLoading(true);
		const response = await getResetPasswordLink({ email });
		setIsLoading(false);
		if (response?.error) {
			toast.error(response?.error);
		} else if (response?.responseObj?.data?.resetUrl) {
			setResetLink(response?.responseObj?.data?.resetUrl);
			setOpen(true);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const columns = [
		{
			name: "_id",
			options: {
				filter: false,
				display: false, // hide this filed in Data-Table
			},
		},
		{
			name: "Name",
			label: "",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const userData = get(
						tableMeta,
						`tableData[${tableMeta.rowIndex}]`,
						{}
					);
					return (
						<div>
							<p className="desc-normal font-primary font-700 mb-1">{`${userData.firstName} ${userData.lastName}`}</p>
							<p className="desc-small mt-2 mb-0">{userData.email}</p>
						</div>
					);
				},
			},
		},
		// {
		// 	name: "contactNumber",
		// 	label: "Contact No",
		// 	options: {
		// 		filter: false,
		// 		sort: true,
		// 		customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
		// 			return (
		// 				<Box display="flex" flexWrap="wrap" className="alertEmailContact">
		// 					{value}
		// 				</Box>
		// 			);
		// 		},
		// 	},
		// },
		{
			name: "write",
			label: "Role",

			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const userData: any = get(
						tableMeta,
						`tableData[${tableMeta.rowIndex}]`,
						{}
					);
					return (
						<Box display="flex" flexWrap="wrap" className="alertEmailContact">
							{[UserRoles.CONTRACTOR, UserRoles.ADMIN].includes(userData?.role)
								? userRoleAlias[userData?.role]
								: value
								? "Admin"
								: "Viewer"}
						</Box>
					);
				},
			},
		},
		{
			name: "email",
			label: "Password Reset",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => {
					return (
						<p onClick={() => generateLink(value)} className="link">
							Reset Password Link
						</p>
					);
				},
			},
		},
		{
			name: "_id",
			label: " ",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const userData = get(
						tableMeta,
						`tableData[${tableMeta.rowIndex}]`,
						{}
					);
					if (userData?.role !== "contractor") {
						return (
							<div className="d-flex justify-content-end table-actions">
								<IconButton
									aria-label="Edit Customer Button"
									onClick={() => {
										setShowEditUser(true);
										setSelectedUser(userData);
									}}
								>
									<Edit />
								</IconButton>
								<IconButton
									aria-label="Delete Customer Button"
									onClick={() => confirmDeleteAsset(userData)}
								>
									<DeleteOutlineIcon />
								</IconButton>
							</div>
						);
					}
				},
			},
		},
	];

	const tourSteps = [
		{
			selector: ".user-edit-icon",
			content: `Click on the pencil icon to edit customer account details or 
		  reconfigure alert notifications/ sales email / schedule consultation details.`,
		},
		{
			selector: ".add-user-edit",
			content: `You can add users to access the customer account as
		  Admin who can configure devices and can access dashboards, reports and also edit settings in the web console
		  (Or)
		  Viewer who can only see dashboards and reports in the web console`,
		},
	];

	return (
		<React.Fragment>
			{/* <SystemTour tourSteps={tourSteps} position={4} /> */}
			<Drawer
				anchor="right"
				open={showDrawer}
				onClose={() => setShowDrawer(false)}
				className="customer-drawer"
			>
				{open && (
					<PasswordModal
						open={open}
						handleClose={handleClose}
						resetLink={resetLink}
					/>
				)}
				<div className="drawer-content-section customer-account-drawer">
					<div className="drawer-header">
						<div className="drawer-header-title d-flex justify-content-between">
							<div className="align-items-center d-flex justify-content-center">
								<h6 className="drawer-header-heading">{assetData.name}</h6>
								<IconButton
									size="small"
									onClick={() => setShowEditAsset(true)}
									className="ml-1 cursor-pointer"
								>
									<Edit className="i-20 user-edit-icon" />
								</IconButton>
							</div>
							<CloseIcon
								className="cursor-pointer m-d-none"
								onClick={() => setShowDrawer(false)}
							/>
							<ArrowBackIosIcon
								className="i-18 txt-primary cursor-pointer d-none m-d-block mr-2"
								onClick={() => setShowDrawer(false)}
							/>
						</div>
						<div className="mt-2">
							<div className="d-flex">
								<CallIcon className="i-16 font-secondary" />
								<p className="desc-small ml-2 mb-0">
									<span>{assetData.alertContact}</span>
								</p>
							</div>
							<div className="d-flex mt-3">
								<MailOutlineIcon className="i-16 font-secondary" />
								<div className="ml-2">
									{assetData.alertEmail?.split(",").map((email) => (
										<p className="desc-small mb-0">{email}</p>
									))}
								</div>
							</div>
							{assetData?.assignedConsultant?.length && (
								<div className="d-flex mt-3">
									<AccountCircle className="i-16 font-secondary" />
									<div className="ml-2">
										<p className="desc-small mb-0">
											{assetData?.assignedConsultant}
										</p>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="drawer-content view-user-content">
						<div className="d-flex justify-content-end pt-3 pb-4 px-4 m-d-none">
							<div className="d-flex">
								{window.innerWidth > 1024 && (
									<button
										onClick={() => {
											setShowAssetModal(true);
										}}
										className="btn btn-medium primary-btn ml-3 add-user-edit"
									>
										Add User
									</button>
								)}
							</div>
						</div>
						<div className="view-asset-table m-d-none">
							<CustomDataTable
								title=""
								rows={assetData.authorized_users}
								columns={columns}
								options={options}
							/>
						</div>
						<div className="d-none m-d-block">
							<div className="m-user-account-header border-bottom d-flex align-items-center justify-content-between py-2 px-3">
								<p className="desc-normal font-600 mb-0">User List</p>
							</div>
							<div className="m-user-account-cards p-3">
								{assetData?.authorized_users?.length === 0 && (
									<div className="mt-3 text-align-center">Users not found</div>
								)}
								{assetData.authorized_users.map((user: any) => (
									<div className="m-user-account-card mb-2 d-flex">
										<div className="circle-icon asset-circle-icon">A</div>
										<div className="flex-1 ml-2 pl-1">
											<h4 className="desc-normal font-bold font-primary d-flex justify-content-between mb-0">
												<span>{`${user.firstName} ${user.lastName}`}</span>
												<Dropdown className="more-dropdown">
													<Dropdown.Toggle
														id="dropdown-basic"
														bsPrefix="my-dropdown-toggle p-0"
													>
														<MoreHorizIcon className="i-20 font-tertiary cursor-pointer" />
													</Dropdown.Toggle>
													<Dropdown.Menu className="card-action">
														<Dropdown.Item
															onClick={() => {
																setShowEditUser(true);
																setSelectedUser(user);
															}}
														>
															Edit
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() => {
																dialogCloseClick();
																confirmDeleteAsset(user);
															}}
														>
															Delete
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</h4>
											<p className="desc-small font-tertiary text-truncate mb-0 mt-1 m-mv-200">
												{user.email}
											</p>
											<p className="desc-small font-500 font-tertiary text-truncate d-flex align-items-center justify-content-between mt-2 mb-0">
												<span>{user.contactNumber}</span>
												<span className="txt-primary">
													{[UserRoles.CONTRACTOR, UserRoles.ADMIN].includes(
														user?.role
													)
														? userRoleAlias[user?.role]
														: user?.write
														? "Admin"
														: "Viewer"}
												</span>
											</p>
											<p
												onClick={() => generateLink(user?.email)}
												className="txt-primary desc-small mt-3 mb-0"
											>
												Reset Password Link
											</p>
										</div>
									</div>
								))}
							</div>
							{window.innerWidth <= 1024 && (
								<div className="m-add-customer d-none m-d-block">
									<button
										onClick={() => {
											setShowAssetModal(true);
										}}
										className="btn btn-medium primary-btn add-user-edit"
									>
										<AddIcon /> Add User
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
				{isShownConfirmDialog && (
					<ConfirmDialog
						dialogTitle="Delete"
						message="Are you sure want to remove this user?"
						positiveBtnLabel="Delete"
						negativeBtnLabel=""
						onPositiveBtnClicked={() => {
							deleteUserFromAsset(selectedser);
							setIsShownConfirmDialog(false);
						}}
						onNegativeBtnClicked={() => dialogCloseClick()}
						onCloseIconClicked={() => dialogCloseClick()}
					/>
				)}
				{showEditAsset && (
					<EditAsset
						showDrawer={showEditAsset}
						setShowDrawer={setShowEditAsset}
						assetData={assetData}
						updateAsset={updateAssetAction}
					/>
				)}
				{showEditUser && (
					<EditUser
						showDrawer={showEditUser}
						setShowDrawer={setShowEditUser}
						userData={selectedser}
						updateAssetUserData={updateAssetUserData}
					/>
				)}
				{(loading || isLoading) && <SpinnerLoader />}
			</Drawer>
		</React.Fragment>
	);
}
