import { useState } from "react";
import LazyLoad from "react-lazyload";

import CanvasJSReact from "../../lib/CanvasJs/canvasjs.stock.react";

const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

export const CanvasChat = ({ title, graphData }: any) => {
	const [containerProps] = useState({
		width: "100%",
		height: "450px",
		margin: "auto",
	});
	let selectedButtonRange: number = 1;

	const [chartOption] = useState<any>({
		title: {
			text: "",
			fontSize: 16,
			fontFamily: "Inter, sans-serif",
		},
		theme: "light1",
		animationEnabled: false,
		exportEnabled: true,
		charts: [
			{
				axisX: {
					crosshair: {
						enabled: true,
						snapToDataPoint: true,
						// valueFormatString: "MMM DD, YYYY HH:mm:ss",
					},
				},
				axisY: {
					crosshair: {
						enabled: true,
						snapToDataPoint: true,
					},
					valueFormatString: "####",
					margin: 10,
				},
				toolTip: {
					shared: true,
				},
				legend: {
					fontSize: 12,
				},
				data: graphData.groupDataValues,
			},
		],
		rangeSelector: {
			height: 50,
			label: " ",
			selectedRangeButtonIndex: selectedButtonRange,
			buttons: [
				{
					range: 8,
					rangeType: "hour",
					label: "8 hours",
				},
				{
					rangeType: "all",
					label: "1 Day", //Change it to "All"
				},
			],
			inputFields: {
				enabled: false,
			},
		},
		navigator: {
			data: graphData.groupSliderValues,
			axisX: {
				labelAngle: -1,
			},
		},
		rangeChanging: function (e: any) {
			selectedButtonRange = e.index;
		},
	});

	return (
		<>
			{graphData?.groupDataValues && chartOption && (
				<LazyLoad key={title} height={450} offset={100} unmountIfInvisible once>
					<CanvasJSStockChart
						containerProps={containerProps}
						options={chartOption}
					/>
				</LazyLoad>
			)}
		</>
	);
};
