import React, { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import CanvasJSReact from "../../lib/CanvasJs/canvasjs.stock.react";

import "./Dashboard.scoped.scss";

const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

interface MonitorProps {
	imagePath: string;
	subTitle: string;
	title: string;
	monitorData: any;
}
interface MonitorChartProps {
	title: string;
	locationName: string;
	chartData: any;
}

const Monitor: React.FC<MonitorProps> = ({
	subTitle,
	imagePath,
	title,
	monitorData,
}) => {
	return (
		<>
			{monitorData.map((monitor: any, index: number) => {
				return (
					<div
						key={get(monitor, "name")}
						className={`box3 ion-monitor-responsive d-flex ${
							monitorData.length % 2 !== 0 && monitorData.length === index + 1
								? "w-100"
								: ""
						}`}
					>
						<div className="align-items-center d-flex justify-content-center monitor-card">
							<div>
								<h3 className="desc-exlarge font-600 font-primary mb-5">
									{subTitle}
								</h3>
								<div className="monitor-image mt-4">
									<img src={imagePath} alt="iron-icon" />
								</div>
								<div className="mt-5">
									<p className="desc-large txt-primary font-600 mb-1">
										{get(monitor, "latestEntry.value", "N/A")}
									</p>
									{/* <p className="desc-large txt-primary font-600 mb-0">Good</p> */}
								</div>
							</div>
						</div>
						<div className="monitor-chart-card flex-1">
							<MonitorChart
								title={get(monitor, "name")}
								chartData={get(monitor, "readings", [])}
								locationName={subTitle}
							/>
						</div>
					</div>
				);
			})}
		</>
	);
};

const MonitorChart = ({
	title,
	chartData,
	locationName,
}: MonitorChartProps) => {
	const generateDataPoints = (extraOption: any) => {
		const groupDataValues = [];
		if (chartData.length) {
			let dps: any = [];
			chartData.forEach((data: any) => {
				dps.push({
					x: new Date(get(data, "timestamp")),
					y: get(data, "value"),
					markerSize: 1,
				});
			});

			groupDataValues.push({
				dataPoints: dps,
				name: title,
				...extraOption,
			});
		}
		return groupDataValues;
	};

	const optionsValues = () => {
		return {
			title: {
				text: `${title} - ${locationName}`,
				fontSize: 17,
				fontFamily: "'Inter', sans-serif",
			},
			theme: "light1",
			animationEnabled: true,
			exportEnabled: true,
			charts: [
				{
					axisX: {
						crosshair: {
							enabled: true,
							snapToDataPoint: true,
							valueFormatString: "MMM DD, YYYY HH:mm:ss",
						},
					},
					axisY: {
						crosshair: {
							enabled: true,
							snapToDataPoint: true,
						},
					},
					toolTip: {
						shared: true,
					},
					legend: {
						fontSize: 12,
					},
					data: generateDataPoints({
						showInLegend: true,
						type: "spline",
						xValueFormatString: "MMM DD YYYY HH:mm:ss",
						xValueType: "dateTime",
					}),
				},
			],
			rangeSelector: {
				height: 50,
				label: "",
				buttonStyle: {
					labelFontSize: 16,
				},
				// selectedRangeButtonIndex: 1,
				buttons: [
					{
						range: 1,
						rangeType: "hour",
						label: "1 hour",
					},
					{
						range: 12,
						rangeType: "hour",
						label: "12 hour",
					},
					{
						range: 1,
						rangeType: "day",
						label: "1 day",
					},
					{
						rangeType: "all",
						label: "All", //Change it to "All"
					},
				],
				inputFields: {
					enabled: false,
				},
			},
			navigator: {
				data: generateDataPoints({
					type: "splineArea",
					showInLegend: false,
				}),
			},
		};
	};

	const [chartOption, setChartOption] = useState(optionsValues());

	useEffect(() => {
		if (!isEmpty(chartData)) {
			setChartOption(optionsValues());
		}
	}, [chartData]);

	return (
		<CanvasJSStockChart
			containerProps={{
				width: "100%",
				height: "350px",
				margin: "auto",
			}}
			options={chartOption}
		/>
	);
};

export default Monitor;
