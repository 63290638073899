import { theme } from "../theme/CustomTheme";

export const colorStatus: any = {
	Good: theme.good,
	Moderate: theme.moderate,
	Poor: theme.poor,
	"Very Poor": theme.veryPoor,
	Hazardous: theme.hezardous,
};

export const getPerc: any = {
	Good: 25,
	Moderate: 50,
	Poor: 65,
	"Very Poor": 78,
	Hazardous: 90,
};

export const DeviceTypes: any = {
	AQMonitor: "aqMonitor",
	Remediation: "remediation",
	Monitor: "monitor",
};

export const CommonEnum = {
	StatusNA: "N/A",
};

export const MobileAppLink: {
	AndroidToolsApp: string;
	IosToolsApp: string;
} = {
	AndroidToolsApp:
		"https://play.google.com/store/apps/details?id=com.iqicloud.smart",
	IosToolsApp: "https://apps.apple.com/app/iqi-cloud/id1607105384",
};
