import { SvgIcon } from "@material-ui/core";
import { memo } from "react";

const Temperature = (props) => {
	return (
		<SvgIcon
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.97568 21.4717C11.9237 22.0437 14.4757 19.7997 14.4757 16.9397C14.4757 15.4437 13.7277 14.0797 12.5837 13.2437V3.1677C12.5837 1.6717 11.3957 0.439697 9.85568 0.439697C8.35968 0.439697 7.12768 1.6717 7.12768 3.1677V13.2437C5.76368 14.2557 4.97168 15.9717 5.36768 17.9077C5.71968 19.6677 7.17168 21.1197 8.97568 21.4717ZM7.69968 14.0797L8.09568 13.7717V3.2117C8.09568 2.2437 8.88768 1.4957 9.81168 1.4957C10.7797 1.4957 11.5717 2.2877 11.5717 3.2117V13.7717L11.9677 14.0797C12.8917 14.7397 13.4197 15.8397 13.4197 16.9837C13.4197 17.9517 13.0237 18.8757 12.3637 19.5357C11.6597 20.1957 10.7797 20.5917 9.81168 20.5917C8.00768 20.5917 6.59968 19.3157 6.29168 17.7317C6.07168 16.2797 6.59968 14.8717 7.69968 14.0797Z"
				fill={props.color}
			/>
			<path
				d="M9.85501 19.5353C10.559 19.5353 11.219 19.2713 11.703 18.7873C12.187 18.3033 12.451 17.6433 12.451 16.9393C12.451 15.1793 10.955 14.6073 10.559 14.2553V6.81934H9.10701V14.2553C8.79901 14.6073 6.86301 15.3113 7.30301 17.4673C7.52301 18.6113 8.53501 19.5353 9.85501 19.5353Z"
				fill={props.color}
			/>
			<path
				d="M16.2346 3.43213H13.7266V4.44413H16.2346C16.4986 4.44413 16.7186 4.22413 16.7186 3.96013C16.7626 3.65213 16.5426 3.43213 16.2346 3.43213Z"
				fill={props.color}
			/>
			<path
				d="M15.7534 6.59997C15.7534 6.33597 15.5334 6.11597 15.2694 6.11597H13.7734V7.12797H15.2694C15.5334 7.08397 15.7534 6.86397 15.7534 6.59997Z"
				fill={props.color}
			/>
			<path
				d="M16.7654 9.23986C16.7654 8.97586 16.5454 8.75586 16.2814 8.75586H13.7734V9.76786H16.2814C16.5454 9.76786 16.7654 9.50386 16.7654 9.23986Z"
				fill={props.color}
			/>
		</SvgIcon>
	);
};

export default memo(Temperature);
