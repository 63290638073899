import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function getPaymentSetupSession(
	data: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"account-subscription/create-paymentsetup",
		undefined,
		data,
		undefined,
		false,
		false,
		true
	);
}

export async function upgradeToPremiumOffline(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("PUT", "account-subscription/upgrade-with-offline");
}

export async function upgradeToPremiumOnline(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("PUT", "account-subscription/upgrade-with-online");
}
