import { URL } from "../../../components/Navbar/routes";

import ConsultantIcon from "../../../images/Consultant.svg";
import ProductIcon from "../../../images/product.svg";
import ServiceIcon from "../../../images/service.svg";

const IAQStoreSubmenuValues: any = [
	{
		title: "Consultant",
		icon: <img className="mr-2" src={ConsultantIcon} alt="Data Service" />,
		description:
			"Reach professionals with just the right skills and experience.",
		path: URL.IAQCONSULTANT,
		viewText: "Connect Consultant",
		viewLink: URL.CONSULTATION_REPORTS,
	},
	{
		title: "Product",
		icon: <img className="mr-2" src={ProductIcon} alt="Data Service" />,
		description:
			"Our Marketplace enables building owners to identify air quality issues, consult with certified air quality experts, and access all remediation options right from the platform.",
		path: URL.IAQPRODUCT,
		viewText: "View IAQ Store",
		viewLink: URL.IAQPRODUCT,
	},
];

export { IAQStoreSubmenuValues };
