import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { URL } from "../../../../components/Navbar/routes";
import "../style.scoped.scss";
interface ProductCategoryProps {
	category: any;
}

const ProductCategory: React.FC<ProductCategoryProps> = ({ category }) => {
	let bgClass = "bg-green-light-1";
	if (category.name === "Filtration") {
		bgClass = "bg-green-light-2";
	} else if (category.name === "Humidity Control") bgClass = "bg-red-light-1";
	else if (category.name === "Ventilation") bgClass = "bg-green-light-3";
	return (
		<NavLink
			className="flex-1"
			to={`${URL.IAQALLPRODUCT}/${category._id}/${category.name}`}
		>
			<div className="flex-1 d-flex align-items-center flex-column">
				<div
					className={`product-img mr-0 d-flex align-items-end justify-content-center rounded-circle ${bgClass}`}
				>
					<img src={category.image} alt="Machine" />
				</div>
				<p className="mb-0 mt-2 pt-1 desc-small ls-024 text-center">
					{category.name}
				</p>
			</div>
		</NavLink>
	);
};
export default ProductCategory;
