import React, { useState } from "react";
import { useSelector } from "react-redux";
import { pull } from "lodash";

import DeviceManagementTable from "../DeviceManagementTable/DeviceManagementTable";
import {
	deleteDeviceData,
	getDeviceControlls,
	changeDeviceControlls,
	updateDevice,
} from "../../api/DevicesController";
import { SpinnerLoader } from "../../common/Global.Style";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import AddEditRemediation from "../AddEditRemediation/AddEditRemediation";
import { RemediationDetailModal } from "../../modals/device/RemediationDetail";
import { RootState } from "../../redux/reducers";
import { DeviceControllerDrawer } from "./DeviceControllerDrawer";
import Device from "../../pages/DeviceManagement/Device";

import "./DeviceList.scoped.scss";

interface DeviceListProps {
	filteredDeviceList: any;
	refreshBuildingData: any;
	locationId: string;
	getBuildingDetailsApi: () => void;
}

const DeviceList: React.FC<DeviceListProps> = (props: any) => {
	const {
		filteredDeviceList,
		refreshBuildingData,
		getBuildingDetailsApi,
		locationId,
	} = props;
	const [modalShow, setModalShow] = React.useState(false);
	const [selectedDevice, setSelectedDevice] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
	const [isShownConfirmDialog, setIsShownConfirmDialog] =
		useState<boolean>(false);
	const [remediationDetail, setRemediationDetail] =
		useState<RemediationDetailModal>(new RemediationDetailModal());
	const building = useSelector((state: RootState) => state.building);
	const [controlDrawerOpen, setControlDrawerOpen] = useState<any>(false);
	const [selectedDeviceControlls, setSelectedDeviceControlls] = useState<any>(
		{}
	);
	const [drawerLoading, setDrawerLoading] = useState<boolean>(false);

	const [confirmationMessage, setConfirmationMessage] = useState<string>(
		"Do you want to remove the device from this location?"
	);
	const [positiveButtonLabel, setPositiveButtonLabel] =
		useState<string>("Remove Device");
	const [negativeButtonLabel, setNegativeButtonLabel] = useState<string>("");

	const deleteDevice = () => {
		setIsLoading(true);
		deleteDeviceData(selectedDeviceId)
			.then((response: any) => {
				setIsLoading(false);
				if (response && response.responseObj.message) {
					refreshBuildingData();
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
		dialogCloseClick();
	};

	const getDeviceController = async (data: any) => {
		setIsLoading(true);
		const response = await getDeviceControlls(data.deviceId);
		setIsLoading(false);
		if (response?.responseObj) {
			setSelectedDeviceControlls(response?.responseObj);
			setControlDrawerOpen(true);
			setSelectedDevice(data);
		}
	};

	const changeDeviceControll = async (data: any) => {
		setDrawerLoading(true);
		const response = await changeDeviceControlls(selectedDevice.deviceId, data);
		if (response?.responseObj) {
			setSelectedDeviceControlls(response?.responseObj);
			getBuildingDetailsApi();
		}
		setDrawerLoading(false);
	};

	const removeDeviceFromThisLocation = async () => {
		setIsLoading(true);
		remediationDetail.location = pull(remediationDetail.location, locationId);
		try {
			const response = await updateDevice(remediationDetail);
			setIsLoading(false);
			if (response && response.responseObj.message) {
				refreshBuildingData();
			}
		} catch (error) {
			setIsLoading(false);
		}
		dialogCloseClick();
	};

	const onDeleteIconClicked = (selectedDevice: any) => {
		setRemediationDetail(selectedDevice);
		if (
			selectedDevice &&
			selectedDevice?.deviceType === "interops" &&
			selectedDevice?.location.length > 1
		) {
			setConfirmationMessage(
				"Do you want to remove the device from this location or from all the locations?"
			);
			setPositiveButtonLabel("From All Locations");
			setNegativeButtonLabel("From This Location");
		}
		setSelectedDeviceId(selectedDevice._id);
		setIsShownConfirmDialog(true);
	};

	const dialogCloseClick = () => {
		setIsShownConfirmDialog(false);
	};

	const onEditIconClicked = (id: string) => {
		const remediationDetailTemp = filteredDeviceList.find(
			(x: any) => x._id === id
		);
		if (remediationDetailTemp) {
			const remediationDetails = Object.assign({}, remediationDetailTemp);
			delete remediationDetails.__v;
			delete remediationDetails.status;
			delete remediationDetails.userId;
			delete remediationDetails.createdAt;
			delete remediationDetails.updatedAt;
			setRemediationDetail(remediationDetails);
			setModalShow(true);
		}
	};

	const mobileEditIconClicked = (deviceData: any) => {
		onEditIconClicked(deviceData?._id);
	};

	const onHideRemediationDetail = (isCallFromSave: boolean) => {
		if (isCallFromSave) {
			refreshBuildingData();
		}
		setModalShow(false);
	};

	return (
		<div className="mt-3">
			{isShownConfirmDialog && (
				<ConfirmDialog
					dialogTitle="Delete"
					message={confirmationMessage}
					positiveBtnLabel={positiveButtonLabel}
					negativeBtnLabel={negativeButtonLabel}
					onPositiveBtnClicked={() => deleteDevice()}
					onNegativeBtnClicked={() => removeDeviceFromThisLocation()}
					onCloseIconClicked={() => dialogCloseClick()}
				/>
			)}
			<div className="device-responsive m-d-block">
				{filteredDeviceList.map((mapData: any) => (
					<Device
						onEdit={mobileEditIconClicked}
						onDelete={onDeleteIconClicked}
						deviceData={mapData}
						openControll={getDeviceController}
					/>
				))}
				{filteredDeviceList.length === 0 && (
					<p className="mb-3 ml-2 mt-4">No device found</p>
				)}
			</div>
			{isLoading && <SpinnerLoader />}
			{modalShow && (
				<AddEditRemediation
					show={true}
					onHide={onHideRemediationDetail}
					remediationDetailsModal={remediationDetail}
				/>
			)}
			{controlDrawerOpen && (
				<DeviceControllerDrawer
					open={controlDrawerOpen}
					onClose={() => setControlDrawerOpen(false)}
					selectedDeviceControlls={selectedDeviceControlls}
					selectedDevice={selectedDevice}
					changeDeviceControll={changeDeviceControll}
					drawerLoading={drawerLoading}
				/>
			)}
			<div className="device-management-table">
				<DeviceManagementTable
					tableData={filteredDeviceList}
					onEdit={mobileEditIconClicked}
					onDelete={onDeleteIconClicked}
					openControll={getDeviceController}
				/>
			</div>
		</div>
	);
};
export default DeviceList;
