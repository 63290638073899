import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function createFloorApi(
	floorFormData: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"POST",
		"v2.0/floor/create",
		undefined,
		undefined,
		floorFormData
	);
}

export async function updateFloorApi(
	floorFormData: any,
	asset: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		`v2.0/floor/edit?assetId=${asset}`,
		undefined,
		floorFormData
	);
}

export async function getFloorApi(
	id: string,
	asset: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `v2.0/floor?building=${id}&assetId=${asset}`);
}

export async function getDeviceReadingApi(
	deviceId: string | null,
	deviceType: string,
	asset: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		`v2.0/floor/reading?deviceId=${deviceId}&deviceType=${deviceType}&assetId=${asset}`
	);
}

export async function removeDeviceFromFloor(
	deviceId: string,
	floorId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", `v2.0/floor/${floorId}/${deviceId}`);
}

export async function deleteFloorPlan(id: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", "v2.0/floor/" + id);
}
