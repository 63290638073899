import React, { useState } from "react";

import {
	deleteDeviceData,
	detechDeviceFromLocation,
} from "../../api/DevicesController";
import { SpinnerLoader } from "../../common/Global.Style";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import AddEditGateway from "../AddEditGateway/AddEditGateway";
import { GatewayDetailModal } from "../../modals/device/GatewayDetail";
import Device from "../../pages/DeviceManagement/Device";
import DeviceManagementTable from "../DeviceManagementTable/DeviceManagementTable";

import "./GatewayList.scoped.scss";

interface DeviceListProps {
	filteredGatewayList: any;
	refreshBuildingData: any;
}

const MonitorList: React.FC<DeviceListProps> = (props: any) => {
	const { filteredGatewayList, refreshBuildingData } = props;

	const [modalShow, setModalShow] = React.useState(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isShownConfirmDialog, setIsShownConfirmDialog] =
		useState<boolean>(false);
	const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
	const [gatewayDetail, setGatewayDetail] = useState<GatewayDetailModal>(
		new GatewayDetailModal()
	);

	const deleteDevice = () => {
		setIsLoading(true);
		deleteDeviceData(selectedDeviceId)
			.then((response: any) => {
				setIsLoading(false);
				if (response && response.responseObj.message) {
					refreshBuildingData();
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
		dialogCloseClick();
	};

	const detechDevice = () => {
		setIsLoading(true);
		detechDeviceFromLocation(selectedDeviceId)
			.then((response: any) => {
				setIsLoading(false);
				if (response && response.responseObj.message) {
					refreshBuildingData();
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
		dialogCloseClick();
	};

	const onDeleteIconClicked = (selectedDevice: any) => {
		setSelectedDeviceId(selectedDevice._id);
		setIsShownConfirmDialog(true);
	};

	const dialogCloseClick = () => {
		setIsShownConfirmDialog(false);
	};

	const onEditIconClicked = (id: any) => {
		const gatewayDetailTemp = filteredGatewayList.find(
			(x: any) => x._id === id
		);
		if (gatewayDetailTemp) {
			const gatewayDetails = Object.assign({}, gatewayDetailTemp);
			delete gatewayDetails.__v;
			delete gatewayDetails.status;
			delete gatewayDetails.userId;
			delete gatewayDetails.createdAt;
			delete gatewayDetails.updatedAt;
			delete gatewayDetails.dimensions;
			delete gatewayDetails.replacementParts;
			delete gatewayDetails.features;
			setGatewayDetail(gatewayDetails);
			setModalShow(true);
		}
	};

	const mobileEditIconClicked = (deviceData: any) => {
		onEditIconClicked(deviceData?._id);
	};

	const closeEditModal = (isCallFromSave: boolean) => {
		if (isCallFromSave) {
			refreshBuildingData();
		}
		setModalShow(false);
	};

	return (
		<>
			{modalShow && (
				<AddEditGateway
					show={modalShow}
					onHide={closeEditModal}
					gatewayDetailsModal={gatewayDetail}
				/>
			)}
			<div className="mt-3">
				<div className="table device-responsive m-d-block">
					{filteredGatewayList.map((mapData: any) => (
						<Device
							onEdit={mobileEditIconClicked}
							onDelete={onDeleteIconClicked}
							deviceData={mapData}
						/>
					))}
					{filteredGatewayList.length === 0 && (
						<p className="mb-3 ml-2 mt-4">No device found</p>
					)}
				</div>

				{isShownConfirmDialog && (
					<ConfirmDialog
						dialogTitle="Delete"
						message="Do you want to remove the device from this location?"
						positiveBtnLabel="Remove Device"
						negativeBtnLabel=""
						onPositiveBtnClicked={() => deleteDevice()}
						onNegativeBtnClicked={() => detechDevice()}
						onCloseIconClicked={() => dialogCloseClick()}
					/>
				)}
				{isLoading && <SpinnerLoader />}
				<div className="device-management-table">
					<DeviceManagementTable
						onEdit={mobileEditIconClicked}
						onDelete={onDeleteIconClicked}
						tableData={filteredGatewayList}
					/>
				</div>
			</div>
		</>
	);
};
export default MonitorList;
