import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { getLocations } from "../../redux/actions/locationActions";
import { locationMapSelector } from "../../redux/selectors/locationSelector";
import Select from "react-select";

import "./LocationSelector.scoped.scss";
interface Item {
	label: string;
	value: string;
	path?: string;
}

interface LocationSelectorProps {
	onSelectLocation: (value: Item | null) => void;
	selectedLocationId?: string;
	buildingId?: string;
	name?: string;
	selectProps?: any;
}

const LocationSelector: React.FC<LocationSelectorProps> = ({
	onSelectLocation,
	selectedLocationId,
	buildingId,
	name = "location",
	selectProps = {},
}) => {
	const locations: Item[] = locationMapSelector(
		useSelector((state: RootState) => state)
	);
	const building = useSelector((state: RootState) => state.building);
	const dispatch = useDispatch();

	useEffect(() => {
		const id = buildingId || building._id;
		dispatch(getLocations([], (id && id) ?? ""));
	}, []);

	const selectedLocation = () =>
		locations.find((x: Item) => x.value === selectedLocationId);

	return (
		<>
			<Select
				className="basic-multi-select"
				onChange={onSelectLocation}
				options={locations}
				placeholder="Select location"
				value={selectedLocation()}
				styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
				isClearable={true}
				name={name}
				{...selectProps}
			/>
		</>
	);
};
export default LocationSelector;
