import { default as ReactSelect, components } from "react-select";
const multiSelect: any = {
	control: (styles: any) => ({
		...styles,
		"min-width": "400px",
		"@media only screen and (max-width: 768px)": {
			...styles["@media only screen and (max-width:768px)"],
			"min-width": "400px",
			width: "100%",
		},
		"@media only screen and (max-width: 480px)": {
			...styles["@media only screen and (max-width:480px)"],
			"min-width": "300px",
			width: "100%",
		},
		"@media only screen and (max-width: 320px)": {
			...styles["@media only screen and (max-width:480px)"],
			"min-width": "260px",
			width: "100%",
		},
	}),
};

const Option = (props: any) => {
	return (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					className="select-input mr-2"
					checked={props.isSelected}
					onChange={() => null}
				/>
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

export const MultiSelectWithCheckBox = ({
	seletedOptions,
	onSelectionChange,
	selectValue,
	placeholder,
	customStyle,
}: any) => {
	const onSelectChange = (selected: any, event: any) => {
		if (event?.option?.value === "*") {
			onSelectionChange(seletedOptions);
		} else {
			onSelectionChange(selected);
		}
	};

	return (
		<span
			className="d-inline-block "
			data-toggle="popover"
			data-trigger="focus"
			data-content="Please selecet dimension(s)"
		>
			<ReactSelect
				options={[{ label: "All", value: "*" }, ...seletedOptions]}
				isMulti
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				components={{
					Option,
				}}
				onChange={onSelectChange}
				allowSelectAll={true}
				value={selectValue}
				styles={customStyle || multiSelect}
				placeholder={placeholder || ""}
			/>
		</span>
	);
};
