import React, { useEffect, useState } from "react";
import "./style.scoped.scss";

import MobileBack from "../../../components/MobileBack";
import { Button } from "@material-ui/core";

import FavoriteIcon from "@material-ui/icons/Favorite";

import machine from "../../../images/machine.svg";

import ReportProblemIcon from "@material-ui/icons/ReportProblemOutlined";
import { SpinnerLoader } from "../../../common/Global.Style";

import "./style.scoped.scss";
import {
	getConsultant,
	assignAssetToConsultant,
	doFavouriteUnFavourite,
} from "../../../api/commonController";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import AllConsultant from "./Components/AllConsultant";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ViewConsultant from "./Components/ViewConsultant";
import { URL } from "../../../components/Navbar/routes";
import { toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme: any) => ({
	root: {
		[theme.breakpoints.down("xs")]: {
			alignItems: "flex-end", // push the dialog to bottom
		},
	},
	paper: {
		// make the content full width
		[theme.breakpoints.down("xs")]: {
			margin: 0,
			maxWidth: "100%",
			width: "100%",
		},
	},
}));
const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};
const AllFavouriteConsultant: React.FC = (props: any) => {
	const { match } = props;
	let query = useQuery();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showConsultant, SetShowConsultant] = useState(false);
	const asset = useSelector((state: RootState) => state.asset);
	const consultationType: any = query.get("type");
	const [favouriteConsultantList, setFavouriteConsultantList] = useState([]);
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const openDialog = () => setOpen(true);
	const [search, setSearch] = useState("");
	const [viewConsultantData, setViewConsultantData] = useState<any>();

	const addToFavourite = async (consultant: any) => {
		setIsLoading(true);
		const response = await doFavouriteUnFavourite({ consultant: consultant });
		if (response?.responseObj?.message) {
			getAllConsultant();
			closePopUp();
		}
	};
	const openPopUp = (consultant: any) => {
		setViewConsultantData(consultant);
		setOpen(true);
	};
	const closePopUp = () => {
		setOpen(false);
		setViewConsultantData("");
	};
	const getAllConsultant = async () => {
		const category = match?.params?.category ? match?.params?.category : "";
		setIsLoading(true);
		const response = await getConsultant(
			decodeURIComponent(category.replace("+", " ")),
			asset._id,
			search
		);
		if (response?.responseObj?.length) {
			const products = get(response, "responseObj", []);
			const favorite = products?.filter(
				(p: any) => p?.favorite[0]?.isFavourite
			);
			if (category) {
				setFavouriteConsultantList(products);
			} else setFavouriteConsultantList(favorite);
		}
		setIsLoading(false);
	};
	const assignAsset = async (email: string) => {
		setIsLoading(true);
		const response = await assignAssetToConsultant({
			assets: [{ _id: asset._id, name: asset.name }],
			email,
		});
		if (response.responseObj) {
			await getAllConsultant();
			closePopUp();
			toast.success(response?.responseObj?.message);
		} else {
			toast.error(response.error);
		}
		setIsLoading(false);
	};
	const onSearchSubmit = (e: any) => {
		e.preventDefault();
		getAllConsultant();
	};
	useEffect(() => {
		getAllConsultant();
	}, [asset]);
	return (
		<div className="w-100 consultant-section">
			<div className="mobile-header d-none m-d-flex align-items-center justify-content-between bg-white p-12 px-3">
				<MobileBack
					heading={
						match?.params?.category
							? match?.params?.category.replace("+", " ")
							: "Back"
					}
					redirect={URL.IAQCONSULTANT}
					subheading={favouriteConsultantList.length}
				/>
			</div>
			<div className="bg-white px-3 py-2">
				<div className="icon-input">
					<form className="position-relative" onSubmit={onSearchSubmit}>
						<input
							name="search"
							onChange={(e) => setSearch(e.target.value)}
							className="add-re-input form-control pt-2 pb-2"
							placeholder="Search Consultant"
						/>
						<SearchIcon className="search-icon" />
					</form>
				</div>
			</div>
			<div className="content-section bg-white">
				<div className="mt-1 p-3 bg-white">
					<div className="w-100 radius-4 bg-warn-light-1 d-flex align-items-start p-2">
						<div className="p-2 bg-warn-light-1 rounded-circle d-flex align-items-center justify-content-center">
							<ReportProblemIcon className="i-16 font-warn-light" />
						</div>
						<p className="pl-3 desc-small font-grey-light-4 ls-024 lh-18 mb-0">
							iQi is not responsible for consultant hiring process and payments
							for consultants. It is purely between the user and consultants
						</p>
					</div>
				</div>
				<Dialog
					className="view-consultant-details-modal"
					open={open}
					classes={{ container: classes.root, paper: classes.paper }}
				>
					<DialogContent>
						{viewConsultantData && (
							<ViewConsultant
								closePopUp={closePopUp}
								viewConsultantData={viewConsultantData}
								addToFavourite={addToFavourite}
								assignAsset={assignAsset}
							/>
						)}
					</DialogContent>
				</Dialog>
				{isLoading && <SpinnerLoader />}
				{favouriteConsultantList.length > 0 && (
					<div className="mt-3 px-3">
						<div className="all-product-section d-flex flex-wrap c-g-8 r-g-8">
							{favouriteConsultantList.map((consulatan: any) => {
								return (
									<AllConsultant
										consulatan={consulatan}
										addToFavourite={addToFavourite}
										openPopUp={openPopUp}
									/>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default AllFavouriteConsultant;
