import React, { useEffect } from "react";

import "./AdminSubMenu.scoped.scss";
import { AdminSubMenuValues } from "./Menus";
import SubMenu from "../Components/SubMenu";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { setupWebViewJavascriptBridge } from "../../../common/JsBridge";
import { useHistory, useLocation } from "react-router-dom";
import { UserRoles } from "../../../enums/UserRoles";
import { URL } from "../../../components/Navbar/routes";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import Subscription from "../../../images/card.svg";

const AdminSubMenu: React.FC = () => {
	const history = useHistory();
	const user: any = useSelector((state: RootState) => state.user);
	const locationHistory = useLocation();
	const goBack = () => {
		if (locationHistory.key) {
			history.goBack();
		} else {
			callHandler("sendBackToNative", {}, (data: any) => {});
		}
	};

	useEffect(() => {
		if (user?.role === UserRoles.CONTRACTOR) {
			AdminSubMenuValues[2] = {
				title: "Subscription",
				icon: <img className="mr-2" src={Subscription} alt="Subscription" />,
				description:
					"Software subscription details including plans subscribed, invoices, amount due and renewal details.",
				viewText: "View Subscription",
				viewLink: "",
				path: URL.OFFLINE_SUBSCRIPTION,
			};
		}
	});

	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};
	const renderIAQStoreSubmenuValues = () => (
		<>
			{AdminSubMenuValues.map((item: any, index: any) => {
				return (
					<div key={`${index}-${item.path}`}>
						<SubMenu item={item} />
					</div>
				);
			})}
		</>
	);
	return (
		<div className="px-3 pt-3 w-100 bg-white">
			<div
				className="d-flex align-items-center pb-3 px-0"
				onClick={() => goBack()}
			>
				<ArrowBackIcon className="i-16 txt-primary font-600" />
				<p className="desc-large font-text-4 font-600 ls-024 mb-0 ml-1">Back</p>
			</div>
			<div className="navbar-sub-menu"> {renderIAQStoreSubmenuValues()} </div>
		</div>
	);
};

export default AdminSubMenu;
