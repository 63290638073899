import React, { memo, useState } from "react";
import get from "lodash/get";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

import { colorStatus, CommonEnum } from "../../../constant";
import Temperature from "../../../svg/Temperature";
import Pm1 from "../../../svg/Pm1";
import Co2 from "../../../svg/Co2";
import Formaldehyde from "../../../svg/Formaldehyde";
import Humidity from "../../../svg/Humidity";
import Pm10 from "../../../svg/Pm10";
import Pm25 from "../../../svg/Pm25";
import Tvoc from "../../../svg/Tvoc";

import "./Summary.scopped.scss";

const Reading: React.FC<{
	statusColor: string;
	value: string;
	status: string;
	label: string;
	code: string;
	description: string;
	extraInfo: string | null;
	imgSrc: string;
	unit: string;
	setExtraInfo: (value: string | null) => void;
}> = memo(
	({
		statusColor,
		value,
		status,
		label,
		code,
		description,
		extraInfo,
		imgSrc,
		unit,
		setExtraInfo,
	}) => {
		const renderIcon = () => {
			switch (label) {
				case "Temperature":
					return <Temperature />;
				case "Humidity":
					return <Humidity />;
				case "CO2":
					return <Co2 />;
				case "PM2.5":
					return <Pm25 />;
				case "PM10":
					return <Pm10 />;
				case "PM1.0":
					return <Pm1 />;
				case "TVOC":
					return <Tvoc />;
				case "Formaldehyde":
					return <Formaldehyde />;
			}
		};

		return (
			<div
				className="col col-m-sm-4 m-mt-3"
				onMouseOver={() => setExtraInfo(code)}
				onMouseLeave={() => setExtraInfo(null)}
			>
				<div className="d-flex flex-column align-items-center para-items">
					<div className="para-name fw-bold">{label}</div>
					<div
						className="para-image d-flex justify-content-center rounded-circle align-items-center"
						style={{
							backgroundColor: statusColor,
						}}
					>
						{status === CommonEnum.StatusNA ? (
							<div className="d-flex justify-content-center status-na align-items-center">
								{renderIcon()}
							</div>
						) : (
							<img src={imgSrc} alt="" className="w-50 h-auto" />
						)}
					</div>
					<div
						className="para-unit fw-bold"
						style={{
							color: statusColor,
						}}
					>
						{value !== undefined && value !== "N/A" ? value + unit : "N/A"}
					</div>
					<small
						style={{
							color: statusColor,
						}}
					>
						{value !== undefined && value !== "N/A" ? status : "N/A"}
					</small>
					{extraInfo === code && (
						<div className="position-absolute extra-info px-0 d-block">
							<p className="mb-0 text-white text-justify text-center text-break">
								{description}
							</p>
						</div>
					)}
				</div>
			</div>
		);
	}
);

const Summary: React.FC<any> = ({
	aqiData,
	buildingData,
	dewPointData,
	buildingDataUpdatedAt,
}) => {
	const [extraInfo, setExtraInfo] = useState<string | null>(null);

	const aqiColor = get(colorStatus, aqiData.status, "");
	const tempStatus = get(buildingData, "latest.temp_current_f.status", "");
	const tempValue = get(buildingData, "latest.temp_current_f.value");
	const tempStatusColor = get(colorStatus, tempStatus, "");
	const humidityStatus = get(buildingData, "latest.humidity_value.status", "");
	const humidityValue = get(buildingData, "latest.humidity_value.value");
	const humidityStatusColor = get(colorStatus, humidityStatus, "");
	const co2Status = get(buildingData, "latest.co2_value.status", "");
	const co2Value = get(buildingData, "latest.co2_value.value");
	const co2StatusColor = get(colorStatus, co2Status, "");
	const pm25Status = get(buildingData, "latest.pm25_value.status", "");
	const pm25Value = get(buildingData, "latest.pm25_value.value");
	const pm25StatusColor = get(colorStatus, pm25Status, "");
	const pm10Status = get(buildingData, "latest.pm10.status", "");
	const pm10Value = get(buildingData, "latest.pm10.value");
	const pm10StatusColor = get(colorStatus, pm10Status, "");
	const pm1Status = get(buildingData, "latest.pm1.status", "");
	const pm1Value = get(buildingData, "latest.pm1.value");
	const pm1StatusColor = get(colorStatus, pm1Status, "");
	const vocStatus = get(buildingData, "latest.voc_value.status", "");
	const vocValue = get(buildingData, "latest.voc_value.value");
	const vocStatusColor = get(colorStatus, vocStatus, "");
	const formaldehydeStatus = get(buildingData, "latest.ch2o_value.status", "");
	const formaldehydeValue = get(buildingData, "latest.ch2o_value.value");
	const formaldehydeStatusColor = get(colorStatus, formaldehydeStatus, "");
	const building: any = useSelector((state: RootState) => state.building);

	const buildingUpdateTime = moment(buildingDataUpdatedAt)
		.tz(building?.timeZoneName || "EST5EDT")
		.format("DD MMM hh:mm");
	return (
		<div className="box2 w-100 p-3 overflow-auto">
			<div className="row m-m-auto w-100">
				<div className="col-lg-2 col-m-sm-12 bg-white text-center align-items-center px-0 summery-section">
					<div className="pt-2 fw-bold mb-3">
						<h6 className="">Air Quality Index</h6>
					</div>
					<div
						className="d-flex img m-auto"
						style={{
							backgroundColor: aqiColor,
						}}
					>
						<img className="w-50 m-auto" src="/images/de-good.svg" alt="" />
					</div>
					<div
						className="para-unit fw-bold"
						style={{
							color: aqiColor,
						}}
					>
						{get(aqiData, "value", "N/A")}
					</div>
					<div className="flex-column align-items-start">
						<h3
							className="m-0 p-0 mt-2"
							style={{
								color: aqiColor,
							}}
						>
							{get(aqiData, "status", "N/A")}
						</h3>
					</div>
					<div className="position-absolute extra-info px-0 d-block summery-content">
						<p className="mb-0 text-white text-justify text-center text-break">
							AQI below 150 is acceptable
						</p>
					</div>
				</div>
				<div className="col-lg-10 col-m-sm-12 temp bg-white px-0">
					<div className="d-flex justify-content-between bg-white para-title">
						<div className="pt-2 w-100">
							<h6 className="text-center fw-bold mb-3">Aggregate Parameters</h6>
						</div>
					</div>
					<div className="d-flex w-100 sensor-value-row flex-nowrap m-flex-wrap">
						<Reading
							statusColor={tempStatusColor}
							value={tempValue}
							status={tempStatus}
							label="Temperature"
							code="s1-temperature"
							unit="F"
							imgSrc="/images/temperature-l.svg"
							description="Temperature between 68.5 F to 80.5 F is acceptable"
							extraInfo={extraInfo}
							setExtraInfo={setExtraInfo}
						/>
						<Reading
							statusColor={humidityStatusColor}
							value={humidityValue}
							status={humidityStatus}
							label="Humidity"
							unit="%"
							code="s1-humidity"
							imgSrc="/images/humidity-l.svg"
							description="Humidity between 30% to 65% is acceptable"
							extraInfo={extraInfo}
							setExtraInfo={setExtraInfo}
						/>
						<div
							className="col col-m-sm-4 m-mt-3"
							onMouseOver={() => setExtraInfo("dew-point")}
							onMouseLeave={() => setExtraInfo(null)}
						>
							<div className="d-flex flex-column align-items-center para-items">
								<div className="para-name fw-bold">Dew Point</div>
								<div
									className="para-image d-flex justify-content-center rounded-circle align-items-center"
									style={{
										backgroundColor: get(
											colorStatus,
											`${dewPointData.status}`,
											""
										),
									}}
								>
									{get(dewPointData, `status`, "") !== "N/A" && (
										<img
											src={"/images/dewpoint.png"}
											alt=""
											className="dew-point-icon"
										/>
									)}
								</div>
								<div
									className="para-unit fw-bold"
									style={{
										color: get(colorStatus, `${dewPointData.status}`, ""),
									}}
								>
									{dewPointData.value != "N/A" &&
									dewPointData.value != undefined
										? `${dewPointData.value} F`
										: "N/A"}
								</div>
								<small
									style={{
										color: get(colorStatus, `${dewPointData.status}`, ""),
									}}
								>
									{dewPointData.value != undefined
										? dewPointData.status
										: "N/A"}
								</small>
								{extraInfo === "dew-point" && (
									<div className="position-absolute extra-info px-0 d-block">
										<p className="mb-0 text-white text-justify text-center text-break">
											Dewpoint below 62 F is acceptable
										</p>
									</div>
								)}
							</div>
						</div>

						<Reading
							statusColor={co2StatusColor}
							value={co2Value}
							status={co2Status}
							label="CO2"
							unit="ppm"
							code="s1-co2"
							imgSrc="/images/co2-l.svg"
							description="CO2 less than 2000 ppm is acceptable"
							extraInfo={extraInfo}
							setExtraInfo={setExtraInfo}
						/>

						<Reading
							statusColor={pm25StatusColor}
							value={pm25Value}
							status={pm25Status}
							label="PM2.5"
							code="s1-pm25"
							unit="ug/m3"
							imgSrc="/images/PM 10-l.svg"
							description="PM2.5 less than 35 ug/m3 is acceptable"
							extraInfo={extraInfo}
							setExtraInfo={setExtraInfo}
						/>
						<Reading
							statusColor={pm10StatusColor}
							value={pm10Value}
							status={pm10Status}
							label="PM10"
							unit="ug/m3"
							code="s1-pm10"
							imgSrc="/images/PM 10-l.svg"
							description="PM10 less than 150 ug/m3 is acceptable"
							extraInfo={extraInfo}
							setExtraInfo={setExtraInfo}
						/>
						<Reading
							statusColor={pm1StatusColor}
							value={pm1Value}
							status={pm1Status}
							label="PM1.0"
							code="s1-pm1"
							unit="ug/m3"
							imgSrc="/images/PM 1.0-l.svg"
							description="PM1.0 less than 12 ug/m3 is acceptable"
							extraInfo={extraInfo}
							setExtraInfo={setExtraInfo}
						/>
						<Reading
							statusColor={vocStatusColor}
							value={vocValue}
							status={vocStatus}
							label="TVOC"
							code="s1-tvoc"
							unit="ppb"
							imgSrc="/images/tvoc-l.svg"
							description="TVOC less than 1000 ppb is acceptable"
							extraInfo={extraInfo}
							setExtraInfo={setExtraInfo}
						/>
						<Reading
							statusColor={formaldehydeStatusColor}
							value={formaldehydeValue}
							status={formaldehydeStatus}
							label="Formaldehyde"
							code="s1-formaldehyde"
							unit="ppb"
							imgSrc="/images/formaldehyde-l.svg"
							description="Formaldehyde less than 100 ppb is acceptable"
							extraInfo={extraInfo}
							setExtraInfo={setExtraInfo}
						/>
					</div>
					<div className="last-updated-time">
						<p className="m-0">Last updated: {buildingUpdateTime}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(Summary);
