import React, { useEffect, useState } from "react";
import "./ScheduleReports.scoped.scss";
import Select from "react-select";
import LocationSelector from "../../../components/LocationSelector/LocationSelector";
import {
	DeleteScheduleReport,
	getScheduleReport,
	setReportSchedule,
	updateReportSchedule,
} from "../../../api/ReportsController";
import { SpinnerLoader } from "../../../common/Global.Style";
import { SelectableRows } from "mui-datatables";
import CustomDataTable from "../../../components/CustomDataTable/CustomDataTable";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MySelect from "../../../components/MySelect/MySelect";
import { components } from "react-select";
import makeAnimated from "react-select/animated";

const ScheduleReports: React.FC = () => {
	const [selectedLocation, setSelectedLocation] = useState<any>();
	const [selectedReport, setSelectedReport] = useState<any>();
	const [selectedSchedule, setSelectedSchedule] = useState<any>();
	const [emailIds, setEmailIds] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isEmailError, setEmailError] = useState<boolean>(false);
	const [scheduleReport, setScheduleReport] = useState<Array<any>>([]);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [scheduleReportId, setScheduleReportId] = useState<boolean>(false);

	const schedule: any = [
		{
			label: "Daily",
			value: "daily",
		},
		{
			label: "Weekly",
			value: "weekly",
		},
		{
			label: "Monthly",
			value: "monthly",
		},
	];

	const reports: any = [
		{
			label: "IAQ",
			value: "iaq",
		},
		{
			label: "Maintenance",
			value: "maintenance",
		},
		{
			label: "Autopilot",
			value: "autopilot",
		},
		{
			label: "Consultation",
			value: "consultation",
		},
	];

	const onChangeLocation = (location: any) => {
		setSelectedLocation(location);
	};
	const onChangeReport = (report: any) => {
		setSelectedReport(report);
	};
	const onChangeSchedule = (schedule: any) => {
		setSelectedSchedule(schedule);
	};
	const onEmailEnter = (email: any) => {
		setEmailError(false);
		if (email.target.value.length > 0) {
			const list = email.target.value.replace(/\s/g, "").split(",");
			var regex =
				/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			for (var i = 0; i < list.length; i++) {
				if (list[i] == "" || !regex.test(list[i])) {
					// valid = false;
					setEmailError(true);
					setEmailIds(email.target.value);
				} else {
					setEmailError(false);
					setEmailIds(email.target.value);
				}
			}
		}
	};
	const callSetScheduleAPI = () => {
		if (
			!isEmailError &&
			selectedSchedule?.value &&
			selectedLocation?.value &&
			selectedReport.length > 0
		) {
			setIsLoading(true);
			const reqBody: any = {};
			reqBody.schedule = selectedSchedule.value;
			reqBody.location = selectedLocation.value;
			reqBody.emails = !isEditMode
				? emailIds.split(",")
				: !Array.isArray(emailIds)
				? emailIds.split(",")
				: emailIds;

			const cloneSelectedReport = JSON.parse(JSON.stringify(selectedReport));
			const selectAllOptionExist = cloneSelectedReport.findIndex(
				(report: any) => {
					return report.value === "*";
				}
			);

			if (selectAllOptionExist === 0)
				cloneSelectedReport.splice(selectAllOptionExist, 1);

			reqBody.reports = cloneSelectedReport.map((rep: any) => {
				return rep.value;
			});

			if (!isEditMode) {
				callSetReportSchedule(reqBody);
			} else {
				callUpdateReportSchedule(scheduleReportId, reqBody);
			}
		}
	};

	const callSetReportSchedule = (reqBody: any) => {
		setReportSchedule(reqBody)
			.then((response: any) => {
				setIsLoading(false);
				cancleClick();
				if (response.responseObj.result) {
					getScheduleReportData();
				} else {
				}
			})
			.catch((error) => {
				setIsLoading(true);
			});
	};

	const callUpdateReportSchedule = (reportId: any, reqBody: any) => {
		updateReportSchedule(reportId, reqBody)
			.then((response: any) => {
				setIsLoading(false);
				cancleClick();
				if (response.responseObj) {
					getScheduleReportData();
				} else {
				}
			})
			.catch((error) => {
				setIsLoading(true);
			});
	};

	useEffect(() => {
		getScheduleReportData();
	}, []);
	const getScheduleReportData = () => {
		setIsLoading(true);
		getScheduleReport()
			.then((response: any) => {
				if (
					response.responseObj.results &&
					response.responseObj.results.length > 0
				) {
					setIsLoading(false);
					setScheduleReport(response.responseObj.results);
				} else {
					setIsLoading(false);
					setScheduleReport([]);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				setScheduleReport([]);
			});
	};

	const onEditIconClicked = (id: string) => {
		const scheduleTemp = scheduleReport.find((x: any) => x._id === id);
		if (scheduleTemp) {
			setIsEditMode(true);
			const scheduleDetail = Object.assign({}, scheduleTemp) as any;
			setScheduleReportId(scheduleTemp._id);
			const filterReports = reports.filter((reportData: any) => {
				return scheduleDetail.reports.includes(reportData.value);
			});
			const filterSchedules = schedule.find((scheduleData: any) => {
				return scheduleDetail.schedule === scheduleData.value;
			});
			// const filterSchedules = schedule.filter((scheduleData) => {
			// 	return scheduleDetail.schedule.includes(scheduleData.value);
			// });

			setSelectedReport(filterReports);
			setSelectedSchedule(filterSchedules);
			setSelectedLocation({ label: "", value: scheduleDetail.location });
			setEmailIds(scheduleDetail.emails);
			// setScheduleDetail(scheduleDetail);
		}
	};

	const cancleClick = () => {
		setIsEditMode(false);
		setSelectedReport([]);
		setSelectedSchedule("");
		setSelectedLocation({});
		setEmailIds("");
		setEmailError(false);
	};

	const onDeleteIconClicked = (id: string) => {
		setIsLoading(true);
		DeleteScheduleReport(id)
			.then((response: any) => {
				setIsLoading(false);
				if (response && response.responseObj.message) {
					getScheduleReportData();
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const Option = (props: any) => {
		return (
			<div>
				<components.Option {...props}>
					<div className="d-flex w-100 justify-content-between">
						<div className="dropdown-checkbox">
							<input
								className="ml-auto"
								type="checkbox"
								checked={props.isSelected}
								onChange={() => null}
							/>
						</div>
						<div className="dropdown-lable">
							<label className="w-100 ml-0">{props.label}</label>
						</div>
					</div>
				</components.Option>
			</div>
		);
	};

	const MultiValue = (props: any) => (
		<components.MultiValue {...props}>
			<span>{props.data.label}</span>
		</components.MultiValue>
	);

	const animatedComponents = makeAnimated();

	const options = {
		print: true,
		download: true,
		viewColumns: false,
		downloadOptions: {
			filename: "SchduleReport.csv",
		},
		selectableRows: "none" as SelectableRows,
	};
	const columns = [
		{
			name: "_id",
			options: {
				filter: false,
				display: false, // hide this filed in Data-Table
			},
		},
		{
			name: "reports",
			label: "Reports",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex].length > 0 &&
								tableMeta.rowData[tableMeta.columnIndex].map(
									(report: any, i: number) => {
										return (
											<div>
												<span>{report}</span>
												<br />
											</div>
										);
									}
								)}
						</>
					);
				},
			},
		},
		{
			name: "emails",
			label: "Emails",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex].length > 0 &&
								tableMeta.rowData[tableMeta.columnIndex].map(
									(email: any, i: number) => {
										return (
											<div>
												<span>{email}</span>
												<br />
											</div>
										);
									}
								)}
						</>
					);
				},
			},
		},
		{
			name: "schedule",
			label: "Schedule",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "N/A"}{" "}
						</>
					);
				},
			},
		},
		{
			name: "location",
			label: "Location",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "N/A"}{" "}
						</>
					);
				},
			},
		},
		{
			name: "action",
			label: "ACTION",
			options: {
				empty: true,
				sort: false,
				filter: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="device-list-edit">
							<span className="p-1 mr-1 edit rounded-circle cursor-pointer">
								<EditIcon
									onClick={() => onEditIconClicked(tableMeta.rowData[0])}
								/>
							</span>
							<span
								className="p-1 delet rounded-circle cursor-pointer"
								onClick={() => onDeleteIconClicked(tableMeta.rowData[0])}
							>
								<DeleteIcon />
							</span>
						</div>
					);
				},
			},
		},
	];
	return (
		<div className="container-fluid">
			<div className="row mt-3">
				<div className="col-lg-12 col-md-12 col-sm-12 schedule-report bg-white p-3">
					<div className="col-lg-12">
						<h5>Schedule Report</h5>

						<div className="mt-3 d-flex">
							<div className="w-50 pr-3">
								<h6 className="">Report</h6>
								<div className="dropdown pl-0 pr-0">
									<MySelect
										components={{
											Option,
											MultiValue,
											animatedComponents,
										}}
										isMulti
										hideSelectedOptions={false}
										closeMenuOnSelect={false}
										onChange={onChangeReport}
										options={reports}
										allowSelectAll={true}
										value={selectedReport}
										allOption={{
											label: "Select All",
											value: "*",
										}}
									/>
								</div>
							</div>
							<div className="w-50">
								<h6 className="">Report Schedule</h6>
								<div className="dropdown pl-0 pr-0">
									<Select
										className="basic-multi-select"
										onChange={onChangeSchedule}
										options={schedule}
										placeholder="List of Schedule"
										value={selectedSchedule}
									/>
								</div>
							</div>
						</div>
						<div className="mt-3 d-flex">
							<div className="w-50 pr-3 mt-3">
								<h6 className="">Select Location</h6>
								<div className="dropdown pl-0 pr-0">
									<LocationSelector
										onSelectLocation={onChangeLocation}
										selectedLocationId={selectedLocation?.value}
									/>
								</div>
							</div>
							<div className="w-50 mt-3">
								<div className="mb-0 d-flex">
									<h6>Email IDs</h6>
									<p className="mb-0">
										(Add multiple email separated by comma)
									</p>
								</div>
								<div className="">
									<textarea
										id="textarea"
										className="schedule-input w-100"
										value={emailIds}
										onChange={onEmailEnter}
									></textarea>
									{isEmailError && <p className="text-danger">Invalid email</p>}
								</div>
							</div>
						</div>
						<div className="mt-4 mb-4">
							<button
								className="btn schedule-re-btn pl-5 pr-5"
								onClick={callSetScheduleAPI}
							>
								{isEditMode ? "Edit" : "Add"} Schedule
							</button>
							<button
								type="button"
								className="btn mopbtnc pl-5 pr-5"
								onClick={() => cancleClick()}
							>
								Cancel
							</button>
						</div>
						<div>
							<CustomDataTable
								title="Schedule Report"
								rows={scheduleReport}
								columns={columns}
								options={options}
							/>
						</div>
					</div>
				</div>

				{isLoading && <SpinnerLoader />}
			</div>
		</div>
	);
};
export default ScheduleReports;
