import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import pull from "lodash/pull";

import {
	updateDevice,
	getDeviceFeatureAndCategory,
	getCategoriesList,
} from "../../api/DevicesController";
import { RemediationDetailModal } from "../../modals/device/RemediationDetail";
import LocationSelector from "../LocationSelector/LocationSelector";
import { SpinnerLoader } from "../../common/Global.Style";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers";
import { getAssetDimension } from "../../api/AssetController";
import Select from "react-select";
import { colourStyles } from "../../pages/Devices/ConfigureDevice/ConfigureDevice";
import "./AddEditRemediation.scoped.scss";
import { MultiSelectWithCheckBox } from "../MultiSelectWithCheckbox/MultiSelectWithCheckbox";
import { FieldArray, useFormik, FormikProvider, ErrorMessage } from "formik";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { CategoryOptions } from "../../common/Interface";
import { RemoveCircle } from "@material-ui/icons";
import * as Yup from "yup";
import { locationMapSelector } from "../../redux/selectors/locationSelector";
import { getLocations } from "../../redux/actions/locationActions";

interface AddEditRemediationProps {
	show: boolean;
	onHide?: (isCallFromSave: boolean) => void;
	remediationDetailsModal: RemediationDetailModal;
}
interface Item {
	label: string;
	value: any;
	path?: string;
}

export interface ReplacementPart {
	name?: string;
	days?: number;
}

const AddEditRemediation: React.FC<AddEditRemediationProps> = ({
	show,
	onHide,
	remediationDetailsModal,
}) => {
	const asset = useSelector((state: RootState) => state.asset);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [initialAPiLoading, setInitialApiIsLoading] = useState<boolean>(false);
	const [remediationDetailsState, setRemediationDetailsState] =
		useState<RemediationDetailModal>(remediationDetailsModal);
	const [airParamData, setAirParamData] = useState<any>({});
	const [categoryData, setCategoryData] = useState<any>({});
	const [featureData, setfeatureData] = useState<any>({});
	const [categoryOption, setCategoryOption] = useState<any>();
	const [selectedRemediationType, setSelectedRemediationType] =
		useState<Item[]>();
	const [remediationType, setRemediationType] = useState<string[]>();
	const [selectedCategory, setSelectedCategory] = useState<CategoryOptions>();
	const [selectedReplacement, setSelectedReplacement] = useState<Item>();
	const [showReplacementSection, setShowReplacementSection] =
		useState<boolean>(false);
	const replacementOptions: Item[] = [
		{ label: "Yes", value: true },
		{ label: "No", value: false },
	];
	const [selectedLocations, setSelectedLocations] = useState<Array<any>>([]);
	const dispatch = useDispatch();
	const locations: Item[] = locationMapSelector(
		useSelector((state: RootState) => state)
	);

	let [defaultRemediationTypes] = useState<Array<Item>>([
		{
			label: "Particulate Matter (PM)",
			value: ["pm25_value", "pm10", "pm1"],
		},
		{ label: "Carbon di oxide (CO2)", value: ["co2_value"] },
		{
			label: "Volatile Organic Compounds (VOC)",
			value: ["voc_value", "ch2o_value"],
		},
		{ label: "Humidifier", value: ["humidity_value"] },
		{ label: "Dehumidifier", value: ["humidity_value"] },
	]);

	useEffect(() => {
		if (asset._id) {
			initialApiLoading();
		}
	}, [asset]);

	useEffect(() => {
		if (remediationDetailsState.deviceType === "interops") {
			const id = remediationDetailsState.building;
			dispatch(getLocations([], (id && id) ?? ""));
		}
	}, []);

	useEffect(() => {
		if (remediationDetailsState.location.length) {
			setSelectedLocations(
				remediationDetailsState.location.map((item: any) => {
					return {
						value: item,
						label: locations.find((x: Item) => x.value === item)?.label,
					};
				})
			);
		}
	}, [remediationDetailsState, locations]);

	useEffect(() => {
		if (
			remediationDetailsState?.deviceType === "interops" &&
			!isEmpty(featureData)
		) {
			selectedRemediationType?.forEach((x: any) => {
				if (x.label === "Humidifier" || x.label === "Dehumidifier") {
					const selectedFeature: any = Object.values(featureData)[0];
					if (
						(x.label === "Humidifier" &&
							selectedFeature?.name !== "Humidifier") ||
						(x.label === "Dehumidifier" &&
							selectedFeature?.name !== "Dehumidifier")
					) {
						pull(selectedRemediationType, x);
					}
				}
			});
		}
	}, [featureData, selectedRemediationType]);

	const initialApiLoading = async () => {
		setInitialApiIsLoading(true);
		await loadDimension();
		await loadCategoryAndFeature();
		await getCategoriesListApi();
		setInitialApiIsLoading(false);
	};

	const loadDimension = async () => {
		const response = await getAssetDimension(asset._id);
		if (response.responseObj) {
			let tempDimData: any = {};
			get(response, "responseObj", []).forEach((mapData: any) => {
				tempDimData[mapData._id] = mapData;
			});
			setAirParamData(tempDimData);

			let remediationTypes: any = {};
			response.responseObj.forEach((dimension: any) => {
				remediationTypes[dimension["code"]] = dimension["_id"];
			});

			defaultRemediationTypes.map((item: any) => {
				let value = item.value.map((data: any) => {
					return remediationTypes[data];
				});
				item.value = value;
			});

			let selectedRemediationType: any = [];

			defaultRemediationTypes.forEach((x: any) => {
				if (
					!isEmpty(intersection(remediationDetailsState.dimensions, x.value))
				) {
					selectedRemediationType.push({ label: x.label, value: x.value });
				}
			});
			setSelectedRemediationType(selectedRemediationType);
		}
	};

	const loadCategoryAndFeature = async () => {
		const response = await getDeviceFeatureAndCategory({
			categoryId: remediationDetailsState.category,
			featureIds: remediationDetailsState.features,
		});
		if (response.responseObj) {
			setCategoryData(get(response, "responseObj.categoryData", {}));
			let tempDimData: any = {};
			get(response, "responseObj.featureData", []).map((mapData: any) => {
				tempDimData[mapData._id] = mapData;
			});
			setfeatureData(tempDimData);
		}
	};

	const onChangeLocation = (data: any) => {
		const tempData = cloneDeep(remediationDetailsState);
		tempData.location = data.value;
		setRemediationDetailsState(tempData);
	};

	const onDescChange = (e: any) => {
		const tempData = cloneDeep(remediationDetailsState);
		tempData.description = e.target.value;
		setRemediationDetailsState(tempData);
	};

	const saveDetails = (values: any) => {
		remediationDetailsState.replacementParts = showReplacementSection
			? formik.values.replacementParts
			: [];
		const remediationDetails = cloneDeep(remediationDetailsState);
		callUpdateRemediationDetail(remediationDetails);
	};

	const callUpdateRemediationDetail = async (
		remediationDetails: RemediationDetailModal
	) => {
		setIsLoading(true);
		const response = await updateDevice(remediationDetails);
		setIsLoading(false);
		if (response.responseObj) {
			onHide && onHide(true);
		}
	};

	const getCategoriesListApi = async () => {
		const productCategories: any = await getCategoriesList(true);

		if (productCategories?.responseObj) {
			setCategoryOption(
				productCategories?.responseObj.map((mapData: any) => ({
					value: mapData,
					label: mapData.name,
				}))
			);
			if (remediationDetailsState && categoryOption) {
				const selectedCategory = categoryOption.filter((x: any) =>
					remediationDetailsState.category.includes(x.value)
				);
				setSelectedCategory(selectedCategory);
			}

			if (remediationDetailsState.replacementParts.length > 0) {
				setShowReplacementSection(true);
				setSelectedReplacement(replacementOptions[0]);
			} else {
				setShowReplacementSection(false);
				setSelectedReplacement(replacementOptions[1]);
			}
		}
	};

	const onChangeCategory = (event: any) => {
		setSelectedCategory(event);
		remediationDetailsState.category = event?.value?._id;
		remediationDetailsState.features = event?.value?.features;
	};

	const onChangeRemediationType = (event: any) => {
		setSelectedRemediationType(event);
		let dimensions: string[] = [];
		event?.forEach((data: any) => {
			dimensions.push(data.value);
		});
		remediationDetailsState.dimensions = flatten(dimensions);
		setRemediationType(flatten(dimensions));
	};

	const onChangeReplacement = (event: any) => {
		setSelectedReplacement(event);
		setShowReplacementSection(event.value);
	};

	let validationSchemaTemp: any = {
		name: Yup.string().trim().required("Name is required"),
		replacementParts: null,
	};

	if (showReplacementSection) {
		validationSchemaTemp.replacementParts = Yup.array().of(
			Yup.object().shape({
				name: Yup.string().required("Name is required"),
				days: Yup.number()
					.min(1, "Minimum 1 day required")
					.max(9999, "Maximum days should be lesser than 4 years"),
			})
		);
	}

	const formik = useFormik({
		initialValues: {
			replacementParts: remediationDetailsState.replacementParts.length
				? (remediationDetailsState.replacementParts as ReplacementPart[])
				: [
						{
							name: "",
							days: 0,
						},
				  ],
		},
		validationSchema: Yup.object().shape(validationSchemaTemp),
		onSubmit: (values: any) => {
			saveDetails(values);
		},
	});

	const resetParts = (index: any, parts: any) => {
		setIsLoading(true);
		let remediationDetails: any = new RemediationDetailModal();
		remediationDetails = remediationDetailsState;
		parts[index].dueDate = moment().add(parts[index].days, "days").toDate();
		const reqBody: any = {};
		reqBody.replacementParts = parts;
		reqBody._id = remediationDetails._id;
		callUpdateRemediationDetail(reqBody);
	};

	return (
		<Modal
			show={show}
			onHide={() => onHide && onHide(false)}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<h5 className="mb-0">Edit Remediation Device</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container-fluid">
					<FormikProvider value={formik}>
						<form onSubmit={formik.handleSubmit}>
							<div>
								<div className="mb-4 mt-2">
									<h3 className="device-name">
										{remediationDetailsState.deviceName}
									</h3>
									<p className="desc-medium font-500 font-secondary mb-1">
										Category:
										<strong className="font-primary ml-1">
											{categoryData.name}
										</strong>
									</p>
									<p className="desc-medium font-500 font-secondary">
										Area Coverage:
										<strong className="font-primary ml-1">
											{remediationDetailsState.areaCoverage}
										</strong>
									</p>
								</div>

								<div className="row mb-2">
									<div className="col-12">
										<div className="form-group">
											<h6>Description</h6>
											<input
												id="description"
												defaultValue={remediationDetailsState.description}
												onChange={onDescChange}
												type="text"
												className="input w-100 p-2"
												placeholder="Device Description"
											/>
										</div>
									</div>
									{remediationDetailsState.deviceType !== "interops" && (
										<div className="col-12">
											<div className="form-group">
												<h6>Location</h6>
												<div className="dropdown w-100 pl-0 pr-0">
													<LocationSelector
														onSelectLocation={onChangeLocation}
														selectedLocationId={
															remediationDetailsState?.location.length
																? remediationDetailsState?.location[0]
																: ""
														}
														buildingId={remediationDetailsModal.building}
														selectProps={{
															isClearable: false,
														}}
													/>
												</div>
											</div>
										</div>
									)}

									{remediationDetailsState.deviceType === "interops" && (
										<div className="ml-3 w-50">
											<h6>Locations</h6>
											<div className="dropdown w-100 pl-0 pr-0">
												<MultiSelectWithCheckBox
													seletedOptions={locations}
													selectValue={selectedLocations}
													placeholder="Select locations"
													onSelectionChange={(event: Item[]) => {
														setSelectedLocations(event);
														remediationDetailsState.location = event.map(
															(item: Item) => {
																return item.value;
															}
														);
													}}
												/>
											</div>
										</div>
									)}

									{remediationDetailsState.deviceType === "interops" && (
										<div className="w-100">
											<div className="col-12">
												<div className="form-group">
													<h6>Category</h6>
													<div className="dropdown w-100 pl-0 pr-0">
														<Select
															className="basic-select"
															classNamePrefix="select"
															name="category"
															placeholder="Select Category"
															onChange={onChangeCategory}
															options={categoryOption}
															value={{
																label: categoryData.name,
																value: categoryData,
															}}
															styles={colourStyles}
														/>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<h6>Remediation Type</h6>
													<div className="dropdown w-100 pl-0 pr-0">
														<MultiSelectWithCheckBox
															seletedOptions={defaultRemediationTypes}
															selectValue={selectedRemediationType}
															onSelectionChange={onChangeRemediationType}
														/>
													</div>
													<span className="desc-normal">
														Choose the right remediation type, auto-remediation
														works based on this selection
													</span>
												</div>
											</div>
											<div className="w-100 form-group d-flex ml-3">
												<div className="w-50 form-group mr-4">
													<h6>Replacement Parts</h6>
													<div className="dropdown w-100  pl-0 pr-0">
														<Select
															className="basic-select"
															classNamePrefix="select"
															onChange={onChangeReplacement}
															options={replacementOptions}
															value={selectedReplacement}
														/>
													</div>
												</div>
											</div>
											{showReplacementSection && (
												<div className="w-100 bg-light add-re p-3 ml-3">
													<FieldArray
														name="replacementParts"
														render={(arrayHelpers) => (
															<>
																{formik.values.replacementParts &&
																	formik.values.replacementParts.map(
																		(
																			replacementPart: ReplacementPart,
																			index: number
																		) => (
																			<div
																				className="col-lg-12 p-0 add-re-pr d-flex mb-3"
																				key={index}
																			>
																				<div className="col-lg-4 form-group">
																					<h6>Part Name {index + 1}</h6>
																					<input
																						type="text"
																						className="add-re-input bg-white form-control pt-2 pb-2 ml-1"
																						placeholder="input"
																						name={`replacementParts[${index}].name`}
																						id={`replacementParts[${index}].name`}
																						value={replacementPart.name}
																						onChange={formik.handleChange}
																					/>
																					<ErrorMessage
																						name={`replacementParts[${index}].name`}
																						component="div"
																						className="text-danger"
																					/>
																				</div>
																				<div className="col-lg-4 form-group mb-3">
																					<h6>Replacement Time (in days)</h6>
																					<input
																						type="number"
																						className="add-re-input bg-white form-control pt-2 pb-2 ml-1"
																						placeholder="Days"
																						name={`replacementParts[${index}].days`}
																						id={`replacementParts[${index}].days`}
																						value={replacementPart?.days}
																						onChange={formik.handleChange}
																					/>
																					<ErrorMessage
																						name={`replacementParts[${index}].days`}
																						component="div"
																						className="text-danger"
																					/>
																				</div>
																				{formik.values.replacementParts
																					.length >= 1 && (
																					<div className="col-lg-2 align-items-center d-flex justify-content-center">
																						<RemoveCircle
																							onClick={() =>
																								arrayHelpers.remove(index)
																							}
																							className="txt-light"
																						/>
																					</div>
																				)}
																				<div className="col-lg-2 pt-4 pb-2">
																					<button
																						type="button"
																						className="btn add w-100"
																						onClick={() =>
																							resetParts(
																								index,
																								formik.values.replacementParts
																							)
																						}
																					>
																						Reset
																					</button>
																				</div>
																			</div>
																		)
																	)}
																<div className="text-right plus-icon">
																	<span className="mt-2 rounded-circle p-3">
																		<AddIcon
																			onClick={() =>
																				arrayHelpers.push({
																					name: "",
																					days: 0,
																				})
																			}
																		/>
																	</span>
																</div>
															</>
														)}
													/>
												</div>
											)}
										</div>
									)}
								</div>

								<div className="mb-4">
									{!initialAPiLoading && (
										<>
											<div className="pb-2 ml-1">
												<h6 className="mb-0">Device Features</h6>
												<div className="d-flex flex-wrap">
													{get(remediationDetailsState, "features", []).map(
														(mapData: any) => (
															<span
																key={mapData}
																className="dimension mt-2 mr-2 py-1 px-2"
															>
																{get(featureData, `${mapData}.name`)}
															</span>
														)
													)}
												</div>
											</div>
											<div className="mt-4 pb-2 ml-1">
												<h6 className="mb-0">Air quality parameters</h6>
												<div className="d-flex flex-wrap">
													{uniq(
														get(remediationDetailsState, "dimensions", [])
													).map((mapData: string) => {
														return (
															<span
																className="dimension mt-2 mr-2 py-1 px-2"
																key={mapData}
															>
																{get(airParamData, `${mapData}.shortName`, "")}
															</span>
														);
													})}
												</div>
											</div>
										</>
									)}

									{remediationDetailsState?.deviceType !== "interops" && (
										<div className="mt-4 pb-2">
											<h6 className="mb-4">
												Replacement Parts:
												<strong>
													{remediationDetailsState?.replacementParts?.length
														? "Yes"
														: "No"}
												</strong>
											</h6>

											<div className="replacement-parts-list">
												{remediationDetailsState?.replacementParts?.map(
													(mapData: any) => (
														<p className="desc-normal replacement-part-item d-flex justify-content-between mb-4">
															<span>
																Part Name :
																<span className="font-700 font-primary">
																	{mapData.name}
																</span>
															</span>
															<span>
																Replacement time in days :
																<span className="font-700 font-primary">
																	{mapData.days}
																</span>
															</span>
														</p>
													)
												)}
											</div>
										</div>
									)}
								</div>

								<div className="d-flex mt-4 justify-content-end">
									<button
										type="button"
										className="btn mopbtnc px-4"
										onClick={() => onHide && onHide(false)}
									>
										Cancel
									</button>
									<button
										onClick={saveDetails}
										className="btn add px-4 mr-0"
										type="button"
									>
										Update
									</button>
								</div>
							</div>
						</form>
					</FormikProvider>
					{isLoading && <SpinnerLoader />}
				</div>
			</Modal.Body>
		</Modal>
	);
};
export default AddEditRemediation;
