import React, { FC, useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import { getAutoCompleteAddress } from "../../api/buildingsController";

interface autoCompleteProps {
	setFormikAddress?: any;
	formikFormData?: any;
	getTimeZone?: any;
}
const AddressAutoComplete: FC<autoCompleteProps> = ({
	setFormikAddress,
	formikFormData,
	getTimeZone,
}) => {
	const [addressList, setAddressList] = useState<any>([]);
	const [selectedAddress, setSelectedAddress] = useState<any>(formikFormData);
	let timer: any;
	const addressOnChange = (event: any) => {
		setSelectedAddress(event.target.value);
		clearTimeout(timer);
		timer = setTimeout(async () => {
			const response = await getAutoCompleteAddress(event.target.value);
			if (response.responseObj && response.responseObj?.list) {
				response.responseObj?.list.length > 0
					? setAddressList(response.responseObj?.list)
					: setAddressList([]);
			}
		}, 100);
	};
	const selectAddress = (value: any) => {
		setSelectedAddress(value);
		setFormikAddress(value);
		const latlng = addressList.filter((add: any) => add.address === value);
		getTimeZone(latlng);
	};
	useEffect(() => {
		setSelectedAddress(formikFormData);
	}, [formikFormData]);
	return (
		<div className="auto-address-input">
			<Autocomplete
				getItemValue={(item: any) => item.address}
				items={addressList}
				renderMenu={(item) => (
					<div
						className={
							"dropdown " + (addressList.length === 0 ? "dropdown-invalid" : "")
						}
					>
						{item}
					</div>
				)}
				renderItem={(item, isHighlighted): any => (
					<div
						className="auto-complete-address"
						style={{
							background: isHighlighted ? "lightblue" : "white",
						}}
					>
						{item.address}
					</div>
				)}
				value={selectedAddress}
				onChange={(event) => addressOnChange(event)}
				onSelect={(val) => selectAddress(val)}
			/>
		</div>
	);
};
export default AddressAutoComplete;
