export const validationErrorMessage = {
	CONTACT: "Please enter 10 digit valid phone number without country code",
	EMAIL: "Please enter valid email address",
	SIM_LENGTH: "SIM number should 19 or 20 character length",
	SIM_PATTERN:
		"SIM can be 19 digits number or it can be 19 digits number ends with an Alphabet",
};

export const requiredFieldError = {
	CONTACT: "Alert Phone Number is required",
	EMAIL: "Alert Email is required",
	ASSET_NAME: "Name is required",
	ACC_TYPE: "Please select account type",
	SIMNO: "Sim number required",
	PRICE_ID: "Plan is required",
};
