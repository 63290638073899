import { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useParams, useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash/debounce";

import { SpinnerLoader } from "../../common/Global.Style";
import { Product } from "../MarketPlace/Product";
import { getMarketPlaceProduct } from "../../api/MarketPlaceController";

import "./ProductList.scoped.scss";

const ProductList = () => {
	const params: any = useParams();
	const history = useHistory();
	const { category_id } = params;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [productList, setProductList] = useState([]);
	const [initialProductList, setInitialProductList] = useState([]);

	useEffect(() => {
		getProductsApiCall(category_id, false);
	}, []);

	const getProductsApiCall = async (category: string, feature: boolean) => {
		setIsLoading(true);
		const response = await getMarketPlaceProduct(category, feature);
		if (response?.responseObj?.length) {
			setProductList(get(response, "responseObj", []));
			setInitialProductList(get(response, "responseObj", []));
		}
		setIsLoading(false);
	};

	const searchAutopilotLog = (e: any) => {
		let { value } = e.target;
		value = value.toLowerCase();
		setProductList(
			initialProductList.filter((filterData: any) => {
				if (
					filterData?.name?.toLowerCase()?.includes(value) ||
					filterData?.description?.toLowerCase()?.includes(value) ||
					filterData?.specificaion?.toLowerCase()?.includes(value) ||
					filterData?.brand?.toLowerCase()?.includes(value)
				) {
					return true;
				}
				return false;
			})
		);
	};

	return (
		<div className="p-4">
			<div className="market-place-container">
				{isLoading && <SpinnerLoader />}
				<div className="d-flex cursor-pointer" onClick={() => history.goBack()}>
					<ArrowBackIosIcon fontSize="small" />
					<p className="breadcrumb-para">IAQ Store</p>
				</div>

				<div className="search-section mt-2">
					<input
						onChange={debounce(searchAutopilotLog, 1000)}
						type="text"
						placeholder="search"
					/>
					<span>
						<SearchIcon />
					</span>
				</div>

				<div className="market-block">
					<div>
						<div className="recomended-products d-flex ">
							{productList?.map((mapData: any) => (
								<Product
									name={mapData.name}
									id={mapData?._id}
									imgSrc={get(mapData, "images[0]")}
									isFeatured={get(mapData, "isFeatured")}
								/>
							))}
							{isEmpty(productList) && (
								<div className="d-flex justify-content-center mt-5 w-100">
									<p>No Product Available</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductList;
