import { useEffect, useState } from "react";
import CanvasJSReact from "../../../lib/CanvasJs/canvasjs.stock.react";
import { isEmpty } from "lodash";
import Shared from "../../../common/Shared";

const CanvasJSStockChart = CanvasJSReact.CanvasJSChart;

export const CanvasExposureChart = ({ title, graphData }: any) => {
	interface DataPoints {
		name: string;
		y: any;
		color: string;
	}

	const [containerProps] = useState({
		width: "250px",
		height: "220px",
		margin: "auto",
	});

	const generateDataPoints = () => {
		const groupDataValues: DataPoints[] = [];
		if (!isEmpty(graphData)) {
			Object.entries(graphData).forEach(([key, value]: [string, any]) => {
				groupDataValues.push({
					name: key,
					y: value,
					color: Shared.exposureGraphColorCodes[key],
				});
			});
		}
		return groupDataValues;
	};

	const optionsValues = () => {
		return {
			animationEnabled: true,
			exportEnabled: false,
			legend: {
				fontSize: 14,
				fontFamily: "sans-serif",
			},
			subtitles: [
				{
					text: title,
					verticalAlign: "center",
					fontSize: 14,
					dockInsidePlotArea: true,
					fontFamily: "sans-serif",
				},
			],
			data: [
				{
					type: "doughnut",
					indexLabelFontSize: 17,
					toolTipContent: "<b>{name}:</b> (#percent%)",
					legendText: "{name}: #percent%",
					indexLabelWrap: true,
					showInLegend: true,
					dataPoints: generateDataPoints(),
				},
			],
		};
	};

	let options = optionsValues();

	useEffect(() => {
		options = optionsValues();
	}, [graphData]);
	return (
		<>
			{options?.data[0]?.dataPoints.length && (
				<CanvasJSStockChart containerProps={containerProps} options={options} />
			)}
		</>
	);
};
