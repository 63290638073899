import apiPlugin, { ApiResponse } from "./apiPlugin";
import { IAQReportSchedule } from "../types/IAQReportSchedule";

export async function getIAQReport(data: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		"v2.0/reports/iaq?" + data,
		undefined,
		undefined,
		undefined,
		true,
		undefined,
		true
	);
}

export async function getMergeIAQReport(data: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "v2.0/reports/iaq-merged?" + data);
}

export async function getMergeIAQLinear(data: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		"v2.0/reports/iaq-linear?" + data,
		undefined,
		undefined,
		undefined,
		true
	);
}

export async function getAutopilotReport(data: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "v2.0/reports/autopilots?" + data);
}

export async function getConsultationReport(
	data: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "v2.0/reports/consultation?" + data);
}

export async function createConsultation(data: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "v2.0/reports/consultation", undefined, data);
}

export async function deleteConsultation(
	consultationId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"DELETE",
		`v2.0/reports/consultation/${consultationId}`
	);
}

export async function updateConsultationReport(
	consultationId: string,
	data: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		`v2.0/reports/consultation/${consultationId}`,
		undefined,
		data
	);
}

export async function getMaintenanceReport(
	buildingId: string,
	asset: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		`reports/maintenance?building=${buildingId}&assetId=${asset}`
	);
}

export async function getScheduleReport(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "reports/schedule-reports");
}

export async function setReportSchedule(body: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "reports/create-schedule", undefined, body);
}

export async function DeleteScheduleReport(id: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", "reports/schedule-reports/" + id);
}

export async function updateReportSchedule(
	id: any,
	body: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		"reports/schedule-reports/" + id,
		undefined,
		body
	);
}

export async function createIAQReportSchedule(
	data: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "reports/iaq-create-schedule", undefined, data);
}

export async function getIAQReportSchedules(
	buildingId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", `reports/iaq-schedule-reports/${buildingId}`);
}

export async function deleteIAQReportSchedule(
	scheduleId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", "reports/iaq-schedule-reports/" + scheduleId);
}

export async function editIAQScheduleReport(
	schedule: IAQReportSchedule
): Promise<ApiResponse<IAQReportSchedule>> {
	return apiPlugin<IAQReportSchedule>(
		"PUT",
		"reports/iaq-schedule-reports/" + schedule?._id,
		undefined,
		schedule
	);
}
