import { useEffect, useState } from "react";
import { SelectableRows } from "mui-datatables";
import get from "lodash/get";

import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import { SpinnerLoader } from "../../common/Global.Style";
import moment from "moment";
import { getOfflineSubscriptionList } from "../../api/SubscriptionController";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import "./Subscription.scopped.scss";

const OfflineSubscription = () => {
	const [list, setList] = useState([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const user = useSelector((state: RootState) => state.user);

	const statusText: any = {
		0: "Not Sent",
		1: "Invoice Sent",
		2: "Awaiting Check",
		3: "Paid",
		4: "Overdue",
		5: "Suspended",
	};

	useEffect(() => {
		getSubscriptionApiCall();
	}, []);

	const getSubscriptionApiCall = async () => {
		setIsLoading(true);
		const response = await getOfflineSubscriptionList(user?._id);
		if (response?.responseObj?.list) {
			const tempData = get(response, "responseObj.list", []);
			setList(tempData);
		}
		setIsLoading(false);
	};

	const columns = [
		{
			name: "asset",
			label: "Customer Account",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => (
					<p className="table-p mb-0">{value}</p>
				),
			},
		},
		{
			name: "activeDevices",
			label: "Active Devices",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "licenseCode",
			label: "License Code",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => (
					<p className="table-p mb-0">{value}</p>
				),
			},
		},
		{
			name: "count",
			label: "Seats Count",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => (
					<p className="table-p mb-0">{value}</p>
				),
			},
		},
		{
			name: "activatedAt",
			label: "License Activated Date",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => (
					<p className="table-p mb-0">
						{value ? `${moment(value).format("MMM DD YYYY")}` : "-"}
					</p>
				),
			},
		},
		{
			name: "isExceeded",
			label: "Exceeding License Tier",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => (
					<p className="table-p mb-0">{value ? "Yes" : "No"}</p>
				),
			},
		},
		{
			name: "status",
			label: "Invoice Status",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any) => (
					<p className="table-p mb-0">{statusText[value]}</p>
				),
			},
		},
	];

	const options = {
		print: false,
		download: false,
		viewColumns: false,
		responsive: "standard",
		selectableRows: "none" as SelectableRows,
	};

	const renderMobileCard = (mapData: any) => (
		<div>
			<div className="d-flex justify-content-between">
				<p className="desc-normal font-primary font-bold text-truncate mb-2 m-mv-200 asset-name">
					{mapData?.asset}
				</p>
			</div>
			<div className="d-flex card-first-block">
				<div className="d-flex flex-1 flex-column">
					<p className="desc-small card-title-color d-flex justify-content-between mb-0">
						Active Devices
					</p>
					<p className="desc-small font-bold card-value-color d-flex justify-content-between mb-0">
						<span>{mapData.activeDevices}</span>
					</p>
				</div>
				<div className="d-flex flex-1 flex-column">
					<p className="desc-small card-title-color d-flex justify-content-between mb-0">
						License Code
					</p>
					<p className="desc-small font-bold card-value-color d-flex justify-content-between mb-0">
						<span>{mapData?.licenseCode}</span>
					</p>
				</div>
				<div className="d-flex flex-1 flex-column">
					<p className="desc-small card-title-color  d-flex justify-content-between mb-0">
						Exceeding License Tier
					</p>
					<p className="desc-small font-bold card-value-color d-flex justify-content-between mb-0">
						<span>{mapData?.isExceeded ? "Yes" : "No"}</span>
					</p>
				</div>
			</div>

			<div className="d-flex justify-content-between card-first-block mt-3">
				<div className="d-flex flex-1 flex-column">
					<p className="desc-small card-title-color  d-flex justify-content-between mb-0">
						Invoice Status
					</p>
					<p className="desc-small font-bold card-value-color d-flex justify-content-between mb-0">
						{statusText[mapData?.status]}
					</p>
				</div>
			</div>
		</div>
	);

	return (
		<div className="ml-2">
			{isLoading && <SpinnerLoader />}
			<div className="d-flex justify-content-between mb-2">
				<h3>Subscription</h3>
			</div>
			<p className="pl-1 desc-small font-grey-light-6 ls-024 lh-18 mb-2">
				Subscription data is refreshed daily by EOD
			</p>
			<div className="m-d-none">
				<CustomDataTable
					title=""
					rows={list}
					columns={columns}
					options={options}
				/>
			</div>

			{/* Mobile Card Design */}
			<div className="m-customer-account d-none m-d-block">
				<div className="m-customer-account-cards">
					{!!list.length &&
						list.map((mapData: any, index: number) => (
							<div
								className={
									list.length - 1 === index
										? "m-customer-account-card mb-2 subscription-last-element"
										: "m-customer-account-card mb-2"
								}
							>
								{renderMobileCard(mapData)}
							</div>
						))}
				</div>
				{!list.length && (
					<div className="d-flex justify-content-center">
						<p>Sorry, no matching records found</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default OfflineSubscription;
