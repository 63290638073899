import React, { useEffect, useState, forwardRef } from "react";
import get from "lodash/get";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { SelectableRows } from "mui-datatables";
import { useSelector } from "react-redux";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { CSVLink } from "react-csv";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { Dropdown } from "react-bootstrap";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import cloneDeep from "lodash/cloneDeep";
import ClearIcon from "@material-ui/icons/Clear";
import debounce from "lodash/debounce";
import SearchIcon from "@material-ui/icons/Search";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";

import { RootState } from "../../../redux/reducers";
import {
	deleteSchedule,
	getScheduleList,
} from "../../../api/SchedulerController";
import "./List.scoped.scss";
import LocationSelector from "../../../components/LocationSelector/LocationSelector";
import NewOrEditSchedule from "../AddEditSchedule/AddEditSchedule";
import { ScheduleModal } from "../../../modals/device/Schedule";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { SpinnerLoader } from "../../../common/Global.Style";
import CustomDataTable from "../../../components/CustomDataTable/CustomDataTable";
import PaymentPendingInfo from "../../../components/PaymentPendingInfo/PaymentPendingInfo";
import { URL } from "../../../components/Navbar/routes";
import { UserRoles } from "../../../enums/UserRoles";
import SystemTour from "../../../components/SystemTour/SystemTour";

interface Item {
	label: string;
	value: number;
}

const ScheduleList: React.FC = () => {
	const history = useHistory();

	const [scheduleList, setScheduleList] = useState([]);
	const [filteredScheduleList, setFilteredScheduleList] = useState<Array<any>>(
		[]
	);
	const [selectedLocation, setSelectedLocation] = useState<Array<Item>>([]);
	const [modalShow, setModalShow] = React.useState(false);
	const [selectedScheduleId, setSelectedScheduleId] = useState<string>("");
	const [deleteConfirmationMessage, setDeleteConfirmationMessage] =
		useState<string>("Are you sure want to delete?");
	const [editConfirmationMessage, setEditConfirmationMessage] =
		useState<string>("");
	const [isShownConfirmDialog, setIsShownConfirmDialog] =
		useState<boolean>(false);
	const [isShownEditConfirmDialog, setIsShownEditConfirmDialog] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [downloadCsvData, setDownloadCsvData] = useState<any>([]);

	const building = useSelector((state: RootState) => state.building);
	const asset = useSelector((state: RootState) => state.asset);
	const user = useSelector((state: RootState) => state.user);

	const daysList = ["Su", "Mo", "Tue", "We", "Thu", "Fr", "Sa"];
	const [scheduleDetail, setScheduleDetail] = useState<ScheduleModal>(
		new ScheduleModal()
	);
	const [tourSteps] = useState<any>([
		{
			selector: ".create-schedule-tour",
			content: `Click here to set up work schedule for remediation devices`,
		},
	]);

	const options = {
		print: false,
		download: true,
		viewColumns: false,
		selectableRows: "none" as SelectableRows,
		downloadOptions: {
			filename: "Schedule.csv",
		},
		onDownload: () => {
			return false;
		},
		customSearch: (searchQuery: any, currentRow: any) => {
			return get(currentRow, `[2][0].name`, "").includes(searchQuery);
		},
	};

	const editColumnData = [
		{
			name: "action",
			label: "OPERATIONS",
			options: {
				empty: true,
				filter: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="device-list-edit">
							<span className="p-1 mr-1 edit rounded-circle cursor-pointer">
								<EditIcon
									onClick={() =>
										onEditIconClicked(
											tableMeta.rowData[0],
											tableMeta.rowData[9]
										)
									}
								/>
							</span>
							<span
								className="p-1 delete rounded-circle cursor-pointer"
								onClick={() =>
									onDeleteIconClicked(
										tableMeta.rowData[0],
										tableMeta.rowData[9]
									)
								}
							>
								<DeleteIcon />
							</span>
						</div>
					);
				},
			},
		},
	];

	const [editColumn, setEditColumn] = useState<any>([...editColumnData]);

	const columns = [
		{
			name: "_id",
			options: {
				filter: false,
				display: false, // hide this filed in Data-Table
			},
		},
		{
			name: "sno",
			label: "S.NO",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <>{tableMeta.rowIndex + 1}</>;
				},
			},
		},
		{
			name: "locations",
			label: "LOCATIONS",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex].map(
								(location: any, i: number) => {
									return (
										<div>
											<span>{location["name"]}</span>
											<br />
										</div>
									);
								}
							)}
						</>
					);
				},
			},
		},
		{
			name: "name",
			label: "NAME",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <span>{tableMeta.rowData[tableMeta.columnIndex]}</span>;
				},
			},
		},
		{
			name: "devices",
			label: "DEVICES",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex].length > 0 &&
								tableMeta.rowData[tableMeta.columnIndex].map(
									(device: any, i: number) => {
										return (
											<div>
												<span>{device["deviceName"]}</span>
												<br />
											</div>
										);
									}
								)}
						</>
					);
				},
			},
		},
		{
			name: "timeTo",
			options: {
				display: false, // hide this filed in Data-Table
				filter: false,
			},
		},
		{
			name: "timeFrom",
			label: "START TIME",
			options: {
				empty: true,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <span>{tableMeta.rowData[tableMeta.columnIndex]}</span>;
				},
			},
		},
		{
			name: "interval",
			label: "DURATION (Hrs)",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <span>{tableMeta.rowData[tableMeta.columnIndex]}</span>;
				},
			},
		},
		{
			name: "days",
			label: "DAY",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					value = value.sort();
					return (
						<>
							{value?.length &&
								value.map((day: any, i: number) => {
									return (
										<div>
											<span>{daysList[day]}</span>
											<br />
										</div>
									);
								})}
						</>
					);
				},
			},
		},
		{
			name: "status",
			label: "STATUS",
			options: {
				empty: true,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							<span
								className={`${
									tableMeta.rowData[tableMeta.columnIndex]
										? "text-success"
										: "text-grey"
								}`}
							>
								<FiberManualRecordIcon />
							</span>
							{tableMeta.rowData[tableMeta.columnIndex] ? "ON" : "OFF"}{" "}
						</>
					);
				},
			},
		},
		...editColumn,
	];

	useEffect(() => {
		getSchedule();
	}, [building]);

	useEffect(() => {
		if (filteredScheduleList?.length) {
			const processedData = filteredScheduleList.map((mapData: any) => {
				let dayText = "";
				get(mapData, "days", []).forEach((item: any) => {
					dayText = dayText + daysList[item] + ",";
				});
				return {
					Name: mapData.name,
					StartTime: mapData.timeFrom,
					Duration_Hrs: mapData.interval,
					Devices: mapData?.devices
						?.map((deviceData: any) => deviceData?.deviceName)
						.join(", "),
					Day: dayText,
					Locations: mapData?.locations
						?.map((locationData: any) => locationData?.name)
						.join(", "),
					Status: mapData.status ? "ON" : "OFF",
					_id: mapData._id,
				};
			});
			setDownloadCsvData(processedData);
		}
	}, [filteredScheduleList]);

	useEffect(() => {
		const isCreatedUser = user._id === asset.userId;
		let isAdmin = true;
		if (isCreatedUser) {
			isAdmin = isCreatedUser;
		} else {
			const assetData = user?.assets?.find(
				(mapData: any) => mapData._id === asset._id
			);
			isAdmin = assetData?.permissions?.includes("write");
		}
		if (!isAdmin) {
			setEditColumn([]);
		} else {
			setEditColumn(editColumnData);
		}
	}, [asset]);

	const getSchedule = () => {
		setIsLoading(true);

		getScheduleList(building._id)
			.then((response: any) => {
				setIsLoading(false);

				if (response.responseObj) {
					const finalRes = response.responseObj.results.map((result: any) => {
						result.key = result._id;
						return result;
					});
					setScheduleList(finalRes);
					setFilteredScheduleList(finalRes);
				} else {
					setScheduleList([]);
					setFilteredScheduleList([]);
				}
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const deleteDevice = () => {
		setIsLoading(true);
		deleteSchedule(selectedScheduleId)
			.then((response: any) => {
				setIsLoading(false);
				if (response && response.responseObj.success) {
					getSchedule();
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
		dialogCloseClick();
	};

	const onChangeLocation = (value: any) => {
		setSelectedLocation(value);
		filterScheduleByLocation(value);
	};

	const onEditIconClicked = (id: string, status: boolean) => {
		if (status) {
			setEditConfirmationMessage(
				"This schedule is currently active. Do you still want to proceed? This action will reset the current schedule"
			);
			setIsShownEditConfirmDialog(true);
			setSelectedScheduleId(id);
		} else {
			let tempScheduleList: any;
			setFilteredScheduleList((prevState) => {
				tempScheduleList = prevState;
				return prevState;
			});
			const scheduleTemp = tempScheduleList.find((x: any) => x._id === id);
			if (scheduleTemp) {
				const scheduleDetail = Object.assign({}, scheduleTemp) as any;
				delete scheduleDetail.user;
				delete scheduleDetail.createdAt;
				delete scheduleDetail.updatedAt;
				delete scheduleDetail.__v;
				delete scheduleDetail.key;
				setScheduleDetail(scheduleDetail);
				setModalShow(true);
			}
		}
	};

	const confirmEdit = () => {
		onEditIconClicked(selectedScheduleId, false);
		setIsShownEditConfirmDialog(false);
	};

	const onDeleteIconClicked = (id: any, status: boolean) => {
		status
			? setDeleteConfirmationMessage(
					"This schedule is currently active. Do you still want to proceed? This action will stop the devices and remove the schedule"
			  )
			: setDeleteConfirmationMessage("Are you sure want to delete?");

		setSelectedScheduleId(id);
		setIsShownConfirmDialog(true);
	};

	const dialogCloseClick = () => {
		setIsShownConfirmDialog(false);
		setIsShownEditConfirmDialog(false);
	};

	const closeEditModal = (isCallFromSave: boolean) => {
		if (isCallFromSave) {
			getSchedule();
		}
		setModalShow(false);
	};

	const filterScheduleByLocation = (location: any) => {
		if (location)
			setFilteredScheduleList(
				scheduleList.filter((schedule: any) =>
					schedule.location.includes(location.value)
				)
			);
		else setFilteredScheduleList(scheduleList);
	};

	const onSearchSchedule = (e: any) => {
		let { value } = e?.target;
		value = value?.toLowerCase();
		let tempData = cloneDeep(scheduleList);
		tempData = tempData.filter((mapData: any) => {
			if (
				mapData?.name?.toLowerCase()?.includes(value) ||
				mapData?.locations
					?.map((mapData: any) => mapData?.name)
					.join(",")
					?.includes(value) ||
				mapData?.devices
					?.map((deviceData: any) => deviceData?.deviceName)
					.join(",")
					?.includes(value)
			) {
				return true;
			}
			return false;
		});
		setFilteredScheduleList(tempData);
	};

	const checkAsset = () => {
		return !asset.payPending;
	};

	const checkAdmin = () => {
		if (user?.role === UserRoles.CONTRACTOR) {
			return true;
		}
		return user?.assets
			.find((mapData: any) => mapData._id === asset._id)
			?.permissions?.includes("write");
	};

	return checkAsset() ? (
		<>
			<div className="container-fluid schedule-list-responssive">
				{/* <SystemTour tourSteps={tourSteps} position={0} /> */}
				<div className="row mt-3 mb-3">
					<div className="col-lg-12 col-md-12 col-sm-12 schedule bg-white p-3">
						<div className="d-flex schdule-mobile-section justify-content-between">
							<div className="d-flex w-100 justify-content-between heading align-items-end mb-3">
								<div className="d-flex justify-content-between pt-2">
									<div className="heading">
										<h5 className="mb-0">Schedule List</h5>
										<p className="schdule-count mb-0">
											Total :{filteredScheduleList.length}
										</p>
									</div>
								</div>
								<div className="d-flex align-items-center mb-2 d-none mobile-search search-bar">
									<input
										placeholder="Search Schedule"
										className="d-none m-d-block mobile-search"
										onChange={debounce(onSearchSchedule, 1000)}
									/>
									<ClearIcon
										onClick={() => {
											setFilteredScheduleList(scheduleList);
										}}
										className="d-none m-d-block ml-1 clear-icon"
									/>
									<SearchIcon className="search-icon" />
								</div>
								<div className="w-50 search-location">
									<div className="ml-auto w-100">
										<h6 className="">Select Location</h6>
										<div className="dropdown pl-0 pr-0 w-100">
											<LocationSelector onSelectLocation={onChangeLocation} />
										</div>
									</div>
								</div>
								<div className="d-flex flex-wrap pl-3 m-pr-3 m-py-2 schedule-btn-container">
									<div className="flex-1">
										{checkAdmin() && (
											<button
												type="button"
												className="btn add w-100 mr-0 create-schedule-tour"
												onClick={() => history.push(URL.NEW_SCHEDULE)}
											>
												Create Schedule
											</button>
										)}
									</div>
									<div className="flex-1 d-none m-d-block">
										{checkAdmin() && (
											<button
												type="button"
												className="btn add w-100 mr-0 create-schedule-tour"
												onClick={() => history.push(URL.MAINTENANCE_REPORTS)}
											>
												Maintenance
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="m-d-none">
								<CustomDataTable
									title=""
									rows={filteredScheduleList}
									columns={columns}
									options={options}
									components={{
										icons: {
											DownloadIcon: forwardRef((iconProps: any, ref: any) => (
												<CSVLink
													data={downloadCsvData}
													uFEFF={false}
													className="d-inherit"
													filename={"Schedule.csv"}
												>
													<CloudDownload
														className="custom-download-icon"
														{...iconProps}
														ref={ref}
													/>
												</CSVLink>
											)),
										},
									}}
								/>
							</div>
							<div className="d-none m-d-block p-2">
								{filteredScheduleList.map((mapData: any) => (
									<div key={mapData.Name} className="mobile-card mb-2">
										<div className="d-flex justify-content-between">
											<p className="content-name">{mapData.name}</p>
											<Dropdown className="more-dropdown">
												<Dropdown.Toggle
													id="dropdown-basic"
													bsPrefix="my-dropdown-toggle p-0"
												>
													<MoreHorizIcon className="i-20 font-tertiary cursor-pointer" />
												</Dropdown.Toggle>
												<Dropdown.Menu
													className="card-action"
													style={{ width: "inherit" }}
												>
													<Dropdown.Item
														onClick={() => {
															onEditIconClicked(mapData?._id, mapData?.Status);
														}}
													>
														Edit
													</Dropdown.Item>
													<Dropdown.Item
														onClick={() => {
															onDeleteIconClicked(
																mapData?._id,
																mapData?.Status
															);
														}}
													>
														Delete
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<p className="content-label">LOCATIONS</p>
										<p className="content-value">
											{mapData?.locations
												?.map((mapData: any) => mapData?.name)
												.join(", ")}
										</p>
										<p className="content-label">DEVICES</p>
										<p className="content-value">
											{mapData?.devices
												?.map((deviceData: any) => deviceData?.deviceName)
												.join(", ")}
										</p>
										<div className="d-flex justify-content-between">
											<div>
												<p className="content-label">START TIME</p>
												<p className="content-value">{mapData?.timeFrom}</p>
											</div>
											<div>
												<p className="content-label">DURATION (Hrs)</p>
												<p className="content-value">{mapData?.interval}</p>
											</div>
											<div>
												<p className="content-label">STATUS</p>
												<p
													className={
														mapData?.Status
															? "content-value text-success"
															: "content-value text-danger"
													}
												>
													<FiberManualRecordIcon className="i-18 mr-1 schedule-status" />
													<span className="content-value">
														{mapData?.Status ? "ON" : "OFF"}
													</span>
												</p>
											</div>
										</div>
										<p className="content-label">DAY</p>
										<p className="content-value">
											{mapData?.days
												?.map((item: any) => daysList[item])
												.join(", ")}
										</p>
									</div>
								))}
								{isEmpty(filteredScheduleList) && (
									<div className="d-flex justify-content-center mt-2">
										<p>No Record found</p>
									</div>
								)}
							</div>
							{isShownConfirmDialog && (
								<ConfirmDialog
									dialogTitle="Delete"
									message={deleteConfirmationMessage}
									positiveBtnLabel="Delete"
									negativeBtnLabel="Cancel"
									onPositiveBtnClicked={() => deleteDevice()}
									onNegativeBtnClicked={() => dialogCloseClick()}
									onCloseIconClicked={() => dialogCloseClick()}
								/>
							)}
							{isShownEditConfirmDialog && (
								<ConfirmDialog
									dialogTitle="Edit"
									message={editConfirmationMessage}
									positiveBtnLabel="Edit"
									negativeBtnLabel="Cancel"
									onPositiveBtnClicked={() => confirmEdit()}
									onNegativeBtnClicked={() => dialogCloseClick()}
									onCloseIconClicked={() => dialogCloseClick()}
								/>
							)}
							{isLoading && <SpinnerLoader />}
						</div>
					</div>
				</div>
			</div>
			{modalShow && (
				<NewOrEditSchedule
					show={modalShow}
					scheduleDetail={scheduleDetail}
					onHide={closeEditModal}
				/>
			)}
		</>
	) : (
		<PaymentPendingInfo name={asset.name} />
	);
};
export default ScheduleList;
