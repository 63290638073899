import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function addLocation(
	locationDetails: Object
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("POST", "location", undefined, locationDetails);
}

export async function getLocationList(
	buildingId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "location?building=" + buildingId);
}

export async function getAllLocation(
	buildingId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "v2.0/location/all?&building=" + buildingId);
}

export async function getLocationListWithGraph(
	period: any,
	buildingId: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		"location?period=" + period + "&building=" + buildingId
	);
}
export async function getSingleLocation(
	locationId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "location/" + locationId);
}

export async function updateLocation(
	locationId: any,
	locationDetails: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		`location/${locationId}`,
		undefined,
		locationDetails
	);
}

export async function deleteLocationData(id: any): Promise<ApiResponse<any>> {
	return apiPlugin<any>("DELETE", "location/" + id);
}
