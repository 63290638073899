import "./DeviceManagement.scoped.scss";

interface IDeviceTab {
	name: string;
	selectedmonitorType: string;
	deviceType: string;
	locationData: {
		_id: string;
	};
	selectDeviceType: (type: string, id: string) => void;
}

export const DeviceTab = ({
	name,
	selectedmonitorType,
	deviceType,
	locationData,
	selectDeviceType,
}: IDeviceTab) => {
	return (
		<button
			type="button"
			className={
				selectedmonitorType === deviceType
					? "btn btn-medium libtnmo-click m-0"
					: "btn btn-medium btn-primary-outline m-0"
			}
			onClick={() => selectDeviceType(deviceType, locationData._id)}
		>
			{name}
		</button>
	);
};
