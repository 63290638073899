import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { createContractor } from "../../api/UserController";
import { SpinnerLoader } from "../../common/Global.Style";
import { setToken, setUser } from "../../redux/actions/userActions";
import { URL } from "../../components/Navbar/routes";
import { validationErrorMessage } from "../../utils/errMessages";

import "./NormiSignup.scss";
import { getPaymentSetupSession } from "../../api/ApplicationSubscriptionController";

const NormiSignUp: React.FC = () => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const dispatch = useDispatch();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};
	const logo: string =
		"https://iqintel-dev-files.s3.amazonaws.com/normi-files/normi-logo.png";

	const formIk = useFormik({
		initialValues: {
			email: "",
			password: "",
			confirmPassword: "",
			firstName: "",
			lastName: "",
			contactNumber: "",
			terms: false,
			agreement: false,
			role: "admin",
			providerId: process.env.REACT_APP_NORMI_PROVIDER_ID,
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email(validationErrorMessage.EMAIL)
				.required("Email is required"),
			password: Yup.string()
				.required("Password is required")
				.test(
					"len",
					"The password must be 6–16 characters in length",
					function (newPassword: any) {
						return newPassword?.length > 5 && newPassword?.length < 17;
					}
				)
				.test(
					"security-check",
					"The password should contain at least two of the following types: numbers, uppercase letters, lowercase letters, and underscores.",
					function (newPassword: any) {
						let isFound = [];
						if (/\d/.test(newPassword)) {
							isFound.push(true);
						}
						if (/[a-z]/.test(newPassword)) {
							isFound.push(true);
						}

						if (/[A-Z]/.test(newPassword)) {
							isFound.push(true);
						}

						if (newPassword && newPassword.includes("_")) {
							isFound.push(true);
						}
						return isFound.length >= 2;
					}
				),
			confirmPassword: Yup.string()
				.required("Confirm Password is required")
				.oneOf(
					[Yup.ref("password")],
					`Password and confirm password didn't match`
				),
			firstName: Yup.string().trim().required("First Name is required"),
			lastName: Yup.string().trim().required("Last Name is required"),
			contactNumber: Yup.string()
				.matches(/^(\d{10}(,(?=.))?)+$/, validationErrorMessage.CONTACT)
				.required("Phone Number is required"),
			terms: Yup.bool().oneOf([true], "Please accept terms"),
			agreement: Yup.bool().oneOf([true], "Please accept privacy policy"),
		}),
		onSubmit: async (values: any) => {
			setIsLoading(true);
			const response = await createContractor(values);
			setIsLoading(false);
			if (response?.responseObj?.token) {
				toast.success(response?.responseObj?.message);
				dispatch(setToken(response?.responseObj?.token));
				dispatch(setUser(response?.responseObj?.user));
			} else if (response.error) {
				toast.error(response.error);
			}
		},
	});

	const setupPaymentMethod = async () => {
		try {
			setIsLoading(true);
			const data = {
				success_url: `${window.origin}${URL.PAYMENT_SETUP}`,
				cancel_url: `${window.origin + URL.PAYMENT_SETUP}`,
			};
			const response = await getPaymentSetupSession(data);
			if (response && response?.responseObj && response.responseObj?.session) {
				window.open(response.responseObj.session.url, "_self");
			}
		} catch (error: any) {
			setIsLoading(true);
		}
	};

	return (
		<>
			<div className="container sign-up-section">
				<div className="mb-4 mx-auto logo">
					<img className="d-block mt-4 w-100" src={logo} alt="" />
					<p className="text-right font-tertiary font-500">Powered by iQi</p>
				</div>

				{isLoading && <SpinnerLoader />}
				<div>
					<p>*Please fill in this form to create a new user account.</p>
				</div>
				<form onSubmit={formIk.handleSubmit}>
					<div className="row">
						<div className="form-group col-md-6 mb-3">
							<label htmlFor="firstName">First Name</label>
							<input
								type="text"
								className="form-control"
								autoComplete="off"
								placeholder="First Name"
								name="firstName"
								id="firstName"
								onChange={formIk.handleChange}
								onBlur={formIk.handleBlur}
								value={formIk.values.firstName}
							/>
							{formIk.touched.firstName && formIk.errors.firstName && (
								<div className="text-danger mt-1">
									{formIk.errors.firstName}
								</div>
							)}
						</div>
						<div className="form-group col-md-6 mb-3">
							<label htmlFor="lastName">Last Name</label>
							<input
								type="text"
								className="form-control"
								autoComplete="off"
								placeholder="Last Name"
								name="lastName"
								id="lastName"
								onChange={formIk.handleChange}
								onBlur={formIk.handleBlur}
								value={formIk.values.lastName}
							/>
							{formIk.touched.lastName && formIk.errors.lastName && (
								<div className="text-danger mt-1">{formIk.errors.lastName}</div>
							)}
						</div>
					</div>
					<div className="row">
						<div className="from-group col-md-6 mb-3">
							<label htmlFor="email">Email</label>
							<input
								className="form-control"
								type="text"
								autoComplete="off"
								placeholder="Email"
								name="email"
								id="email"
								onChange={formIk.handleChange}
								onBlur={formIk.handleBlur}
								value={formIk.values.email}
							/>
							{formIk.touched.email && formIk.errors.email && (
								<div className="text-danger mt-1">{formIk.errors.email}</div>
							)}
						</div>
						<div className="form-group col-md-6 mb-3">
							<label htmlFor="contactNumber">Phone Number</label>
							<input
								type="text"
								className="form-control"
								autoComplete="off"
								placeholder="Phone Number"
								name="contactNumber"
								id="contactNumber"
								onChange={formIk.handleChange}
								onBlur={formIk.handleBlur}
								value={formIk.values.contactNumber}
							/>
							{formIk.touched.contactNumber && formIk.errors.contactNumber && (
								<div className="text-danger mt-1">
									{formIk.errors.contactNumber}
								</div>
							)}
						</div>
					</div>
					<div className="row">
						<div className="from-group col-md-6 mb-3">
							<label htmlFor="password">Password</label>
							<div className="position-relative">
								<input
									type={showPassword ? "text" : "password"}
									className="form-control"
									autoComplete="off"
									placeholder="Enter Password"
									name="password"
									id="password"
									onChange={formIk.handleChange}
									onBlur={formIk.handleBlur}
									value={formIk.values.password}
								/>

								<IconButton
									className="eye-icon"
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
								>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</div>
							{formIk.touched.password && formIk.errors.password && (
								<div className="text-danger mt-1">{formIk.errors.password}</div>
							)}
						</div>
						<div className="form-group col-md-6 mb-3">
							<label htmlFor="confirm-password">Confirm Password</label>
							<div className="position-relative">
								<input
									type={showConfirmPassword ? "text" : "password"}
									className="form-control"
									autoComplete="off"
									placeholder="Re-Enter Password"
									name="confirmPassword"
									id="confirm-password"
									data-bv-identical="true"
									onChange={formIk.handleChange}
									onBlur={formIk.handleBlur}
									value={formIk.values.confirmPassword}
								/>
								<IconButton
									className="eye-icon"
									aria-label="toggle password visibility"
									onClick={handleClickShowConfirmPassword}
									onMouseDown={handleMouseDownPassword}
								>
									{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</div>
							{formIk.touched.confirmPassword &&
								formIk.errors.confirmPassword && (
									<div className="text-danger mt-1">
										{formIk.errors.confirmPassword}
									</div>
								)}
						</div>
					</div>
					<div className="row">
						<div className="form-group col-md-6 mb-3">
							<label htmlFor="zip">Provider Id</label>
							<input
								type="text"
								className="form-control"
								autoComplete="off"
								placeholder="Provider Id"
								name="providerId"
								id="providerId"
								min="0"
								onChange={formIk.handleChange}
								onBlur={formIk.handleBlur}
								value={formIk.values.providerId}
								disabled={true}
							/>
							{formIk.touched.providerId && formIk.errors.providerId && (
								<div className="text-danger mt-1">
									{formIk.errors.providerId}
								</div>
							)}
						</div>
					</div>

					<hr className="mb-3" />
					<p className="">
						*Please agree to the Terms & Privacy Policy to create account.
					</p>
					<div className="mb-3 col-md-4">
						<div className="d-flex align-items-center">
							<input
								className="sign-up-checkbox"
								type="checkbox"
								id="terms"
								name="terms"
								onChange={formIk.handleChange}
								checked={formIk.values.terms}
							/>
							<label className="ml-2 mb-0 cursor-pointer" htmlFor="terms">
								I agree to
								<a
									className="link ml-1"
									target="_blank"
									rel="noreferrer"
									href="https://public.iqicloud.io/toc.pdf"
								>
									Terms
								</a>
								.
							</label>
						</div>
						{formIk.touched.terms && formIk.errors.terms && (
							<div className="text-danger mt-1">{formIk.errors.terms}</div>
						)}
					</div>
					<div className="mb-3 col-md-4">
						<div className="d-flex align-items-center">
							<input
								className="sign-up-checkbox"
								type="checkbox"
								id="agreement"
								name="agreement"
								onChange={formIk.handleChange}
								checked={formIk.values.agreement}
							/>
							<label className="ml-2 mb-0 cursor-pointer" htmlFor="agreement">
								I agree to
								<a
									className="link ml-1"
									target="_blank"
									href="https://public.iqicloud.io/privacy_policy.pdf"
									rel="noreferrer"
								>
									Privacy Policy
								</a>
								.
							</label>
						</div>
						{formIk.touched.agreement && formIk.errors.agreement && (
							<div className="text-danger mt-1">{formIk.errors.agreement}</div>
						)}
					</div>
					<div className="d-flex justify-content-center mb-4">
						<button type="submit" className="btn btn-lg pull-right">
							Sign Up
						</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default NormiSignUp;
