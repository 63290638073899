import React, { useEffect, useState } from "react";
import "./BottomNavBar.scoped.scss";

import { ToggleButtonGroup } from "@material-ui/lab";
import { URL } from "../Navbar/routes";
import BottomNav from "./Components";
import { BottomMenuValues } from "./Menus";
import { UserRoles } from "../../enums/UserRoles";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { roleConditionChecker } from "../../utils/helpers";

interface BottomBar {
	showSidNav: boolean;
}

const BottomNavBar: React.FC = () => {
	const user = useSelector((state: RootState) => state.user);
	const asset: any = useSelector((state: RootState) => state.asset);
	const renderBottomMenuValues = (item: any, index: any) => (
		<>
			{item.roles.includes(user.role) &&
				roleConditionChecker(item, user, asset) && (
					<div className="flex-1" key={`${index}-${item.path}`}>
						<BottomNav item={item} />
					</div>
				)}
		</>
	);
	return (
		<div className="mobile-navbar w-100">
			<ToggleButtonGroup className="w-100">
				{BottomMenuValues.map((item: any, index: any) => {
					return renderBottomMenuValues(item, index);
				})}
			</ToggleButtonGroup>
		</div>
	);
};

export default BottomNavBar;
