import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { URL } from "../components/Navbar/routes";

interface PrivateRouteProps extends RouteProps {
	// tslint:disable-next-line:no-any
	component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
	const { component: Component, ...rest } = props;
	const token = useSelector((state: RootState) => state.user.token);
	const paymentType = useSelector(
		(state: RootState) => state.user?.paymentType
	);
	const paymentSetup = useSelector(
		(State: RootState) => State.user.paymentSetup
	);
	const payPending = useSelector((State: RootState) => State.user.payPending);

	return (
		<Route
			{...rest}
			render={(routeProps) => {
				if (!token) {
					return (
						<Redirect
							to={{
								pathname: "/login",
							}}
						/>
					);
				} else if (payPending) {
					return (
						<Redirect
							to={{
								pathname: URL.PAYMENT_ACTION_REQUIRED,
							}}
						/>
					);
				}
				return <Component {...routeProps} />;
			}}
		/>
	);
};

export default PrivateRoute;
