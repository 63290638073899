import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function getAutopilotRule(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "misc/rules");
}

export async function getDimensions(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "misc/dimensions");
}

export async function getUserDimension(
	assetId: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "misc/user-dimensions?asset=" + assetId);
}

export async function updateAutopilotRule(
	data: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>("PUT", "misc/rule", undefined, data);
}

export async function updateClusterConfig(
	id: string,
	data: any,
	assetId: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		"misc/user-dimension/" + id + "?asset=" + assetId,
		undefined,
		data
	);
}

export async function updateUserDimension(
	id: string,
	payload: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		`v2.0/misc/new-user-dimension/${id}`,
		undefined,
		payload
	);
}

export async function masterToggle(
	assetId: string,
	payload: any
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		`v2.0/misc/toggle-autopilot/${assetId}`,
		undefined,
		payload
	);
}

export async function resetUserDimension(
	assetId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		`v2.0/misc/reset-dimensions/${assetId}`,
		undefined
	);
}

export async function syncDimensions(
	assetId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"PUT",
		`v2.0/misc/sync-dimensions/${assetId}`,
		undefined
	);
}

export async function buildingAndLocationList(): Promise<ApiResponse<any>> {
	return apiPlugin<any>("GET", "misc/building-list");
}

export async function checkCurrentlyRunningAutopilotStatus(
	dimensionId: string,
	assetId: string
): Promise<ApiResponse<any>> {
	return apiPlugin<any>(
		"GET",
		`v2.0/misc/check-autopilot-running?dimensionId=${dimensionId}&assetId=${assetId}`
	);
}
