import React, { useState, useEffect } from "react";
import { Area } from "@ant-design/charts";
import { toast } from "react-toastify";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import Select from "react-select";
import moment from "moment";

import { getBuildingsList } from "../../api/buildingsController";
import { setBuilding, setBuildings } from "../../redux/actions/buildingActions";
import { SpinnerLoader } from "../../common/Global.Style";
import { URL } from "../../components/Navbar/routes";
import { RootState } from "../../redux/reducers";
import { EventEmitter } from "../../common/EventEmitter";
import {
	getAllAssetBuildings,
	getContractorAssets,
} from "../../api/AssetController";
import { setAssets } from "../../redux/actions/assetActions";

import "./Buildings.scoped.scss";
import PaymentPendingInfo from "../../components/PaymentPendingInfo/PaymentPendingInfo";
import { UserRoles } from "../../enums/UserRoles";
import { isIpadOS } from "../../common/service";
import {
	setupWebViewJavascriptBridge,
	registerHandler,
} from "../../common/JsBridge";

export interface Building {
	name: string;
	_id: string;
	userId: string;
	address: string;
	city: string;
	state: string;
	zipcode: string;
	locations: number;
	good: number;
	notGood: number;
	chartData: string[];
	timeZone: string;
}

interface Item {
	label: string;
	value: string;
}

const Buildings: React.FC = () => {
	const [buildingsList, setBuildingsList] = useState([]);
	const asset = useSelector((state: RootState) => state.asset);
	const user = useSelector((state: RootState) => state.user);

	const [filterBuildingsList, setFilterBuildingsList] = useState([]);
	const [periods] = useState<any>([
		{ value: "month", label: "Month" },
		{ value: "week", label: "Week" },
		{ value: "day", label: "Day" },
	]);
	const [selectedperiods, setSelectedPeriods] = useState<any>({
		value: "day",
		label: "Day",
	});
	const dispatch = useDispatch();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<any>();
	const colourStyles = {
		control: (styles: any) => ({
			...styles,
			borderRadius: "10px",
			backgroundColor: "#FFF",
		}),
	};

	const callAPI = (event: any) => {
		setSelectedPeriods({ value: "day", label: "Day" });
		getBuildingData("day", event._id);
	};

	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};

	useEffect(() => {
		callHandler("sendUserData", { userData: user }, () => {});
		setupWebViewJavascriptBridge(function (bridge: any) {
			registerHandler("isWebViewHandler", function (data: any) {
				localStorage.setItem("isWebView", "true");
			});
		});
	}, []);

	useEffect(() => {
		if (asset?._id) {
			getBuildingData("day", asset._id);
		}
	}, []);

	useEffect(() => {
		EventEmitter.subscribe("assetChange", (event) => callAPI(event));
		return () => EventEmitter.unsubscribe("assetChange");
	}, []);

	const getBuildingData = (period: any, assetId: any) => {
		setIsLoading(true);
		getBuildingsList(period, assetId)
			.then((response: any) => {
				if (response.responseObj) {
					setBuildingsList(response.responseObj.buildings);
					setFilterBuildingsList(response.responseObj.buildings);
					let assetIndex = 0;
					if ([UserRoles.CONTRACTOR].includes(user?.role)) {
						getContractorAssets()
							.then((resContractor: any) => {
								if (resContractor.responseObj) {
									if (resContractor.responseObj?.length > 0) {
										dispatch(setAssets(resContractor.responseObj));
										resContractor.responseObj.map(
											(assetObj: any, index: any) => {
												if (assetObj._id == assetId) {
													assetIndex = index;
													return assetObj;
												}
											}
										);
										dispatch(
											setBuildings(
												resContractor.responseObj[assetIndex].buildings
											)
										);
										dispatch(
											setBuilding(
												get(
													resContractor,
													`responseObj[${assetIndex}].buildings[0]`,
													{}
												)
											)
										);
									}

									// handle response
									setIsLoading(false);
								} else {
									toast.error(resContractor.error);
									setIsLoading(false);
								}
							})
							.catch((error: any) => {});
					} else {
						getAllAssetBuildings()
							.then((resUser: any) => {
								if (resUser.responseObj) {
									if (resUser.responseObj?.length > 0) {
										dispatch(setAssets(resUser.responseObj));
										resUser.responseObj.map((assetObj: any, index: any) => {
											if (assetObj._id == assetId) {
												assetIndex = index;
											}
										});
										dispatch(
											setBuildings(resUser.responseObj[assetIndex].buildings)
										);
										dispatch(
											setBuilding(
												get(
													resUser,
													`responseObj[${assetIndex}].buildings[0]`,
													{}
												)
											)
										);
									}

									// handle response
									setIsLoading(false);
								} else {
									toast.error(resUser.error);
									setIsLoading(false);
								}
							})
							.catch((error: any) => {});
					}
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const setBuildingData = (building: Building) => {
		dispatch(setBuilding(building));
		history.push(URL.DASHBOARD);
	};

	const getGraphData = (sensorData: any[], timeZone: string) => {
		const tempArr = [...sensorData];
		let newArr;

		newArr = tempArr.map((chart) => {
			return {
				...chart,
				_id:
					selectedperiods?.value == "day"
						? moment(chart._id.toLocaleString())
								.tz(timeZone)
								.format("MM/DD HH:mm")
						: moment(chart._id).format("MM/DD"),
			};
		});
		return {
			data: newArr as never[],
			width: 342,
			height: 206,
			xField: "_id",
			yField: "reading",
			xAxis: {
				range: [0, 1],
			},
			yAxis: {
				label: {
					formatter: function formatter(v: any) {
						return Math.floor(Number(v));
					},
				},
				min:
					Math.floor(Math.min(...newArr.map((data: any) => data.reading))) - 3,
				max: Math.max(...newArr.map((data: any) => data.reading)) + 1,
			},
			smooth: true,
		};
	};

	const onChangePeriods = (value: any) => {
		setSelectedPeriods(value);
		getBuildingData(value.value, asset._id);
	};

	const searchBuilding = (e: any) => {
		const arr: any = [];
		buildingsList.forEach((ele: any) => {
			if (ele.name.toLowerCase().includes(e.target.value.toLowerCase())) {
				arr.push(ele);
			}
		});
		if (arr.length > 0) {
			setFilterBuildingsList(arr);
		} else {
			setFilterBuildingsList([]);
		}
	};

	const singleBuilding = (building: Building) => {
		return (
			<div className="col-md-6 col-sm-12 col-lg-4 p-2" key={building._id}>
				<div className=" modern pl-0 pr-0">
					<div
						className="h-30 w-100 p-3 house"
						onClick={() => setBuildingData(building)}
					>
						<div className="d-flex align-items-start">
							<img className="w-10 h-10" src="/images/home.svg" alt="" />
							<div className="text-white-color ml-3">
								<h5 className="bo text-break">{building.name}</h5>
								<p className="d-flex align-items-center text-break">
									<LocationOnIcon />
									{!building?.address
										? `-`
										: `${building?.address}, ${building?.city}, ${building?.state}, ${building?.zipcode}`}
								</p>
							</div>
						</div>
						<div className="d-flex">
							<div className="loc col-md-5 col-lg-4 p-0 text-center">
								<h6 className="no mb-0 mt-1">No of Location</h6>
								<h4 className="text-white-color text-break">
									{building.locations}
								</h4>
							</div>
							<div className="loc col-md-4 col-lg-4 p-0 text-center">
								<div className="ml-2">
									<h6 className="no mb-0 mt-1">Good</h6>
									<h4 className="text-white-color">{building.good}</h4>
								</div>
							</div>
							<div className="loc col-md-4 col-lg-4 p-0 text-center">
								<div className="ml-2">
									<h6 className="no mb-0 mt-1">Not Good</h6>
									<h4 className="text-white-color">{building.notGood}</h4>
								</div>
							</div>
						</div>
					</div>
					<div className="airgr p-3">
						<div className="d-flex justify-content-between">
							<div className="col-lg-5">
								<h6>Air Quality Trend (PM)</h6>
							</div>
						</div>
						<div className="grimg mt-3 graph-area d-flex justify-content-center">
							{building.chartData?.length ? (
								<Area
									{...getGraphData(
										building.chartData,
										building?.timeZone || "EST5EDT"
									)}
								/>
							) : (
								<div className="text-center d-flex align-items-center">
									Data not found
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	};

	return !asset.payPending ? (
		<>
			<div className="container-fluid p-0">
				<div className="mt-3 bg-white mb-3 buli p-4 building-page">
					<div className="d-flex align-items-center w-100 header-block">
						<h5>Buildings</h5>

						<div className="d-flex ml-4 align-items-center justify-content-between">
							<div className="align-items-center d-flex responssive-hide">
								<p className="font-14 mb-0 mr-2 white-no-wrap">
									Select Date Range:
								</p>
								<div className="bg-light dropdown min-w-200 mr-3 p-0">
									<Select
										className="basic-multi-select"
										components={{ IndicatorSeparator: () => null }}
										onChange={(value) => onChangePeriods(value)}
										options={periods}
										placeholder="Month"
										styles={colourStyles}
										value={selectedperiods}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="responsive-search">
						<input
							type="text"
							value={searchText}
							onChange={searchBuilding}
							placeholder="Search"
						/>
						<SearchIcon />
					</div>
					<div className="d-flex mobile-select">
						<p className="font-14 mb-0 mr-2 white-no-wrap">
							Select Date Range:
						</p>
						<div className="bg-light dropdown min-w-200 mr-3 p-0">
							<Select
								className="basic-multi-select"
								components={{ IndicatorSeparator: () => null }}
								onChange={(value) => onChangePeriods(value)}
								options={periods}
								placeholder="Month"
								styles={colourStyles}
								value={selectedperiods}
							/>
						</div>
					</div>

					<div className=" row mt-3 mobile-buildings">
						{filterBuildingsList?.length > 0 &&
							filterBuildingsList.map((building: Building) => {
								return singleBuilding(building);
							})}
						{filterBuildingsList?.length == 0 && (
							<div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 text-center">
								No Building Found
							</div>
						)}
					</div>
				</div>
				{isLoading && <SpinnerLoader />}
			</div>
		</>
	) : (
		<PaymentPendingInfo name={asset.name} />
	);
};
export default Buildings;
