import React from "react";
import "../style.scoped.scss";
import { NavLink } from "react-router-dom";
import { URL } from "../../../../components/Navbar/routes";

interface ConsultationCategoryProps {
	category: any;
}

const ConsultationCategory: React.FC<ConsultationCategoryProps> = ({
	category,
}) => {
	return (
		<NavLink
			to={`${URL.IAQCONSULTANTALL}/${decodeURIComponent(
				category.name.replace(" ", "+")
			)}`}
		>
			<div className="flex-1 d-flex align-items-center flex-column">
				<div
					className={`product-img mr-0 d-flex align-items-center justify-content-center rounded-circle ${category.bgClass}`}
				>
					<img src={category.img} alt="store" />
				</div>
				<p className="mb-0 mt-2 pt-1 desc-small font-400 ls-024 text-center font-text-4">
					{category.name}
				</p>
			</div>
		</NavLink>
	);
};
export default ConsultationCategory;
