import React, { useRef, useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { toast } from "react-toastify";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Select from "react-select";

import AddUser, { CreatUserFormData } from "./AddUserForm";
import { addAsset, updateAsset } from "../../api/AssetController";
import {
	getSignatureUrl,
	updateUserAvtar,
	addUserAPi,
	generateS3Url,
} from "../../api/UserController";
import { SpinnerLoader } from "../../common/Global.Style";
import Shared from "../../common/Shared";
import SystemTour from "../SystemTour/SystemTour";
import {
	requiredFieldError,
	validationErrorMessage,
} from "../../utils/errMessages";
import { URL } from "../Navbar/routes";
import { useHistory } from "react-router-dom";
import { isIpadOS } from "../../common/service";

import "./CreateAssets.scss";
import { callHandler } from "../../common/JsBridge";
interface CreateAssetProps {
	showDrawer: boolean;
	setShowDrawer: (value: boolean) => void;
	getAssets: () => void;
	updateLocalAsset: () => void;
	editAssetData: {
		alertContact?: string;
		alertEmail?: string;
		name?: string;
		authorized_users: IUserLit[];
		_id: string;
	};
}

interface AssetCreationData {
	alertContact: string;
	alertEmail: string;
	name: string;
}

interface IUserLit {
	contactNumber?: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	password?: string;
	permissions?: string[];
}

export default function CreateAsset({
	showDrawer,
	setShowDrawer,
	getAssets,
	updateLocalAsset,
	editAssetData,
}: // assetData,
CreateAssetProps) {
	const [step, setStep] = useState(1);
	const [type, setType] = useState("add");
	const [steps] = useState(["Add Customer", "Invite user", "Complete"]);
	const [loading, setIsLoading] = useState<boolean>(false);
	const [assetData, setAsetData] = useState<any>({});
	const [userList, setUserList] = useState<IUserLit[]>([]);
	const [appLogo, setAppLogo] = useState<any>("");
	const [appBackground, setAppBackground] = useState<any>("");
	const [appLogoS3Url, setAppLogoS3Url] = useState<string>("");
	const [appBackgroundS3Url, setAppBackgroundS3Url] = useState<string>("");
	const appLogoUploader = useRef<HTMLInputElement>(null);
	const appBackgroundUploader = useRef<HTMLInputElement>(null);
	const { pathname } = window.location;
	const history = useHistory();

	const pdfExportComponent = useRef(null);
	const container = useRef(null);

	useEffect(() => {
		if (editAssetData._id) {
			setStep(2);
			setType("edit");
		}
	}, [editAssetData]);

	const emailRegexPattern =
		/^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])(,)?)+$/;
	const contactRegexPattern = /^(\d{10}(,(?=.))?)+$/;
	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.trim()
			.required(requiredFieldError.ASSET_NAME)
			.max(50, "Name should be lesser than 50 characters"),
		alertContact: Yup.string()
			.matches(contactRegexPattern, validationErrorMessage.CONTACT)
			.required(requiredFieldError.CONTACT),
		alertEmail: Yup.string()
			.matches(emailRegexPattern, validationErrorMessage.EMAIL)
			.required(requiredFieldError.EMAIL),
		salesEmails: Yup.string().matches(
			emailRegexPattern,
			validationErrorMessage.EMAIL
		),
	});

	const formik = useFormik({
		initialValues: {
			name: assetData.name ? assetData.name : "",
			alertContact: assetData.alertContact ? assetData.alertContact : "",
			alertEmail: assetData.alertEmail ? assetData.alertEmail : "",
			salesEmails: assetData?.salesEmails ? assetData.salesEmails : "",
			calendlyLink: assetData?.calendlyLink ? assetData.calendlyLink : "",
			licenseCode: assetData?.licenseCode ? assetData?.licenseCode?.code : "",
			assignedConsultant: assetData?.assignedConsultant
				? assetData?.assignedConsultant
				: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values: any) => {
			values.alertEmail = values.alertEmail.toLowerCase();
			values.assignedConsultant = values.assignedConsultant.trim();
			const email = values.alertEmail.split(",");
			values.alertEmail = [...new Set(email)].join();
			createAsset({ ...values, logo: appLogoS3Url, theme: appBackgroundS3Url });
		},
	});

	const createAsset = async (assetDetails: AssetCreationData) => {
		toast.success(assetDetails);
		if (assetData._id) {
			setIsLoading(true);
			const response = await updateAsset(assetDetails, assetData._id);
			setIsLoading(false);
			if (response.responseObj) {
				setAsetData(get(response, "responseObj.asset", {}));
				setStep(2);
				getAssets();
			}
		} else {
			setIsLoading(true);
			const response = await addAsset(assetDetails);
			toast.success(response);
			setIsLoading(false);
			if (response.responseObj) {
				setAsetData(get(response, "responseObj.asset", {}));
				setStep(2);
				getAssets();
			} else if (response.error) {
				toast.error(
					response.error === "Asset validation failed"
						? "Customer Account already exists, Try another name"
						: response.error
				);
			}
		}
	};

	const addUserTOAsset = async (userData: CreatUserFormData) => {
		if (userData.users.length === 0) {
			setStep(3);
			return;
		}
		setIsLoading(true);
		let apiPayload = {
			_id: assetData._id,
			name: assetData.name,
		};
		if (type === "edit") {
			apiPayload = {
				_id: editAssetData._id,
				name: editAssetData.name,
			};
		}
		const response = await addUserAPi({
			...userData,
			...apiPayload,
			url: Shared.appUrl,
		});
		setIsLoading(false);
		if (response.responseObj) {
			setUserList(get(response, "responseObj.users", []));
			setStep(3);
		}
	};

	const finish = () => {
		setStep(1);
		onClose();
		if (isEmpty(userList)) return;
		if (type === "edit") {
			updateLocalAsset();
		} else {
			getAssets();
		}
	};

	const handleLogoUpload = (e: any) => {
		const [file] = e.target.files;
		if (file) {
			setIsLoading(true);
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target!.result) {
					setAppLogo(e?.target?.result);
				}
			};
			reader.readAsDataURL(file);
			getSignatureUrl({ name: file.name, type: file.type }).then(
				async (response: any) => {
					setAppLogoS3Url(response.responseObj.signedUrl.split("?")[0]);
					await updateUserAvtar(
						response.responseObj.signedUrl,
						e.target.files[0]
					).then(() => {
						setIsLoading(false);
					});
				}
			);
		}
	};

	const handleImageUpload = (e: any) => {
		const [file] = e.target.files;
		if (file) {
			setIsLoading(true);
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target!.result) {
					setAppBackground(e?.target?.result);
				}
			};
			reader.readAsDataURL(file);
			getSignatureUrl({ name: file.name, type: file.type }).then(
				async (response: any) => {
					setAppBackgroundS3Url(response.responseObj.signedUrl.split("?")[0]);
					await updateUserAvtar(
						response.responseObj.signedUrl,
						e.target.files[0]
					).then(() => {
						setIsLoading(false);
					});
				}
			);
		}
	};

	const onClose = () => {
		setShowDrawer(false);
		if ([URL.CUSTOMER_ACCOUNT].includes(pathname)) {
			history.push(URL.CUSTOMER_ACCOUNT);
		} else {
			callHandler("sendStatusToNative", {}, (data: any) => {});
		}
	};

	const Prints: any = () => (
		<PDFExport ref={pdfExportComponent} paperSize="A4">
			<div
				id={"add-class"}
				className="d-none"
				ref={container}
				style={{
					maxHeight: "400px",
				}}
			>
				<h6>User credentials</h6>
				<table className="table assets-table mt-3">
					<thead>
						<tr>
							<th>Username</th>
							<th>Password</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{userList.map((mapData) => (
							<tr>
								<td className="align-middle">{mapData.email}</td>
								<td className="align-middle">{mapData.password}</td>
								<td className="align-middle">
									{isEmpty(mapData.password) && "user already exist"}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</PDFExport>
	);

	const exportPDFWithMethod = () => {
		document.getElementById("add-class")?.classList.remove("d-none");
		const element = container.current || document.body;
		if (
			/android/.test(navigator.userAgent.toLowerCase()) ||
			/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) ||
			isIpadOS()
		) {
			setIsLoading(true);
			try {
				drawDOM(element, {
					paperSize: "A4",
				})
					.then((group) => {
						return exportPDF(group);
					})
					.then(async (dataURI) => {
						const userData = dataURI.split(",")[1];
						const response = await generateS3Url({
							userData: userData,
							fileName: "user_credentials.pdf",
							contentType: "application/pdf",
							isBase64: true,
							contentEncoding: "base64",
						});
						if (response.responseObj) {
							setIsLoading(false);
							window.open(response.responseObj.locationUrl);
						} else setIsLoading(false);
					});
			} catch (e) {
				setIsLoading(false);
			}
		} else {
			savePDF(element, {
				paperSize: "A4",
				margin: 40,
				fileName: `User_credentials.pdf`,
			});
		}
		document.getElementById("add-class")?.classList.add("d-none");
	};

	const completeUserTourSteps = [
		{
			selector: ".downlod-user-pdf",
			content: ({}) => (
				<div className="tour-content-styl">
					You can download the user accounts created with their passwords. An
					email will be sent directly to the users with their login credentials
					to iqicloud web dashboard{" "}
					<a href="https://iqicloud.io" target="_blank" rel="noreferrer">
						https://iqicloud.io.
					</a>
				</div>
			),
		},
		{
			selector: ".complete-user-journey",
			content: ({}) => (
				<div className="tour-content-styl">
					Click Finish to complete the customer account creation process.
					<br />
					<br />
					You can use the same credentials for iQi Cloud mobile tool box & iQi
					Cloud web dashboard.
					<br />
					<br />
					<b>Next Step:</b> Configure devices in the iQi Cloud mobile tool box.
					Click here for instructions -{" "}
					<a
						href="https://toolbox.iqicloud.io/"
						target="_blank"
						rel="noreferrer"
					>
						https://toolbox.iqicloud.io/.
					</a>
					<br />
					<br />
					To access dashboards and reports visit iQi Cloud Web dashboard -{" "}
					<a href="https://www.iqicloud.io/" target="_blank" rel="noreferrer">
						https://www.iqicloud.io/.
					</a>
				</div>
			),
		},
	];

	const onChangeLicenseCode = (code: string) => {
		code = code.toUpperCase();
		if (code.length >= 4) {
			code = `iQi-${code?.slice(4)}`;
		}
		formik.setFieldValue("licenseCode", code);
	};

	const renderStep = () => {
		switch (step) {
			case 1:
				return (
					<FormikProvider value={formik}>
						<form onSubmit={formik.handleSubmit}>
							<div className="drawer-content">
								<div className="col-md-12 col-lg-12 col-sm-12">
									<h3 className="desc-large font-bold font-primary mb-3">
										Add Customer
									</h3>

									<div className="w-100 form-group">
										<label className="desc-normal font-500 font-secondary">
											Customer Name
											<span className="error-message ml-1">*</span>
										</label>
										<input
											id="name"
											name="name"
											type="text"
											className="add-re-input form-control pt-2 pb-2"
											value={formik.values.name}
											onChange={formik.handleChange}
											maxLength={100}
										/>

										<div className="text-danger">
											{formik.touched.name && get(formik, "errors.name", "")}
										</div>
									</div>
									<div className="w-100 form-group">
										<label className="desc-normal font-500 font-secondary">
											Cell Phone Number for Text Alerts
											<span className="error-message ml-1">*</span>
										</label>
										<input
											id="alertContact"
											name="alertContact"
											type="text"
											className="add-re-input form-control pt-2 pb-2"
											value={formik.values.alertContact}
											onChange={formik.handleChange}
										/>
										<p className="desc-small text-secondary mt-2">
											Enter multiple phone numbers separated by comma
										</p>

										<div className="text-danger">
											{formik.touched.alertContact &&
												get(formik, "errors.alertContact", "")}
										</div>
									</div>
									<div className="w-100 form-group">
										<label className="desc-normal font-500 font-secondary">
											Email Ids for Email Alerts
											<span className="error-message ml-1">*</span>
										</label>
										<input
											id="alertEmail"
											name="alertEmail"
											type="text"
											className="add-re-input form-control pt-2 pb-2"
											value={formik.values.alertEmail}
											onChange={formik.handleChange}
										/>
										<p className="desc-small text-secondary mt-2">
											Add multiple email ids separated by comma
										</p>

										<div className="text-danger">
											{formik.touched.alertEmail &&
												get(formik, "errors.alertEmail")}
										</div>
									</div>
									<div className="w-100 form-group">
										<label className="desc-normal font-500 font-secondary">
											License Code
										</label>
										<input
											id="licenseCode"
											name="licenseCode"
											type="text"
											className="add-re-input form-control pt-2 pb-2"
											value={formik.values.licenseCode}
											onChange={(e: any) => {
												onChangeLicenseCode(e?.target?.value);
											}}
										/>
										<p className="desc-small text-secondary mt-2">
											Enter if you have a license code
										</p>
										<div className="text-danger">
											{formik.touched.licenseCode &&
												get(formik, "errors.licenseCode")}
										</div>
									</div>
									<div className="w-100 form-group">
										<label className="desc-normal font-500 font-secondary">
											Assigned IAQ Consultant
										</label>
										<input
											id="assignedConsultant"
											name="assignedConsultant"
											type="text"
											className="add-re-input form-control pt-2 pb-2"
											value={formik.values.assignedConsultant}
											onChange={formik.handleChange}
										/>
										<p className="desc-small text-secondary mt-2">
											Name of the consultant if there is a dedicated consultant
											assigned for this account
										</p>
										<div className="text-danger">
											{formik.touched.assignedConsultant &&
												get(formik, "errors.assignedConsultant")}
										</div>
									</div>
									<div className="w-100 form-group">
										<label className="desc-normal font-500 font-secondary">
											Sales email for customers to order parts from you
										</label>
										<input
											id="salesEmails"
											name="salesEmails"
											type="text"
											className="add-re-input form-control pt-2 pb-2"
											value={formik.values.salesEmails}
											onChange={formik.handleChange}
										/>
										<p className="desc-small text-secondary mt-2">
											Add multiple email ids separated by comma
										</p>
										<div className="text-danger">
											{formik.touched.salesEmails &&
												get(formik, "errors.salesEmails")}
										</div>
									</div>
									<div className="w-100 form-group">
										<label className="desc-normal font-500 font-secondary">
											Calendly link for customers to schedule consulations with
											you
										</label>
										<input
											id="calendlyLink"
											name="calendlyLink"
											type="text"
											className="add-re-input form-control pt-2 pb-2"
											value={formik.values.calendlyLink}
											onChange={formik.handleChange}
										/>
									</div>
									<div className="mb-3 customer-account-logo">
										<p className="desc-normal font-500 font-secondary mb-2">
											Logo for TV Displays & Reports
										</p>
										<p className="desc-small font-500 font-tertiary mb-2">
											(if not set default iQi logo will be used)
										</p>
										{appLogo ? (
											<div className="d-flex">
												<div
													className="customer-account-img"
													onClick={() => appLogoUploader.current!.click()}
												>
													<img
														src={appLogo}
														alt="user Profile"
														className="cursor-pointer"
													/>
													<div className="img-action">
														<EditIcon />
													</div>
												</div>
												<div className="d-flex align-items-baseline">
													<IconButton>
														<DeleteIcon
															className="cursor-pointer font-secondary i-18"
															onClick={() => {
																setAppLogo("");
																setAppLogoS3Url("");
															}}
														/>
													</IconButton>
												</div>
											</div>
										) : (
											<div
												onClick={() => appLogoUploader.current!.click()}
												className="customer-account-upload d-flex justify-content-center p-3"
											>
												<CloudUploadOutlinedIcon className="mr-2" />
												Upload Your logo
											</div>
										)}

										<input
											type="file"
											accept="image/*"
											onClick={(event: any) => {
												event.target.value = null;
											}}
											onChange={handleLogoUpload}
											ref={appLogoUploader}
											multiple={false}
											hidden
										/>
									</div>

									<div className="mb-3 customer-account-logo">
										<p className="desc-normal font-500 font-secondary mb-2">
											Background Theme for TV Displays
										</p>
										<p className="desc-small font-500 font-tertiary mb-2">
											(if not set default iQi Theme will be used)
										</p>
										{appBackground ? (
											<div className="d-flex">
												<div
													className="customer-account-img cover-img"
													onClick={() => appBackgroundUploader.current!.click()}
												>
													<img
														src={appBackground}
														alt="user Profile"
														className="cursor-pointer"
													/>
													<div className="img-action">
														<EditIcon />
													</div>
												</div>
												<div className="d-flex align-items-baseline i-18">
													<IconButton>
														<DeleteIcon
															className="cursor-pointer cursor-pointer font-secondary i-18"
															onClick={() => {
																setAppBackground("");
																setAppBackgroundS3Url("");
															}}
														/>
													</IconButton>
												</div>
											</div>
										) : (
											<div
												onClick={() => appBackgroundUploader.current!.click()}
												className="customer-account-upload d-flex justify-content-center p-3"
											>
												<CloudUploadOutlinedIcon className="mr-2" />
												Upload Your background image
											</div>
										)}

										<input
											type="file"
											onClick={(event: any) => {
												event.target.value = null;
											}}
											accept="image/*"
											onChange={handleImageUpload}
											ref={appBackgroundUploader}
											multiple={false}
											hidden
										/>
									</div>
								</div>
							</div>
							<div className="drawer-footer d-flex align-items-center justify-content-end">
								<button
									onClick={() => onClose()}
									type="button"
									className="btn btn-medium btn-accent"
								>
									Cancel
								</button>
								<button
									className="btn btn-medium primary-btn ml-2"
									type="submit"
								>
									Next
								</button>
							</div>
						</form>
					</FormikProvider>
				);
			case 2:
				return (
					<div>
						<AddUser
							updateStep={setStep}
							addUserTOAsset={addUserTOAsset}
							type={type}
						/>
					</div>
				);
			case 3:
				return (
					<div>
						{/* <SystemTour tourSteps={completeUserTourSteps} position={2} /> */}

						<div className="drawer-content">
							{isEmpty(userList) ? (
								<div>
									<h3 className="desc-large font-bold font-primary mb-0">
										Users
									</h3>
									<table className="table assets-table mt-3">
										<thead>
											<tr>
												<th>User</th>
												<th>Permissions</th>
											</tr>
										</thead>
									</table>
									<tr>
										<td>
											<span>Users not found</span>
										</td>
									</tr>
								</div>
							) : (
								<>
									<div>
										<h3 className="desc-large font-bold font-primary mb-0">
											Users
										</h3>

										<button
											className={`btn btn-primary-outline mt-3 downlod-user-pdf`}
											onClick={exportPDFWithMethod}
										>
											Download PDF
										</button>
										{Prints()}
									</div>
									<table className="table assets-table mt-3">
										<thead>
											<tr>
												<th>User</th>
												<th>Permissions</th>
											</tr>
										</thead>
										<tbody>
											{userList.map((mapData) => (
												<tr>
													<td className="align-middle">
														<p className="desc-normal font-bold font-primary mb-0">
															<span className="circle-icon table-circle-icon">
																{mapData?.firstName?.substring(0, 1)}
															</span>
															<span className="ml-2 pl-1">{`${mapData.firstName} ${mapData.lastName}`}</span>
														</p>
													</td>
													<td className="align-middle">
														{mapData?.permissions?.includes("write")
															? "Admin"
															: "Viewer"}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</>
							)}
						</div>

						<div className="drawer-footer d-flex align-items-center justify-content-end">
							<button
								onClick={finish}
								className="btn btn-medium primary-btn ml-2 complete-user-journey"
							>
								Finish
							</button>
						</div>
					</div>
				);
		}
	};

	return (
		<React.Fragment>
			<Drawer anchor="right" open={showDrawer} onClose={() => onClose()}>
				<div className="drawer-content-section">
					<div className="drawer-header">
						<div className="drawer-header-title d-flex justify-content-between">
							<h6 className="drawer-header-heading">Customer Account</h6>
							<CloseIcon
								className="cursor-pointer m-d-none"
								onClick={() => onClose()}
							/>
							<ArrowBackIosIcon
								className="i-18 txt-primary cursor-pointer d-none m-d-block mr-2"
								onClick={() => onClose()}
							/>
						</div>
						<div>
							<Stepper activeStep={step - 1} alternativeLabel>
								{steps.map((label) => (
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
								))}
							</Stepper>
						</div>
					</div>
					{loading && <SpinnerLoader />}
					{renderStep()}
				</div>
			</Drawer>
		</React.Fragment>
	);
}
