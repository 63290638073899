import { URL } from "../../../components/Navbar/routes";
import { IMenuItem } from "../../../common/Interface";

import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import BuildIcon from "@material-ui/icons/Build";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import {
	UserRoles,
	CheckWriteAccess,
	CheckWriteAccessNotAllowedRoute,
	CheckPremiumUser,
} from "../../../enums/UserRoles";

const MoreMenuValues: any = [
	{
		title: "Admin",
		icon: <PersonIcon className="font-grey-light-1 i-20" />,
		description: "Data SIM, Account, Subcription",
		path: URL.ADMINSUBMENU,
		roles: [UserRoles.CONTRACTOR, UserRoles.ADMIN, UserRoles.USER],
		roleconditions: [
			CheckWriteAccess,
			CheckWriteAccessNotAllowedRoute,
			CheckPremiumUser,
		],
	},
	{
		title: "Building",
		icon: <BusinessIcon className="font-grey-light-1 i-20" />,
		description: "Building overview",
		path: URL.DASHBOARD_BUILDINGS,
		roles: [
			UserRoles.CONTRACTOR,
			UserRoles.SUPER_ADMIN,
			UserRoles.ADMIN,
			UserRoles.USER,
		],
		roleconditions: [{}, {}, CheckPremiumUser],
	},
	{
		title: "Floorplan",
		icon: <HomeWorkIcon className="font-grey-light-1 i-20" />,
		description: "Devices mapping overview",
		path: URL.FLOOR_PLAN_LIST,
		roles: [
			UserRoles.CONTRACTOR,
			UserRoles.SUPER_ADMIN,
			UserRoles.ADMIN,
			UserRoles.USER,
		],
		roleconditions: [{}, {}, CheckPremiumUser],
	},
	{
		title: "IAQ Reports",
		icon: <AssessmentOutlinedIcon className="font-grey-light-1 i-20" />,
		description: "Run, Schedule detailed IAQ reports",
		path: URL.IAQ_REPORTS,
		roles: [
			UserRoles.CONTRACTOR,
			UserRoles.SUPER_ADMIN,
			UserRoles.ADMIN,
			UserRoles.USER,
		],
		roleconditions: [{}, {}, CheckPremiumUser],
	},
	{
		title: "Consultation Reports",
		icon: <AssessmentOutlinedIcon className="font-grey-light-1 i-20" />,
		description: "Run, Schedule detailed Consultation reports",
		path: URL.CONSULTATION_REPORTS,
		roles: [
			UserRoles.CONTRACTOR,
			UserRoles.SUPER_ADMIN,
			UserRoles.ADMIN,
			UserRoles.USER,
		],
		roleconditions: [{}, {}, CheckPremiumUser],
	},
	{
		title: "Autofix",
		icon: <BuildIcon className="font-grey-light-1 i-20" />,
		description: "Autofix IAQ issues",
		path: URL.CLUSTER_CONFIG,
		roles: [
			UserRoles.CONTRACTOR,
			UserRoles.SUPER_ADMIN,
			UserRoles.ADMIN,
			UserRoles.USER,
		],
		roleconditions: [{}, {}, CheckPremiumUser],
	},
	{
		title: "Schedules",
		icon: <EventAvailableIcon className="font-grey-light-1 i-20" />,
		description: "Create work schedule for devices",
		path: URL.SCHEDULE,
		roles: [
			UserRoles.CONTRACTOR,
			UserRoles.SUPER_ADMIN,
			UserRoles.ADMIN,
			UserRoles.USER,
		],
		roleconditions: [{}, {}, CheckPremiumUser],
	},
];

export { MoreMenuValues };
