import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";

import { SpinnerLoader } from "../../common/Global.Style";
import { rejectAdmin } from "../../api/UserController";
import Shared from "../../common/Shared";
import { URL } from "../../components/Navbar/routes";

// return url search param from url bar
const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const RejectAdmin: React.FC<any> = ({ history }) => {
	let query = useQuery();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const token = query.get("token");

	useEffect(() => {
		if (token) {
			rejectAdminApiCall();
		} else {
			setIsLoading(false);
			setErrorMessage("This looks like the link is not valid");
		}
	}, []);

	const rejectAdminApiCall = async () => {
		const response = await rejectAdmin({ token });
		setIsLoading(false);
		if (response?.error) {
			setErrorMessage(response.error);
		} else if (response?.responseObj?.message) {
			setErrorMessage(response.responseObj.message);
		}
	};

	return (
		<div>
			<div className="mt-3 d-flex justify-content-center">
				<img
					onClick={() => history.push(URL.DASHBOARD)}
					className="cursor-pointer"
					src={Shared.reportLogoUrl}
					alt="login-logo"
				/>
			</div>
			{isLoading && <SpinnerLoader />}
			{errorMessage && (
				<div className="d-flex justify-content-center mt-5">
					<h6>{errorMessage}</h6>
				</div>
			)}
		</div>
	);
};
export default withRouter(RejectAdmin);
