import React, { useState, useEffect } from "react";
import { SelectableRows } from "mui-datatables";
import { Dropdown } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import Popover from "@material-ui/core/Popover";
import get from "lodash/get";
import PeopleRoundedIcon from "@material-ui/icons/PeopleRounded";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import debounce from "lodash/debounce";

import { SpinnerLoader } from "../../common/Global.Style";
import {
	getContractorAssets,
	getSimCardSubscriptions,
} from "../../api/AssetController";
import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import { RootState } from "../../redux/reducers";
import ActivateSim from "../../components/ActivateSim/ActivateSim";
import StripePaymentStatusDialog from "../../components/StripePaymentStatusDialog/StripePaymentStatusDialog";
import { useLocation, useHistory } from "react-router-dom";
import {
	cancelSubscription,
	getPaymentSummary,
	getSimCardPrices,
	reactivateSimSubscription,
} from "../../api/SubscriptionController";
import { PaymentSummary } from "../../common/Interface";
import moment from "moment";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { toast } from "react-toastify";
import ReactivateSimConfirmDialog from "../../components/ReactivateSimConfirmDialog/ReactivateSimConfirmDialog";
import { setupWebViewJavascriptBridge } from "../../common/JsBridge";

import "./SimSubscriptions.scoped.scss";
import SystemTour from "../../components/SystemTour/SystemTour";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";

declare global {
	interface Window {
		WebViewJavascriptBridge: any;
		WKWebViewJavascriptBridge: any;
		WKWVJBCallbacks: any;
		webkit: any;
	}
}

const SimSubscriptions: React.FC = () => {
	const history = useHistory();
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};

	let query = useQuery();
	const transaction = query.get("transaction");
	const sessionKey = query.get("sessionKey");
	const reactivateSimNo = query.get("reactive");
	const { innerWidth: width } = window;

	const user = useSelector((state: RootState) => state.user);
	const [showSimSubscriptionModal, setShowSimSubscriptionModal] =
		React.useState(false);
	const [showEditSimSubscriptionModal, setShowEditSimSubscriptionModal] =
		React.useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isShowStripeStatusDialog, setIsShowStripeStatusDialog] =
		useState<boolean>(false);
	const [isShowReactivateDialog, setIsShowReactivateDialog] =
		useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const [popoverData, setPopOverData] = useState<any>({});
	const [simCards, setSimCards] = useState<any>([]);
	const [initialSimCardData, setInitialSimCardData] = useState<any>([]);
	const [assetsList, setAssetsList] = useState<Array<any>>([]);
	const [prices, setPrices] = useState<any>([]);
	const [paymentSummary, setPaymentSummary] = useState<PaymentSummary>();
	const [editSimSubscription, setEditSimSubscription] = useState<any>();
	const [isShownConfirmDialog, setIsShownConfirmDialog] =
		useState<boolean>(false);
	const [cancelSubscriptionData, setCancelSubscriptionData] = useState<any>();
	const [nativeBridgeData, setNativeBridgeData] = useState<string>("");

	const registerHandler = (funName: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.registerHandler(
				funName,
				function (data: any, responseCallback: any) {
					// data 接收的数据，responseCallback 调用回调函数
					callback && callback(data, responseCallback);
				}
			);
		});
	};

	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};

	useEffect(() => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			// if (/android/.test(navigator.userAgent.toLowerCase())) {
			// 	bridge.init(function (message: any, responseCallback: any) {
			// 		// 'JS got a message', message
			// 		var data = {
			// 			"Javascript Responds": "测试中文!",
			// 		};

			// 		if (responseCallback) {
			// 			// 'JS responding with', data
			// 			responseCallback(data);
			// 		}
			// 	});
			// }

			registerHandler("updateFromNative", function (data: any) {
				setNativeBridgeData(data);
			});
			registerHandler("scanScreen", function (data: any) {
				setNativeBridgeData(data);
			});
		});
	}, []);

	useEffect(() => {
		init(transaction, sessionKey);
	}, [transaction, sessionKey]);

	const onClickScanner = () => {
		callHandler("sendEventToNative", {}, (data: any) => {});
	};

	const init = async (
		transaction: string | null,
		sessionKey: string | null
	) => {
		getSimSubscription();
		getPrices();
		if (!!transaction && !!sessionKey) {
			setIsLoading(true);
			const response = await getPaymentSummary(sessionKey);
			const paymentSummary: PaymentSummary = response.responseObj?.summary;
			if (Object.keys(paymentSummary).length) {
				setPaymentSummary(paymentSummary);
				setIsLoading(false);
				setIsShowStripeStatusDialog(true);
			} else {
				setIsLoading(false);
			}
		} else if (!!reactivateSimNo) {
			// Get Subscription
			// Check if is schedule for cancel or not
			setIsShowReactivateDialog(true);
		}
	};

	const resetPage = async () => {
		await getSimSubscription();
		await getPrices();
	};

	const getSimSubscription = async () => {
		try {
			setIsLoading(true);
			const simSubscriptions = await getSimCardSubscriptions();
			simSubscriptions.responseObj?.subscriptions?.forEach(
				(obj: any) => (obj.amount = `$${obj.amount / 100} / yr`)
			);
			setSimCards(simSubscriptions?.responseObj?.subscriptions);
			setInitialSimCardData(simSubscriptions?.responseObj?.subscriptions);
			const assetsResponse: any = await getContractorAssets();
			if (!!assetsResponse) {
				setAssetsList(assetsResponse.responseObj);
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}
	};

	const searchSimCard = (e: any) => {
		let { value } = e.target;
		value = value.toLowerCase();
		setSimCards(
			initialSimCardData?.filter(
				(filterData: any) =>
					filterData?.simNo?.toLowerCase()?.includes(value) ||
					filterData?.assetName?.toLowerCase()?.includes(value)
			)
		);
	};

	const getPrices = async () => {
		try {
			const simPrices = await getSimCardPrices();
			if (simPrices?.responseObj?.prices) {
				setPrices(simPrices.responseObj.prices);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const newSubscriptionPlaceHolder = () => {
		return {
			name: `${user.firstName} ${user.lastName}`,
			simNo: "",
			asset: "",
			email: user.email,
			userId: user._id,
			phone: user.phone,
			subscriptionId: null,
		};
	};

	const getEditSim = () => {
		const data = editSimSubscription;
		if (!data) {
			return;
		}
		const simSubscription = simCards.find(
			(sim: any) => sim.simNo === data.simNo
		);
		data.subscriptionId = simSubscription.subscriptionId;
		return data;
	};

	const openAddAssetPopUp = () => {
		setShowSimSubscriptionModal(true);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const dialogCloseClick = () => {
		setIsShowStripeStatusDialog(false);
	};

	const cancelSubscriptionDialogClose = () => {
		setIsShownConfirmDialog(false);
	};

	const reactivateSIMNumber = async () => {
		if (!reactivateSimNo) {
			return;
		}

		const subscription = simCards.find(
			(simCard: any) => simCard.simNo === reactivateSimNo
		);
		if (!subscription && !subscription.subscriptionId) {
			return;
		}

		setIsLoading(true);

		try {
			const simSubscription = await reactivateSimSubscription({
				subscriptionId: subscription.subscriptionId,
			});

			if (simSubscription?.error) {
				toast.error(simSubscription.error);
			} else {
				toast.success(`${reactivateSimNo} activated successfully`);
			}
			setIsShowReactivateDialog(false);
			setIsLoading(false);
		} catch (err: any) {
			setIsShowReactivateDialog(false);
			setIsLoading(false);
			toast.error(err?.message);
		}
	};

	const options = {
		print: false,
		download: false,
		filter: false,
		responsive: "vertical",
		viewColumns: false,
		selectableRows: "none" as SelectableRows,
	};

	const [tourSteps] = useState<any>([
		{
			selector: ".activate-sim-tour",
			content: `Click here to activate your data SIM cards.`,
		},
	]);

	const onEditClick = (data: any) => {
		const rowData = {
			name: `${user.firstName} ${user.lastName}`,
			simNo: data.simNo,
			asset: data.assetId,
			email: `${user.email}`,
			userId: data._id,
			phone: data.phone,
			priceId: data.priceId,
		};
		setEditSimSubscription(rowData);
		setShowEditSimSubscriptionModal(true);
	};

	const showCancel = (data: any) => {
		if (data.scheduledForDelete) {
			// already scheduled for deleted
			toast.success(`${data.simNo} Already Scheduled for Cancel Subscription`);
			return;
		}
		setCancelSubscriptionData(data);
		setIsShownConfirmDialog(true);
	};

	const cancel = async (data: any) => {
		try {
			setIsLoading(true);
			await cancelSubscription(data);
			toast.success(cancelSubscriptionData.simNo + " subscription canceled");
			resetPage();
			setIsLoading(false);
			cancelSubscriptionDialogClose();
		} catch (err: any) {
			toast.error(err.message);
			setIsLoading(false);
		}
	};

	const open = Boolean(anchorEl);
	const id = open ? "user-popover" : undefined;

	const columns = [
		{
			name: "simNo",
			label: "SIM Number",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="table-bold">
							<p>{value}</p>
						</div>
					);
				},
			},
		},
		{
			name: "assetName",
			label: "Customer Account",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="table-normal">
							<p>{value || "-"}</p>
						</div>
					);
				},
			},
		},
		{
			name: "startDate",
			label: "Date Activated",

			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="table-normal">
							<p>{moment(value).format("MMMM DD, yyyy")}</p>
						</div>
					);
				},
			},
		},
		{
			name: "endDate",
			label: "Next Payment Date",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="table-normal">
							<p>{moment(value).format("MMMM DD, yyyy")}</p>
						</div>
					);
				},
			},
		},
		{
			name: "amount",
			label: "Data Plan",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="table-normal">
							<p>{value}</p>
						</div>
					);
				},
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="table-normal">
							<p className="text-capitalize">{value}</p>
						</div>
					);
				},
			},
		},
		{
			name: "scheduledForDelete",
			label: "Auto-renewal Enabled",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="table-normal">
							<p>{!value ? "Yes" : "No"}</p>
						</div>
					);
				},
			},
		},
		{
			name: "",
			label: "Actions",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className="d-flex justify-content-end table-actions sim-table-action">
							<IconButton aria-label="Edit Customer Button">
								<Edit
									onClick={() => {
										onEditClick(tableMeta.tableData[tableMeta.rowIndex]);
									}}
								/>
							</IconButton>
							<IconButton aria-label="Delete Customer Button">
								<CancelIcon
									onClick={(event) => {
										showCancel(tableMeta.tableData[tableMeta.rowIndex]);
									}}
								/>
							</IconButton>
						</div>
					);
				},
			},
		},
	];
	const goBack = () => {
		history.goBack();
	};
	return (
		<>
			<div className="customer-account-page p-4 m-p-0 w-100">
				{/* <SystemTour tourSteps={tourSteps} position={0} /> */}
				<div className="customer-account-content">
					{width > 1024 && (
						<div className="d-flex justify-content-end p-4 m-d-none">
							<div className="w-100 d-flex justify-content-between">
								<div className="search-section">
									<input type="text" placeholder="search" />
									<span>
										<SearchIcon />
									</span>
								</div>

								<button
									className="btn primary-btn ml-3 add-customer activate-sim-tour"
									onClick={openAddAssetPopUp}
								>
									Activate New SIM Card
								</button>
							</div>
						</div>
					)}
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						<div className="p-2">
							<div className="align-items-center d-flex justify-content-between p-1">
								<p className="mb-2 popover-heading">Users</p>
								<div>
									<PeopleRoundedIcon className="mr-1" />
									{get(popoverData, "authorized_users.length", 0) + 1}
								</div>
							</div>
							<div>
								{[
									{
										firstName: user?.firstName,
										lastName: user?.lastName,
										email: user?.email,
									},
									...get(popoverData, "authorized_users", []),
								].map((mapData: any) => (
									<div
										key={get(mapData, "email", "")}
										className="popover-content"
									>
										<p className="name mb-2">{`${get(
											mapData,
											"firstName",
											""
										)} ${get(mapData, "lastName", "")}`}</p>
										<p className="user-email mb-3">
											{get(mapData, "email", "")}
										</p>
									</div>
								))}
							</div>
						</div>
					</Popover>
					{isLoading && <SpinnerLoader />}
					<div className="table-responsive customer-table m-d-none">
						<CustomDataTable
							title=""
							rows={simCards}
							columns={columns}
							options={options}
						/>
					</div>

					{/* Mobile Card Design */}
					<div className="m-customer-account d-none m-d-block">
						<div
							className="m-customer-account-header border-bottom d-flex align-items-center"
							onClick={() => goBack()}
						>
							<ArrowBackIcon className="i-16 txt-primary font-600" />
							<p className="desc-normal font-text-4 font-600 mb-0">Data SIM</p>
						</div>
						<div className="responsive-search">
							<input
								type="text"
								onChange={debounce(searchSimCard, 700)}
								placeholder="Search by Sim No or Asset Name"
							/>
							<SearchIcon />
						</div>
						<div className="m-customer-account-cards">
							{simCards &&
								!!simCards?.length &&
								simCards?.map((mapData: any, index: number) => (
									<div
										className={
											simCards.length - 1 === index
												? "m-customer-account-card mb-2 subscription-last-element"
												: "m-customer-account-card mb-2"
										}
										key={mapData?._id}
									>
										<div>
											<div className="d-flex align-items-center justify-content-between md-m-b-3">
												<p className="desc-small font-primary font-bold text-truncate mb-2 m-mv-200 md-m-0">
													{mapData.simNo}
												</p>
												<Dropdown className="more-dropdown">
													<Dropdown.Toggle
														id="dropdown-basic"
														bsPrefix="my-dropdown-toggle p-0"
													>
														<MoreHorizIcon className="i-20 font-tertiary cursor-pointer" />
													</Dropdown.Toggle>
													<Dropdown.Menu
														className="card-action"
														style={{ width: "inherit" }}
													>
														<Dropdown.Item
															onClick={() => {
																onEditClick(mapData);
															}}
														>
															Edit
														</Dropdown.Item>
														<Dropdown.Item
															onClick={(event) => {
																showCancel(mapData);
															}}
														>
															Cancel
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div>
											<div className="d-flex">
												<p className="desc-small font-teritiary mb-2 rate-peryr flex-1">
													{mapData?.assetName}
												</p>
												<p className="desc-small font-teritiary mb-2 rate-peryr text-capitalize">
													{"Status: " + mapData?.status}
												</p>
											</div>
											<p className="desc-small font-secondary mb-2 rate-peryr">
												{mapData.amount}
											</p>
											<div className="d-flex align-items-center mt-2">
												<div className="date-block">
													<div className="active-date">
														<p className="desc-exsmall date-heading">
															Date Activated
														</p>
														<p className="desc-small f-w-500">
															{moment(mapData.startDate).format(
																"MMMM DD, yyyy"
															)}
														</p>
													</div>
													<div className="active-date align-items-center">
														<p className="desc-exsmall">Next Payment Date</p>
														<p className="desc-small f-w-500">
															{moment(mapData.endDate).format("MMMM DD, yyyy")}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
						{!simCards.length && (
							<div className="d-flex justify-content-center py-3">
								<p className="text-center">Sorry, no matching records found</p>
							</div>
						)}
						{width < 1024 && (
							<div>
								<div className="m-add-customer d-none m-d-block add-customer">
									<button
										className="btn btn-medium primary-btn activate-sim-tour"
										onClick={openAddAssetPopUp}
									>
										<AddIcon className="mr-0" />
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{showSimSubscriptionModal && (
				<ActivateSim
					showDrawer={showSimSubscriptionModal}
					setShowDrawer={setShowSimSubscriptionModal}
					getSimData={newSubscriptionPlaceHolder}
					assets={assetsList}
					prices={prices}
					onClickScanner={onClickScanner}
					nativeBridgeData={nativeBridgeData}
					updateSimSubscriptions={resetPage}
					setNativeBridgeData={setNativeBridgeData}
				/>
			)}
			{showEditSimSubscriptionModal && (
				<ActivateSim
					showDrawer={showEditSimSubscriptionModal}
					setShowDrawer={setShowEditSimSubscriptionModal}
					getSimData={getEditSim}
					assets={assetsList}
					prices={prices}
					onClickScanner={onClickScanner}
					nativeBridgeData={nativeBridgeData}
					updateSimSubscriptions={resetPage}
					setNativeBridgeData={setNativeBridgeData}
				/>
			)}
			{isShowStripeStatusDialog && (
				<StripePaymentStatusDialog
					paymentSummary={paymentSummary}
					positiveBtnLabel="Back To Data Sim"
					negativeBtnLabel="Cancel"
					onPositiveBtnClicked={() => {}}
					onNegativeBtnClicked={() => dialogCloseClick()}
					onCloseIconClicked={() => dialogCloseClick()}
				/>
			)}
			{isShowReactivateDialog && reactivateSimNo && (
				<ReactivateSimConfirmDialog
					reactivateSimNo={reactivateSimNo}
					positiveBtnLabel="Activate"
					negativeBtnLabel=""
					onPositiveBtnClicked={() => reactivateSIMNumber()}
					onNegativeBtnClicked={() => dialogCloseClick()}
					onCloseIconClicked={() => dialogCloseClick()}
				/>
			)}
			{isShownConfirmDialog && (
				<ConfirmDialog
					dialogTitle="Cancel Subscription"
					message={`Are you sure want to cancel the subscription?`}
					positiveBtnLabel="Cancel Subscription"
					onPositiveBtnClicked={() => {
						cancel(cancelSubscriptionData);
					}}
					onNegativeBtnClicked={() => {}}
					onCloseIconClicked={() => cancelSubscriptionDialogClose()}
					negativeBtnLabel=""
				/>
			)}
			{isLoading && <SpinnerLoader />}
		</>
	);
};

export default SimSubscriptions;
