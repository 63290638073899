import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

import { AntSwitch } from "../CommonSwitch/CommonSwitch";
import { SpinnerLoader } from "../../common/Global.Style";
import { checkCurrentlyRunningAutopilot } from "../../api/DevicesController";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

const BootstrapInput = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"label + &": {
				marginTop: theme.spacing(3),
			},
		},
		input: {
			borderRadius: 4,
			position: "relative",
			backgroundColor: theme.palette.background.paper,
			border: "1px solid #ced4da",
			fontSize: 16,
			padding: "10px 26px 10px 12px",
			transition: theme.transitions.create(["border-color", "box-shadow"]),
			"&:focus": {
				borderRadius: 4,
				borderColor: "#80bdff",
				boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
			},
		},
	})
)(InputBase);

export const DeviceControllerDrawer = ({
	open,
	onClose,
	selectedDevice,
	changeDeviceControll,
	selectedDeviceControlls,
	drawerLoading,
}: any) => {
	const [isShownEditConfirmDialog, setIsShownConfirmDialog] =
		useState<boolean>(false);
	const [editConfirmationMessage, setEditConfirmationMessage] =
		useState<string>("");

	const checkIfAutoPilotRunning = async (
		deviceId: string,
		code: string,
		action: boolean
	) => {
		const response = await checkCurrentlyRunningAutopilot(deviceId);
		if (response?.responseObj && response?.responseObj?.isAutoPilotRunning) {
			setIsShownConfirmDialog(true);
			setEditConfirmationMessage(
				"There is an active auto remediation in progress, do you want to cancel it?"
			);
		} else {
			await changeDeviceControl(action, code);
		}
	};

	const changeDeviceControl = async (value: boolean, code: string) => {
		changeDeviceControll({
			value,
			code,
		});
		setIsShownConfirmDialog(false);
	};

	const controlDevice = async (value: boolean, data: any) => {
		if (data.code === "switch" && !value) {
			await checkIfAutoPilotRunning(selectedDevice.deviceId, data.code, value);
		} else {
			await changeDeviceControl(value, data.code);
		}
	};

	const renderControll = (data: any) => {
		switch (data.type) {
			case "Enum":
				return (
					<FormControl variant="filled">
						<Select
							input={<BootstrapInput />}
							labelId={data.code}
							id={data.code}
							onChange={(event: any) => {
								changeDeviceControll({
									value: event.target.value,
									code: data.code,
								});
							}}
							value={data.value}
						>
							{JSON.parse(data?.values).range.map((value: any) => (
								<MenuItem value={value} key={value}>
									{value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				);
			case "Boolean":
				return (
					<div>
						<FormControl>
							<AntSwitch
								onChange={(event: any, value: any) => {
									controlDevice(value, data);
								}}
								checked={data.value}
							/>
						</FormControl>
						{isShownEditConfirmDialog && (
							<ConfirmDialog
								dialogTitle="Edit"
								message={editConfirmationMessage}
								positiveBtnLabel="Edit"
								negativeBtnLabel="Cancel"
								onPositiveBtnClicked={() =>
									changeDeviceControl(false, "switch")
								}
								onNegativeBtnClicked={() => setIsShownConfirmDialog(false)}
								onCloseIconClicked={() => setIsShownConfirmDialog(false)}
							/>
						)}
					</div>
				);
		}
	};

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			className="device-controll-drawer"
		>
			{drawerLoading && <SpinnerLoader />}
			<div className="drawer-content-section">
				<div>
					<div className="drawer-header d-flex align-items-center justify-content-between">
						<h3 className="drawer-header-heading">{`${selectedDevice?.deviceName} Controller`}</h3>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</div>
					<div className="drawer-contents">
						{selectedDeviceControlls?.map((mapData: any) => (
							<div className="form-group mb-4">
								<div className="row">
									<label className="col-4 font-primary form-label text-capitalize">
										{mapData.code}
									</label>
									<div className="col-8">{renderControll(mapData)}</div>
								</div>
							</div>
						))}
					</div>
					<div className="drawer-footer d-flex justify-content-end">
						<Button
							className="mr-2"
							variant="outlined"
							color="default"
							onClick={onClose}
						>
							Cancel
						</Button>
					</div>
				</div>
			</div>
		</Drawer>
	);
};
