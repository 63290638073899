import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AnySrvRecord } from "dns";
import { useHistory } from "react-router-dom";

import LocationSelector from "../LocationSelector/LocationSelector";
import {
	addDevice,
	getGatewayTypeDataList,
	updateDevice,
} from "../../api/DevicesController";
import { GatewayDetailModal } from "../../modals/device/GatewayDetail";
import { URL } from "../Navbar/routes";

import "./AddEditGateway.scoped.scss";
interface AddEditGatewayProps {
	show: boolean;
	onHide: (isCallFromSave: boolean) => void;
	gatewayDetailsModal: GatewayDetailModal;
}
interface Item {
	label: string;
	value: number;
	path?: string;
}

const AddEditGateway: React.FC<AddEditGatewayProps> = ({
	show,
	onHide,
	gatewayDetailsModal,
}) => {
	const history = useHistory();
	const [gateways, setGateways] = useState<Array<Item>>([]);
	const [selectedGateway, setSelectedGateway] = useState<any>();
	const [selectedLocation, setSelectedLocation] = useState<any>();

	const [gatewayDetailsState, setGatewayDetailsState] =
		useState<GatewayDetailModal>(gatewayDetailsModal);

	const isEditMode = () => {
		return gatewayDetailsState._id ? true : false;
	};

	const saveDetails = () => {
		let gatewayDetail: GatewayDetailModal = new GatewayDetailModal();
		gatewayDetail = gatewayDetailsState;
		gatewayDetail.location = selectedLocation?.value
			? selectedLocation?.value
			: "";
		gatewayDetail.gatewayType = selectedGateway?.value
			? selectedGateway?.value
			: "";
		if (!isEditMode()) {
			delete gatewayDetail._id;
			callAddGatewayDetail(gatewayDetail);
		} else {
			callUpdateGatewayDetail(gatewayDetail);
		}
	};

	const callAddGatewayDetail = (gatewayDetail: GatewayDetailModal) => {
		addDevice(gatewayDetail)
			.then((response: any) => {
				if (response.responseObj) {
					onHide(true);
					history.push(URL.DEVICE_MANAGEMENT);
				}
			})
			.catch((error: AnySrvRecord) => {});
	};

	const callUpdateGatewayDetail = (gatewayDetail: GatewayDetailModal) => {
		updateDevice(gatewayDetail)
			.then((response: any) => {
				if (response.responseObj) {
					onHide(true);
				}
			})
			.catch((error: AnySrvRecord) => {});
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			getGatewayTypeDataList()
				.then((response: any) => {
					if (
						response.responseObj.types &&
						response.responseObj.types.length > 0
					) {
						const optionsArray: Item[] = response.responseObj.types.map(
							(item: any) => {
								return { value: item.value, label: item.label };
							}
						);
						setGateways(optionsArray);
						setSelectedGateway(
							optionsArray.find(
								(x: any) => x.value === gatewayDetailsState.gatewayType
							)
						);
						setSelectedLocation({
							label: "",
							value: gatewayDetailsState.location[0],
						});
					}
				})
				.catch((error) => {});
			return () => {
				isMounted = false;
			};
		}
	}, []);

	const onChangeLocation = (value: any) => {
		setSelectedLocation(value);
	};

	const updateDeviceDetail = (value: string, propertyName: string) => {
		const gatewayDetailTemp = { ...gatewayDetailsState };
		(gatewayDetailTemp as any)[propertyName] = value;
		setGatewayDetailsState(gatewayDetailTemp);
	};

	return (
		<Modal
			show={show}
			onHide={() => onHide(false)}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			dialogClassName="modal-custom"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<h5>{isEditMode() ? "Edit" : "Add"} Gateway</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-sm-12">
							<div className="d-flex">
								<div className="w-100 pl-0 pr-0">
									<div className="form-group">
										<h6>Device Name</h6>
										<input
											type="text"
											id="name"
											disabled
											className="input w-100"
											placeholder="Device Name"
											value={gatewayDetailsState.deviceName}
											onChange={(event) =>
												updateDeviceDetail(event.target.value, "deviceName")
											}
										/>
									</div>
								</div>
							</div>
							<h6 className="mt-4">Gateway Type</h6>
							<div className="dropdown w-100 pl-0 pr-0 pb-3">
								<input
									type="text"
									id="type"
									disabled
									className="input w-100"
									placeholder="Device NamTypee"
									value={gatewayDetailsState.gatewayType}
								/>
							</div>

							<div className="d-flex">
								<div className="w-100 pl-0 pr-0 pt-3">
									<div className="form-group ">
										<h6>Description</h6>
										<input
											id="description"
											type="text"
											className="input w-100"
											placeholder="Device Description"
											value={gatewayDetailsState.description}
											onChange={(event) =>
												updateDeviceDetail(event.target.value, "description")
											}
										/>
									</div>
								</div>
							</div>

							<h6 className="mt-4">Location</h6>
							<div className="dropdown w-100  pl-0 pr-0">
								<LocationSelector
									onSelectLocation={onChangeLocation}
									buildingId={gatewayDetailsModal.building}
									selectedLocationId={selectedLocation?.value}
									selectProps={{
										isClearable: false,
									}}
								/>
							</div>
							<div className="d-flex mt-4 justify-content-end">
								<button
									type="button"
									className="btn mopbtnc pl-5 pr-5"
									onClick={() => onHide(false)}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn add pl-5 pr-5"
									onClick={saveDetails}
								>
									{isEditMode() ? "Update" : "Add"}
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};
export default AddEditGateway;
