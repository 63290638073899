import { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";

import { SpinnerLoader } from "../../common/Global.Style";
import { Product } from "./Product";
import { Category } from "./Category";
import {
	getMarketPlaceCategory,
	getMarketPlaceProduct,
} from "../../api/MarketPlaceController";

import "./MarketPlace.scoped.scss";
import { URL } from "../../components/Navbar/routes";
import Shared from "../../common/Shared";
import { consultationTypes } from "../../enums/common";

const MarketPlace = () => {
	const [productCategoryList, setProductCategoryList] = useState([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [productList, setProductList] = useState([]);
	const [consultationCategoryList] = useState(consultationTypes);

	const history = useHistory();

	useEffect(() => {
		getProductsApiCall("", true);
		getProductCategoryApiCall();
	}, []);

	const getProductCategoryApiCall = async () => {
		const response = await getMarketPlaceCategory();
		if (response?.responseObj?.length) {
			setProductCategoryList(get(response, "responseObj", []));
		}
	};

	const getProductsApiCall = async (category: string, feature: boolean) => {
		setIsLoading(true);
		const response = await getMarketPlaceProduct(category, feature);
		if (response?.responseObj?.length) {
			setProductList(get(response, "responseObj", []));
		}
		setIsLoading(false);
	};

	return (
		<div className="market-place-container">
			{isLoading && <SpinnerLoader />}
			<div className="market-block">
				<h2 className="market-place-heading">IAQ Store</h2>
				<p className="market-place-para">
					One Stop Solution for all your Indoor Air Quality Needs.
				</p>
				<div>
					<h6 className="cat-heading">Category</h6>
					<div className="category-card-container">
						{productCategoryList?.map((mapData: any) => (
							<Category
								name={mapData?.name}
								id={mapData?._id}
								imgSrc={mapData?.image}
							/>
						))}
						{isEmpty(productCategoryList) && (
							<div className="d-flex justify-content-center mt-5 w-100">
								<p>No Category Available</p>
							</div>
						)}
					</div>
					<div>
						<h6 className="cat-heading">Consultation Services</h6>
						{Shared.title === "iQi Cloud" && (
							<div className="m-d-none">
								<div className="consultation-card-container row m-0 mb-4 mr-0 mt-5">
									{consultationCategoryList?.map((mapData: any) => (
										<div className="category-cards col-12 col-sm-12 col-md-6 col-lg-3">
											<div
												onClick={() =>
													history.push(
														`${URL.CONSULTANTS}?type=${mapData?.name}`
													)
												}
												className="categroy-card w-100 px-3 justify-content-between cursor-pointer"
											>
												<p>{mapData.name}</p>
												<img alt="category" src={mapData?.img} />
											</div>
										</div>
									))}
								</div>
							</div>
						)}
						{/* <div
								onClick={() => history.push(URL.CONSULTATION_REPORTS)}
								className="categroy-card cursor-pointer"
							>
								<p>Consultation Services</p>
								<img
									alt="prod"
									src={
										"https://iqintel-dev-files.s3.amazonaws.com/product/1653990077160-consulant.png"
									}
								/>
							</div> */}
					</div>
				</div>
				<div>
					<h6 className="cat-heading">Featured Products</h6>
					<div className="recomended-products row">
						{productList?.map((mapData: any) => (
							<Product
								name={mapData.name}
								id={mapData?._id}
								imgSrc={get(mapData, "images[0]")}
								isFeatured={get(mapData, "isFeatured")}
							/>
						))}
						{isEmpty(productList) && (
							<div className="d-flex justify-content-center mt-5 w-100">
								<p>No Product Available</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MarketPlace;
