import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

import "./ReactivateSimConfirmDialog.scss";

interface ReactivateSimConfirmDialogProps {
	positiveBtnLabel: string;
	negativeBtnLabel: string;
	onPositiveBtnClicked: () => void;
	onNegativeBtnClicked: () => void;
	onCloseIconClicked: () => void;
	reactivateSimNo: string;
}

const ReactivateSimConfirmDialog: React.FC<ReactivateSimConfirmDialogProps> = ({
	reactivateSimNo,
	positiveBtnLabel,
	onPositiveBtnClicked,
	onNegativeBtnClicked,
	onCloseIconClicked,
}) => {
	const history = useHistory();
	const [show, setShow] = useState(true);

	const onPositiveBtn = () => {
		setShow(false);
		history.push("/dashboard/data-sim");
		onPositiveBtnClicked();
	};

	const hideModal = () => {
		setShow(false);
		onCloseIconClicked();
	};

	return (
		<>
			<Modal show={show} onHide={hideModal} backdrop="static" keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Reactivate SIM</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<p>Are you want to reactivate SIM {reactivateSimNo}?</p>
						<Button
							variant="primary"
							onClick={onPositiveBtn}
							style={{ cursor: "pointer" }}
							className="w-100"
						>
							{positiveBtnLabel}
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ReactivateSimConfirmDialog;
