export class MonitorDetailModal {
	public deviceType: string;
	public building: string;
	public deviceId: string;
	public description: string;
	public deviceName: string;
	public location: string;
	public features: string[];
	public image: string;
	public _id?: string;
	public subType?: string;

	public constructor() {
		this.deviceType = "";
		this.building = "";
		this.deviceId = "";
		this.description = "";
		this.deviceName = "";
		this.location = "";
		this.features = [];
		this.image = "";
		this._id = "";
		this.subType = "";
	}
}
