import React, { useEffect, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { SelectableRows } from "mui-datatables";
import get from "lodash/get";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { CSVLink } from "react-csv";
import CloudDownload from "@material-ui/icons/CloudDownload";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";

import LocationSelector from "../../../components/LocationSelector/LocationSelector";
import CustomDataTable from "../../../components/CustomDataTable/CustomDataTable";
import { getMaintenanceReport } from "../../../api/ReportsController";
import { SpinnerLoader } from "../../../common/Global.Style";
import { RootState } from "../../../redux/reducers";
import { resetMaintenanceReport } from "../../../api/DevicesController";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { UserRoles } from "../../../enums/UserRoles";
import { URL } from "../../../components/Navbar/routes";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SearchIcon from "@material-ui/icons/Search";

import "./MaintenanceReports.scoped.scss";
import { toast } from "react-toastify";
import PaymentPendingInfo from "../../../components/PaymentPendingInfo/PaymentPendingInfo";
import { IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const MaintenanceReports: React.FC = () => {
	const building = useSelector((state: RootState) => state.building);
	const asset = useSelector((state: RootState) => state.asset);
	const assets: any[] = useSelector((state: RootState) => state.assets);
	const locations: any = useSelector((state: RootState) => state.locations);
	const user: any = useSelector((state: RootState) => state.user);

	const [maintenanceReport, setMaintenanceReport] = useState<Array<any>>([]);
	const [filterMaintenanceReport, setFilterMaintenanceReport] = useState<
		Array<any>
	>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [locationData, setLocationData] = useState<any>({});
	const [showSalesEmailsNotFoundModal, setShowSalesEmailsNotFoundModal] =
		useState<boolean>(false);
	const [downloadCsvData, setDownloadCsvData] = useState<any>([]);

	const history = useHistory();

	const [tourSteps] = useState<any>([
		{
			selector: ".order-parts-tour",
			content: `Click here to purchase replacement parts.`,
		},
		{
			selector: ".maintenance-refresh-tour",
			content: `Click here to get the list of remediation devices needed attention.`,
		},
	]);

	useEffect(() => {
		getMaintenanceReportData();
	}, [building]);

	useEffect(() => {
		if (locations.length) {
			let temData: any = {};
			locations.forEach((mapData: any) => {
				temData[mapData._id] = mapData;
			});
			setLocationData(temData);
		}
	}, [locations]);

	const onChangeLocation = (value: any) => {
		filterMaintenanceByLocation(value);
	};

	const filterMaintenanceByLocation = (location: any) => {
		if (location)
			setFilterMaintenanceReport(
				maintenanceReport.filter(
					(maintenance: any) => maintenance.location === location.value
				)
			);
		else setFilterMaintenanceReport(maintenanceReport);
	};

	const salesEmails = () =>
		assets?.find((val: any) => val._id === asset._id)?.salesEmails;

	const salesEmailNotFound = () => {
		setShowSalesEmailsNotFoundModal(true);
	};

	const getSalesEmailsNotFoundMsg = () =>
		UserRoles.CONTRACTOR === user?.role
			? "Add sales email under customer account to order parts"
			: "Contact your admin to get sales email added for this customer account";

	const getSalesEmailsNotFoundModalPostiveButtonLabel = () =>
		user.role === UserRoles.CONTRACTOR ? "Add Sales Email" : "Close";

	const getMaintenanceReportData = async () => {
		setIsLoading(true);
		const response = await getMaintenanceReport(building._id, asset._id);
		setIsLoading(false);
		if (
			response?.responseObj?.devices &&
			response.responseObj.devices?.length > 0
		) {
			setMaintenanceReport(response.responseObj.devices);
			setFilterMaintenanceReport(response.responseObj.devices);
		} else {
			setMaintenanceReport([]);
			setFilterMaintenanceReport([]);
		}
	};

	const resetReplacementPart = async (deviceData: any) => {
		setIsLoading(true);
		const response = await resetMaintenanceReport({
			deviceId: deviceData._id,
			replacmentPartsId: deviceData.replacmentPartsId,
			days: deviceData.days,
		});
		setIsLoading(false);
		if (response.responseObj) {
			toast.success(response.responseObj.message);
			getMaintenanceReportData();
		} else {
			toast.error(response.error);
		}
	};

	const options = {
		print: true,
		download: true,
		viewColumns: false,
		showResponsive: true,
		responsive: "scrollMaxHeight",
		downloadOptions: {
			filename: "MaintenanceReport.csv",
		},
		selectableRows: "none" as SelectableRows,
		onDownload: () => {
			return false;
		},
	};

	const checkAsset = () => {
		return !asset?.payPending;
	};

	useEffect(() => {
		if (filterMaintenanceReport?.length) {
			setDownloadCsvData(
				filterMaintenanceReport.map((mapData: any) => {
					let AlertStatus = "";
					switch (mapData.maintenaceStatus) {
						case "week":
							AlertStatus = `Need Immediate attention. ${
								mapData.maintenanceType
							} Replacement due by ${moment(mapData.dueDate).format(
								"MM/DD/YYYY"
							)}`;
							break;
						case "past":
							AlertStatus = `Urgent - Replacement ${
								mapData.maintenanceType
							} past due ${moment(mapData.dueDate).format("MM/DD/YYYY")}`;
							break;
						default:
							AlertStatus = `${
								mapData.maintenanceType
							} Replacement due by ${moment(mapData.dueDate).format(
								"MM/DD/YYYY"
							)}`;
							break;
					}
					return {
						LocationName: mapData?.location?.map((loc: any) => {
							return locationData[loc]?.name;
						}),
						DeviceName: mapData.deviceName,
						DeviceImage: mapData.image,
						MaintenanceType: mapData.maintenanceType,
						AlertStatus,
						Maintenance_Required_By_Date: mapData.dueDate,
					};
				})
			);
		}
	}, [filterMaintenanceReport]);

	const searchReport = (e: any) => {
		let { value } = e.target;
		value = value.toLowerCase();
		setFilterMaintenanceReport(
			maintenanceReport.filter(
				(filterData: any) =>
					filterData?.deviceName?.toLowerCase()?.includes(value) ||
					filterData?.location
						.map(
							(location: any) => get(locationData, location, { name: "" }).name
						)
						.join(", ")
						?.toLowerCase()
						?.includes(value)
			)
		);
	};

	const renderAlertStatus = (
		value: string,
		partType: string,
		dueData: string
	) => {
		switch (value) {
			case "week":
				return (
					<span
						style={{ color: "#FF0000" }}
					>{`Need Immediate attention. ${partType} Replacement due by ${moment(
						dueData
					).format("MM/DD/YYYY")}`}</span>
				);
			case "past":
				return (
					<span
						style={{ color: "#8B0000" }}
					>{`Urgent - Replacement ${partType} past due ${moment(dueData).format(
						"MM/DD/YYYY"
					)}`}</span>
				);
			default:
				return (
					<span style={{ color: "#FFA500" }}>{`${partType} Replacement due
				by
				${moment(dueData).format("MM/DD/YYYY")}`}</span>
				);
		}
	};

	const columns = [
		{
			name: "_id",
			options: {
				filter: false,
				display: false, // hide this filed in Data-Table
			},
		},
		{
			name: "location",
			label: "LOCATION NAME",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return value.map((val: any) => `${locationData[val]?.name} `);
				},
			},
		},
		{
			name: "deviceName",
			label: "DEVICE NAME",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "N/A"}
						</>
					);
				},
			},
		},
		{
			name: "image",
			label: "DEVICE IMAGE",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<img
							className="w-10 h-10 product-image"
							src={
								tableMeta.rowData[tableMeta.columnIndex]
									? tableMeta.rowData[tableMeta.columnIndex]
									: "/images/device1.svg"
							}
							alt=""
						/>
					);
				},
			},
		},
		{
			name: "maintenanceType",
			label: "MAINTENANCE TYPE",
			options: {
				filterOptions: { fullWidth: true },
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? tableMeta.rowData[tableMeta.columnIndex]
								: "N/A"}
						</>
					);
				},
			},
		},
		{
			name: "dueDate",
			label: "MAINTENANCE REQUIRED BY DATE/TIME",
			options: {
				filterOptions: { fullWidth: true },
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							{tableMeta.rowData[tableMeta.columnIndex]
								? moment(value).format("MM/DD/YYYY")
								: "N/A"}
						</>
					);
				},
			},
		},
		{
			name: "maintenaceStatus",
			label: "ALERT STATUS",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return renderAlertStatus(
						value,
						tableMeta.rowData[tableMeta.columnIndex - 2],
						tableMeta.rowData[tableMeta.columnIndex - 1]
					);
				},
			},
		},
		{
			name: "_id",
			label: "Reset",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<RotateLeftIcon
							onClick={() => {
								resetReplacementPart(
									filterMaintenanceReport[tableMeta.rowIndex]
								);
							}}
						/>
					);
				},
			},
		},
	];
	return checkAsset() ? (
		<div className="container-fluid maintainance-report">
			{/* <SystemTour tourSteps={tourSteps} position={0} /> */}
			<div className="row mt-3 mb-3 ">
				<div className="col-lg-12 col-md-12 col-sm-12 Maintenance-re bg-white p-3 maintainance-responssive">
					<div className="w-100 d-flex maintain-header">
						<div
							className="w-50 m-header align-items-center m-d-flex d-none"
							onClick={() => history.goBack()}
						>
							<ArrowBackIosIcon fontSize="small" />
							<h5 className="">Maintenance Report</h5>
						</div>
						<div className="w-50 m-header align-items-center justify-content-between m-d-none d-block">
							<h5 className="">Maintenance Report</h5>
						</div>
						<div className="responsive-search">
							<input
								type="text"
								onChange={debounce(searchReport, 1000)}
								placeholder="Search"
							/>
							<SearchIcon />
						</div>
						<div className="w-50 d-flex mr-2 maintainance-report-search">
							<div className="w-50 ml-auto location-search">
								<h6>Select Location</h6>
								<div className="dropdown  pl-0 pr-0">
									<LocationSelector onSelectLocation={onChangeLocation} />
								</div>
							</div>
							<div className="d-flex button-blocks">
								<div className="pt-3 pb-3 pl-3 mt-2 mr-0 d-flex buttons">
									{!!salesEmails() ? (
										<a
											href={`mailto:${salesEmails()}`}
											className="btn Maintenance-re-btn pl-4 pr-4 mr-0 order-parts-tour"
										>
											Order Parts
										</a>
									) : (
										<button
											className="btn Maintenance-re-btn pl-4 pr-4 mr-0 order-parts-tour"
											onClick={salesEmailNotFound}
										>
											Order Parts
										</button>
									)}
								</div>
								<div className="pt-3 pb-3 pl-3 mt-2 mr-0  buttons">
									<button
										onClick={getMaintenanceReportData}
										className="btn Maintenance-re-btn pl-4 pr-4 mr-0 maintenance-refresh-tour"
									>
										Refresh
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-between mt-2"></div>
					<div className="mt-3 mb-5 maintainance-report-table">
						<CustomDataTable
							title="Maintenance Report"
							rows={filterMaintenanceReport}
							columns={columns}
							options={options}
							components={{
								icons: {
									DownloadIcon: forwardRef((iconProps: any, ref: any) => (
										<CSVLink
											data={downloadCsvData}
											uFEFF={false}
											className="d-inherit"
											filename={"MaintenanceReport.csv"}
										>
											<CloudDownload
												className="custom-download-icon"
												{...iconProps}
												ref={ref}
											/>
										</CSVLink>
									)),
								},
							}}
						/>
					</div>
					<div className="maintainance-card-container">
						{filterMaintenanceReport.map((mapData: any) => (
							<div key={mapData?._id} className="maintainance-card">
								<div className="m-card-img-block d-flex align-items-center">
									<div className="image-block">
										<img src={mapData?.image} alt="" />
									</div>
									<div className="name-locations-action d-flex flex-1 align-items-center justify-content-between">
										<div className="name-location d-flex flex-column">
											<p className="name">{mapData?.deviceName}</p>
											<p className="location">
												<span>
													<LocationOnIcon />
												</span>
												{mapData?.location
													?.map(
														(location: any) =>
															get(locationData, location, { name: "" }).name
													)
													.join(", ")}
											</p>
										</div>
										<div className="action">
											<IconButton
												onClick={() => {
													resetReplacementPart(mapData);
												}}
											>
												<AutorenewIcon />
											</IconButton>
										</div>
									</div>
								</div>
								<div className="d-flex align-items-center prop-block">
									<div className="d-flex flex-column flex-1">
										<p className="prop-title">MAINTENANCE TYPE</p>
										<p className="prop-value">
											{get(mapData, "maintenanceType", "N/A")}
										</p>
									</div>
									<div className="d-flex flex-column flex-1">
										<p className="prop-title">REQUIRED BY DATE/TIME</p>
										<p className="prop-value">
											{mapData?.dueDate
												? moment(mapData?.dueDate).format("MM/DD/YYYY")
												: " N/A "}
										</p>
									</div>
								</div>
								<div className="prop-block">
									<p className="prop-title">ALERT STATUS</p>
									<p className="prop-value">
										{renderAlertStatus(
											mapData?.maintenaceStatus,
											mapData?.maintenanceType,
											mapData?.dueDate
										)}
									</p>
								</div>
							</div>
						))}
						{isEmpty(filterMaintenanceReport) && (
							<div className="d-flex justify-content-center mt-2">
								<p>No Record found</p>
							</div>
						)}
					</div>
					{isLoading && <SpinnerLoader />}
				</div>
			</div>
			{showSalesEmailsNotFoundModal && (
				<ConfirmDialog
					dialogTitle="Add Sales Email"
					message={getSalesEmailsNotFoundMsg()}
					positiveBtnLabel={getSalesEmailsNotFoundModalPostiveButtonLabel()}
					negativeBtnLabel=""
					onPositiveBtnClicked={() => {
						setShowSalesEmailsNotFoundModal(false);
						if (user.role === UserRoles.CONTRACTOR) {
							history.push(`${URL.CUSTOMER_ACCOUNT}?edit=${asset._id}`);
						}
					}}
					onNegativeBtnClicked={() => {
						setShowSalesEmailsNotFoundModal(false);
					}}
					onCloseIconClicked={() => {
						setShowSalesEmailsNotFoundModal(false);
					}}
				/>
			)}
		</div>
	) : (
		<PaymentPendingInfo name={asset.name} />
	);
};
export default MaintenanceReports;
