import React from "react";

import "./IAQStoreSubMenu.scoped.scss";
import { IAQStoreSubmenuValues } from "./Menus";
import { IMenuItem } from "../../../common/Interface";
import SubMenu from "../Components/SubMenu";

const IAQStoreSubMenu: React.FC = () => {
	const renderIAQStoreSubmenuValues = () => (
		<>
			{IAQStoreSubmenuValues.map((item: any, index: any) => {
				return (
					<div key={`${index}-${item.path}`}>
						<SubMenu item={item} />
					</div>
				);
			})}
		</>
	);
	return (
		<div className="navbar-sub-menu px-3 pt-3 w-100 bg-white">
			{renderIAQStoreSubmenuValues()}
		</div>
	);
};

export default IAQStoreSubMenu;
