import React from "react";
import { useHistory } from "react-router-dom";

import { URL } from "../../components/Navbar/routes";

import "./MarketPlace.scoped.scss";

export interface IProduct {
	name: string;
	id: string;
	imgSrc: string;
	isFeatured: boolean;
}

export const Product: React.FC<IProduct> = ({
	name,
	imgSrc,
	id,
	isFeatured,
}) => {
	const history = useHistory();

	return (
		<div
			onClick={() => history.push(`${URL.PRODUCT_DETAILS}/${id}`)}
			className="product-card col cursor-pointer"
		>
			{/* <div className="offer ">off 10%</div> */}
			{isFeatured && <div className="recomented ">Featured</div>}
			<div className="product-img d-flex align-items-center justify-content-center">
				<img alt="prod" src={imgSrc} />
			</div>
			<div className="product-des">
				<p className="product-name">{name}</p>
			</div>
		</div>
	);
};
