import React from "react";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

import "./DeviceManagement.scoped.scss";
import { DeviceSetUp } from "./DeviceSetup";
interface IDeviceManagementHeader {
	setSelectedBuildingData: (buildingData: object) => void;
	setShowAddBuilding: (open: boolean) => void;
	onFilterData: (e: any) => void;
	setShowLocationModal: (open: boolean) => void;
	setBuildingIndex: (index: any) => void;
	fetchDeviceManagementData: () => void;
}

export const DeviceManagementHeader = ({
	setSelectedBuildingData,
	setShowAddBuilding,
	onFilterData,
	setShowLocationModal,
	setBuildingIndex,
	fetchDeviceManagementData,
}: IDeviceManagementHeader) => {
	return (
		<div className="row">
			{/* <div className="col-lg-6 col-md-12 col-sm-12 ">
				<div className="icon-input">
					<input
						name="search"
						onChange={onFilterData}
						className="add-re-input form-control pt-2 pb-2"
						placeholder="Search Building/Location/Device Id/Device Name"
					/>
					<SearchIcon className="search-icon" />
				</div>
			</div> */}
			{window.innerWidth <= 1024 && (
				<DeviceSetUp onRefresh={fetchDeviceManagementData} />
			)}
			{window.innerWidth > 1024 && (
				<div className="col-12 m-d-none">
					<div className="d-flex align-items-center btn-align-ment">
						<Button
							onClick={() => {
								setShowAddBuilding(true);
								setSelectedBuildingData({});
							}}
							type="button"
							className="btn add-new-building-tour btn-medium mr-3 m-m-0"
						>
							<span className="">Add New Building</span>
						</Button>

						<Button
							onClick={() => {
								setShowLocationModal(true);
								setBuildingIndex(null);
							}}
							type="button"
							className="btn add-new-location-tour btn-medium mr-3 m-mx-2"
						>
							<span>Add New Location</span>
						</Button>
						<Button
							onClick={fetchDeviceManagementData}
							className="btn btn-medium add-device refresh-button-tour m-d-block"
						>
							Refresh
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};
