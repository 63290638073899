import { put, takeLatest } from "redux-saga/effects";
import { getLocationList } from "../../api/LocationController";
import { GET_LOCATION } from "../actions/actionTypes";
import { setLocations } from "../actions/locationActions";
import * as Effects from "redux-saga/effects";

interface LocationResponse {
	responseObj: LocationList;
	message: string;
}

interface LocationList {
	locations: Array<object>;
}

const call: any = Effects.call;

export function* watchGetLocationRequests() {
	yield takeLatest(GET_LOCATION, fetchLocationList);
}

function* fetchLocationList(action: any) {
	try {
		if (action.payload.locations.length === 0) {
			if (action.payload.buildingId) {
				const response: LocationResponse = yield call(
					getLocationList,
					action.payload.buildingId // passing the buildingId in api call
				);
				if (response.responseObj) {
					const locationList = response.responseObj.locations;
					yield put(setLocations(locationList));
				} else {
					yield put(setLocations([]));
				}
			} else {
				yield put(setLocations([]));
			}
		}
	} catch (error) {}
}
