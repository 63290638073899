import { SET_BUILDING, SET_BUILDINGS } from "../actions/actionTypes";

export const buildingReducer = (state = {}, action: any) => {
	switch (action.type) {
		case SET_BUILDING:
			return { ...action.payload };
		default:
			return state;
	}
};

export const buildingsReducer = (state = [], action: any) => {
	switch (action.type) {
		case SET_BUILDINGS:
			return [...action.payload];
		default:
			return state;
	}
};
