export const URL = {
	DASHBOARD: "/dashboard",
	DASHBOARD_BUILDINGS: "/dashboard/buildings",
	DASHBOARD_AQ_PARAMETERS_EXPLAINED: "/dashboard/help/air-quality-param",
	DASHBOARD_PRODUCT_HELp: "/dashboard/help",
	FLOOR_PLAN_LIST: "/dashboard/floor",
	LOCATION_LIST: "/dashboard/location/list",
	DEVICES: "/dashboard/devices",
	AUTOPILOT: "/dashboard/autopilot",
	CLUSTER_CONFIG: "/dashboard/cluster-config",
	SCHEDULE: "/dashboard/schedule",
	IAQ_REPORTS: "/dashboard/reports/iaq-reports",
	MAINTENANCE_REPORTS: "/dashboard/reports/maintenance-reports",
	AUTOPILOT_REPORTS: "/dashboard/reports/autopilot-reports",
	CUSTOM_REPORTS: "/dashboard/reports/custom-reports",
	DEVICE_UTILIZATION_REPORTS: "/dashboard/reports/device-utilization-reports",
	CONSULTATION_REPORTS: "/dashboard/consultation-reports",
	CONSULTANTS: "/dashboard/consultants",
	SCHEDULE_REPORTS: "/dashboard/reports/schedule-reports",
	NEW_BUILDING: "/dashboard/building/new",
	NEW_LOCATION: "/dashboard/location/new",
	CONFIGURE_DEVICE: "/dashboard/devices/configureDevice",
	NEW_SCHEDULE: "/dashboard/schedule/new",
	DASHBOARD_PROFILE: "/dashboard/profile",
	ASSETS: "/dashboard/assets",
	USERS: "/dashboard/users",
	CONTRACTOR_USERS: "/dashboard/contractor-users",
	DEVICE_MANAGEMENT: "/dashboard/device-management",
	CUSTOMER_ACCOUNT: "/dashboard/customer-account",
	CUSTOMER_ACCOUNT_UNASSIGNED: "/dashboard/customer-account/unassigned",
	CHANGE_PASSWORD: "/dashboard/profile/change-password",
	ADD_EDIT_CONSULTANT_REPORT: "/dashboard/consultation",
	MARKET_PLACE: "/dashboard/market-place",
	MARKET_PLACE_PRODUCT_LIST: "/dashboard/market-place/product-list",
	PRODUCT_DETAILS: "/dashboard/market-place/Product-details",
	PAYMENT_SETUP: "/payment-setup",
	PAYMENT_ACTION_REQUIRED: "/payment-action-required",
	SUBSCRIPTION: "/dashboard/subscription",
	OFFLINE_SUBSCRIPTION: "/dashboard/offline-subscription",
	SIGN_UP: "/sign-up",
	NORMI_SIGN_UP: "/sign-up/normi",
	FORGOT_PASSWORD: "/forgotPassword",
	RESET_PASSWORD: "/reset-password",
	LOGIN: "/login",
	DATA_SIM: "/dashboard/data-sim",
	REJECT_ADMIN: "/reject-admin",
	MORENAVBAR: "/dashboard/more",
	IAQSUBMENU: "/dashboard/iaq-submenu",
	ADMINSUBMENU: "/dashboard/admin-submenu",
	IAQPRODUCT: "/dashboard/iaq-product",
	IAQCONSULTANT: "/dashboard/iaq-consultant",
	IAQSERVICES: "/dashboard/iaq-services",
	IAQCONSULTANTALL: "/dashboard/iaq-consultant/all",
	IAQVIEWPRODUCT: "/dashboard/iaq-product/view",
	IAQALLPRODUCT: "/dashboard/iaq-product/all",
	ADDNEWBUILDING: "/dashboard/device-management/addnew-building",
	EDITLOCATION: "/dashboard/device-management/edit-location",
	ADDLOCATION: "/dashboard/device-management/add-location",
	EDITNEWBUILDING: "/dashboard/device-management/edit-building",
};
