export class BuildingsDetailsModel {
	public name: string;
	public address: string;
	public description: string;
	public zipcode: string;
	public city: string;
	public state: string;
	public timeZoneName: string;

	public constructor() {
		this.name = "";
		this.address = "";
		this.description = "";
		this.zipcode = "";
		this.city = "";
		this.state = "";
		this.timeZoneName = "";
	}
}
