import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./More.scopped.scss";

import cloudLogo from "../../../images/iqi-cloud-logo.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { URL } from "../../../components/Navbar/routes";
import { MoreMenuValues } from "./Menus";
import MoreMenus from "../Components/MoreMenus";
import { setupWebViewJavascriptBridge } from "../../../common/JsBridge";
import { setAsset, setAssets } from "../../../redux/actions/assetActions";
import {
	setBuilding,
	setBuildings,
} from "../../../redux/actions/buildingActions";
import { clearUserData } from "../../../redux/actions/userActions";
import { isIpadOS } from "../../../common/service";
import { RootState } from "../../../redux/reducers";
import { roleConditionChecker } from "../../../utils/helpers";

const More: React.FC = (props: any) => {
	const dispatch = useDispatch();
	const user = useSelector((state: RootState) => state.user);
	const asset: any = useSelector((state: RootState) => state.asset);
	// const renderMoreMenus = () => (
	// 	<>
	// 		{MoreMenuValues.map((item: any, index: any) => {
	// 			return (
	// 				<div key={`${index}-${item.path}`}>
	// 					<MoreMenus item={item} />
	// 				</div>
	// 			);
	// 		})}
	// 	</>
	// );
	const renderMoreMenus = (item: any, index: any) => (
		<>
			{item.roles.includes(user.role) &&
				roleConditionChecker(item, user, asset) && (
					<div key={`${index}-${item.path}`}>
						<MoreMenus item={item} />
					</div>
				)}
		</>
	);
	const callHandler = (funName: any, dataJson: any, callback: any) => {
		setupWebViewJavascriptBridge(function (bridge: any) {
			bridge.callHandler(funName, dataJson, function (response: any) {
				callback && callback(response);
			});
		});
	};
	const goToLogin: any = () => {
		if (
			/android/.test(navigator.userAgent.toLowerCase()) ||
			/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) ||
			isIpadOS()
		) {
			callHandler("logout", {}, () => {});
		}
		dispatch(setAssets([]));
		dispatch(setAsset({}));
		dispatch(setBuildings([]));
		dispatch(setBuilding({}));
		dispatch(clearUserData());
		localStorage?.clear();
		props.history.push(URL.LOGIN);
	};
	return (
		<div className="more-container bg-white w-100">
			<div>
				<NavLink className="flex-1" to={URL.DASHBOARD_PROFILE}>
					<div className="mx-3 more-profile d-flex align-items-center justify-content-between p-3 bg-grey-1 radius-6">
						<div className="d-flex align-items-center flex-1 mr-2">
							<div className="profile-pic rounded-circle d-flex align-items-center justify-content-center mr-3 bg-white">
								{user?.avatar ? (
									<img
										className="w-100 h-100 rounded-circle"
										src={user?.avatar}
										alt=""
									/>
								) : (
									<p className="desc-large font-600 mb-0">
										{user?.firstName?.charAt(0).toUpperCase()}{" "}
										{user?.lastName?.charAt(0).toUpperCase()}
									</p>
								)}
							</div>
							<div className="flex-1 text-truncate">
								<p className="mb-1 text-truncate desc-large font-text-1 font-600">
									{user?.firstName} {user?.lastName}
								</p>
								<p className="mb-0 text-truncate desc-normal font-text-2">
									{user?.email}
								</p>
							</div>
						</div>
						<ArrowForwardIcon className="font-grey-light-2 i-16" />
					</div>
				</NavLink>
				<div className="mt-2 mb-3">
					{MoreMenuValues.map((item: any, index: any) => {
						return renderMoreMenus(item, index);
					})}
					<div className="more-list d-flex align-items-center justify-content-between p-3">
						<div
							onClick={goToLogin}
							className="d-flex align-items-center flex-1"
						>
							<div className="menu-icon rounded-circle d-flex align-items-center justify-content-center mr-3 bg-danger">
								<PowerSettingsNewIcon className="text-danger i-20" />
							</div>
							<div className="flex-1 text-truncate">
								<p className="mb-0 text-truncate desc-large txt-danger font-500">
									<button className="logout-btn txt-danger">Logout </button>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default More;
